import { toggleProductPicturesHeader } from 'qs-helpers/ProcessUploadedImage';
import { addNewVideo } from '../../Apis/Products/api';
import { uploadVideosViaWebWorker } from '../../VideoUpload/VideoUploader';
import CacheRequest from '../CacheRequest';
import { connector } from '../ApiAndCacheConnector';
import cloneDeep from 'lodash.clonedeep';
import { getUniqueDeviceId } from 'qs-helpers/DeviceIdGenerator';
import { videoThumbnailUrl, hslVideoUrl } from '../../../Services/VideoUpload/UploadVideos/Api';
import { fetchAndUpdateProductMetaInCache } from '../Products';

const changeProductVideosInCache = async ({
  catalogueId,
  productId,
  videos,
  cacheKey: basicInfoCacheKey,
  videoId
}) => {
  const basicInfoCache = CacheRequest.getCacheForKey(basicInfoCacheKey);
  const newBasicInfoCache = cloneDeep(basicInfoCache) || {};

  const cacheFormatOfPictures = {};
  const apiMeta = {
    pictureMeta: []
  };

  const newVideoToUpload = [];
  const uuid = getUniqueDeviceId();

  const pictureMeta = {
    id: videoId,
    url: videoThumbnailUrl(videoId),
    prepared: false,
    processed: false,
    videoUrl: hslVideoUrl(videoId)
  };

  const apiData = {
    videoId,
    url: videoThumbnailUrl(videoId),
    prepared: false,
    processed: false,
    uuid,
    videoUrl: hslVideoUrl(videoId)
  };

  newVideoToUpload.push({
    videoId,
    product: videos,
    prepared: false,
    processed: false,
    productId,
    catalogueId,
    timestamp: new Date().getTime()
  });

  apiMeta.pictureMeta.push(apiData);
  cacheFormatOfPictures[videoId] = pictureMeta;

  newBasicInfoCache.videos = {
    ...(newBasicInfoCache.videos || {}),
    ...cacheFormatOfPictures
  };

  CacheRequest.setCacheForKey(basicInfoCacheKey, newBasicInfoCache);
  return {
    newVideoToUpload
  };
};

const addVideoToRemote = ({ videos, productId }) => {
  return addNewVideo({ videos, productId });
};

const uploadVideosToProduct = async ({ videos, productId, catalogueId }) => {
  const cacheKey = `${connector.BASIC_INFO.cacheKey}${productId}`;

  try {
    const videoData = await addVideoToRemote({ videos, productId });
    const { videoId, reason = '' } = videoData;
    if (reason !== '') {
      return reason;
    }

    await changeProductVideosInCache({
      catalogueId,
      productId,
      videos,
      cacheKey,
      videoId
    });

    toggleProductPicturesHeader({ productId, totalImages: videos.length });

    fetchAndUpdateProductMetaInCache(productId);

    await uploadVideosViaWebWorker({ videoData, ...videos });
  } catch (error) {
    // Handle error
  }
};

export { uploadVideosToProduct };
