export const isAnyDomainConnected = domainData => {
  if (!domainData || !Array.isArray(domainData.data)) {
    return false;
  }
  return domainData.data.findIndex(domain => domain.domainStatus === 'connected') > -1;
};

export const getConnectedDomainData = domainData => {
  const connectedDomains = {};
  domainData.data.forEach(domain => {
    if (domain.domainStatus === 'connected') {
      connectedDomains[domain.type] = domain.domainName;
    }
  });
  return connectedDomains;
};
