import CacheRequest from '../CacheRequest';
import { ORDER_PRODUCTS, ORDER_PRODUCTS_SHIPPING } from 'qs-api/Orders/ApiCacheConnector';
import {
  deleteInquiries,
  updateInquiriesPrice,
  readInquiries,
  downloadZip,
  downloadExcel,
  downloadOrderPdf,
  sendOrderToVendor,
  setStockCount,
  updateInquiryTax,
  updateInquiryQuantity,
  updateOrderShippingPrice,
  updateOrderStatus,
  confirmOrder,
  updateOrderDiscount,
  downloadOrderB2BPdf,
  placeShippingOrder
} from 'qs-api/Orders/api';
import { reportError } from 'qs-helpers/ErrorReporting';
import { getMinimumPriceForOrder } from 'qs-helpers/Orders/OrderPriceCalculator';
import {
  deleteOrderFromOrderListCache,
  setOrderMetaInCache,
  getCompanyUnreadInquiryCountFromCache,
  setCompanyUnreadInquiryCountInCache,
  updateOrdersListPostStatusUpdate
} from './OrdersOperations';
import { getStockCountFromNewItemCount } from 'qs-helpers/Orders/ResponseProcessor';
import Mixpanel from '../Mixpanel';
import { ORDER_STATUS } from 'qs-helpers/Orders/constants';
import { roundNumberToGivenDecimals } from 'qs-helpers';
import moment from 'moment-timezone';

export const attachOrdersProductsListener = ({ listener, orderId }) => {
  const key = `${ORDER_PRODUCTS.cacheKey}${orderId}`;
  CacheRequest.attachListener(key, listener);
};

export const removeOrdersProductsListener = ({ listener, orderId }) => {
  const key = `${ORDER_PRODUCTS.cacheKey}${orderId}`;
  CacheRequest.removeListener(key, listener);
};

export const getOrdersProducts = ({ orderId }) => {
  const key = `${ORDER_PRODUCTS.cacheKey}${orderId}`;
  const apiName = ORDER_PRODUCTS.apiFunction;

  CacheRequest.makeRequest(key, apiName, {
    params: [orderId],
    options: {
      shouldNotStoreInNative: true
    }
  });
};

export const getOrdersProductsFromCache = ({ orderId }) => {
  const key = `${ORDER_PRODUCTS.cacheKey}${orderId}`;

  //Explicitly return undefined, so that default variable assignment works
  return CacheRequest.getCacheForKey(key) || undefined;
};

export const setOrdersProductsInCache = ({ updates, orderId }) => {
  const key = `${ORDER_PRODUCTS.cacheKey}${orderId}`;
  const existingOrderData = CacheRequest.getCacheForKey(key) || {};
  CacheRequest.setCacheForKey(key, {
    ...existingOrderData,
    ...updates
  });
};

export const placeExternalShippingOrder = async orderId => {
  try {
    const shippingConfig = await placeShippingOrder(orderId);
    setOrdersProductsInCache({
      orderId,
      updates: {
        shippingConfig
      }
    });
  } catch (placeShippingOrderError) {
    reportError(placeShippingOrderError);
    throw placeShippingOrderError;
  }
};

const getInquiriesMetaMappedById = inquiriesMeta => {
  if (!Array.isArray(inquiriesMeta)) {
    return {};
  }

  return inquiriesMeta.reduce((cumulativeMap, inquiry) => {
    const inquiryMeta = { ...inquiry };
    delete inquiryMeta.id;
    cumulativeMap[inquiry.id] = inquiry;
    return cumulativeMap;
  }, {});
};

export const mergeUpdatedInquiriesWithExistingInquiries = ({
  orderId,
  inquiriesMeta,
  inquiriesProductMeta
}) => {
  const { inquiries } = getOrdersProductsFromCache({ orderId });
  if (!Array.isArray(inquiries)) {
    return [];
  }

  if (!(inquiriesMeta || inquiriesProductMeta)) {
    return inquiries;
  }

  const inquiriesMetaMap = getInquiriesMetaMappedById(inquiriesMeta);
  inquiriesProductMeta = inquiriesProductMeta || {};

  return inquiries.map(inquiry => {
    const inquiryMeta = inquiriesMetaMap[inquiry.inquiryId];
    const productMeta = inquiriesProductMeta[inquiry.inquiryId];
    if (!inquiryMeta && !productMeta) {
      return inquiry;
    }

    const { product } = inquiry;
    const inquiryMetaForUpdate = inquiryMeta || {};
    const { product: productMetaInInquiry } = inquiryMetaForUpdate;
    return {
      ...inquiry,
      ...inquiryMetaForUpdate,
      product: {
        ...product,
        ...(productMeta || {}),
        ...(productMetaInInquiry || {})
      }
    };
  });
};

export const updateOrderProductsPrice = async ({ orderId, inquiryId, price, discountedPrice }) => {
  let shippingCost, inquiriesPriceMeta;
  try {
    ({ shippingCost, inquiriesPriceMeta } = await updateInquiriesPrice({
      inquiryId,
      price,
      discountedPrice
    }));
  } catch (updateError) {
    reportError(updateError);
    throw updateError;
  }

  const { inquiries: existingInquiries } = getOrdersProductsFromCache({ orderId }) || {};
  if (!Array.isArray(existingInquiries)) {
    return;
  }

  const mergedInquiries = mergeUpdatedInquiriesWithExistingInquiries({
    orderId,
    inquiriesMeta: inquiriesPriceMeta,
    inquiriesProductMeta: {
      [inquiryId]: {
        discounted_price: discountedPrice,
        price
      }
    }
  });

  setOrdersProductsInCache({
    orderId,
    updates: {
      inquiries: mergedInquiries,
      shippingCost
    }
  });

  const inquiryData = getOrdersProductsFromCache({ orderId });

  setOrderMetaInCache({
    orderId,
    updates: {
      finalAmount: getMinimumPriceForOrder(inquiryData)
    }
  });
};

export const deleteProductsFromOrder = async ({
  orderId,
  inquiryIdsMap,
  sortKey,
  confirmed,
  updateInventory
}) => {
  let inventoryUpdated, ordersPriceMetaMap;
  try {
    ({ inventoryUpdated, ordersPriceMetaMap } = await deleteInquiries({
      inquiryIds: Object.keys(inquiryIdsMap),
      updateInventory
    }));
  } catch (deleteError) {
    reportError(deleteError);
    throw deleteError;
  }

  let { inquiries } = getOrdersProductsFromCache({ orderId }) || {};
  if (!Array.isArray(inquiries)) {
    return {};
  }

  inquiries = inquiries.filter(inquiryData => inquiryIdsMap[inquiryData.inquiryId] !== true);
  setOrdersProductsInCache({
    orderId,
    updates: {
      inquiries
    }
  });

  //All inquiries in an order have been deleted, delete the order
  if (inquiries.length === 0) {
    const nextEligibleOrderId = deleteOrderFromOrderListCache({ sortKey, confirmed, orderId });
    return { orderDeleted: true, nextEligibleOrderId, inventoryUpdated };
  }

  // Added `OR` checks for safety, this data should always be present in the API response
  const { shippingCost, inquiriesPriceMeta } = (ordersPriceMetaMap || {})[orderId] || {};
  const mergedInquiries = mergeUpdatedInquiriesWithExistingInquiries({
    orderId,
    inquiriesMeta: inquiriesPriceMeta
  });

  setOrdersProductsInCache({
    orderId,
    updates: {
      inquiries: mergedInquiries,
      shippingCost
    }
  });

  const inquiryData = getOrdersProductsFromCache({ orderId });
  setOrderMetaInCache({
    orderId,
    updates: {
      numberOfItems: inquiryData.inquiries.reduce((finalCount, { isDeletedByVisitor }) => {
        let itemCount = 0;
        if (!isDeletedByVisitor) {
          itemCount = 1;
        }

        return (finalCount += itemCount);
      }, 0),
      finalAmount: getMinimumPriceForOrder(inquiryData)
    }
  });

  return { orderDeleted: false, inventoryUpdated };
};

export const markOrderInquiriesAsRead = async ({ orderId, inquiries, confirmed }) => {
  const inquiryIds = inquiries.reduce((unreadInquiryIds, inquiriyData) => {
    if (!inquiriyData.read) {
      unreadInquiryIds.push(inquiriyData.inquiryId);
    }
    return unreadInquiryIds;
  }, []);

  if (inquiryIds.length === 0) {
    return;
  }
  try {
    await readInquiries({ inquiryIds });
  } catch (markAsReadError) {
    reportError(markAsReadError);
  }

  setOrderMetaInCache({
    orderId,
    updates: {
      isRead: true
    }
  });
  updateAllUnreadInquiriesToRead({ orderId, confirmed });
};

export const updateAllUnreadInquiriesToRead = ({ orderId, confirmed }) => {
  const { inquiries } = getOrdersProductsFromCache({ orderId }) || {};
  if (!Array.isArray(inquiries)) {
    return;
  }

  let newInquiryCount = 0;
  const modifiedInquiries = inquiries.map(inquiryData => {
    if (inquiryData.read === false) {
      newInquiryCount += 1;
    }
    inquiryData.read = true;
    return inquiryData;
  });

  setOrdersProductsInCache({
    orderId,
    updates: {
      inquiries: modifiedInquiries
    }
  });

  let unreadInquiryCount =
    getCompanyUnreadInquiryCountFromCache({ orderStatusFilter: confirmed }) || {};
  unreadInquiryCount -= newInquiryCount;
  setCompanyUnreadInquiryCountInCache({
    orderStatusFilter: confirmed,
    updates: {
      unreadInquiryCount
    }
  });
};

export const downloadOrderAsZip = async ({ orderId }) => {
  try {
    return await downloadZip(orderId);
  } catch (downloadError) {
    reportError(downloadError);
    throw downloadError;
  }
};

export const downloadOrderAsPdf = async ({ orderId }) => {
  try {
    return await downloadOrderPdf(orderId);
  } catch (downloadError) {
    reportError(downloadError);
    throw downloadError;
  }
};

export const downloadOrderAsB2BPdf = async ({ orderId }) => {
  let timezone = 'Asia/Calcutta';
  try {
    timezone = moment.tz.guess(true);
  } catch (error) {
    // do nothing
  }

  try {
    return await downloadOrderB2BPdf({ orderId, timezone });
  } catch (downloadError) {
    reportError(downloadError);
    throw downloadError;
  }
};

export const downloadOrderAsExcel = async ({ orderId }) => {
  try {
    return await downloadExcel(orderId);
  } catch (downloadError) {
    reportError(downloadError);
    throw downloadError;
  }
};

export const sendCurrentOrderToVendor = async ({ orderId }) => {
  try {
    return await sendOrderToVendor(orderId);
  } catch (sendError) {
    reportError(sendError);
    throw sendError;
  }
};

export const changeOrderProductStockCount = async ({
  orderId,
  productId,
  inquiryId,
  stockCount
}) => {
  try {
    await setStockCount({ productId, stockCount });
  } catch (stockCountError) {
    reportError(stockCountError);
    throw stockCountError;
  }

  const { inquiries } = getOrdersProductsFromCache({ orderId }) || {};
  if (!Array.isArray(inquiries)) {
    return;
  }

  setOrdersProductsInCache({
    orderId,
    updates: {
      inquiries: inquiries.map(inquiryData => {
        if (inquiryId === inquiryData.inquiryId) {
          inquiryData.stockCount = stockCount;
        }
        return inquiryData;
      })
    }
  });
};

export const changeOrderProductTax = async ({ orderId, inquiryId, taxes }) => {
  let updatedShippingPrice, inquiriesPriceMeta;
  let taxIds = [];
  if (Array.isArray(taxes)) {
    //Remove nulls if exist
    taxes = taxes.filter(tax => tax);
    taxIds = taxes.map(({ taxId }) => taxId);
  } else {
    taxes = [];
  }

  try {
    ({ updatedShippingPrice, inquiriesPriceMeta } = await updateInquiryTax({ inquiryId, taxIds }));
  } catch (inquiryTaxError) {
    reportError(inquiryTaxError);
    throw inquiryTaxError;
  }

  const mergedInquiries = mergeUpdatedInquiriesWithExistingInquiries({
    orderId,
    inquiriesMeta: inquiriesPriceMeta,
    inquiriesProductMeta: {
      [inquiryId]: {
        taxes
      }
    }
  });

  setOrdersProductsInCache({
    orderId,
    updates: {
      inquiries: mergedInquiries,
      shippingCost: updatedShippingPrice
    }
  });

  const inquiryData = getOrdersProductsFromCache({ orderId });
  setOrderMetaInCache({
    orderId,
    updates: {
      finalAmount: getMinimumPriceForOrder(inquiryData)
    }
  });
};

export const changeInquiryQuantity = async ({ inquiryId, orderId, itemCount, isFinalized }) => {
  let updatedShippingPrice = null;
  let inventoryUpdated = false;
  let inquiriesPriceMeta;
  try {
    ({ updatedShippingPrice, inventoryUpdated, inquiriesPriceMeta } = await updateInquiryQuantity({
      inquiryId,
      itemCount
    }));
  } catch (updateInquiryError) {
    reportError(updateInquiryError);
    throw updateInquiryError;
  }

  const ordersPrdFromCache = getOrdersProductsFromCache({ orderId }) || {};
  const { inquiries } = ordersPrdFromCache;
  if (!Array.isArray(inquiries)) {
    return { inventoryUpdated };
  }

  const mergedInquiries = mergeUpdatedInquiriesWithExistingInquiries({
    orderId,
    inquiriesMeta: inquiriesPriceMeta
  });

  setOrdersProductsInCache({
    orderId,
    updates: {
      inquiries: mergedInquiries.map(inquiryData => {
        if (inquiryId !== inquiryData.inquiryId) {
          return inquiryData;
        }

        const updatedInquiryData = {
          ...inquiryData,
          itemCount
        };

        if (
          !inquiryData.canUpdateStock ||
          typeof inquiryData.stockCount !== 'number' ||
          inquiryData.stockCount <= 0
        ) {
          return updatedInquiryData;
        }

        if (isFinalized) {
          updatedInquiryData.stockCount = Math.max(
            getStockCountFromNewItemCount({
              stockCount: inquiryData.stockCount,
              itemCount: inquiryData.itemCount,
              updatedCount: itemCount
            }),
            0
          );
        }

        return updatedInquiryData;
      }),
      shippingCost: updatedShippingPrice
    }
  });

  const inquiryData = getOrdersProductsFromCache({ orderId });
  setOrderMetaInCache({
    orderId,
    updates: {
      finalAmount: getMinimumPriceForOrder(inquiryData)
    }
  });

  return { inventoryUpdated };
};

export const changeOrderStatusAndUpdateOrderProducts = async ({
  orderId,
  orderStatus,
  sortKey
}) => {
  Mixpanel.sendEvent('order_status_changed', { orderId, orderStatus }, 'OrderProductsView');
  let inventoryUpdated = false,
    inquiryUpdates;
  try {
    ({ inventoryUpdated, inquiryUpdates } = await updateOrderStatus({ orderId, orderStatus }));
  } catch (updateOrderError) {
    reportError(updateOrderError);
    throw updateOrderError;
  }

  let { inquiries } = getOrdersProductsFromCache({ orderId }) || {};
  if (inquiryUpdates) {
    inquiries = inquiries.map(inquiry => {
      const inquiryUpdate = inquiryUpdates[inquiry.inquiryId];
      if (!inquiryUpdate) {
        return inquiry;
      }

      return {
        ...inquiry,
        ...inquiryUpdate
      };
    });
  }

  setOrdersProductsInCache({
    orderId,
    updates: {
      orderStatus,
      inquiries
    }
  });

  const nextEligibleOrderId = updateOrdersListPostStatusUpdate({ orderId, orderStatus, sortKey });

  setOrderMetaInCache({
    orderId,
    updates: {
      orderStatus
    }
  });

  return { inventoryUpdated, nextEligibleOrderId };
};

export const markOrderAsConfirmed = async ({ orderId, sortKey }) => {
  let data = {};
  try {
    data = await confirmOrder({ orderId });
  } catch (confirmOrderError) {
    reportError(confirmOrderError);
    throw confirmOrderError;
  }
  const orderStatus = ORDER_STATUS.ACCEPTED;

  const nextEligibleOrderId = updateOrdersListPostStatusUpdate({
    orderId,
    orderStatus,
    sortKey
  });

  setOrdersProductsInCache({
    orderId,
    updates: {
      orderStatus,
      orderEditable: true
    }
  });

  setOrderMetaInCache({
    orderId,
    updates: {
      isFinalized: true,
      isFinalizedByCompany: true,
      dateFinalized: data.dateFinalized,
      orderStatus
    }
  });

  return {
    ...data,
    nextEligibleOrderId
  };
};

export const attachOrdersShippingListener = ({ listener, orderId }) => {
  const key = `${ORDER_PRODUCTS_SHIPPING.cacheKey}${orderId}`;
  CacheRequest.attachListener(key, listener);
};

export const removeOrdersShippingListener = ({ listener, orderId }) => {
  const key = `${ORDER_PRODUCTS_SHIPPING.cacheKey}${orderId}`;
  CacheRequest.removeListener(key, listener);
};

export const getOrdersShippingInfo = ({ orderId }) => {
  const key = `${ORDER_PRODUCTS_SHIPPING.cacheKey}${orderId}`;
  const apiName = ORDER_PRODUCTS_SHIPPING.apiFunction;
  CacheRequest.makeRequest(key, apiName, {
    params: [orderId],
    options: {
      shouldNotStoreInNative: true
    }
  });
};

export const getOrdersShippingFromCache = ({ orderId }) => {
  const key = `${ORDER_PRODUCTS_SHIPPING.cacheKey}${orderId}`;

  //Explicitly return undefined, so that default variable assignment works
  return CacheRequest.getCacheForKey(key) || undefined;
};

export const setOrdersShippingInCache = ({ updates, orderId }) => {
  const key = `${ORDER_PRODUCTS_SHIPPING.cacheKey}${orderId}`;
  const existingOrderData = CacheRequest.getCacheForKey(key) || {};
  CacheRequest.setCacheForKey(key, {
    ...existingOrderData,
    ...updates
  });
};

export const changeOrderShippingPrice = async ({ orderId, shippingCost }) => {
  try {
    await updateOrderShippingPrice({ orderId, shippingCost });
  } catch (updateShippingError) {
    reportError(updateShippingError);
    throw updateShippingError;
  }

  setOrdersProductsInCache({
    orderId,
    updates: {
      shippingCostSeller: shippingCost
    }
  });

  const inquiryData = getOrdersProductsFromCache({ orderId });
  setOrderMetaInCache({
    orderId,
    updates: {
      finalAmount: getMinimumPriceForOrder(inquiryData)
    }
  });
};

export const updateOrderDiscountAmount = async ({ orderId, amount, type }) => {
  if (typeof amount === 'number') {
    amount = roundNumberToGivenDecimals(amount);
  }

  //No change in data, no need to make an API call
  const { orderDiscountType, orderDiscount } = getOrdersProductsFromCache({ orderId });
  if (type === orderDiscountType && orderDiscount === amount) {
    return;
  }

  let shippingCost, inquiriesPriceMeta;
  try {
    ({ shippingCost, inquiriesPriceMeta } = await updateOrderDiscount({
      orderId,
      discount: amount,
      type
    }));
  } catch (updateError) {
    reportError(updateError);
    throw updateError;
  }

  const mergedInquiries = mergeUpdatedInquiriesWithExistingInquiries({
    orderId,
    inquiriesMeta: inquiriesPriceMeta
  });

  setOrdersProductsInCache({
    orderId,
    updates: {
      inquiries: mergedInquiries,
      orderDiscount: amount || null,
      orderDiscountType: type,
      shippingCost
    }
  });

  const inquiryData = getOrdersProductsFromCache({ orderId });

  setOrderMetaInCache({
    orderId,
    updates: {
      finalAmount: getMinimumPriceForOrder(inquiryData)
    }
  });
};
