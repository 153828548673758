import { CATALOGUE_HEADLINE } from 'qs-api/Catalogues/ApiCacheConnector';
import { setCatalogueHeadline } from 'qs-api/Catalogues/api';
import CacheRequest from '../CacheRequest';
import { reportError } from 'qs-helpers/ErrorReporting';

const createCacheKeyForHeadline = catalogueId => `${CATALOGUE_HEADLINE}${catalogueId}`;

export const getCatalogueHeadlineFromCache = ({ catalogueId }) =>
  CacheRequest.getCacheForKey(createCacheKeyForHeadline(catalogueId)) || undefined;

export const attachCatalogueHeadlineListener = ({ listener, catalogueId }) => {
  CacheRequest.attachListener(createCacheKeyForHeadline(catalogueId), listener);
};

export const getCatalogueHeadlineData = ({ catalogueId }) => {
  const key = createCacheKeyForHeadline(catalogueId);
  const apiName = CATALOGUE_HEADLINE.apiFunction;
  CacheRequest.makeRequest(key, apiName, {
    params: [catalogueId],
    options: {
      shouldNotStoreInNative: true
    }
  });
};

export const removeCatalogueHeadlineListener = ({ catalogueId, listener }) => {
  return CacheRequest.removeListener(createCacheKeyForHeadline(catalogueId), listener);
};

const setCatalogueHeadlineInCache = (catalogueId, headline) => {
  CacheRequest.setCacheForKey(createCacheKeyForHeadline(catalogueId), { headline });
};

export const updateCatalogueHeadline = async ({ catalogueId, headline }) => {
  if (!catalogueId) {
    return;
  }
  try {
    await setCatalogueHeadline({
      catalogueId,
      updates: {
        description: headline
      }
    });
    setCatalogueHeadlineInCache(catalogueId, headline);
  } catch (updateError) {
    reportError(updateError);
    throw updateError;
  }
};
