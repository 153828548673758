import React, { useState, useRef } from 'react';
import { getCompanyCurrencySymbol } from 'qs-data-manager/Company';
import { ensureInputIsNumber, isInputNumber, roundNumberToGivenDecimals } from 'qs-helpers';
import { getPriceAfterDiscount } from 'qs-helpers/Orders/OrderPriceCalculator';
import {
  DialogDarkTheme,
  PrimaryButton,
  SecondaryButton
} from 'qs-components/Common/DarkThemeDialog';
import {
  DialogContent,
  DialogActions,
  InputAdornment,
  makeStyles,
  TextField
} from '@material-ui/core';
import { PRICE_INPUT, DISCOUNT_INPUT } from 'qs-helpers/Orders/constants';
import { useInputStyles } from 'qs-hooks/useInputStyles';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

const useComponentSyles = makeStyles({
  textFieldRoot: {
    marginTop: '10px'
  }
});

export default props => {
  const componentSyles = useComponentSyles();
  const inputStyles = useInputStyles();
  let {
    price,
    discountedPrice,
    coupon,
    selectedField,
    disableDiscountField,
    isSet,
    setMultiplier
  } = props;
  const { t } = getI18N();

  if (price === undefined || price === null) {
    price = '';
  }
  if (discountedPrice === undefined || discountedPrice === null) {
    discountedPrice = '';
  }

  let showCouponField = false;
  if (coupon && typeof coupon.applyOnDiscountedPrice === 'boolean') {
    showCouponField = true;
  }

  const [actualPrice, setActualPrice] = useState(price);
  const [actualPriceError, setActualPriceError] = useState('');
  const [inputDiscountedPrice, setDiscountedPrice] = useState(discountedPrice);
  const [discountedPriceError, setDiscountedPriceError] = useState('');
  const [finalPrice, setFinalPrice] = useState(
    getPriceAfterDiscount({ discountedPrice, price, coupon })
  );

  const savedCurrencySymbol = useRef(getCompanyCurrencySymbol());
  const { current: companyCurrencySymbol } = savedCurrencySymbol;

  const handleActualPriceChange = event => {
    const value = ensureInputIsNumber({ event, integer: false });
    if (value === null) {
      return;
    }

    setActualPrice(value);
    setActualPriceError('');
    setFinalPrice(
      getPriceAfterDiscount({
        price: value,
        discountedPrice: inputDiscountedPrice,
        coupon
      })
    );
  };

  const handleDiscountedPriceChange = function(event) {
    const value = ensureInputIsNumber({ event, integer: false });
    if (value === null) {
      return;
    }

    setDiscountedPrice(value);
    setDiscountedPriceError('');
    setFinalPrice(
      getPriceAfterDiscount({
        price: actualPrice,
        discountedPrice: value,
        coupon
      })
    );
  };

  const validatePrice = function(priceVal) {
    let number,
      error = '';

    //The state is set as blank, hence this is the only invalid value
    if (priceVal !== '') {
      if (isInputNumber(priceVal)) {
        number = Number(priceVal);
        if (number < 0) {
          error = 'Original Price can not be a negative number';
        }
      } else {
        error = 'Invalid Value';
      }
    }

    return { number, error };
  };

  const handleSetPrice = function(event) {
    event.preventDefault();

    const actualPriceProps = validatePrice(actualPrice);
    const numActualPrice = actualPriceProps.number,
      numActualPriceError = actualPriceProps.error;
    setActualPriceError(numActualPriceError);

    const discountedPriceProps = validatePrice(inputDiscountedPrice);
    const numDiscountedPrice = discountedPriceProps.number,
      numDiscountedPriceError = discountedPriceProps.error;
    setDiscountedPriceError(numDiscountedPriceError);

    // Both numbers are valid, validate further
    if (!numActualPriceError && !numDiscountedPriceError) {
      /*
        discountedPrice is not set, hence actual price can not be
        less than the discountedPrice. Hence the API call can be made
        directly. If present, validate if the actual price has been set
        and is greater than the discounted price
      */
      if (typeof numDiscountedPrice === 'number') {
        /*
          Actual price exists and discounted price exists,
          validate if the actual price is more
        */
        if (typeof numActualPrice === 'number') {
          if (numActualPrice < numDiscountedPrice) {
            setDiscountedPriceError('Discounted price can not be more than the original price');
            return;
          }
        } else {
          // Discounted price is present but actual price is unset, show the error
          setActualPriceError('Price is required when discounted price is set');
          return;
        }
      }

      //Everything valid, proceed
      props.onComplete(
        roundNumberToGivenDecimals(numActualPrice, 2),
        roundNumberToGivenDecimals(numDiscountedPrice, 2)
      );
    }
  };

  const handleCancel = function(event) {
    event.preventDefault();
    props.onCancel();
  };

  const getDiscountedPriceForRendering = () => {
    //No discount added, no discount given
    if (inputDiscountedPrice === '') {
      return `${companyCurrencySymbol} 0`;
    }

    const priceNum = Number(actualPrice);
    const discountPriceNum = Number(inputDiscountedPrice);
    return (
      `${companyCurrencySymbol} ${roundNumberToGivenDecimals(priceNum - discountPriceNum, 2)} ` +
      `(${companyCurrencySymbol} ${priceNum} - ${companyCurrencySymbol} ${discountPriceNum})`
    );
  };

  const getTotalDiscountForRendering = () => {
    let totalDiscount = 0;
    if (inputDiscountedPrice !== '') {
      totalDiscount += Number(actualPrice) - Number(inputDiscountedPrice);
    }

    if (finalPrice !== -1) {
      totalDiscount += finalPrice;
    }

    return `${companyCurrencySymbol} ${roundNumberToGivenDecimals(totalDiscount, 2)}`;
  };

  const renderCouponData = () => {
    const { discountPercent, code, name } = coupon;
    return (
      <div className="couponApplied">
        <div className="ellipsis">
          {code} {discountPercent}% off - {name}
        </div>
        <div className="totalCouponDiscount">
          {companyCurrencySymbol} {finalPrice >= 0 ? finalPrice : 0} off
        </div>
      </div>
    );
  };

  const getTextField = ({ placeholder, autoFocus, disabled, error, value, onChange }) => {
    return (
      <TextField
        InputProps={{
          classes: {
            root: inputStyles.inputRoot,
            underline: inputStyles.inputUnderline,
            input: inputStyles.input
          },
          placeholder,
          startAdornment: (
            <InputAdornment position="start" disableTypography>
              {companyCurrencySymbol}
            </InputAdornment>
          ),
          autoFocus
        }}
        classes={{ root: componentSyles.textFieldRoot }}
        FormHelperTextProps={{
          classes: { root: inputStyles.inputError }
        }}
        error={error !== ''}
        helperText={error}
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
    );
  };

  const renderPricePerPiece = () => {
    if (!isSet) {
      return null;
    }

    return (
      <>
        <div className="sectionSeperator title">
          {t('original_price')} ({t('per_pc')})
        </div>
        {getTextField({
          disabled: true,
          error: '',
          value: roundNumberToGivenDecimals(actualPrice / setMultiplier)
        })}
        <div className="sectionSeperator title">
          {t('discounted_price')} ({t('per_pc')})
        </div>
        {getTextField({
          disabled: true,
          error: '',
          value: roundNumberToGivenDecimals(inputDiscountedPrice / setMultiplier)
        })}
      </>
    );
  };

  return (
    <DialogDarkTheme
      open={true}
      PaperProps={{
        className: 'orderProductsSetPriceContentContainer',
        component: 'form',
        onSubmit: handleSetPrice,
        noValidate: true
      }}
      onClose={handleCancel}
    >
      <DialogContent className="dialogSetPriceContentContainer columnAlignContent">
        <div className="title">
          {t('original_price')} {isSet ? `(${t('per_set')})` : ''}
        </div>
        {getTextField({
          placeholder: t('enter_original_price'),
          autoFocus: selectedField === PRICE_INPUT,
          error: actualPriceError,
          value: actualPrice,
          onChange: handleActualPriceChange
        })}
        <div className="sectionSeperator title">
          {t('discounted_price')} {isSet ? `(${t('per_set')})` : ''}
        </div>
        {getTextField({
          placeholder: t('enter_discounted_price'),
          autoFocus: selectedField === DISCOUNT_INPUT,
          disabled: disableDiscountField,
          error: discountedPriceError,
          value: inputDiscountedPrice,
          onChange: handleDiscountedPriceChange
        })}
        {renderPricePerPiece()}
        <div className="sectionSeperator title">{t('discount')}</div>
        <div className="discountCalculated">{getDiscountedPriceForRendering()}</div>
        {showCouponField && (
          <>
            <div className="sectionSeperator title">{t('coupon_applied')}</div>
            {renderCouponData()}
            <div className="totalDiscountSection">
              {t('total_discount')}: {getTotalDiscountForRendering()}
            </div>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <SecondaryButton onClick={handleCancel}>
          <span>{t('cancel')}</span>
        </SecondaryButton>
        <PrimaryButton color={'primary'} onClick={handleSetPrice} type="submit">
          <span>{t('save_price')}</span>
        </PrimaryButton>
      </DialogActions>
    </DialogDarkTheme>
  );
};
