export const getPlaceholderInputData = ({ value = '', displayValue = 'Select Value' } = {}) => {
  return {
    value,
    displayValue,
    disabled: true
  };
};

export const renderValueForSelect = (
  selected,
  { seperator = ', ', placeholder = 'Select Value' } = {}
) => {
  if (selected.length === 0) {
    return placeholder;
  }

  return selected.join(seperator);
};

export const keepArrayNoSelectionValueSameForSelect = (valueToCheck, noSelectionValue) => {
  if (!Array.isArray(valueToCheck) || valueToCheck.length === 0) {
    return noSelectionValue;
  }
  return valueToCheck;
};
