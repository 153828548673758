import { ALL_ORDERS, DATE_UPDATED_SORT_KEY } from 'qs-helpers/Orders/constants';

export const UPDATE_ORDER_META = 'UPDATE_ORDER_META';
export const UPDATE_ORDER_LIST_FILTER = 'UPDATE_ORDER_LIST_FILTER';
export const UPDATE_ORDER_LIST_SEARCH = 'UPDATE_ORDER_LIST_SEARCH';

export const activeOrderMetaInit = () => ({
  selectedOrderId: null,
  currentSortKey: DATE_UPDATED_SORT_KEY,
  currentConfirmFilter: ALL_ORDERS,
  searchText: ''
});

export const activeOrderMetaReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_ORDER_META:
      return {
        ...state,
        selectedOrderId: action.orderId || null
      };
    case UPDATE_ORDER_LIST_FILTER:
      return {
        ...state,
        currentSortKey: action.sortKey || state.currentSortKey,
        currentConfirmFilter: action.confirm || state.currentConfirmFilter,
        selectedOrderId: null
      };
    case UPDATE_ORDER_LIST_SEARCH:
      const newSearchText = action.searchText || '';
      //Search text has not changed, don't update the state
      if (state.searchText === newSearchText) {
        return state;
      }

      return {
        ...state,
        searchText: newSearchText
      };
    default:
      return state;
  }
};
