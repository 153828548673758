import AppConfig from 'qs-config/AppConfig';
import Api from '../../Api';
import request from '../../Request';

export const searchProductLibCatalogueMeta = ({ page, searchTerm, excludeCatalogueId }) => {
  const queryParamsString = request.createQueryParams([
    {
      key: 'searchTerm',
      value: searchTerm ? searchTerm : null
    },
    {
      key: 'page',
      value: page
    },
    {
      key: 'excludeCatalogueId',
      value: excludeCatalogueId
    }
  ]);
  return request.authGet(
    `${AppConfig.qsApiEndPoint}/v1/product-library/search-catalogues${queryParamsString}`
  );
};

export const getCatalogueHeadline = data =>
  request.authGet(`${AppConfig.qsApiEndPoint}/v1/catalogue/headline?catalogueId=${data}`);

export const setCatalogueHeadline = data =>
  request.authPost(`${AppConfig.qsApiEndPoint}/v1/catalogue/upsert`, data);

export const setHomepagePublish = data =>
  request.authPost(`${AppConfig.qsApiEndPoint}/v1/showcase/homepage/publish`, data);

export const duplicateSelectedProducts = ({ productIds, catalogueId, shouldCreate, title }) =>
  Api.duplicateProductsToCatalogue({ catalogueId, productIds, shouldCreate, title });
