import {
  updateVariantsStock,
  updateInventoryData,
  setVariantOperationData,
  updateVariantsStockAndUpdateOperation
} from 'qs-data-manager/Variants/VariantInventory';
import { updateProductsStock } from 'qs-data-manager/Products';
import {
  FROM_VARIANTS,
  VARIANT_OPERATION_KEYS,
  FROM_VARIANTS_TRACK
} from 'qs-helpers/Variants/constants';
import { toggleGlobalLoader } from 'qs-helpers';
import Api from 'qs-services/Api';
import * as Sentry from '@sentry/browser';

let STOCK_COUNT_DEBOUNCER = {};
const DEBOUNCE_TIME = 500;

export const INVENTORY_META_IDS = {
  TRACK_INVENTORY: 'TRACK_INVENTORY',
  AUTO_REDUCE_INVENTORY: 'AUTO_REDUCE_INVENTORY',
  STOCK_VISIBILITY: 'STOCK_VISIBILITY',
  ORDER_ON_OOS: 'ORDER_ON_OOS',
  STOCK_COUNT_CHANGE: 'STOCK_COUNT_CHANGE'
};

const onStockCountChange = ({ stockCount, productId }, { from } = {}) => {
  if (STOCK_COUNT_DEBOUNCER[productId]) {
    clearTimeout(STOCK_COUNT_DEBOUNCER[productId]);
  }

  let debounceCallback = null;
  if (from === FROM_VARIANTS) {
    debounceCallback = () =>
      updateVariantsStockAndUpdateOperation({ variantId: productId, stock: stockCount });
  } else if (from === FROM_VARIANTS_TRACK) {
    debounceCallback = () => updateVariantsStock({ variantId: productId, stock: stockCount });
  } else {
    debounceCallback = () => updateProductsStock({ productId, stockCount });
  }

  STOCK_COUNT_DEBOUNCER[productId] = setTimeout(() => {
    clearTimeout(STOCK_COUNT_DEBOUNCER[productId]);
    debounceCallback();
  }, DEBOUNCE_TIME);
};

const onTrackInventoryChange = async ({ value, productId } = {}, { from } = {}) => {
  if (from === FROM_VARIANTS) {
    setVariantOperationData({
      variantId: productId,
      updates: [
        {
          key: VARIANT_OPERATION_KEYS.TRACK_INVENTORY,
          inProgress: true
        }
      ]
    });
    await Api.setInventoryTracking({ productIds: [productId], value });
    updateInventoryData({ inventoryId: productId, trackInventory: value });
    setVariantOperationData({
      variantId: productId,
      updates: [
        {
          key: VARIANT_OPERATION_KEYS.TRACK_INVENTORY,
          inProgress: false
        }
      ]
    });
    return;
  }

  await Api.setInventoryTracking({ productIds: [productId], value });
};

const changeAutoReduceQuantity = async ({ productId, value }, { from } = {}) => {
  await Api.autoReduceQuantity({
    productIds: [productId],
    value
  });

  if (from === FROM_VARIANTS) {
    updateInventoryData({ inventoryId: productId, autoReduceInventory: value });
  }
};

const stockVisibilityChange = async ({ productId, value }, { from } = {}) => {
  await Api.setOutOfStockVisibility({ productIds: [productId], value });

  if (from === FROM_VARIANTS) {
    updateInventoryData({ inventoryId: productId, showOutOfStockProduct: value });
  }
};

const orderOnOutOfStock = async ({ productId, value }, { from } = {}) => {
  await Api.setAllowOrderOnOutOfStock({
    productIds: [productId],
    value
  });

  if (from === FROM_VARIANTS) {
    updateInventoryData({ inventoryId: productId, allowOrdersOnOutOfStock: value });
  }
};

export const onInventoryChange = async (
  key,
  data,
  { showLoader = true, handleError = true, from } = {}
) => {
  const loaderKey = `onInventoryChange${Date.now()}`;
  showLoader && toggleGlobalLoader(loaderKey, true);

  let promise;
  if (key === INVENTORY_META_IDS.TRACK_INVENTORY) {
    promise = onTrackInventoryChange(data, { from });
  } else if (key === INVENTORY_META_IDS.AUTO_REDUCE_INVENTORY) {
    promise = changeAutoReduceQuantity(data, { from });
  } else if (key === INVENTORY_META_IDS.STOCK_VISIBILITY) {
    promise = stockVisibilityChange(data, { from });
  } else if (key === INVENTORY_META_IDS.ORDER_ON_OOS) {
    promise = orderOnOutOfStock(data, { from });
  } else if (key === INVENTORY_META_IDS.STOCK_COUNT_CHANGE) {
    promise = onStockCountChange(data, { from });
  }

  if (!promise) {
    promise = Promise.resolve();
  }

  try {
    await promise;
  } catch (error) {
    Sentry.captureException(error);
    if (!handleError) {
      throw error;
    }
  } finally {
    showLoader && toggleGlobalLoader(loaderKey, false);
  }
};
