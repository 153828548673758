import React from 'react';
import InfoFormField from '../InfoFormField';
import useMakeQuery from 'qs-hooks/useMakeQuery';
import {
  fetchShippingWeightUnitsHandler,
  handleShippingWeightUnitsCleanup
} from 'qs-helpers/Shipping/DataQueryHelper';
import { getShippingWeightUnitsFromCache } from 'qs-data-manager/Shipping/WeightUnits';
import Loader from '../../../../Common/Loader';
import NumberInput from '../../../../Common/NumberInput';
import { Button, makeStyles } from '@material-ui/core';
import { navigateToPath } from 'qs-data-manager';
import {
  ROUTE_MAP,
  SETTINGS_COMPONENT_ID_MAP,
  SETTINGS_COMPONENT_ROUTE_MAP
} from 'qs-helpers/CompanySettings/constants';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

const useStyles = makeStyles({
  manageShipping: {
    color: '#82B4FB',
    fontSize: 14,
    textTransform: 'none',
    fontWeight: 400
  }
});

export default ({ showWeightChange, onChangeClick, weight, onWeightChange, disabled, isSet }) => {
  const [{ loading, data }] = useMakeQuery({
    changeDependancy: [],
    actionHandler: fetchShippingWeightUnitsHandler,
    cleanupHandler: handleShippingWeightUnitsCleanup,
    cachedDataHandler: getShippingWeightUnitsFromCache,
    globalLoader: false
  });
  const componentStyles = useStyles();
  const { t } = getI18N();

  const getShippingUnitComponent = () => {
    if (loading) {
      return <Loader size="extraSmall" />;
    }
    if (!data) {
      return null;
    }

    let weightUnitString = data.weightUnit;
    if (isSet) {
      weightUnitString += `/${t('set')}`.toLowerCase();
    } else {
      weightUnitString += `/${t('piece')}`.toLowerCase();
    }
    return weightUnitString;
  };

  const onManageShippingClick = () => {
    navigateToPath(
      `/${ROUTE_MAP.ACCOUNT_SETTINGS}/${
        SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.SHIPPING_COST_SETTINGS].ROUTE
      }`
    );
  };

  const getFieldHeading = () => {
    return (
      <>
        <span>{t('weight')}</span>
        <Button onClick={onManageShippingClick} className={componentStyles.manageShipping}>
          {t('manage_shipping')}
        </Button>
      </>
    );
  };

  return (
    <InfoFormField
      fieldType={'INPUT'}
      fieldLabel={t('weight')}
      label={getFieldHeading()}
      changeButton={showWeightChange}
      onChangeClick={onChangeClick}
      fieldContainerClass="shippingUnitsContainer"
      fieldHeaderClass="shippingFieldHeaderContainer"
    >
      <NumberInput
        value={weight}
        onChange={onWeightChange}
        inputClassName={`shippingInput`}
        disabled={disabled}
        placeholder={t('type_weight')}
      />
      <span className="shippingUnit">{getShippingUnitComponent()}</span>
    </InfoFormField>
  );
};
