import React from 'react';
import CheckBox from 'qs-components/Common/CheckBox';
import { ReactComponent as DeleteIcon } from 'qs-assets/Media/trash.svg';
import { ReactComponent as BackIcon } from 'qs-assets/Media/arrow-left.svg';
import { Tooltip, withStyles } from '@material-ui/core';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

const IconToolTip = withStyles({
  tooltip: {
    backgroundColor: '#37A06D',
    fontSize: 12
  }
})(Tooltip);

export default ({
  showCheckbox = true,
  onCheckBoxToggle,
  checkboxLabel,
  checkboxValue,
  disableCheckbox,
  goBack,
  headerClass,
  deleteIconVisibility,
  deleteIconTitle,
  onDeleteClick,
  backButtonClass = '',
  selectButtonClass,
  hideSelect = false,
  rightSideAdditionalIcons = () => null,
  renderAdditionalIcons = () => null
}) => {
  const { t } = getI18N();

  const getCheckboxLabel = () => {
    if (checkboxLabel) {
      return checkboxLabel;
    }

    return !!checkboxValue ? t('deselect_all') : t('select_all');
  };

  return (
    <div className={'headerWithBackSelect'}>
      <div className={`backAndDeleteContainer ${headerClass} ${backButtonClass}`}>
        <div className={'topContainer'}>
          <div className={'leftContainer'}>
            <div className="backButton" onClick={goBack}>
              <BackIcon id={'backIcon'} width={18} height={18} fill="#fff" />
              <span className="backText">{t('back')}</span>
            </div>
          </div>

          <div className={'rightContainer'}>
            <div className={'deleteIconContainer'}>
              {rightSideAdditionalIcons()}
              {!!deleteIconVisibility && (
                <>
                  {renderAdditionalIcons()}
                  <IconToolTip title={deleteIconTitle}>
                    <DeleteIcon
                      onClick={onDeleteClick}
                      className={'deleteIcon'}
                      aria-label={deleteIconTitle}
                    />
                  </IconToolTip>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {!showCheckbox ? null : (
        <div className={`selectDeselectCheckBox ${selectButtonClass}`}>
          <CheckBox
            disabled={disableCheckbox}
            checked={checkboxValue}
            onCheckChanged={onCheckBoxToggle}
            styleProps={{ margin: '0px 16px 0px 0px', labelFontSize: 16, controlMarginRight: 0 }}
            label={getCheckboxLabel()}
          />
        </div>
      )}
    </div>
  );
};
