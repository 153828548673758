import React, { useState, useEffect, useCallback } from 'react';
import { selectedCatalogue, selectedLabels } from 'qs-data-manager/Selected';
import CatalogueLib, { CREATING_NEW_CATALOGUE } from 'qs-data-manager/Catalogues';
import { resetForLabelScreen } from 'qs-helpers/CatalogueLabels';
import eventbus from 'eventing-bus';
import { ReactComponent as SearchIcon } from 'qs-assets/Media/magnifyingGlass.svg';
import { ReactComponent as EditIcon } from 'qs-assets/Media/pencil.svg';
import { ReactComponent as NewCatalogue } from 'qs-assets/Media/plus.svg';
import { ReactComponent as CloseIcon } from 'qs-assets/Media/close.svg';
import { ReactComponent as ToggleLabel } from 'qs-assets/Media/menu.svg';
import { canUseFeature, FEATURE_LIST } from 'qs-data-manager/FeatureUsage';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

const { NEW_CATALOGUE_EB_KEY, CREATING_CATALOGUE, DELETE_CATALOGUE } = CREATING_NEW_CATALOGUE;

export default ({ showSearchHeader, showEditCatalogueHeader, headerClass }) => {
  const [catalogueCount, setCatalogueCount] = useState(CatalogueLib.getCatalogueCountFromCache());
  const [creatingNewCatalogue, setCreateNewCatalogue] = useState(false);

  const { t } = getI18N();

  const catalogueIdsListener = useCallback((error, { status, data } = {}) => {
    let catalogueCount = 0;
    if (error) {
      // Handle error
    } else {
      switch (status) {
        case CatalogueLib.OPERATION_STATUS.REFRESHING: {
          catalogueCount = (data.catalogueIds || []).length;
          break;
        }
        case CatalogueLib.OPERATION_STATUS.SUCCESS: {
          catalogueCount = (data.catalogueIds || []).length;
          break;
        }
        case CatalogueLib.OPERATION_STATUS.UPDATE: {
          catalogueCount = (data.catalogueIds || []).length;
          break;
        }
        default:
      }
      setCatalogueCount(catalogueCount);
    }
  }, []);

  useEffect(() => {
    CatalogueLib.attachCatalogueIdsListener(catalogueIdsListener);

    return () => CatalogueLib.removeCatalogueIdsListener(catalogueIdsListener);
  }, [catalogueIdsListener]);

  const createNewCatalogue = useCallback(e => {
    e.stopPropagation();
    const canUse = canUseFeature(FEATURE_LIST.CATALOGUES.id);
    if (!canUse) {
      return;
    }
    const onSaveNewCatalogue = () => {
      setCreateNewCatalogue(false);
    };
    eventbus.publish(NEW_CATALOGUE_EB_KEY, CREATING_CATALOGUE, {
      callback: onSaveNewCatalogue
    });
    setCreateNewCatalogue(true);
  }, []);

  const deleteNewCatalogue = useCallback(e => {
    if (e) {
      e.stopPropagation();
    }
    eventbus.publish(NEW_CATALOGUE_EB_KEY, DELETE_CATALOGUE);
    setCreateNewCatalogue(false);
  }, []);

  const onEditIconClick = useCallback(
    e => {
      deleteNewCatalogue();
      e.stopPropagation();
      if (!catalogueCount) {
        return;
      }
      showEditCatalogueHeader();
      selectedCatalogue.activate();
    },
    [catalogueCount, showEditCatalogueHeader, deleteNewCatalogue]
  );

  const onSearchClick = useCallback(() => {
    if (creatingNewCatalogue) {
      deleteNewCatalogue();
    }
    showSearchHeader();
  }, [showSearchHeader, creatingNewCatalogue, deleteNewCatalogue]);

  const toggleLabelScreen = event => {
    event.stopPropagation();
    selectedLabels.toggleActive();
    resetForLabelScreen();
  };

  return (
    <div id={'CatalogueListHeader'} className={`catalogueListHeaderContent ${headerClass}`}>
      <div className={'catalogueCount ellipsis'}>
        <ToggleLabel className={'toggleLabel'} onClick={toggleLabelScreen} />
        <div className={'ellipsis'}>
          {catalogueCount} {t('catalogues')}
        </div>
      </div>
      <div className={'options'}>
        <SearchIcon onClick={onSearchClick} className={'icon'} id={'searchIcon'} />
        <EditIcon onClick={onEditIconClick} className={'icon'} id={'editIcon'} />
        {creatingNewCatalogue ? (
          <CloseIcon onClick={deleteNewCatalogue} className={'icon closeIcon'} id={'editIcon'} />
        ) : (
          <NewCatalogue
            onClick={createNewCatalogue}
            className={'icon createNewCatalogue'}
            id={'editIcon'}
          />
        )}
      </div>
    </div>
  );
};
