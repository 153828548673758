import React, { useState, useCallback, useEffect } from 'react';
import eventbus from 'eventing-bus';
import {
  CATALOGUE_SEARCH,
  CATALOGUE_SEARCH_STATE,
  CATALOGUE_SEARCH_TERM
} from 'qs-services/DataManager/Catalogues';
import './styles.scss';
import { ReactComponent as BackIcon } from 'qs-assets/Media/arrow-left.svg';
import { ReactComponent as ClearIcon } from 'qs-assets/Media/multiply.svg';
import Mixpanel from 'qs-data-manager/Mixpanel';
import { getI18N } from 'qs-services/i18N';

const DEBOUNCER_META = {
  DEBOUNCER_TIME: 500,
  DEBOUNCED_DATA: null
};

export default ({ showCatalogueHeader, headerClass }) => {
  const [searchText, setSearchText] = useState('');
  const { t } = getI18N();

  const onSearchTextChange = useCallback(e => {
    const value = e.target.value;
    setSearchText(value);
    if (DEBOUNCER_META.DEBOUNCED_DATA) {
      clearTimeout(DEBOUNCER_META.DEBOUNCED_DATA);
    }
    DEBOUNCER_META.DEBOUNCED_DATA = setTimeout(() => {
      Mixpanel.sendEvent({
        eventName: 'search_catalogue_performed',
        props: {
          query: value
        }
      });
      eventbus.publish(CATALOGUE_SEARCH, {
        type: CATALOGUE_SEARCH_TERM,
        value
      });
    }, DEBOUNCER_META.DEBOUNCER_TIME);
  }, []);

  const clearSearch = useCallback(() => {
    setSearchText('');
    eventbus.publish(CATALOGUE_SEARCH, {
      type: CATALOGUE_SEARCH_TERM,
      value: ''
    });
  }, []);

  const goBack = useCallback(() => {
    showCatalogueHeader();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    eventbus.publish(CATALOGUE_SEARCH, {
      type: CATALOGUE_SEARCH_STATE,
      value: true
    });

    Mixpanel.sendEvent({
      eventName: 'search_catalogue_opened'
    });

    return () => {
      eventbus.publish(CATALOGUE_SEARCH, {
        type: CATALOGUE_SEARCH_STATE,
        value: false
      });
    };
  }, []);

  return (
    <div id={'SearchHeader'} className={headerClass}>
      <BackIcon onClick={goBack} id={'backIcon'} />

      <div id={'searchBar'}>
        <input
          autoFocus
          id="searchInputBox"
          value={searchText}
          onChange={onSearchTextChange}
          placeholder={t('search_catalogues')}
        />
        {searchText.length ? <ClearIcon onClick={clearSearch} className={'clearIcon'} /> : null}
      </div>
    </div>
  );
};
