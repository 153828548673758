import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles, Radio, RadioGroup, FormControlLabel } from '@material-ui/core';
import DialogBox from '../DialogBox';
import Loader from '../Loader';
import toastr from 'toastr';
import {
  setCompanyBulkOrderingSettings,
  getCompanySettingsFromCache
} from 'qs-data-manager/Company';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

const useStyles = makeStyles({
  radioRoot: {
    padding: 9
  },
  radioView: {
    color: '#4ed29b',
    '&$checked': {
      color: '#4ed29b'
    },
    '&$disabled': {
      color: '#4ed29b'
    }
  },
  checked: {},
  disabled: {},
  formLabel: {
    maxWidth: 200,
    marginRight: 20,
    overflowWrap: 'break-word'
  },
  formLabelStart: {
    marginLeft: 0,
    display: 'flex',
    flex: '1 1 auto',
    justifyContent: 'space-between',
    width: 340
  }
});

export default ({ show, onClose, allVariants, title, selectedBulkOrderingViewSetting }) => {
  const componentStyles = useStyles();
  const currentlySetLang = getCompanySettingsFromCache();
  const [loading, setLoading] = useState(false);
  const [disableLoading, setDisableLoading] = useState(false);
  const [b2bSetting, setB2bSetting] = useState({});
  const [selectedValue, setSelectedValue] = useState();

  const { t } = getI18N();

  const handleDefaultSelectedSetting = useCallback(() => {
    if (
      selectedBulkOrderingViewSetting &&
      Object.keys(selectedBulkOrderingViewSetting).length > 0
    ) {
      const selectedSetting = Object.keys(selectedBulkOrderingViewSetting)[0];
      const defaultSetting = allVariants.filter(variant => {
        if (variant.optionTypeId === selectedSetting) {
          return variant.optionTypeId;
        } else if (variant.optionType === selectedSetting) {
          return variant.optionType;
        }
        return false;
      });
      if (defaultSetting && defaultSetting.length > 0) {
        selectedSetting === 'SIZES' || selectedSetting === 'COLORS'
          ? setSelectedValue(defaultSetting[0].optionType)
          : setSelectedValue(defaultSetting[0].optionTypeId);
      }
      return;
    }
    setSelectedValue();
  }, [selectedBulkOrderingViewSetting, allVariants]);

  useEffect(() => {
    setB2bSetting(allVariants);
    handleDefaultSelectedSetting();
  }, [allVariants, handleDefaultSelectedSetting]);

  if (!show) {
    return null;
  }

  const handleB2BBulkOrderSetting = async event => {
    event.preventDefault();
    setLoading(true);
    try {
      if (!selectedValue) {
        toastr.error(t('please_select_an_option'));
        return;
      }
      await setCompanyBulkOrderingSettings(selectedValue).then(() => {
        toastr.success(t('bulk_order_setting_saved_succesfully'));
        onClose();
      });
    } catch (error) {
      toastr.error(t('something_went_wrong_while_updating_the_bulk_order_setting'));
    } finally {
      setLoading(false);
    }
  };

  const onBulkOrderOptionChange = event => {
    const selecteBulkOrderSetting = event.target.value;
    setSelectedValue(selecteBulkOrderSetting);
  };

  const onDialogClose = event => {
    event.preventDefault();
    event.stopPropagation();
    setB2bSetting(allVariants);
    handleDefaultSelectedSetting();
    onClose();
  };

  const onDiableBulkOrderSetting = async event => {
    event.preventDefault();
    event.stopPropagation();
    setDisableLoading(true);
    try {
      await setCompanyBulkOrderingSettings(null).then(() => {
        toastr.success(t('bulk_order_setting_disabled_succesfully'));
        setSelectedValue();
        onClose();
      });
    } catch (error) {
      toastr.error(t('something_went_wrong_while_disabling_the_bulk_order_setting'));
    } finally {
      setDisableLoading(false);
    }
  };

  const getCompanySetLanguage = currentlySetLang => {
    return currentlySetLang && currentlySetLang.i18n ? currentlySetLang.i18n : 'en_US';
  };

  return (
    <DialogBox
      maxWidth={'sm'}
      open={true}
      dialogAsForm={{ onSubmit: handleB2BBulkOrderSetting }}
      title={title}
      onClose={onDialogClose}
      actionsConfig={[
        {
          content: disableLoading ? (
            <span>
              <Loader size="small" />
            </span>
          ) : (
            t('disable')
          ),
          clickHandler: onDiableBulkOrderSetting,
          role: 'SECONDARY'
        },
        {
          content: loading ? (
            <span>
              <Loader size="small" />
            </span>
          ) : (
            t('set')
          ),
          clickHandler: handleB2BBulkOrderSetting,
          role: 'PRIMARY'
        }
      ]}
      id="b2b-edit-dialog-box"
    >
      <RadioGroup value={selectedValue} onChange={onBulkOrderOptionChange} name="bulkOrderSetting">
        {b2bSetting.length > 0 &&
          b2bSetting.map(({ optionTypeId, translation }) => {
            return (
              <div key={optionTypeId} className="bulkOrderOptionContainer">
                <FormControlLabel
                  value={optionTypeId}
                  classes={{
                    label: componentStyles.formLabel,
                    labelPlacementStart: componentStyles.formLabelStart
                  }}
                  control={
                    <Radio
                      classes={{
                        colorSecondary: componentStyles.radioView,
                        checked: componentStyles.checked,
                        disabled: componentStyles.disabled,
                        root: componentStyles.radioRoot
                      }}
                    />
                  }
                  label={translation[getCompanySetLanguage(currentlySetLang)]}
                  labelPlacement="start"
                />
              </div>
            );
          })}
      </RadioGroup>
    </DialogBox>
  );
};
