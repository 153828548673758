import './styles.scss';
import { convertImageUrlSize } from 'qs-helpers';

const createLoaderElement = () => {
  const spinner = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  spinner.setAttributeNS(null, 'class', 'circular spinner');
  spinner.setAttributeNS(null, 'viewBox', '25 25 50 50');

  const circleSvg = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
  circleSvg.setAttributeNS(null, 'class', 'path');
  circleSvg.setAttributeNS(null, 'cx', '50');
  circleSvg.setAttributeNS(null, 'cy', '50');
  circleSvg.setAttributeNS(null, 'r', '20');
  circleSvg.setAttributeNS(null, 'fill', 'none');
  circleSvg.setAttributeNS(null, 'stroke-width', '4');
  circleSvg.setAttributeNS(null, 'stroke-miter-limit', '10');

  spinner.appendChild(circleSvg);
  return spinner;
};

const createErrorElement = () => {
  const errorSvg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  errorSvg.setAttributeNS(null, 'class', 'errorIcon');
  errorSvg.setAttributeNS(null, 'viewBox', '0 0 50 50');

  const gContainer = document.createElementNS('http://www.w3.org/2000/svg', 'g');
  const iconPath = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  iconPath.setAttributeNS(
    null,
    'd',
    'M 49.777344 45.347656 L 25.867188 3.929688 C 25.507813 3.3125 24.492188 3.3125 24.132813 3.929688 L 0.132813 45.5 C -0.0429688 45.808594 -0.0429688 46.191406 0.132813 46.5 C 0.3125 46.808594 0.644531 47 1 47 L 49 47 C 49.007813 47 49.015625 47 49.019531 47 C 49.574219 47 50.019531 46.554688 50.019531 46 C 50.019531 45.75 49.929688 45.523438 49.777344 45.347656 Z M 23.492188 19.265625 C 23.492188 19.050781 23.636719 18.90625 23.851563 18.90625 L 26.148438 18.90625 C 26.363281 18.90625 26.503906 19.050781 26.503906 19.265625 L 26.503906 33.726563 C 26.503906 33.941406 26.363281 34.085938 26.148438 34.085938 L 23.851563 34.085938 C 23.636719 34.085938 23.492188 33.941406 23.492188 33.726563 Z M 26.578125 39.9375 C 26.578125 40.152344 26.433594 40.292969 26.222656 40.292969 L 23.78125 40.292969 C 23.566406 40.292969 23.425781 40.148438 23.425781 39.9375 L 23.425781 37.316406 C 23.425781 37.101563 23.570313 36.960938 23.78125 36.960938 L 26.222656 36.960938 C 26.4375 36.960938 26.578125 37.101563 26.578125 37.316406 Z'
  );

  gContainer.appendChild(iconPath);
  errorSvg.appendChild(gContainer);
  return errorSvg;
};

const transformUrl = url => convertImageUrlSize({ size: '100', url });

export default function({ eGridCell, value, data }) {
  if (eGridCell && eGridCell.classList) {
    const classes = eGridCell.classList;
    classes.remove('productLibraryImageCellContainer');
    classes.add('productLibraryImageCellContainer');
    classes.remove('productLibraryRowLoader');

    if (typeof data === 'undefined') {
      classes.add('productLibraryRowLoader');
    }
    eGridCell.classList = classes;
  }

  if (typeof data === 'undefined') {
    return createLoaderElement();
  }

  if (!value) {
    return '';
  }

  const url = transformUrl(value);

  const imageContainerDiv = document.createElement('div');
  imageContainerDiv.classList.add('smallImageContainer');

  const imageDiv = document.createElement('div');
  imageDiv.classList.add('smallBackgroundImageCover');
  imageDiv.style.backgroundImage = `url(${url})`;

  imageContainerDiv.appendChild(imageDiv);

  const imageForLoadStatus = document.createElement('img');
  imageForLoadStatus.classList.add('noDisplayImage');

  imageForLoadStatus.onerror = function() {
    const errorContainer = document.createElement('div');
    errorContainer.classList.add('errorImageContainer');
    errorContainer.appendChild(createErrorElement());

    imageContainerDiv.removeChild(imageDiv);
    imageContainerDiv.removeChild(imageForLoadStatus);
    imageContainerDiv.appendChild(errorContainer);
  };
  imageForLoadStatus.src = url;

  imageContainerDiv.appendChild(imageForLoadStatus);

  return imageContainerDiv;
}
