import React, { useRef, useState } from 'react';
import './SuggestionFeedback.scss';
import { submitFeedback } from 'qs-data-manager/Company';

export default () => {
  const contentRef = useRef();
  const [operationState, setOperationState] = useState();
  const [feedbackError, setFeedbackError] = useState('');

  const focusOnMount = function(refVal) {
    if (refVal) {
      contentRef.current = refVal;
      contentRef.current.focus();
    }
  };

  const validFeedback = function() {
    if (!contentRef.current.innerText) {
      setFeedbackError('Please provide your feedback');
      return false;
    }
    setFeedbackError('');
    return true;
  };

  const handleSubmit = function(event) {
    event.preventDefault();

    if (!validFeedback()) {
      return;
    }

    submitFeedback(contentRef.current.innerText)
      .then(() => {
        contentRef.current.innerText = '';
        setOperationState('Feedback submitted succesfully');
      })
      .catch(() => {
        setOperationState('Something went wrong while submitting your Feedback');
      });
  };

  const handleFeedbackInput = function() {
    //Only re-render when needed
    if (feedbackError) {
      setFeedbackError('');
    }
  };

  let suggestionComponent;
  if (operationState) {
    suggestionComponent = <span className="feedback-opertion-status">{operationState}</span>;
  } else {
    let errorClass = '',
      errorComponent = null;
    if (feedbackError) {
      errorComponent = <p className="editable-error-msg">{feedbackError}</p>;
      errorClass = 'editable-error';
    }
    suggestionComponent = (
      <div className="suggestion-feedback-box">
        <h3 className="bottom-seperator">Feedback</h3>
        <p className="description bottom-seperator">Share your feedback and help us improve</p>
        <div className={`editable-content bottom-seperator ${errorClass}`}>
          <div
            contentEditable={true}
            className="content"
            ref={focusOnMount}
            onInput={handleFeedbackInput}
          ></div>
        </div>
        {errorComponent}
        <div className="button-container">
          <button type="submit" className="submit-feedback" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
    );
  }

  return <div className="suggestion-feedback-container">{suggestionComponent}</div>;
};
