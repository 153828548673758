import React, { useReducer, useEffect, useState, useRef, useCallback } from 'react';
import Filters from './Filters';
import ProductLibrary from './ProductLibrary';
import { selectedFilterInit, selectedFilterReducer } from './reducer';
import { Button, withStyles, DialogContent } from '@material-ui/core';
import { SecondaryButton, DialogDarkTheme } from '../../../Common/DarkThemeDialog';
import {
  deleteCachedCompanyProductsData,
  copyProductsToCatalogue
} from 'qs-data-manager/ProductLibrary/ProductsForLibrary';
import { getActiveCatalogueId } from 'qs-data-manager/Catalogues';
import Loader from '../../../Common/Loader';
import ErrorPopup from '../../../Common/ErrorPopup';
import { getFirstEligibleCatalogueId } from 'qs-helpers/ProductLibrary/ResponseProcessor';
import { getCatalogueFilterDataFromCache } from 'qs-data-manager/ProductLibrary/FiltersForLibrary';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

const CatalogueButton = withStyles({
  containedPrimary: {
    backgroundColor: '#1BAF7A',
    '&:disabled': {
      backgroundColor: '#1BAF7A',
      opacity: 0.6,
      color: '#ffffff',
      cursor: 'not-allowed'
    }
  }
})(Button);

export default ({
  onCancel,
  productLibraryUsedWithinIframe = false,
  disableMultiple = false,
  defaultSelectedProducts = [],
  getSelectedProducts
}) => {
  const { t } = getI18N();
  const [selectedFilters, setSelectedFilters] = useReducer(
    selectedFilterReducer,
    {
      catalogueFilter: getFirstEligibleCatalogueId({
        catalogueList: getCatalogueFilterDataFromCache(),
        catalogueIdToExclude: productLibraryUsedWithinIframe ? null : getActiveCatalogueId()
      })
    },
    selectedFilterInit
  );
  const [productsSelectedCount, setProductsSelectedCount] = useState(
    defaultSelectedProducts.length
  );
  const [createProductsStatus, setCreateProductsStatus] = useState({ status: 'NONE' });
  const [noDataAvailable, setNoDataAvailable] = useState(false);

  const defaultSelectedProductsMap = new Map();
  if (defaultSelectedProducts.length > 0) {
    defaultSelectedProducts.forEach(defaultSelectedProduct => {
      defaultSelectedProductsMap.set(defaultSelectedProduct, true);
    });
  }

  const selectedProducts = useRef(defaultSelectedProductsMap);

  useEffect(() => {
    return () => deleteCachedCompanyProductsData();
  }, []);

  //Add it in a use callback to ensure that the child does not re-render everytime
  const handleProductSelection = useCallback(
    selectionData => {
      if (selectionData instanceof Map) {
        const selectedProductCount = selectionData.size;
        setProductsSelectedCount(selectedProductCount);
        selectedProducts.current = selectionData;
        return;
      }

      //Uknown value type sent, reset the total count and disable the button
      setProductsSelectedCount(0);
      selectedProducts.current = undefined;
    },
    [setProductsSelectedCount]
  );

  const processCataloguesResponse = useCallback(cataloguesList => {
    if (!Array.isArray(cataloguesList)) {
      return;
    }
    setNoDataAvailable(cataloguesList.length === 0);
  }, []);

  const createProductsForCatalogue = async event => {
    event.preventDefault();
    if (!selectedProducts.current) {
      setCreateProductsStatus({ status: 'ERROR', data: 'internal state error' });
      return;
    }

    try {
      setCreateProductsStatus({ status: 'LOADING' });
      if (productLibraryUsedWithinIframe && typeof getSelectedProducts === 'function') {
        const productIds = Array.from(selectedProducts.current.keys());
        getSelectedProducts(
          disableMultiple ? [productIds[0]] : productIds,
          selectedFilters && selectedFilters.catalogueFilter
            ? selectedFilters.catalogueFilter
            : null
        );
      } else {
        await copyProductsToCatalogue({
          catalogueId: getActiveCatalogueId(),
          productIds: Array.from(selectedProducts.current.keys())
        });
      }
      setCreateProductsStatus({ status: 'NONE' });
      onCancel();
    } catch (copyProductsError) {
      setCreateProductsStatus({ status: 'ERROR', data: copyProductsError });
    }
  };

  const getOverlayContent = () => {
    const { status, data } = createProductsStatus;
    if (status === 'ERROR') {
      let body = '';
      if (typeof data === 'string') {
        body = data;
      } else if (data instanceof Error) {
        body = data.message;
      }

      return (
        <ErrorPopup
          title={t('create_products_from_library')}
          onClose={() => setCreateProductsStatus({ status: 'NONE' })}
          mailProps={{ subject: '', body }}
        >
          {t('something_went_wrong_while_creating_products')}
        </ErrorPopup>
      );
    }

    if (status === 'LOADING') {
      return (
        <DialogDarkTheme open={true}>
          <DialogContent>
            <Loader size="large" />
            <span>{t('copying_products')}</span>
          </DialogContent>
        </DialogDarkTheme>
      );
    }

    return null;
  };

  let buttonDisabled = true,
    countComponent = null;
  if (productsSelectedCount > 0) {
    buttonDisabled = false;
    countComponent = (
      <span className="ellipsis selectedText">
        {t('products_selected', { count: productsSelectedCount })}
      </span>
    );
  }

  if (noDataAvailable) {
    return (
      <div className="productLibraryViewColumnContainer">
        <div className="productLibraryViewContainer productLibraryNoDataContainer">
          <p>{t('no_catalogues_available_for_selecting_products')}</p>
        </div>
        <div className="productLibraryFooterContainer">
          <SecondaryButton onClick={onCancel}>{t('cancel')}</SecondaryButton>
        </div>
      </div>
    );
  }

  const currentCatalogue = getActiveCatalogueId();

  return (
    <div className="productLibraryViewColumnContainer">
      <div className="productLibraryViewContainer">
        <Filters
          updateFilters={setSelectedFilters}
          filters={selectedFilters}
          currentCatalogueId={currentCatalogue}
          processCataloguesResponse={processCataloguesResponse}
        />
        <ProductLibrary
          filters={selectedFilters}
          defaultSelectedProducts={defaultSelectedProducts}
          onSelectionChange={handleProductSelection}
          currentCatalogueId={currentCatalogue}
          disableMultiple={disableMultiple}
        />
      </div>
      <div className="productLibraryFooterContainer">
        <SecondaryButton onClick={onCancel}>{t('cancel')}</SecondaryButton>
        <div className="footerSelectionContainer">
          {countComponent}
          <CatalogueButton
            color={'primary'}
            variant="contained"
            disabled={buttonDisabled}
            onClick={createProductsForCatalogue}
          >
            {productLibraryUsedWithinIframe
              ? `${disableMultiple ? t('select_product') : t('select_products')}`
              : t('add_to_catalogue')}
          </CatalogueButton>
        </div>
      </div>
      {getOverlayContent()}
    </div>
  );
};
