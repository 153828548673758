import React, { useCallback, useEffect, useState } from 'react';
import GenerateLink from './GenerateLink';
import SharingOptions from './SharingOptions';
import PublishToHomepage from './PublishToHomepage';
import { CATALOGUE_SHARE_TYPES } from 'qs-services/DataManager/Catalogues';
import { getElementParents } from 'qs-helpers';

import './styles.scss';

export default ({ catalogueIds = [], isVisible, closePopover } = {}) => {
  const [loadingLink, setLoadingLink] = useState(true);
  useEffect(() => {
    const handleBodyClick = e => {
      const parents = getElementParents(e.target);
      const parentsClass = parents.map(parent => parent.className);

      if (
        !parentsClass.includes('react-tiny-popover-container') &&
        !parentsClass.includes('iconContainer shareDialogueContainer')
      ) {
        closePopover();
      }
    };

    document.querySelector('body').addEventListener('click', handleBodyClick);

    return () => document.querySelector('body').removeEventListener('click', handleBodyClick);
  }, [isVisible, closePopover]);

  const toggleLoader = useCallback(value => {
    setLoadingLink(value);
  }, []);

  return (
    <div id={'ShareDialogue'}>
      <GenerateLink
        catalogueId={catalogueIds[0]}
        isVisible={isVisible}
        loadingLink={toggleLoader}
      />
      <SharingOptions
        catalogueId={catalogueIds[0]}
        shareType={CATALOGUE_SHARE_TYPES.SINGLE}
        closePopover={closePopover}
      />
      <PublishToHomepage catalogueId={catalogueIds[0]} loadingLink={loadingLink} />
    </div>
  );
};
