import AppConfig from 'qs-config/AppConfig';
import request from '../../Request';

export const getDomainData = () => request.authGet(`${AppConfig.qsApiEndPoint}/v1/domain`);

export const setDomainData = data => request.authPost(`${AppConfig.qsApiEndPoint}/v1/domain`, data);

export const getCatalogCount = () =>
  request.authGet(`${AppConfig.qsApiEndPoint}/v1/domain/catalogue-count`);

export const getDomainSettings = () =>
  request.authGet(`${AppConfig.qsApiEndPoint}/v1/domain/settings`);

export const deleteDomainData = data =>
  request.authDelete(`${AppConfig.qsApiEndPoint}/v1/domain`, data);
