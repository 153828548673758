import React from 'react';
import ClickHandlerWithLoaderContainer from '../../../../Common/ClickHandlerWithLoader/ClickHandlerWithLoaderContainer';
import SettingsRow from '../../../Common/SettingsRow/SettingsRow';
import { getI18N } from 'qs-services/i18N';

export default ({ onSelected, settingComponentId, classForContainer }) => {
  const { t } = getI18N();

  return (
    <ClickHandlerWithLoaderContainer
      clickProps={{
        clickHandler: onSelected,
        clickParams: settingComponentId
      }}
      type="button"
      classForContainer={classForContainer}
    >
      <SettingsRow
        imageSrc={'https://web.quicksell.co/static/media/ic_quicksell_custom_themes.svg'}
        title={t('custom_themes')}
      >
        <span>{t('create_customized_theme_for_your_catalogues')}</span>
      </SettingsRow>
    </ClickHandlerWithLoaderContainer>
  );
};
