import React, { useState, useEffect } from 'react';
import Loader from '../../../Common/Loader';
import AppConfig from 'qs-config/AppConfig';
import firebase from 'qs-config/FirebaseConfig';
import { reportError } from 'qs-helpers/ErrorReporting';
import request from 'qs-services/Request';
import { getLanguageCode, getTranslationCode } from 'qs-services/i18N';
import './styles.scss';

export default ({ id, route, queryParams = [], removeBeginningHash, title, iframeStyle }) => {
  const [token, setToken] = useState(null);
  const [iframeLoaded, toggleIframeLoaded] = useState(false);

  useEffect(() => {
    const getToken = () => {
      const currentUser = firebase.auth().currentUser;
      if (currentUser) {
        currentUser
          .getIdToken()
          .then(token => {
            setToken(token);
          })
          .catch(error => {
            reportError(error);
          });
      }
    };

    getToken();

    // refresh after 65 mins (5 mins buffer, as token gets refreshed every 60 mins)
    const interval = setInterval(() => {
      getToken();
    }, 65 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const postMessageToIframe = msg => {
      let iframe = document.getElementById(id);
      if (iframe) {
        let iframeContent = iframe.contentWindow || iframe.contentDocument;
        if (iframeContent) {
          iframeContent.postMessage(msg, '*');
        }
      }
    };
    if (token) {
      setTimeout(() => {
        postMessageToIframe(
          JSON.stringify({
            token: token
          })
        );
      }, 5000);
    }
  }, [token, id]);

  const getQueryParams = () => {
    return request.createQueryParams([
      ...(queryParams || []),
      {
        key: 'token',
        value: token
      },
      {
        key: 'source',
        value: 'desktop'
      },
      {
        key: 'languageCode',
        value: getTranslationCode(getLanguageCode())
      }
    ]);
  };

  let updatedStyle = Object.assign(
    {
      display: iframeLoaded ? 'flex' : 'none'
    },
    iframeStyle
  );

  const renderIframe = () => {
    return (
      <iframe
        id={id}
        name={Date.now()}
        src={`${
          removeBeginningHash
            ? AppConfig.iframeHost.replace('/#', '').replace(':3001', ':3002')
            : AppConfig.iframeHost
        }${route}${getQueryParams()}`}
        title={title}
        style={updatedStyle}
        onLoad={() => toggleIframeLoaded(true)}
      ></iframe>
    );
  };

  const renderLoaderComponent = () => {
    return (
      <div className={'loadingComponent'}>
        <Loader size="large" />
        <span className="loadingIframeText">{title}</span>
      </div>
    );
  };

  return (
    <div className={'settings-iframe-container'}>
      {token && renderIframe()}
      {!iframeLoaded && renderLoaderComponent()}
    </div>
  );
};
