import React, { Fragment } from 'react';
import ErrorPopup from '../../../Common/ErrorPopup';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

export default ({
  status,
  verifyErrorMsg,
  actualError,
  csvRowLength,
  csvColumnLength,
  onCancel
}) => {
  const { t } = getI18N();

  const getContentForModal = () => {
    if (verifyErrorMsg) {
      return null;
    }

    if (status === 'PARSING') {
      return <div className="title">{t('reading_excel_file')}</div>;
    }

    if (status === 'VERIFYING') {
      return (
        <Fragment>
          <div className="title">{t('verifying_excel_file')}</div>
          <div className="subtitle">{t('csv_column_length_columns', { csvColumnLength })}</div>
          <div className="subtitle">
            {t('csv_column_length_rows', { rowsLength: csvRowLength - 1 })}
          </div>
        </Fragment>
      );
    }
  };

  if (verifyErrorMsg) {
    return (
      <ErrorPopup
        title={`${
          status === 'VERIFYING' ? t('excel_verification_error') : t('excel_parsing_error')
        }`}
        mailProps={{ subject: 'Help with acceptable excel format', body: actualError }}
        onClose={onCancel}
      >
        {verifyErrorMsg}
      </ErrorPopup>
    );
  }

  /*
    TODO: make the modal containers use common backdrop and make the containers common.
  */

  return (
    <div className="excelVerifyContainer">
      <div className="excelVerifyContent">{getContentForModal()}</div>
    </div>
  );
};
