import React, { useEffect, useState, Fragment, useMemo } from 'react';
import {
  getGlobalOptionsFromCache,
  getGlobalOptionsData,
  attachGlobalOptionsListener,
  removeGlobalOptionsListener,
  getCurrentProductOptionsListFromCache,
  attachProductOptionsListener,
  removeProductOptionsListener,
  createNewOption
} from 'qs-data-manager/Variants/ProductOptions';
import CacheListenerCallback from 'qs-helpers/CacheListenerCallback';
import useRefreshHandlerHook from 'qs-hooks/refreshHandlerHook';
import Loader from '../../../../../../../Common/Loader';
import { getProductOptionsAsSet, stringifyRgb } from 'qs-helpers/Variants/ResponseProcessor';
import { VARIANT_ADD_TABS, OPTION_STATIC_COLORS } from 'qs-helpers/Variants/constants';
import toastr from 'toastr';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

export default ({
  optionType,
  optionTypeId,
  activeProductId,
  searchColors,
  searchClick,
  optionAdded
}) => {
  const [refreshGlobalOptions, toggleRefreshGlobalOptions] = useState(false);
  const [refreshProductOptions, toggleRefreshProductOptions] = useState(false);
  const [globalOptionsStatus, updateGlobalOptionsData] = useRefreshHandlerHook();
  const [showLoader, setShowLoader] = useState({});

  const { t } = getI18N();

  const globalOptions = useMemo(
    () => getGlobalOptionsFromCache(optionType),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [optionType, refreshGlobalOptions]
  );

  const optionsForProduct = useMemo(
    () =>
      getProductOptionsAsSet(
        getCurrentProductOptionsListFromCache({
          productId: activeProductId,
          currentTab: optionType
        })
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeProductId, optionType, refreshProductOptions]
  );

  useEffect(() => {
    const listener = (error, payload) => {
      const dataFromCacheListener = CacheListenerCallback(error, payload);
      updateGlobalOptionsData(dataFromCacheListener);
      const { err, loading, refreshing, data } = dataFromCacheListener;
      if (err || loading || refreshing || !data) {
        return;
      }

      //Toggle the state to re-render the component and pick the latest data
      toggleRefreshGlobalOptions(prevState => !prevState);
    };

    const globalOptionsForType = getGlobalOptionsFromCache(optionType);
    updateGlobalOptionsData({ data: globalOptionsForType, localRefresh: true });

    getGlobalOptionsData(optionType);
    attachGlobalOptionsListener({ listener, optionType });
    return () => removeGlobalOptionsListener({ listener, optionType });
  }, [optionType, updateGlobalOptionsData]);

  useEffect(() => {
    const listener = (error, payload) => {
      // Callback when cache is manipulated
      const { err, loading, refreshing, data } = CacheListenerCallback(error, payload);
      if (err || loading || refreshing || !data) {
        return;
      }

      toggleRefreshProductOptions(prevState => !prevState);
    };

    attachProductOptionsListener({ listener, productId: activeProductId });
    return () => removeProductOptionsListener({ listener, productId: activeProductId });
  }, [activeProductId, optionType]);

  const handleOptionClick = ({ event, id, optionValue, rgbValue }) => {
    event.preventDefault();

    const optionData = {
      parentProductId: activeProductId,
      optionType,
      optionTypeId,
      optionValue,
      rgbValue
    };

    setShowLoader(prevState => ({
      ...prevState,
      [id]: true
    }));

    createNewOption(optionData)
      .then(() => {
        setShowLoader(prevState => {
          const newState = { ...prevState };
          delete newState[id];
          return newState;
        });
        optionAdded();
      })
      .catch(() => {
        toastr.error(t('something_went_wrong_while_creating_the_option'));
        setShowLoader(prevState => {
          const newState = { ...prevState };
          delete newState[id];
          return newState;
        });
      });
  };

  const getLabelBlock = ({ label }) => {
    if (showLoader[label]) {
      return (
        <div key={label} className="optionsSizesBlock optionsLoader">
          <Loader />
        </div>
      );
    }

    return (
      <div
        className="optionsSizesBlock"
        onClick={event => handleOptionClick({ event, id: label, optionValue: label })}
        key={label}
      >
        <div className="sizesBlock">{label}</div>
      </div>
    );
  };

  const getColorBlock = ({ rgb, label, rgbKey, clickHandler }) => {
    if (showLoader[rgbKey]) {
      return (
        <div key={rgbKey} className="colorBlockContainer">
          <Loader />
        </div>
      );
    }

    if (!clickHandler) {
      clickHandler = handleOptionClick;
    }

    return (
      <div
        key={rgbKey}
        className="colorBlockContainer"
        onClick={event => clickHandler({ event, id: rgbKey, optionValue: label, rgbValue: rgb })}
      >
        <div
          title={label}
          className="colorBlock"
          style={{ backgroundColor: `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})` }}
        />
      </div>
    );
  };

  const getStaticColorsComponent = () => {
    if (optionType !== VARIANT_ADD_TABS.COLORS) {
      return null;
    }

    let colorsList = OPTION_STATIC_COLORS,
      clickHandler = null;
    if (searchColors) {
      if (searchColors.length === 0) {
        return null;
      }

      colorsList = searchColors;
      clickHandler = searchClick;
    }

    const eligibleStaticColors = colorsList.reduce((eligibleColors, { rgb, label }) => {
      const rgbKey = stringifyRgb(rgb);
      if (!optionsForProduct.has(rgbKey)) {
        eligibleColors.push(getColorBlock({ rgb, label, rgbKey, clickHandler }));
      }
      return eligibleColors;
    }, []);

    if (eligibleStaticColors.length === 0) {
      return null;
    }

    return <div className="eligibleOptionsContainer staticColors">{eligibleStaticColors}</div>;
  };

  const filterOptionsList = () => {
    if (optionType === VARIANT_ADD_TABS.COLORS) {
      return globalOptions.reduce((eligibleOptions, { rgb, label }) => {
        if (!rgb) {
          return eligibleOptions;
        }

        const rgbKey = stringifyRgb(rgb);
        if (!optionsForProduct.has(rgbKey)) {
          eligibleOptions.push(getColorBlock({ rgb, label, rgbKey }));
        }
        return eligibleOptions;
      }, []);
    }

    return globalOptions.reduce((eligibleOptions, { label }) => {
      if (!optionsForProduct.has(label)) {
        eligibleOptions.push(getLabelBlock({ label }));
      }
      return eligibleOptions;
    }, []);
  };

  const getGlobalOptionsComponent = () => {
    const eligibleOptionsList = filterOptionsList();
    if (eligibleOptionsList.length === 0) {
      return null;
    }

    let displayText = t('your_variant_type', { variantType: optionType.toLowerCase() });
    if (optionType === VARIANT_ADD_TABS.SIZES) {
      displayText = t('your_variant_type', { variantType: t('sizes') });
    }
    if (optionType === VARIANT_ADD_TABS.COLORS) {
      displayText = t('your_variant_type', { variantType: t('colors') });
    }

    return (
      <Fragment>
        <div className="ellipsis">{displayText}</div>
        <div className="eligibleOptionsContainer">{eligibleOptionsList}</div>
      </Fragment>
    );
  };

  const { error, loading } = globalOptionsStatus;

  if (error) {
    return (
      <div className="optionsQuickView errorLoadView">
        <div>{t('something_went_wrong_while_fetching_the_options')}</div>
      </div>
    );
  }

  if (loading || !globalOptions) {
    return (
      <div className="optionsQuickView">
        <Loader size={'small'} />
      </div>
    );
  }

  const globalOptionsComponent = getGlobalOptionsComponent();
  const staticColorsComponent = getStaticColorsComponent();

  //No options available for quick view, don't render anything
  if (globalOptionsComponent === null && staticColorsComponent === null) {
    return null;
  }

  return (
    <div className="optionsQuickView">
      {staticColorsComponent}
      {globalOptionsComponent}
    </div>
  );
};
