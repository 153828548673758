import { nativeDB } from 'qs-services/DexieInit';

const saveProductList = async (apiResp = {}, extraData = {}) => {
  try {
    const { catalogueId } = extraData;
    if (!catalogueId) {
      return;
    }
    const data = apiResp.productsList;
    await nativeDB.productListMeta.put(data, catalogueId);
  } catch (error) {
    // Handle error
  }
};

const saveProductRowMeta = async (apiResp = {}, extraData = {}) => {
  try {
    const { productMeta } = apiResp;
    const data = Object.values(productMeta);
    await nativeDB.productRowMeta.bulkPut(data);
  } catch (error) {
    // Handle error
  }
};

export { saveProductList, saveProductRowMeta };
