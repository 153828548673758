import { attachCompanyMetaListener, removeCompanyMetaListener } from './Company';
import { attachUserMetaListener, removeUserMetaListener } from './User';
import { attachCatalogueChangesListener, removeCatalogueChangesListener } from './Catalogues';
import * as Sentry from '@sentry/browser';
import { attachConfigListener } from './Config';
import { attachConnectivityListener, removeConnectivityListener } from './Connectivity';
import { attachFirebaseListeners, removeFirebaseListeners } from 'qs-helpers';

const attachDefaultListeners = ({ companyId, userId }) => {
  try {
    attachCompanyMetaListener({ companyId });
    attachConfigListener();
    attachUserMetaListener({ userId });
    attachFirebaseListeners(() => attachCatalogueChangesListener({ companyId }));
    attachConnectivityListener();
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const removeDefaultListeners = ({ companyId, userId }) => {
  removeCompanyMetaListener({ companyId });
  removeUserMetaListener({ userId });
  //removeInquiriesCountListener({ companyId });
  removeFirebaseListeners(() => removeCatalogueChangesListener({ companyId }));
  removeConnectivityListener();
};

export { attachDefaultListeners, removeDefaultListeners };
