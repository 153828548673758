import React, { useState, useEffect, useRef } from 'react';
import Switch from 'react-switch';
import toastr from 'toastr';
import {
  changeCompanyOutOfStockVisibility,
  changeCompanyAllowOrderOnOutOfStock,
  changeCompanyTrackInventory,
  changeCompanyAutoReduceInventory,
  getCompanySettingsFromCache,
  attachCompanySettingsListener,
  removeCompanySettingsListener
} from 'qs-data-manager/Company';
import CacheListenerCallback from 'qs-helpers/CacheListenerCallback';
import SettingsDataRow from '../../Common/SettingsDataRow/SettingsDataRow';
import { getI18N } from 'qs-services/i18N';

export default () => {
  const autoReduceMapping = useRef({
    VISITOR: true,
    COMPANY: false
  });
  const loaderKeyValues = useRef({
    showOutOfStockProduct: 'showOutOfStockProduct',
    allowOrdersOnOutOfStock: 'allowOrdersOnOutOfStock',
    trackInventory: 'trackInventory',
    autoReduceInventory: 'autoReduceInventory'
  });
  const [inventoryState, setInventoryState] = useState(getCompanySettingsFromCache() || {});
  const [showInventoryLoader, toggleInventoryLoader] = useState({});

  const { t } = getI18N();

  useEffect(() => {
    const inventoryUpdateListener = function(error, payload) {
      const { err, loading, refreshing, data } = CacheListenerCallback(error, payload);

      if (err) {
        toastr.error(err.message);
        return;
      }

      if (loading || refreshing) {
        return;
      }

      const { settings } = data || {};
      setInventoryState(settings || {});
    };

    attachCompanySettingsListener(inventoryUpdateListener);
    return () => removeCompanySettingsListener(inventoryUpdateListener);
  });

  const handleSwitchChange = toggleFrom => {
    return (value, event) => {
      event.preventDefault();
      event.stopPropagation();
      onSwitchToggle({ value, toggleFrom });
    };
  };

  const onSwitchToggle = async ({ value, toggleFrom }) => {
    let loaderKey;

    let promise = null;

    if (toggleFrom === 'stockVisibility') {
      loaderKey = loaderKeyValues.current.showOutOfStockProduct;
      promise = changeCompanyOutOfStockVisibility({ value });
    } else if (toggleFrom === 'orderOnOOS') {
      loaderKey = loaderKeyValues.current.allowOrdersOnOutOfStock;
      promise = changeCompanyAllowOrderOnOutOfStock({ value });
    } else if (toggleFrom === 'trackInventory') {
      loaderKey = loaderKeyValues.current.trackInventory;
      promise = changeCompanyTrackInventory({ value });
    } else if (toggleFrom === 'autoReduce') {
      loaderKey = loaderKeyValues.current.autoReduceInventory;
      const inventoryValue = value ? 'VISITOR' : 'COMPANY';
      promise = changeCompanyAutoReduceInventory({ value: inventoryValue });
    }

    if (promise) {
      toggleInventoryLoader(prevState => ({
        ...prevState,
        [loaderKey]: true
      }));
      try {
        await promise;
      } catch (error) {
        toastr.error(t('something_went_wrong_while_updating_the_inventory_settings'));
      }
      toggleInventoryLoader(prevState => ({
        ...prevState,
        [loaderKey]: false
      }));
    }
  };

  const {
    showOutOfStockProduct,
    allowOrdersOnOutOfStock,
    trackInventory,
    autoReduceInventory
  } = inventoryState;

  return (
    <div className="inventory-settings-container">
      <SettingsDataRow
        onSectionClick={() =>
          onSwitchToggle({ value: !showOutOfStockProduct, toggleFrom: 'stockVisibility' })
        }
        icon={
          <Switch
            checked={showOutOfStockProduct}
            onChange={handleSwitchChange('stockVisibility')}
            onColor="#515863"
            offColor="#515863"
            onHandleColor="#4DA47A"
            offHandleColor="#929faf"
            handleDiameter={25}
            uncheckedIcon={false}
            checkedIcon={false}
            height={17}
            width={40}
          />
        }
        loader={showInventoryLoader[loaderKeyValues.current.showOutOfStockProduct]}
        title={t('out_of_stock_visibility')}
      >
        <span>{t('show_out_of_stock_products_to_catalogue')}</span>
      </SettingsDataRow>

      {!!showOutOfStockProduct && (
        <SettingsDataRow
          onSectionClick={() =>
            onSwitchToggle({ value: !allowOrdersOnOutOfStock, toggleFrom: 'orderOnOOS' })
          }
          icon={
            <Switch
              checked={allowOrdersOnOutOfStock}
              onChange={handleSwitchChange('orderOnOOS')}
              onColor="#515863"
              offColor="#515863"
              onHandleColor="#4DA47A"
              offHandleColor="#929faf"
              handleDiameter={25}
              uncheckedIcon={false}
              checkedIcon={false}
              height={17}
              width={40}
            />
          }
          loader={showInventoryLoader[loaderKeyValues.current.allowOrdersOnOutOfStock]}
          title={t('allow_orders_on_out_of_stock_products')}
        >
          <span>{t('allow_customers_to_place_an_order_out_of_stock')}</span>
        </SettingsDataRow>
      )}

      <SettingsDataRow
        onSectionClick={() =>
          onSwitchToggle({ value: !trackInventory, toggleFrom: 'trackInventory' })
        }
        icon={
          <Switch
            checked={trackInventory}
            onChange={handleSwitchChange('trackInventory')}
            onColor="#515863"
            offColor="#515863"
            onHandleColor="#4DA47A"
            offHandleColor="#929faf"
            handleDiameter={25}
            uncheckedIcon={false}
            checkedIcon={false}
            height={17}
            width={40}
          />
        }
        loader={showInventoryLoader[loaderKeyValues.current.trackInventory]}
        title={t('track_inventory')}
      >
        <span>{t('turn_on_inventory_tracking_settings')}</span>
      </SettingsDataRow>

      {!!trackInventory && (
        <SettingsDataRow
          onSectionClick={() =>
            onSwitchToggle({
              value: !autoReduceMapping.current[autoReduceInventory],
              toggleFrom: 'autoReduce'
            })
          }
          icon={
            <Switch
              checked={autoReduceMapping.current[autoReduceInventory]}
              onChange={handleSwitchChange('autoReduce')}
              onColor="#515863"
              offColor="#515863"
              onHandleColor="#4DA47A"
              offHandleColor="#929faf"
              handleDiameter={25}
              uncheckedIcon={false}
              checkedIcon={false}
              height={17}
              width={40}
            />
          }
          loader={showInventoryLoader[loaderKeyValues.current.autoReduceInventory]}
          title={t('auto_reduce_inventory_on_visitor_confirmation')}
        >
          <span>{t('turn_on_this_setting_by_default_for_new_products')}</span>
        </SettingsDataRow>
      )}
    </div>
  );
};
