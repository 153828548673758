import React, { useRef, useState, Fragment, useCallback, useEffect } from 'react';
import useVariantMetaHook from '../../../../Hooks/variantMetaHook';
import { ReactComponent as SettingsIcon } from 'qs-assets/Media/settingsOutlineIcon.svg';
import Loader from '../../../../../../Common/Loader';
import Modal from 'react-responsive-modal';
import Inventory from '../../Inventory';
import TrackInventory from '../../TrackInventory';
import ErrorIcon from '../../../../../../Common/ErrorIcon';
import { FROM_VARIANTS, VARIANT_INVENTORY_ROW } from 'qs-helpers/Variants/constants';
import {
  getInventoryForVariantFromCache,
  isVariantInventoryListenerRequired,
  getVariantInventoryData
} from 'qs-data-manager/Variants/VariantInventory';
import useRefreshHandlerHook from 'qs-hooks/refreshHandlerHook';
import { attachInventoryListener, removeInventoryListener } from 'qs-data-manager/ProductDetails';
import CacheListenerCallback from 'qs-helpers/CacheListenerCallback';
import useUpdateListOnItemUpdate from 'qs-hooks/useUpdateListOnItemUpdate';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

export default ({ variantId, onMetaUpdate, indexInList }) => {
  const variantMetaData = useVariantMetaHook(variantId);
  const [showInventorySettings, toggleInventorySettings] = useState(false);
  const modalStyles = useRef({
    borderRadius: 10,
    maxWidth: '500px',
    width: '100%',
    height: 'auto',
    maxHeight: '100%',
    boxSizing: 'border-box',
    background: '#1F2229',
    overflow: 'auto'
  });
  const [variantInventoryStatus, updateVariantInventoryStatus] = useRefreshHandlerHook(
    getInventoryForVariantFromCache({ variantId }),
    false
  );
  const { t } = getI18N();

  const { loading, metaData, error } = variantMetaData;

  const { color, label } = metaData || {};

  const [, setInventoryRowRef] = useUpdateListOnItemUpdate({
    reRenderListOnItemUpdate: onMetaUpdate,
    defaultRowHeight: VARIANT_INVENTORY_ROW.estimatedHeight,
    index: indexInList,
    uniqueId: variantId
  });

  useEffect(() => {
    updateVariantInventoryStatus({
      data: getInventoryForVariantFromCache({ variantId }),
      localRefresh: true
    });

    // Before attaching listeners determine if the variant call is required.
    // This will ensure that this component does not listen to unnecessary updates
    if (
      !isVariantInventoryListenerRequired({
        variantId
      })
    ) {
      return;
    }

    // Setup the listener only if the variant inventory call will be made.
    // Continuous data changes are not required while the changes are being
    // done directly through this component
    const listener = (error, payload) => {
      const dataFromListener = CacheListenerCallback(error, payload);
      updateVariantInventoryStatus(dataFromListener);
      const { err, loading, refreshing, data } = dataFromListener;
      if (err || loading || refreshing || !data) {
        return;
      }

      //Setup a one time listener. remove it once the data arrives
      removeInventoryListener({
        productId: variantId,
        listener
      });
    };

    attachInventoryListener({
      listener,
      productId: variantId
    });

    getVariantInventoryData({ variantId }).then(() => {});

    return () =>
      removeInventoryListener({
        productId: variantId,
        listener
      });
  }, [variantId, updateVariantInventoryStatus]);

  const onInventorySettingsClick = event => {
    event.preventDefault();
    toggleInventorySettings(true);
  };

  const closeModal = useCallback(() => {
    toggleInventorySettings(false);
  }, [toggleInventorySettings]);

  const getVariantLabelComponent = (containerClass = '') => {
    if (error) {
      return (
        <div className="variantLabelContainer variantInventoryErrorContainer">
          <ErrorIcon />
          <div className="variantErrorLabel">{t('error_fetching_the_variant_label')} </div>
        </div>
      );
    }

    if (loading) {
      return (
        <div className="variantLabelContainer">
          <Loader size="small" />
        </div>
      );
    }

    return (
      <div className={`variantLabelContainer ${containerClass}`}>
        {Array.isArray(color) && (
          <div
            className="variantColor"
            style={{ background: `rgb(${color[0]},${color[1]},${color[2]})` }}
          />
        )}
        <div className="variantLabel">{label}</div>
      </div>
    );
  };

  return (
    <Fragment>
      <div className="variantInventoryDataContainer variantInventoryLabel" ref={setInventoryRowRef}>
        {getVariantLabelComponent()}
        <TrackInventory variantId={variantId} variantInventoryStatus={variantInventoryStatus} />
        <div className="variantSettingsIcon" onClick={onInventorySettingsClick}>
          <SettingsIcon />
        </div>
      </div>
      {showInventorySettings && (
        <Modal
          open={true}
          center={true}
          onClose={closeModal}
          showCloseIcon={false}
          styles={{ modal: modalStyles.current }}
        >
          <div className="variantInventoryLabel">
            {getVariantLabelComponent('variantInventoryModal')}
          </div>
          <Inventory isBulkEditing={false} activeProductId={variantId} from={FROM_VARIANTS} />
        </Modal>
      )}
    </Fragment>
  );
};
