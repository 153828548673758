import React from 'react';
import { ORDER_STATUS } from 'qs-helpers/Orders/constants';
import { Button, withStyles } from '@material-ui/core';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

export default ({
  isFinalized,
  orderStatus,
  statusChangeProg,
  onUpdateStatus,
  shouldBeginUpdate
}) => {
  const { t } = getI18N();

  const orderStatusButtonClick = updatedOrderStatus => async () => {
    try {
      await shouldBeginUpdate();
    } catch (updateRejected) {
      // Update not required
      return;
    }
    await onUpdateStatus({ orderStatus: updatedOrderStatus });
  };

  const getButtonStylesAsPerStatus = ({ orderStatus: buttonOrderStatus }) => {
    if (buttonOrderStatus !== ORDER_STATUS.REJECTED) {
      return {};
    }

    return {
      backgroundColor: '#7A1515',
      '&:hover': {
        backgroundColor: '#F04646'
      },
      disabled: {
        backgroundColor: '#7A1515'
      }
    };
  };

  const getButtonsAsPerOrderStatus = () => {
    if (!isFinalized) {
      return [
        {
          label: t('confirm_and_accept'),
          orderStatus: ORDER_STATUS.ACCEPTED
        }
      ];
    }

    if (typeof orderStatus !== 'string') {
      return [
        {
          label: t('reject'),
          orderStatus: ORDER_STATUS.REJECTED
        },
        {
          label: t('accept'),
          orderStatus: ORDER_STATUS.ACCEPTED
        }
      ];
    }

    if (orderStatus === ORDER_STATUS.ACCEPTED) {
      return [
        {
          label: t('mark_as_complete'),
          orderStatus: ORDER_STATUS.COMPLETED
        }
      ];
    }

    if (orderStatus === ORDER_STATUS.REJECTED) {
      return [
        {
          label: t('accept'),
          orderStatus: ORDER_STATUS.ACCEPTED
        }
      ];
    }

    return null;
  };

  const buttonsToRender = getButtonsAsPerOrderStatus();
  if (!Array.isArray(buttonsToRender)) {
    return null;
  }

  return (
    <>
      <div className="orderStatusButtonContainer">
        {buttonsToRender.map(({ orderStatus, label }, index) => {
          const { disabled: disabledClass = {}, ...restStyles } = getButtonStylesAsPerStatus({
            orderStatus
          });
          const StyledButton = withStyles({
            root: {
              color: '#fff',
              backgroundColor: '#4DA47A',
              '&:hover': {
                backgroundColor: '#31d184'
              },
              '&$disabled': {
                opacity: 0.5,
                color: '#fff',
                backgroundColor: '#4DA47A',
                ...disabledClass
              },
              ...restStyles
            },
            disabled: {}
          })(Button);
          return (
            <StyledButton
              key={index}
              className="orderStatusButton"
              disabled={statusChangeProg}
              onClick={orderStatusButtonClick(orderStatus)}
              variant="contained"
            >
              {label}
            </StyledButton>
          );
        })}
      </div>
    </>
  );
};
