import moment from 'moment';

export const formatProductTitle = ({ value, data }) => {
  if (typeof data === 'undefined') {
    return 'Fetching product data';
  }

  if (!value) {
    return 'No product Title';
  }

  return value;
};

export const formatTaxes = ({ value }) => {
  if (!Array.isArray(value)) {
    return '';
  }

  return value
    .reduce((taxStrings, currentTax) => {
      if (!currentTax) {
        return taxStrings;
      }

      const { taxPercentage, taxType } = currentTax;
      taxStrings.push(`${taxPercentage}% ${taxType}`);
      return taxStrings;
    }, [])
    .join(', ');
};

export const formatCreatedDate = ({ value }) => {
  if (typeof value !== 'string') {
    return '';
  }

  return moment(value).format('Do MMMM YYYY, h:mm a');
};

export const priceRenderer = ({ data, value } = {}) => {
  if (!data || typeof value !== 'string') {
    return '';
  }

  return `${data.currencySymbol} ${value}`;
};
