import React, { useRef, useState } from 'react';
import Modal from 'react-responsive-modal';
import Chrome from 'react-color/lib/Chrome';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

export default ({ rgb, onClose, onSelectColor }) => {
  const [rgbValue, setRgbValue] = useState(rgb || [255, 255, 255]);
  /*
    The hex value is needed only to search colors. In case if rgb is not
    present then white color would be chosen, hence set the default to white.
    In case if some other selection is present, then the calling component,
    currently, does not require hex value
  */
  const [hexValue, setHexValue] = useState(rgb ? null : '#ffffff');
  const { t } = getI18N();

  const modalStyles = useRef({
    borderRadius: 10,
    background: 'transparent',
    display: 'flex',
    flexDirection: 'column'
  });

  const handleCancel = event => {
    event.preventDefault();
    onClose();
  };

  const handleSelect = event => {
    event.preventDefault();
    onSelectColor(rgbValue, hexValue);
  };

  const selectionComplete = color => {
    const { rgb: { r = 0, g = 0, b = 0 } = {}, hex } = color;
    setRgbValue([r, g, b]);
    setHexValue(hex);
  };

  return (
    <Modal
      open={true}
      center={true}
      closeOnEsc={false}
      showCloseIcon={false}
      closeOnOverlayClick={false}
      styles={{ modal: modalStyles.current }}
    >
      <div>
        <Chrome
          width={'unset'}
          color={{ r: rgbValue[0], g: rgbValue[1], b: rgbValue[2] }}
          onChange={selectionComplete}
          disableAlpha={true}
        />
        <div className="colorPickerButtonContainer">
          <button onClick={handleCancel} className="buttonPlain">
            {t('cancel')}
          </button>
          <button className="buttonPlain buttonPrimary" onClick={handleSelect}>
            {t('select_color')}
          </button>
        </div>
      </div>
    </Modal>
  );
};
