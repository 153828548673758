import {
  BOOLEAN_FIELD,
  NUMBER_FIELD,
  STRING_FIELD
} from 'qs-helpers/CustomProductFields/constants';
import { PRICE_FILTER_KEYS_CONFIG } from 'qs-helpers/Products/constants';

export const UPDATE_PRODUCT_LIST_PRICE_FILTER = 'UPDATE_PRODUCT_LIST_PRICE_FILTER';
export const RESET_PRODUCT_LIST_PRICE_FILTER = 'RESET_PRODUCT_LIST_PRICE_FILTER';
export const UPDATE_PRODUCT_LIST_PRICE_FILTER_OPTION = 'UPDATE_PRODUCT_LIST_PRICE_FILTER_OPTION';
export const SET_PRODUCT_LIST_APPLY_FILTER = 'SET_PRODUCT_LIST_APPLY_FILTER';
export const UNSET_PRODUCT_LIST_APPLY_FILTER = 'UNSET_PRODUCT_LIST_APPLY_FILTER';
export const SET_REORDER_PRODUCTS = 'SET_REORDER_PRODUCTS';
export const UPDATE_PRODUCT_LIST_SEARCH = 'UPDATE_PRODUCT_LIST_SEARCH';
export const UPDATE_PRODUCT_LIST_TAGS = 'UPDATE_PRODUCT_LIST_TAGS';
export const REMOVE_MISSING_TAGS_PRODUCT_LIST = 'REMOVE_MISSING_TAGS_PRODUCT_LIST';
export const TOGGLE_PRODUCT_LIST_TAGS = 'TOGGLE_PRODUCT_LIST_TAGS';
export const RESET_PRODUCT_LIST_META = 'RESET_PRODUCT_LIST_META';
export const UPDATE_SELECTED_VARIANT = 'UPDATE_SELECTED_VARIANT';
export const UPDATE_SELECTED_CUSTOM_FIELDS = 'UPDATE_SELECTED_CUSTOM_FIELDS';
export const UPDATE_CUSTOM_FIELDS_NUMERIC_SELECTION = 'UPDATE_CUSTOM_FIELDS_NUMERIC_SELECTION';
export const UNSET_PRODUCT_LIST_META_RESET = 'UNSET_PRODUCT_LIST_META_RESET';
export const PRODUCT_LIST_CLEAR_FILTERS = 'PRODUCT_LIST_CLEAR_FILTERS';
export const PRODUCT_LIST_CLEAR_FILTERS_AND_SEARCH = 'PRODUCT_LIST_CLEAR_FILTERS_AND_SEARCH';

const getPriceFiltersForExistingOption = state => {
  const existingOptionId = state.currentPriceFilterSelectedOption;
  if (!existingOptionId) {
    return null;
  }
  return { ...state.currentPriceFilters };
};

export const productListMetaInit = () => ({
  currentSearchTerm: null,
  /*
    STRUCTURE: {
      [optionId]: {
        [key]: type string (numValAsString)
      }
    }
  */
  currentPriceFilters: {},
  currentPriceFilterSelectedOption: PRICE_FILTER_KEYS_CONFIG.EQUALS.id,
  lastSeenPriceValue: '',
  applyFilters: false,
  /*
    STRUCTURE: {
      [variantType]: []
    }
  */
  currentVariantSelection: {},
  /*
    STRUCTURE: {
      [fieldId]: {
        stringValues: [],
        booleanValue: type bool,
        numericValue: {
          [optionId]: {
            [key]: type string (numValAsString)
          }
        }
      }
    }
  */
  currentCustomFieldsSelection: {},
  customFieldsNumericOption: {},
  enableProductsReorder: false,
  currentSelectedTags: new Set(),
  reset: false
});

export const productListMetaReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_PRODUCT_LIST_PRICE_FILTER: {
      const { num, key } = action;
      const copiedPriceFilters = getPriceFiltersForExistingOption(state);
      if (copiedPriceFilters === null) {
        return state;
      }

      const existingOptionId = state.currentPriceFilterSelectedOption;
      copiedPriceFilters[existingOptionId] = {
        ...(copiedPriceFilters[existingOptionId] || {})
      };
      if (num === '') {
        delete copiedPriceFilters[existingOptionId][key];
      } else {
        copiedPriceFilters[existingOptionId][key] = num;
      }

      if (Object.keys(copiedPriceFilters[existingOptionId]).length === 0) {
        delete copiedPriceFilters[existingOptionId];
      }

      return {
        ...state,
        currentPriceFilters: copiedPriceFilters,
        lastSeenPriceValue: num
      };
    }
    case RESET_PRODUCT_LIST_PRICE_FILTER:
      return {
        ...state,
        currentPriceFilters: {}
      };
    case UPDATE_PRODUCT_LIST_PRICE_FILTER_OPTION: {
      const { priceFilterOption, key } = action;
      let existingPriceFilters = state.currentPriceFilters;
      let { lastSeenPriceValue } = state;
      if (!priceFilterOption || !PRICE_FILTER_KEYS_CONFIG[priceFilterOption].id) {
        existingPriceFilters = {};
      }

      const selectedOptionId = priceFilterOption || PRICE_FILTER_KEYS_CONFIG.EQUALS.id;
      if (existingPriceFilters[selectedOptionId]) {
        const firstNumKey = Object.keys(existingPriceFilters[selectedOptionId])[0];
        const firstNumKeyVal = existingPriceFilters[selectedOptionId][firstNumKey];
        if (firstNumKeyVal) {
          lastSeenPriceValue = firstNumKeyVal;
        }
      }

      //Copy over the last seen price value if it exists and apply to the key of the current value
      if (lastSeenPriceValue) {
        if (!existingPriceFilters[selectedOptionId]) {
          existingPriceFilters[selectedOptionId] = {};
        }
        existingPriceFilters[selectedOptionId][key] = lastSeenPriceValue;
      }

      return {
        ...state,
        currentPriceFilterSelectedOption: selectedOptionId,
        currentPriceFilters: existingPriceFilters,
        lastSeenPriceValue
      };
    }
    case SET_PRODUCT_LIST_APPLY_FILTER:
      if (state.applyFilters) {
        return state;
      }
      return {
        ...state,
        applyFilters: true
      };
    case UNSET_PRODUCT_LIST_APPLY_FILTER:
      if (!state.applyFilters) {
        return state;
      }
      return {
        ...state,
        applyFilters: false
      };
    case SET_REORDER_PRODUCTS: {
      return {
        ...state,
        enableProductsReorder: action.enableProductsReorder
      };
    }
    case UPDATE_PRODUCT_LIST_SEARCH:
      return {
        ...state,
        currentSearchTerm: action.searchTerm || null
      };
    case UPDATE_PRODUCT_LIST_TAGS: {
      let newTags = action.tags;
      if (!(newTags instanceof Set)) {
        newTags = new Set();
      }

      return {
        ...state,
        currentSelectedTags: newTags
      };
    }
    case REMOVE_MISSING_TAGS_PRODUCT_LIST: {
      if (state.currentSelectedTags.size === 0) {
        return state;
      }

      const { tags } = action;
      if ((!Array.isArray(tags) || tags.length === 0) && state.currentSelectedTags.size > 0) {
        return {
          ...state,
          currentSelectedTags: new Set()
        };
      }

      //Tag title is same as Id
      const updatedTags = new Set();
      tags.forEach(({ title }) => {
        if (state.currentSelectedTags.has(title)) {
          updatedTags.add(title);
        }
      });

      //No change in size, meaning no item was ignored, return the same state
      //so that no change is detected by any component and hence no data is updated
      if (updatedTags.size === state.currentSelectedTags.size) {
        return state;
      }

      return {
        ...state,
        currentSelectedTags: updatedTags
      };
    }
    case TOGGLE_PRODUCT_LIST_TAGS:
      const shallowCopiedTags = new Set(state.currentSelectedTags);
      if (shallowCopiedTags.has(action.tagId)) {
        shallowCopiedTags.delete(action.tagId);
      } else {
        shallowCopiedTags.add(action.tagId);
      }

      return {
        ...state,
        currentSelectedTags: shallowCopiedTags
      };
    case UPDATE_SELECTED_VARIANT: {
      const { variantType, variantValues } = action;
      if (!variantType || !Array.isArray(variantValues)) {
        return state;
      }

      const copiedVariantSelection = { ...state.currentVariantSelection };
      if (variantValues.length > 0) {
        copiedVariantSelection[variantType] = variantValues;
      } else {
        delete copiedVariantSelection[variantType];
      }

      return {
        ...state,
        currentVariantSelection: copiedVariantSelection
      };
    }
    case UPDATE_SELECTED_CUSTOM_FIELDS: {
      const { fieldId, fieldType, value } = action;
      if (!fieldId || !fieldType) {
        return state;
      }

      const copiedCustomFieldsSelection = { ...state.currentCustomFieldsSelection };

      switch (fieldType) {
        case STRING_FIELD:
          if (!Array.isArray(value)) {
            return state;
          }
          if (value.length > 0) {
            copiedCustomFieldsSelection[fieldId] = {
              ...(copiedCustomFieldsSelection[fieldId] || {}),
              stringValues: value
            };
          } else {
            if (copiedCustomFieldsSelection[fieldId]) {
              delete copiedCustomFieldsSelection[fieldId].stringValues;

              if (Object.keys(copiedCustomFieldsSelection[fieldId]).length === 0) {
                delete copiedCustomFieldsSelection[fieldId];
              }
            }
          }
          break;
        case BOOLEAN_FIELD:
          //Currently only true case is handled. can be changed to boolean type check to handle other cases
          if (value) {
            copiedCustomFieldsSelection[fieldId] = {
              ...(copiedCustomFieldsSelection[fieldId] || {}),
              booleanValue: value
            };
          } else {
            if (copiedCustomFieldsSelection[fieldId]) {
              delete copiedCustomFieldsSelection[fieldId].booleanValue;

              if (Object.keys(copiedCustomFieldsSelection[fieldId]).length === 0) {
                delete copiedCustomFieldsSelection[fieldId];
              }
            }
          }
          break;
        case NUMBER_FIELD: {
          const { key } = action;
          //Get the current selection for the current fieldId. If no selection
          //is saved, then don't process the results further
          const selectionId = state.customFieldsNumericOption[fieldId];
          if (!selectionId) {
            return state;
          }

          //Clear out saved entries if possible
          if (value === '') {
            //Remove the curernt key for the selected option
            const numericFieldData = copiedCustomFieldsSelection[fieldId];
            if (
              numericFieldData &&
              numericFieldData.numericValue &&
              numericFieldData.numericValue[selectionId]
            ) {
              //Remove the curernt key for the selected option
              delete numericFieldData.numericValue[selectionId][key];

              //No values exist for the selected option, delete its entry too
              if (Object.keys(numericFieldData.numericValue[selectionId]).length === 0) {
                delete numericFieldData.numericValue[selectionId];
              }

              //No values exist for the selected field, delete its entry too
              if (Object.keys(numericFieldData.numericValue).length === 0) {
                delete numericFieldData.numericValue;
              }

              if (Object.keys(numericFieldData).length === 0) {
                //numericFieldData points to the original objects fieldId key
                delete copiedCustomFieldsSelection[fieldId];
              }
            }
          } else {
            //Populate the data structure
            let fieldData = copiedCustomFieldsSelection[fieldId];
            if (!fieldData) {
              fieldData = {};
            }

            const numericValueData = {
              ...(fieldData.numericValue || {})
            };
            numericValueData[selectionId] = {
              ...(numericValueData[selectionId] || {}),
              [key]: value
            };

            //Shallow copy entire
            copiedCustomFieldsSelection[fieldId] = {
              ...fieldData,
              numericValue: numericValueData
            };
          }
          break;
        }
        default:
          break;
      }

      return {
        ...state,
        currentCustomFieldsSelection: copiedCustomFieldsSelection
      };
    }
    case UPDATE_CUSTOM_FIELDS_NUMERIC_SELECTION: {
      const { fieldId, optionId } = action;
      if (!fieldId) {
        return state;
      }

      const copiedNumericOption = { ...state.customFieldsNumericOption };
      if (!optionId) {
        delete copiedNumericOption[fieldId];
      } else {
        copiedNumericOption[fieldId] = optionId;
      }

      return {
        ...state,
        customFieldsNumericOption: copiedNumericOption
      };
    }
    case PRODUCT_LIST_CLEAR_FILTERS:
      return {
        ...state,
        currentPriceFilters: {},
        lastSeenPriceValue: '',
        currentPriceFilterSelectedOption: PRICE_FILTER_KEYS_CONFIG.EQUALS.id,
        //So that subscribers can update their data as needed
        applyFilters: true,
        currentVariantSelection: {},
        currentCustomFieldsSelection: {},
        customFieldsNumericOption: {}
      };
    case PRODUCT_LIST_CLEAR_FILTERS_AND_SEARCH:
      return {
        ...state,
        currentPriceFilters: {},
        currentPriceFilterSelectedOption: PRICE_FILTER_KEYS_CONFIG.EQUALS.id,
        //So that subscribers can update their data as needed
        applyFilters: true,
        currentVariantSelection: {},
        currentCustomFieldsSelection: {},
        customFieldsNumericOption: {},
        currentSearchTerm: null
      };
    case UNSET_PRODUCT_LIST_META_RESET:
      if (!state.reset) {
        return state;
      }

      return {
        ...state,
        reset: false
      };
    case RESET_PRODUCT_LIST_META:
      return { ...productListMetaInit(), reset: true };
    default:
      return state;
  }
};
