import {
  getOrderInquiries,
  getAllCompanyOrders,
  getShippingAddress,
  getOrderPdfSettings,
  getInventoryUpdateOnOrderStatusChange,
  getUnreadInquiryCountForOrderStatus
} from './api';

export const ALL_COMPANY_ORDERS = {
  cacheKey: 'ALL_COMPANY_ORDERS',
  apiFunction: getAllCompanyOrders
};

export const ORDER_META = {
  cacheKey: 'ORDER_META'
};

export const ORDER_PRODUCTS = {
  cacheKey: 'ORDER_PRODUCTS',
  apiFunction: getOrderInquiries
};

export const ORDER_PRODUCTS_SHIPPING = {
  cacheKey: 'ORDER_PRODUCTS_SHIPPING',
  apiFunction: getShippingAddress
};

export const COMPANY_UNREAD_INQUIRIES = {
  cacheKey: 'COMPANY_UNREAD_INQUIRIES',
  apiFunction: getUnreadInquiryCountForOrderStatus
};

export const COMPANY_ORDER_PDF_SETTINGS = {
  cacheKey: 'COMPANY_ORDER_PDF_SETTINGS',
  apiFunction: getOrderPdfSettings
};

export const INVENTORY_UPDATE_ORDER_STATUS_CHANGE = {
  cacheKey: 'INVENTORY_UPDATE_ORDER_STATUS_CHANGE',
  apiFunction: getInventoryUpdateOnOrderStatusChange
};
