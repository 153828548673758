import React, { useContext, Fragment, useState, useEffect, useCallback, memo } from 'react';
import { VariantDataForUpdate } from '../context';
import {
  UPDATE_TITLE,
  UPDATE_DESCRIPTION,
  RESET_VARIANT_DATA,
  UPDATE_FROM_PRODUCT_TITLE,
  UPDATE_FROM_PRODUCT_DESC,
  UPDATE_WEIGHT,
  UPDATE_FROM_WEIGHT,
  UPDATE_SKU,
  UPDATE_JEWELLERY_RATES
} from '../reducer';
import InfoFormField from '../../Common/InfoFormField';
import {
  removeVariantInfoListener,
  attachVariantInfoListener,
  getVariantInfoFromRemote,
  getVariantBasicInfoFromCache
} from 'qs-data-manager/Variants/VariantsDetails';
import CacheListenerCallback from 'qs-helpers/CacheListenerCallback';
import Loader from '../../../../Common/Loader';
import { ActiveVariantId } from '../../context';
import PriceAndDiscount from './PriceAndDiscount';
import useRefreshHandlerHook from 'qs-hooks/refreshHandlerHook';
import VariantImages from './VariantImages';
import CustomFields from '../../CustomFields';
import WeightInput from '../../Common/WeightInput';
import './styles.scss';
import VariantMoq from './VariantMoq';
import { getVariantsMetaDataFromCache } from 'qs-data-manager/Variants/FetchVariants';
import { ProductPrices } from '../../ActiveTabMeta/BasicInfo/ProductPrices';
import { getI18N } from 'qs-services/i18N';

const OPTION_CHIPS = [
  {
    text: 'basic_info',
    type: 'basic'
  },
  {
    text: 'custom_fields',
    type: 'custom'
  }
];

export default memo(({ activeProductId }) => {
  const { t } = getI18N();
  const { activeVariantId } = useContext(ActiveVariantId);
  const variantInfo = getVariantBasicInfoFromCache({ activeVariantId, activeProductId });
  const [currencySymbol, setCurrencySymbol] = useState(variantInfo.currencySymbol);
  const [variantDataStatus, updateVariantStatus] = useRefreshHandlerHook(variantInfo);
  const { variantData, setVariantData } = useContext(VariantDataForUpdate);

  const {
    title,
    showTitleChange,
    sku,
    weight,
    showWeightChange,
    description,
    showDescriptionChange
  } = variantData;

  const [activeTab, setActive] = useState('basic');

  useEffect(() => {
    const cachedVariantData = getVariantBasicInfoFromCache({
      activeVariantId,
      activeProductId
    });

    setVariantData({
      type: RESET_VARIANT_DATA,
      variantData: cachedVariantData
    });

    setCurrencySymbol(cachedVariantData.currencySymbol);
    updateVariantStatus({ data: cachedVariantData, localRefresh: true });

    const listener = (error, payload) => {
      const dataFromListener = CacheListenerCallback(error, payload);
      updateVariantStatus(dataFromListener);
      const { err, loading, refreshing, data } = dataFromListener;
      if (err || loading || refreshing || !data) {
        return;
      }

      const updatedVariantData = getVariantBasicInfoFromCache({
        activeVariantId,
        activeProductId
      });
      setVariantData({
        type: RESET_VARIANT_DATA,
        variantData: updatedVariantData
      });

      setCurrencySymbol(updatedVariantData.currencySymbol);

      //Listen for the data only to update the initial state of the input fields
      // post the initial load, the updates will become the source of truth
      removeVariantInfoListener({ listener, variantId: activeVariantId });
    };

    attachVariantInfoListener({ listener, variantId: activeVariantId });
    getVariantInfoFromRemote({ variantId: activeVariantId });

    return () => removeVariantInfoListener({ listener, variantId: activeVariantId });
  }, [activeVariantId, activeProductId, setVariantData, updateVariantStatus]);

  const onTitleChange = event => {
    event.preventDefault();
    setVariantData({ type: UPDATE_TITLE, title: event.target.value });
  };

  const onSkuChange = event => {
    event.preventDefault();
    setVariantData({ type: UPDATE_SKU, sku: event.target.value });
  };

  const onWeightChange = weightValue => {
    setVariantData({ type: UPDATE_WEIGHT, weight: weightValue });
  };

  const onDescriptionChanged = event => {
    event.preventDefault();
    setVariantData({ type: UPDATE_DESCRIPTION, description: event.target.value });
  };

  const onChangeClick = useCallback(
    label => {
      if (label === 'Title') {
        setVariantData({ type: UPDATE_FROM_PRODUCT_TITLE, showTitleChange: false });
        return;
      }

      if (label === 'Weight') {
        setVariantData({ type: UPDATE_FROM_WEIGHT, showWeightChange: false });
        return;
      }

      if (label === 'Description') {
        setVariantData({ type: UPDATE_FROM_PRODUCT_DESC, showDescriptionChange: false });
        return;
      }

      if (label === 'productTypeRateCharges') {
        setVariantData({ type: UPDATE_JEWELLERY_RATES, showProductTypeRateChargesChange: false });
      }
    },
    [setVariantData]
  );

  const getVariantSetInfo = () => {
    let isSet = false;
    const variantMeta = getVariantsMetaDataFromCache(activeVariantId);
    if (variantMeta) {
      ({ isSet } = variantMeta);
    }
    return isSet;
  };

  const renderPriceAndDiscountComponent = () => {
    return (
      <PriceAndDiscount
        containerClass={'variantsInfoView sectionContainer'}
        currencySymbol={currencySymbol}
        disabled={disableFields}
      />
    );
  };

  const renderPriceComponent = () => {
    return (
      <ProductPrices
        productId={activeVariantId}
        parentProductId={activeProductId}
        productDetails={{
          productMetaFromRow: variantInfo
        }}
        priceAndDiscountRenderer={renderPriceAndDiscountComponent}
      />
    );
  };

  if (variantDataStatus.error) {
    return (
      <div className="variantsInfoView variantDataPlaceholder">
        <div className="error">
          {t('something_went_wrong_while_fetching_data_for_this_variant')}
        </div>
      </div>
    );
  }

  // When no cached data is present, show a regular loader.
  // when cached data is present the custom hook will toggle
  // the global loader
  if (variantDataStatus.loading) {
    return (
      <div className="variantsInfoView variantDataPlaceholder">
        <Loader size="large" />
      </div>
    );
  }

  // Disable the fields while the data is refreshing.
  const disableFields = variantDataStatus.refreshing;

  const setTabOption = type => {
    setActive(type);
  };

  return (
    <Fragment>
      <div className="variantCustomize">
        {OPTION_CHIPS.map((chip, index) => (
          <p
            key={index}
            className={`${activeTab === chip.type ? 'activeTab' : ''} customizeOption`}
            onClick={() => setTabOption(chip.type)}
          >
            {t(chip.text)}
          </p>
        ))}
      </div>
      {activeTab === 'basic' && (
        <Fragment>
          <VariantImages containerClasses={'variantsInfoView sectionContainer'} />
          <div className="variantsInfoView sectionContainer">
            <InfoFormField
              fieldType={'INPUT'}
              label={t('title')}
              fieldLabel="Title"
              changeButton={showTitleChange}
              onChangeClick={onChangeClick}
            >
              <input
                type="text"
                value={title}
                onChange={onTitleChange}
                disabled={showTitleChange || disableFields}
                placeholder={t('type_variant_title')}
              />
            </InfoFormField>
          </div>

          <div className="variantsInfoView sectionContainer">
            <InfoFormField fieldType={'INPUT'} label={t('SKU')} fieldLabel="SKU">
              <input
                type="text"
                value={sku}
                onChange={onSkuChange}
                disabled={disableFields}
                placeholder={t('type_sku')}
              />
            </InfoFormField>
          </div>

          {renderPriceComponent()}

          <div className="variantsInfoView sectionContainer">
            <WeightInput
              showWeightChange={showWeightChange}
              onChangeClick={onChangeClick}
              onWeightChange={onWeightChange}
              weight={weight}
              disabled={showWeightChange || disableFields}
              isSet={getVariantSetInfo()}
            />
          </div>

          <div className="variantsInfoView sectionContainer">
            <VariantMoq />
          </div>

          <div className="variantsInfoView sectionContainer">
            <InfoFormField
              fieldType={'TEXTAREA'}
              label={t('description')}
              fieldLabel="Description"
              changeButton={showDescriptionChange}
              onChangeClick={onChangeClick}
            >
              <textarea
                rows="5"
                cols="20"
                type="text"
                onChange={onDescriptionChanged}
                value={description}
                disabled={showDescriptionChange || disableFields}
                placeholder={t('type_variant_description')}
              />
            </InfoFormField>
          </div>
        </Fragment>
      )}
      {activeTab === 'custom' && <CustomFields isVariant activeProductId={activeVariantId} />}
    </Fragment>
  );
});
