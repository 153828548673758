import React, { forwardRef, useRef } from 'react';
import { ReactComponent as ProductLibIcon } from 'qs-assets/Media/icon_product_library.svg';
import UploadImage from 'qs-assets/Media/photos-upload.png';
import ColoredExcel from 'qs-assets/Media/coloredExcel.png';
import { uploadFromFilePicker } from 'qs-data-manager/Products';
import { getActiveCatalogueId } from 'qs-data-manager/Catalogues';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

export const UploadButtonsFooterComponent = ({
  containerClassName = '',
  style,
  toggleProductLibrary,
  disablebutton,
  forwardedRef
}) => {
  const { t } = getI18N();
  const pickerRef = useRef(null);
  const excelRef = useRef(null);

  const handleFilesChange = e => {
    e.stopPropagation();
    e.preventDefault();

    const catalogueId = getActiveCatalogueId();
    uploadFromFilePicker({ filesList: e.target.files, catalogueId });
    if (pickerRef.current.value) {
      pickerRef.current.value = null;
    }
    if (excelRef.current.value) {
      excelRef.current.value = null;
    }
  };

  const showProductLibrary = event => {
    event.preventDefault();
    toggleProductLibrary(true);
  };

  return (
    <div
      className={`uploadFooterProductsEdit ${containerClassName}`}
      ref={forwardedRef}
      style={style}
    >
      <div className="editTextContainer">{t('upload_new_products')}</div>
      <div className="editButtonsContainer">
        <div className={`editButtonContainer uploadFiles`}>
          <img src={UploadImage} className="iconStyles" alt={''} />
          <input
            className="fileInput"
            ref={pickerRef}
            type="file"
            name="photos"
            accept="image/*"
            multiple
            onChange={handleFilesChange}
            disabled={disablebutton}
          />
          <span className="ellipsis">{t('file_system')}</span>
        </div>
        <button
          className="editButtonContainer uploadLibrary"
          onClick={showProductLibrary}
          disabled={disablebutton}
        >
          <ProductLibIcon className="iconStyles" />
          <span className="ellipsis">{t('library')}</span>
        </button>
        <div className="editButtonContainer uploadFiles">
          <img src={ColoredExcel} className="iconStyles" alt={''} />
          <input
            className="fileInput"
            ref={excelRef}
            type="file"
            name="excelFile"
            accept=".csv,.xls,.xlsx"
            multiple
            onChange={handleFilesChange}
            disabled={disablebutton}
          />
          <span className="ellipsis">{t('excel')}</span>
        </div>
      </div>
    </div>
  );
};

export default forwardRef((props, ref) => (
  <UploadButtonsFooterComponent {...props} forwardedRef={ref} />
));
