import React from 'react';
import toastr from 'toastr';

import './styles.scss';
import DialogBox from 'qs-components/Common/DialogBox';
import { getSortedProductList } from 'qs-services/DataManager/Products/CatalogueProductsList';
import { toggleGlobalLoader } from 'qs-helpers';
import { getI18N } from 'qs-services/i18N';

const ConfirmSortDialog = ({ catalogueId, open, typeOfSort, onClose, setShowSortProducts }) => {
  const { t } = getI18N();
  const getTitle = () => {
    let newTitle = '';
    if (open) {
      const { title = '', subTitle = '' } = typeOfSort;
      newTitle = t('are_you_sure_you_want_to_sort_your_catalogue_by_title_subtitle', {
        title: title ? title : '',
        subTitle: subTitle ? subTitle : ''
      });
    }
    return newTitle;
  };

  const confirmButtonClick = async () => {
    const { sortType = '', sortOrder = 'ASC', title = '' } = typeOfSort;
    const key = `updateHeadline${Date.now()}`;
    try {
      toggleGlobalLoader(key, true);
      await getSortedProductList({
        catalogueId,
        sortType,
        sortOrder
      });
      setShowSortProducts(false);
      toastr.success(t('products_sorted_by_title', { title }));
    } catch (error) {
      toastr.error(t('could_not_sort_products_please_try_again'));
    } finally {
      onClose();
      toggleGlobalLoader(key, false);
    }
  };

  return (
    <DialogBox open={open} onClose={onClose} title={''} maxWidth={'xs'}>
      <div className={'sortConfirmContainer'}>
        <div className={'sortConfirmTitle'}>{getTitle()}</div>
        <div className={'sortConfirmInfo'}>{t('this_will_change_your_customer_view_as_well')}</div>
        <div className={'sortConfirmOption'}>
          <div className={'closeDialogButton'} onClick={onClose}>
            {t('cancel')}
          </div>
          <div className={'confirmDialogButton'} onClick={confirmButtonClick}>
            {t('sort_products')}
          </div>
        </div>
      </div>
    </DialogBox>
  );
};
export default ConfirmSortDialog;
