import React, { memo, useEffect, useState, useContext } from 'react';
import { getVariantPicturesFromCache } from 'qs-data-manager/Variants/VariantPictures';
import { ActiveVariantId } from '../../../context';
import CacheListenerCallback from 'qs-helpers/CacheListenerCallback';
import {
  attachVariantInfoListener,
  removeVariantInfoListener
} from 'qs-data-manager/Variants/VariantsDetails';
import VariantImageView from './VariantImageView';

export default memo(({ containerClasses = '' }) => {
  const { activeVariantId } = useContext(ActiveVariantId);
  const [variantPictures, setVariantPictures] = useState();

  useEffect(() => {
    const cachedPictureData = getVariantPicturesFromCache({ activeVariantId });
    setVariantPictures(cachedPictureData);

    const listener = (error, payload) => {
      const { err, loading, refreshing, data } = CacheListenerCallback(error, payload);
      if (err || loading || refreshing || !data) {
        return;
      }
      const cachedPictureData = getVariantPicturesFromCache({ activeVariantId });
      setVariantPictures(cachedPictureData);
    };

    attachVariantInfoListener({ listener, variantId: activeVariantId });
    return () => removeVariantInfoListener({ listener, variantId: activeVariantId });
  }, [activeVariantId]);

  return (
    <div className={containerClasses}>
      <VariantImageView pictures={variantPictures} activeVariantId={activeVariantId} />
    </div>
  );
});
