export const UPDATE_IMAGE_DATA = 'UPDATE_IMAGE';
export const SET_CURRENT_AS_DEFAULT_IMAGE = 'SET_CURRENT_AS_DEFAULT_IMAGE';
export const UPDATE_DEFAULT_IMAGE = 'UPDATE_DEFAULT_IMAGE';

export const UPDATE_PRODUCT_ID = 'UPDATE_PRODUCT_ID';
export const NEW_CATALOGUE_OPENED = 'NEW_CATALOGUE_OPENED';
export const BULK_PRODUCTS_UPDATED = 'BULK_PRODUCTS_UPDATED';

export const imageMetaInitFunction = () => ({
  defaultImageId: null,
  currentImageDefault: false,
  currentImageData: null
});

export const imageMetaReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_IMAGE_DATA:
      return {
        ...state,
        currentImageDefault: action.newImageData.id === state.defaultImageId,
        currentImageData: action.newImageData
      };
    case SET_CURRENT_AS_DEFAULT_IMAGE:
      return {
        defaultImageId: action.newImageData.id,
        currentImageDefault: true,
        currentImageData: action.newImageData
      };
    case UPDATE_DEFAULT_IMAGE:
      return {
        ...state,
        defaultImageId: action.newImageData.id,
        currentImageDefault: state.currentImageData.id === action.newImageData.id
      };
    default:
      return state;
  }
};

export const activeProductsInitFunction = () => ({
  activeProductId: null,
  activeProductIds: null,
  isBulkEditing: null
});

export const activeProductsReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_PRODUCT_ID:
      return {
        ...state,
        activeProductId: action.productId
      };
    case NEW_CATALOGUE_OPENED:
      return activeProductsInitFunction();
    case BULK_PRODUCTS_UPDATED:
      const productIds = Array.from(action.all);
      if (productIds.length) {
        return {
          ...state,
          activeProductIds: productIds,
          isBulkEditing: true,
          activeProductId: null
        };
      }
      return {
        ...state,
        activeProductIds: null,
        isBulkEditing: null
      };
    default:
      return state;
  }
};
