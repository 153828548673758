import React, { useCallback, useState, useEffect } from 'react';

import { getActiveCatalogueId } from 'qs-data-manager/Catalogues';

import Loader from 'qs-components/Common/Loader';
import './styles.scss';
import {
  getShowcaseLinkForProductId,
  setShowcaseLinkInCacheForProductId
} from 'qs-data-manager/Products/ProductShowcaseLinks';
import { getI18N } from 'qs-services/i18N';

export default ({ activeProductId }) => {
  const [productLink, setProductLink] = useState('');
  const [loading, setLoading] = useState(true);
  const [failedToGetProductLink, setFailedToGetProductLink] = useState(false);
  const { t } = getI18N();

  const getProductShowcaseLink = useCallback(() => {
    setLoading(true);
    const catalogueId = getActiveCatalogueId();
    getShowcaseLinkForProductId({
      catalogueId,
      productId: activeProductId
    })
      .then(response => {
        setProductLink(response.link);
        setFailedToGetProductLink(false);
        if (response.cachedLink) {
          return;
        }
        setShowcaseLinkInCacheForProductId({
          catalogueId,
          productId: activeProductId,
          link: response.link
        });
      })
      .catch(() => {
        setFailedToGetProductLink(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [activeProductId]);

  useEffect(() => {
    getProductShowcaseLink();
  }, [getProductShowcaseLink]);

  const getProductLink = () => {
    if (loading) {
      return (
        <div className={'loadingProductLink'}>
          <Loader size="extraSmall" />
        </div>
      );
    }
    if (failedToGetProductLink) {
      return (
        <>
          <p className={'productLink'}>{t('failed_to_load_product_link')}</p>
          <p onClick={getProductShowcaseLink} className={'openProductLink error'}>
            {t('retry')}
          </p>
        </>
      );
    }
    return (
      <>
        <a href={productLink} target="_blank" className={'productLink'} rel="noopener noreferrer">
          {productLink}
        </a>
        <a
          href={productLink}
          target="_blank"
          className={'openProductLink'}
          rel="noopener noreferrer"
        >
          {t('view_product')}
        </a>
      </>
    );
  };

  return <div id={'ViewProduct'}>{getProductLink()}</div>;
};
