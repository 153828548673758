import React, { useState, useRef, useEffect, Fragment, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import ReactSwitch from 'react-switch';
import SettingsDataRow from '../../Common/SettingsDataRow/SettingsDataRow';
import Modal from 'react-responsive-modal';
import CacheListenerCallback from 'qs-helpers/CacheListenerCallback';
import {
  ROUTE_MAP,
  SETTINGS_COMPONENT_ID_MAP,
  SETTINGS_COMPONENT_ROUTE_MAP
} from 'qs-helpers/CompanySettings/constants';
import {
  getCompanyCurrencySymbol,
  setCompanyCheckoutSettings,
  setCompanyCheckoutMessageSettings,
  getCompanySettingsFromCache,
  attachCompanySettingsListener,
  removeCompanySettingsListener,
  setCompanyOrderReceiptsSettings
} from 'qs-data-manager/Company';
import { canUseFeature, FEATURE_LIST } from 'qs-data-manager/FeatureUsage';
import Loader from '../../../Common/Loader';
import DialogBox from '../../../Common/DialogBox';
import TextInput from '../TaxSettings/TextInput';
import { ReactComponent as EditIcon } from 'qs-assets/Media/pencil.svg';
import toastr from 'toastr';
import { InputAdornment } from '@material-ui/core';
import {
  extractCompanyCheckoutSettings,
  extractCompanyCheckoutMessageSettings,
  extractMoaSetting,
  extractCompanyOrderReceiptSettings,
  extractBulkOrderViewSettings
} from 'qs-helpers/CompanySettings/DataExtracters';
import { disableMinOrderAmount, setMinimumOrderAmount } from 'qs-data-manager/Checkout';
import CompanyMoq from './CompanyMoq';
import CompanyB2B from './CompanyB2B';
import './styles.scss';
import { getI18N } from 'qs-services/i18N';

export default () => {
  const history = useHistory();
  const [captureAddress, toggleCaptureAddress] = useState(
    extractCompanyCheckoutSettings(getCompanySettingsFromCache())
  );
  const [showAddressLoader, toggleAddressLoader] = useState(false);

  const msgVal = extractCompanyCheckoutMessageSettings(getCompanySettingsFromCache());
  const [checkoutMsg, setCheckoutMessage] = useState(msgVal);
  const [showMsgLoader, toggleMsgLoader] = useState(false);

  const [textAreaVal, setTextAreaVal] = useState(msgVal || '');
  const [showOrderMsgModal, toggleOrderMsgModal] = useState(false);

  const { moaNum, moaString } = extractMoaSetting(getCompanySettingsFromCache()) || {};
  const [minAmtText, setMinAmtText] = useState(moaString);
  const [showMinOrderLoader, toggleMinOrderLoader] = useState(false);

  const [minOrderAmount, setMinOrderAmount] = useState(moaNum);
  const [showMinOrderAmtModal, toggleMinOrderAmtModal] = useState(false);

  const [bulkOrderingView, setBulkOrderingView] = useState(
    extractBulkOrderViewSettings(getCompanySettingsFromCache())
  );

  const [orderReceipts, toggleOrderReceipts] = useState(
    extractCompanyOrderReceiptSettings(getCompanySettingsFromCache())
  );
  const [showOrderReceiptsLoader, toggleOrderReceiptsLoader] = useState(false);

  const currency = getCompanyCurrencySymbol();

  const { t } = getI18N();

  const modalStyles = useRef({
    backgroundColor: 'white',
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    width: '400px'
  });

  useEffect(() => {
    const checkoutListener = function(error, payload) {
      const { err, loading, refreshing, data } = CacheListenerCallback(error, payload);

      if (loading || refreshing) {
        return;
      }

      if (err) {
        toastr.error(t('something_went_wrong_while_fetching_the_checkout_information'));
        return;
      }

      const { settings } = data || {};
      toggleCaptureAddress(extractCompanyCheckoutSettings(settings));
      toggleOrderReceipts(extractCompanyOrderReceiptSettings(settings));
      setBulkOrderingView(extractBulkOrderViewSettings(settings));

      setCheckoutMessage(extractCompanyCheckoutMessageSettings(settings));
      setTextAreaVal(extractCompanyCheckoutMessageSettings(settings));

      const { moaNum, moaString } = extractMoaSetting(settings) || {};
      setMinAmtText(moaString);
      setMinOrderAmount(moaNum);
    };

    attachCompanySettingsListener(checkoutListener);
    return () => removeCompanySettingsListener(checkoutListener);
  });

  const handleOpenCustomCheckoutForm = async event => {
    event.preventDefault();
    history.push(
      `/${ROUTE_MAP.ACCOUNT_SETTINGS}/${
        SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CUSTOM_CHECKOUT_FORM_SETTINGS].ROUTE
      }`
    );
  };

  const handleCaptureAddressClick = async event => {
    event.preventDefault();
    toggleAddressLoader(true);
    try {
      await setCompanyCheckoutSettings(!captureAddress);
    } catch (error) {
      toastr.error(t('something_went_wrong_while_updating_the_capture_shipping_address_setting'));
    }
    toggleAddressLoader(false);
  };

  const handleOrderReceiptsClick = async function(event) {
    event.preventDefault();
    toggleOrderReceiptsLoader(true);
    try {
      await setCompanyOrderReceiptsSettings(!orderReceipts);
    } catch (error) {
      toastr.error(t('something_went_wrong_while_updating_the_order_receipts_address_setting'));
    } finally {
      toggleOrderReceiptsLoader(false);
    }
  };

  const handleOrderMsgClick = function(event) {
    event.preventDefault();
    toggleOrderMsgModal(!showOrderMsgModal);
  };

  const handleMinOrderClick = function(event) {
    event.preventDefault();
    toggleMinOrderAmtModal(!showMinOrderAmtModal);
  };

  const closeTextModal = function() {
    toggleOrderMsgModal(false);
  };

  const handleCancel = function(event) {
    event.preventDefault();
    closeTextModal();
  };

  const submitText = async function(event) {
    event.preventDefault();
    closeTextModal();
    toggleMsgLoader(true);
    try {
      await setCompanyCheckoutMessageSettings(textAreaVal);
    } catch (error) {
      toastr.error(t('something_went_wrong_while_updating_the_checkout_message'));
    }
    toggleMsgLoader(false);
  };

  const renderTextModal = () => (
    <Modal
      open={true}
      center={true}
      onClose={closeTextModal}
      showCloseIcon={false}
      styles={{ modal: modalStyles.current }}
    >
      <h3 className="checkout-section-modal checkout-text--title">
        {t('order_confirmation_message')}
      </h3>
      <p className="checkout-section-modal checkout-text-description">
        {t('show_a_message_to_your_customers')}
      </p>
      <textarea
        className="checkout-section-modal checkout-text-area"
        value={textAreaVal}
        placeholder={t('thank_you_for_placing_an_order_with_us')}
        onChange={event => setTextAreaVal(event.target.value)}
      />
      <div className="checkout-section-modal button-container">
        <button type="button" className="button-plain" onClick={handleCancel}>
          {t('cancel')}
        </button>
        <button type="submit" className="button-plain button-submit" onClick={submitText}>
          {t('set_message')}
        </button>
      </div>
    </Modal>
  );

  const setMinOrderData = async () => {
    toggleMinOrderAmtModal(!showMinOrderAmtModal);
    const minOrderVal = Number(minAmtText);
    if (minOrderVal === Number(minOrderAmount)) {
      return;
    }

    toggleMinOrderLoader(true);
    try {
      if (minOrderVal === 0) {
        await disableMinOrderAmount();
      } else {
        await setMinimumOrderAmount(minOrderVal);
      }
      toastr.success(t('minimum_order_amount_updated_successfully'));
    } catch (err) {
      toastr.error(t('something_went_wrong_please_try_again'));
    }
    toggleMinOrderLoader(false);
  };

  const closeDialog = () => {
    toggleMinOrderAmtModal(!showMinOrderAmtModal);
  };

  const changeMinOrder = useCallback(val => {
    setMinAmtText(val);
  }, []);

  const renderMinOrderModal = () => {
    return (
      <DialogBox
        open={true}
        primaryBtnText={t('set_amount')}
        onSuccess={setMinOrderData}
        onClose={closeDialog}
        dark
        title={t('minimum_order_amount')}
        dialogAsForm={{ onSubmit: setMinOrderData }}
        actionsConfig={[
          {
            content: t('cancel'),
            clickHandler: closeDialog,
            role: 'SECONDARY'
          },
          {
            content: t('set_amount'),
            clickHandler: setMinOrderData,
            role: 'PRIMARY'
          }
        ]}
        id="moa-edit-dialog-box"
      >
        <TextInput
          placeholder="999.99"
          label={t('minimum_amount')}
          name="minAmount"
          onChange={changeMinOrder}
          pattern="^\d{0,}(?:\.\d{0,2})?$"
          value={minAmtText}
          startAdornment={
            <InputAdornment>
              <p className="currency">{currency}</p>
            </InputAdornment>
          }
        />
      </DialogBox>
    );
  };

  const renderCheckoutFormsSettings = () => {
    const canUse = canUseFeature(FEATURE_LIST.CHECKOUT_FORM_SETTINGS.id);
    if (!canUse) {
      return;
    }
    return (
      <SettingsDataRow
        onSectionClick={handleOpenCustomCheckoutForm}
        icon={<EditIcon />}
        title={t('custom_checkout_form')}
      >
        <span>{t('collect_custom_information')}</span>
      </SettingsDataRow>
    );
  };

  let catalogueComponent;
  if (typeof captureAddress === 'boolean' && typeof checkoutMsg === 'string') {
    //Added to ensure that the required prop of the switch component is satisfied
    const dummyChangeFunction = () => {};
    catalogueComponent = (
      <Fragment>
        {renderCheckoutFormsSettings()}
        <SettingsDataRow
          onSectionClick={handleCaptureAddressClick}
          icon={
            <ReactSwitch
              checked={captureAddress}
              onChange={dummyChangeFunction}
              onColor="#515863"
              offColor="#515863"
              onHandleColor="#4DA47A"
              offHandleColor="#929faf"
              handleDiameter={25}
              uncheckedIcon={false}
              checkedIcon={false}
              height={17}
              width={40}
            />
          }
          loader={showAddressLoader}
          title={t('capture_shipping_address')}
        >
          <span>{t('require_customers_to_enter_their_shipping_address')}</span>
        </SettingsDataRow>
        <SettingsDataRow
          icon={<EditIcon />}
          loader={showMsgLoader}
          title={t('order_confirmation_message')}
          onSectionClick={handleOrderMsgClick}
        >
          <span>{checkoutMsg ? checkoutMsg : t('show_a_message_to_your_customers')}</span>
        </SettingsDataRow>
        <SettingsDataRow
          icon={<EditIcon />}
          loader={showMinOrderLoader}
          title={t('minimum_order_amount')}
          onSectionClick={handleMinOrderClick}
        >
          <span>
            {minOrderAmount > 0
              ? t('minimum_order_amount_is_set_to', {
                  currency: currency,
                  minOrderAmount: minOrderAmount
                })
              : t('no_minimum_order_amount_set')}
          </span>
        </SettingsDataRow>
        <CompanyMoq />
        <CompanyB2B selectedBulkOrderingViewSetting={bulkOrderingView} />
        <SettingsDataRow
          onSectionClick={handleOrderReceiptsClick}
          icon={
            <ReactSwitch
              checked={orderReceipts}
              onChange={dummyChangeFunction}
              onColor="#515863"
              offColor="#515863"
              onHandleColor="#4DA47A"
              offHandleColor="#929faf"
              handleDiameter={25}
              uncheckedIcon={false}
              checkedIcon={false}
              height={17}
              width={40}
            />
          }
          loader={showOrderReceiptsLoader}
          title={t('order_receipts_via_whatsapp')}
        >
          <span>{t('allow_customers_to_receive_order_receipts')}</span>
        </SettingsDataRow>
        {showMinOrderAmtModal && renderMinOrderModal()}
        {showOrderMsgModal && renderTextModal()}
      </Fragment>
    );
  } else {
    catalogueComponent = (
      <div className="checkout-section-loader">
        <Loader size="large" />
      </div>
    );
  }

  return <div className="checkout-section">{catalogueComponent}</div>;
};
