import React, { useEffect, useState, useCallback } from 'react';
import Modal from 'react-responsive-modal';
import Loader from 'qs-components/Common/Loader';
import CatalogueLib from 'qs-services/DataManager/Catalogues';
import { openWhatsApp, openMail, openFacebook } from 'qs-services/Helpers/index';
import {
  Dialog,
  DialogTitle,
  TextField,
  Button,
  DialogContent,
  DialogActions
} from '@material-ui/core';
import toastr from 'toastr';

import './styles.scss';
import WhatsappIcon from 'qs-assets/Media/whatsapp.png';
import FacebookIcon from 'qs-assets/Media/facebook-bg.png';
import MailIcon from 'qs-assets/Media/mail.png';
import BrochureImage from 'qs-assets/Media/brochure.png';
import * as Sentry from '@sentry/browser';
import { getI18N } from 'qs-services/i18N';

export default ({ isVisible, catalogueIds, onModalClose }) => {
  const [link, setLink] = useState(null);
  const [isCopied, setIsCopied] = useState(false);
  const [showModal, setModal] = useState(false);
  const [brochureName, setBrochureName] = useState('');
  const [downloadingBrochure, setDownloading] = useState(false);
  const [disableCreate, setDisabled] = useState(true);

  const { t } = getI18N();

  useEffect(() => {
    const generateLink = async catalogueIds => {
      try {
        const resp = await CatalogueLib.createCataloguesLink(catalogueIds);
        const { link } = resp;
        setLink(link);
      } catch (err) {
        onModalClose();
        Sentry.captureException(err);
      }
    };
    generateLink(catalogueIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  const toggleModal = useCallback(() => {
    setModal(prevState => !prevState);
  }, []);

  const modalClose = useCallback(() => {
    onModalClose();
  }, [onModalClose]);

  const shareCatalogue = useCallback(
    shareType => {
      switch (shareType) {
        case 'whatsapp':
          openWhatsApp({ text: link });
          break;
        case 'email':
          openMail({ text: link });
          break;
        case 'facebook':
          openFacebook({ text: link });
          break;
        case 'brochure':
          toggleModal();
          break;
        default:
          break;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [link]
  );

  const shareCatalogueEmail = useCallback(
    e => {
      e.stopPropagation();
      shareCatalogue('email');
    },
    [shareCatalogue]
  );

  const shareCatalogueFacebook = useCallback(
    e => {
      e.stopPropagation();
      shareCatalogue('facebook');
    },
    [shareCatalogue]
  );

  const shareCatalogueBrochure = useCallback(
    e => {
      e.stopPropagation();
      shareCatalogue('brochure');
    },
    [shareCatalogue]
  );

  const downloadBrochure = async () => {
    setDownloading(true);
    toggleModal();
    try {
      const brochureData = { brochureName, catalogueIds };
      await CatalogueLib.createBrochureLink(brochureData);
      setDownloading(false);
      onModalClose();
    } catch (err) {
      setDownloading(false);
      toastr.error(t('something_went_wrong_please_try_again'));
    }
  };

  const shareCatalogueWhatsapp = useCallback(
    e => {
      e.stopPropagation();
      shareCatalogue('whatsapp');
    },
    [shareCatalogue]
  );

  const handleTapToCopy = useCallback(
    e => {
      e.stopPropagation();
      const el = document.createElement('textarea');
      el.value = link;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      setIsCopied(true);
    },
    [link]
  );

  const handleNameEdit = useCallback(
    ({ target: { value } }) => {
      setBrochureName(value);
      if (value.trim().length > 0) {
        setDisabled(false);
        return;
      }
      setDisabled(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [brochureName, disableCreate]
  );

  if (showModal) {
    return (
      <Dialog
        onClose={toggleModal}
        className="brochureDlg"
        aria-labelledby="brochure-name"
        open={showModal}
      >
        <DialogTitle id="brochure-name">{t('brochure_name')}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="brochure-name-txt"
            label="Enter brochure name"
            fullWidth
            onChange={handleNameEdit}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={downloadBrochure} disabled={disableCreate} color="primary">
            {t('create_brochure')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Modal
      open={isVisible}
      onClose={modalClose}
      center
      styles={{
        modal: { backgroundColor: 'white', borderRadius: 10, padding: '23px 25px 20px 25px' }
      }}
      showCloseIcon={false}
    >
      {link && !downloadingBrochure ? (
        <div>
          <div className="ShareCataloguePopupBody" onClick={handleTapToCopy}>
            <a className="CatalogueLinkText" href={link} target="_blank" rel="noopener noreferrer">
              {link}
            </a>
            <div className="TapToCopyBtn">{isCopied ? t('link_copied') : t('click_to_copy')}</div>
          </div>
          <div className="ShareCataloguePopupFooter">
            <div className="shareType" onClick={shareCatalogueWhatsapp}>
              <img src={WhatsappIcon} alt="whatsapp" />
              <span>{t('whatsapp')}</span>
            </div>
            {catalogueIds.length > 1 && (
              <div className="shareType" onClick={shareCatalogueBrochure}>
                <img src={BrochureImage} alt="brochure" />
                <span>{t('brochure')}</span>
              </div>
            )}
            <div className="shareType" onClick={shareCatalogueFacebook}>
              <img src={FacebookIcon} alt="facebook" />
              <span>{t('facebook')}</span>
            </div>
            <div className="shareType" onClick={shareCatalogueEmail}>
              <img src={MailIcon} alt="email" />
              <span>{t('email')}</span>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <Loader size="large" color="black" />
        </div>
      )}
    </Modal>
  );
};
