import React, { useEffect, useState, useRef } from 'react';

import './index.scss';
import { ReactComponent as SearchIcon } from 'qs-assets/Media/magnifyingGlass.svg';
import { ReactComponent as CloseIcon } from 'qs-assets/Media/close.svg';
import { getCatalogueCDNAssetPath } from '../../../Services/Helpers';

export default ({
  onClose,
  isVisible,
  onSelect,
  pickerList,
  placeHolderText,
  listEndComponent = null,
  selectedPickerValue,
  showIcon
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [inputPlaceHolderText] = useState(placeHolderText);
  const [commonListEndComponent] = useState(listEndComponent);
  const pickerListRef = useRef(null);
  const pickerItemElement = useRef(null);

  useEffect(() => {
    const escFunction = event => {
      if (event && event.keyCode === 27) {
        onClose();
      }
    };
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [onClose]);

  useEffect(() => {
    if (pickerListRef && pickerListRef.current && pickerItemElement && pickerItemElement.current) {
      pickerItemElement.current.scrollIntoView();
    }
  }, [pickerListRef, pickerItemElement, isVisible]);

  const onSearchChange = event => {
    event.preventDefault();
    setSearchTerm(event.target.value);
  };

  const onPickerComponentSelect = pickerData => {
    onSelect(pickerData);
    onClose();
  };

  const getFilteredPickerList = () => {
    if (!searchTerm) {
      return pickerList;
    }

    return pickerList.filter(
      pickerComponent =>
        pickerComponent.label.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
    );
  };

  const renderPicekerItems = () => {
    const pickerComponentList = getFilteredPickerList();
    return pickerComponentList.map((pickerComponent, index) => (
      <div
        key={pickerComponent.label + pickerComponent.symbol}
        className={`pickerItem pickerItem${pickerComponent.label.replace(/ /g, '')} ${
          selectedPickerValue.label === pickerComponent.label ? 'selected' : ''
        }`}
        onClick={() => onPickerComponentSelect(pickerComponent)}
        ref={selectedPickerValue.label === pickerComponent.label ? pickerItemElement : null}
      >
        <div className="name">
          {showIcon ? (
            <img
              className="icon"
              alt=""
              src={getCatalogueCDNAssetPath(
                `assets/flags/${pickerComponent.countryCode &&
                  pickerComponent.countryCode.toLowerCase()}.png`
              )}
            />
          ) : null}
          <span>{pickerComponent.label}</span>
        </div>
        {pickerComponent.symbol ? (
          <div className="symbol">
            <span>{pickerComponent.symbol}</span>
            {pickerComponent.currencyCode && (
              <span className="currencyCode">{pickerComponent.currencyCode}</span>
            )}
          </div>
        ) : null}
      </div>
    ));
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className="commonPickerContainer">
      <div className="commonPicker">
        <div className="searchBar">
          <div className="searchIcon">
            <SearchIcon size={20} color="#000" />
          </div>

          <div className="inputContainer">
            <input
              type="text"
              autoFocus
              placeholder={inputPlaceHolderText}
              autoComplete="new-password"
              value={searchTerm || ''}
              onChange={onSearchChange}
            />
          </div>

          <div className="closeIcon" onClick={() => onClose()}>
            <CloseIcon />
          </div>
        </div>

        <div ref={pickerListRef} className="pickerList">
          {renderPicekerItems()}
          {commonListEndComponent}
        </div>
      </div>
    </div>
  );
};
