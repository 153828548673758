import React, { Fragment, useState, useEffect, useRef } from 'react';
import { ReactComponent as DeleteIcon } from 'qs-assets/Media/trash.svg';
//import { ReactComponent as DownloadIcon } from 'qs-assets/Media/download.svg';
import { ReactComponent as ErrorIcon } from 'qs-assets/Media/filled-error.svg';
import { deleteErroredProductsCatalogue } from 'qs-product-operations/DeleteAllErrored/ErroredProducts';
import CatalogueLib from 'qs-data-manager/Catalogues';
import toastr from 'toastr';
import { toggleGlobalLoader } from 'qs-helpers';
import Modal from 'react-responsive-modal';
import './styles.scss';
import { getI18N } from 'qs-services/i18N';

export default ({ catalogueId }) => {
  const [catalogueMeta, setCatalogueMeta] = useState(
    CatalogueLib.getCatalogueMetaFromCache(catalogueId)
  );
  const [showDeleteConfirmation, toggleDeleteConfirmation] = useState(false);
  const [disableDeleteButton, toggleDeleteButton] = useState(false);

  const { t } = getI18N();

  const modalStyles = useRef({
    color: 'black',
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '500px',
    padding: '8px',
    paddingLeft: '24px'
  });

  useEffect(() => {
    toggleDeleteButton(false);
    // Catalogue Id changed, update the catalogue meta
    setCatalogueMeta(CatalogueLib.getCatalogueMetaFromCache(catalogueId));

    //Listen for the changes in the current catalogue. Only listen for the success and updates
    // because in other states no visual updates are needed.If an error is encountered then
    // remove the bar
    const metaListener = (err, { status, data } = {}) => {
      if (err) {
        //Reset the values in case of error
        setCatalogueMeta({});
        return;
      } else {
        let updates;
        switch (status) {
          case CatalogueLib.OPERATION_STATUS.SUCCESS:
            updates = data || {};
            break;
          case CatalogueLib.OPERATION_STATUS.UPDATE:
            updates = data || {};
            break;
          default:
            updates = null;
            break;
        }
        if (updates !== null) {
          toggleDeleteButton(false);
          setCatalogueMeta(updates);
        }
      }
    };

    CatalogueLib.attachCatalogueMetaListener(metaListener, catalogueId);

    return () => CatalogueLib.removeAttachCatalogueMetaListener(metaListener, catalogueId);
  }, [catalogueId]);

  /*const downloadErrorFile = event => {
    event.preventDefault();
  };*/

  const handleDeleteConfirmation = async event => {
    event.preventDefault();
    toggleDeleteConfirmation(false);
    const loaderKey = `deleteAllErroredCatalogue_${catalogueId}${Date.now()}`;
    toggleGlobalLoader(loaderKey, true);
    toggleDeleteButton(true);
    try {
      await deleteErroredProductsCatalogue({ catalogueId });
    } catch (deleteErroredError) {
      toastr.error(t('something_went_wrong_while_deleting_the_errored_products_in_the_catalogue'));
    } finally {
      toggleGlobalLoader(loaderKey, false);
    }
  };

  const deleteAllFailed = event => {
    event.preventDefault();
    toggleDeleteConfirmation(true);
  };

  const closeDeleteModal = event => {
    event.preventDefault();
    toggleDeleteConfirmation(false);
  };

  const getDeleteAllOption = () => {
    let disableClass = '',
      clickHandler = deleteAllFailed;
    if (disableDeleteButton) {
      clickHandler = null;
      disableClass = 'deleteDisabled';
    }

    return (
      <div className={`deleteContainer ${disableClass}`} onClick={clickHandler}>
        <DeleteIcon className="deleteIcon" />
        <span className="ellipsis">DELETE ALL</span>
      </div>
    );
  };

  return catalogueMeta.erroredProductCount ? (
    <Fragment>
      <div className="productsError errorCountContainer">
        <ErrorIcon className="errorIcon" />
        <span className="ellipsis">{catalogueMeta.erroredProductCount} pictures errored </span>
      </div>
      <div className="productsError errorActionContainer">
        {/* TODO The below component will be made functional later
        <div className="downloadContainer" onClick={downloadErrorFile}>
          <DownloadIcon className="downloadIcon" />
          <span className="ellipsis">DOWNLOAD ERROR FILE</span>
        </div>*/}
        {getDeleteAllOption()}
      </div>
      {showDeleteConfirmation && (
        <Modal
          open={true}
          center={true}
          closeOnOverlayClick={false}
          closeOnEsc={false}
          showCloseIcon={false}
          styles={{ modal: modalStyles.current }}
        >
          <div className="productsError confirmationTitle">Delete errored images</div>
          <div className="productsError confirmationDescription">
            Do you want to delete {catalogueMeta.erroredProductCount} errored images? If errored
            image is the only image in the product, then the product will also be deleted.
          </div>
          <div className="productsError buttonContainer">
            <button
              type="button"
              className="buttonPlain buttonDelete"
              onClick={handleDeleteConfirmation}
            >
              DELETE ALL
            </button>
            <button type="button" className="buttonPlain" onClick={closeDeleteModal}>
              CANCEL
            </button>
          </div>
        </Modal>
      )}
    </Fragment>
  ) : null;
};
