import React, { useReducer, useEffect, useState } from 'react';
import CacheListenerCallback from 'qs-helpers/CacheListenerCallback';
import Loader from '../../../../../Common/Loader';
import {
  updateTrackQuantity,
  getInventoryForVariantFromCache,
  attachVariantOperationListener,
  removeVariantOperationListener
} from 'qs-data-manager/Variants/VariantInventory';
import toastr from 'toastr';
import ErrorIcon from '../../../../../Common/ErrorIcon';
import NumberInput from '../../../../../Common/NumberInput';
import {
  variantInventoryInit,
  variantsInventoryReducer,
  SET_INVENTORY_DATA,
  SET_STOCK_COUNT,
  SET_TRACK_QTY
} from './reducer';
import { onInventoryChange, INVENTORY_META_IDS } from 'qs-helpers/Inventory';
import { VARIANT_OPERATION_KEYS, FROM_VARIANTS_TRACK } from 'qs-helpers/Variants/constants';
import { getTotalOperationRunningCount } from 'qs-helpers/Variants/OperationTracker';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

export default ({ variantId, variantInventoryStatus }) => {
  const [inventoryData, setInventoryData] = useReducer(
    variantsInventoryReducer,
    getInventoryForVariantFromCache({ variantId }),
    variantInventoryInit
  );

  const [showTrackLoader, toggleTrackLoader] = useState(false);
  const { t } = getI18N();

  useEffect(() => {
    const { loading, refreshing, error } = variantInventoryStatus;
    //Don't process the update as long as the data has not arrived
    if (error || loading || refreshing) {
      return;
    }

    setInventoryData({
      type: SET_INVENTORY_DATA,
      data: getInventoryForVariantFromCache({ variantId })
    });
  }, [variantId, variantInventoryStatus]);

  useEffect(() => {
    const listener = (error, payload) => {
      const dataFromListener = CacheListenerCallback(error, payload);
      const { err, loading, refreshing, data } = dataFromListener;
      if (err || loading || refreshing || !data) {
        return;
      }

      const totalOperationCount = getTotalOperationRunningCount({
        data,
        operationKeys: [VARIANT_OPERATION_KEYS.STOCK_CHANGE, VARIANT_OPERATION_KEYS.TRACK_INVENTORY]
      });

      if (totalOperationCount > 0) {
        toggleTrackLoader(true);
      } else {
        //Loader must be rmeoved, fetch the update data from the cache
        setInventoryData({
          type: SET_INVENTORY_DATA,
          data: getInventoryForVariantFromCache({ variantId })
        });
        toggleTrackLoader(false);
      }
    };

    attachVariantOperationListener({ listener, variantId });
    return () => removeVariantOperationListener({ listener, variantId });
  }, [variantId]);

  const updateStockCountInRemoteAndCache = async newStockCount => {
    onInventoryChange(
      INVENTORY_META_IDS.STOCK_COUNT_CHANGE,
      {
        stockCount: newStockCount,
        productId: variantId
      },
      { showLoader: false, handleError: false, from: FROM_VARIANTS_TRACK }
    );
    const count = Number(newStockCount);
    setInventoryData({ type: SET_STOCK_COUNT, count });
  };

  const changeInventory = (event, increaseBy) => {
    event.preventDefault();
    const { count } = inventoryData;
    const newStockCount = Number(count) + increaseBy;
    updateStockCountInRemoteAndCache(newStockCount);
  };

  const changeStockAvailability = stockNum => {
    updateStockCountInRemoteAndCache(stockNum);
  };

  const handleTrackInventoryChange = async ({ setQuantity, trackInventory }) => {
    toggleTrackLoader(true);
    try {
      await updateTrackQuantity({ variantId, trackInventory, setQuantity });
      setInventoryData({ type: SET_TRACK_QTY, trackInventory });
      if (setQuantity) {
        setInventoryData({ type: SET_STOCK_COUNT, count: 1 });
      }
    } catch (updateTrackError) {
      toastr.error(t('something_went_wrong_while_updating_the_track_value'));
    } finally {
      toggleTrackLoader(false);
    }
  };

  const onTrackInventoryChange = event => {
    event.preventDefault();
    handleTrackInventoryChange({ setQuantity: false, trackInventory: true });
  };

  const showTrackInventory = event => {
    event.preventDefault();
    handleTrackInventoryChange({ setQuantity: true, trackInventory: false });
  };

  const getTrackButton = () => {
    return (
      <div onClick={onTrackInventoryChange} className="variantTrackQuantity variantTrackButton">
        {t('track_quantity')}
      </div>
    );
  };

  const showTrackQuantity = () => {
    if (!inventoryData) {
      return false;
    }

    const { trackInventory, count } = inventoryData;
    if (trackInventory && count > 0) {
      return true;
    }
    return false;
  };

  const { error, loading, refreshing } = variantInventoryStatus;
  if (error) {
    return (
      <div className="variantTrackQuantity">
        <div className="errorContainer">
          <ErrorIcon width={'20px'} height={'20px'} />
        </div>
      </div>
    );
  }

  const { count, isSet, setQuantity } = inventoryData;

  //show a loader if the the data is loading or a loader must replace the button
  // or if the count is not a number which indicates that there is not data present
  // in cache
  if (loading || showTrackLoader || typeof count !== 'number') {
    return (
      <div className={' variantTrackQuantity trackLoaderContainer'}>
        <Loader size={'extraSmall'} />
      </div>
    );
  }

  if (count === 0) {
    return (
      <div className="variantTrackQuantity outOfStock" onClick={showTrackInventory}>
        <span>{t('out_of_stock')}</span>
      </div>
    );
  }

  if (showTrackQuantity()) {
    return (
      <div className="variantTrackQuantity">
        <div className="insertQunatityContainer">
          <div
            onClick={refreshing ? null : event => changeInventory(event, -1)}
            className="minusContainer noselect"
          >
            <span>-</span>
          </div>
          <div className="quantityInputContainer">
            <NumberInput
              value={count}
              integer={true}
              inputClassName="quantityContainer"
              onChange={changeStockAvailability}
              disabled={refreshing}
            />
          </div>
          <div
            onClick={refreshing ? null : event => changeInventory(event, 1)}
            className="plusContainer noselect"
          >
            <span>+</span>
          </div>
        </div>
        {isSet && (
          <div className="totalQuantityContainer">
            {t('total_quantity_count_and_set_quantity', { quantity: count * setQuantity })}
          </div>
        )}
      </div>
    );
  }

  return getTrackButton();
};
