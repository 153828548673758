import React, { useState, useEffect } from 'react';
import { selectedLabels } from 'qs-data-manager/Selected';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

export default () => {
  const [labelsCount, setLabelsCount] = useState(false);
  const { t } = getI18N();

  useEffect(() => {
    const onLabelToggle = () => {
      const numberOfLabelsSelected = selectedLabels.getCount();
      setLabelsCount(numberOfLabelsSelected);
    };
    selectedLabels.addListener(onLabelToggle);
    return () => {
      selectedLabels.removeListener(onLabelToggle);
    };
  }, []);

  const clearSelectedLabels = event => {
    event.stopPropagation();
    selectedLabels.removeAll();
  };

  if (typeof labelsCount !== 'number' || labelsCount === 0) {
    return null;
  }

  return (
    <div className={'selectedLabelsSection'} onClick={clearSelectedLabels}>
      <div className={'selectedLabelsCount'}>
        {labelsCount > 1
          ? t('labels_selected', { labelsCount })
          : t('label_selected', { labelsCount })}
      </div>
      <div className={'clearLabelsButton'}>{t('clear')}</div>
    </div>
  );
};
