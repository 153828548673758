import { connector } from './ApiAndCacheConnector';
import CacheRequest from './CacheRequest';
import Api from 'qs-services/Api';
import CategoryMapper from 'qs-helpers/CategoryMapper';
import { currencies } from 'qs-helpers/CurrencyMapper';
import { getUserMetaFromCache } from './User';
import * as Sentry from '@sentry/browser';
import { CUSTOM_SHARE_TEXT_APPEND } from 'qs-helpers/CompanySettings/constants';
import { reportError } from 'qs-helpers/ErrorReporting';

// TODO: move this to more common location with a generic name like
const COMPANY_LOCAL_STORAGE_KEYS = {
  COMPANY_META: 'COMPANY_META',
  USER_META: 'USER_META',
  AUTH_UUID: 'AUTH_UUID',
  APP_LANGUAGE: 'APP_LANGUAGE'
};

// Company settings
const attachCompanySettingsListener = listener => {
  const key = `${connector.COMPANY_SETTINGS.cacheKey}`;
  CacheRequest.attachListener(key, listener);
};

const removeCompanySettingsListener = listener => {
  const key = `${connector.COMPANY_SETTINGS.cacheKey}`;
  CacheRequest.removeListener(key, listener);
};

const getCompanySettings = () => {
  const key = `${connector.COMPANY_SETTINGS.cacheKey}`;
  const apiName = connector.COMPANY_SETTINGS.apiFunction;
  return CacheRequest.makeRequest(key, apiName, {
    params: [],
    options: {
      shouldNotStoreInNative: true
    }
  });
};

const getCompanySettingsFromCache = () => {
  const key = `${connector.COMPANY_SETTINGS.cacheKey}`;
  const { settings } = CacheRequest.getCacheForKey(key) || {};
  return settings;
};

const setCompanySettingsInCache = ({ data, error = null }) => {
  const key = connector.COMPANY_SETTINGS.cacheKey;
  CacheRequest.setCacheForKey(key, data, { error });
};

const updateCompanySettingsInCache = ({ updateData }) => {
  const cachedSettings = getCompanySettingsFromCache();
  setCompanySettingsInCache({
    data: {
      settings: {
        ...cachedSettings,
        ...updateData
      }
    }
  });
};

const getCompanyLevelGoldRateAndProductTypeFromCache = () => {
  const key = `${connector.COMPANY_GOLD_RATES_AND_PRODUCT_TYPE.cacheKey}`;
  return CacheRequest.getCacheForKey(key) || '';
};

const setCompanyLevelGoldRateAndProductTypeInCache = ({ data, error = null }) => {
  const key = `${connector.COMPANY_GOLD_RATES_AND_PRODUCT_TYPE.cacheKey}`;
  CacheRequest.setCacheForKey(key, data, { error });
};

// Catalogue share text listener
const attachCatalogueShareTextListener = listener => {
  const key = `${connector.CATALOGUE_SHARE_TEXT.cacheKey}`;
  CacheRequest.attachListener(key, listener);
};

const removeCatalogueShareTextListener = listener => {
  const key = `${connector.CATALOGUE_SHARE_TEXT.cacheKey}`;
  CacheRequest.removeListener(key, listener);
};

const getCatalogueShareText = () => {
  const key = `${connector.CATALOGUE_SHARE_TEXT.cacheKey}`;
  const apiName = connector.CATALOGUE_SHARE_TEXT.apiFunction;
  return CacheRequest.makeRequest(key, apiName, {
    params: [],
    options: {
      shouldNotStoreInNative: true
    }
  });
};

const getCatalogueShareTextFromCache = () => {
  const key = `${connector.CATALOGUE_SHARE_TEXT.cacheKey}`;
  return CacheRequest.getCacheForKey(key) || undefined;
};

const setCatalogueShareTextInCache = shareTemplate => {
  const key = `${connector.CATALOGUE_SHARE_TEXT.cacheKey}`;
  const oldCache = CacheRequest.getCacheForKey(key) || {};
  CacheRequest.setCacheForKey(key, {
    ...oldCache,
    shareTemplate
  });
};

const updateCompanyShareTextApi = async updateData => {
  if (typeof updateData !== 'string') {
    return Promise.resolve();
  }

  updateData = `${updateData}${CUSTOM_SHARE_TEXT_APPEND}`;
  try {
    await Api.updateCompanyShareText({ shareTemplate: updateData }, getCompanyIdFromCache());
    setCatalogueShareTextInCache(updateData);
  } catch (error) {
    reportError(error);
  }
};

// Company meta
const attachCompanyMetaListener = listener => {
  const key = connector.COMPANY_META.cacheKey;
  CacheRequest.attachListener(key, listener);
};

const removeCompanyMetaListener = listener => {
  const key = connector.COMPANY_META.cacheKey;
  CacheRequest.removeListener(key, listener);
};

/*
 * @description Sets the company object in RAM and localstorage and notifies all listeners of this change
 * @params {Object} data - Entire company object
 * @params {Object} data.address - address of the company
 * @params {Object} data.catalogues - all catalogue ids of the company
 * @params {Object} data.categories - predefined category the company belongs to
 * @params {string} data.companyRepUserId - user id of the company
 * @params {string} data.currencyCode - currency code of that company
 * @params {string} data.logoExists - Company has logo or not
 * @params {string} data.logoVersion - Version of the logo
 * @params {string} data.name - Name of the company
 * @params {Object} data.products - All existing productIds of the company
 * @params {Object} data.showcase - All existing showcaseIds of the company
 */

const setCompanyMetaInCache = ({ data, error = null } = {}) => {
  const modifiedMetaData = { ...(data || {}) };

  modifiedMetaData.productsCount = Object.keys(modifiedMetaData.products || {}).length;
  modifiedMetaData.cataloguesCount = Object.keys(modifiedMetaData.catalogues || {}).length;
  modifiedMetaData.showcasesCount = Object.keys(modifiedMetaData.showcases || {}).length;
  modifiedMetaData.groupCatalogueShowcasesCount = Object.keys(
    modifiedMetaData['group-catalogue-showcases'] || {}
  ).length;

  delete modifiedMetaData.products;
  delete modifiedMetaData.catalogues;
  delete modifiedMetaData.showcases;
  delete modifiedMetaData['group-catalogue-showcases'];

  const key = connector.COMPANY_META.cacheKey;
  CacheRequest.setCacheForKey(key, modifiedMetaData, { error });
  const localStorageData = JSON.stringify(modifiedMetaData);
  localStorage.setItem(COMPANY_LOCAL_STORAGE_KEYS.COMPANY_META, localStorageData);
};

const getCompanyMetaFromCache = () => CacheRequest.getCacheForKey(connector.COMPANY_META.cacheKey);

const rehydrateCompanyMeta = data => {
  const companyMeta = JSON.parse(data);
  const key = connector.COMPANY_META.cacheKey;
  CacheRequest.setCacheForKey(key, companyMeta);
};

const updateCompanyMeta = value => {
  Api.updateCompanyMetaData(value, getCompanyIdFromCache()).catch(error =>
    setCompanyMetaInCache({ error })
  );
};

const updateCompanyAddress = addressObj =>
  //Default value can be null but undefined is not allowed
  Api.updateCompanyAddress(
    {
      addressLineOne: addressObj.addressLineOne || null,
      addressLineTwo: addressObj.addressLineTwo || null,
      city: addressObj.city || null,
      country: addressObj.country || null,
      pincode: addressObj.pincode || null,
      state: addressObj.state || null
    },
    getCompanyIdFromCache()
  );

const updateCompanyNameAndCurrency = (companyName, currency) => {
  if (typeof companyName !== 'string' || typeof currency !== 'string') {
    return;
  }

  //Default value can be null but undefined is not allowed
  updateCompanyMeta({ name: companyName, currencyCode: currency });
};

const rehydrateUserMeta = data => {
  const userMeta = JSON.parse(data);
  const key = connector.USER_META.cacheKey;
  CacheRequest.setCacheForKey(key, userMeta);
};

const changeCompanyOutOfStockVisibility = async ({ value }) => {
  try {
    await Api.changeCompanyOutOfStockVisibility({
      value
    });
    updateCompanySettingsInCache({
      updateData: {
        showOutOfStockProduct: value
      }
    });
  } catch (updateError) {
    reportError(updateError);
    throw updateError;
  }
};

const changeCompanyAllowOrderOnOutOfStock = async ({ value }) => {
  try {
    await Api.changeCompanyAllowOrderOnOutOfStock({
      value
    });
    updateCompanySettingsInCache({
      updateData: {
        allowOrdersOnOutOfStock: value
      }
    });
  } catch (updateError) {
    reportError(updateError);
    throw updateError;
  }
};

const changeCompanyTrackInventory = async ({ value }) => {
  try {
    await Api.changeCompanyTrackInventory({
      value
    });
    updateCompanySettingsInCache({
      updateData: {
        trackInventory: value
      }
    });
  } catch (updateError) {
    reportError(updateError);
    throw updateError;
  }
};

const changeCompanyAutoReduceInventory = async ({ value }) => {
  try {
    await Api.changeCompanyAutoReduceInventory({
      value
    });
    updateCompanySettingsInCache({
      updateData: {
        autoReduceInventory: value
      }
    });
  } catch (updateError) {
    reportError(updateError);
    throw updateError;
  }
};

const getCompanyCurrencyCode = () => {
  const key = connector.COMPANY_META.cacheKey;
  const cache = CacheRequest.getCacheForKey(key);
  if (!cache) {
    return null;
  }
  return cache.currencyCode;
};

const getCompanyMetaForUserDetails = () => {
  const key = connector.COMPANY_META.cacheKey;
  const cache = CacheRequest.getCacheForKey(key);
  if (!cache) {
    return {
      name: '',
      currency: '',
      category: ''
    };
  }
  const categoryCode = Object.keys(cache.categories || {})[0];
  const category = CategoryMapper[categoryCode];

  return cache
    ? {
        name: cache.name,
        currency: cache.currencyCode,
        category
      }
    : {};
};

const getCompanyIdFromCache = () => {
  const key = connector.COMPANY_META.cacheKey;
  const cache = CacheRequest.getCacheForKey(key);

  if (!cache) {
    const parsedCompanyObject = JSON.parse(
      localStorage.getItem(COMPANY_LOCAL_STORAGE_KEYS.COMPANY_META)
    );
    return parsedCompanyObject && parsedCompanyObject.id ? parsedCompanyObject.id : null;
  }

  return cache.id || null;
};

const getCompanyCurrencySymbol = () => {
  const key = connector.COMPANY_META.cacheKey;
  const cache = CacheRequest.getCacheForKey(key);
  let currencyCode = null;
  if (cache && cache.currencyCode) {
    currencyCode = cache.currencyCode;
  }

  if (!currencyCode) {
    return '';
  }

  return (currencies[currencyCode] && currencies[currencyCode].symbol) || '';
};

export const getCompanyCurrencySymbolOptions = () => {
  const key = connector.COMPANY_META.cacheKey;
  const cache = CacheRequest.getCacheForKey(key);

  // TODO: get company curremcy option from backend
  if (!cache) {
    return {};
  }

  return cache.currencyOptions;
};

const getCompanyMetaForUserBar = () => {
  const key = connector.COMPANY_META.cacheKey;
  const cache = CacheRequest.getCacheForKey(key);

  if (!cache) {
    return {
      name: ''
    };
  }

  return {
    name: cache.name
  };
};

const getAllCatalogueCount = () => {
  const companyMeta = getCompanyMetaFromCache();
  if (companyMeta && typeof companyMeta.cataloguesCount === 'number') {
    return companyMeta.cataloguesCount;
  }

  return null;
};

const getAllProductCount = () => {
  const companyMeta = getCompanyMetaFromCache();
  if (companyMeta && typeof companyMeta.productsCount === 'number') {
    return companyMeta.productsCount;
  }

  return null;
};

const getCompanyDomain = () => {
  const key = connector.COMPANY_META.cacheKey;
  const cache = CacheRequest.getCacheForKey(key);

  if (!cache) {
    return null;
  }

  return cache && cache.companyDomain ? cache.companyDomain : null;
};

const getCompanyStockSetting = async () => {
  const key = `${connector.COMPANY_SETTINGS.cacheKey}`;
  const data = await Api.getCompanySettings();
  CacheRequest.setCacheForKey(key, data);

  return data.settings.showOutOfStockProduct;
};

const getCompanyOrderOnOOS = async () => {
  const key = `${connector.COMPANY_SETTINGS.cacheKey}`;

  const data = await Api.getCompanySettings();
  CacheRequest.setCacheForKey(key, data);

  return data.settings.allowOrdersOnOutOfStock;
};

const getCompanyCategory = () => {
  const key = connector.COMPANY_META.cacheKey;
  const cache = CacheRequest.getCacheForKey(key);
  return cache.categories || {};
};

//Company settings plan
const attachCompanyPlanListener = listener => {
  const key = connector.COMPANY_SETTINGS_PLAN.cacheKey;
  CacheRequest.attachListener(key, listener);
};

const removeCompanyPlanListener = listener => {
  const key = connector.COMPANY_SETTINGS_PLAN.cacheKey;
  CacheRequest.removeListener(key, listener);
};

const getCompanyPlan = () => {
  const key = `${connector.COMPANY_SETTINGS_PLAN.cacheKey}`;
  const apiName = connector.COMPANY_SETTINGS_PLAN.apiFunction;
  return CacheRequest.makeRequest(key, apiName, {
    params: [],
    options: {
      shouldNotStoreInNative: true
    }
  });
};

const getCompanyPlanFromCache = () => {
  const key = `${connector.COMPANY_SETTINGS_PLAN.cacheKey}`;
  return CacheRequest.getCacheForKey(key);
};

//Company request product demo
const attachCompanyProductDemoListener = listener => {
  const key = connector.COMPANY_SETTINGS_PRODUCT_DEMO.cacheKey;
  CacheRequest.attachListener(key, listener);
};

const removeCompanyProductDemoListener = listener => {
  const key = connector.COMPANY_SETTINGS_PRODUCT_DEMO.cacheKey;
  CacheRequest.removeListener(key, listener);
};

const getCompanyProductDemo = () => {
  const key = connector.COMPANY_SETTINGS_PRODUCT_DEMO.cacheKey;
  const apiName = connector.COMPANY_SETTINGS_PRODUCT_DEMO.apiFunction;
  return CacheRequest.makeRequest(key, apiName, {
    params: [],
    options: {
      shouldNotStoreInNative: true
    }
  });
};

const getCompanyProductDemoFromCache = () => {
  const key = connector.COMPANY_SETTINGS_PRODUCT_DEMO.cacheKey;
  return CacheRequest.getCacheForKey(key);
};

const updateCompanySettingsFirebase = async updateData => {
  try {
    await Api.updateCompanySettings(updateData, getCompanyIdFromCache());
    updateCompanySettingsInCache({ updateData });
  } catch (updateError) {
    reportError(updateError);
    throw updateError;
  }
};

const setCatalogueLanguage = languageCode => {
  if (!languageCode) {
    return Promise.resolve();
  }
  return updateCompanySettingsFirebase({ i18n: languageCode });
};

const setAppLanguage = languageCode => {
  if (!languageCode) {
    return Promise.resolve();
  }
  const companyObject = getCompanyMetaFromCache();
  return Api.setAppLanguage(companyObject.companyId, languageCode);
};

const setCompanyPaymentsSettings = paymentSetting => {
  if (typeof paymentSetting !== 'boolean') {
    return Promise.resolve();
  }
  return updateCompanySettingsFirebase({ acceptPaymentsOnOrderConfirmation: paymentSetting });
};

const setCompanyCataloguePdfSettings = pdfSetting => {
  if (typeof pdfSetting !== 'boolean') {
    return Promise.resolve();
  }
  return updateCompanySettingsFirebase({ pdfDownloadFromWeb: pdfSetting });
};

const setCompanyCatalogueProductSettings = productSetting => {
  if (typeof productSetting !== 'boolean') {
    return Promise.resolve();
  }
  return updateCompanySettingsFirebase({ enableProductDownload: productSetting });
};

const setCompanyCatalogueProductCountSettings = async updateData => {
  try {
    await Api.updateCompanyCatalogueProductCountSettings(updateData);
    updateCompanySettingsInCache({
      updateData: {
        [updateData.field]: updateData.value
      }
    });
  } catch (updateError) {
    reportError(updateError);
    throw updateError;
  }
};

const setCompanyCheckoutSettings = checkoutSetting => {
  if (typeof checkoutSetting !== 'boolean') {
    return Promise.resolve();
  }

  return updateCompanySettingsFirebase({ captureShippingAddress: checkoutSetting });
};

const setCompanyCheckoutMessageSettings = checkoutMessageSetting => {
  if (typeof checkoutMessageSetting !== 'string') {
    return Promise.resolve();
  }

  return updateCompanySettingsFirebase({ orderConfirmationCustomMessage: checkoutMessageSetting });
};

const submitFeedback = feedbackData => {
  const userObject = getUserMetaFromCache();
  const companyObject = getCompanyMetaFromCache();

  return Api.submitFeedback({
    feedbackData,
    companyId: companyObject.id,
    userId: userObject.id,
    userName: userObject.name,
    phoneNumber: userObject.phone,
    companyName: companyObject.name
  }).catch(error => {
    Sentry.captureException(error);

    //ensure that the client receives the error
    Promise.reject(error);
  });
};

const setCompanyBulkOrderingSettings = bulkOrderingSetting => {
  try {
    Api.updateBulkOrderingViewSetting(bulkOrderingSetting);
    const existingSettings = getCompanySettingsFromCache();
    if (!existingSettings) {
      return;
    }
    updateCompanySettingsInCache({
      updateData: {
        bulkOrderingView: { [bulkOrderingSetting]: true }
      }
    });
    return Promise.resolve();
  } catch (updateError) {
    reportError(updateError);
    throw updateError;
  }
};

const setCompanyOrderReceiptsSettings = async receiveOrderOnWhatsapp => {
  if (typeof receiveOrderOnWhatsapp !== 'boolean') {
    return Promise.resolve();
  }

  try {
    await Api.updateOrderReceiptsSetting(receiveOrderOnWhatsapp);
    updateCompanySettingsInCache({
      updateData: {
        receiveOrderOnWhatsapp
      }
    });
  } catch (updateError) {
    reportError(updateError);
    throw updateError;
  }
};

const attachCompanyUnitDetailsListner = ({ listener }) => {
  const key = `${connector.COMPANY_UNIT_DETAILS.cacheKey}`;
  CacheRequest.attachListener(key, listener);
};

const removeCompanyUnitDetailsListener = ({ listener }) => {
  const key = `${connector.COMPANY_UNIT_DETAILS.cacheKey}`;
  CacheRequest.removeListener(key, listener);
};

const getCompanyUnitDetailsFromCache = () => {
  const key = `${connector.COMPANY_UNIT_DETAILS.cacheKey}`;
  return CacheRequest.getCacheForKey(key) || undefined;
};

const getCompanyUnitDetailsFromRemote = () => {
  const key = connector.COMPANY_UNIT_DETAILS.cacheKey;
  const apiName = connector.COMPANY_UNIT_DETAILS.apiFunction;
  return CacheRequest.makeRequest(key, apiName, {
    params: [],
    options: {
      shouldNotStoreInNative: true
    }
  });
};

export {
  COMPANY_LOCAL_STORAGE_KEYS,
  // Company settings listener
  attachCompanySettingsListener,
  removeCompanySettingsListener,
  getCompanySettings,
  setCompanySettingsInCache,
  getCompanySettingsFromCache,
  updateCompanySettingsInCache,
  // Company meta lsitener
  attachCompanyMetaListener,
  removeCompanyMetaListener,
  setCompanyMetaInCache,
  updateCompanyAddress,
  updateCompanyNameAndCurrency,
  changeCompanyOutOfStockVisibility,
  changeCompanyAllowOrderOnOutOfStock,
  changeCompanyTrackInventory,
  changeCompanyAutoReduceInventory,
  getCompanyCurrencyCode,
  getCompanyMetaForUserDetails,
  getCompanyIdFromCache,
  getCompanyCurrencySymbol,
  getCompanyMetaForUserBar,
  getAllCatalogueCount,
  getAllProductCount,
  getCompanyDomain,
  rehydrateCompanyMeta,
  rehydrateUserMeta,
  getCompanyStockSetting,
  getCompanyOrderOnOOS,
  getCompanyMetaFromCache,
  getCompanyCategory,
  attachCompanyPlanListener,
  removeCompanyPlanListener,
  getCompanyPlan,
  getCompanyPlanFromCache,
  attachCompanyProductDemoListener,
  removeCompanyProductDemoListener,
  getCompanyProductDemo,
  getCompanyProductDemoFromCache,
  updateCompanySettingsFirebase,
  setCatalogueLanguage,
  setAppLanguage,
  setCompanyPaymentsSettings,
  setCompanyCataloguePdfSettings,
  setCompanyCatalogueProductSettings,
  setCompanyCatalogueProductCountSettings,
  setCompanyCheckoutSettings,
  setCompanyCheckoutMessageSettings,
  submitFeedback,
  setCompanyOrderReceiptsSettings,
  attachCompanyUnitDetailsListner,
  removeCompanyUnitDetailsListener,
  getCompanyUnitDetailsFromCache,
  getCompanyUnitDetailsFromRemote,
  //Company share text
  attachCatalogueShareTextListener,
  removeCatalogueShareTextListener,
  getCatalogueShareText,
  getCatalogueShareTextFromCache,
  setCatalogueShareTextInCache,
  updateCompanyShareTextApi,
  setCompanyBulkOrderingSettings,
  getCompanyLevelGoldRateAndProductTypeFromCache,
  setCompanyLevelGoldRateAndProductTypeInCache
};
