import { searchProductLibCatalogueMeta } from 'qs-api/Catalogues/api';
import { CATALOGUE_PRODUCT_LIB_META } from 'qs-api/Catalogues/ApiCacheConnector';
import {
  processCataloguePageIncrement,
  PRODUCT_LIB_CATALOGUE_SEARCH_DATA
} from 'qs-helpers/Catalogues/ResponseProcessor';
import { CATALOGUE_PRODUCT_LIB_PAGE_SIZE } from 'qs-helpers/Catalogues/constants';
import CacheRequest from '../CacheRequest';
import { paginatedSearchProductLibrary } from 'qs-helpers/ProductLibrary/PaginatedRequest';

const getCacheKeyForCatalogueSearch = () => CATALOGUE_PRODUCT_LIB_META.cacheKey;

export const attachCataloguesProductLibSearchListener = ({ listener }) => {
  CacheRequest.attachListener(getCacheKeyForCatalogueSearch(), listener);
};
export const removeCataloguesProductLibSearchListener = ({ listener }) => {
  CacheRequest.removeListener(getCacheKeyForCatalogueSearch(), listener);
};
export const getCataloguesProductLibListFromCache = () =>
  CacheRequest.getCacheForKey(getCacheKeyForCatalogueSearch()) || undefined;

export const setCataloguesProductLibListInCache = ({ updates }) => {
  const existingData = getCataloguesProductLibListFromCache() || {};
  if (!existingData) {
    return;
  }
  CacheRequest.setCacheForKey(getCacheKeyForCatalogueSearch(), {
    ...existingData,
    ...updates
  });
};

export const clearCatalogueSearchDataFromCache = ({ searchTerm } = {}) => {
  PRODUCT_LIB_CATALOGUE_SEARCH_DATA.currentSearchTerm = searchTerm;
  PRODUCT_LIB_CATALOGUE_SEARCH_DATA.currentPage = 0;
  PRODUCT_LIB_CATALOGUE_SEARCH_DATA.paginationCompleted = false;
  PRODUCT_LIB_CATALOGUE_SEARCH_DATA.requestNumber = 0;
  CacheRequest.deleteCacheForKeys([getCacheKeyForCatalogueSearch()]);
};

const notifyCatalogueSearchListeners = () => {
  const existingCatalogueCache = getCataloguesProductLibListFromCache();
  if (!existingCatalogueCache) {
    CacheRequest.notifyListeners(getCacheKeyForCatalogueSearch(), null, {
      status: CacheRequest.OPERATION_STATUS.LOADING
    });
  }
};

const notifyCatalogueErrorListener = ({ error }) => {
  CacheRequest.notifyListeners(getCacheKeyForCatalogueSearch(), error);
};

const mergeCatalogueSearchDataWithCache = ({ apiSearchData }) => {
  const existingCacheData = getCataloguesProductLibListFromCache();
  const { catalogues: existingCatalogues = [], cataloguesTotal } = existingCacheData || {};

  if (
    apiSearchData.catalogues.length === 0 ||
    apiSearchData.catalogues.length < CATALOGUE_PRODUCT_LIB_PAGE_SIZE
  ) {
    PRODUCT_LIB_CATALOGUE_SEARCH_DATA.paginationCompleted = true;
  }

  setCataloguesProductLibListInCache({
    updates: {
      catalogues: existingCatalogues.concat(apiSearchData.catalogues),
      cataloguesTotal: apiSearchData.cataloguesTotal || cataloguesTotal || 0
    }
  });
};

export const searchCataloguesFromLibrary = ({ searchTerm, forceLoad, activeCatalogueId } = {}) =>
  paginatedSearchProductLibrary({
    searchTerm,
    forceLoad,
    notifyListeners: notifyCatalogueSearchListeners,
    apiFunction: searchProductLibCatalogueMeta,
    getApiParams: () => ({
      searchTerm,
      page: PRODUCT_LIB_CATALOGUE_SEARCH_DATA.currentPage,
      excludeCatalogueId: activeCatalogueId
    }),
    notifyError: notifyCatalogueErrorListener,
    processSearchTermChange: clearCatalogueSearchDataFromCache,
    processPageIncrement: processCataloguePageIncrement,
    searchDataConst: PRODUCT_LIB_CATALOGUE_SEARCH_DATA,
    onMergePaginatedData: mergeCatalogueSearchDataWithCache
  });
