import React, { useState, useEffect } from 'react';
import useRefreshHandlerHook from 'qs-hooks/refreshHandlerHook';
import CacheListenerCallback from 'qs-helpers/CacheListenerCallback';
import {
  attachCatalogueFilterListener,
  removeCatalogueFilterListener,
  getCatalogueFilterData,
  getCatalogueFilterDataFromCache
} from 'qs-data-manager/ProductLibrary/FiltersForLibrary';
import Loader from '../../../../Common/Loader';
import { SET_CATALOGUE_FILTER } from '../reducer';
import { removeCurrentCatalogueFromList } from 'qs-helpers/ProductLibrary/ResponseProcessor';
import SelectInput from '../../../../Common/SelectInput';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

export default ({
  updateFilters,
  filters: { catalogueFilter = '' } = {},
  currentCatalogueId,
  processCataloguesResponse
}) => {
  const cachedCatalogueFilters = getCatalogueFilterDataFromCache();
  const [catalogueDropDownValues, setCatalogueDropDownValues] = useState(() =>
    removeCurrentCatalogueFromList({
      catalogueList: cachedCatalogueFilters,
      catalogueIdToRemove: currentCatalogueId
    })
  );

  const { t } = getI18N();

  const [catalogueDropDownStatus, updateCatalogueDropDownStatus] = useRefreshHandlerHook(
    cachedCatalogueFilters,
    false
  );

  //Set the first catalogue's title as the default value when the drop down values change
  useEffect(() => {
    if (!Array.isArray(catalogueDropDownValues) || catalogueDropDownValues.length === 0) {
      return;
    }
    updateFilters({
      type: SET_CATALOGUE_FILTER,
      catalogueFilter: catalogueDropDownValues[0].catalogueId
    });
  }, [updateFilters, catalogueDropDownValues]);

  useEffect(() => {
    const catalogueFilterListener = (error, payload) => {
      const dataFromListener = CacheListenerCallback(error, payload);
      updateCatalogueDropDownStatus(dataFromListener);
      const { err, loading, refreshing, data } = dataFromListener;
      if (err || loading || refreshing || !data || !Array.isArray(data.catalogues)) {
        return;
      }

      const eligibleCatalogues = removeCurrentCatalogueFromList({
        catalogueList: data.catalogues,
        catalogueIdToRemove: currentCatalogueId
      });
      setCatalogueDropDownValues(eligibleCatalogues);
      processCataloguesResponse(eligibleCatalogues);
    };

    attachCatalogueFilterListener({ listener: catalogueFilterListener });
    getCatalogueFilterData();
    return () => {
      removeCatalogueFilterListener({
        listener: catalogueFilterListener
      });
    };

    /*
      Currently currentCatalogueId will not change throughout this components
      lifecycle, hence adding it as a dependency will not re-trigger this
      effect unnecessarily
    */
  }, [updateCatalogueDropDownStatus, currentCatalogueId, processCataloguesResponse]);

  const handleFilterChange = event => {
    updateFilters({ type: SET_CATALOGUE_FILTER, catalogueFilter: event.target.value });
  };

  const getCatalogueFilterComponent = () => {
    const { loading, error } = catalogueDropDownStatus;
    if (error) {
      return (
        <div className="filterPlaceHolder errorMessage">
          {t('something_went_wrong_while_fetching_catalogue_filters')}
        </div>
      );
    }

    if (loading) {
      return (
        <div className="filterPlaceHolder">
          <Loader size="small" />
        </div>
      );
    }

    const options = catalogueDropDownValues.map(({ catalogueId, title }) => ({
      value: catalogueId,
      displayValue: title || t('no_title')
    }));

    return (
      <SelectInput
        value={catalogueFilter}
        onChange={handleFilterChange}
        options={options}
        noSelectionValue={''}
        displayEmpty={true}
      />
    );
  };

  return (
    <div className="productLibraryFilterContainer">
      <h3 className="filterTitle">{t('product_library')}</h3>
      {getCatalogueFilterComponent()}
    </div>
  );
};
