export const ALLOWED_CSV_MIME_TYPES = [
  'text/csv',
  'application/vnd.ms-excel',
  'application/octet-stream',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
];

export const ALLOWED_FILE_EXTENSIONS = ['csv', 'xls', 'xlsx'];

export const PROPERTY_NAME_MAP = Object.freeze({
  FIELDS: 'fields',
  VARIANTS: 'variants'
});
