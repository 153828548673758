import React, { Component } from 'react';

import Modal from 'react-responsive-modal';

import api from 'qs-services/Api';
import Loader from 'qs-components/Common/Loader';

import 'toastr/build/toastr.min.css';
import './ResellModal.css';

import DecisionBox from './DecisionBox/DecisionBox';

import toastr from 'toastr';
import qs from 'query-string';
import { navigateToPath } from 'qs-data-manager';
import { ROUTE_MAP } from 'qs-helpers/CompanySettings/constants';
import * as Sentry from '@sentry/browser';
import { getI18N } from 'qs-services/i18N';

class ResellModal extends Component {
  state = {
    showModal: false,
    screenStatus: 'showLoader', //showLoader, DecisionBox, showError
    noOfProducts: '',
    catalogueName: '',
    companyName: ''
  };

  constructor() {
    super();
    toastr.options = {
      positionClass: 'toast-bottom-left',
      showDuration: 300,
      hideDuration: 1000,
      timeOut: 2000
    };
  }

  async UNSAFE_componentWillMount() {
    const parsed = qs.parse(window.location.search);

    let showCaseFetchUrl = null;
    if (parsed.showcaseSlug) {
      showCaseFetchUrl = parsed.showcaseSlug;
    } else {
      showCaseFetchUrl = localStorage.getItem('showcaseSlug');
    }

    if (!showCaseFetchUrl) {
      return;
    }

    if (showCaseFetchUrl) {
      this.setState({ showModal: true });
      this.removeSlugFromLocalStorage();
      this.fetchCatalogueId(showCaseFetchUrl);
    }
  }

  removeSlugFromLocalStorage = () => {
    localStorage.removeItem('showcaseSlug');
  };

  fetchCatalogueId = async showCaseFetchUrl => {
    try {
      const { catalogueId } = await api.getCatalogIdFromShowcaseUrlViaRds(showCaseFetchUrl);
      if (catalogueId) {
        this.resellerCatalogueId = catalogueId;
        this.fetchCompanyDetails(this.resellerCatalogueId);
      } else {
        this.callError();
      }
    } catch (err) {
      this.callError();
      Sentry.captureException(err);
    }
  };

  fetchCompanyDetails = async catalogueId => {
    try {
      const updates = {};
      const { belongsToCompanyId, productList, title } = await api.getResellersProductMeta(
        catalogueId
      );
      const urls = await this.fetchUrls(productList);
      updates.urls = urls;
      updates.noOfProducts = Object.keys(productList).length;
      updates.catalogueName = title;
      const companyName = await api.getCompanyName(belongsToCompanyId);
      updates.companyName = companyName;
      updates.screenStatus = 'DecisionBox';
      this.setState(updates);
    } catch (error) {
      this.callError();
      Sentry.captureException(error);
    }
  };

  callError = () => {
    const { t } = getI18N();
    toastr.error(t('something_went_wrong_please_try_again'));
    localStorage.removeItem('showcaseSlug');
    this.closeModal();
  };

  fetchUrls = async productList => {
    const allPicturesMeta = await api.getLinks(productList);
    const fivePictures = allPicturesMeta.filter((picture, index) => index < 5);
    const urls = [];
    fivePictures.forEach(picture => {
      if (picture.default_picture_id) {
        urls.push(picture.pictureUrl);
      }
    });
    return urls;
  };

  onDuplicate = async () => {
    this.setState({ screenStatus: 'showLoader' });
    const { error } = await api.duplicateCatalogue(this.resellerCatalogueId);
    if (!error) {
      const { t } = getI18N();
      toastr.success(t('catalogue_duplicated_into_your_account'));
      setTimeout(() => {
        this.closeModal();
      }, 1200);
      return;
    }

    this.callError();
  };

  closeModal = () => {
    navigateToPath(`/${ROUTE_MAP.CATALOGUES}`);
    this.setState({ showModal: false });
  };

  render() {
    const { showModal, screenStatus, noOfProducts, catalogueName, companyName, urls } = this.state;

    return (
      <Modal
        open={showModal}
        center
        onClose={() => {
          this.setState({ showModal: true });
        }}
        styles={{ modal: { backgroundColor: 'transparent', borderRadius: 15, padding: 0 } }}
        showCloseIcon={false}
      >
        {screenStatus === 'showLoader' && (
          <div className="loaderContainer">
            <Loader size="large" />
          </div>
        )}

        {screenStatus === 'DecisionBox' && (
          <DecisionBox
            onYesClick={this.onDuplicate}
            onCancelClick={this.closeModal}
            companyName={companyName}
            noOfProducts={noOfProducts}
            catalogueName={catalogueName}
            urls={urls}
          />
        )}
      </Modal>
    );
  }
}

export default ResellModal;
