export const CATALOGUE_PRODUCT_LIB_PAGE_SIZE = 20;

export const CATALOGUE_COPY_OPTIONS = [
  { id: 'EXISTING_CATALOGUE', buttonText: 'copy_to_another_catalogue' },
  { id: 'NEW_CATALOGUE', buttonText: 'create_new_catalogue' }
];
export const CATALOGUE_COPY_OPTIONS_MAP = {
  NEW_CATALOGUE: {
    id: 'NEW_CATALOGUE'
  },
  EXISTING_CATALOGUE: {
    id: 'EXISTING_CATALOGUE'
  }
};

export const CATALOGUE_PRODUCT_LIB_FIXED_LIST = {
  overscanCount: 5,
  height: 95,
  nextPageThresholdRows: Math.min(CATALOGUE_PRODUCT_LIB_PAGE_SIZE / 2, 15)
};

export const CATALOGUE_COPY_DIALOG_WIDTH = 420;
