import React from 'react';
import Loader from 'qs-components/Common/Loader';
import './styles.css';

export default function CatalogueLoader({ fetchingLink = null }) {
  if (!fetchingLink) return null;
  return (
    <div className={'loaderContainer'}>
      <Loader size={'large'} />
    </div>
  );
}
