import React, { useState } from 'react';
import { PrimaryButton, SecondaryButton } from 'qs-components/Common/DarkThemeDialog';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

export default ({ toggleOpen, updateHeadline, headline }) => {
  const { t } = getI18N();
  const [tempHeadline, setTempHeadLine] = useState(headline || '');
  return (
    <div className={'catalogueHeadlineContentInput'}>
      <div className={'title'}>{t('catalogue_description')}</div>
      <textarea
        className={'inputField'}
        value={tempHeadline}
        placeholder={t('type_description')}
        onChange={event => setTempHeadLine(event.target.value)}
      />
      <div className={'toggleOptions'}>
        <SecondaryButton onClick={toggleOpen}>{t('cancel')}</SecondaryButton>
        <PrimaryButton onClick={updateHeadline(tempHeadline.trim())}>{t('save')}</PrimaryButton>
      </div>
    </div>
  );
};
