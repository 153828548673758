import React, { useEffect, useCallback } from 'react';
import usePreviousHook from 'qs-hooks/usePreviousHook';
import { DialogLightTheme } from 'qs-components/Common/LightThemeDialog';
import SettingsIframeComponent from 'qs-components/AccountSettings/CompanySettings/SettingsIframeComponent';
import FeatureModal from '../../../../../../Common/FeatureModal';
import {
  ACCESS_TYPE,
  ENTITY_TYPE,
  getCataloguePrivacySettingsCacheKey
} from './CataloguePrivacyConstants';
import {
  SETTINGS_COMPONENT_ID_MAP,
  SETTINGS_COMPONENT_ROUTE_MAP
} from 'qs-helpers/CompanySettings/constants';
import { getPaidFeatures } from '../../../../../../Common/FeatureModal/helper';
import CacheRequest from 'qs-data-manager/CacheRequest';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

const CataloguePrivacySettingsDialog = ({
  showCataloguePrivacyDialog,
  closeCataloguePrivacyDialog,
  catalogueId
}) => {
  const [previousShowCataloguePrivacyDialog] = usePreviousHook({
    values: [showCataloguePrivacyDialog]
  });

  const accessSettings = CacheRequest.getCacheForKey(
    getCataloguePrivacySettingsCacheKey(catalogueId)
  );

  const onModalCloseHandler = useCallback(() => {
    closeCataloguePrivacyDialog();
    if (window.location.hash.includes(catalogueId)) {
      window.history.back();
    }
  }, [catalogueId, closeCataloguePrivacyDialog]);

  const onHashChangedHandler = useCallback(() => {
    if (!window.location.hash.includes(catalogueId)) {
      onModalCloseHandler();
    }
  }, [onModalCloseHandler, catalogueId]);

  useEffect(() => {
    if (showCataloguePrivacyDialog && !window.location.hash.includes(catalogueId)) {
      window.location.hash = '#/' + catalogueId;
    }
  }, [showCataloguePrivacyDialog, catalogueId]);

  useEffect(() => {
    if (
      previousShowCataloguePrivacyDialog !== undefined &&
      previousShowCataloguePrivacyDialog !== showCataloguePrivacyDialog &&
      showCataloguePrivacyDialog === false
    ) {
      onModalCloseHandler();
    }
  }, [previousShowCataloguePrivacyDialog, showCataloguePrivacyDialog, onModalCloseHandler]);

  useEffect(() => {
    window.addEventListener('hashchange', onHashChangedHandler);
    return () => {
      window.removeEventListener('hashchange', onHashChangedHandler);
    };
  }, [onHashChangedHandler]);

  const { t } = getI18N();

  const planFeature = getPaidFeatures().PRIVACY_MODULE;
  const {
    isAllowed: { allowed }
  } = planFeature;
  if (!allowed) {
    return <FeatureModal currentPlanFeature={planFeature} onClose={closeCataloguePrivacyDialog} />;
  }

  return (
    <DialogLightTheme
      open={showCataloguePrivacyDialog}
      onClose={onModalCloseHandler}
      title={''}
      className="catalogue-privacy-settings-dialog"
      onClick={event => {
        event.stopPropagation();
        event.preventDefault();
      }}
    >
      <div className="catalogue-privacy-settings-dialog-container">
        <SettingsIframeComponent
          id={
            SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CATALOGUE_PRIVACY_SETTINGS].TITLE
          }
          title={t(
            SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CATALOGUE_PRIVACY_SETTINGS].TITLE
          )}
          route={`${
            SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CATALOGUE_PRIVACY_SETTINGS]
              .IFRAME_ROUTE
          }/${catalogueId}/${ENTITY_TYPE.CATALOGUE}/${ACCESS_TYPE[accessSettings.accessLevel]}`}
          removeBeginningHash={
            SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CATALOGUE_PRIVACY_SETTINGS]
              .REMOVE_HASH
          }
        />
      </div>
    </DialogLightTheme>
  );
};

export default CataloguePrivacySettingsDialog;
