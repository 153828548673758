import { isProductTypeDetailsChanged } from 'qs-data-manager/ProductDetails';

export const UPDATE_TITLE = 'UPDATE_TITLE';
export const UPDATE_SKU = 'UPDATE_SKU';
export const UPDATE_PRICE = 'UPDATE_PRICE';
export const UPDATE_DISCOUNT = 'UPDATE_DISCOUNT';
export const UPDATE_JEWELLERY_RATES = 'UPDATE_JEWELLERY_RATES';
export const UPDATE_DESCRIPTION = 'UPDATE_DESCRIPTION';
export const UPDATE_WEIGHT = 'UPDATE_WEIGHT';
export const UPDATE_MOQ = 'UPDATE_MOQ';
export const UPDATE_ORIGINAL_TO_CURRENT = 'UPDATE_ORIGINAL_TO_CURRENT';
export const UPDATE_FROM_PRODUCT_TITLE = 'UPDATE_FROM_PRODUCT_TITLE';
export const UPDATE_FROM_WEIGHT = 'UPDATE_FROM_WEIGHT';
export const UPDATE_FROM_PRODUCT_DESC = 'UPDATE_FROM_PRODUCT_DESC';
export const UPDATE_FROM_PRODUCT_PRICE = 'UPDATE_FROM_PRODUCT_PRICE';
export const UPDATE_FROM_PRODUCT_DISCOUNT = 'UPDATE_FROM_PRODUCT_DISCOUNT';
export const UPDATE_FROM_PRODUCT_MOQ = 'UPDATE_FROM_PRODUCT_MOQ';
export const RESET_VARIANT_DATA = 'RESET_VARIANT_DATA';
export const RESET_MOQ_DATA = 'RESET_MOQ_DATA';
export const CUSTOM_FIELDS_CHANGED = 'CUSTOM_FIELDS_CHANGED';
export const CUSTOM_FIELDS_UPDATED = 'CUSTOM_FIELDS_UPDATED';

const moqInit = (moqData = {}) => {
  const moqVal = typeof moqData.moq === 'number' ? moqData.moq : '';

  return {
    moq: moqVal.toString(),
    originalMoq: moqVal,
    showMoqChange: moqData.moqFromProduct || false
  };
};

export const variantInit = (variantData = {}) => {
  //Preserve 0
  const priceVal = typeof variantData.price === 'number' ? variantData.price : '';
  const discountVal =
    typeof variantData.discounted_price === 'number' ? variantData.discounted_price : '';
  const weightVal = typeof variantData.weight === 'number' ? variantData.weight : '';
  const { moq, originalMoq, showMoqChange } = moqInit(variantData);

  return {
    title: variantData.name || '',
    sku: variantData.sku || '',
    price: priceVal.toString(),
    discount: discountVal.toString(),
    description: variantData.description || '',
    weight: weightVal.toString(),
    productTypeDetails: variantData.productTypeDetails,
    productTypeRateCharges: variantData.productTypeRateCharges || [],
    moq,

    // Default values will all be set to empty string
    originalTitle: variantData.name || '',
    originalSku: variantData.sku || '',
    originalPrice: priceVal,
    originalDiscount: discountVal,
    originalDesc: variantData.description || '',
    originalWeight: variantData.weight || '',
    originalProductTypeDetails: variantData.productTypeDetails,
    originalProductTypeRateCharges: variantData.productTypeRateCharges || [],
    originalMoq,

    showTitleChange: variantData.nameFromProduct || false,
    showWeightChange: variantData.weightFromProduct || false,
    showDescriptionChange: variantData.descriptionFromProduct || false,
    showPriceChange: variantData.priceFromProduct || false,
    showDiscountChange: variantData.discountFromProduct || false,
    showProductTypeDetailsChange: variantData.productTypeDetails || false,
    showProductTypeRateChargesChange: variantData.productTypeRateChargesFromProduct || false,
    showMoqChange,

    showSave: false
  };
};

const getUpdatedDiscountData = ({ showDiscountChange, price, discount }) => {
  const priceExists = price !== '';
  //This discount had inherited from the product and now the price has been added to the variant
  if (priceExists && showDiscountChange) {
    return {
      discountFromProduct: false,
      discounted_price: ''
    };
  }

  return {
    discountFromProduct: showDiscountChange,
    discounted_price: discount === '' ? '' : Number(discount)
  };
};

const toggleShowSave = newState => {
  const {
    title,
    originalTitle,
    sku,
    originalSku,
    price,
    originalPrice,
    discount,
    originalDiscount,
    description,
    originalDesc,
    weight,
    originalWeight,
    moq,
    productTypeDetails,
    originalProductTypeDetails,
    originalMoq
  } = newState;
  if (title !== originalTitle) {
    return true;
  }

  if (sku !== originalSku) {
    return true;
  }

  const productTypeDetailsChanged = isProductTypeDetailsChanged({
    productTypeDetails,
    originalProductTypeDetails
  });

  if (productTypeDetailsChanged) {
    return true;
  }

  if (price !== originalPrice.toString()) {
    /*
      originalPrice will either be a number or an empty string. Hence if the
      stringified value is the same as the price that has been entered, then
      no change has happened. the price from the input will always be a stringified
      value.
    */
    return true;
  }

  // discount handling same as price
  if (discount !== originalDiscount.toString()) {
    return true;
  }

  if (description !== originalDesc) {
    return true;
  }

  if (weight !== originalWeight.toString()) {
    return true;
  }

  if (moq !== originalMoq.toString()) {
    return true;
  }

  return false;
};

export const variantDataReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_TITLE: {
      const newState = {
        ...state,
        title: action.title
      };
      newState.showSave = toggleShowSave(newState);
      return newState;
    }
    case UPDATE_SKU: {
      const newState = {
        ...state,
        sku: action.sku
      };
      newState.showSave = toggleShowSave(newState);
      return newState;
    }
    case UPDATE_PRICE: {
      const newState = {
        ...state,
        price: action.price
      };
      newState.showSave = toggleShowSave(newState);
      return newState;
    }
    case UPDATE_DISCOUNT: {
      const newState = {
        ...state,
        discount: action.discount
      };
      newState.showSave = toggleShowSave(newState);
      return newState;
    }
    case UPDATE_JEWELLERY_RATES: {
      const newState = {
        ...state,
        ...(action.productTypeDetails ? { productTypeDetails: action.productTypeDetails } : {}),
        ...(action.productTypeRateCharges
          ? { productTypeRateCharges: action.productTypeRateCharges }
          : {})
      };
      newState.showSave = toggleShowSave(newState);
      return newState;
    }
    case UPDATE_DESCRIPTION: {
      const newState = {
        ...state,
        description: action.description
      };
      newState.showSave = toggleShowSave(newState);
      return newState;
    }
    case UPDATE_WEIGHT: {
      const newState = {
        ...state,
        weight: action.weight
      };
      newState.showSave = toggleShowSave(newState);
      return newState;
    }
    case UPDATE_MOQ: {
      const newState = {
        ...state,
        moq: action.moq
      };
      newState.showSave = toggleShowSave(newState);
      return newState;
    }
    case UPDATE_FROM_PRODUCT_TITLE:
      return {
        ...state,
        showTitleChange: action.showTitleChange
      };
    case UPDATE_FROM_WEIGHT:
      return {
        ...state,
        showWeightChange: action.showWeightChange
      };
    case UPDATE_FROM_PRODUCT_DESC:
      return {
        ...state,
        showDescriptionChange: action.showDescriptionChange
      };
    case UPDATE_FROM_PRODUCT_PRICE:
      return {
        ...state,
        showPriceChange: action.showPriceChange
      };
    case UPDATE_FROM_PRODUCT_DISCOUNT: {
      const price = state.price;
      let discount = state.discount;
      if (Number(price) < Number(discount)) {
        // Discount is less than price, unset it
        discount = '';
      }

      return {
        ...state,
        discount,
        showDiscountChange: action.showDiscountChange
      };
    }
    case UPDATE_FROM_PRODUCT_MOQ:
      return {
        ...state,
        showMoqChange: action.showMoqChange
      };
    case UPDATE_ORIGINAL_TO_CURRENT:
      let updatePriceChange = state.showPriceChange;

      /*
        The discount has changed, but the price change is still required
        this signifies that the price has been applied to the variant. Hence change
        the price change to false to enable editing the price
      */
      if (Number(state.discount) !== Number(state.originalDiscount) && updatePriceChange) {
        updatePriceChange = false;
      }

      const { discounted_price, discountFromProduct } = getUpdatedDiscountData(state);
      const currentVariantData = {
        name: state.title,
        sku: state.sku,
        price: state.price === '' ? '' : Number(state.price),
        discounted_price,
        description: state.description,
        weight: state.weight === '' ? '' : Number(state.weight),
        moq: state.moq === '' ? '' : Number(state.moq),

        descriptionFromProduct: state.showDescriptionChange,
        discountFromProduct,
        priceFromProduct: updatePriceChange,
        nameFromProduct: state.showTitleChange,
        weightFromProduct: state.showWeightChange,
        moqFromProduct: state.showMoqChange,
        productTypeDetails: state.productTypeDetails,
        productTypeRateCharges: state.productTypeRateCharges
      };
      const newState = variantInit(currentVariantData);
      return newState;
    case RESET_VARIANT_DATA:
      return variantInit(action.variantData);
    case RESET_MOQ_DATA:
      return {
        ...state,
        ...moqInit(action.moqData)
      };
    case CUSTOM_FIELDS_UPDATED: {
      const newState = {
        ...state,
        customFieldsChanged: false
      };
      newState.showSave = false;
      return newState;
    }
    case CUSTOM_FIELDS_CHANGED: {
      const newState = {
        ...state,
        customFieldsChanged: true,
        fieldsData: { ...action.fieldsData }
      };
      newState.showSave = true;
      return newState;
    }
    default:
      return state;
  }
};

export const UPDATE_IMAGE_DATA = 'UPDATE_IMAGE_DATA';
export const SET_CURRENT_AS_DEFAULT_IMAGE = 'SET_CURRENT_AS_DEFAULT_IMAGE';

export const variantImageInit = () => ({
  defaultImageId: null,
  currentImageDefault: false,
  currentImageData: null
});

//TODO will be used when the image is shown in the variant
export const variantImageReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_IMAGE_DATA:
      return {
        ...state,
        currentImageDefault: action.newImageData.id === state.defaultImageId,
        currentImageData: action.newImageData
      };
    case SET_CURRENT_AS_DEFAULT_IMAGE:
      return {
        defaultImageId: action.newImageData.id,
        currentImageDefault: true,
        currentImageData: action.newImageData
      };
    default:
      return state;
  }
};
