import { PRICE_FILTER_KEYS_CONFIG } from './constants';

export const productListFiltersExist = ({
  selectedTags,
  searchTerm,
  priceFilters,
  variantSelection,
  customFieldsSelection
}) =>
  selectedTags instanceof Set ||
  searchTerm ||
  priceFilters ||
  variantSelection ||
  customFieldsSelection;

export const isMaxNumMoreThanMinNum = (selectedOptionId, priceFilterObj) => {
  if (selectedOptionId !== PRICE_FILTER_KEYS_CONFIG.RANGE.id) {
    return true;
  }

  const { gtKey, ltKey } = PRICE_FILTER_KEYS_CONFIG.RANGE.keyMap;
  const { [selectedOptionId]: { [gtKey]: savedGtKey, [ltKey]: savedLtKey } = {} } = priceFilterObj;
  //Validate only if both values exist
  if (!(savedGtKey && savedLtKey)) {
    return true;
  }

  if (Number(savedGtKey) >= Number(savedLtKey)) {
    return false;
  }
  return true;
};

export const isRangeInputDataValid = (selectedOptionId, numFiltersToValidate, errorsInfo) => {
  if (selectedOptionId !== PRICE_FILTER_KEYS_CONFIG.RANGE.id) {
    return true;
  }

  const { gtKey, ltKey } = PRICE_FILTER_KEYS_CONFIG.RANGE.keyMap;
  const {
    [selectedOptionId]: { [gtKey]: savedGtKey, [ltKey]: savedLtKey } = {}
  } = numFiltersToValidate;

  // One of the values does not exist, show missing value specific message
  if ((!savedLtKey && savedGtKey) || (savedLtKey && !savedGtKey)) {
    if (!savedLtKey) {
      window.alert(errorsInfo[ltKey].message);
      return false;
    }
    if (!savedGtKey) {
      window.alert(errorsInfo[gtKey].message);
      return false;
    }
  }

  if (!isMaxNumMoreThanMinNum(selectedOptionId, numFiltersToValidate)) {
    window.alert(errorsInfo.RANGE.message);
    return false;
  }

  return true;
};

export const isFilterApplied = ({
  currentPriceFilters,
  currentVariantSelection,
  currentCustomFieldsSelection,
  customFieldsNumericOption
}) =>
  Object.keys(currentPriceFilters).length > 0 ||
  Object.keys(currentVariantSelection).length > 0 ||
  (Object.keys(currentCustomFieldsSelection).length > 0 &&
    Object.keys(customFieldsNumericOption).length > 0);
