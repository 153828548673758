import React, { useState, useEffect } from 'react';
import { bulkSavePrice } from 'qs-data-manager/ProductDetails';
import NumberInput from '../../../../../../Common/NumberInput';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

export default ({
  price,
  originalPrice,
  informDiscountOfPriceChange,
  isBulkEditing,
  activeProductIds,
  currencySymbol
} = {}) => {
  const [priceState, setPriceState] = useState({
    showSaveButton: false
  });
  const { t } = getI18N();

  useEffect(() => {
    if (isBulkEditing) {
      setPriceState(prevState => ({
        ...prevState,
        showSaveButton: false
      }));
    }
  }, [isBulkEditing]);

  const onPriceChange = value => {
    informDiscountOfPriceChange(value);

    let showSaveButton = false;
    const hasChanged = originalPrice !== value;
    if (isBulkEditing) {
      showSaveButton = hasChanged;
    }

    setPriceState(prevState => ({
      ...prevState,
      showSaveButton
    }));
  };

  const onSaveClick = () => {
    const priceToSave =
      price && typeof Number(price) === 'number' && !isNaN(Number(price)) ? Number(price) : null;

    bulkSavePrice({ productIds: activeProductIds, price: priceToSave }).then(() => {
      setPriceState(prevState => ({
        ...prevState,
        showSaveButton: false
      }));
    });
  };
  return (
    <div id={'PriceContainer'}>
      <div className={'rowContainer'}>
        <div className={'rowHeading'}>{t('price')}</div>
        <div className={'inputContainer priceInputContainer'}>
          <div className={'currencySymbol'}>{currencySymbol}</div>
          <NumberInput
            onChange={onPriceChange}
            value={price}
            digitsAfterDecimal={3}
            inputClassName={`input ${priceState.showSaveButton ? 'reducedWidth' : ''}`}
            placeholder={t('type_product_price')}
          />
          {!!priceState.showSaveButton ? (
            <div className={'saveButton'} onClick={onSaveClick}>
              {t('save')}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

// TODO: discount is fucked if price changes and backend is not handling it
