export const UPDATE_MANAGE_INVENTORY = 'UPDATE_MANAGE_INVENTORY';
export const UPDATE_HEADER_VIEW = 'UPDATE_HEADER_VIEW';

export const activeOrderViewInit = () => ({
  manageInventory: false,
  headerView: null
});

export const activeOrderViewReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_MANAGE_INVENTORY:
      return {
        ...state,
        manageInventory: action.manageInventory
      };
    case UPDATE_HEADER_VIEW:
      return {
        ...state,
        headerView: action.headerView || null
      };
    default:
      return state;
  }
};
