import React, { Component } from 'react';
import './DecisionBox.css';
import { getProductPictureUrl } from 'qs-helpers';
import AppConfig from 'qs-config/AppConfig';

class DecisionBox extends Component {
  renderImage = (url, index) => {
    const userId = url.split('quicksales/')[1].split('/')[0];

    const imageDetails = {
      imageId: null,
      source: AppConfig.imageUrlSource.CDN,
      size: 100,
      pictureUrl: url,
      userId: userId
    };

    const smallPictureUrl = getProductPictureUrl(imageDetails);

    const styles = {
      backgroundImage: `url(${smallPictureUrl})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      width: '65px',
      height: '65px',
      borderRadius: '5px',
      zIndex: 5,
      marginRight: '22px'
    };

    if (index === 4) {
      delete styles.marginRight;
    }

    return <div key={index} style={styles} />;
  };

  renderPictures = urls => urls.map((url, index) => this.renderImage(url, index));

  render() {
    const {
      onYesClick,
      onCancelClick,
      companyName,
      noOfProducts,
      catalogueName,
      urls
    } = this.props;

    return (
      <div className="resellModalContainer zoomIn">
        <div className="catalogueInfoContainer">
          <div>
            <div className="catalogueNameContainer">
              <div className="catalogueName">{catalogueName}</div>
              <div className="noOfProducts">{noOfProducts} items</div>
            </div>
            <div className="companyName">by {companyName}</div>
          </div>
          <div className="pictureContainer">{this.renderPictures(urls)}</div>
        </div>
        <div className="decisionBoxContainer">
          <div className="title">Download catalogue</div>
          <div className="subtitle">
            Are you sure you want to duplicate this catalogue into your account?
          </div>
          <div className="decisionButton">
            <div onClick={onCancelClick} className="cancel">
              cancel
            </div>
            <div onClick={onYesClick} className="accept">
              download now
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DecisionBox;
