import { makeStyles, TextField } from '@material-ui/core';
import { ensureInputIsNumber } from 'qs-helpers';
import { useInputStyles } from 'qs-hooks/useInputStyles';
import React, { useEffect, useState } from 'react';
import DialogBox from '../DialogBox';
import { getI18N } from 'qs-services/i18N';

const useTextFieldStyles = makeStyles({
  textRoot: {
    display: 'flex'
  }
});

export default ({ show, onClose, onUpdate, minOrderQuantity, title }) => {
  const inputStyles = useInputStyles();
  const textFieldStyles = useTextFieldStyles();
  const [moq, setMoq] = useState('');
  const { t } = getI18N();

  useEffect(() => {
    setMoq(minOrderQuantity);
  }, [minOrderQuantity]);

  if (!show) {
    return null;
  }

  const onMoqChange = event => {
    const value = ensureInputIsNumber({ event, integer: true });
    if (value === null) {
      return;
    }
    setMoq(value);
  };

  const onUpdateMoq = event => {
    event.preventDefault();
    onUpdate(moq);
  };

  const onDialogClose = event => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <DialogBox
      maxWidth={'xs'}
      open={true}
      dialogAsForm={{ onSubmit: onUpdateMoq }}
      title={title}
      onClose={onDialogClose}
      actionsConfig={[
        {
          content: t('cancel'),
          clickHandler: onClose,
          role: 'SECONDARY'
        },
        {
          content: t('set'),
          clickHandler: onUpdateMoq,
          role: 'PRIMARY'
        }
      ]}
      id="moq-edit-dialog-box"
    >
      <TextField
        InputProps={{
          placeholder: t('type_moq'),
          autoFocus: true,
          classes: {
            root: inputStyles.inputRoot,
            underline: inputStyles.inputUnderline,
            input: inputStyles.input
          }
        }}
        classes={{ root: textFieldStyles.textRoot }}
        value={moq}
        onChange={onMoqChange}
      />
    </DialogBox>
  );
};
