import { getCompanyIdFromCache } from 'qs-data-manager/Company';
import { MOQ_COMPANY_ENTITY } from 'qs-helpers/Moq/constants';
import {
  fetchMoqForEntityHandler,
  getCachedMoqData,
  handleMoqForEntityCleanup
} from 'qs-helpers/Moq/DataQueryHelper';
import useMakeQuery from 'qs-hooks/useMakeQuery';
import React, { useEffect, useState } from 'react';
import SettingsDataRow from '../../../Common/SettingsDataRow/SettingsDataRow';
import { ReactComponent as EditIcon } from 'qs-assets/Media/pencil.svg';
import { getMinimumOrderQuantity } from 'qs-helpers/Moq/ResponseProcessor';
import { updateMoqForEntity } from 'qs-data-manager/Moq/MoqOperations';
import toastr from 'toastr';
import MoqDialog from '../../../../Common/MoqDialog';
import { getI18N } from 'qs-services/i18N';

export default () => {
  const companyId = getCompanyIdFromCache();
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [updateMoqLoader, setUpdateMoqLoader] = useState(false);
  const [{ loading, refreshing, error, data }] = useMakeQuery({
    changeDependancy: [companyId, MOQ_COMPANY_ENTITY],
    actionHandler: fetchMoqForEntityHandler,
    cleanupHandler: handleMoqForEntityCleanup,
    cachedDataHandler: getCachedMoqData
  });

  const minOrderQuantity = getMinimumOrderQuantity(data);
  const { t } = getI18N();

  useEffect(() => {
    if (!error) {
      return;
    }
    toastr.error(t('something_went_wrong_while_fetching_the_updated_moq_value'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const onEditClick = () => {
    setShowEditPopup(true);
  };

  const onCloseEdit = () => {
    setShowEditPopup(false);
  };

  const updateCompanyMoq = async moq => {
    onCloseEdit();
    setUpdateMoqLoader(true);
    try {
      await updateMoqForEntity({
        entityId: companyId,
        entityType: MOQ_COMPANY_ENTITY,
        moqChange: {
          minOrderQuantity: moq ? Number(moq) : null
        }
      });
    } catch (error) {
      toastr.error(t('something_went_wrong_while_updating_the_moq'));
    } finally {
      setUpdateMoqLoader(false);
    }
  };

  const title = t('minimum_order_quantity');

  return (
    <>
      <SettingsDataRow
        icon={<EditIcon />}
        loader={loading || refreshing || updateMoqLoader}
        title={title}
        onSectionClick={onEditClick}
      >
        <span>
          {minOrderQuantity !== ''
            ? t('minimum_order_quantity_is_set_to', { minOrderQuantity })
            : t('no_minimum_order_quantity_set')}
        </span>
      </SettingsDataRow>
      <MoqDialog
        show={showEditPopup}
        title={title}
        minOrderQuantity={minOrderQuantity}
        onClose={onCloseEdit}
        onUpdate={updateCompanyMoq}
      />
    </>
  );
};
