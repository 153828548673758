export const SET_CATALOGUE_FILTER = 'SET_CATALOGUE_FILTER';

export const selectedFilterInit = initialState => initialState;

export const selectedFilterReducer = (state, action) => {
  switch (action.type) {
    case SET_CATALOGUE_FILTER:
      return { ...state, catalogueFilter: action.catalogueFilter };
    default:
      return state;
  }
};
