import { processFileList, isFileFormatValid, createNameForProduct } from './ProcessUploadedFiles';
import { RawFileData, DIRECTORY_TYPE, DirectoryData } from './RawFileData';

const readEntriesPromise = async directoryReader => {
  try {
    return await new Promise((resolve, reject) => {
      directoryReader.readEntries(resolve, reject);
    });
  } catch (err) {
    // send an empty array so any further processing can be stopped
    return [];
  }
};

const convertEntryToFile = async fileEntry => {
  try {
    return await new Promise((resolve, reject) => {
      fileEntry.file(file => {
        if (isFileFormatValid(file)) {
          resolve(file);
        }
        reject('unsupported format');
      }, reject);
    });
  } catch (error) {
    // Handle error
  }
};

/**
 * Sets the default image in place in the provided array. The default image is
 * determined if the  name of a file matches the directory name, otherwise if
 * name is 1. If neither of the above is satisifed then the file on the first
 * index is picked
 * @param {Array} directoryFiles array of files or raw files
 * @param {string} directoryName is the name of the directory
 */
const setDefaultImageForDirectory = (directoryFiles, directoryName) => {
  if (!(Array.isArray(directoryFiles) && directoryFiles.length)) {
    return;
  }

  let defaultImageIndex = 0;
  for (let index = 0; index < directoryFiles.length; index++) {
    const file = directoryFiles[index];
    if (!(file instanceof File)) {
      continue;
    }

    const fileName = createNameForProduct(file.name);
    // Highest priority given to that file that has the same name as the directory
    if (fileName === directoryName) {
      defaultImageIndex = index;
      break;
    }

    // Pick the image that is named as one. Continue searching for any image that may satisfy the above condition
    if (fileName === '1') {
      defaultImageIndex = index;
    }
  }

  // Swap the 0th entry with the default image value
  const tempFileData = directoryFiles[0];
  directoryFiles[0] = directoryFiles[defaultImageIndex];
  directoryFiles[defaultImageIndex] = tempFileData;
};

const processDirectory = async ({ directoryEntry, maxDirectoryLevel = 0 }) => {
  if (maxDirectoryLevel < 0 || typeof directoryEntry.createReader !== 'function') {
    return;
  }

  const directoryName = directoryEntry.name;
  const directoryReader = directoryEntry.createReader();
  const files = [];
  let readEntries = await readEntriesPromise(directoryReader);
  while (readEntries.length > 0) {
    const nestedFilePromises = readEntries.map(async currentEntry => {
      if (currentEntry.isFile) {
        return await convertEntryToFile(currentEntry);
      }

      if (currentEntry.isDirectory) {
        return await processDirectory({
          directoryEntry: currentEntry,
          maxDirectoryLevel: maxDirectoryLevel - 1
        });
      }
    });

    const nestedFilesData = await Promise.all(nestedFilePromises);

    // Filter out all those items that are not files or directories
    for (const fileData of nestedFilesData) {
      if (fileData instanceof File) {
        files.push(fileData);
      }

      if (fileData instanceof DirectoryData && fileData.files.length) {
        files.push(
          new RawFileData({
            fileData,
            type: DIRECTORY_TYPE,
            extraData: {
              name: fileData.name
            }
          })
        );
      }
    }

    readEntries = await readEntriesPromise(directoryReader);
  }
  setDefaultImageForDirectory(files, directoryName);
  return new DirectoryData(files, directoryName);
};

/**
 * allFilesData and allFiles must have the exact same length and their indices
 * must correspond to their expected values. allFiles contains an Array of
 * RawDataFiles. allFilesData holds the fileData that needs to be set to each
 * RawDataFiles object in the allFiles arrray. If after setting the values
 * index by index, there are still some values that don't have the fileData
 * then remove those values
 * @param {*} param0
 */
const filterValidFilesData = ({ allFilesData, allFiles }) => {
  allFilesData.forEach((fileData, index) => {
    allFiles[index].files = fileData;
  });

  return allFiles.filter(rawFileData => {
    if (!rawFileData.files) {
      return false;
    }
    return true;
  });
};

const getFileDataFromDataTransferItem = dataTransferItem => {
  if (!dataTransferItem) {
    return;
  }

  if (typeof dataTransferItem.webkitGetAsEntry === 'function') {
    return dataTransferItem.webkitGetAsEntry();
  }

  if (typeof dataTransferItem.getAsEntry === 'function') {
    return dataTransferItem.getAsEntry();
  }

  return;
};

const processFileEntry = ({ fileSystemEntry, maxDirectoryLevel = 0 }) => {
  if (fileSystemEntry.isFile) {
    return {
      fileDataPromise: convertEntryToFile(fileSystemEntry),
      rawFileData: new RawFileData()
    };
  }

  if (fileSystemEntry.isDirectory) {
    return {
      fileDataPromise: processDirectory({
        directoryEntry: fileSystemEntry,
        maxDirectoryLevel
      }),
      rawFileData: new RawFileData({
        type: DIRECTORY_TYPE,
        extraData: {
          name: fileSystemEntry.name
        }
      })
    };
  }
};

const isFileSystemEntryFeaturePresent = () => {
  // Currently it does not seem possible to detect FileSystemEntry. One of these
  // methods return the FileSystemEntry, hence ensure that these exist
  if (
    'webkitGetAsEntry' in DataTransferItem.prototype ||
    'getAsEntry' in DataTransferItem.prototype
  ) {
    return true;
  }

  return false;
};

const processSingleDataTransferItem = async dataTransferItems => {
  if (dataTransferItems.length !== 1) {
    return;
  }

  // File data was unavailable, no point processing anything further, return an empty array
  const fileData = getFileDataFromDataTransferItem(dataTransferItems[0]);
  if (!fileData) {
    return [];
  }

  if (!fileData.isDirectory) {
    return;
  }

  const directoryData = await processDirectory({ directoryEntry: fileData, maxDirectoryLevel: 1 });
  if (!(directoryData instanceof DirectoryData) || !directoryData.files.length) {
    return [];
  }

  return [
    new RawFileData({
      fileData: directoryData,
      type: DIRECTORY_TYPE,
      extraData: {
        name: directoryData.name
      }
    })
  ];
};

export const getRawFilesFromEvent = async event => {
  if (!event.dataTransfer) {
    return processFileList(event.target.files);
  }

  const { items, files } = event.dataTransfer;

  /*
    Either the DataTransferItem does not exist or it exists but the method
    to fetch file as entry does not exist. Pick through the files from the
    datatranfer objecr or simply through the files object
  */
  if (!items || !isFileSystemEntryFeaturePresent()) {
    if (files instanceof FileList && files.length) {
      return processFileList(files);
    }

    return processFileList(event.target.files);
  }

  const rawFileList = await processSingleDataTransferItem(items);
  if (rawFileList) {
    return rawFileList;
  }

  const allFilePromises = [],
    allFiles = [];
  for (let index = 0; index < items.length; index++) {
    const dataTransferItem = items[index];
    const fileData = getFileDataFromDataTransferItem(dataTransferItem);
    if (!fileData) {
      continue;
    }

    const { fileDataPromise, rawFileData } = processFileEntry({ fileSystemEntry: fileData });
    allFilePromises.push(fileDataPromise);
    allFiles.push(rawFileData);
  }

  const allFilesData = await Promise.all(allFilePromises);
  return filterValidFilesData({ allFilesData, allFiles });
};
