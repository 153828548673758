import React from 'react';
import { makeStyles, Checkbox, FormControlLabel } from '@material-ui/core';
import './styles.css';

const useStyles = makeStyles({
  iconRoot: {
    borderRadius: 2,
    width: '1em',
    height: '1em',
    border: props => props.border,
    fontSize: props => props.fontSize || 16,
    backgroundColor: props => props.uncheckedBgColor || '#435161',
    'input:hover ~ &': {
      backgroundColor: props => props.uncheckedHoverFill || '#68798c'
    },
    'input:disabled ~ &': {
      cursor: 'not-allowed'
    }
  },
  checkedIconRoot: {
    position: 'relative',
    backgroundColor: '#37A06D !important',
    'input:hover ~ &': {
      backgroundColor: '#37A06D'
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      left: '0.35em',
      top: '1.2px',
      width: '0.2em',
      height: '0.52em',
      border: 'solid white',
      borderWidth: '0 2px 2px 0',
      transform: 'rotate(45deg)'
    }
  },
  checkboxRoot: {
    padding: props => props.padding || 0,
    margin: props => props.margin || 0,
    flex: props => props.flex
  },
  controlRoot: {
    marginLeft: props => -(props.padding || 0),
    marginRight: props => props.controlMarginRight,
    marginTop: props => props.controlMarginTop,
    marginBottom: props => props.controlMarginBottom
  },
  labelRoot: {
    color: '#fff !important',
    fontSize: props => props.labelFontSize || 14
  }
});

export default ({ checked, onCheckChanged, disabled, label = '', styleProps = {} }) => {
  const componentStyles = useStyles(styleProps);

  if (!label) {
    return (
      <Checkbox
        icon={<span className={componentStyles.iconRoot} />}
        checkedIcon={
          <span className={`${componentStyles.iconRoot} ${componentStyles.checkedIconRoot}`} />
        }
        color="primary"
        inputProps={{ 'aria-label': 'primary checkbox' }}
        classes={{ root: componentStyles.checkboxRoot }}
        disabled={disabled}
        checked={checked}
        onChange={onCheckChanged}
      />
    );
  }

  return (
    <FormControlLabel
      control={
        <Checkbox
          icon={<span className={componentStyles.iconRoot} />}
          checkedIcon={
            <span className={`${componentStyles.iconRoot} ${componentStyles.checkedIconRoot}`} />
          }
          color="primary"
          inputProps={{ 'aria-label': 'primary checkbox' }}
          classes={{ root: componentStyles.checkboxRoot }}
        />
      }
      disabled={disabled}
      checked={checked}
      onChange={onCheckChanged}
      label={label}
      classes={{ root: componentStyles.controlRoot, label: componentStyles.labelRoot }}
    />
  );
};
