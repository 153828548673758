import request from 'qs-services/Request';
import AppConfig from 'qs-config/AppConfig.js';

const videoCDN = `https://${AppConfig.videoCDNBucketName}.s3.ap-south-1.amazonaws.com`;

const getTemporaryCredentials = idToken =>
  request.get(`${AppConfig.s3TempCredentials}?idToken=${idToken}`);

const poll = async ({ fn, validate, interval, maxAttempts }) => {
  let attempts = 0;

  const executePoll = async (resolve, reject) => {
    const result = await fn();
    attempts++;

    if (validate(result)) {
      resolve(result);
    } else if (maxAttempts && attempts === maxAttempts) {
      reject(new Error('Exceeded max attempts'));
    } else {
      setTimeout(executePoll, interval * attempts, resolve, reject);
    }
  };

  return new Promise(executePoll);
};

export const videoThumbnailUrl = videoId => {
  return `${videoCDN}/${videoId}/Thumbnails/${videoId}.0000000.jpg`;
};

export const hslVideoUrl = videoId => {
  return [`${videoCDN}/${videoId}/HLS/${videoId}.m3u8`];
};

export default { getTemporaryCredentials, poll, videoThumbnailUrl, hslVideoUrl };
