import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './translations/en.json';
import es from './translations/es.json';
import hi from './translations/hi.json';
import pt from './translations/pt.json';

import { COMPANY_LOCAL_STORAGE_KEYS } from 'qs-data-manager/Company';

let i18N;

export const APP_LANGUAGES = {
  en_US: { display: 'English', code: 'en' },
  es_ES: { display: 'Spanish', code: 'es' },
  hi_IN: { display: 'Hindi', code: 'hi' },
  pt_PT: { display: 'Portugese', code: 'pt' }
};

export const getTranslationCode = languageCode => {
  switch (languageCode) {
    case 'en_US': {
      return 'en';
    }
    case 'es_ES': {
      return 'es';
    }
    case 'hi_IN': {
      return 'hi';
    }
    case 'pt_PT': {
      return 'pt';
    }
    default: {
      return 'en';
    }
  }
};

export const getLanguageCode = () => {
  try {
    const localLanguageCode = localStorage.getItem(COMPANY_LOCAL_STORAGE_KEYS.APP_LANGUAGE);
    return Object.keys(APP_LANGUAGES).indexOf(localLanguageCode) > -1 ? localLanguageCode : 'en';
  } catch (error) {
    return 'en';
  }
};

export function getI18N() {
  if (!i18N) {
    throw new Error('Called before initialization');
  }
  return i18N;
}

export default function i18NInit() {
  const languageCode = getLanguageCode();
  i18n
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      debug: false,
      lng: (APP_LANGUAGES[languageCode] || {}).code || 'en',
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false // not needed for react as it escapes by default
      },
      resources: {
        en: {
          translation: en
        },
        en_US: {
          translation: en
        },
        es: {
          translation: es
        },
        es_ES: {
          translation: es
        },
        hi: {
          translation: hi
        },
        hi_IN: {
          translation: hi
        },
        pt: {
          translation: pt
        },
        pt_PT: {
          translation: pt
        }
      }
    });
  i18N = i18n;
}
