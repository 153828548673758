import React, { useState, useCallback } from 'react';
import eventbus from 'eventing-bus';
import { bulkSaveDiscount } from 'qs-data-manager/ProductDetails';
import { deleteDiscountFromProducts } from 'qs-data-manager/Products';
import BulkDiscount from './BulkEditDiscount/BulkEditDiscount.js';
import { SAVE_BUTTON_META } from 'qs-helpers/Products/constants';
import { DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import {
  ButtonDanger,
  DialogDarkTheme,
  SecondaryButton
} from '../../../../../../Common/DarkThemeDialog';
import ProductDiscount from '../../../../Common/ProductDiscount';
import toastr from 'toastr';
import { getI18N } from 'qs-services/i18N/index.js';
import './styles.scss';

export default ({
  discount,
  originalDiscount,
  originalPrice,
  price,
  currencySymbol,
  isSetSelected,
  handleDiscountChange,
  isBulkEditing,
  activeProductId,
  activeProductIds,
  bulkContainerClass = '',
  singleContainerClass = '',
  singleButtonContainerClass = '',
  discountPercentAbsolute = false
} = {}) => {
  const [showBulkDiscountModal, setShowBulkDiscountModal] = useState(false);
  const [showRemoveDiscountConfirm, setShowRemoveDiscountConfirm] = useState(false);

  const toggleDiscountConfirm = useCallback(() => {
    setShowRemoveDiscountConfirm(showConfirm => !showConfirm);
  }, []);

  const onDeleteBulkDiscount = useCallback(() => {
    bulkSaveDiscount({ productIds: activeProductIds });
    toggleDiscountConfirm();
  }, [activeProductIds, toggleDiscountConfirm]);
  const { t } = getI18N();

  const onDiscountRemoval = useCallback(async () => {
    if (!activeProductId) {
      return;
    }

    const productIds = [activeProductId];
    try {
      await deleteDiscountFromProducts({ productIds });
    } catch (error) {
      toastr.error(t('something_went_wrong_while_removing_discount_from_product'));
      return;
    }

    //Clear out the state from the one who's listening to these changes
    eventbus.publish(SAVE_BUTTON_META.eventbusKey, {
      id: SAVE_BUTTON_META.PRODUCT_DISCOUNT.id,
      hasChanged: false,
      eventType: SAVE_BUTTON_META.eventType.PRODUCT_META.id
    });
    handleDiscountChange('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeProductId, handleDiscountChange]);

  const toggleBulkDiscountModal = useCallback(() => {
    setShowBulkDiscountModal(prevShow => !prevShow);
  }, []);

  const onSetPercentClick = useCallback(
    discount => {
      if (discount === 0) {
        toggleBulkDiscountModal();
        return;
      }

      bulkSaveDiscount({ productIds: activeProductIds, discountInPercent: Number(discount) });
      toggleBulkDiscountModal();
    },
    [activeProductIds, toggleBulkDiscountModal]
  );

  const getSelectedProductCount = () => {
    if (!Array.isArray(activeProductIds)) {
      return 0;
    }

    return activeProductIds.length;
  };

  if (isBulkEditing) {
    const selectedProductCount = getSelectedProductCount();
    return (
      <div id={'ProductDiscountContainer'} className={`bulkContainer ${bulkContainerClass}`}>
        <div className="discountButtonContainer bulkDiscountButtons">
          {/*TODO make this discount button common */}
          <button onClick={toggleBulkDiscountModal} className={`discountButton`}>
            {t('offer_discount')}
          </button>
          <button onClick={toggleDiscountConfirm} className="removeDiscountButton">
            {t('remove_discount')}
          </button>
        </div>
        <BulkDiscount
          visible={showBulkDiscountModal}
          initialPercentage={''}
          closeModal={toggleBulkDiscountModal}
          onSetPercentClick={onSetPercentClick}
        />
        {showRemoveDiscountConfirm && (
          <DialogDarkTheme open={true} onClose={toggleDiscountConfirm}>
            <DialogTitle>{t('remove_discount')}</DialogTitle>
            <DialogContent>
              {selectedProductCount === 1
                ? t('are_you_sure_you_want_to_remove_discount_from_product', {
                    selectedProductCount
                  })
                : t('are_you_sure_you_want_to_remove_discount_from_products', {
                    selectedProductCount
                  })}
            </DialogContent>
            <DialogActions>
              <SecondaryButton onClick={toggleDiscountConfirm}>
                <span>{t('cancel')}</span>
              </SecondaryButton>
              <ButtonDanger color={'primary'} onClick={onDeleteBulkDiscount}>
                <span>{t('remove')}</span>
              </ButtonDanger>
            </DialogActions>
          </DialogDarkTheme>
        )}
      </div>
    );
  }

  return (
    <ProductDiscount
      key={activeProductId}
      discount={discount}
      originalDiscount={originalDiscount}
      originalPrice={originalPrice}
      price={price}
      isSetSelected={isSetSelected}
      currencySymbol={currencySymbol}
      onDiscountRemoval={onDiscountRemoval}
      inputLabel={t('after_discount')}
      handleDiscountChange={handleDiscountChange}
      placeholder={t('type_discount')}
      containerClass={singleContainerClass}
      buttonContainerClass={singleButtonContainerClass}
      discountPercentAbsolute={discountPercentAbsolute}
    />
  );
};
