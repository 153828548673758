import React, { useState, Fragment, useCallback, useRef, useEffect } from 'react';
import { ReactComponent as EditIcon } from 'qs-assets/Media/pencil.svg';
import Modal from 'react-responsive-modal';
import useRefreshHandlerHook from 'qs-hooks/refreshHandlerHook';
import CacheListenerCallback from 'qs-helpers/CacheListenerCallback';
import {
  attachProductOptionsListener,
  removeProductOptionsListener,
  getProductOptionsFromCache,
  getProductOptionsData
} from 'qs-data-manager/Variants/ProductOptions';
import Loader from '../../../../../Common/Loader';
import { navigateToPath } from 'qs-data-manager';
import ProductOptionsList from './ProductOptionsList';
import CreationOptions from './CreationOptions';
import Fab from '@material-ui/core/Fab';
import './styles.scss';
import {
  ROUTE_MAP,
  SETTINGS_COMPONENT_ID_MAP,
  SETTINGS_COMPONENT_ROUTE_MAP
} from 'qs-helpers/CompanySettings/constants';
import { getI18N } from 'qs-services/i18N';

export default ({ activeProductId, reloadVariants }) => {
  const [showAddModal, toggleAddModal] = useState(false);
  const [currentTabProperties, changeCurrentTabProperties] = useState({});
  const modalStyles = useRef({
    borderRadius: 10,
    maxWidth: '500px',
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    background: '#1F2229',
    display: 'flex',
    flexDirection: 'column'
  });

  const [productOptions, setProductOptions] = useState();
  const [optionAdded, setOptionAdded] = useState(false);
  const [productOptionsStatus, updateProductOptionsStatus] = useRefreshHandlerHook();
  const reorderDoneRef = useRef(null);
  const scrollRef = useRef(null);
  const { t } = getI18N();
  useEffect(() => {
    if (!showAddModal) {
      return;
    }

    const listener = (error, payload) => {
      const dataFromListener = CacheListenerCallback(error, payload);
      updateProductOptionsStatus(dataFromListener);
      const { err, loading, refreshing, data } = dataFromListener;
      if (err || loading || refreshing || !data) {
        return;
      }
      setProductOptions(data);
      changeCurrentTabProperties(data.options[0]);
      removeProductOptionsListener({ listener, productId: activeProductId });
    };
    attachProductOptionsListener({ listener, productId: activeProductId });
    getProductOptionsData({ productId: activeProductId });

    return () => removeProductOptionsListener({ listener, productId: activeProductId });
  }, [activeProductId, showAddModal, updateProductOptionsStatus]);

  const onAddClicked = event => {
    event.preventDefault();
    toggleAddModal(true);
    const cachedProductOptions = getProductOptionsFromCache(activeProductId);
    setProductOptions(cachedProductOptions);
  };

  const closeModal = useCallback(() => {
    toggleAddModal(false);
    if (reorderDoneRef.current) {
      reloadVariants();
    }
    reorderDoneRef.current = null;
  }, [reloadVariants]);

  const toggleOptionAdded = useCallback(() => {
    setOptionAdded(prevState => !prevState);
  }, []);

  const hasMoreThanTwoVariants = useCallback(() => {
    if (productOptions && productOptions.options) {
      return productOptions.options.length > 2;
    }
    return false;
  }, [productOptions]);

  const changeReorderStatus = useCallback(() => {
    reorderDoneRef.current = true;
  }, []);

  const isScrollable = () => {
    if (scrollRef.current) return scrollRef.current.scrollWidth > scrollRef.current.clientWidth;
  };

  const getModalContent = () => {
    const { loading, error } = productOptionsStatus;

    if (error) {
      return (
        <div className="optionsColorNoContentContainer">
          <span className="optionsErrorMsg">
            {t('something_went_wrong_while_fetching_the_options_for_this_product')}
          </span>
        </div>
      );
    }

    if (loading || !productOptions) {
      return (
        <div className="optionsColorNoContentContainer">
          <Loader size={'large'} />
        </div>
      );
    }
    const onAddVariant = () => {
      navigateToPath(
        `/${ROUTE_MAP.ACCOUNT_SETTINGS}/${
          SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.PRODUCT_OPTIONS].ROUTE
        }`
      );
    };

    return (
      <div className="productOptions">
        <div style={{ display: 'flex', justifyContent: isScrollable() ? 'space-between' : null }}>
          <div
            className="productOptionsTabContainer"
            id="variantScroll"
            ref={scrollRef}
            style={{
              justifyContent: hasMoreThanTwoVariants() ? 'space-between' : 'space-around',
              flex: isScrollable() ? null : '2'
            }}
          >
            {productOptions.options.map((productOption, index) => (
              <div
                key={index}
                className={`productOptionsTab ${
                  currentTabProperties.type === productOption.type ? 'activeOptionsTab' : ''
                }`}
                onClick={() => changeCurrentTabProperties(productOption)}
              >
                {typeof productOption.url === 'string' && productOption.url.length > 0 && (
                  <img
                    className="productOptionIcon"
                    src={productOption.url}
                    alt="product option icon"
                  />
                )}
                {typeof productOption.name === 'string' && productOption.name.length > 0 && (
                  <span className="productOptionName ellipsis">
                    {t(productOption.name.toLowerCase())}
                  </span>
                )}
              </div>
            ))}
          </div>
          <div
            onClick={onAddVariant}
            className="addVariantBtn"
            style={{ flex: isScrollable() ? null : '1' }}
          >
            <img
              className="productOptionIcon"
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAABhklEQVRIidWVTU4CQRCFq5nARryB8QDgSg8gLnSpYIxXIBDjzyUI8Qx6B7mG0YXoQlFXGj0AG8DI52JqoAM90zOyopJOZ+q9rtdVU90tsuxmkkCgICJVHZsisqbQp4jci0hHRG6MMaPMysAh8I7f3oBalsA54NIK8ACcAiVgRUcZOAO6Fq8N5NIIRMEHQD1pkW6moVyAdpqyRMG3M2RdsUSqcaSCVfN6DAeAGKyp8CuQdxGOrZo7y+IRCIBHpRxFfjtQ1AlXxpixM80EM8b8isi1fs6XCXhR9VJckKQMFC8r5dkF9hUszgb0mcVfVVc/8vn7NptFN8OkxLbAl87rE/aM+fzW2m+XwJ3OuwtksKfz7RxitWl3gTZ9mm1Tm1AgvLgAGv8QOFG45zxoSqopaQBUnCT3uh1gCIyBfR+5bYk0gSCBG+jOh7qmlWY3OUsEwuN/TniIijo2gAur5mOgFffv4oSqhBeXz3pJZfE9mXkROZDwbtmS6ZP5IdMns2OM+Um986WzPzyuU5pGL3H9AAAAAElFTkSuQmCC"
              alt="product option icon"
            />
            <span className="productOptionName ellipsis">{t('add_variant')}</span>
          </div>
        </div>

        {/*
          Reset the below component when the currentTab changes so that all
          existing state is cleared
        */}

        <ProductOptionsList
          key={currentTabProperties.type}
          activeProductId={activeProductId}
          currentTab={currentTabProperties}
          optionAdded={optionAdded}
          optionAdditionProcessed={toggleOptionAdded}
          changeReorderStatus={changeReorderStatus}
        />
        {currentTabProperties.type && (
          <CreationOptions
            currentTab={currentTabProperties}
            activeProductId={activeProductId}
            optionAdded={toggleOptionAdded}
          />
        )}
      </div>
    );
  };

  let optionsModal = null;
  if (showAddModal) {
    optionsModal = (
      <Modal
        open={true}
        center={true}
        onClose={closeModal}
        closeOnOverlayClick={true}
        styles={{ modal: modalStyles.current }}
        showCloseIcon={false}
      >
        {getModalContent()}
      </Modal>
    );
  }
  return (
    <Fragment>
      <div className={'addProductOptions'} onClick={onAddClicked}>
        <Fab color="primary" aria-label="edit" size="medium">
          <EditIcon className={'addIcon'} />
        </Fab>
      </div>
      {optionsModal}
    </Fragment>
  );
};
