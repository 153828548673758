import React from 'react';
import NumberInput from '../../../../../../Common/NumberInput';
import SelectInput from '../../../../../../Common/SelectInput';
import { PERCENT, PERGRAM } from '../ProductTypeDetails';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

export default ({
  makingChargeType,
  makingCharge,
  onMakingChargeSelectChange,
  onMakingChargeInputChange,
  currencySymbol
}) => {
  const { t } = getI18N();
  return (
    <div className="makingCharges">
      <div className="titleAndInput">
        <div className="title">{t('making_charges')}</div>
        <div className="inputContainer makingChargesInputContainer">
          {makingChargeType === PERGRAM.id && (
            <span className={'currencySymbol'}>{currencySymbol}</span>
          )}

          <NumberInput
            onChange={onMakingChargeInputChange}
            value={makingCharge}
            inputClassName="input setInput"
            placeholder={t('type_making_charges')}
            digitsAfterDecimal={3}
          />
          {makingChargeType === PERCENT.id && <span className={'percentageSymbol'}>%</span>}
        </div>
      </div>
      <div className="setDropDownWrapper">
        <SelectInput
          value={makingChargeType || PERCENT.id}
          options={[
            {
              value: PERCENT.id,
              displayValue: t('percent')
            },
            {
              value: PERGRAM.id,
              displayValue: t('per_gram')
            }
          ]}
          onChange={onMakingChargeSelectChange}
          materialStyles={{
            formSelectionBorder: '1px solid #ffffff',
            selectTextColor: '#ffffff',
            selectedItemBackgroundColor: '#ffffff',
            selectedItemColor: '#232b36'
          }}
        />
      </div>
    </div>
  );
};
