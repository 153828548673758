// Extracted into another file to ensure that if it is called from the main
// thread then the bundle doesn't include the image compression library

const JPEG_IMAGE_EXTENSIONS = new Set(['jpe', 'jpeg', 'jpg', 'jfif', 'pjpeg', 'pjp']);

export const AUTO_ORIENTATION = 'AUTO_ORIENTATION';

export const NO_AUTO_ORIENTATION = 'NO_AUTO_ORIENTATION';

export const LOAD_IMAGE_FAILED = 'LOAD_IMAGE_FAILED';

export const FAILED_TO_DETERMINE = 'FAILED_TO_DETERMINE';

/**
 * This method expects the data uri of an image that has been proceesed
 * through canvas. The image must be of height 2px and width 1px with a
 * 90° CCW rotation. Hence if the image is rorated by the browser
 * then the width would be 1 and height would be 2 and that would imply
 * that the browser is performing the exif rotation on its own
 * @param {string} imgSrc src for the image
 */
export const loadImageAndCheckOrientation = imgSrc => {
  return new Promise(resolve => {
    try {
      const img = new Image();
      img.onload = function() {
        if (img.width === 1 && img.height === 2) {
          resolve({ status: AUTO_ORIENTATION });
          return;
        }

        resolve({ status: NO_AUTO_ORIENTATION });
      };
      img.onerror = () => {
        resolve({ status: LOAD_IMAGE_FAILED, data: imgSrc });
      };
      img.src = imgSrc;
    } catch (imageAccessError) {
      resolve({ status: LOAD_IMAGE_FAILED, data: imgSrc });
    }
  });
};

export const getImageTypeFromExtension = fileName => {
  if (typeof fileName !== 'string') {
    return;
  }

  const splitFileName = fileName.split('.');
  const extension = splitFileName[splitFileName.length - 1].toLowerCase();
  if (JPEG_IMAGE_EXTENSIONS.has(extension)) {
    return 'image/jpeg';
  }

  if (extension === 'png') {
    return 'image/png';
  }

  if (extension === 'svg') {
    return 'image/svg+xml';
  }

  if (extension === 'tiff' || extension === 'tif') {
    return 'image/tiff';
  }

  if (extension === 'bmp') {
    return 'image/bmp';
  }

  if (extension === 'webp') {
    return 'image/webp';
  }

  // Image type not supported
  return;
};

export const getVideoTypeFromExtension = fileName => {
  if (typeof fileName !== 'string') {
    return;
  }

  const splitFileName = fileName.split('.');
  const extension = splitFileName[splitFileName.length - 1].toLowerCase();
  if (extension === 'mp4') {
    return 'video/mp4';
  }

  if (extension === 'webm') {
    return 'video/webm';
  }

  if (extension === 'ogg') {
    return 'video/ogg';
  }
};

export const getErrorMessageForUploadFailure = errData => {
  const { err, pictureId, productId } = errData || {};
  let errorMessage = [`picture ${pictureId} upload to ${productId} failed`];
  if (err && err.error instanceof Error) {
    errorMessage.push(`reason: ${err.error.message}`);
  }
  return errorMessage.join(', ');
};
