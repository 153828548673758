import React, { useContext, useEffect, useState, useCallback, useRef } from 'react';
import { ActiveVariantId } from '../../context';
import {
  getVariantsDataForProductFromCache,
  attachProductVariantListener,
  removeProductVariantListener
} from 'qs-data-manager/Variants/FetchVariants';
import CacheListenerCallback from 'qs-helpers/CacheListenerCallback';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList as List } from 'react-window';
import { VARIANT_ROW } from 'qs-helpers/Variants/constants';
import LabelView from './LabelView';
import './styles.scss';

export default ({ activeProductId }) => {
  const { activeVariantId } = useContext(ActiveVariantId);

  const [variantList, setVariantList] = useState(
    getVariantsDataForProductFromCache(activeProductId)
  );

  const horizontalListRef = useRef(null);
  const itemWidthMap = useRef(new Map());
  const minIndex = useRef(Number.MAX_VALUE);
  const currentThrottleTimer = useRef(null);
  const indexToScrollTo = useRef(null);

  //Save the variant list if it exists along with the variant Id. Will be
  //required for processing the scroll to item behavior once the list mounts
  const savedVariantData = useRef({
    variantProductIds: variantList ? variantList.variantProductIds || [] : [],
    variantId: activeVariantId
  });

  useEffect(() => {
    savedVariantData.current.variantId = activeVariantId;
  }, [activeVariantId]);

  useEffect(() => {
    // Get the data of the variants of the active product
    const variantListData = getVariantsDataForProductFromCache(activeProductId);
    setVariantList(variantListData);

    //Save the data only if the data is currently saved; this indicates that the
    // saved data has not been processed
    if (Array.isArray(savedVariantData.current.variantProductIds)) {
      savedVariantData.current.variantProductIds = variantListData.variantProductIds;
    }

    const productVariantListener = (error, payload) => {
      // Callback when cache is manipulated
      const { err, loading, refreshing, data } = CacheListenerCallback(error, payload);

      if (err || loading || refreshing || !data) {
        return;
      }

      setVariantList(data);
      if (Array.isArray(savedVariantData.current.variantProductIds)) {
        savedVariantData.current.variantProductIds = data.variantProductIds;
      }
    };

    attachProductVariantListener({ productId: activeProductId, listener: productVariantListener });
    return () => {
      clearTimeout(currentThrottleTimer.current);
      removeProductVariantListener({
        productId: activeProductId,
        listener: productVariantListener
      });
    };
  }, [activeProductId]);

  const reRenderListOnItemUpdate = useCallback((index, variantId, width) => {
    if (typeof variantId !== 'undefined') {
      //Add one because the width will be rounded to an integer
      itemWidthMap.current.set(variantId, width + 1);
    }

    if (index < minIndex.current) {
      minIndex.current = index;
    }

    if (!currentThrottleTimer.current) {
      //Wait 100 milliseconds to update the data in a batch
      currentThrottleTimer.current = setTimeout(() => {
        const indexResetAfter = minIndex.current;
        //RESET the values to enable throttling for the next update
        minIndex.current = Number.MAX_VALUE;
        currentThrottleTimer.current = null;

        //List still exists, reset the indices
        if (horizontalListRef.current) {
          horizontalListRef.current.resetAfterIndex(indexResetAfter);
          if (typeof indexToScrollTo.current === 'number') {
            horizontalListRef.current.scrollToItem(indexToScrollTo.current);
          }
        }

        //Scroll to is required only once, clear it out
        indexToScrollTo.current = null;
      }, 50);
    }
  }, []);

  // This function must remain refrentially stable hence wrap it in useCallback
  // The reRenderListOnItemUpdate is also refrentially hence adding it as a dependancy is safe
  const renderRow = useCallback(
    ({ data, index, style }) => {
      return (
        <LabelView
          data={data}
          style={style}
          reRenderListOnItemUpdate={reRenderListOnItemUpdate}
          index={index}
        />
      );
    },
    [reRenderListOnItemUpdate]
  );

  const itemKey = useCallback((index, data) => data[index][0], []);

  const getItemWidth = useCallback(
    index => {
      const variantArray = variantList.variantProductIds[index];
      if (!Array.isArray(variantArray)) {
        return VARIANT_ROW.width;
      }
      const variantId = variantArray[0];

      const itemWidth = itemWidthMap.current.get(variantId);
      if (!itemWidth) {
        return VARIANT_ROW.width;
      }

      //Acount for the 8px padding on either side of the component
      return itemWidth + 8 * 2;
    },
    [variantList]
  );

  const handleListMount = useCallback(ref => {
    horizontalListRef.current = ref;
    if (ref) {
      /*
        The scroll to item behavior is only required when the list mounts.
        The scroll however can not be done immediately since the list is updated
        once the children render. Hence find the required index and save it for
        update later. Read the saved variantProductIds and the activeVariantId.
        This saving is essential so that this callback is never re-created and the
        scroll to item behavior is not triggered again
      */
      const itemIndex = savedVariantData.current.variantProductIds.findIndex(
        variantArray => variantArray[0] === savedVariantData.current.variantId
      );

      if (itemIndex === -1) {
        return;
      }
      indexToScrollTo.current = itemIndex;

      //Clear out the saved value as it is no longer needed until the component
      // remounts
      savedVariantData.current.variantProductIds = null;
    }
  }, []);

  const renderVariantsList = () => {
    return (
      <AutoSizer>
        {({ height, width }) => (
          <List
            ref={handleListMount}
            height={height}
            itemData={variantList.variantProductIds}
            itemCount={variantList.variantProductIds.length}
            itemKey={itemKey}
            itemSize={getItemWidth}
            width={width}
            layout="horizontal"
            overscanCount={VARIANT_ROW.overscanCount}
          >
            {renderRow}
          </List>
        )}
      </AutoSizer>
    );
  };

  return <div className="horizontalVariantViewContainer">{renderVariantsList()}</div>;
};
