import React, { useEffect, useCallback, useRef, useState /* , useMemo */ } from 'react';
import {
  getProductLibraryDataBasedOnFilter,
  attachCatalogueProductsListener,
  removeCatalogueProductsListener,
  getProductLibraryProductsDataBasedOnFilter,
  getCatalogueProductsData
} from 'qs-data-manager/ProductLibrary/ProductsForLibrary';
import CacheListenerCallback from 'qs-helpers/CacheListenerCallback';
import useRefreshHandlerHook from 'qs-hooks/refreshHandlerHook';
import { getCachedCatalogueTitleForFilter } from 'qs-data-manager/ProductLibrary/FiltersForLibrary';
import Loader from '../../../../../Common/Loader';
import InfiniteScrollGrid from './InfiniteScrollGrid';
import DataGrid from './DataGrid';
import { getI18N } from 'qs-services/i18N';
// import useMakeQuery from 'qs-hooks/useMakeQuery';
import './styles.scss';
/* import {
  getCachedProductSearchData,
  handleProductSearchDataCleanup,
  handleProductSearchDataFetch
} from 'qs-helpers/ProductLibrary/DataQueryHelpers'; */

export default ({
  onProductsLoaded,
  catalogueFilter,
  productSelection,
  currentCatalogueId,
  disableMultiple = false,
  defaultSelectedProducts = []
}) => {
  const [productsDataStatus, updateProductsDataStatus] = useRefreshHandlerHook(
    getProductLibraryDataBasedOnFilter(catalogueFilter),
    false
  );
  const { t } = getI18N();

  /* const [{ loading, error, data }] = useMakeQuery({
    actionHandler: handleProductSearchDataFetch,
    cachedDataHandler: getCachedProductSearchData,
    cleanupHandler: handleProductSearchDataCleanup,
    changeDependancy: [catalogueFilter, true, catalogueFilter !== ''],
    globalLoader: false
  });

  const products = useMemo(() => {
    if (!data || !Array.isArray(data.products)) {
      return [];
    }
    return data.products;
  }, [data]); */

  const cachedProductsData = getProductLibraryProductsDataBasedOnFilter(catalogueFilter);
  const [productsData, setProductsData] = useState(cachedProductsData);
  //const initialCompanyProductCall = useRef(false);

  const defaultSelectedProductsMap = new Map();
  if (defaultSelectedProducts.length > 0) {
    defaultSelectedProducts.forEach(defaultSelectedProduct => {
      defaultSelectedProductsMap.set(defaultSelectedProduct, true);
    });
  }

  const checkboxMappedValues = useRef(defaultSelectedProductsMap);

  useEffect(() => {
    const productsDataListener = (error, payload) => {
      const dataFromListener = CacheListenerCallback(error, payload);
      updateProductsDataStatus(dataFromListener);
      const { loading, refreshing, err, data } = dataFromListener;
      if (loading || refreshing || err || !data) {
        return;
      }

      const newProductsData = getProductLibraryProductsDataBasedOnFilter(catalogueFilter);
      setProductsData(newProductsData);
    };

    if (catalogueFilter === '') {
      //Nothing to process. Keeping the below code commented in case if all catalogues must be enabled
      /* attachCompanyProductsListener({ listener: productsDataListener });
      //Make the company product REST call only once when the data is mounted.
      //Post that all REST calls will be done by the pagination handler
      if (!initialCompanyProductCall.current) {
        getCompanyProductsData();
        initialCompanyProductCall.current = true;
      }
      return () => removeCompanyProductsListener({ listener: productsDataListener }); */
      return;
    }

    attachCatalogueProductsListener({
      listener: productsDataListener,
      catalogueId: catalogueFilter
    });
    getCatalogueProductsData(catalogueFilter);

    return () =>
      removeCatalogueProductsListener({
        listener: productsDataListener,
        catalogueId: catalogueFilter
      });
  }, [catalogueFilter, setProductsData, onProductsLoaded, updateProductsDataStatus]);

  useEffect(() => {
    updateProductsDataStatus({
      data: getProductLibraryDataBasedOnFilter(catalogueFilter),
      localRefresh: true
    });

    //catalogue filter changed, pick products from cache if they exist
    const cachedFilterProductsData = getProductLibraryProductsDataBasedOnFilter(catalogueFilter);
    setProductsData(cachedFilterProductsData);
    onProductsLoaded(cachedFilterProductsData);
  }, [catalogueFilter, updateProductsDataStatus, onProductsLoaded]);

  const onRowSelected = useCallback(
    event => {
      if (!event || !event.node) {
        return;
      }
      const { node } = event;
      const { data, selected } = node || {};
      if (!data || !data.id) {
        return;
      }

      const { current: mappedValues } = checkboxMappedValues;
      const prevValue = mappedValues.get(data.id);
      if (prevValue === selected) {
        return;
      }

      if (selected) {
        if (disableMultiple) {
          mappedValues.clear();
        }
        mappedValues.set(data.id, selected);
      } else {
        mappedValues.delete(data.id);
      }
      productSelection(mappedValues);
    },
    [disableMultiple, productSelection]
  );

  const handleRowChange = useCallback(gridApi => {
    if (!gridApi) {
      return;
    }

    gridApi.forEachNode(node => {
      if (!node || !node.data) {
        return;
      }
      const { current: mappedValues } = checkboxMappedValues;
      if (mappedValues.get(node.data.id)) {
        node.setSelected(true);
      }
    });
  }, []);

  const getRowNodeId = useCallback(item => {
    if (item) {
      return item.id;
    }
    return 'loading';
  }, []);

  const getProductRowsComponent = () => {
    const { loading, error } = productsDataStatus;
    if (error) {
      return (
        <div className="productsDataPlaceholder errorContainer">
          {catalogueFilter
            ? t('something_went_wrong_while_fetching_the_products_for_catalogue', {
                catalogue: getCachedCatalogueTitleForFilter(catalogueFilter)
              })
            : t('something_went_wrong_while_fetching_the_products')}
        </div>
      );
    }

    if (loading) {
      return (
        <div className="productsDataPlaceholder">
          <Loader size="large" />
        </div>
      );
    }

    const productDataLength = productsData.products.length;
    if (productDataLength === 0) {
      return (
        <div className="productsDataPlaceholder">
          {catalogueFilter
            ? t('no_products_available_in_catalogue_filter', {
                catalogue: getCachedCatalogueTitleForFilter(catalogueFilter)
              })
            : t('no_products_available')}
        </div>
      );
    }

    if (catalogueFilter === '') {
      return (
        <InfiniteScrollGrid
          getRowNodeId={getRowNodeId}
          handleRowSelection={onRowSelected}
          handleRowChange={handleRowChange}
          containerClass="productLibGrid"
          currentCatalogueId={currentCatalogueId}
          catalogueFilter={catalogueFilter}
          disableMultiple={disableMultiple}
        />
      );
    }

    return (
      <DataGrid
        getRowNodeId={getRowNodeId}
        handleRowSelection={onRowSelected}
        productsData={productsData}
        handleRowChange={handleRowChange}
        containerClass="productLibGrid"
        disableMultiple={disableMultiple}
      />
    );
  };

  return <div className="productLibraryDataContainer">{getProductRowsComponent()}</div>;
};
