import { navigateToPath } from 'qs-data-manager';
import {
  ROUTE_MAP,
  SETTINGS_COMPONENT_ID_MAP,
  SETTINGS_COMPONENT_ROUTE_MAP
} from 'qs-helpers/CompanySettings/constants';
import React from 'react';
import { ReactComponent as RightArrow } from 'qs-assets/Media/icon_long_arrow_right.svg';
import './styles.scss';
import { SELECT_VALUE } from '../ProductTypeDetails';
import { getI18N } from 'qs-services/i18N';

export default ({
  setShowModal,
  getFinalPrice = () => {},
  currencySymbol,
  checkIfRatesAreNotSet,
  existingPrice,
  getSelectedMetalKaratPrice,
  metalKaratQuality,
  netWeight,
  grossWeight,
  necessaryJewelleryFieldValuesExist,
  isAllProductTypeDetailsFieldsEmpty,
  productStone
  // productPrice
}) => {
  const { t } = getI18N();

  const onSetMetalRatesClick = () => {
    navigateToPath(
      `/${ROUTE_MAP.ACCOUNT_SETTINGS}/${
        SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.METAL_RATES_OPTIONS].ROUTE
      }`
    );
  };

  const showProductPriceOrText = () => {
    if (
      (productStone || []).length ||
      (getSelectedMetalKaratPrice() &&
        metalKaratQuality !== SELECT_VALUE.id &&
        Number(netWeight) &&
        Number(grossWeight))
    ) {
      return (
        <>
          <div className="price">{`${currencySymbol} ${getFinalPrice()}`}</div>
          <div className="viewCalculationContainer">
            <div
              className="viewCalculation"
              onClick={() => {
                setShowModal(true);
              }}
            >
              {t('view_calculation')}
            </div>
          </div>
        </>
      );
    }

    if (!checkIfRatesAreNotSet() && metalKaratQuality === SELECT_VALUE.id) {
      return <div className="price">{t('not_specified')}</div>;
    }
    if (
      checkIfRatesAreNotSet() &&
      metalKaratQuality !== SELECT_VALUE.id &&
      !getSelectedMetalKaratPrice()
    ) {
      return showSetMetalRatePriceText();
    }

    return <div className="price">{t('not_specified')}</div>;
  };

  const showSetMetalRatePriceText = () => {
    return (
      <div className="setMetalRate" onClick={onSetMetalRatesClick}>
        <div className="setMetalRateText">{t('set_metal_rate_to_see_the_price')}</div>
        <RightArrow className="rightArrow" />
      </div>
    );
  };

  if (
    existingPrice &&
    !necessaryJewelleryFieldValuesExist &&
    isAllProductTypeDetailsFieldsEmpty &&
    !productStone.length
  ) {
    return null;
  }
  return (
    <div className="productPriceContainer">
      <div className="title">{t('product_price')}</div>
      <div className="priceAndViewCalculation">{showProductPriceOrText()}</div>
    </div>
  );
};
