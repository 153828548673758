import grapesjsBlocksBasic from 'grapesjs-blocks-basic';
// import grapesjsBlocksFlexbox from 'grapesjs-blocks-flexbox';
import grapesjsComponentCountdown from 'grapesjs-component-countdown';
import grapesjsCustomCode from 'grapesjs-custom-code';
// import grapesjsLorySlider from 'grapesjs-lory-slider';
import grapesjsPluginExport from 'grapesjs-plugin-export';
import grapesjsStyleBg from 'grapesjs-style-bg';
import grapesjsStyleFilter from 'grapesjs-style-filter';
import grapesjsStyleGradient from 'grapesjs-style-gradient';
import grapesjsTouch from 'grapesjs-touch';
import grapesjsTuiImageEditor from 'grapesjs-tui-image-editor';
import grapesjsPresetWebpage from 'grapesjs-preset-webpage';
import grapesjsPluginForms from 'grapesjs-plugin-forms';
import grapesjsParserPostcss from 'grapesjs-parser-postcss';
import grapesjsTabs from 'grapesjs-tabs';
import grapesjsTooltip from 'grapesjs-tooltip';
import grapesjsTyped from 'grapesjs-typed';
import { getI18N } from 'qs-services/i18N';

import {
  PRODUCT_GRID_TILE_CLASSNAMES_PREFIX,
  PRODUCT_LIST_TILE_CLASSNAMES_PREFIX,
  PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX,
  CATALOGUE_GRID_TILE_CLASSNAMES_PREFIX
} from '../Constants';

export const bulletListPlugin = editor => {
  const { t } = getI18N();
  editor.DomComponents.addType('bullet-list', {
    model: {
      defaults: {
        copyable: true,
        'custom-name': t('list'),
        draggable: true,
        editable: true,
        removable: true,
        tagName: 'ul',
        attributes: {}
      }
    }
  });
  editor.BlockManager.add('bullet-list', {
    id: 'bullet-list',
    type: 'bullet-list',
    label: t('list'),
    activate: true,
    media: '<svg viewBox="0 0 109 44" fill="none" xmlns="http://www.w3.org/2000/svg" height="52" width="52" style="height: 52px; width: 52px;"><circle cx="5" cy="5" r="5" fill="#57DD9C" /><circle cx="5" cy="22" r="5" fill="#57DD9C" /><circle cx="5" cy="39" r="5" fill="#57DD9C" /><rect x="23" y="2" width="86" height="7" rx="3.5" fill="#57DD9C" /><rect x="23" y="19" width="86" height="7" rx="3.5" fill="#57DD9C" /><rect x="23" y="36" width="86" height="7" rx="3.5" fill="#57DD9C" /></svg>',
    category: 'Basic',
    copyable: true,
    'custom-name': t('list'),
    draggable: true,
    editable: true,
    removable: true,
    tagName: 'ul',
    attributes: {},
    content: {
      attributes: {
        className: 'gjs-component'
      },
      tagName: 'ul',
      type: 'text',
      components: [
        {
          tagName: 'li',
          type: 'text',
          content: t('list_item')
        },
        {
          tagName: 'li',
          type: 'text',
          content: t('list_item')
        }
      ]
    },
  });
};

export const inputTraitsPlugin = editor => {
  const { t } = getI18N();
  editor.DomComponents.addType('input', {
    isComponent: el => el.tagName === 'INPUT',
    model: {
      defaults: {
        traits: [
          {
            type: 'text',
            name: 'name',
            label: t('name'),
            placeholder: t('enter_name')
          },
          {
            type: 'text',
            name: 'placeholder',
            label: t('placeholder'),
            placeholder: t('enter_placeholder')
          },
          {
            type: 'text',
            name: 'pattern',
            label: t('pattern'),
            placeholder: t('enter_pattern')
          },
          {
            type: 'text',
            name: 'minLength',
            label: t('min_length'),
            placeholder: t('enter_min_length')
          },
          {
            type: 'text',
            name: 'maxLength',
            label: t('max_length'),
            placeholder: t('enter_max_length')
          },
          {
            type: 'select',
            label: t('input_type'),
            name: 'type',
            placeholder: t('enter_input_type'),
            options: [
              { id: 'text', name: t('text') },
              { id: 'email', name: t('email') },
              { id: 'password', name: t('password') },
              { id: 'number', name: t('number') }
            ]
          },
          {
            type: 'checkbox',
            name: 'required',
            label: t('required'),
            placeholder: t('enter_required')
          },
          {
            type: 'text',
            name: 'autocapitalize',
            label: t('auto_capitalize'),
            placeholder: t('enter_auto_capitalize')
          },
          {
            type: 'text',
            name: 'autocomplete',
            label: t('auto_complete'),
            placeholder: t('enter_auto_complete')
          },
          {
            type: 'text',
            name: 'autocorrect',
            label: t('auto_correct'),
            placeholder: t('enter_auto_correct')
          },
          {
            type: 'text',
            name: 'autosave',
            label: t('auto_save'),
            placeholder: t('enter_auto_save')
          },
          {
            type: 'checkbox',
            name: 'autofocus',
            label: t('auto_focus'),
            placeholder: t('enter_auto_focus')
          },
          {
            type: 'checkbox',
            name: 'readonly',
            label: t('readonly'),
            placeholder: t('enter_readonly')
          },
          {
            type: 'checkbox',
            name: 'disabled',
            label: t('disabled'),
            placeholder: t('enter_disabled')
          },
          {
            type: 'checkbox',
            name: 'spellcheck',
            label: t('spell_check'),
            placeholder: t('enter_spell_check')
          }
        ]
      }
    }
  });
};

export const svgTraitsPlugin = editor => {
  const { t } = getI18N();
  editor.DomComponents.addType('svg', {
    isComponent: el =>
      el.tagName === 'SVG' ||
      el.tagName === 'G' ||
      el.tagName === 'PATH' ||
      el.tagName === 'LINE' ||
      el.tagName === 'POLYGON' ||
      el.tagName === 'RECT' ||
      el.tagName === 'ELLIPSE' ||
      el.tagName === 'CIRCLE',
    model: {
      defaults: {
        traits: [
          {
            type: 'text',
            name: 'stroke',
            label: t('stroke_color'),
            placeholder: t('enter_stroke_color'),
            default: 'none'
          },
          {
            type: 'number',
            name: 'stroke-width',
            label: t('stroke_width'),
            placeholder: t('enter_stroke_width'),
            units: [''],
            unit: '',
            default: 0,
            min: 0,
            step: 1
          },
          {
            type: 'color',
            name: 'fill',
            label: t('fill_color'),
            placeholder: t('enter_fill_color'),
            default: 'none'
          },
          {
            type: 'text',
            name: 'fill-rule',
            label: t('fill_rule'),
            placeholder: t('enter_fill_rule'),
            default: 'none'
          },
          {
            type: 'number',
            name: 'fill-opacity',
            label: t('fill_opacity'),
            placeholder: t('enter_fill_opacity'),
            units: [''],
            unit: '',
            default: 1,
            min: 0,
            max: 1,
            step: 0.1
          }
        ]
      }
    }
  });
};

export const defaultImageTraitsPlugin = editor => {
  const { t } = getI18N();
  editor.DomComponents.addType('image', {
    isComponent: el =>
      (!el.classList ||
        [...el.classList].findIndex(className =>
          className.includes(PRODUCT_GRID_TILE_CLASSNAMES_PREFIX) ||
          className.includes(PRODUCT_LIST_TILE_CLASSNAMES_PREFIX) ||
          className.includes(PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX) ||
          className.includes(CATALOGUE_GRID_TILE_CLASSNAMES_PREFIX)
        ) === -1) &&
      el.tagName === 'IMG',
    model: {
      defaults: {
        traits: [
          {
            type: 'text',
            name: 'alt',
            label: t('image_alt_text'),
            placeholder: t('enter_image_alt_text')
          },
          {
            type: 'number',
            name: 'height',
            label: t('height'),
            placeholder: t('enter_height'),
            min: 0,
            step: 1
          },
          {
            type: 'number',
            name: 'width',
            label: t('width'),
            placeholder: t('enter_width'),
            min: 0,
            step: 1
          },
          {
            type: 'select',
            label: t('loading'),
            name: 'loading',
            placeholder: t('enter_loading_type'),
            defaults: 'lazy',
            options: [
              { id: 'lazy', name: t('lazy') },
              { id: 'eager', name: t('eager') }
            ]
          },
          {
            type: 'select',
            label: t('decoding'),
            name: 'decoding',
            placeholder: t('enter_decoding_type'),
            defaults: 'sync',
            options: [
              { id: 'auto', name: t('auto') },
              { id: 'sync', name: t('sync') },
              { id: 'async', name: t('async') }
            ]
          }
        ]
      }
    }
  });
};

export const quickSellImageTraitsPlugin = editor => {
  const { t } = getI18N();
  editor.DomComponents.addType('image', {
    isComponent: el =>
      (!el.classList ||
        [...el.classList].findIndex(className =>
          className.includes(PRODUCT_GRID_TILE_CLASSNAMES_PREFIX) ||
          className.includes(PRODUCT_LIST_TILE_CLASSNAMES_PREFIX) ||
          className.includes(PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX) ||
          className.includes(CATALOGUE_GRID_TILE_CLASSNAMES_PREFIX)
        ) > -1) &&
      el.tagName === 'IMG',
    model: {
      defaults: {
        traits: [
          {
            type: 'select',
            label: t('loading'),
            name: 'loading',
            placeholder: t('enter_loading_type'),
            defaults: 'lazy',
            options: [
              { id: 'lazy', name: t('lazy') },
              { id: 'eager', name: t('eager') }
            ]
          },
          {
            type: 'select',
            label: t('decoding'),
            name: 'decoding',
            placeholder: t('enter_decoding_type'),
            defaults: 'sync',
            options: [
              { id: 'auto', name: t('auto') },
              { id: 'sync', name: t('sync') },
              { id: 'async', name: t('async') }
            ]
          }
        ]
      }
    }
  });
};

export const videoTraitsPlugin = editor => {
  const { t } = getI18N();
  editor.DomComponents.addType('video', {
    isComponent: el => el.tagName === 'VIDEO' || el.tagName === 'IFRAME',
    model: {
      defaults: {
        traits: [
          {
            type: 'text',
            name: 'poster',
            label: t('poster_image'),
            placeholder: `${t('example')}: ./media/image.jpg`
          },
          {
            type: 'number',
            name: 'height',
            label: t('height'),
            placeholder: t('enter_height'),
            min: 0,
            step: 1
          },
          {
            type: 'number',
            name: 'width',
            label: t('width'),
            placeholder: t('enter_width'),
            min: 0,
            step: 1
          },
          {
            type: 'select',
            label: t('preload'),
            name: 'preload',
            placeholder: t('enter_preload'),
            defaults: 'auto',
            options: [
              { id: 'auto', name: t('auto') },
              { id: 'metadata', name: t('metadata') },
              { id: 'none', name: t('none') }
            ]
          },
          {
            type: 'text',
            name: 'title',
            label: t('title'),
            placeholder: t('enter_title')
          },
          {
            type: 'checkbox',
            name: 'controls',
            label: t('controls'),
            placeholder: t('enter_controls')
          },
          {
            type: 'checkbox',
            name: 'playsinline',
            label: t('plays_inline'),
            placeholder: t('enter_plays_inline')
          },
          {
            type: 'checkbox',
            name: 'muted',
            label: t('muted'),
            placeholder: t('enter_muted')
          },
          {
            type: 'checkbox',
            name: 'loop',
            label: t('loop'),
            placeholder: t('enter_loop')
          },
          {
            type: 'checkbox',
            name: 'autoplay',
            label: t('autoplay'),
            placeholder: t('enter_autoplay')
          }
        ]
      }
    }
  });
};

export const GRAPES_JS_PLUGINS = [
  editor =>
    grapesjsBlocksBasic(editor, {
      flexGrid: true,
      category: 'Basic',
      addBasicStyle: true,
      blocks: [
        'column1',
        'column2',
        'column3',
        'column4',
        'column3-7',
        'text',
        'link',
        'image',
        'video',
        'map'
      ]
    }),
  // editor => {
  //   const { t } = getI18N();
  //   return grapesjsBlocksFlexbox(editor, {
  //     labelRow: t('row'),
  //     labelColumn: t('column'),
  //     flexboxBlock: {}
  //   })
  // },
  editor => {
    const { t } = getI18N();
    return grapesjsComponentCountdown(editor, {
      label: t('countdown'),
      endText: t('expired'),
      labelDays: t('days'),
      labelHours: t('hours'),
      labelMinutes: t('minutes'),
      labelSeconds: t('seconds')
    })
  },
  editor => {
    const { t } = getI18N();
    return grapesjsCustomCode(editor, {
      modalTitle: t('insert_custom_code'),
      buttonLabel: t('save')
    });
  },
  // editor => {
  //   const { t } = getI18N();
  //   return grapesjsLorySlider(editor, {
  //     sliderBlock: {
  //       label: t('slider')
  //     },
  //     sliderProps: {
  //       name: t('slider')
  //     }
  //   });
  // },
  editor => {
    const { t } = getI18N();
    return grapesjsPluginExport(editor, {
      addExportBtn: true,
      btnLabel: t('download_html_css_code'),
      filenamePfx: 'quicksell_custom_component'
    });
  },
  editor => grapesjsStyleBg(editor, {}),
  editor => grapesjsStyleFilter(editor, {}),
  editor => grapesjsStyleGradient(editor, {}),
  editor => grapesjsTouch(editor, {}),
  editor => {
    const { t } = getI18N();
    return grapesjsTabs(editor, {
      tabsBlock: {
        label: t('tabs'),
        category: 'Extra'
      },
      tabsProps: {
        name: t('tabs')
      }
    })
  },
  editor => {
    const { t } = getI18N();
    return grapesjsTooltip(editor, {
      labelTooltip: t('tooltip')
    });
  },
  editor =>
    grapesjsTuiImageEditor(editor, {
      script: [
        'https://uicdn.toast.com/tui.code-snippet/v1.5.2/tui-code-snippet.min.js',
        'https://uicdn.toast.com/tui-color-picker/v2.2.7/tui-color-picker.min.js',
        'https://uicdn.toast.com/tui-image-editor/v3.15.2/tui-image-editor.min.js'
      ],
      style: [
        'https://uicdn.toast.com/tui-color-picker/v2.2.7/tui-color-picker.min.css',
        'https://uicdn.toast.com/tui-image-editor/v3.15.2/tui-image-editor.min.css'
      ],
      config: {
        includeUI: {
          initMenu: 'filter',
          menuBarPosition: 'bottom'
        },
        cssMaxWidth: 700,
        cssMaxHeight: 500,
        usageStatistics: false
      }
    }),
  editor => {
    const { t } = getI18N();
    return grapesjsPresetWebpage(editor, {
      modalImportTitle: t('import_html_css_code'),
      modalImportLabel: `<div style="margin-bottom: 10px; font-size: 13px;">${t(
        'paste_your_html_css_here_and_click_Import'
      )}</div>`,
      modalImportButton: t('import'),
      textCleanCanvas: t('are_you_sure_you_want_to_clear_all_the_html_css'),
      modalImportContent: editor => {
        return editor.getWrapper().getInnerHTML() + '<style>' + editor.getCss() + '</style>';
      }
    });
  },
  editor => grapesjsPluginForms(editor, {
    blocks: [
      'form',
      'input',
      'textarea',
      'select',
      'button',
      'label',
      'checkbox',
      'radio'
    ]
  }),
  editor => grapesjsParserPostcss(editor, {}),
  editor => grapesjsTooltip(editor, {}),
  editor =>
    grapesjsTyped(editor, {
      block: {
        category: 'Extra',
        content: {
          type: 'typed',
          'type-speed': 40,
          strings: ['Text row one', 'Text row two', 'Text row three']
        }
      }
    }),
  bulletListPlugin,
  inputTraitsPlugin,
  svgTraitsPlugin,
  defaultImageTraitsPlugin,
  quickSellImageTraitsPlugin,
  videoTraitsPlugin
];
