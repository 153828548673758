import React, { useCallback, useRef, useState } from 'react';

import WhatsappImage from 'qs-assets/Media/whatsapp.png';
import BrochureImage from 'qs-assets/Media/brochure.png';
import PdfImage from 'qs-assets/Media/colouredPdf.png';
import FacebookImage from 'qs-assets/Media/facebook-bg.png';
import EmailImage from 'qs-assets/Media/mail.png';
import DownloadBlack from 'qs-assets/Media/downloadBlack.png';
import ExcelImage from 'qs-assets/Media/excel.png';
import {
  openWhatsApp,
  downloadBrochure,
  downloadPdf,
  openFacebook,
  openMail,
  downloadCatalogueImages,
  downloadCatalogueExcel
} from 'qs-helpers';
import CatalogueLib from 'qs-data-manager/Catalogues';
import GrtIcon from 'qs-assets/Media/ic-grt-logo.png';
import { GRT_PM_META } from 'qs-data-manager';
import eventbus from 'eventing-bus';
import Mixpanel from 'qs-data-manager/Mixpanel';
import './styles.scss';
import Modal from 'react-responsive-modal';
import { Checkbox } from '@material-ui/core';
import { useMemo } from 'react';
import { makeStyles } from '@material-ui/styles';
import OnePerPageIcon from '../../../ReactComponentOfSVGIcons/OnePerPageIcon';
import TwoPerPageIcon from '../../../ReactComponentOfSVGIcons/TwoPerPageIcon';
import FourPerPageIcon from '../../../ReactComponentOfSVGIcons/FourPerPageIcon';
import { getI18N } from 'qs-services/i18N';

const SHARE_OPTIONS = {
  GRT_PM: 'GRT_PM',
  WHATSAPP: 'WHATSAPP',
  BROCHURE: 'BROCHURE',
  PDF: 'PDF',
  FB: 'FB',
  EMAIL: 'EMAIL',
  DOWNLOAD_IMAGES: 'DOWNLOAD_IMAGES',
  DOWNLOAD_EXCEL: 'DOWNLOAD_EXCEL'
};
const PER_PAGE = {
  ONE_PER_PAGE_VALUE: 1,
  TWO_PER_PAGE_VALUE: 2,
  FOUR_PER_PAGE_VALUE: 4
};

const useStyles = makeStyles({
  root: {
    padding: '0px 5px 0px 0px',
    height: '24px',
    width: '24px',
    '&.Mui-checked': {
      color: '#4da47a'
    },
    '&:hover': {
      backgroundColor: 'transparent !important'
    }
  }
});

export default ({ catalogueId, closePopover } = {}) => {
  const { t } = getI18N();
  const classes = useStyles();
  const modalStyles = useRef({
    backgroundColor: 'white',
    height: '465px',
    width: '320px',
    borderRadius: '4px',
    padding: '8px 8px 0px 8px'
  });
  const [isGrtPM] = useState(GRT_PM_META.isGrtPM);
  const [showGeneratePDF, setShowGeneratePDF] = useState(false);
  const [perPage, setPerPage] = useState(PER_PAGE.FOUR_PER_PAGE_VALUE);

  const [productCheckBoxes, setProductCheckBoxes] = useState({
    showProductName: { enabled: true, label: t('product_name') },
    showPrice: { enabled: true, label: t('price') },
    showSku: { enabled: true, label: 'SKU' },
    showVariants: { enabled: true, label: t('variants') },
    showDesc: { enabled: false, label: t('description') }
  });

  const showSelectAll = useMemo(() => {
    return Object.values(productCheckBoxes).every(({ label, enabled }) => {
      if (perPage === PER_PAGE.FOUR_PER_PAGE_VALUE && label === 'Description') {
        return true;
      }
      return enabled;
    });
  }, [productCheckBoxes, perPage]);

  const setOption = e => {
    const { name, checked } = e.target;
    setProductCheckBoxes(previousState => ({
      ...previousState,
      [name]: { ...previousState[name], enabled: checked }
    }));
  };

  const generatePDFModal = useCallback(() => {
    setShowGeneratePDF(true);
    closePopover();
  }, [closePopover]);
  const setPerPageValue = value => {
    setPerPage(value);
    if (value === PER_PAGE.FOUR_PER_PAGE_VALUE && productCheckBoxes.showDesc.enabled) {
      setProductCheckBoxes({
        ...productCheckBoxes,
        showDesc: { ...productCheckBoxes.showDesc, enabled: false }
      });
    }
  };

  const toggleCheckbox = value => {
    setProductCheckBoxes(previousState =>
      Object.entries(previousState).reduce((acc, [name, { label }]) => {
        if (label === 'Description' && perPage === PER_PAGE.FOUR_PER_PAGE_VALUE) {
          acc[name] = { label, enabled: false };
          return acc;
        }
        acc[name] = { label, enabled: value };
        return acc;
      }, {})
    );
  };

  const generatePDFClick = () => {
    const productDetailsChecboxValues = Object.entries(productCheckBoxes).reduce(
      (acc, [name, { enabled }]) => {
        acc[name] = enabled;
        return acc;
      },
      {}
    );
    const generatePDFObject = {
      productsPerPage: perPage,
      catalogueId,
      ...productDetailsChecboxValues
    };
    downloadPdf(generatePDFObject);
    onClose();
  };

  const renderProductDetailsOptions = () =>
    Object.entries(productCheckBoxes).map(([name, { label, enabled }], index) => {
      return (
        <div
          key={index}
          className={`singleProductDetailsOption ${
            perPage === PER_PAGE.FOUR_PER_PAGE_VALUE && label === 'Description'
              ? 'disabledSingleProductDetailsOption'
              : ''
          }`}
        >
          <div class="productDetailsOptionsTitle">{label}</div>
          <Checkbox
            disableRipple={true}
            checked={enabled}
            disabled={perPage === PER_PAGE.FOUR_PER_PAGE_VALUE && label === 'Description'}
            onChange={setOption}
            name={name}
            classes={{ root: classes.root }}
          />
        </div>
      );
    });

  const onClose = () => {
    setShowGeneratePDF(false);
  };

  const renderGeneratePDFModal = () => {
    return (
      <Modal
        open={showGeneratePDF}
        center={true}
        onClose={onClose}
        showCloseIcon={false}
        styles={{ modal: modalStyles.current }}
      >
        <div className="modal">
          <div className="modalTitle">{t('choose_layout')}</div>
          <div className="productsPerPageContainer">
            <div className="productsPerPageContainerTitle">{t('products_per_page')}</div>
            <div className="productsPerPageContainerIconContainer">
              <div
                className={`perPageContainer ${
                  perPage === PER_PAGE.FOUR_PER_PAGE_VALUE ? 'selectedPerPageContainer' : ''
                }`}
                onClick={() => setPerPageValue(PER_PAGE.FOUR_PER_PAGE_VALUE)}
              >
                <FourPerPageIcon />
                <div className="perPageText">{t('per_page', { count: '4' })}</div>
              </div>
              <div
                className={`perPageContainer ${
                  perPage === PER_PAGE.TWO_PER_PAGE_VALUE ? 'selectedPerPageContainer' : ''
                }`}
                onClick={() => setPerPageValue(PER_PAGE.TWO_PER_PAGE_VALUE)}
              >
                <TwoPerPageIcon />
                <div className="perPageText">{t('per_page', { count: '2' })}</div>
              </div>
              <div
                className={`perPageContainer ${
                  perPage === PER_PAGE.ONE_PER_PAGE_VALUE ? 'selectedPerPageContainer' : ''
                }`}
                onClick={() => setPerPageValue(PER_PAGE.ONE_PER_PAGE_VALUE)}
              >
                <OnePerPageIcon />
                <div className="perPageText">{t('per_page', { count: '1' })}</div>
              </div>
            </div>
          </div>
          <div class="shareProductDetailsContainer">
            <div class="shareProductDetailsHeader">
              <div>{t('show_product_details')}</div>
              <div className="selectAll" onClick={() => toggleCheckbox(!showSelectAll)}>
                {showSelectAll ? t('clear_all') : t('select_all')}
              </div>
            </div>
            <div class="productDetailsOptions">{renderProductDetailsOptions()}</div>
          </div>
          <div className="buttonContainer">
            <button type="button" className="buttonPlain buttonCancel" onClick={onClose}>
              {t('cancel')}
            </button>
            <button
              type="submit"
              className=" buttonPlain buttonGeneratePDF"
              onClick={generatePDFClick}
            >
              {t('generate_pdf')}
            </button>
          </div>
        </div>
      </Modal>
    );
  };

  const onOptionClick = useCallback(
    option => {
      let eventName = '';
      switch (option) {
        case SHARE_OPTIONS.WHATSAPP: {
          const link = CatalogueLib.getCatalogueLinkFromCache(catalogueId);
          openWhatsApp({ text: link });
          eventName = 'shared_via_whatsapp';
          break;
        }

        case SHARE_OPTIONS.BROCHURE: {
          downloadBrochure({ catalogueId });
          eventName = 'brochure_downloaded';
          break;
        }

        case SHARE_OPTIONS.PDF: {
          generatePDFModal();
          eventName = 'pdf_downloaded';
          break;
        }

        case SHARE_OPTIONS.FB: {
          const link = CatalogueLib.getCatalogueLinkFromCache(catalogueId);
          openFacebook({ text: link });
          eventName = 'shared_via_downloaded';
          break;
        }

        case SHARE_OPTIONS.EMAIL: {
          const link = CatalogueLib.getCatalogueLinkFromCache(catalogueId);
          openMail({ text: link });
          eventName = 'shared_via_email';
          break;
        }

        case SHARE_OPTIONS.DOWNLOAD_IMAGES: {
          downloadCatalogueImages({ catalogueId });
          eventName = 'catalogue_images_downloaded';
          break;
        }

        case SHARE_OPTIONS.DOWNLOAD_EXCEL: {
          downloadCatalogueExcel({ catalogueId });
          eventName = 'catalogue_excel_downloaded';
          break;
        }

        default:
          break;
      }

      Mixpanel.sendEvent({
        eventName,
        props: {
          catalogueId
        }
      });
    },
    [catalogueId, generatePDFModal]
  );

  const openGrtModal = useCallback(() => {
    const catalogueName = CatalogueLib.getCatalogueTitle({ catalogueId });

    const link = CatalogueLib.getCatalogueLinkFromCache();

    if (typeof link !== 'string') {
      return;
    }

    const data = {
      showModal: true,
      catalogueLink: link,
      catalogueName
    };

    eventbus.publish(GRT_PM_META.EVENT_BUS_KEY, data);
  }, [catalogueId]);

  return (
    <div id={'shareOptions'}>
      {isGrtPM && (
        <div className={`option`}>
          <div className={'iconContainer'} onClick={openGrtModal}>
            <img src={GrtIcon} alt="GRT icon" className={'icon'} />
            <div>{t('showrooms')}</div>
          </div>
        </div>
      )}
      <div onClick={() => onOptionClick(SHARE_OPTIONS.WHATSAPP)} className={`option`}>
        <div className={'iconContainer'}>
          <img src={WhatsappImage} alt="" className={'icon'} />
        </div>
        <div className={'optionName'}>{t('whatsapp')}</div>
      </div>
      <div onClick={() => onOptionClick(SHARE_OPTIONS.BROCHURE)} className={'option'}>
        <div className={'iconContainer'}>
          <img src={BrochureImage} alt="" className={'icon'} />
        </div>
        <div className={'optionName'}>{t('brochure')}</div>
      </div>
      <div onClick={() => onOptionClick(SHARE_OPTIONS.PDF)} className={'option'}>
        <div className={'iconContainer'}>
          <img src={PdfImage} className={'icon pdfIcon'} alt="" />
        </div>
        <div className={'optionName'}>{t('PDF')}</div>
      </div>
      <div onClick={() => onOptionClick(SHARE_OPTIONS.FB)} className={'option'}>
        <div className={'iconContainer'}>
          <img src={FacebookImage} className={'icon facebook'} alt="" />
        </div>
        <div className={'optionName'}>{t('facebook')}</div>
      </div>
      <div onClick={() => onOptionClick(SHARE_OPTIONS.EMAIL)} className={'option'}>
        <div className={'iconContainer'}>
          <img src={EmailImage} className={'icon emailIcon'} alt="" />
        </div>
        <div className={'optionName'}>{t('email')}</div>
      </div>
      <div onClick={() => onOptionClick(SHARE_OPTIONS.DOWNLOAD_IMAGES)} className={'option'}>
        <div className={'iconContainer'}>
          <img src={DownloadBlack} className={'icon'} alt="" />
        </div>
        <div className={'optionName'}>{t('download')}</div>
      </div>
      <div onClick={() => onOptionClick(SHARE_OPTIONS.DOWNLOAD_EXCEL)} className={'option'}>
        <div className={'iconContainer'}>
          <img src={ExcelImage} className={'icon'} alt="" />
        </div>
        <div className={'optionName'}>{t('excel')}</div>
      </div>
      {showGeneratePDF && renderGeneratePDFModal()}
    </div>
  );
};
