import React from 'react';
import OrderKeyValue from '../OrderKeyValue';
import { getMinimumPriceForOrder } from 'qs-helpers/Orders/OrderPriceCalculator';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

export default ({ inquiryData, marginScrollOffset }) => {
  const { t } = getI18N();

  return (
    <div className="orderFooterContainer" style={{ marginRight: marginScrollOffset }}>
      <div className="orderItemsTotalContainer">
        <span>
          {inquiryData.inquiries.length} {t(inquiryData.inquiries.length === 1 ? 'item' : 'items')}
        </span>
        <OrderKeyValue
          rows={[
            {
              label: <div className="totalLabel">{t('total')}</div>,
              value: getMinimumPriceForOrder(inquiryData),
              valueContainerClass: 'totalValue'
            }
          ]}
          padding={false}
        />
      </div>
    </div>
  );
};
