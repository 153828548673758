import React, { useEffect } from 'react';
import Mixpanel from 'qs-data-manager/Mixpanel';
import { PRICE_FILTER_KEYS_CONFIG, PRICE_FILTER_OPTIONS } from 'qs-helpers/Products/constants';
import {
  UPDATE_PRODUCT_LIST_PRICE_FILTER,
  UPDATE_PRODUCT_LIST_PRICE_FILTER_OPTION
} from '../../../reducer';
import { isValidNumber } from 'qs-helpers';
import FilterInput from '../FilterInput';
import { isRangeInputDataValid } from 'qs-helpers/Products/ProductsListFilterHelper';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

export default ({
  currentPriceFilters,
  lastSeenPriceValue,
  selectedOption,
  setProductListMeta,
  registerFilterCallback,
  unRegisterFilterCallback
} = {}) => {
  const { t } = getI18N();
  useEffect(() => {
    const applyPriceFilter = ({
      currentPriceFilters: priceFiltersToValidate,
      currentPriceFilterSelectedOption: selectedOptionId
    }) => {
      const analyticsData = {};
      if (
        !isRangeInputDataValid(selectedOptionId, priceFiltersToValidate, {
          [PRICE_FILTER_KEYS_CONFIG.RANGE.keyMap.gtKey]: { message: 'Min price is missing' },
          [PRICE_FILTER_KEYS_CONFIG.RANGE.keyMap.ltKey]: { message: 'Max price is missing' },
          [PRICE_FILTER_KEYS_CONFIG.RANGE.id]: { message: 'Max price must be more than min price' }
        })
      ) {
        return false;
      }

      if (selectedOptionId === PRICE_FILTER_KEYS_CONFIG.RANGE.id) {
        const {
          gtKey,
          analyticsGtKey,
          ltKey,
          analyticsLtKey
        } = PRICE_FILTER_KEYS_CONFIG.RANGE.keyMap;
        const {
          [selectedOptionId]: { [gtKey]: savedGtKey, [ltKey]: savedLtKey } = {}
        } = priceFiltersToValidate;

        if (savedGtKey) {
          analyticsData[analyticsGtKey] = true;
        }
        if (savedLtKey) {
          analyticsData[analyticsLtKey] = true;
        }
      } else {
        const { key, analyticsKey } = PRICE_FILTER_KEYS_CONFIG[selectedOptionId].keyMap;
        const { [selectedOptionId]: { [key]: savedKey } = {} } = priceFiltersToValidate;
        if (savedKey) {
          analyticsData[analyticsKey] = true;
        }
      }

      if (Object.keys(analyticsData).length > 0) {
        Mixpanel.sendEvent({
          eventName: 'filter_applied',
          props: analyticsData
        });
      }

      return true;
    };

    registerFilterCallback(applyPriceFilter);

    return () => unRegisterFilterCallback(applyPriceFilter);
  }, [registerFilterCallback, unRegisterFilterCallback]);

  const onFilterOptionSelected = (optionId, key) => {
    setProductListMeta({
      type: UPDATE_PRODUCT_LIST_PRICE_FILTER_OPTION,
      priceFilterOption: optionId,
      key
    });
  };

  const renderFilterOptions = ({ optionId, title, key }) => {
    const selectedOptionStyles = selectedOption === optionId ? 'option selectedOption' : 'option';

    return (
      <div
        key={optionId}
        className={selectedOptionStyles}
        onClick={() => onFilterOptionSelected(optionId, key)}
      >
        {t(title)}
      </div>
    );
  };

  const setPrice = key => event => {
    event.stopPropagation();
    const { value } = event.target;
    if (value !== '' && !isValidNumber(value)) {
      window.alert(t('please_enter_only_numbers'));
      return;
    }

    setProductListMeta({
      type: UPDATE_PRODUCT_LIST_PRICE_FILTER,
      num: value,
      key
    });
  };

  return (
    <>
      <div className="productPriceFilterContainer titleContainer">{t('filter_by_price')}</div>
      <div className="productPriceFilterContainer optionsContainer">
        {PRICE_FILTER_OPTIONS.map(({ id, title, key }) =>
          renderFilterOptions({ optionId: id, title, key })
        )}
      </div>
      <FilterInput
        priceFilterObj={currentPriceFilters}
        selectedOption={selectedOption}
        defaultInputVal={lastSeenPriceValue}
        onChange={setPrice}
        currencySymbolRequired
        rangeInputClassName="productPriceFilterContainer priceInputContainer"
        priceInputClassName="productPriceFilterContainer priceInputContainer"
      />
    </>
  );
};
