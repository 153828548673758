import { useRef, useCallback, useEffect } from 'react';

export default () => {
  const currentThrottleTimer = useRef(null);
  const minIndex = useRef(Number.MAX_VALUE);
  const variableList = useRef(null);
  const itemHeightMap = useRef(new Map());

  const reRenderListOnItemUpdate = useCallback((index, itemId, height) => {
    if (itemId !== undefined) {
      // Add one because the height will be rounded to an integer
      itemHeightMap.current.set(itemId, height + 1);
    }

    if (index < minIndex.current) {
      minIndex.current = index;
    }

    if (!currentThrottleTimer.current) {
      //Wait a 100 milliseconds to update the data in a batch
      currentThrottleTimer.current = setTimeout(() => {
        const indexResetAfter = minIndex.current;

        //RESET the values to enable throttling for the next update
        minIndex.current = Number.MAX_VALUE;
        currentThrottleTimer.current = null;
        //If last item is removed then the list will no longer be available
        if (variableList.current) {
          variableList.current.resetAfterIndex(indexResetAfter);
        }
      }, 50);
    }
  }, []);

  useEffect(() => {
    return () => {
      clearTimeout(currentThrottleTimer.current);
      currentThrottleTimer.current = null;
      minIndex.current = Number.MAX_VALUE;
    };
  }, []);

  return [variableList, itemHeightMap, reRenderListOnItemUpdate];
};
