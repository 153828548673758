import React, { useRef, useState, useContext } from 'react';
import { ActionButton, useDropDownStyles } from '../Common/ActionButtons';
import { ReactComponent as ContactIcon } from 'qs-assets/Media/contact.svg';
import { ActiveOrderMeta } from '../../../context';
import { getOrderMetaFromCache } from 'qs-data-manager/Orders/OrdersOperations';
import { ReactComponent as CallIcon } from 'qs-assets/Media/phone_disconnected_icon.svg';
import { ReactComponent as ChatIcon } from 'qs-assets/Media/whatsapp_filled_icon.svg';
import MenuDropDown from '../../../../Common/MenuDropDown';
import { handleCallClick, handleChatClick } from 'qs-helpers/ContactHelper';
import { getI18N } from 'qs-services/i18N';

import './styles.scss';

export default ({ buttonClasses }) => {
  const {
    activeOrderMeta: { selectedOrderId }
  } = useContext(ActiveOrderMeta);

  const [showContactMenu, setShowContactMenu] = useState(false);
  const contactButtonRef = useRef();
  const componentStyles = useDropDownStyles();
  const { t } = getI18N();

  const toggleContactMenu = () => {
    setShowContactMenu(prevVal => !prevVal);
  };

  const onCallClick = () => {
    const { visitorPhone } = getOrderMetaFromCache({ orderId: selectedOrderId }) || {};
    handleCallClick(visitorPhone);
    setShowContactMenu(false);
  };
  const onChatClick = () => {
    const { visitorPhone } = getOrderMetaFromCache({ orderId: selectedOrderId }) || {};
    handleChatClick(visitorPhone);
    setShowContactMenu(false);
  };

  const menuItemClasses = { root: componentStyles.menuItemRoot };
  const iconClasses = { root: componentStyles.listIconRoot };
  const menuItemsConfig = [
    {
      classes: menuItemClasses,
      clickHandler: onCallClick,
      label: t('call'),
      icon: <CallIcon className="orderHeaderButtonIcon" />,
      iconClass: iconClasses
    },
    {
      classes: menuItemClasses,
      clickHandler: onChatClick,
      label: t('chat'),
      icon: <ChatIcon className="orderHeaderButtonIcon" />,
      iconClass: iconClasses
    }
  ];

  return (
    <>
      <ActionButton
        classes={{ root: buttonClasses.buttonRoot }}
        startIcon={<ContactIcon className="orderHeaderButtonIcon" />}
        color={'primary'}
        variant="contained"
        onClick={toggleContactMenu}
        ref={contactButtonRef}
      >
        {t('contact_buyer')}
      </ActionButton>
      {showContactMenu && (
        <MenuDropDown
          buttonRef={contactButtonRef.current}
          onClose={toggleContactMenu}
          paperClasses={{ root: componentStyles.paperRoot }}
          menuItemsConfig={menuItemsConfig}
        />
      )}
    </>
  );
};
