import React from 'react';

import { ReactComponent as RightArrow } from 'qs-assets/Media/ic_arrow_right.svg';

import './styles.scss';
import DialogBox from 'qs-components/Common/DialogBox';
import { SORT_PRODUCTS_OPTIONS } from 'qs-helpers/Products/constants';
import { getI18N } from 'qs-services/i18N';

const SortProductsDialog = ({ open, onClose, setTypeOfSort }) => {
  const { t } = getI18N();
  return (
    <DialogBox
      open={open}
      onClose={onClose}
      title={t('sort_products')}
      maxWidth={'xs'}
      dialogOnClick={{
        onClick: event => {
          event.preventDefault();
          event.stopPropagation();
        }
      }}
    >
      <div className={'sortDialogContainer'}>
        <div className={'sortDialogInfo'}>{t('choose_an_option_below_to_sort_your_products')}</div>
        <div className={'sortDialogOptions'}>
          {SORT_PRODUCTS_OPTIONS.map((item, index) => (
            <div key={index} className={'sortDialogOption'} onClick={() => setTypeOfSort(item)}>
              <div className={'sortContent'}>
                <div>{t('sort_by_order', { order: t(item.title) })}</div>
                <div className={'sortSubTitle'}>{t(item.subTitle)}</div>
              </div>
              <RightArrow />
            </div>
          ))}
        </div>
      </div>
    </DialogBox>
  );
};

export default SortProductsDialog;
