import React, { useEffect, useState, Fragment } from 'react';
import { getImagesStatus } from 'qs-data-manager/ProductDetails';
import Loader from 'qs-components/Common/Loader';
import { convertImageUrlSize } from 'qs-helpers';
import { ReactComponent as ErrorIcon } from 'qs-assets/Media/image-error.svg';
import './styles.scss';

export default ({ activeProductIds } = {}) => {
  const mapPicturesToLoaders = (newPictureStates, existingLoadState = {}) => {
    return newPictureStates.reduce((loaderMap, pictureState) => {
      const loaderMapped = existingLoadState[pictureState.id];
      // update all new products with the status loader
      if (typeof loaderMapped === 'undefined') {
        loaderMap[pictureState.id] = 'LOADER';
      } else {
        loaderMap[pictureState.id] = loaderMapped;
      }
      return loaderMap;
    }, {});
  };

  const [picturesState, setPicturesState] = useState(() => getImagesStatus({ activeProductIds }));
  const [picturesLoadState, setPicturesLoadState] = useState(() =>
    mapPicturesToLoaders(picturesState)
  );

  useEffect(() => {
    const updatedProducts = getImagesStatus({ activeProductIds });
    setPicturesState(updatedProducts);
    setPicturesLoadState(prevState => mapPicturesToLoaders(updatedProducts, prevState));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [(activeProductIds || []).length]);

  const handleImageLoad = (pictureId, state) => () => {
    setPicturesLoadState(prevState => ({
      ...prevState,
      [pictureId]: state
    }));
  };

  const getImageUiBasedOnState = pictureState => {
    if (
      (pictureState.error && !picturesState.prepared) ||
      picturesLoadState[pictureState.id] === 'LOAD_ERROR'
    ) {
      return (
        <div key={pictureState.id} className="imageDisplayArea errorIconContainer">
          <ErrorIcon className={'errorIcon'} />
        </div>
      );
    }

    const url = convertImageUrlSize({ size: 100, url: pictureState.url });

    return (
      <div
        key={pictureState.id}
        className={`bulkImageContainer ${pictureState.prepared ? '' : 'imageDisplayArea'}`}
      >
        {picturesLoadState[pictureState.id] === 'LOADER' && (
          <div className={'loader'}>
            <Loader size={'small'} />
          </div>
        )}
        {pictureState.prepared && (
          <Fragment>
            <div
              style={{ backgroundImage: `url(${url})` }}
              className={'imageDisplayArea imageAspectRatio bulkBlurredImage'}
            />
            <div
              style={{ backgroundImage: `url(${url})` }}
              className={'imageDisplayArea imageAspectRatio nonBlurredBulkImage'}
            />
            <img
              onError={handleImageLoad(pictureState.id, 'LOAD_ERROR')}
              onLoad={handleImageLoad(pictureState.id, 'HIDE_LOADER')}
              src={url}
              className={'ProductImage previewImage'}
              alt={''}
            />
          </Fragment>
        )}
      </div>
    );
  };

  return (
    <div id={'multipleProductSelectedImages'}>
      <div className={'selectedItemsHeader'}>{activeProductIds.length} items selected</div>

      <div className={'imageContainer'}>
        {picturesState.map(pictureState => {
          return getImageUiBasedOnState(pictureState);
        })}
      </div>
    </div>
  );
};
