import React, { useState, useEffect, useRef } from 'react';
import { selectedProducts } from 'qs-data-manager/Selected';
import UploadButtonsFooter from '../UploadButtonsFooter';
import './styles.scss';
import { EDIT_ACTIVE } from 'qs-helpers/Products/constants';

export default ({ toggleProductLibrary, onButtonsVisible }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [translateAmount, setTranslateAmount] = useState(-1000);
  const editContainerRef = useRef(null);

  useEffect(() => {
    const removeFileUploaderFromView = () => {
      setTranslateAmount(-1000);
      onButtonsVisible(0);
    };

    const hideFileUploaderFromView = () => {
      onButtonsVisible(0);
      setTranslateAmount(-editContainerRef.current.offsetHeight);
    };

    const onProductsSelectionChange = () => {
      if (selectedProducts.isAnySelected()) {
        setIsVisible(false);
        if (!editContainerRef.current) {
          removeFileUploaderFromView();
          return;
        }

        hideFileUploaderFromView();
      }
    };

    const onSelectionStateChange = isActive => {
      setIsVisible(!!isActive);

      /*
        Ref is not available, there is no way to know the height of the buttons
        and hence hide the edit buttons completely and also don't update the
        buttons visible height
      */
      if (!editContainerRef.current) {
        removeFileUploaderFromView();
        return;
      }

      if (isActive) {
        //Attach product selection listener only if active listener for EDIT mode was hit
        selectedProducts.removeListener(onProductsSelectionChange);
        selectedProducts.addListener(onProductsSelectionChange);
        setTranslateAmount(0);
        onButtonsVisible(editContainerRef.current.offsetHeight);
        return;
      }

      hideFileUploaderFromView();
    };

    selectedProducts.addActiveListener(EDIT_ACTIVE, onSelectionStateChange);

    return () => {
      selectedProducts.removeActiveListener(EDIT_ACTIVE, onSelectionStateChange);
      selectedProducts.removeListener(onProductsSelectionChange);
    };
  }, [onButtonsVisible]);

  return (
    <UploadButtonsFooter
      containerClassName={`filePickerProducts ${isVisible ? ' showFilePicker' : ''}`}
      toggleProductLibrary={toggleProductLibrary}
      ref={editContainerRef}
      style={{ bottom: translateAmount }}
      disablebutton={!isVisible}
    />
  );
};
