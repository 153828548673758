import React from 'react';
import { FormControl, Select, Input, makeStyles, MenuItem } from '@material-ui/core';
import { getI18N } from 'qs-services/i18N';

const useSelectStyles = makeStyles({
  formControlRoot: {
    borderRadius: '4px',
    padding: '4px 12px',
    backgroundColor: props => props.backgroundColor || 'rgb(24, 31, 39)',
    cursor: 'pointer'
  },
  formControlUnselected: {
    border: props => props.unselectedBorder || '2px solid rgb(129, 146, 167)',
    '&:hover': {
      backgroundColor: props => props.unselectedHoverFill || '#121821'
    }
  },
  formControlSelected: {
    border: props => props.formSelectionBorder || '2px solid #1FE49A',
    '&:hover': {
      backgroundColor: props => props.selectedHoverFill
    }
  },
  selectControlUnselected: {
    color: props => props.unselectTextColor || 'rgb(186, 203, 222)',
    '&:hover': {
      color: props => props.unselectedHoverColor
    }
  },
  selectControlSelected: {
    color: props => props.selectTextColor || '#1FE49A',
    '&:hover': {
      color: props => props.selectedHoverColor
    }
  },
  selectIconUnselected: {
    color: props => `${props.unselectTextColor || 'rgb(174, 184, 195)'} !important`
  },
  selectIconSelected: {
    color: props => `${props.selectTextColor || '#1FE49A'} !important`
  },
  menuItem: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.4) !important'
    }
  },
  menuItemSelected: {
    backgroundColor: props =>
      `${props.selectedItemBackgroundColor || 'rgb(30, 172, 113)'} !important`,
    color: props => props.selectedItemColor,
    '&:hover': {
      backgroundColor: props =>
        `${props.selectedItemBackgroundColor || 'rgb(30, 172, 113)'} !important`
    }
  }
});

const getStylesAsPerSelection = (selectedValue, componentStyles) => {
  let formSelection = componentStyles.formControlUnselected,
    icon = componentStyles.selectIconUnselected,
    select = componentStyles.selectControlUnselected,
    menuSelected;
  if (selectedValue) {
    formSelection = componentStyles.formControlSelected;
    icon = componentStyles.selectIconSelected;
    select = componentStyles.selectControlSelected;
    menuSelected = componentStyles.menuItemSelected;
  }

  return {
    formControl: {
      root: componentStyles.formControlRoot,
      formSelection
    },
    selectControl: {
      icon,
      select
    },
    menuItemControl: {
      root: componentStyles.menuItem,
      selected: menuSelected
    }
  };
};

const DefaultMenuStyles = {
  backgroundColor: 'rgb(28, 33, 43)',
  color: 'white',
  maxWidth: 500,
  overflowX: 'hidden',
  overflowY: 'auto'
};

const DefaultSpanMenuItemStyles = {
  flex: '1 1 0'
};

export default ({
  options,
  value,
  onChange,
  containerClass = '',
  noSelectionValue,
  displayEmpty = false,
  materialStyles = {},
  renderValue,
  multiple = false,
  menuStyles = {},
  name = ''
}) => {
  const { t } = getI18N();
  const selectComponentStyles = useSelectStyles(materialStyles);

  // Currently selected value matches the value that signifies no selection state
  const { formControl, selectControl, menuItemControl } = getStylesAsPerSelection(
    value !== noSelectionValue,
    selectComponentStyles
  );
  const mergedMenuStyles = { ...DefaultMenuStyles, ...menuStyles };

  return (
    <FormControl className={`${formControl.root} ${formControl.formSelection} ${containerClass}`}>
      <Select
        multiple={multiple}
        displayEmpty={displayEmpty}
        value={value}
        onChange={onChange}
        classes={selectControl}
        input={<Input disableUnderline />}
        MenuProps={{
          PaperProps: {
            style: mergedMenuStyles
          }
        }}
        name={name}
        inputProps={{ 'aria-label': 'Without label' }}
        renderValue={renderValue}
      >
        {options.map(({ value, displayValue, disabled }) => (
          <MenuItem key={value} value={value} classes={menuItemControl} disabled={disabled}>
            <span className="ellipsis" style={DefaultSpanMenuItemStyles}>
              {typeof displayValue === 'string' ? t(displayValue) : displayValue}
            </span>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
