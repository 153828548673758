import React from 'react';
import NumberInput from '../../../../../../../Common/NumberInput';
import { FLAT_AMOUNT, PERCENT } from '../../ProductTypeDetails';
import './styles.scss';
import { getI18N } from 'qs-services/i18N';

const StoneInputField = ({
  title,
  symbol = '',
  placeholder = '',
  keyName,
  value,
  setAddStoneDetails,
  mandatory,
  autoCalculate = false,
  discountType = '',
  currencySymbol,
  errorMessage,
  setErrorMessage,
  integer = false,
  digitsAfterDecimal = 0
}) => {
  const { t } = getI18N();
  const onInputValueChange = value => {
    setAddStoneDetails(prevState => {
      return {
        ...prevState,
        [keyName]: value
      };
    });
    if (errorMessage[keyName] && errorMessage[keyName]) {
      setErrorMessage(prevState => {
        return {
          ...prevState,
          [keyName]: ''
        };
      });
    }
  };
  return (
    <div className="stoneInputField" style={{ flex: '1 1 calc(50% - 40px)' }}>
      <div className="title">
        {title}
        {keyName !== 'discountAmount' && (mandatory || autoCalculate) && (
          <span
            style={{
              ...(mandatory ? { color: '#FF4572' } : autoCalculate ? { color: '#0820FA' } : {})
            }}
          >
            {' '}
            *
          </span>
        )}
      </div>
      <div
        className="inputContainer inputAndSymbol"
        style={keyName === 'discountAmount' ? { alignItems: 'center' } : {}}
      >
        {discountType === FLAT_AMOUNT.id.toLowerCase() && (
          <span className={'currencySymbol'}>{currencySymbol}</span>
        )}
        <NumberInput
          onChange={onInputValueChange}
          value={value}
          inputClassName="input setInput inputCustomStyles"
          placeholder={placeholder}
          integer={integer}
          digitsAfterDecimal={digitsAfterDecimal}
        />
        {symbol && <span className="symbol">{symbol}</span>}
        {discountType === PERCENT.id.toLowerCase() && <span className={'currencySymbol'}>%</span>}
      </div>
      {errorMessage && Object.keys(errorMessage).length > 0 && errorMessage[keyName] && (
        <span className="error">
          {typeof errorMessage[keyName] === 'string'
            ? t(errorMessage[keyName])
            : errorMessage[keyName]}
        </span>
      )}
    </div>
  );
};

export default StoneInputField;
