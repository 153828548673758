import { DialogActions, DialogContent } from '@material-ui/core';
import { deleteOrderNotes } from 'qs-api/Orders/api';
import React, { useCallback, useState } from 'react';
import {
  DialogDarkTheme,
  PrimaryButton,
  SecondaryButton
} from '../../../../../Common/DarkThemeDialog';
import toastr from 'toastr';
import Loader from '../../../../../Common/Loader';
import './styles.scss';
import { getI18N } from 'qs-services/i18N';

const DeleteOrderNoteDialog = ({ open, setOpen, orderId, onDeleteNoteSuccess }) => {
  const { t } = getI18N();
  const [loading, setLoading] = useState(false);

  const handleDeleteOrderNote = useCallback(async () => {
    try {
      setLoading(true);
      await deleteOrderNotes({ orderId });
      onDeleteNoteSuccess();
      toastr.success(t('order_notes_deleted'));
    } catch (error) {
      toastr.error(error.message || t('error_deleting_order_note'));
    } finally {
      setLoading(false);
    }
  }, [onDeleteNoteSuccess, orderId, t]);

  return (
    <DialogDarkTheme
      open={open}
      PaperProps={{
        className: 'orderNoteDeleteDialog',
        noValidate: true
      }}
      onClose={() => setOpen(false)}
    >
      <DialogContent className="deleteOrderNoteDialogContent">
        <div className="deleteConfirmationMessage">
          {t('are_you_sure_you_want_to_delete_this_note')}
        </div>
      </DialogContent>
      <DialogActions>
        <SecondaryButton onClick={() => setOpen(false)}>
          <span>{t('cancel')}</span>
        </SecondaryButton>
        <PrimaryButton onClick={handleDeleteOrderNote} type="submit" disabled={loading}>
          {loading ? (
            <Loader size="extraSmall" />
          ) : (
            <span className="deleteLabel">{t('delete_notes')}</span>
          )}
        </PrimaryButton>
      </DialogActions>
    </DialogDarkTheme>
  );
};

export default DeleteOrderNoteDialog;
