import React from 'react';
import CheckBox from '../../../../../../../Common/CheckBox';
import NumberInput from '../../../../../../../Common/NumberInput';
import Loader from '../../../../../../../Common/Loader';
import { canUseFeature, FEATURE_LIST } from 'qs-data-manager/FeatureUsage';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

export default ({
  addAsSet,
  addAsSetChange,
  quantity,
  quantityChange,
  showLoader,
  iconComponent,
  currentTabType,
  inputText,
  onInputChange,
  createOption,
  inputRef
}) => {
  const setFeatureAvailable = canUseFeature(FEATURE_LIST.SET_WISE_ORDERING.id);
  const { t } = getI18N();

  const getButtonComponent = () => {
    if (showLoader) {
      return <Loader size={'small'} />;
    }

    let buttonText = t('add_tabtype', { tabType: t(currentTabType.toLowerCase()) });

    if (addAsSet) {
      buttonText += ` ${t('set')}`;
    }

    return (
      <button type="submit" className="optionsButton" onClick={createOption}>
        {buttonText}
      </button>
    );
  };

  const onAddAsSetChange = event => {
    addAsSetChange(event);
  };

  return (
    <form className="newProductOptionsForm">
      <div className="newProductOptionsInput">
        {iconComponent}
        <input
          placeholder={t('type_tabtype', { tabType: t(currentTabType.toLowerCase()) })}
          key={currentTabType}
          ref={inputRef}
          autoFocus={true}
          className="optionsInput lableInput"
          type="text"
          value={inputText}
          onChange={onInputChange}
          disabled={showLoader}
        />
        {addAsSet && (
          <NumberInput
            placeholder={t('type_set_quantity')}
            inputClassName="optionsInput input"
            type="text"
            integer
            value={quantity}
            autoFocus={true}
            onChange={quantityChange}
            disabled={showLoader}
          />
        )}
        {getButtonComponent()}
      </div>
      {setFeatureAvailable && (
        <div className="variantSetOptions">
          <CheckBox
            checked={addAsSet}
            onCheckChanged={onAddAsSetChange}
            disabled={showLoader}
            label={t('sell_as_set')}
            styleProps={{ padding: 9, fontSize: 15 }}
          />
        </div>
      )}
    </form>
  );
};
