import React, { useCallback, useEffect } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList as List } from 'react-window';
import { VARIANT_INVENTORY_ROW } from 'qs-helpers/Variants/constants';
import useDynamicSizedList from 'qs-hooks/dynamicSizedList';
import VariantInventoryRow from './VariantInventoryRow';
import { getVariantsMetaData } from 'qs-data-manager/Variants/FetchVariants';
import { clearSavedVariantInventoryData } from 'qs-data-manager/Variants/VariantInventory';
import './styles.scss';

export default ({ variantList, activeProductId }) => {
  const [variantsListRef, itemHeightMap, reRenderListOnItemUpdate] = useDynamicSizedList();

  const renderRow = useCallback(
    ({ data, index, style }) => {
      const [variantId] = data[index];

      return (
        <div style={style} className="variantInventoryRowHoverContainer">
          <div className="variantInventoryRowContainer">
            <VariantInventoryRow
              variantId={variantId}
              onMetaUpdate={reRenderListOnItemUpdate}
              indexInList={index}
            />
          </div>
        </div>
      );
    },
    [reRenderListOnItemUpdate]
  );

  useEffect(() => {
    //Clear the saved inventory data once the product changes
    return () => clearSavedVariantInventoryData(activeProductId);
  }, [activeProductId]);

  const onItemsRendered = ({ visibleStartIndex, visibleStopIndex }) => {
    const visibleVariantArrays = variantList.slice(visibleStartIndex, visibleStopIndex + 2);

    const visibleVariantIds = visibleVariantArrays.map(variantArray => variantArray[0]);

    getVariantsMetaData({ variantIds: visibleVariantIds, productId: activeProductId });
  };

  const itemKey = useCallback((index, data) => data[index][0], []);

  const getItemHeight = useCallback(
    index => {
      const variantArray = variantList[index];
      if (!Array.isArray(variantArray)) {
        return VARIANT_INVENTORY_ROW.estimatedHeight;
      }

      const variantId = variantArray[0];
      const variantHeight = itemHeightMap.current.get(variantId);
      if (variantHeight !== undefined) {
        return variantHeight + 20;
      }

      return VARIANT_INVENTORY_ROW.estimatedHeight;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [variantList]
  );

  return (
    <AutoSizer>
      {({ height, width }) => (
        <List
          ref={variantsListRef}
          height={height}
          itemData={variantList}
          itemCount={variantList.length}
          onItemsRendered={onItemsRendered}
          itemKey={itemKey}
          itemSize={getItemHeight}
          width={width}
          className="variantInventoryList"
          overscanCount={VARIANT_INVENTORY_ROW.overscanCount}
          estimatedItemSize={VARIANT_INVENTORY_ROW.estimatedHeight}
        >
          {renderRow}
        </List>
      )}
    </AutoSizer>
  );
};
