import React from 'react';
import Modal from 'react-responsive-modal';
import ModalContent from './ModalContent';

export default ({ open, onClose, tags, catalogueId }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      center
      styles={{
        modal: { backgroundColor: '#fff', borderRadius: 10, padding: 0 }
      }}
      showCloseIcon={false}
    >
      {open && (
        <ModalContent
          catalogueTagsTutorialShown={false}
          setCatalogueTagsTutorialShown={() => {}}
          catalogueId={catalogueId}
          tags={tags}
          onClose={onClose}
        />
      )}
    </Modal>
  );
};
