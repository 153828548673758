export const SET_INVENTORY_DATA = 'SET_INVENTORY_DATA';
export const SET_TRACK_QTY = 'SET_TRACK_QTY';
export const SET_STOCK_COUNT = 'SET_STOCK_COUNT';

export const variantInventoryInit = (inventoryData = {}) => ({
  ...inventoryData,
  count: inventoryData.stockCount
});

export const variantsInventoryReducer = (state, action) => {
  switch (action.type) {
    case SET_INVENTORY_DATA:
      return variantInventoryInit(action.data);
    case SET_TRACK_QTY:
      return {
        ...state,
        trackInventory: action.trackInventory
      };
    case SET_STOCK_COUNT:
      return {
        ...state,
        count: action.count
      };
    default:
      return state;
  }
};
