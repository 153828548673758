import { deleteOrder, setPdfSettings } from 'qs-api/Orders/api';
import { reportError } from 'qs-helpers/ErrorReporting';
import {
  getLastFetchedOrderKey,
  getLastFetchedOrderTimeStamp,
  setPaginatedCallInProg,
  createKeyForOrderOperation,
  normalizePaginatedOrdersList,
  clearSavedQueryVars,
  incrementRequestNumber,
  getCurrentRequestNumber,
  getOrderMetaAsPerOrderStatus,
  getOrderStatusFromStatusFilter
} from 'qs-helpers/Orders/ResponseProcessor';
import {
  ALL_COMPANY_ORDERS,
  ORDER_META,
  COMPANY_UNREAD_INQUIRIES,
  COMPANY_ORDER_PDF_SETTINGS,
  INVENTORY_UPDATE_ORDER_STATUS_CHANGE
} from 'qs-api/Orders/ApiCacheConnector';
import CacheRequest from '../CacheRequest';
import { ALL_FILTER_SORT_KEYS, ALL_ORDERS } from 'qs-helpers/Orders/constants';
import {
  createCacheKeyForInventoryUpdateInfo,
  createCacheKeyForOrderPdfSetting,
  createCacheKeyForUnreadInquiries,
  getCompanyUnreadInquiryFromCache,
  setCompanyOrderPdfSettingsInCache,
  setCompanyUnreadInquiryInCache
} from 'qs-helpers/Orders/CacheHelpers';

export const attachCompanyOrdersListener = ({ confirmed, sortKey, listener }) => {
  CacheRequest.attachListener(
    `${ALL_COMPANY_ORDERS.cacheKey}${createKeyForOrderOperation({ confirmed, sortKey })}`,
    listener
  );
};

export const removeCompanyOrdersListener = ({ confirmed, sortKey, listener }) => {
  CacheRequest.removeListener(
    `${ALL_COMPANY_ORDERS.cacheKey}${createKeyForOrderOperation({ confirmed, sortKey })}`,
    listener
  );
};

export const getPaginatedCompanyOrders = async ({
  returnError = false,
  confirmed,
  sortKey,
  searchText
} = {}) => {
  //Request number to ensure that a new request had not been initiated while this was being completed.
  incrementRequestNumber({ confirmed, sortKey });
  const currentRequestNumber = getCurrentRequestNumber({ confirmed, sortKey });
  setPaginatedCallInProg({ confirmed, sortKey, inProgress: true });
  const lastSortKeyValue = getLastFetchedOrderTimeStamp({ confirmed, sortKey });
  const lastOrderId = getLastFetchedOrderKey({ confirmed, sortKey });
  const key = `${ALL_COMPANY_ORDERS.cacheKey}${createKeyForOrderOperation({ confirmed, sortKey })}`;
  const apiCall = ALL_COMPANY_ORDERS.apiFunction;
  try {
    const existingData = CacheRequest.getCacheForKey(key);
    if (!existingData) {
      CacheRequest.notifyListeners(key, null, {
        status: CacheRequest.OPERATION_STATUS.LOADING
      });
    }

    const nextPageOrders = await apiCall({
      lastSortKeyValue,
      lastOrderId,
      sortKey,
      confirmed,
      searchText
    });

    // The request number before the request was made is not the same anymore. This indicates a new request was
    // made while this was being completed, hence discard this one. No need to process anyhting further
    // as the latest request would do all the required cleanup
    if (currentRequestNumber !== getCurrentRequestNumber({ confirmed, sortKey })) {
      return;
    }

    setPaginatedCallInProg({ confirmed, sortKey, inProgress: false });

    const existingOrdersData = getCompanyOrdersFromCache({ sortKey, confirmed }) || { orders: [] };
    if (Array.isArray(nextPageOrders.orders)) {
      nextPageOrders.orders = normalizePaginatedOrdersList(nextPageOrders.orders);
      existingOrdersData.orders.push(...nextPageOrders.orders);
      nextPageOrders.orders = existingOrdersData.orders;
      setCompanyOrdersInCache({ sortKey, confirmed, updates: nextPageOrders });
      return;
    }

    //No new data available, simply send back updated to notify any avaialble listeners
    CacheRequest.notifyListeners(key, null, {
      status: CacheRequest.OPERATION_STATUS.UPDATE
    });
  } catch (fetchCompanyOrdersError) {
    //Notify all listeners of an error
    CacheRequest.setCacheForKey(key, undefined, {
      error: fetchCompanyOrdersError
    });
    setPaginatedCallInProg({ confirmed, sortKey, inProgress: false });
    if (returnError) {
      throw fetchCompanyOrdersError;
    }
  }
};

export const getCompanyOrdersFromCache = ({ sortKey, confirmed }) =>
  CacheRequest.getCacheForKey(
    `${ALL_COMPANY_ORDERS.cacheKey}${createKeyForOrderOperation({ confirmed, sortKey })}`
  ) || undefined;

export const setCompanyOrdersInCache = ({ sortKey, confirmed, updates }) => {
  const existingOrders = getCompanyOrdersFromCache({ sortKey, confirmed });
  CacheRequest.setCacheForKey(
    `${ALL_COMPANY_ORDERS.cacheKey}${createKeyForOrderOperation({ confirmed, sortKey })}`,
    {
      ...existingOrders,
      ...updates
    }
  );
};

export const attachOrderMetaListener = ({ listener, orderId }) => {
  CacheRequest.attachListener(`${ORDER_META.cacheKey}${orderId}`, listener);
};

export const removeOrderMetaListener = ({ listener, orderId }) => {
  CacheRequest.removeListener(`${ORDER_META.cacheKey}${orderId}`, listener);
};

export const getOrderMetaFromCache = ({ orderId }) =>
  CacheRequest.getCacheForKey(`${ORDER_META.cacheKey}${orderId}`) || undefined;

export const setOrderMetaInCache = ({ orderId, updates }) => {
  const existingOrderMeta = getOrderMetaFromCache({ orderId }) || {};
  CacheRequest.setCacheForKey(`${ORDER_META.cacheKey}${orderId}`, {
    ...existingOrderMeta,
    ...updates
  });
};

export const deleteOrderFromOrderListCache = ({ sortKey, confirmed, orderId }) => {
  CacheRequest.deleteCacheForKeys([`${ORDER_META.cacheKey}${orderId}`]);
  let nextEligibleOrderId = null;

  //Iterate through all possible cached lists, and remove the order id from these lists, if it exists
  ALL_FILTER_SORT_KEYS.forEach(({ confirmed: savedConfirmedKey, sortKey: savedSortKey }) => {
    let { orders, totalOrders } =
      getCompanyOrdersFromCache({ sortKey: savedSortKey, confirmed: savedConfirmedKey }) || {};
    if (!Array.isArray(orders)) {
      return;
    }

    orders = orders.filter(({ id }, index) => {
      if (id === orderId) {
        const nextOrderId = orders[index + 1];
        //Saved the next eligilbe order id only if the filter set matches the current filter set
        if (savedConfirmedKey === confirmed && savedSortKey === sortKey && nextOrderId) {
          nextEligibleOrderId = nextOrderId.id;
        }
        return false;
      }

      return true;
    });
    totalOrders--;
    setCompanyOrdersInCache({
      sortKey: savedSortKey,
      confirmed: savedConfirmedKey,
      updates: {
        orders,
        totalOrders
      }
    });
  });

  return nextEligibleOrderId;
};

export const deleteOrderFromList = async ({ sortKey, confirmed, orderId, updateInventory }) => {
  let inventoryUpdated;
  try {
    ({ inventoryUpdated } = await deleteOrder({ orderId, updateInventory }));
  } catch (deleteError) {
    reportError(deleteError);
    throw deleteError;
  }

  return {
    inventoryUpdated,
    nextEligibleOrderId: deleteOrderFromOrderListCache({ sortKey, confirmed, orderId })
  };
};

export const updateOrdersListPostStatusUpdate = ({
  orderId,
  orderStatus,
  sortKey,
  isFinalized = true
}) => {
  let nextEligibleOrderId = null;
  const existingOrderMeta = getOrderMetaFromCache({ orderId });
  if (!existingOrderMeta) {
    return nextEligibleOrderId;
  }

  // Get the list from where this order must be removed
  const { orderStatusCacheKey: oldOrderStatusKey } = getOrderMetaAsPerOrderStatus(
    existingOrderMeta.orderStatus,
    existingOrderMeta.isFinalized
  );

  const { orders, totalOrders } =
    getCompanyOrdersFromCache({ sortKey, confirmed: oldOrderStatusKey }) || {};

  // Filter the list only if the list exists, the list may not exist if the desired tab has not
  // been visited
  if (Array.isArray(orders)) {
    const currentOrderIndex = orders.findIndex(({ id }) => id === orderId);
    const filteredOrderList = orders.filter(({ id }) => id !== orderId);
    const orderAtCurrentIndex = filteredOrderList[currentOrderIndex];
    if (orderAtCurrentIndex) {
      nextEligibleOrderId = orderAtCurrentIndex.id;
    }

    setCompanyOrdersInCache({
      updates: {
        orders: filteredOrderList,
        totalOrders: totalOrders - (orders.length - filteredOrderList.length)
      },
      sortKey,
      confirmed: oldOrderStatusKey
    });
  }

  const { orderStatusCacheKey } = getOrderMetaAsPerOrderStatus(orderStatus, isFinalized);

  //The new list exists, move it to the head of the list. List is non-existant, a new API call
  //will be made for it, hence no need to create one
  const { orders: existingOrders, totalOrders: existingTotalOrders } =
    getCompanyOrdersFromCache({ sortKey, confirmed: orderStatusCacheKey }) || {};
  if (!Array.isArray(existingOrders)) {
    return nextEligibleOrderId;
  }

  const newList = [{ id: orderId }].concat(existingOrders);
  setCompanyOrdersInCache({
    updates: {
      orders: newList,
      totalOrders: existingTotalOrders + 1
    },
    sortKey,
    confirmed: orderStatusCacheKey
  });
  return nextEligibleOrderId;
};

export const clearCachedQueryData = () => {
  clearSavedQueryVars();
  const cacheKeys = ALL_FILTER_SORT_KEYS.map(
    ({ confirmed, sortKey }) =>
      `${ALL_COMPANY_ORDERS.cacheKey}${createKeyForOrderOperation({ confirmed, sortKey })}`
  );
  CacheRequest.deleteCacheForKeys(cacheKeys);
};

export const attachCompanyOrderPdfSettingListener = ({ listener }) => {
  CacheRequest.attachListener(createCacheKeyForOrderPdfSetting(), listener);
};

export const removeCompanyOrderPdfSettingListener = ({ listener }) => {
  CacheRequest.removeListener(createCacheKeyForOrderPdfSetting(), listener);
};

export const getCompanyOrderPDFSettings = () => {
  CacheRequest.makeRequest(
    createCacheKeyForOrderPdfSetting(),
    COMPANY_ORDER_PDF_SETTINGS.apiFunction,
    {
      params: [],
      options: {
        shouldNotStoreInNative: true
      }
    }
  );
};

const orderPdfSettingsArray = settingsData =>
  Object.entries(settingsData).map(data => ({ field: data[0], value: data[1] }));

export const setOrderPdfSettings = async settingsData => {
  try {
    await setPdfSettings({ fieldsData: orderPdfSettingsArray(settingsData) });
  } catch (updateSettingsError) {
    reportError(updateSettingsError);
    throw updateSettingsError;
  }

  setCompanyOrderPdfSettingsInCache(settingsData);
};

export const attachInventoryUpdateInfoListener = ({ listener, orderId }) => {
  CacheRequest.attachListener(createCacheKeyForInventoryUpdateInfo({ orderId }), listener);
};

export const removeInventoryUpdateInfoListener = ({ listener, orderId }) => {
  CacheRequest.removeListener(createCacheKeyForInventoryUpdateInfo({ orderId }), listener);
};

export const getInventoryUpdateInfo = ({ orderId }) => {
  CacheRequest.makeRequest(
    createCacheKeyForInventoryUpdateInfo({ orderId }),
    INVENTORY_UPDATE_ORDER_STATUS_CHANGE.apiFunction,
    {
      params: [orderId]
    }
  );
};

export const attachCompanyUnreadInquiryListener = ({
  orderStatusFilter = ALL_ORDERS,
  listener
}) => {
  CacheRequest.attachListener(createCacheKeyForUnreadInquiries({ orderStatusFilter }), listener);
};

export const removeCompanyUnreadInquiryListener = ({
  orderStatusFilter = ALL_ORDERS,
  listener
}) => {
  CacheRequest.removeListener(createCacheKeyForUnreadInquiries({ orderStatusFilter }), listener);
};

export const getCompanyUnreadInquiriesForStatus = ({ orderStatusFilter = ALL_ORDERS } = {}) => {
  CacheRequest.makeRequest(
    createCacheKeyForUnreadInquiries({ orderStatusFilter }),
    COMPANY_UNREAD_INQUIRIES.apiFunction,
    {
      params: [getOrderStatusFromStatusFilter(orderStatusFilter)],
      options: {
        shouldNotStoreInNative: true
      }
    }
  );
};

export const getCompanyUnreadInquiryCountFromCache = ({ orderStatusFilter = ALL_ORDERS } = {}) =>
  (getCompanyUnreadInquiryFromCache({ orderStatusFilter }) || {}).unreadInquiryCount || 0;

const processUnreadInquiryCount = updates => {
  const { unreadInquiryCount } = updates || {};
  if (typeof unreadInquiryCount === 'undefined') {
    return updates;
  }

  const copiedUpdates = { ...updates };
  if (typeof unreadInquiryCount !== 'number') {
    delete copiedUpdates.unreadInquiryCount;
    return copiedUpdates;
  }

  if (unreadInquiryCount < 0) {
    copiedUpdates.unreadInquiryCount = 0;
  }

  return copiedUpdates;
};

export const setCompanyUnreadInquiryCountInCache = ({ orderStatusFilter, updates }) => {
  const processedUpdates = processUnreadInquiryCount(updates);
  setCompanyUnreadInquiryInCache({ orderStatusFilter, updates: processedUpdates });
};
