import { useReducer, useCallback, useEffect } from 'react';
import { unsetGlobalLoader, setGlobalLoader } from 'qs-helpers/DataRefresh';
import CacheListenerCallback from 'qs-helpers/CacheListenerCallback';

const SET_ERROR = 'SET_ERROR';
const SET_LOAD_STATE = 'SET_LOAD_STATE';
const SET_REFRESH_STATE = 'SET_REFRESH_STATE';
const RESET_LOAD_ERROR = 'RESET_LOAD_ERROR';
const RESET_OPERATION_STATUS = 'RESET_OPERATION_STATUS';

const checkIfDataIsEmpty = data => {
  if (!data) {
    return true;
  }

  if (Array.isArray(data) && data.length === 0) {
    return true;
  }

  if (typeof data === 'object' && Object.keys(data).length === 0) {
    return true;
  }

  return false;
};

const operationStatusInit = data => ({
  error: undefined,
  // TODO pass prop here to set loading only if required
  loading: checkIfDataIsEmpty(data),
  refreshing: undefined,
  data: data,
  uniqueOperationKey: undefined
});

const operationStatusReducer = (state, action) => {
  const { globalLoader } = action;
  switch (action.type) {
    case SET_ERROR:
      unsetGlobalLoader({
        refreshing: state.refreshing,
        uniqueLoaderKey: state.uniqueOperationKey
      });

      // Show the error only if the state was not refreshing
      let errorToSet = action.err;
      if (state.refreshing) {
        errorToSet = null;
      }

      return {
        ...state,
        error: errorToSet,
        loading: undefined,
        refreshing: undefined
      };
    case SET_LOAD_STATE:
      unsetGlobalLoader({
        refreshing: state.refreshing,
        uniqueLoaderKey: state.uniqueOperationKey
      });
      return {
        ...state,
        loading: true,
        error: undefined,
        refreshing: undefined
      };
    case SET_REFRESH_STATE:
      let uniqueOperationKey;
      if (globalLoader) {
        uniqueOperationKey = setGlobalLoader({ prevLoader: state.refreshing, currLoader: true });
      }

      if (!uniqueOperationKey) {
        uniqueOperationKey = state.uniqueOperationKey;
      }

      return {
        ...state,
        refreshing: true,
        loading: undefined,
        error: undefined,
        uniqueOperationKey
      };
    case RESET_LOAD_ERROR:
      unsetGlobalLoader({
        refreshing: state.refreshing,
        uniqueLoaderKey: state.uniqueOperationKey
      });
      return {
        ...state,
        loading: undefined,
        refreshing: undefined,
        error: undefined
      };
    case RESET_OPERATION_STATUS:
      unsetGlobalLoader({
        refreshing: state.refreshing,
        uniqueLoaderKey: state.uniqueOperationKey
      });
      return {
        error: undefined,
        loading: undefined,
        refreshing: undefined,
        data: action.data,
        uniqueOperationKey: undefined
      };
    default:
      return state;
  }
};

export default ({
  cachedDataHandler,
  globalLoader = true,
  actionHandler,
  cleanupHandler,
  changeDependancy = [],
  onceListener = false,
  sendEmptyData = false
} = {}) => {
  const [operationStatus, setOperationStatus] = useReducer(
    operationStatusReducer,
    cachedDataHandler({ changeDependancy }),
    operationStatusInit
  );

  const resetErrorLoaders = useCallback(() => {
    setOperationStatus({ type: RESET_LOAD_ERROR, globalLoader });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // TODO don't set loader here
    setOperationStatus({
      type: RESET_OPERATION_STATUS,
      data: cachedDataHandler({ changeDependancy }),
      globalLoader
    });

    const listener = (error, payload) => {
      const { data, loading, refreshing, err } = CacheListenerCallback(error, payload);
      if (err) {
        setOperationStatus({ type: SET_ERROR, err, globalLoader });
        return;
      }

      if (loading) {
        setOperationStatus({
          type: SET_LOAD_STATE,
          globalLoader
        });
        return;
      }

      if (refreshing) {
        setOperationStatus({
          type: SET_REFRESH_STATE,
          globalLoader
        });
        return;
      }

      // Empty data must be sent, then don't prevent it from being set
      if (!data && !sendEmptyData) {
        // No error loading or refreshing, don't update the data but reset everything else
        setOperationStatus({ type: RESET_LOAD_ERROR, globalLoader });
        return;
      }

      setOperationStatus({ type: RESET_OPERATION_STATUS, data, globalLoader });

      // Must be executed only once, remove the listener once the data is loaded
      if (onceListener) {
        cleanupHandler({ listener, changeDependancy });
      }
    };

    // TODO probably get info if API was fired and hence loader needs to be added
    actionHandler({ listener, changeDependancy });
    return () => cleanupHandler({ listener, changeDependancy });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, changeDependancy);

  useEffect(() => {
    return () => setOperationStatus({ type: RESET_LOAD_ERROR, globalLoader });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [operationStatus, resetErrorLoaders];
};
