import { getUserIdFromCache } from 'qs-data-manager/User';
import webWorkerConfig from 'qs-web-worker/config';
import { sendMessageToWorker } from 'qs-web-worker/master.worker.js';
import Api from '../Api';
import { S3_CLOCK_SKEW_TIME } from 'qs-config/s3';

const getClockSkewAmount = async () => {
  let clockSkew = 0,
    serverTime;
  const currentTime = Date.now();
  try {
    ({ timestamp: serverTime } = await Api.getCurrentServerTime());
  } catch (error) {
    //Continue on a best effort basis
    return 0;
  }

  clockSkew = serverTime - currentTime;

  /*
      If below the AWS limit, don't set any skew. This assumes that a request to
      fetch time would not take more than the S3 skew limit, otherwise even
      though system time maybe correct, extra diff will be sent and will fail
      video upload
    */
  if (Math.abs(clockSkew) < S3_CLOCK_SKEW_TIME) {
    return 0;
  }
  return clockSkew;
};

export const uploadVideosViaWebWorker = async videosData => {
  const clockOffset = await getClockSkewAmount();
  sendMessageToWorker({
    type: webWorkerConfig.UPLOAD_VIDEOS_MESSAGE.type,
    data: { userId: getUserIdFromCache(), videosData, clockOffset }
  });
};
