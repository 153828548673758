import {
  getVariantsForProducts,
  getVariantsMeta,
  getVariantsInfo,
  getProductOptions,
  getGlobalOptions
} from './api';

export const PRODUCT_VARIANT_IDS = {
  cacheKey: 'PRODUCT_VARIANT_IDS',
  apiFunction: getVariantsForProducts
};

export const PRODUCT_VARIANT_META = {
  cacheKey: 'PRODUCT_VARIANT_META',
  apiFunction: getVariantsMeta
};

export const PRODUCT_VARIANT_INFO = {
  cacheKey: 'PRODUCT_VARIANT_INFO',
  apiFunction: getVariantsInfo
};

export const PRODUCT_VARIANT_OPTIONS = {
  cacheKey: 'PRODUCT_VARIANT_OPTIONS',
  apiFunction: getProductOptions
};

export const GLOBAL_OPTIONS = {
  cacheKey: 'GLOBAL_OPTIONS',
  apiFunction: getGlobalOptions
};
