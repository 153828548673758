import React, { useState, Fragment, useEffect } from 'react';
import VariantSummaryView from '../VariantSummaryView';
import './styles.scss';
import {
  getVariantsMetaDataFromCache,
  attachProductVariantMetaListener,
  removeProductVariantMetaListener
} from 'qs-data-manager/Variants/FetchVariants';
import Loader from '../../../../../Common/Loader';
import CacheListenerCallback from 'qs-helpers/CacheListenerCallback';
import { getI18N } from 'qs-services/i18N';

export default ({ defaultVariantId, onVariantIdChange, titleClass }) => {
  const [showVariantInfo, toggleVariantInfo] = useState(false);
  const [variantMeta, setVaraiantMeta] = useState(getVariantsMetaDataFromCache(defaultVariantId));
  const { t } = getI18N();

  useEffect(() => {
    setVaraiantMeta(getVariantsMetaDataFromCache(defaultVariantId));
    const listener = (error, payload) => {
      const { err, loading, refreshing, data } = CacheListenerCallback(error, payload);
      if (err) {
        // Set an empty label
        setVaraiantMeta({ label: '' });
      }

      if (loading || refreshing || !data) {
        return;
      }

      setVaraiantMeta(data);
    };

    attachProductVariantMetaListener({ listener, variantId: defaultVariantId });
    return () => removeProductVariantMetaListener({ listener, variantId: defaultVariantId });
  }, [defaultVariantId]);

  const { label } = variantMeta;

  const closeVariantInfo = () => {
    toggleVariantInfo(false);
  };

  const variantInfoClicked = event => {
    // Prevent the click from propagating to the parent which will close the overlay
    event.preventDefault();
    event.stopPropagation();
  };

  const defaulVariantLinkClick = event => {
    event.preventDefault();
    toggleVariantInfo(true);
  };

  const handleDetailsClick = event => {
    event.preventDefault();
    onVariantIdChange(defaultVariantId);
  };

  const getDefaultVariantInfo = () => {
    if (!showVariantInfo) {
      return;
    }

    let labelComponent;
    if (typeof label === 'string') {
      labelComponent = t(
        'the_default_variant_of_this_product_will_be_shown_on_the_main_page_of_your_catalogue',
        { label }
      );
    } else {
      labelComponent = <Loader size={'small'} />;
    }

    return (
      <div className="overlayInfo" onClick={variantInfoClicked}>
        {labelComponent}
      </div>
    );
  };

  return (
    <Fragment>
      <div className="defaultVariantContainer">
        <div className={titleClass}>{t('default_variant')}</div>
        <div className="defaultVariantInfoContainer">
          <div onClick={defaulVariantLinkClick} className="defaultVariantButton">
            <div>{t('what_is_a_default_variant')}</div>
          </div>
          {getDefaultVariantInfo()}
        </div>
        <div className="defaultVariantDataContainer" onClick={handleDetailsClick}>
          <VariantSummaryView variantId={defaultVariantId} onVariantIdChange={onVariantIdChange} />
        </div>
        {showVariantInfo && <div onClick={closeVariantInfo} className="fixedOverlay" />}
      </div>
    </Fragment>
  );
};
