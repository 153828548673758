import {
  COMPANY_ORDER_PDF_SETTINGS,
  COMPANY_UNREAD_INQUIRIES,
  INVENTORY_UPDATE_ORDER_STATUS_CHANGE
} from 'qs-api/Orders/ApiCacheConnector';
import CacheRequest from 'qs-data-manager/CacheRequest';

export const createCacheKeyForOrderPdfSetting = () => COMPANY_ORDER_PDF_SETTINGS.cacheKey;

export const getCompanyOrderPdfSettingsFromCache = () =>
  CacheRequest.getCacheForKey(createCacheKeyForOrderPdfSetting()) || undefined;

export const setCompanyOrderPdfSettingsInCache = updates => {
  CacheRequest.setCacheForKey(createCacheKeyForOrderPdfSetting(), {
    ...getCompanyOrderPdfSettingsFromCache(),
    ...updates
  });
};

export const createCacheKeyForInventoryUpdateInfo = ({ orderId }) =>
  `${INVENTORY_UPDATE_ORDER_STATUS_CHANGE.cacheKey}${orderId}`;

export const getInventoryUpdateInfoFromCache = ({ orderId }) =>
  CacheRequest.getCacheForKey(createCacheKeyForInventoryUpdateInfo({ orderId })) || undefined;

export const createCacheKeyForUnreadInquiries = ({ orderStatusFilter }) =>
  `${COMPANY_UNREAD_INQUIRIES.cacheKey}${orderStatusFilter}`;

export const getCompanyUnreadInquiryFromCache = ({ orderStatusFilter }) =>
  CacheRequest.getCacheForKey(createCacheKeyForUnreadInquiries({ orderStatusFilter })) || undefined;

export const setCompanyUnreadInquiryInCache = ({ orderStatusFilter, updates }) => {
  const existingUnreadData = getCompanyUnreadInquiryFromCache({ orderStatusFilter }) || {};
  CacheRequest.setCacheForKey(createCacheKeyForUnreadInquiries({ orderStatusFilter }), {
    ...existingUnreadData,
    ...updates
  });
};
