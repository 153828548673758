import React, { useState, useEffect } from 'react';
import eventbus from 'eventing-bus';
import { getPicturesUploadingMetaForHeader } from 'qs-helpers/ProcessUploadedImage';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

export default ({ uploadHeader, mappingId, containerClassName }) => {
  const [uploadMeta, setUploadMeta] = useState(() =>
    getPicturesUploadingMetaForHeader({ uploadHeader, mappingId })
  );
  const [videoUploadProgress, setVideoUploadProgress] = useState(null);

  const { t } = getI18N();

  useEffect(() => {
    const videoUploadProgressListener = eventbus.on('UPLOAD_PRODUCT_HEADER', uploaded => {
      setVideoUploadProgress(uploaded);
    });

    return () => {
      videoUploadProgressListener();
    };
  }, []);

  useEffect(() => {
    const meta = getPicturesUploadingMetaForHeader({ uploadHeader, mappingId });
    setUploadMeta(meta);
    const key = uploadHeader.eventbusKey(mappingId);
    const removeListener = eventbus.on(key, ({ remaining, percent } = {}) => {
      const shouldShow = !!remaining;
      setUploadMeta({
        shouldShow,
        percent,
        remaining
      });
    });

    return () => {
      setUploadMeta({
        shouldShow: false,
        percent: 0,
        remaining: 0
      });
      removeListener();
    };
  }, [mappingId, uploadHeader, videoUploadProgress]);

  if (!uploadMeta.shouldShow) {
    return null;
  }

  return (
    <div className={`imageHeaderContainer ${containerClassName ? containerClassName : ''}`}>
      <div className={'uploadingPictures'}>
        {videoUploadProgress < 100 && uploadMeta.remaining >= 1
          ? t('uploading_your_media_files_percent', {
              percent: videoUploadProgress ? videoUploadProgress : uploadMeta.percent
            })
          : t('files_uploaded_successfully')}
      </div>
      <div className={'progressBarContainer'}>
        <div
          className={'progressBar'}
          style={{ width: `${videoUploadProgress ? videoUploadProgress : uploadMeta.percent}%` }}
        />
      </div>
      <div className={'picturesRemaining'}>
        {videoUploadProgress < 100 && uploadMeta.remaining >= 1
          ? t('count_media_files_remaining', { count: uploadMeta.remaining })
          : t('please_wait_while_we_process_your_media')}
      </div>
    </div>
  );
};
