import { getI18N } from "qs-services/i18N";

export const ACCENT_COLOR = '#2547AD';

export const WHITE_COLOR = '#FFFFFF';

export const BLACK_COLOR = '#000000';

export const TEXT_COLOR = '#2F3D55';

export const BORDER_COLOR = '#CCCCCC';

export const transformGrapesJSDataAttributes = text => {
  if (!text || typeof text !== 'string') {
    return '';
  }
  return text.replace(
    /data-quicksell-component-(\w+(?:-\w+)*)="([^"]*)"/g,
    'data-quicksell-component-$1="$2" data-gjs-$1="$2"'
  );
};

export const transformGrapesJSVariables = ({ text, type } = {}) => {
  if (!text || typeof text !== 'string' || !type || typeof type !== 'string') {
    return '';
  }
  let variables = [];
  if (type === CUSTOM_COMPONENT_KEYS.PRODUCT_GRID_TILE) {
    variables = Object.entries(PRODUCT_GRID_TILE_VARIABLES_PLACEHOLDER.VARIABLES);
  } else if (type === CUSTOM_COMPONENT_KEYS.PRODUCT_LIST_TILE) {
    variables = Object.entries(PRODUCT_LIST_TILE_VARIABLES_PLACEHOLDER.VARIABLES);
  } else if (type === CUSTOM_COMPONENT_KEYS.PRODUCT_MAGAZINE_TILE) {
    variables = Object.entries(PRODUCT_MAGAZINE_TILE_VARIABLES_PLACEHOLDER.VARIABLES);
  } else if (type === CUSTOM_COMPONENT_KEYS.CATALOGUE_GRID_TILE) {
    variables = Object.entries(CATALOGUE_GRID_TILE_VARIABLES_PLACEHOLDER.VARIABLES);
  }
  variables.forEach(([key, value]) => {
    text = text.replace(new RegExp(key, 'g'), value);
  });
  return text;
};

export const CUSTOM_COMPONENT_KEYS = {
  CATALOGUE_GRID_TILE: 'CATALOGUE_GRID_TILE',
  PRODUCT_GRID_TILE: 'PRODUCT_GRID_TILE',
  PRODUCT_LIST_TILE: 'PRODUCT_LIST_TILE',
  PRODUCT_MAGAZINE_TILE: 'PRODUCT_MAGAZINE_TILE',
  CUSTOM_PAGE_HTML_SECTION: 'CUSTOM_PAGE_HTML_SECTION'
};

export const PRODUCT_GRID_TILE_CLASSNAMES_PREFIX = 'product-grid-tile';

export const PRODUCT_LIST_TILE_CLASSNAMES_PREFIX = 'product-list-tile';

export const PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX = 'product-magazine-tile';

export const CATALOGUE_GRID_TILE_CLASSNAMES_PREFIX = 'catalogue-grid-tile';

export const PRODUCT_GRID_TILE_VARIABLES_MAP = {
  userId: '{{userId}}',
  productId: '{{productId}}',
  pictureId: '{{pictureId}}',
  pictureUrl: '{{pictureUrl}}',
  pictureAlt: '{{pictureAlt}}',
  pictureHeight: '{{pictureHeight}}',
  pictureWidth: '{{pictureWidth}}',
  discountPercent: '{{discountPercent}}',
  outOfStockText: '{{outOfStockText}}',
  videoUrl: '{{videoUrl}}',
  pictureCount: '{{pictureCount}}',
  variantColorCount: '{{variantColorCount}}',
  productName: '{{productName}}',
  productPrice: '{{productPrice}}',
  productDiscountedPrice: '{{productDiscountedPrice}}',
  productTaxPrice: '{{productTaxPrice}}',
  addToCartButtonText: '{{addToCartButtonText}}'
};

export const PRODUCT_LIST_TILE_VARIABLES_MAP = {
  userId: '{{userId}}',
  productId: '{{productId}}',
  pictureId: '{{pictureId}}',
  pictureUrl: '{{pictureUrl}}',
  pictureAlt: '{{pictureAlt}}',
  pictureHeight: '{{pictureHeight}}',
  pictureWidth: '{{pictureWidth}}',
  discountPercent: '{{discountPercent}}',
  outOfStockText: '{{outOfStockText}}',
  videoUrl: '{{videoUrl}}',
  pictureCount: '{{pictureCount}}',
  variantColorCount: '{{variantColorCount}}',
  productName: '{{productName}}',
  productDescription: '{{productDescription}}',
  productPrice: '{{productPrice}}',
  productDiscountedPrice: '{{productDiscountedPrice}}',
  productTaxPrice: '{{productTaxPrice}}',
  addToCartButtonText: '{{addToCartButtonText}}'
};

export const PRODUCT_MAGAZINE_TILE_VARIABLES_MAP = {
  userId: '{{userId}}',
  productId: '{{productId}}',
  pictureId: '{{pictureId}}',
  pictureUrl: '{{pictureUrl}}',
  pictureAlt: '{{pictureAlt}}',
  pictureHeight: '{{pictureHeight}}',
  pictureWidth: '{{pictureWidth}}',
  pictureNext: '{{pictureNext}}',
  picturePrevious: '{{picturePrevious}}',
  discountPercent: '{{discountPercent}}',
  outOfStockText: '{{outOfStockText}}',
  videoUrl: '{{videoUrl}}',
  pictureCount: '{{pictureCount}}',
  variantColorCount: '{{variantColorCount}}',
  productName: '{{productName}}',
  productSku: '{{productSku}}',
  productDescription: '{{productDescription}}',
  productPrice: '{{productPrice}}',
  productDiscountedPrice: '{{productDiscountedPrice}}',
  productTaxPrice: '{{productTaxPrice}}',
  productMinimumOrderQuantity: '{{productMinimumOrderQuantity}}',
  addToCartButtonText: '{{addToCartButtonText}}'
};

export const CATALOGUE_GRID_TILE_VARIABLES_MAP = {
  userId: '{{userId}}',
  catalogueId: '{{catalogueId}}',
  pictureId: '{{pictureId}}',
  pictureUrl: '{{pictureUrl}}',
  pictureAlt: '{{pictureAlt}}',
  pictureHeight: '{{pictureHeight}}',
  pictureWidth: '{{pictureWidth}}',
  catalogueTitle: '{{catalogueTitle}}',
  productCount: '{{productCount}}'
};

export const PRODUCT_GRID_TILE_VARIABLES_PLACEHOLDER = {
  get VARIABLES() {
    const { t } = getI18N();
    return {
      [PRODUCT_GRID_TILE_VARIABLES_MAP.pictureUrl]: 'https://cdn.quicksell.co/-L7-Hytr6HLMUWWAbsy1/products_400/-NTXL2eS2B3ZVNWdv0mN.jpg',
      [PRODUCT_GRID_TILE_VARIABLES_MAP.pictureHeight]: '322',
      [PRODUCT_GRID_TILE_VARIABLES_MAP.pictureWidth]: '400',
      [PRODUCT_GRID_TILE_VARIABLES_MAP.discountPercent]: t('percent_off', { percent: 25 }),
      [PRODUCT_GRID_TILE_VARIABLES_MAP.outOfStockText]: t('out_of_stock'),
      [PRODUCT_GRID_TILE_VARIABLES_MAP.pictureCount]: t('count_of_photos', { count: 10 }),
      [PRODUCT_GRID_TILE_VARIABLES_MAP.variantColorCount]: t('count_of_colors', { count: 10 }),
      [PRODUCT_GRID_TILE_VARIABLES_MAP.productName]: t('product_name'),
      [PRODUCT_GRID_TILE_VARIABLES_MAP.productPrice]: '₹ 1000',
      [PRODUCT_GRID_TILE_VARIABLES_MAP.productDiscountedPrice]: '₹ 1200',
      [PRODUCT_GRID_TILE_VARIABLES_MAP.productTaxPrice]: `₹ 1100 (${t('including_percent_gst', { percent: 18 })})`,
      [PRODUCT_GRID_TILE_VARIABLES_MAP.addToCartButtonText]: t('add_to_cart').toUpperCase()
    };
  }
};

export const PRODUCT_LIST_TILE_VARIABLES_PLACEHOLDER = {
  get VARIABLES() {
    const { t } = getI18N();
    return {
      [PRODUCT_LIST_TILE_VARIABLES_MAP.pictureUrl]: 'https://cdn.quicksell.co/-L7-Hytr6HLMUWWAbsy1/products_400/-NTXL2eS2B3ZVNWdv0mN.jpg',
      [PRODUCT_LIST_TILE_VARIABLES_MAP.pictureHeight]: '322',
      [PRODUCT_LIST_TILE_VARIABLES_MAP.pictureWidth]: '400',
      [PRODUCT_LIST_TILE_VARIABLES_MAP.discountPercent]: t('percent_off', { percent: 25 }),
      [PRODUCT_LIST_TILE_VARIABLES_MAP.outOfStockText]: t('out_of_stock'),
      [PRODUCT_LIST_TILE_VARIABLES_MAP.pictureCount]: t('count_of_photos', { count: 10 }),
      [PRODUCT_LIST_TILE_VARIABLES_MAP.variantColorCount]: t('count_of_colors', { count: 10 }),
      [PRODUCT_LIST_TILE_VARIABLES_MAP.productName]: t('product_name'),
      [PRODUCT_LIST_TILE_VARIABLES_MAP.productDescription]: t('product_description'),
      [PRODUCT_LIST_TILE_VARIABLES_MAP.productPrice]: '₹ 1000',
      [PRODUCT_LIST_TILE_VARIABLES_MAP.productDiscountedPrice]: '₹ 1200',
      [PRODUCT_LIST_TILE_VARIABLES_MAP.productTaxPrice]: `₹ 1100 (${t('including_percent_gst', { percent: 18 })})`,
      [PRODUCT_LIST_TILE_VARIABLES_MAP.addToCartButtonText]: t('add').toUpperCase()
    };
  }
};

export const PRODUCT_MAGAZINE_TILE_VARIABLES_PLACEHOLDER = {
  get VARIABLES() {
    const { t } = getI18N();
    return {
      [PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.pictureUrl]: 'https://cdn.quicksell.co/-L7-Hytr6HLMUWWAbsy1/products_400/-NTXL2eS2B3ZVNWdv0mN.jpg',
      [PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.pictureHeight]: '322',
      [PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.pictureWidth]: '400',
      [PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.discountPercent]: t('percent_off', { percent: 25 }),
      [PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.outOfStockText]: t('out_of_stock'),
      [PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.pictureCount]: t('count_of_photos', { count: 10 }),
      [PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.variantColorCount]: t('count_of_colors', { count: 10 }),
      [PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productName]: t('product_name'),
      [PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productSku]: t('product_sku'),
      [PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productDescription]: t('product_description'),
      [PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productPrice]: '₹ 1000',
      [PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productDiscountedPrice]: '₹ 1200',
      [PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productTaxPrice]: `₹ 1100 (${t('including_percent_gst', { percent: 18 })})`,
      [PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productMinimumOrderQuantity]: 'MOQ: 10',
      [PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.addToCartButtonText]: t('add_to_cart').toUpperCase()
    };
  }
};

export const CATALOGUE_GRID_TILE_VARIABLES_PLACEHOLDER = {
  get VARIABLES() {
    const { t } = getI18N();
    return {
      [CATALOGUE_GRID_TILE_VARIABLES_MAP.pictureUrl]: 'https://cdn.quicksell.co/-L7-Hytr6HLMUWWAbsy1/products_400/-NTXL2eS2B3ZVNWdv0mN.jpg',
      [CATALOGUE_GRID_TILE_VARIABLES_MAP.pictureHeight]: '322',
      [CATALOGUE_GRID_TILE_VARIABLES_MAP.pictureWidth]: '400',
      [CATALOGUE_GRID_TILE_VARIABLES_MAP.discountPercent]: t('percent_off', { percent: 25 }),
      [CATALOGUE_GRID_TILE_VARIABLES_MAP.catalogueTitle]: t('catalogue_title'),
      [CATALOGUE_GRID_TILE_VARIABLES_MAP.productCount]: t('count_products', { count: 10 })
    };
  }
};

export const PRODUCT_GRID_TILE_CLASSNAMES_MAP = {
  productGridTileId: `${PRODUCT_GRID_TILE_CLASSNAMES_PREFIX}-id`,
  productGridTileContainer: `${PRODUCT_GRID_TILE_CLASSNAMES_PREFIX}-container`,
  productGridTileImageContainer: `${PRODUCT_GRID_TILE_CLASSNAMES_PREFIX}-image-container`,
  productGridTileDiscountBadge: `${PRODUCT_GRID_TILE_CLASSNAMES_PREFIX}-discount-badge`,
  productGridTileOutOfStockBadge: `${PRODUCT_GRID_TILE_CLASSNAMES_PREFIX}-out-of-stock-badge`,
  productGridTileVideoBadge: `${PRODUCT_GRID_TILE_CLASSNAMES_PREFIX}-video-badge`,
  productGridTilePhotoCountBadge: `${PRODUCT_GRID_TILE_CLASSNAMES_PREFIX}-photo-count-badge`,
  productGridTileImage: `${PRODUCT_GRID_TILE_CLASSNAMES_PREFIX}-image`,
  productGridTileImageId: `${PRODUCT_GRID_TILE_CLASSNAMES_PREFIX}-picture-id`,
  productGridTileImageHeight: `${PRODUCT_GRID_TILE_CLASSNAMES_PREFIX}-image-height`,
  productGridTileImageWidth: `${PRODUCT_GRID_TILE_CLASSNAMES_PREFIX}-image-width`,
  productGridTileDetailsContainer: `${PRODUCT_GRID_TILE_CLASSNAMES_PREFIX}-details-container`,
  productGridTileVariantDetailsContainer: `${PRODUCT_GRID_TILE_CLASSNAMES_PREFIX}-variant-details-container`,
  productGridTileVariantColors: `${PRODUCT_GRID_TILE_CLASSNAMES_PREFIX}-variant-colors`,
  productGridTileVariantColor: `${PRODUCT_GRID_TILE_CLASSNAMES_PREFIX}-variant-color`,
  productGridTileVariantColorCount: `${PRODUCT_GRID_TILE_CLASSNAMES_PREFIX}-variant-color-count`,
  productGridTileName: `${PRODUCT_GRID_TILE_CLASSNAMES_PREFIX}-name`,
  productGridTilePriceContainer: `${PRODUCT_GRID_TILE_CLASSNAMES_PREFIX}-price-container`,
  productGridTilePrice: `${PRODUCT_GRID_TILE_CLASSNAMES_PREFIX}-price`,
  productGridTilePriceDiscount: `${PRODUCT_GRID_TILE_CLASSNAMES_PREFIX}-price-discount`,
  productGridTilePriceTax: `${PRODUCT_GRID_TILE_CLASSNAMES_PREFIX}-price-tax`,
  productGridTileAddToCartContainer: `${PRODUCT_GRID_TILE_CLASSNAMES_PREFIX}-add-to-cart-container`,
  productGridTileAddToCartLoader: `${PRODUCT_GRID_TILE_CLASSNAMES_PREFIX}-add-to-cart-loader`,
  productGridTileAddToCartLoaderSvg: `${PRODUCT_GRID_TILE_CLASSNAMES_PREFIX}-add-to-cart-loader-svg`,
  productGridTileAddToCart: `${PRODUCT_GRID_TILE_CLASSNAMES_PREFIX}-add-to-cart`,
  productGridTileInquiryContainer: `${PRODUCT_GRID_TILE_CLASSNAMES_PREFIX}-inquiry-container`,
  productGridTileInquiryMinus: `${PRODUCT_GRID_TILE_CLASSNAMES_PREFIX}-inquiry-minus`,
  productGridTileInquiryMinusText: `${PRODUCT_GRID_TILE_CLASSNAMES_PREFIX}-inquiry-minus-text`,
  productGridTileInquiryCount: `${PRODUCT_GRID_TILE_CLASSNAMES_PREFIX}-inquiry-count`,
  productGridTileInquiryCountText: `${PRODUCT_GRID_TILE_CLASSNAMES_PREFIX}-inquiry-count-text`,
  productGridTileInquiryPlus: `${PRODUCT_GRID_TILE_CLASSNAMES_PREFIX}-inquiry-plus`,
  productGridTileInquiryPlusText: `${PRODUCT_GRID_TILE_CLASSNAMES_PREFIX}-inquiry-plus-text`
};

export const PRODUCT_LIST_TILE_CLASSNAMES_MAP = {
  productListTileId: `${PRODUCT_LIST_TILE_CLASSNAMES_PREFIX}-id`,
  productListTileContainer: `${PRODUCT_LIST_TILE_CLASSNAMES_PREFIX}-container`,
  productListTileImageContainer: `${PRODUCT_LIST_TILE_CLASSNAMES_PREFIX}-image-container`,
  productListTileDiscountBadge: `${PRODUCT_LIST_TILE_CLASSNAMES_PREFIX}-discount-badge`,
  productListTileOutOfStockBadge: `${PRODUCT_LIST_TILE_CLASSNAMES_PREFIX}-out-of-stock-badge`,
  productListTileImage: `${PRODUCT_LIST_TILE_CLASSNAMES_PREFIX}-image`,
  productListTileImageId: `${PRODUCT_LIST_TILE_CLASSNAMES_PREFIX}-picture-id`,
  productListTileImageHeight: `${PRODUCT_LIST_TILE_CLASSNAMES_PREFIX}-image-height`,
  productListTileImageWidth: `${PRODUCT_LIST_TILE_CLASSNAMES_PREFIX}-image-width`,
  productListTileDetailsContainer: `${PRODUCT_LIST_TILE_CLASSNAMES_PREFIX}-details-container`,
  productListTileVariantDetailsContainer: `${PRODUCT_LIST_TILE_CLASSNAMES_PREFIX}-variant-details-container`,
  productListTileVariantColors: `${PRODUCT_LIST_TILE_CLASSNAMES_PREFIX}-variant-colors`,
  productListTileVariantColor: `${PRODUCT_LIST_TILE_CLASSNAMES_PREFIX}-variant-color`,
  productListTileVariantColorCount: `${PRODUCT_LIST_TILE_CLASSNAMES_PREFIX}-variant-color-count`,
  productListTileName: `${PRODUCT_LIST_TILE_CLASSNAMES_PREFIX}-name`,
  productListTileDescription: `${PRODUCT_LIST_TILE_CLASSNAMES_PREFIX}-description`,
  productListTilePriceAddToCartContainer: `${PRODUCT_LIST_TILE_CLASSNAMES_PREFIX}-price-add-to-cart-container`,
  productListTilePriceDiscountContainer: `${PRODUCT_LIST_TILE_CLASSNAMES_PREFIX}-price-discount-container`,
  productListTilePriceContainer: `${PRODUCT_LIST_TILE_CLASSNAMES_PREFIX}-price-container`,
  productListTilePrice: `${PRODUCT_LIST_TILE_CLASSNAMES_PREFIX}-price`,
  productListTilePriceDiscount: `${PRODUCT_LIST_TILE_CLASSNAMES_PREFIX}-price-discount`,
  productListTilePriceTax: `${PRODUCT_LIST_TILE_CLASSNAMES_PREFIX}-price-tax`,
  productListTileAddToCartContainer: `${PRODUCT_LIST_TILE_CLASSNAMES_PREFIX}-add-to-cart-container`,
  productListTileAddToCartLoader: `${PRODUCT_LIST_TILE_CLASSNAMES_PREFIX}-add-to-cart-loader`,
  productListTileAddToCartLoaderSvg: `${PRODUCT_LIST_TILE_CLASSNAMES_PREFIX}-add-to-cart-loader-svg`,
  productListTileAddToCart: `${PRODUCT_LIST_TILE_CLASSNAMES_PREFIX}-add-to-cart`,
  productListTileInquiryContainer: `${PRODUCT_LIST_TILE_CLASSNAMES_PREFIX}-inquiry-container`,
  productListTileInquiryMinus: `${PRODUCT_LIST_TILE_CLASSNAMES_PREFIX}-inquiry-minus`,
  productListTileInquiryMinusText: `${PRODUCT_LIST_TILE_CLASSNAMES_PREFIX}-inquiry-minus-text`,
  productListTileInquiryCount: `${PRODUCT_LIST_TILE_CLASSNAMES_PREFIX}-inquiry-count`,
  productListTileInquiryCountText: `${PRODUCT_LIST_TILE_CLASSNAMES_PREFIX}-inquiry-count-text`,
  productListTileInquiryPlus: `${PRODUCT_LIST_TILE_CLASSNAMES_PREFIX}-inquiry-plus`,
  productListTileInquiryPlusText: `${PRODUCT_LIST_TILE_CLASSNAMES_PREFIX}-inquiry-plus-text`
};

export const PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP = {
  productMagazineTileId: `${PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX}-id`,
  productMagazineTileContainer: `${PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX}-container`,
  productMagazineTileImageContainer: `${PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX}-image-container`,
  productMagazineTileOutOfStockBadge: `${PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX}-out-of-stock-badge`,
  productMagazineTileImage: `${PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX}-image`,
  productMagazineTileImageId: `${PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX}-picture-id`,
  productMagazineTileImageHeight: `${PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX}-image-height`,
  productMagazineTileImageWidth: `${PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX}-image-width`,
  productMagazineTileDetailsContainer: `${PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX}-details-container`,
  productMagazineTileThumbnailsContainer: `${PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX}-thumbnails-container`,
  productMagazineTileThumbnailContainer: `${PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX}-thumbnail-container`,
  productMagazineTileThumbnailSelected: `${PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX}-thumbnail-selected`,
  productMagazineTileThumbnail: `${PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX}-thumbnail`,
  productMagazineTileImageNext: `${PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX}-image-next`,
  productMagazineTileImagePrevious: `${PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX}-image-previous`,
  productMagazineTileThumbnailId: `${PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX}-thumbnail-id`,
  productMagazineTileName: `${PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX}-name`,
  productMagazineTileSku: `${PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX}-sku`,
  productMagazineTileDiscountBadge: `${PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX}-discount-badge`,
  productMagazineTileDescription: `${PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX}-description`,
  productMagazineTilePriceContainer: `${PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX}-price-container`,
  productMagazineTilePrice: `${PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX}-price`,
  productMagazineTilePriceDiscount: `${PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX}-price-discount`,
  productMagazineTilePriceTax: `${PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX}-price-tax`,
  productMagazineTilePriceMinimumOrderQuantity: `${PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX}-minimum-order-quantity`,
  productMagazineTileVariantDetailsContainer: `${PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX}-variant-details-container`,
  productMagazineTileVariantColors: `${PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX}-variant-colors`,
  productMagazineTileVariantColor: `${PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX}-variant-color`,
  productMagazineTileVariantColorCount: `${PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX}-variant-color-count`,
  productMagazineTileAddToCartContainer: `${PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX}-add-to-cart-container`,
  productMagazineTileAddToCartLoader: `${PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX}-add-to-cart-loader`,
  productMagazineTileAddToCartLoaderSvg: `${PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX}-add-to-cart-loader-svg`,
  productMagazineTileAddToCart: `${PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX}-add-to-cart`,
  productMagazineTileInquiryContainer: `${PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX}-inquiry-container`,
  productMagazineTileInquiryMinus: `${PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX}-inquiry-minus`,
  productMagazineTileInquiryMinusText: `${PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX}-inquiry-minus-text`,
  productMagazineTileInquiryCount: `${PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX}-inquiry-count`,
  productMagazineTileInquiryCountText: `${PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX}-inquiry-count-text`,
  productMagazineTileInquiryPlus: `${PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX}-inquiry-plus`,
  productMagazineTileInquiryPlusText: `${PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX}-inquiry-plus-text`
};

export const CATALOGUE_GRID_TILE_CLASSNAMES_MAP = {
  catalogueGridTileId: `${CATALOGUE_GRID_TILE_CLASSNAMES_PREFIX}-id`,
  catalogueGridTileContainer: `${CATALOGUE_GRID_TILE_CLASSNAMES_PREFIX}-container`,
  catalogueGridTileImageContainer: `${CATALOGUE_GRID_TILE_CLASSNAMES_PREFIX}-image-container`,
  catalogueGridTileLockBadge: `${CATALOGUE_GRID_TILE_CLASSNAMES_PREFIX}-lock-badge`,
  catalogueGridTileLoader: `${CATALOGUE_GRID_TILE_CLASSNAMES_PREFIX}-loader`,
  catalogueGridTileLoaderSvg: `${CATALOGUE_GRID_TILE_CLASSNAMES_PREFIX}-loader-svg`,
  catalogueGridTileImage: `${CATALOGUE_GRID_TILE_CLASSNAMES_PREFIX}-image`,
  catalogueGridTileImageId: `${CATALOGUE_GRID_TILE_CLASSNAMES_PREFIX}-picture-id`,
  catalogueGridTileImageHeight: `${CATALOGUE_GRID_TILE_CLASSNAMES_PREFIX}-image-height`,
  catalogueGridTileImageWidth: `${CATALOGUE_GRID_TILE_CLASSNAMES_PREFIX}-image-width`,
  catalogueGridTileDetailsContainer: `${CATALOGUE_GRID_TILE_CLASSNAMES_PREFIX}-details-container`,
  catalogueGridTileTitle: `${CATALOGUE_GRID_TILE_CLASSNAMES_PREFIX}-title`,
  catalogueGridTileProductCount: `${CATALOGUE_GRID_TILE_CLASSNAMES_PREFIX}-product-count`
};

export const PRODUCT_GRID_TILE_ELEMENT_TYPES = {
  get CONFIG() {
    const { t } = getI18N();
    return [
      {
        id: PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileDiscountBadge,
        name: t('product_grid_tile_discount_badge'),
        variable: PRODUCT_GRID_TILE_VARIABLES_MAP.discountPercent
      },
      {
        id: PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileOutOfStockBadge,
        name: t('product_grid_tile_out_of_stock_badge'),
        variable: PRODUCT_GRID_TILE_VARIABLES_MAP.outOfStockText
      },
      {
        id: PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileVideoBadge,
        name: t('product_grid_tile_video_badge'),
        variable: PRODUCT_GRID_TILE_VARIABLES_MAP.videoUrl
      },
      {
        id: PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTilePhotoCountBadge,
        name: t('product_grid_tile_photo_count_badge'),
        variable: PRODUCT_GRID_TILE_VARIABLES_MAP.pictureCount
      },
      {
        id: PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileImage,
        name: t('product_grid_tile_image'),
        variable: PRODUCT_GRID_TILE_VARIABLES_MAP.pictureUrl
      },
      // {
      //   id: PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileVariantColor,
      //   name: t('product_grid_tile_variant_color'),
      //   variable: PRODUCT_GRID_TILE_VARIABLES_MAP.variantColorCount
      // },
      {
        id: PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileVariantColorCount,
        name: t('product_grid_tile_variant_color_count'),
        variable: PRODUCT_GRID_TILE_VARIABLES_MAP.variantColorCount
      },
      {
        id: PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileName,
        name: t('product_grid_tile_name'),
        variable: PRODUCT_GRID_TILE_VARIABLES_MAP.productName
      },
      {
        id: PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTilePrice,
        name: t('product_grid_tile_price'),
        variable: PRODUCT_GRID_TILE_VARIABLES_MAP.productPrice
      },
      {
        id: PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTilePriceDiscount,
        name: t('product_grid_tile_price_discount'),
        variable: PRODUCT_GRID_TILE_VARIABLES_MAP.productDiscountedPrice
      },
      {
        id: PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTilePriceTax,
        name: t('product_grid_tile_price_tax'),
        variable: PRODUCT_GRID_TILE_VARIABLES_MAP.productTaxPrice
      },
      {
        id: PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileAddToCart,
        name: t('product_grid_tile_add_to_cart'),
        variable: PRODUCT_GRID_TILE_VARIABLES_MAP.addToCartButtonText
      }
    ];
  }
};

export const PRODUCT_LIST_TILE_ELEMENT_TYPES = {
  get CONFIG() {
    const { t } = getI18N();
    return [
      {
        id: PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileDiscountBadge,
        name: t('product_list_tile_discount_badge'),
        variable: PRODUCT_LIST_TILE_VARIABLES_MAP.discountPercent
      },
      {
        id: PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileOutOfStockBadge,
        name: t('product_list_tile_out_of_stock_badge'),
        variable: PRODUCT_LIST_TILE_VARIABLES_MAP.outOfStockText
      },
      {
        id: PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileImage,
        name: t('product_list_tile_image'),
        variable: PRODUCT_LIST_TILE_VARIABLES_MAP.pictureUrl
      },
      // {
      //   id: PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileVariantColor,
      //   name: t('product_list_tile_variant_color'),
      //   variable: PRODUCT_LIST_TILE_VARIABLES_MAP.variantColorCount
      // },
      {
        id: PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileVariantColorCount,
        name: t('product_list_tile_variant_color_count'),
        variable: PRODUCT_LIST_TILE_VARIABLES_MAP.variantColorCount
      },
      {
        id: PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileName,
        name: t('product_list_tile_name'),
        variable: PRODUCT_LIST_TILE_VARIABLES_MAP.productName
      },
      {
        id: PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileDescription,
        name: t('product_list_tile_description'),
        variable: PRODUCT_LIST_TILE_VARIABLES_MAP.productDescription
      },
      {
        id: PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTilePrice,
        name: t('product_list_tile_price'),
        variable: PRODUCT_LIST_TILE_VARIABLES_MAP.productPrice
      },
      {
        id: PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTilePriceDiscount,
        name: t('product_list_tile_price_discount'),
        variable: PRODUCT_LIST_TILE_VARIABLES_MAP.productDiscountedPrice
      },
      {
        id: PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTilePriceTax,
        name: t('product_list_tile_price_tax'),
        variable: PRODUCT_LIST_TILE_VARIABLES_MAP.productTaxPrice
      },
      {
        id: PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileAddToCart,
        name: t('product_list_tile_add_to_cart'),
        variable: PRODUCT_LIST_TILE_VARIABLES_MAP.addToCartButtonText
      }
    ];
  }
};

export const PRODUCT_MAGAZINE_TILE_ELEMENT_TYPES = {
  get CONFIG() {
    const { t } = getI18N();
    return [
      {
        id: PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileOutOfStockBadge,
        name: t('product_magazine_tile_out_of_stock_badge'),
        variable: PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.outOfStockText
      },
      {
        id: PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileImage,
        name: t('product_magazine_tile_image'),
        variable: PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.pictureUrl
      },
      {
        id: PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileThumbnail,
        name: t('product_magazine_tile_thumbnail_image'),
        variable: PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.pictureUrl
      },
      {
        id: PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileImageNext,
        name: t('product_magazine_tile_image_next'),
        variable: PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.pictureNext
      },
      {
        id: PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileImagePrevious,
        name: t('product_magazine_tile_image_previous'),
        variable: PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.picturePrevious
      },
      // {
      //   id: PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileVariantColor,
      //   name: t('product_magazine_tile_variant_color'),
      //   variable: PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.variantColorCount
      // },
      {
        id: PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileVariantColorCount,
        name: t('product_magazine_tile_variant_color_count'),
        variable: PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.variantColorCount
      },
      {
        id: PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileName,
        name: t('product_magazine_tile_name'),
        variable: PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productName
      },
      {
        id: PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileSku,
        name: t('product_magazine_tile_sku'),
        variable: PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productSku
      },
      {
        id: PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileDiscountBadge,
        name: t('product_magazine_tile_discount_badge'),
        variable: PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.discountPercent
      },
      {
        id: PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileDescription,
        name: t('product_magazine_tile_description'),
        variable: PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productDescription
      },
      {
        id: PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTilePrice,
        name: t('product_magazine_tile_price'),
        variable: PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productPrice
      },
      {
        id: PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTilePriceDiscount,
        name: t('product_magazine_tile_price_discount'),
        variable: PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productDiscountedPrice
      },
      {
        id: PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTilePriceTax,
        name: t('product_magazine_tile_price_tax'),
        variable: PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productTaxPrice
      },
      {
        id: PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTilePriceMinimumOrderQuantity,
        name: t('product_magazine_tile_price_minimum_order_quantity'),
        variable: PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productMinimumOrderQuantity
      },
      {
        id: PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileAddToCart,
        name: t('product_magazine_tile_add_to_cart'),
        variable: PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.addToCartButtonText
      }
    ];
  }
};

export const CATALOGUE_GRID_TILE_ELEMENT_TYPES = {
  get CONFIG() {
    const { t } = getI18N();
    return [
      {
        id: CATALOGUE_GRID_TILE_CLASSNAMES_MAP.catalogueGridTileImage,
        name: t('catalogue_grid_tile_image'),
        variable: CATALOGUE_GRID_TILE_VARIABLES_MAP.pictureUrl
      },
      {
        id: CATALOGUE_GRID_TILE_CLASSNAMES_MAP.catalogueGridTileTitle,
        name: t('catalogue_grid_tile_title'),
        variable: CATALOGUE_GRID_TILE_VARIABLES_MAP.catalogueTitle
      },
      {
        id: CATALOGUE_GRID_TILE_CLASSNAMES_MAP.catalogueGridTileProductCount,
        name: t('catalogue_grid_tile_product_count'),
        variable: CATALOGUE_GRID_TILE_VARIABLES_MAP.productCount
      },
    ];
  }
};

export const CUSTOM_COMPONENT_DEFAULT_STYLE = `
  html,
  body {
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    padding: 0px 0px 0px 0px;
    margin: 0px 0px 0px 0px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;

export const CUSTOM_COMPONENT_WRAPPER_STYLE = `
  background-color: ${WHITE_COLOR};
  color: ${TEXT_COLOR};
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
  font-family: ${[
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"'
  ].join(',')};
  position: relative;
`;

export const PRODUCT_GRID_TILE_WRAPPER_STYLE = `
  height: 100%;
  min-height: 0px;
  max-height: 400px;
  width: 100%;
  min-width: 0px;
  max-width: 300px;
`;

export const PRODUCT_LIST_TILE_WRAPPER_STYLE = `
  height: 100%;
  min-height: 100px;
  max-height: 200px;
  width: 100%;
  min-width: 400px;
  max-width: 400px;
`;

export const PRODUCT_MAGAZINE_TILE_WRAPPER_STYLE = `
  height: 100%;
  min-height: 100px;
  max-height: 600px;
  width: 100%;
  min-width: 300px;
  max-width: 300px;
`;

export const CATALOGUE_GRID_TILE_WRAPPER_STYLE = `
  height: 100%;
  min-height: 100px;
  max-height: 350px;
  width: 100%;
  min-width: 300px;
  max-width: 300px;
`;