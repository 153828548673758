import React, { useContext, useState } from 'react';
import { ActionButton } from '../../../Header/Common/ActionButtons';
import { handleStockCountChange } from 'qs-helpers/Orders/DataQueryHelper';
import { ActiveOrderMeta } from '../../../../context';
import ErrorPopup from 'qs-components/Common/ErrorPopup';
import Loader from 'qs-components/Common/Loader';
import { getErrorMessage } from 'qs-helpers';
import './styles.scss';
import { getI18N } from 'qs-services/i18N';

export default ({ headerRow, inquiryData }) => {
  const {
    activeOrderMeta: { selectedOrderId }
  } = useContext(ActiveOrderMeta);
  const [errorData, setErrorData] = useState();
  const [showLoader, setShowLoader] = useState();

  const { t } = getI18N();

  const { inquiryId, stockCount, product: { id: productId } = {}, isDeletedByVisitor } =
    inquiryData || {};

  const handleManageStockClick = async () => {
    if (headerRow) {
      return;
    }

    setShowLoader(true);
    try {
      // Toggle the stock value
      await handleStockCountChange({
        inquiryId,
        orderId: selectedOrderId,
        productId,
        stockCount: stockCount ? 0 : 1
      });
    } catch (stockUpdateError) {
      setErrorData({
        title: t('inventory_management'),
        subject: t('failed_to_update_inventory_for_inquiry_in_order', {
          inquiryId,
          selectedOrderId
        }),
        body: getErrorMessage(stockUpdateError),
        message: t('something_went_wrong_while_updating_the_inventory')
      });
    } finally {
      setShowLoader(false);
    }
  };

  const renderButtonComponent = () => {
    if (showLoader) {
      return <Loader size="small" />;
    }

    if (headerRow || isDeletedByVisitor) {
      return null;
    }

    return (
      <ActionButton color={'primary'} variant="contained" onClick={handleManageStockClick}>
        {stockCount ? t('mark_out_of_stock') : t('out_of_stock')}
      </ActionButton>
    );
  };

  return (
    <div className="orderStockActionContainer">
      {renderButtonComponent()}
      {errorData && (
        <ErrorPopup onClose={() => setErrorData()} mailProps={errorData} title={errorData.title}>
          {errorData.message}
        </ErrorPopup>
      )}
    </div>
  );
};
