export const PRODUCT_HEADER = 'PRODUCT_HEADER';
export const EDIT_PRODUCTS_HEADER = 'EDIT_PRODUCTS_HEADER';
export const REORDER_PRODUCTS_HEADER = 'REORDER_PRODUCTS_HEADER';
export const SEARCH_PRODUCTS_HEADER = 'SEARCH_PRODUCTS_HEADER';
export const HEADERS = {
  PRODUCT_HEADER,
  EDIT_PRODUCTS_HEADER,
  SEARCH_PRODUCTS_HEADER,
  REORDER_PRODUCTS_HEADER
};
export const HEADER_EDIT_TYPES = {
  ADD_REMOVE: 'add/remove',
  REORDER: 'reorder'
};
