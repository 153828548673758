import React, { useState, useCallback, useEffect, memo } from 'react';
import eventbus from 'eventing-bus';
import {
  attachPrivateNotesListener,
  removePrivateNotesListener,
  getPrivateNotes,
  productDetailsInitialState,
  savePrivateNotes
} from 'qs-data-manager/ProductDetails';
import CacheListenerCallback from 'qs-helpers/CacheListenerCallback';
import Loader from 'qs-components/Common/Loader';
import { Typography, Button, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TaxesLib from 'qs-data-manager/Taxes';
import toastr from 'toastr';
import { navigateToPath } from 'qs-data-manager';

import './styles.scss';
import '../BasicInfo/styles.scss';
import Mixpanel from 'qs-data-manager/Mixpanel';
import ProductWeights from './ProductWeights';
import {
  ROUTE_MAP,
  SETTINGS_COMPONENT_ID_MAP,
  SETTINGS_COMPONENT_ROUTE_MAP
} from 'qs-helpers/CompanySettings/constants';
import { SAVE_BUTTON_META } from 'qs-helpers/Products/constants';
import ProductMoq from './ProductMoq';
import { getI18N } from 'qs-services/i18N';

const useStyles = makeStyles({
  taxContainer: {
    paddingTop: '1rem'
  },
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '0.25rem'
  },
  title: {
    fontSize: 18,
    fontWeight: 700,
    display: 'flex',
    alignItems: 'center'
  },
  titleNoTax: {
    fontSize: 14,
    fontWeight: 400,
    display: 'flex',
    alignItems: 'center',
    color: '#95A3BB'
  },
  manageTax: {
    color: '#82B4FB',
    fontSize: 14,
    textTransform: 'none',
    fontWeight: 400
  },
  removeTax: {
    color: '#FE6767',
    fontSize: 14,
    textTransform: 'none',
    fontWeight: 400,
    borderBottom: '1px solid #FE6767',
    padding: 0,
    borderRadius: 'inherit',
    paddingTop: '0.5rem'
  },
  taxes: {
    display: 'flex',
    marginBottom: '1rem',
    overflowX: 'auto'
  },
  eachTax: {
    borderStyle: 'dashed',
    borderWidth: 1,
    borderColor: '#6F8199',
    borderRadius: '50rem',
    padding: '0.25rem',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    marginRight: 8,
    textAlign: 'center',
    fontSize: 13,
    fontWeight: 700,
    cursor: 'pointer',
    maxHeight: 37,
    whiteSpace: 'nowrap',
    color: '#6F8199'
  },
  selectedTax: {
    display: 'none'
  }
});

export default memo(({ activeProductIds, isBulkEditing, activeProductId } = {}) => {
  const [privateNotesState, setPrivateNotesState] = useState(() =>
    productDetailsInitialState(isBulkEditing ? activeProductIds : [activeProductId])
  );

  const classes = useStyles();

  const [taxes, setTaxes] = useState([]);
  const [loadingTaxes, setLoadingTaxes] = useState(false);
  const [selectedTax, setSelectedTax] = useState({});

  const { t } = getI18N();

  const getTaxes = useCallback(() => {
    const callApi = async () => {
      try {
        setLoadingTaxes(true);
        const { taxes } = await TaxesLib.getAllTaxes();
        setLoadingTaxes(false);
        setTaxes(taxes);
      } catch (err) {
        setLoadingTaxes(false);
        toastr.error(t('something_went_wrong_please_try_again'));
      }
    };
    callApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getProductTax = useCallback(activeProductId => {
    const callApi = async () => {
      try {
        setLoadingTaxes(true);
        if (!Array.isArray(activeProductId)) {
          const productTax = await TaxesLib.getProductTax(activeProductId);
          setLoadingTaxes(false);
          setSelectedTax(productTax);
        }
      } catch (err) {
        setLoadingTaxes(false);
        toastr.error(t('something_went_wrong_please_try_again'));
      }
    };
    callApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getTaxes();
    getProductTax(activeProductId || activeProductIds);
  }, [activeProductId, activeProductIds, getTaxes, getProductTax]);

  // Effect for attaching private notes changes listener
  useEffect(() => {
    const privateNoteListener = (error, payload) => {
      const { err, loading, refreshing, data } = CacheListenerCallback(error, payload);

      if (err) {
        setPrivateNotesState(prevState => ({
          ...prevState,
          error: err,
          loading
        }));
        return;
      }

      if (loading) {
        setPrivateNotesState(prevState => ({
          ...prevState,
          error: null,
          loading
        }));
        return;
      }

      setPrivateNotesState(prevState => ({
        ...prevState,
        error: null,
        loading,
        refreshing,
        privateNotes: data.privateNotes || '',
        changedPrivateNotes: data.privateNotes || ''
      }));
    };

    if (!isBulkEditing) {
      attachPrivateNotesListener(privateNoteListener, activeProductId);
      getPrivateNotes(activeProductId);
    }

    return isBulkEditing
      ? () => {}
      : () => {
          removePrivateNotesListener(privateNoteListener, activeProductId);
        };
  }, [isBulkEditing, activeProductId]);

  useEffect(() => {
    setPrivateNotesState(prevState => ({
      ...prevState,
      privateNotes: '',
      changedPrivateNotes: '',
      showSaveButton: false
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [(activeProductIds || []).length]);

  const changePrivateNotes = useCallback(
    e => {
      e.stopPropagation();
      const value = e.target.value;
      const hasChanged = privateNotesState.privateNotes !== value;

      const showSaveButton = isBulkEditing && hasChanged;

      setPrivateNotesState(prevState => ({
        ...prevState,
        changedPrivateNotes: value,
        showSaveButton
      }));

      if (!isBulkEditing) {
        eventbus.publish(SAVE_BUTTON_META.eventbusKey, {
          id: SAVE_BUTTON_META.PRIVATE_NOTES.id,
          hasChanged,
          data: value,
          eventType: SAVE_BUTTON_META.eventType.PRODUCT_META.id
        });

        Mixpanel.sendEvent({
          eventName: 'private_notes_saved',
          prop: {
            private_notes: value
          }
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [privateNotesState.privateNotes, isBulkEditing]
  );

  const onSaveClick = useCallback(() => {
    setPrivateNotesState(prevState => ({
      ...prevState,
      showSaveButton: false
    }));

    savePrivateNotes({
      notes: privateNotesState.changedPrivateNotes,
      productIds: activeProductIds
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [privateNotesState.changedPrivateNotes, activeProductIds]);

  const addTaxToProduct = useCallback(
    tax => {
      const callApi = async () => {
        setLoadingTaxes(true);
        try {
          if (activeProductIds) {
            await TaxesLib.setProductTaxBulk({ taxId: tax.taxId, productIds: activeProductIds });
            toastr.success(
              t('tax_percent_type_applied', {
                taxPercentage: tax.taxPercentage,
                taxType: tax.taxType
              })
            );
          } else {
            await TaxesLib.setProductTax({ taxId: tax.taxId, productId: activeProductId });
          }
          setSelectedTax(tax);
          setLoadingTaxes(false);
        } catch (err) {
          setLoadingTaxes(false);
          toastr.error(t('something_went_wrong_please_try_again'));
        }
      };
      callApi();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeProductId, activeProductIds]
  );

  const onRemoveTax = useCallback(() => {
    const callApi = async () => {
      setLoadingTaxes(true);
      try {
        if (activeProductIds) {
          await TaxesLib.removeProductTaxBulk(activeProductIds);
        } else {
          await TaxesLib.removeProductTax({ taxId: selectedTax.taxId, productId: activeProductId });
        }
        setSelectedTax({});
        setLoadingTaxes(false);
      } catch (err) {
        setLoadingTaxes(false);
        toastr.error(t('something_went_wrong_please_try_again'));
      }
    };
    callApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeProductId, selectedTax, activeProductIds]);

  const onManageTaxes = () => {
    navigateToPath(
      `/${ROUTE_MAP.ACCOUNT_SETTINGS}/${
        SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.TAXES_SETTINGS].ROUTE
      }`
    );
  };

  return (
    <div id={'PrivateNoteContainer'}>
      <Box className={classes.taxContainer}>
        <Box className={classes.heading}>
          <Typography className={classes.title}>Tax</Typography>
          <Button onClick={onManageTaxes} className={classes.manageTax}>
            {t('manage_taxes')}
          </Button>
        </Box>
        {loadingTaxes ? (
          <div className={'loaderContainer'}>
            <Loader size={'small'} />
          </div>
        ) : (
          taxes.length > 0 && (
            <Box className={classes.taxes}>
              {taxes.map(tax => (
                <Typography
                  key={tax.taxId}
                  className={`${classes.eachTax} ${
                    tax.taxId === selectedTax.taxId && !activeProductIds ? classes.selectedTax : ''
                  }`}
                  onClick={() => addTaxToProduct(tax)}
                >
                  {`${tax.taxPercentage}% ${tax.taxType}`}
                </Typography>
              ))}
            </Box>
          )
        )}
        {selectedTax.taxPercentage
          ? !activeProductIds && (
              <Typography
                className={classes.title}
              >{`${selectedTax.taxPercentage}% ${selectedTax.taxType}`}</Typography>
            )
          : !activeProductIds && <Typography className={classes.titleNoTax}>{t('')}</Typography>}
        {selectedTax.taxPercentage && (
          <Button onClick={onRemoveTax} className={classes.removeTax}>
            {t('remove_tax')}
          </Button>
        )}
      </Box>
      <ProductWeights activeProductId={activeProductId} />
      <ProductMoq activeProductId={activeProductId} />
      <div className={'rowContainer'}>
        {privateNotesState.loading ? (
          <div className={'loaderContainer'}>
            <Loader size={'small'} />
          </div>
        ) : (
          <>
            <div className={'rowHeading privateNoteHeading'}>{t('private_notes')}</div>
            <textarea
              rows="5"
              cols="20"
              type="text"
              onChange={changePrivateNotes}
              value={privateNotesState.changedPrivateNotes}
              className={`privateNotes ${privateNotesState.showSaveButton && 'marginRight'}`}
              placeholder={t('write_private_notes_on_this_product_that_only_you_can_see')}
            />
            {privateNotesState.showSaveButton ? (
              <div className="privateNoteBulkSaveButton" onClick={onSaveClick}>
                {t('save')}
              </div>
            ) : null}
          </>
        )}
      </div>
    </div>
  );
});
