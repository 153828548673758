import React, { useState, useEffect, Fragment } from 'react';
import { Button, makeStyles, TextField } from '@material-ui/core';
import './style.scss';
import DialogBox from 'qs-components/Common/DialogBox';
import { setDomainData, getDomainSettings } from 'qs-api/Domain/api';
import Loader from '../../../../Common/Loader';
import toastr from 'toastr';
import { ReactComponent as LeftArrow } from 'qs-assets/Media/arrow-left.svg';
import { ReactComponent as RightArrow } from 'qs-assets/Media/icon_long_arrow_right.svg';
import { ReactComponent as CopyIcon } from 'qs-assets/Media/copy-icon.svg';
import { getI18N } from 'qs-services/i18N';

const useStyles = makeStyles({
  mainContainer: {
    backgroundColor: '#202633',
    borderRadius: '8px',
    width: '800px'
  },

  dialogContent: {
    padding: '0px'
  },

  input: {
    color: 'white',
    paddingBottom: '5px',
    borderBottom: '2px solid',
    borderBottomColor: 'white',
    minWidth: '260px',
    WebkitBoxShadow: '0 0 0 1000px #202633 inset',
    WebkitTextFillColor: 'white'
  },

  root: {
    color: '#677184',
    fontSize: '15px',

    '&$disabled': {
      color: '#677184'
    }
  },

  disabled: {}
});

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
};

export default function DomainDialog({ domainType, domainName, dialogClose, updateDomain }) {
  const dialogStyles = useStyles();
  const [isFilled, setIsFilled] = useState(false);
  const [domain, setDomain] = useState();
  const [domainSettings, setDomainSettings] = useState();
  const [isDisabledMain, setIsDisabledMain] = useState(false);
  const [isDisabledSub, setIsDisabledSub] = useState(false);
  const [isDisabledBut, setIsDisabledBut] = useState(false);
  const [entry, setEntry] = useState();
  const [loading, setLoading] = useState(false);
  const [closeDialog, setCloseDialog] = useState(false);
  const [copied, setCopied] = useState(false);

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const { t } = getI18N();

  useEffect(() => {
    if (domainName) {
      if (domainType === 'custom') {
        if (domainName.split('.').length === 2) {
          setIsDisabledMain(false);
          setDomain(domainName);
          setEntry('root');
          setIsDisabledSub(true);
        } else {
          setIsDisabledSub(false);
          setDomain(domainName);
          setEntry('sub');
          setIsDisabledMain(true);
        }
      }
      if (domainType === 'catalog') {
        setDomain(domainName.split('.')[0]);
      }
    }
  }, [domainName, domainType]);

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleChange = (e, entryType) => {
    const domainValue = e.target.value.trim();
    if (domainType === 'custom') {
      if (domainValue && domainValue !== '') {
        if (entryType) {
          if (entryType.domain) {
            setIsDisabledSub(true);
            setEntry('root');
          }
          if (entryType.subdomain) {
            setIsDisabledMain(true);
            setEntry('sub');
          }
        }
        setDomain(domainValue);
      } else {
        setIsDisabledMain(false);
        setIsDisabledSub(false);
        setEntry(null);
      }
    }

    if (domainType === 'catalog') {
      if (domainValue && domainValue !== '') {
        setDomain(domainValue);
        setIsDisabledBut(false);
      } else {
        setIsDisabledBut(true);
      }
    }
  };

  const handleClose = () => {
    dialogClose();
    setCloseDialog(true);
  };

  const submitDomain = (domain, domainFieldType, entry) => {
    if (!domain || domain === '') {
      toastr.error(t('please_enter_domain_sub_domain'));
      return;
    }
    setLoading(true);
    // API Call
    if (domainFieldType === 'custom') {
      setDomainData({ type: domainFieldType, entryType: entry, domainName: domain })
        .then(() => {
          getDomainSettings()
            .then(data => {
              setDomainSettings(data);
              setLoading(false);
              setIsFilled(true);
            })
            .catch(() => {
              toastr.error(t('an_error_occured_while_fetching_domain_connection_instructions'));
              setLoading(false);
              handleClose();
            });
        })
        .catch(() => {
          toastr.error(t('an_error_doccured_while_changing_domain'));
          setLoading(false);
          handleClose();
        });
    } else {
      setDomainData({ type: domainFieldType, domainName: domain + '.catalog.to' })
        .then(() => {
          setLoading(false);
          toastr.success(t('domain_name_updated_successfully'));
          setCloseDialog(true);
          handleClose();
          updateDomain();
        })
        .catch(() => {
          toastr.error(t('an_error_doccured_while_changing_domain'));
          setLoading(false);
          handleClose();
        });
    }
  };

  return (
    <DialogBox
      dialogContainerClass={dialogStyles.mainContainer}
      maxWidth={'xl'}
      open={!closeDialog}
      onClose={handleClose}
      dialogContentContainerClass={dialogStyles.dialogContent}
    >
      {isFilled ? (
        <Fragment>
          <div className="domainDialogContainer">
            <div className="domainInstructions">
              <LeftArrow
                style={{ height: '30px', marginRight: '15px', cursor: 'pointer' }}
                fill="white"
                onClick={() => setIsFilled(false)}
              />
              {t('domain_connection_instructions')}
            </div>
            <div className="domain-name">{domain}</div>
            <div className="domain-text">
              {t('almost_there')}!
              <br />
              {t('a_few_more_steps')}
              <br />
              {t('ready_to_go')}
            </div>
            <div style={{ fontSize: '1rem', marginTop: '10px' }}>{t('add_these_dns_records')}</div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {entry === 'root' && (
                <div
                  className="domain-info"
                  style={{
                    borderRight: 'solid',
                    borderRightColor: '#707C92',
                    paddingRight: '20px',
                    marginRight: '20px'
                  }}
                >
                  <div className="records">A</div>
                  <div className="record-values">
                    {t('host')} : <div className="values">@</div>
                  </div>
                  <div className="record-values">
                    {t('value')}: <div className="values">{domainSettings.A}</div>
                  </div>
                </div>
              )}
              <div className="domain-info">
                <div className="records">CNAME</div>
                <div className="record-values">
                  {t('host')} :
                  <div className="values">{entry === 'root' ? 'www' : domain.split('.')[0]}</div>
                </div>
                <div className="record-values">
                  {t('value')} : <div className="values">{domainSettings.CNAME}</div>
                </div>
              </div>
            </div>
            <div>
              <Button
                className="copy-instructions"
                onClick={() => {
                  navigator.clipboard.writeText(
                    `To connect ${domain} to QuickSell, please add the following to your DNS records for this domain - \n \n ` +
                      (entry === 'root'
                        ? `A Record \n Host: @ \n Value: ${domainSettings.A} \n \n CNAME \n Host : www \n Value: ${domainSettings.CNAME}`
                        : `CNAME \n Host : ${domain.split('.')[0]} \n Value: ${
                            domainSettings.CNAME
                          }`)
                  );
                  setCopied(true);
                }}
              >
                <div style={{ display: 'flex' }}>
                  <CopyIcon fill="white" style={{ height: '25px', marginRight: '15px' }} />
                  <span>{t('copy_instructions')}</span>
                </div>
              </Button>
              {copied && `${t('copied_to_clipboard')}!!`}
            </div>
          </div>
          <div className="dialog-footer">
            <Button
              style={{
                backgroundColor: '#48C283',
                marginTop: '20px',
                marginBottom: '20px',
                marginRight: '25px',
                width: '80px',
                color: 'white'
              }}
              onClick={() => {
                setCopied(false);
                updateDomain();
                handleClose();
              }}
            >
              <span>{t('done')}</span>
            </Button>
          </div>
        </Fragment>
      ) : (
        <div className="domainDialogContainer">
          {domainType === 'catalog' ? (
            <Fragment>
              <div className="dialogTitle">{t('attach_your_domain_name_to_quicksell')}</div>
              <div className="catalogDomain">
                <div className="mainDomain">
                  {t('catalogue_domain')}
                  <div className="domainInputBox">
                    <TextField
                      id="standard-helperText"
                      placeholder={t('type_your_domain')}
                      defaultValue={domainName ? domainName.split('.')[0] : ''}
                      helperText={`${t('example')} : example.catalog.to`}
                      variant="standard"
                      inputProps={{ className: dialogStyles.input }}
                      FormHelperTextProps={{ classes: { root: dialogStyles.root } }}
                      onChange={e => handleChange(e)}
                    />
                    <div style={{ marginTop: '10px', marginLeft: '10px' }}>.catalog.to</div>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'baseline'
                  }}
                >
                  <Button
                    style={{
                      backgroundColor: '#48C283',
                      marginTop: '50px',
                      marginBottom: '50px',
                      color: 'white'
                    }}
                    onClick={() => {
                      submitDomain(domain, domainType);
                    }}
                    disabled={isDisabledBut || loading}
                  >
                    <span>{t('done')}</span>
                  </Button>
                  <div style={{ marginLeft: '20px' }}>
                    {loading && (
                      <div>
                        <Loader size="small" />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <div className="dialogTitle">{t('connect_your_domain_or_sub_domain')}</div>
              <div
                className="customDomains"
                style={
                  windowDimensions.width > 550
                    ? { flexDirection: 'row' }
                    : { flexDirection: 'column' }
                }
              >
                <div className="mainDomain">
                  {t('main_domain')}
                  <div className="domainInputBox">
                    <div style={{ marginTop: '5px', marginRight: '10px' }}>www.</div>
                    <TextField
                      id="standard-helperText"
                      placeholder={t('type_your_domain')}
                      helperText={`${t('example')} : abc.com, xyz.io`}
                      defaultValue={
                        domainName ? (domainName.split('.').length === 2 ? domainName : '') : ''
                      }
                      variant="standard"
                      inputProps={{ className: dialogStyles.input }}
                      FormHelperTextProps={{
                        classes: { root: dialogStyles.root, disabled: dialogStyles.disabled }
                      }}
                      onChange={e => handleChange(e, { domain: 'true' })}
                      disabled={isDisabledMain}
                    />
                  </div>
                  {isDisabledSub ? (
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
                      <Button
                        style={{
                          backgroundColor: '#4FBB8D',
                          marginTop: '50px',
                          marginBottom: '20px',
                          padding: '15px',
                          color: 'white'
                        }}
                        onClick={() => submitDomain(domain, domainType, entry)}
                        disabled={loading}
                      >
                        <div style={{ display: 'flex' }}>
                          <span>{t('continue')}</span>
                          <RightArrow fill="white" style={{ width: '25px', marginLeft: '20px' }} />
                        </div>
                      </Button>
                      <div style={{ marginLeft: '20px' }}>
                        {loading && (
                          <div>
                            <Loader size="small" />
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        marginTop: '50px',
                        marginBottom: '45px',
                        padding: '15px'
                      }}
                    ></div>
                  )}
                </div>
                <div
                  className="subDomain"
                  style={
                    windowDimensions.width > 550
                      ? {
                          borderLeftStyle: 'solid',
                          borderLeftColor: '#56637C',
                          paddingLeft: '30px'
                        }
                      : {}
                  }
                >
                  {t('sub_domain')}
                  <div className="domainInputBox">
                    <TextField
                      id="standard-helperText"
                      placeholder={t('type_your_domain')}
                      defaultValue={
                        domainName ? (domainName.split('.').length > 2 ? domainName : '') : ''
                      }
                      helperText={`${t('example')} : catalog.abc.com, products.xyzi`}
                      variant="standard"
                      inputProps={{ className: dialogStyles.input }}
                      FormHelperTextProps={{
                        classes: { root: dialogStyles.root, disabled: dialogStyles.disabled }
                      }}
                      onChange={e => handleChange(e, { subdomain: 'true' })}
                      disabled={isDisabledSub}
                    />
                  </div>
                  {isDisabledMain && (
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
                      <Button
                        style={{
                          backgroundColor: '#4FBB8D',
                          marginTop: '50px',
                          marginBottom: '20px',
                          padding: '15px',
                          color: 'white'
                        }}
                        onClick={() => submitDomain(domain, domainType, entry)}
                        disabled={loading}
                      >
                        <div style={{ display: 'flex' }}>
                          <span>{t('continue')}</span>
                          <RightArrow fill="white" style={{ width: '25px', marginLeft: '20px' }} />
                        </div>
                      </Button>
                      <div style={{ marginLeft: '20px' }}>
                        {loading && (
                          <div>
                            <Loader size="small" />
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Fragment>
          )}
        </div>
      )}
    </DialogBox>
  );
}
