import { db } from 'qs-config/FirebaseConfig';

class Listener {
  constructor(generalDb) {
    this.listeners = {};
    this.dbUsed = db;
    if (generalDb) {
      this.dbUsed = generalDb;
    }
  }

  once({ key, type }) {
    let ref = this.dbUsed.ref(key);
    return ref.once(type);
  }

  add({ key, type, ...options }, callback, errorCallback = () => {}) {
    if (this.doesExist({ key, type })) {
      return;
    }

    let ref = this.dbUsed.ref(key);

    if (options.orderByChild) {
      ref = ref.orderByChild(options.orderByChild);
    }

    if (options.orderByValue) {
      ref = ref.orderByValue();
    }

    if (options.startAt) {
      ref = ref.startAt(options.startAt);
    }

    if (options.limitToFirst) {
      ref = ref.limitToFirst(options.limitToFirst);
    }

    ref.on(type, callback, errorCallback);

    if (!this.listeners[key]) {
      this.listeners[key] = {};
    }

    this.listeners[key][type] = true;
  }

  remove({ key, type }) {
    this.dbUsed.ref(key).off(type);

    if (this.listeners[key]) {
      delete this.listeners[key][type];

      if (!Object.keys(this.listeners[key]).length) {
        delete this.listeners[key];
      }
    }
  }

  removeAll() {
    Object.keys(this.listeners).forEach(key => {
      Object.keys(this.listeners[key]).forEach(type => {
        this.remove({ key, type });
      });
    });
  }

  doesExist({ key, type }) {
    return this.listeners[key] && this.listeners[key][type];
  }
}

export default Listener;
