import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import ColumnNamePicker from './ColumnNamePicker';
import { useStyles, getFormControlStyles } from './MUIFormControlHelpers';

export default props => {
  const { value, containerClasses = '', ...columnNameProps } = props;
  const classes = useStyles();
  const formControlStyles = getFormControlStyles({ labelValue: value, classes });

  return (
    <FormControl className={`${formControlStyles} ${containerClasses}`}>
      <ColumnNamePicker {...columnNameProps} value={value} />
    </FormControl>
  );
};
