import {
  getAllCompanyProducts,
  getAllCatalogueProducts,
  getProductLibCatalogueMeta,
  productLibCatalogueSearch,
  productLibProductSearch,
  productLibCatalogueProducts
} from './api';

export const ALL_COMPANY_PRODUCTS = {
  cacheKey: 'ALL_COMPANY_PRODUCTS',
  apiFunction: getAllCompanyProducts
};

export const ALL_CATALOGUE_PRODUCTS = {
  cacheKey: 'ALL_CATALOGUE_PRODUCTS',
  apiFunction: getAllCatalogueProducts
};

export const ALL_CATALOGUE_META = {
  cacheKey: 'ALL_CATALOGUE_META',
  apiFunction: getProductLibCatalogueMeta
};

export const CATALOGUE_SEARCH_DATA = {
  cacheKey: 'CATALOGUE_SEARCH_DATA',
  apiFunction: productLibCatalogueSearch
};

export const PRODUCT_SEARCH_DATA = {
  cacheKey: 'PRODUCT_SEARCH_DATA',
  apiFunctionSearch: productLibProductSearch,
  apiFunction: productLibCatalogueProducts
};
