import React from 'react';
import NumberInput from '../../../../../../Common/NumberInput';
import InfoFormField from '../../../../Common/InfoFormField';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

export default ({ currencySymbol, setPrice, onChangeSetPrice, discount }) => {
  const { t } = getI18N();
  const onChangePrice = value => {
    onChangeSetPrice(value);
  };

  let label = t('set_price');
  if (discount) {
    label += `(${t('discounted_price_and_set_quantity')})`;
  } else {
    label += `(${t('product_price_and_set_quantity')})`;
  }
  return (
    <InfoFormField
      fieldType={'INPUT'}
      fieldLabel={t('set_price')}
      label={label}
      containerClass="productSetPriceContainer"
      fieldContainerClass="inputContainer"
    >
      <div className={'currencySymbol'}>{currencySymbol}</div>
      <NumberInput
        value={setPrice}
        digitsAfterDecimal={3}
        onChange={onChangePrice}
        placeholder={t('type_set_price')}
      />
    </InfoFormField>
  );
};
