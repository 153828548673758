import React, { useState, useRef, Fragment } from 'react';
import { ReactComponent as DeleteIcon } from 'qs-assets/Media/trash.svg';
import Loader from '../../../../../../../Common/Loader';
import { deleteProductOption } from 'qs-data-manager/Variants/ProductOptions';
import Modal from 'react-responsive-modal';
import toastr from 'toastr';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

export default ({ productId, optionId, currentTab }) => {
  const [showLoaderForDelete, toggleShowLoaderForDelete] = useState(false);
  const [showDeleteConfirmation, toggleDeleteConfirmation] = useState(false);

  const { t } = getI18N();

  const modalStyles = useRef({
    color: '#ffffff',
    background: '#252627',
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '500px',
    padding: '8px',
    paddingLeft: '24px'
  });

  const onDeleteOption = () => {
    toggleDeleteConfirmation(true);
  };

  const closeDeleteConfirmation = () => {
    toggleDeleteConfirmation(false);
  };

  const onDeleteConfirmation = async event => {
    event.preventDefault();
    toggleDeleteConfirmation(false);
    toggleShowLoaderForDelete(true);
    deleteProductOption({ productId, optionId, currentTab })
      .then(() => {
        toggleShowLoaderForDelete(false);
      })
      .catch(() => {
        toastr.error(t('something_went_wrong_while_deleting_the_variant'));
        toggleShowLoaderForDelete(false);
      });
  };

  const getDeleteIcon = () => {
    if (showLoaderForDelete) {
      return <Loader size={'extraSmall'} />;
    }
    return <DeleteIcon className="optionDeleteIcon" onClick={onDeleteOption} />;
  };

  return (
    <Fragment>
      <div className="productOptionsDelete">{getDeleteIcon()}</div>
      {showDeleteConfirmation && (
        <Modal
          open={true}
          center={true}
          closeOnOverlayClick={false}
          closeOnEsc={false}
          showCloseIcon={false}
          styles={{ modal: modalStyles.current }}
        >
          <div className="optionDelete confirmationTitle">Delete Option</div>
          <div className="optionDelete confirmationDescription">
            {t('all_associated_variants_with_this_option_will_be_deleted_are_you_sure')}
          </div>
          <div className="optionDelete buttonContainer">
            <button
              type="button"
              className="buttonPlain buttonDelete"
              onClick={onDeleteConfirmation}
            >
              {t('delete')}
            </button>
            <button type="button" className="buttonPlain" onClick={closeDeleteConfirmation}>
              {t('cancel')}
            </button>
          </div>
        </Modal>
      )}
    </Fragment>
  );
};
