import AppConfig from 'qs-config/AppConfig';
import request from '../../Request';
import {
  setLastFetchedOrderTimeStamp,
  setLastFetchedOrderKey
} from 'qs-helpers/Orders/ResponseProcessor';
import {
  ACCEPTED_ORDERS,
  COMPLETED_ORDERS,
  CONFIRMED_ORDERS,
  ORDER_STATUS,
  REJECTED_ORDERS,
  UNCONFIRMED_ORDERS
} from 'qs-helpers/Orders/constants';
import Api from '../../Api';

const getConfirmValueAndOrderStatus = confirmed => {
  switch (confirmed) {
    case CONFIRMED_ORDERS:
      return { confirmFilter: true };
    case UNCONFIRMED_ORDERS:
      return { confirmFilter: false };
    case ACCEPTED_ORDERS:
      return { confirmFilter: true, orderStatus: ORDER_STATUS.ACCEPTED };
    case REJECTED_ORDERS:
      return { confirmFilter: true, orderStatus: ORDER_STATUS.REJECTED };
    case COMPLETED_ORDERS:
      return { confirmFilter: true, orderStatus: ORDER_STATUS.COMPLETED };
    default:
      return {};
  }
};

export const getAllCompanyOrders = async ({
  lastSortKeyValue,
  sortKey,
  lastOrderId,
  confirmed,
  searchText
}) => {
  const { confirmFilter, orderStatus } = getConfirmValueAndOrderStatus(confirmed);
  const queryParamsString = request.createQueryParams([
    {
      key: 'sortKey',
      value: sortKey
    },
    {
      key: 'confirmed',
      value: typeof confirmFilter === 'boolean' ? confirmFilter : null
    },
    {
      key: 'orderStatus',
      value: orderStatus
    },
    {
      key: 'lastSortKeyValue',
      value: lastSortKeyValue
    },
    {
      key: 'lastOrderId',
      value: lastOrderId
    },
    {
      key: 'q',
      value: searchText
    }
  ]);

  const ordersData = await request.authGet(
    `${AppConfig.qsApiEndPoint}/v1/order/company${queryParamsString}`
  );
  setLastFetchedOrderTimeStamp({ sortKey, confirmed, date: ordersData.nextTimeStamp });
  setLastFetchedOrderKey({ sortKey, confirmed, key: ordersData.nextSortKeyValue });
  return ordersData;
};

export const deleteOrder = ({ orderId, updateInventory }) =>
  request.authDelete(`${AppConfig.qsApiEndPoint}/v2/order/app`, { orderId, updateInventory });

export const getOrderInquiries = orderId =>
  request.authGet(
    `${AppConfig.qsApiEndPoint}/v8/order/inquiries?orderId=${encodeURIComponent(
      orderId
    )}&parseText=${true}`
  );

export const updateInquiriesPrice = ({ inquiryId, price, discountedPrice }) =>
  request.authPost(`${AppConfig.qsApiEndPoint}/v2/order/inquiry/price`, {
    inquiryId,
    price,
    discountedPrice
  });

export const deleteInquiries = ({ inquiryIds, updateInventory }) =>
  request.authDelete(`${AppConfig.qsApiEndPoint}/v2/inquiry/app`, { inquiryIds, updateInventory });

export const readInquiries = ({ inquiryIds }) =>
  request.authPost(`${AppConfig.qsApiEndPoint}/v1/showcase-visitor-meta/read/inquiries`, {
    inquiryIds
  });

export const downloadZip = orderId =>
  request.authPost(`${AppConfig.qsApiEndPoint}/v1/order/images/download`, { orderId: orderId });

export const downloadExcel = orderId =>
  request.authPost(`${AppConfig.qsApiEndPoint}/v1/order/excel/download`, {
    orderId: orderId
  });

export const downloadOrderPdf = orderId =>
  request.authPost(`${AppConfig.qsApiEndPoint}/v1/order/pdf/download`, { orderId: orderId });

export const downloadOrderB2BPdf = ({ orderId, timezone }) =>
  request.authPost(`${AppConfig.qsApiEndPoint}/v1/order/pdf/b2b/download`, {
    orderId: orderId,
    timezone
  });

export const sendOrderToVendor = orderId =>
  request.authPost(`${AppConfig.qsApiEndPoint}/v1/order/vendor`, { orderId });

export const setStockCount = ({ productId, stockCount }) =>
  Api.setStockCount({ productId, stockCount });

export const getShippingAddress = orderId =>
  request.authGet(`${AppConfig.qsApiEndPoint}/v1/order/shipping?orderId=${orderId}`);

export const updateInquiryTax = requestBody =>
  request.authPut(`${AppConfig.qsApiEndPoint}/v1/inquiry/product/tax`, requestBody);

export const updateInquiryQuantity = requestBody =>
  request.authPut(`${AppConfig.qsApiEndPoint}/v1/inquiry/quantity`, requestBody);

export const placeShippingOrder = orderId =>
  request.authPost(`${AppConfig.qsApiEndPoint}/v1/order/place-shipping-order`, { orderId });

export const updateOrderShippingPrice = requestBody =>
  request.authPut(`${AppConfig.qsApiEndPoint}/v1/order/shipping-cost`, requestBody);

export const updateOrderStatus = requestBody =>
  request.authPut(`${AppConfig.qsApiEndPoint}/v1/order/status`, requestBody);

export const confirmOrder = (requestBody = {}) =>
  request.authPut(`${AppConfig.qsApiEndPoint}/v1/order/mark-confirmed`, requestBody);

export const getInventoryUpdateOnOrderStatusChange = orderId =>
  request.authGet(`${AppConfig.qsApiEndPoint}/v1/order/inventory/status-change?orderId=${orderId}`);

export const getUnreadInquiryCountForOrderStatus = orderStatus =>
  request.authGet(
    `${AppConfig.qsApiEndPoint}/v1/order/inquiry/status-unread-count?orderStatus=${orderStatus}`
  );

export const updateOrderDiscount = ({ orderId, discount, type }) =>
  request.authPut(`${AppConfig.qsApiEndPoint}/v1/order/discount`, {
    orderId,
    discount,
    type
  });

export const getOrderPdfSettings = () =>
  request.authGet(`${AppConfig.qsApiEndPoint}/v1/company/order-pdf-settings`);

export const setPdfSettings = requestBody =>
  request.authPut(`${AppConfig.qsApiEndPoint}/v1/experiment/bulk/field-value`, requestBody);

export const getOrderNotes = ({ orderId }) =>
  request.authGet(
    `${AppConfig.qsApiEndPoint}/v1/order/notes?orderId=${encodeURIComponent(orderId)}`
  );

export const saveOrderNotes = ({ orderId, notes, addToInvoice }) =>
  request.authPost(`${AppConfig.qsApiEndPoint}/v1/order/notes`, { orderId, notes, addToInvoice });

export const deleteOrderNotes = ({ orderId }) =>
  request.authDelete(`${AppConfig.qsApiEndPoint}/v1/order/notes`, { orderId });

export const saveCustomerInquiryText = ({ orderId, inquiryId, inquiryText }) =>
  request.authPost(`${AppConfig.qsApiEndPoint}/v1/inquiry/inquiry-text`, {
    orderId,
    inquiryId,
    inquiryText
  });

export const saveMerchantInquiryText = ({ inquiryId, notes }) =>
  request.authPut(`${AppConfig.qsApiEndPoint}/v1/inquiry/${inquiryId}/merchant-notes`, {
    inquiryId,
    notes
  });
