import React from 'react';
import './styles.scss';
import { ReactComponent as CloseIcon } from 'qs-assets/Media/close.svg';
import { DIAMOND, showStoneValueDetailsName, STONE } from '../ProductTypeDetails';
import { getPriceFormattedComponent } from 'qs-helpers';
import { getI18N } from 'qs-services/i18N';

const addStoneCharges = ['DIAMOND', 'STONE'];

const StoneCharges = ({
  setShowStonesModal,
  productStone,
  setAddStoneDetails,
  setShowConfirmationBox
}) => {
  const { t } = getI18N();

  const onDelete = (id, name, stoneDetails, index) => {
    setShowConfirmationBox(true);
    setAddStoneDetails(() => ({
      position: index,
      productId: stoneDetails.productId,
      type: name,
      id: id,
      ...stoneDetails.details
    }));
  };

  const openStoneModal = (id, name, stoneDetails, index) => {
    setAddStoneDetails(prevState => ({
      ...prevState,
      position: index,
      type: name,
      id: id,
      ...stoneDetails.details
    }));
    if (name === DIAMOND.id) {
      setShowStonesModal(DIAMOND.id);
      return;
    }
    setShowStonesModal(STONE.id);
  };

  return (
    <div className="stoneChargesContainer">
      <div className="title">{t('stone_charges')}</div>
      {productStone &&
        productStone.length > 0 &&
        productStone.map((stoneDetails, index) => {
          const { id, type, details } = stoneDetails;
          return (
            <div className="productStoneValueContainer" key={id}>
              <div className="productStoneTitleAndTypeContainer">
                <div className="productStoneTitle">
                  {type === DIAMOND.id ? t('diamond') : t(details.name)}
                </div>
                <div className="productStoneType">
                  {showStoneValueDetailsName(id, productStone)}
                </div>
                <div className="edit" onClick={() => openStoneModal(id, type, stoneDetails, index)}>
                  {t('edit')}
                </div>
              </div>
              <div className="amountTitleAndCostContainer">
                <div className="amountTitle">{t('amount')}</div>
                <div className="price">{getPriceFormattedComponent(details.cost)}</div>
              </div>
              <div
                className="closeIconContainer"
                onClick={() => onDelete(id, type, stoneDetails, index)}
              >
                <CloseIcon className="closeIcon" />
              </div>
            </div>
          );
        })}
      <div className="addChargesContainer">
        {addStoneCharges.map((name, index) => {
          return (
            <div
              key={index}
              className="addCharge"
              onClick={() => {
                setShowStonesModal(name);
                setAddStoneDetails(prevState => ({
                  ...prevState,
                  type: name
                }));
              }}
            >
              {name === STONE.id ? `+ ${t('add_stone')}` : `+ ${t('add_diamond')}`}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default StoneCharges;
