import React from 'react';
import Loader from '../../../../Common/Loader';
import NumberInput from '../../../../Common/NumberInput';
import InfoFormField from '../InfoFormField';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

export default ({
  loading,
  error,
  disabled,
  moq,
  onChange,
  changeButton = false,
  onChangeClick,
  isSet,
  children
}) => {
  const { t } = getI18N();
  const getSetOrPieceText = () => {
    let endText = t('piece').toLowerCase();
    if (isSet) {
      endText = t('set').toLowerCase();
    }
    const moqNum = Number(moq);
    if (!Number.isNaN(moqNum) && moqNum > 1) {
      endText = t('pieces').toLowerCase();
    }

    return endText;
  };

  if (error) {
    return null;
  }

  if (loading) {
    return <Loader size="small" />;
  }

  return (
    <InfoFormField
      fieldType={'INPUT'}
      fieldLabel="Minimum order quantity"
      label={t('minimum_order_quantity')}
      changeButton={changeButton}
      onChangeClick={onChangeClick}
      fieldContainerClass="productMoqFieldContainer"
    >
      <div className="productMoqInputContainer">
        <NumberInput
          value={moq}
          onChange={onChange}
          inputClassName="moqInput"
          disabled={changeButton || disabled}
          placeholder={t('type_moq')}
          integer
        />
        <span className="moqSetText ellipsis">{getSetOrPieceText()}</span>
      </div>
      {children}
    </InfoFormField>
  );
};
