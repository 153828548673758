import {
  getInventory,
  attachInventoryListener,
  removeInventoryListener
} from 'qs-data-manager/ProductDetails';
import Api from '../../Api';
import { reportError } from 'qs-helpers/ErrorReporting';
import { connector } from 'qs-data-manager/ApiAndCacheConnector';
import CacheRequest from 'qs-data-manager/CacheRequest';
import {
  getVariantsMetaDataFromCache,
  setVariantsMetaDataInCache,
  getVariantsDataForProductFromCache
} from './FetchVariants';
import { toggleGlobalLoader } from 'qs-helpers';
import CacheListenerCallback from 'qs-helpers/CacheListenerCallback';
import { VARIANT_INVENTORY_OPERATION, VARIANT_OPERATION_KEYS } from 'qs-helpers/Variants/constants';

const VARIANT_INVENTORY_REQUEST_SEND = {};
const VARIANT_INVENTORY_RESPONSE_RECEIVED = {};

export const attachVariantOperationListener = ({ listener, variantId }) => {
  const key = `${VARIANT_INVENTORY_OPERATION}${variantId}`;
  CacheRequest.attachListener(key, listener);
};

export const removeVariantOperationListener = ({ listener, variantId }) => {
  const key = `${VARIANT_INVENTORY_OPERATION}${variantId}`;
  CacheRequest.removeListener(key, listener);
};

export const setVariantOperationData = ({ variantId, updates = [] }) => {
  const key = `${VARIANT_INVENTORY_OPERATION}${variantId}`;
  const oldOperationData = CacheRequest.getCacheForKey(key) || {};
  const newOperationData = { ...oldOperationData };

  updates.forEach(updateData => {
    let existingCount = newOperationData[updateData.key] || 0;
    if (updateData.inProgress) {
      existingCount++;
      newOperationData[updateData.key] = existingCount;
      return;
    }

    existingCount--;
    if (existingCount <= 0) {
      delete newOperationData[updateData.key];
      return;
    }

    newOperationData[updateData.key] = existingCount;
  });

  CacheRequest.setCacheForKey(key, newOperationData);
};

export const updateVariantMeta = ({ variantId, stock }) => {
  const variantMeta = getVariantsMetaDataFromCache(variantId);
  if (!variantMeta) {
    return;
  }

  setVariantsMetaDataInCache({
    variantId,
    metaData: {
      ...variantMeta,
      inventory: stock
    }
  });
};

export const updateStockCount = async ({ variantId, stock }) => {
  const stockCount = Number(stock);
  try {
    await Api.setStockCount({ productId: variantId, stockCount });
    updateInventoryData({ inventoryId: variantId, count: stockCount });
    updateVariantMeta({ variantId, stock: stockCount });
  } catch (error) {
    reportError(error);
    throw error;
  }
};

export const updateVariantsStock = async ({ variantId, stock }) => {
  const loaderKey = `remoteSaveInventory${Date.now()}`;
  toggleGlobalLoader(loaderKey, true);
  try {
    await updateStockCount({ variantId, stock });
  } catch (updateError) {
    //No-op simply consume error
  } finally {
    toggleGlobalLoader(loaderKey, false);
  }
};

export const updateVariantsStockAndUpdateOperation = async ({ variantId, stock }) => {
  setVariantOperationData({
    variantId,
    updates: [
      {
        key: VARIANT_OPERATION_KEYS.STOCK_CHANGE,
        inProgress: true
      }
    ]
  });

  await updateVariantsStock({ variantId, stock });

  setVariantOperationData({
    variantId,
    updates: [
      {
        key: VARIANT_OPERATION_KEYS.STOCK_CHANGE,
        inProgress: false
      }
    ]
  });
};

export const updateTrackQuantity = async ({ variantId, trackInventory, setQuantity }) => {
  if (setQuantity) {
    try {
      //Updating the stock failed, making API call to set track inventory will not yeild any thing
      await updateStockCount({ variantId, stock: 1 });
    } catch (updateStockError) {
      reportError(updateStockError);
      throw updateStockError;
    }
  }

  try {
    await Api.setInventoryTracking({ productIds: [variantId], value: trackInventory });
    updateInventoryData({ inventoryId: variantId, trackInventory });
  } catch (error) {
    reportError(error);
    throw error;
  }
};

export const isVariantInventoryListenerRequired = ({ variantId }) => {
  if (VARIANT_INVENTORY_RESPONSE_RECEIVED[variantId]) {
    return false;
  }

  return true;
};

export const getVariantInventoryData = async ({ variantId } = {}) => {
  if (VARIANT_INVENTORY_REQUEST_SEND[variantId]) {
    return;
  }

  VARIANT_INVENTORY_REQUEST_SEND[variantId] = true;
  VARIANT_INVENTORY_RESPONSE_RECEIVED[variantId] = false;

  const listener = (error, payload) => {
    const { err, loading, refreshing, remoteSuccess } = CacheListenerCallback(error, payload);

    //Do not process anything as long as the data is loading/refreshing
    if (loading || refreshing) {
      return;
    }

    if (err) {
      /*
        Rest call failed, remove from the caching to allow the request to
        be sent again.
      */
      delete VARIANT_INVENTORY_REQUEST_SEND[variantId];
    }

    if (remoteSuccess) {
      //Response received from the call, mark it as such so that a listener
      //need not be attached to the component
      if (typeof VARIANT_INVENTORY_RESPONSE_RECEIVED[variantId] === 'boolean') {
        VARIANT_INVENTORY_RESPONSE_RECEIVED[variantId] = true;
      }
    }

    removeInventoryListener({
      listener,
      productId: variantId
    });
  };

  attachInventoryListener({
    listener,
    productId: variantId
  });
  getInventory({ productId: variantId });
};

export const clearSavedVariantInventoryData = productId => {
  const cachedVariantsData = getVariantsDataForProductFromCache(productId);
  if (!cachedVariantsData) {
    return;
  }

  const { variantProductIds } = cachedVariantsData;
  if (!Array.isArray(variantProductIds)) {
    return;
  }

  variantProductIds.forEach(([variantId]) => {
    delete VARIANT_INVENTORY_REQUEST_SEND[variantId];
    delete VARIANT_INVENTORY_RESPONSE_RECEIVED[variantId];
  });
};

export const getInventoryForVariantFromCache = ({ variantId }) => {
  if (!variantId) {
    return {};
  }

  const cacheKey = `${connector.INVENTORY.cacheKey}${variantId}`;
  const cache = CacheRequest.getCacheForKey(cacheKey);
  if (!cache) {
    return {};
  }

  const setData = {};
  if (cache.setDetails) {
    setData.isSet = cache.setDetails.isSet;
    setData.setQuantity = cache.setDetails.setQuantity;
  }

  return {
    trackInventory: cache.trackInventory,
    stockCount: typeof cache.count === 'number' ? cache.count : 1,
    ...setData
  };
};

export const updateInventoryData = ({
  inventoryId,
  trackInventory,
  count,
  autoReduceInventory,
  showOutOfStockProduct,
  allowOrdersOnOutOfStock
}) => {
  const cacheKey = `${connector.INVENTORY.cacheKey}${inventoryId}`;
  const cache = CacheRequest.getCacheForKey(cacheKey);
  if (!cache) {
    return;
  }

  const newCache = { ...cache };
  if (typeof trackInventory !== 'undefined') {
    newCache.trackInventory = trackInventory;
  }

  if (typeof count !== 'undefined') {
    newCache.count = count;
  }

  if (typeof autoReduceInventory !== 'undefined') {
    newCache.autoReduceInventory = autoReduceInventory;
  }

  if (typeof showOutOfStockProduct !== 'undefined') {
    if (typeof showOutOfStockProduct === 'boolean') {
      newCache.showOutOfStockProduct = showOutOfStockProduct;
    } else if (showOutOfStockProduct === null) {
      delete newCache.showOutOfStockProduct;
    }
  }

  if (typeof allowOrdersOnOutOfStock !== 'undefined') {
    if (typeof allowOrdersOnOutOfStock === 'boolean') {
      newCache.allowOrdersOnOutOfStock = allowOrdersOnOutOfStock;
    } else if (allowOrdersOnOutOfStock === null) {
      delete newCache.allowOrdersOnOutOfStock;
    }
  }

  CacheRequest.setCacheForKey(cacheKey, newCache);
};
