import { getI18N } from 'qs-services/i18N';
import React from 'react';

export default ({ columnNamesLength, excelRowCount }) => {
  const { t } = getI18N();
  return (
    <div className="header">
      <div className="title">{t('bulk_import')}</div>
      <div className="column-count">{t('length_columns', { columnLength: columnNamesLength })}</div>
      <div className="product-count">
        {excelRowCount - 1 === 1
          ? t('selected_product', { selectedProductsCount: excelRowCount - 1 })
          : t('selected_products', { selectedProductsCount: excelRowCount - 1 })}
      </div>
    </div>
  );
};
