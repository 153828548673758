import React, { useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#FFFFFF',
    color: '#000000'
  }
}));

export default function Toast(props) {
  const [state] = useState({
    vertical: 'bottom',
    horizontal: 'center'
  });
  const classes = useStyles();

  const { vertical, horizontal } = state;

  return (
    <Snackbar
      ContentProps={{
        classes: {
          root: classes.root
        }
      }}
      anchorOrigin={{ vertical, horizontal }}
      key={`${vertical},${horizontal}`}
      open={props.open}
      message={props.message}
      onClose={props.onClose}
      autoHideDuration={props.duration || 2000}
    />
  );
}
