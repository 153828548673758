import Api from 'qs-services/Api';
import * as Sentry from '@sentry/browser';
import { getCompanyTaxes } from 'qs-api/Tax/api';

const getAllTaxes = async () => {
  try {
    return await getCompanyTaxes();
  } catch (error) {
    Sentry.captureException(error);
  }
};

const getProductTax = productId => {
  try {
    return Api.getProductTax(productId);
  } catch (error) {
    Sentry.captureException(error);
  }
};

const setProductTax = ({ productId, taxId } = {}) => {
  try {
    return Api.addProductTax({ productId, taxId });
  } catch (error) {
    Sentry.captureException(error);
  }
};

const removeProductTax = ({ productId, taxId } = {}) => {
  try {
    return Api.removeProductTax({ productId, taxId });
  } catch (error) {
    Sentry.captureException(error);
  }
};

const removeProductTaxBulk = (productIds = []) => {
  try {
    return Api.removeProductTaxBulk(productIds);
  } catch (error) {
    Sentry.captureException(error);
  }
};

const setProductTaxBulk = ({ productIds = [], taxId }) => {
  try {
    return Api.setProductTaxBulk({ productIds, taxId });
  } catch (error) {
    Sentry.captureException(error);
  }
};

export default {
  getAllTaxes,
  setProductTax,
  getProductTax,
  removeProductTaxBulk,
  removeProductTax,
  setProductTaxBulk
};
