import React, { useCallback, useState, useEffect, useContext } from 'react';
import { Box, Typography, Input, Select, MenuItem, Button, Chip, Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import eventbus from 'eventing-bus';
import Api from 'qs-services/Api';
import Loader from 'qs-components/Common/Loader';
import toastr from 'toastr';
import { navigateToPath } from 'qs-data-manager';
import PrivateSvg from 'qs-assets/Media/private_filled.svg';
import { VariantDataForUpdate } from '../ProductVariantView/context';
import { CUSTOM_FIELDS_CHANGED } from '../ProductVariantView/reducer';
import {
  ROUTE_MAP,
  SETTINGS_COMPONENT_ID_MAP,
  SETTINGS_COMPONENT_ROUTE_MAP
} from 'qs-helpers/CompanySettings/constants';
import { SAVE_BUTTON_META } from 'qs-helpers/Products/constants';
import { getI18N } from 'qs-services/i18N';

const useStyles = makeStyles({
  label: {
    fontSize: 19,
    fontWeight: 600,
    color: '#FFFFFF',
    marginRight: '1.5rem'
  },
  fieldsContainer: {
    padding: 26,
    height: '100%',
    overflow: 'auto'
  },
  inputBox: {
    marginBottom: '1rem'
  },
  input: {
    color: '#FFFFFF'
  },
  root: {
    color: 'white',
    marginBottom: '1.5rem',
    fontSize: 16,
    fontWeight: 500
  },
  underline: {
    borderBottom: '1px solid #565D70',
    '&:after': {
      borderBottom: '1px solid #565D70'
    },
    '&:before': {
      borderBottom: '1px solid #565D70 !important'
    }
  },
  icon: {
    color: '#565D70'
  },
  button: {
    color: '#82B4FB',
    fontSize: 16,
    textTransform: 'none',
    fontWeight: 400,
    padding: 0,
    marginBottom: '1rem'
  },
  manageBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    textDecoration: 'underline',
    textDecorationColor: '#82B4FB'
  },
  fieldName: {
    display: 'flex'
  },
  chip: {
    height: 28,
    background: '#47515E',
    color: '#B7C4D5',
    textTransform: 'uppercase'
  },
  avatar: {
    width: '20px !important',
    height: '20px !important'
  },
  variantType: {
    padding: 0
  },
  noFields: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#738399',
    height: '90%'
  }
});

const patterns = {
  STRING: '',
  NUMBER: '^[0-9]{0,}(?:\\.[0-9]{0,5})?$',
  BOOLEAN: ''
};

function CustomText({ label, onChange, value, type, id, isPrivate }) {
  const classes = useStyles();
  const { t } = getI18N();

  const handleChange = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();
      const {
        target: { value }
      } = e;
      if (new RegExp(patterns[type]).test(value)) {
        onChange(value, id);
      }
    },
    [type, onChange, id]
  );

  return (
    <Box className={classes.inputBox}>
      <div className={classes.fieldName}>
        <Typography className={classes.label}>{label}</Typography>
        {isPrivate && (
          <Chip
            className={classes.chip}
            avatar={<Avatar className={classes.avatar} alt={t('private')} src={PrivateSvg} />}
            label={t('private')}
          />
        )}
      </div>
      {type === 'BOOLEAN' ? (
        <Select
          labelId={label}
          value={value === null ? '' : value}
          classes={{
            icon: classes.icon
          }}
          input={
            <Input
              classes={{
                root: classes.root,
                underline: classes.underline
              }}
            />
          }
          onChange={handleChange}
          fullWidth
        >
          <MenuItem value={true}>{t('true')}</MenuItem>
          <MenuItem value={false}>{t('false')}</MenuItem>
        </Select>
      ) : (
        <Input
          id={`text-${label.replace(/ /g, '-')}`}
          placeholder={`${t('enter')} ${label}`}
          classes={{
            underline: classes.underline,
            root: classes.root
          }}
          fullWidth
          value={value || ''}
          onChange={handleChange}
          className={classes.input}
        />
      )}
    </Box>
  );
}

let inputTimer = null;

export default function CustomFields({ activeProductId, activeProductIds, isVariant }) {
  const [fieldsData, setFieldsData] = useState({});
  const classes = useStyles();

  const [fields, setFields] = useState([]);
  const [loadingFields, setLoading] = useState(false);

  const [privateFieldIds, setPrivateFields] = useState([]);

  const { setVariantData } = useContext(VariantDataForUpdate);
  const { t } = getI18N();

  const getFields = useCallback(() => {
    const callApi = async () => {
      setLoading(true);
      try {
        const { fields: allFields } = await Api.getAllCustomFields();
        const privateFields = Array.isArray(allFields)
          ? allFields.filter(one => one.visibility === 'PRIVATE').map(one => one.id)
          : [];
        setPrivateFields(privateFields);
        const { fields } = await Api.getProductCustomFields(activeProductId);
        fields.forEach(field => {
          fieldsData[field.fieldId] = { value: field.fieldValue, type: field.fieldType };
        });
        setFieldsData({ ...fieldsData });
        setFields(fields);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        toastr.error(t('something_went_wrong_while_getting_custom_fields'));
      }
    };
    callApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeProductId, loadingFields, privateFieldIds, fields, fieldsData]);

  useEffect(() => {
    getFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeProductId]);

  const handleChange = (value, id, type) => {
    fieldsData[id] = { type, value };
    setFieldsData({ ...fieldsData });
    clearTimeout(inputTimer);
    inputTimer = setTimeout(() => {
      clearTimeout(inputTimer);
      if (isVariant) {
        setVariantData({
          type: CUSTOM_FIELDS_CHANGED,
          fieldsData
        });
      } else {
        eventbus.publish(SAVE_BUTTON_META.eventbusKey, {
          id: SAVE_BUTTON_META.PRODUCT_CUSTOM_FIELD.id,
          hasChanged: true,
          data: { ...fieldsData },
          eventType: SAVE_BUTTON_META.eventType.PRODUCT_META.id
        });
      }
    }, 1000);
  };

  const onManageFields = () => {
    navigateToPath(
      `/${ROUTE_MAP.ACCOUNT_SETTINGS}/${
        SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CUSTOM_FIELDS].ROUTE
      }`
    );
  };

  return (
    <Box className={`${classes.fieldsContainer} ${isVariant ? classes.variantType : ''}`}>
      <div className={classes.manageBtn}>
        <Button onClick={onManageFields} className={classes.button}>
          {t('manage_fields')}
        </Button>
      </div>
      {loadingFields ? (
        <Box>
          <Loader size="large" />
        </Box>
      ) : fields.length > 0 ? (
        fields.map(({ fieldName, fieldType, fieldId }) => (
          <CustomText
            key={fieldId}
            label={fieldName}
            onChange={(val, id) => handleChange(val, id, fieldType)}
            value={fieldsData[fieldId].value}
            type={fieldsData[fieldId].type}
            id={fieldId}
            isPrivate={privateFieldIds.includes(fieldId)}
          />
        ))
      ) : (
        <div className={classes.noFields}>{t('no_custom_fields')}</div>
      )}
    </Box>
  );
}
