import React, { useReducer, useEffect, useRef, useCallback } from 'react';
import eventbus from 'eventing-bus';
import { saveProductDetailsChanges } from 'qs-data-manager/ProductDetails';
import { SAVE_BUTTON_META } from 'qs-helpers/Products/constants';
import './styles.scss';
import { getI18N } from 'qs-services/i18N';

const initialState = () => ({
  showSaveButton: false,
  saveButtonData: {}
});

const saveReducer = (state, action) => {
  switch (action.type) {
    case 'NO_CHANGE_FOR_ID': {
      const newState = {
        showSaveButton: state.showSaveButton,
        saveButtonData: { ...state.saveButtonData }
      };
      delete newState.saveButtonData[action.id];
      if (Object.keys(newState.saveButtonData).length === 0) {
        newState.showSaveButton = false;
      }
      return newState;
    }
    case 'CHANGE_FOR_ID': {
      const newState = {
        showSaveButton: true,
        saveButtonData: { ...state.saveButtonData }
      };
      newState.saveButtonData[action.id] = action.data;
      return newState;
    }
    case 'RESET':
      return initialState();
    default:
      return state;
  }
};

export default ({ activeProductId }) => {
  const [saveButtonState, saveButtonDispatch] = useReducer(saveReducer, null, initialState);
  const registeredValidators = useRef({});
  const { t } = getI18N();
  useEffect(() => {
    //Reset state on change of active product id
    saveButtonDispatch({ type: 'RESET' });
  }, [activeProductId]);

  const isDataValid = savedData => {
    for (const key in registeredValidators.current) {
      if (!registeredValidators.current.hasOwnProperty(key)) {
        continue;
      }

      const validator = registeredValidators.current[key];
      if (typeof validator !== 'function') {
        continue;
      }

      // Validator returns false, show error message and do not proceed further
      const { valid, errorMessage } = validator(savedData);
      if (!valid) {
        window.alert(t(errorMessage));
        return false;
      }
    }

    return true;
  };

  const onSaveClick = useCallback(
    event => {
      if (event) {
        event.stopPropagation();
      }

      if (Object.keys(saveButtonState.saveButtonData).length) {
        if (!isDataValid(saveButtonState.saveButtonData)) {
          return;
        }

        saveProductDetailsChanges({ data: saveButtonState.saveButtonData });
      }
      saveButtonDispatch({
        type: 'RESET'
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [saveButtonState.saveButtonData]
  );

  // Effect for when some data is changed in the bottom sheet
  useEffect(() => {
    const removeEventbus = eventbus.on(
      SAVE_BUTTON_META.eventbusKey,
      ({
        id,
        hasChanged,
        data,
        validator,
        eventType = SAVE_BUTTON_META.eventType.PRODUCT_META.id
      }) => {
        if (eventType === SAVE_BUTTON_META.eventType.REGISTER_VALIDATOR.id) {
          registeredValidators.current[id] = validator;
          return;
        }
        if (eventType === SAVE_BUTTON_META.eventType.UNREGISTER_VALIDATOR.id) {
          delete registeredValidators.current[id];
          return;
        }
        if (eventType === SAVE_BUTTON_META.eventType.AUTO_SAVE.id) {
          onSaveClick();
          return;
        }

        if (!hasChanged) {
          saveButtonDispatch({
            type: 'NO_CHANGE_FOR_ID',
            id
          });
          return;
        }

        saveButtonDispatch({
          type: 'CHANGE_FOR_ID',
          id,
          data
        });
      }
    );

    return () => removeEventbus();
  }, [onSaveClick]);

  return saveButtonState.showSaveButton ? (
    <div onClick={onSaveClick} className={'productDetailsSaveButton'}>
      {t('save')}
    </div>
  ) : null;
};
