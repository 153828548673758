export const B2B_PDF_CANT_GENERATE_REASONS = {
  ORDER_MISSING_EXPAND_VARIANT_B2B_INVOICE: 'ORDER_MISSING_EXPAND_VARIANT_B2B_INVOICE',
  ORDER_WRONG_VARIANT_COMBINATIONS_B2B_INVOICE: 'ORDER_WRONG_VARIANT_COMBINATIONS_B2B_INVOICE',
  ORDER_TOO_MANY_EXPAND_VARIANT_COLUMNS_B2B_INVOICE:
    'ORDER_TOO_MANY_EXPAND_VARIANT_COLUMNS_B2B_INVOICE'
};

export const B2BErrorReasonMap = {
  [B2B_PDF_CANT_GENERATE_REASONS.ORDER_MISSING_EXPAND_VARIANT_B2B_INVOICE]:
    'b2b_format_only_available_when_all_products_in_the_order_have_a_size_variant',
  [B2B_PDF_CANT_GENERATE_REASONS.ORDER_WRONG_VARIANT_COMBINATIONS_B2B_INVOICE]:
    'b2b_format_only_available_for_valid_variant_combinations',
  [B2B_PDF_CANT_GENERATE_REASONS.ORDER_TOO_MANY_EXPAND_VARIANT_COLUMNS_B2B_INVOICE]:
    'b2b_format_only_available_for_a_small_number_of_unique_sizes'
};
