import React, { useState, useCallback, useEffect, useContext } from 'react';
import TagsMenu from './TagsMenu';
import NonVirtualisedList from 'qs-components/Common/NonVirtualisedList';
import CatalogueTagReorderModal from './CatalogueTagReorderModal';
import CatalogueLib from 'qs-data-manager/Catalogues';
import CacheListenerCallback from 'qs-helpers/CacheListenerCallback';
import { selectedProducts } from 'qs-data-manager/Selected';

import { ReactComponent as EditIcon } from 'qs-assets/Media/pencil.svg';
import { ActiveCatalogueProductListMeta } from '../../context';
import {
  REMOVE_MISSING_TAGS_PRODUCT_LIST,
  TOGGLE_PRODUCT_LIST_TAGS,
  UNSET_PRODUCT_LIST_META_RESET,
  UPDATE_PRODUCT_LIST_TAGS
} from '../../reducer';
import {
  attachCatalogueTagsListener,
  deleteProductsFilteredCatalogueTags,
  removeCatalogueTagsListener
} from 'qs-data-manager/CatalogueTags/Tags';
import './styles.scss';
import { EDIT_ACTIVE } from 'qs-helpers/Products/constants';
import { getI18N } from 'qs-services/i18N';

export default ({ activeCatalogueId: catalogueId }) => {
  const {
    productListMeta: { currentSelectedTags, reset },
    setProductListMeta
  } = useContext(ActiveCatalogueProductListMeta);

  const [tagsState, setTagState] = useState({
    loading: false,
    refreshing: false,
    err: null,
    sortedTags: null
  });

  const { t } = getI18N();

  const [editModalActive, setEditModalActive] = useState(false);
  const [editMode, setEditMode] = useState(() => selectedProducts.isActive(EDIT_ACTIVE));

  const selectedCount = currentSelectedTags.size;

  useEffect(() => {
    const onSelectionStateChange = isActive => {
      setEditMode(isActive);
    };
    selectedProducts.addActiveListener(EDIT_ACTIVE, onSelectionStateChange);
    return () => {
      selectedProducts.removeActiveListener(EDIT_ACTIVE, onSelectionStateChange);
    };
  }, []);

  const processTagData = useCallback(({ tags } = {}, apiData = {}) => {
    if (!tags) {
      return;
    }

    const sortedTags = Object.keys(tags)
      .sort((t1, t2) => {
        if (tags[t1].v === tags[t2].v) {
          return tags[t1].p - tags[t2].p;
        }

        return tags[t2].v ? 1 : -1;
      })
      .map(tag => tags[tag]);

    setTagState(prevState => ({
      ...prevState,
      sortedTags,
      ...apiData
    }));
  }, []);

  useEffect(() => {
    const catalogueTagListener = (error, payload) => {
      const { err, loading, refreshing, data } = CacheListenerCallback(error, payload);
      if (err) {
        setTagState(prevState => ({
          ...prevState,
          err,
          loading
        }));

        return;
      }

      if (loading) {
        setTagState(prevState => ({
          ...prevState,
          loading,
          refreshing,
          err
        }));

        return;
      }

      processTagData(data, { loading, refreshing, err });
    };

    processTagData(CatalogueLib.getCatalogueTagsFromCache(catalogueId));
    CatalogueLib.attachCatalogueTagsListener(catalogueTagListener, catalogueId);
    CatalogueLib.getCatalogueTags(catalogueId);

    return () => CatalogueLib.removeCatalogueTagsListener(catalogueTagListener, catalogueId);
  }, [catalogueId, processTagData]);

  useEffect(() => {
    const filterListener = (error, payload) => {
      const { data } = CacheListenerCallback(error, payload);
      const cachedTagData = CatalogueLib.getCatalogueTagsFromCache(catalogueId);
      if (!cachedTagData || !cachedTagData.tags) {
        return;
      }

      if (!data || !data.tags) {
        processTagData({ tags: cachedTagData.tags });
        return;
      }

      let tagsToSort = cachedTagData.tags;
      const tagsToFilter = new Set();
      data.tags.forEach(({ tag }) => tagsToFilter.add(tag));
      tagsToSort = Object.keys(tagsToSort).reduce((cumulativeMap, tagId) => {
        if (tagsToFilter.has(tagId)) {
          cumulativeMap[tagId] = tagsToSort[tagId];
        }
        return cumulativeMap;
      }, {});
      processTagData({ tags: tagsToSort });
    };

    attachCatalogueTagsListener({
      listener: filterListener,
      catalogueId,
      productFilter: true
    });

    return () =>
      removeCatalogueTagsListener({
        listener: filterListener,
        catalogueId,
        productFilter: true
      });
  }, [catalogueId, processTagData]);

  useEffect(() => {
    if (reset) {
      setProductListMeta({ type: UNSET_PRODUCT_LIST_META_RESET });
      deleteProductsFilteredCatalogueTags(catalogueId);
    }
  }, [reset, setProductListMeta, catalogueId]);

  useEffect(() => () => deleteProductsFilteredCatalogueTags(catalogueId), [catalogueId]);

  useEffect(() => {
    setProductListMeta({ type: REMOVE_MISSING_TAGS_PRODUCT_LIST, tags: tagsState.sortedTags });
  }, [tagsState.sortedTags, setProductListMeta]);

  const onEditPress = useCallback(e => {
    e.stopPropagation();
    setEditModalActive(prevState => !prevState);
  }, []);

  const onClearTagsPress = useCallback(() => {
    setProductListMeta({ type: UPDATE_PRODUCT_LIST_TAGS });
  }, [setProductListMeta]);

  const onEditModalClose = useCallback(e => {
    e.stopPropagation();
    setEditModalActive(prevState => !prevState);
  }, []);

  const onToggleTag = useCallback(
    tagId => {
      setProductListMeta({ type: TOGGLE_PRODUCT_LIST_TAGS, tagId });
    },
    [setProductListMeta]
  );

  const renderItem = useCallback(
    ({ item: tag }) => {
      const isSelected = currentSelectedTags.has(tag.title);
      return (
        <button
          disabled={!tag.v}
          className={`tag ${isSelected ? 'selectedTag' : ''} ${!tag.v ? 'tagNotVisible' : ''}`}
          onClick={() => onToggleTag(tag.title)}
        >
          <span className={`tagText ${!tag.v && 'inactiveTagText'}`}>{tag.title}</span>
        </button>
      );
    },
    [onToggleTag, currentSelectedTags]
  );

  const keyExtractor = useCallback(item => item.title, []);

  if (!tagsState.sortedTags || !tagsState.sortedTags.length) {
    return null;
  }

  return (
    <div className="catalogueTagsSelector">
      <div className="tagsContainer">
        {editMode && (
          <div className="editButtonContainer">
            <button className="editButton" onClick={onEditPress}>
              <EditIcon fill="#4BC68C" />
            </button>
          </div>
        )}

        <NonVirtualisedList
          data={tagsState.sortedTags}
          renderItem={renderItem}
          keyExtractor={keyExtractor}
          contentContainerClassName={editMode ? 'tagsList' : 'tagsList noEdit'}
          contentContainerStyle={{ overflow: 'auto' }}
          ItemSeparatorComponent={() => <div className="tagSeparator" />}
          ListHeaderComponent={() => <div className="tagSeparator" />}
          ListFooterComponent={() => (
            <div style={{ width: tagsState.sortedTags.length > 2 ? 85 : 10 }} />
          )}
        />

        {tagsState.sortedTags.length > 2 && (
          <TagsMenu sortedTags={tagsState.sortedTags} onToggleTag={onToggleTag} />
        )}
      </div>

      {!!selectedCount && (
        <div className="clearSelectedContainer">
          <div className="clearSelectedButton" onClick={onClearTagsPress}>
            <span className="clearSelectedText">
              {selectedCount === 1
                ? t('clear_filters_count', { filterCount: selectedCount })
                : t('clear_filter_count', { filterCount: selectedCount })}
            </span>
          </div>
        </div>
      )}

      {!!editModalActive && (
        <CatalogueTagReorderModal
          open={editModalActive}
          onClose={onEditModalClose}
          tags={tagsState.sortedTags}
          catalogueId={catalogueId}
        />
      )}
    </div>
  );
};
