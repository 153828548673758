import React, { useState, useRef } from 'react';
import Switch from 'react-switch';
import Modal from 'react-responsive-modal';

import useMakeQuery from 'qs-hooks/useMakeQuery';
import {
  getCachedPublishToHomepage,
  setupPublishToHomepageHandler,
  handlePublishToHomepageCleanup
} from 'qs-helpers/Catalogues/DataQueryHelper';
import toastr from 'toastr';
import { toggleGlobalLoader } from 'qs-helpers';
import { updatePublishToHomepage } from 'qs-data-manager/Catalogues/PublishToHomepage';
import { navigateToPath } from 'qs-data-manager';
import {
  ROUTE_MAP,
  SETTINGS_COMPONENT_ID_MAP,
  SETTINGS_COMPONENT_ROUTE_MAP
} from 'qs-helpers/CompanySettings/constants';

import './styles.scss';
import { getCachedDomainData } from 'qs-helpers/Domain/DataQueryHelper';
import { isAnyDomainConnected } from 'qs-helpers/Domain/ResponseProcessor';
import { ReactComponent as HomeIcon } from 'qs-assets/Media/publishToHomePage.svg';
import RightIcon from 'qs-assets/Media/right-arrow.png';
import { getI18N } from 'qs-services/i18N';

const ERROR_MESSAGE = 'something_went_wrong_while_updating_publish_to_store_website_status';

export default ({ catalogueId, loadingLink }) => {
  const [showDomainUnvailableDialog, setShowDomainUnvailableDialog] = useState(false);
  const { t } = getI18N();
  const modalStyles = useRef({
    backgroundImage: 'linear-gradient(to bottom, rgba(36, 44, 54, 0.8), rgba(36, 44, 54, 1))',
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    width: '275px',
    padding: '20px'
  });
  const [{ data }] = useMakeQuery({
    changeDependancy: [catalogueId],
    cachedDataHandler: getCachedPublishToHomepage,
    actionHandler: setupPublishToHomepageHandler,
    cleanupHandler: handlePublishToHomepageCleanup,
    globalLoader: false
  });

  const isPublished = (data && data.published) || false;

  const togglePublished = async () => {
    const domainData = getCachedDomainData();
    if (!isAnyDomainConnected(domainData)) {
      return setShowDomainUnvailableDialog(true);
    }

    const key = `updatePublishToHomepage${Date.now()}`;
    toggleGlobalLoader(key, true);
    try {
      await updatePublishToHomepage({
        catalogueIds: [catalogueId],
        published: !isPublished
      });
    } catch (updateError) {
      toastr.error(t(ERROR_MESSAGE));
    } finally {
      toggleGlobalLoader(key, false);
    }
  };

  const redirectToDomainSetupPage = event => {
    event.preventDefault();
    setShowDomainUnvailableDialog(false);
    navigateToPath(
      `/${ROUTE_MAP.ACCOUNT_SETTINGS}/${
        SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.DOMAIN_SETTINGS].ROUTE
      }`
    );
  };

  const getActionIcon = () => (
    <Switch
      onChange={() => {}}
      checked={isPublished}
      onColor="#e8e8e8"
      offColor="#e8e8e8"
      onHandleColor="#4DA47A"
      handleDiameter={20}
      uncheckedIcon={false}
      checkedIcon={false}
      boxShadow={data && data.isActive ? '0px 1px 5px #4DA47A' : '0px 1px 5px rgba(0, 0, 0, 0.6)'}
      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
      id="normal-switch"
    />
  );

  const getDomainPageRedirectDialog = () => {
    return (
      <Modal
        open={showDomainUnvailableDialog}
        center={true}
        onClose={() => setShowDomainUnvailableDialog(false)}
        classNames={{ modal: 'setupDomainDialogContainer' }}
        showCloseIcon={false}
        styles={{ modal: modalStyles.current }}
      >
        <HomeIcon className="homepageIcon" />
        <p className="setupDomainTitle">{t('homepage')}</p>
        <p className="setupDomainMessage">
          {t('setup_you_domain_to_publish_catalogues_to_your_homepage')}
        </p>
        <button onClick={redirectToDomainSetupPage} className="setupDomainButton">
          {t('setup_now')} <img src={RightIcon} className="rightArrowIcon" alt="" />
        </button>
      </Modal>
    );
  };

  //We get data of Publish to store website from the requests we make to getLink.
  // So because of which while loading link, we have passed the same status to publish to store website.
  if (loadingLink) {
    return null;
  }

  return (
    <div className="publishToHomepageWrapper">
      <div className="publishToHomepageContainer" onClick={() => togglePublished()}>
        <div className="publishToHomepageMeta">
          <span className="publishToHomepageTitle">{t('publish_to_store_website')}</span>
        </div>
        {getActionIcon()}
      </div>
      {getDomainPageRedirectDialog()}
    </div>
  );
};
