import React, { useCallback, useContext } from 'react';
import OrderKeyValue from '../../OrderKeyValue';
import { ActiveOrderMeta } from '../../../context';
import EditOrderDiscount from './EditOrderDiscount';
import { editNotAllowedReason } from 'qs-helpers/Orders/ShippingProviderHelper';
import { updateOrderDiscountAmount } from 'qs-data-manager/Orders/OrdersProductsOperations';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

export default ({
  discountValue,
  orderDiscount,
  orderDiscountType,
  orderEditable,
  shippingConfig
}) => {
  const {
    activeOrderMeta: { selectedOrderId }
  } = useContext(ActiveOrderMeta);
  const { t } = getI18N();

  const { orderDiscountReason } = editNotAllowedReason(shippingConfig);

  const canEditDiscount = useCallback(() => {
    return orderEditable;
  }, [orderEditable]);

  const onUpdateOrderDiscount = useCallback(
    ({ amount, type }) => updateOrderDiscountAmount({ orderId: selectedOrderId, amount, type }),
    [selectedOrderId]
  );

  let discountValueToShow = t('add_discount'),
    addCurrencyInfo = false,
    valueContainerClass = 'orderSingleViewAddMissingData',
    showNegative = false;
  if (typeof discountValue === 'number' && discountValue > 0) {
    discountValueToShow = discountValue;
    addCurrencyInfo = true;
    valueContainerClass = undefined;
    showNegative = true;
  }

  const renderOrderDiscountEditView = ({ onCancel, onUpdate }) => (
    <EditOrderDiscount
      onCancel={onCancel}
      onUpdate={onUpdate}
      orderDiscount={orderDiscount}
      orderDiscountType={orderDiscountType}
    />
  );

  return (
    <OrderKeyValue
      rows={[
        {
          label: t('discount'),
          negative: showNegative,
          valueContainerClass: valueContainerClass,
          value: discountValueToShow,
          addCurrencyInfo: addCurrencyInfo,
          editable: {
            canEditField: canEditDiscount,
            errorInfo: {
              mail: {
                title: t('edit_order_discount'),
                subject: t('failed_to_modify_discount_for_order', { selectedOrderId }),
                message: t('something_went_wrong_while_modifying_this_order_discount')
              },
              fieldEditError: {
                title: t('edit_order_discount'),
                content: orderDiscountReason
              }
            },
            updaterFunction: onUpdateOrderDiscount,
            renderEditableField: renderOrderDiscountEditView
          }
        }
      ]}
    />
  );
};
