import React, { useReducer, useMemo, useEffect } from 'react';
import WithNavRef from 'qs-helpers/WithNavRef';
import OrdersPage from './OrdersPage';
import { activeOrderMetaReducer, activeOrderMetaInit } from './reducer';
import { ActiveOrderMeta } from './context';
import { clearCachedQueryData } from 'qs-data-manager/Orders/OrdersOperations';
import OrderHeader from './OrderHeader';
import './styles.scss';

export default props => {
  const [activeOrderMeta, setActiveOrderMeta] = useReducer(
    activeOrderMetaReducer,
    undefined,
    activeOrderMetaInit
  );

  const orderMetaContext = useMemo(
    () => ({
      activeOrderMeta,
      setActiveOrderMeta
    }),
    [activeOrderMeta, setActiveOrderMeta]
  );

  useEffect(() => {
    return () => clearCachedQueryData();
  }, []);

  return (
    <WithNavRef navigationRef={props}>
      <ActiveOrderMeta.Provider value={orderMetaContext}>
        <div className="ordersContainer">
          <OrderHeader />
          <OrdersPage />
        </div>
      </ActiveOrderMeta.Provider>
    </WithNavRef>
  );
};
