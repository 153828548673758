import React from 'react';
import ClickHandlerWithLoaderContainer from '../../../../Common/ClickHandlerWithLoader/ClickHandlerWithLoaderContainer';
import SettingsRow from '../../../Common/SettingsRow/SettingsRow';
import CustomFontIcon from '../../../../Common/CustomFontIcon';
import { getI18N } from 'qs-services/i18N';

export default ({ onSelected, settingComponentId, classForContainer }) => {
  const { t } = getI18N();

  return (
    <ClickHandlerWithLoaderContainer
      clickProps={{
        clickHandler: onSelected,
        clickParams: settingComponentId
      }}
      type="button"
      classForContainer={classForContainer}
    >
      <SettingsRow Icon={CustomFontIcon} title={t('custom_website_fonts')}>
        <span>{t('select_fonts_for_your_website')}</span>
      </SettingsRow>
    </ClickHandlerWithLoaderContainer>
  );
};
