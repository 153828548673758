import React from 'react';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import { getCompanyCurrencyCode, getCompanyCurrencySymbolOptions } from 'qs-data-manager/Company';
import { getCurrencySymbol } from 'qs-helpers';
import { parseAndValidateNumber } from 'qs-utils';

export default ({ loading, margin, color, onChange = () => {}, disabled, ...inputProps }) => {
  const currencyCode = getCompanyCurrencyCode();
  const symbol = getCurrencySymbol({ currencyCode });
  const currencyOptions = getCompanyCurrencySymbolOptions() || {};

  const showSymbolOnRight = !!currencyOptions.symbolOnRight;

  const currencyComponent = (
    <InputAdornment className="currencySymbol" position={showSymbolOnRight ? 'end' : 'start'}>
      {symbol}
    </InputAdornment>
  );

  const onMarginChange = event => {
    const { value } = event.target;

    const stringNum = parseAndValidateNumber(value, {
      decimal: true,
      numDecimals: 2,
      blankStringValid: true
    });
    const valueNum = Number(stringNum);

    if (stringNum === null || Number.isNaN(valueNum)) {
      event.target.value = margin;
      return;
    }

    onChange(stringNum);
    event.target.value = stringNum;
  };

  return (
    <FormControl>
      <OutlinedInput
        {...inputProps}
        color={color}
        value={margin}
        disabled={disabled}
        onChange={onMarginChange}
        startAdornment={!showSymbolOnRight ? currencyComponent : null}
        endAdornment={showSymbolOnRight ? currencyComponent : null}
      />
      {loading && <div className="loader" />}
    </FormControl>
  );
};
