import React from 'react';
import './styles.scss';
import { getI18N } from 'qs-services/i18N';

const StoneTextField = ({
  title,
  keyName,
  value,
  setAddStoneDetails,
  mandatory,
  autoCalculate = false,
  errorMessage,
  setErrorMessage
}) => {
  const { t } = getI18N();
  const onInputValueChange = e => {
    const { value } = e.target;
    setAddStoneDetails(prevState => {
      return {
        ...prevState,
        [keyName]: value
      };
    });
    if (errorMessage[keyName]) {
      setErrorMessage(prevState => {
        return {
          ...prevState,
          [keyName]: ''
        };
      });
    }
  };
  return (
    <div className="inputFieldContainer" style={{ flex: '1 1 calc(50% - 40px)' }}>
      <div className="title">
        {title}
        {(mandatory || autoCalculate) && (
          <span
            style={{
              ...(mandatory ? { color: '#FF4572' } : autoCalculate ? { color: '#0820FA' } : {})
            }}
          >
            *
          </span>
        )}
      </div>
      <div className="inputType">
        <div id={'TitleContainer'}>
          <div className={'rowContainer textContainer'}>
            <div className={'inputContainer customInputContainerStyle'}>
              <input
                spellCheck="false"
                type="text"
                value={value}
                onChange={e => {
                  onInputValueChange(e);
                }}
                className={`input inputCustomStyle`}
                placeholder={''}
              />
            </div>
          </div>
        </div>
      </div>
      {errorMessage && Object.keys(errorMessage).length > 0 && errorMessage[keyName] && (
        <span className="error">
          {typeof errorMessage[keyName] === 'string'
            ? t(errorMessage[keyName])
            : errorMessage[keyName]}
        </span>
      )}
    </div>
  );
};

export default StoneTextField;
