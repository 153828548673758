import React from 'react';
import { ReactComponent as CustomFieldsIcon } from 'qs-assets/Media/design_filled.svg';
import './styles.scss';

export default ({ width, height, fill, classes = '' }) => {
  return (
    <CustomFieldsIcon
      style={{ width, height, fill }}
      className={`settingsIconComponent ${classes}`}
    />
  );
};
