import React from 'react';

function TwoPerPageIcon() {
  return (
    <svg width="28" height="46" viewBox="0 0 28 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="28"
        y="21"
        width="28"
        height="21"
        rx="2"
        transform="rotate(180 28 21)"
        fill="#9DA4B1"
      />
      <rect
        x="28"
        y="46"
        width="28"
        height="21"
        rx="2"
        transform="rotate(180 28 46)"
        fill="#9DA4B1"
      />
    </svg>
  );
}

export default TwoPerPageIcon;
