import {
  toggleProductHeaderProgressBar,
  IMAGE_UPLOAD_HELPER
} from 'qs-helpers/ProcessUploadedImage';
import { reportError } from 'qs-helpers/ErrorReporting';
import Mixpanel from 'qs-data-manager/Mixpanel';
import Api from 'qs-services/Api';
import { getUniqueDeviceId } from 'qs-helpers/DeviceIdGenerator';
import { uploadImages } from 'qs-image-upload/ImageUploader';
import { db } from 'qs-config/FirebaseConfig';
import {
  updateProductListInCache,
  updateProductsListInNative,
  updateProductMetaInNative
} from '../Helper/CacheNativeHelper';
import { updatePictureDataBasicInfo } from '../Helper/ProductBasicInfoHelper';
import CacheRequest from 'qs-data-manager/CacheRequest';
import { getImageUrlFromPictureId } from 'qs-helpers';
import { getActiveProductId, uploadPicturesToProduct } from 'qs-data-manager/Products';
import { connector } from 'qs-data-manager/ApiAndCacheConnector';
import { RawFileData, FILE_TYPE, DIRECTORY_TYPE } from 'qs-helpers/FileUploads/RawFileData';
import { createNameForProduct } from 'qs-helpers/FileUploads/ProcessUploadedFiles';

export const MAP_NONE = 'NONE';
export const MAP_TITLE = 'NAME';
export const MAP_DESCRIPTION = 'DESCRIPTION';
export const MAP_PRICE = 'PRICE';
export const MAP_SKU = 'SKU';
export const MAP_SKU_NAME = 'SKU_NAME';

export const UPLOAD_MAP_KEYS_VALUES = new Map([
  [MAP_NONE, 'none'],
  [MAP_TITLE, 'product_title'],
  [MAP_DESCRIPTION, 'product_description'],
  [MAP_SKU, 'product_sku'],
  [MAP_SKU_NAME, 'product_sku_and_title'],
  [MAP_PRICE, 'product_price']
]);

const createProductsInRemote = async ({ catalogueId, imageDataForApiCall, fileNameMappedTo }) => {
  let pictureNameField;
  switch (fileNameMappedTo) {
    case MAP_TITLE:
    case MAP_DESCRIPTION:
    case MAP_PRICE:
    case MAP_SKU:
    case MAP_SKU_NAME:
      pictureNameField = fileNameMappedTo;
      break;
    default:
      pictureNameField = MAP_NONE;
      break;
  }

  const requestBody = {
    pictures: imageDataForApiCall,
    pictureNameField,
    catalogueId
  };

  try {
    return await Api.addProductPictures(requestBody);
  } catch (error) {
    reportError(error);
    throw error;
  }
};

const createDataForUpdateInRemote = async ({ rawFiles, productIds }) => {
  const uuid = getUniqueDeviceId();
  const imageDataForApiCall = [],
    imageMapForProductUpload = {};
  for (let index = 0; index < rawFiles.length; index += 1) {
    const pictureId = db.ref().push().key;
    const rawFile = rawFiles[index];
    if (!(rawFile instanceof RawFileData)) {
      continue;
    }

    const product = rawFile.files[0];
    if (!(product instanceof File)) {
      continue;
    }

    let productName = '';
    if (rawFile.type === FILE_TYPE) {
      productName = createNameForProduct(product.name);
    } else if (rawFile.type === DIRECTORY_TYPE) {
      productName = rawFile.extraData.name;
    }

    let productId = null,
      pictureName = null;
    if (index < productIds.length) {
      productId = productIds[index];
    } else {
      pictureName = productName;
    }

    //Save the image for later use
    imageMapForProductUpload[pictureId] = { default: product, additional: rawFile.files.slice(1) };
    //Send the image along with its name for product creation
    imageDataForApiCall.push({
      pictureId,
      productId,
      pictureName,
      uuid
    });
  }
  return { imageDataForApiCall, imageMapForProductUpload };
};

const createDataForImageUpload = ({ catalogueId, imageMapForProductUpload, pictureMap }) => {
  const extraData = {
    calledFrom: IMAGE_UPLOAD_HELPER.PRODUCT_UPLOAD.key
  };
  const additionalProductPictureMap = {};

  const newPicturesToUpload = [];
  for (const pictureId in pictureMap) {
    if (!pictureMap.hasOwnProperty(pictureId)) {
      continue;
    }

    const productId = pictureMap[pictureId].productId;
    const { default: product, additional } = imageMapForProductUpload[pictureId];

    //If additional pictures are present then map the product id to the additional pictures
    if (additional.length) {
      additionalProductPictureMap[productId] = additional;
    }

    // Add data for the image uploader
    newPicturesToUpload.push({
      pictureId,
      product,
      prepared: false,
      productId,
      catalogueId,
      timestamp: new Date().getTime(),
      extraData
    });
  }
  return { newPicturesToUpload, additionalProductPictureMap };
};

const updateProductView = ({ pictureMap }) => {
  let activeProductData = null,
    existingPictureId;
  const activeProductId = getActiveProductId();
  const productDataForNative = [];

  for (const pictureId in pictureMap) {
    if (!pictureMap.hasOwnProperty(pictureId)) {
      continue;
    }

    //Read the current product data from cache
    const productId = pictureMap[pictureId];
    const productCacheKey = `${connector.PRODUCT_ROW_META.cacheKey}${productId}`;
    const productData = CacheRequest.getCacheForKey(productCacheKey);

    //Current product is either new or is yet to be brought into view.
    // No need to update its data
    if (!productData) {
      continue;
    }

    // Create new product data and add it to cache
    const newProductData = {
      ...productData,
      pictureId,
      pictureUrl: getImageUrlFromPictureId({ size: 'FULL', pictureId }),
      defaultImageErrored: false,
      isPrepared: false
    };
    CacheRequest.setCacheForKey(productCacheKey, newProductData);

    //Current viewed product is active. Save its info so that the product basic info
    // data can also be updated correctly
    if (productId === activeProductId) {
      existingPictureId = productData.pictureId;
      activeProductData = newProductData;
    }

    // Create data for update in native
    productDataForNative.push(newProductData);
  }

  if (activeProductData) {
    updatePictureDataBasicInfo({ activeProductData, activeProductId, existingPictureId });
  }

  return productDataForNative;
};

const updateProductDataInNative = async ({ catalogueId, productList, updateProductData }) => {
  await Promise.all([
    updateProductsListInNative({ catalogueId, productList }),
    updateProductMetaInNative(updateProductData)
  ]);
};

const uploadAdditionalPicturesToProduct = async ({ additionalProductPictureMap, catalogueId }) => {
  for (const productId in additionalProductPictureMap) {
    if (additionalProductPictureMap.hasOwnProperty(productId)) {
      const additionalPictures = additionalProductPictureMap[productId];
      try {
        await uploadPicturesToProduct({
          images: additionalPictures,
          catalogueId,
          productId
        });
      } catch (error) {
        // Handle error
      }
    }
  }
};

export const upsertCatalogueProducts = async ({
  catalogueId,
  rawFiles,
  productIds = [],
  fileNameMappedTo = MAP_TITLE
}) => {
  Mixpanel.sendEvent({ eventName: 'Product Uploaded' });
  toggleProductHeaderProgressBar({ catalogueId, totalImages: rawFiles.length });
  const { imageMapForProductUpload, imageDataForApiCall } = await createDataForUpdateInRemote({
    rawFiles,
    productIds
  });

  let pictureMap, productList;
  try {
    ({ pictureMap, productList } = await createProductsInRemote({
      catalogueId,
      imageDataForApiCall,
      fileNameMappedTo
    }));
  } catch (remoteError) {
    // Remove the images that were added to the header
    toggleProductHeaderProgressBar({ catalogueId, totalImages: -rawFiles.length });

    // Notify error to the caller so that the appropriate error can be shown in the UI
    throw remoteError;
  }

  updateProductListInCache({ catalogueId, productList });
  const updateProductData = updateProductView({ catalogueId, pictureMap });

  const { newPicturesToUpload, additionalProductPictureMap } = createDataForImageUpload({
    catalogueId,
    imageMapForProductUpload,
    pictureMap
  });

  // After processing the back-end's response, some images may be ignored if the
  // same product name is found. So reduce the upload count by that number
  const imagesIgnored = rawFiles.length - newPicturesToUpload.length;
  toggleProductHeaderProgressBar({ catalogueId, totalImages: -imagesIgnored });
  uploadImages(newPicturesToUpload);

  updateProductDataInNative({ catalogueId, productList, updateProductData });
  uploadAdditionalPicturesToProduct({ additionalProductPictureMap, catalogueId });
};
