import React, { useState, useContext, useEffect, Fragment, useCallback } from 'react';
import DialogBox from 'qs-components/Common/DialogBox';
import { toggleGlobalLoader } from 'qs-helpers';
import eventbus from 'eventing-bus';
import { UPDATE_IMAGE_DATA } from '../../reducer';
import { CurrentDefaultImageContext } from '../../context';
import { deleteCurrentImage, deleteCurrentVideo } from 'qs-data-manager/ProductDetails';
import { ReactComponent as TrashIcon } from 'qs-assets/Media/trash.svg';
import toastr from 'toastr';
import './styles.scss';
import { DELETED_PICTURE_ID } from 'qs-data-manager/Products';
import { getI18N } from 'qs-services/i18N';

export default ({ activeProductId }) => {
  const [showDeleteModal, toggleDeleteModal] = useState(false);
  const currentImageContext = useContext(CurrentDefaultImageContext);
  const mediaData =
    (currentImageContext.currentImageMeta &&
      currentImageContext.currentImageMeta.currentImageData) ||
    null;

  const { t } = getI18N();

  useEffect(() => {
    //Reset state on change of active product id
    toggleDeleteModal(false);
  }, [activeProductId]);

  const mediaIsVideo = useCallback(() => {
    return mediaData.hasOwnProperty('processed');
  }, [mediaData]);

  const deleteRenderedImage = async () => {
    toggleDeleteModal(false);
    const loaderKey = `deleteCurrentImage${activeProductId}`;
    toggleGlobalLoader(loaderKey, true);

    try {
      let prevPictureState;
      eventbus.publish(DELETED_PICTURE_ID.eventbusKey, mediaData.id, {
        callback: data => {
          prevPictureState = data;
        }
      });
      if (mediaIsVideo()) {
        await deleteCurrentVideo({
          productId: activeProductId,
          currentVideoId: mediaData.id
        });
        toastr.success(t('video_deleted_successfully'));
      } else {
        await deleteCurrentImage({
          productId: activeProductId,
          currentImage: mediaData
        });
        toastr.success(t('image_deleted_successfully'));
      }
      if (prevPictureState) {
        currentImageContext.setNewImageData({
          type: UPDATE_IMAGE_DATA,
          newImageData: prevPictureState
        });
      }
    } catch (deleteImageError) {
      toastr.error(t('something_went_wrong_while_deleting_this_media'));
    } finally {
      toggleGlobalLoader(loaderKey, false);
    }
  };

  const onDeleteClick = () => {
    toggleDeleteModal(!showDeleteModal);
  };

  const closeModal = useCallback(() => {
    toggleDeleteModal(false);
  }, [toggleDeleteModal]);

  return (
    <Fragment>
      <div className={'deleteImageContainer'}>
        <div className="deleteImageAction" onClick={onDeleteClick}>
          <TrashIcon className={'deleteImageActionIcon trashIcon'} />
          {`${mediaIsVideo() ? t('delete_video') : t('delete_image')}`}
        </div>
      </div>
      {showDeleteModal && (
        <DialogBox
          open={true}
          center={true}
          title={`${mediaIsVideo() ? t('delete_video') : t('delete_image')}`}
          onClose={closeModal}
          closeOnOverlayClick={false}
          closeOnEsc={false}
          showCloseIcon={false}
          id="image-delete-confirm-dialog-box"
          actionsConfig={[
            {
              content: t('cancel'),
              clickHandler: () => toggleDeleteModal(false),
              role: 'SECONDARY'
            },
            {
              content: t('confirm'),
              clickHandler: deleteRenderedImage,
              role: 'PRIMARY'
            }
          ]}
        >
          <div className="productImageDelete confirmationDescription">
            {`${
              mediaIsVideo()
                ? t('are_you_sure_you_want_to_delete_this_video')
                : t('are_you_sure_you_want_to_delete_this_image')
            }`}
          </div>
        </DialogBox>
      )}
    </Fragment>
  );
};
