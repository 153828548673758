export const currencies = {
  INR: {
    label: 'India Rupee',
    symbol: '₹',
    currencyCode: 'INR'
  },
  USD: {
    label: 'United States Dollar',
    symbol: '$',
    currencyCode: 'USD'
  },
  AED: {
    label: 'Emirati Dirham',
    symbol: 'د.إ',
    currencyCode: 'AED'
  },
  AFN: {
    label: 'Afghanistan Afghani',
    symbol: '؋',
    currencyCode: 'AFN'
  },
  ALL: {
    label: 'Albanian Lek',
    symbol: 'ALL',
    currencyCode: 'ALL'
  },
  AMD: {
    label: 'Armenian dram',
    symbol: 'Դրամ',
    currencyCode: 'AMD'
  },
  ANG: {
    label: 'Netherlands Antillean Guilder',
    symbol: 'NAƒ',
    currencyCode: 'ANG'
  },
  AOA: {
    label: 'Angolan Kwanza',
    symbol: 'Kz',
    currencyCode: 'AOA'
  },
  ARS: {
    label: 'Argentine Peso',
    symbol: '$',
    currencyCode: 'ARS'
  },
  AUD: {
    label: 'Australian Dollar',
    symbol: '$',
    currencyCode: 'AUD'
  },
  AWG: {
    label: 'Aruban florin',
    symbol: 'AWG',
    currencyCode: 'AWG'
  },
  AZN: {
    label: 'Azerbaijani Manat',
    symbol: 'AZN',
    currencyCode: 'AZN'
  },
  BAM: {
    label: 'Bosnia-Herzegovina Convertible Mark',
    symbol: 'BAM',
    currencyCode: 'BAM'
  },
  BBD: {
    label: 'Barbados Dollar',
    symbol: '$',
    currencyCode: 'BBD'
  },
  BDT: {
    label: 'Bangladeshi Taka',
    symbol: ' Tk',
    currencyCode: 'BDT'
  },
  BGN: {
    label: 'Bulgarian Lev',
    symbol: 'лв',
    currencyCode: 'BGN'
  },
  BHD: {
    label: 'Bahraini Dinar',
    symbol: 'BD',
    currencyCode: 'BHD'
  },
  BIF: {
    label: 'Burundian Franc ',
    symbol: 'FBu',
    currencyCode: 'BIF'
  },
  BMD: {
    label: 'Bermuda Dollar',
    symbol: '$',
    currencyCode: 'BMD'
  },
  BND: {
    label: 'Brunei Darussalam Dollar',
    symbol: '$',
    currencyCode: 'BND'
  },
  BOB: {
    label: 'Bolivia Bolíviano',
    symbol: '$b',
    currencyCode: 'BOB'
  },
  BRL: {
    label: 'Brazil Real',
    symbol: 'R$',
    currencyCode: 'BRL'
  },
  BSD: {
    label: 'Bahamian Dollar',
    symbol: 'BSD',
    currencyCode: 'BSD'
  },
  BTN: {
    label: 'Bhutanese Ngultrum',
    symbol: 'Nu.',
    currencyCode: 'BTN'
  },
  BWP: {
    label: 'Botswanan Pula',
    symbol: 'BWP',
    currencyCode: 'BWP'
  },
  BYN: {
    label: 'Belarusian Ruble',
    symbol: 'BYN',
    currencyCode: 'BYN'
  },
  BZD: {
    label: 'Belize Dollar',
    symbol: 'BZ$',
    currencyCode: 'BZD'
  },
  CAD: {
    label: 'Canada Dollar',
    symbol: '$',
    currencyCode: 'CAD'
  },
  CHF: {
    label: 'Switzerland Franc',
    symbol: 'CHF',
    currencyCode: 'CHF'
  },
  CLP: {
    label: 'Chile Peso',
    symbol: '$',
    currencyCode: 'CLP'
  },
  CNY: {
    label: 'China Yuan Renminbi',
    symbol: '¥',
    currencyCode: 'CNY'
  },
  COP: {
    label: 'Colombia Peso',
    symbol: '$',
    currencyCode: 'COP'
  },
  CRC: {
    label: 'Costa Rica Colon',
    symbol: '₡',
    currencyCode: 'CRC'
  },
  CUP: {
    label: 'Cuba Peso',
    symbol: '₱',
    currencyCode: 'CUP'
  },
  CVE: {
    label: 'Cape Verdean Escudo',
    symbol: '$',
    currencyCode: 'CVE'
  },
  CZK: {
    label: 'Czech Koruna',
    symbol: 'Kč',
    currencyCode: 'CZK'
  },
  DJF: {
    label: 'Djiboutian Franc',
    symbol: 'Fdj',
    currencyCode: 'DJF'
  },
  DKK: {
    label: 'Denmark Krone',
    symbol: 'kr',
    currencyCode: 'DKK'
  },
  DOP: {
    label: 'Dominican Republic Peso',
    symbol: 'RD$',
    currencyCode: 'DOP'
  },
  DZD: {
    label: 'Algerian Dinar',
    symbol: 'دج',
    currencyCode: 'DZD'
  },
  EGP: {
    label: 'Egypt Pound',
    symbol: '£',
    currencyCode: 'EGP'
  },
  ERN: {
    label: 'Eritrean Nakfa',
    symbol: 'Nfk',
    currencyCode: 'ERN'
  },
  ETB: {
    label: 'Ethiopian Birr',
    symbol: 'Br',
    currencyCode: 'ETB'
  },
  EUR: {
    label: 'Euro',
    symbol: '€',
    currencyCode: 'EUR'
  },
  FJD: {
    label: 'Fijian Dollar',
    symbol: '$',
    currencyCode: 'FJD'
  },
  FKP: {
    label: 'Falkland Islands Pound',
    symbol: 'FKP',
    currencyCode: 'FKP'
  },
  GBP: {
    label: 'British Pound',
    symbol: '£',
    currencyCode: 'GBP'
  },
  GEL: {
    label: 'Georgian Lari',
    symbol: '₾',
    currencyCode: 'GEL'
  },
  GHS: {
    label: 'Ghana Cedi',
    symbol: '¢',
    currencyCode: 'GHS'
  },
  GIP: {
    label: 'Gibraltar Pound',
    symbol: '£',
    currencyCode: 'GIP'
  },
  GMD: {
    label: 'Gambian dalasi',
    symbol: 'D',
    currencyCode: 'GMD'
  },
  GNF: {
    label: 'Guinean Franc',
    symbol: 'FG',
    currencyCode: 'GNF'
  },
  GTQ: {
    label: 'Guatemalan quetzal',
    symbol: 'Q',
    currencyCode: 'GTQ'
  },
  GYD: {
    label: 'Guyana Dollar',
    symbol: '$',
    currencyCode: 'GYD'
  },
  HKD: {
    label: 'Hong Kong Dollar',
    symbol: '$',
    currencyCode: 'HKD'
  },
  HNL: {
    label: 'Honduran Lempira',
    symbol: 'HNL',
    currencyCode: 'HNL'
  },
  HRK: {
    label: 'Croatia Kuna',
    symbol: 'kn',
    currencyCode: 'HRK'
  },
  HTG: {
    label: 'Haitian Gourde',
    symbol: 'HTG',
    currencyCode: 'HTG'
  },
  HUF: {
    label: 'Hungary Forint',
    symbol: 'Ft',
    currencyCode: 'HUF'
  },
  IDR: {
    label: 'Indonesia Rupiah',
    symbol: 'Rp',
    currencyCode: 'IDR'
  },
  ILS: {
    label: 'Israel Shekel',
    symbol: '₪',
    currencyCode: 'ILS'
  },
  IQD: {
    label: 'Iraqi Dinar',
    symbol: 'د.ع',
    currencyCode: 'IQD'
  },
  IRR: {
    label: 'Iranian Rial',
    symbol: '﷼',
    currencyCode: 'IRR'
  },
  ISK: {
    label: 'Iceland Krona',
    symbol: 'kr',
    currencyCode: 'ISK'
  },
  JMD: {
    label: 'Jamaica Dollar',
    symbol: 'J$',
    currencyCode: 'JMD'
  },
  JOD: {
    label: 'Jordanian Dinar',
    symbol: 'JOD',
    currencyCode: 'JOD'
  },
  JPY: {
    label: 'Japanese Yen',
    symbol: '¥',
    currencyCode: 'JPY'
  },
  KES: {
    label: 'Kenyan Shilling',
    symbol: 'KSh',
    currencyCode: 'KES'
  },
  KGS: {
    label: 'Kyrgystani Som',
    symbol: 'KGS',
    currencyCode: 'KGS'
  },
  KHR: {
    label: 'Cambodian Riel',
    symbol: '៛',
    currencyCode: 'KHR'
  },
  KMF: {
    label: 'Comorian Franc',
    symbol: 'KMF',
    currencyCode: 'KMF'
  },
  KPW: {
    label: 'North Korean Won',
    symbol: '₩',
    currencyCode: 'KPW'
  },
  KRW: {
    label: 'Korea (South) Won',
    symbol: '₩',
    currencyCode: 'KRW'
  },
  KWD: {
    label: 'Kuwaiti Dinar',
    symbol: 'د.ك',
    currencyCode: 'KWD'
  },
  KYD: {
    label: 'Cayman Islands Dollar',
    symbol: '$',
    currencyCode: 'KYD'
  },
  KZT: {
    label: 'Kazakhstan Tenge',
    symbol: 'лв',
    currencyCode: 'KZT'
  },
  LAK: {
    label: 'Laos Kip',
    symbol: '₭',
    currencyCode: 'LAK'
  },
  LBP: {
    label: 'Lebanese Pound',
    symbol: 'ل.ل.',
    currencyCode: 'LBP'
  },
  LKR: {
    label: 'Sri Lanka Rupee',
    symbol: '₨',
    currencyCode: 'LKR'
  },
  LRD: {
    label: 'Liberia Dollar',
    symbol: '$',
    currencyCode: 'LRD'
  },
  LTL: {
    label: 'Lithuanian Litas',
    symbol: 'Lt',
    currencyCode: 'LTL'
  },
  LYD: {
    label: 'Libyan Dinar',
    symbol: 'LD',
    currencyCode: 'LYD'
  },
  MAD: {
    label: 'Moroccan Dirham',
    symbol: 'MAD',
    currencyCode: 'MAD'
  },
  MDL: {
    label: 'Moldovan Leu',
    symbol: 'MDL',
    currencyCode: 'MDL'
  },
  MGA: {
    label: 'Malagasy Ariary',
    symbol: 'MGA',
    currencyCode: 'MGA'
  },
  MKD: {
    label: 'Macedonia Denar',
    symbol: 'ден',
    currencyCode: 'MKD'
  },
  MMK: {
    label: 'Myanmar Kyat',
    symbol: 'K',
    currencyCode: 'MMK'
  },
  MNT: {
    label: 'Mongolia Tughrik',
    symbol: '₮',
    currencyCode: 'MNT'
  },
  MOP: {
    label: 'Macanese Pataca',
    symbol: 'MOP$',
    currencyCode: 'MOP'
  },
  MRO: {
    label: 'Mauritanian Ouguiya',
    symbol: 'UM',
    currencyCode: 'MRO'
  },
  MUR: {
    label: 'Mauritius Rupee',
    symbol: '₨',
    currencyCode: 'MUR'
  },
  MWK: {
    label: 'Malawian Kwacha',
    symbol: 'MK',
    currencyCode: 'MWK'
  },
  MXN: {
    label: 'Mexico Peso',
    symbol: '$',
    currencyCode: 'MXN'
  },
  MYR: {
    label: 'Malaysia Ringgit',
    symbol: 'RM',
    currencyCode: 'MYR'
  },
  MZN: {
    label: 'Mozambique Metical',
    symbol: 'MT',
    currencyCode: 'MZN'
  },
  NAD: {
    label: 'Namibia Dollar',
    symbol: '$',
    currencyCode: 'NAD'
  },
  NGN: {
    label: 'Nigeria Naira',
    symbol: '₦',
    currencyCode: 'NGN'
  },
  NIO: {
    label: 'Nicaragua Cordoba',
    symbol: 'C$',
    currencyCode: 'NIO'
  },
  NOK: {
    label: 'Norway Krone',
    symbol: 'kr',
    currencyCode: 'NOK'
  },
  NPR: {
    label: 'Nepal Rupee',
    symbol: '₨',
    currencyCode: 'NPR'
  },
  NZD: {
    label: 'New Zealand Dollar',
    symbol: '$',
    currencyCode: 'NZD'
  },
  OMR: {
    label: 'Oman Rial',
    symbol: '﷼',
    currencyCode: 'OMR'
  },
  PAB: {
    label: 'Panamanian Balboa',
    symbol: 'B/.',
    currencyCode: 'PAB'
  },
  PEN: {
    label: 'Peru Sol',
    symbol: 'S/.',
    currencyCode: 'PEN'
  },
  PGK: {
    label: 'Papua New Guinean Kina',
    symbol: 'K',
    currencyCode: 'PGK'
  },
  PHP: {
    label: 'Philippines Peso',
    symbol: '₱',
    currencyCode: 'PHP'
  },
  PKR: {
    label: 'Pakistan Rupee',
    symbol: '₨',
    currencyCode: 'PKR'
  },
  PLN: {
    label: 'Poland Zloty',
    symbol: 'zł',
    currencyCode: 'PLN'
  },
  PYG: {
    label: 'Paraguay Guarani',
    symbol: 'Gs',
    currencyCode: 'PYG'
  },
  QAR: {
    label: 'Qatar Riyal',
    symbol: '﷼',
    currencyCode: 'QAR'
  },
  RON: {
    label: 'Romania Leu',
    symbol: 'lei',
    currencyCode: 'RON'
  },
  RSD: {
    label: 'Serbia Dinar',
    symbol: 'Дин.',
    currencyCode: 'RSD'
  },
  RUB: {
    label: 'Russia Ruble',
    symbol: '₽',
    currencyCode: 'RUB'
  },
  RWF: {
    label: 'Rwandan Franc',
    symbol: 'FRw',
    currencyCode: 'RWF'
  },
  SAR: {
    label: 'Saudi Arabia Riyal',
    symbol: '﷼',
    currencyCode: 'SAR'
  },
  SBD: {
    label: 'Solomon Islands Dollar',
    symbol: 'SBD',
    currencyCode: 'SBD'
  },
  SCR: {
    label: 'Seychellois Rupee',
    symbol: 'SR',
    currencyCode: 'SCR'
  },
  SDG: {
    label: 'Sudanese pound',
    symbol: 'SD',
    currencyCode: 'SDG'
  },
  SEK: {
    label: 'Sweden Krona',
    symbol: 'kr',
    currencyCode: 'SEK'
  },
  SGD: {
    label: 'Singapore Dollar',
    symbol: '$',
    currencyCode: 'SGD'
  },
  SHP: {
    label: 'Saint Helena pound',
    symbol: '£',
    currencyCode: 'SHP'
  },
  SLL: {
    label: 'Sierra Leonean leone',
    symbol: 'Le',
    currencyCode: 'SLL'
  },
  SOS: {
    label: 'Somalia Shilling',
    symbol: 'S',
    currencyCode: 'SOS'
  },
  SRD: {
    label: 'Suriname Dollar',
    symbol: '$',
    currencyCode: 'SRD'
  },
  SSP: {
    label: 'South Sudanese pound',
    symbol: 'SSP',
    currencyCode: 'SSP'
  },
  STD: {
    label: 'São Tomé and Príncipe dobra',
    symbol: 'Db',
    currencyCode: 'STD'
  },
  SYP: {
    label: 'Syrian Pound',
    symbol: 'SYP',
    currencyCode: 'SYP'
  },
  SZL: {
    label: 'Swazi Lilangeni',
    symbol: 'SZL',
    currencyCode: 'SZL'
  },
  THB: {
    label: 'Thailand Baht',
    symbol: '฿',
    currencyCode: 'THB'
  },
  TND: {
    label: 'Tunisian Dinar',
    symbol: 'DT',
    currencyCode: 'TND'
  },
  TOP: {
    label: 'Tongan paʻanga',
    symbol: 'T$',
    currencyCode: 'TOP'
  },
  TRY: {
    label: 'Turkish Lira',
    symbol: '₺',
    currencyCode: 'TRY'
  },
  TTD: {
    label: 'Trinidad and Tobago Dollar',
    symbol: 'TT$',
    currencyCode: 'TTD'
  },
  TWD: {
    label: 'Taiwan New Dollar',
    symbol: 'NT$',
    currencyCode: 'TWD'
  },
  TZS: {
    label: 'Tanzanian Shilling',
    symbol: 'TSh',
    currencyCode: 'TZS'
  },
  UAH: {
    label: 'Ukraine Hryvnia',
    symbol: '₴',
    currencyCode: 'UAH'
  },
  UGX: {
    label: 'Ugandan Shilling',
    symbol: 'USh',
    currencyCode: 'UGX'
  },
  UYU: {
    label: 'Uruguay Peso',
    symbol: '$U',
    currencyCode: 'UYU'
  },
  UZS: {
    label: 'Uzbekistani soʻm',
    symbol: 'UZS',
    currencyCode: 'UZS'
  },
  VEF: {
    label: 'Venezuela Bolívar',
    symbol: 'Bs',
    currencyCode: 'VEF'
  },
  VND: {
    label: 'Viet Nam Dong',
    symbol: '₫',
    currencyCode: 'VND'
  },
  VUV: {
    label: 'Vanuatu Vatu',
    symbol: 'VT',
    currencyCode: 'VUV'
  },
  WST: {
    label: 'Samoan tālā',
    symbol: 'WS$',
    currencyCode: 'WST'
  },
  XAF: {
    label: 'Central African CFA franc',
    symbol: 'FCFA',
    currencyCode: 'XAF'
  },
  XCD: {
    label: 'East Caribbean Dollar',
    symbol: '$',
    currencyCode: 'XCD'
  },
  XOF: {
    label: 'West African CFA franc',
    symbol: 'CFA',
    currencyCode: 'XOF'
  },
  XPF: {
    label: 'CFP franc',
    symbol: 'CFP',
    currencyCode: 'XPF'
  },
  YER: {
    label: 'Yemen Rial',
    symbol: '﷼',
    currencyCode: 'YER'
  },
  ZAR: {
    label: 'South Africa Rand',
    symbol: 'R',
    currencyCode: 'ZAR'
  },
  ZMW: {
    label: 'Zambian Kwacha',
    symbol: 'ZK',
    currencyCode: 'ZMW'
  },
  GOG: {
    label: 'Grams of Precious Metal',
    symbol: 'gm',
    currencyCode: 'GOG'
  }
};

const sortedCurrencies = Object.values(currencies).sort((c1, c2) =>
  c1.label.localeCompare(c2.label)
);

export const getSortedCurrencies = () => sortedCurrencies;
