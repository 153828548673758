import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import './styles.scss';
import { ReactComponent as TimesIcon } from 'qs-assets/Media/close.svg';
import { ReactComponent as InfoFilledIcon } from 'qs-assets/Media/info-filled.svg';
import { ReactComponent as EyeIcon } from 'qs-assets/Media/eye.svg';
import { ReactComponent as ReorderIcon } from 'qs-assets/Media/drag-reorder.svg';

class CatalogueTagHelp extends PureComponent {
  static propTypes = {
    isVisible: PropTypes.bool,
    onDismissPress: PropTypes.func
  };

  render() {
    const { isVisible, onDismissPress } = this.props;

    if (!isVisible) {
      return null;
    }

    return (
      <div className="catalogueTagHelpContainer">
        <div className="helpItem">
          <div className="helpItemIconContainer">
            <InfoFilledIcon fill="#000" className="helpInfoIcon" />
          </div>

          <div className="helpItemTextContainer">
            <span className="helpItemText">
              These tags have been populated using your product tags. Add more tags to your products
              to see them here
            </span>
          </div>
        </div>
        <div className="helpItem">
          <div className="helpItemIconContainer">
            <EyeIcon fill="#000" className="helpEyeIcon" />
          </div>

          <div className="helpItemTextContainer">
            <span className="helpItemText">Tap to hide a tag from your customers</span>
          </div>
        </div>
        <div className="helpItem">
          <div className="helpItemIconContainer">
            <ReorderIcon fill="#000" className="helpDragIcon" />
          </div>

          <div className="helpItemTextContainer">
            <span className="helpItemText">
              Hold and drag to re-arrange how your customers see your tags
            </span>
          </div>
        </div>

        <div className="dismissHelpContainer">
          <div className="dismissButton" onClick={onDismissPress}>
            <TimesIcon fill="#000" className="dismissIcon" />

            <span className="dismissText">Dismiss</span>
          </div>
        </div>
      </div>
    );
  }
}

export default CatalogueTagHelp;
