import React, { useState, useEffect } from 'react';
import DialogBox from '../../Common/DialogBox';
import './styles.scss';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as CallIcon } from 'qs-assets/Media/phone_disconnected_icon.svg';
import { ReactComponent as ChatIcon } from 'qs-assets/Media/whatsapp_filled_icon.svg';
import { ReactComponent as EmailIcon } from 'qs-assets/Media/icons8-email.svg';
import { PHONE_NUMBER } from 'qs-helpers/constants';
import { CHAT_NUMBER } from 'qs-helpers/constants';
import { EMAIL_ID } from 'qs-helpers/constants';
import { ReactComponent as ArrowRight } from 'qs-assets/Media/ic_arrow_right.svg';
import {
  getConfigFromCache,
  attachConfigsListener,
  removeConfigsListener
} from 'qs-data-manager/Config';
import CacheListenerCallback from 'qs-helpers/CacheListenerCallback';
import Loader from '../../Common/Loader';
import toastr from 'toastr';
import { handleCallClick, handleChatClick } from 'qs-helpers/ContactHelper';
import { getI18N } from 'qs-services/i18N';
import { openPopup } from 'qs-utils';

const useStyles = makeStyles({
  buttonRoot: {
    padding: '0 20px',
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    borderRadius: 0,

    '&:focus': {
      outline: 'none'
    },

    '&:hover': {
      outline: 'none',
      backgroundColor: '#0d131b'
    }
  },
  buttonStartIcon: {
    marginLeft: 0,
    marginRight: 0
  },
  dialogPaperRoot: {
    width: '100%',
    maxWidth: 400
  },
  dialongContentRoot: {
    padding: 0
  }
});

const HelpAndSupport = ({ title, onClose }) => {
  const classes = useStyles();

  const [knowledgeCenterLoader, setKnowledgeCenterLoader] = useState(false);
  const [linkConfig, setLinkConfig] = useState(getConfigFromCache());

  useEffect(() => {
    const configListener = function(error, payload) {
      const { err, loading, refreshing, data } = CacheListenerCallback(error, payload);

      if (loading || refreshing) {
        setKnowledgeCenterLoader(true);
        return;
      }
      if (err) {
        toastr.error(t('something_went_wrong'));
        return;
      }
      setKnowledgeCenterLoader(false);
      setLinkConfig(data);
    };

    attachConfigsListener(configListener);

    return () => removeConfigsListener(configListener);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onKnowledgeCenterClick = () => {
    openPopup(linkConfig.knowledgeGuide);
  };

  const onChatClick = () => {
    handleChatClick(CHAT_NUMBER);
  };

  const onCallClick = () => {
    handleCallClick(PHONE_NUMBER);
  };

  const onContactByEmailClick = event => {
    event.preventDefault();
    openPopup(EMAIL_ID);
  };

  const { t } = getI18N();

  const actions = [
    {
      title: t('knowledge_center'),
      icon: (
        <img
          src="https://web.quicksell.co/static/media/ic_knowledge_guide.png"
          className="icon"
          alt=""
        />
      ),
      onClick: onKnowledgeCenterClick,
      showLoader: knowledgeCenterLoader
    },
    {
      title: t('email'),
      icon: <EmailIcon className="icon" />,
      onClick: onContactByEmailClick,
      showLoader: false
    },
    {
      title: t('chat'),
      icon: <ChatIcon className="icon" />,
      onClick: onChatClick,
      showLoader: false
    },
    {
      title: t('phone'),
      icon: <CallIcon className="icon " />,
      onClick: onCallClick,
      showLoader: false
    }
  ];

  return (
    <DialogBox
      maxWidth={'xs'}
      open={true}
      title={title}
      onClose={onClose}
      dialogContainerClass={classes.dialogPaperRoot}
      dialogContentContainerClassName={classes.dialongContentRoot}
    >
      <div className="buttons-container">
        {actions.map(({ showLoader, icon, onClick, title }, key) => {
          if (showLoader) {
            return <Loader key={key} size="small" />;
          }

          return (
            <Button
              key={key}
              classes={{
                root: classes.buttonRoot,
                startIcon: classes.buttonStartIcon
              }}
              startIcon={icon}
              onClick={event => {
                onClick(event);
                onClose();
              }}
            >
              <div className={'textAndArrowContainer'}>
                <div className="text">{title}</div>
                <ArrowRight />
              </div>
            </Button>
          );
        })}
      </div>
    </DialogBox>
  );
};

export default HelpAndSupport;
