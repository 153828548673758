import { SHIPPING_PROVIDERS } from 'qs-helpers/Orders/constants';
import React, { useState } from 'react';
import { ActionButton } from '../Common/ActionButtons';
import { getErrorMessage } from 'qs-helpers';
import toastr from 'toastr';
import ErrorPopup from 'qs-components/Common/ErrorPopup';
import { placeExternalShippingOrder } from 'qs-data-manager/Orders/OrdersProductsOperations';
import Loader from 'qs-components/Common/Loader';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

export default function PlaceExternalOrder({ buttonClasses, shippingConfig, orderId }) {
  const { t } = getI18N();
  const [errorPopupData, setErrorPopupData] = useState();
  const [overlayLoader, setOverlayLoader] = useState(false);

  const { key, canPlaceExternalOrder } = shippingConfig;
  const shippingProviderConfig = SHIPPING_PROVIDERS[key];
  if (!shippingProviderConfig || !canPlaceExternalOrder) {
    return null;
  }

  const onPlaceOrderClick = async () => {
    setOverlayLoader(true);
    try {
      await placeExternalShippingOrder(orderId);
      toastr.success(
        t('successfully_placed_order_through_shipping_provider', {
          shippingProvider: shippingProviderConfig.label
        })
      );
    } catch (placeExternalShippingOrderError) {
      setErrorPopupData({
        title: t('place_order_on_shipping_provider', {
          shippingProvider: shippingProviderConfig.label
        }),
        subject: t('failed_to_place_order_with_shipping_provider', {
          shippingProvider: shippingProviderConfig.label
        }),
        body: getErrorMessage(placeExternalShippingOrderError),
        message: t('something_went_wrong_while_placing_order_with_shipping_provider', {
          shippingProvider: shippingProviderConfig.label
        })
      });
    } finally {
      setOverlayLoader(false);
    }
  };

  return (
    <>
      <ActionButton
        classes={{ root: buttonClasses.buttonRoot }}
        color={'primary'}
        variant="contained"
        onClick={onPlaceOrderClick}
        disabled={overlayLoader}
      >
        Place Order on {shippingProviderConfig.label}
        {overlayLoader && (
          <div className="overlayLoaderContainer">
            <Loader size="small" />
          </div>
        )}
      </ActionButton>
      {errorPopupData && (
        <ErrorPopup
          onClose={() => setErrorPopupData()}
          mailProps={errorPopupData}
          title={errorPopupData.title}
        >
          {errorPopupData.message}
        </ErrorPopup>
      )}
    </>
  );
}
