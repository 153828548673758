import React, { useState, useCallback, useEffect, useContext, Fragment } from 'react';
import FilterProductsPopover from './FilterProductsPopover';
import { ReactComponent as BackIcon } from 'qs-assets/Media/arrow-left.svg';
import { ReactComponent as CloseIcon } from 'qs-assets/Media/close.svg';
import { ReactComponent as FunnelIcon } from 'qs-assets/Media/funnel.svg';
import { ReactComponent as DeleteIcon } from 'qs-assets/Media/trash.svg';
import { ReactComponent as CreateCatalogueIcon } from 'qs-assets/Media/create_catalogue.svg';
import Mixpanel from 'qs-data-manager/Mixpanel';
import { ActiveCatalogueProductListMeta } from '../../context';
import { PRODUCT_LIST_CLEAR_FILTERS_AND_SEARCH, UPDATE_PRODUCT_LIST_SEARCH } from '../../reducer';
import { selectedProducts } from 'qs-data-manager/Selected';
import { deleteProducts } from 'qs-data-manager/Products';
import CopySelectedProducts from './CopySelectedProducts';
import Catalogues from 'qs-data-manager/Catalogues';
import { SEARCH_ACTIVE } from 'qs-helpers/Products/constants';
import CheckBox from 'qs-components/Common/CheckBox';
import { Tooltip, withStyles } from '@material-ui/core';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

const IconToolTip = withStyles({
  tooltip: {
    backgroundColor: '#37A06D',
    fontSize: 12
  }
})(Tooltip);

export default ({ goBack, headerClass, activeCatalogueId }) => {
  const {
    productListMeta: { currentSearchTerm },
    setProductListMeta
  } = useContext(ActiveCatalogueProductListMeta);

  const [checkboxValue, setCheckboxValue] = useState(false);
  const [showCatalogueCopyOptions, setShowCatalogueCopyOptions] = useState(false);
  const [totalSelected, setTotalSelected] = useState(0);
  const [totalItemsCount, setTotalItemsCount] = useState(0);

  const { t } = getI18N();

  useEffect(() => {
    const onSelectedValueChange = () => {
      setTotalSelected(selectedProducts.getTotalPreservedSelectionCount());
      setCheckboxValue(selectedProducts.areAllSelected());
      setTotalItemsCount(selectedProducts.getAllItemsCount());
    };

    const activeStateListener = isActive => {
      if (!isActive) {
        goBack();
        return;
      }
      selectedProducts.removeListener(onSelectedValueChange);
      selectedProducts.addListener(onSelectedValueChange);
    };

    selectedProducts.activate(SEARCH_ACTIVE);
    selectedProducts.addActiveListener(SEARCH_ACTIVE, activeStateListener);

    return () => {
      selectedProducts.removeListener(onSelectedValueChange);
      selectedProducts.removeActiveListener(SEARCH_ACTIVE, activeStateListener);
      selectedProducts.deactivate();
    };
  }, [goBack]);

  const [showPopover, setShowPopover] = useState(false);

  const onClear = useCallback(() => {
    setProductListMeta({ type: UPDATE_PRODUCT_LIST_SEARCH });
  }, [setProductListMeta]);

  useEffect(() => () => setProductListMeta({ type: PRODUCT_LIST_CLEAR_FILTERS_AND_SEARCH }), [
    setProductListMeta
  ]);

  useEffect(() => {
    Mixpanel.sendEvent({
      eventName: 'search_catalogue_product_opened',
      props: {
        catalogue_id: activeCatalogueId
      }
    });
  }, [activeCatalogueId]);

  const backIconClicked = useCallback(() => {
    goBack();
  }, [goBack]);

  const catalogueTitle = Catalogues.getCatalogueMetaFromCache(activeCatalogueId).title || '';

  const onSearchTextChange = event => {
    event.stopPropagation();
    setProductListMeta({ type: UPDATE_PRODUCT_LIST_SEARCH, searchTerm: event.target.value });
  };

  const togglePopover = useCallback(() => {
    setShowPopover(prevState => !prevState);
  }, []);

  const onDeleteClick = event => {
    event.stopPropagation();
    const selectedProductsCount = selectedProducts.getTotalPreservedSelectionCount();
    const shouldRemove = window.confirm(
      `${
        selectedProductsCount === 1
          ? t('are_you_sure_you_want_to_delete_product_from_catalogue', { selectedProductsCount })
          : t('are_you_sure_you_want_to_delete_products_from_catalogue', { selectedProductsCount })
      }`
    );
    if (shouldRemove) {
      const productIds = Array.from(selectedProducts.getAllPreservedSelections());
      deleteProducts(productIds);
      goBack();
    }
  };

  const onCopyClick = () => {
    setShowCatalogueCopyOptions(true);
  };

  const onCancelCopyClick = useCallback(() => {
    setShowCatalogueCopyOptions(false);
  }, []);

  const onCompleteCopy = useCallback(() => {
    onCancelCopyClick();
    goBack();
  }, [onCancelCopyClick, goBack]);

  const checkboxValueChange = event => {
    const { checked } = event.target;
    if (checked) {
      selectedProducts.setAll();
      return;
    }

    selectedProducts.removeSelected();
  };

  const renderAdditionalIcons = () => {
    if (totalSelected <= 0) {
      return null;
    }

    return (
      <>
        <IconToolTip title={t('copy_products')}>
          <CreateCatalogueIcon
            onClick={onCopyClick}
            className={'copyIcon'}
            aria-label="Copy products"
          />
        </IconToolTip>
        <IconToolTip title={t('delete_products')}>
          <DeleteIcon
            onClick={onDeleteClick}
            className={'deleteIcon'}
            aria-label={t('delete_products')}
          />
        </IconToolTip>
      </>
    );
  };

  return (
    <Fragment>
      <div id={'SearchProductHeader'} className={`${headerClass}`}>
        <div className={'searchProductContainer'}>
          <div className={'leftContainer'}>
            <BackIcon onClick={backIconClicked} className={'backIcon'} />
            <div className={'productSearch'}>
              <input
                autoFocus
                value={currentSearchTerm || ''}
                onChange={onSearchTextChange}
                className={'inputBox'}
                placeholder={t('search_in_title_price_description')}
              />
              {currentSearchTerm ? <CloseIcon onClick={onClear} className={'closeIcon'} /> : null}
            </div>
          </div>
          <div className={'rightContainer'}>
            <FunnelIcon className={'funnelIcon'} onClick={togglePopover} />
          </div>
        </div>
        {totalSelected > 0 && (
          <div className="searchProductActionsContainer">
            <CheckBox
              disabled={totalItemsCount === 0}
              checked={checkboxValue}
              label={`${checkboxValue ? t('deselect_all') : t('select_all')} (${totalItemsCount})`}
              onCheckChanged={checkboxValueChange}
              styleProps={{
                margin: '0 16px 0 0px',
                flex: '0 0 auto',
                labelFontSize: 16,
                controlMarginRight: 0
              }}
            />
            <div className="rightActionsContainer">{renderAdditionalIcons()}</div>
          </div>
        )}
      </div>
      <FilterProductsPopover
        showPopover={showPopover}
        catalogueId={activeCatalogueId}
        onClosePopover={togglePopover}
      />
      {showCatalogueCopyOptions && (
        <CopySelectedProducts
          onClose={onCancelCopyClick}
          onComplete={onCompleteCopy}
          catalogueTitle={catalogueTitle}
          activeCatalogueId={activeCatalogueId}
        />
      )}
    </Fragment>
  );
};
