import React, { useState, useEffect, useCallback } from 'react';

import CatalogueOverlappingImages from '../../../../Common/CatalogueOverlappingImages';
import CatalogueTitle from './CatalogueTitle';
import CatalogueProductCount from './CatalogueProductCount';
import Checkbox from 'qs-components/Common/CheckBox';
import { selectedCatalogue } from 'qs-data-manager/Selected.js';
import CatalogueShimmer from './CatalogueShimmer';
import CatalogueLib, {
  setActiveCatalogueId,
  ACTIVE_CATALOGUE_META
} from 'qs-data-manager/Catalogues';
import SkeletonCatalogue from './SkeletonCatalogue';
import eventbus from 'eventing-bus';
import { ReactComponent as DeleteIcon } from 'qs-assets/Media/trash.svg';
import { ReactComponent as ErrorIcon } from 'qs-assets/Media/filled-error.svg';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

export default ({ id, isSkeleton }) => {
  const [editMode, setEditMode] = useState(false);
  const [checkboxValue, setCheckboxValue] = useState(false);
  const [catalogueMeta, setCatalogueMeta] = useState({
    meta: CatalogueLib.getCatalogueMetaFromCache(id),
    loading: false,
    refreshing: false,
    error: false
  });
  const [isSelected, setIsSelected] = useState(() => CatalogueLib.isCatalogueSelected(id));

  const { t } = getI18N();

  useEffect(() => {
    const removeEventbus = eventbus.on(ACTIVE_CATALOGUE_META.eventbusKey, catalogueId => {
      setIsSelected(id === catalogueId);
    });

    return () => removeEventbus();
  }, [id]);

  const onSelectedValueChange = useCallback(
    (all = {}) => {
      const selectionState = all[id] || false;
      setCheckboxValue(selectionState);
    },
    [id]
  );

  const onSelectionStateChange = useCallback(isActive => {
    setEditMode(isActive);
  }, []);

  const metaListener = useCallback((err, { status, data } = {}) => {
    const updates = {};
    if (err) {
      updates.loading = false;
      updates.error = err;
    } else {
      switch (status) {
        case CatalogueLib.OPERATION_STATUS.LOADING: {
          updates.loading = true;
          updates.refreshing = false;
          updates.error = null;
          break;
        }
        case CatalogueLib.OPERATION_STATUS.REFRESHING: {
          updates.loading = false;
          updates.refreshing = true;
          updates.error = null;
          updates.meta = data || {};
          break;
        }
        case CatalogueLib.OPERATION_STATUS.SUCCESS: {
          updates.loading = false;
          updates.refreshing = false;
          updates.error = null;
          updates.meta = data || {};
          break;
        }
        case CatalogueLib.OPERATION_STATUS.UPDATE: {
          updates.loading = false;
          updates.refreshing = false;
          updates.error = null;
          updates.meta = data || {};
          break;
        }
        default:
      }
      setCatalogueMeta(prevState => ({ ...prevState, ...updates }));
    }
  }, []);

  useEffect(() => {
    CatalogueLib.attachCatalogueMetaListener(metaListener, id);
    CatalogueLib.getCatalogueMeta({ catalogueIds: [id] });
    selectedCatalogue.addActiveListener(onSelectionStateChange);
    selectedCatalogue.addListener(onSelectedValueChange);

    return () => {
      selectedCatalogue.removeActiveListener(onSelectionStateChange);
      selectedCatalogue.removeListener(onSelectedValueChange);
      CatalogueLib.removeCatalogueIdsListener(metaListener, id);
    };
  }, [id, onSelectedValueChange, onSelectionStateChange, metaListener]);

  const deleteCatalogue = useCallback(
    e => {
      e.stopPropagation();
      const shouldDeleteCatalogue = window.confirm(
        t('are_you_sure_you_want_to_delete_this_catalogue')
      );
      if (shouldDeleteCatalogue) {
        selectedCatalogue.remove(id);
        CatalogueLib.deleteCatalogues([id]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id]
  );

  const toggleCheckbox = useCallback(
    e => {
      e.stopPropagation();
      e.preventDefault();
      const newValue = !checkboxValue;
      setCheckboxValue(newValue);
      if (newValue) {
        selectedCatalogue.add(id);
      } else {
        selectedCatalogue.remove(id);
      }
    },
    [checkboxValue, id]
  );

  const onRowClick = useCallback(
    e => {
      e.stopPropagation();
      e.preventDefault();
      if (editMode) {
        toggleCheckbox(e);
      }
      if (!CatalogueLib.isCatalogueSelected(id)) {
        setActiveCatalogueId(id);
        setIsSelected(true);
      }
    },
    [toggleCheckbox, editMode, id]
  );

  if (isSkeleton) {
    return <SkeletonCatalogue />;
  }

  if (catalogueMeta.loading) {
    return <CatalogueShimmer />;
  }

  const getRightContainer = () => {
    // Either the catalogue has an error or it is being edited; render the right container
    if (editMode || catalogueMeta.meta.erroredProductCount) {
      let deleteIconComponent;
      if (!!editMode) {
        deleteIconComponent = <DeleteIcon onClick={deleteCatalogue} className={'deleteIcon'} />;
      }

      let errorIconComponent;
      if (catalogueMeta.meta.erroredProductCount) {
        errorIconComponent = (
          <div className="errorIconContainer">
            <ErrorIcon className="errorIcon" />
          </div>
        );
      }

      return (
        <div className={'rightContainer'}>
          {errorIconComponent}
          {deleteIconComponent}
        </div>
      );
    }

    return null;
  };

  return (
    <div onClick={onRowClick} className={`catalogueRow ${isSelected ? 'selectedCatalogue' : ''}`}>
      <div className={'leftContainer'}>
        {!!editMode && (
          <Checkbox
            checked={checkboxValue}
            onCheckChanged={toggleCheckbox}
            styleProps={{ margin: '0 14px 0 0' }}
          />
        )}
        <CatalogueOverlappingImages pictures={catalogueMeta.meta.picturesMeta} />
        <div className={'titleCountContainer'}>
          <CatalogueTitle title={catalogueMeta.meta.title} catalogueEditMode={editMode} id={id} />
          <CatalogueProductCount productCount={catalogueMeta.meta.productCount} />
        </div>
      </div>
      {getRightContainer()}
    </div>
  );
};
