import React from 'react';
import './styles.scss';
import { ReactComponent as Arrow } from 'qs-assets/Media/icon_right_arrow.svg';
import { Button } from '@material-ui/core';
import useMuiStyles from './MuiStyles';
import { getI18N } from 'qs-services/i18N';

export default ({ onCancel, onNext, nextDisabled }) => {
  const muiStyles = useMuiStyles();
  const { t } = getI18N();

  return (
    <div id={'excel-footer'}>
      <div onClick={onCancel} className={'cancel'}>
        {t('cancel')}
      </div>

      {!nextDisabled && (
        <Button
          variant="contained"
          onClick={onNext}
          color="primary"
          classes={{
            root: muiStyles.root,
            endIcon: muiStyles.endIcon,
            iconSizeMedium: muiStyles.iconSizeMedium
          }}
          endIcon={<Arrow className={'arrow'} />}
        >
          {t('next')}
        </Button>
      )}
    </div>
  );
};
