import React, { useState, useRef, useEffect } from 'react';
import Loader from '../../../../Common/Loader';
import Modal from 'react-responsive-modal';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

export default ({ onClose }) => {
  const [imageUploaderView, setImageUploaderView] = useState('LOADER');

  // Block the user for 15 seconds before showing the cancel button
  const timerId = useRef();

  const modalStyles = useRef({
    color: 'black',
    background:
      'linear-gradient(to bottom, rgba(255, 255, 255, 0.95) 0%, rgba(255, 255, 255, 1) 100%)',
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    width: '700px',
    height: '500px',
    minHeight: '300px'
  });

  const { t } = getI18N();

  useEffect(() => {
    timerId.current = setTimeout(() => {
      setImageUploaderView('LOADER_CANCEL');
    }, 15000);
    return () => clearTimeout(timerId.current);
  }, []);

  let overlayClick = false,
    escClick = false,
    closeButton = null;
  if (imageUploaderView === 'LOADER_CANCEL') {
    overlayClick = true;
    escClick = true;
    closeButton = (
      <div className="prepareImageUploadContainer closeCancelUpload" onClick={onClose}>
        {t('close_modal')}
      </div>
    );
  }

  return (
    <Modal
      open={true}
      center={true}
      onClose={onClose}
      closeOnOverlayClick={overlayClick}
      closeOnEsc={escClick}
      showCloseIcon={false}
      styles={{ modal: modalStyles.current }}
    >
      {closeButton}
      <div className="prepareImageUploadContainer prepareImageUploader">
        <div>
          <Loader size="large" />
        </div>
        <div>{t('preparing_images_for_upload')}</div>
      </div>
    </Modal>
  );
};
