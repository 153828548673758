import React, { useCallback, useEffect } from 'react';
import './styles.scss';
import { convertImageUrlSize } from 'qs-helpers';
import Loader from 'qs-components/Common/Loader';
import { ReactComponent as ErrorIcon } from 'qs-assets/Media/image-error.svg';
import usePictureRetryHook from 'qs-hooks/usePictureRetryHook';
import { IMAGE_ERROR, IMAGE_HIDE_LOADER, IMAGE_LOADER } from 'qs-helpers/PicturesProcessingHelper';

/*
  Since pictureId is a dependancy for useEffect
  Ensure that the default value's reference does not change
  between subsequent renders
*/
const defaultPictureState = [];

export default ({
  pictures = defaultPictureState,
  imageContainerClass = '',
  overlapContainerClass = ''
} = {}) => {
  /*
    Structure of pictures : [{
      pictureId: <string>,
      url: <string>,
      prepared: <bool>,
      error: <bool>
    }]
  */

  const [
    picturesLoadState,
    handleImageLoad,
    handleImageError,
    updateTimeoutRefForPictures
  ] = usePictureRetryHook();

  useEffect(() => {
    updateTimeoutRefForPictures(pictures);
  }, [pictures, updateTimeoutRefForPictures]);

  const getPositionClass = useCallback(index => {
    if (index === 0) {
      return 'firstImageCatalogueRow';
    }

    if (index === 1) {
      return 'secondImageCatalogueRow';
    }

    if (index === 2) {
      return 'thirdImageCatalogueRow';
    }

    if (index === 3) {
      return 'fourthImageCatalogueRow';
    }

    return '';
  }, []);

  let picturesState = [...pictures];
  if (pictures.length !== 4) {
    for (let index = 0; index < 4 - pictures.length; index += 1) {
      picturesState.push({
        pictureId: '',
        url: '',
        prepared: true
      });
    }
  }

  const stateBasedImageUi = (pictureState, url) => {
    const { state = IMAGE_LOADER, retryCount = 0 } =
      picturesLoadState[pictureState.pictureId] || {};
    if ((pictureState.error && !pictureState.prepared) || state === IMAGE_ERROR) {
      return <ErrorIcon className={'errorIcon'} />;
    }

    let imageComponent = null;
    if (!!pictureState.prepared) {
      imageComponent = (
        <div className={'actualImageContainer'}>
          <div
            key={`${pictureState.pictureId}-background-${retryCount}`}
            className={'actualImage'}
            style={{ backgroundImage: `url(${url})` }}
          />
          <img
            key={`${pictureState.pictureId}-${retryCount}`}
            onError={handleImageError(pictureState.pictureId, IMAGE_ERROR)}
            onLoad={handleImageLoad(pictureState.pictureId, IMAGE_HIDE_LOADER)}
            src={url}
            className={'image'}
            alt={''}
          />
        </div>
      );
    }

    let loaderComponent = null;
    if (state === IMAGE_LOADER) {
      loaderComponent = (
        <div className={'loaderContainer'}>
          <Loader size={'extraSmall'} />
        </div>
      );
    }

    return (
      <>
        {loaderComponent}
        {imageComponent}
      </>
    );
  };

  return (
    <div className={`catalogueProducts ${overlapContainerClass}`}>
      {picturesState.map((pictureState, index) => {
        const positionClass = getPositionClass(index);

        //If the picture state does not exist or if it is blank
        //return without processing
        if (!pictureState || !pictureState.pictureId || !pictureState.url) {
          const key = `${index}${Date.now()}`;
          return (
            <div key={key} className={`imageContainer ${positionClass}`}>
              <div className={`loaderImageContainer dashed-border ${imageContainerClass}`} />
            </div>
          );
        }

        const url = convertImageUrlSize({
          size: 100,
          url: pictureState.url
        });

        return (
          <div key={index} className={`imageContainer ${positionClass}`}>
            <div className={`loaderImageContainer ${imageContainerClass}`}>
              {stateBasedImageUi(pictureState, url)}
            </div>
          </div>
        );
      })}
    </div>
  );
};
