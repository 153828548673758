export default {
  eventbusKey: 'MAIN_WORKER_EB_KEY',
  UPLOAD_IMAGES: {
    type: 'UPLOAD_IMAGES',
    eventbusKey: id => {
      return `UPLOAD_IMAGES_${id}`;
    }
  },
  UPLOAD_VIDEOS: {
    type: 'UPLOAD_VIDEOS',
    eventbusKey: id => {
      return `UPLOAD_VIDEOS_${id}`;
    }
  },
  UPLOAD_IMAGES_MESSAGE: {
    type: 'UPLOAD_IMAGES_MESSAGE'
  },
  UPLOAD_VIDEOS_MESSAGE: {
    type: 'UPLOAD_VIDEOS_MESSAGE'
  },
  INTERNET_CONNECTIVITY_MESSAGE: {
    type: 'INTERNET_CONNECTIVITY_MESSAGE'
  },
  UPLOAD_IMAGES_CLEAR_MESSAGE: {
    type: 'UPLOAD_IMAGES_CLEAR_MESSAGE'
  },
  UPLOAD_VIDEOS_CLEAR_MESSAGE: {
    type: 'UPLOAD_VIDEOS_CLEAR_MESSAGE'
  },
  IMAGE_UPLOAD_DONE_REPLY: {
    type: 'IMAGE_UPLOAD_DONE_REPLY'
  },
  VIDEO_UPLOAD_DONE_REPLY: {
    type: 'VIDEO_UPLOAD_DONE_REPLY'
  },
  VIDEO_UPLOAD_PROGRESS: {
    type: 'VIDEO_UPLOAD_PROGRESS'
  },
  COMPRESS_IMAGE_MAIN: {
    type: 'COMPRESS_IMAGE_MAIN'
  },
  COMPRESS_IMAGE_MAIN_COMPLETE: {
    type: 'COMPRESS_IMAGE_MAIN_COMPLETE'
  },

  //Image load repy to worker
  BROWSER_EXIF_ORIENTATION_LOAD_IMAGE: {
    type: 'BROWSER_EXIF_ORIENTATION_LOAD_IMAGE'
  },
  //Image load send to main
  LOAD_IMAGE_DATAURI: {
    type: 'LOAD_IMAGE_DATAURI'
  },
  //Video load send to main
  LOAD_VIDEO_DATAURI: {
    type: 'LOAD_VIDEO_DATAURI'
  },

  //Image load orientation reply to worker
  BROWSER_EXIF_ORIENTATION: {
    type: 'BROWSER_EXIF_ORIENTATION'
  },
  //Image load orientation send to main
  EXIF_ORIENTATION_MAIN: {
    type: 'EXIF_ORIENTATION_MAIN'
  }
};
