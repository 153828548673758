import React, { useState, useCallback } from 'react';
import './styles.scss';

import CatalogueHeader from './CatalogueHeader';
import SearchHeader from './SearchHeader';
import EditCatalogueHeader from './EditCatalogueHeader';
import ClearLabelHeader from './ClearLabelHeader';

const CATALOGUE_HEADER = 'CATALOGUE_HEADER';
const SEARCH_HEADER = 'SEARCH_HEADER';
const EDIT_CATALOGUE_HEADER = 'EDIT_CATALOGUE_HEADER';

export default ({ headerClass }) => {
  const [headerType, setHeaderType] = useState(CATALOGUE_HEADER);

  const showSearchHeader = useCallback(() => {
    setHeaderType(SEARCH_HEADER);
  }, []);

  const showCatalogueHeader = useCallback(() => {
    setHeaderType(CATALOGUE_HEADER);
  }, []);

  const showEditCatalogueHeader = useCallback(() => {
    setHeaderType(EDIT_CATALOGUE_HEADER);
  }, []);

  if (headerType === CATALOGUE_HEADER) {
    return (
      <>
        <CatalogueHeader
          headerClass={headerClass}
          showSearchHeader={showSearchHeader}
          showEditCatalogueHeader={showEditCatalogueHeader}
        />
        <ClearLabelHeader />
      </>
    );
  }

  if (headerType === SEARCH_HEADER) {
    return <SearchHeader showCatalogueHeader={showCatalogueHeader} headerClass={headerClass} />;
  }

  if (headerType === EDIT_CATALOGUE_HEADER) {
    return (
      <>
        <EditCatalogueHeader onBackClick={showCatalogueHeader} headerClass={headerClass} />
        <ClearLabelHeader />
      </>
    );
  }

  return null;
};
