export const RESET_PRODUCT_LIST_FILTER = 'RESET_PRODUCT_LIST_FILTER';
export const SET_PRODUCT_LIST_FILTERS = 'SET_PRODUCT_LIST_FILTERS';
export const SET_PRODUCT_LIST_TAGS_FILTER = 'SET_PRODUCT_LIST_TAGS_FILTER';
export const SET_PRODUCT_LIST_SEARCH = 'SET_PRODUCT_LIST_SEARCH';
export const DELETE_CATALOGUE_FILTERS = 'DELETE_CATALOGUE_FILTERS';

const setCurrentPriceFilters = ({ currentPriceFilterSelectedOption, currentPriceFilters }) => {
  //Indicates a possible reset
  if (!currentPriceFilterSelectedOption || !currentPriceFilters) {
    return;
  }

  return currentPriceFilters[currentPriceFilterSelectedOption];
};

const setCurrentVariantFilters = ({ currentVariantSelection }) => {
  //Indicates a possible reset
  if (!currentVariantSelection || Object.keys(currentVariantSelection).length === 0) {
    return;
  }

  return currentVariantSelection;
};

const setCustomFieldsFilters = ({ currentCustomFieldsSelection, customFieldsNumericOption }) => {
  //Indicates a possible reset
  if (!currentCustomFieldsSelection || Object.keys(currentCustomFieldsSelection).length === 0) {
    return;
  }

  const customFieldsSelection = Object.keys(currentCustomFieldsSelection).reduce(
    (cumulativeArray, fieldId) => {
      const selectedNumericOption = customFieldsNumericOption[fieldId];
      const { numericValue, ...restFieldData } = currentCustomFieldsSelection[fieldId];
      //Save rest fields only for later check
      const fieldDataForFilter = {
        ...restFieldData
      };

      //Pick the numeric value that matches the current selection
      if (numericValue && selectedNumericOption) {
        fieldDataForFilter.numericValue = numericValue[selectedNumericOption];
      }

      //Ensure that this field data can be included
      if (Object.keys(fieldDataForFilter).length > 0) {
        fieldDataForFilter.fieldId = fieldId;
        cumulativeArray.push(fieldDataForFilter);
      }

      return cumulativeArray;
    },
    []
  );

  return customFieldsSelection;
};

const getExistingCatalogueData = (state, catalogueId) => state[catalogueId] || {};

export const productListFilterInit = catalogueId => ({
  [catalogueId]: {
    searchTerm: undefined,
    priceFilters: undefined,
    variantSelection: undefined,
    customFieldsSelection: undefined,
    selectedTags: undefined
  }
});

export const productListFilterReducer = (state, action) => {
  const { catalogueId, type } = action;

  switch (type) {
    case SET_PRODUCT_LIST_FILTERS: {
      const updatedPriceFilters = setCurrentPriceFilters(action);
      const updatedVariantFilters = setCurrentVariantFilters(action);
      const updatedCustomFieldsFilters = setCustomFieldsFilters(action);

      return {
        ...state,
        [catalogueId]: {
          ...getExistingCatalogueData(state, catalogueId),
          priceFilters: updatedPriceFilters,
          variantSelection: updatedVariantFilters,
          customFieldsSelection: updatedCustomFieldsFilters
        }
      };
    }
    case SET_PRODUCT_LIST_TAGS_FILTER: {
      const { currentSelectedTags } = action;
      let updatedTags;
      if (currentSelectedTags instanceof Set && currentSelectedTags.size > 0) {
        updatedTags = currentSelectedTags;
      }
      return {
        ...state,
        [catalogueId]: {
          ...getExistingCatalogueData(state, catalogueId),
          selectedTags: updatedTags
        }
      };
    }
    case SET_PRODUCT_LIST_SEARCH:
      return {
        ...state,
        [catalogueId]: {
          ...getExistingCatalogueData(state, catalogueId),
          searchTerm: action.searchTerm
        }
      };
    case RESET_PRODUCT_LIST_FILTER:
      //Catalogue id data doesnt exist in the state object, nothing to reset
      if (!state[catalogueId]) {
        return state;
      }

      return { ...state, [catalogueId]: productListFilterInit(catalogueId) };
    case DELETE_CATALOGUE_FILTERS:
      const updatedState = { ...state };
      delete updatedState[catalogueId];
      return updatedState;
    default:
      return state;
  }
};
