import { navigateToPath } from 'qs-data-manager';
import {
  ROUTE_MAP,
  SETTINGS_COMPONENT_ID_MAP,
  SETTINGS_COMPONENT_ROUTE_MAP
} from 'qs-helpers/CompanySettings/constants';
import React from 'react';
import { ReactComponent as RightArrow } from 'qs-assets/Media/icon_long_arrow_right.svg';
import './styles.scss';
import { SELECT_VALUE } from '../ProductTypeDetails';
import { getI18N } from 'qs-services/i18N';

export default ({
  getSelectedMetalKaratPrice,
  currencySymbol,
  checkIfRatesAreNotSet,
  selectedMetalType,
  metalKaratQuality
}) => {
  const { t } = getI18N();
  const editMetalRate = () => {
    navigateToPath(
      `/${ROUTE_MAP.ACCOUNT_SETTINGS}/${
        SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.METAL_RATES_OPTIONS].ROUTE
      }/${selectedMetalType || ''}`
    );
  };

  const showMetalPrice = getSelectedMetalKaratPrice();

  const showProductPriceOrText = () => {
    if (
      !checkIfRatesAreNotSet() &&
      metalKaratQuality !== SELECT_VALUE.id &&
      getSelectedMetalKaratPrice()
    ) {
      return (
        <div className="metalPriceSetMetalRateContainer">
          <div className="price">
            <span>{currencySymbol}</span> {showMetalPrice}
          </div>
          <div className="change">
            <div className="changeMetalPrice" onClick={editMetalRate}>
              {t('change_rates')}
            </div>
          </div>
        </div>
      );
    }
    if (
      checkIfRatesAreNotSet() &&
      metalKaratQuality !== SELECT_VALUE.id &&
      !getSelectedMetalKaratPrice()
    ) {
      return showSetMetalRateText();
    }
    if (!checkIfRatesAreNotSet() && metalKaratQuality === SELECT_VALUE.id) {
      return <div className="notSpecified">{t('not_specified')}</div>;
    }
  };

  const showSetMetalRateText = () => {
    return (
      <div className="setMetalRate" onClick={editMetalRate}>
        <div className="setMetalRateText">{t('set_metal_rate')}</div>
        <RightArrow className="rightArrow" />
      </div>
    );
  };
  return (
    <div className="metalRateConatiner">
      <div className="title">{t('metal_rate')}</div>
      {showProductPriceOrText()}
    </div>
  );
};
