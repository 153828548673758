import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  SETTINGS_COMPONENT_ID_MAP,
  SETTINGS_COMPONENT_ROUTE_MAP
} from 'qs-helpers/CompanySettings/constants';
import SettingsIframeComponent from '../SettingsIframeComponent';
import FeatureModal from '../../../Common/FeatureModal';
import { getPaidFeatures } from '../../../Common/FeatureModal/helper';
import { getI18N } from 'qs-services/i18N';

export default () => {
  const { t } = getI18N();
  const history = useHistory();

  const planFeature = getPaidFeatures().WEBHOOKS;
  const {
    isAllowed: { allowed }
  } = planFeature;
  if (!allowed) {
    return <FeatureModal currentPlanFeature={planFeature} onClose={() => history.goBack()} />;
  }

  return (
    <SettingsIframeComponent
      id={SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.WEBHOOKS_SETTINGS].TITLE}
      title={t(SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.WEBHOOKS_SETTINGS].TITLE)}
      route={SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.WEBHOOKS_SETTINGS].IFRAME_ROUTE}
      removeBeginningHash={
        SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.WEBHOOKS_SETTINGS].REMOVE_HASH
      }
      iframeStyle={{ width: '100%', border: 'none' }}
    />
  );
};
