import React, { useCallback, useContext } from 'react';
import { roundNumberToGivenDecimals } from 'qs-helpers';
import { getShippingCostFromInquiryData } from 'qs-helpers/Orders/ResponseProcessor';
import OrderKeyValue from '../../OrderKeyValue';
import { ActiveOrderMeta } from '../../../context';
import EditShippingCost from './EditShippingCost';
import { editNotAllowedReason } from 'qs-helpers/Orders/ShippingProviderHelper';
import { changeOrderShippingPrice } from 'qs-data-manager/Orders/OrdersProductsOperations';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

export default ({ inquiryData }) => {
  const { orderEditable, shippingConfig } = inquiryData;
  const {
    activeOrderMeta: { selectedOrderId }
  } = useContext(ActiveOrderMeta);
  const { shippingReason } = editNotAllowedReason(shippingConfig);

  const canEditShipping = useCallback(() => {
    return orderEditable;
  }, [orderEditable]);

  const onUpdateShippingPrice = useCallback(
    shippingCost => changeOrderShippingPrice({ orderId: selectedOrderId, shippingCost }),
    [selectedOrderId]
  );

  const { t } = getI18N();

  const shippingCost = getShippingCostFromInquiryData(inquiryData);
  let shippingValueToShow = t('add_shipping'),
    addCurrencyInfo = false,
    valueContainerClass = 'orderSingleViewAddMissingData';
  if (typeof shippingCost === 'number') {
    shippingValueToShow = roundNumberToGivenDecimals(shippingCost);
    addCurrencyInfo = true;
    valueContainerClass = undefined;
  }

  const renderShippingEditView = ({ onCancel, onUpdate }) => (
    <EditShippingCost onCancel={onCancel} onUpdate={onUpdate} inquiryData={inquiryData} />
  );

  return (
    <OrderKeyValue
      rows={[
        {
          label: t('shipping'),
          valueContainerClass: valueContainerClass,
          value: shippingValueToShow,
          addCurrencyInfo: addCurrencyInfo,
          editable: {
            canEditField: canEditShipping,
            errorInfo: {
              mail: {
                title: t('edit_order_shipping'),
                subject: t('failed_to_modify_shipping_cost_for_order', { selectedOrderId }),
                message: t('something_went_wrong_while_modifying_this_order_shipping')
              },
              fieldEditError: {
                title: t('edit_order_shipping'),
                content: shippingReason
              }
            },
            updaterFunction: onUpdateShippingPrice,
            renderEditableField: renderShippingEditView
          }
        }
      ]}
    />
  );
};
