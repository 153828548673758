import React from 'react';
import './styles.scss';
import Loader from '../../../../Common/Loader';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { ReactComponent as HelpIcon } from 'qs-assets/Media/icon_help.svg';
import { getLabelStyles, useStyles, useSelectedStyles, MenuProps } from './MUISelectHelpers';
import { EXCEL_UPLOAD_META } from 'qs-data-manager/Catalogues';
import ErrorIcon from '../../../../Common/ErrorIcon';
import { getI18N } from 'qs-services/i18N';

export default ({
  multiple,
  column,
  onChange,
  value,
  disabled: selectDisabled,
  id,
  dropDownValues,
  excelComponentMap,
  showDialogue
}) => {
  const classes = useStyles();
  const selectedClasses = useSelectedStyles();
  const {
    select,
    icon,
    underline,
    selected,
    root,
    listItemDisabled,
    listItemRoot
  } = getLabelStyles({
    classes,
    selectedClasses,
    labelValue: value
  });

  const { t } = getI18N();

  const renderAdditionalDataIfExists = componentData => {
    if (!componentData || typeof componentData.type !== 'string') {
      return null;
    }

    switch (componentData.type) {
      case 'LOADER':
        const { loading, error } = componentData;
        if (loading) {
          return (
            <div className="loaderSeperator">
              <Loader size="extraSmall" />
            </div>
          );
        } else if (error) {
          return (
            <div className="loaderSeperator">
              <ErrorIcon width={20} height={20} />
            </div>
          );
        }
        return null;
      default:
        return null;
    }
  };

  const renderValue = selected => {
    if (Array.isArray(selected)) {
      return selected.length === 0
        ? t('select_product_field')
        : selected.map(id => excelComponentMap[id].title).join(', ');
    }

    return selected
      ? excelComponentMap[selected] && excelComponentMap[selected].title
      : t('select_product_field');
  };

  return (
    <Select
      multiple={multiple}
      displayEmpty
      classes={{ icon, select }}
      value={value}
      onChange={e => onChange(e, { id, column, multiple })}
      input={<Input classes={{ underline }} />}
      renderValue={renderValue}
      MenuProps={MenuProps}
      inputProps={{ 'aria-label': 'without label' }}
      disabled={selectDisabled}
    >
      {!multiple && (
        <MenuItem classes={{ root }} key={'NONE'} value={''}>
          {t('none')}
        </MenuItem>
      )}
      {dropDownValues.map(
        ({
          value,
          displayValue,
          disabled: menuDisabled = false,
          componentData,
          parentSection,
          customStyles
        }) => {
          return (
            <MenuItem
              classes={{ selected, root }}
              className="excelDropDownItemContainer"
              ListItemClasses={{ disabled: listItemDisabled, root: listItemRoot }}
              style={customStyles || {}}
              key={`${id}_${value}`}
              value={value}
              disabled={menuDisabled}
            >
              {displayValue}
              {/*TODO move help icon as an additional data, so that multiple columns can reuse*/}
              {value === EXCEL_UPLOAD_META.PARENT_SKU.id && (
                <div onClick={showDialogue} id={'excel-productSKU-helper-icon'}>
                  <HelpIcon />
                </div>
              )}
              {parentSection && <div className="parentSectionSeperator" />}
              {renderAdditionalDataIfExists(componentData)}
            </MenuItem>
          );
        }
      )}
    </Select>
  );
};
