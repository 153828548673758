import React from 'react';
import { ReactComponent as CustomFooterIcon } from 'qs-assets/Media/ic_custom_footer_icon.svg';
import './styles.scss';

export default ({ width, height, fill, classes = '' }) => {
  return (
    <CustomFooterIcon
      style={{ width, height, fill }}
      className={`settingsIconComponent ${classes}`}
    />
  );
};
