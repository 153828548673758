import Listener from 'qs-helpers/Listener';
import { getUserMetaFromCache, setUserMetaInCache } from 'qs-data-manager/User';
import { registerCleanupHandler } from 'qs-helpers/ClearSavedData';

const listener = new Listener();
let LISTENER_ATTACHED = {};
const END_POINT = {
  USER_META: ({ userId }) => `users/${userId}`
};

const attachUserMetaListener = ({ userId }) => {
  if (!userId) {
    return;
  }

  const key = END_POINT.USER_META({ userId });

  if (LISTENER_ATTACHED[key]) {
    return;
  }

  LISTENER_ATTACHED[key] = true;
  listener.add({ key, type: 'value' }, snapshot => {
    let value = snapshot.val();
    if (!value || !value.id) {
      const cachedUser = getUserMetaFromCache();
      value = {
        ...(cachedUser || {}),
        ...value
      };
    }
    setUserMetaInCache({ data: value });
  });
};

const removeUserMetaListener = ({ userId }) => {
  if (!userId) {
    return;
  }

  const key = END_POINT.USER_META({ userId });
  LISTENER_ATTACHED[key] = false;
  listener.remove({ key, type: 'value' });
};

const clearAttachedListener = () => {
  LISTENER_ATTACHED = {};
  listener.removeAll();
};

registerCleanupHandler(clearAttachedListener);

export { attachUserMetaListener, removeUserMetaListener };
