import { SHIPPING_PROVIDERS } from 'qs-helpers/Orders/constants';
import React from 'react';
import { ActionButton } from '../Common/ActionButtons';
import { openPopup } from 'qs-utils';
import { getI18N } from 'qs-services/i18N';

export default function ViewExternalOrder({ buttonClasses, shippingConfig }) {
  const { t } = getI18N();

  const onViewOrderClick = () => {
    openPopup(shippingConfig.url, 'noopener=true');
  };

  const { key, canPlaceExternalOrder } = shippingConfig;
  const shippingProviderConfig = SHIPPING_PROVIDERS[key];
  if (!shippingProviderConfig || !shippingConfig.url || canPlaceExternalOrder) {
    return null;
  }

  return (
    <ActionButton
      classes={{ root: buttonClasses.buttonRoot }}
      color={'primary'}
      variant="contained"
      onClick={onViewOrderClick}
    >
      {t(shippingProviderConfig.label)} {t('order')}
    </ActionButton>
  );
}
