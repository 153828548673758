import React, { useEffect, useState } from 'react';
import eventingBus from 'eventing-bus';
import { MOQ_CATALOGUE_ENTITY, MOQ_PRODUCT_ENTITY } from 'qs-helpers/Moq/constants';
import { SAVE_BUTTON_META } from 'qs-helpers/Products/constants';
import MoqField from '../../../Common/MoqField';
import useMakeQuery from 'qs-hooks/useMakeQuery';
import {
  fetchMoqForEntityHandler,
  getCachedMoqData,
  handleMoqForEntityCleanup
} from 'qs-helpers/Moq/DataQueryHelper';
import { getMinimumOrderQuantity } from 'qs-helpers/Moq/ResponseProcessor';
import { getProductMetaFromCache } from 'qs-data-manager/Products';
import { getMoqForEntity, updateMoqForEntity } from 'qs-data-manager/Moq/MoqOperations';
import { toggleGlobalLoader } from 'qs-helpers';
import toastr from 'toastr';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

export default ({ activeProductId }) => {
  const [moq, setMoq] = useState('');
  const [{ loading, refreshing, error, data }] = useMakeQuery({
    changeDependancy: [activeProductId, MOQ_PRODUCT_ENTITY, true],
    actionHandler: fetchMoqForEntityHandler,
    cleanupHandler: handleMoqForEntityCleanup,
    cachedDataHandler: getCachedMoqData
  });

  const { t } = getI18N();

  const minOrderQuantity = getMinimumOrderQuantity(data);

  useEffect(() => {
    setMoq(minOrderQuantity);
  }, [minOrderQuantity]);

  const onMoqChange = value => {
    setMoq(value);
    eventingBus.publish(SAVE_BUTTON_META.eventbusKey, {
      id: SAVE_BUTTON_META.PRODUCT_MOQ_FIELD.id,
      hasChanged: value !== minOrderQuantity,
      data: value,
      eventType: SAVE_BUTTON_META.eventType.PRODUCT_META.id
    });
  };

  const onResetClick = async event => {
    event.preventDefault();
    const loaderKey = `reset-product-moq-${Date.now()}`;
    toggleGlobalLoader(loaderKey, true);
    try {
      await updateMoqForEntity({
        entityId: activeProductId,
        entityType: MOQ_PRODUCT_ENTITY,
        moqChange: {
          minOrderQuantity: null,
          entityType: MOQ_CATALOGUE_ENTITY
        }
      });
      eventingBus.publish(SAVE_BUTTON_META.eventbusKey, {
        id: SAVE_BUTTON_META.PRODUCT_MOQ_FIELD.id,
        hasChanged: false,
        eventType: SAVE_BUTTON_META.eventType.PRODUCT_META.id
      });
    } catch (error) {
      toastr.error(t('something_went_wrong_while_resetting_moq_to_catalogue_default'));
    } finally {
      toggleGlobalLoader(loaderKey, false);
    }
    getMoqForEntity({ entityId: activeProductId, entityType: MOQ_PRODUCT_ENTITY, computed: true });
  };

  if (!activeProductId) {
    return null;
  }

  let isSet = false;
  const productMeta = getProductMetaFromCache(activeProductId);
  if (productMeta) {
    ({ isSet } = productMeta);
  }

  const getManagedByComponent = () => {
    if (!data) {
      return null;
    }

    if (data.entityType === MOQ_PRODUCT_ENTITY) {
      return (
        <div onClick={onResetClick} className="productMoqManagedByContainer resetCatalogue">
          {t('reset_to_catalogue_default')}
        </div>
      );
    }

    return (
      <div className="productMoqManagedByContainer inheritFromCatalogue">
        {t('currently_managed_by_catalogue_settings')}
      </div>
    );
  };

  return (
    <MoqField
      moq={moq}
      onChange={onMoqChange}
      loading={loading}
      error={error}
      disabled={refreshing}
      isSet={isSet}
    >
      {getManagedByComponent()}
    </MoqField>
  );
};
