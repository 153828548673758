import Api from 'qs-services/Api';
import { reportError } from 'qs-helpers/ErrorReporting';
import { updateCompanySettingsInCache } from './Company';

export const setMinimumOrderAmount = async minOrderVal => {
  try {
    await Api.setMinOrderAmt({ minOrderVal });
    updateCompanySettingsInCache({
      updateData: {
        minOrderAmount: minOrderVal
      }
    });
  } catch (err) {
    reportError(err);
    throw err;
  }
};

export const disableMinOrderAmount = async () => {
  try {
    await Api.disableMinOrderAmt();
    updateCompanySettingsInCache({
      updateData: {
        minOrderAmount: 0
      }
    });
  } catch (err) {
    reportError(err);
    throw err;
  }
};
