import React, { useContext, useCallback, Fragment } from 'react';
import InfoFormField from '../../../Common/InfoFormField';
import NumberInput from '../../../../../Common/NumberInput';
import ProductDiscount from '../../../Common/ProductDiscount';
import { toggleGlobalLoader } from 'qs-helpers';
import { saveVariantChanges } from 'qs-data-manager/Variants/VariantsDetails';
import { VariantDataForUpdate } from '../../context';
import {
  UPDATE_PRICE,
  UPDATE_DISCOUNT,
  UPDATE_ORIGINAL_TO_CURRENT,
  UPDATE_FROM_PRODUCT_PRICE,
  UPDATE_FROM_PRODUCT_DISCOUNT
} from '../../reducer';
import { ActiveVariantId } from '../../../context';
import './styles.scss';

const discountLabel = 'After discount';

export default ({ containerClass, currencySymbol, disabled, isSetSelected }) => {
  const { activeVariantId } = useContext(ActiveVariantId);
  const {
    variantData: {
      price,
      originalPrice,
      showPriceChange,
      discount,
      originalDiscount,
      showDiscountChange
    },
    setVariantData
  } = useContext(VariantDataForUpdate);

  const onDiscountChange = discountVal => {
    setVariantData({ type: UPDATE_DISCOUNT, discount: discountVal });
  };

  const processPriceChange = stringifiedPrice => {
    setVariantData({ type: UPDATE_PRICE, price: stringifiedPrice });

    // If change button for discount is being shown, no need to process
    // anything related to it
    if (showDiscountChange) {
      return;
    }

    const priceNum = Number(stringifiedPrice);
    if (priceNum === 0) {
      //Price is 0 reset the discount
      onDiscountChange('');
      return;
    }
  };

  const onDiscountRemoval = async () => {
    const loaderKey = `removeVariantDiscount${Date.now()}`;
    toggleGlobalLoader(loaderKey, true);
    try {
      await saveVariantChanges([
        { data: { price, originalPrice, discount: '' }, variantId: activeVariantId }
      ]);
      setVariantData({ type: UPDATE_DISCOUNT, discount: '' });
      setVariantData({ type: UPDATE_ORIGINAL_TO_CURRENT });
    } finally {
      toggleGlobalLoader(loaderKey, false);
    }
  };

  const changeClicked = useCallback(
    label => {
      if (label === 'Price') {
        setVariantData({ type: UPDATE_FROM_PRODUCT_PRICE, showPriceChange: false });
        return;
      }

      if (label === discountLabel) {
        setVariantData({ type: UPDATE_FROM_PRODUCT_DISCOUNT, showDiscountChange: false });
        return;
      }
    },
    [setVariantData]
  );

  return (
    <Fragment>
      <div className={`${containerClass} variantPriceDiscountContainer`}>
        <InfoFormField
          fieldType={'INPUT'}
          label="Price"
          fieldLabel="Price"
          changeButton={showPriceChange}
          onChangeClick={changeClicked}
        >
          <div className="variantsPriceContainer">
            <span className="currency">{currencySymbol}</span>
            <NumberInput
              value={price}
              onChange={processPriceChange}
              disabled={showPriceChange || disabled}
              placeholder="Type variant price"
            />
          </div>
        </InfoFormField>
      </div>
      {showDiscountChange ? (
        <Fragment>
          {/*
            While the change button is shown, don't overload the discount component
            Simply create a view only number component
          */}
          <InfoFormField
            fieldType={'INPUT'}
            label={discountLabel}
            fieldLabel={discountLabel}
            changeButton={showDiscountChange}
            onChangeClick={changeClicked}
          >
            <div className="variantsPriceContainer">
              <span className="currency">{currencySymbol}</span>
              <NumberInput
                value={discount}
                disabled={showDiscountChange}
                placeholder="Type variant discounted price"
              />
            </div>
          </InfoFormField>
        </Fragment>
      ) : (
        <Fragment>
          {/* Pass a key to ensure that when the activeVariantId changes, the component is reset*/}
          <ProductDiscount
            key={activeVariantId}
            productId={activeVariantId}
            originalDiscount={originalDiscount}
            discount={discount}
            originalPrice={originalPrice}
            price={price}
            isSetSelected={isSetSelected}
            currencySymbol={currencySymbol}
            handleDiscountChange={onDiscountChange}
            onDiscountRemoval={onDiscountRemoval}
            inputLabel={discountLabel}
            disabled={disabled}
            placeholder="Type variant discounted price"
            discountPercentAbsolute={true}
          />
        </Fragment>
      )}
    </Fragment>
  );
};
