import React from 'react';
import CompanySettings from './CompanySettings';
import WithNavRef from 'qs-helpers/WithNavRef';
import './styles.scss';

export default props => {
  return (
    <WithNavRef navigationRef={props}>
      <div id={'AccountSettingsScreen'}>
        <CompanySettings {...props} />
      </div>
    </WithNavRef>
  );
};
