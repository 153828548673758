import React, { useContext, useEffect, useRef, useState } from 'react';
import useMakeQuery from 'qs-hooks/useMakeQuery';
import { ActiveOrderMeta } from '../context';
import { UPDATE_ORDER_LIST_FILTER, UPDATE_ORDER_LIST_SEARCH } from '../reducer';
import {
  ACCEPTED_ORDERS,
  ALL_ORDERS,
  COMPLETED_ORDERS,
  CONFIRMED_ORDERS,
  REJECTED_ORDERS,
  UNCONFIRMED_ORDERS
} from 'qs-helpers/Orders/constants';
import { ReactComponent as SearchIcon } from 'qs-assets/Media/magnifyingGlass.svg';
import { ReactComponent as ClearIcon } from 'qs-assets/Media/multiply.svg';
import { ReactComponent as ConfirmedIcon } from 'qs-assets/Media/checkmark.svg';
import { ReactComponent as CompletedIcon } from 'qs-assets/Media/checkmark_filled.svg';
import { ReactComponent as AcceptedIcon } from 'qs-assets/Media/thumbs_up.svg';
import { ReactComponent as RejectedIcon } from 'qs-assets/Media/thumbs_down.svg';
import Loader from '../../Common/Loader';
import {
  getCachedCompanyUnreadInquiries,
  handleCompanyUnreadInquiriesCleanup,
  handleFetchCompanyUnreadInquiries
} from 'qs-helpers/Orders/DataQueryHelper';
import './styles.scss';
import { getI18N } from 'qs-services/i18N';

export default () => {
  const {
    activeOrderMeta: { currentConfirmFilter },
    setActiveOrderMeta
  } = useContext(ActiveOrderMeta);
  const [searchText, setSearchText] = useState('');
  const searchDebounceTimer = useRef();
  const { t } = getI18N();

  useEffect(() => {
    clearTimeout(searchDebounceTimer.current);
    searchDebounceTimer.current = setTimeout(() => {
      searchDebounceTimer.current = null;
      setActiveOrderMeta({ type: UPDATE_ORDER_LIST_SEARCH, searchText });
    }, 200);
  }, [searchText, setActiveOrderMeta]);

  const [{ loading, data: unreadInquiryData }] = useMakeQuery({
    changeDependancy: [currentConfirmFilter],
    cachedDataHandler: getCachedCompanyUnreadInquiries,
    actionHandler: handleFetchCompanyUnreadInquiries,
    cleanupHandler: handleCompanyUnreadInquiriesCleanup,
    globalLoader: false
  });

  const onFilterClick = orderStatus => event => {
    event.preventDefault();
    setActiveOrderMeta({ type: UPDATE_ORDER_LIST_FILTER, confirm: orderStatus });
  };

  const orderStatusFilters = useRef([
    {
      label: t('all'),
      status: ALL_ORDERS
    },
    {
      label: t('confirmed'),
      status: CONFIRMED_ORDERS,
      icon: ({ isActive }) => (
        <ConfirmedIcon
          className={`filterIcons confirmedIcon ${isActive ? 'activeConfirmed' : ''}`}
        />
      )
    },
    {
      label: t('accepted'),
      status: ACCEPTED_ORDERS,
      icon: ({ isActive }) => (
        <AcceptedIcon className={`filterIcons acceptedIcon ${isActive ? 'activeAccepted' : ''}`} />
      )
    },
    {
      label: t('completed'),
      status: COMPLETED_ORDERS,
      icon: ({ isActive }) => (
        <CompletedIcon
          className={`filterIcons completedIcon ${isActive ? 'activeCompleted' : ''}`}
        />
      )
    },
    {
      label: t('unconfirmed'),
      status: UNCONFIRMED_ORDERS,
      icon: ({ isActive }) => (
        <ConfirmedIcon
          className={`filterIcons unconfirmedIcon ${isActive ? 'activeUnconfirmed' : ''}`}
        />
      )
    },
    {
      label: t('rejected'),
      status: REJECTED_ORDERS,
      icon: ({ isActive }) => (
        <RejectedIcon className={`filterIcons rejectedIcon ${isActive ? 'activeRejected' : ''}`} />
      )
    }
  ]);

  const onSearchChange = event => {
    setSearchText(event.target.value);
  };

  const onClearSearch = event => {
    event.preventDefault();
    setSearchText('');
  };

  const getSelectedFilterClass = filterVal => {
    if (currentConfirmFilter === filterVal) {
      return 'selectedFilter';
    }

    return '';
  };

  const getUnreadInquiryComponent = () => {
    if (loading || !unreadInquiryData) {
      return (
        <span className="unreadInquiryLoader">
          <Loader size="extraSmall" />
        </span>
      );
    }

    const { unreadInquiryCount } = unreadInquiryData;
    if (!unreadInquiryCount) {
      return null;
    }

    return <div className="unreadInquiryCount ellipsis">{`${unreadInquiryCount} ${t('new')}`}</div>;
  };

  const getHeaderAsPerFilter = () => {
    switch (currentConfirmFilter) {
      case ALL_ORDERS:
        return t('all_orders');
      case CONFIRMED_ORDERS:
        return t('confirmed_orders');
      case UNCONFIRMED_ORDERS:
        return t('unconfirmed_orders');
      case ACCEPTED_ORDERS:
        return t('accepted_orders');
      case REJECTED_ORDERS:
        return t('rejected_orders');
      default:
        return t('completed_orders');
    }
  };

  return (
    <header className="ordersHeaderContainer">
      <section className="ordersScreenTitleContainer">
        <h2 className="ordersTitle ellipsis">{getHeaderAsPerFilter()}</h2>
        {getUnreadInquiryComponent()}
        <div className="searchContainer">
          <SearchIcon className={'searchIcon'} />
          <input
            className="searchInputBox"
            value={searchText}
            onChange={onSearchChange}
            placeholder={`${t('search_orders')}...`}
            type="text"
          />
          {searchText.length ? <ClearIcon onClick={onClearSearch} className={'clearIcon'} /> : null}
        </div>
      </section>
      <section className="orderScreenFilterContainer">
        {orderStatusFilters.current.map(({ status, label, icon }, index) => (
          <button
            key={index}
            className={`filterContainer ${getSelectedFilterClass(status)}`}
            onClick={onFilterClick(status)}
          >
            {typeof icon === 'function'
              ? icon({ isActive: status === currentConfirmFilter })
              : null}
            {label}
          </button>
        ))}
      </section>
    </header>
  );
};
