import { connector } from 'qs-data-manager/ApiAndCacheConnector';
import CacheRequest from 'qs-data-manager/CacheRequest';
import cloneDeep from 'lodash.clonedeep';

export const updatePictureDataBasicInfo = ({
  activeProductData,
  activeProductId,
  existingPictureId
}) => {
  const basicInfoCacheKey = `${connector.BASIC_INFO.cacheKey}${activeProductId}`;
  const basicInfoCache = CacheRequest.getCacheForKey(basicInfoCacheKey);
  if (!(basicInfoCache && basicInfoCache.pictures && basicInfoCache.pictures[existingPictureId])) {
    return;
  }

  const newBasicInfoCache = cloneDeep(basicInfoCache);

  //eliminate the earlier picture Id as it will no longer be used
  delete newBasicInfoCache.pictures[existingPictureId];

  // Add the new picture Id to the list of the pictures
  const { pictureId, isPrepared, defaultImageErrored, pictureUrl } = activeProductData;
  newBasicInfoCache.pictures[pictureId] = {
    id: pictureId,
    prepared: isPrepared,
    error: defaultImageErrored,
    url: pictureUrl
  };

  CacheRequest.setCacheForKey(basicInfoCacheKey, newBasicInfoCache);
};

export const deleteDefaultImageBasicInfo = ({ productId, pictureId }) => {
  const basicInfoCacheKey = `${connector.BASIC_INFO.cacheKey}${productId}`;
  const basicInfoCache = CacheRequest.getCacheForKey(basicInfoCacheKey);
  if (!(basicInfoCache && basicInfoCache.pictures && basicInfoCache.pictures[pictureId])) {
    return;
  }

  const newBasicInfoCache = cloneDeep(basicInfoCache);

  //eliminate the earlier picture Id as it will no longer be used
  delete newBasicInfoCache.pictures[pictureId];
  CacheRequest.setCacheForKey(basicInfoCacheKey, newBasicInfoCache);
};

export const updateDefaultImageBasicInfo = ({
  productId,
  pictureId,
  isPrepared,
  defaultImageErrored,
  pictureUrl
}) => {
  const basicInfoCacheKey = `${connector.BASIC_INFO.cacheKey}${productId}`;
  const basicInfoCache = CacheRequest.getCacheForKey(basicInfoCacheKey);
  if (!(basicInfoCache && basicInfoCache.pictures && basicInfoCache.pictures[pictureId])) {
    return;
  }

  const newBasicInfoCache = cloneDeep(basicInfoCache);

  // update the data for the default image
  newBasicInfoCache.pictures[pictureId].id = pictureId;
  newBasicInfoCache.pictures[pictureId].prepared = isPrepared;
  newBasicInfoCache.pictures[pictureId].error = defaultImageErrored;
  newBasicInfoCache.pictures[pictureId].url = pictureUrl;

  CacheRequest.setCacheForKey(basicInfoCacheKey, newBasicInfoCache);
};
