import CacheRequest from 'qs-data-manager/CacheRequest';
import cloneDeep from 'lodash.clonedeep';
import {
  changeProgressBarHeadersForMeta,
  UPLOAD_PRODUCT_PICURES_HEADER
} from '../ProcessUploadedImage';
import { connector } from 'qs-data-manager/ApiAndCacheConnector';

// const handleUploadFailure = async ({ pictureId }) => {
//   try {
//     await Api.updateProductPicturesError([pictureId]);
//   } catch (error) {
//     reportError(error);
//   }
// };

const updateVideoInfoCache = ({ prepared, processed, id }, { cacheKey }) => {
  const basicInfoCache = CacheRequest.getCacheForKey(cacheKey);
  if (!basicInfoCache) {
    return;
  }

  const newBasicInfoCache = cloneDeep(basicInfoCache);
  //Update the prepared state of the video id
  if (!newBasicInfoCache.videos || !newBasicInfoCache.videos[id]) {
    return;
  }

  const existingVideoData = newBasicInfoCache.videos[id];
  newBasicInfoCache.videos[id] = {
    ...existingVideoData,
    prepared,
    processed
  };

  CacheRequest.setCacheForKey(cacheKey, newBasicInfoCache);
};

// Handles side effect of when extra picture are done uploading to a single product
// TODO this entrie thing will be replaced by real time updates
const videoUploadToProductDone = data => {
  const { processedVideo } = data;
  updateVideoInfoCache(processedVideo, {
    cacheKey: `${connector.BASIC_INFO.cacheKey}${processedVideo.productId}`
  });
  changeProgressBarHeadersForMeta({
    uploadHeader: UPLOAD_PRODUCT_PICURES_HEADER,
    mappingId: processedVideo.productId,
    uploadedImages: 1
  });
};

export const onVideoUploadDone = data => {
  if (data) {
    if (data.error) {
      //   handleUploadFailure(data);
      return;
    }

    videoUploadToProductDone(data);
  }
};
