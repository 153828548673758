import { registerCleanupHandler } from 'qs-helpers/ClearSavedData';
import { reportError } from '../ErrorReporting';
import { getCommonMetaCount, uploadVariantPictures } from 'qs-api/Variants/api';
import { UPLOAD_VARIANT_HEADER } from '../ProcessUploadedImage';
import { MOQ_VARIANT_ENTITY } from '../Moq/constants';

const PRODUCT_VARIANT_LAST_FETCHED = {
  ts: {}
};

export const setLastFetchedVariantTimeStamp = ({ date, productId }) => {
  PRODUCT_VARIANT_LAST_FETCHED.ts[productId] = date;
};

export const getLastFetchedVariantTimeStamp = productId => {
  return PRODUCT_VARIANT_LAST_FETCHED.ts[productId] || null;
};

export const stringifyRgb = rgb => {
  if (!Array.isArray(rgb)) {
    return '';
  }

  return rgb.join('-');
};

export const getProductOptionsAsSet = productOptionsList => {
  const set = new Set();
  productOptionsList.forEach(({ rgb, label }) => {
    if (!rgb) {
      set.add(label);
      return;
    }
    set.add(stringifyRgb(rgb));
  });
  return set;
};

export const processCommonMetaCount = async variantId => {
  try {
    const commonMetaCount = await getCommonMetaCount(variantId);
    return commonMetaCount.commonVariants;
  } catch (commonMetaError) {
    reportError(commonMetaError);
    throw commonMetaError;
  }
};

export const processVariantImagesAdd = async ({ pictures, variantId, optionId }) => {
  let { variantIds } = await uploadVariantPictures({ pictures, variantId, optionId });
  if (!Array.isArray(variantIds)) {
    variantIds = [];
  }
  variantIds.push(variantId);

  //Save the variants that are affected for this option, also save the first
  // image as that may become the default image
  const uploadData = {
    affectedVariantsIds: variantIds,
    possibleDefaultImage: (pictures[0] || {}).pictureId,
    totalPictureCount: pictures.length
  };

  UPLOAD_VARIANT_HEADER.optionVariantsMap.set(optionId, uploadData);
};

export const isAnyColorNull = variantColorsData => {
  if (!Array.isArray(variantColorsData)) {
    return false;
  }
  return variantColorsData.some(({ rgbValue }) => !Array.isArray(rgbValue));
};

export const convertOptionsDataToProductOptionsFormat = modifiedOptionsData => {
  return modifiedOptionsData.map(({ id, label, rgb } = {}) => {
    const productOptionData = {
      optionId: id,
      optionValue: label
    };
    if (Array.isArray(rgb)) {
      productOptionData.rgbValue = rgb;
    }
    return productOptionData;
  });
};

export const getOptionsDataToSet = optionsDataArray => {
  if (!(Array.isArray(optionsDataArray) && optionsDataArray.length)) {
    return undefined;
  }

  return optionsDataArray;
};

export const processMoqForVariant = ({ variantMoq }) => {
  const moqData = {
    moq: null,
    moqFromProduct: false
  };

  if (typeof variantMoq === 'undefined' || variantMoq.minOrderQuantity === null) {
    return moqData;
  }

  moqData.moq = variantMoq.minOrderQuantity;
  moqData.moqFromProduct = variantMoq.entityType !== MOQ_VARIANT_ENTITY;
  return moqData;
};

const clearProcessVariantsResponse = () => {
  PRODUCT_VARIANT_LAST_FETCHED.ts = {};
};

registerCleanupHandler(clearProcessVariantsResponse);
