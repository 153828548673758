import React from 'react';
import UploadButtonsFooter from '../../UploadButtonsFooter';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

const EmptyProductListNormal = ({ toggleProductLibrary }) => {
  const { t } = getI18N();

  return (
    <div id={'NoProductListContainer'}>
      <div className={'noProducts'}>{t('no_products_created_yet_in_this_catalogue')}</div>
      <UploadButtonsFooter
        containerClassName={`uploadButtons`}
        toggleProductLibrary={toggleProductLibrary}
        disablebutton={false}
      />
    </div>
  );
};

const EmptyProductListSearch = () => {
  const { t } = getI18N();

  return (
    <div id={'NoProductListContainer'}>
      {t('could_not_find_products_matching_your_search_criteria')}
    </div>
  );
};

export default {
  NORMAL: EmptyProductListNormal,
  SEARCH: EmptyProductListSearch
};
