import React, { useEffect, useState } from 'react';
import CacheCallback from 'qs-helpers/CacheListenerCallback';
import {
  attachGoldRatesAndProductTypeListener,
  getGoldRatesAndProductType,
  getGoldRatesAndProductTypeFromCache,
  removeGoldRatesAndProductTypeListener
} from 'qs-data-manager/ProductDetails';
import { JEWELLERY } from '../JewelleryProductPrices/ProductTypeDetails';
import JewelleryProductPrices from '../JewelleryProductPrices';

export const ProductPrices = ({
  productId,
  parentProductId,
  activeProductIds,
  productDetails,
  isBulkEditing = '',
  priceAndDiscountRenderer = () => null
}) => {
  const [{ loading, data: goldRateAndProductType }, setGoldRatesAndProductTypeResponse] = useState({
    loading: false,
    err: false,
    refreshing: false,
    data: getGoldRatesAndProductTypeFromCache() || {}
  });

  useEffect(() => {
    const listener = (error, payload) => {
      const { err, loading, refreshing, data } = CacheCallback(error, payload);
      const updates = {};
      if (err) {
        updates.err = err;
        updates.loading = loading;
        return;
      }

      if (loading) {
        updates.loading = loading;
        updates.refreshing = refreshing;
        updates.err = err;
        setGoldRatesAndProductTypeResponse(prevState => {
          return {
            ...prevState,
            ...updates
          };
        });
        return;
      }

      if (data) {
        setGoldRatesAndProductTypeResponse(prevState => ({
          ...prevState,
          loading: false,
          refreshing: false,
          data
        }));
      }
    };

    if (!isBulkEditing) {
      attachGoldRatesAndProductTypeListener({ listener });
      getGoldRatesAndProductType();
    }

    return isBulkEditing
      ? () => {}
      : () => {
          removeGoldRatesAndProductTypeListener({ listener });
        };
  }, [isBulkEditing]);

  if (loading || productDetails.loading || !goldRateAndProductType) {
    return null;
  }

  const { productType } = goldRateAndProductType;
  const { productMetaFromRow } = productDetails;

  if (productType === JEWELLERY.id) {
    return (
      <JewelleryProductPrices
        activeProductId={productId}
        parentProductId={parentProductId}
        activeProductIds={activeProductIds}
        isBulkEditing={isBulkEditing}
        productMetaFromRow={productMetaFromRow}
        goldRateAndProductType={goldRateAndProductType}
      />
    );
  }

  return priceAndDiscountRenderer();
};
