import React, { useState, useEffect } from 'react';

import { ReactComponent as ChangeLayoutIcon } from 'qs-assets/Media/change_layout.svg';
import { ReactComponent as GridViewIcon } from 'qs-assets/Media/icon_grid_view.svg';
import { ReactComponent as ListViewIcon } from 'qs-assets/Media/icon_list_view.svg';
import { ReactComponent as MagzineViewIcon } from 'qs-assets/Media/icon_magazine_view.svg';
import {
  DialogLightTheme,
  PrimaryButton,
  SecondaryButton
} from 'qs-components/Common/LightThemeDialog';
import { TYPE_OF_LAYOUTS } from 'qs-helpers/Products/constants';
import CheckBox from 'qs-components/Common/CheckBox';
import CatalogueLib from 'qs-data-manager/Catalogues';
import { toggleGlobalLoader } from 'qs-helpers/index';
import toastr from 'toastr';

import './styles.scss';
import Loader from 'qs-components/Common/Loader';
import { getI18N } from 'qs-services/i18N';

const LayoutDialog = ({
  open,
  loading,
  errorFetchingData,
  selectedLayout,
  catalogueId,
  onClose
}) => {
  const [updateLayout, setUpdateLayout] = useState(selectedLayout);
  const { t } = getI18N();

  const onUpdateLayout = (event, field) => {
    event.preventDefault();
    setUpdateLayout(field);
  };

  useEffect(() => {
    setUpdateLayout(selectedLayout);
  }, [selectedLayout, open]);

  const updateListView = async event => {
    event.preventDefault();
    const loaderKey = Date.now();
    toggleGlobalLoader(loaderKey, true);
    try {
      const changes = {};
      changes.homeLayout = updateLayout;
      await CatalogueLib.changeCatalogueSettings({ catalogueId, changes });
      toastr.success(t('layout_updated_successfully'));
    } catch (error) {
      toastr.error(t('something_went_wrong_while_updating_layout'));
    } finally {
      toggleGlobalLoader(loaderKey, false);
      onClose();
    }
  };

  const getViewIcon = field => {
    switch (field) {
      case 'grid':
        return <GridViewIcon className={'viewIcon'} />;
      case 'list-view':
        return <ListViewIcon className={'viewIcon'} />;
      case 'list':
        return <MagzineViewIcon className={'viewIcon'} />;
      default:
        break;
    }
  };
  const renderLayoutData = () => {
    if (loading) {
      return <Loader size={'small'} />;
    }

    if (errorFetchingData) {
      return t('something_went_wrong_please_try_again');
    }

    return (
      <>
        {TYPE_OF_LAYOUTS.map(layout => (
          <div
            key={layout.field}
            className={'actionLayout'}
            onClick={event => onUpdateLayout(event, layout.field)}
          >
            <div className={`layoutIcon ${updateLayout === layout.field ? 'selectedLayout' : ''}`}>
              {getViewIcon(layout.field)}
              {updateLayout === layout.field && (
                <CheckBox
                  styleProps={{ margin: '-11px -5px -5px auto' }}
                  checked={true}
                  onCheckChanged={() => {}}
                />
              )}
            </div>
            <div className={'layoutType'}>
              <div className={'layoutTitle'}>{t(layout.title)}</div>
              <div className={'layoutInfo'}>{t(layout.info)}</div>
            </div>
          </div>
        ))}
        <div className={'layoutConfirmOptions'}>
          <SecondaryButton onClick={onClose}>{t('cancel')}</SecondaryButton>
          <PrimaryButton onClick={updateListView}>{t('set_layout')}</PrimaryButton>
        </div>
      </>
    );
  };
  return (
    <DialogLightTheme
      open={open}
      onClose={onClose}
      title={''}
      maxWidth={'xs'}
      onClick={event => {
        event.stopPropagation();
        event.preventDefault();
      }}
    >
      <div className={'actionLayoutDialogContainer'}>
        <div className={'titleContainer'}>
          <ChangeLayoutIcon className={'changeLayoutIcon'} />
          {t('change_layout')}
        </div>
        <div className={'actionLayoutOptions'}>{renderLayoutData()}</div>
      </div>
    </DialogLightTheme>
  );
};

export default LayoutDialog;
