import { connector } from 'qs-data-manager/ApiAndCacheConnector';
import CacheRequest from 'qs-data-manager/CacheRequest';
import { setProductMetaInCache } from 'qs-data-manager/Products';
import { roundNumberToGivenDecimals } from 'qs-helpers';
import { SAVE_BUTTON_META } from 'qs-helpers/Products/constants';
import { getGoldRatesAndProductTypeFromCache } from 'qs-data-manager/ProductDetails';

export const METAL_TYPES = {
  GOLD: 'GOLD',
  SILVER: 'SILVER'
};

export const METAL_UNITS = {
  KARAT: 'kt',
  PURITY: ''
};
export const SELECT_VALUE = {
  id: 'select_value',
  value: 'Select Value',
  displayValue: 'select_value'
};
export const JEWELLERY = {
  id: 'JEWELLERY'
};
export const BASIC = {
  id: 'BASIC'
};
export const NET_WEIGHT = {
  id: 'NET_WEIGHT'
};
export const GROSS_WEIGHT = {
  id: 'GROSS_WEIGHT'
};

export const LABELS = {
  [NET_WEIGHT.id]: 'net_weight',
  [GROSS_WEIGHT.id]: 'gross_weight'
};

export const PERCENT = {
  id: 'PERCENT',
  displayValue: 'percent'
};
export const PERGRAM = {
  id: 'PER_GRAM'
};
export const FLAT_AMOUNT = {
  id: 'ABSOLUTE',
  displayValue: 'flat_amount'
};
export const DIAMOND = {
  id: 'DIAMOND',
  displayValue: 'diamond'
};

export const STONE = {
  id: 'STONE',
  displayValue: 'stone'
};
export const STONE_KEYS = {
  TYPE: 'type',
  POSITION: 'position',
  NAME: 'name',
  SHAPE: 'shape',
  WEIGHT: 'weight',
  COLOR: 'color',
  RATE: 'rate',
  PIECES: 'pieces',
  COST: 'cost',
  CLARITY: 'clarity',
  AVERAGE_SIZE: 'average_size',
  DISCOUNT_TYPE: 'discountType',
  DISCOUNT_AMOUNT: 'discountAmount'
};

export const DIAMOND_DROPDOWN = {
  CLARITY: 'CLARITY',
  SHAPE: 'SHAPE',
  COLOR: 'COLOR'
};

export const STONE_TITLES = {
  DIAMOND_SHAPE: 'diamond_shape',
  DIAMOND_COLOR: 'diamond_color',
  DIAMOND_CLARITY: 'diamond_clarity',
  DIAMOND_WEIGHT: 'total_diamond_weight',
  DIAMOND_RATE: 'diamond_rate',
  DIAMOND_PIECES: 'diamond_pieces',
  DIAMOND_COST: 'diamond_cost',
  AVERAGE_SIZE: 'average_size',
  DIAMOND_DISCOUNT_AMOUNT: 'diamond_discount',
  STONE_SHAPE: 'stone_shape',
  STONE_NAME: 'stone_name',
  STONE_PIECES: 'stone_pieces',
  STONE_RATE: 'stone_rate',
  STONE_COST: 'stone_cost',
  STONE_WEIGHT: 'total_stone_weight',
  STONE_DISCOUNT_AMOUNT: 'stone_discount'
};

export const STONE_EXCEL_COLUMN_NAMES = {
  SKU: 'SKU',
  STONE_TYPE: 'STONE TYPE',
  STONE_SHAPE: 'STONE SHAPE',
  STONE_PCS: 'STONE PIECES',
  DIA_COLOR: 'DIA COLOR',
  DIA_CLARITY: 'DIA CLARITY',
  TOTAL_STONE_WEIGHT: 'TOTAL STONE WEIGHT',
  TOTAL_STN_COST: 'TOTAL STONE COST',
  DISCOUNT_PERCENT: 'Discount %',
  DISCOUNT_AMOUNT: 'Discount Amt',
  FINAL_COST: 'Final cost'
};

export const options = [
  {
    value: SELECT_VALUE.id,
    displayValue: SELECT_VALUE.displayValue
  },
  {
    value: '24',
    displayValue: '24kt'
  },
  {
    value: '22',
    displayValue: '22kt'
  },
  {
    value: '18',
    displayValue: '18kt'
  },
  {
    value: '12',
    displayValue: '12kt'
  }
];

export const updateCacheForJewelleryProductTypeDetails = ({ productTypeDetails, productId }) => {
  let key = `${connector.BASIC_INFO.cacheKey}${productId}`;
  let cache = CacheRequest.getCacheForKey(key);
  if (cache) {
    CacheRequest.setCacheForKey(key, {
      ...cache,
      productTypeDetails: productTypeDetails
    });
  }
  setProductMetaInCache({ productId, updates: productTypeDetails });
};

export const anyJewelleryRelatedFieldsIsSet = ({
  metalKaratQuality,
  grossWeight,
  netWeight,
  makingCharges,
  wastageCharges,
  otherChargesList
}) =>
  !!(
    metalKaratQuality !== SELECT_VALUE.id ||
    grossWeight ||
    netWeight ||
    makingCharges.makingCharge ||
    wastageCharges.wastageCharge ||
    (otherChargesList || []).length
  );

export const getSelectedMetalKaratPrice = ({
  productId,
  metalKaratQuality,
  selectedEntityType = METAL_TYPES.GOLD
}) => {
  if (metalKaratQuality === SELECT_VALUE.id) {
    return null;
  }

  const { entityRates = [] } = getGoldRatesAndProductTypeFromCache({ productId });

  const { rates } =
    entityRates.find(
      ({ entityType, entityLabel }) => entityType === selectedEntityType && entityLabel === 'METAL'
    ) || {};

  if (!rates) {
    return null;
  }

  const priceOfSelectedMetalKarat = rates.find(
    ({ quality }) => quality === Number(metalKaratQuality)
  );
  const { rate } = priceOfSelectedMetalKarat || {};
  return rate ? Number(rate) : null;
};
export const validateJewelleryRelatedFieldsInBasicInfo = ({
  isVariant = false,
  updatedEntityType,
  updatedGrossWeight,
  updatedNetWeight,
  updatedMakingCharge,
  updatedWastagePercent,
  updatedOtherChargesList,
  updatedMetalKaratQuality,
  getTheSelectedKaratPrice,
  checkIfAnyJewelleryRelatedFieldsAreSet
}) => savedData => {
  const grossWeightField = savedData[SAVE_BUTTON_META.PRODUCT_GROSS_WEIGHT.id];
  const netWeightField = savedData[SAVE_BUTTON_META.PRODUCT_NET_WEIGHT.id];
  const makingChargeInputField = savedData[SAVE_BUTTON_META.PRODUCT_MAKING_CHARGE.id] || {};
  const makingChargeTypeField = savedData[SAVE_BUTTON_META.PRODUCT_MAKING_CHARGE_TYPE.id] || {};
  const otherChargesField = savedData[SAVE_BUTTON_META.PRODUCT_OTHER_CHARGES.id];
  const metalKaratQualityField = savedData[SAVE_BUTTON_META.PRODUCT_ENTITY_QUALITY.id];

  // to prevent saving when user changes from percent to per gram and all other jewellery fields are empty
  if (
    !isVariant &&
    !(checkIfAnyJewelleryRelatedFieldsAreSet() || Object.keys(makingChargeTypeField).length)
  ) {
    return { valid: true };
  }
  if (typeof metalKaratQualityField !== 'number' && updatedMetalKaratQuality === null) {
    //0
    return {
      valid: false,
      errorMessage:
        updatedEntityType === METAL_TYPES.SILVER
          ? 'select_the_metal_purity_value'
          : 'select_the_metal_karat_value'
    };
  }
  if (typeof getTheSelectedKaratPrice !== 'number') {
    return {
      valid: false,
      errorMessage:
        updatedEntityType === METAL_TYPES.SILVER
          ? 'set_the_metal_purity_price'
          : 'set_the_metal_karat_price'
    };
  }
  if (
    typeof grossWeightField !== 'number' &&
    !updatedGrossWeight &&
    typeof netWeightField !== 'number' &&
    !updatedNetWeight
  ) {
    //1

    return {
      valid: false,
      errorMessage: 'gross_weight_and_net_weight_cannot_be_empty'
    };
  }
  //2
  if (grossWeightField === 0 || updatedGrossWeight === 0) {
    return {
      valid: false,
      errorMessage: 'gross_weight_cannot_be_zero'
    };
  }
  if (typeof grossWeightField !== 'number' && !updatedGrossWeight) {
    return {
      valid: false,
      errorMessage: 'gross_weight_cannot_be_empty'
    };
  }
  //3
  if (netWeightField === 0 || updatedNetWeight === 0) {
    return {
      valid: false,
      errorMessage: 'net_weight_cannot_be_zero'
    };
  }
  if (typeof netWeightField !== 'number' && !updatedNetWeight) {
    return {
      valid: false,
      errorMessage: 'net_weight_cannot_be_empty'
    };
  }
  // 4. changed value of gross weight or net weight or changed both the values and clicked on save
  if (
    (typeof grossWeightField === 'number' || typeof netWeightField === 'number') &&
    updatedGrossWeight &&
    updatedNetWeight
  ) {
    if (updatedGrossWeight < updatedNetWeight) {
      return {
        valid: false,
        errorMessage: 'gross_weight_cannot_be_less'
      };
    }
  }
  //5
  if (
    typeof updatedMakingCharge === 'number' &&
    typeof makingChargeInputField.makingCharge === 'number' &&
    updatedMakingCharge === 0
  ) {
    return {
      valid: false,
      errorMessage: 'making_charges_cannot_be_zero'
    };
  }

  //6
  if (typeof updatedWastagePercent === 'number') {
    if (updatedWastagePercent === 0) {
      return {
        valid: false,
        errorMessage: 'wastage_cannot_be_zero'
      };
    }

    if (updatedWastagePercent < 0) {
      return {
        valid: false,
        errorMessage: 'wastage_cannot_less_than_0'
      };
    }

    if (updatedWastagePercent > 100) {
      return {
        valid: false,
        errorMessage: 'wastage_cannot_more_than_100'
      };
    }
  }

  //7
  if (Array.isArray(updatedOtherChargesList) && Array.isArray(otherChargesField)) {
    for (let i = 0; i < updatedOtherChargesList.length; i += 1) {
      const { type, amount } = updatedOtherChargesList[i];
      if (type && amount === 0) {
        return {
          valid: false,
          errorMessage: 'other_charges_amount_cannot_be_zero'
        };
      }
      if (type.trim().length === 0 || !amount) {
        return {
          valid: false,
          errorMessage: 'other_charges_fields_cannot_be_empty'
        };
      }
    }
  }

  return { valid: true };
};

export const createANewOtherChargesList = otherCharges => {
  const copiedList = [...otherCharges] || [];
  return copiedList.map(otherCharge => {
    return { ...otherCharge };
  });
};

export const getAverageSize = addStoneDetails => {
  if (addStoneDetails.weight && addStoneDetails.pieces) {
    return roundNumberToGivenDecimals(
      Number(addStoneDetails.weight) / Number(addStoneDetails.pieces),
      3
    );
  }
  return '';
};

export const getStoneCost = addStoneDetails => {
  if (!addStoneDetails.rate) {
    return 0;
  }
  if (!addStoneDetails.weight) {
    return Number(addStoneDetails.rate);
  }
  return roundNumberToGivenDecimals(
    Number(addStoneDetails.weight) * Number(addStoneDetails.rate),
    2
  );
};
export const getUpdatedOptions = (arr, lowerCase) => {
  let updatedArr = [];
  (arr || []).forEach(singleOption => {
    const updatedRate = {
      value: lowerCase ? singleOption.quality.toLowerCase() : singleOption.quality,
      displayValue: lowerCase ? singleOption.quality.toLowerCase() : singleOption.quality
    };
    updatedArr.push(updatedRate);
  });
  return updatedArr;
};

export const showStoneValueDetailsName = (id, productStone) => {
  const stoneValue = productStone.find(stone => stone.id === id);
  if (!stoneValue) {
    return;
  }
  const { details = {} } = stoneValue;
  const { shape, color, clarity, pieces, weight } = details;
  const shapeValue = shape?.toLowerCase() || '';
  const weightValue = weight ? `${weight}ct` : '';

  let values = [];
  if (stoneValue.type === DIAMOND.id) {
    values = [shapeValue, color, clarity, pieces, weightValue];
  } else if (stoneValue.type === STONE.id) {
    values = [shapeValue, pieces, weightValue];
  }
  return values.filter(value => value).join('-');
};

export const validateInputValues = ({ t, name, addStoneDetails }) => {
  let errorFlag = false;
  let errorMessage = {};

  if (name === STONE.id && !addStoneDetails.name) {
    errorFlag = true;
    errorMessage = {
      ...errorMessage,
      name: t('please_enter_name_of_product_attribute', { productAttribute: t(name.toLowerCase()) })
    };
  }

  if (
    name === DIAMOND.id &&
    (!addStoneDetails.color || addStoneDetails.color === SELECT_VALUE.id.toUpperCase())
  ) {
    errorFlag = true;
    errorMessage = {
      ...errorMessage,
      color: t('please_select_color_of_product_attribute', {
        productAttribute: t(name.toLowerCase())
      })
    };
  }

  if (
    name === DIAMOND.id &&
    (!addStoneDetails.clarity || addStoneDetails.clarity === SELECT_VALUE.id.toUpperCase())
  ) {
    errorFlag = true;

    errorMessage = {
      ...errorMessage,
      clarity: t('please_select_clarity_of_product_attribute', {
        productAttribute: t(name.toLowerCase())
      })
    };
  }

  if (!Number(addStoneDetails.weight)) {
    errorFlag = true;
    errorMessage = {
      ...errorMessage,
      weight: t('please_enter_weight_of_product_attribute', {
        productAttribute: t(name.toLowerCase())
      })
    };
  }

  if (!Number(addStoneDetails.rate)) {
    errorFlag = true;
    errorMessage = {
      ...errorMessage,
      rate: t('please_enter_rate_of_product_attribute', { productAttribute: t(name.toLowerCase()) })
    };
  }

  if (addStoneDetails.discountType && addStoneDetails.discountAmount) {
    if (
      addStoneDetails.discountType === PERCENT.id &&
      !(addStoneDetails.discountAmount <= 100 && addStoneDetails.discountAmount > 0)
    ) {
      errorFlag = true;
      errorMessage = {
        ...errorMessage,
        discountAmount: 'discount_percent_should_be_between_0_and_100'
      };
    } else if (
      addStoneDetails.discountType === FLAT_AMOUNT.id &&
      Number(addStoneDetails.discountAmount) > getStoneCost(addStoneDetails)
    ) {
      errorFlag = true;
      errorMessage = {
        ...errorMessage,
        discountAmount: 'discount_amount_cannot_be_greater_than_cost'
      };
    }
  }
  return { errorFlag, errorMessage };
};

export const getFormattedStoneData = ({
  activeProductId,
  addStoneDetails,
  productStoneLength = 0
}) => ({
  productId: activeProductId,
  type: addStoneDetails.type,
  ...(addStoneDetails.id && { id: addStoneDetails.id }),
  position:
    typeof addStoneDetails.position === 'number' ? addStoneDetails.position : productStoneLength,
  details: {
    ...(addStoneDetails.name && { name: addStoneDetails.name }),
    shape: addStoneDetails.shape,
    pieces: addStoneDetails.pieces && Number(addStoneDetails.pieces),
    ...(addStoneDetails.color && { color: addStoneDetails.color }),
    ...(addStoneDetails.clarity && { clarity: addStoneDetails.clarity }),
    weight: addStoneDetails.weight && Number(addStoneDetails.weight),
    rate: addStoneDetails.rate && Number(addStoneDetails.rate),
    ...(addStoneDetails.discountAmount &&
      addStoneDetails.discountType && {
        discountType: addStoneDetails.discountType === FLAT_AMOUNT.id ? FLAT_AMOUNT.id : PERCENT.id,
        discountAmount: Number(addStoneDetails.discountAmount)
      })
  }
});

export const getFinalStonePrice = addStoneDetails => {
  const discountType = addStoneDetails.discountType;
  const discountAmount = Number(addStoneDetails.discountAmount);
  if (discountType && discountAmount) {
    if (discountType === PERCENT.id) {
      return roundNumberToGivenDecimals(
        getStoneCost(addStoneDetails) * ((100 - discountAmount) / 100),
        2
      );
    }
    return roundNumberToGivenDecimals(getStoneCost(addStoneDetails) - discountAmount, 2);
  }
  if (getStoneCost(addStoneDetails)) {
    return getStoneCost(addStoneDetails);
  }
  return 0;
};
