import React, { useState, useEffect } from 'react';
import logo from 'qs-assets/Media/logo.png';
import './styles.scss';
import GlobalLoader from '../GlobalLoader';
import {
  getCompanyMetaForUserBar,
  attachCompanyMetaListener,
  removeCompanyMetaListener
} from 'qs-data-manager/Company';
import {
  attachUserMetaListener,
  removeUserMetaListener,
  getUserMetaFromCache
} from 'qs-data-manager/User';
import CacheListenerCallback from 'qs-helpers/CacheListenerCallback';
import { getConnectedDomainData } from 'qs-helpers/Domain/ResponseProcessor';
import {
  attachDomainDataListner,
  getDomainDataFromCache,
  getDomainDataFromRemote,
  removeDomainDataListener
} from 'qs-data-manager/Domain/DomainCheck';
import { getI18N } from 'qs-services/i18N';
import { navigateToPath } from 'qs-data-manager';
import {
  ROUTE_MAP,
  SETTINGS_COMPONENT_ID_MAP,
  SETTINGS_COMPONENT_ROUTE_MAP
} from 'qs-helpers/CompanySettings/constants';
import { openPopup } from 'qs-utils';

export default () => {
  const [companyMeta, setCompanyMeta] = useState(() => {
    return getCompanyMetaForUserBar();
  });
  const [userMeta, setUserMeta] = useState(() => getUserMetaFromCache() || {});
  const [connectedDomain, setConnectedDomain] = useState('');

  // Effect to keep company data in real time
  useEffect(() => {
    const listener = (error, payload) => {
      const { err, loading, data } = CacheListenerCallback(error, payload);
      if (err || loading || !data) {
        return;
      }
      const companyMeta = {
        name: data.name
      };

      setCompanyMeta(companyMeta);
    };

    attachCompanyMetaListener(listener);

    return () => {
      removeCompanyMetaListener(listener);
    };
  });

  // Effect to keep user data in real time
  useEffect(() => {
    const listener = (error, payload) => {
      const { err, loading, data } = CacheListenerCallback(error, payload);
      if (err || loading || !data) {
        return;
      }
      const newUserMeta = {
        email: data.email,
        accountEmail: data.accountEmail
      };
      setUserMeta(newUserMeta);
    };

    attachUserMetaListener(listener);

    return () => {
      removeUserMetaListener(listener);
    };
  });

  useEffect(() => {
    if (getDomainDataFromCache() === null) {
      getDomainDataFromRemote();
    }
    const listener = (error, payload) => {
      const { err, loading, data } = CacheListenerCallback(error, payload);
      if (err || loading || !data) {
        return;
      }
      const connectedDomains = getConnectedDomainData(data);
      setConnectedDomain(connectedDomains.custom || connectedDomains.catalog || '');
    };

    attachDomainDataListner({ listener });

    return () => {
      removeDomainDataListener({ listener });
    };
  });

  const openCompanyStore = event => {
    event.preventDefault();
    if (!connectedDomain) {
      return navigateToPath(
        `/${ROUTE_MAP.ACCOUNT_SETTINGS}/${
          SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.DOMAIN_SETTINGS].ROUTE
        }`
      );
    }
    openPopup('https://' + connectedDomain, 'noopener=true');
  };

  const openNewUpdatesPage = event => {
    event.preventDefault();
    openPopup('https://updates.quicksell.co/', 'noopener=true');
  };

  const { t } = getI18N();

  return (
    <div id={'NavBarContainer'}>
      <div id={'NavBar'}>
        <div className={'quicksellLogo'}>
          <img src={logo} className="logo" alt="logo" />
          <span>QuickSell</span>
          <button className="viewStoreButton" onClick={openCompanyStore}>
            {t('view_store')}
          </button>
        </div>
        <div className="companyNameWithUpdates">
          {Object.keys(userMeta).length === 0 ? null : (
            <button className="newUpdates" onClick={openNewUpdatesPage}>
              {t('new_updates')}
            </button>
          )}
          <div className="companyName">
            <div className="name">{companyMeta.name}</div>
            <div className="email">{userMeta.accountEmail || userMeta.email}</div>
          </div>
        </div>
      </div>
      <GlobalLoader />
    </div>
  );
};
