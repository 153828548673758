import React from 'react';
import { FLAT_AMOUNT, PERCENT, STONE, STONE_KEYS, STONE_TITLES } from '../ProductTypeDetails';
import StoneInputField from '../StoneCharges/StoneInputField';
import StonesDropDown from '../StoneCharges/StonesDropDown';
import StoneTextField from '../StoneCharges/StoneTextField';
import { getI18N } from 'qs-services/i18N';

const Stone = ({
  addStoneDetails,
  setAddStoneDetails,
  errorMessage,
  setErrorMessage,
  renderCost,
  currencySymbol,
  showFinalStonePrice,
  showMandatoryText
}) => {
  const { t } = getI18N();
  return (
    <>
      <StoneTextField
        title={t(STONE_TITLES.STONE_NAME)}
        value={addStoneDetails.name}
        type={STONE.id}
        keyName={STONE_KEYS.NAME}
        setAddStoneDetails={setAddStoneDetails}
        mandatory
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      />
      <StoneTextField
        title={t(STONE_TITLES.STONE_SHAPE)}
        value={addStoneDetails.shape}
        type={STONE.id}
        keyName={STONE_KEYS.SHAPE}
        setAddStoneDetails={setAddStoneDetails}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      />
      <StoneInputField
        title={t(STONE_TITLES.STONE_PIECES)}
        value={addStoneDetails.pieces}
        type={STONE.id}
        keyName={STONE_KEYS.PIECES}
        setAddStoneDetails={setAddStoneDetails}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        integer
      />
      <StoneInputField
        title={t(STONE_TITLES.STONE_WEIGHT)}
        symbol={'ct'}
        value={addStoneDetails.weight}
        type={STONE.id}
        mandatory
        keyName={STONE_KEYS.WEIGHT}
        setAddStoneDetails={setAddStoneDetails}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        digitsAfterDecimal={3}
      />
      <StoneInputField
        title={t(STONE_TITLES.STONE_RATE)}
        value={addStoneDetails.rate}
        type={STONE.id}
        keyName={STONE_KEYS.RATE}
        setAddStoneDetails={setAddStoneDetails}
        mandatory
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        digitsAfterDecimal={3}
      />
      {renderCost({ title: t(STONE_TITLES.STONE_COST) })}
      <StoneInputField
        title={t(STONE_TITLES.STONE_DISCOUNT_AMOUNT)}
        value={addStoneDetails.discountAmount}
        discountType={addStoneDetails.discountType.toLowerCase()}
        currencySymbol={currencySymbol}
        type={STONE.id}
        keyName={STONE_KEYS.DISCOUNT_AMOUNT}
        setAddStoneDetails={setAddStoneDetails}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        digitsAfterDecimal={2}
      />
      <StonesDropDown
        options={[
          {
            value: PERCENT.id.toLowerCase(),
            displayValue: t(PERCENT.displayValue)
          },
          {
            value: FLAT_AMOUNT.id.toLowerCase(),
            displayValue: t(FLAT_AMOUNT.displayValue)
          }
        ]}
        value={addStoneDetails.discountType.toLowerCase()}
        type={STONE.id}
        keyName={STONE_KEYS.DISCOUNT_TYPE}
        setAddStoneDetails={setAddStoneDetails}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      />
      {showFinalStonePrice()}
      {showMandatoryText()}
    </>
  );
};

export default Stone;
