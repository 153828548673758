import React, { useContext, useState, useEffect, useCallback } from 'react';
import {
  getVariantsDataForProductFromCache,
  changeDefaultVariant
} from 'qs-data-manager/Variants/FetchVariants';
import { ActiveVariantId } from '../../context';
import { toggleGlobalLoader } from 'qs-helpers';
import toastr from 'toastr';
import './styles.scss';
import { getI18N } from 'qs-services/i18N';

export default ({ activeProductId }) => {
  const { t } = getI18N();
  const { activeVariantId } = useContext(ActiveVariantId);
  const [currentDefault, toggleCurrentDefault] = useState();

  const isActiveVariantDefault = useCallback(() => {
    const variantData = getVariantsDataForProductFromCache(activeProductId);
    if (!variantData) {
      return;
    }

    if (variantData.defaultVariantId === activeVariantId) {
      return true;
    }

    return false;
  }, [activeVariantId, activeProductId]);

  useEffect(() => {
    toggleCurrentDefault(isActiveVariantDefault());
  }, [isActiveVariantDefault]);

  const setAsDefault = async event => {
    event.preventDefault();
    const loaderKey = `setVariantAsDefault${Date.now()}`;
    toggleGlobalLoader(loaderKey, true);
    try {
      await changeDefaultVariant({ parentProductId: activeProductId, variantId: activeVariantId });
      toggleCurrentDefault(isActiveVariantDefault());
    } catch (setDefaultError) {
      toastr.error(t('something_went_wrong_while_setting_the_variant_as_default'));
    } finally {
      toggleGlobalLoader(loaderKey, false);
    }
  };

  return typeof currentDefault === 'boolean' && !currentDefault ? (
    <div className={'variantDefaultButton setAsDefaultContainer'}>
      <div className="setAsDefaultButton" onClick={setAsDefault}>
        {t('set_this_variant_as_default')}
      </div>
    </div>
  ) : null;
};
