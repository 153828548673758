import React from 'react';
import EditableField from '../EditableField';
import { getPriceFormattedComponent } from 'qs-helpers';
import './styles.scss';

export default ({ borderBottom = false, padding = true, rows = [] }) => {
  let borderClass = '';
  if (borderBottom) {
    borderClass = 'bottomBorderRow';
  }

  let paddingRowClass = '';
  if (padding) {
    paddingRowClass = 'paddingRow';
  }

  const renderValue = row => {
    const { negative = false, addCurrencyInfo = true, value } = row;
    if (addCurrencyInfo) {
      let negativeSign = '';
      if (negative) {
        negativeSign = '- ';
      }

      return `${negativeSign} ${getPriceFormattedComponent(value)}`.trim();
    }

    return value;
  };

  const renderFinalValue = row => {
    const { editable, valueContainerClass, value } = row;
    if (value === undefined || value === null) {
      return null;
    }

    if (!editable) {
      return <div className={valueContainerClass}>{renderValue(row)}</div>;
    }

    return (
      <EditableField classes={valueContainerClass} {...editable}>
        {renderValue(row)}
      </EditableField>
    );
  };

  const renderRowData = (row, key) => (
    <div className="overviewRowContainer" key={key}>
      <div className="orderOverviewDataContainer labelContainer">
        {typeof row.icon === 'function' ? row.icon({ iconClass: 'labelIcon' }) : null}
        <span className="ellipsis">{row.label}</span>
      </div>
      <div className="orderOverviewDataContainer">{renderFinalValue(row)}</div>
    </div>
  );

  return (
    <div className={`overviewColContainer ${borderClass} ${paddingRowClass}`}>
      {rows.map((row, index) => renderRowData(row, index))}
    </div>
  );
};
