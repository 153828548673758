import { PRODUCT_VARIANT_INFO, PRODUCT_VARIANT_META } from 'qs-api/Variants/ApiCacheConnector';
import CacheRequest from 'qs-data-manager/CacheRequest';
import {
  getBasicInfo,
  getProductStoneValuesFromCache,
  getProductTypeDetailsFromCache,
  isProductTypeDetailsChanged,
  setProductStoneDetails,
  updateProductStoneDetailsInCache
} from 'qs-data-manager/ProductDetails';
import { getCurrencySymbol } from 'qs-helpers';
import { updateVariantData } from 'qs-api/Variants/api';
import { reportError } from 'qs-helpers/ErrorReporting';
import { processMoqForVariant } from 'qs-helpers/Variants/ResponseProcessor';
import { getMoqForEntityFromCache, updateMoqForEntity } from '../Moq/MoqOperations';
import { MOQ_PRODUCT_ENTITY, MOQ_VARIANT_ENTITY } from 'qs-helpers/Moq/constants';
import { SAVE_BUTTON_META } from 'qs-helpers/Products/constants';
import {
  getFormattedStoneData,
  getSelectedMetalKaratPrice,
  METAL_TYPES,
  validateJewelleryRelatedFieldsInBasicInfo
} from 'qs-components/Catalogue/ProductDetailsScreen/ActiveTabMeta/BasicInfo/JewelleryProductPrices/ProductTypeDetails';
import { UPDATE_JEWELLERY_RATES } from 'qs-components/Catalogue/ProductDetailsScreen/ProductVariantView/reducer';
import { getI18N } from '../../i18N';

export const attachVariantInfoListener = ({ listener, variantId }) => {
  const key = `${PRODUCT_VARIANT_INFO.cacheKey}${variantId}`;
  CacheRequest.attachListener(key, listener);
};

export const removeVariantInfoListener = ({ listener, variantId }) => {
  const key = `${PRODUCT_VARIANT_INFO.cacheKey}${variantId}`;
  CacheRequest.removeListener(key, listener);
};

export const getVariantInfoFromRemote = ({ variantId }) => {
  const key = `${PRODUCT_VARIANT_INFO.cacheKey}${variantId}`;
  const apiName = PRODUCT_VARIANT_INFO.apiFunction;
  CacheRequest.makeRequest(key, apiName, {
    params: [variantId],
    options: {
      shouldNotStoreInNative: true
    }
  });
};

export const getVariantInfoFromCache = ({ activeVariantId }) => {
  const key = `${PRODUCT_VARIANT_INFO.cacheKey}${activeVariantId}`;
  // Explicitly return undefined, so that default variable assignment works
  return CacheRequest.getCacheForKey(key) || undefined;
};

export const setVariantInfoInCache = ({ activeVariantId, updates }) => {
  const existingVariantInfo = getVariantInfoFromCache({ activeVariantId }) || {};
  CacheRequest.setCacheForKey(`${PRODUCT_VARIANT_INFO.cacheKey}${activeVariantId}`, {
    ...existingVariantInfo,
    ...updates
  });
};

export const getVariantBasicInfoFromCache = ({ activeVariantId, activeProductId }) => {
  const basicInfo = {
    discounted_price: null,
    discountFromProduct: false,
    price: null,
    priceFromProduct: false,
    productTypeRateCharges: [],
    productTypeRateChargesFromProduct: false,
    productTypeDetails: null,
    productTypeDetailsFromProduct: false,
    name: null,
    nameFromProduct: false,
    sku: null,
    weight: null,
    weightFromProduct: false,
    description: null,
    descriptionFromProduct: false,
    currencySymbol: null
  };

  const variantInfo = getVariantInfoFromCache({ activeVariantId });
  const productInfo = getBasicInfo({ activeProductId });

  if (typeof variantInfo === 'undefined') {
    return {};
  }

  const productObj = productInfo || {};

  // Preserve 0 if it exists
  basicInfo.price = variantInfo.price;
  basicInfo.discounted_price = variantInfo.discounted_price;
  if (typeof basicInfo.price !== 'number' && typeof productObj.price === 'number') {
    basicInfo.price = productObj.price;
    basicInfo.priceFromProduct = true;

    // Inherit discounted price only if the variant does not have price set
    if (typeof basicInfo.discounted_price !== 'number' && typeof productObj.discount === 'number') {
      basicInfo.discounted_price = productObj.discount;
      basicInfo.discountFromProduct = true;
    }
  }

  basicInfo.name = variantInfo.name;
  if (!basicInfo.name && productObj.title) {
    basicInfo.name = productObj.title;
    basicInfo.nameFromProduct = true;
  }

  basicInfo.sku = variantInfo.sku;

  basicInfo.description = variantInfo.description;
  if (!basicInfo.description && productObj.description) {
    basicInfo.description = productObj.description;
    basicInfo.descriptionFromProduct = true;
  }

  basicInfo.weight = variantInfo.weight;
  if (typeof basicInfo.weight !== 'number' && typeof productObj.weight === 'number') {
    basicInfo.weight = productObj.weight;
    basicInfo.weightFromProduct = true;
  }

  basicInfo.productTypeRateCharges = variantInfo.productTypeRateCharges || [];
  const productTypeRateCharges =
    getProductStoneValuesFromCache({
      productId: activeProductId
    }) || [];

  if (!basicInfo.productTypeRateCharges.length && productTypeRateCharges.length) {
    basicInfo.productTypeRateCharges = productTypeRateCharges;
    basicInfo.productTypeRateChargesFromProduct = true;
  }

  basicInfo.productTypeDetails = variantInfo.productTypeDetails;
  const productTypeDetails = getProductTypeDetailsFromCache({
    productId: activeProductId
  });

  if (!basicInfo.productTypeDetails && productTypeDetails) {
    basicInfo.productTypeDetails = productTypeDetails;
    basicInfo.productTypeDetailsFromProduct = true;
  }

  const moqData = processMoqForVariant({
    variantMoq: getMoqForEntityFromCache({
      entityId: activeVariantId,
      entityType: MOQ_PRODUCT_ENTITY
    })
  });

  basicInfo.currencySymbol =
    getCurrencySymbol({ currencyCode: variantInfo.currency }) || productObj.currencySymbol;

  return {
    ...basicInfo,
    ...moqData
  };
};

const getProductTypeDetailsForVariantFromCache = ({ variantId, parentProductId }) => {
  const cache = getVariantBasicInfoFromCache({
    activeProductId: parentProductId,
    activeVariantId: variantId
  });

  if (!cache) {
    return;
  }

  return cache.productTypeDetails
    ? { ...cache.productTypeDetails }
    : {
        entityLabel: '',
        entityType: '',
        entityQuality: null,
        grossWeight: null,
        netWeight: null,
        makingChargeType: '',
        makingCharge: null,
        otherCharges: [],
        wastagePercent: null,
        wastageOn: ''
      };
};

const getVariantStoneValuesFromCache = ({ variantId, parentProductId }) => {
  const cache = getVariantBasicInfoFromCache({
    activeProductId: '',
    activeVariantId: variantId
  });

  if (!cache) {
    return;
  }
  return cache.productTypeRateCharges || [];
};

export const getProductTypeDetailsForProductOrVariantFromCache = ({
  productId,
  parentProductId,
  isBulkEditing
}) => {
  if (isBulkEditing) {
    return {
      entityLabel: '',
      entityType: '',
      entityQuality: null,
      grossWeight: null,
      netWeight: null,
      makingChargeType: '',
      makingCharge: null,
      otherCharges: [],
      wastagePercent: null,
      wastageOn: ''
    };
  }
  if (!parentProductId) {
    return getProductTypeDetailsFromCache({ productId });
  }
  return getProductTypeDetailsForVariantFromCache({
    variantId: productId,
    parentProductId
  });
};

export const getProductTypeRateChargesForProductOrVariantFromCache = ({
  productId,
  parentProductId,
  isBulkEditing
}) => {
  if (isBulkEditing) {
    return [];
  }
  if (!parentProductId) {
    // To fetch details if product or default structure in bulk editing mode
    return getProductStoneValuesFromCache({ productId });
  }

  return getVariantStoneValuesFromCache({
    variantId: productId,
    parentProductId
  });
};

const updateStoneDetailsForVariants = ({ variantId, productTypeRateCharges, price }) => {
  setVariantInfoInCache({
    activeVariantId: variantId,
    updates: {
      productTypeRateCharges,
      price
    }
  });
};

export const updateProductOrVariantStoneDetailsInCache = ({
  productId,
  parentProductId,
  productTypeRateCharges,
  price
}) => {
  if (!parentProductId) {
    updateProductStoneDetailsInCache({ productId, productTypeRateCharges, price });
    return;
  }
  updateStoneDetailsForVariants({
    variantId: productId,
    productTypeRateCharges,
    price
  });
};

export const getDefaultPictureIdOfVariantFromCache = ({ activeVariantId }) => {
  const variantInfo = getVariantInfoFromCache({ activeVariantId });
  if (!variantInfo || !variantInfo.default_picture_id) {
    return '';
  }
  return variantInfo.default_picture_id;
};

const updateInfoInRemoteAndCache = async ({ variantId, infoChanges }) => {
  if (!Object.keys(infoChanges).length) {
    return;
  }

  try {
    await updateVariantData({ variantId, updates: infoChanges });
  } catch (updateVariantError) {
    reportError(updateVariantError);
    throw updateVariantError;
  }

  setVariantInfoInCache({ activeVariantId: variantId, updates: infoChanges });

  const sharedMetaCacheKey = `${PRODUCT_VARIANT_META.cacheKey}`;
  const metaKey = `${sharedMetaCacheKey}${variantId}`;
  const metaCache = CacheRequest.getCacheForKey(metaKey);
  const newMetaCache = { ...metaCache };
  if (typeof infoChanges.price !== 'undefined') {
    newMetaCache.price = infoChanges.price;
  }
  if (typeof infoChanges.name !== 'undefined') {
    newMetaCache.name = infoChanges.name;
  }
  CacheRequest.setCacheForKey(metaKey, newMetaCache);
};

const changeVariantCacheAndUpdateRemote = async (meta = []) => {
  const updatePromises = meta.map(async ({ variantId, infoChanges, moqChange } = {}) => {
    if (!variantId) {
      return;
    }

    await Promise.all([
      updateInfoInRemoteAndCache({ variantId, infoChanges }),
      updateMoqForEntity({ entityId: variantId, entityType: MOQ_PRODUCT_ENTITY, moqChange })
    ]);
  });
  await Promise.all(updatePromises);
};

const processNumericValueForChanges = ({
  data,
  changesObj,
  dataKey,
  changesKey,
  originalKey,
  valueAccepted
}) => {
  if (data[dataKey] !== '') {
    const numVal = Number(data[dataKey]);
    if (numVal !== data[originalKey]) {
      changesObj[changesKey] = numVal;
      if (typeof valueAccepted === 'function') {
        valueAccepted();
      }
    }
    return;
  }

  //current data key is blank but original key is not blank, indicates a reset; send null
  if (data[originalKey] !== '') {
    changesObj[changesKey] = null;
  }
};

export const saveVariantProductTypeRateCharges = async ({ productId, productTypeRateCharges }) => {
  return Promise.all(
    productTypeRateCharges.map((stoneDetails, idx) =>
      setProductStoneDetails({
        productId,
        formData: getFormattedStoneData({
          activeProductId: productId,
          addStoneDetails: {
            ...stoneDetails,
            ...stoneDetails.details,
            position: idx,
            id: undefined
          },
          productStoneLength: idx
        })
      })
    )
  );
};

export const saveVariantChanges = async (variantUpdates = []) => {
  // forceUpdate: optional boolean value to save jewellery details forcefully,
  // can be used when savin details from product to variant
  let isValidData = true;
  const modifications = await Promise.all(
    variantUpdates.map(async ({ data, variantId, forceUpdate, updateData, variantInfoCache }) => {
      const infoChanges = {},
        moqChange = {};

      if (data) {
        // Add the title to the changes only if the title is not the same as the original title
        if (typeof data.title === 'string' && data.title !== data.originalTitle) {
          infoChanges.name = data.title;
        }

        if (typeof data.sku === 'string' && data.sku !== data.originalSku) {
          infoChanges.sku = data.sku;
        }

        // Add the price to the changes only if it is not the same as the original price
        processNumericValueForChanges({
          data,
          changesObj: infoChanges,
          dataKey: 'price',
          changesKey: 'price',
          originalKey: 'originalPrice'
        });

        processNumericValueForChanges({
          data,
          changesObj: infoChanges,
          dataKey: 'discount',
          changesKey: 'discountedPrice',
          originalKey: 'originalDiscount',
          valueAccepted: () => {
            // If the discounted value is being set then ensure that the price is also sent
            if (typeof infoChanges.price !== 'number') {
              infoChanges.price = data.originalPrice;
            }
          }
        });

        if (typeof data.description === 'string' && data.description !== data.originalDesc) {
          infoChanges.description = data.description;
        }

        processNumericValueForChanges({
          data,
          changesObj: infoChanges,
          dataKey: 'weight',
          changesKey: 'weight',
          originalKey: 'originalWeight'
        });

        processNumericValueForChanges({
          data,
          changesObj: moqChange,
          dataKey: 'moq',
          changesKey: 'minOrderQuantity',
          originalKey: 'originalMoq',
          valueAccepted: () => {
            // Value is being reset update cache to reflect that the entity is product
            if (typeof moqChange.minOrderQuantity !== 'number') {
              moqChange.entityType = MOQ_PRODUCT_ENTITY;
            } else {
              moqChange.entityType = MOQ_VARIANT_ENTITY;
            }
          }
        });

        const productTypeDetailsChanged =
          forceUpdate ||
          isProductTypeDetailsChanged({
            productTypeDetails: data.productTypeDetails,
            originalProductTypeDetails: data.originalProductTypeDetails
          });

        if (productTypeDetailsChanged) {
          infoChanges.productTypeDetails = data.productTypeDetails;

          const {
            entityQuality,
            grossWeight,
            makingCharge,
            makingChargeType,
            netWeight,
            entityType,
            entityLabel,
            otherCharges,
            wastagePercent,
            wastageOn
          } = data.productTypeDetails;

          // set default values if not available
          if (!entityLabel) {
            infoChanges.productTypeDetails.entityLabel = 'METAL';
          }

          if (!entityType) {
            infoChanges.productTypeDetails.entityType = METAL_TYPES.GOLD;
          }

          const { productTypeRateChargesFromProduct } = variantInfoCache || {};

          const { valid, errorMessage } = validateJewelleryRelatedFieldsInBasicInfo({
            isVariant: true,
            updatedGrossWeight: grossWeight,
            updatedNetWeight: netWeight,
            updatedMakingCharge: makingCharge,
            updatedMetalKaratQuality: entityQuality,
            updatedOtherChargesList: otherCharges,
            updatedWastagePercent: wastagePercent,
            updatedWastageOn: wastageOn,
            getTheSelectedKaratPrice: getSelectedMetalKaratPrice({
              productId: variantId,
              metalKaratQuality: entityQuality,
              selectedEntityType: entityType
            }),
            checkIfAnyJewelleryRelatedFieldsAreSet: () => false
          })({
            [SAVE_BUTTON_META.PRODUCT_GROSS_WEIGHT.id]: grossWeight,
            [SAVE_BUTTON_META.PRODUCT_NET_WEIGHT.id]: netWeight,
            [SAVE_BUTTON_META.PRODUCT_MAKING_CHARGE.id]: makingCharge,
            [SAVE_BUTTON_META.PRODUCT_MAKING_CHARGE_TYPE.id]: makingChargeType,
            [SAVE_BUTTON_META.PRODUCT_OTHER_CHARGES.id]: otherCharges,
            [SAVE_BUTTON_META.PRODUCT_ENTITY_QUALITY.id]: entityQuality,
            [SAVE_BUTTON_META.PRODUCT_WASTAGE_CHARGE.id]: wastagePercent,
            [SAVE_BUTTON_META.PRODUCT_WASTAGE_CHARGE_TYPE.id]: wastageOn
          });

          const { t } = getI18N();

          if (!valid && errorMessage) {
            isValidData = false;
            alert(t(errorMessage));
            return;
          }
          if (productTypeRateChargesFromProduct) {
            const stones = await saveVariantProductTypeRateCharges({
              productId: variantId,
              productTypeRateCharges: data.productTypeRateCharges
            });
            if (typeof updateData === 'function') {
              updateData({
                type: UPDATE_JEWELLERY_RATES,
                productTypeRateCharges: stones
              });
            }
          }
        }
      }

      return { variantId, infoChanges, moqChange };
    })
  );

  if (!isValidData) {
    return false;
  }

  await changeVariantCacheAndUpdateRemote(modifications);
  return true;
};
