import React, { useRef, useState } from 'react';
import toastr from 'toastr';
import { getActiveCatalogueId } from 'qs-data-manager/Catalogues';
import { toggleGlobalLoader } from 'qs-helpers';
import { ReactComponent as UploadIcon } from 'qs-assets/Media/plus.svg';
import { filterImagesAndVideoFromFiles } from 'qs-helpers/FileUploads/ProcessUploadedFiles';
import { getActiveProductId, uploadPicturesToProduct } from 'qs-data-manager/Products';
import { uploadVideosToProduct } from 'qs-data-manager/Products/VideoUpload';
import { PRODUCT_VIDEOS_LIMIT_REACHED } from 'qs-helpers/ProcessVideo/VideosProcessingHelper';
import ErrorPopup from 'qs-components/Common/ErrorPopup';
import FeatureModal from 'qs-components/Common/FeatureModal';
import { getPaidFeatures } from 'qs-components/Common/FeatureModal/helper';
import './styles.scss';
import { getI18N } from 'qs-services/i18N';

export default ({ classes }) => {
  const pickerRef = useRef(null);
  const [noImage, setNoImage] = useState(false);
  const [isFeatureAllowed, setIsFeatureAllowed] = useState(false);
  const planFeature = getPaidFeatures().PRODUCT_VIDEO;
  const errorMessage = useRef();
  const { t } = getI18N();

  const onUpload = async e => {
    e.stopPropagation();
    e.preventDefault();
    const productId = getActiveProductId();

    const { mediaFiles, error } = await filterImagesAndVideoFromFiles(e.target.files);
    let videoMessage;
    // Important. This should be done once the files have been processed.
    // If nullified before that, then the files in the event are lost
    pickerRef.current.value = null;

    if (error || mediaFiles.length <= 0) {
      errorMessage.current = error;
      setNoImage(true);
      return;
    }

    const loaderKey = `uploadPicturesToProduct${Date.now()}`;
    toggleGlobalLoader(loaderKey, true);

    try {
      const videoFiles = mediaFiles.filter(media => /^video/.test(media.type));
      const imageFiles = mediaFiles.filter(media => /^image/.test(media.type));

      if (Object.keys(videoFiles).length > 0) {
        const {
          isAllowed: { allowed }
        } = planFeature;
        if (!allowed) {
          setIsFeatureAllowed(!allowed);
          return;
        } else {
          videoMessage = await uploadVideosToProduct({
            videos: videoFiles,
            productId,
            catalogueId: getActiveCatalogueId()
          });

          if (videoMessage === PRODUCT_VIDEOS_LIMIT_REACHED) {
            errorMessage.current = 'cannot_upload_more_than_1_video_per_product';
            setNoImage(true);
            return;
          }
        }
      }

      if (Object.keys(imageFiles).length > 0) {
        await uploadPicturesToProduct({
          images: imageFiles,
          productId,
          catalogueId: getActiveCatalogueId()
        });
      }
    } catch (uploadError) {
      toastr.error(t('something_went_wrong_while_uploading_media_to_the_product'));
    } finally {
      toggleGlobalLoader(loaderKey, false);
    }
  };

  return (
    <>
      <div className={`imagesUploadButton ${classes || ''}`}>
        <UploadIcon className={'uploadIcon'} />
        <input
          ref={pickerRef}
          type="file"
          name="ProductPictures"
          accept="image/*,video/*"
          multiple
          onChange={onUpload}
          className={'filePicker'}
        />
      </div>
      {isFeatureAllowed && (
        <FeatureModal currentPlanFeature={planFeature} onClose={() => setIsFeatureAllowed(false)} />
      )}
      {noImage && (
        <ErrorPopup
          showContactSupport={false}
          onClose={() => setNoImage(false)}
          title="Upload Media"
        >
          {errorMessage.current ? t(errorMessage.current) : t('no_media_files_found')}
        </ErrorPopup>
      )}
    </>
  );
};
