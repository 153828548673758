import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { EXCEL_UPLOAD_META } from 'qs-data-manager/Catalogues';
import { getProductMetaFromCSV } from 'qs-data-manager/Products';
import {
  getCustomProductPropertiesIdMap,
  getMappingOnIds,
  getPreviewFromExistingMapping,
  lazyAutoMapColumns,
  onSelectFromDropDown,
  unSelectFromDropDown
} from 'qs-helpers/CSVUploader';
import {
  fetchCustomProductProperties,
  getCachedCustomPropertiesData,
  handleCustomProductPropertiesCleanup
} from 'qs-helpers/CSVUploader/DataQueryHelper';
import useMakeQuery from 'qs-hooks/useMakeQuery';
import ColumnMapper from './ColumnMapper';
import ExcelFooter from './ExcelFooter';
import ExcelMapperAlert from './ExcelMapperAlert';
import ProductPreview from './ProductPreview';
import './styles.scss';

let clickedOnHelp = false;

export default ({
  csvData,
  columnNames,
  onCancel,
  existingColumnMap = {},
  onNext: parentOnNext
}) => {
  const [showDialogue, setShowDialogue] = useState(false);
  const [columnNameDetailsMap, setColumnNameDetailsMap] = useState(existingColumnMap);

  const [
    {
      loading: customPropertiesLoading,
      refreshing: customPropertiesRefreshing,
      error: customPropertiesError,
      data: customPropertiesData
    }
  ] = useMakeQuery({
    actionHandler: fetchCustomProductProperties,
    cleanupHandler: handleCustomProductPropertiesCleanup,
    cachedDataHandler: getCachedCustomPropertiesData
  });

  const previousLoading = useRef(customPropertiesLoading);

  //Based on the dynamically generated data create the final map of excel components and their data
  const savedExcelComponentMap = useMemo(() => {
    return {
      ...EXCEL_UPLOAD_META,
      ...getCustomProductPropertiesIdMap({
        data: customPropertiesData,
        loading: customPropertiesLoading,
        refreshing: customPropertiesRefreshing,
        error: customPropertiesError
      })
    };
  }, [
    customPropertiesData,
    customPropertiesLoading,
    customPropertiesRefreshing,
    customPropertiesError
  ]);

  const row = useMemo(() => csvData[1], [csvData]);

  const [previewMeta, setPreviewMeta] = useState(
    getPreviewFromExistingMapping({
      existingColumnMap,
      columnNames,
      row,
      excelComponentMap: savedExcelComponentMap
    })
  );

  const setPreview = useCallback(
    ({ columnNameDetailsMap }) => {
      const reverseMapping = getMappingOnIds({ columnNameDetailsMap });
      const newPreview = {};
      Object.keys(reverseMapping).forEach(fieldId => {
        const meta = getProductMetaFromCSV(
          {
            headers: columnNames,
            componentCSVMapping: reverseMapping,
            row,
            fieldId,
            excelComponentMap: savedExcelComponentMap
          },
          { acceptInvalidImages: true }
        );
        if (reverseMapping[fieldId] && reverseMapping[fieldId].length) {
          newPreview[fieldId] = meta;
        }
      });
      setPreviewMeta(newPreview);
    },
    [row, columnNames, savedExcelComponentMap]
  );

  // Lazily load excel mapping for those fields that are obtained from API call, currently
  // handles only custom fields
  useEffect(() => {
    // Loading state hasn't changed or previous loading value was falsy, no need to compute the data
    if (previousLoading.current === customPropertiesLoading || !previousLoading.current) {
      previousLoading.current = customPropertiesLoading;
      return;
    }

    previousLoading.current = customPropertiesLoading;
    // Loader is now false, earlier it was true, process the data. Custom fields data
    // will be updated whenever loader changes state, hence savedExcelComponentMap
    // will also change and the below call would be required
    const updatedColumnNameMap = lazyAutoMapColumns({
      columnNames,
      excelComponents: Object.keys(EXCEL_UPLOAD_META)
        .map(meta => {
          const { id, hasSubcomponents } = EXCEL_UPLOAD_META[meta];
          if (hasSubcomponents) {
            return id;
          }
          return null;
        })
        .filter(Boolean),
      excelComponentMap: savedExcelComponentMap
    });

    setColumnNameDetailsMap(prevState => {
      const newColumnNameDetailsMap = { ...prevState, ...updatedColumnNameMap };
      setPreview({ columnNameDetailsMap: newColumnNameDetailsMap });
      return newColumnNameDetailsMap;
    });
  }, [customPropertiesLoading, savedExcelComponentMap, columnNames, setPreview]);

  const onChange = (e, { multiple, column: columnName }) => {
    if (clickedOnHelp) {
      clickedOnHelp = false;
      return;
    }

    const { value } = e.target;
    const newValues = multiple ? value : value ? [value] : [];

    setColumnNameDetailsMap(prevState => {
      const newColumnNameDetailsMap = { ...prevState };

      Object.keys(newColumnNameDetailsMap[columnName] || {}).forEach(id => {
        if (newValues.indexOf(id) < 0) {
          unSelectFromDropDown(id);
        }
      });

      delete newColumnNameDetailsMap[columnName];

      if (newValues.length) {
        newValues.forEach(value => {
          const { acceptsMultipleColumns, parentId } = savedExcelComponentMap[value];

          if (!acceptsMultipleColumns) {
            onSelectFromDropDown(value, columnName);
          }

          if (!newColumnNameDetailsMap[columnName]) {
            newColumnNameDetailsMap[columnName] = {};
          }
          newColumnNameDetailsMap[columnName][value] = { parentId };
        });
      }

      setPreview({ columnNameDetailsMap: newColumnNameDetailsMap });

      return newColumnNameDetailsMap;
    });
  };

  const dismissModal = () => {
    clickedOnHelp = false;
    setShowDialogue(false);
  };

  const showModal = () => {
    clickedOnHelp = true;
    setShowDialogue(true);
  };

  const onNext = () => {
    if (
      (previewMeta[EXCEL_UPLOAD_META.COLORS.id] || previewMeta[EXCEL_UPLOAD_META.SIZES.id]) &&
      !previewMeta[EXCEL_UPLOAD_META.PARENT_SKU.id]
    ) {
      setShowDialogue(true);
      return;
    }

    parentOnNext({ columnNameDetailsMap, excelComponentMap: savedExcelComponentMap });
  };

  /*
    TODO: make the modal containers use common backdrop and make the containers common.
  */

  return (
    <div id={'excel-column-mapper-modal'}>
      <div className={'column-mapper-container'}>
        <div id={'column-mapper-preview-container'}>
          <ColumnMapper
            csvData={csvData}
            columnNames={columnNames}
            excelComponentMap={savedExcelComponentMap}
            columnNameDetailsMap={columnNameDetailsMap}
            onChange={onChange}
            showDialogue={showModal}
          />
          <ProductPreview previewMeta={previewMeta} excelComponentMap={savedExcelComponentMap} />
        </div>
        <ExcelFooter
          onCancel={onCancel}
          onNext={onNext}
          nextDisabled={!Object.keys(columnNameDetailsMap).length}
        />
      </div>
      <ExcelMapperAlert
        showDialogue={showDialogue}
        dismissModal={dismissModal}
        id={EXCEL_UPLOAD_META.PARENT_SKU.id}
      />
    </div>
  );
};
