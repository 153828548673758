import React from 'react';
import { ReactComponent as CloseIcon } from 'qs-assets/Media/close.svg';
import './styles.scss';

export default ({ didError, error, closeErrorModal, showSupportButton }) => {
  if (!didError) {
    return null;
  }

  return (
    <div id={'LoginFailedContainer'}>
      <div className={'LoginFailed'}>
        <div className={'title'}> {error.title} </div>
        <div className={'description'}> {error.description} </div>

        {showSupportButton && (
          <a className={'contactSupport'} href="mailto:support@quicksell.co?subject=Login Failed">
            Contact support
          </a>
        )}
        <div onClick={closeErrorModal} className={'closeIconContainer'}>
          <CloseIcon className={'closeIcon'} />
        </div>
      </div>
    </div>
  );
};
