export const handleGalleryData = data => {
  if (data) {
    return data.map(inquiry => {
      const pictureData = {
        prepared: false,
        error: true,
        id: '',
        url: ''
      };

      if (inquiry.product) {
        pictureData.id = inquiry.product.id;
        pictureData.url = inquiry.product.pictureUrl;
        pictureData.prepared = true;
        pictureData.error = false;
      }

      return pictureData;
    });
  }
};
