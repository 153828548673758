import React, { useContext, useEffect } from 'react';
import { getNextEligibleImage } from 'qs-data-manager/ProductDetails';
import BasicInfo from './BasicInfo';
import ProductVariantInventory from './ProductVariantInventory';
import PrivateNotes from './PrivateNotes';
import Variants from './Variants';
import CacheListenerCallback from 'qs-helpers/CacheListenerCallback';
import {
  getImagesStatus,
  attachBasicInfoListener,
  removeBasicInfoListener
} from 'qs-data-manager/ProductDetails';
import { UPDATE_IMAGE_DATA, UPDATE_DEFAULT_IMAGE, SET_CURRENT_AS_DEFAULT_IMAGE } from '../reducer';
import { CurrentDefaultImageContext } from '../context';
import { removeProductMetaListener, attachProductMetaListener } from 'qs-data-manager/Products';
import { updateDefaultImageBasicInfo } from 'qs-product-operations/Helper/ProductBasicInfoHelper';
import { hasImageChanged } from 'qs-helpers/PicturesProcessingHelper';
import CustomFields from '../CustomFields';
import { BOTTOM_SHEET_TABS } from 'qs-helpers/Products/constants';
import './styles.scss';

export default ({
  activeTab,
  activeProductIds,
  isBulkEditing,
  containerRef,
  contentContainerRef,
  activeProductId,
  showProductImage,
  scrollToTop,
  reloadVariants
} = {}) => {
  // Listen to changes to the product images and the default image
  const { currentImageMeta, setNewImageData } = useContext(CurrentDefaultImageContext);
  const { currentImageData = {}, defaultImageId } = currentImageMeta;

  useEffect(() => {
    //nothing to do in case of bulk edits
    if (isBulkEditing) {
      return;
    }

    const listener = (error, payload) => {
      const { err, loading, data } = CacheListenerCallback(error, payload);
      if (err || loading || !data) {
        return;
      }

      // Default picture Id changed update the context
      if (data.pictureId !== defaultImageId) {
        setNewImageData({
          type: UPDATE_DEFAULT_IMAGE,
          newImageData: getImagesStatus({ activeProductIds: [activeProductId] })[0]
        });
      }

      updateDefaultImageBasicInfo(data);
    };

    attachProductMetaListener({ listener, productId: activeProductId });
    return () => removeProductMetaListener({ listener, productId: activeProductId });
  }, [activeProductId, defaultImageId, isBulkEditing, setNewImageData]);

  useEffect(() => {
    //nothing to do in case of bulk edits
    if (isBulkEditing) {
      return;
    }

    const listener = (error, payload) => {
      const { err, loading, data } = CacheListenerCallback(error, payload);
      if (err || loading || !data) {
        return;
      }

      const { pictures, videos } = data;
      if (!pictures) {
        return;
      }

      /*
        If product's meta data is not yet loaded, then the default pictureId will not be set.
        If the basic info's response has arrived but the default image has still not been set,
        then read the default image data from the response and set it.
      */
      if (!currentImageData.id) {
        const defaultImageData = data.pictures[data.default_picture_id];
        //The default image is not present in the pictures object, do not process anything
        if (!defaultImageData) {
          return;
        }

        const newImageData = {
          id: defaultImageData.id,
          prepared: defaultImageData.prepared,
          error: defaultImageData.error,
          url: defaultImageData.url
        };
        setNewImageData({
          type: SET_CURRENT_AS_DEFAULT_IMAGE,
          newImageData
        });
        return;
      }

      //Pictures array updated, update the current image if it has changed
      let currentPicture = Object.values(pictures).find(
        existingPicture => existingPicture.id === currentImageData.id
      );

      const videoCurrent = !!videos[currentImageData.id];

      // Current picture is not present in the updated pictures array
      // Update the current picture data to the next image from the
      // available images
      if (!currentPicture && !videoCurrent) {
        setNewImageData({
          type: UPDATE_IMAGE_DATA,
          newImageData: getNextEligibleImage(activeProductId)
        });
        return;
      }

      // If the current single image view is of video.
      if (videoCurrent) {
        currentPicture = videos[currentImageData.id];
        setNewImageData({
          type: UPDATE_IMAGE_DATA,
          newImageData: currentPicture
        });
        return;
      }

      //prepared or error state changed, propogate the update
      if (hasImageChanged(currentPicture, currentImageData) && !videoCurrent) {
        setNewImageData({
          type: UPDATE_IMAGE_DATA,
          newImageData: currentPicture
        });
      }
    };

    attachBasicInfoListener({ listener, productId: activeProductId });

    return () => removeBasicInfoListener({ listener, productId: activeProductId });
  }, [activeProductId, currentImageData, isBulkEditing, setNewImageData]);

  if (activeTab === BOTTOM_SHEET_TABS.BASIC_INFO) {
    return (
      <BasicInfo
        activeProductIds={activeProductIds}
        isBulkEditing={isBulkEditing}
        activeProductId={activeProductId}
        scrollToTop={scrollToTop}
      />
    );
  }

  if (activeTab === BOTTOM_SHEET_TABS.CUSTOM) {
    return <CustomFields activeProductIds={activeProductIds} activeProductId={activeProductId} />;
  }

  if (activeTab === BOTTOM_SHEET_TABS.VARIANTS) {
    //Wait until both the containers are rendered and available
    if (!(containerRef && contentContainerRef)) {
      return null;
    }

    return (
      <Variants
        contentContainerRef={contentContainerRef}
        containerRef={containerRef}
        activeProductId={activeProductId}
        reloadVariants={reloadVariants}
      />
    );
  }

  if (activeTab === BOTTOM_SHEET_TABS.INVENTORY) {
    return (
      <ProductVariantInventory
        showProductImage={showProductImage}
        isBulkEditing={isBulkEditing}
        activeProductId={activeProductId}
        activeProductIds={activeProductIds}
      />
    );
  }

  if (activeTab === BOTTOM_SHEET_TABS.PRIVATE_NOTES) {
    return (
      <PrivateNotes
        isBulkEditing={isBulkEditing}
        activeProductId={activeProductId}
        activeProductIds={activeProductIds}
      />
    );
  }
};
