import { WindowPostMessageProxy } from 'window-post-message-proxy';

export const POST_MESSAGE_SLUGS = {
  Show: 'show',
  Hide: 'hide',
  ProductType: 'product-type',
  ProductLibrary: 'product-library',
  ProductSelected: 'product-selected',
  ProductLinkLibrary: 'product-link-library',
  ProductLinkSelected: 'product-link-selected',
  AddCatalogueBannerDialog: 'add-catalogue-banner-dialog',
  RefreshToken: 'refresh-token',
  CustomHtmlComponent: 'custom-html-component',
  CustomHtmlComponentSelected: 'custom-html-component-selected'
};

export const windowPostMessageProxy = new WindowPostMessageProxy({
  name: 'custompages',
  suppressWarnings: true
});
