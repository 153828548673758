import React, { useContext, useState } from 'react';
import { ActiveOrderMeta } from '../../../context';
import { getOrderMetaFromCache } from 'qs-data-manager/Orders/OrdersOperations';
import useMakeQuery from 'qs-hooks/useMakeQuery';
import {
  fetchOrderShippingHandler,
  handleOrderShippingChange,
  getCachedOrderShippingData
} from 'qs-helpers/Orders/DataQueryHelper';
import { ActiveOrderView } from '../../context';
import { getPaymentModeTextFromEnum } from 'qs-helpers/Orders/ResponseProcessor';
import { getCompanyCurrencySymbol } from 'qs-data-manager/Company';
import moment from 'moment';
import OrderStatus from './OrderStatus';
import { ReactComponent as CallIcon } from 'qs-assets/Media/phone_disconnected_icon.svg';
import Loader from 'qs-components/Common/Loader';
import './styles.scss';
import { PAYMENT_MODES, SHIPPING_PROVIDERS } from 'qs-helpers/Orders/constants';
import OrderNoteComponent from '../OrderNote';
import { getI18N } from 'qs-services/i18N';

export default ({
  paymentMode,
  paymentCaptured,
  amountCaptured,
  additionalCheckoutFields,
  businessName,
  taxNumber,
  shippingConfig,
  additionalPaymentMode,
  pendingOrderAmount
}) => {
  const {
    activeOrderMeta: { selectedOrderId }
  } = useContext(ActiveOrderMeta);

  const {
    activeOrderView: { headerView }
  } = useContext(ActiveOrderView);

  const [statusChangeProg, setStatusChangeProg] = useState(false);

  const [{ loading, error, data: shippingData }] = useMakeQuery({
    changeDependancy: [selectedOrderId],
    actionHandler: fetchOrderShippingHandler,
    cleanupHandler: handleOrderShippingChange,
    cachedDataHandler: getCachedOrderShippingData,
    globalLoader: false
  });

  const { t } = getI18N();

  const orderMeta = getOrderMetaFromCache({ orderId: selectedOrderId });
  if (!orderMeta) {
    return null;
  }

  const renderSectionData = ({
    children,
    title,
    titleSectionChildren = null,
    titleStyle,
    titleClass = ''
  }) => (
    <div className="sectionContainer">
      <div className="titleContainer">
        <span className={`title ${titleClass}`} style={titleStyle}>
          {title}
        </span>
        {titleSectionChildren}
      </div>
      {children}
    </div>
  );

  const getShippingDataComponent = () => {
    if (error) {
      return (
        <span className="errorShippingContainer orderMetaText">
          Something went wrong while fetching the shipping info
        </span>
      );
    }

    if (loading) {
      return (
        <>
          <div className="dataPlaceholder orderMetaText shippingShimmer" />
          <div className="dataPlaceholder orderMetaText shippingShimmer" />
        </>
      );
    }

    const { formattedAddress } = shippingData;
    const { visitorName, visitorPhone, visitorEmail } = orderMeta;
    let finalFormattedAddress = null;
    if (typeof formattedAddress === 'string' && formattedAddress.trim() !== '') {
      finalFormattedAddress = formattedAddress.replace(/\n/g, ', ');
    }

    return (
      <>
        {visitorName && <span className="orderMetaText">{visitorName}</span>}
        {businessName && businessName.value ? (
          <span className="orderMetaText">{businessName.value}</span>
        ) : null}
        {finalFormattedAddress && <span className="orderMetaText">{finalFormattedAddress}</span>}
        {visitorEmail && <span className="orderMetaText">{visitorEmail}</span>}
        {visitorPhone && (
          <div className="orderMetaText phoneContainer">
            <span>{visitorPhone}</span>
            <CallIcon className="callIcon" />
          </div>
        )}
        {taxNumber && taxNumber.value ? (
          <span className="orderMetaText">{taxNumber.value}</span>
        ) : null}
      </>
    );
  };

  const getAdditionalCheckoutFieldsComponent = () => {
    if (!Array.isArray(additionalCheckoutFields) || additionalCheckoutFields.length === 0) {
      return null;
    }
    return (
      <div className="metaDataRow additionalInformation">
        {renderSectionData({
          title: 'ADDITIONAL INFORMATION',
          children: (
            <>
              {additionalCheckoutFields.map(additionField => (
                <span key={additionField.id} className="orderMetaText">
                  {additionField.name}
                  {' : '}
                  {additionField.value}
                </span>
              ))}
            </>
          )
        })}
      </div>
    );
  };

  const getPaymentComponent = () => {
    if (!paymentMode) {
      return null;
    }
    return renderSectionData({
      title: 'PAYMENT MODE',
      children: (
        <span className="orderMetaText">
          {getPaymentModeTextFromEnum(paymentMode, additionalPaymentMode)}
        </span>
      )
    });
  };

  const getPaymentCapturedComponent = () => {
    if (!paymentCaptured) {
      return null;
    }

    const companyCurrencySymbol = getCompanyCurrencySymbol();

    if (paymentMode === PAYMENT_MODES.PARTIAL_COD) {
      const partialCodMetadata = [
        { title: 'PAYMENT CAPTURED', value: `${companyCurrencySymbol}${amountCaptured}` },
        {
          title: 'PAYMENT PENDING',
          value: `${companyCurrencySymbol}${pendingOrderAmount}`,
          titleClass: 'partialCodHeaderText'
        }
      ];
      return partialCodMetadata.map(metadata =>
        renderSectionData({
          title: metadata.title,
          children: <span className="orderMetaText">{metadata.value}</span>,
          titleClass: metadata.titleClass
        })
      );
    }

    return renderSectionData({
      title: 'PAYMENT AMOUNT',
      children: (
        <span className="orderMetaText">
          {companyCurrencySymbol} {amountCaptured}
        </span>
      )
    });
  };

  const getOrderPlacedWithComponent = () => {
    const { key } = shippingConfig;
    const { displaySection, label } = SHIPPING_PROVIDERS[key] || {};
    if (!displaySection) {
      return null;
    }

    return renderSectionData({
      title: 'SHIPPED WITH',
      children: <span className="orderMetaText">{label}</span>
    });
  };

  const getOrderNoteComponent = () => {
    return <OrderNoteComponent orderId={selectedOrderId} />;
  };

  const getOrderDateComponent = () => {
    const { dateCreated } = orderMeta;
    return renderSectionData({
      title: t('order_date'),
      children: (
        <span className="orderMetaText">{moment(dateCreated).format('Do MMMM YYYY, h:mm a')}</span>
      )
    });
  };

  const getOrderStatusComponent = () =>
    renderSectionData({
      title: t('order_status'),
      titleSectionChildren: statusChangeProg ? (
        <span className="loaderWrapper">
          <Loader width={18} />
        </span>
      ) : null,
      children: (
        <div className="orderMetaText orderStatusActionsContainer">
          <OrderStatus
            statusChangeProg={statusChangeProg}
            setStatusChangeProg={setStatusChangeProg}
          />
        </div>
      )
    });

  let shippingContainerClass = 'orderInquiriesMetaData';
  if (headerView) {
    shippingContainerClass += ' marginTop';
  }

  return (
    <div className={shippingContainerClass}>
      <div className="metaDataRow">{getOrderStatusComponent()}</div>
      <div className="orderNotes">{getOrderNoteComponent()}</div>
      <div className="metaDataRow metaDataWrapper">
        {getOrderDateComponent()}
        {getPaymentComponent()}
        {getPaymentCapturedComponent()}
        {getOrderPlacedWithComponent()}
      </div>
      <div className="metaDataRow">
        {renderSectionData({ title: t('customer_details'), children: getShippingDataComponent() })}
      </div>
      {getAdditionalCheckoutFieldsComponent()}
    </div>
  );
};
