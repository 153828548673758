import React, { Fragment, useState, useEffect } from 'react';
import { Prompt, Link, useHistory, useLocation } from 'react-router-dom';
import { Button, Typography } from '@material-ui/core';
import Modal from 'react-responsive-modal';
import Route from 'route-parser';
import eventbus from 'eventing-bus';
import grapesjs from 'grapesjs';
import toastr from 'toastr';
import ProductGridTileIcon from 'qs-assets/Media/product_grid_tile_icon.svg';
import ProductListTileIcon from 'qs-assets/Media/product_list_tile_icon.svg';
import ProductMagazineTileIcon from 'qs-assets/Media/product_magazine_tile_icon.svg';
import CatalogueGridTileIcon from 'qs-assets/Media/catalogue_grid_tile_icon.svg';
import { ReactComponent as EditIcon } from 'qs-assets/Media/pencil.svg';
import { ReactComponent as CloseIcon } from 'qs-assets/Media/close.svg';
import { ROUTE_MAP, SETTINGS_COMPONENT_ID_MAP, SETTINGS_COMPONENT_ROUTE_MAP, SIDEBAR_VISIBILITY_CHANGED_EB_KEY } from 'qs-helpers/CompanySettings/constants';
import {
  CUSTOM_COMPONENT_KEYS,
  transformGrapesJSVariables,
  transformGrapesJSDataAttributes,
  PRODUCT_GRID_TILE_VARIABLES_MAP,
  PRODUCT_LIST_TILE_VARIABLES_MAP,
  PRODUCT_MAGAZINE_TILE_VARIABLES_MAP,
  CATALOGUE_GRID_TILE_VARIABLES_MAP,
  PRODUCT_GRID_TILE_CLASSNAMES_MAP,
  PRODUCT_LIST_TILE_CLASSNAMES_MAP,
  PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP,
  CATALOGUE_GRID_TILE_CLASSNAMES_MAP,
  PRODUCT_GRID_TILE_CLASSNAMES_PREFIX,
  PRODUCT_LIST_TILE_CLASSNAMES_PREFIX,
  PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX,
  CATALOGUE_GRID_TILE_CLASSNAMES_PREFIX,
  PRODUCT_GRID_TILE_ELEMENT_TYPES,
  PRODUCT_LIST_TILE_ELEMENT_TYPES,
  PRODUCT_MAGAZINE_TILE_ELEMENT_TYPES,
  CATALOGUE_GRID_TILE_ELEMENT_TYPES,
  PRODUCT_GRID_TILE_VARIABLES_PLACEHOLDER,
  PRODUCT_LIST_TILE_VARIABLES_PLACEHOLDER,
  PRODUCT_MAGAZINE_TILE_VARIABLES_PLACEHOLDER,
  CATALOGUE_GRID_TILE_VARIABLES_PLACEHOLDER,
  CUSTOM_COMPONENT_DEFAULT_STYLE,
  CUSTOM_COMPONENT_WRAPPER_STYLE,
  PRODUCT_GRID_TILE_WRAPPER_STYLE,
  PRODUCT_LIST_TILE_WRAPPER_STYLE,
  PRODUCT_MAGAZINE_TILE_WRAPPER_STYLE,
  CATALOGUE_GRID_TILE_WRAPPER_STYLE
} from './Constants';
import { GRAPES_JS_DEFAULT_CONFIG } from './Plugins/config';
import {
  PRODUCT_GRID_TILE_ELEMENT_TYPE_TRAIT_ID,
  PRODUCT_GRID_TILE_ELEMENT_TYPE_TRAIT,
  PRODUCT_GRID_TILE_GRAPES_JS_CONFIG,
  PRODUCT_GRID_TILE_CUSTOM_COMPONENT,
  getProductGridTileBlockConfig,
  getProductGridTileBlockComponent,
  getProductGridTypeThemeStyles
} from './Components/ProductGridTile';
import {
  PRODUCT_LIST_TILE_ELEMENT_TYPE_TRAIT_ID,
  PRODUCT_LIST_TILE_ELEMENT_TYPE_TRAIT,
  PRODUCT_LIST_TILE_GRAPES_JS_CONFIG,
  PRODUCT_LIST_TILE_CUSTOM_COMPONENT,
  getProductListTileBlockConfig,
  getProductListTileBlockComponent,
  getProductListTypeThemeStyles
} from './Components/ProductListTile';
import {
  PRODUCT_MAGAZINE_TILE_ELEMENT_TYPE_TRAIT_ID,
  PRODUCT_MAGAZINE_TILE_ELEMENT_TYPE_TRAIT,
  PRODUCT_MAGAZINE_TILE_GRAPES_JS_CONFIG,
  PRODUCT_MAGAZINE_TILE_CUSTOM_COMPONENT,
  getProductMagazineTileBlockConfig,
  getProductMagazineTileBlockComponent,
  getProductMagazineTypeThemeStyles
} from './Components/ProductMagazineTile';
import {
  CATALOGUE_GRID_TILE_ELEMENT_TYPE_TRAIT_ID,
  CATALOGUE_GRID_TILE_ELEMENT_TYPE_TRAIT,
  CATALOGUE_GRID_TILE_GRAPES_JS_CONFIG,
  CATALOGUE_GRID_TILE_CUSTOM_COMPONENT,
  getCatalogueGridTileBlockConfig,
  getCatalogueGridTileBlockComponent,
  getCatalogueGridTypeThemeStyles
} from './Components/CatalogueGridTile';
import Loader from 'qs-components/Common/Loader';
import {
  getCompanyCustomComponent,
  createCompanyCustomComponent,
  updateCompanyCustomComponent,
  // eslint-disable-next-line no-unused-vars
  deleteCompanyCustomComponentType
} from 'qs-services/Apis/CustomComponent/api';
import { capitalizeFirstLetter } from 'qs-helpers';
import { getI18N } from 'qs-services/i18N';

import './style.scss';
import './editor.scss';

export default ({ section, onClose, onSelect } = {}) => {
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [grapesJSEditor, setGrapesJSEditor] = useState(null);
  const [customComponentId, setCustomComponentId] = useState(null);
  const [customComponentType, setCustomComponentType] = useState(null);
  const { t } = getI18N();

  useEffect(() => {
    const { pathname } = location;
    const { type: customPageSectionType } = section || {};
    const { type: routeType } = new Route(`/${ROUTE_MAP.ACCOUNT_SETTINGS}/:type`).match(pathname) || {};
    if (routeType === PRODUCT_GRID_TILE_CLASSNAMES_PREFIX) {
      setCustomComponentType(CUSTOM_COMPONENT_KEYS.PRODUCT_GRID_TILE);
      getProductGridTileComponent();
    } else if (routeType === PRODUCT_LIST_TILE_CLASSNAMES_PREFIX) {
      setCustomComponentType(CUSTOM_COMPONENT_KEYS.PRODUCT_LIST_TILE);
      getProductListTileComponent();
    } else if (routeType === PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX) {
      setCustomComponentType(CUSTOM_COMPONENT_KEYS.PRODUCT_MAGAZINE_TILE);
      getProductMagazineTileComponent();
    } else if (routeType === CATALOGUE_GRID_TILE_CLASSNAMES_PREFIX) {
      setCustomComponentType(CUSTOM_COMPONENT_KEYS.CATALOGUE_GRID_TILE);
      getCatalogueGridTileComponent();
    } else if (customPageSectionType === 'html' || routeType === CUSTOM_COMPONENT_KEYS.CUSTOM_PAGE_HTML_SECTION) {
      setCustomComponentType(CUSTOM_COMPONENT_KEYS.CUSTOM_PAGE_HTML_SECTION);
      getCustomPageHtmlSectionComponent();
      eventbus.publish(SIDEBAR_VISIBILITY_CHANGED_EB_KEY);
    } else {
      setCustomComponentType(null);
      setCustomComponentId(null);
    }
    return () => {
      if (customPageSectionType === 'html' || routeType === CUSTOM_COMPONENT_KEYS.CUSTOM_PAGE_HTML_SECTION) {
        eventbus.publish(SIDEBAR_VISIBILITY_CHANGED_EB_KEY);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, section]);

  useEffect(() => {
    if (grapesJSEditor) {
      setupGrapesJSEvents(grapesJSEditor);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grapesJSEditor]);

  const getProductGridTileComponent = async () => {
    setLoading(true);
    try {
      const type = CUSTOM_COMPONENT_KEYS.PRODUCT_GRID_TILE;
      // await deleteCompanyCustomComponentType({ type });
      const { id, html, style, theme } = await getCompanyCustomComponent({ type });
      let updatedHtml = transformGrapesJSVariables({ text: !html || html.length === 0 ? PRODUCT_GRID_TILE_CUSTOM_COMPONENT.HTML : html, type });
      let updatedStyle = transformGrapesJSVariables({ text: !style || style.length === 0 ? PRODUCT_GRID_TILE_CUSTOM_COMPONENT.STYLE : style, type });
      if (!!id && id.length > 0) {
        setCustomComponentId(id);
      }
      const themeStyle = getProductGridTypeThemeStyles(theme);
      initializeGrapesJS({ type, html: updatedHtml, style: `${updatedStyle}${id ? '' : themeStyle}` });
    } catch (error) {
      toastr.error(error ? error.message : t('something_went_wrong'));
      setLoading(false);
    }
  };

  const getProductListTileComponent = async () => {
    setLoading(true);
    try {
      const type = CUSTOM_COMPONENT_KEYS.PRODUCT_LIST_TILE;
      // await deleteCompanyCustomComponentType({ type });
      const { id, html, style, theme } = await getCompanyCustomComponent({ type });
      let updatedHtml = transformGrapesJSVariables({ text: !html || html.length === 0 ? PRODUCT_LIST_TILE_CUSTOM_COMPONENT.HTML : html, type });
      let updatedStyle = transformGrapesJSVariables({ text: !style || style.length === 0 ? PRODUCT_LIST_TILE_CUSTOM_COMPONENT.STYLE : style, type });
      if (!!id && id.length > 0) {
        setCustomComponentId(id);
      }
      const themeStyle = getProductListTypeThemeStyles(theme);
      initializeGrapesJS({ type, html: updatedHtml, style: `${updatedStyle}${id ? '' : themeStyle}` });
    } catch (error) {
      toastr.error(error ? error.message : t('something_went_wrong'));
      setLoading(false);
    }
  };

  const getProductMagazineTileComponent = async () => {
    setLoading(true);
    try {
      const type = CUSTOM_COMPONENT_KEYS.PRODUCT_MAGAZINE_TILE;
      // await deleteCompanyCustomComponentType({ type });
      const { id, html, style, theme } = await getCompanyCustomComponent({ type });
      let updatedHtml = transformGrapesJSVariables({ text: !html || html.length === 0 ? PRODUCT_MAGAZINE_TILE_CUSTOM_COMPONENT.HTML : html, type });
      let updatedStyle = transformGrapesJSVariables({ text: !style || style.length === 0 ? PRODUCT_MAGAZINE_TILE_CUSTOM_COMPONENT.STYLE : style, type });
      if (!!id && id.length > 0) {
        setCustomComponentId(id);
      }
      const themeStyle = getProductMagazineTypeThemeStyles(theme);
      initializeGrapesJS({ type, html: updatedHtml, style: `${updatedStyle}${id ? '' : themeStyle}` });
    } catch (error) {
      toastr.error(error ? error.message : t('something_went_wrong'));
      setLoading(false);
    }
  };

  const getCatalogueGridTileComponent = async () => {
    setLoading(true);
    try {
      const type = CUSTOM_COMPONENT_KEYS.CATALOGUE_GRID_TILE;
      // await deleteCompanyCustomComponentType({ type });
      const { id, html, style, theme } = await getCompanyCustomComponent({ type });
      let updatedHtml = transformGrapesJSVariables({ text: !html || html.length === 0 ? CATALOGUE_GRID_TILE_CUSTOM_COMPONENT.HTML : html, type });
      let updatedStyle = transformGrapesJSVariables({ text: !style || style.length === 0 ? CATALOGUE_GRID_TILE_CUSTOM_COMPONENT.STYLE : style, type });
      if (!!id && id.length > 0) {
        setCustomComponentId(id);
      }
      const themeStyle = getCatalogueGridTypeThemeStyles(theme);
      initializeGrapesJS({ type, html: updatedHtml, style: `${updatedStyle}${id ? '' : themeStyle}` });
    } catch (error) {
      toastr.error(error ? error.message : t('something_went_wrong'));
      setLoading(false);
    }
  };

  const getCustomPageHtmlSectionComponent = () => {
    setLoading(true);
    try {
      const type = CUSTOM_COMPONENT_KEYS.CUSTOM_PAGE_HTML_SECTION;
      const { id, html, style } = section || {};
      let updatedHtml = transformGrapesJSVariables({ text: !html || html.length === 0 ? '' : html, type });
      let updatedStyle = transformGrapesJSVariables({ text: !style || style.length === 0 ? CUSTOM_COMPONENT_DEFAULT_STYLE : style, type });
      if (!!id && id.length > 0) {
        setCustomComponentId(id);
      }
      setTimeout(() => {
        initializeGrapesJS({ type, html: updatedHtml, style: updatedStyle });
      }, 0);
    } catch (error) {
      toastr.error(error ? error.message : t('something_went_wrong'));
      setLoading(false);
    }
  }

  const initializeGrapesJS = ({ type, html, style }) => {
    let config = GRAPES_JS_DEFAULT_CONFIG.COMMON;
    let wrapperStyle = CUSTOM_COMPONENT_WRAPPER_STYLE;
    if (type === CUSTOM_COMPONENT_KEYS.PRODUCT_GRID_TILE) {
      config = PRODUCT_GRID_TILE_GRAPES_JS_CONFIG.CONFIG;
      wrapperStyle += PRODUCT_GRID_TILE_WRAPPER_STYLE;
    } else if (type === CUSTOM_COMPONENT_KEYS.PRODUCT_LIST_TILE) {
      config = PRODUCT_LIST_TILE_GRAPES_JS_CONFIG.CONFIG;
      wrapperStyle += PRODUCT_LIST_TILE_WRAPPER_STYLE;
    } else if (type === CUSTOM_COMPONENT_KEYS.PRODUCT_MAGAZINE_TILE) {
      config = PRODUCT_MAGAZINE_TILE_GRAPES_JS_CONFIG.CONFIG;
      wrapperStyle += PRODUCT_MAGAZINE_TILE_WRAPPER_STYLE;
    } else if (type === CUSTOM_COMPONENT_KEYS.CATALOGUE_GRID_TILE) {
      config = CATALOGUE_GRID_TILE_GRAPES_JS_CONFIG.CONFIG;
      wrapperStyle += CATALOGUE_GRID_TILE_WRAPPER_STYLE;
    }
    const editor = grapesjs.init(config);
    editor.setComponents(transformGrapesJSDataAttributes(html));
    editor.setStyle(style);
    const wrapper = editor.getWrapper();
    wrapper.setStyle(wrapperStyle);
    if (type === CUSTOM_COMPONENT_KEYS.PRODUCT_GRID_TILE) {
      const productGridTileBlockContent = editor.DomComponents.getComponents().parseString(`
        ${transformGrapesJSDataAttributes(transformGrapesJSVariables({ text: PRODUCT_GRID_TILE_CUSTOM_COMPONENT.HTML, type }))}
        <style type="text/css">${PRODUCT_GRID_TILE_CUSTOM_COMPONENT.STYLE}</style>
      `);
      const productGridTileBlockConfig = getProductGridTileBlockConfig(productGridTileBlockContent);
      editor.BlockManager.categories.unshift({ id: 'quicksell', label: t('quicksell'), blocks: [] });
      editor.BlockManager.render();
      editor.DomComponents.addType(PRODUCT_GRID_TILE_CLASSNAMES_PREFIX, getProductGridTileBlockComponent(productGridTileBlockContent));
      editor.BlockManager.add(PRODUCT_GRID_TILE_CLASSNAMES_PREFIX, productGridTileBlockConfig, { at: 0 });
    } else if (type === CUSTOM_COMPONENT_KEYS.PRODUCT_LIST_TILE) {
      const productListTileBlockContent = editor.DomComponents.getComponents().parseString(`
        ${transformGrapesJSDataAttributes(transformGrapesJSVariables({ text: PRODUCT_LIST_TILE_CUSTOM_COMPONENT.HTML, type }))}
        <style type="text/css">${PRODUCT_LIST_TILE_CUSTOM_COMPONENT.STYLE}</style>
      `);
      const productListTileBlockConfig = getProductListTileBlockConfig(productListTileBlockContent);
      editor.BlockManager.categories.unshift({ id: 'quicksell', label: t('quicksell'), blocks: [] });
      editor.BlockManager.render();
      editor.DomComponents.addType(PRODUCT_LIST_TILE_CLASSNAMES_PREFIX, getProductListTileBlockComponent(productListTileBlockContent));
      editor.BlockManager.add(PRODUCT_LIST_TILE_CLASSNAMES_PREFIX, productListTileBlockConfig, { at: 0 });
    } else if (type === CUSTOM_COMPONENT_KEYS.PRODUCT_MAGAZINE_TILE) {
      const productMagazineTileBlockContent = editor.DomComponents.getComponents().parseString(`
        ${transformGrapesJSDataAttributes(transformGrapesJSVariables({ text: PRODUCT_MAGAZINE_TILE_CUSTOM_COMPONENT.HTML, type }))}
        <style type="text/css">${PRODUCT_MAGAZINE_TILE_CUSTOM_COMPONENT.STYLE}</style>
      `);
      const productMagazineTileBlockConfig = getProductMagazineTileBlockConfig(productMagazineTileBlockContent);
      editor.BlockManager.categories.unshift({ id: 'quicksell', label: t('quicksell'), blocks: [] });
      editor.BlockManager.render();
      editor.DomComponents.addType(PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX, getProductMagazineTileBlockComponent(productMagazineTileBlockContent));
      editor.BlockManager.add(PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX, productMagazineTileBlockConfig, { at: 0 });
    } else if (type === CUSTOM_COMPONENT_KEYS.CATALOGUE_GRID_TILE) {
      const catalogueGridTileBlockContent = editor.DomComponents.getComponents().parseString(`
        ${transformGrapesJSDataAttributes(transformGrapesJSVariables({ text: CATALOGUE_GRID_TILE_CUSTOM_COMPONENT.HTML, type }))}
        <style type="text/css">${CATALOGUE_GRID_TILE_CUSTOM_COMPONENT.STYLE}</style>
      `);
      const catalogueGridTileBlockConfig = getCatalogueGridTileBlockConfig(catalogueGridTileBlockContent);
      editor.BlockManager.categories.unshift({ id: 'quicksell', label: t('quicksell'), blocks: [] });
      editor.BlockManager.render();
      editor.DomComponents.addType(CATALOGUE_GRID_TILE_CLASSNAMES_PREFIX, getCatalogueGridTileBlockComponent(catalogueGridTileBlockContent));
      editor.BlockManager.add(CATALOGUE_GRID_TILE_CLASSNAMES_PREFIX, catalogueGridTileBlockConfig, { at: 0 });
    }
    window.editor = editor;
    setGrapesJSEditor(editor);
  };

  const stopTrackingUndoEvents = (editor) => {
    if (!editor) {
      return;
    }
    if (editor.UndoManager && typeof editor.UndoManager.stop === 'function') {
      editor.UndoManager.stop();
    }
  };

  const startTrackingUndoEvents = (editor) => {
    if (!editor) {
      return;
    }
    if (editor.UndoManager && typeof editor.UndoManager.start === 'function') {
      editor.UndoManager.start();
    }
  };

  const setupGrapesJSEvents = (editor) => {
    if (!editor) {
      return;
    }
    stopTrackingUndoEvents(editor);
    const tooltipElements = [...document.querySelectorAll('.custom-html-builder-grapes-js-editor *[title]')].filter(tooptipElement => {
      let tooltip = tooptipElement.getAttribute('title');
      tooltip = tooltip ? tooltip.trim() : '';
      return !!tooltip;
    });
    tooltipElements.forEach((tooltipElement) => {
      const tooltip = tooltipElement.getAttribute('title');
      tooltipElement.setAttribute('data-tooltip', tooltip);
      tooltipElement.setAttribute('data-tooltip-pos', 'bottom');
      tooltipElement.setAttribute('title', '');
    });
    if (editor.SelectorManager) {
      if (typeof editor.SelectorManager.setStates === 'function') {
        editor.SelectorManager.setStates([
          { name: 'hover', label: `${t('hover')} (:hover)` },
          { name: 'focus', label: `${t('focus')} (:focus)` },
          { name: 'focus-within', label: `${t('focus_within')} (:focus-within)` },
          { name: 'active', label: `${t('active')} (:active)` },
          { name: 'nth-of-type(2n)', label: `${t('odd_or_even')} (:nth-of-type(2n))` }
        ]);
      }
      if (typeof editor.SelectorManager.getAll === 'function') {
        editor.SelectorManager.getAll().each(selector => {
          const classNames = selector.get('name');
          if (classNames && classNames.length > 0) {
            const isPrivateClassName = classNames.split(' ').some(className => {
              return (
                className.trim().includes(PRODUCT_GRID_TILE_CLASSNAMES_PREFIX) ||
                className.trim().includes(PRODUCT_LIST_TILE_CLASSNAMES_PREFIX) ||
                className.trim().includes(PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX) ||
                className.trim().includes(CATALOGUE_GRID_TILE_CLASSNAMES_PREFIX)
              );
            });
            if (isPrivateClassName) {
              selector.set('private', true);
              selector.set('protected', true);
              selector.set('removable', false);
              selector.set('copyable', false);
              selector.set('draggable', false);
            }
          }
        });
      }
    }
    if (editor.Commands && typeof editor.Commands.add === 'function') {
      editor.Commands.add('canvas-clear', {
        run: (editor) => {
          if (window.confirm(t('are_you_sure_you_want_to_clear_all_the_html_css'))) {
            if (editor.DomComponents && typeof editor.DomComponents.clear === 'function') {
              editor.DomComponents.clear();
            }
          }
        }
      });
    }
    if (editor.Panels) {
      if (typeof editor.Panels.removeButton === 'function') {
        editor.Panels.removeButton('options', 'canvas-clear');
      }
      if (typeof editor.Panels.addButton === 'function') {
        editor.Panels.addButton('options', {
          id: 'canvas-clear',
          command: 'canvas-clear',
          attributes: { title: t('clear') },
          label: '<svg style="height: 22px; width: 22px;" viewBox="0 0 24 24"><path fill="currentColor" d="M19, 4H15.5L14.5, 3H9.5L8.5, 4H5V6H19M6, 19A2, 2 0 0, 0 8, 21H16A2, 2 0 0, 0 18, 19V7H6V19Z"></path></svg>'
        });
      }
    }
    startTrackingUndoEvents(editor);
    if (typeof editor.on === 'function') {
      editor.on('load', () => {
        const $ = grapesjs.$;
        stopTrackingUndoEvents(editor);
        if (editor.Panels && typeof editor.Panels.getButton === 'function') {
          const swVisibilityBtn = editor.Panels.getButton('options', 'sw-visibility');
          if (swVisibilityBtn) {
            swVisibilityBtn.set('active', 1);
          }
          const openTmBtn = editor.Panels.getButton('views', 'open-tm');
          if (openTmBtn) {
            openTmBtn.set('active', 1);
          }
          const openSmBtn = editor.Panels.getButton('views', 'open-sm');
          if (openSmBtn) {
            openSmBtn.set('active', 1);
          }
          const viewComponentsBtn = editor.Panels.getButton('options', 'sw-visibility');
          if (viewComponentsBtn) {
            viewComponentsBtn.set('active', 0);
          }
          editor.Panels.removeButton('views', 'open-tm');
        }
        const traitsSector = $(
          `<div class="gjs-sm-sector no-select">
            <div class="gjs-sm-sector-title">
              <span class="icon-settings fa fa-cog"></span>
              <span class="gjs-sm-sector-label">
                ${capitalizeFirstLetter(t('settings').toLowerCase())}
              </span>
            </div>
            <div class="gjs-sm-properties"></div>
          </div>`
        );
        const traitsProps = traitsSector.find('.gjs-sm-properties');
        if (traitsProps) {
          traitsProps.append($('.gjs-trt-traits'));
        }
        $('.gjs-sm-sectors').before(traitsSector);
        const openBlocksBtn = editor.Panels.getButton('views', 'open-blocks');
        if (openBlocksBtn) {
          openBlocksBtn.set('active', 1);
        }
        if (editor.DomComponents && typeof editor.DomComponents.getWrapper === 'function') {
          editor.DomComponents.getWrapper().set({
            removable: false,
            badgable: false,
            selectable: false,
            copyable: false,
            draggable: false,
            traits: []
          });
        }
        startTrackingUndoEvents(editor);
        setLoading(false);
      });
      editor.on('component:selected', () => {
        const component = editor.getSelected();
        const attributes = component.getAttributes() || {};
        stopTrackingUndoEvents(editor);
        if (customComponentType === CUSTOM_COMPONENT_KEYS.PRODUCT_GRID_TILE) {
          const existingTraits = (component.getTraits() || []).filter(
            trait => trait.attributes.name !== PRODUCT_GRID_TILE_ELEMENT_TYPE_TRAIT_ID
          );
          component.setTraits(existingTraits);
          component.addTrait(
            {
              ...PRODUCT_GRID_TILE_ELEMENT_TYPE_TRAIT.CONFIG,
              value: attributes[PRODUCT_GRID_TILE_ELEMENT_TYPE_TRAIT_ID] || 'none'
            },
            { at: 0 }
          );
          const trait = component.getTrait(PRODUCT_GRID_TILE_ELEMENT_TYPE_TRAIT_ID);
          trait.off('change:value', productGridTileElementTypeChanged);
          trait.on('change:value', productGridTileElementTypeChanged);
        } else if (customComponentType === CUSTOM_COMPONENT_KEYS.PRODUCT_LIST_TILE) {
          const existingTraits = (component.getTraits() || []).filter(
            trait => trait.attributes.name !== PRODUCT_LIST_TILE_ELEMENT_TYPE_TRAIT_ID
          );
          component.setTraits(existingTraits);
          component.addTrait(
            {
              ...PRODUCT_LIST_TILE_ELEMENT_TYPE_TRAIT.CONFIG,
              value: attributes[PRODUCT_LIST_TILE_ELEMENT_TYPE_TRAIT_ID] || 'none'
            },
            { at: 0 }
          );
          const trait = component.getTrait(PRODUCT_LIST_TILE_ELEMENT_TYPE_TRAIT_ID);
          trait.off('change:value', productListTileElementTypeChanged);
          trait.on('change:value', productListTileElementTypeChanged);
        } else if (customComponentType === CUSTOM_COMPONENT_KEYS.PRODUCT_MAGAZINE_TILE) {
          const existingTraits = (component.getTraits() || []).filter(
            trait => trait.attributes.name !== PRODUCT_MAGAZINE_TILE_ELEMENT_TYPE_TRAIT_ID
          );
          component.setTraits(existingTraits);
          component.addTrait(
            {
              ...PRODUCT_MAGAZINE_TILE_ELEMENT_TYPE_TRAIT.CONFIG,
              value: attributes[PRODUCT_MAGAZINE_TILE_ELEMENT_TYPE_TRAIT_ID] || 'none'
            },
            { at: 0 }
          );
          const trait = component.getTrait(PRODUCT_MAGAZINE_TILE_ELEMENT_TYPE_TRAIT_ID);
          trait.off('change:value', productMagazineTileElementTypeChanged);
          trait.on('change:value', productMagazineTileElementTypeChanged);
        } else if (customComponentType === CUSTOM_COMPONENT_KEYS.CATALOGUE_GRID_TILE) {
          const existingTraits = (component.getTraits() || []).filter(
            trait => trait.attributes.name !== CATALOGUE_GRID_TILE_ELEMENT_TYPE_TRAIT_ID
          );
          component.setTraits(existingTraits);
          component.addTrait(
            {
              ...CATALOGUE_GRID_TILE_ELEMENT_TYPE_TRAIT.CONFIG,
              value: attributes[CATALOGUE_GRID_TILE_ELEMENT_TYPE_TRAIT_ID] || 'none'
            },
            { at: 0 }
          );
          const trait = component.getTrait(CATALOGUE_GRID_TILE_ELEMENT_TYPE_TRAIT_ID);
          trait.off('change:value', catalogueGridTileElementTypeChanged);
          trait.on('change:value', catalogueGridTileElementTypeChanged);
        }
        startTrackingUndoEvents(editor);
      });
    }
  };

  const productGridTileElementTypeChanged = (model, value) => {
    const component = model.target;
    const attributes = component.getAttributes() || {};
    const elementTypeOption = PRODUCT_GRID_TILE_ELEMENT_TYPES.CONFIG.find(
      elementType => elementType.id === value
    );
    PRODUCT_GRID_TILE_ELEMENT_TYPES.CONFIG.map(elementType => elementType.id).forEach(
      elementType => {
        delete attributes[`data-${elementType}`];
      }
    );
    attributes[`data-${value}`] = PRODUCT_GRID_TILE_VARIABLES_MAP.productId;
    component.setAttributes(attributes);
    if (!!elementTypeOption && !!elementTypeOption.variable) {
      const placeholder =
        PRODUCT_GRID_TILE_VARIABLES_PLACEHOLDER.VARIABLES[elementTypeOption.variable];
      if (!!placeholder && placeholder.length > 0) {
        component.components(placeholder);
      }
    }
  };

  const productListTileElementTypeChanged = (model, value) => {
    const component = model.target;
    const attributes = component.getAttributes() || {};
    const elementTypeOption = PRODUCT_LIST_TILE_ELEMENT_TYPES.CONFIG.find(
      elementType => elementType.id === value
    );
    PRODUCT_LIST_TILE_ELEMENT_TYPES.CONFIG.map(elementType => elementType.id).forEach(
      elementType => {
        delete attributes[`data-${elementType}`];
      }
    );
    attributes[`data-${value}`] = PRODUCT_LIST_TILE_VARIABLES_MAP.productId;
    component.setAttributes(attributes);
    if (!!elementTypeOption && !!elementTypeOption.variable) {
      const placeholder =
        PRODUCT_LIST_TILE_VARIABLES_PLACEHOLDER.VARIABLES[elementTypeOption.variable];
      if (!!placeholder && placeholder.length > 0) {
        component.components(placeholder);
      }
    }
  };

  const productMagazineTileElementTypeChanged = (model, value) => {
    const component = model.target;
    const attributes = component.getAttributes() || {};
    const elementTypeOption = PRODUCT_MAGAZINE_TILE_ELEMENT_TYPES.CONFIG.find(
      elementType => elementType.id === value
    );
    PRODUCT_MAGAZINE_TILE_ELEMENT_TYPES.CONFIG.map(elementType => elementType.id).forEach(
      elementType => {
        delete attributes[`data-${elementType}`];
      }
    );
    attributes[`data-${value}`] = PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productId;
    component.setAttributes(attributes);
    if (!!elementTypeOption && !!elementTypeOption.variable) {
      const placeholder =
        PRODUCT_MAGAZINE_TILE_VARIABLES_PLACEHOLDER.VARIABLES[elementTypeOption.variable];
      if (!!placeholder && placeholder.length > 0) {
        component.components(placeholder);
      }
    }
  };

  const catalogueGridTileElementTypeChanged = (model, value) => {
    const component = model.target;
    const attributes = component.getAttributes() || {};
    const elementTypeOption = CATALOGUE_GRID_TILE_ELEMENT_TYPES.CONFIG.find(
      elementType => elementType.id === value
    );
    CATALOGUE_GRID_TILE_ELEMENT_TYPES.CONFIG.map(elementType => elementType.id).forEach(
      elementType => {
        delete attributes[`data-${elementType}`];
      }
    );
    attributes[`data-${value}`] = CATALOGUE_GRID_TILE_VARIABLES_MAP.catalogueId;
    component.setAttributes(attributes);
    if (!!elementTypeOption && !!elementTypeOption.variable) {
      const placeholder =
        CATALOGUE_GRID_TILE_VARIABLES_PLACEHOLDER.VARIABLES[elementTypeOption.variable];
      if (!!placeholder && placeholder.length > 0) {
        component.components(placeholder);
      }
    }
  };

  const saveCustomComponentMarkup = () => {
    if (customComponentType === CUSTOM_COMPONENT_KEYS.PRODUCT_GRID_TILE) {
      saveProductGridTileMarkup();
    } else if (customComponentType === CUSTOM_COMPONENT_KEYS.PRODUCT_LIST_TILE) {
      saveProductListTileMarkup();
    } else if (customComponentType === CUSTOM_COMPONENT_KEYS.PRODUCT_MAGAZINE_TILE) {
      saveProductMagazineTileMarkup();
    } else if (customComponentType === CUSTOM_COMPONENT_KEYS.CATALOGUE_GRID_TILE) {
      saveCatalogueGridTileMarkup();
    } else if (customComponentType === CUSTOM_COMPONENT_KEYS.CUSTOM_PAGE_HTML_SECTION) {
      saveCustomPageHtmlSectionMarkup();
    }
  };

  const saveProductGridTileMarkup = async () => {
    try {
      if (loading || !customComponentType || !grapesJSEditor || !grapesJSEditor.Canvas || !grapesJSEditor.Canvas.getBody) {
        return;
      }
      const body = grapesJSEditor.Canvas.getBody();
      if (!body) {
        return;
      }
      const confirm = window.confirm(t('are_you_sure_you_want_to_save_product_grid_tile_html'));
      if (!confirm) {
        return;
      }
      setLoading(true);
      const productImage = body.querySelector(
        `[data-${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileImage}="${PRODUCT_GRID_TILE_VARIABLES_MAP.productId}"]`
      );
      if (productImage) {
        productImage.src = PRODUCT_GRID_TILE_VARIABLES_MAP.pictureUrl;
        productImage.alt = PRODUCT_GRID_TILE_VARIABLES_MAP.pictureAlt;
        productImage.height = PRODUCT_GRID_TILE_VARIABLES_MAP.pictureHeight;
        productImage.setAttribute(
          `data-${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileImageHeight}`,
          PRODUCT_GRID_TILE_VARIABLES_MAP.pictureHeight
        );
        productImage.width = PRODUCT_GRID_TILE_VARIABLES_MAP.pictureWidth;
        productImage.setAttribute(
          `data-${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileImageWidth}`,
          PRODUCT_GRID_TILE_VARIABLES_MAP.pictureWidth
        );
      }
      const productDiscountPercent = body.querySelector(
        `[data-${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileDiscountBadge}="${PRODUCT_GRID_TILE_VARIABLES_MAP.productId}"]`
      );
      if (productDiscountPercent && !productDiscountPercent.innerHTML.startsWith('{{')) {
        productDiscountPercent.innerHTML = PRODUCT_GRID_TILE_VARIABLES_MAP.discountPercent;
      }
      const productOutOfStock = body.querySelector(
        `[data-${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileOutOfStockBadge}="${PRODUCT_GRID_TILE_VARIABLES_MAP.productId}"]`
      );
      if (productOutOfStock && !productOutOfStock.innerHTML.startsWith('{{')) {
        productOutOfStock.innerHTML = PRODUCT_GRID_TILE_VARIABLES_MAP.outOfStockText;
      }
      const productPhotoCount = body.querySelector(
        `[data-${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTilePhotoCountBadge}="${PRODUCT_GRID_TILE_VARIABLES_MAP.productId}"]`
      );
      if (productPhotoCount && !productPhotoCount.innerHTML.startsWith('{{')) {
        productPhotoCount.innerHTML = PRODUCT_GRID_TILE_VARIABLES_MAP.pictureCount;
      }
      const variantColorCount = body.querySelector(
        `[data-${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileVariantColorCount}="${PRODUCT_GRID_TILE_VARIABLES_MAP.productId}"]`
      );
      if (variantColorCount && !variantColorCount.innerHTML.startsWith('{{')) {
        variantColorCount.innerHTML = PRODUCT_GRID_TILE_VARIABLES_MAP.variantColorCount;
      }
      const productName = body.querySelector(
        `[data-${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileName}="${PRODUCT_GRID_TILE_VARIABLES_MAP.productId}"]`
      );
      if (productName && !productName.innerHTML.startsWith('{{')) {
        productName.innerHTML = PRODUCT_GRID_TILE_VARIABLES_MAP.productName;
      }
      const productPrice = body.querySelector(
        `[data-${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTilePrice}="${PRODUCT_GRID_TILE_VARIABLES_MAP.productId}"]`
      );
      if (productPrice && !productPrice.innerHTML.startsWith('{{')) {
        productPrice.innerHTML = PRODUCT_GRID_TILE_VARIABLES_MAP.productPrice;
      }
      const productDiscountedPrice = body.querySelector(
        `[data-${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTilePriceDiscount}="${PRODUCT_GRID_TILE_VARIABLES_MAP.productId}"]`
      );
      if (productDiscountedPrice && !productDiscountedPrice.innerHTML.startsWith('{{')) {
        productDiscountedPrice.innerHTML = PRODUCT_GRID_TILE_VARIABLES_MAP.productDiscountedPrice;
      }
      const productTaxPrice = body.querySelector(
        `[data-${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTilePriceTax}="${PRODUCT_GRID_TILE_VARIABLES_MAP.productId}"]`
      );
      if (productTaxPrice && !productTaxPrice.innerHTML.startsWith('{{')) {
        productTaxPrice.innerHTML = PRODUCT_GRID_TILE_VARIABLES_MAP.productTaxPrice;
      }
      const addToCartButtonText = body.querySelector(
        `[data-${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileAddToCart}="${PRODUCT_GRID_TILE_VARIABLES_MAP.productId}"]`
      );
      if (addToCartButtonText && !addToCartButtonText.innerHTML.startsWith('{{')) {
        addToCartButtonText.innerHTML = PRODUCT_GRID_TILE_VARIABLES_MAP.addToCartButtonText;
      }
      grapesJSEditor.setComponents(transformGrapesJSDataAttributes(body.innerHTML));
      const upsertCompanyCustomComponent = customComponentId
        ? updateCompanyCustomComponent
        : createCompanyCustomComponent;
      const productGridTileHtml = grapesJSEditor.getWrapper().getInnerHTML();
      const productGridTileElement = grapesJSEditor
        .getWrapper()
        .getEl()
        .querySelector(
          `[data-${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileId}="${PRODUCT_GRID_TILE_VARIABLES_MAP.productId}"]`
        );
      const { id } = await upsertCompanyCustomComponent({
        id: customComponentId ? customComponentId : undefined,
        type: customComponentType,
        html: transformGrapesJSDataAttributes(
          productGridTileElement ? productGridTileElement.outerHTML : productGridTileHtml
        ),
        style: grapesJSEditor.getCss(),
        script: grapesJSEditor.getJs(),
        data: grapesJSEditor.getProjectData(),
        settings: {}
      });
      if (!!id && id.length > 0) {
        setCustomComponentId(id);
      }
      await getProductGridTileComponent();
      toastr.success(t('product_grid_tile_component_has_been_saved_successfully'));
    } catch (error) {
      toastr.error(
        error.message || t('something_went_wrong_while_saving_the_product_grid_tile_component')
      );
    }
  };

  const saveProductListTileMarkup = async () => {
    try {
      if (loading || !customComponentType || !grapesJSEditor || !grapesJSEditor.Canvas || !grapesJSEditor.Canvas.getBody) {
        return;
      }
      const body = grapesJSEditor.Canvas.getBody();
      if (!body) {
        return;
      }
      const confirm = window.confirm(t('are_you_sure_you_want_to_save_product_list_tile_html'));
      if (!confirm) {
        return;
      }
      setLoading(true);
      const productImage = body.querySelector(
        `[data-${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileImage}="${PRODUCT_LIST_TILE_VARIABLES_MAP.productId}"]`
      );
      if (productImage) {
        productImage.src = PRODUCT_LIST_TILE_VARIABLES_MAP.pictureUrl;
        productImage.alt = PRODUCT_LIST_TILE_VARIABLES_MAP.pictureAlt;
        productImage.height = PRODUCT_LIST_TILE_VARIABLES_MAP.pictureHeight;
        productImage.setAttribute(
          `data-${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileImageHeight}`,
          PRODUCT_LIST_TILE_VARIABLES_MAP.pictureHeight
        );
        productImage.width = PRODUCT_LIST_TILE_VARIABLES_MAP.pictureWidth;
        productImage.setAttribute(
          `data-${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileImageWidth}`,
          PRODUCT_LIST_TILE_VARIABLES_MAP.pictureWidth
        );
      }
      const productDiscountPercent = body.querySelector(
        `[data-${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileDiscountBadge}="${PRODUCT_LIST_TILE_VARIABLES_MAP.productId}"]`
      );
      if (productDiscountPercent && !productDiscountPercent.innerHTML.startsWith('{{')) {
        productDiscountPercent.innerHTML = PRODUCT_LIST_TILE_VARIABLES_MAP.discountPercent;
      }
      const productOutOfStock = body.querySelector(
        `[data-${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileOutOfStockBadge}="${PRODUCT_LIST_TILE_VARIABLES_MAP.productId}"]`
      );
      if (productOutOfStock && !productOutOfStock.innerHTML.startsWith('{{')) {
        productOutOfStock.innerHTML = PRODUCT_LIST_TILE_VARIABLES_MAP.outOfStockText;
      }
      const variantColorCount = body.querySelector(
        `[data-${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileVariantColorCount}="${PRODUCT_LIST_TILE_VARIABLES_MAP.productId}"]`
      );
      if (variantColorCount && !variantColorCount.innerHTML.startsWith('{{')) {
        variantColorCount.innerHTML = PRODUCT_LIST_TILE_VARIABLES_MAP.variantColorCount;
      }
      const productName = body.querySelector(
        `[data-${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileName}="${PRODUCT_LIST_TILE_VARIABLES_MAP.productId}"]`
      );
      if (productName && !productName.innerHTML.startsWith('{{')) {
        productName.innerHTML = PRODUCT_LIST_TILE_VARIABLES_MAP.productName;
      }
      const productDescription = body.querySelector(
        `[data-${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileDescription}="${PRODUCT_LIST_TILE_VARIABLES_MAP.productId}"]`
      );
      if (productDescription && !productDescription.innerHTML.startsWith('{{')) {
        productDescription.innerHTML = PRODUCT_LIST_TILE_VARIABLES_MAP.productDescription;
      }
      const productPrice = body.querySelector(
        `[data-${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTilePrice}="${PRODUCT_LIST_TILE_VARIABLES_MAP.productId}"]`
      );
      if (productPrice && !productPrice.innerHTML.startsWith('{{')) {
        productPrice.innerHTML = PRODUCT_LIST_TILE_VARIABLES_MAP.productPrice;
      }
      const productDiscountedPrice = body.querySelector(
        `[data-${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTilePriceDiscount}="${PRODUCT_LIST_TILE_VARIABLES_MAP.productId}"]`
      );
      if (productDiscountedPrice && !productDiscountedPrice.innerHTML.startsWith('{{')) {
        productDiscountedPrice.innerHTML = PRODUCT_LIST_TILE_VARIABLES_MAP.productDiscountedPrice;
      }
      const productTaxPrice = body.querySelector(
        `[data-${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTilePriceTax}="${PRODUCT_LIST_TILE_VARIABLES_MAP.productId}"]`
      );
      if (productTaxPrice && !productTaxPrice.innerHTML.startsWith('{{')) {
        productTaxPrice.innerHTML = PRODUCT_LIST_TILE_VARIABLES_MAP.productTaxPrice;
      }
      const addToCartButtonText = body.querySelector(
        `[data-${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileAddToCart}="${PRODUCT_LIST_TILE_VARIABLES_MAP.productId}"]`
      );
      if (addToCartButtonText && !addToCartButtonText.innerHTML.startsWith('{{')) {
        addToCartButtonText.innerHTML = PRODUCT_LIST_TILE_VARIABLES_MAP.addToCartButtonText;
      }
      grapesJSEditor.setComponents(transformGrapesJSDataAttributes(body.innerHTML));
      const upsertCompanyCustomComponent = customComponentId
        ? updateCompanyCustomComponent
        : createCompanyCustomComponent;
      const productListTileHtml = grapesJSEditor.getWrapper().getInnerHTML();
      const productListTileElement = grapesJSEditor
        .getWrapper()
        .getEl()
        .querySelector(
          `[data-${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileId}="${PRODUCT_LIST_TILE_VARIABLES_MAP.productId}"]`
        );
      const { id } = await upsertCompanyCustomComponent({
        id: customComponentId ? customComponentId : undefined,
        type: customComponentType,
        html: transformGrapesJSDataAttributes(
          productListTileElement ? productListTileElement.outerHTML : productListTileHtml
        ),
        style: grapesJSEditor.getCss(),
        script: grapesJSEditor.getJs(),
        data: grapesJSEditor.getProjectData(),
        settings: {}
      });
      if (!!id && id.length > 0) {
        setCustomComponentId(id);
      }
      await getProductListTileComponent();
      toastr.success(t('product_list_tile_component_has_been_saved_successfully'));
    } catch (error) {
      toastr.error(
        error.message || t('something_went_wrong_while_saving_the_product_list_tile_component')
      );
    }
  };

  const saveProductMagazineTileMarkup = async () => {
    try {
      if (loading || !customComponentType || !grapesJSEditor || !grapesJSEditor.Canvas || !grapesJSEditor.Canvas.getBody) {
        return;
      }
      const body = grapesJSEditor.Canvas.getBody();
      if (!body) {
        return;
      }
      const confirm = window.confirm(t('are_you_sure_you_want_to_save_product_magazine_tile_html'));
      if (!confirm) {
        return;
      }
      setLoading(true);
      const productImage = body.querySelector(
        `[data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileImage}="${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productId}"]`
      );
      if (productImage) {
        productImage.src = PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.pictureUrl;
        productImage.alt = PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.pictureAlt;
        productImage.height = PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.pictureHeight;
        productImage.setAttribute(
          `data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileImageHeight}`,
          PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.pictureHeight
        );
        productImage.width = PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.pictureWidth;
        productImage.setAttribute(
          `data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileImageWidth}`,
          PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.pictureWidth
        );
      }
      const productThumbnails = body.querySelectorAll(
        `[data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileThumbnail}="${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productId}"]`
      );
      [...productThumbnails].forEach(productThumbnail => {
        if (productThumbnail) {
          productThumbnail.src = PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.pictureUrl;
          productThumbnail.alt = PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.pictureAlt;
          productThumbnail.height = PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.pictureHeight;
          productThumbnail.setAttribute(
            `data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileImageHeight}`,
            PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.pictureHeight
          );
          productThumbnail.width = PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.pictureWidth;
          productThumbnail.setAttribute(
            `data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileImageWidth}`,
            PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.pictureWidth
          );
        }
      });
      const productOutOfStock = body.querySelector(
        `[data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileOutOfStockBadge}="${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productId}"]`
      );
      if (productOutOfStock && !productOutOfStock.innerHTML.startsWith('{{')) {
        productOutOfStock.innerHTML = PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.outOfStockText;
      }
      const variantColorCount = body.querySelector(
        `[data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileVariantColorCount}="${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productId}"]`
      );
      if (variantColorCount && !variantColorCount.innerHTML.startsWith('{{')) {
        variantColorCount.innerHTML = PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.variantColorCount;
      }
      const productName = body.querySelector(
        `[data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileName}="${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productId}"]`
      );
      if (productName && !productName.innerHTML.startsWith('{{')) {
        productName.innerHTML = PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productName;
      }
      const productSku = body.querySelector(
        `[data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileSku}="${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productId}"]`
      );
      if (productSku && !productSku.innerHTML.startsWith('{{')) {
        productSku.innerHTML = PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productSku;
      }
      const productDiscountPercent = body.querySelector(
        `[data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileDiscountBadge}="${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productId}"]`
      );
      if (productDiscountPercent && !productDiscountPercent.innerHTML.startsWith('{{')) {
        productDiscountPercent.innerHTML = PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.discountPercent;
      }
      const productDescription = body.querySelector(
        `[data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileDescription}="${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productId}"]`
      );
      if (productDescription && !productDescription.innerHTML.startsWith('{{')) {
        productDescription.innerHTML = PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productDescription;
      }
      const productPrice = body.querySelector(
        `[data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTilePrice}="${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productId}"]`
      );
      if (productPrice && !productPrice.innerHTML.startsWith('{{')) {
        productPrice.innerHTML = PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productPrice;
      }
      const productDiscountedPrice = body.querySelector(
        `[data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTilePriceDiscount}="${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productId}"]`
      );
      if (productDiscountedPrice && !productDiscountedPrice.innerHTML.startsWith('{{')) {
        productDiscountedPrice.innerHTML = PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productDiscountedPrice;
      }
      const productTaxPrice = body.querySelector(
        `[data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTilePriceTax}="${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productId}"]`
      );
      if (productTaxPrice && !productTaxPrice.innerHTML.startsWith('{{')) {
        productTaxPrice.innerHTML = PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productTaxPrice;
      }
      const productMagazineTilePriceMinimumOrderQuantity = body.querySelector(
        `[data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTilePriceMinimumOrderQuantity}="${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productId}"]`
      );
      if (productMagazineTilePriceMinimumOrderQuantity && !productMagazineTilePriceMinimumOrderQuantity.innerHTML.startsWith('{{')) {
        productMagazineTilePriceMinimumOrderQuantity.innerHTML = PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productMinimumOrderQuantity;
      }
      const addToCartButtonText = body.querySelector(
        `[data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileAddToCart}="${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productId}"]`
      );
      if (addToCartButtonText && !addToCartButtonText.innerHTML.startsWith('{{')) {
        addToCartButtonText.innerHTML = PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.addToCartButtonText;
      }
      grapesJSEditor.setComponents(transformGrapesJSDataAttributes(body.innerHTML));
      const upsertCompanyCustomComponent = customComponentId
        ? updateCompanyCustomComponent
        : createCompanyCustomComponent;
      const productMagazineTileHtml = grapesJSEditor.getWrapper().getInnerHTML();
      const productMagazineTileElement = grapesJSEditor
        .getWrapper()
        .getEl()
        .querySelector(
          `[data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileId}="${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productId}"]`
        );
      const { id } = await upsertCompanyCustomComponent({
        id: customComponentId ? customComponentId : undefined,
        type: customComponentType,
        html: transformGrapesJSDataAttributes(
          productMagazineTileElement ? productMagazineTileElement.outerHTML : productMagazineTileHtml
        ),
        style: grapesJSEditor.getCss(),
        script: grapesJSEditor.getJs(),
        data: grapesJSEditor.getProjectData(),
        settings: {}
      });
      if (!!id && id.length > 0) {
        setCustomComponentId(id);
      }
      await getProductMagazineTileComponent();
      toastr.success(t('product_magazine_tile_component_has_been_saved_successfully'));
    } catch (error) {
      toastr.error(
        error.message || t('something_went_wrong_while_saving_the_product_magazine_tile_component')
      );
    }
  };

  const saveCatalogueGridTileMarkup = async () => {
    try {
      if (loading || !customComponentType || !grapesJSEditor || !grapesJSEditor.Canvas || !grapesJSEditor.Canvas.getBody) {
        return;
      }
      const body = grapesJSEditor.Canvas.getBody();
      if (!body) {
        return;
      }
      const confirm = window.confirm(t('are_you_sure_you_want_to_save_catalogue_grid_tile_html'));
      if (!confirm) {
        return;
      }
      setLoading(true);
      const catalogueImage = body.querySelector(
        `[data-${CATALOGUE_GRID_TILE_CLASSNAMES_MAP.catalogueGridTileImage}="${CATALOGUE_GRID_TILE_VARIABLES_MAP.catalogueId}"]`
      );
      if (catalogueImage) {
        catalogueImage.src = CATALOGUE_GRID_TILE_VARIABLES_MAP.pictureUrl;
        catalogueImage.alt = CATALOGUE_GRID_TILE_VARIABLES_MAP.pictureAlt;
        catalogueImage.height = CATALOGUE_GRID_TILE_VARIABLES_MAP.pictureHeight;
        catalogueImage.setAttribute(
          `data-${CATALOGUE_GRID_TILE_CLASSNAMES_MAP.catalogueGridTileImageHeight}`,
          CATALOGUE_GRID_TILE_VARIABLES_MAP.pictureHeight
        );
        catalogueImage.width = CATALOGUE_GRID_TILE_VARIABLES_MAP.pictureWidth;
        catalogueImage.setAttribute(
          `data-${CATALOGUE_GRID_TILE_CLASSNAMES_MAP.catalogueGridTileImageWidth}`,
          CATALOGUE_GRID_TILE_VARIABLES_MAP.pictureWidth
        );
      }
      const catalogueTitle = body.querySelector(
        `[data-${CATALOGUE_GRID_TILE_CLASSNAMES_MAP.catalogueGridTileTitle}="${CATALOGUE_GRID_TILE_VARIABLES_MAP.catalogueId}"]`
      );
      if (catalogueTitle && !catalogueTitle.innerHTML.startsWith('{{')) {
        catalogueTitle.innerHTML = CATALOGUE_GRID_TILE_VARIABLES_MAP.catalogueTitle;
      }
      const productCount = body.querySelector(
        `[data-${CATALOGUE_GRID_TILE_CLASSNAMES_MAP.catalogueGridTileProductCount}="${CATALOGUE_GRID_TILE_VARIABLES_MAP.catalogueId}"]`
      );
      if (productCount && !productCount.innerHTML.startsWith('{{')) {
        productCount.innerHTML = CATALOGUE_GRID_TILE_VARIABLES_MAP.productCount;
      }
      grapesJSEditor.setComponents(transformGrapesJSDataAttributes(body.innerHTML));
      const upsertCompanyCustomComponent = customComponentId
        ? updateCompanyCustomComponent
        : createCompanyCustomComponent;
      const catalogueGridTileHtml = grapesJSEditor.getWrapper().getInnerHTML();
      const catalogueGridTileElement = grapesJSEditor
        .getWrapper()
        .getEl()
        .querySelector(
          `[data-${CATALOGUE_GRID_TILE_CLASSNAMES_MAP.catalogueGridTileId}="${CATALOGUE_GRID_TILE_VARIABLES_MAP.catalogueId}"]`
        );
      const { id } = await upsertCompanyCustomComponent({
        id: customComponentId ? customComponentId : undefined,
        type: customComponentType,
        html: transformGrapesJSDataAttributes(
          catalogueGridTileElement ? catalogueGridTileElement.outerHTML : catalogueGridTileHtml
        ),
        style: grapesJSEditor.getCss(),
        script: grapesJSEditor.getJs(),
        data: grapesJSEditor.getProjectData(),
        settings: {}
      });
      if (!!id && id.length > 0) {
        setCustomComponentId(id);
      }
      await getCatalogueGridTileComponent();
      toastr.success(t('catalogue_grid_tile_component_has_been_saved_successfully'));
    } catch (error) {
      toastr.error(
        error.message || t('something_went_wrong_while_saving_the_catalogue_grid_tile_component')
      );
    }
  };

  const saveCustomPageHtmlSectionMarkup = async () => {
    try {
      if (loading || !customComponentType || !grapesJSEditor || !grapesJSEditor.Canvas || !grapesJSEditor.Canvas.getBody) {
        return;
      }
      if (typeof onSelect !== 'function') {
        return;
      }
      const body = grapesJSEditor.Canvas.getBody();
      if (!body) {
        return;
      }
      const confirm = window.confirm(t('are_you_sure_you_want_to_save_custom_page_html_section'));
      if (!confirm) {
        return;
      }
      setLoading(true);
      onSelect({
        id: customComponentId ? customComponentId : undefined,
        type: customComponentType,
        html: transformGrapesJSDataAttributes(grapesJSEditor.getWrapper().getInnerHTML()),
        style: grapesJSEditor.getCss(),
        script: grapesJSEditor.getJs(),
        data: grapesJSEditor.getProjectData(),
        settings: {}
      });
    } catch (error) {
      toastr.error(
        error.message || t('something_went_wrong_while_saving_the_catalogue_grid_tile_component')
      );
    }
  };

  const onModalClose = () => {
    if (typeof onClose === 'function') {
      onClose();
      return; 
    }
    history.goBack();
  };

  return (
    <Fragment>
      <Modal
        open={true}
        center={true}
        onClose={onModalClose}
        closeOnEsc={false}
        showCloseIcon={false}
        classNames={{
          modal: 'custom-html-builder-modal',
          overlay: 'custom-html-builder-modal-overlay'
        }}
        closeOnOverlayClick={false}
        container={document.querySelector('.settingsContainer')}
      >
        {(!!customComponentType || !!customComponentId) ? (
          <Fragment>
            <div
              id="gjs"
              className="custom-html-builder-editor"
              style={{
                height: !grapesJSEditor || loading ? 0 : undefined,
                width: !grapesJSEditor || loading ? 0 : undefined,
                opacity: !grapesJSEditor || loading ? 0 : undefined,
                visibility: !grapesJSEditor || loading ? 'hidden' : undefined
              }}
            />
            <Button
              variant="contained"
              color="primary"
              disabled={!grapesJSEditor || loading}
              classes={{ root: 'custom-html-builder-editor-save-button' }}
              onClick={saveCustomComponentMarkup}
            >
              {t(customComponentId ? 'update_component' : 'create_component')}
              {!grapesJSEditor || loading ? <Loader size="small" /> : <EditIcon />}
            </Button>
          </Fragment>
        ) : (
          <div className="custom-html-builder-tiles-list-container">
            <Typography className="custom-html-builder-tiles-list-label">
              {t('select_a_custom_component_to_edit')}
            </Typography>
            <div className="custom-html-builder-tiles-list-items">
              <Link to={`/${ROUTE_MAP.ACCOUNT_SETTINGS}/${SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CUSTOM_PRODUCT_GRID_TILE].ROUTE}`} target="_self" className="custom-html-builder-tiles-list-item">
                <div className="custom-html-builder-tiles-list-item-icon">
                  <img src={ProductGridTileIcon} alt={t('product_grid_tile')} />
                </div>
                <div className="custom-html-builder-tiles-list-item-label">
                  <Typography>{t('product_grid_tile')}</Typography>
                </div>
              </Link>
              <Link to={`/${ROUTE_MAP.ACCOUNT_SETTINGS}/${SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CUSTOM_PRODUCT_LIST_TILE].ROUTE}`} target="_self" className="custom-html-builder-tiles-list-item">
                <div className="custom-html-builder-tiles-list-item-icon">
                  <img src={ProductListTileIcon} alt={t('product_list_tile')} />
                </div>
                <div className="custom-html-builder-tiles-list-item-label">
                  <Typography>{t('product_list_tile')}</Typography>
                </div>
              </Link>
              <Link to={`/${ROUTE_MAP.ACCOUNT_SETTINGS}/${SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CUSTOM_PRODUCT_MAGAZINE_TILE].ROUTE}`} target="_self" className="custom-html-builder-tiles-list-item">
                <div className="custom-html-builder-tiles-list-item-icon">
                  <img src={ProductMagazineTileIcon} alt={t('product_magazine_tile')} />
                </div>
                <div className="custom-html-builder-tiles-list-item-label">
                  <Typography>{t('product_magazine_tile')}</Typography>
                </div>
              </Link>
              <Link to={`/${ROUTE_MAP.ACCOUNT_SETTINGS}/${SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CUSTOM_CATALOGUE_GRID_TILE].ROUTE}`} target="_self" className="custom-html-builder-tiles-list-item">
                <div className="custom-html-builder-tiles-list-item-icon">
                  <img src={CatalogueGridTileIcon} alt={t('catalogue_grid_tile')} />
                </div>
                <div className="custom-html-builder-tiles-list-item-label">
                  <Typography>{t('catalogue_grid_tile')}</Typography>
                </div>
              </Link>
            </div>
          </div>
        )}
        <div
          className="custom-html-builder-editor-loading"
          style={{ display: loading ? undefined : 'none' }}
        >
          <Loader size="large" />
        </div>
        <Button
          variant="contained"
          color="primary"
          disabled={loading}
          classes={{ root: 'custom-html-builder-modal-close-button' }}
          onClick={onModalClose}
        >
          <CloseIcon className="custom-html-builder-modal-close-icon" />
        </Button>
      </Modal>
      <Prompt message={t('are_you_sure_you_want_to_leave_this_page')} when={!!customComponentType || !!customComponentId} />
    </Fragment>
  );
};
