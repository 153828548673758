import React, { useState, useEffect } from 'react';
import { DialogDarkTheme } from 'qs-components/Common/DarkThemeDialog';
import useMakeQuery from 'qs-hooks/useMakeQuery';
import { ReactComponent as DescriptionIcon } from 'qs-assets/Media/descriptionIcon.svg';
import DescriptionInput from './DescriptionInput';
import './styles.scss';
import { updateCatalogueHeadline } from 'qs-services/DataManager/Catalogues/Headline';
import Loader from 'qs-components/Common/Loader';
import toastr from 'toastr';

import {
  fetchCatalogueHeadlineHandler,
  getCachedCatalogueHeadline,
  handleCatalogueHeadlineCleanup
} from 'qs-helpers/Catalogues/DataQueryHelper';
import { toggleGlobalLoader } from 'qs-helpers';
import { getI18N } from 'qs-services/i18N';

const ADD_HEADLINE = 'add_a_description';
const ERROR_MESSAGE = 'something_went_wrong_while_fetching_the_headline_for_the_catalogue';
const UPDATE_ERROR_MESSAGE = 'something_went_wrong_while_updating_the_headline_for_the_catalogue';

export default ({ catalogueId }) => {
  const [isOpen, setIsOpen] = useState(false);

  const [{ loading, error, data: headlineData }] = useMakeQuery({
    changeDependancy: [catalogueId],
    cachedDataHandler: getCachedCatalogueHeadline,
    actionHandler: fetchCatalogueHeadlineHandler,
    cleanupHandler: handleCatalogueHeadlineCleanup,
    globalLoader: false
  });

  const { t } = getI18N();

  useEffect(() => {
    if (!error) {
      return;
    }
    toastr.error(t(ERROR_MESSAGE));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const updateHeadline = value => async event => {
    event.preventDefault();
    const key = `updateHeadline${Date.now()}`;
    toggleGlobalLoader(key, true);
    try {
      await updateCatalogueHeadline({ catalogueId, headline: value });
    } catch (updateError) {
      toastr.error(t(UPDATE_ERROR_MESSAGE));
    } finally {
      toggleGlobalLoader(key, false);
      toggleOpen();
    }
  };

  if (loading)
    return (
      <div className={'catalogueHeadlineContentContainer'}>
        <Loader size="extraSmall" />
      </div>
    );

  return (
    <>
      <div className={'catalogueHeadlineContentContainer'} onClick={toggleOpen}>
        <DescriptionIcon className={'descriptionLines'} />
        <span className={'ellipsis'}>
          {headlineData && headlineData.headline ? headlineData.headline : t(ADD_HEADLINE)}
        </span>
      </div>
      <DialogDarkTheme open={isOpen} onClose={toggleOpen}>
        <DescriptionInput
          toggleOpen={toggleOpen}
          updateHeadline={updateHeadline}
          headline={(headlineData && headlineData.headline) || ''}
        />
      </DialogDarkTheme>
    </>
  );
};
