import React from 'react';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

export default ({ productCount }) => {
  const { t } = getI18N();
  return (
    <div className={'CatalogueProductCount'}>
      {productCount} {productCount > 1 ? t('product') : t('products')}
    </div>
  );
};
