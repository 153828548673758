import { nativeDB } from '../../DexieInit';
import { reportError } from 'qs-helpers/ErrorReporting';
import * as Sentry from '@sentry/browser';

export const createCatalogueInNative = async (meta, catalogueId) => {
  try {
    const listData = {
      sortTimestamp: Date.now(),
      catalogueId: catalogueId
    };
    const rowMeta = {
      title: meta.title,
      productCount: 0,
      catalogueId
    };

    await Promise.all([
      nativeDB.cataloguesListMeta.put(listData),
      nativeDB.catalogueRowMeta.put(rowMeta, catalogueId)
    ]);
  } catch (error) {
    Sentry.captureException(error);
  }
};

export const updateCatalogueInNative = async (catalogueId, rowData) => {
  try {
    await nativeDB.catalogueRowMeta.put(rowData, catalogueId);
  } catch (error) {
    reportError(error);
  }
};

export const updateCatalogueTitleInNative = async ({ title, catalogueId }) => {
  try {
    await nativeDB.catalogueRowMeta.update(catalogueId, { title });
  } catch (error) {
    Sentry.captureException(error);
  }
};

export const deleteCataloguesFromNative = async catalogueIds => {
  try {
    await Promise.all([
      nativeDB.catalogueRowMeta.bulkDelete(catalogueIds),
      nativeDB.cataloguesListMeta.bulkDelete(catalogueIds)
    ]);
  } catch (error) {
    Sentry.captureException(error);
  }
};

export const upsertCatalogueRowInNative = async (changes, newCataloguesAdded) => {
  try {
    const catalogueIds = Object.keys(changes);
    const newMeta = Object.keys(changes).map(id => changes[id]);
    const promises = [nativeDB.catalogueRowMeta.bulkPut(newMeta, catalogueIds)];

    if (newCataloguesAdded) {
      promises.push(nativeDB.cataloguesListMeta.bulkPut(newCataloguesAdded));
    }

    await Promise.all(promises);
  } catch (error) {
    Sentry.captureException(error);
  }
};

// CATALOGUE TAGS methods:
// TODO: the below method could be merged
export const setCatalogueTagVisibilityInNative = async ({ catalogueId, changes }) => {
  try {
    const { newTagsCache } = changes;
    await nativeDB.catalogueTags.put(newTagsCache, catalogueId);
  } catch (error) {
    Sentry.captureException(error);
  }
};

export const saveSortedTagsInNative = async ({ catalogueId, changes }) => {
  try {
    const { newTagsCache } = changes;
    await nativeDB.catalogueTags.put(newTagsCache, catalogueId);
  } catch (error) {
    Sentry.captureException(error);
  }
};

export const saveTagsChangesInNative = async ({ catalogueId, tags }) => {
  try {
    nativeDB.catalogueTags.put(tags, catalogueId);
  } catch (error) {
    Sentry.captureException(error);
  }
};

const clearCatalogueMetaFromNative = async () => {
  try {
    await nativeDB.catalogueRowMeta.clear();
  } catch (error) {
    Sentry.captureException(error);
  }
};

const clearCatalogueListFromNative = async () => {
  try {
    await nativeDB.catalogueTags.clear();
  } catch (error) {
    // Handle error
  }
};

const clearCataloguetTagsFromNative = async () => {
  try {
    await nativeDB.cataloguesListMeta.clear();
  } catch (error) {
    // Handle error
  }
};

export const clearCatalogueDataFromNative = async () => {
  await Promise.all([
    clearCatalogueMetaFromNative(),
    clearCatalogueListFromNative(),
    clearCataloguetTagsFromNative()
  ]);
};
