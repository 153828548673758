import { PRODUCT_LIB_CATALOGUE_SEARCH_DATA, PRODUCT_LIB_PRODUCT_SEARCH_DATA } from './constants';

const PRODUCT_COMPANY_LAST_FETCHED = {
  ts: undefined,
  key: undefined,
  inProgress: false
};

export const setLastFetchedProductLibTimeStamp = date => {
  if (!date) {
    return;
  }
  PRODUCT_COMPANY_LAST_FETCHED.ts = date;
};

export const getLastFetchedProductLibTimeStamp = () => {
  return PRODUCT_COMPANY_LAST_FETCHED.ts;
};

export const setLastFetchedProductLibKey = key => {
  if (!key) {
    return;
  }
  PRODUCT_COMPANY_LAST_FETCHED.key = key;
};

export const getLastFetchedProductLibKey = () => {
  return PRODUCT_COMPANY_LAST_FETCHED.key;
};

export const clearCompanyProductsLastFetchedResponse = () => {
  PRODUCT_COMPANY_LAST_FETCHED.ts = undefined;
  PRODUCT_COMPANY_LAST_FETCHED.key = undefined;
  PRODUCT_COMPANY_LAST_FETCHED.inProgress = false;
};

export const setPaginatedCallInProg = inProgress => {
  if (typeof inProgress !== 'boolean') {
    return;
  }
  PRODUCT_COMPANY_LAST_FETCHED.inProgress = inProgress;
};

export const isPaginatedCallInProg = () => PRODUCT_COMPANY_LAST_FETCHED.inProgress;

export const removeCurrentCatalogueFromList = ({ catalogueList, catalogueIdToRemove }) => {
  //Either the list is not an array or there is no catalogue to remove,
  //return the original data unchanged
  if (!Array.isArray(catalogueList) || !catalogueIdToRemove) {
    return catalogueList;
  }

  return catalogueList.filter(({ catalogueId }) => {
    if (catalogueId === catalogueIdToRemove) {
      return false;
    }

    return true;
  });
};

export const getFirstEligibleCatalogueId = ({ catalogueList, catalogueIdToExclude }) => {
  if (!Array.isArray(catalogueList)) {
    return '';
  }

  for (const catalogueData of catalogueList) {
    const { catalogueId } = catalogueData;
    //Current catalogue is the same as the one to exclude, go to the next entry
    if (catalogueId === catalogueIdToExclude) {
      continue;
    }
    //Valid entry return id
    return catalogueId;
  }

  return '';
};

export const processCataloguePageIncrement = () => {
  PRODUCT_LIB_CATALOGUE_SEARCH_DATA.currentPage += 1;
};

export const processProductPageIncrement = ({ searchTerm }) => {
  if (!searchTerm) {
    return;
  }
  PRODUCT_LIB_PRODUCT_SEARCH_DATA.currentPage += 1;
};
