import toastr from 'toastr';
import { connector } from './ApiAndCacheConnector';
import CacheRequest from './CacheRequest';
import Api from 'qs-services/Api';
import { deleteProductVideo } from '../Apis/Products/api';
import { getCurrencySymbol, roundNumberToGivenDecimals, toggleGlobalLoader } from '../Helpers';
import { updateExistingProductsInNative } from 'qs-data-manager/Dexie/ProductDexieHelpers';
import {
  getActiveProductId,
  getProductMetaFromCache,
  setProductMetaInCache,
  getProductPositionMapForCatalogue,
  UPDATE_PICTURES_POSITIONS
} from './Products';
import { getCompanyCurrencyCode, getCompanyCurrencySymbol } from './Company';
import { getActiveCatalogueId } from './Catalogues';
import * as Sentry from '@sentry/browser';
import { reportError } from 'qs-helpers/ErrorReporting';
import { updateProductWeightData } from './Products/Weights';
import { SAVE_BUTTON_META } from 'qs-helpers/Products/constants';
import { updateCacheForProductSet } from 'qs-services/DataManager/Products/ProductSet';
import { updateMoqForEntity } from './Moq/MoqOperations';
import { MOQ_CATALOGUE_ENTITY, MOQ_PRODUCT_ENTITY } from 'qs-helpers/Moq/constants';
import {
  JEWELLERY,
  METAL_TYPES,
  updateCacheForJewelleryProductTypeDetails
} from 'qs-components/Catalogue/ProductDetailsScreen/ActiveTabMeta/BasicInfo/JewelleryProductPrices/ProductTypeDetails';
import eventbus from 'eventing-bus';
import { getProductTypeDetailsForProductOrVariantFromCache } from './Variants/VariantsDetails';
import { getI18N } from 'qs-services/i18N';

// PRIVATE NOTES api call
const attachPrivateNotesListener = (listener, productId) => {
  const key = `${connector.PRIVATE_NOTES.cacheKey}${productId}`;
  CacheRequest.attachListener(key, listener);
};

const removePrivateNotesListener = (listener, productId) => {
  const key = `${connector.PRIVATE_NOTES.cacheKey}${productId}`;
  CacheRequest.removeListener(key, listener);
};

const getPrivateNotes = productId => {
  const key = `${connector.PRIVATE_NOTES.cacheKey}${productId}`;
  const apiName = connector.PRIVATE_NOTES.apiFunction;
  CacheRequest.makeRequest(key, apiName, {
    params: [productId],
    options: {
      shouldNotStoreInNative: true
    }
  });
};

// Inventory listener
const attachInventoryListener = ({ listener, productId }) => {
  const key = `${connector.INVENTORY.cacheKey}${productId}`;
  CacheRequest.attachListener(key, listener);
};

const removeInventoryListener = ({ listener, productId }) => {
  const key = `${connector.INVENTORY.cacheKey}${productId}`;
  CacheRequest.removeListener(key, listener);
};

const getInventory = ({ productId }) => {
  const key = `${connector.INVENTORY.cacheKey}${productId}`;
  const apiName = connector.INVENTORY.apiFunction;
  CacheRequest.makeRequest(key, apiName, {
    params: [productId],
    options: {
      shouldNotStoreInNative: true
    }
  });
};

const getGoldRatesKey = () => `${connector.GOLD_RATES_AND_PRODUCT_TYPE.cacheKey}`;

//Product Type and Gold Rates api call
const getGoldRatesAndProductType = () => {
  const key = getGoldRatesKey();
  const apiName = connector.GOLD_RATES_AND_PRODUCT_TYPE.apiFunction;
  CacheRequest.makeRequest(key, apiName, {
    params: [],
    options: {
      shouldNotStoreInNative: true
    }
  });
};
const getGoldRatesAndProductTypeFromCache = () => {
  const key = getGoldRatesKey();
  return CacheRequest.getCacheForKey(key) || {};
};
const attachGoldRatesAndProductTypeListener = ({ listener }) => {
  const key = getGoldRatesKey();
  CacheRequest.attachListener(key, listener);
};

const removeGoldRatesAndProductTypeListener = ({ listener }) => {
  const key = getGoldRatesKey();
  CacheRequest.removeListener(key, listener);
};
// Basic info api call
const attachBasicInfoListener = ({ listener, productId }) => {
  const key = `${connector.BASIC_INFO.cacheKey}${productId}`;
  CacheRequest.attachListener(key, listener);
};

const removeBasicInfoListener = ({ listener, productId }) => {
  const key = `${connector.BASIC_INFO.cacheKey}${productId}`;
  CacheRequest.removeListener(key, listener);
};

const getBasicInfoFromRemote = ({ productId }) => {
  const key = `${connector.BASIC_INFO.cacheKey}${productId}`;
  const apiName = connector.BASIC_INFO.apiFunction;
  CacheRequest.makeRequest(key, apiName, {
    params: [productId],
    options: {
      shouldNotStoreInNative: true
    }
  });
};

const getBasicInfoFromCache = ({ productId }) => {
  const key = `${connector.BASIC_INFO.cacheKey}${productId}`;
  return CacheRequest.getCacheForKey(key) || undefined;
};

const updateBasicInfoInCache = ({ data, productId }) => {
  const key = `${connector.BASIC_INFO.cacheKey}${productId}`;
  const updatedCacheData = {
    ...getBasicInfoFromCache({ productId }),
    ...data
  };
  CacheRequest.setCacheForKey(key, updatedCacheData);
};

const getProductSetInfoFromCache = ({ productId }) => {
  const cache = getBasicInfoFromCache({ productId });
  if (!cache) {
    return;
  }

  return {
    isSet: cache.isSet,
    setName: cache.setName,
    setQuantity: cache.setQuantity,
    setType: cache.setType
  };
};

const getProductTypeDetailsFromCache = ({ productId }) => {
  const cache = getBasicInfoFromCache({ productId });
  if (!cache) {
    return;
  }
  return cache.productTypeDetails
    ? { ...cache.productTypeDetails }
    : {
        entityLabel: '',
        entityType: '',
        entityQuality: null,
        grossWeight: null,
        netWeight: null,
        makingChargeType: '',
        makingCharge: null,
        otherCharges: [],
        wastagePercent: null,
        wastageOn: ''
      };
};

const getProductStoneValuesFromCache = ({ productId }) => {
  const cache = getBasicInfoFromCache({ productId });
  if (!cache) {
    return;
  }
  return cache.productTypeRateCharges || [];
};

const updateProductStoneDetailsInCache = ({ productId, productTypeRateCharges, price }) => {
  const key = `${connector.BASIC_INFO.cacheKey}${productId}`;
  let cache = CacheRequest.getCacheForKey(key);
  if (cache) {
    CacheRequest.setCacheForKey(key, {
      ...cache,
      productTypeRateCharges: productTypeRateCharges
    });
  }
  setProductMetaInCache({ productId, updates: { price: price, discount: null } });
};

const setProductStoneDetails = async ({ formData, productId }) => {
  try {
    const data = await Api.setProductStoneDetails(formData);
    const { productType } = getGoldRatesAndProductTypeFromCache({ productId }) || {};

    if (getProductIsSlabFromCache({ productId }) && productType === JEWELLERY.id) {
      await deleteSlabPricesForProduct({ productId });
    }

    return data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const updateProductStoneDetails = async formData => {
  try {
    const data = await Api.updateProductStoneDetails(formData);
    return data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

export const isProductTypeDetailsChanged = ({ productTypeDetails, originalProductTypeDetails }) => {
  const {
    grossWeight,
    entityLabel,
    entityQuality,
    entityType,
    makingCharge,
    makingChargeType,
    netWeight,
    otherCharges = [],
    wastagePercent,
    wastageOn
  } = productTypeDetails || {};
  const {
    grossWeight: originalGrossWeight,
    entityLabel: originalEntityLabel,
    entityQuality: originalEntityQuality,
    entityType: originalEntityType,
    makingCharge: originalMakingCharge,
    makingChargeType: originalMakingChargeType,
    netWeight: originalNetWeight,
    otherCharges: originalOtherCharges = [],
    wastagePercent: originalWastagePercent,
    wastageOn: originalWastageOn
  } = originalProductTypeDetails || {};
  if (
    grossWeight !== originalGrossWeight ||
    entityLabel !== originalEntityLabel ||
    entityQuality !== originalEntityQuality ||
    netWeight !== originalNetWeight ||
    entityType !== originalEntityType ||
    makingCharge !== originalMakingCharge ||
    makingChargeType !== originalMakingChargeType ||
    wastagePercent !== originalWastagePercent ||
    wastageOn !== originalWastageOn
  ) {
    return true;
  }

  const areOfEqualLengths = (otherCharges || []).length === (originalOtherCharges || []).length;
  const allValuesAvailable = (otherCharges || []).every(({ type, amount }) =>
    (originalOtherCharges || []).find(
      ({ type: oType, amount: oAmount }) => type === oType && amount === oAmount
    )
  );

  return !areOfEqualLengths || !allValuesAvailable;
};

const deleteProductStoneDetails = async ({ productId, id }) => {
  try {
    const data = await Api.deleteProductStoneDetails({ productId, id });
    return data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

// Gets initial state of product details screen
const productDetailsInitialState = (productIds = []) => {
  if (productIds.length > 1) {
    return {
      error: null,
      loading: false,
      refreshing: false,
      privateNotes: '',
      changedPrivateNotes: '',
      showSaveButton: false
    };
  }
  const productId = productIds[0];
  const key = `${connector.PRIVATE_NOTES.cacheKey}${productId}`;
  const cache = CacheRequest.getCacheForKey(key);
  const loading = !cache;
  const privateNotes = loading ? '' : cache.privateNote;

  return {
    error: null,
    loading,
    refreshing: true,
    privateNotes,
    changedPrivateNotes: privateNotes,
    showSaveButton: false
  };
};

const saveSingleProductChanges = async ({ data, productId, parentProductId }) => {
  const loaderKey = `saveSingleProductChanges${Date.now()}`;
  toggleGlobalLoader(loaderKey, true);
  const promises = [];
  const changes = {};
  const setChanges = {};
  const moqChange = {};
  let productTypeDetails = {
    ...getProductTypeDetailsForProductOrVariantFromCache({
      productId,
      parentProductId
    })
  };
  let productTypeDetailsValueHasChanged = false;

  const { t } = getI18N();

  const { productType } = getGoldRatesAndProductTypeFromCache({ productId }) || {};
  if (data && typeof data[SAVE_BUTTON_META.PRODUCT_CUSTOM_FIELD.id] !== 'undefined') {
    const customFields = data[SAVE_BUTTON_META.PRODUCT_CUSTOM_FIELD.id];
    Object.keys(customFields).forEach(key => {
      if (customFields[key].value !== null) {
        let { value: fieldValue, type } = customFields[key];

        if ((type === 'STRING' || type === 'NUMBER') && fieldValue === '') {
          promises.push(
            Api.deleteProductCustomField({
              fieldId: key,
              productId
            })
          );
        } else {
          if (type === 'NUMBER') {
            fieldValue = Number(fieldValue);
          }
          promises.push(
            Api.setProductCustomFieldData({
              fieldValue,
              fieldId: key,
              productId
            })
          );
        }
      }
    });
  }

  if (data && typeof data[SAVE_BUTTON_META.PRIVATE_NOTES.id] !== 'undefined') {
    const privateNotes = data[SAVE_BUTTON_META.PRIVATE_NOTES.id];
    const key = `${connector.PRIVATE_NOTES.cacheKey}${productId}`;
    CacheRequest.setCacheForKey(key, { privateNotes });
    promises.push(Api.setPrivateNotes([productId], privateNotes));
  }

  if (data && typeof data[SAVE_BUTTON_META.PRODUCT_TITLE.id] !== 'undefined') {
    changes.name = data[SAVE_BUTTON_META.PRODUCT_TITLE.id];
  }

  if (data && typeof data[SAVE_BUTTON_META.PRODUCT_PRICE.id] !== 'undefined') {
    changes.price = data[SAVE_BUTTON_META.PRODUCT_PRICE.id];
  }

  if (data && typeof data[SAVE_BUTTON_META.PRODUCT_DISCOUNT.id] !== 'undefined') {
    changes.discount = data[SAVE_BUTTON_META.PRODUCT_DISCOUNT.id];
  }

  if (data && typeof data[SAVE_BUTTON_META.PRODUCT_DESCRIPTION.id] !== 'undefined') {
    changes.description = data[SAVE_BUTTON_META.PRODUCT_DESCRIPTION.id];
  }

  if (data && typeof data[SAVE_BUTTON_META.PRODUCT_SKU.id] !== 'undefined') {
    changes.sku = data[SAVE_BUTTON_META.PRODUCT_SKU.id];
  }

  if (data && typeof data[SAVE_BUTTON_META.PRODUCT_WEIGHT_FIELD.id] !== 'undefined') {
    const weight = data[SAVE_BUTTON_META.PRODUCT_WEIGHT_FIELD.id];
    // Empty string reset the value and send it
    if (weight === '') {
      changes.weight = null;
    } else {
      changes.weight = Number(weight);
    }
  }

  if (data && typeof data[SAVE_BUTTON_META.PRODUCT_MOQ_FIELD.id] !== 'undefined') {
    const moq = data[SAVE_BUTTON_META.PRODUCT_MOQ_FIELD.id];
    // Empty string reset the value and send it
    if (moq === '') {
      moqChange.minOrderQuantity = null;
      moqChange.entityType = MOQ_CATALOGUE_ENTITY;
    } else {
      moqChange.minOrderQuantity = Number(moq);
      moqChange.entityType = MOQ_PRODUCT_ENTITY;
    }
    promises.push(
      updateMoqForEntity({
        entityId: productId,
        entityType: MOQ_PRODUCT_ENTITY,
        moqChange
      })
    );
  }

  if (data && typeof data[SAVE_BUTTON_META.PRODUCT_SET_FIELD.id] !== 'undefined') {
    setChanges.isSet = data[SAVE_BUTTON_META.PRODUCT_SET_FIELD.id];
  }

  if (data && typeof data[SAVE_BUTTON_META.PRODUCT_SET_TYPE.id] !== 'undefined') {
    setChanges.setType = data[SAVE_BUTTON_META.PRODUCT_SET_TYPE.id];
    setChanges.isSet = true;
  }

  if (data && typeof data[SAVE_BUTTON_META.PRODUCT_SET_NAME_FIELD.id] !== 'undefined') {
    setChanges.setName = data[SAVE_BUTTON_META.PRODUCT_SET_NAME_FIELD.id];
  }

  if (data && typeof data[SAVE_BUTTON_META.PRODUCT_SET_QTY_FIELD.id] !== 'undefined') {
    setChanges.setQuantity = data[SAVE_BUTTON_META.PRODUCT_SET_QTY_FIELD.id];
  }

  if (data && typeof data[SAVE_BUTTON_META.PRODUCT_ENTITY_LABEL.id] !== 'undefined') {
    productTypeDetails = {
      ...productTypeDetails,
      entityLabel: data[SAVE_BUTTON_META.PRODUCT_ENTITY_LABEL.id]
    };
    productTypeDetailsValueHasChanged = true;
  }

  if (data && typeof data[SAVE_BUTTON_META.PRODUCT_ENTITY_TYPE.id] !== 'undefined') {
    productTypeDetails = {
      ...productTypeDetails,
      entityType: data[SAVE_BUTTON_META.PRODUCT_ENTITY_TYPE.id]
    };
    productTypeDetailsValueHasChanged = true;
  }

  if (data && typeof data[SAVE_BUTTON_META.PRODUCT_ENTITY_QUALITY.id] !== 'undefined') {
    productTypeDetails = {
      ...productTypeDetails,
      entityQuality: data[SAVE_BUTTON_META.PRODUCT_ENTITY_QUALITY.id]
    };
    productTypeDetailsValueHasChanged = true;
  }

  if (data && typeof data[SAVE_BUTTON_META.PRODUCT_GROSS_WEIGHT.id] !== 'undefined') {
    productTypeDetails = {
      ...productTypeDetails,
      grossWeight: data[SAVE_BUTTON_META.PRODUCT_GROSS_WEIGHT.id]
    };
    productTypeDetailsValueHasChanged = true;
  }

  if (data && typeof data[SAVE_BUTTON_META.PRODUCT_NET_WEIGHT.id] !== 'undefined') {
    productTypeDetails = {
      ...productTypeDetails,
      netWeight: data[SAVE_BUTTON_META.PRODUCT_NET_WEIGHT.id]
    };
    productTypeDetailsValueHasChanged = true;
  }

  if (data && typeof data[SAVE_BUTTON_META.PRODUCT_MAKING_CHARGE_TYPE.id] !== 'undefined') {
    if (!productTypeDetails.makingCharge && typeof productTypeDetails.makingCharge !== 'number') {
      productTypeDetails = {
        ...productTypeDetails,
        makingChargeType: '',
        makingCharge: null
      };
      productTypeDetailsValueHasChanged = true;
    } else {
      productTypeDetails = {
        ...productTypeDetails,
        ...data[SAVE_BUTTON_META.PRODUCT_MAKING_CHARGE_TYPE.id]
      };
      productTypeDetailsValueHasChanged = true;
    }
  }

  if (data && typeof data[SAVE_BUTTON_META.PRODUCT_MAKING_CHARGE.id] !== 'undefined') {
    if (
      !data[SAVE_BUTTON_META.PRODUCT_MAKING_CHARGE.id].makingCharge &&
      typeof data[SAVE_BUTTON_META.PRODUCT_MAKING_CHARGE.id].makingCharge !== 'number'
    ) {
      productTypeDetails = {
        ...productTypeDetails,
        makingCharge: null,
        makingChargeType: ''
      };
      productTypeDetailsValueHasChanged = true;
    } else {
      productTypeDetails = {
        ...productTypeDetails,
        ...data[SAVE_BUTTON_META.PRODUCT_MAKING_CHARGE.id]
      };
      productTypeDetailsValueHasChanged = true;
    }
  }

  if (data && typeof data[SAVE_BUTTON_META.PRODUCT_WASTAGE_CHARGE.id] !== 'undefined') {
    if (
      !data[SAVE_BUTTON_META.PRODUCT_WASTAGE_CHARGE.id].wastagePercent &&
      typeof data[SAVE_BUTTON_META.PRODUCT_WASTAGE_CHARGE.id].wastagePercent !== 'number'
    ) {
      productTypeDetails = {
        ...productTypeDetails,
        wastageOn: '',
        wastagePercent: null
      };
      productTypeDetailsValueHasChanged = true;
    } else {
      productTypeDetails = {
        ...productTypeDetails,
        wastageOn: productTypeDetails.wastageOn || 'NET_WEIGHT',
        ...data[SAVE_BUTTON_META.PRODUCT_WASTAGE_CHARGE.id]
      };
      productTypeDetailsValueHasChanged = true;
    }
  }

  if (data && typeof data[SAVE_BUTTON_META.PRODUCT_WASTAGE_CHARGE_TYPE.id] !== 'undefined') {
    if (!data[SAVE_BUTTON_META.PRODUCT_WASTAGE_CHARGE_TYPE.id].wastageOn) {
      productTypeDetails = {
        ...productTypeDetails,
        wastageOn: '',
        wastagePercent: null
      };
      productTypeDetailsValueHasChanged = true;
    } else {
      productTypeDetails = {
        ...productTypeDetails,
        ...data[SAVE_BUTTON_META.PRODUCT_WASTAGE_CHARGE_TYPE.id]
      };
      productTypeDetailsValueHasChanged = true;
    }
  }

  if (data && typeof data[SAVE_BUTTON_META.PRODUCT_OTHER_CHARGES.id] !== 'undefined') {
    const updatedList = [...data[SAVE_BUTTON_META.PRODUCT_OTHER_CHARGES.id]].map(otherCharges => {
      if (otherCharges.amount) {
        otherCharges.amount = Number(otherCharges.amount);
      }
      return otherCharges;
    });
    productTypeDetails = {
      ...productTypeDetails,
      otherCharges: [...updatedList]
    };
    productTypeDetailsValueHasChanged = true;
  }

  let isAllProductTypeDetailsFieldsEmpty = Object.keys(productTypeDetails).every(key => {
    if (key !== 'otherCharges' && !productTypeDetails[key]) {
      return true;
    } else if (key === 'otherCharges' && (productTypeDetails.key || []).length === 0) {
      return true;
    }
    return false;
  });

  if (isAllProductTypeDetailsFieldsEmpty) {
    productTypeDetails = null;
  }

  if (!isAllProductTypeDetailsFieldsEmpty) {
    if (!productTypeDetails.entityType) {
      productTypeDetails = { ...productTypeDetails, entityType: METAL_TYPES.GOLD };
    }
    if (!productTypeDetails.entityLabel) {
      productTypeDetails = { ...productTypeDetails, entityLabel: 'METAL' };
    }
  }

  if (
    getProductIsSlabFromCache({ productId }) &&
    productType === JEWELLERY.id &&
    productTypeDetails
  ) {
    promises.push(deleteSlabPricesForProduct({ productId }));
  }

  if (
    productType === JEWELLERY.id &&
    productTypeDetailsValueHasChanged &&
    isAllProductTypeDetailsFieldsEmpty &&
    !changes.price
  ) {
    changes.price = null;
    changes.discount = null;
  }

  if (productType === JEWELLERY.id && productTypeDetailsValueHasChanged) {
    setChanges.isSet = null;
    setChanges.setType = null;
    setChanges.setName = null;
    setChanges.setQuantity = null;
    changes.discount = null;
  }

  const modifications = [
    {
      productId,
      changes,
      setChanges,
      productTypeDetails,
      ...(productType === JEWELLERY.id && productTypeDetailsValueHasChanged
        ? {
            discounted_price: { discounted_price: null }
          }
        : {})
    }
  ];

  promises.push(updateProductData(modifications));

  try {
    await Promise.all(promises);
  } catch (error) {
    toastr.error(t('something_went_wrong_while_updating_the_product_data'));
  } finally {
    toggleGlobalLoader(loaderKey, false);
  }
};

const saveProductDetailsChanges = ({ data = {} }) => {
  const productId = getActiveProductId();
  if (!productId) {
    return;
  }
  saveSingleProductChanges({ data, productId });
};

const computeBasicInfoFromProductRow = productRowMetaCache => {
  const basicInfo = {};

  const currencyCode = getCompanyCurrencyCode();
  basicInfo.currencySymbol = getCurrencySymbol({ currencyCode });

  if (!productRowMetaCache) {
    return basicInfo;
  }

  basicInfo.title = productRowMetaCache.name || null;

  basicInfo.price =
    typeof productRowMetaCache.price === 'number' ? productRowMetaCache.price : null;

  basicInfo.discount =
    typeof productRowMetaCache.discount === 'number' ? productRowMetaCache.discount : null;

  basicInfo.description = productRowMetaCache.description ? productRowMetaCache.description : null;

  basicInfo.sku = typeof productRowMetaCache.sku !== 'undefined' ? productRowMetaCache.sku : null;

  basicInfo.weight =
    typeof productRowMetaCache.weight === 'number' ? productRowMetaCache.weight : null;

  return basicInfo;
};

const getBasicInfo = ({ activeProductId = '', isBulkEditing = '' }) => {
  if (isBulkEditing) {
    return {
      title: null,
      currencyCode: getCompanyCurrencyCode() || null,
      currencySymbol: getCompanyCurrencySymbol() || null,
      price: null,
      discount: null,
      description: null,
      weight: null,
      productTypeDetails: null
    };
  }

  const productRowMetaCache = CacheRequest.getCacheForKey(
    `${connector.PRODUCT_ROW_META.cacheKey}${activeProductId}`
  );
  return computeBasicInfoFromProductRow(productRowMetaCache);
};

const formatProductTypeDetailsFromJewellery = productTypeDetails => {
  if (!productTypeDetails) {
    return productTypeDetails;
  }

  const convertToNumber = strNum => (strNum ? Number(strNum) : strNum);
  let { grossWeight, netWeight, makingCharge, wastagePercent } = productTypeDetails;

  grossWeight = convertToNumber(grossWeight);
  netWeight = convertToNumber(netWeight);
  makingCharge = convertToNumber(makingCharge);
  wastagePercent = convertToNumber(wastagePercent);

  return {
    ...productTypeDetails,
    grossWeight,
    netWeight,
    makingCharge,
    wastagePercent
  };
};

const updateProductData = async (meta = []) => {
  const loaderkey = `updateProductData${Date.now()}`;
  toggleGlobalLoader(loaderkey, true);
  const sharedCacheKey = connector.PRODUCT_ROW_META.cacheKey;
  const sharedDetailsCacheKey = connector.BASIC_INFO.cacheKey;
  const modifications = [];

  meta.forEach(({ productId, changes, setChanges, productTypeDetails } = {}) => {
    updateCacheForProductSet({ setData: setChanges, productId });
    const { productType } = getGoldRatesAndProductTypeFromCache({ productId }) || {};
    if (productType === JEWELLERY.id) {
      updateCacheForJewelleryProductTypeDetails({
        productTypeDetails,
        productId
      });
    }

    if (!productId || !Object.keys(changes).length) {
      return;
    }

    if (changes.price === null) {
      changes.discount = null;
    }

    if (typeof changes.weight !== 'undefined') {
      updateProductWeightData({ productId, weight: changes.weight });
    }

    let key = `${sharedCacheKey}${productId}`;
    let cache = CacheRequest.getCacheForKey(key);
    const newCache = { ...cache, ...changes };

    modifications.push(newCache);
    CacheRequest.setCacheForKey(key, newCache);

    key = `${sharedDetailsCacheKey}${productId}`;
    cache = CacheRequest.getCacheForKey(key);
    if (cache) {
      CacheRequest.setCacheForKey(key, {
        ...cache,
        ...changes
      });
    }
  });

  const updatePromises = [];

  const updates = {
    ...meta[0].changes,
    ...meta[0].setChanges,
    productTypeDetails: formatProductTypeDetailsFromJewellery(meta[0].productTypeDetails),
    ...meta[0].discounted_price
  };
  if (Object.keys(updates).length !== 0) {
    updatePromises.push(
      Api.updateProduct({
        productId: meta[0].productId,
        updates
      })
    );
  }

  if (modifications.length) {
    updatePromises.push(updateExistingProductsInNative(modifications));
  }

  toggleGlobalLoader(loaderkey, false);
  return Promise.all(updatePromises);
};

const getDiscountInitDependencies = productId => {
  const key = `${connector.PRODUCT_ROW_META.cacheKey}${productId}`;
  const { price, discount } = CacheRequest.getCacheForKey(key) || {};

  return {
    originalPrice: price,
    discount
  };
};

export const getImagesStatus = ({ activeProductIds }) => {
  const ids = activeProductIds.slice(0, 12);
  return ids.map(id => {
    const key = `${connector.PRODUCT_ROW_META.cacheKey}${id}`;
    const defaultPicture = CacheRequest.getCacheForKey(key) || {};
    return {
      id: defaultPicture.pictureId,
      prepared: defaultPicture.isPrepared,
      error: defaultPicture.defaultImageErrored,
      url: defaultPicture.pictureUrl
    };
  });
};

export const getNextEligibleImage = productId => {
  const cacheKey = `${connector.BASIC_INFO.cacheKey}${productId}`;
  const { pictures } = CacheRequest.getCacheForKey(cacheKey) || {};
  if (pictures) {
    const nextImage = Object.values(pictures)[0];
    if (nextImage) {
      return nextImage;
    }
  }

  /*
    The basic info cache does not have the images data, hence get the default image
    data from the meta cache. This will ensure that the correct default image always
    exists
  */
  return getImagesStatus({ activeProductIds: [productId] })[0];
};

const deleteCurrentImage = async ({ productId, currentImage }) => {
  try {
    await Api.deleteProductPicture({ productId, pictureIds: [currentImage.id] });
  } catch (remoteDeleteError) {
    reportError(remoteDeleteError);
    throw remoteDeleteError;
  }

  const cacheKey = `${connector.BASIC_INFO.cacheKey}${productId}`;
  const basicInfo = CacheRequest.getCacheForKey(cacheKey);
  const { pictures } = basicInfo;
  for (const id in pictures) {
    if (pictures.hasOwnProperty(id) && id === currentImage.id) {
      delete pictures[id];
      break;
    }
  }

  CacheRequest.setCacheForKey(cacheKey, { ...basicInfo, pictures });

  const { pictureCount } = getProductMetaFromCache(productId) || {};
  if (typeof pictureCount !== 'number' || pictureCount === 0) {
    return;
  }
  setProductMetaInCache({ productId, updates: { pictureCount: pictureCount - 1 } });
};

const deleteCurrentVideo = async ({ productId, currentVideoId }) => {
  try {
    await deleteProductVideo(currentVideoId);
  } catch (error) {
    reportError(error);
    throw error;
  }

  const cacheKey = `${connector.BASIC_INFO.cacheKey}${productId}`;
  const basicInfo = CacheRequest.getCacheForKey(cacheKey);
  const { videos } = basicInfo;
  for (const id in videos) {
    if (videos.hasOwnProperty(id) && id === currentVideoId) {
      delete videos[id];
      break;
    }
  }

  CacheRequest.setCacheForKey(cacheKey, { ...basicInfo, videos });

  const { videoCount } = getProductMetaFromCache(productId) || {};
  if (typeof videoCount !== 'number' || videoCount === 0) {
    return;
  }
  setProductMetaInCache({ productId, updates: { videoCount: videoCount - 1 } });
};

// Save private notes
const savePrivateNotesInLocal = ({ notes, productIds }) => {
  const sharedCacheKey = connector.PRIVATE_NOTES.cacheKey;
  productIds.forEach(id => {
    const key = `${sharedCacheKey}${id}`;
    CacheRequest.setCacheForKey(key, notes);
  });
};

const savePrivateNotesInRemote = ({ notes, productIds }) => {
  return Api.setPrivateNotes(productIds, notes);
};

const getSlabPricesForProduct = async ({ productId }) => {
  try {
    const cachedSlabs = getProductSlabPricesFromCache({ productId });
    const cachedIsSlab = getProductIsSlabFromCache({ productId });
    if (cachedSlabs && cachedSlabs.length) {
      return { slabPrices: cachedSlabs, isSlab: cachedIsSlab };
    }
    const { slabPrices, isSlab } = await Api.getSlabPricesForProduct({ productId });
    setProductSlabPricesInCache({ productId, slabPrices, isSlab });
    return { slabPrices, isSlab };
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const setSlabPricesForProduct = async ({ productId, slabPrices }) => {
  try {
    await Api.setSlabPricesForProduct({ productId, slabPrices });
    setProductSlabPricesInCache({ productId, slabPrices, isSlab: true });
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const deleteSlabPricesForProduct = async ({ productId }) => {
  try {
    await Api.deleteSlabPricesForProduct({ productId });
    setProductSlabPricesInCache({ productId, slabPrices: null, isSlab: null });
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const savePrivateNotes = async ({ notes, productIds }) => {
  const loaderKey = `savePrivateNotes${Date.now()}`;
  try {
    toggleGlobalLoader(loaderKey, true);

    savePrivateNotesInLocal({ notes, productIds });
    await savePrivateNotesInRemote({ notes, productIds });

    toggleGlobalLoader(loaderKey, false);
  } catch (error) {
    toggleGlobalLoader(loaderKey, false);
    Sentry.captureException(error);
  }
};

const getInventoryFromCache = ({ productId, isBulkEditing }) => {
  const defaultObject = {
    stockCount: 1,
    trackInventory: null,
    autoReduce: null,
    showOutOfStockProduct: null,
    stockManagedFrom: null,
    allowOrderOutOfStock: null,
    orderOnOOSControlledFrom: null,
    isSet: false,
    setQuantity: null,
    setType: null,
    availableInCache: false
  };

  if (!productId || isBulkEditing) {
    return defaultObject;
  }

  const cacheKey = `${connector.INVENTORY.cacheKey}${productId}`;
  const cache = CacheRequest.getCacheForKey(cacheKey);
  if (!cache) {
    return defaultObject;
  }

  return {
    trackInventory: cache.trackInventory,
    stockCount: typeof cache.count === 'number' ? cache.count : 1,
    autoReduce: cache.autoReduceInventory === 'VISITOR',
    showOutOfStockProduct:
      typeof cache.showOutOfStockProduct === 'boolean'
        ? cache.showOutOfStockProduct
        : cache.catalogueInventory.showOutOfStockProduct,
    stockManagedFrom: typeof cache.showOutOfStockProduct === 'boolean' ? 'PRODUCT' : 'CATALOGUE',
    allowOrderOutOfStock:
      typeof cache.allowOrdersOnOutOfStock === 'boolean'
        ? cache.allowOrdersOnOutOfStock
        : cache.catalogueInventory.allowOrdersOnOutOfStock,
    orderOnOOSControlledFrom:
      typeof cache.allowOrdersOnOutOfStock === 'boolean' ? 'PRODUCT' : 'CATALOGUE',
    isSet: cache.setDetails ? cache.setDetails.isSet : false,
    setQuantity: cache.setDetails ? cache.setDetails.setQuantity : null,
    setType: cache.setDetails ? cache.setDetails.setType : null,
    availableInCache: true
  };
};

const getInventoryCatalogueDefaultsFromCache = productId => {
  const cacheKey = `${connector.INVENTORY.cacheKey}${productId}`;
  const cache = CacheRequest.getCacheForKey(cacheKey);
  if (!cache) {
    return {};
  }

  return {
    allowOrderOutOfStock: cache.catalogueInventory.allowOrdersOnOutOfStock,
    showOutOfStockProduct: cache.catalogueInventory.showOutOfStockProduct
  };
};

const setProductSlabPricesInCache = ({ productId, slabPrices, isSlab }) => {
  const key = `${connector.PRODUCT_ROW_META.cacheKey}${productId}`;
  const cache = CacheRequest.getCacheForKey(key) || {};
  CacheRequest.setCacheForKey(key, {
    ...cache,
    isSlab: isSlab !== undefined ? isSlab : cache.isSlab,
    slabPrices: { prices: slabPrices }
  });
};

const getProductIsSlabFromCache = ({ productId }) => {
  const key = `${connector.PRODUCT_ROW_META.cacheKey}${productId}`;
  const cache = CacheRequest.getCacheForKey(key) || {};
  return (cache || {}).isSlab;
};

const getProductSlabPricesFromCache = ({ productId }) => {
  const key = `${connector.PRODUCT_ROW_META.cacheKey}${productId}`;
  const cache = CacheRequest.getCacheForKey(key) || {};
  const { prices } = cache.slabPrices || {};
  // deep copying
  return prices ? JSON.parse(JSON.stringify(prices)) : prices;
};

const getProductPriceFromCache = ({ productId }) => {
  const key = `${connector.PRODUCT_ROW_META.cacheKey}${productId}`;
  const cache = CacheRequest.getCacheForKey(key) || {};
  return cache.price || null;
};

const getProductDiscountFromCache = ({ productId }) => {
  const key = `${connector.PRODUCT_ROW_META.cacheKey}${productId}`;
  const cache = CacheRequest.getCacheForKey(key) || {};
  return cache.discount || null;
};

const bulkSaveTitle = async ({ productIds, title }) => {
  const loaderKey = `bulkSaveTitle${Date.now()}`;
  toggleGlobalLoader(loaderKey, true);
  try {
    const updates = { name: title };
    const nativeStorageUpdates = [];

    productIds.forEach(id => {
      const key = `${connector.PRODUCT_ROW_META.cacheKey}${id}`;
      const cache = CacheRequest.getCacheForKey(key);
      const newCache = {
        ...cache,
        ...updates
      };
      nativeStorageUpdates.push(newCache);
      CacheRequest.setCacheForKey(key, newCache);
    });

    await Promise.all([
      Api.bulkUpdateProducts({
        productIds,
        updates
      }),
      updateExistingProductsInNative(nativeStorageUpdates)
    ]);
    toggleGlobalLoader(loaderKey, false);
  } catch (error) {
    toggleGlobalLoader(loaderKey, false);
    Sentry.captureException(error);
  }
};

const bulkSavePrice = async ({ productIds, price }) => {
  const loaderKey = `bulkSavePrice${Date.now()}`;
  toggleGlobalLoader(loaderKey, true);
  const updates = { price: price };
  const nativeStorageUpdates = [];

  try {
    productIds.forEach(id => {
      const key = `${connector.PRODUCT_ROW_META.cacheKey}${id}`;
      const cache = CacheRequest.getCacheForKey(key);
      const newCache = {
        ...cache,
        ...updates
      };
      nativeStorageUpdates.push(newCache);
      CacheRequest.setCacheForKey(key, newCache);
    });

    await Promise.all([
      Api.bulkUpdateProductsPrice({
        productIds,
        price
      }),
      updateExistingProductsInNative(nativeStorageUpdates)
    ]);

    toggleGlobalLoader(loaderKey, false);
  } catch (error) {
    toggleGlobalLoader(loaderKey, false);
    Sentry.captureException(error);
  }
};

const bulkSaveDiscount = async ({ productIds, discountInPercent }) => {
  const loaderKey = `bulkSaveDiscount${Date.now()}`;
  toggleGlobalLoader(loaderKey, true);

  try {
    await Api.bulkUpdatePriceAndDiscount({ productIds, discountInPercent });

    //Update cache and native storage
    const nativeStorageUpdates = [];
    productIds.forEach(id => {
      const key = `${connector.PRODUCT_ROW_META.cacheKey}${id}`;
      const cache = CacheRequest.getCacheForKey(key);
      if (cache && typeof cache.price === 'number') {
        let discount = null;
        if (typeof discountInPercent === 'number') {
          discount = roundNumberToGivenDecimals(((100 - discountInPercent) / 100) * cache.price, 2);
        }
        const newCache = {
          ...cache,
          discount
        };
        nativeStorageUpdates.push(newCache);
        CacheRequest.setCacheForKey(key, newCache);
      }
    });

    updateExistingProductsInNative(nativeStorageUpdates).catch(() => {
      //no-op added just to prevent unhandled rejections
    });
  } catch (error) {
    reportError(error);
  } finally {
    toggleGlobalLoader(loaderKey, false);
  }
};

const bulkSaveDescription = async ({ productIds, description }) => {
  const loaderKey = `bulkSaveDescription${Date.now()}`;
  toggleGlobalLoader(loaderKey, true);
  const updates = {
    description
  };
  const nativeStorageUpdates = [];

  try {
    productIds.forEach(id => {
      const key = `${connector.PRODUCT_ROW_META.cacheKey}${id}`;
      const cache = CacheRequest.getCacheForKey(key);
      const newCache = {
        ...cache,
        ...updates
      };
      nativeStorageUpdates.push(newCache);
      CacheRequest.setCacheForKey(key, newCache);
    });

    await Promise.all([
      Api.bulkUpdateProducts({ productIds, updates }),
      updateExistingProductsInNative(nativeStorageUpdates)
    ]);
  } catch (error) {
    Sentry.captureException(error);
  }

  toggleGlobalLoader(loaderKey, false);
};

const getSotedPicturesByPosition = ({ pictures }) =>
  pictures.sort((a, b) => (a.position || undefined) - (b.position || undefined));

const setPictureAsDefault = async (
  { id: pictureId, prepared, error } = {},
  pictureUrl,
  shouldUpdateProduct = false
) => {
  const productId = getActiveProductId();
  const catalogueId = getActiveCatalogueId();
  const key = `${connector.PRODUCT_ROW_META.cacheKey}${productId}`;
  const productRowCache = CacheRequest.getCacheForKey(key);
  const newCache = {
    ...productRowCache,
    pictureId,
    isPrepared: prepared,
    defaultImageErrored: error,
    pictureUrl
  };
  CacheRequest.setCacheForKey(key, newCache);

  const catalogueProductPosition = getProductPositionMapForCatalogue({ catalogueId });
  const { position } = catalogueProductPosition[productId] || {};
  if (typeof position === 'number' && position < 4) {
    const key = `${connector.CATALOGUE_ROW_META.cacheKey}${catalogueId}`;
    const cache = CacheRequest.getCacheForKey(key);

    const newPicturesMeta = [...cache.picturesMeta];
    newPicturesMeta[position] = {
      pictureId,
      url: pictureUrl,
      prepared,
      error
    };

    const newCache = {
      ...cache,
      picturesMeta: newPicturesMeta
    };

    CacheRequest.setCacheForKey(key, newCache);
  }

  const promises = [updateExistingProductsInNative([newCache])];
  let pictureIds;

  if (shouldUpdateProduct) {
    const cachedBasicInfo = getBasicInfoFromCache({ productId });
    const cachedPictures = (cachedBasicInfo && cachedBasicInfo.pictures) || {};
    const prevPicturePositionOfDefaultImg =
      (cachedPictures[pictureId] && cachedPictures[pictureId].position) || null;
    let updatedPictures = [];
    for (const key in cachedPictures) {
      if (key === pictureId) {
        cachedPictures[key].position = 1;
        updatedPictures.push(cachedPictures[key]);
        continue;
      }
      if (!cachedPictures[key].position) {
        continue;
      }
      if (
        prevPicturePositionOfDefaultImg &&
        cachedPictures[key].position > prevPicturePositionOfDefaultImg
      ) {
        updatedPictures.push(cachedPictures[key]);
        continue;
      }
      cachedPictures[key].position += 1;
      updatedPictures.push(cachedPictures[key]);
    }
    updatedPictures = getSotedPicturesByPosition({ pictures: updatedPictures });
    pictureIds = updatedPictures.map(picture => picture.id);
    const updates = {
      default_picture_id: pictureId,
      pictureUrl
    };
    promises.push(Api.updateProduct({ productId, updates }));
  }

  try {
    await Promise.all(promises);
    if (pictureIds) {
      eventbus.publish(UPDATE_PICTURES_POSITIONS.eventbusKey, {
        pictureIds,
        defaultPictureId: pictureId
      });
    }
    updateBasicInfoInCache({
      data: {
        default_picture_id: pictureId
      },
      productId
    });
  } catch (error) {
    throw error;
  }
};

export {
  getGoldRatesAndProductType,
  getGoldRatesAndProductTypeFromCache,
  attachGoldRatesAndProductTypeListener,
  removeGoldRatesAndProductTypeListener,
  getProductTypeDetailsFromCache,
  updateBasicInfoInCache,
  attachBasicInfoListener,
  removeBasicInfoListener,
  getBasicInfoFromRemote,
  getBasicInfoFromCache,
  getProductSetInfoFromCache,
  attachPrivateNotesListener,
  removePrivateNotesListener,
  getPrivateNotes,
  attachInventoryListener,
  removeInventoryListener,
  getInventory,
  productDetailsInitialState,
  saveProductDetailsChanges,
  getBasicInfo,
  computeBasicInfoFromProductRow,
  getDiscountInitDependencies,
  deleteCurrentImage,
  deleteCurrentVideo,
  savePrivateNotes,
  getInventoryFromCache,
  getInventoryCatalogueDefaultsFromCache,
  getProductIsSlabFromCache,
  getSlabPricesForProduct,
  setSlabPricesForProduct,
  deleteSlabPricesForProduct,
  setProductSlabPricesInCache,
  getProductSlabPricesFromCache,
  getProductPriceFromCache,
  getProductDiscountFromCache,
  bulkSaveTitle,
  bulkSavePrice,
  bulkSaveDiscount,
  bulkSaveDescription,
  getSotedPicturesByPosition,
  setPictureAsDefault,
  getProductStoneValuesFromCache,
  updateProductStoneDetails,
  deleteProductStoneDetails,
  setProductStoneDetails,
  updateProductStoneDetailsInCache
};
