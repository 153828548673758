import React, { useState, useReducer } from 'react';
import Switch from 'react-switch';
import {
  DarkThemeTitle,
  DialogDarkTheme,
  PrimaryButton,
  SecondaryButton
} from 'qs-components/Common/DarkThemeDialog';
import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { getErrorMessage } from 'qs-helpers';
import { setOrderPdfSettings } from 'qs-data-manager/Orders/OrdersOperations';
import Loader from '../../../../Common/Loader';
import ErrorIcon from '../../../../Common/ErrorIcon';
import {
  TOGGLE_SHOW_IMAGES_IN_PDF,
  TOGGLE_REMOVE_CUSTOMER_DETAILS_FROM_PDF,
  TOGGLE_SHOW_SKU_BAR_CODE,
  pdfOrderSettingsReducer,
  pdfOrderSettingsInit
} from './reducer';
import './styles.scss';
import { getI18N } from 'qs-services/i18N';

const useAdditionalStyles = makeStyles({
  modalRoot: {
    width: '100%'
  },
  buttonSetting: {
    textTransform: 'none',
    color: 'white',
    justifyContent: 'space-between',
    fontFamily: 'sans-serif',
    fontSize: '1.05rem',
    borderRadius: 0,
    borderBottom: '1px solid #3a424e',
    padding: '14px 0',
    textAlign: 'left',
    '&:last-child': {
      borderBottom: 0,
      paddingBottom: 0
    },
    '&:first-child': {
      paddingTop: 0
    }
  },
  content: {
    flexDirection: 'column'
  }
});

export default ({
  pdfSettingsLoading,
  pdfSettingsError,
  pdfSettings,
  toggleShowPdfSettings,
  setErrorPopupData
}) => {
  const additionalStyles = useAdditionalStyles();
  const isDataReadyToDisplay = pdfSettingsLoading || pdfSettingsError;
  const { t } = getI18N();

  const [
    { showImagesInPdf, removeCustomerDetailsFromPdf, showSkuBarcode },
    setPdfSettingsData
  ] = useReducer(pdfOrderSettingsReducer, pdfSettings, pdfOrderSettingsInit);

  const [pdfSettingsSaveStatus, setPdfSettingsSaveStatus] = useState();

  const savePdfOrderSetting = async () => {
    setPdfSettingsSaveStatus('LOADER');
    try {
      await setOrderPdfSettings({
        showImagesInPdf: typeof showImagesInPdf === 'boolean' ? showImagesInPdf : false,
        removeCustomerDetailsFromPdf:
          typeof removeCustomerDetailsFromPdf === 'boolean' ? removeCustomerDetailsFromPdf : false,
        showSkuBarcode: showSkuBarcode
      });
    } catch (updateSettingsError) {
      setErrorPopupData({
        title: t('order_pdf_settings'),
        subject: t('failed_to_update_pdf_setting'),
        body: getErrorMessage(updateSettingsError),
        message: t('something_went_wrong_while_saving_pdf_settings')
      });
    } finally {
      toggleShowPdfSettings();
      setPdfSettingsSaveStatus();
    }
  };

  const buttonsConfig = [
    {
      onClick: () => setPdfSettingsData({ type: TOGGLE_SHOW_IMAGES_IN_PDF }),
      label: t('show_images_in_pdf'),
      checkedValue: showImagesInPdf,
      savedValue: pdfSettings.showImagesInPdf
    },
    {
      onClick: () => setPdfSettingsData({ type: TOGGLE_REMOVE_CUSTOMER_DETAILS_FROM_PDF }),
      label: t('hide_customer_details_from_pdf'),
      checkedValue: removeCustomerDetailsFromPdf,
      savedValue: pdfSettings.removeCustomerDetailsFromPdf
    },
    {
      onClick: () => setPdfSettingsData({ type: TOGGLE_SHOW_SKU_BAR_CODE }),
      label: t('show_barcode'),
      checkedValue: showSkuBarcode,
      savedValue: pdfSettings.showSkuBarcode,
      description: t('barcode_will_be_generated_from_products_sku')
    }
  ];

  const renderPdfSettingSwitch = ({ checkedValue, savedValue }) => {
    if (pdfSettingsError) {
      return <ErrorIcon />;
    }

    if (pdfSettingsLoading) {
      return (
        <span>
          <Loader size="small" />
        </span>
      );
    }

    // there will be a delay while setting the prop value in state, hence show
    // prop value if state is not available
    if (typeof checkedValue !== 'boolean') {
      checkedValue = savedValue || false;
    }

    return (
      <Switch
        checked={checkedValue}
        onChange={() => {}}
        onColor="#515863"
        offColor="#515863"
        onHandleColor="#4DA47A"
        offHandleColor="#929faf"
        handleDiameter={25}
        uncheckedIcon={false}
        checkedIcon={false}
        height={17}
        width={40}
      />
    );
  };

  const renderPdfSettingsDialogActions = () => {
    if (pdfSettingsSaveStatus === 'LOADER') {
      return (
        <span>
          <Loader size="small" />
        </span>
      );
    }

    return (
      <>
        <SecondaryButton onClick={toggleShowPdfSettings}>{t('cancel')}</SecondaryButton>
        <PrimaryButton
          disabled={isDataReadyToDisplay}
          onClick={savePdfOrderSetting}
          color={'primary'}
        >
          {t('save')}
        </PrimaryButton>
      </>
    );
  };

  return (
    <DialogDarkTheme
      open={true}
      onClose={toggleShowPdfSettings}
      maxWidth="xs"
      PaperProps={{
        classes: {
          root: additionalStyles.modalRoot
        }
      }}
    >
      <DarkThemeTitle>{t('pdf_settings')}</DarkThemeTitle>
      <DialogContent className={additionalStyles.content}>
        {buttonsConfig.map(({ onClick, label, checkedValue, savedValue, description }, index) => (
          <Button
            key={index}
            disableRipple
            disabled={pdfSettingsSaveStatus === 'LOADER' || isDataReadyToDisplay}
            fullWidth
            className={`${additionalStyles.buttonSetting} sectionOrderPdfSettings`}
            onClick={onClick}
          >
            <Box display="flex" flexDirection="column">
              <h4 className="sectionPdfSettingsLabel">{label}</h4>
              {description && <div className="sectionPdfSettingsDescription">{description}</div>}
            </Box>
            {renderPdfSettingSwitch({ checkedValue, savedValue })}
          </Button>
        ))}
      </DialogContent>
      <DialogActions>{renderPdfSettingsDialogActions()}</DialogActions>
    </DialogDarkTheme>
  );
};
