import CacheRequest from '../CacheRequest';
import {
  createCompanyTaxCacheKey,
  getCompanyTaxesFromCache,
  setCompanyTaxesInCache
} from 'qs-helpers/Tax/CacheHelpers';
import { ALL_COMPANY_TAXES } from 'qs-api/Tax/ApiCacheConnector';
import {
  createNewCompanyTax,
  deleteCompanyTax,
  removeDefaultTaxCompany,
  setDefaultTaxCompany
} from 'qs-api/Tax/api';
import { reportError } from 'qs-helpers/ErrorReporting';

export const attachCompanyTaxListener = ({ listener }) => {
  CacheRequest.attachListener(createCompanyTaxCacheKey(), listener);
};

export const removeCompanyTaxListener = ({ listener }) => {
  CacheRequest.removeListener(createCompanyTaxCacheKey(), listener);
};

export const getCompanyTaxes = () => {
  CacheRequest.makeRequest(createCompanyTaxCacheKey(), ALL_COMPANY_TAXES.apiFunction, {
    params: [],
    options: {
      shouldNotStoreInNative: true
    }
  });
};

export const createNewTax = async ({ taxType, taxPercentage } = {}) => {
  try {
    const {
      taxId,
      taxType: createdTaxType,
      taxPercentage: createdTaxPercentage,
      isDefault
    } = await createNewCompanyTax({ taxType, taxPercentage });
    const { taxes } = getCompanyTaxesFromCache() || {};
    if (!Array.isArray(taxes)) {
      return;
    }
    setCompanyTaxesInCache(
      [{ isDefault, taxId, taxType: createdTaxType, taxPercentage: createdTaxPercentage }].concat(
        taxes
      )
    );
  } catch (error) {
    reportError(error);
    throw error;
  }
};

const updateDefaultTaxDataInCache = ({ taxId, isDefault }) => {
  const { taxes } = getCompanyTaxesFromCache() || {};
  if (!Array.isArray(taxes)) {
    return;
  }

  setCompanyTaxesInCache(
    taxes.map(taxData => {
      if (taxData.taxId !== taxId) {
        return taxData;
      }
      return {
        ...taxData,
        isDefault
      };
    })
  );
};

export const setTaxDefault = async taxIdData => {
  try {
    await setDefaultTaxCompany(taxIdData);
    updateDefaultTaxDataInCache({ taxId: taxIdData.taxId, isDefault: true });
  } catch (error) {
    reportError(error);
    throw error;
  }
};

export const removeTaxDefault = async taxIdData => {
  try {
    await removeDefaultTaxCompany(taxIdData);
    updateDefaultTaxDataInCache({ taxId: taxIdData.taxId, isDefault: false });
  } catch (error) {
    reportError(error);
    throw error;
  }
};

export const deleteTax = async taxIdData => {
  try {
    await deleteCompanyTax(taxIdData);
    const { taxes } = getCompanyTaxesFromCache() || {};
    if (!Array.isArray(taxes)) {
      return;
    }

    setCompanyTaxesInCache(taxes.filter(({ taxId }) => taxId !== taxIdData.taxId));
  } catch (error) {
    reportError(error);
    throw error;
  }
};
