import { getVariantInfoFromCache } from './VariantsDetails';
import { PRODUCT_VARIANT_INFO } from 'qs-api/Variants/ApiCacheConnector';
import CacheRequest from '../CacheRequest';
import { convertImageUrlSize } from 'qs-helpers';
import Api from '../../Api';
import { reportError } from 'qs-helpers/ErrorReporting';
import { getVariantsMetaDataFromCache, setVariantsMetaDataInCache } from './FetchVariants';

export const getVariantPicturesFromCache = ({ activeVariantId }) => {
  const cachedVariantData = getVariantInfoFromCache({ activeVariantId });
  if (!cachedVariantData) {
    return;
  }

  const { default_picture_id, pictures } = cachedVariantData;
  const picturesData = Object.values(pictures || {});

  // No pictures present exit early
  if (picturesData.length === 0) {
    return;
  }

  const initialValue = [];

  // Currently the default_picture_id exists incorrectly, until that bug is
  // fixed add extra check to ensure that default_picture_id exists
  if (default_picture_id && pictures[default_picture_id]) {
    // Add an empty object so that the default image stays the first
    initialValue.push({});
  }

  return picturesData.reduce((accumalatedPictures, pictureData) => {
    if (pictureData.id === default_picture_id) {
      accumalatedPictures[0].id = pictureData.id;
      accumalatedPictures[0].url = pictureData.url;
      accumalatedPictures[0].prepared = pictureData.prepared;
      accumalatedPictures[0].error = pictureData.error;
      accumalatedPictures[0].position = pictureData.position || undefined;
      return accumalatedPictures;
    }

    accumalatedPictures.push({
      id: pictureData.id,
      url: pictureData.url,
      prepared: pictureData.prepared,
      error: pictureData.error,
      position: pictureData.position || undefined
    });
    return accumalatedPictures;
  }, initialValue);
};

const updateCacheForDefaultImageChange = ({ variantId, updates }) => {
  const cachedVariantData = getVariantInfoFromCache({ activeVariantId: variantId }) || {};
  const key = `${PRODUCT_VARIANT_INFO.cacheKey}${variantId}`;
  CacheRequest.setCacheForKey(key, {
    ...cachedVariantData,
    ...updates
  });
};

const updateDefaultImageInRemote = async ({ variantId, pictureId, url }) => {
  const updates = {
    default_picture_id: pictureId,
    pictureUrl: convertImageUrlSize({ size: 'FULL', url })
  };

  try {
    await Api.updateProduct({ productId: variantId, updates });
  } catch (updateProductError) {
    reportError(updateProductError);
    throw updateProductError;
  }
  return updates;
};

export const setVariantPictureAsDefault = async ({ variantId, pictureId, url }) => {
  const updates = await updateDefaultImageInRemote({ variantId, pictureId, url });
  updateCacheForDefaultImageChange({ variantId, updates });
  updateVariantMetaWithDefaultPicture({
    variantId,
    pictures: [{ pictureId, url }],
    forceUpdate: true
  });
};

export const isCurrentImageDefault = ({ activeVariantId, pictureId }) => {
  const cachedVariantData = getVariantInfoFromCache({ activeVariantId }) || {};
  if (cachedVariantData.default_picture_id === pictureId) {
    return true;
  }

  return false;
};

const getNextEligibleImage = pictures => {
  if (!pictures) {
    return;
  }

  for (const pictureId in pictures) {
    if (pictures.hasOwnProperty(pictureId)) {
      // return the first image that is found in the pictureMap
      return pictures[pictureId];
    }
  }
};

export const deleteSelectedVariantImage = async ({ variantId, currentImage }) => {
  try {
    await Api.deleteProductPicture({ productId: variantId, pictureIds: [currentImage.id] });
  } catch (remoteDeleteError) {
    reportError(remoteDeleteError);
    throw remoteDeleteError;
  }

  const cacheKey = `${PRODUCT_VARIANT_INFO.cacheKey}${variantId}`;
  const basicInfo = CacheRequest.getCacheForKey(cacheKey);
  const { pictures } = basicInfo;
  if (!pictures) {
    return;
  }

  for (const id in pictures) {
    if (pictures.hasOwnProperty(id) && id === currentImage.id) {
      delete pictures[id];
      break;
    }
  }

  if (!isCurrentImageDefault({ activeVariantId: variantId, pictureId: currentImage.id })) {
    CacheRequest.setCacheForKey(cacheKey, { ...basicInfo, pictures });
    return;
  }

  const imageData = getNextEligibleImage(pictures);
  if (!imageData) {
    CacheRequest.setCacheForKey(cacheKey, {
      ...basicInfo,
      pictures,
      default_picture_id: null,
      pictureUrl: null
    });

    // Remove from meta cache too
    const variantMeta = getVariantsMetaDataFromCache(variantId);
    setVariantsMetaDataInCache({
      variantId,
      metaData: {
        ...variantMeta,
        pictureUrl: null
      }
    });
    return;
  }

  try {
    const updates = await updateDefaultImageInRemote({
      variantId,
      pictureId: imageData.id,
      url: imageData.url
    });
    CacheRequest.setCacheForKey(cacheKey, {
      ...basicInfo,
      pictures,
      ...updates
    });
    updateVariantMetaWithDefaultPicture({
      variantId,
      pictures: [{ pictureId: imageData.id, url: imageData.url }],
      forceUpdate: true
    });
  } catch (setAsDefaultError) {
    CacheRequest.setCacheForKey(cacheKey, {
      ...basicInfo,
      pictures,
      default_picture_id: null,
      pictureUrl: null
    });
    throw setAsDefaultError;
  }
};

export const updateVariantMetaWithDefaultPicture = ({
  variantId,
  pictures,
  forceUpdate = false
}) => {
  const variantMeta = getVariantsMetaDataFromCache(variantId);
  if (!Array.isArray(pictures) || (!forceUpdate && variantMeta.pictureUrl)) {
    return;
  }

  setVariantsMetaDataInCache({
    variantId,
    metaData: {
      ...variantMeta,
      pictureUrl: convertImageUrlSize({ size: 'FULL', url: pictures[0].url })
    }
  });
};
