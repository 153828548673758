import React, { useRef, useState } from 'react';
import {
  DarkThemeTitle,
  DialogDarkTheme,
  SecondaryButton
} from 'qs-components/Common/DarkThemeDialog';
import {
  Button,
  DialogActions,
  DialogContent,
  InputAdornment,
  makeStyles,
  Radio,
  RadioGroup,
  TextField
} from '@material-ui/core';
import { NO_SHIPPING, CALCULATED_SHIPPING, CUSTOM_SHIPPING } from 'qs-helpers/Orders/constants';
import { getCompanyCurrencySymbol } from 'qs-data-manager/Company';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

const useStyles = makeStyles({
  radioRoot: {
    //Match the padding in CSS. Keep 1 less than the CSS to account for underlying components spacing
    padding: 9
  },
  radioView: {
    color: '#ffffff',
    '&$checked': {
      color: '#ffffff'
    },
    '&$disabled': {
      color: '#ffffff'
    }
  },
  checked: {},
  disabled: {},
  buttonRoot: {
    color: '#ffffff',
    '&$buttonDisabled': {
      color: '#ffffff'
    }
  },
  buttonLabelRoot: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  buttonDisabled: {
    opacity: 0.5
  },
  textFieldRoot: {
    marginTop: 10
  },
  textInput: {
    color: '#ffffff'
  },
  textInputError: {
    color: '#DB5354'
  },
  textInputUnderline: {
    '&:before': {
      borderColor: '#ffffff'
    },
    '&:after': {
      borderColor: '#ffffff'
    },
    '&:hover:before': {
      borderColor: '#ffffff !important'
    }
  }
});

export default ({ onCancel, onUpdate, inquiryData }) => {
  const componentStyles = useStyles();
  const textInputRef = useRef();
  const { t } = getI18N();

  const [shippingMode, setShippingMode] = useState(() => {
    const { shippingCost, shippingCostSeller } = inquiryData;
    if (typeof shippingCostSeller === 'number') {
      //Treat 0 as no shipping explicitly set
      if (shippingCostSeller > 0) {
        return CUSTOM_SHIPPING;
      }

      return NO_SHIPPING;
    }

    if (typeof shippingCost === 'number') {
      return CALCULATED_SHIPPING;
    }

    return NO_SHIPPING;
  });

  const [shippingCostSeller, setShippingCostSeller] = useState(() => {
    const { shippingCostSeller } = inquiryData;
    if (typeof shippingCostSeller === 'number' && shippingCostSeller > 0) {
      return shippingCostSeller.toString();
    }
    return '';
  });

  const [shippingCostError, setShippingCostError] = useState();

  const handleModeChange = selectedMode => {
    //Don't process naything on click of current value
    if (selectedMode === shippingMode) {
      return;
    }

    setShippingCostError();
    setShippingMode(selectedMode);
    if (selectedMode === CUSTOM_SHIPPING && textInputRef.current) {
      textInputRef.current.focus();
    }
  };

  const onModeChange = event => {
    const selectedMode = event.target.value;
    handleModeChange(selectedMode);
  };

  const onRadioViewClick = selectedMode => event => {
    event.preventDefault();
    handleModeChange(selectedMode);
  };

  const onShippingPriceChange = event => {
    const { value } = event.target;
    setShippingCostError();
    setShippingCostSeller(value);
  };

  const onProceed = () => {
    if (shippingMode === CALCULATED_SHIPPING) {
      onUpdate(null);
      return;
    }

    if (shippingMode === NO_SHIPPING) {
      onUpdate(0);
      return;
    }

    const numShippingCost = Number(shippingCostSeller);
    if (shippingCostSeller.trim() === '' || Number.isNaN(numShippingCost)) {
      setShippingCostError(t('please_enter_shipping_cost'));
      return;
    }

    if (numShippingCost < 0) {
      setShippingCostError(t('shipping_cannot_be_negative'));
      return;
    }

    onUpdate(numShippingCost);
  };

  const getClassNameForRadioContainer = requiredMode =>
    `shippingRadioButtonContainer${shippingMode === requiredMode ? ' checked' : ''}`;

  const renderCalculatedShippingView = () => {
    const calculatedShippingExists = typeof inquiryData.shippingCost === 'number';
    return (
      <Button
        className={getClassNameForRadioContainer(CALCULATED_SHIPPING)}
        classes={{
          root: componentStyles.buttonRoot,
          label: componentStyles.buttonLabelRoot,
          disabled: componentStyles.buttonDisabled
        }}
        disableRipple
        disabled={!calculatedShippingExists}
        onClick={onRadioViewClick(CALCULATED_SHIPPING)}
      >
        <Radio
          value={CALCULATED_SHIPPING}
          classes={{
            colorSecondary: componentStyles.radioView,
            checked: componentStyles.checked,
            disabled: componentStyles.disabled,
            root: componentStyles.radioRoot
          }}
          disabled={!calculatedShippingExists}
        />
        <div className="shippingLabelContainer">
          <span>{t('weight_based_shipping_cost')}</span>
          {calculatedShippingExists && (
            <span className="calcualtedShippingCostVal">
              {getCompanyCurrencySymbol()} {inquiryData.shippingCost}
            </span>
          )}
        </div>
      </Button>
    );
  };

  const renderCustomShippingView = () => (
    <Button
      className={getClassNameForRadioContainer(CUSTOM_SHIPPING)}
      onClick={onRadioViewClick(CUSTOM_SHIPPING)}
      classes={{
        root: componentStyles.buttonRoot,
        label: componentStyles.buttonLabelRoot
      }}
    >
      <Radio
        value={CUSTOM_SHIPPING}
        classes={{
          colorSecondary: componentStyles.radioView,
          checked: componentStyles.checked,
          root: componentStyles.radioRoot
        }}
      />
      <div className="shippingLabelContainer">
        <span>{t('custom_shipping_cost')}</span>
        <TextField
          classes={{ root: componentStyles.textFieldRoot }}
          inputRef={textInputRef}
          error={shippingCostError !== undefined}
          onChange={onShippingPriceChange}
          value={shippingCostSeller}
          placeholder={t('type_shipping_cost')}
          helperText={shippingCostError || undefined}
          InputProps={{
            startAdornment: (
              <InputAdornment
                position="start"
                classes={{
                  root:
                    shippingCostError === undefined
                      ? componentStyles.textInput
                      : componentStyles.textInputError
                }}
                disableTypography
              >
                {getCompanyCurrencySymbol()}
              </InputAdornment>
            ),
            classes: {
              error: componentStyles.textInputError,
              root: componentStyles.textInput,
              underline: componentStyles.textInputUnderline
            },
            error: shippingCostError !== undefined
          }}
        />
      </div>
    </Button>
  );

  const renderNoShippingView = () => (
    <Button
      className={getClassNameForRadioContainer(NO_SHIPPING)}
      onClick={onRadioViewClick(NO_SHIPPING)}
      classes={{
        root: componentStyles.buttonRoot,
        label: componentStyles.buttonLabelRoot
      }}
    >
      <Radio
        value={NO_SHIPPING}
        classes={{
          colorSecondary: componentStyles.radioView,
          checked: componentStyles.checked,
          root: componentStyles.radioRoot
        }}
      />
      <span className="shippingLabelContainer">{t('no_shipping')}</span>
    </Button>
  );

  return (
    <DialogDarkTheme
      open={true}
      onClose={onCancel}
      maxWidth="xs"
      PaperProps={{
        classes: {}
      }}
    >
      <DarkThemeTitle>{t('shipping_cost')}</DarkThemeTitle>
      <DialogContent className="editOrderShippingCostDialog">
        <span>{t('enter_shipping_cost')}</span>
        <RadioGroup value={shippingMode} onChange={onModeChange} name="shippingMode">
          {renderCalculatedShippingView()}
          {renderCustomShippingView()}
          {renderNoShippingView()}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <SecondaryButton onClick={onCancel}>{t('cancel')}</SecondaryButton>
        <SecondaryButton onClick={onProceed}>{t('proceed')}</SecondaryButton>
      </DialogActions>
    </DialogDarkTheme>
  );
};
