import ClickHandlerWithLoaderContainer from '../../../Common/ClickHandlerWithLoader/ClickHandlerWithLoaderContainer';
import React from 'react';
import SettingsRow from '../SettingsRow/SettingsRow';

export default ({
  buttonRowData: { componentId, Icon, imageSrc, title, description },
  classForContainer,
  onSelected
}) => {
  return (
    <ClickHandlerWithLoaderContainer
      clickProps={{
        clickHandler: onSelected,
        clickParams: componentId
      }}
      type="button"
      classForContainer={classForContainer}
    >
      <SettingsRow Icon={Icon} imageSrc={imageSrc} title={title}>
        <span>{description}</span>
      </SettingsRow>
    </ClickHandlerWithLoaderContainer>
  );
};
