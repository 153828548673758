import { Button, DialogActions, DialogContent, makeStyles } from '@material-ui/core';
import React from 'react';
import {
  ButtonDanger,
  DarkThemeTitle,
  DialogDarkTheme,
  PrimaryButton,
  SecondaryButton
} from '../DarkThemeDialog';

const useComponentStyles = makeStyles({
  dialogActionSpacing: props => props.dialogActionSpacing
});

export default ({
  open = false,
  maxWidth,
  title,
  titleRootClass,
  actionsConfig = [],
  dialogAsForm,
  dialogContainerClass = '',
  materialStyles = {},
  onClose,
  id,
  dialogContentContainerClassName = '',
  dialogContentContainerClass,
  children,
  dialogOnClick
}) => {
  const componetStyles = useComponentStyles(materialStyles);
  const getDialogProps = () => {
    const dialogProps = {};
    if (dialogContainerClass) {
      dialogProps.className = dialogContainerClass;
    }

    if (dialogAsForm) {
      dialogProps.component = 'form';
      dialogProps.onSubmit = dialogAsForm.onSubmit;
      dialogProps.noValidate = dialogAsForm.noValidate || true;
    }

    if (dialogOnClick) {
      dialogProps.onClick = dialogOnClick.onClick;
    }

    return dialogProps;
  };

  const renderDialogActions = () => {
    if (actionsConfig.length === 0) {
      return null;
    }

    return (
      <DialogActions classes={{ spacing: componetStyles.dialogActionSpacing }}>
        {actionsConfig.map(({ clickHandler, content, role, disabled = false }) => {
          let ButtonType, color;
          switch (role) {
            case 'PRIMARY':
              ButtonType = PrimaryButton;
              color = 'primary';
              break;
            case 'DANGER':
              ButtonType = ButtonDanger;
              break;
            case 'SECONDARY':
              ButtonType = SecondaryButton;
              break;
            default:
              ButtonType = Button;
          }

          return (
            <ButtonType
              key={role ? role : content}
              disabled={disabled}
              color={color}
              onClick={clickHandler}
            >
              {content}
            </ButtonType>
          );
        })}
      </DialogActions>
    );
  };

  return (
    <DialogDarkTheme
      open={open}
      maxWidth={maxWidth}
      PaperProps={getDialogProps()}
      onClose={onClose}
      id={id}
    >
      {title && <DarkThemeTitle classes={{ root: titleRootClass }}>{title}</DarkThemeTitle>}
      <DialogContent
        className={dialogContentContainerClassName}
        classes={{ root: dialogContentContainerClass }}
      >
        {children}
      </DialogContent>
      {renderDialogActions()}
    </DialogDarkTheme>
  );
};
