import React, { useState, useRef, useContext, useCallback, useEffect } from 'react';
import { ReactComponent as SendIcon } from 'qs-assets/Media/send.svg';
import { ReactComponent as OrderLink } from 'qs-assets/Media/link_icon.svg';
import { ReactComponent as PdfIcon } from 'qs-assets/Media/pdf_icon.svg';
import { ReactComponent as ExcelIcon } from 'qs-assets/Media/excel_icon.svg';
import { ReactComponent as ZipIcon } from 'qs-assets/Media/zip_icon.svg';
import { ReactComponent as SettingsOutline } from 'qs-assets/Media/settingsOutlineIcon.svg';
import { useDropDownStyles, ActionButton } from '../Common/ActionButtons';
import { ActiveOrderMeta } from '../../../context';
import { toggleGlobalLoader, openWhatsApp, getErrorMessage } from 'qs-helpers';
import { getOrderMetaFromCache } from 'qs-data-manager/Orders/OrdersOperations';
import { getCompanyDomain } from 'qs-data-manager/Company';
import ErrorPopup from 'qs-components/Common/ErrorPopup';
import toastr from 'toastr';
import {
  downloadOrderAsZip,
  downloadOrderAsExcel,
  downloadOrderAsPdf,
  downloadOrderAsB2BPdf
} from 'qs-data-manager/Orders/OrdersProductsOperations';
import MenuDropDown from '../../../../Common/MenuDropDown';
import useMakeQuery from 'qs-hooks/useMakeQuery';
import {
  getCachedOrderPdfSettingsData,
  handleFetchOrderPdfSettings,
  handleOrderPdfSettingsCleanup
} from 'qs-helpers/Orders/DataQueryHelper';
import OrderPDFSettings from '../OrderPDFSettings/index.js';
import './styles.scss';
import { getB2BErrorFromReason } from './utils';
import { getI18N } from 'qs-services/i18N';

export default ({ buttonClasses, b2bInvoiceConfig = {} }) => {
  const {
    activeOrderMeta: { selectedOrderId }
  } = useContext(ActiveOrderMeta);

  const { cantGenerateB2BInvoiceReason, canGenerateB2BInvoice } = b2bInvoiceConfig;

  const [errorPopupData, setErrorPopupData] = useState();
  const [showSendOrderMenu, setShowSendOrderMenu] = useState(false);
  const [showPdfSettings, setShowPdfSettings] = useState(false);
  const sendOrderButtonRef = useRef();
  const downloadPdfRef = useRef({
    autoDownload: false
  });

  const componentStyles = useDropDownStyles();

  const [
    { loading: pdfSettingsLoading, error: pdfSettingsError, data: pdfSettings }
  ] = useMakeQuery({
    changeDependancy: [],
    actionHandler: handleFetchOrderPdfSettings,
    cachedDataHandler: getCachedOrderPdfSettingsData,
    cleanupHandler: handleOrderPdfSettingsCleanup,
    globalLoader: false
  });

  const toggleShowPdfSettings = useCallback(() => {
    setShowPdfSettings(prevVal => !prevVal);
    setShowSendOrderMenu(false);
  }, []);

  const toggleSendOrderMenu = () => {
    setShowSendOrderMenu(prevVal => !prevVal);
  };

  const closeSendOrderMenu = () => {
    setShowSendOrderMenu(false);
  };

  const { showImagesInPdf } = pdfSettings || {};

  const { t } = getI18N();

  // dont use pdfSettings for now in B2B as it supports none
  const downloadB2BPdf = useCallback(async () => {
    if (!canGenerateB2BInvoice) {
      toastr.error(t(getB2BErrorFromReason(cantGenerateB2BInvoiceReason)));
      return;
    }

    closeSendOrderMenu();
    const key = `downloadB2BPDF${Date.now()}`;
    toggleGlobalLoader(key, true);
    try {
      const { pdfLink, fileName } = await downloadOrderAsB2BPdf({ orderId: selectedOrderId });
      const downloadPdfAnchor = document.createElement('a');
      downloadPdfAnchor.href = pdfLink;
      downloadPdfAnchor.download = fileName;
      downloadPdfAnchor.click();
    } catch (downloadB2BPdfError) {
      setErrorPopupData({
        title: t('download_as_b2b_pdf'),
        subject: t('failed_to_download_order_id_as_b2b_pdf', { selectedOrderId }),
        body: getErrorMessage(downloadB2BPdfError),
        message: t('something_went_wrong_while_downloading_order_as_b2b_pdf')
      });
    } finally {
      toggleGlobalLoader(key, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canGenerateB2BInvoice, cantGenerateB2BInvoiceReason, selectedOrderId]);

  const downloadPDF = useCallback(async () => {
    // If the loaded value is not a boolean then prompt the user to decide the value
    if (typeof showImagesInPdf !== 'boolean' && !pdfSettingsError) {
      downloadPdfRef.current.autoDownload = true;
      toggleShowPdfSettings();
      return;
    }

    downloadPdfRef.current.autoDownload = false;
    closeSendOrderMenu();
    const key = `downloadPDF${Date.now()}`;
    toggleGlobalLoader(key, true);
    try {
      const { pdfLink } = await downloadOrderAsPdf({ orderId: selectedOrderId });
      const downloadPdfAnchor = document.createElement('a');
      downloadPdfAnchor.href = pdfLink;
      downloadPdfAnchor.click();
    } catch (downloadPdfError) {
      setErrorPopupData({
        title: t('download_as_pdf'),
        subject: t('failed_to_download_order_id_as_pdf', { selectedOrderId }),
        body: getErrorMessage(downloadPdfError),
        message: t('something_went_wrong_while_downloading_order_as_pdf')
      });
    } finally {
      toggleGlobalLoader(key, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdfSettingsError, selectedOrderId, showImagesInPdf, toggleShowPdfSettings]);

  useEffect(() => {
    if (downloadPdfRef.current.autoDownload && typeof showImagesInPdf === 'boolean') {
      downloadPDF();
    }
  }, [showImagesInPdf, downloadPDF]);

  const downloadZip = async () => {
    toggleSendOrderMenu();
    const key = `downloadZip${Date.now()}`;
    toggleGlobalLoader(key, true);

    try {
      const { zipLink } = await downloadOrderAsZip({ orderId: selectedOrderId });
      const zipAnchor = document.createElement('a');
      zipAnchor.href = zipLink;
      zipAnchor.click();
    } catch (downloadZipError) {
      setErrorPopupData({
        title: t('download_as_zip'),
        subject: t('failed_to_download_order_id_as_zip', { selectedOrderId }),
        body: getErrorMessage(downloadZipError),
        message: t('something_went_wrong_while_downloading_order_as_zip')
      });
    } finally {
      toggleGlobalLoader(key, false);
    }
  };

  const downloadExcel = async () => {
    toggleSendOrderMenu();
    const key = `downloadExcel${Date.now()}`;
    toggleGlobalLoader(key, true);

    try {
      const { excelLink } = await downloadOrderAsExcel({ orderId: selectedOrderId });
      const excelAnchor = document.createElement('a');
      excelAnchor.href = excelLink;
      excelAnchor.click();
    } catch (downloadExcelError) {
      setErrorPopupData({
        title: t('download_as_excel'),
        subject: t('failed_to_download_order_id_as_excel', { selectedOrderId }),
        body: getErrorMessage(downloadExcelError),
        message: t('something_went_wrong_while_downloading_order_as_excel')
      });
    } finally {
      toggleGlobalLoader(key, false);
    }
  };

  const shareLink = () => {
    const { visitorPhone } = getOrderMetaFromCache({ orderId: selectedOrderId }) || {};
    toggleSendOrderMenu();

    let domain = getCompanyDomain() || 'quicksell.co';
    let protocol = 'http://';

    if (domain.indexOf('.catalog.to') !== -1 || domain === 'quicksell.co') {
      protocol = 'https://';
    }

    const text = `${protocol}${domain}/order/${selectedOrderId}`;
    let contact = visitorPhone;
    if (visitorPhone.charAt(0) === '+') {
      contact = contact.slice(1);
    }
    openWhatsApp({ text, contact });
  };

  const menuItemClasses = { root: componentStyles.menuItemRoot };
  const iconClasses = { root: componentStyles.listIconRoot };
  const menuItemsConfig = [
    {
      classes: menuItemClasses,
      clickHandler: shareLink,
      label: t('send_order_link'),
      icon: <OrderLink className="orderHeaderButtonIcon" />,
      iconClass: iconClasses
    },
    {
      classes: menuItemClasses,
      clickHandler: downloadExcel,
      label: t('download_as_excel'),
      icon: <ExcelIcon className="orderHeaderButtonIcon" />,
      iconClass: iconClasses
    },
    {
      classes: menuItemClasses,
      clickHandler: downloadZip,
      label: t('download_as_zip'),
      icon: <ZipIcon className="orderHeaderButtonIcon" />,
      iconClass: iconClasses
    },
    {
      classes: menuItemClasses,
      clickHandler: downloadPDF,
      label: t('download_pdf'),
      icon: <PdfIcon className="orderHeaderButtonIcon" />,
      iconClass: iconClasses,
      disabled: pdfSettingsLoading === true
    },
    ...(canGenerateB2BInvoice
      ? [
          {
            classes: menuItemClasses,
            clickHandler: downloadB2BPdf,
            label: t('download_b2b_pdf'),
            icon: <PdfIcon className="orderHeaderButtonIcon" />,
            iconClass: iconClasses
          }
        ]
      : []),
    {
      classes: menuItemClasses,
      clickHandler: toggleShowPdfSettings,
      label: t('pdf_settings'),
      icon: <SettingsOutline className="orderHeaderButtonIcon" />,
      iconClass: iconClasses
    }
  ];

  return (
    <>
      <ActionButton
        color={'primary'}
        variant="contained"
        onClick={toggleSendOrderMenu}
        startIcon={<SendIcon className="orderHeaderButtonIcon" />}
        classes={{ root: buttonClasses.buttonRoot }}
        ref={sendOrderButtonRef}
      >
        {t('send_order')}
      </ActionButton>
      {showSendOrderMenu && (
        <MenuDropDown
          buttonRef={sendOrderButtonRef.current}
          onClose={toggleSendOrderMenu}
          paperClasses={{ root: componentStyles.paperRoot }}
          menuItemsConfig={menuItemsConfig}
        />
      )}
      {showPdfSettings && (
        <OrderPDFSettings
          pdfSettingsLoading={pdfSettingsLoading}
          pdfSettingsError={pdfSettingsError}
          pdfSettings={pdfSettings}
          toggleShowPdfSettings={toggleShowPdfSettings}
          setErrorPopupData={setErrorPopupData}
        />
      )}
      {errorPopupData && (
        <ErrorPopup
          onClose={() => setErrorPopupData()}
          mailProps={errorPopupData}
          title={errorPopupData.title}
        >
          {errorPopupData.message}
        </ErrorPopup>
      )}
    </>
  );
};
