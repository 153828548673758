import React, { useContext } from 'react';
import { Button, DialogContent, makeStyles } from '@material-ui/core';
import { DarkThemeTitle, DialogDarkTheme } from 'qs-components/Common/DarkThemeDialog';
import { ORDER_STATUS } from 'qs-helpers/Orders/constants';
import useMakeQuery from 'qs-hooks/useMakeQuery';
import { getI18N } from 'qs-services/i18N';
import { ActiveOrderMeta } from '../../../../context';
import {
  getCachedOrderInventoryUpdateData,
  handleFetchOrderInventoryUpdate,
  handleOrderInventoryUpdateCleanup
} from 'qs-helpers/Orders/DataQueryHelper';
import ErrorIcon from 'qs-components/Common/ErrorIcon';
import Loader from 'qs-components/Common/Loader';
import './styles.scss';

const useStyles = makeStyles({
  dialogContentRoot: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch'
  },
  buttonRoot: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flex: '0 0 auto',
    alignItems: 'stretch',
    padding: '0px 24px',
    margin: 0,
    color: '#fff',
    justifyContent: 'flex-start',
    '&:hover': {
      backgroundColor: '#0a1015'
    },
    overflow: 'hidden',
    borderRadius: 0
  },
  buttonLabel: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch'
  }
});

export default ({ onClose, onUpdate, isFinalizedByCompany, orderStatus }) => {
  const {
    activeOrderMeta: { selectedOrderId }
  } = useContext(ActiveOrderMeta);

  const [{ loading, refreshing, error, data }] = useMakeQuery({
    changeDependancy: [selectedOrderId],
    actionHandler: handleFetchOrderInventoryUpdate,
    cachedDataHandler: getCachedOrderInventoryUpdateData,
    cleanupHandler: handleOrderInventoryUpdateCleanup,
    globalLoader: false
  });

  const { t } = getI18N();
  const componentStyles = useStyles();

  const inventoryData = (data || {}).inventoryForStatus;

  const getModalButtons = () => {
    const modalOrderStatusButtonData = [
      {
        label: t('customer_confirmed'),
        status: null,
        onClick: () => onUpdate()
      },
      {
        label: t('rejected'),
        status: ORDER_STATUS.REJECTED,
        onClick: () => onUpdate({ orderStatus: ORDER_STATUS.REJECTED })
      },
      {
        label: t('accepted'),
        status: ORDER_STATUS.ACCEPTED,
        onClick: () => onUpdate({ orderStatus: ORDER_STATUS.ACCEPTED })
      },
      {
        label: t('completed'),
        status: ORDER_STATUS.COMPLETED,
        onClick: () => onUpdate({ orderStatus: ORDER_STATUS.COMPLETED })
      }
    ];

    // Eliminate customer confirmed
    if (isFinalizedByCompany) {
      return modalOrderStatusButtonData.slice(1);
    }

    return modalOrderStatusButtonData;
  };

  const getStatusChangeButtonClass = ({ orderStatusMatched }) => {
    let buttonClass = '';
    if (!orderStatusMatched) {
      buttonClass += ' stepper';
    }

    if (orderStatus === ORDER_STATUS.REJECTED) {
      buttonClass += ' redHighlight';
    }
    return buttonClass;
  };

  const renderModalChildren = () => {
    if (error) {
      return (
        <div className="noContentContainer">
          <ErrorIcon width={40} height={40} />
          <span className="errorText">
            {t('something_went_wrong_while_fetching_data_for_changing_the_orders_status')}
          </span>
        </div>
      );
    }

    if (loading || refreshing) {
      return (
        <div className="noContentContainer">
          <Loader size="large" />
        </div>
      );
    }

    const buttonData = getModalButtons();
    let orderStatusMatched,
      matchedIndex = -1,
      highlightColor = '#35D29C';
    if (orderStatus === ORDER_STATUS.REJECTED) {
      highlightColor = '#F04646';
    }

    return (
      <>
        {buttonData.map(({ onClick, label, status }, index) => {
          if (!orderStatusMatched) {
            orderStatusMatched = orderStatus === status;
          }

          // Check for the matched index as long as match has not been encountered
          if (matchedIndex === -1 && orderStatusMatched) {
            matchedIndex = index;
          }

          /*
            Show the circle either if the property is set, if the status matched
            or for all the options that come after the matched order status option.
            When the circle is hidden, make it transparent, so that the alignemnt
            does not change
          */
          const statusCircleStyle = {
            backgroundColor: highlightColor
          };
          //Hide the status circles as long as order status has not matched
          if (!orderStatusMatched) {
            if (index !== 0) {
              statusCircleStyle.backgroundColor = 'transparent';
            }
          } else if (index > matchedIndex) {
            //For all remaining statuses post match, override background color
            statusCircleStyle.backgroundColor = '#3D5466';
          }

          const statusInventoryInfo =
            inventoryData[status === null ? ORDER_STATUS.CONFIRMED : status];

          let statusTextStyles,
            statusTextClasses = 'orderStatusText';
          if (index === matchedIndex) {
            statusTextClasses += ' currentStatus';
            statusTextStyles = { color: highlightColor };
          }

          return (
            <Button
              onClick={onClick}
              key={label}
              classes={{ root: componentStyles.buttonRoot, label: componentStyles.buttonLabel }}
              className={`orderStatusChangeButtonContainer${getStatusChangeButtonClass({
                orderStatusMatched
              })}`}
            >
              <div
                className={`orderStatusChangeLabelContainer ${
                  index === matchedIndex && index !== 0 ? 'lastMatched' : ''
                }`}
              >
                <div style={statusCircleStyle} className="orderStatusCircle" />
                <div style={statusTextStyles} className={statusTextClasses}>
                  {label}
                </div>
              </div>
              {statusInventoryInfo && (
                <span className="orderStatusChangeInventoryDescriptionText">
                  {t('inventory_will_be_updated_for_products_whose_tracking_is_on')}
                </span>
              )}
            </Button>
          );
        })}
      </>
    );
  };

  return (
    <DialogDarkTheme open={true} onClose={onClose}>
      <DarkThemeTitle>{t('change_order_status')}</DarkThemeTitle>
      <DialogContent
        className="orderStatusChangeDialogContentContainer"
        classes={{ root: componentStyles.dialogContentRoot }}
      >
        {renderModalChildren()}
      </DialogContent>
    </DialogDarkTheme>
  );
};
