import eventbus from 'eventing-bus';

export const VIDEO_LOADER = 'LOADER';

export const VIDEO_ERROR = 'LOAD_ERROR';

export const VIDEO_HIDE_LOADER = 'HIDE_LOADER';

export const PRODUCT_VIDEOS_LIMIT_REACHED = 'PRODUCT_VIDEOS_LIMIT_REACHED';

export const getVideoUploadProgress = data => {
  const { uploaded } = data;
  eventbus.publish('UPLOAD_PRODUCT_HEADER', uploaded);
};
