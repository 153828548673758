import React, { Component } from 'react';
import ImagePlaceholder from 'qs-assets/Media/image-placeholder.png';
import RightArrow from 'qs-assets/Media/right-arrow.png';
import DownArrow from 'qs-assets/Media/down-triangle-arrow.png';
import eventbus from 'eventing-bus';
import { UPLOAD_IMAGE_MODAL, closeImageUploadModal } from 'qs-data-manager/Products';
import { getCurrencySymbol, toggleGlobalLoader, computeDataUrlFromFile } from 'qs-helpers';
import { getCompanyCurrencyCode } from 'qs-data-manager/Company';
import toastr from 'toastr';
import {
  upsertCatalogueProducts,
  MAP_TITLE,
  MAP_PRICE,
  MAP_DESCRIPTION,
  MAP_SKU,
  MAP_SKU_NAME,
  UPLOAD_MAP_KEYS_VALUES,
  MAP_NONE
} from 'qs-product-operations/UpsertPictureUpload/UpsertCatalogueProducts';
import PrepareImageUpload from './PrepareImageUpload';
import ErrorPopup from '../../../Common/ErrorPopup';
import './styles.scss';
import { getI18N } from 'qs-services/i18N';

class ImageUploaderOption extends Component {
  constructor(props) {
    super(props);

    const currencyCode = getCompanyCurrencyCode();
    const currencySymbol = getCurrencySymbol({ currencyCode });
    this.state = {
      shouldShow: false,
      firstImage: '',
      image: null,
      noOfFiles: null,
      displayName: '',
      canFileNameBePrice: true,
      imageUploaderFieldOption: MAP_TITLE,
      currencySymbol,
      allImages: null,
      showImageUploader: false
    };
  }

  componentDidMount() {
    this.removeEventbus = eventbus.on(UPLOAD_IMAGE_MODAL.eventbusKey, data => {
      const { file, displayName, noOfFiles, canFileNameBePrice, shouldShow, allImages } = data;

      if (shouldShow) {
        this.computeImage(file);
      }

      this.setState({
        noOfFiles,
        canFileNameBePrice,
        shouldShow: !!shouldShow,
        displayName: displayName || '',
        allImages
      });
    });
  }

  componentWillUnmount() {
    if (this.removeEventbus) {
      this.removeEventbus();
    }
  }

  computeImage = async image => {
    if (!(image instanceof File)) {
      return;
    }

    try {
      const dataUrl = await computeDataUrlFromFile(image);
      this.setState({
        image: dataUrl
      });
    } catch (error) {
      // No-op, added to prevent uncaught errors
    }
  };

  closeModal = () => {
    this.setState({
      shouldShow: false,
      showImageUploader: false,
      imageUploaderFieldOption: MAP_TITLE
    });
    closeImageUploadModal();
  };

  setImageUploadLoader = () => {
    this.setState({
      showImageUploader: true
    });
  };

  handleImageUploaderFieldOption = ({ target: { value } }) => {
    this.setState({
      imageUploaderFieldOption: value
    });
  };

  handleImageFilesUpload = async () => {
    const { allImages, imageUploaderFieldOption } = this.state;
    const { t } = getI18N();
    const loaderKey = `handleImageFilesUpload${Date.now()}`;
    const catalogueId = (UPLOAD_IMAGE_MODAL.meta || {}).catalogueId || null;
    if (!catalogueId) {
      this.closeModal();
      return;
    }

    this.setImageUploadLoader();
    toggleGlobalLoader(loaderKey, true);

    try {
      await upsertCatalogueProducts({
        catalogueId,
        rawFiles: allImages,
        fileNameMappedTo: imageUploaderFieldOption
      });
    } catch (uploadError) {
      toastr.error(t('something_went_wrong_while_uploading_pictures_to_catalogue'));
    } finally {
      toggleGlobalLoader(loaderKey, false);
      this.closeModal();
    }
  };

  render() {
    const {
      shouldShow,
      displayName,
      canFileNameBePrice,
      noOfFiles,
      imageUploaderFieldOption,
      image,
      allImages,
      currencySymbol,
      showImageUploader
    } = this.state;
    const { t } = getI18N();

    if (!shouldShow) {
      return null;
    }

    if (!Array.isArray(allImages) || !allImages.length) {
      return (
        <ErrorPopup showContactSupport={false} onClose={this.closeModal} title="Upload Images">
          {t('no_image_files_present_in_your_folder_file_selections')}
        </ErrorPopup>
      );
    }

    if (showImageUploader) {
      return <PrepareImageUpload onClose={this.closeModal} />;
    }

    const firstImageStyle = {
      backgroundImage: `url(${image})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center'
    };

    return (
      <div className="ImageUploaderOption">
        <div className={'headerContainer'}>
          <div className="CloseCancelUpload" onClick={this.closeModal}>
            {t('cancel_upload')}
          </div>
          <h3 className="uploadFiles">
            {noOfFiles === 1
              ? t('upload_count_of_file', { count: noOfFiles || 0 })
              : t('upload_count_of_files', { count: noOfFiles || 0 })}
          </h3>
          <div>{t('use_file_name_to_auto_fill_product_information')}</div>
        </div>
        <div className="ImageUploaderOptionContent">
          <div className={'imageContainer'}>
            <img src={ImagePlaceholder} alt="" />
            <p className="ImageUploaderFileName">{displayName}</p>
          </div>
          <div className="arrowContainer">
            <div
              className="custom-select"
              style={{ background: `url(${DownArrow}) no-repeat 100% 50%` }}
            >
              <select
                value={imageUploaderFieldOption}
                onChange={this.handleImageUploaderFieldOption}
              >
                <option value={MAP_NONE}>{t(UPLOAD_MAP_KEYS_VALUES.get(MAP_NONE))}</option>
                <option value={MAP_TITLE}>{t(UPLOAD_MAP_KEYS_VALUES.get(MAP_TITLE))}</option>
                <option value={MAP_SKU}>{t(UPLOAD_MAP_KEYS_VALUES.get(MAP_SKU))}</option>
                <option value={MAP_SKU_NAME}>{t(UPLOAD_MAP_KEYS_VALUES.get(MAP_SKU_NAME))}</option>
                <option value={MAP_DESCRIPTION}>
                  {t(UPLOAD_MAP_KEYS_VALUES.get(MAP_DESCRIPTION))}
                </option>
                <option value={MAP_PRICE} disabled={!canFileNameBePrice}>
                  {t(UPLOAD_MAP_KEYS_VALUES.get(MAP_PRICE))}
                </option>
              </select>
              <div className="custom-select-right-arrow" />
            </div>
            <div className={'rightArrowContainer'}>
              <img src={RightArrow} alt="" />
            </div>
          </div>
          <div className="ImageUploaderOptionContentRight">
            <div className="ImageUploaderOptionContentRightImage">
              <div style={firstImageStyle} />
            </div>
            <div className="SelectedFileUploadText">
              {t(UPLOAD_MAP_KEYS_VALUES.get(imageUploaderFieldOption))}
            </div>
            <div className="ImageUploaderFileName">
              {`${imageUploaderFieldOption === MAP_PRICE ? currencySymbol : ''}  ${displayName}`}
            </div>
          </div>
        </div>
        <div className={'uploadImagesContainer'}>
          <button className="UploadFilesBtn" onClick={this.handleImageFilesUpload}>
            {t('upload_number_of_products', { count: `${noOfFiles || 0}` })}
            <img src={RightArrow} className="m-l-5" alt="" />
          </button>
        </div>
      </div>
    );
  }
}

export default ImageUploaderOption;
