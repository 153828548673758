import request from '../../Request';
import AppConfig from 'qs-config/AppConfig.js';
import { setLastFetchedVariantTimeStamp } from 'qs-helpers/Variants/ResponseProcessor.js';
import { modifyPicturesUrlBackToS3 } from 'qs-helpers/PicturesProcessingHelper';
import Api from '../../Api';

export const getAllCustomVariants = (getAllOptions = false) =>
  request.authGet(`${AppConfig.qsApiEndPoint}/v1/option-type?global=${getAllOptions}`);

export const getVariantsForProducts = async (productId, lastFetchedData) => {
  let queryParams = ``;
  if (lastFetchedData) {
    queryParams += `&lastFetchedDate=${encodeURIComponent(lastFetchedData)}`;
  }

  const variantsData = await request.authGet(
    `${AppConfig.qsApiEndPoint}/v1/variants?productId=${productId}${queryParams}`
  );
  setLastFetchedVariantTimeStamp({ date: variantsData.fetchedDate, productId });
  return variantsData;
};

export const getVariantsMeta = variantIds =>
  request.authPost(`${AppConfig.qsApiEndPoint}/v1/variants/meta`, { variantIds });

export const getVariantsInfo = variantId => Api.getBasicInfo(variantId);

export const updateVariantData = ({ variantId, updates }) =>
  request.authPost(`${AppConfig.qsApiEndPoint}/v1/variants`, {
    variantId,
    updates
  });
export const reorderOptions = ({ optionId, newIndex }) => {
  request.authPost(`${AppConfig.qsApiEndPoint}/v1/variants/reorder-options`, {
    optionId,
    newIndex
  });
};
export const deleteVariantData = variantId =>
  request.authDelete(`${AppConfig.qsApiEndPoint}/v1/variants`, { variantId });

export const setDefaultVariant = (parentProductId, variantId) =>
  request.authPost(`${AppConfig.qsApiEndPoint}/v1/variants/change-default-variant`, {
    parentProductId,
    variantId
  });

export const getProductOptions = parentProductId =>
  request.authGet(
    `${AppConfig.qsApiEndPoint}/v1/variants/options-android?productId=${parentProductId}&languageCode=en`
  );

export const deleteOptionsData = (productId, optionId) =>
  request.authDelete(`${AppConfig.qsApiEndPoint}/v1/variants/options`, { productId, optionId });

export const createProductOption = options =>
  request.authPost(`${AppConfig.qsApiEndPoint}/v1/variants/options`, {
    options
  });

export const updateProductOption = ({ id, label, rgb, isSet, setQuantity }) =>
  request.authPost(`${AppConfig.qsApiEndPoint}/v1/variants/edit-options`, {
    id,
    label,
    rgb,
    isSet,
    setQuantity
  });

export const updateProductOptionQuantity = ({ id, setQuantity }) =>
  request.authPut(`${AppConfig.qsApiEndPoint}/v1/variants/set/quantity`, {
    optionId: id,
    setQuantity
  });

export const getGlobalOptions = optionType =>
  request.authGet(`${AppConfig.qsApiEndPoint}/v1/variants/global-options?optionType=${optionType}`);

export const getCommonMetaCount = variantId =>
  request.authGet(
    `${AppConfig.qsApiEndPoint}/v1/variants/common-meta-count?variantId=${variantId}`
  );

export const uploadVariantPictures = ({ pictures, variantId, optionId } = {}) =>
  request.authPost(`${AppConfig.qsApiEndPoint}/v1/variants/pictures`, {
    pictures: modifyPicturesUrlBackToS3(pictures),
    variantId,
    optionId
  });
