import { useEffect } from 'react';
import {
  setupFirebaseAndSentry,
  setupAppForLogin,
  setupAppLanguage,
  beforeUnloadHandler,
  markAllPendingImagesAsErrored
} from './PostLoginHelper';
import { POST_MESSAGE_SLUGS, windowPostMessageProxy } from 'qs-helpers/WindowPostMessageProxy';
import firebase from 'firebase/app';
import 'firebase/auth';
import { setCompanyLevelGoldRateAndProductTypeInCache } from 'qs-data-manager/Company';

export default ({ children, loginState }) => {
  /*
    The effects will only be executed if the user is already logged in.
    That is if the user is visiting the web and is already logged-in
    If the user is logging in then these effects will not be hit. Instead
    the login page will have the functionality
  */
  useEffect(() => {
    return setupFirebaseAndSentry(loginState);
  }, [loginState]);

  useEffect(() => {
    setupAppForLogin(loginState);
    setupAppLanguage(loginState);
    markAllPendingImagesAsErrored(loginState);
  }, [loginState]);

  const refreshToken = () => {
    const currentUser = firebase.auth().currentUser;
    if (currentUser) {
      return currentUser.getIdToken();
    }
    return null;
  };

  useEffect(() => {
    const handler = {
      test: message => {
        return message?.slug === POST_MESSAGE_SLUGS.RefreshToken;
      },
      handle: async () => {
        const data = await refreshToken();
        return { token: data };
      }
    };
    windowPostMessageProxy.addHandler(handler);
    return () => windowPostMessageProxy.removeHandler(handler);
  }, []);

  useEffect(() => {
    const handler = {
      test: message => {
        return message?.slug === POST_MESSAGE_SLUGS.ProductType;
      },
      handle: message => {
        if (message?.value) {
          setCompanyLevelGoldRateAndProductTypeInCache({ data: message?.value });
          return;
        }
        return;
      }
    };
    windowPostMessageProxy.addHandler(handler);
    return () => windowPostMessageProxy.removeHandler(handler);
  }, []);

  //Set up the unload handler that will show a dialog to the user before
  //unloading if images are currently being uploaded
  useEffect(() => {
    window.addEventListener('beforeunload', beforeUnloadHandler);
    return () => window.removeEventListener('beforeunload', beforeUnloadHandler);
  }, []);

  return children;
};
