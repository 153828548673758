import request from '../../Request';
import AppConfig from 'qs-config/AppConfig.js';
import {
  setLastFetchedProductLibTimeStamp,
  setLastFetchedProductLibKey
} from 'qs-helpers/ProductLibrary/ResponseProcessor';
import Api from '../../Api';

export const getAllCompanyProducts = async (timeStamp, nextKey) => {
  let queryParams = [];
  if (timeStamp) {
    queryParams.push(`timestamp=${encodeURIComponent(timeStamp)}`);
  }
  if (nextKey) {
    queryParams.push(`nextKey=${encodeURIComponent(nextKey)}`);
  }

  let queryParamsString = ``;
  if (queryParams.length > 0) {
    queryParamsString += `?${queryParams.join('&')}`;
  }

  const companyProductsData = await request.authGet(
    `${AppConfig.qsApiEndPoint}/v1/product-library/products${queryParamsString}`
  );
  setLastFetchedProductLibTimeStamp(companyProductsData.nextTimestamp);
  setLastFetchedProductLibKey(companyProductsData.nextKey);
  return companyProductsData;
};

export const getAllCatalogueProducts = catalogueId =>
  request.authGet(
    `${AppConfig.qsApiEndPoint}/v1/product-library/catalogue-products?catalogueId=${catalogueId}`
  );

export const getProductLibCatalogueMeta = () =>
  request.authGet(`${AppConfig.qsApiEndPoint}/v1/product-library/catalogues-meta`);

export const duplicateProductsToCatalogue = ({
  catalogueId,
  productIds /* , catalogueProductMap */
}) =>
  /* request.authPost(`${AppConfig.qsApiEndPoint}/v2/product-library/catalogue-products/duplicate`, {
    catalogueId,
    productIds,
    shouldCreate: false,
    catalogueProductMap
  }); */
  Api.duplicateProductsToCatalogue({ catalogueId, productIds, shouldCreate: false });

export const productLibCatalogueSearch = ({ searchTerm, page, excludeCatalogueId }) => {
  const queryParamsString = request.createQueryParams([
    {
      key: 'searchTerm',
      value: searchTerm ? searchTerm : null
    },
    {
      key: 'excludeCatalogueId',
      value: excludeCatalogueId
    },
    {
      key: 'page',
      value: page
    }
  ]);
  return request.authGet(
    `${AppConfig.qsApiEndPoint}/v1/product-library/search-catalogues${queryParamsString}`
  );
};

export const productLibProductSearch = ({ catalogueId, searchTerm, page, unpackDefaultVariant }) =>
  request.authPost(`${AppConfig.qsApiEndPoint}/v1/product-library/search-products`, {
    page,
    searchTerm: searchTerm ? searchTerm : null,
    catalogueIds: catalogueId ? [catalogueId] : null,
    unpackDefaultVariant
  });

export const productLibCatalogueProducts = ({
  catalogueId,
  nextPosition,
  unpackDefaultVariant
}) => {
  const queryParamString = request.createQueryParams([
    {
      key: 'catalogueId',
      value: catalogueId
    },
    {
      key: 'lastPosition',
      value: nextPosition
    },
    {
      key: 'unpackDefaultVariant',
      value: unpackDefaultVariant
    }
  ]);
  return request.authGet(
    `${AppConfig.qsApiEndPoint}/v2/product-library/catalogue-products${queryParamString}`
  );
};
