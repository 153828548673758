export const matchText = ({ text, matchValue }, { caseInsensitive = false } = {}) => {
  if (typeof text !== 'string' || typeof matchValue !== 'string') {
    return false;
  }

  let textToMatch = text.trim();
  let textToMatchAgainst = matchValue.trim();
  if (caseInsensitive) {
    textToMatch = textToMatch.toLowerCase();
    textToMatchAgainst = textToMatchAgainst.toLowerCase();
  }

  return textToMatchAgainst === textToMatch;
};
