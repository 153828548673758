import eventbus from 'eventing-bus';
import { attachDefaultListeners, removeDefaultListeners } from 'qs-services/Firebase';
import { COMPANY_LOCAL_STORAGE_KEYS } from 'qs-data-manager/Company';
import { setSentryContext } from 'qs-helpers/ErrorReporting';
import { rehydrationService } from 'qs-services/Rehydration';
import Api from '../Api';
import { APP_LANGUAGES } from '../i18N';
import Mixpanel from 'qs-data-manager/Mixpanel';
import { isImageUploadInProgress } from './ProcessUploadedImage';
import { failPendingImagesForThisDevice } from './ProcessUploadedImage';
import { APP_LANGUAGE_CHANGED_EB_KEY } from './CompanySettings/constants';

export const setupFirebaseAndSentry = loginState => {
  if (loginState.isLoggedIn && !loginState.loggingIn) {
    const companyId = (
      JSON.parse(localStorage.getItem(COMPANY_LOCAL_STORAGE_KEYS.COMPANY_META)) || {}
    ).id;

    const userId = (JSON.parse(localStorage.getItem(COMPANY_LOCAL_STORAGE_KEYS.USER_META)) || {})
      .id;

    const email = (JSON.parse(localStorage.getItem(COMPANY_LOCAL_STORAGE_KEYS.USER_META)) || {})
      .email;

    attachDefaultListeners({
      companyId,
      userId
    });

    setSentryContext({ userId, email, companyId });

    return () => {
      removeDefaultListeners({ companyId, userId });
    };
  }

  return () => {};
};

export const setupAppForLogin = loginState => {
  if (loginState.isLoggedIn && !loginState.loggingIn) {
    rehydrationService();
    Mixpanel.setSessionAttributes();
  }
};

export const setupAppLanguage = loginState => {
  if (loginState.isLoggedIn && !loginState.loggingIn) {
    const companyId = (
      JSON.parse(localStorage.getItem(COMPANY_LOCAL_STORAGE_KEYS.COMPANY_META)) || {}
    ).id;
    Api.getAppLanguage(companyId)
      .then(({ value: languageCode = 'en_US' }) => {
        if (Object.keys(APP_LANGUAGES).indexOf(languageCode) > -1) {
          localStorage.setItem(COMPANY_LOCAL_STORAGE_KEYS.APP_LANGUAGE, languageCode);
          eventbus.publish(APP_LANGUAGE_CHANGED_EB_KEY, languageCode);
        }
      })
      .catch(() => {
        localStorage.setItem(COMPANY_LOCAL_STORAGE_KEYS.APP_LANGUAGE, 'en_US');
        eventbus.publish(APP_LANGUAGE_CHANGED_EB_KEY, 'en_US');
      });
  }
};

export const beforeUnloadHandler = event => {
  if (isImageUploadInProgress()) {
    // This will be enough for certain browsers to display their own message
    event.preventDefault();

    // This additional text is useful in those browsers that don't work with the above approach
    event.returnValue =
      'Product images are still uploading. If you leave now these images will not be uploaded. Are you sure you want to exit?';
  }
};

export const markAllPendingImagesAsErrored = loginState => {
  if (loginState.isLoggedIn && !loginState.loggingIn && !loginState.isSetupIncomplete) {
    failPendingImagesForThisDevice();
  }
};
