import React from 'react';

import './styles.scss';
import { selectedLabelsToEdit } from 'qs-data-manager/Selected';
import { getI18N } from 'qs-services/i18N';

export default () => {
  const { t } = getI18N();

  return (
    <div id={'NoCatalogueSelected'}>
      {selectedLabelsToEdit.isActive() ? '' : t('select_a_catalogue_to_view_the_list_of_products')}
    </div>
  );
};
