import React from 'react';
import { EXCEL_UPLOAD_META } from 'qs-data-manager/Catalogues';
import Header from './Header';
import { ReactComponent as Arrow } from 'qs-assets/Media/icon_right_arrow.svg';
import ColumnPicker from '../../Common/ColumnPicker';
import { shouldShowInDropDown } from 'qs-helpers/CSVUploader';
import { ReactComponent as ExcelIcon } from 'qs-assets/Media/icon_excel.svg';
import './styles.scss';

const getDropDownValues = ({ column, excelComponentMap }) =>
  EXCEL_UPLOAD_META.RENDER_ORDER.reduce((cumulativeArray, id) => {
    if (!shouldShowInDropDown(id, column)) {
      return cumulativeArray;
    }

    const {
      title,
      disabled,
      parentSection,
      customStyles,
      componentType,
      loading,
      refreshing,
      error,
      subExcelComponents,
      hasSubcomponents
    } = excelComponentMap[id];

    if (
      hasSubcomponents &&
      (!subExcelComponents || (Array.isArray(subExcelComponents) && !subExcelComponents.length))
    ) {
      return cumulativeArray;
    }

    let subSectionItems = [];
    if (Array.isArray(subExcelComponents)) {
      subSectionItems = subExcelComponents.reduce((nestedCumulativeArray, subExcelId) => {
        if (!shouldShowInDropDown(subExcelId, column)) {
          return nestedCumulativeArray;
        }

        const {
          title: subSectionTitle,
          disabled: subSectionDisabled,
          parentSection: subSectionParent,
          customStyles: subSectionCustomStyles
        } = excelComponentMap[subExcelId] || {};

        nestedCumulativeArray.push({
          value: subExcelId,
          displayValue: subSectionTitle,
          disabled: subSectionDisabled,
          parentSection: subSectionParent,
          customStyles: subSectionCustomStyles
        });
        return nestedCumulativeArray;
      }, []);
    }

    // Sub section exists but none of the rows must be shown in the
    // drop down values, hence remove the field heading too
    if (Array.isArray(subExcelComponents) && subSectionItems.length === 0) {
      return cumulativeArray;
    }

    cumulativeArray.push({
      value: id,
      displayValue: title,
      disabled,
      parentSection,
      customStyles,
      componentData: {
        type: componentType,
        loading,
        refreshing,
        error
      }
    });
    cumulativeArray.push(...subSectionItems);

    return cumulativeArray;
  }, []);

const getSelectedValues = ({ columnNameDetailsMap, column, multiple }) => {
  const selectedColumn = columnNameDetailsMap[column] || {};
  const sortedSelectedColumn = Object.keys(selectedColumn).sort(
    (key, key1) => selectedColumn[key] - selectedColumn[key1]
  );

  return multiple ? sortedSelectedColumn : sortedSelectedColumn[0] || '';
};

export default ({
  csvData = [],
  columnNames = [],
  excelComponentMap,
  columnNameDetailsMap,
  onChange,
  showDialogue
}) => {
  return (
    <div id={'excel-column-mapper'}>
      <Header columnNamesLength={columnNames.length} excelRowCount={csvData.length} />

      <div className={'mapper'}>
        {columnNames.map((column, key) => {
          const multiple = false;
          const selectedValues = getSelectedValues({ columnNameDetailsMap, column, multiple });
          const dropDownValues = getDropDownValues({
            column,
            excelComponentMap
          });

          return (
            <div key={key} className={'row'}>
              <ExcelIcon className={'excel-icon'} />
              <div className={'component-name'}>{column}</div>
              <div className={'right-container'}>
                <Arrow className={'arrow'} />
                <ColumnPicker
                  dropDownValues={dropDownValues}
                  column={column}
                  multiple={multiple}
                  onChange={onChange}
                  value={selectedValues}
                  showDialogue={showDialogue}
                  excelComponentMap={excelComponentMap}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
