import { reportError } from 'qs-helpers/ErrorReporting';
import { getAllCustomVariants } from 'qs-api/Variants/api';

export const getAllCompanyVariants = async () => {
  try {
    return await getAllCustomVariants(true).then(data => {
      const { optionTypes } = data;
      return optionTypes;
    });
  } catch (error) {
    reportError(error);
    throw error;
  }
};
