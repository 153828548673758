import React from 'react';
import DeleteAction from './DeleteAction';
import SaveAction from './SaveAction';
import ImageUploadProgressBar from '../../../Common/ImageUploadProgressBar';
import { UPLOAD_PRODUCT_PICURES_HEADER } from 'qs-helpers/ProcessUploadedImage';
import { BOTTOM_SHEET_TABS } from 'qs-helpers/Products/constants';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

export default ({ onChangeTab, activeTab, activeProductId, isBulkEditing, headerClass } = {}) => {
  const hasCustomFields = false;
  const { t } = getI18N();
  return (
    <div className={`productDetailsHeader ${headerClass}`}>
      <div className={'productTabsSection'}>
        <div
          onClick={() => onChangeTab(BOTTOM_SHEET_TABS.BASIC_INFO, true)}
          className={`option ellipsis ${
            activeTab === BOTTOM_SHEET_TABS.BASIC_INFO ? 'activeTab' : ''
          }`}
        >
          {t('basic_info')}
        </div>
        {!isBulkEditing && (
          <div
            onClick={() => onChangeTab(BOTTOM_SHEET_TABS.VARIANTS, false)}
            className={`option ellipsis ${
              activeTab === BOTTOM_SHEET_TABS.VARIANTS ? 'activeTab' : ''
            }`}
          >
            {t('variants')}
          </div>
        )}
        <div
          onClick={() => onChangeTab(BOTTOM_SHEET_TABS.INVENTORY, false)}
          className={`option ellipsis ${
            activeTab === BOTTOM_SHEET_TABS.INVENTORY ? 'activeTab' : ''
          }`}
        >
          {t('inventory')}
        </div>
        <div
          onClick={() => onChangeTab(BOTTOM_SHEET_TABS.PRIVATE_NOTES, true)}
          className={`option ellipsis ${
            activeTab === BOTTOM_SHEET_TABS.PRIVATE_NOTES ? 'activeTab' : ''
          }`}
        >
          {t('more')}
        </div>
        {!hasCustomFields && !isBulkEditing && (
          <div
            onClick={() => onChangeTab(BOTTOM_SHEET_TABS.CUSTOM, false)}
            className={`option ellipsis ${
              activeTab === BOTTOM_SHEET_TABS.CUSTOM ? 'activeTab' : ''
            }`}
          >
            {t('custom')}
          </div>
        )}
      </div>
      {!isBulkEditing && (
        <div className="productActionsSection">
          <DeleteAction activeProductId={activeProductId} />
          <SaveAction activeProductId={activeProductId} />
          <ImageUploadProgressBar
            uploadHeader={UPLOAD_PRODUCT_PICURES_HEADER}
            mappingId={activeProductId}
            containerClassName="imageUploadHeader"
          />
        </div>
      )}
    </div>
  );
};
