import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    backgroundColor: '#168c5b'
  },
  endIcon: {
    marginLeft: '15px'
  }
});

export default useStyles;
