import React, { useCallback, useState } from 'react';
import toastr from 'toastr';
import { addNewCatalogueLabelToRemote } from 'qs-data-manager/Catalogues/CatalogueLabels';
import DialogBox from 'qs-components/Common/DialogBox';
import { toggleGlobalLoader } from 'qs-helpers';
import Mixpanel from 'qs-data-manager/Mixpanel';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

export default ({ open, setOpen }) => {
  const [labelName, setLabelName] = useState('');
  const { t } = getI18N();

  const closeDialog = () => {
    setLabelName('');
    setOpen(false);
  };

  const updateLabelName = useCallback(event => {
    event.preventDefault();
    setLabelName(event.target.value);
  }, []);

  const createNewLabel = async value => {
    if (!value || !value.trim()) {
      return;
    }
    const labelName = value.trim();
    const key = `addNewCatalogueLabel${Date.now()}`;
    toggleGlobalLoader(key, true);
    try {
      await addNewCatalogueLabelToRemote({ labelName });
      toastr.success(t('new_catalogue_label_added'));
      Mixpanel.sendEvent({
        eventName: 'catalogue_label_created',
        props: {
          label_name: labelName,
          label_created: true
        }
      });
    } catch (updateError) {
      toastr.error(t('failed_to_add_new_catalogue_label'));
    } finally {
      toggleGlobalLoader(key, false);
      closeDialog();
    }
  };

  const handleCreateLabelClick = event => {
    event.preventDefault();
    createNewLabel(labelName);
  };

  const updateOnKeyPress = useCallback(event => {
    if (event.key === 'Enter') {
      createNewLabel(event.target.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DialogBox
      open={open}
      onClose={() => closeDialog()}
      title={t('new_catalogue_label')}
      actionsConfig={[
        {
          content: t('create_label'),
          clickHandler: handleCreateLabelClick,
          role: 'PRIMARY'
        }
      ]}
      maxWidth="sm"
      id="create-new-label-dialog-box"
    >
      <div className={'newCatalogueLabelDialog'}>
        <div className={'labelDialogSubtitle'}>{t('what_are_labels')}</div>
        <div className={'labelDialogContent'}>
          {t('labels_help_you_organize_your_catalogues_better_and_are_only_visible_to_you')}
        </div>
        <input
          className={'labelDialogInputField'}
          placeholder={t('type_label_name')}
          value={labelName}
          onChange={updateLabelName}
          onKeyPress={updateOnKeyPress}
          autoFocus
        />
      </div>
    </DialogBox>
  );
};
