import React, { useState, useEffect, Fragment, useCallback, useRef } from 'react';
import { VARIANT_ADD_TABS } from 'qs-helpers/Variants/constants';
import toastr from 'toastr';
import {
  createNewOption,
  getCurrentProductOptionsListFromCache
} from 'qs-data-manager/Variants/ProductOptions';
import ColorPicker from '../ColorPicker';
import GlobalOptions from './GlobalOptions';
import { getNameFromColor, getColorsFromName } from 'qs-helpers/Variants/ColorMapping';
import CreateOptionsView from './CreateOptionsView';
import './styles.scss';
import { getI18N } from 'qs-services/i18N';

export default ({ activeProductId, currentTab, optionAdded }) => {
  const [rgbValue, setRgbValue] = useState(null);
  const [rgbSetByUser, setRgbSetByUser] = useState(null);
  const [inputText, setInputText] = useState('');
  const [inputAddedByUser, setInputAddedByUser] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showColorPicker, toggleColorPicker] = useState(false);
  const [colorsFromSearch, setColorsFromSearch] = useState();
  const [addAsSet, setAddAsSet] = useState(false);
  const [quantity, setQuantity] = useState('');
  const inputRef = useRef(null);
  const addColorClicked = useRef(false);

  const { t } = getI18N();

  const onAddAsSetChange = useCallback(event => {
    const { checked } = event.target;
    setAddAsSet(checked);
    //When adding as set, rgb can not be set
    if (checked) {
      setRgbValue(null);
      setColorsFromSearch();
    }
  }, []);

  const resetState = useCallback(() => {
    setRgbValue(null);
    setInputText('');
    setRgbSetByUser(false);
    setInputAddedByUser(false);
    setColorsFromSearch();
    toggleColorPicker(false);
    setShowLoader(false);
    setAddAsSet(false);
    setQuantity('');
  }, []);

  useEffect(() => {
    resetState();
  }, [currentTab.type, resetState]);

  //Will be used to search for colors based on text
  const handleInputChange = colorName => {
    if (colorName.trim() === '') {
      setColorsFromSearch();
      return;
    }

    const { matchingColors, exactMatchColor } = getColorsFromName(colorName);
    if (Array.isArray(exactMatchColor) && !rgbSetByUser) {
      setRgbValue(exactMatchColor);
    }

    setColorsFromSearch(matchingColors);
  };

  const isSetInputValid = () => {
    if (!addAsSet) {
      return true;
    }

    if (Number(quantity) >= 1) {
      return true;
    }

    window.alert(t('please_specify_a_quantity_for_the_set'));
    return false;
  };

  const isOptionValid = () => {
    const trimmedInput = inputText.trim();
    if (trimmedInput === '') {
      window.alert(t('please_specify_a_name_for_the_option'));
      return false;
    }

    if (currentTab.type === VARIANT_ADD_TABS.COLORS) {
      //Check rgb only if addAsSet is false. Otherwise rgb can not be set
      if (!addAsSet && rgbValue === null) {
        toggleColorPicker(true);
        addColorClicked.current = true;
        return false;
      }

      return isSetInputValid();
    }

    //Ensure that duplicate size is not set for a product
    if (currentTab.type === VARIANT_ADD_TABS.SIZES) {
      if (!isSetInputValid()) {
        return false;
      }

      const currentTabOptionData = getCurrentProductOptionsListFromCache({
        productId: activeProductId,
        currentTab: currentTab.type
      });

      for (const optionDatum of currentTabOptionData) {
        if (trimmedInput === optionDatum.label) {
          toastr.error(t('this_size_already_exists_in_the_product'));
          return false;
        }
      }
    }
    return true;
  };

  const creationOptionInProduct = optionData => {
    setShowLoader(true);
    createNewOption(optionData)
      .then(() => {
        resetState();
        inputRef.current.focus();
        optionAdded();
      })
      .catch(err => {
        toastr.error(err.message);
        setShowLoader(false);
      });
  };

  const createOption = event => {
    event.preventDefault();

    if (!isOptionValid()) {
      return;
    }

    const optionData = {
      parentProductId: activeProductId,
      optionType: currentTab.type,
      optionTypeId: currentTab.optionTypeId,
      optionValue: inputText.trim(),
      rgbValue
    };
    if (addAsSet) {
      optionData.isSet = addAsSet;
      optionData.setQuantity = Number(quantity);
    }

    creationOptionInProduct(optionData);
  };

  const createOptionColorSelected = rgbFromPicker => {
    const optionData = {
      parentProductId: activeProductId,
      optionType: currentTab.type,
      optionValue: inputText.trim(),
      rgbValue: rgbFromPicker
    };

    creationOptionInProduct(optionData);
  };

  const handleColorButtonClick = event => {
    event.preventDefault();
    toggleColorPicker(true);
  };

  const handleColorPickerClose = useCallback(() => {
    inputRef.current.focus();
    toggleColorPicker(false);
    addColorClicked.current = false;
  }, [toggleColorPicker]);

  const handleColorPickerSelection = (rgbFromPicker, hexFromPicker) => {
    /*
      If the picker was opened because the add color button was clicked but the
      color was not selected, then upon successful selection create the option
    */
    const proceedWithOptionCreation = addColorClicked.current;
    handleColorPickerClose();
    setRgbSetByUser(true);
    if (!rgbFromPicker) {
      return;
    }

    setRgbValue(rgbFromPicker);

    /*
      proceedWithOptionCreation true signifies that the text is added by the
      user, hence the checks below this can be safely ignored
    */
    if (proceedWithOptionCreation) {
      createOptionColorSelected(rgbFromPicker);
      return;
    }

    /*
      Hex value is used to match the color with the text hence if that is not
      present no need to proceed furhter. If the input has been added by user
      and not by the UI then irrespective of the color, honour the user's input
      and don't overwrite it
    */
    if (!hexFromPicker || inputAddedByUser) {
      return;
    }

    //Get the name and set it as a label
    const colorName = getNameFromColor(hexFromPicker);
    setInputText(colorName);
  };

  const inputChanged = event => {
    const { value } = event.target;
    setInputText(value);
    setInputAddedByUser(value === '' ? false : true);
    //Prevent search if add as set is selected
    if (!addAsSet && currentTab.type === VARIANT_ADD_TABS.COLORS) {
      handleInputChange(value);
    }
  };

  const searchClick = useCallback(({ event, rgbValue: rgbFromSearch }) => {
    event.preventDefault();
    setRgbValue(rgbFromSearch);
    setRgbSetByUser(true);
  }, []);

  const getIconComponent = () => {
    if (currentTab.type !== VARIANT_ADD_TABS.COLORS) {
      return null;
    }

    let colorIconClick = handleColorButtonClick,
      iconClass = 'optionsIcon';
    if (showLoader) {
      colorIconClick = null;
      iconClass += ' loadInProgress';
    }

    //As long as add as set is true, this condition will never be true,
    //hence addAsSet check can be done after this
    if (rgbValue) {
      return (
        <div className={iconClass} onClick={colorIconClick}>
          <div
            className="rgbColorIcon"
            style={{ backgroundColor: `rgb(${rgbValue[0]}, ${rgbValue[1]}, ${rgbValue[2]})` }}
          ></div>
        </div>
      );
    }

    if (addAsSet) {
      colorIconClick = null;
      iconClass += ' readOnlyOptionsIcon';
    }

    return (
      <div className={iconClass} onClick={colorIconClick}>
        <img className="colorPickerIcon" src={currentTab.url} alt="colorIcon" />
      </div>
    );
  };

  return (
    <Fragment>
      <div className="newProductOptions">
        <CreateOptionsView
          addAsSet={addAsSet}
          addAsSetChange={onAddAsSetChange}
          quantity={quantity}
          quantityChange={setQuantity}
          showLoader={showLoader}
          inputText={inputText}
          onInputChange={inputChanged}
          currentTabType={currentTab.type}
          createOption={createOption}
          iconComponent={getIconComponent()}
          inputRef={inputRef}
        />
        <GlobalOptions
          optionType={currentTab.type}
          optionTypeId={currentTab.optionTypeId}
          activeProductId={activeProductId}
          searchClick={searchClick}
          searchColors={colorsFromSearch}
          optionAdded={optionAdded}
        />
      </div>
      {showColorPicker && (
        <ColorPicker
          onClose={handleColorPickerClose}
          onSelectColor={handleColorPickerSelection}
          rgb={rgbValue}
        />
      )}
    </Fragment>
  );
};
