import React, { Fragment, useContext, useState, useRef } from 'react';
import { ReactComponent as BackIcon } from 'qs-assets/Media/arrow-left.svg';
import { ReactComponent as TrashIcon } from 'qs-assets/Media/trash.svg';
import { VariantDataForUpdate } from '../context';
import {
  getVariantBasicInfoFromCache,
  saveVariantChanges
} from 'qs-data-manager/Variants/VariantsDetails';
import { toggleGlobalLoader } from 'qs-helpers';
import { ActiveVariantId } from '../../context';
import { deleteVariants } from 'qs-data-manager/Variants/FetchVariants';
import { UPDATE_ORIGINAL_TO_CURRENT, CUSTOM_FIELDS_UPDATED } from '../reducer';
import Modal from 'react-responsive-modal';
import toastr from 'toastr';
import Api from 'qs-services/Api';
import './styles.scss';
import { getI18N } from 'qs-services/i18N';

export default ({ activeProductId }) => {
  const { variantData, setVariantData } = useContext(VariantDataForUpdate);
  const { activeVariantId, setActiveVariantId } = useContext(ActiveVariantId);

  const [showDeleteConfirmation, toggleDeleteConfirmation] = useState(false);
  const modalStyles = useRef({
    color: 'black',
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '500px',
    padding: '8px',
    paddingLeft: '24px'
  });

  const { t } = getI18N();

  const onSaveClick = async () => {
    if (variantData.customFieldsChanged) {
      const loaderKey = `saveVariantCustomFields${Date.now()}`;
      toggleGlobalLoader(loaderKey, true);
      try {
        const { fieldsData } = variantData;
        const promises = [];
        Object.keys(fieldsData).forEach(key => {
          if (fieldsData[key].value !== null) {
            let { value: fieldValue, type } = fieldsData[key];
            if ((type === 'STRING' || type === 'NUMBER') && fieldValue === '') {
              promises.push(
                Api.deleteProductCustomField({
                  fieldId: key,
                  productId: activeVariantId
                })
              );
            } else {
              if (type === 'NUMBER') {
                fieldValue = Number(fieldValue);
              }
              promises.push(
                Api.setProductCustomFieldData({
                  fieldValue,
                  fieldId: key,
                  productId: activeVariantId
                })
              );
            }
          }
        });
        await Promise.all(promises);
        setVariantData({
          type: CUSTOM_FIELDS_UPDATED
        });
      } catch {
        toastr.error(t('something_went_wrong_while_saving_the_variant_custom_field'));
      } finally {
        toggleGlobalLoader(loaderKey, false);
      }
      return;
    }

    //Discount is editable and discount is greater than price, show error and return
    if (
      !variantData.showDiscountChange &&
      Number(variantData.discount) > Number(variantData.price)
    ) {
      alert(t('discount_cannot_be_greater_than_price'));
      return;
    }

    const loaderKey = `saveSingleVariantChanges${Date.now()}`;
    toggleGlobalLoader(loaderKey, true);
    try {
      const variantInfoCache = getVariantBasicInfoFromCache({ activeVariantId, activeProductId });

      const isValid = await saveVariantChanges([
        {
          data: variantData,
          variantId: activeVariantId,
          updateData: setVariantData,
          variantInfoCache
        }
      ]);
      // FIXME: allow saving productVariantDetails in save variants changes api
      // showing stone details from product
      // save them too
      if (!isValid) {
        return;
      }
      setVariantData({ type: UPDATE_ORIGINAL_TO_CURRENT });
    } catch (saveError) {
      toastr.error(t('something_went_wrong_while_saving_the_variant_info'));
    } finally {
      toggleGlobalLoader(loaderKey, false);
    }
  };

  const onTrashClick = () => {
    toggleDeleteConfirmation(true);
  };

  const deleteConfirmationClick = async () => {
    toggleDeleteConfirmation(false);
    const loaderUniqueKey = `DELETE_VARIANT_${activeVariantId}`;
    toggleGlobalLoader(loaderUniqueKey, true);
    try {
      const nextVariantId = await deleteVariants([activeVariantId], activeProductId);
      setActiveVariantId(nextVariantId);
      toastr.success(t('variant_deleted_successfully'));
    } catch (deleteVariantError) {
      toastr.error(t('something_went_wrong_while_deleting_the_variant'));
    } finally {
      toggleGlobalLoader(loaderUniqueKey, false);
    }
  };

  const closeDeleteConfirmation = () => {
    toggleDeleteConfirmation(false);
  };

  return (
    <Fragment>
      <div className="variantsViewHeader">
        <div className="backButtonContainer" onClick={() => setActiveVariantId()}>
          <div className="backIcon">
            <BackIcon />
          </div>
          <div className="ellipsis productText">{t('back_to_product')}</div>
        </div>
        <div className="actionContainer">
          <TrashIcon onClick={onTrashClick} className={'trashIcon'} />
          {variantData.showSave && (
            <div onClick={onSaveClick} className={'variantsSaveButton'}>
              {t('save')}
            </div>
          )}
        </div>
      </div>
      {showDeleteConfirmation && (
        <Modal
          open={true}
          center={true}
          closeOnOverlayClick={false}
          closeOnEsc={false}
          showCloseIcon={false}
          styles={{ modal: modalStyles.current }}
        >
          <div className="variantDelete confirmationTitle">{t('delete_variant')}</div>
          <div className="variantDelete confirmationDescription">
            {t('are_you_sure_you_want_to_delete_this_variant')}
          </div>
          <div className="variantDelete buttonContainer">
            <button
              type="button"
              className="buttonPlain buttonDelete"
              onClick={deleteConfirmationClick}
            >
              {t('delete')}
            </button>
            <button type="button" className="buttonPlain" onClick={closeDeleteConfirmation}>
              {t('cancel')}
            </button>
          </div>
        </Modal>
      )}
    </Fragment>
  );
};
