import React, { useContext, useState, useEffect } from 'react';
import { getCompanyCurrencySymbol } from 'qs-data-manager/Company';
import moment from 'moment';
import { ActiveOrderMeta } from '../../../context';
import { UPDATE_ORDER_META } from '../../../reducer';
import {
  getOrderMetaFromCache,
  attachOrderMetaListener,
  removeOrderMetaListener
} from 'qs-data-manager/Orders/OrdersOperations';
import CacheListenerCallback from 'qs-helpers/CacheListenerCallback';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

export default ({ orderData, style }) => {
  const { id: orderId } = orderData || {};
  const [orderMeta, setOrderMeta] = useState(getOrderMetaFromCache({ orderId }) || {});
  const {
    displayOrderId,
    visitorName,
    visitorPhone,
    numberOfItems,
    dateUpdated,
    finalAmount,
    isRead
  } = orderMeta;
  const { t } = getI18N();

  useEffect(() => {
    setOrderMeta(getOrderMetaFromCache({ orderId }) || {});
    const listener = (_, payload) => {
      const { data } = CacheListenerCallback(undefined, payload);
      if (!data) {
        return;
      }
      setOrderMeta(data);
    };

    attachOrderMetaListener({ listener, orderId });
    return () => removeOrderMetaListener({ listener, orderId });
  }, [orderId, setOrderMeta]);

  const {
    activeOrderMeta: { selectedOrderId },
    setActiveOrderMeta
  } = useContext(ActiveOrderMeta);

  const handleOrderClick = event => {
    event.preventDefault();
    setActiveOrderMeta({ type: UPDATE_ORDER_META, orderId });
  };

  const getUpdateDateFromNow = () => {
    if (typeof dateUpdated !== 'string') {
      return '';
    }
    const date = moment(dateUpdated).fromNow();
    const translatedDate = date
      .split(' ')
      .map(dateFragment => t(dateFragment.toLowerCase()))
      .join(' ');
    return translatedDate;
  };

  const getVisitorInfo = () => {
    if (!visitorName) {
      return visitorPhone || '';
    }
    return visitorName;
  };

  let orderRowContainerClass = 'ordersRowHoverContainer';
  if (selectedOrderId === orderId) {
    orderRowContainerClass += ' selectedOrder';
  }

  return (
    <div style={style} onClick={handleOrderClick} className={orderRowContainerClass}>
      <div className="ordersRowContainer">
        <div className="orderHeader">
          <div className="orderNumber ellipsis">
            {t('order')} #{displayOrderId}
          </div>
          {!isRead && <div className="unreadOrder ellipsis"> {t('new')} </div>}
        </div>
        <div className="customerName ellipsis">{getVisitorInfo()}</div>
        <div className="additionalOrderInfo">
          <div className="orderLeftContainer">
            <span className="ellipsis">
              {numberOfItems} {numberOfItems === 1 ? t('item') : t('items')}
            </span>
            <div className="dotSeperator" />
            <span className="ellipsis">{getUpdateDateFromNow()}</span>
          </div>
          <div className="orderRightContainer">{`${getCompanyCurrencySymbol()} ${finalAmount}`}</div>
        </div>
      </div>
    </div>
  );
};
