import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { Drawer as MaterialUiDrawer } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import eventbus from 'eventing-bus';
import { NAVIGATION } from 'qs-helpers/index';
import {
  ROUTE_MAP,
  SETTINGS_COMPONENT_ID_MAP,
  SETTINGS_COMPONENT_ROUTE_MAP,
  HELP_CENTER_DRAWER_POST_MESSAGE_ID,
  SIDEBAR_VISIBILITY_CHANGED_EB_KEY
} from '../../../Services/Helpers/CompanySettings/constants';
import './styles.scss';
import { ReactComponent as CatalogueIcon } from 'qs-assets/Media/folder.svg';
import { ReactComponent as InquiryIcon } from 'qs-assets/Media/inquiryIcon.svg';
import { ReactComponent as SettingsIcon } from 'qs-assets/Media/settingsOutlineIcon.svg';
import { ReactComponent as GlobalInventoryIcon } from 'qs-assets/Media/globalInventoryIcon.svg';
import { ReactComponent as HelpIcon } from 'qs-assets/Media/icons8-help.svg';
import { ReactComponent as HelpCenterIcon } from 'qs-assets/Media/training.svg';
import { ReactComponent as ViewStoreIcon } from 'qs-assets/Media/homeIcon.svg';
import { navigateToPath } from 'qs-data-manager';
import { uploadSkuExcel } from 'qs-data-manager/Products';
import GLobalInventory from './GlobalInventory';
import {
  getCompanyUnreadInquiryCountFromCache,
  attachCompanyUnreadInquiryListener,
  removeCompanyUnreadInquiryListener,
  getCompanyUnreadInquiriesForStatus
} from 'qs-data-manager/Orders/OrdersOperations';
import CacheListenerCallback from 'qs-helpers/CacheListenerCallback';
import HelpAndSupport from '../../AccountSettings/HelpAndSupport';
import { selectedLabels } from 'qs-data-manager/Selected';
import { resetForLabelScreen } from 'qs-helpers/CatalogueLabels';
import SettingsIframeComponent from '../../AccountSettings/CompanySettings/SettingsIframeComponent';
import Mixpanel from 'qs-data-manager/Mixpanel';
import {
  attachDomainDataListner,
  removeDomainDataListener
} from 'qs-data-manager/Domain/DomainCheck';
import { getConnectedDomainData } from 'qs-helpers/Domain/ResponseProcessor';
import { getI18N } from 'qs-services/i18N';
import Toast from '../Toast';
import { openPopup } from 'qs-utils';

const { screens, animationDuration } = NAVIGATION;

const StyledMaterialUiDrawer = withStyles(theme => ({
  paper: {
    width: '75%',
    backgroundColor: '#242c36',
    [theme.breakpoints.up('sm')]: {
      width: '50%'
    },
    [theme.breakpoints.up('lg')]: {
      width: '33%'
    }
  }
}))(MaterialUiDrawer);

class Drawer extends Component {
  constructor(props) {
    super(props);
    const currentActiveScreen = this.getCurrentActiveScreen();
    this.state = {
      currentActiveScreen,
      unreadInquries: getCompanyUnreadInquiryCountFromCache(),
      showHelp: false,
      helpCenterDrawerOpen: false,
      connectedDomain: '',
      shouldHideSideNavbar: false,
      toastOpen: false,
      toastMessage: ''
    };
    this.helpSupportHandler = this.helpSupportHandler.bind(this);
    this.toggleHelpCenterDrawer = this.toggleHelpCenterDrawer.bind(this);
    this.setGlobalInventorySelected = this.setGlobalInventorySelected.bind(this);
    this.unSetGlobalInventorySelected = this.unSetGlobalInventorySelected.bind(this);
    this.onCatalogueNavClick = this.onCatalogueNavClick.bind(this);
    this.messageListener = this.messageListener.bind(this);

    this.pickerRef = null;
    this.unreadCountFetchProcessed = false;
  }

  componentDidMount() {
    attachCompanyUnreadInquiryListener({ listener: this.unreadInquirylistener });
    attachDomainDataListner({ listener: this.domainDataListener });
    this.processReadyFlag();

    const elements = document.getElementsByClassName('iconContainer');

    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];
      const isInventoryIcon = element.id === 'inventoryIcon';

      element.addEventListener('mouseenter', () => {
        this.onDrawerMouseEnter(document, isInventoryIcon);
      });

      const parentNode = element.parentElement.parentElement;

      parentNode.addEventListener('mouseleave', () => {
        this.onDrawerLeave(document, isInventoryIcon);
      });
    }

    this.removeEventbus = eventbus.on(SIDEBAR_VISIBILITY_CHANGED_EB_KEY, this.toggleSideNavbar);
    window.addEventListener('message', this.messageListener);
  }

  componentDidUpdate(prevProps) {
    this.processReadyFlag();
    const currentActiveScreen = this.getCurrentActiveScreen();
    if (
      this.state.currentActiveScreen !== currentActiveScreen ||
      prevProps.location.pathname !== this.props.location.pathname
    ) {
      this.setState({ currentActiveScreen });
    }
  }

  componentWillUnmount() {
    removeCompanyUnreadInquiryListener({ listener: this.unreadInquirylistener });
    removeDomainDataListener({ listener: this.domainDataListener });
    this.unreadCountFetchProcessed = false;
    if (this.removeEventbus) {
      this.removeEventbus();
    }
    window.removeEventListener('message', this.messageListener);
  }

  getCurrentActiveScreen = () => {
    const url = window.location.href;
    let currentActiveScreen = screens.catalogueScreen;
    if (url.includes(ROUTE_MAP.CATALOGUES)) {
      currentActiveScreen = screens.catalogueScreen;
    } else if (url.includes(ROUTE_MAP.INQUIRIES)) {
      currentActiveScreen = screens.inquiriesScreen;
    } else if (
      url.includes(
        `${ROUTE_MAP.ACCOUNT_SETTINGS}/${
          SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.DOMAIN_SETTINGS].ROUTE
        }`
      )
    ) {
      currentActiveScreen = screens.domainScreen;
    } else if (
      url.includes(
        `${ROUTE_MAP.ACCOUNT_SETTINGS}/${
          SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.COMPANY_NAME].ROUTE
        }`
      )
    ) {
      currentActiveScreen = screens.profileScreen;
    } else if (
      url.includes(
        `${ROUTE_MAP.ACCOUNT_SETTINGS}/${
          SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CUSTOM_PRODUCT_GRID_TILE].ROUTE
        }`
      ) ||
      url.includes(
        `${ROUTE_MAP.ACCOUNT_SETTINGS}/${
          SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CUSTOM_PRODUCT_LIST_TILE].ROUTE
        }`
      ) ||
      url.includes(
        `${ROUTE_MAP.ACCOUNT_SETTINGS}/${
          SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CUSTOM_PRODUCT_MAGAZINE_TILE].ROUTE
        }`
      ) ||
      url.includes(
        `${ROUTE_MAP.ACCOUNT_SETTINGS}/${
          SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CUSTOM_CATALOGUE_GRID_TILE].ROUTE
        }`
      )
    ) {
      currentActiveScreen = screens.customHTMLScreen;
    } else if (
      url.includes(
        `${ROUTE_MAP.ACCOUNT_SETTINGS}/${
          SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CUSTOM_PAGES_SETTINGS].ROUTE
        }`
      )
    ) {
      currentActiveScreen = screens.customPagesScreen;
    } else if (
      url.includes(
        `${ROUTE_MAP.ACCOUNT_SETTINGS}/${
          SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CUSTOM_FOOTER_SETTINGS].ROUTE
        }`
      )
    ) {
      currentActiveScreen = screens.customFooterScreen;
    } else if (
      url.includes(
        `${ROUTE_MAP.ACCOUNT_SETTINGS}/${
          SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.OTHER_PAGES_SETTINGS].ROUTE
        }`
      )
    ) {
      currentActiveScreen = screens.otherPagesScreen;
    } else if (
      url.includes(
        `${ROUTE_MAP.ACCOUNT_SETTINGS}/${
          SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CREATE_MENU_SETTINGS].ROUTE
        }`
      )
    ) {
      currentActiveScreen = screens.createMenuScreen;
    } else if (url.includes(ROUTE_MAP.ACCOUNT_SETTINGS)) {
      currentActiveScreen = screens.accountSettingsScreen;
    }
    return currentActiveScreen;
  };

  messageListener = ({ data }) => {
    try {
      data = JSON.parse(data);
      if (data[HELP_CENTER_DRAWER_POST_MESSAGE_ID] === HELP_CENTER_DRAWER_POST_MESSAGE_ID) {
        this.toggleHelpCenterDrawer();
      } else if (data.path && data.screen) {
        if (data.screen === screens.catalogueScreen) {
          this.onCatalogueNavClick();
        } else {
          this.navigateToRoute(data.screen, data.path, data.alternateScreen, data.alternatePath);
        }
      }
    } catch (error) {
      // Cannot parse json, probably received a string from some other iframe. Ignore this message.
    }
  };

  processReadyFlag = () => {
    const { isReady } = this.props;
    const { currentActiveScreen } = this.state;

    if (isReady === true && !this.unreadCountFetchProcessed) {
      // Added to ensure that this ready flag is not processed until logout occurs
      this.unreadCountFetchProcessed = true;
      if (currentActiveScreen !== screens.inquiriesScreen) {
        getCompanyUnreadInquiriesForStatus();
      }
    }
  };

  unreadInquirylistener = (error, payload) => {
    const { data } = CacheListenerCallback(error, payload);
    if (!data) {
      return;
    }
    this.setState({ unreadInquries: getCompanyUnreadInquiryCountFromCache() });
  };

  domainDataListener = (error, payload) => {
    const { data } = CacheListenerCallback(error, payload);
    if (!data) {
      return;
    }
    const connectedDomains = getConnectedDomainData(data);
    this.setState({ connectedDomain: connectedDomains.custom || connectedDomains.catalog || '' });
  };

  openDrawer = (document, globalInventoryItem) => {
    // Return when user hovers over the globalInventory icon, and hide the text.
    if (globalInventoryItem && !this.isGlobalInventoryTextVisible()) {
      this.hideGlobalInventoryText();
      return;
    }

    // return when globalInventorySelected popup is already visible and user hovers over other items
    if (this.state.globalInventorySelected) {
      return;
    }
    this.unSetGlobalInventorySelected();

    // Handle Global Inventory pop-over position on drawer mouse enter
    this.showGlobalInventoryText();
    const parentNode = document.getElementsByClassName('drawerContainer');
    const elements = document.getElementsByClassName('hideOptionNames');

    for (let i = 0; i < parentNode.length; i++) {
      const element = parentNode[i];
      element.style.WebkitTransitionDuration = animationDuration.TRANSITION_DURATION;
      element.style.transitionDuration = animationDuration.TRANSITION_DURATION;
      element.style.width = `${NAVIGATION.openDrawerWidth}px`;
    }

    for (let j = 0; j < elements.length; j++) {
      elements[j].classList.add('fade-in');
    }
  };

  onDrawerMouseEnter = (document, globalInventoryItem) => {
    this.openDrawer(document, globalInventoryItem);
  };

  closeDrawer = (document, globalInventoryItem) => {
    if (this.state.globalInventorySelected) {
      return;
    }

    // Handle Global Inventory pop-over position on drawer mouse leave
    this.hideGlobalInventoryText();
    if (globalInventoryItem) {
      return;
    }

    const parentNode = document.getElementsByClassName('drawerContainer');
    const elements = document.getElementsByClassName('hideOptionNames');

    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.remove('fade-in');
    }

    for (let i = 0; i < parentNode.length; i++) {
      const element = parentNode[i];
      element.style.WebkitTransitionDuration = animationDuration.TRANSITION_DURATION;
      element.style.transitionDuration = animationDuration.TRANSITION_DURATION;
      element.style.removeProperty('width');
    }
  };

  onDrawerLeave = (document, globalInventoryItem) => {
    this.closeDrawer(document, globalInventoryItem);
  };

  navigate = screenName => {
    this.props.navigation.history.push(screenName);
  };

  handleSkuPickerChange = async e => {
    e.stopPropagation();
    e.preventDefault();
    await uploadSkuExcel({ file: e.target.files[0] });
    this.pickerRef.value = null;
  };

  navigateToRoute(screen, path, alternateScreen, alternatePath) {
    if (this.state.currentActiveScreen !== screen) {
      this.setState({ currentActiveScreen: screen });
      navigateToPath(path);
    } else if (
      !!alternateScreen &&
      !!alternatePath &&
      this.state.currentActiveScreen !== alternateScreen
    ) {
      this.setState({ currentActiveScreen: alternateScreen });
      navigateToPath(alternatePath);
    }
  }

  setGlobalInventorySelected() {
    if (!this.state.globalInventorySelected) {
      this.setState({
        globalInventorySelected: true
      });
    }
  }

  unSetGlobalInventorySelected() {
    if (this.state.globalInventorySelected) {
      this.setState(
        {
          globalInventorySelected: false
        },
        () => this.closeDrawer(document)
      );
    }
  }

  hideGlobalInventoryText() {
    this.setInventoryOptionStyle('none', NAVIGATION.closedDrawerWidth);
  }

  showGlobalInventoryText() {
    this.setInventoryOptionStyle('flex', NAVIGATION.openDrawerWidth);
  }

  setInventoryOptionStyle(display, width) {
    document.getElementById('inventoryButton').style.width = `${width}px`;
    const el = document.getElementById('inventoryButtonOption');
    el.style.WebkitTransitionDuration = animationDuration.TRANSITION_DURATION;
    el.style.transitionDuration = animationDuration.TRANSITION_DURATION;
    el.style.display = display;
  }

  isGlobalInventoryTextVisible() {
    return (
      document.getElementById('inventoryButton').style.width === `${NAVIGATION.openDrawerWidth}px`
    );
  }

  helpSupportHandler() {
    this.setState({ showHelp: !this.state.showHelp });
  }

  toggleHelpCenterDrawer() {
    if (this.state.helpCenterDrawerOpen) {
      Mixpanel.sendEvent({ eventName: 'help_center_closed' });
    } else {
      Mixpanel.sendEvent({ eventName: 'help_center_opened' });
    }
    this.setState({ helpCenterDrawerOpen: !this.state.helpCenterDrawerOpen });
  }

  onCatalogueNavClick() {
    this.navigateToRoute(screens.catalogueScreen, `/${ROUTE_MAP.CATALOGUES}`);
    selectedLabels.activate();
    resetForLabelScreen();
  }

  onViewStoreClick = () => {
    if (!this.state.connectedDomain) {
      return this.navigateToRoute(
        screens.accountSettingsScreen,
        `/${ROUTE_MAP.ACCOUNT_SETTINGS}/${
          SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.DOMAIN_SETTINGS].ROUTE
        }`
      );
    }
    openPopup('https://' + this.state.connectedDomain, 'noopener=true');
  };

  toggleSideNavbar = () => {
    this.setState(prevState => ({ shouldHideSideNavbar: !prevState.shouldHideSideNavbar }));
  };

  shouldHideSideNavbar = () => {
    const { currentActiveScreen, shouldHideSideNavbar } = this.state;
    return currentActiveScreen === screens.customHTMLScreen || !!shouldHideSideNavbar;
  };

  render() {
    const { currentActiveScreen, unreadInquries } = this.state;

    let catalogueIconStyles = 'white',
      inquiryIconStyles = 'white',
      accountSettingsStyles = 'white',
      globalInventoryStyles = 'white',
      helpAndSupportSettingsStyles = 'white',
      helpCenterSettingsStyles = 'white';

    if (this.state.globalInventorySelected) {
      globalInventoryStyles = '#8DF0C1';
    } else if (this.state.showHelp) {
      helpAndSupportSettingsStyles = '#8DF0C1';
    } else if (this.state.helpCenterDrawerOpen) {
      helpCenterSettingsStyles = '#8DF0C1';
    } else {
      switch (currentActiveScreen) {
        case screens.catalogueScreen:
          catalogueIconStyles = '#8DF0C1';
          break;
        case screens.inquiriesScreen:
          inquiryIconStyles = '#8DF0C1';
          break;
        case screens.accountSettingsScreen:
          accountSettingsStyles = '#8DF0C1';
          break;
        default:
          break;
      }
    }

    const { t } = getI18N();

    return (
      <Fragment>
        <div
          className="sideNavContainer"
          style={{
            width: this.shouldHideSideNavbar() ? 0 : NAVIGATION.closedDrawerWidth,
            flexBasis: this.shouldHideSideNavbar() ? 0 : NAVIGATION.closedDrawerWidth
          }}
        >
          <div className="drawerContainer">
            <button onClick={this.onViewStoreClick} className="sideNavRowContainer">
              <div className="iconContainer">
                <ViewStoreIcon className="drawerIcon" style={{ fill: '#ffffff' }} />
              </div>
              <div className="optionName hideOptionNames">{t('view_store')}</div>
            </button>

            <button onClick={this.onCatalogueNavClick} className="sideNavRowContainer">
              <div className="iconContainer">
                <CatalogueIcon className="drawerIcon" style={{ fill: catalogueIconStyles }} />
              </div>
              <div className="optionName hideOptionNames">{t('catalogues')}</div>
            </button>

            <button
              onClick={() =>
                this.navigateToRoute(screens.inquiriesScreen, `/${ROUTE_MAP.INQUIRIES}`)
              }
              className="sideNavRowContainer inquiryContainer"
            >
              <div className="iconContainer inquiryIcon">
                <InquiryIcon className="drawerIcon" style={{ fill: inquiryIconStyles }} />
                {!!Number(unreadInquries) && <div className="unreadInquiryBadge" />}
              </div>
              <div className="optionName hideOptionNames">{t('orders')}</div>
            </button>

            <button
              onClick={() =>
                this.navigateToRoute(
                  screens.accountSettingsScreen,
                  `/${ROUTE_MAP.ACCOUNT_SETTINGS}/${
                    SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.COMPANY_NAME].ROUTE
                  }`
                )
              }
              className="sideNavRowContainer"
            >
              <div className="iconContainer">
                <SettingsIcon className="drawerIcon" style={{ fill: accountSettingsStyles }} />
              </div>
              <div className="optionName hideOptionNames">{t('account_settings')}</div>
            </button>

            <button
              onClick={this.setGlobalInventorySelected}
              id={'inventoryButton'}
              className="sideNavRowContainer"
            >
              <div className="iconContainer" id="inventoryIcon">
                <GlobalInventoryIcon
                  className="drawerIcon"
                  style={{ fill: globalInventoryStyles }}
                />
              </div>
              <div className="optionName hideOptionNames" id={'inventoryButtonOption'}>
                {t('sync_global_product_data')}
              </div>
              {this.state.globalInventorySelected && (
                <GLobalInventory hidePopOver={this.unSetGlobalInventorySelected} />
              )}
            </button>

            <button
              onClick={this.toggleHelpCenterDrawer}
              id={'helpAndSupportButton'}
              className="sideNavRowContainer helpCenterSideNavRowContainer"
            >
              <div className="iconContainer">
                <HelpCenterIcon className="drawerIcon" style={{ fill: helpCenterSettingsStyles }} />
              </div>
              <div className="optionName hideOptionNames">{t('help_center')}</div>
            </button>
            <button
              onClick={this.helpSupportHandler}
              id={'helpAndSupportButton'}
              className="sideNavRowContainer"
            >
              <div className="iconContainer">
                <HelpIcon className="drawerIcon" style={{ fill: helpAndSupportSettingsStyles }} />
              </div>
              <div className="optionName hideOptionNames">{t('help_and_support')}</div>
            </button>
          </div>
        </div>

        {this.state.showHelp && (
          <HelpAndSupport title={t('help_and_support')} onClose={this.helpSupportHandler} />
        )}

        <StyledMaterialUiDrawer
          anchor="right"
          open={this.state.helpCenterDrawerOpen}
          onClose={this.toggleHelpCenterDrawer}
        >
          <SettingsIframeComponent
            id={SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.HELP_CENTER].TITLE}
            title={t(SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.HELP_CENTER].TITLE)}
            route={SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.HELP_CENTER].IFRAME_ROUTE}
            removeBeginningHash={
              SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.HELP_CENTER].REMOVE_HASH
            }
            queryParams={[
              {
                key: 'postMessageKey',
                value: HELP_CENTER_DRAWER_POST_MESSAGE_ID
              },
              {
                key: 'postMessageValue',
                value: HELP_CENTER_DRAWER_POST_MESSAGE_ID
              }
            ]}
          />
        </StyledMaterialUiDrawer>
        <Toast
          message={this.state.toastMessage}
          open={this.state.toastOpen}
          onClose={() => {
            this.setState({
              toastOpen: false,
              toastMessage: ''
            });
          }}
        />
      </Fragment>
    );
  }
}

export default withRouter(Drawer);
