import React, { useRef } from 'react';
import { getActiveCatalogueId } from 'qs-data-manager/Catalogues';
import { toggleGlobalLoader } from 'qs-helpers';
import toastr from 'toastr';
import { upsertCatalogueProducts } from 'qs-product-operations/UpsertPictureUpload/UpsertCatalogueProducts';
import { RawFileData } from 'qs-helpers/FileUploads/RawFileData';
import { isFileImage } from 'qs-helpers/FileUploads/ProcessUploadedFiles';
import './styles.scss';
import { getI18N } from 'qs-services/i18N';

export default ({ productId }) => {
  const pickerRef = useRef(null);

  const { t } = getI18N();

  // If this component is called without a productId,
  // then do not render anything because without the productId
  // no operation can be done
  if (!productId) {
    return null;
  }

  const handleFilesChange = async event => {
    event.stopPropagation();
    event.preventDefault();
    const catalogueId = getActiveCatalogueId();
    const file = event.currentTarget.files[0];
    pickerRef.current.value = null;

    if (!isFileImage(file)) {
      toastr.error(t('only_a_picture_can_be_uploaded'));
      return;
    }

    const loaderKey = `imageFilesUploadRetry${Date.now()}`;
    toggleGlobalLoader(loaderKey, true);

    try {
      await upsertCatalogueProducts({
        catalogueId,
        productIds: [productId],
        rawFiles: [new RawFileData({ fileData: file })]
      });
    } catch (uploadError) {
      toastr.error(t('something_went_wrong_while_uploading_pictures_to_catalogue'));
    } finally {
      toggleGlobalLoader(loaderKey, false);
    }
  };

  const handleFilePickerClick = event => {
    event.stopPropagation();
  };

  return (
    <div className="uploadButton">
      UPLOAD
      <input
        onClick={handleFilePickerClick}
        ref={pickerRef}
        type="file"
        name="productphoto"
        accept="image/*"
        onChange={handleFilesChange}
      />
    </div>
  );
};
