import React, { useState, useEffect } from 'react';
import { POST_MESSAGE_SLUGS, windowPostMessageProxy } from 'qs-helpers/WindowPostMessageProxy';
import HtmlPageBuilder from '../../AccountSettings/CompanySettings/HtmlPageBuilder/HtmlPageBuilder';

export const HtmlSectionForCustomPagesIframe = () => {
  const [showCustomHtmlComponent, toggleShowCustomHtmlComponent] = useState(false);
  const [customHtmlSectionComponent, setCustomHtmlSectionComponent] = useState(null);

  useEffect(() => {
    if (!showCustomHtmlComponent) {
      sendIframePostMessage({
        slug: POST_MESSAGE_SLUGS.CustomHtmlComponent,
        value: POST_MESSAGE_SLUGS.Hide
      });
    }
  }, [showCustomHtmlComponent]);

  windowPostMessageProxy.addHandler({
    test: message => {
      return message?.slug === POST_MESSAGE_SLUGS.CustomHtmlComponent;
    },
    handle: message => {
      if (message?.section) {
        setCustomHtmlSectionComponent(message?.section);
      } else {
        setCustomHtmlSectionComponent(null);
      }
      setShowCustomHtmlComponent(message?.value === POST_MESSAGE_SLUGS.Show);
      return;
    }
  });

  const setShowCustomHtmlComponent = showCustomHtmlComponent => {
    toggleShowCustomHtmlComponent(showCustomHtmlComponent);
  };

  const getSelectedCustomHtmlSection = customHtmlSection => {
    sendIframePostMessage({
      slug: POST_MESSAGE_SLUGS.CustomHtmlComponentSelected,
      value: customHtmlSection
    });
    setShowCustomHtmlComponent(false);
  };

  const sendIframePostMessage = postMessageBody => {
    const iframe =
      document.querySelector('iframe#custom_pages') ||
      document.querySelector('iframe#other_pages') ||
      document.querySelector('iframe#catalogue_banners');
    if (iframe && iframe.contentWindow && iframe.contentWindow.postMessage) {
      windowPostMessageProxy.postMessage(iframe.contentWindow, postMessageBody);
    }
  };

  if (!showCustomHtmlComponent) {
    return null;
  }

  return (
    <HtmlPageBuilder
      section={customHtmlSectionComponent || {}}
      onClose={() => setShowCustomHtmlComponent(false)}
      onSelect={section => getSelectedCustomHtmlSection(section)}
    />
  );
};

export default HtmlSectionForCustomPagesIframe;
