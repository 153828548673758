import {
  attachCustomPropertiesListener,
  getCustomProductProperties,
  getCustomProductPropertiesFromCache,
  removeCustomPropertiesListener
} from 'qs-data-manager/CSVUploader/Operations';

export const fetchCustomProductProperties = ({ listener }) => {
  attachCustomPropertiesListener({ listener });

  getCustomProductProperties();
};

export const handleCustomProductPropertiesCleanup = ({ listener }) => {
  removeCustomPropertiesListener({ listener });
};

export const getCachedCustomPropertiesData = () => getCustomProductPropertiesFromCache();
