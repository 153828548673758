import React from 'react';
import { ReactComponent as JewelleryPriceDisplayIcon } from 'qs-assets/Media/ic_jewellery_price_display.svg';
import './styles.scss';

export default ({ width, height, fill, classes = '' }) => {
  return (
    <JewelleryPriceDisplayIcon
      style={{ width, height, fill }}
      className={`settingsIconComponent ${classes}`}
    />
  );
};
