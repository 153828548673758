import {
  getProductWeight,
  getProductList,
  getFilteredProductList,
  getProductListSearchParams,
  sortProductsForCatalogue
} from './api';

export const PRODUCT_WEIGHT = {
  cacheKey: 'PRODUCT_WEIGHT',
  apiFunction: getProductWeight
};

export const PRODUCT_LIST_META = {
  cacheKey: 'PRODUCT_LIST_META',
  apiFunction: getProductList,
  filterApiFunction: getFilteredProductList,
  nativeStorageKey: 'PRODUCT_LIST_META'
};

export const PRODUCT_LIST_SEARCH_PARAMS = {
  cacheKey: 'PRODUCT_LIST_SEARCH_PARAMS',
  apiFunction: getProductListSearchParams
};

export const PRODUCT_SORT_DATA = {
  apiFunction: sortProductsForCatalogue
};
