import {
  PRODUCT_LIST_META,
  PRODUCT_LIST_SEARCH_PARAMS,
  PRODUCT_SORT_DATA
} from 'qs-api/Products/ApiCacheConnector';
import CacheRequest from '../CacheRequest';
import { reorderProductInCache } from 'qs-data-manager/Products';

const createCatalogueProductCacheKey = ({ catalogueId, filters }) =>
  `${PRODUCT_LIST_META.cacheKey}${catalogueId}${filters ? `-FILTER` : ''}`;

export const attachProductListListener = ({ listener, catalogueId, filters }) => {
  CacheRequest.attachListener(createCatalogueProductCacheKey({ catalogueId, filters }), listener);
};

export const removeProductListListener = ({ listener, catalogueId, filters }) => {
  CacheRequest.removeListener(createCatalogueProductCacheKey({ catalogueId, filters }), listener);
};

export const getProductList = ({
  catalogueId,
  selectedTags,
  searchTerm,
  priceFilters,
  variantSelection,
  customFieldsSelection,
  filters
}) => {
  let apiCall = PRODUCT_LIST_META.apiFunction;
  let shouldNotStoreInNative = false;
  if (filters) {
    apiCall = PRODUCT_LIST_META.filterApiFunction;
    shouldNotStoreInNative = true;
  }

  CacheRequest.makeRequest(createCatalogueProductCacheKey({ catalogueId, filters }), apiCall, {
    params: [
      catalogueId,
      searchTerm,
      priceFilters,
      selectedTags,
      variantSelection,
      customFieldsSelection
    ],
    options: {
      extraData: {
        catalogueId
      },
      shouldNotStoreInNative,
      nativeStorageKey: PRODUCT_LIST_META.nativeStorageKey,
      ignorePreviousRequest: true
    }
  });
};

export const getProductListDataFromCache = ({ catalogueId, filters = false }) =>
  CacheRequest.getCacheForKey(createCatalogueProductCacheKey({ catalogueId, filters })) ||
  undefined;

export const getProductListFromCache = ({ catalogueId, filters = false }) => {
  const meta = CacheRequest.getCacheForKey(
    createCatalogueProductCacheKey({ catalogueId, filters })
  );
  if (!meta || !meta.productsList) {
    return null;
  }

  return meta.productsList;
};

export const setProductListInCache = ({ productsList, catalogueId, filters = false }) => {
  if (!Array.isArray(productsList)) {
    return;
  }

  const existingProductListData = getProductListDataFromCache({ catalogueId, filters }) || {};
  CacheRequest.setCacheForKey(createCatalogueProductCacheKey({ catalogueId, filters }), {
    ...existingProductListData,
    productsList
  });
};

export const deleteFilteredCacheData = ({ catalogueId }) => {
  CacheRequest.deleteCacheForKeys([createCatalogueProductCacheKey({ catalogueId, filters: true })]);
};

const createCatalogueProductSearchParamsCacheKey = ({ catalogueId }) =>
  `${PRODUCT_LIST_SEARCH_PARAMS.cacheKey}${catalogueId}`;

export const attachCatalogueProductSearchParamsListener = ({ listener, catalogueId }) => {
  CacheRequest.attachListener(
    createCatalogueProductSearchParamsCacheKey({ catalogueId }),
    listener
  );
};

export const removeCatalogueProductSearchParamsListener = ({ listener, catalogueId, filters }) => {
  CacheRequest.removeListener(
    createCatalogueProductSearchParamsCacheKey({ catalogueId, filters }),
    listener
  );
};

export const getCatalogueProductSearchParams = ({ catalogueId }) => {
  CacheRequest.makeRequest(
    createCatalogueProductSearchParamsCacheKey({ catalogueId }),
    PRODUCT_LIST_SEARCH_PARAMS.apiFunction,
    {
      params: [catalogueId],
      options: {
        shouldNotStoreInNative: true
      }
    }
  );
};

export const getCatalogueProductSearchParamsFromCache = catalogueId =>
  CacheRequest.getCacheForKey(createCatalogueProductSearchParamsCacheKey({ catalogueId })) ||
  undefined;

export const setCatalogueProductSearchParamsInCache = ({ updates, catalogueId }) => {
  CacheRequest.setCacheForKey(createCatalogueProductSearchParamsCacheKey({ catalogueId }), {
    ...getCatalogueProductSearchParamsFromCache(catalogueId),
    ...updates
  });
};

export const getSortedProductList = async data => {
  try {
    const result = await PRODUCT_SORT_DATA.apiFunction(data);
    const { sortedProductIds } = result;
    const updtedProductIds = [];
    const filteredIds = {};
    const updatedFilteredIds = [];
    let getFilteredList = getProductListFromCache({
      catalogueId: data.catalogueId,
      filters: true
    });

    if (getFilteredList && getFilteredList.length > 0) {
      getFilteredList.forEach(element => (filteredIds[element.productId] = true));
    }

    sortedProductIds.forEach(element => {
      const data = {
        productId: element
      };
      updtedProductIds.push(data);
      if (filteredIds && filteredIds[element]) {
        updatedFilteredIds.push(data);
      }
    });

    if (updatedFilteredIds.length > 0) {
      setProductListInCache({
        productsList: updatedFilteredIds,
        catalogueId: data.catalogueId,
        filters: true
      });
    }
    reorderProductInCache({
      newProductList: updtedProductIds,
      catalogueId: data.catalogueId,
      oldIndex: 0,
      newIndex: 0
    });
  } catch (error) {
    throw error;
  }
};
