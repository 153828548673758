import React, { Fragment } from 'react';
import { MIN_ORDER_QTY } from './helper';
import { getPaidFeatures } from '../../../../../Common/FeatureModal/helper';
import Row from './Row';
import { getI18N } from 'qs-services/i18N';
import './styles.css';

const getExtraRowData = (id, state) => {
  const {
    stockManagedFrom,
    // oosControlledFrom,
    // catalogueStockVisibility,
    // companySettings,
    orderOnOOS
  } = state;
  switch (id) {
    case 'pdfDownload':
      return {
        managedBy: state.pdfDownload ? state.pdfDownload.managedBy : null
      };
    case 'productDownload':
      return {
        managedBy: state.productDownload ? state.productDownload.managedBy : null
      };
    case 'shippingAddress':
      return {
        managedBy: state.shippingAddress ? state.shippingAddress.managedBy : null
      };
    case 'orderConfirmationCustomMessage':
      return {
        managedBy: state.orderConfirmationCustomMessage
          ? state.orderConfirmationCustomMessage.managedBy
          : null
      };
    case 'stockVisibility':
      return {
        managedBy: stockManagedFrom
      };
    case 'orderOnOutOfStock':
      return {
        managedBy: orderOnOOS
      };
    case MIN_ORDER_QTY:
      return {
        managedBy: state.minOrderQuantity ? state.minOrderQuantity.managedBy : null
      };
    default:
      return {};
  }
};

const getCategoryLink = (label, state) => {
  const { catalogueLink } = state;
  switch (label) {
    case 'Catalogue Link':
      return catalogueLink;
    default:
      return null;
  }
};

export default function SettingsOptions(props) {
  const {
    Settings,
    state,
    onSettingChanged,
    showResetModal,
    renderResetModal,
    resetToCompanyDefault,
    toggleCustomComponent,
    toggleCataloguePrivacySettings
  } = props;
  const { catalogueLink, showCustomOptionModal } = state;
  const { t } = getI18N();
  if (!catalogueLink) return null;
  return (
    <div className={'CatalogueSettingLinkWrapper'}>
      {Settings.map((category, key) => {
        const { label, settings: options } = category;
        const link = getCategoryLink(label, state);
        if (label === 'privacy_settings') {
          const planFeature = getPaidFeatures().PRIVACY_MODULE;
          const {
            isAllowed: { allowed }
          } = planFeature;
          if (!allowed) {
            return null;
          }
        }
        return (
          <Fragment key={key}>
            {label && (
              <div className="CatalogueSettingLinkContainer">
                <label className={`${link ? 'CatalogueLinkLabel' : 'CategoryLabel'}`}>
                  {t(label)}
                </label>
                {link && <div className="CatalogueLink">{link}</div>}
              </div>
            )}
            {options.map(setting => {
              let value =
                state[setting.id] && typeof state[setting.id] === 'object'
                  ? state[setting.id].finalValue
                  : state[setting.id];
              if (!state.stockVisibility && setting.id === 'orderOnOutOfStock') {
                return null;
              }
              if (setting.id === 'cataloguePrivacySettings') {
                value = 'DEFAULT';
              }
              return (
                <Row
                  key={setting.id}
                  value={value}
                  shouldShowLoader={typeof value === 'undefined'}
                  {...setting}
                  onSettingChanged={onSettingChanged}
                  {...getExtraRowData(setting.id, state)}
                  resetToCompanyDefault={resetToCompanyDefault}
                  showCustomOptionModal={showCustomOptionModal}
                  toggleCustomComponent={toggleCustomComponent}
                  toggleCataloguePrivacySettings={toggleCataloguePrivacySettings}
                />
              );
            })}
          </Fragment>
        );
      })}
      {showResetModal && renderResetModal()}
    </div>
  );
}
