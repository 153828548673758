import React, { useState, useEffect, useCallback } from 'react';
import { getActiveCatalogueId } from 'qs-data-manager/Catalogues';
import { deleteProducts, resetActiveProductId } from 'qs-data-manager/Products';
import { ReactComponent as TrashIcon } from 'qs-assets/Media/trash.svg';
import DialogBox from 'qs-components/Common/DialogBox';
import toastr from 'toastr';
import './styles.scss';
import { getI18N } from 'qs-services/i18N';

export default ({ activeProductId }) => {
  const [showDeleteModal, toggleDeleteModal] = useState(false);

  const { t } = getI18N();

  useEffect(() => {
    //Reset state on change of active product id
    toggleDeleteModal(false);
  }, [activeProductId]);

  const deleteCurrentProduct = () => {
    toggleDeleteModal(false);
    resetActiveProductId();
    const catalogueId = getActiveCatalogueId();
    deleteProducts([activeProductId], catalogueId, {
      showLoader: true,
      makeRemoteChanges: true
    }).then(() => {
      toastr.success(t('product_deleted_successfully'));
    });
  };

  const onTrashClick = () => {
    toggleDeleteModal(!showDeleteModal);
  };

  const closeModal = useCallback(() => {
    toggleDeleteModal(false);
  }, [toggleDeleteModal]);

  return (
    <>
      {<TrashIcon onClick={onTrashClick} className={'productDeleteAction trashIcon'} />}
      {showDeleteModal && (
        <DialogBox
          open={true}
          center={true}
          title={t('delete_product')}
          onClose={closeModal}
          closeOnOverlayClick={false}
          closeOnEsc={false}
          showCloseIcon={false}
          id="product-delete-confirm-dialog-box"
          maxWidth="sm"
          actionsConfig={[
            {
              content: t('cancel'),
              clickHandler: () => toggleDeleteModal(false),
              role: 'SECONDARY'
            },
            {
              content: t('confirm'),
              clickHandler: deleteCurrentProduct,
              role: 'PRIMARY'
            }
          ]}
        >
          <div className="productImageDelete confirmationDescription">
            {t('are_you_sure_you_want_to_delete_this_product')}
          </div>
        </DialogBox>
      )}
    </>
  );
};
