import React from 'react';

import './styles.scss';

export default () => {
  return (
    <div id={'ShimmerContainer'}>
      <div className={'circle circle4 animate'}></div>
      <div className={'circle circle3 animate'}></div>
      <div className={'circle circle2 animate'}></div>
      <div className={'circle circle1 animate'}></div>

      <div className={'react titleRect animate'}></div>
      <div className={' react productCountRect animate'}></div>
    </div>
  );
};
