import React from 'react';
import './styles.scss';

export default ({ result }) => {
  if (!result) {
    return null;
  }

  return <div id={'CatalogueSearchResult'}>{result}</div>;
};
