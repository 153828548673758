import { roundNumberToGivenDecimals } from '..';

export const getDiscountPercentage = (modifiedDiscount, modifiedPrice) => {
  let discountInPercentage = 0,
    discountVal = Number(modifiedDiscount),
    priceVal = Number(modifiedPrice);

  if (Number.isNaN(discountVal)) {
    discountVal = 0;
  }

  if (!Number.isNaN(priceVal) && priceVal > 0) {
    discountInPercentage = (discountVal / priceVal) * 100;
  }

  discountInPercentage = 100 - discountInPercentage;
  discountInPercentage = Math.round(discountInPercentage * 100) / 100;
  return discountInPercentage;
};

export const getPercentChangedDiscount = (discount, newPrice, oldPrice) => {
  let priceVal = Number(newPrice);
  if (discount === '' || Number.isNaN(priceVal) || priceVal <= 0) {
    return 0;
  }

  const discountPercent = getDiscountPercentage(discount, oldPrice);
  return roundNumberToGivenDecimals((priceVal * (100 - discountPercent)) / 100, 2);
};

export const isProductPriceValid = price => {
  if (price === '' || price === null || price === undefined) {
    return false;
  }

  return true;
};
