import React, { useEffect, useState } from 'react';
import './index.scss';
import { Button } from '@material-ui/core';
import { getI18N } from 'qs-services/i18N';

export const JewelleryRateCharge = ({
  initialDisabled = false,
  children,
  showSaveButton,
  showChangeButton
}) => {
  const [editMode, setEditMode] = useState(false);

  const onSaveHandler = () => {
    setEditMode(!initialDisabled);
  };

  useEffect(() => {
    setEditMode(!initialDisabled);
  }, [initialDisabled, setEditMode]);

  if (!initialDisabled || initialDisabled) {
    return children;
  }
  const { t } = getI18N();

  return (
    <div className={`value-input-container${!editMode ? ' disabled' : ''}`}>
      <div className={'value-input'}>{children}</div>
      <div className={'value-control'}>
        {editMode && showSaveButton && (
          <Button type={'button'} className={'saveButton'} onClick={onSaveHandler}>
            {t('save')}
          </Button>
        )}
        {showChangeButton && (
          <button
            type={'button'}
            className={'changeButton'}
            style={editMode ? { visibility: 'hidden' } : {}}
            onClick={() => setEditMode(true)}
          >
            {t('change')}
          </button>
        )}
      </div>
    </div>
  );
};
