import request from '../../Request';
import AppConfig from 'qs-config/AppConfig.js';

export const updateProductViaSKU = (updates, requestType) =>
  request.authPost(`${AppConfig.qsApiEndPoint}/v3/product/update-via-sku`, {
    updates,
    ...(requestType && { requestType: requestType })
  });
export const getUpdateProductViaSKUStatus = requestId =>
  request.authGet(`${AppConfig.qsApiEndPoint}/v3/product/update-via-sku-status/${requestId}`);

export const getSkuList = () =>
  request.authGet(`${AppConfig.qsApiEndPoint}/v2/product/sku-template`);

export const getStoneDetailsbySku = () =>
  request.authGet(`${AppConfig.qsApiEndPoint}/v1/product/sku-diamond-template`);
