import React, { Component } from 'react';
import './RenderForMobile.css';

import laptop from 'qs-assets/Media/qswebMobile.png';

class RenderForMobile extends Component {
  render() {
    return (
      <div className="renderMobileMainContainer">
        <div className="title">QuickSell Web</div>
        <div className="description">
          Upload your products using excel import and easy drag and drop
        </div>
        <img className="logo" src={laptop} alt="laptop" />
        <div className="linkToWebsite">web.quicksell.co</div>
        <div className="instructions">Open on a web browser on your computer</div>
      </div>
    );
  }
}

export default RenderForMobile;
