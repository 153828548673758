import React, { useState, useEffect, useCallback } from 'react';
import './styles.scss';
import { ReactComponent as ArrowLeft } from 'qs-assets/Media/arrow-left.svg';
import Api from 'qs-services/Api';
import Loader from '../../../../../../Common/Loader';
import Button from '@material-ui/core/Button';
import {
  ACCESS_LEVEL_META,
  ENTITY_TYPE,
  getCataloguePrivacySettingsCacheKey,
  MANAGE_PRIVACY
} from './CataloguePrivacyConstants';
import CacheRequest from 'qs-data-manager/CacheRequest';
import { getI18N } from 'qs-services/i18N';
import { getPaidFeatures } from '../../../../../../Common/FeatureModal/helper';
import FeatureModal from '../../../../../../Common/FeatureModal';

const CataloguePrivacySettings = props => {
  const { t } = getI18N();

  const { toggleCataloguePrivacySettings, catalogueId, closeCataloguePrivacyDialog } = props;
  const [loading, setLoading] = useState(false);
  const [accessSettings, setAccessSettings] = useState(
    CacheRequest.getCacheForKey(getCataloguePrivacySettingsCacheKey(catalogueId)) || {}
  );

  const getAccessSettings = useCallback(async () => {
    if (!CacheRequest.getCacheForKey(getCataloguePrivacySettingsCacheKey(catalogueId))) {
      setLoading(true);
    }
    try {
      const data = await Api.getCataloguePrivacyAccessSettings(ENTITY_TYPE.CATALOGUE, catalogueId);
      setLoading(false);
      setAccessSettings(data);
      CacheRequest.setCacheForKey(getCataloguePrivacySettingsCacheKey(catalogueId), data);
    } catch {
      setLoading(false);
    }
  }, [catalogueId]);

  useEffect(() => {
    getAccessSettings();
  }, [getAccessSettings]);

  const onChangeAccesslevel = async accessType => {
    try {
      const data = await Api.setCataloguePrivacyAccessSettings({
        accessLevel: accessType,
        entityId: catalogueId,
        entityType: ENTITY_TYPE.CATALOGUE
      });
      setAccessSettings(data);
      CacheRequest.setCacheForKey(getCataloguePrivacySettingsCacheKey(catalogueId), data);
    } catch {}
  };

  const renderText = () => {
    if (accessSettings.memberCount) {
      return `${accessSettings.memberCount} ${t(
        MANAGE_PRIVACY[accessSettings.accessLevel].description
      )}`;
    }
    return t(MANAGE_PRIVACY[accessSettings.accessLevel].noContactDescription);
  };

  const planFeature = getPaidFeatures().PRIVACY_MODULE;
  const {
    isAllowed: { allowed }
  } = planFeature;
  if (!allowed) {
    return <FeatureModal currentPlanFeature={planFeature} onClose={closeCataloguePrivacyDialog} />;
  }

  return (
    <div className="cataloguePrivacySettings">
      <div className="header">
        <div className="backIcon" onClick={toggleCataloguePrivacySettings}>
          <ArrowLeft />
        </div>
        <div className="title">{t('privacy_setting')}</div>
      </div>
      {loading ? (
        <div className="loader">
          <Loader size="small" />
        </div>
      ) : (
        <>
          <div className="accessLevelContainer">
            <div className="accessLevelTitle">{t('access_level')}</div>
            <div className="accessLevels">
              {Object.keys(ACCESS_LEVEL_META).map((accessType, index) => {
                return (
                  accessSettings &&
                  Object.keys(accessSettings).length > 0 &&
                  accessSettings.accessLevel && (
                    <div
                      className={`accessLevel ${
                        accessType === accessSettings.accessLevel ? `selectedAccessLevelType` : ''
                      }`}
                      onClick={() => onChangeAccesslevel(accessType)}
                      key={index}
                    >
                      {t(ACCESS_LEVEL_META[accessType].displayName)}
                    </div>
                  )
                );
              })}
            </div>
            {accessSettings &&
              Object.keys(accessSettings).length > 0 &&
              accessSettings.accessLevel && (
                <div className="accessLevelDescription">
                  {accessSettings.accessLevel &&
                    t(ACCESS_LEVEL_META[accessSettings.accessLevel].description)}
                </div>
              )}
          </div>
          {accessSettings &&
            Object.keys(accessSettings).length > 0 &&
            accessSettings.accessLevel &&
            accessSettings.accessLevel !== 'DEFAULT' && (
              <div
                className="managePrivacyContainer"
                onClick={() => {
                  closeCataloguePrivacyDialog();
                }}
              >
                <div className="managePrivacyDetails">
                  <div className="managePrivacyDetailsTitle">
                    {t(MANAGE_PRIVACY[accessSettings.accessLevel].title)}
                  </div>
                  <div
                    className="managePrivacyDetailsText"
                    style={{
                      color: accessSettings.memberCount
                        ? MANAGE_PRIVACY[accessSettings.accessLevel].color
                        : MANAGE_PRIVACY[accessSettings.accessLevel].noContactColor
                    }}
                  >
                    {renderText()}
                  </div>
                  <div className="editText">{t('tap_to_edit_access')}</div>
                </div>
                <div className="buttonContainer">
                  <Button className="managePrivacyButton">
                    <span>{t('manage_privacy')}</span>
                  </Button>
                </div>
              </div>
            )}
        </>
      )}
    </div>
  );
};

export default CataloguePrivacySettings;
