import { connector } from 'qs-data-manager/ApiAndCacheConnector';
import CacheRequest from 'qs-data-manager/CacheRequest';
import {
  changeProductsListInNative,
  updateExistingProductsInNative
} from 'qs-data-manager/Dexie/ProductDexieHelpers';
import { reportError } from 'qs-helpers/ErrorReporting';

export const updateProductListInCache = ({ catalogueId, productList }) => {
  const productListCacheKey = `${connector.PRODUCT_LIST_META.cacheKey}${catalogueId}`;
  CacheRequest.setCacheForKey(productListCacheKey, { productsList: productList });
};

export const updateProductsListInNative = async ({ catalogueId, productList }) => {
  try {
    await changeProductsListInNative({ catalogueId, productList });
  } catch (error) {
    reportError(error);
  }
};

export const updateProductMetaInNative = async updateProductData => {
  try {
    await updateExistingProductsInNative(updateProductData);
  } catch (error) {
    reportError(error);
  }
};
