import React, { useState, useEffect, useCallback, Fragment } from 'react';
import AddDomain from './AddDomain';
import { getDomainData } from 'qs-api/Domain/api';
import './style.scss';
import toastr from 'toastr';
import Loader from '../../../Common/Loader';
import SetUp from './SetUpDomain';
import { setDomainDataInCache } from 'qs-data-manager/Domain/DomainCheck';
import { getI18N } from 'qs-services/i18N';

export default function DomainSettings() {
  const [domains, setDomains] = useState([]);
  const [loading, setLoading] = useState(false);
  const [customPresent, setCustomPresent] = useState(true);
  const [catalogPresent, setCatalogPresent] = useState(true);

  const { t } = getI18N();

  const getList = () => {
    setLoading(true);
    getDomainData()
      .then(domains => {
        setDomains(domains.data);
        setDomainDataInCache(domains.data);
        setLoading(false);
      })
      .catch(() => {
        toastr.error(t('something_went_wrong_while_fetching_the_domain'));
        setLoading(false);
      });
  };

  const isEmpty = useCallback(() => {
    const catalogIsPresent = domains.some(domain => {
      if (domain.type === 'catalog') {
        return true;
      }
      return false;
    });
    const customIsPresent = domains.some(domain => {
      if (domain.type === 'custom') {
        return true;
      }
      return false;
    });
    if (catalogIsPresent) {
      setCatalogPresent(true);
    } else {
      setCatalogPresent(false);
    }
    if (customIsPresent) {
      setCustomPresent(true);
    } else {
      setCustomPresent(false);
    }
  }, [domains]);

  const getDomainList = useCallback(() => {
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    isEmpty();
  }, [isEmpty]);

  useEffect(() => {
    getDomainList();
  }, [getDomainList]);

  return (
    <div className="domain-page-container">
      <div className="domainNavBar">{t('manage_your_domain')}</div>
      {loading ? (
        <div style={{ position: 'fixed', top: '50%', right: '23%' }}>
          <Loader size="large" />
        </div>
      ) : (
        <Fragment>
          {domains.map((domain, key) => {
            return (
              <AddDomain
                key={key}
                type={domain.type}
                domain={domain.domainName}
                status={domain.domainStatus}
                update={() => {
                  getList();
                }}
              />
            );
          })}
          {!loading && !catalogPresent && <SetUp type="catalog" update={getList} />}
          {!loading && !customPresent && <SetUp type="custom" update={getList} />}
        </Fragment>
      )}
    </div>
  );
}
