import { connector } from 'qs-data-manager/ApiAndCacheConnector';
import CacheRequest from 'qs-data-manager/CacheRequest';

export const PRODUCT_LIB_CATALOGUE_SEARCH_DATA = {
  currentSearchTerm: undefined,
  currentPage: 0,
  paginationInProg: false,
  paginationCompleted: false,
  requestNumber: 0
};

export const processCataloguePageIncrement = () => {
  PRODUCT_LIB_CATALOGUE_SEARCH_DATA.currentPage += 1;
};

export const isCatalogueProductLibPaginatedCallInProg = () =>
  PRODUCT_LIB_CATALOGUE_SEARCH_DATA.paginationInProg;

export const hasCatalogueProductLibPaginationCompleted = () =>
  PRODUCT_LIB_CATALOGUE_SEARCH_DATA.paginationCompleted;

export const upsertChangesDataToCatalogueRowMetaCache = catalogueRowData => {
  const { catalogueId } = catalogueRowData || {};
  if (!catalogueId) {
    return;
  }

  const cacheKey = `${connector.CATALOGUE_ROW_META.cacheKey}${catalogueId}`;
  const cachedCatalogueData = { ...(CacheRequest.getCacheForKey(cacheKey) || {}) };
  cachedCatalogueData.catalogueId = catalogueRowData.catalogueId;
  cachedCatalogueData.title = catalogueRowData.title;
  cachedCatalogueData.productCount = catalogueRowData.productCount;
  cachedCatalogueData.erroredProductCount = catalogueRowData.erroredProductCount;

  cachedCatalogueData.picturesMeta = catalogueRowData.pictureIds.map((pictureState, index) => {
    const pictureUrl = catalogueRowData.pictureUrls[index];
    return {
      ...pictureState,
      url: pictureUrl
    };
  });

  if (!Array.isArray(cachedCatalogueData.labelIds)) {
    cachedCatalogueData.labelIds = [];
  }

  CacheRequest.setCacheForKey(cacheKey, cachedCatalogueData);
};
