export const PAGE_SIZE = 50;

export const PRODUCT_LIBRARY_GRID = {
  type: 'PRODUCT_LIBRARY_GRID',
  pageSize: PAGE_SIZE,
  unloadedRowCount: 1,

  //One block represents the page count, hence cache only a max of 250 products
  maxCachedBlocks: Math.ceil(250 / PAGE_SIZE),
  rowsBufferedForDisplay: 10,

  /*
    Reflects the height defined in the scss file of the component that creates
    the grid. Must be defined directly on the grid as well as in the scss to
    ensure that the height is not overiden by the grid
  */
  rowHeight: 79,
  headerHeight: 62
};

export const PRODUCT_LIB_CATALOGUE_SEARCH_DATA = {
  currentSearchTerm: undefined,
  currentPage: 0,
  paginationInProg: false,
  paginationCompleted: false,
  requestNumber: 0
};

export const PRODUCT_LIB_PRODUCT_SEARCH_DATA = {
  noCatalogue: 'NO_CATALOGUE',
  currentSearchTerm: undefined,
  currentPage: 0,
  paginationInProg: false,
  paginationCompleted: false,
  requestNumber: 0,
  nextPosition: undefined
};
