import React from 'react';
import Loader from 'qs-components/Common/Loader';
import './styles.css';

export default function LinkSettings({ generatingLink, createLink, catalogueLink }) {
  if (catalogueLink) return null;
  return (
    <div className="CatalogueSettingsCreateLink">
      {generatingLink ? (
        <div>
          <div className="genratingLinkLoaderStyle">
            <Loader />
          </div>
          <p>Creating new link...</p>
        </div>
      ) : (
        <div>
          <button className="CatalogueCreateLink" onClick={createLink}>
            Create catalogue link
          </button>
          <p>Create a link to share this catalogue</p>
        </div>
      )}
    </div>
  );
}
