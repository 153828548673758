import {
  ALL_COMPANY_PRODUCTS,
  ALL_CATALOGUE_PRODUCTS /* ,
  PRODUCT_SEARCH_DATA */
} from 'qs-api/ProductLibrary/ApiCacheAndConnector';
import CacheRequest from '../CacheRequest';
import {
  getLastFetchedProductLibKey,
  getLastFetchedProductLibTimeStamp,
  clearCompanyProductsLastFetchedResponse,
  isPaginatedCallInProg,
  setPaginatedCallInProg /* ,
  processProductPageIncrement */
} from 'qs-helpers/ProductLibrary/ResponseProcessor';
import { duplicateProductsToCatalogue } from 'qs-api/ProductLibrary/api';
import { reportError } from 'qs-helpers/ErrorReporting';
import Api from '../../Api';
import { connector } from '../ApiAndCacheConnector';
/* import { paginatedSearchProductLibrary } from 'qs-helpers/ProductLibrary/PaginatedRequest';
import {
  getCacheKeyForProductSearch,
  mergeProductSearchDataWithCache,
  notifyProductErrorListener,
  notifyProductSearchListeners
} from 'qs-helpers/ProductLibrary/CacheHelpers';
import { PRODUCT_LIB_PRODUCT_SEARCH_DATA } from 'qs-helpers/ProductLibrary/constants'; */

export const attachCompanyProductsListener = ({ listener }) => {
  const key = ALL_COMPANY_PRODUCTS.cacheKey;
  CacheRequest.attachListener(key, listener);
};

export const removeCompanyProductsListener = ({ listener }) => {
  const key = ALL_COMPANY_PRODUCTS.cacheKey;
  CacheRequest.removeListener(key, listener);
};

export const getCompanyProductsData = async ({ returnError = false } = {}) => {
  //Call already in progress
  if (isPaginatedCallInProg()) {
    return;
  }

  setPaginatedCallInProg(true);
  const lastFetchedDate = getLastFetchedProductLibTimeStamp();
  const lastFetchedKey = getLastFetchedProductLibKey();
  const key = ALL_COMPANY_PRODUCTS.cacheKey;
  const apiCall = ALL_COMPANY_PRODUCTS.apiFunction;
  try {
    const existingData = CacheRequest.getCacheForKey(key);
    if (!existingData) {
      CacheRequest.notifyListeners(key, null, {
        status: CacheRequest.OPERATION_STATUS.LOADING
      });
    }

    const companyProductsData = await apiCall(lastFetchedDate, lastFetchedKey);
    setPaginatedCallInProg(false);

    const oldProductsData = getCompanyProductsDataFromCache();
    if (Array.isArray(companyProductsData.products)) {
      oldProductsData.products.push(...companyProductsData.products);
      companyProductsData.products = oldProductsData.products;
      setCompanyProductsInCache(companyProductsData);
      return;
    }

    //No new data available, simply send back updated to notify any avaialble listeners
    CacheRequest.notifyListeners(key, null, {
      status: CacheRequest.OPERATION_STATUS.UPDATE
    });
  } catch (fetchCompanyProductsError) {
    setPaginatedCallInProg(false);
    //Notify all listeners of an error
    CacheRequest.setCacheForKey(key, undefined, {
      error: fetchCompanyProductsError
    });
    if (returnError) {
      throw fetchCompanyProductsError;
    }
  }
};

export const getCompanyProductsDataFromCache = () => {
  const key = ALL_COMPANY_PRODUCTS.cacheKey;
  const companyProducts = CacheRequest.getCacheForKey(key);
  if (!companyProducts || !Array.isArray(companyProducts.products)) {
    return {
      totalProducts: 0,
      products: []
    };
  }

  return {
    totalProducts: companyProducts.totalProducts,
    products: companyProducts.products
  };
};

export const setCompanyProductsInCache = updatedProductsData => {
  const key = ALL_COMPANY_PRODUCTS.cacheKey;
  const oldCompanyProducts = CacheRequest.getCacheForKey(key);
  CacheRequest.setCacheForKey(key, {
    ...oldCompanyProducts,
    ...updatedProductsData
  });
};

export const deleteCachedCompanyProductsData = () => {
  const key = ALL_COMPANY_PRODUCTS.cacheKey;
  CacheRequest.deleteCacheForKeys([key]);
  clearCompanyProductsLastFetchedResponse();
};

export const attachCatalogueProductsListener = ({ listener, catalogueId }) => {
  const key = `${ALL_CATALOGUE_PRODUCTS.cacheKey}${catalogueId}`;
  CacheRequest.attachListener(key, listener);
};

export const removeCatalogueProductsListener = ({ listener, catalogueId }) => {
  const key = `${ALL_CATALOGUE_PRODUCTS.cacheKey}${catalogueId}`;
  CacheRequest.removeListener(key, listener);
};

export const getCatalogueProductsData = catalogueId => {
  const key = `${ALL_CATALOGUE_PRODUCTS.cacheKey}${catalogueId}`;
  const apiCall = ALL_CATALOGUE_PRODUCTS.apiFunction;
  CacheRequest.makeRequest(key, apiCall, {
    params: [catalogueId],
    options: {
      shouldNotStoreInNative: true
    }
  });
};

export const getCatalogueProductsDataFromCache = catalogueId => {
  const key = `${ALL_CATALOGUE_PRODUCTS.cacheKey}${catalogueId}`;
  const catalogueProducts = CacheRequest.getCacheForKey(key);
  if (!catalogueProducts || !Array.isArray(catalogueProducts.products)) {
    return {
      totalProducts: 0,
      products: []
    };
  }

  return {
    totalProducts: catalogueProducts.products.length,
    products: catalogueProducts.products
  };
};

export const getProductLibraryProductsDataBasedOnFilter = catalogueFilter => {
  if (!catalogueFilter) {
    return getCompanyProductsDataFromCache();
  }

  return getCatalogueProductsDataFromCache(catalogueFilter);
};

export const getProductLibraryDataBasedOnFilter = catalogueFilter => {
  if (!catalogueFilter) {
    const key = ALL_COMPANY_PRODUCTS.cacheKey;
    return CacheRequest.getCacheForKey(key) || undefined;
  }

  const key = `${ALL_CATALOGUE_PRODUCTS.cacheKey}${catalogueFilter}`;
  return CacheRequest.getCacheForKey(key) || undefined;
};

//NEW PART

/* export const attachProductSearchListener = ({ catalogueId, listener }) => {
  CacheRequest.attachListener(getCacheKeyForProductSearch({ catalogueId }), listener);
};

export const removeProductSearchListener = ({ catalogueId, listener }) => {
  CacheRequest.removeListener(getCacheKeyForProductSearch({ catalogueId }), listener);
};

const clearProductSearchDataFromCache = ({ searchTerm, catalogueId } = {}) => {
  PRODUCT_LIB_PRODUCT_SEARCH_DATA.currentSearchTerm = searchTerm;
  PRODUCT_LIB_PRODUCT_SEARCH_DATA.currentPage = 0;
  PRODUCT_LIB_PRODUCT_SEARCH_DATA.paginationCompleted = false;
  PRODUCT_LIB_PRODUCT_SEARCH_DATA.nextPosition = undefined;
  CacheRequest.deleteCacheForKeys([getCacheKeyForProductSearch({ catalogueId })]);
};

export const searchProductsFromLibrary = ({
  searchTerm,
  catalogueId,
  forceLoad,
  unpackDefaultVariant
}) =>
  paginatedSearchProductLibrary({
    searchTerm,
    forceLoad,
    notifyListeners: () => notifyProductSearchListeners({ catalogueId }),
    apiFunction: apiParams => {
      if (searchTerm) {
        return PRODUCT_SEARCH_DATA.apiFunctionSearch(apiParams);
      }
      return PRODUCT_SEARCH_DATA.apiFunction(apiParams);
    },
    getApiParams: () => {
      const params = {
        catalogueId,
        unpackDefaultVariant
      };

      if (searchTerm) {
        params.searchTerm = searchTerm;
        params.page = PRODUCT_LIB_PRODUCT_SEARCH_DATA.currentPage;
      } else {
        params.nextPosition = PRODUCT_LIB_PRODUCT_SEARCH_DATA.nextPosition;
      }

      return params;
    },
    notifyError: ({ error }) => {
      notifyProductErrorListener({ error, catalogueId });
    },
    processSearchTermChange: clearProductSearchDataFromCache,
    processPageIncrement: () => processProductPageIncrement({ searchTerm }),
    searchDataConst: PRODUCT_LIB_PRODUCT_SEARCH_DATA,
    onMergePaginatedData: ({ apiSearchData }) => {
      mergeProductSearchDataWithCache({ apiSearchData, catalogueId, unpackDefaultVariant });
    }
  }); */

/* const canRequestNextSearchProductPage = () =>
  !PRODUCT_LIB_SEARCH_DATA.paginationInProg &&
  !PRODUCT_LIB_PRODUCT_SEARCH_DATA.paginationInProg &&
  !PRODUCT_LIB_PRODUCT_SEARCH_DATA.paginationCompleted; */

export const copyProductsToCatalogue = async ({ catalogueId, productIds }) => {
  try {
    await duplicateProductsToCatalogue({ catalogueId, productIds });
  } catch (copyProductsError) {
    reportError(copyProductsError);
    throw copyProductsError;
  }

  //Products created successfully, get the products list and set it in cache
  try {
    const { productsList } = await Api.getProductList(catalogueId);
    const cacheKey = `${connector.PRODUCT_LIST_META.cacheKey}${catalogueId}`;
    CacheRequest.setCacheForKey(cacheKey, {
      productsList
    });
  } catch (fetchProductListError) {
    reportError(fetchProductListError);
  }
};
