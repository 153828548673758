import CacheRequest from './CacheRequest';
import {
  debouncer,
  DEBOUNCER_TYPE,
  isFileCSV,
  toggleGlobalLoader,
  getImageUrlFromPictureId,
  getCurrencySymbol,
  PRODUCT_LAST_FETCH_TS,
  isValidNumber
} from 'qs-helpers';
import { connector } from './ApiAndCacheConnector';
import {
  deleteProductsFromNative,
  updateExistingProductsInNative,
  addProductsInNative,
  changeProductsListInNative,
  clearProductDataFromNative
} from './Dexie/ProductDexieHelpers';
import Api from 'qs-services/Api';
import { db } from 'qs-config/FirebaseConfig';
import { CSV_UPLOADER_EB_KEY, getActiveCatalogueId, EXCEL_UPLOAD_META } from './Catalogues';
import eventbus from 'eventing-bus';
import Tags from 'qs-data-manager/Tags';
import { getImageWidthHeight } from 'qs-helpers/index';
import CatalogueLib from 'qs-data-manager/Catalogues';
import { getCompanyCurrencyCode } from 'qs-data-manager/Company';
import {
  upsertCatalogueRowInNative,
  saveTagsChangesInNative
} from 'qs-data-manager/Dexie/CatalogueDexieHelpers';
import cloneDeep from 'lodash.clonedeep';
import { canUseFeature, FEATURE_LIST } from 'qs-data-manager/FeatureUsage';
import Mixpanel from 'qs-data-manager/Mixpanel';
import * as Sentry from '@sentry/browser';
import { reportError } from 'qs-helpers/ErrorReporting';
import { getUniqueDeviceId } from 'qs-helpers/DeviceIdGenerator';
import {
  toggleProductPicturesHeader,
  IMAGE_UPLOAD_HELPER,
  processImageUpload,
  toggleVariantPicturesHeader
} from 'qs-helpers/ProcessUploadedImage';
import { uploadImages } from 'qs-image-upload/ImageUploader';
import { registerCleanupHandler } from 'qs-helpers/ClearSavedData';
import { parseFileToCsv } from 'qs-helpers/CSVUploader';
import toastr from 'toastr';
import { PRODUCT_VARIANT_INFO } from 'qs-api/Variants/ApiCacheConnector';
import { processVariantImagesAdd } from 'qs-helpers/Variants/ResponseProcessor';
import { updateVariantMetaWithDefaultPicture } from 'qs-data-manager/Variants/VariantPictures';
import {
  processFileList,
  createDirectoryStructureFromRelativePath,
  getFirstFileMeta,
  removeCSVFromFinalList,
  isSingleFolderUploaded,
  doesFolderContainOnlyFiles,
  unpackSingleFolderFiles
} from 'qs-helpers/FileUploads/ProcessUploadedFiles';
import { getProductListFromCache, setProductListInCache } from './Products/CatalogueProductsList';
import { getBasicInfoFromCache, getSotedPicturesByPosition } from './ProductDetails';
import { openPopup } from 'qs-utils';
import { getI18N } from '../i18N';

let PRODUCT_CHANGES_THROTTLER_ID = null;
let PRODUCT_CHANGES_QUEUE = [];

const PRODUCT_ROW__META_DEBOUNCER = {
  key: 'PRODUCT_ROW__META_DEBOUNCER_KEY',
  timeInMs: 200,
  type: DEBOUNCER_TYPE.ADD
};

// { `catalogueId`: {} }
let PRODUCT_POSITION_MAP = {};

const PRODUCT_ROW_TYPES = {
  PRODUCT_ROW: {
    estimatedHeight: 126
  },
  overscanCount: 20
};

const ACTIVE_PRODUCT_ID_META = {
  eventbusKey: 'ACTIVE_PRODUCT_ID_EB_KEY',
  productId: null
};

const UPLOAD_IMAGE_MODAL = {
  eventbusKey: 'UPLOAD_IMAGE_MODAL',
  meta: {}
};

const UPLOAD_IMAGE_FORMAT = {
  eventbusKey: 'UPLOAD_IMAGE_FORMAT',
  meta: {}
};

const UPDATE_PICTURES_POSITIONS = {
  eventbusKey: 'UPDATE_PICTURES_POSITIONS',
  data: {}
};

const DELETED_PICTURE_ID = {
  eventbusKey: 'DELETED_PICTURE_ID'
};

const TOGGLE_REARRANGING_PICTURES = {
  eventbusKey: 'TOGGLE_REARRANGING_PICTURES'
};

let PRODUCT_META_REQUEST_SEND = {};

const OPERATION_STATUS = CacheRequest.OPERATION_STATUS;

//TODO remove listeners and data fetch
const attachProductListListener = ({ listener, catalogueId }) => {
  const key = `${connector.PRODUCT_LIST_META.cacheKey}${catalogueId}`;
  CacheRequest.attachListener(key, listener);
};

const removeProductListListener = ({ listener, catalogueId }) => {
  const key = `${connector.PRODUCT_LIST_META.cacheKey}${catalogueId}`;
  CacheRequest.removeListener(key, listener);
};

const getProductList = ({ catalogueId }) => {
  const key = `${connector.PRODUCT_LIST_META.cacheKey}${catalogueId}`;
  const apiCall = connector.PRODUCT_LIST_META.apiFunction;
  CacheRequest.makeRequest(key, apiCall, {
    params: [catalogueId],
    options: {
      extraData: {
        catalogueId
      },
      nativeStorageKey: connector.PRODUCT_LIST_META.nativeStorageKey
    }
  });
};

const attachProductMetaListener = ({ listener, productId }) => {
  const key = `${connector.PRODUCT_ROW_META.cacheKey}${productId}`;
  CacheRequest.attachListener(key, listener);
};

const removeProductMetaListener = ({ listener, productId }) => {
  const key = `${connector.PRODUCT_ROW_META.cacheKey}${productId}`;
  CacheRequest.removeListener(key, listener);
};

const productMetaBatchCallback = (response, error) => {
  const sharedCacheKey = connector.PRODUCT_ROW_META.cacheKey;
  if (error) {
    return;
  }

  if (!!response && !!response.productMeta && typeof response.productMeta === 'object') {
    Object.keys(response.productMeta).forEach(id => {
      const cache = response.productMeta[id];
      const key = `${sharedCacheKey}${id}`;
      PRODUCT_META_REQUEST_SEND[id] = true;
      CacheRequest.setCacheForKey(key, cache);
    });
  }
};

const productMetaDebounceCallback = (multipleBatchedProductData = []) => {
  const allRenderedProductIds = {};
  const sharedCacheKey = connector.PRODUCT_ROW_META.cacheKey;

  multipleBatchedProductData.forEach(productIds => {
    productIds.forEach(productId => {
      if (!productId) {
        return;
      }

      const ifExists = PRODUCT_META_REQUEST_SEND[productId];
      if (!ifExists) {
        allRenderedProductIds[productId] = true;
      }
    });
  });

  const renderedProductIds = Object.keys(allRenderedProductIds);
  if (!renderedProductIds.length) {
    return;
  }
  const oneTimeUniqueKey = `PRODUCT_META_LISTENER_${new Date().getTime()}`;
  const apiCall = connector.PRODUCT_ROW_META.apiFunction;

  CacheRequest.makeRequest(oneTimeUniqueKey, apiCall, {
    params: [renderedProductIds],
    options: {
      isBatched: true,
      sharedCacheKey: sharedCacheKey,
      batchCallback: productMetaBatchCallback,
      nativeStorageKey: connector.PRODUCT_ROW_META.nativeStorageKey
    }
  });
};

const getProductMeta = ({ productIds } = {}) => {
  debouncer(
    { data: productIds, key: PRODUCT_ROW__META_DEBOUNCER.key },
    { time: PRODUCT_ROW__META_DEBOUNCER.timeInMs, type: PRODUCT_ROW__META_DEBOUNCER.type },
    productMetaDebounceCallback
  );
};

const fetchAndUpdateProductMetaInCache = async productId => {
  try {
    const { productMeta } = await Api.getBatchedProductList([productId]);
    setProductMetaInCache({ productId, updates: productMeta[productId] || {} });
  } catch (fetchMetaError) {
    reportError(fetchMetaError);
  }
};

// HELPER FUNCTIONS

const previewCatalogue = async ({ catalogueId }) => {
  const loaderKey = `previewCatalogue${catalogueId}`;
  toggleGlobalLoader(loaderKey, true);

  const key = `${connector.CATALOGUE_LINK.cacheKey}${catalogueId}`;
  let link = '';
  const linkMeta = CacheRequest.getCacheForKey(key) || {};
  const { linkDomain, companySlug, catalogueSlug, randomSlug } = linkMeta || {};

  if (linkDomain && companySlug && catalogueSlug && randomSlug) {
    link = `${linkDomain}/${companySlug}/${catalogueSlug}/${randomSlug}`;
  } else {
    const resp = await CatalogueLib.createCataloguesLink([catalogueId]);
    ({ link } = resp);
  }

  if (!link) {
    toggleGlobalLoader(loaderKey, false);
    return;
  }

  openPopup(link);
  toggleGlobalLoader(loaderKey, false);
};

const getProductMetaFromCache = productId => {
  const key = `${connector.PRODUCT_ROW_META.cacheKey}${productId}`;
  const meta = CacheRequest.getCacheForKey(key);
  if (!meta) {
    return null;
  }

  const newMeta = { ...meta };
  const currencyCode = getCompanyCurrencyCode();
  newMeta.currencySymbol = getCurrencySymbol({ currencyCode });
  return newMeta;
};

const setProductMetaInCache = ({ productId, updates }) => {
  const key = `${connector.PRODUCT_ROW_META.cacheKey}${productId}`;
  const meta = CacheRequest.getCacheForKey(key) || {};
  CacheRequest.setCacheForKey(key, {
    ...meta,
    ...updates
  });
};

const deleteProductsFromCache = (productIds, catalogueId, changeCatalogueRow) => {
  const updates = {};

  // Process cached data
  const productRowCacheKeys = [];
  let deletedErroredProducts = 0;
  const productsList = getProductListFromCache({ catalogueId }) || [];
  const productIdMapForSearch = productIds.reduce((cumulativeMap, productId) => {
    const productRowCacheKey = `${connector.PRODUCT_ROW_META.cacheKey}${productId}`;
    // Save product cache key for delete
    productRowCacheKeys.push(productRowCacheKey);

    // Fetch product data to get delete count
    const productData = CacheRequest.getCacheForKey(productRowCacheKey);
    // Current product being deleted had an error, add to the deleted count
    if (productData && productData.defaultImageErrored) {
      deletedErroredProducts += 1;
    }

    // Create map for search
    cumulativeMap[productId] = true;
    return cumulativeMap;
  }, {});

  const newProductList = productsList.filter(
    ({ productId } = {}) => !productIdMapForSearch[productId]
  );
  let filteredProductList = getProductListFromCache({ catalogueId, filters: true });
  if (Array.isArray(filteredProductList)) {
    filteredProductList = filteredProductList.filter(
      ({ productId } = {}) => !productIdMapForSearch[productId]
    );
  }
  updates.newProductList = newProductList;

  const top4ProductIds = newProductList.slice(0, 4);
  const top4PicturesMeta = top4ProductIds.map(({ productId } = {}) => {
    const key = `${connector.PRODUCT_ROW_META.cacheKey}${productId}`;
    const product = CacheRequest.getCacheForKey(key);
    return product && product.pictureId
      ? {
          pictureId: product.pictureId,
          prepared: product.isPrepared,
          error: product.defaultImageErrored,
          url: product.pictureUrl
        }
      : {
          pictureId: '',
          url: '',
          prepared: false,
          error: false
        };
  });

  if (changeCatalogueRow) {
    const catalogueRowCacheKey = `${connector.CATALOGUE_ROW_META.cacheKey}${catalogueId}`;
    const catalogueRow = CacheRequest.getCacheForKey(catalogueRowCacheKey);

    let erroredProductCount = catalogueRow.erroredProductCount - deletedErroredProducts;
    const newProductCount = catalogueRow.productCount - productIds.length;

    /*
      If the new productCount is 0 then all products have been deleted.
      In this case the erroredProductCount calculate above may not be accurate because
      all the errored products may not be loaded while sending the delete all request.
      Hence if there are no products left, reduce the error count to 0 as well
    */
    if (newProductCount === 0) {
      erroredProductCount = 0;
    }

    const newCatalogueRow = {
      ...catalogueRow,
      erroredProductCount,
      productCount: newProductCount,
      picturesMeta: top4PicturesMeta
    };

    CacheRequest.setCacheForKey(catalogueRowCacheKey, newCatalogueRow);
    updates.newProductCount = newProductCount;
    updates.newCatalogueRow = newCatalogueRow;
  }

  setProductListInCache({ productsList: newProductList, catalogueId });
  setProductListInCache({ productsList: filteredProductList, catalogueId, filters: true });
  CacheRequest.deleteCacheForKeys(productRowCacheKeys);

  return updates;
};

const deleteProductsFromRemote = (productIds, catalogueId) => {
  return Api.deleteProducts(productIds, catalogueId);
};

const deleteProducts = async (productIds = [], id, extraData = {}) => {
  const loaderUniqueKey = `DELETE_PRODUCT_${productIds[0]}`;
  try {
    const { showLoader = true, makeRemoteChanges = true, changeCatalogueRow = true } = extraData;
    let catalogueId = id;
    if (!catalogueId) {
      catalogueId = getActiveCatalogueId();
    }

    if (!catalogueId) {
      return;
    }

    if (showLoader) {
      toggleGlobalLoader(loaderUniqueKey, true);
    }

    if (makeRemoteChanges) {
      await deleteProductsFromRemote(productIds, catalogueId);
    }

    const changes = deleteProductsFromCache(productIds, catalogueId, changeCatalogueRow);

    const promises = [
      deleteProductsFromNative({ productIds, catalogueId, changeCatalogueRow }, changes)
    ];

    await Promise.all(promises);
    CatalogueLib.getCatalogueTags(catalogueId);

    if (showLoader) {
      toggleGlobalLoader(loaderUniqueKey, false);
    }
  } catch (err) {
    toggleGlobalLoader(loaderUniqueKey, false);
    Sentry.captureException(err);
  }
};

const uploadFromFilePicker = ({ filesList }) => {
  if (!filesList) {
    return;
  }

  const rawFiles = processFileList(filesList);
  handleProcessedRawFiles(rawFiles);
};

const uploadFromFolderPicker = ({ fileEntries }) => {
  if (!fileEntries) {
    return;
  }

  const rawFiles = createDirectoryStructureFromRelativePath(fileEntries);
  handleProcessedRawFiles(rawFiles);
};

const handleProcessedRawFiles = async acceptedFiles => {
  const catalogueId = getActiveCatalogueId();
  if (!(Array.isArray(acceptedFiles) && acceptedFiles.length)) {
    UPLOAD_IMAGE_MODAL.meta = {
      noOfFiles: 0,
      canFileNameBePrice: false,
      shouldShow: true,
      catalogueId,
      allImages: []
    };
    eventbus.publish(UPLOAD_IMAGE_MODAL.eventbusKey, UPLOAD_IMAGE_MODAL.meta);
    return;
  }

  const { file: firstFile } = getFirstFileMeta(acceptedFiles);
  if (isFileCSV(firstFile)) {
    eventbus.publish(CSV_UPLOADER_EB_KEY, true, firstFile);
    return;
  }

  const folderUploaded = isSingleFolderUploaded(acceptedFiles);
  if (folderUploaded) {
    //Only files are present in the folder, ask the user how they want to create products
    if (doesFolderContainOnlyFiles(acceptedFiles[0])) {
      const csvRemovedFiles = removeCSVFromFinalList(acceptedFiles);
      const rawFolderData = csvRemovedFiles[0];

      /*
        Single file is present in the folder, no need to present options to the user
        Simply open the existing image modal
      */
      if (rawFolderData.files.length === 1) {
        openImageUploadModal({ files: unpackSingleFolderFiles(rawFolderData), catalogueId });
        return;
      }

      eventbus.publish(UPLOAD_IMAGE_FORMAT.eventbusKey, { rawFolderData });
      return;
    }

    //Folder has either multiple folders or a mix of folders and files
    //In either case, unpack it so that multiple products are created accordingly
    acceptedFiles = unpackSingleFolderFiles(acceptedFiles[0]);
  }

  //Remove all CSV files from the final list as only images are going to be uploaded
  acceptedFiles = removeCSVFromFinalList(acceptedFiles);
  const options = { extraProductCount: acceptedFiles.length };
  const canUse = canUseFeature(FEATURE_LIST.PRODUCTS.id, options);
  if (!canUse) {
    return;
  }
  openImageUploadModal({ files: acceptedFiles, catalogueId });
};

const getColumnValueForExcelMapping = ({
  headers,
  componentCSVMapping,
  row,
  fieldId,
  excelComponentMap
}) => {
  const productMeta = getProductMetaFromCSV({
    headers,
    componentCSVMapping,
    row,
    fieldId,
    excelComponentMap
  });
  const { getApiFormattedValue } = excelComponentMap[fieldId] || {};
  if (typeof getApiFormattedValue !== 'function') {
    return;
  }

  return getApiFormattedValue(productMeta, { columns: componentCSVMapping[fieldId] });
};

const getColumnValueForMappingAndPerformAction = ({
  headers,
  componentCSVMapping,
  row,
  fieldId,
  excelComponentMap,
  actionCallback
}) => {
  const value = getColumnValueForExcelMapping({
    headers,
    componentCSVMapping,
    row,
    fieldId,
    excelComponentMap
  });
  const { apiKey } = excelComponentMap[fieldId] || {};
  if (value !== undefined && value !== null) {
    actionCallback({ apiKey, value });
  }
};

// On upload from CSV
const createProductsFromCSV = async (
  { headers, data, componentCSVMapping, catalogueId, mappedValue, excelComponentMap },
  { toggleLoader, onSuccess, onFail }
) => {
  try {
    let keyForMapping = 'NONE';
    if (excelComponentMap[mappedValue]) {
      keyForMapping = excelComponentMap[mappedValue].apiChangesKey;
    }

    const uuid = getUniqueDeviceId();
    const products = [];

    data.forEach((row, index) => {
      if (index === 0) {
        return;
      }

      const meta = {};

      EXCEL_UPLOAD_META.RENDER_ORDER.forEach(id => {
        const { subExcelComponents, appendKeyToProductBaseObject } = excelComponentMap[id] || {};
        if (Array.isArray(subExcelComponents)) {
          if (appendKeyToProductBaseObject) {
            subExcelComponents.forEach(subComponentId => {
              getColumnValueForMappingAndPerformAction({
                headers,
                componentCSVMapping,
                row,
                fieldId: subComponentId,
                excelComponentMap,
                actionCallback: ({ apiKey, value }) => {
                  meta[apiKey.toLocaleLowerCase()] = value;
                }
              });
            });
            return;
          }

          const apiValue = subExcelComponents.reduce((cumulativeArray, subComponentId) => {
            getColumnValueForMappingAndPerformAction({
              headers,
              componentCSVMapping,
              row,
              fieldId: subComponentId,
              excelComponentMap,
              actionCallback: ({ value }) => {
                cumulativeArray.push(value);
              }
            });

            return cumulativeArray;
          }, []);

          const { apiKey } = excelComponentMap[id];
          if (apiValue.length > 0) {
            meta[apiKey] = apiValue;
          }

          return;
        }

        getColumnValueForMappingAndPerformAction({
          headers,
          componentCSVMapping,
          row,
          fieldId: id,
          excelComponentMap,
          actionCallback: ({ apiKey, value }) => {
            meta[apiKey] = value;
          }
        });
      });

      products.push(meta);
    });

    toggleLoader(true);
    Mixpanel.sendEvent({ eventName: 'Excel uploaded' });
    const {
      updatedProducts,
      newProductList,
      productCount,
      top4PictureUrls,
      catalogueTags
    } = await Api.uploadExcel({
      productsMeta: products,
      keyForMapping,
      catalogueId,
      uuid
    });

    await updateLocalAfterUpload({
      updatedProducts,
      newProductList,
      productCount,
      top4PictureUrls,
      catalogueId,
      catalogueTags
    });

    onSuccess({ showLoader: false, reset: true });
  } catch (error) {
    Sentry.captureException(error);
    onFail(error);
  }
};

const updateLocalAfterUpload = async ({
  updatedProducts,
  newProductList,
  productCount,
  top4PictureUrls,
  catalogueId,
  catalogueTags
}) => {
  try {
    // close third pane
    eventbus.publish(ACTIVE_PRODUCT_ID_META.eventbusKey, { productId: null });

    const nativeChangePromise = [];
    const catalogueRowCacheKey = `${connector.CATALOGUE_ROW_META.cacheKey}${catalogueId}`;
    const catalogueRowCache = CacheRequest.getCacheForKey(catalogueRowCacheKey);
    const newCatalogueRowCache = cloneDeep(catalogueRowCache);
    newCatalogueRowCache.productCount = productCount;
    newCatalogueRowCache.picturesMeta = top4PictureUrls;
    CacheRequest.setCacheForKey(catalogueRowCacheKey, newCatalogueRowCache);

    const productRowSharedKey = connector.PRODUCT_ROW_META.cacheKey;
    Object.keys(updatedProducts).forEach(productId => {
      const key = `${productRowSharedKey}${productId}`;
      const product = updatedProducts[productId];
      CacheRequest.setCacheForKey(key, product);
    });
    const productsNativeFormat = Object.values(updatedProducts);

    const productListCacheKey = `${connector.PRODUCT_LIST_META.cacheKey}${catalogueId}`;
    CacheRequest.setCacheForKey(productListCacheKey, { productsList: newProductList });

    if (catalogueTags) {
      const catalogueTagCacheKey = `${connector.CATALOGUE_TAGS.cacheKey}${catalogueId}`;
      CacheRequest.setCacheForKey(catalogueTagCacheKey, { tags: catalogueTags });
      nativeChangePromise.push(saveTagsChangesInNative({ catalogueId, tags: catalogueTags }));
    }

    await Promise.all([
      upsertCatalogueRowInNative({ [catalogueId]: catalogueRowCache }),
      updateExistingProductsInNative(productsNativeFormat),
      changeProductsListInNative({ newProductList, catalogueId }),
      ...nativeChangePromise
    ]);
  } catch (error) {
    reportError(error);
  }
};

const removeComma = value => {
  const indexOfComma = value.indexOf(',');
  if (indexOfComma > -1) {
    value = `${value.slice(0, indexOfComma)}${value.slice(indexOfComma + 1)}`;
  }

  return value;
};

const parseTax = value => {
  const indexOfPercentage = value.indexOf('%');
  if (indexOfPercentage > -1) {
    value = `${value.slice(0, indexOfPercentage)}${value.slice(indexOfPercentage + 1)}`;
  }

  return value;
};

const handleCsvNumberField = ({ fieldId, value }) => {
  if (!value) {
    return;
  }

  let parsedValue = '';

  parsedValue = removeComma(value);

  if (fieldId === EXCEL_UPLOAD_META.TAX_PERCENTAGE.id) {
    parsedValue = parseTax(value);
  }

  const isValid = isValidNumber(parsedValue);
  if (!isValid) {
    parsedValue = '';
  }

  return parsedValue;
};

const getProductMetaFromCSV = (
  { headers, componentCSVMapping, row, fieldId, excelComponentMap },
  { acceptInvalidImages = false } = {}
) => {
  let data = [];

  if (!componentCSVMapping[fieldId] || !componentCSVMapping[fieldId].length) {
    return data;
  }

  const columnNames = componentCSVMapping[fieldId];
  columnNames.forEach(column => {
    const position = headers.indexOf(column);
    let value = row[position];

    if (excelComponentMap[fieldId] && excelComponentMap[fieldId].fieldType === 'NUMBER') {
      const parsedValue = handleCsvNumberField({ fieldId, value });

      if (!parsedValue) {
        return;
      }

      value = parsedValue;
    }

    if (
      excelComponentMap[fieldId] &&
      excelComponentMap[fieldId].fieldType !== 'NUMBER' &&
      !value &&
      fieldId !== excelComponentMap.IMAGE.id &&
      !acceptInvalidImages
    ) {
      return;
    }

    if (fieldId === excelComponentMap.DESCRIPTION.id) {
      const formattedColumnName =
        column.toLocaleLowerCase() === 'description' ||
        column.toLocaleLowerCase() === 'product description'
          ? ''
          : `${column}: `;
      data.push(`${formattedColumnName}${value}\n`);
    } else {
      data.push(value);
    }
  });

  return data;
};

//TODO remove
const changeProductListInLocal = ({ productsList, catalogueId }) => {
  const cacheKey = `${connector.PRODUCT_LIST_META.cacheKey}${catalogueId}`;
  CacheRequest.setCacheForKey(cacheKey, {
    productsList
  });
};

const changeProductsList = async ({ productsList }, extraData = {}) => {
  const catalogueId = extraData.catalogueId || getActiveCatalogueId();
  changeProductListInLocal({ productsList, catalogueId });
  await changeProductsListInNative({ productsList, catalogueId });
};

// ACTIVE PRODUCT IDs helpers
const setActiveProductId = productId => {
  ACTIVE_PRODUCT_ID_META.productId = productId;

  eventbus.publish(ACTIVE_PRODUCT_ID_META.eventbusKey, {
    productId
  });
};

const getActiveProductId = () => ACTIVE_PRODUCT_ID_META.productId;

const resetActiveProductId = () => {
  ACTIVE_PRODUCT_ID_META.productId = null;
  eventbus.publish(ACTIVE_PRODUCT_ID_META.eventbusKey, {
    productId: null
  });
};

const isProductSelected = productId => {
  const activeProductId = ACTIVE_PRODUCT_ID_META.productId ? ACTIVE_PRODUCT_ID_META.productId : '';

  return productId === activeProductId;
};

const reorderProductInCache = ({ newProductList, catalogueId, oldIndex, newIndex }) => {
  const key = `${connector.PRODUCT_LIST_META.cacheKey}${catalogueId}`;
  const nativeListFormat = newProductList;
  const listApiFormat = [];

  newProductList.forEach((row, index) => {
    listApiFormat.push({ id: row.productId, position: index });
  });

  const newCache = { productsList: nativeListFormat };
  CacheRequest.setCacheForKey(key, newCache);

  if (oldIndex < 4 || newIndex < 4) {
    const top4Ids = nativeListFormat.slice(0, 4).map(({ productId }) => productId);
    const pictureStatus = top4Ids.map(id => {
      const key = `${connector.PRODUCT_ROW_META.cacheKey}${id}`;
      const cache = CacheRequest.getCacheForKey(key);
      return {
        pictureId: cache.pictureId,
        url: cache.pictureUrl,
        prepared: cache.isPrepared,
        error: cache.defaultImageErrored
      };
    });

    const catalogueRowKey = `${connector.CATALOGUE_ROW_META.cacheKey}${catalogueId}`;
    const catalogueRowCache = CacheRequest.getCacheForKey(catalogueRowKey);
    const catalogueRowNewCache = {
      ...catalogueRowCache,
      picturesMeta: pictureStatus
    };

    CacheRequest.setCacheForKey(catalogueRowKey, catalogueRowNewCache);
  }

  return {
    newList: nativeListFormat,
    listApiFormat
  };
};

const changeProductListInRemote = ({ productsList, catalogueId }) => {
  Api.reorderProductInRemote({ productsList, catalogueId });
};

const reorderProduct = async ({ newProductList, catalogueId, oldIndex, newIndex }) => {
  const loaderKey = `reorderProduct${catalogueId}`;

  toggleGlobalLoader(loaderKey, true);
  const changes = reorderProductInCache({ newProductList, catalogueId, oldIndex, newIndex });
  setProductPositionMap({ productList: newProductList, catalogueId });

  await Promise.all([
    changeProductsListInNative({ productsList: changes.newList, catalogueId }),
    changeProductListInRemote({ productsList: changes.listApiFormat, catalogueId })
  ]);

  toggleGlobalLoader(loaderKey, false);
};

/**
 *
 * @param {Array<RawFileData>} files
 */
const canFileNameBePrice = files => {
  return files.filter(rawFile => {
    const { files: [{ name = '' } = {}] = [] } = rawFile;
    const splitedName = name.split('.');
    const fileName = splitedName.slice(0, splitedName.length - 1).join('');
    return !isNaN(fileName);
  }).length;
};

/**
 *
 * @param {*} param0
 * @param {Array<RawFileData>} files
 */
const openImageUploadModal = ({ files, catalogueId }) => {
  const { file, displayName } = getFirstFileMeta(files);
  UPLOAD_IMAGE_MODAL.meta = {
    file,
    displayName,
    noOfFiles: files.length,
    canFileNameBePrice: canFileNameBePrice(files),
    shouldShow: true,
    catalogueId,
    allImages: files
  };
  eventbus.publish(UPLOAD_IMAGE_MODAL.eventbusKey, UPLOAD_IMAGE_MODAL.meta);
};

const closeImageUploadModal = () => {
  UPLOAD_IMAGE_MODAL.meta = {};
  eventbus.publish(UPLOAD_IMAGE_MODAL.eventbusKey, UPLOAD_IMAGE_MODAL.meta);
};

const deleteNewPicturesFromProductInCache = ({ cacheKey: basicInfoCacheKey, newPicturesMeta }) => {
  const basicInfoCache = CacheRequest.getCacheForKey(basicInfoCacheKey);

  //Delete the newly added data from the cache
  newPicturesMeta.forEach(({ pictureId }) => {
    delete basicInfoCache.pictures[pictureId];
  });

  CacheRequest.setCacheForKey(basicInfoCacheKey, basicInfoCache);
};

// Upload more product images
const uploadPicturesToProduct = async ({
  images,
  productId,
  catalogueId,
  uploadData: { key = IMAGE_UPLOAD_HELPER.PRODUCT_EXTRA_PICTURE_UPLOAD.key, optionId } = {}
}) => {
  const extraData = {
    calledFrom: key
  };

  //Add the option id to the extra data for image upload if it exists
  if (optionId) {
    extraData.optionId = optionId;
  }

  let cacheKey;
  if (key === IMAGE_UPLOAD_HELPER.VARIANT_PICTURE_UPLOAD.key) {
    cacheKey = `${PRODUCT_VARIANT_INFO.cacheKey}${productId}`;
    toggleVariantPicturesHeader({ variantId: productId, totalImages: images.length });
  } else {
    cacheKey = `${connector.BASIC_INFO.cacheKey}${productId}`;
    toggleProductPicturesHeader({ productId, totalImages: images.length });
  }

  const changes = await changeProductPicturesInCache({
    catalogueId,
    productId,
    images,
    cacheKey,
    extraData
  });

  try {
    await addImageToRemote({ apiMeta: changes.apiMeta, productId, optionId, key });
  } catch (error) {
    // Products could not be updated in remote at all. Don't persist anything. Fail the entire upload process
    Sentry.captureException(error);
    deleteNewPicturesFromProductInCache({
      cacheKey,
      newPicturesMeta: changes.apiMeta.pictureMeta
    });
    if (key === IMAGE_UPLOAD_HELPER.VARIANT_PICTURE_UPLOAD.key) {
      toggleVariantPicturesHeader({ variantId: productId, totalImages: -images.length });
    } else {
      toggleProductPicturesHeader({ productId, totalImages: -images.length });
    }
    throw error;
  }

  if (key === IMAGE_UPLOAD_HELPER.VARIANT_PICTURE_UPLOAD.key) {
    updateVariantMetaWithDefaultPicture({
      variantId: productId,
      pictures: changes.apiMeta.pictureMeta
    });
  } else {
    fetchAndUpdateProductMetaInCache(productId);
  }

  uploadImages(changes.newPicturesToUpload);
};

const addImageToRemote = ({ apiMeta, productId, optionId, key }) => {
  const pictures = apiMeta.pictureMeta;
  if (key === IMAGE_UPLOAD_HELPER.VARIANT_PICTURE_UPLOAD.key) {
    return processVariantImagesAdd({ pictures, variantId: productId, optionId });
  }
  return Api.addNewPicture({ pictures, productId });
};

const changeProductPicturesInCache = async ({
  catalogueId,
  productId,
  images,
  cacheKey: basicInfoCacheKey,
  extraData
}) => {
  const basicInfoCache = CacheRequest.getCacheForKey(basicInfoCacheKey);
  const newBasicInfoCache = cloneDeep(basicInfoCache) || {};

  const cacheFormatOfPictures = {};
  const apiMeta = {
    pictureMeta: []
  };
  const newPicturesToUpload = [];
  const uuid = getUniqueDeviceId();

  for (let i = 0; i < images.length; i += 1) {
    const image = images[i];

    const pictureId = db
      .ref('products')
      .child(productId)
      .child('pictures')
      .push().key;

    const url = getImageUrlFromPictureId({ size: 'FULL', pictureId });
    let width = 0,
      height = 0;
    try {
      ({ width, height } = await getImageWidthHeight(image));
    } catch (error) {
      reportError(error);
    }

    const pictureMeta = {
      id: pictureId,
      url,
      extension: 'jpg',
      prepared: false,
      width,
      height
    };

    const apiData = {
      pictureId,
      url,
      extension: 'jpg',
      prepared: false,
      width,
      height,
      uuid
    };

    newPicturesToUpload.push({
      pictureId,
      product: image,
      prepared: false,
      productId,
      catalogueId,
      timestamp: new Date().getTime(),
      extraData
    });

    apiMeta.pictureMeta.push(apiData);
    cacheFormatOfPictures[pictureId] = pictureMeta;
  }

  //Default picture does not exist, set it from the first available value.
  if (!newBasicInfoCache.default_picture_id) {
    //Default to null as that is the default from the API
    const { pictureId = null, url = null } = apiMeta.pictureMeta[0] || {};
    newBasicInfoCache.default_picture_id = pictureId;
    newBasicInfoCache.pictureUrl = url;
  }

  newBasicInfoCache.pictures = {
    ...(newBasicInfoCache.pictures || {}),
    ...cacheFormatOfPictures
  };

  CacheRequest.setCacheForKey(basicInfoCacheKey, newBasicInfoCache);

  return {
    apiMeta,
    newPicturesToUpload
  };
};

// FIREBASE CHANGES LISTENER CALLBACK

const setLastFetchDate = ({ date, catalogueId }) => {
  PRODUCT_LAST_FETCH_TS.ts[catalogueId] = date;
  const key = PRODUCT_LAST_FETCH_TS.localstorageKey(catalogueId);
  const stringifiedDate = JSON.stringify(date);
  localStorage.setItem(key, stringifiedDate);
};

const getLastFetchDate = ({ catalogueId }) => {
  return PRODUCT_LAST_FETCH_TS.ts && PRODUCT_LAST_FETCH_TS.ts[catalogueId]
    ? PRODUCT_LAST_FETCH_TS.ts[catalogueId]
    : null;
};

const executeThrottledCallback = () => {
  PRODUCT_CHANGES_THROTTLER_ID = setTimeout(async () => {
    const storedFunction = PRODUCT_CHANGES_QUEUE.pop();
    //Execute the latest stored function
    try {
      await storedFunction();
    } catch (error) {
      // Handle error
    }
    PRODUCT_CHANGES_THROTTLER_ID = null;

    //While the request was being processed, another operation was queued
    //since the notification recieved while processing the function could be the last
    //one, queue the update again. If more requests arrive, the throttler will pick the
    //latest one
    if (PRODUCT_CHANGES_QUEUE.length === 1) {
      executeThrottledCallback();
    }
  }, 1500);
};

const handleProductChangeListener = ({ timestamp, catalogueId }) => {
  const localTs = getLastFetchDate({ catalogueId });
  if (!localTs) {
    setLastFetchDate({ date: timestamp, catalogueId });
    return;
  }

  if (localTs < timestamp) {
    PRODUCT_CHANGES_QUEUE.pop();
    PRODUCT_CHANGES_QUEUE.push(async () => {
      await onProductScreenChange({ newTimestamp: timestamp, catalogueId, prevTimestamp: localTs });
    });

    if (!PRODUCT_CHANGES_THROTTLER_ID) {
      executeThrottledCallback();
    }
  }
};

const onProductScreenChange = async ({ newTimestamp, catalogueId, prevTimestamp }) => {
  try {
    const { changes } = await Api.productsListScreenChanges(prevTimestamp, catalogueId);
    setLastFetchDate({ date: newTimestamp, catalogueId });
    let shouldChangeList = true;
    if (changes && changes.inserted && Object.keys(changes.inserted).length) {
      const products = Object.values(changes.inserted);
      shouldChangeList = false;
      addProductsFirebaseCallback({ products, catalogueId, productsList: changes.productList });
    }

    if (changes && changes.removed && Object.keys(changes.removed).length) {
      try {
        const deletedProducts = Object.keys(changes.removed);
        shouldChangeList = false;
        deleteProducts(deletedProducts, catalogueId, {
          showLoader: false,
          makeRemoteChanges: false,
          changeCatalogueRow: false
        });
      } catch (err) {
        Sentry.captureException(err);
      }
    }

    if (changes && shouldChangeList && changes.productList) {
      changeProductsList({ productsList: changes.productList }, { catalogueId });
    }

    if (changes && changes.updated && Object.keys(changes.updated).length) {
      const products = Object.values(changes.updated || {}) || [];
      updateExistingProducts({ products, catalogueId });
    }

    if (changes && changes.tags && Object.keys(changes.tags).length) {
      Tags.onChangeCatalogueTagsFromRemote({ tags: changes.tags, catalogueId });
    }

    if (changes && (changes.companySlug || changes.catalogueSlug || changes.randomSlug)) {
      updateSlug({
        companySlug: changes.companySlug,
        catalogueSlug: changes.catalogueSlug,
        randomSlug: changes.randomSlug,
        catalogueId
      });
    }
  } catch (error) {
    Sentry.captureException(error);
  }
};

const addProductsFirebaseCallback = async ({ products, catalogueId, productsList }) => {
  try {
    const changes = addProductsInLocal({ products, catalogueId, productsList });

    await addProductsInNative({
      products: changes.products,
      catalogueId,
      productsList: changes.productsList,
      catalogueMeta: changes.catalogueMeta
    });
  } catch (error) {
    Sentry.captureException(error);
  }
};

const addProductsInLocal = ({ products, catalogueId, productsList }) => {
  const productListSharedKey = connector.PRODUCT_LIST_META.cacheKey;
  const productMetaSharedKey = connector.PRODUCT_ROW_META.cacheKey;

  const productListCacheKey = `${productListSharedKey}${catalogueId}`;
  const catalogueRowMetaCacheKey = `${connector.CATALOGUE_ROW_META.cacheKey}${catalogueId}`;
  const productsForNative = [];

  (products || []).forEach(product => {
    const { productId, pictureId, isPrepared, defaultImageErrored } = product;
    // If a newly added product is updated with the error data before it's real-time update
    // has been processed, then the new product that is being uploaded will arrive in the
    // inserted list. Hence process all those images that are eligible
    processImageUpload({
      pictureId,
      isPrepared,
      defaultImageErrored,
      catalogueId
    });

    const key = `${productMetaSharedKey}${productId}`;
    const cache = CacheRequest.getCacheForKey(key) || {};

    let newCache = cloneDeep(cache);
    newCache = {
      ...newCache,
      ...product
    };

    productsForNative.push(newCache);
    CacheRequest.setCacheForKey(key, newCache);
  });

  if (!Array.isArray(productsList)) {
    return {
      productsList: [],
      products: productsForNative,
      catalogueMeta: CacheRequest.getCacheForKey(catalogueRowMetaCacheKey)
    };
  }

  const top4Products = productsList.slice(0, 4);
  const top4Picture = [];
  top4Products.forEach(({ productId }) => {
    const cache = CacheRequest.getCacheForKey(`${productMetaSharedKey}${productId}`) || {};
    const { pictureId, pictureUrl, isPrepared, defaultImageErrored } = cache;
    if (!pictureId) {
      return;
    }

    //Pick the prepared status of the response rather than always setting true
    top4Picture.push({
      pictureId,
      url: pictureUrl,
      prepared: isPrepared,
      error: defaultImageErrored
    });
  });

  CacheRequest.setCacheForKey(productListCacheKey, { productsList });
  const oldCatalogueRowMeta = CacheRequest.getCacheForKey(catalogueRowMetaCacheKey);

  const newCatalogueRowMeta = {
    ...oldCatalogueRowMeta,
    productCount: productsList.length,
    picturesMeta: top4Picture
  };
  CacheRequest.setCacheForKey(catalogueRowMetaCacheKey, newCatalogueRowMeta);

  return {
    productsList,
    products: productsForNative,
    catalogueMeta: newCatalogueRowMeta
  };
};

// Updating top4 products should also change catalogue row
const updateExistingProducts = async ({ products = [], catalogueId }) => {
  const changes = updateExistingProductsInLocal(products, catalogueId);
  await updateExistingProductsInNative(changes.nativeCacheChanges);
};

const updateExistingProductsInLocal = (products = [], catalogueId) => {
  const nativeCacheChanges = [];
  const sharedCacheKey = connector.PRODUCT_ROW_META.cacheKey;
  let smallestUpdatePosition = 0;

  products.forEach(product => {
    const { pictureId, productId, isPrepared, defaultImageErrored } = product;

    // Those images that were being uploaded locally will be processed
    // the others will be ignored
    processImageUpload({
      pictureId,
      isPrepared,
      defaultImageErrored,
      catalogueId
    });

    const key = `${sharedCacheKey}${productId}`;
    const cache = CacheRequest.getCacheForKey(key) || {};

    const oldCache = cloneDeep(cache);
    const newCache = {
      ...oldCache,
      ...product
    };
    nativeCacheChanges.push(newCache);
    CacheRequest.setCacheForKey(key, newCache);

    const productListObject = (PRODUCT_POSITION_MAP[catalogueId] || {})[productId];
    const productPosition = (productListObject || {}).position;
    smallestUpdatePosition =
      productPosition < smallestUpdatePosition ? productPosition : smallestUpdatePosition;
  });

  return {
    nativeCacheChanges
  };
};

const updateSlug = ({ companySlug, catalogueSlug, randomSlug, catalogueId }) => {
  const key = `${connector.CATALOGUE_LINK.cacheKey}${catalogueId}`;
  const linkMeta = CacheRequest.getCacheForKey(key);

  const updates = {};

  if (companySlug) {
    updates.companySlug = companySlug;
  }

  if (catalogueSlug) {
    updates.catalogueSlug = catalogueSlug;
  }

  if (randomSlug) {
    updates.randomSlug = randomSlug;
  }

  const newCache = {
    ...linkMeta,
    ...updates
  };

  CacheRequest.setCacheForKey(key, newCache);
};

const setProductStockCount = ({ stock, productIds }) => {
  const catalogueId = getActiveCatalogueId();
  let count = 0;

  const nativeProductRowChanges = [],
    productIdSearchMap = {};
  productIds.forEach(productId => {
    const key = `${connector.PRODUCT_ROW_META.cacheKey}${productId}`;
    const cache = CacheRequest.getCacheForKey(key) || {};
    const newCache = {
      ...cache,
      stock
    };
    productIdSearchMap[productId] = true;
    nativeProductRowChanges.push(newCache);
    CacheRequest.setCacheForKey(key, newCache);
  });

  const listKey = `${connector.PRODUCT_LIST_META.cacheKey}${catalogueId}`;
  const { productsList } = CacheRequest.getCacheForKey(listKey);

  for (let i = 0; i < productsList.length; i += 1) {
    const productId = productsList[i].productId;
    if (!productIdSearchMap[productId]) {
      continue;
    }
    productsList[i] = { ...productsList[i], stock };
    count += 1;

    if (count === productIds.length) {
      break;
    }
  }

  CacheRequest.setCacheForKey(listKey, { productsList });

  setProductPositionMap({ productList: productsList, catalogueId });

  updateExistingProductsInNative(nativeProductRowChanges);
  changeProductsListInNative({ productsList, catalogueId });
};

const getProductPositionMapForCatalogue = ({ catalogueId }) =>
  PRODUCT_POSITION_MAP[catalogueId] || {};

const setProductPositionMap = ({ productList, catalogueId }) => {
  if (!PRODUCT_POSITION_MAP[catalogueId]) {
    PRODUCT_POSITION_MAP[catalogueId] = {};
  }

  if (!Array.isArray(productList)) {
    return;
  }

  productList.forEach(row => {
    PRODUCT_POSITION_MAP[catalogueId][row.productId] = row;
  });
};

const clearProductPositionMapForCatalogue = ({ catalogueId }) => {
  delete PRODUCT_POSITION_MAP[catalogueId];
};

const getProductRows = ({ productIds, catalogueId }) => {
  return productIds.map(id => PRODUCT_POSITION_MAP[catalogueId][id]).filter(row => !!row);
};

const deleteDiscountFromProducts = async ({ productIds }) => {
  const loaderKey = `deleteDiscountFromProducts${Date.now()}`;
  toggleGlobalLoader(loaderKey, true);

  const sharedKey = connector.PRODUCT_ROW_META.cacheKey;
  const productNativeChange = [];

  productIds.forEach(id => {
    const key = `${sharedKey}${id}`;
    const cache = CacheRequest.getCacheForKey(key);
    const newCache = { ...cache };
    delete newCache.discount;
    productNativeChange.push(newCache);
    CacheRequest.setCacheForKey(key, newCache);
  });

  // If required convert api call to accept multiple products ids
  await Promise.all([
    updateExistingProductsInNative(productNativeChange),
    Api.updateProduct({ productId: productIds[0], updates: { discount: null } })
  ]);
  toggleGlobalLoader(loaderKey, false);
};

// Mixpanel Helpers
const getProductPropsForMixpanel = ({ productId }) => {
  const props = {};

  const key = `${connector.PRODUCT_ROW_META.cacheKey}${productId}`;
  const cache = CacheRequest.getCacheForKey(key);
  const currencyCode = getCompanyCurrencyCode();

  if (currencyCode) {
    props.product_currency = currencyCode;
  }

  props.product_name = cache.name;
  props.product_discount = cache.discount;
  props.product_description = cache.description;

  props.product_pictureId = cache.pictureId;
  props.product_prepared = cache.isPrepared;
  props.product_id = cache.productId;

  return props;
};

const updateProductsStock = async ({ stockCount, productId }) => {
  const loaderKey = `remoteSaveInventory${Date.now()}`;
  toggleGlobalLoader(loaderKey, true);

  const stock = Number(stockCount);

  try {
    const catalogueId = getActiveCatalogueId();

    setProductStockCount({ stock, productIds: [productId] });
    await Api.setStockCount({ stockCount: stock, productId });
    CatalogueLib.getCatalogueTags(catalogueId);

    toggleGlobalLoader(loaderKey, false);
  } catch (error) {
    toggleGlobalLoader(loaderKey, false);
    Sentry.captureException(error);
  }
};

const cleanupAllProductData = () => {
  PRODUCT_CHANGES_THROTTLER_ID = null;
  PRODUCT_CHANGES_QUEUE = [];
  PRODUCT_POSITION_MAP = {};

  PRODUCT_META_REQUEST_SEND = {};
  UPLOAD_IMAGE_MODAL.meta = {};
  ACTIVE_PRODUCT_ID_META.productId = null;

  clearProductDataFromNative();
};

const uploadSkuExcel = async ({ file }) => {
  const loaderId = `uploadSkuExcel_${Math.random()}`;
  toggleGlobalLoader(loaderId, true);

  const result = await parseFileToCsv(file);
  let headers = result[0];
  headers = headers.map(row => row.toLowerCase());
  const excelData = result.slice(1);

  const skuIndex = headers.indexOf('sku');
  const stockCountIndex = headers.indexOf('inventory');

  const meta = [];

  excelData.forEach(row => {
    const skuMeta = {
      sku: null,
      data: {}
    };

    const sku = row[skuIndex];
    const stockCount = row[stockCountIndex];

    if (
      !sku ||
      typeof stockCount === 'undefined' ||
      Number.isNaN(Number(stockCount)) ||
      Number(stockCount) > Number.MAX_SAFE_INTEGER
    ) {
      return;
    }

    skuMeta.sku = sku;
    skuMeta.data.stockCount = Number(stockCount);

    skuMeta.data = Number.isSafeInteger(skuMeta.data) ? skuMeta.data : Number.MAX_SAFE_INTEGER;

    meta.push(skuMeta);
  });

  const { t } = getI18N();

  await Api.skuExcelUpload({ meta });
  toastr.success(t('product_inventory_successfully_updated'));
  toggleGlobalLoader(loaderId, false);
};

const getPictures = ({ pictures, defaultPictureId }) => {
  if (!Array.isArray(pictures) || pictures.length < 2) {
    return pictures;
  }

  if (!defaultPictureId) {
    const baiscInfoFromCache = getBasicInfoFromCache({ productId: getActiveProductId() });
    defaultPictureId = baiscInfoFromCache && baiscInfoFromCache.default_picture_id;
  }

  const positionedPictures = getSotedPicturesByPosition({ pictures });

  if (defaultPictureId) {
    const pos = pictures.findIndex(picture => picture.id === defaultPictureId);

    if (pos !== -1) {
      positionedPictures.splice(0, 0, positionedPictures.splice(pos, 1)[0]);
    }
  }

  if (positionedPictures.length > 1 && !positionedPictures[0].videoUrl) {
    const pos = pictures.findIndex(picture => picture.hasOwnProperty('videoUrl'));

    if (pos !== -1) {
      positionedPictures.splice(0, 0, positionedPictures.splice(pos, 1)[0]);
    }
  }
  return positionedPictures;
};

registerCleanupHandler(cleanupAllProductData);

export {
  OPERATION_STATUS,
  PRODUCT_ROW_TYPES,
  ACTIVE_PRODUCT_ID_META,
  UPLOAD_IMAGE_MODAL,
  UPLOAD_IMAGE_FORMAT,
  PRODUCT_POSITION_MAP,
  UPDATE_PICTURES_POSITIONS,
  DELETED_PICTURE_ID,
  TOGGLE_REARRANGING_PICTURES,
  attachProductListListener,
  removeProductListListener,
  getProductList,
  attachProductMetaListener,
  removeProductMetaListener,
  getProductMeta,
  previewCatalogue,
  uploadFromFilePicker,
  uploadFromFolderPicker,
  fetchAndUpdateProductMetaInCache,
  getProductMetaFromCache,
  setProductMetaInCache,
  deleteProducts,
  createProductsFromCSV,
  setActiveProductId,
  getActiveProductId,
  resetActiveProductId,
  isProductSelected,
  reorderProduct,
  reorderProductInCache,
  handleProcessedRawFiles,
  openImageUploadModal,
  closeImageUploadModal,
  uploadPicturesToProduct,
  getPictures,
  // Firebase listener handler
  setLastFetchDate,
  getLastFetchDate,
  handleProductChangeListener,
  setProductStockCount,
  getProductPositionMapForCatalogue,
  setProductPositionMap,
  clearProductPositionMapForCatalogue,
  getProductRows,
  deleteDiscountFromProducts,
  getProductPropsForMixpanel,
  updateProductsStock,
  uploadSkuExcel,
  getProductMetaFromCSV
};
