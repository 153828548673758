import { COMPANY_LOCAL_STORAGE_KEYS } from 'qs-data-manager/Company';
import { CATALOGUE_LANGUAGUES, APP_LANGUAGUES, CUSTOM_SHARE_TEXT_APPEND } from './constants';

export const extractCompanyLanguage = companySettingsData => {
  if (companySettingsData) {
    return CATALOGUE_LANGUAGUES.get(companySettingsData.i18n || 'en_US');
  }
  return null;
};

export const extractAppLanguage = () => {
  return APP_LANGUAGUES.get(
    localStorage.getItem(COMPANY_LOCAL_STORAGE_KEYS.APP_LANGUAGE) || 'en_US'
  );
};

export const extractCompanyPaymentsSettings = companySettingsData => {
  if (companySettingsData) {
    return companySettingsData.acceptPaymentsOnOrderConfirmation || false;
  }
  return null;
};

export const extractCompanyCataloguePdfSettings = companySettingsData => {
  if (companySettingsData) {
    return companySettingsData.pdfDownloadFromWeb || false;
  }
  return null;
};

export const extractCompanyCatalogueProductSettings = companySettingsData => {
  if (companySettingsData) {
    return companySettingsData.enableProductDownload || false;
  }
  return null;
};

export const extractCompanyCatalogueProductCountFromViewSettings = companySettingsData => {
  if (companySettingsData) {
    return companySettingsData.removeProductCountFromCatalogueView || false;
  }
  return null;
};

export const extractCompanyCatalogueProductCountFromTileSettings = companySettingsData => {
  if (companySettingsData) {
    return companySettingsData.removeProductCountFromCatalogueTile || false;
  }
  return null;
};

export const extractCompanyCheckoutSettings = companySettingsData => {
  if (companySettingsData) {
    return companySettingsData.captureShippingAddress || false;
  }
  return null;
};

export const extractCompanyCheckoutMessageSettings = companySettingsData => {
  if (companySettingsData) {
    return companySettingsData.orderConfirmationCustomMessage || '';
  }
  return null;
};

export const extractCompanyShareTextProperty = shareTextTemplate => {
  if (shareTextTemplate) {
    const indexOfCustomText = shareTextTemplate.shareTemplate.indexOf(CUSTOM_SHARE_TEXT_APPEND);
    return shareTextTemplate.shareTemplate.substring(0, indexOfCustomText);
  }

  return '';
};

export const extractMoaSetting = data => {
  if (!data) {
    return;
  }
  let { minOrderAmount } = data;
  if (!minOrderAmount) {
    minOrderAmount = 0;
  }

  const moaNum = Number(minOrderAmount).toFixed(2);
  return { moaNum, moaString: moaNum.toString() };
};

export const extractBulkOrderViewSettings = companySettingsData => {
  if (companySettingsData && companySettingsData.bulkOrderingView) {
    return companySettingsData.bulkOrderingView || {};
  }
  return null;
};

export const extractCompanyOrderReceiptSettings = companySettingsData => {
  if (companySettingsData) {
    return companySettingsData.receiveOrderOnWhatsapp || false;
  }
  return null;
};
