import React, { PureComponent } from 'react';
import Switch from 'react-switch';
import Modal from 'react-responsive-modal';
import PropTypes from 'prop-types';
import {
  getInventoryFromCache,
  attachInventoryListener,
  removeInventoryListener,
  getInventory,
  getInventoryCatalogueDefaultsFromCache
} from 'qs-data-manager/ProductDetails';
import CacheListenerCallback from 'qs-helpers/CacheListenerCallback';
import Loader from 'qs-components/Common/Loader';
import BulkInventory from '../../BulkInventory/BulkInventory';
import { getCompanyIdFromCache } from 'qs-data-manager/Company';
import Mixpanel from 'qs-data-manager/Mixpanel';
import { FROM_VARIANTS } from 'qs-helpers/Variants/constants';
import { onInventoryChange, INVENTORY_META_IDS } from 'qs-helpers/Inventory';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

class Inventory extends PureComponent {
  static propTypes = {
    activeProductId: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    activeProductIds: PropTypes.any,
    stockCount: PropTypes.number,
    trackInventory: PropTypes.bool,
    autoReduce: PropTypes.bool,
    stockVisible: PropTypes.object,
    orderOnOOS: PropTypes.object
  };

  constructor(props) {
    super(props);
    const { activeProductId, isBulkEditing } = this.props;
    this.companyId = getCompanyIdFromCache();

    const newState = this.readDataFromCache({ activeProductId, isBulkEditing });
    this.state = newState;
  }

  componentDidMount() {
    const { activeProductId, isBulkEditing, from } = this.props;
    if (!isBulkEditing && from !== FROM_VARIANTS) {
      attachInventoryListener({ listener: this.inventoryListener, productId: activeProductId });
      getInventory({ productId: activeProductId });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.activeProductId !== this.props.activeProductId && !nextProps.isBulkEditing) {
      // Refer the old props to remove the listener if it was added for the earlier entity
      if (!this.props.isBulkEditing && this.props.from !== FROM_VARIANTS) {
        removeInventoryListener({
          listener: this.inventoryListener,
          productId: this.props.activeProductId
        });
      }

      // No need to listen to any updates if the view is the variant one
      if (nextProps.from !== FROM_VARIANTS) {
        attachInventoryListener({
          listener: this.inventoryListener,
          productId: nextProps.activeProductId
        });
        getInventory({ productId: nextProps.activeProductId });
      }

      const updatedState = this.readDataFromCache({
        activeProductId: nextProps.activeProductId,
        isBulkEditing: nextProps.isBulkEditing
      });
      this.setState(updatedState);
    }
  }

  componentWillUnmount() {
    const { activeProductId, isBulkEditing, from } = this.props;
    if (!isBulkEditing || from !== FROM_VARIANTS) {
      removeInventoryListener({ listener: this.inventoryListener, productId: activeProductId });
    }
  }

  readDataFromCache({ activeProductId, isBulkEditing }) {
    const {
      stockCount,
      trackInventory,
      autoReduce,
      showOutOfStockProduct,
      stockManagedFrom,
      allowOrderOutOfStock,
      orderOnOOSControlledFrom,
      isSet,
      setQuantity,
      availableInCache
    } = getInventoryFromCache({
      productId: activeProductId,
      isBulkEditing: isBulkEditing
    });

    return {
      stockCount,
      trackInventory,
      autoReduce,
      showOutOfStockProduct,
      stockManagedFrom,
      allowOrderOutOfStock,
      orderOnOOSControlledFrom,
      isSet,
      setQuantity,
      error: false,
      loading: !availableInCache,
      refreshing: false
    };
  }

  inventoryListener = (error, payload) => {
    const { err, loading, refreshing, data } = CacheListenerCallback(error, payload);
    if (err) {
      this.setState({
        error: err,
        loading,
        refreshing
      });
      return;
    }

    if (loading) {
      this.setState({
        loading,
        refreshing
      });
      return;
    }

    let stockManagedFrom = 'PRODUCT',
      showOutOfStockProduct = data.showOutOfStockProduct;
    if (typeof data.showOutOfStockProduct !== 'boolean') {
      stockManagedFrom = 'CATALOGUE';
      showOutOfStockProduct = data.catalogueInventory.showOutOfStockProduct;
    }

    let orderOnOOSControlledFrom = 'PRODUCT',
      allowOrderOutOfStock = data.allowOrdersOnOutOfStock;
    if (typeof data.allowOrdersOnOutOfStock !== 'boolean') {
      orderOnOOSControlledFrom = 'CATALOGUE';
      allowOrderOutOfStock = data.catalogueInventory.allowOrdersOnOutOfStock;
    }

    let isSet = false,
      setQuantity = null;
    if (data.setDetails) {
      ({ isSet } = data.setDetails);
      ({ setQuantity } = data.setDetails);
    }

    this.setState({
      trackInventory: data.trackInventory,
      stockCount: typeof data.count === 'number' ? data.count : 1,
      autoReduce: data.autoReduceInventory === 'VISITOR',
      showOutOfStockProduct,
      stockManagedFrom,
      allowOrderOutOfStock,
      orderOnOOSControlledFrom,
      isSet,
      setQuantity,
      error: err,
      loading,
      refreshing
    });
  };

  changeStockAvailability = stockCount => {
    const { activeProductId } = this.props;

    this.setState({ stockCount });

    onInventoryChange(
      INVENTORY_META_IDS.STOCK_COUNT_CHANGE,
      {
        stockCount,
        productId: activeProductId
      },
      { showLoader: false, handleError: false, from: this.props.from }
    );

    Mixpanel.sendEvent({
      eventName: 'manage_inventory',
      props: {
        status: !!stockCount ? 'IN_STOCK' : 'OUT_OF_STOCK'
      }
    });
  };

  reduceInventory = () => {
    const { stockCount } = this.state;

    const currentStockCount = Number(stockCount);
    const newStockCount = currentStockCount <= 2 ? 1 : currentStockCount - 1;

    this.changeStockAvailability(newStockCount);
  };

  increaseInventory = () => {
    const { stockCount } = this.state;

    const newStockCount = Number(stockCount) + 1;
    this.setState({ stockCount: newStockCount });

    this.changeStockAvailability(newStockCount);
  };

  onTrackInventoryChange = async trackInventory => {
    const { activeProductId } = this.props;

    this.setState({ trackInventory });

    await onInventoryChange(
      INVENTORY_META_IDS.TRACK_INVENTORY,
      {
        value: trackInventory,
        productId: activeProductId
      },
      {
        from: this.props.from
      }
    );
  };

  changeAutoReduceQuantity = async switchValue => {
    const { activeProductId, from } = this.props;

    const value = switchValue ? 'VISITOR' : 'COMPANY';
    this.setState({ autoReduce: switchValue });

    await onInventoryChange(
      INVENTORY_META_IDS.AUTO_REDUCE_INVENTORY,
      {
        value: value,
        productId: activeProductId
      },
      { from }
    );
  };

  onStockVisibilityChange = async switchValue => {
    const { activeProductId, from } = this.props;
    const updates = {};
    updates.showOutOfStockProduct = switchValue;

    if (this.state.stockManagedFrom === 'CATALOGUE' && typeof switchValue === 'boolean') {
      updates.stockManagedFrom = 'PRODUCT';
    } else if (switchValue === null) {
      updates.showResetModal = false;
      updates.stockManagedFrom = 'CATALOGUE';
      const { showOutOfStockProduct } = getInventoryCatalogueDefaultsFromCache(activeProductId);
      updates.showOutOfStockProduct = showOutOfStockProduct;
    }

    this.setState(updates);

    await onInventoryChange(
      INVENTORY_META_IDS.STOCK_VISIBILITY,
      {
        productId: activeProductId,
        value: switchValue
      },
      { from }
    );

    Mixpanel.sendEvent({
      eventName: 'out_of_stock_visibility_setting_changed',
      props: {
        status: !!switchValue
      }
    });
  };

  orderOnOutStockChange = switchValue => {
    const { activeProductId, from } = this.props;
    const updates = {};
    updates.allowOrderOutOfStock = switchValue;

    if (this.state.orderOnOOSControlledFrom === 'CATALOGUE' && typeof switchValue === 'boolean') {
      updates.orderOnOOSControlledFrom = 'PRODUCT';
    } else if (switchValue === null) {
      updates.showOrderResetModal = false;
      updates.orderOnOOSControlledFrom = 'CATALOGUE';
      const { allowOrderOutOfStock } = getInventoryCatalogueDefaultsFromCache(activeProductId);
      updates.allowOrderOutOfStock = allowOrderOutOfStock;
    }

    this.setState(updates);

    onInventoryChange(
      INVENTORY_META_IDS.ORDER_ON_OOS,
      {
        productId: activeProductId,
        value: switchValue
      },
      { from }
    );
  };

  handleResetClick = resetHandler => event => {
    event.preventDefault();
    event.stopPropagation();
    if (typeof resetHandler === 'function') {
      resetHandler(true);
    }
  };

  renderManagedFrom = key => {
    const { stockManagedFrom, orderOnOOSControlledFrom } = this.state;
    let managedFrom = '';
    let onResetClick = null;
    const { t } = getI18N();

    if (key === INVENTORY_META_IDS.STOCK_VISIBILITY) {
      managedFrom = stockManagedFrom;
      onResetClick = this.handleResetClick(this.toggleResetConfirmationModal);
    } else if (key === INVENTORY_META_IDS.ORDER_ON_OOS) {
      managedFrom = orderOnOOSControlledFrom;
      onResetClick = this.handleResetClick(this.toggleOrderResetModal);
    }

    if (managedFrom === 'CATALOGUE') {
      // Attach empty click handler to prevent click on this text from toggling the values
      return (
        <div className="managedByCatalogue" onClick={this.handleResetClick()}>
          {t('currently_managed_by_catalogue_settings')}
        </div>
      );
    }

    if (managedFrom === 'PRODUCT') {
      return (
        <div onClick={onResetClick} className="resetCatalogue">
          {t('reset_to_catalogue_default')}
        </div>
      );
    }

    return null;
  };

  toggleResetConfirmationModal = value => {
    this.setState({ showResetModal: value });
  };

  renderResetModal = () => {
    const { t } = getI18N();

    return (
      <Modal
        open={this.state.showResetModal}
        onClose={() => {
          this.toggleResetConfirmationModal(false);
        }}
        center
        styles={{ modal: { backgroundColor: 'white', borderRadius: 10 } }}
        showCloseIcon={false}
      >
        <div className="resetInventoryModalContainer">
          <div className="header">{t('reset_settings')}</div>
          <div className="title">{t('are_you_sure_you_want_to_reset_this_setting')}</div>
          <div className="buttonCotnainer">
            <div onClick={() => this.toggleResetConfirmationModal(false)} className="cancelButton">
              {t('cancel')}
            </div>
            <div onClick={() => this.onStockVisibilityChange(null)} className="acceptButton">
              {t('yes')}
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  toggleOrderResetModal = value => {
    this.setState({ showOrderResetModal: value });
  };

  // TODO: this can be merged with the above modal code

  renderOrderResetModal = () => {
    const { t } = getI18N();

    return (
      <Modal
        open={this.state.showOrderResetModal}
        onClose={() => {
          this.toggleOrderResetModal(false);
        }}
        center
        styles={{ modal: { backgroundColor: 'white', borderRadius: 10 } }}
        showCloseIcon={false}
      >
        <div className="resetInventoryModalContainer">
          <div className="header">{t('reset_settings')}</div>
          <div className="title">{t('are_you_sure_you_want_to_reset_this_setting')}</div>
          <div className="buttonCotnainer">
            <div onClick={() => this.toggleOrderResetModal(false)} className="cancelButton">
              {t('cancel')}
            </div>
            <div onClick={() => this.orderOnOutStockChange(null)} className="acceptButton">
              {t('yes')}
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  renderTackQuantity = () => {
    const { loading, stockCount, trackInventory, isSet, setQuantity } = this.state;
    const { t } = getI18N();

    if (loading) {
      return (
        <div className={'trackQuantityLoader'}>
          <Loader size={'small'} />
        </div>
      );
    }

    if (trackInventory) {
      return (
        <>
          <div className="totalQuantityContainer">
            <div className="insertQunatityContainer">
              <div onClick={this.reduceInventory} className="minusContainer noselect">
                -
              </div>
              <div className="quantityInputContainer">
                <input
                  type="number"
                  value={stockCount}
                  className="quantityContainer"
                  onChange={e => this.changeStockAvailability(e.target.value)}
                />
              </div>
              <div onClick={this.increaseInventory} className="plusContainer noselect">
                +
              </div>
            </div>
            {isSet && <div className="totalStockCount">{stockCount * setQuantity}</div>}
          </div>
          <div onClick={() => this.onTrackInventoryChange(false)} className="dontTrackQuantity">
            {t('do_not_track_quantity')}
          </div>
        </>
      );
    }

    return (
      <div onClick={() => this.onTrackInventoryChange(true)} className="trackInventoryButton">
        {t('track_quantity_available')}
      </div>
    );
  };

  renderSwitchWithText({
    header,
    onRowClick,
    checkedValue,
    primaryDescription,
    secondaryDescription,
    showLoader
  }) {
    return (
      <div
        className={`${
          this.props.from !== FROM_VARIANTS ? 'noVariantsAvailableTab' : ''
        } sectionSeparator`}
      >
        <div className="inventoryHeader">{header}</div>
        <div onClick={() => onRowClick(!checkedValue)} className="switchWithTextContainer">
          <div className="descriptiveTextContainer">
            <div className="primaryDescription">{primaryDescription}</div>
            {secondaryDescription}
          </div>
          <div>
            {showLoader ? (
              <Loader size={'small'} />
            ) : (
              <Switch
                checked={checkedValue}
                /*
                  Added dummy change to ensure that required dependancy works and
                  the rowClick method is not called twice
                */
                onChange={() => {}}
                onColor="#515863"
                offColor="#515863"
                onHandleColor="#4DA47A"
                offHandleColor="#929faf"
                handleDiameter={25}
                uncheckedIcon={false}
                checkedIcon={false}
                height={17}
                width={40}
              />
            )}
          </div>
        </div>
      </div>
    );
  }

  singleProductEditing = () => {
    const {
      stockCount,
      trackInventory,
      autoReduce,
      showOutOfStockProduct,
      showResetModal,
      allowOrderOutOfStock,
      showOrderResetModal,
      isSet,
      loading
    } = this.state;

    const { t } = getI18N();

    return (
      <div className="mainInventoryContainer">
        <div
          className={`${
            this.props.from !== FROM_VARIANTS ? 'noVariantsAvailableTab' : ''
          } stockAvailabilityContainer`}
        >
          <div className="inventoryHeader">{t('stock_availability')}</div>

          <div className={'options'}>
            {loading ? (
              <div className={'stockLoader'}>
                <Loader size={'small'} />
              </div>
            ) : (
              <>
                <div
                  onClick={() => this.changeStockAvailability(0)}
                  className={
                    !!stockCount ? 'outOfStockContainer' : 'outOfStockContainer OOSselected'
                  }
                >
                  {t('out_of_stock')}
                </div>

                <div
                  onClick={() => this.changeStockAvailability(1)}
                  className={
                    !!stockCount ? 'availableContainer availableSelected' : 'availableContainer'
                  }
                >
                  {t('available')}
                </div>
              </>
            )}
          </div>
        </div>

        {!!stockCount && (
          <div
            className={`${
              this.props.from !== FROM_VARIANTS ? 'noVariantsAvailableTab' : ''
            } availableQuantityContainer`}
          >
            <div className="availableQuantityHeader inventoryHeader">
              <div className="quantityHeader">
                {isSet ? t('available_set_quantity') : t('available_quantity')}
              </div>
              {isSet && trackInventory && (
                <div className="quantityHeader">{t('total_quantity')}</div>
              )}
            </div>
            {this.renderTackQuantity()}
          </div>
        )}

        {!!stockCount &&
          !!trackInventory &&
          this.renderSwitchWithText({
            header: t('auto_reduce_quantity'),
            checkedValue: autoReduce,
            onRowClick: this.changeAutoReduceQuantity,
            primaryDescription: t('auto_reduce_the_inventory_when_visitor_confirms_the_order')
          })}

        {this.renderSwitchWithText({
          header: t('out_of_stock_visibility'),
          checkedValue: showOutOfStockProduct,
          onRowClick: this.onStockVisibilityChange,
          primaryDescription: t('show_out_of_stock_product_to_catalogue_visitor'),
          showLoader: !!loading,
          secondaryDescription: this.renderManagedFrom(INVENTORY_META_IDS.STOCK_VISIBILITY)
        })}

        {!!showOutOfStockProduct &&
          this.renderSwitchWithText({
            header: t('allow_order_on_out_of_stock'),
            checkedValue: allowOrderOutOfStock,
            onRowClick: this.orderOnOutStockChange,
            primaryDescription: t('allow_visitors_to_place_on_your_out_of_stock_products'),
            showLoader: !!loading,
            secondaryDescription: this.renderManagedFrom(INVENTORY_META_IDS.ORDER_ON_OOS)
          })}

        {showResetModal && this.renderResetModal()}
        {showOrderResetModal && this.renderOrderResetModal()}
      </div>
    );
  };

  render() {
    const { activeProductIds, isBulkEditing } = this.props;

    return isBulkEditing ? (
      <BulkInventory productIds={activeProductIds} />
    ) : (
      this.singleProductEditing()
    );
  }
}

export default Inventory;
