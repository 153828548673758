import React, { Component } from 'react';
import './styles.css';
import Switch from 'react-switch';
import Loader from 'qs-components/Common/Loader';
import { ReactComponent as EditIcon } from 'qs-assets/Media/pencil.svg';
import { EDIT_ICON, CLICK_MOQ_COMPONENT, CLICK_CATALOGUE_PRIVACY_SETTINGS } from '../helper';
import { getI18N } from 'qs-services/i18N';
import MoqDialog from '../../../../../../Common/MoqDialog';

class CatalogueSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: props.value,
      loader: false
    };

    setTimeout(() => {
      this.setState({
        loader: true
      });
    }, 3000);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      checked: props.value
    });
  }

  updateStateAndNotify(value) {
    const { onSettingChanged } = this.props;
    this.setState({
      checked: value
    });
    onSettingChanged(value, this.props.id);
  }

  toggleSwitch = () => {
    let newState = !this.state.checked;
    this.updateStateAndNotify(newState);
  };

  handleRowAction = value => {
    this.toggleComponent();
    this.updateStateAndNotify(value);
  };

  toggleComponent = () => {
    const {
      toggleCustomComponent,
      toggleCataloguePrivacySettings,
      customComponentOnClick
    } = this.props;
    if (customComponentOnClick === CLICK_CATALOGUE_PRIVACY_SETTINGS) {
      toggleCataloguePrivacySettings();
      return;
    }

    toggleCustomComponent();
  };

  renderCatalogueVisibility = () => {
    const { managedBy, resetToCompanyDefault, id, resetMessage } = this.props;
    const { t } = getI18N();
    if (managedBy === 'CATALOGUE' || managedBy === 'catalogue') {
      return (
        <div
          className="inventoryManagedFrom reset"
          onClick={() =>
            resetToCompanyDefault && resetToCompanyDefault({ toggleValue: true, id, resetMessage })
          }
        >
          {t('reset_to_company_default')}
        </div>
      );
    }

    if (managedBy === 'company' || managedBy === 'COMPANY') {
      return (
        <div className="inventoryManagedFrom">{t('currently_managed_by_company_settings')}</div>
      );
    }

    return null;
  };

  getSettingDescription() {
    const { settingDescription } = this.props;
    const { checked } = this.state;
    const { t } = getI18N();
    if (typeof settingDescription === 'function') {
      const { key, value } = settingDescription(checked) || {};
      return t(value, { [key]: checked, time: 15 });
    }
    return t(settingDescription, { time: 15 });
  }

  getActionIcon() {
    const { icon, shouldShowLoader } = this.props;
    if (shouldShowLoader) {
      return (
        <div className={'loaderContainer'}>
          <Loader size={'small'} />
        </div>
      );
    }

    switch (icon) {
      case EDIT_ICON:
        return <EditIcon className="editIconContainer" />;
      default:
        return (
          <Switch
            onChange={() => {}}
            checked={this.state.checked}
            onColor="#e8e8e8"
            offColor="#e8e8e8"
            onHandleColor="#4DA47A"
            handleDiameter={20}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow={
              this.state.checked ? '0px 1px 5px #4DA47A' : '0px 1px 5px rgba(0, 0, 0, 0.6)'
            }
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            id="normal-switch"
          />
        );
    }
  }

  renderCustomComponent() {
    const { checked } = this.state;
    const { showCustomOptionModal, customComponentOnClick, settingTitle } = this.props;
    const { t } = getI18N();
    if (!showCustomOptionModal) {
      return null;
    }

    if (customComponentOnClick === CLICK_MOQ_COMPONENT) {
      return (
        <MoqDialog
          show={true}
          onClose={this.toggleComponent}
          onUpdate={this.handleRowAction}
          minOrderQuantity={checked}
          title={t(settingTitle)}
        />
      );
    }

    return null;
  }

  renderCataloguePrivacySettings = () => {
    const { toggleCataloguePrivacySettings } = this.props;
    toggleCataloguePrivacySettings();
  };

  render() {
    const { customComponentOnClick } = this.props;
    const { t } = getI18N();
    return (
      <>
        <div className="CatalogueSettingWrapper">
          <div
            className="CatalogueSettingContainer"
            onClick={customComponentOnClick ? this.toggleComponent : this.toggleSwitch}
          >
            <div className="CatalogueSettingMeta">
              <label className="CatalogueSettingTitle">{t(this.props.settingTitle)}</label>
              <div className="CatalogueSettingDescription">{t(this.getSettingDescription())}</div>
            </div>
            <div className="CatalogueSwitch">{this.getActionIcon()}</div>
          </div>
          {this.renderCatalogueVisibility()}
        </div>
        {this.renderCustomComponent()}
      </>
    );
  }
}

export default CatalogueSetting;
