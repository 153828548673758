import React, { useContext, useRef, useState } from 'react';
import {
  changeOrderStatusAndUpdateOrderProducts,
  getOrdersProductsFromCache,
  markOrderAsConfirmed
} from 'qs-data-manager/Orders/OrdersProductsOperations';
import OrderStatusButtons from '../OrderStatusButtons';
import { DialogActions, DialogContent } from '@material-ui/core';
import { ActiveOrderMeta } from '../../../../context';
import { getErrorMessage } from 'qs-helpers';
import ErrorPopup from '../../../../../Common/ErrorPopup';
import {
  canConfirmUnconfirmedOrder,
  getOrderDisplayStatus
} from 'qs-helpers/Orders/ResponseProcessor';
import { ORDER_STATUS } from 'qs-helpers/Orders/constants';
import {
  DarkThemeTitle,
  DialogDarkTheme,
  PrimaryButton,
  SecondaryButton
} from 'qs-components/Common/DarkThemeDialog';
import toastr from 'toastr';
import {
  getCachedOrderMetaData,
  handleOrderMetaCleanup,
  handleOrderMetaFetch
} from 'qs-helpers/Orders/DataQueryHelper';
import useMakeQuery from 'qs-hooks/useMakeQuery';
import OrderStatusChange from '../OrderStatusChange';
import './styles.scss';
import { getI18N } from 'qs-services/i18N';

export default ({ statusChangeProg, setStatusChangeProg }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showCantConfirm, setShowCantConfirm] = useState(false);
  const [showOrderStatusChangeDialog, setShowOrderStatusChangeDialog] = useState(false);
  const [errorData, setErrorData] = useState();
  const {
    activeOrderMeta: { selectedOrderId, currentSortKey }
  } = useContext(ActiveOrderMeta);
  const savedResolver = useRef();
  const { t } = getI18N();

  const [{ data }] = useMakeQuery({
    changeDependancy: [selectedOrderId],
    cachedDataHandler: getCachedOrderMetaData,
    actionHandler: handleOrderMetaFetch,
    cleanupHandler: handleOrderMetaCleanup
  });

  const { isFinalized, isFinalizedByCompany, orderStatus } = data;

  const allowOrderConfirmation = () => {
    // Order is not confirmed and order cannot be confirmed
    const inquiriesData = getOrdersProductsFromCache({ orderId: selectedOrderId }) || {};
    return canConfirmUnconfirmedOrder(inquiriesData.inquiries);
  };

  const onOrderStatusClick = () => {
    if (isFinalized) {
      setShowOrderStatusChangeDialog(true);
      return;
    }

    if (!allowOrderConfirmation()) {
      setShowCantConfirm(true);
      return;
    }

    setShowConfirmation(true);
  };

  const handleUpdateRequest = () =>
    new Promise((resolve, reject) => {
      if (isFinalized) {
        resolve();
        return;
      }

      if (!allowOrderConfirmation()) {
        setShowCantConfirm(true);
        reject();
        return;
      }

      setShowConfirmation(true);
      savedResolver.current = {
        resolve,
        reject
      };
    });

  const handleUpdateOrderStatus = async ({ orderStatus: updatedOrderStatus }) => {
    setStatusChangeProg(true);
    let inventoryUpdated;
    try {
      if (!isFinalized) {
        ({ inventoryUpdated } = await markOrderAsConfirmed({
          orderId: selectedOrderId,
          sortKey: currentSortKey
        }));
        updatedOrderStatus = ORDER_STATUS.ACCEPTED;
      } else {
        ({ inventoryUpdated } = await changeOrderStatusAndUpdateOrderProducts({
          orderId: selectedOrderId,
          orderStatus: updatedOrderStatus,
          sortKey: currentSortKey
        }));
      }

      let toastMessage = '';
      // Since the order can never be unconfirmed, the value of isFinalized will always be true
      const orderStatusText = getOrderDisplayStatus({
        isFinalized: true,
        orderStatus: updatedOrderStatus
      });

      // The new status is confirmed and the order was already confirmed, don't show toast message
      if (orderStatusText !== ORDER_STATUS.CONFIRMED && isFinalized) {
        toastMessage += `${t('order')} ${t(orderStatusText.toLowerCase())}.`;
      }

      if (inventoryUpdated) {
        toastMessage += t('inventory_updated');
      }

      if (toastMessage) {
        toastr.success(toastMessage.trim());
      }
    } catch (updateStatusError) {
      if (updateStatusError.reason === 'OUT_OF_STOCK') {
        setErrorData({
          title: t('updated_order_status'),
          message:
            updateStatusError.extraData.outOfStockInquiries.length > 1
              ? t('order_update_status_products_are_out_of_stock', {
                  productCount: updateStatusError.extraData.outOfStockInquiries.length
                })
              : t('order_update_status_product_is_out_of_stock'),
          subject: t('failed_to_modify_order_status_for_order_id', { selectedOrderId }),
          body: getErrorMessage(updateStatusError)
        });
      } else {
        setErrorData({
          title: t('updated_order_status'),
          message: t('something_went_wrong_while_updating_this_order_status'),
          subject: t('failed_to_modify_order_status_for_order_id', { selectedOrderId }),
          body: getErrorMessage(updateStatusError)
        });
      }
    } finally {
      setStatusChangeProg(false);
    }
  };

  const onOrderStatusChange = async ({ orderStatus: updatedOrderStatus = null } = {}) => {
    setShowOrderStatusChangeDialog(false);
    await handleUpdateOrderStatus({ orderStatus: updatedOrderStatus });
  };

  const onConfirmAction = accepted => {
    setShowConfirmation(false);

    // Called directly from the button click
    if (!savedResolver.current) {
      if (accepted) {
        onOrderStatusChange({ orderStatus: ORDER_STATUS.ACCEPTED });
      }
      return;
    }

    if (accepted) {
      savedResolver.current.resolve();
    } else {
      savedResolver.current.reject();
    }
    savedResolver.current = null;
  };

  const onCloseConfirmationModal = () => {
    onConfirmAction(false);
  };

  const onCloseCantConfirmModal = () => {
    setShowCantConfirm(false);
  };

  return (
    <>
      <div className="orderStatusActionButtonsContainer">
        <div className="changeOrderStatusContainer">
          <span className="orderStatusText">
            {t(getOrderDisplayStatus({ isFinalized, orderStatus }).toLowerCase())}
          </span>
          <button
            onClick={onOrderStatusClick}
            disabled={statusChangeProg}
            className="changeStatusButton"
          >
            {t('change_status')}
          </button>
        </div>
        <OrderStatusButtons
          onUpdateStatus={handleUpdateOrderStatus}
          statusChangeProg={statusChangeProg}
          shouldBeginUpdate={handleUpdateRequest}
          isFinalized={isFinalized}
          orderStatus={orderStatus}
        />
      </div>
      {showConfirmation && (
        <DialogDarkTheme open={true} onClose={onCloseConfirmationModal}>
          <DarkThemeTitle> {t('accept_order')}</DarkThemeTitle>
          <DialogContent className="unconfirmedOrderConfirmationDialogContent">
            <p className="dialogText">
              {t('once_you_confirm_this_order_your_customer_will_not_be')}
            </p>
            <p className="dialogText">{t('on_confirmation_inventory_will_be_updated')}</p>
            <p className="dialogText">{t('are_you_sure_you_want_to_confirm_this_order')}</p>
          </DialogContent>
          <DialogActions>
            <SecondaryButton onClick={onCloseConfirmationModal}>{t('no')}</SecondaryButton>
            <PrimaryButton color={'primary'} onClick={() => onConfirmAction(true)}>
              {t('confirm_and_accept')}
            </PrimaryButton>
          </DialogActions>
        </DialogDarkTheme>
      )}
      {showCantConfirm && (
        <DialogDarkTheme open={true} onClose={onCloseCantConfirmModal}>
          <DarkThemeTitle> {t('accept_order')}</DarkThemeTitle>
          <DialogContent className="unconfirmedOrderConfirmationDialogContent">
            <p className="dialogText">
              {t('all_inquiries_in_this_order_have_been_deleted_by_the_visitor')}
            </p>
          </DialogContent>
          <DialogActions>
            <PrimaryButton color="primary" onClick={onCloseCantConfirmModal}>
              {t('ok')}
            </PrimaryButton>
          </DialogActions>
        </DialogDarkTheme>
      )}
      {showOrderStatusChangeDialog && (
        <OrderStatusChange
          isFinalized={isFinalized}
          isFinalizedByCompany={isFinalizedByCompany}
          orderStatus={orderStatus}
          onClose={() => setShowOrderStatusChangeDialog(false)}
          onUpdate={onOrderStatusChange}
        />
      )}
      {errorData && (
        <ErrorPopup onClose={() => setErrorData()} mailProps={errorData} title={errorData.title}>
          {errorData.message}
        </ErrorPopup>
      )}
    </>
  );
};
