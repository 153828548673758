import React from 'react';

import './styles.scss';

export default () => {
  return (
    <div id={'ProductRowShimmer'} className="animate">
      <div id={'pictureUrl'} className="animate"></div>
      <div id={'productName'} className="animate"></div>
      <div id={'productDescription'} className="animate"></div>
      <div id={'productPrice'} className="animate"></div>
    </div>
  );
};
