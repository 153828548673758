import {
  PRODUCT_GRID_TILE_CLASSNAMES_PREFIX,
  PRODUCT_LIST_TILE_CLASSNAMES_PREFIX,
  PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX,
  CATALOGUE_GRID_TILE_CLASSNAMES_PREFIX
} from 'qs-components/AccountSettings/CompanySettings/HtmlPageBuilder/Constants';

export const CATALOGUE_LANGUAGUES = new Map();

CATALOGUE_LANGUAGUES.set('en_US', 'English');
CATALOGUE_LANGUAGUES.set('es_ES', 'Español');
CATALOGUE_LANGUAGUES.set('pt_PT', 'Português');
CATALOGUE_LANGUAGUES.set('hi_IN', 'हिंदी');
CATALOGUE_LANGUAGUES.set('fr_FR', 'French');
CATALOGUE_LANGUAGUES.set('id_ID', 'Indonesian');
CATALOGUE_LANGUAGUES.set('ar_EG', 'Arabic');

export const APP_LANGUAGUES = new Map();

APP_LANGUAGUES.set('en_US', 'English');
APP_LANGUAGUES.set('es_ES', 'Español');
APP_LANGUAGUES.set('pt_PT', 'Português');
APP_LANGUAGUES.set('hi_IN', 'हिंदी');

export const SETTINGS_COMPONENT_ID_MAP = {
  COMPANY_NAME: 'COMPANY_NAME',
  PLANS_BILLING: 'PLANS_BILLING',
  DOMAIN_SETTINGS: 'DOMAIN_SETTINGS',
  PAYMENT_SETTINGS: 'PAYMENT_SETTINGS',
  SHIPPING_COST_SETTINGS: 'SHIPPING_COST_SETTINGS',
  CUSTOM_THEMES_SETTINGS: 'CUSTOM_THEMES_SETTINGS',
  CUSTOM_PRODUCT_GRID_TILE: 'CUSTOM_PRODUCT_GRID_TILE',
  CUSTOM_PRODUCT_LIST_TILE: 'CUSTOM_PRODUCT_LIST_TILE',
  CUSTOM_PRODUCT_MAGAZINE_TILE: 'CUSTOM_PRODUCT_MAGAZINE_TILE',
  CUSTOM_CATALOGUE_GRID_TILE: 'CUSTOM_CATALOGUE_GRID_TILE',
  OTHER_PAGES_SETTINGS: 'OTHER_PAGES_SETTINGS',
  CUSTOM_PAGES_SETTINGS: 'CUSTOM_PAGES_SETTINGS',
  CUSTOM_FOOTER_SETTINGS: 'CUSTOM_FOOTER_SETTINGS',
  CUSTOM_FONT_SETTINGS: 'CUSTOM_FONT_SETTINGS',
  CATALOGUE_BANNER_SETTINGS: 'CATALOGUE_BANNER_SETTINGS',
  CREATE_MENU_SETTINGS: 'CREATE_MENU_SETTINGS',
  PRODUCT_OPTIONS: 'PRODUCT_OPTIONS',
  METAL_RATES_OPTIONS: 'METAL_RATES_OPTIONS',
  CUSTOM_FIELDS: 'CUSTOM_FIELDS',
  WEBHOOKS_SETTINGS: 'WEBHOOKS_SETTINGS',
  JEWELLERY_PRICE_DISPLAY: 'JEWELLERY_PRICE_DISPLAY',
  TAXES_SETTINGS: 'TAXES_SETTINGS',
  INVENTORY_SETTINGS: 'INVENTORY_SETTINGS',
  LANGUAGE_SETTINGS: 'LANGUAGE_SETTINGS',
  CATALOGUE_SETTINGS: 'CATALOGUE_SETTINGS',
  CHECKOUT_SETTINGS: 'CHECKOUT_SETTINGS',
  CUSTOM_CHECKOUT_FORM_SETTINGS: 'CUSTOM_CHECKOUT_FORM_SETTINGS',
  PRODUCT_TYPE_SETTINGS: 'PRODUCT_TYPE_SETTINGS',
  MANAGE_API_KEY: 'MANAGE_API_KEY',
  PRIVACY_SETTINGS: 'PRIVACY_SETTINGS',
  CATALOGUE_PRIVACY_SETTINGS: 'CATALOGUE_PRIVACY_SETTINGS',
  COUPON_PAGE: 'COUPON_PAGE',
  REPORTS_SETTINGS: 'REPORTS_SETTINGS',
  FACEBOOK_PIXEL_SETTINGS: 'FACEBOOK_PIXEL_SETTINGS',
  GOOGLE_ANALYTICS_SETTINGS: 'GOOGLE_ANALYTICS_SETTINGS',
  GOOGLE_SEARCH_CONSOLE_SETTINGS: 'GOOGLE_SEARCH_CONSOLE_SETTINGS',
  QUICKSELL_PARTNER: 'QUICKSELL_PARTNER',
  WEBINAR: 'WEBINAR',
  REFERRAL_CODE_SETTINGS: 'REFERRAL_CODE_SETTINGS',
  NEW_UPDATES: 'NEW_UPDATES',
  ABOUT_US: 'ABOUT_US',
  HELP_TRANSLATE: 'HELP_TRANSLATE',
  SUGGESTION_FEEDBACKS: 'SUGGESTION_FEEDBACKS',
  HELP_CENTER: 'HELP_CENTER'
};

export const ROUTE_MAP = {
  LOGIN: 'login',
  CATALOGUES: 'catalogues',
  ACCOUNT_SETTINGS: 'account-settings',
  INQUIRIES: 'inquiries'
};

export const SETTINGS_COMPONENT_ROUTE_MAP = {
  [SETTINGS_COMPONENT_ID_MAP.COMPANY_NAME]: {
    TITLE: 'profile',
    ROUTE: 'profile'
  },
  [SETTINGS_COMPONENT_ID_MAP.PLANS_BILLING]: {
    TITLE: 'plans-billing',
    ROUTE: 'plans-billing',
    IFRAME_ROUTE: 'plans-billing'
  },
  [SETTINGS_COMPONENT_ID_MAP.DOMAIN_SETTINGS]: {
    TITLE: 'domain',
    ROUTE: 'domain'
  },
  [SETTINGS_COMPONENT_ID_MAP.PAYMENT_SETTINGS]: {
    TITLE: 'payments',
    ROUTE: 'payments',
    IFRAME_ROUTE: 'payments'
  },
  [SETTINGS_COMPONENT_ID_MAP.SHIPPING_COST_SETTINGS]: {
    TITLE: 'shipping',
    ROUTE: 'shipping',
    IFRAME_ROUTE: 'shipping-settings'
  },
  [SETTINGS_COMPONENT_ID_MAP.CUSTOM_THEMES_SETTINGS]: {
    TITLE: 'custom_themes',
    ROUTE: 'themes',
    IFRAME_ROUTE: 'themes-manager'
  },
  [SETTINGS_COMPONENT_ID_MAP.CUSTOM_PRODUCT_GRID_TILE]: {
    TITLE: PRODUCT_GRID_TILE_CLASSNAMES_PREFIX,
    ROUTE: PRODUCT_GRID_TILE_CLASSNAMES_PREFIX
  },
  [SETTINGS_COMPONENT_ID_MAP.CUSTOM_PRODUCT_LIST_TILE]: {
    TITLE: PRODUCT_LIST_TILE_CLASSNAMES_PREFIX,
    ROUTE: PRODUCT_LIST_TILE_CLASSNAMES_PREFIX
  },
  [SETTINGS_COMPONENT_ID_MAP.CUSTOM_PRODUCT_MAGAZINE_TILE]: {
    TITLE: PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX,
    ROUTE: PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX
  },
  [SETTINGS_COMPONENT_ID_MAP.CUSTOM_CATALOGUE_GRID_TILE]: {
    TITLE: CATALOGUE_GRID_TILE_CLASSNAMES_PREFIX,
    ROUTE: CATALOGUE_GRID_TILE_CLASSNAMES_PREFIX
  },
  [SETTINGS_COMPONENT_ID_MAP.OTHER_PAGES_SETTINGS]: {
    TITLE: 'other_pages',
    ROUTE: 'other-pages',
    IFRAME_ROUTE: 'other-pages'
  },
  [SETTINGS_COMPONENT_ID_MAP.CUSTOM_PAGES_SETTINGS]: {
    TITLE: 'custom_pages',
    ROUTE: 'custom-pages',
    IFRAME_ROUTE: 'custompages/create-page',
    REMOVE_HASH: true
  },
  [SETTINGS_COMPONENT_ID_MAP.CUSTOM_FOOTER_SETTINGS]: {
    TITLE: 'custom_website_footer',
    ROUTE: 'custom-footer',
    IFRAME_ROUTE: 'custompages/create-footer',
    REMOVE_HASH: true
  },
  [SETTINGS_COMPONENT_ID_MAP.CUSTOM_FONT_SETTINGS]: {
    TITLE: 'custom_website_fonts',
    ROUTE: 'custom-fonts',
    IFRAME_ROUTE: 'custom-fonts'
  },
  [SETTINGS_COMPONENT_ID_MAP.CATALOGUE_BANNER_SETTINGS]: {
    TITLE: 'catalogue_banners',
    ROUTE: 'catalogues',
    IFRAME_ROUTE: 'custompages/create-banner',
    REMOVE_HASH: true
  },
  [SETTINGS_COMPONENT_ID_MAP.CREATE_MENU_SETTINGS]: {
    TITLE: 'menu_builder',
    ROUTE: 'menu',
    IFRAME_ROUTE: 'custompages/create-menu',
    REMOVE_HASH: true
  },
  [SETTINGS_COMPONENT_ID_MAP.PRODUCT_OPTIONS]: {
    TITLE: 'custom_variants',
    ROUTE: 'variants',
    IFRAME_ROUTE: 'variants-manager'
  },
  [SETTINGS_COMPONENT_ID_MAP.METAL_RATES_OPTIONS]: {
    TITLE: 'metal_rates',
    ROUTE: 'metal-rate',
    IFRAME_ROUTE: 'metal-rate'
  },
  [SETTINGS_COMPONENT_ID_MAP.CUSTOM_FIELDS]: {
    TITLE: 'custom_fields',
    ROUTE: 'custom-fields',
    IFRAME_ROUTE: 'custom-fields'
  },
  [SETTINGS_COMPONENT_ID_MAP.WEBHOOKS_SETTINGS]: {
    TITLE: 'webhooks',
    ROUTE: 'webhooks',
    IFRAME_ROUTE: 'webhooks'
  },
  [SETTINGS_COMPONENT_ID_MAP.JEWELLERY_PRICE_DISPLAY]: {
    TITLE: 'jewellery_price_display',
    ROUTE: 'jewellery-price-display',
    IFRAME_ROUTE: 'jewellery-price-display'
  },
  [SETTINGS_COMPONENT_ID_MAP.TAXES_SETTINGS]: {
    TITLE: 'tax_settings',
    ROUTE: 'taxes'
  },
  [SETTINGS_COMPONENT_ID_MAP.INVENTORY_SETTINGS]: {
    TITLE: 'inventory_settings',
    ROUTE: 'inventory-settings'
  },
  [SETTINGS_COMPONENT_ID_MAP.LANGUAGE_SETTINGS]: {
    TITLE: 'language_settings',
    ROUTE: 'language-settings'
  },
  [SETTINGS_COMPONENT_ID_MAP.CATALOGUE_SETTINGS]: {
    TITLE: 'catalogue_settings',
    ROUTE: 'catalogue-settings'
  },
  [SETTINGS_COMPONENT_ID_MAP.CHECKOUT_SETTINGS]: {
    TITLE: 'checkout_settings',
    ROUTE: 'checkout-settings'
  },
  [SETTINGS_COMPONENT_ID_MAP.CUSTOM_CHECKOUT_FORM_SETTINGS]: {
    TITLE: 'checkout_form',
    ROUTE: 'checkout-form',
    IFRAME_ROUTE: 'checkout-form'
  },
  [SETTINGS_COMPONENT_ID_MAP.PRODUCT_TYPE_SETTINGS]: {
    TITLE: 'product_type',
    ROUTE: 'product-type',
    IFRAME_ROUTE: 'product-type'
  },
  [SETTINGS_COMPONENT_ID_MAP.MANAGE_API_KEY]: {
    TITLE: 'developer_settings',
    ROUTE: 'developer-settings',
    IFRAME_ROUTE: 'manage-api-key'
  },
  [SETTINGS_COMPONENT_ID_MAP.PRIVACY_SETTINGS]: {
    TITLE: 'privacy_settings',
    ROUTE: 'privacy-settings',
    IFRAME_ROUTE: 'privacy-settings'
  },
  [SETTINGS_COMPONENT_ID_MAP.CATALOGUE_PRIVACY_SETTINGS]: {
    TITLE: 'catalogue_privacy_settings',
    ROUTE: 'catalogue-privacy-settings',
    IFRAME_ROUTE: 'catalogue-privacy-settings'
  },
  [SETTINGS_COMPONENT_ID_MAP.COUPON_PAGE]: {
    TITLE: 'coupon_codes',
    ROUTE: 'coupons',
    IFRAME_ROUTE: 'coupons'
  },
  [SETTINGS_COMPONENT_ID_MAP.REPORTS_SETTINGS]: {
    TITLE: 'reports',
    ROUTE: 'reports',
    IFRAME_ROUTE: 'reports'
  },
  [SETTINGS_COMPONENT_ID_MAP.FACEBOOK_PIXEL_SETTINGS]: {
    TITLE: 'facebook_pixel',
    ROUTE: 'facebook-pixel',
    IFRAME_ROUTE: 'facebook-pixel'
  },
  [SETTINGS_COMPONENT_ID_MAP.GOOGLE_ANALYTICS_SETTINGS]: {
    TITLE: 'google_analytics',
    ROUTE: 'google-analytics',
    IFRAME_ROUTE: 'google-analytics'
  },
  [SETTINGS_COMPONENT_ID_MAP.GOOGLE_SEARCH_CONSOLE_SETTINGS]: {
    TITLE: 'google_search_console',
    ROUTE: 'google-search-console',
    IFRAME_ROUTE: 'google-search-console'
  },
  [SETTINGS_COMPONENT_ID_MAP.WEBINAR]: {
    TITLE: 'product_demo',
    ROUTE: 'product-demo',
    IFRAME_ROUTE: 'webinar'
  },
  [SETTINGS_COMPONENT_ID_MAP.REFERRAL_CODE_SETTINGS]: {
    TITLE: 'referral_code',
    ROUTE: 'referral-code',
    IFRAME_ROUTE: 'referral'
  },
  [SETTINGS_COMPONENT_ID_MAP.SUGGESTION_FEEDBACKS]: {
    TITLE: 'feedback',
    ROUTE: 'feedback'
  },
  [SETTINGS_COMPONENT_ID_MAP.HELP_CENTER]: {
    TITLE: 'help_center',
    ROUTE: 'help-center',
    IFRAME_ROUTE: 'learn'
  }
};

export const APP_LANGUAGE_CHANGED_EB_KEY = 'APP_LANGUAGE_CHANGED';

export const SIDEBAR_VISIBILITY_CHANGED_EB_KEY = 'SIDEBAR_VISIBILITY_CHANGED';

export const CUSTOM_SHARE_TEXT_APPEND =
  '\nCheckout the *{catalogue_name}* catalog here - \n{showcase_link}\n\n\n\nSent via QuickSell App';

export const HELP_CENTER_DRAWER_POST_MESSAGE_ID = 'HELP_CENTER_DRAWER';

export const COMPANY_NAME_VERIFY_REGEX = /[a-zA-Z]/;
