import AWS from 'aws-sdk';

import AppConfig from 'qs-config/AppConfig';

export const BUCKET_REGION = AppConfig.imageBucketRegion;
export const MAX_RETRIES = 1;

/*
  S3 clock skew timeout is 9,00,000 ms or 15 minutes. The time diff calculated
  on the UI will always exist because there will be a difference in the time
  when the request is sent from the UI and the response is received from the
  server. To ensure that the diff is sent only when it is large enough,
  keep the skew-check window to 5 minutes.
*/
export const S3_CLOCK_SKEW_TIME = 5 * 60 * 1000;
export const S3 = new AWS.S3({ region: BUCKET_REGION });

export const BUCKET_NAME = AppConfig.imageBucketName;

export const VIDEO_BUCKET_NAME = AppConfig.videoBucketName;
export const VIDEO_BUCKET_REGION = AppConfig.videoBucketRegion;

export const getObjectMetadata = key =>
  new Promise((resolve, reject) =>
    S3.headObject({ Bucket: BUCKET_NAME, Key: key }, (err, data) =>
      err ? reject(err) : resolve(data)
    )
  );
