import {
  SETTINGS_COMPONENT_ID_MAP,
  SETTINGS_COMPONENT_ROUTE_MAP
} from './CompanySettings/constants';

export const getRouteParamFromSettingsComponentId = settingsComponentId => {
  if (settingsComponentId === SETTINGS_COMPONENT_ID_MAP.COMPANY_NAME) {
    return SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.COMPANY_NAME].ROUTE;
  } else if (settingsComponentId === SETTINGS_COMPONENT_ID_MAP.PLANS_BILLING) {
    return SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.PLANS_BILLING].ROUTE;
  } else if (settingsComponentId === SETTINGS_COMPONENT_ID_MAP.DOMAIN_SETTINGS) {
    return SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.DOMAIN_SETTINGS].ROUTE;
  } else if (settingsComponentId === SETTINGS_COMPONENT_ID_MAP.PAYMENT_SETTINGS) {
    return SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.PAYMENT_SETTINGS].ROUTE;
  } else if (settingsComponentId === SETTINGS_COMPONENT_ID_MAP.SHIPPING_COST_SETTINGS) {
    return SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.SHIPPING_COST_SETTINGS].ROUTE;
  } else if (settingsComponentId === SETTINGS_COMPONENT_ID_MAP.CUSTOM_THEMES_SETTINGS) {
    return SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CUSTOM_THEMES_SETTINGS].ROUTE;
  } else if (settingsComponentId === SETTINGS_COMPONENT_ID_MAP.CUSTOM_PRODUCT_GRID_TILE) {
    return SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CUSTOM_PRODUCT_GRID_TILE].ROUTE;
  } else if (settingsComponentId === SETTINGS_COMPONENT_ID_MAP.CUSTOM_PRODUCT_LIST_TILE) {
    return SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CUSTOM_PRODUCT_LIST_TILE].ROUTE;
  } else if (settingsComponentId === SETTINGS_COMPONENT_ID_MAP.CUSTOM_PRODUCT_MAGAZINE_TILE) {
    return SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CUSTOM_PRODUCT_MAGAZINE_TILE]
      .ROUTE;
  } else if (settingsComponentId === SETTINGS_COMPONENT_ID_MAP.CUSTOM_CATALOGUE_GRID_TILE) {
    return SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CUSTOM_CATALOGUE_GRID_TILE].ROUTE;
  } else if (settingsComponentId === SETTINGS_COMPONENT_ID_MAP.OTHER_PAGES_SETTINGS) {
    return SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.OTHER_PAGES_SETTINGS].ROUTE;
  } else if (settingsComponentId === SETTINGS_COMPONENT_ID_MAP.CUSTOM_PAGES_SETTINGS) {
    return SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CUSTOM_PAGES_SETTINGS].ROUTE;
  } else if (settingsComponentId === SETTINGS_COMPONENT_ID_MAP.CUSTOM_FOOTER_SETTINGS) {
    return SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CUSTOM_FOOTER_SETTINGS].ROUTE;
  } else if (settingsComponentId === SETTINGS_COMPONENT_ID_MAP.CUSTOM_FONT_SETTINGS) {
    return SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CUSTOM_FONT_SETTINGS].ROUTE;
  } else if (settingsComponentId === SETTINGS_COMPONENT_ID_MAP.CREATE_MENU_SETTINGS) {
    return SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CREATE_MENU_SETTINGS].ROUTE;
  } else if (settingsComponentId === SETTINGS_COMPONENT_ID_MAP.PRODUCT_OPTIONS) {
    return SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.PRODUCT_OPTIONS].ROUTE;
  } else if (settingsComponentId === SETTINGS_COMPONENT_ID_MAP.METAL_RATES_OPTIONS) {
    return SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.METAL_RATES_OPTIONS].ROUTE;
  } else if (settingsComponentId === SETTINGS_COMPONENT_ID_MAP.CUSTOM_FIELDS) {
    return SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CUSTOM_FIELDS].ROUTE;
  } else if (settingsComponentId === SETTINGS_COMPONENT_ID_MAP.WEBHOOKS_SETTINGS) {
    return SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.WEBHOOKS_SETTINGS].ROUTE;
  } else if (settingsComponentId === SETTINGS_COMPONENT_ID_MAP.JEWELLERY_PRICE_DISPLAY) {
    return SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.JEWELLERY_PRICE_DISPLAY].ROUTE;
  } else if (settingsComponentId === SETTINGS_COMPONENT_ID_MAP.TAXES_SETTINGS) {
    return SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.TAXES_SETTINGS].ROUTE;
  } else if (settingsComponentId === SETTINGS_COMPONENT_ID_MAP.INVENTORY_SETTINGS) {
    return SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.INVENTORY_SETTINGS].ROUTE;
  } else if (settingsComponentId === SETTINGS_COMPONENT_ID_MAP.LANGUAGE_SETTINGS) {
    return SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.LANGUAGE_SETTINGS].ROUTE;
  } else if (settingsComponentId === SETTINGS_COMPONENT_ID_MAP.CATALOGUE_SETTINGS) {
    return SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CATALOGUE_SETTINGS].ROUTE;
  } else if (settingsComponentId === SETTINGS_COMPONENT_ID_MAP.CHECKOUT_SETTINGS) {
    return SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CHECKOUT_SETTINGS].ROUTE;
  } else if (settingsComponentId === SETTINGS_COMPONENT_ID_MAP.CUSTOM_CHECKOUT_FORM_SETTINGS) {
    return SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CUSTOM_CHECKOUT_FORM_SETTINGS]
      .ROUTE;
  } else if (settingsComponentId === SETTINGS_COMPONENT_ID_MAP.PRODUCT_TYPE_SETTINGS) {
    return SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.PRODUCT_TYPE_SETTINGS].ROUTE;
  } else if (settingsComponentId === SETTINGS_COMPONENT_ID_MAP.MANAGE_API_KEY) {
    return SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.MANAGE_API_KEY].ROUTE;
  } else if (settingsComponentId === SETTINGS_COMPONENT_ID_MAP.PRIVACY_SETTINGS) {
    return SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.PRIVACY_SETTINGS].ROUTE;
  } else if (settingsComponentId === SETTINGS_COMPONENT_ID_MAP.CATALOGUE_PRIVACY_SETTINGS) {
    return SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CATALOGUE_PRIVACY_SETTINGS].ROUTE;
  } else if (settingsComponentId === SETTINGS_COMPONENT_ID_MAP.COUPON_PAGE) {
    return SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.COUPON_PAGE].ROUTE;
  } else if (settingsComponentId === SETTINGS_COMPONENT_ID_MAP.REPORTS_SETTINGS) {
    return SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.REPORTS_SETTINGS].ROUTE;
  } else if (settingsComponentId === SETTINGS_COMPONENT_ID_MAP.FACEBOOK_PIXEL_SETTINGS) {
    return SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.FACEBOOK_PIXEL_SETTINGS].ROUTE;
  } else if (settingsComponentId === SETTINGS_COMPONENT_ID_MAP.GOOGLE_ANALYTICS_SETTINGS) {
    return SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.GOOGLE_ANALYTICS_SETTINGS].ROUTE;
  } else if (settingsComponentId === SETTINGS_COMPONENT_ID_MAP.GOOGLE_SEARCH_CONSOLE_SETTINGS) {
    return SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.GOOGLE_SEARCH_CONSOLE_SETTINGS]
      .ROUTE;
  } else if (settingsComponentId === SETTINGS_COMPONENT_ID_MAP.WEBINAR) {
    return SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.WEBINAR].ROUTE;
  } else if (settingsComponentId === SETTINGS_COMPONENT_ID_MAP.REFERRAL_CODE_SETTINGS) {
    return SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.REFERRAL_CODE_SETTINGS].ROUTE;
  }
  return undefined;
};

export const getSettingsComponentIdFromRouteParam = routeParam => {
  if (routeParam === SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.COMPANY_NAME].ROUTE) {
    return SETTINGS_COMPONENT_ID_MAP.COMPANY_NAME;
  } else if (
    routeParam === SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.PLANS_BILLING].ROUTE
  ) {
    return SETTINGS_COMPONENT_ID_MAP.PLANS_BILLING;
  } else if (
    routeParam === SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.DOMAIN_SETTINGS].ROUTE
  ) {
    return SETTINGS_COMPONENT_ID_MAP.DOMAIN_SETTINGS;
  } else if (
    routeParam === SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.PAYMENT_SETTINGS].ROUTE
  ) {
    return SETTINGS_COMPONENT_ID_MAP.PAYMENT_SETTINGS;
  } else if (
    routeParam ===
    SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.SHIPPING_COST_SETTINGS].ROUTE
  ) {
    return SETTINGS_COMPONENT_ID_MAP.SHIPPING_COST_SETTINGS;
  } else if (
    routeParam ===
    SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CUSTOM_THEMES_SETTINGS].ROUTE
  ) {
    return SETTINGS_COMPONENT_ID_MAP.CUSTOM_THEMES_SETTINGS;
  } else if (
    routeParam ===
    SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CUSTOM_PRODUCT_GRID_TILE].ROUTE
  ) {
    return SETTINGS_COMPONENT_ID_MAP.CUSTOM_PRODUCT_GRID_TILE;
  } else if (
    routeParam ===
    SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CUSTOM_PRODUCT_LIST_TILE].ROUTE
  ) {
    return SETTINGS_COMPONENT_ID_MAP.CUSTOM_PRODUCT_LIST_TILE;
  } else if (
    routeParam ===
    SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CUSTOM_PRODUCT_MAGAZINE_TILE].ROUTE
  ) {
    return SETTINGS_COMPONENT_ID_MAP.CUSTOM_PRODUCT_MAGAZINE_TILE;
  } else if (
    routeParam ===
    SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CUSTOM_CATALOGUE_GRID_TILE].ROUTE
  ) {
    return SETTINGS_COMPONENT_ID_MAP.CUSTOM_CATALOGUE_GRID_TILE;
  } else if (
    routeParam ===
    SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.OTHER_PAGES_SETTINGS].ROUTE
  ) {
    return SETTINGS_COMPONENT_ID_MAP.OTHER_PAGES_SETTINGS;
  } else if (
    routeParam ===
    SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CUSTOM_PAGES_SETTINGS].ROUTE
  ) {
    return SETTINGS_COMPONENT_ID_MAP.CUSTOM_PAGES_SETTINGS;
  } else if (
    routeParam ===
    SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CUSTOM_FOOTER_SETTINGS].ROUTE
  ) {
    return SETTINGS_COMPONENT_ID_MAP.CUSTOM_FOOTER_SETTINGS;
  } else if (
    routeParam ===
    SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CUSTOM_FONT_SETTINGS].ROUTE
  ) {
    return SETTINGS_COMPONENT_ID_MAP.CUSTOM_FONT_SETTINGS;
  } else if (
    routeParam ===
    SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CREATE_MENU_SETTINGS].ROUTE
  ) {
    return SETTINGS_COMPONENT_ID_MAP.CREATE_MENU_SETTINGS;
  } else if (
    routeParam === SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.PRODUCT_OPTIONS].ROUTE
  ) {
    return SETTINGS_COMPONENT_ID_MAP.PRODUCT_OPTIONS;
  } else if (
    routeParam === SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.METAL_RATES_OPTIONS].ROUTE
  ) {
    return SETTINGS_COMPONENT_ID_MAP.METAL_RATES_OPTIONS;
  } else if (
    routeParam === SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CUSTOM_FIELDS].ROUTE
  ) {
    return SETTINGS_COMPONENT_ID_MAP.CUSTOM_FIELDS;
  } else if (
    routeParam === SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.WEBHOOKS_SETTINGS].ROUTE
  ) {
    return SETTINGS_COMPONENT_ID_MAP.WEBHOOKS_SETTINGS;
  } else if (
    routeParam ===
    SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.JEWELLERY_PRICE_DISPLAY].ROUTE
  ) {
    return SETTINGS_COMPONENT_ID_MAP.JEWELLERY_PRICE_DISPLAY;
  } else if (
    routeParam === SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.TAXES_SETTINGS].ROUTE
  ) {
    return SETTINGS_COMPONENT_ID_MAP.TAXES_SETTINGS;
  } else if (
    routeParam === SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.INVENTORY_SETTINGS].ROUTE
  ) {
    return SETTINGS_COMPONENT_ID_MAP.INVENTORY_SETTINGS;
  } else if (
    routeParam === SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.LANGUAGE_SETTINGS].ROUTE
  ) {
    return SETTINGS_COMPONENT_ID_MAP.LANGUAGE_SETTINGS;
  } else if (
    routeParam === SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CATALOGUE_SETTINGS].ROUTE
  ) {
    return SETTINGS_COMPONENT_ID_MAP.CATALOGUE_SETTINGS;
  } else if (
    routeParam === SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CHECKOUT_SETTINGS].ROUTE
  ) {
    return SETTINGS_COMPONENT_ID_MAP.CHECKOUT_SETTINGS;
  } else if (
    routeParam ===
    SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CUSTOM_CHECKOUT_FORM_SETTINGS].ROUTE
  ) {
    return SETTINGS_COMPONENT_ID_MAP.CUSTOM_CHECKOUT_FORM_SETTINGS;
  } else if (
    routeParam ===
    SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.PRODUCT_TYPE_SETTINGS].ROUTE
  ) {
    return SETTINGS_COMPONENT_ID_MAP.PRODUCT_TYPE_SETTINGS;
  } else if (
    routeParam === SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.MANAGE_API_KEY].ROUTE
  ) {
    return SETTINGS_COMPONENT_ID_MAP.MANAGE_API_KEY;
  } else if (
    routeParam === SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.PRIVACY_SETTINGS].ROUTE
  ) {
    return SETTINGS_COMPONENT_ID_MAP.PRIVACY_SETTINGS;
  } else if (
    routeParam ===
    SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CATALOGUE_PRIVACY_SETTINGS].ROUTE
  ) {
    return SETTINGS_COMPONENT_ID_MAP.CATALOGUE_PRIVACY_SETTINGS;
  } else if (
    routeParam === SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.COUPON_PAGE].ROUTE
  ) {
    return SETTINGS_COMPONENT_ID_MAP.COUPON_PAGE;
  } else if (
    routeParam === SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.REPORTS_SETTINGS].ROUTE
  ) {
    return SETTINGS_COMPONENT_ID_MAP.REPORTS_SETTINGS;
  } else if (
    routeParam ===
    SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.FACEBOOK_PIXEL_SETTINGS].ROUTE
  ) {
    return SETTINGS_COMPONENT_ID_MAP.FACEBOOK_PIXEL_SETTINGS;
  } else if (
    routeParam ===
    SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.GOOGLE_ANALYTICS_SETTINGS].ROUTE
  ) {
    return SETTINGS_COMPONENT_ID_MAP.GOOGLE_ANALYTICS_SETTINGS;
  } else if (
    routeParam ===
    SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.GOOGLE_SEARCH_CONSOLE_SETTINGS].ROUTE
  ) {
    return SETTINGS_COMPONENT_ID_MAP.GOOGLE_SEARCH_CONSOLE_SETTINGS;
  } else if (routeParam === SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.WEBINAR].ROUTE) {
    return SETTINGS_COMPONENT_ID_MAP.WEBINAR;
  } else if (
    routeParam ===
    SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.REFERRAL_CODE_SETTINGS].ROUTE
  ) {
    return SETTINGS_COMPONENT_ID_MAP.REFERRAL_CODE_SETTINGS;
  }
  return undefined;
};
