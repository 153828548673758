import React from 'react';
import {
  DIAMOND,
  DIAMOND_DROPDOWN,
  FLAT_AMOUNT,
  getUpdatedOptions,
  PERCENT,
  STONE_KEYS,
  STONE_TITLES
} from '../ProductTypeDetails';
import StoneInputField from '../StoneCharges/StoneInputField';
import StonesDropDown from '../StoneCharges/StonesDropDown';
import { getI18N } from 'qs-services/i18N';

const Diamond = ({
  errorMessage,
  setErrorMessage,
  addStoneDetails,
  setAddStoneDetails,
  showAverageSize,
  renderCost,
  showFinalStonePrice,
  showMandatoryText,
  entityRatesArray,
  currencySymbol
}) => {
  const { t } = getI18N();

  const diamondClarityDropDown = entityRatesArray.find(
    entityRate =>
      entityRate.entityLabel === DIAMOND.id && entityRate.entityType === DIAMOND_DROPDOWN.CLARITY
  );
  const diamondShapeDropDown = entityRatesArray.find(
    entityRate =>
      entityRate.entityLabel === DIAMOND.id && entityRate.entityType === DIAMOND_DROPDOWN.SHAPE
  );
  const diamondColorDropDown = entityRatesArray.find(
    entityRate =>
      entityRate.entityLabel === DIAMOND.id && entityRate.entityType === DIAMOND_DROPDOWN.COLOR
  );

  const diamondClarity = (diamondClarityDropDown || {}).rates;
  const diamondShape = (diamondShapeDropDown || {}).rates;
  const diamondColor = (diamondColorDropDown || {}).rates;

  const diamondClarityOptions = getUpdatedOptions(diamondClarity, false);
  const diamondShapeOptions = getUpdatedOptions(diamondShape, true);
  const diamondColorOptions = getUpdatedOptions(diamondColor, false);

  return (
    <>
      <StonesDropDown
        title={t(STONE_TITLES.DIAMOND_SHAPE)}
        options={diamondShapeOptions.map(diamondShapeOption => ({
          ...diamondShapeOption,
          displayValue: t(diamondShapeOption.displayValue)
        }))}
        value={addStoneDetails.shape ? addStoneDetails.shape.toLowerCase() : ''}
        setAddStoneDetails={setAddStoneDetails}
        type={DIAMOND.id}
        keyName={STONE_KEYS.SHAPE}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      />
      <StoneInputField
        title={t(STONE_TITLES.DIAMOND_PIECES)}
        value={addStoneDetails.pieces}
        type={DIAMOND.id}
        keyName={STONE_KEYS.PIECES}
        setAddStoneDetails={setAddStoneDetails}
        integer
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      />
      <StonesDropDown
        title={t(STONE_TITLES.DIAMOND_COLOR)}
        options={diamondColorOptions.map(diamondColorOption => ({
          ...diamondColorOption,
          displayValue: t(diamondColorOption.displayValue)
        }))}
        value={addStoneDetails.color}
        type={DIAMOND.id}
        keyName={STONE_KEYS.COLOR}
        setAddStoneDetails={setAddStoneDetails}
        mandatory
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      />
      <StonesDropDown
        title={t(STONE_TITLES.DIAMOND_CLARITY)}
        options={diamondClarityOptions.map(diamondClarityOption => ({
          ...diamondClarityOption,
          displayValue: t(diamondClarityOption.displayValue)
        }))}
        value={addStoneDetails.clarity}
        type={DIAMOND.id}
        keyName={STONE_KEYS.CLARITY}
        setAddStoneDetails={setAddStoneDetails}
        mandatory
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      />
      <StoneInputField
        title={t(STONE_TITLES.DIAMOND_WEIGHT)}
        symbol={'ct'}
        value={addStoneDetails.weight}
        type={DIAMOND.id}
        keyName={STONE_KEYS.WEIGHT}
        setAddStoneDetails={setAddStoneDetails}
        mandatory
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        digitsAfterDecimal={3}
      />

      {showAverageSize({ title: t(STONE_TITLES.AVERAGE_SIZE) })}

      <StoneInputField
        title={t(STONE_TITLES.DIAMOND_RATE)}
        value={addStoneDetails.rate}
        type={DIAMOND.id}
        keyName={STONE_KEYS.RATE}
        setAddStoneDetails={setAddStoneDetails}
        mandatory
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        digitsAfterDecimal={3}
      />
      {renderCost({ title: t(STONE_TITLES.DIAMOND_COST) })}
      <StoneInputField
        title={t(STONE_TITLES.DIAMOND_DISCOUNT_AMOUNT)}
        value={addStoneDetails.discountAmount}
        discountType={addStoneDetails.discountType.toLowerCase()}
        currencySymbol={currencySymbol}
        type={DIAMOND.id}
        keyName={STONE_KEYS.DISCOUNT_AMOUNT}
        setAddStoneDetails={setAddStoneDetails}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        digitsAfterDecimal={2}
      />
      <StonesDropDown
        options={[
          {
            value: PERCENT.id.toLowerCase(),
            displayValue: t(PERCENT.displayValue)
          },
          {
            value: FLAT_AMOUNT.id.toLowerCase(),
            displayValue: t(FLAT_AMOUNT.displayValue)
          }
        ]}
        value={addStoneDetails.discountType.toLowerCase()}
        type={DIAMOND.id}
        keyName={STONE_KEYS.DISCOUNT_TYPE}
        setAddStoneDetails={setAddStoneDetails}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      />
      {showFinalStonePrice()}
      {showMandatoryText()}
    </>
  );
};

export default Diamond;
