import React, { useState, useEffect, useCallback, Fragment, useRef } from 'react';
import { ReactComponent as CheckIcon } from 'qs-assets/Media/checkmark.svg';
import {
  excelIdentifierMapper,
  getDropDownValuesFromSelection,
  getBestSelectorAndUpsertCount
} from 'qs-helpers/CSVUploader';
import Catalogues, { EXCEL_UPLOAD_META } from 'qs-data-manager/Catalogues';
import { getProductMetaFromCSV, createProductsFromCSV } from 'qs-data-manager/Products';
import Loader from '../../../Common/Loader';
import ColumnPicker from '../Common/ColumnPicker';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ErrorPopup from '../../../Common/ErrorPopup';
import { getErrorMessage } from 'qs-helpers';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

const useStyles = makeStyles({
  root: {
    backgroundColor: '#168c5b'
  }
});

export default ({
  csvData,
  headers,
  columnNameDetailsMap,
  excelComponentMap,
  catalogueId,
  onBack,
  onCanceled
}) => {
  //Ignore the first row as it is just the columns header
  const rowLength = csvData.length - 1;
  const columnLength = csvData[0].length;
  const excelMappingFieldIds = useRef([
    EXCEL_UPLOAD_META.ID,
    EXCEL_UPLOAD_META.SKU,
    EXCEL_UPLOAD_META.TITLE,
    EXCEL_UPLOAD_META.PARENT_SKU,
    EXCEL_UPLOAD_META.COLORS,
    EXCEL_UPLOAD_META.SIZES
  ]);

  const excelMappingDropDownIds = useRef([
    EXCEL_UPLOAD_META.ID,
    EXCEL_UPLOAD_META.SKU,
    EXCEL_UPLOAD_META.TITLE
  ]);

  const [mappedValue, setMappedValue] = useState('');
  const [mappedResult, setMappedResult] = useState({});
  const [operationStatus, setOperationStatus] = useState({
    loading: 'FETCHING_BEST_SELECTOR',
    error: undefined
  });
  const muiStyles = useStyles();

  const excelRowsToGetUpsertData = useCallback(() => {
    const valuesFromExcel = [];
    for (let index = 1; index < csvData.length; index++) {
      const csvRow = csvData[index];
      const mappingRow = {};
      for (const fieldData of excelMappingFieldIds.current) {
        const { id, apiKey } = fieldData;
        const fieldMeta = getProductMetaFromCSV({
          headers,
          componentCSVMapping: columnNameDetailsMap,
          row: csvRow,
          fieldId: id,
          excelComponentMap
        });
        // Currently none of the mappers accept multiple columns, hence the value
        // will always be present in the 0th element of the array
        mappingRow[apiKey] = fieldMeta[0] || null;
      }
      valuesFromExcel.push(mappingRow);
    }
    return valuesFromExcel;
  }, [csvData, columnNameDetailsMap, headers, excelComponentMap]);

  useEffect(() => {
    const valuesFromExcel = excelRowsToGetUpsertData();

    getBestSelectorAndUpsertCount({
      catalogueId,
      excelRows: valuesFromExcel
    })
      .then(({ selectorId: remoteSelectorId, changes }) => {
        const selectorMeta =
          excelMappingDropDownIds.current.filter(
            dropDownMeta => dropDownMeta.apiChangesKey === remoteSelectorId
          )[0] || {};

        const { id } = selectorMeta;
        setMappedValue(id || '');
        setMappedResult({ changes });
        setOperationStatus({ loading: '', error: undefined });
      })
      .catch(error => {
        const { title } = Catalogues.getCatalogueMetaFromCache(catalogueId);

        const message = `Something went wrong while fetching the data for products.`;
        const subject = `Failed to retrieve product mappings for identifier while uploading to catalogue ${title ||
          ''}`;

        showError({ message, subject, error });
        setMappedResult(null);
      });
  }, [catalogueId, excelRowsToGetUpsertData]);

  const showError = ({ message, subject, error }) => {
    setOperationStatus({
      loading: '',
      error: {
        message,
        subject,
        actualError: getErrorMessage(error)
      }
    });
  };

  const getMappingInfoFromRemote = async ({ mappedFieldId }) => {
    const mapValues = [];
    for (let index = 1; index < csvData.length; index++) {
      const csvRow = csvData[index];
      const mappingRow = {};
      for (const fieldData of excelMappingFieldIds.current) {
        const { id, apiKey } = fieldData;
        const fieldMeta = getProductMetaFromCSV({
          headers,
          componentCSVMapping: columnNameDetailsMap,
          row: csvRow,
          fieldId: id,
          excelComponentMap
        });

        //Currently none of the mappers accept multiple columns, hence the value
        // will always be present in the 0th element of the array
        mappingRow[apiKey] = fieldMeta[0] || null;
      }
      mapValues.push(mappingRow);
    }

    try {
      setOperationStatus({ loading: 'CHANGES_EXCEL', error: undefined });
      setMappedResult();
      let mappingKey = 'NONE';
      if (excelComponentMap[mappedFieldId]) {
        mappingKey = excelComponentMap[mappedFieldId].apiChangesKey || '';
      }
      const mappedResultApi = await excelIdentifierMapper({
        mappingKey,
        mapValues,
        catalogueId
      });
      setMappedResult(mappedResultApi);
      setOperationStatus({ loading: '', error: undefined });
    } catch (mapperError) {
      let mappingTitle = '';
      if (excelComponentMap[mappedFieldId]) {
        mappingTitle = excelComponentMap[mappedFieldId].title;
      }
      const { title } = Catalogues.getCatalogueMetaFromCache(catalogueId);

      const message = `Something went wrong while fetching the data for products that were matched with identifier "${mappingTitle}"`;
      const subject = `Failed to retrieve product mappings for identifier "${mappingTitle}" while uploading to catalogue ${title ||
        ''}`;
      showError({ message, subject, error: mapperError });
    }
  };

  const onChange = event => {
    setMappedValue(event.target.value);
    getMappingInfoFromRemote({ mappedFieldId: event.target.value });
  };

  const onUploadClicked = () => {
    createProductsFromCSV(
      {
        headers,
        data: csvData,
        componentCSVMapping: columnNameDetailsMap,
        catalogueId,
        mappedValue,
        excelComponentMap
      },
      {
        onSuccess: onCanceled,
        onFail: uploadError => {
          const { title } = Catalogues.getCatalogueMetaFromCache(catalogueId);
          setOperationStatus({
            loading: '',
            error: {
              message: `${
                uploadError && uploadError.message.length > 0
                  ? uploadError.message
                  : 'Something went wrong while saving your configuration to the catalogue'
              }`,
              subject: `Failed to upload excel to catalogue ${title || ''}`,
              actualError: getErrorMessage(uploadError)
            }
          });
        },
        toggleLoader: () => {
          setOperationStatus({ loading: 'UPLOAD_EXCEL', error: undefined });
        }
      }
    );
  };

  const getMappingInput = () => {
    const { loading } = operationStatus;

    if (loading === 'FETCHING_BEST_SELECTOR' || loading === 'UPLOAD_EXCEL') {
      return null;
    }

    const dropDownValues = getDropDownValuesFromSelection({
      columnNameDetailsMap,
      excelMappingFieldIds: excelMappingDropDownIds.current
    });

    let disabled = false;
    if (loading === 'CHANGES_EXCEL') {
      disabled = true;
    }

    return (
      <ColumnPicker
        containerClasses="mapIdentifier"
        multiple={false}
        dropDownValues={dropDownValues}
        onChange={onChange}
        value={mappedValue}
        disabled={disabled}
        excelComponentMap={excelComponentMap}
      />
    );
  };

  const getMappingContent = () => {
    const { loading, error } = operationStatus;
    const { t } = getI18N();

    if (error) {
      return null;
    }

    if (loading) {
      return (
        <div className="mappingLoader">
          <Loader size="large" />
        </div>
      );
    }

    if (!mappedResult) {
      return (
        <div className="noMatchedProducts">
          <span>{t('matched_products_data_could_not_be_fetched')}</span>
        </div>
      );
    }

    const { changes: { newProducts, updatedProducts } = {} } = mappedResult;

    const getProductsUpdatedText = () => {
      try {
        const normalText = t('count_products_will_be_updated', { count: updatedProducts });
        const highlightRegex = /\*([^*]+)\*/g;
        const formattedText = normalText.replace(highlightRegex, match => {
          if (match.startsWith('*')) {
            return `<span class="highlightText">${match.slice(1, -1)}</span>`;
          } else {
            return `${match}`;
          }
        });
        return `<span className="normalText">${formattedText}</span>`;
      } catch (error) {
        return `<span className="normalText">${t('count_products_will_be_updated', {
          count: updatedProducts
        })}</span>`;
      }
    };

    const getProductsCreatedText = () => {
      try {
        const normalText = t('count_new_products_will_be_created', { count: newProducts });
        const highlightRegex = /\*([^*]+)\*/g;
        const formattedText = normalText.replace(highlightRegex, match => {
          if (match.startsWith('*')) {
            return `<span class="highlightText">${match.slice(1, -1)}</span>`;
          } else {
            return `${match}`;
          }
        });
        return `<span className="normalText">${formattedText}</span>`;
      } catch (error) {
        return `<span className="normalText">${t('count_new_products_will_be_created', {
          count: newProducts
        })}</span>`;
      }
    };

    return (
      <Fragment>
        {updatedProducts > 0 && (
          <div className="matchedProducts">
            <CheckIcon className="infoIcon" />
            <span className="matchedText">
              {t('count_products_matched', { count: updatedProducts })}
            </span>
          </div>
        )}
        <div className={`extraProductInfo ${updatedProducts < 1 ? 'optional-margin' : ''}`}>
          <CheckIcon className="infoIcon" />
          <span dangerouslySetInnerHTML={{ __html: getProductsCreatedText() }} />
        </div>
        <div className="extraProductInfo">
          <CheckIcon className="infoIcon" />
          <span dangerouslySetInnerHTML={{ __html: getProductsUpdatedText() }} />
        </div>
      </Fragment>
    );
  };

  const handleBackClick = event => {
    event.preventDefault();
    onBack({ columnNameDetailsMap });
  };

  const getFooter = () => {
    const { loading } = operationStatus;
    const { t } = getI18N();
    if (loading) {
      return null;
    }

    return (
      <div className="excelMappingResultFooter">
        <div className="excelSecondaryButtons">
          <button type="button" className="buttonPlain" onClick={handleBackClick}>
            {t('back')}
          </button>
          <button type="button" className="buttonPlain buttonCancel" onClick={onCanceled}>
            {t('cancel')}
          </button>
        </div>
        <Button
          classes={{ root: muiStyles.root }}
          variant="contained"
          onClick={onUploadClicked}
          color="primary"
        >
          {t('upload_now')}
        </Button>
      </div>
    );
  };

  const resetOperationStatus = () => {
    setOperationStatus({ loading: '', error: undefined });
  };

  const { error } = operationStatus;
  const { t } = getI18N();

  /*
    TODO: make the modal containers use common backdrop and make the containers common.
  */

  return (
    <Fragment>
      <div className="excelMappingResultContainer">
        <div className="excelMappingResultContent">
          <div className="excelMappingResultBody">
            <div className="title">{t('choose_product_identifier')}</div>
            <div className="excelDataInfo">
              <span>{t('length_columns', { columnLength })}</span>
              <span className="rowInfo">{t('length_rows', { rowLength })}</span>
            </div>
            {getMappingInput()}
            {getMappingContent()}
          </div>
          {getFooter()}
        </div>
      </div>
      {error && (
        <ErrorPopup
          title={t('excel_mapping_error')}
          onClose={resetOperationStatus}
          mailProps={{ subject: error.subject, body: error.actualError }}
        >
          {error.message}
        </ErrorPopup>
      )}
    </Fragment>
  );
};
