export const MIN_ORDER_QTY = 'minOrderQuantity';
export const CLICK_MOQ_COMPONENT = 'MOQ_DIALOG';
export const CLICK_CATALOGUE_PRIVACY_SETTINGS = 'CATALOGUE_PRIVACY_SETTINGS';

export const EDIT_ICON = 'EditIcon';

const SettingsData = [
  {
    label: 'catalogue_link',
    settings: [
      {
        id: 'visitorsEnabled',
        settingTitle: 'enable_catalogue_visitors',
        settingDescription: 'allow_visitors_to_view_catalogue_link'
      },
      {
        id: 'visitorIdentity',
        settingTitle: 'request_visitor_identity',
        settingDescription: 'require_catalogue_visitors_to_enter_name_and_phone_number_after'
      },
      {
        id: 'otpVerification',
        settingTitle: 'enable_otp_verification',
        settingDescription: 'require_visitors_to_verify_their_phone_via_otp_sms'
      },
      {
        id: 'pdfDownload',
        settingTitle: 'enable_pdf_download',
        settingDescription:
          'show_pdf_download_button_in_catalogue_and_allow_visitors_to_download_catalogue_as_pdf'
      },
      {
        id: 'productDownload',
        settingTitle: 'enable_product_download',
        settingDescription: 'allow_customers_to_download_your_product_images'
      },
      {
        id: 'firstProductAsCover',
        settingTitle: 'first_image_as_cover',
        settingDescription: 'this_will_make_first_image_as_cover_image_in_catalogue'
      }
    ]
  },
  {
    label: 'checkout_settings',
    settings: [
      {
        id: 'shippingAddress',
        settingTitle: 'capture_shipping_address',
        settingDescription: 'require_customers_to_enter_their_shipping_address'
      },
      {
        id: MIN_ORDER_QTY,
        settingTitle: 'minimum_order_quantity',
        settingDescription: moq => {
          if (moq === null || !moq) {
            return 'no_minimum_order_quantity_set';
          }
          return { key: 'moq', value: `minimum_order_quantity_set_to` };
        },
        icon: EDIT_ICON,
        customComponentOnClick: CLICK_MOQ_COMPONENT,
        resetMessage:
          'moq_level_for_product_will_be_automatically_applied_based_on_your_global_moq_level_setting'
      }
      // {
      //   id: 'orderConfirmationCustomMessage',
      //   settingTitle: 'Order confirmation message',
      //   settingDescription: 'Show a message to your customers after they confirm their order'
      // }
    ]
  },
  {
    label: 'inventory',
    settings: [
      {
        id: 'stockVisibility',
        settingTitle: 'out_of_stock_visibility',
        settingDescription: 'show_out_of_stock_products_to_catalogue'
      },
      {
        id: 'orderOnOutOfStock',
        settingTitle: 'allow_order_on_out_of_stock_product',
        settingDescription: 'allow_customers_to_place_an_order_on_out_of_stock_items'
      }
    ]
  },
  {
    label: 'privacy_settings',
    settings: [
      {
        id: 'cataloguePrivacySettings',
        settingTitle: 'catalogue_privacy_settings',
        settingDescription: 'manage_who_can_view_catalogue',
        icon: EDIT_ICON,
        customComponentOnClick: CLICK_CATALOGUE_PRIVACY_SETTINGS
      }
    ]
  }
];

export default SettingsData;
