import { FEATURES_PLAN } from 'qs-api/FeaturePlan/ApiCacheConnector';
import { getCacheKeyForFeaturePlan } from 'qs-helpers/FeaturePlan/DataQueryHelper';
import CacheRequest from '../CacheRequest';

export const attachFeaturePlanDataListener = ({ listener }) => {
  CacheRequest.attachListener(getCacheKeyForFeaturePlan(), listener);
};

export const removeFeaturePlanDataListener = ({ listener }) => {
  CacheRequest.removeListener(getCacheKeyForFeaturePlan(), listener);
};

export const getFeaturePlanDataFromRemote = async () => {
  const key = getCacheKeyForFeaturePlan();
  CacheRequest.makeRequest(key, () => FEATURES_PLAN.apiFunction(), {
    params: [],
    options: {
      shouldNotStoreInNative: true
    }
  });
};

export const getFeaturePlanFromCache = () => {
  return CacheRequest.getCacheForKey(getCacheKeyForFeaturePlan()) || undefined;
};
