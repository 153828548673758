export const FILE_TYPE = 'FILE_TYPE';

export const DIRECTORY_TYPE = 'DIRECTORY_TYPE';

export class DirectoryData {
  constructor(files, name) {
    this.files = Array.isArray(files) ? files : [];
    this.name = name;
  }
}

export class RawFileData {
  constructor({ fileData, type, extraData } = {}) {
    const { rawFiles, filesLength } = RawFileData.processFileData(fileData);
    //Used for internal processing. Acccess this value using getters and setters
    this.rawFiles = rawFiles;
    this.filesLength = filesLength;
    this.type = type || FILE_TYPE;
    this.extraData = extraData;
  }

  static setRawFilesData = filesList => {
    let filesLength = 0;
    filesList.forEach(rawFile => {
      if (rawFile instanceof RawFileData) {
        filesLength += rawFile.filesLength;
        return;
      }
      filesLength += 1;
    });
    return { rawFiles: filesList, filesLength };
  };

  static processFileData = fileData => {
    if (fileData instanceof DirectoryData && fileData.files.length) {
      return RawFileData.setRawFilesData(fileData.files);
    }

    if (Array.isArray(fileData) && fileData.length) {
      return RawFileData.setRawFilesData(fileData);
    }

    if (fileData instanceof File) {
      return { rawFiles: [fileData], filesLength: 1 };
    }

    return { rawFiles: undefined, filesLength: 0 };
  };

  set files(fileData) {
    const { rawFiles, filesLength } = RawFileData.processFileData(fileData);
    this.rawFiles = rawFiles;
    this.filesLength = filesLength;
  }

  get files() {
    return this.rawFiles;
  }
}
