import React, { useState, useEffect } from 'react';

import CreateNewLabelDialog from 'qs-components/Common/CreateNewLabelDialog';
import { ReactComponent as PlusIcon } from 'qs-assets/Media/plus.svg';
import { Tooltip, withStyles } from '@material-ui/core';
import DialogBox from 'qs-components/Common/DialogBox';
import CatalogueLib from 'qs-data-manager/Catalogues';
import { Checkbox } from '@material-ui/core';
import toastr from 'toastr';
import './styles.scss';

import {
  attachAndDetachLabels,
  getCatalogueLabelsFromCache
} from 'qs-data-manager/Catalogues/CatalogueLabels';
import { getCachedCatalogueLabels } from 'qs-helpers/Catalogues/DataQueryHelper';
import { selectedCatalogue } from 'qs-data-manager/Selected';
import Mixpanel from 'qs-data-manager/Mixpanel';
import { toggleGlobalLoader } from 'qs-helpers';
import { getI18N } from 'qs-services/i18N';

const IconToolTip = withStyles({
  tooltip: {
    backgroundColor: '#FFF',
    fontSize: 12,
    color: 'black'
  }
})(Tooltip);

export default ({ show, onClose, title }) => {
  const [allSelectedCataloguesMeta] = useState({});
  const [open, setOpen] = useState(false);
  const [state, setState] = useState({
    attachLabels: {},
    detachLabels: {}
  });
  const [presentInSelectedCatalogues, setPresentInSelectedCatalogues] = useState({});
  const [labelsData, setLabelsData] = useState(getCachedCatalogueLabels());

  const { t } = getI18N();

  useEffect(() => {
    const allCatalogueIds = selectedCatalogue.getAll();
    let allIds = [];
    let result = {};
    for (const [key] of Object.entries(allCatalogueIds)) {
      const temp = CatalogueLib.getCatalogueMetaFromCache(key).labelIds;
      if (!temp || temp.length === 0) {
        allIds = [];
        break;
      }
      allIds = allIds.concat(temp);
    }
    for (let i = 0; i < allIds.length; i++) {
      result[allIds[i]] = (result[allIds[i]] || 0) + 1;
    }
    setPresentInSelectedCatalogues(result);
  }, [show]);

  useEffect(() => {
    setLabelsData(getCatalogueLabelsFromCache());
  }, [open, show]);

  const onDialogClose = event => {
    event.preventDefault();
    setState({
      attachLabels: {},
      detachLabels: {}
    });
    onClose();
  };

  const onUpdateLabelAttachment = async event => {
    event.preventDefault();
    const attachLabelIds = Object.keys(state.attachLabels);
    const detachLabelIds = Object.keys(state.detachLabels);
    const catalogueIds = selectedCatalogue.getAllSelectedItems();
    const labels = (labelsData || {}).labels || [];
    if (attachLabelIds.length === 0 && detachLabelIds.length === 0) {
      return onClose();
    }
    const key = `updateLabelAttachment${Date.now()}`;
    toggleGlobalLoader(key, true);
    try {
      await attachAndDetachLabels({
        attachLabels: attachLabelIds,
        detachLabels: detachLabelIds,
        catalogueIds
      });
      toastr.success(t('linked_label_updated_for_selected_catalogues'));
      selectedCatalogue.removeAll();
      Mixpanel.sendEvent({
        eventName: 'catalogue_labels_applied',
        catalogue_ids: catalogueIds,
        attached_label_names: attachLabelIds
          .map(id => {
            const label = labels.find(label => label.id === id);
            return label ? label.name : undefined;
          })
          .filter(Boolean),
        detached_label_names: detachLabelIds
          .map(id => {
            const label = labels.find(label => label.id === id);
            return label ? label.name : undefined;
          })
          .filter(Boolean)
      });
    } catch (error) {
      toastr.error(t('failed_to_update_catalogues_to_label_link'));
    } finally {
      toggleGlobalLoader(key, false);
      setState({
        attachLabels: {},
        detachLabels: {}
      });
      onClose();
    }
  };

  const toggleAttach = (value, id) => {
    const updatedSelection = { ...presentInSelectedCatalogues };
    const updatedAttachLabels = { ...state.attachLabels };
    const updatedDetachLabels = { ...state.detachLabels };
    if (value) {
      updatedSelection[id] = selectedCatalogue.getCount();
    } else {
      updatedSelection[id] = 0;
    }
    if (updatedAttachLabels[id]) {
      delete updatedAttachLabels[id];
    } else if (updatedDetachLabels[id]) {
      delete updatedDetachLabels[id];
    } else {
      if (value) {
        updatedAttachLabels[id] = true;
      } else {
        updatedDetachLabels[id] = true;
      }
    }
    setState({
      attachLabels: updatedAttachLabels,
      detachLabels: updatedDetachLabels
    });
    setPresentInSelectedCatalogues(updatedSelection);
  };

  return (
    <>
      <DialogBox
        maxWidth={'xs'}
        open={show}
        onClose={onDialogClose}
        title={title}
        actionsConfig={[
          {
            content: t('cancel'),
            clickHandler: onDialogClose,
            role: 'SECONDARY'
          },
          {
            content: t('apply'),
            clickHandler: onUpdateLabelAttachment,
            role: 'PRIMARY',
            disabled:
              Object.keys(state.attachLabels).length === 0 &&
              Object.keys(state.detachLabels).length === 0
          }
        ]}
        id="label-select-dialog-box"
      >
        <p className={'selectLabelsSubtitle'}>
          {t('choose_labels_to_apply_on_catalogue', { count: allSelectedCataloguesMeta.length })}
        </p>
        <IconToolTip
          onOpen={() => {
            Mixpanel.sendEvent({ eventName: 'what_is_catalogue_labels' });
          }}
          title={t('labels_help_you_reorganize_your_catalogues_better_and_are_only_visible_to_you')}
        >
          <p className={'selectLabelsInfo'}>{t('what_are_catalogue_labels')}</p>
        </IconToolTip>
        <div className={'labelDialogRow addNewLabelFromDialog'} onClick={() => setOpen(true)}>
          <PlusIcon className={'plusIcon'} /> {t('create_new_label')}
        </div>
        {labelsData &&
          Array.isArray(labelsData.labels) &&
          labelsData.labels.map(element => {
            const isSelected =
              presentInSelectedCatalogues[element.id] === selectedCatalogue.getCount();
            return (
              <div
                key={element.id}
                onClick={() => toggleAttach(!isSelected, element.id)}
                className={'labelCheckboxRow'}
              >
                <span className={'ellipsis'}>{element.name}</span>
                <Checkbox checked={isSelected} color="primary" />
              </div>
            );
          })}
      </DialogBox>
      <CreateNewLabelDialog open={open} setOpen={setOpen} />
    </>
  );
};
