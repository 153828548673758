import 'intersection-observer';
import { ResizeObserver as ResizeObserverPolyfill } from '@juggle/resize-observer';

const pollyfillResizeObserver = () => {
  // Exit early if all ResizeObserver and ResizeObserverEntry
  // features are natively supported.
  // eslint-disable-next-line no-restricted-globals
  if ('ResizeObserver' in window && 'ResizeObserverEntry' in window) {
    return;
  }

  // eslint-disable-next-line no-restricted-globals
  window.ResizeObserver = ResizeObserverPolyfill;
};

pollyfillResizeObserver();
