import en from 'grapesjs/locale/en';
import es from 'grapesjs/locale/es';
import pt from 'grapesjs/locale/pt';
import GridIcon from 'qs-assets/Media/grid_icon.svg';
import ListIcon from 'qs-assets/Media/list_icon.svg';
import {
  PRODUCT_GRID_TILE_CLASSNAMES_PREFIX,
  CATALOGUE_GRID_TILE_CLASSNAMES_PREFIX
} from '../Constants';
import { GRAPES_JS_PLUGINS } from '.';
import { getI18N } from 'qs-services/i18N';

export const GRAPES_JS_I18N_CONFIG = {
  get EN() {
    const { t } = getI18N();
    return {
      ...en,
      assetManager: {
        ...en.assetManager,
        addButton: t('add_image'),
        inputPlh: 'http://path/to/the/image.jpg',
        modalTitle: t('select_image'),
        uploadTitle: t('drop_files_here_or_click_to_upload')
      },
      blockManager: {
        ...en.blockManager,
        categories: {
          ...(en.blockManager.categories || {}),
          Basic: t('basic'),
          Extra: t('extra'),
          forms: t('form')
        },
        labels: {
          ...(en.blockManager.labels || {}),
          [PRODUCT_GRID_TILE_CLASSNAMES_PREFIX]: t('product_grid_tile'),
          [CATALOGUE_GRID_TILE_CLASSNAMES_PREFIX]: t('catalogue_grid_tile'),
          'grid-items': t('grid_items'),
          'list-items': t('list_items'),
          column1: t('length_columns', {
            columnLength: 1
          }),
          column2: t('length_columns', {
            columnLength: 2
          }),
          column3: t('length_columns', {
            columnLength: 3
          }),
          column4: t('length_columns', {
            columnLength: 4
          }),
          'column3-7': t('length_columns', {
            columnLength: '3 / 7'
          }),
          text: t('text'),
          link: t('link'),
          image: t('image'),
          video: t('video'),
          map: t('map'),
          'link-block': t('link_block'),
          quote: t('quote'),
          'text-basic': t('text_section'),
          'custom-code': t('custom_code'),
          tooltip: t('tooltip'),
          typed: t('typed'),
          form: t('form'),
          input: t('input'),
          textarea: t('textarea'),
          select: t('select'),
          button: t('button'),
          label: t('label'),
          checkbox: t('checkbox'),
          radio: t('radio')
        }
      },
      domComponents: {
        ...en.domComponents,
        names: {
          ...(en.domComponents.names || {}),
          '': t('default'),
          default: t('default'),
          wrapper: t('body'),
          text: t('text'),
          comment: t('comment'),
          image: t('image'),
          video: t('video'),
          label: t('label'),
          link: t('link'),
          map: t('map'),
          tfoot: t('table_footer'),
          tbody: t('table_body'),
          thead: t('table_header'),
          table: t('table'),
          row: t('table_row'),
          cell: t('table_cell'),
          [PRODUCT_GRID_TILE_CLASSNAMES_PREFIX]: t('product_grid_tile'),
          [CATALOGUE_GRID_TILE_CLASSNAMES_PREFIX]: t('catalogue_grid_tile')
        }
      },
      deviceManager: {
        ...en.deviceManager,
        device: t('device'),
        devices: {
          ...(en.deviceManager.devices || {}),
          desktop: t('desktop'),
          tablet: t('tablet'),
          mobileLandscape: t('mobile_landscape'),
          mobilePortrait: t('mobile_portrait')
        }
      },
      panels: {
        ...en.panels,
        buttons: {
          ...(en.panels.buttons || {}),
          titles: {
            ...((en.panels.buttons || {}).titles || {}),
            preview: t('preview'),
            fullscreen: t('fullscreen'),
            'sw-visibility': t('view_components'),
            'export-template': t('view_code'),
            'open-sm': t('open_style_manager'),
            'open-tm': t('settings'),
            'open-layers': t('open_layer_manager'),
            'open-blocks': t('open_block_manager')
          }
        }
      },
      selectorManager: {
        ...en.selectorManager,
        label: t('classes'),
        selected: t('selected'),
        emptyState: t('current_css_state'),
        states: {
          ...(en.selectorManager.states || {}),
          hover: `${t('hover')} (:hover)`,
          focus: `${t('focus')} (:focus)`,
          'focus-within': `${t('focus_within')} (:focus-within)`,
          active: `${t('active')} (:active)`,
          'nth-of-type(2n)': `${t('odd_or_even')} (:nth-of-type(2n))`
        }
      },
      styleManager: {
        ...en.styleManager,
        empty: t('select_an_element_before_using_style_manager'),
        layer: t('layer'),
        fileButton: t('image'),
        sectors: {
          ...(en.styleManager.sectors || {}),
          default: t('default'),
          basic: t('basic'),
          setting: t('setting'),
          settings: t('settings'),
          general: t('general'),
          layout: t('layout'),
          typography: t('typography'),
          decorations: t('decorations'),
          extra: t('extra'),
          flex: t('flex'),
          dimension: t('dimension'),
          display: t('display')
        },
        properties: {
          ...en.styleManager.properties
        }
      },
      traitManager: {
        ...en.traitManager,
        empty: t('select_an_element_before_using_trait_manager'),
        label: t('component_settings'),
        traits: {
          ...(en.traitManager.traits || {}),
          labels: {
            ...(en.traitManager.traits || {}).labels
          },
          attributes: {
            ...(en.traitManager.traits || {}).attributes,
            id: {
              ...((en.traitManager.traits || {}).attributes || {}).id,
              label: t('identifier'),
              placeholder: t('please_enter_identifier')
            },
            alt: {
              ...((en.traitManager.traits || {}).attributes || {}).alt,
              label: t('image_alt_text'),
              placeholder: t('enter_image_alt_text')
            },
            title: {
              ...((en.traitManager.traits || {}).attributes || {}).title,
              label: t('title'),
              placeholder: t('enter_title')
            },
            href: {
              ...((en.traitManager.traits || {}).attributes || {}).href,
              label: t('link'),
              placeholder: `${t('example')}: https://google.com`
            }
          },
          options: {
            ...(en.traitManager.traits.options || {}),
            target: {
              ...((en.traitManager.traits.options || {}).target || {}),
              false: t('this_window'),
              _blank: t('new_window')
            }
          }
        }
      },
      storageManager: {
        ...en.storageManager,
        recover: t('do_you_want_to_recover_unsaved_changes')
      }
    };
  },
  get ES() {
    const { t } = getI18N();
    return {
      ...es,
      assetManager: {
        ...es.assetManager,
        addButton: t('add_image'),
        inputPlh: 'http://path/to/the/image.jpg',
        modalTitle: t('select_image'),
        uploadTitle: t('drop_files_here_or_click_to_upload')
      },
      blockManager: {
        ...es.blockManager,
        categories: {
          ...(es.blockManager.categories || {}),
          Basic: t('basic'),
          Extra: t('extra'),
          forms: t('form')
        },
        labels: {
          ...(es.blockManager.labels || {}),
          [PRODUCT_GRID_TILE_CLASSNAMES_PREFIX]: t('product_grid_tile'),
          [CATALOGUE_GRID_TILE_CLASSNAMES_PREFIX]: t('catalogue_grid_tile'),
          'grid-items': t('grid_items'),
          'list-items': t('list_items'),
          column1: t('length_columns', {
            columnLength: 1
          }),
          column2: t('length_columns', {
            columnLength: 2
          }),
          column3: t('length_columns', {
            columnLength: 3
          }),
          column4: t('length_columns', {
            columnLength: 4
          }),
          'column3-7': t('length_columns', {
            columnLength: '3 / 7'
          }),
          text: t('text'),
          link: t('link'),
          image: t('image'),
          video: t('video'),
          map: t('map'),
          'link-block': t('link_block'),
          quote: t('quote'),
          'text-basic': t('text_section'),
          'custom-code': t('custom_code'),
          tooltip: t('tooltip'),
          typed: t('typed'),
          form: t('form'),
          input: t('input'),
          textarea: t('textarea'),
          select: t('select'),
          button: t('button'),
          label: t('label'),
          checkbox: t('checkbox'),
          radio: t('radio')
        }
      },
      domComponents: {
        ...es.domComponents,
        names: {
          ...(es.domComponents.names || {}),
          '': t('default'),
          default: t('default'),
          wrapper: t('body'),
          text: t('text'),
          comment: t('comment'),
          image: t('image'),
          video: t('video'),
          label: t('label'),
          link: t('link'),
          map: t('map'),
          tfoot: t('table_footer'),
          tbody: t('table_body'),
          thead: t('table_header'),
          table: t('table'),
          row: t('table_row'),
          cell: t('table_cell'),
          [PRODUCT_GRID_TILE_CLASSNAMES_PREFIX]: t('product_grid_tile'),
          [CATALOGUE_GRID_TILE_CLASSNAMES_PREFIX]: t('catalogue_grid_tile')
        }
      },
      deviceManager: {
        ...es.deviceManager,
        device: t('device'),
        devices: {
          ...(es.deviceManager.devices || {}),
          desktop: t('desktop'),
          tablet: t('tablet'),
          mobileLandscape: t('mobile_landscape'),
          mobilePortrait: t('mobile_portrait')
        }
      },
      panels: {
        ...es.panels,
        buttons: {
          ...(es.panels.buttons || {}),
          titles: {
            ...((es.panels.buttons || {}).titles || {}),
            preview: t('preview'),
            fullscreen: t('fullscreen'),
            'sw-visibility': t('view_components'),
            'export-template': t('view_code'),
            'open-sm': t('open_style_manager'),
            'open-tm': t('settings'),
            'open-layers': t('open_layer_manager'),
            'open-blocks': t('open_block_manager')
          }
        }
      },
      selectorManager: {
        ...es.selectorManager,
        label: t('classes'),
        selected: t('selected'),
        emptyState: t('current_css_state'),
        states: {
          ...(es.selectorManager.states || {}),
          hover: `${t('hover')} (:hover)`,
          focus: `${t('focus')} (:focus)`,
          'focus-within': `${t('focus_within')} (:focus-within)`,
          active: `${t('active')} (:active)`,
          'nth-of-type(2n)': `${t('odd_or_even')} (:nth-of-type(2n))`
        }
      },
      styleManager: {
        ...es.styleManager,
        empty: t('select_an_element_before_using_style_manager'),
        layer: t('layer'),
        fileButton: t('image'),
        sectors: {
          ...(es.styleManager.sectors || {}),
          default: t('default'),
          basic: t('basic'),
          setting: t('setting'),
          settings: t('settings'),
          general: t('general'),
          layout: t('layout'),
          typography: t('typography'),
          decorations: t('decorations'),
          extra: t('extra'),
          flex: t('flex'),
          dimension: t('dimension'),
          display: t('display')
        },
        properties: {
          ...es.styleManager.properties
        }
      },
      traitManager: {
        ...es.traitManager,
        empty: t('select_an_element_before_using_trait_manager'),
        label: t('component_settings'),
        traits: {
          ...(es.traitManager.traits || {}),
          labels: {
            ...(es.traitManager.traits || {}).labels
          },
          attributes: {
            ...(es.traitManager.traits || {}).attributes,
            id: {
              ...((es.traitManager.traits || {}).attributes || {}).id,
              label: t('identifier'),
              placeholder: t('please_enter_identifier')
            },
            alt: {
              ...((es.traitManager.traits || {}).attributes || {}).alt,
              label: t('image_alt_text'),
              placeholder: t('enter_image_alt_text')
            },
            title: {
              ...((es.traitManager.traits || {}).attributes || {}).title,
              label: t('title'),
              placeholder: t('enter_title')
            },
            href: {
              ...((es.traitManager.traits || {}).attributes || {}).href,
              label: t('link'),
              placeholder: `${t('example')}: https://google.com`
            }
          },
          options: {
            ...(es.traitManager.traits.options || {}),
            target: {
              ...((es.traitManager.traits.options || {}).target || {}),
              false: t('this_window'),
              _blank: t('new_window')
            }
          }
        }
      },
      storageManager: {
        ...es.storageManager,
        recover: t('do_you_want_to_recover_unsaved_changes')
      }
    };
  },
  get PT() {
    const { t } = getI18N();
    return {
      ...pt,
      assetManager: {
        ...pt.assetManager,
        addButton: t('add_image'),
        inputPlh: 'http://path/to/the/image.jpg',
        modalTitle: t('select_image'),
        uploadTitle: t('drop_files_here_or_click_to_upload')
      },
      blockManager: {
        ...pt.blockManager,
        categories: {
          ...(pt.blockManager.categories || {}),
          Basic: t('basic'),
          Extra: t('extra'),
          forms: t('form')
        },
        labels: {
          ...(pt.blockManager.labels || {}),
          [PRODUCT_GRID_TILE_CLASSNAMES_PREFIX]: t('product_grid_tile'),
          [CATALOGUE_GRID_TILE_CLASSNAMES_PREFIX]: t('catalogue_grid_tile'),
          'grid-items': t('grid_items'),
          'list-items': t('list_items'),
          column1: t('length_columns', {
            columnLength: 1
          }),
          column2: t('length_columns', {
            columnLength: 2
          }),
          column3: t('length_columns', {
            columnLength: 3
          }),
          column4: t('length_columns', {
            columnLength: 4
          }),
          'column3-7': t('length_columns', {
            columnLength: '3 / 7'
          }),
          text: t('text'),
          link: t('link'),
          image: t('image'),
          video: t('video'),
          map: t('map'),
          'link-block': t('link_block'),
          quote: t('quote'),
          'text-basic': t('text_section'),
          'custom-code': t('custom_code'),
          tooltip: t('tooltip'),
          typed: t('typed'),
          form: t('form'),
          input: t('input'),
          textarea: t('textarea'),
          select: t('select'),
          button: t('button'),
          label: t('label'),
          checkbox: t('checkbox'),
          radio: t('radio')
        }
      },
      domComponents: {
        ...pt.domComponents,
        names: {
          ...(pt.domComponents.names || {}),
          '': t('default'),
          default: t('default'),
          wrapper: t('body'),
          text: t('text'),
          comment: t('comment'),
          image: t('image'),
          video: t('video'),
          label: t('label'),
          link: t('link'),
          map: t('map'),
          tfoot: t('table_footer'),
          tbody: t('table_body'),
          thead: t('table_header'),
          table: t('table'),
          row: t('table_row'),
          cell: t('table_cell'),
          [PRODUCT_GRID_TILE_CLASSNAMES_PREFIX]: t('product_grid_tile'),
          [CATALOGUE_GRID_TILE_CLASSNAMES_PREFIX]: t('catalogue_grid_tile')
        }
      },
      deviceManager: {
        ...pt.deviceManager,
        device: t('device'),
        devices: {
          ...(pt.deviceManager.devices || {}),
          desktop: t('desktop'),
          tablet: t('tablet'),
          mobileLandscape: t('mobile_landscape'),
          mobilePortrait: t('mobile_portrait')
        }
      },
      panels: {
        ...pt.panels,
        buttons: {
          ...(pt.buttons || {}),
          titles: {
            ...((pt.buttons || {}).titles || {}),
            preview: t('preview'),
            fullscreen: t('fullscreen'),
            'sw-visibility': t('view_components'),
            'export-template': t('view_code'),
            'open-sm': t('open_style_manager'),
            'open-tm': t('settings'),
            'open-layers': t('open_layer_manager'),
            'open-blocks': t('open_block_manager')
          }
        }
      },
      selectorManager: {
        ...pt.selectorManager,
        label: t('classes'),
        selected: t('selected'),
        emptyState: t('current_css_state'),
        states: {
          ...(pt.selectorManager.states || {}),
          hover: `${t('hover')} (:hover)`,
          focus: `${t('focus')} (:focus)`,
          'focus-within': `${t('focus_within')} (:focus-within)`,
          active: `${t('active')} (:active)`,
          'nth-of-type(2n)': `${t('odd_or_even')} (:nth-of-type(2n))`
        }
      },
      styleManager: {
        ...pt.styleManager,
        empty: t('select_an_element_before_using_style_manager'),
        layer: t('layer'),
        fileButton: t('image'),
        sectors: {
          ...(pt.styleManager.sectors || {}),
          default: t('default'),
          basic: t('basic'),
          setting: t('setting'),
          settings: t('settings'),
          general: t('general'),
          layout: t('layout'),
          typography: t('typography'),
          decorations: t('decorations'),
          extra: t('extra'),
          flex: t('flex'),
          dimension: t('dimension'),
          display: t('display')
        },
        properties: {
          ...pt.styleManager.properties
        }
      },
      traitManager: {
        ...pt.traitManager,
        empty: t('select_an_element_before_using_trait_manager'),
        label: t('component_settings'),
        traits: {
          ...(pt.traitManager.traits || {}),
          labels: {
            ...(pt.traitManager.traits || {}).labels
          },
          attributes: {
            ...(pt.traitManager.traits || {}).attributes,
            id: {
              ...((pt.traitManager.traits || {}).attributes || {}).id,
              label: t('identifier'),
              placeholder: t('please_enter_identifier')
            },
            alt: {
              ...((pt.traitManager.traits || {}).attributes || {}).alt,
              label: t('image_alt_text'),
              placeholder: t('enter_image_alt_text')
            },
            title: {
              ...((pt.traitManager.traits || {}).attributes || {}).title,
              label: t('title'),
              placeholder: t('enter_title')
            },
            href: {
              ...((pt.traitManager.traits || {}).attributes || {}).href,
              label: t('link'),
              placeholder: `${t('example')}: https://google.com`
            }
          },
          options: {
            ...(pt.traitManager.traits.options || {}),
            target: {
              ...((pt.traitManager.traits.options || {}).target || {}),
              false: t('this_window'),
              _blank: t('new_window')
            }
          }
        }
      },
      storageManager: {
        ...pt.storageManager,
        recover: t('do_you_want_to_recover_unsaved_changes')
      }
    };
  },
  get HI() {
    const { t } = getI18N();
    return {
      assetManager: {
        addButton: t('add_image'),
        inputPlh: 'http://path/to/the/image.jpg',
        modalTitle: t('select_image'),
        uploadTitle: t('drop_files_here_or_click_to_upload')
      },
      blockManager: {
        categories: {
          Basic: t('basic'),
          Extra: t('extra'),
          forms: t('form')
        },
        labels: {
          [PRODUCT_GRID_TILE_CLASSNAMES_PREFIX]: t('product_grid_tile'),
          [CATALOGUE_GRID_TILE_CLASSNAMES_PREFIX]: t('catalogue_grid_tile'),
          'grid-items': t('grid_items'),
          'list-items': t('list_items'),
          column1: t('length_columns', {
            columnLength: 1
          }),
          column2: t('length_columns', {
            columnLength: 2
          }),
          column3: t('length_columns', {
            columnLength: 3
          }),
          column4: t('length_columns', {
            columnLength: 4
          }),
          'column3-7': t('length_columns', {
            columnLength: '3 / 7'
          }),
          text: t('text'),
          link: t('link'),
          image: t('image'),
          video: t('video'),
          map: t('map'),
          'link-block': t('link_block'),
          quote: t('quote'),
          'text-basic': t('text_section'),
          'custom-code': t('custom_code'),
          tooltip: t('tooltip'),
          typed: t('typed'),
          form: t('form'),
          input: t('input'),
          textarea: t('textarea'),
          select: t('select'),
          button: t('button'),
          label: t('label'),
          checkbox: t('checkbox'),
          radio: t('radio')
        }
      },
      domComponents: {
        names: {
          '': t('default'),
          default: t('default'),
          wrapper: t('body'),
          text: t('text'),
          comment: t('comment'),
          image: t('image'),
          video: t('video'),
          label: t('label'),
          link: t('link'),
          map: t('map'),
          tfoot: t('table_footer'),
          tbody: t('table_body'),
          thead: t('table_header'),
          table: t('table'),
          row: t('table_row'),
          cell: t('table_cell'),
          [PRODUCT_GRID_TILE_CLASSNAMES_PREFIX]: t('product_grid_tile'),
          [CATALOGUE_GRID_TILE_CLASSNAMES_PREFIX]: t('catalogue_grid_tile')
        }
      },
      deviceManager: {
        device: t('device'),
        devices: {
          desktop: t('desktop'),
          tablet: t('tablet'),
          mobileLandscape: t('mobile_landscape'),
          mobilePortrait: t('mobile_portrait')
        }
      },
      panels: {
        buttons: {
          titles: {
            preview: t('preview'),
            fullscreen: t('fullscreen'),
            'sw-visibility': t('view_components'),
            'export-template': t('view_code'),
            'open-sm': t('open_style_manager'),
            'open-tm': t('settings'),
            'open-layers': t('open_layer_manager'),
            'open-blocks': t('open_block_manager')
          }
        }
      },
      selectorManager: {
        label: t('classes'),
        selected: t('selected'),
        emptyState: t('current_css_state'),
        states: {
          hover: `${t('hover')} (:hover)`,
          focus: `${t('focus')} (:focus)`,
          'focus-within': `${t('focus_within')} (:focus-within)`,
          active: `${t('active')} (:active)`,
          'nth-of-type(2n)': `${t('odd_or_even')} (:nth-of-type(2n))`
        }
      },
      styleManager: {
        empty: t('select_an_element_before_using_style_manager'),
        layer: t('layer'),
        fileButton: t('image'),
        sectors: {
          default: t('default'),
          basic: t('basic'),
          setting: t('setting'),
          settings: t('settings'),
          general: t('general'),
          layout: t('layout'),
          typography: t('typography'),
          decorations: t('decorations'),
          extra: t('extra'),
          flex: t('flex'),
          dimension: t('dimension'),
          display: t('display')
        }
      },
      traitManager: {
        empty: t('select_an_element_before_using_trait_manager'),
        label: t('component_settings'),
        traits: {
          labels: {
            id: t('identifier'),
            alt: t('image_alt_text'),
            title: t('title'),
            href: t('link')
          },
          attributes: {
            id: {
              label: t('identifier'),
              placeholder: t('please_enter_identifier')
            },
            alt: {
              label: t('image_alt_text'),
              placeholder: t('enter_image_alt_text')
            },
            title: {
              label: t('title'),
              placeholder: t('enter_title')
            },
            href: {
              label: t('link'),
              placeholder: `${t('example')}: https://google.com`
            }
          },
          options: {
            target: {
              false: t('this_window'),
              _blank: t('new_window')
            }
          }
        }
      },
      storageManager: {
        recover: t('do_you_want_to_recover_unsaved_changes')
      }
    };
  }
};

export const GRAPES_JS_DEFAULT_CONFIG = {
  get COMMON() {
    const { t, language } = getI18N();
    const defaultSelectOptions = [
      { id: 'none', name: 'None' },
      { id: 'unset', name: 'Unset' },
      { id: 'revert', name: 'Revert' },
      { id: 'initial', name: 'Initial' },
      { id: 'inherit', name: 'Inherit' }
    ];
    return {
      container: '#gjs',
      fromElement: false,
      height: '100%',
      width: '100%',
      showToolbar: true,
      storageManager: false,
      showOffsets: true,
      avoidInlineStyle: true,
      allowScripts: true,
      allowEventListeners: true,
      withProps: true,
      altQuoteAttr: true,
      i18n: {
        locale: language,
        detectLocale: false,
        localeFallback: 'en',
        messages: {
          en: GRAPES_JS_I18N_CONFIG.EN,
          en_US: GRAPES_JS_I18N_CONFIG.EN,
          es: GRAPES_JS_I18N_CONFIG.ES,
          es_ES: GRAPES_JS_I18N_CONFIG.ES,
          pt: GRAPES_JS_I18N_CONFIG.PT,
          pt_PT: GRAPES_JS_I18N_CONFIG.PT,
          hi: GRAPES_JS_I18N_CONFIG.HI,
          hi_IN: GRAPES_JS_I18N_CONFIG.HI
        }
      },
      assetManager: {
        embedAsBase64: true,
        assets: []
      },
      selectorManager: {
        componentFirst: false
      },
      layerManager: {
        sortable: true,
        hidable: true
      },
      styleManager: {
        sectors: [
          {
            name: 'General',
            properties: [
              {
                extend: 'float',
                type: 'radio',
                default: 'none',
                options: [
                  {
                    value: 'none',
                    className: 'fa fa-times'
                  },
                  {
                    value: 'left',
                    className: 'fa fa-align-left'
                  },
                  {
                    value: 'right',
                    className: 'fa fa-align-right'
                  }
                ]
              },
              {
                extend: 'position',
                type: 'select',
                defaults: 'unset'
              },
              'top',
              'right',
              'left',
              'bottom',
              'overflow-x',
              'overflow-y',
              {
                type: 'select',
                label: 'White space',
                name: 'White space',
                property: 'white-space',
                placeholder: 'Enter white space',
                options: [
                  {
                    id: 'break-spaces',
                    name: 'Break spaces'
                  },
                  {
                    id: 'normal',
                    name: 'Normal'
                  },
                  {
                    id: 'nowrap',
                    name: 'Nowrap'
                  },
                  {
                    id: 'pre',
                    name: 'Pre'
                  },
                  {
                    id: 'pre-line',
                    name: 'Pre line'
                  },
                  {
                    id: 'pre-wrap',
                    name: 'Pre wrap'
                  },
                  ...defaultSelectOptions
                ],
                defaults: 'unset'
              },
              {
                type: 'select',
                label: 'Text overflow',
                name: 'Text overflow',
                property: 'text-overflow',
                placeholder: 'Enter text overflow',
                options: [
                  {
                    id: 'clip',
                    name: 'Clip'
                  },
                  {
                    id: 'ellipsis',
                    name: 'Ellipsis'
                  },
                  ...defaultSelectOptions
                ],
                defaults: 'unset'
              },
              {
                type: 'select',
                label: 'Word wrap',
                name: 'Word wrap',
                property: 'word-wrap',
                placeholder: 'Enter word wrap',
                options: [
                  {
                    id: 'break-word',
                    name: 'Break word'
                  },
                  {
                    id: 'normal',
                    name: 'Normal'
                  },
                  ...defaultSelectOptions
                ],
                defaults: 'unset'
              },
              {
                type: 'select',
                label: 'Word break',
                name: 'Word break',
                property: 'word-break',
                placeholder: 'Enter word break',
                options: [
                  {
                    id: 'break-word',
                    name: 'Break word'
                  },
                  {
                    id: 'break-all',
                    name: 'Break all'
                  },
                  {
                    id: 'keep-all',
                    name: 'Keep all'
                  },
                  {
                    id: 'normal',
                    name: 'Normal'
                  },
                  ...defaultSelectOptions
                ],
                defaults: 'unset'
              },
              {
                type: 'select',
                label: 'Word spacing',
                name: 'Word spacing',
                property: 'word-spacing',
                placeholder: 'Enter word spacing',
                options: [
                  {
                    id: 'normal',
                    name: 'Normal'
                  },
                  ...defaultSelectOptions
                ],
                defaults: 'unset'
              },
              {
                type: 'select',
                label: 'Text wrap',
                name: 'Text wrap',
                property: 'text-wrap',
                placeholder: 'Enter text wrap',
                options: [
                  {
                    id: 'balance',
                    name: 'Balance'
                  },
                  {
                    id: 'nowrap',
                    name: 'Nowrap'
                  },
                  {
                    id: 'wrap',
                    name: 'Wrap'
                  },
                  ...defaultSelectOptions
                ],
                defaults: 'unset'
              },
              {
                type: 'select',
                label: 'Overflow wrap',
                name: 'Overflow wrap',
                property: 'overflow-wrap',
                placeholder: 'Enter overflow wrap',
                options: [
                  {
                    id: 'anywhere',
                    name: 'Anywhere'
                  },
                  {
                    id: 'break-word',
                    name: 'Break word'
                  },
                  {
                    id: 'wrap',
                    name: 'Wrap'
                  },
                  ...defaultSelectOptions
                ],
                defaults: 'unset'
              },
              'opacity'
            ]
          },
          {
            name: 'Dimension',
            open: false,
            properties: [
              {
                name: 'Height',
                property: 'height',
                type: 'text',
                defaults: 'auto'
              },
              {
                name: 'Width',
                property: 'width',
                type: 'text',
                defaults: 'auto'
              },
              {
                name: 'Min height',
                property: 'min-height',
                type: 'text',
                defaults: 'auto'
              },
              {
                name: 'Min width',
                property: 'min-width',
                type: 'text',
                defaults: 'auto'
              },
              {
                name: 'Max height',
                property: 'max-height',
                type: 'text',
                defaults: 'auto'
              },
              {
                name: 'Max width',
                property: 'max-width',
                type: 'text',
                defaults: 'auto'
              },
              {
                name: 'Margin',
                property: 'margin',
                type: 'composite',
                properties: [
                  {
                    name: 'Top',
                    property: 'margin-top',
                    type: 'text',
                    defaults: '0px'
                  },
                  {
                    name: 'Right',
                    property: 'margin-right',
                    type: 'text',
                    defaults: '0px'
                  },
                  {
                    name: 'Bottom',
                    property: 'margin-bottom',
                    type: 'text',
                    defaults: '0px'
                  },
                  {
                    name: 'Left',
                    property: 'margin-left',
                    type: 'text',
                    defaults: '0px'
                  }
                ]
              },
              {
                name: 'Padding',
                property: 'padding',
                type: 'composite',
                properties: [
                  {
                    name: 'Top',
                    property: 'padding-top',
                    type: 'text',
                    defaults: '0px'
                  },
                  {
                    name: 'Right',
                    property: 'padding-right',
                    type: 'text',
                    defaults: '0px'
                  },
                  {
                    name: 'Bottom',
                    property: 'padding-bottom',
                    type: 'text',
                    defaults: '0px'
                  },
                  {
                    name: 'Left',
                    property: 'padding-left',
                    type: 'text',
                    defaults: '0px'
                  }
                ]
              },
              {
                type: 'select',
                label: 'List style position',
                name: 'List style position',
                property: 'list-style-position',
                placeholder: 'Enter list style position',
                options: [
                  {
                    id: 'inside',
                    name: 'Inside'
                  },
                  {
                    id: 'outside',
                    name: 'Outside'
                  },
                  ...defaultSelectOptions
                ],
                defaults: 'unset'
              },
              'list-style-image',
              {
                type: 'select',
                label: 'List style type',
                name: 'List style type',
                property: 'list-style-type',
                placeholder: 'Enter list style type',
                options: [
                  {
                    id: 'auto',
                    name: 'auto'
                  },
                  {
                    id: 'circle',
                    name: 'Circle'
                  },
                  {
                    id: 'decimal',
                    name: 'Decimal'
                  },
                  {
                    id: 'disc',
                    name: 'Disc'
                  },
                  {
                    id: 'square',
                    name: 'Square'
                  },
                  {
                    id: 'disclosure-open',
                    name: 'Disclosure open'
                  },
                  {
                    id: 'disclosure-closed',
                    name: 'Disclosure closed'
                  },
                  ...defaultSelectOptions
                ],
                defaults: 'unset'
              },
              {
                name: 'Margin block start',
                property: 'margin-block-start',
                type: 'integer',
                units: ['px', '%', 'em', 'rem', 'vh', 'vw', ''],
                unit: 'px',
                defaults: '0px'
              },
              {
                name: 'Margin block end',
                property: 'margin-block-end',
                type: 'integer',
                units: ['px', '%', 'em', 'rem', 'vh', 'vw', ''],
                unit: 'px',
                defaults: '0px'
              },
              {
                name: 'Margin inline start',
                property: 'margin-inline-start',
                type: 'integer',
                units: ['px', '%', 'em', 'rem', 'vh', 'vw', ''],
                unit: 'px',
                defaults: '0px'
              }, {
                name: 'Margin inline end',
                property: 'margin-inline-end',
                type: 'integer',
                units: ['px', '%', 'em', 'rem', 'vh', 'vw', ''],
                unit: 'px',
                defaults: '0px'
              },
              {
                name: 'Padding block start',
                property: 'padding-block-start',
                type: 'integer',
                units: ['px', '%', 'em', 'rem', 'vh', 'vw', ''],
                unit: 'px',
                defaults: '0px'
              },
              {
                name: 'Padding block end',
                property: 'padding-block-end',
                type: 'integer',
                units: ['px', '%', 'em', 'rem', 'vh', 'vw', ''],
                unit: 'px',
                defaults: '0px'
              },
              {
                name: 'Padding inline start',
                property: 'padding-inline-start',
                type: 'integer',
                units: ['px', '%', 'em', 'rem', 'vh', 'vw', ''],
                unit: 'px',
                defaults: '0px'
              }, {
                name: 'Padding inline end',
                property: 'padding-inline-end',
                type: 'integer',
                units: ['px', '%', 'em', 'rem', 'vh', 'vw', ''],
                unit: 'px',
                defaults: '0px'
              },
            ]
          },
          {
            name: 'Typography',
            open: false,
            properties: [
              'font-size',
              'font-weight',
              {
                type: 'select',
                label: 'Font style',
                name: 'Font style',
                property: 'font-style',
                placeholder: 'Enter font style',
                options: [
                  {
                    id: 'italic',
                    name: 'Italic'
                  },
                  {
                    id: 'normal',
                    name: 'Normal'
                  },
                  {
                    id: 'oblique',
                    name: 'Oblique'
                  },
                  ...defaultSelectOptions
                ],
                defaults: 'unset'
              },
              {
                type: 'select',
                label: 'Font kerning',
                name: 'Font kerning',
                property: 'font-kerning',
                placeholder: 'Enter font kerning',
                options: [
                  {
                    id: 'auto',
                    name: 'Auto'
                  },
                  {
                    id: 'normal',
                    name: 'Normal'
                  },
                  ...defaultSelectOptions
                ],
                defaults: 'unset'
              },
              'letter-spacing',
              {
                name: 'Line height',
                property: 'line-height',
                type: 'integer',
                units: ['px', '%', 'em', 'rem', 'vh', 'vw', ''],
                unit: ''
              },
              'color',
              {
                extend: 'text-align',
                options: [
                  {
                    id: 'left',
                    label: 'Left',
                    className: 'fa fa-align-left'
                  },
                  {
                    id: 'center',
                    label: 'Center',
                    className: 'fa fa-align-center'
                  },
                  {
                    id: 'right',
                    label: 'Right',
                    className: 'fa fa-align-right'
                  },
                  {
                    id: 'justify',
                    label: 'Justify',
                    className: 'fa fa-align-justify'
                  }
                ]
              },
              {
                type: 'select',
                label: 'Text decoration',
                name: 'Text decoration',
                property: 'text-decoration',
                placeholder: 'Enter text decoration',
                options: [
                  {
                    id: 'auto',
                    name: 'Auto'
                  },
                  {
                    id: 'line-through',
                    name: 'Line through'
                  },
                  {
                    id: 'overline',
                    name: 'Overline'
                  },
                  {
                    id: 'underline',
                    name: 'Underline'
                  },
                  ...defaultSelectOptions
                ],
                defaults: 'unset'
              },
              {
                type: 'select',
                label: 'Text transform',
                name: 'Text transform',
                property: 'text-transform',
                placeholder: 'Enter text transform',
                options: [
                  {
                    id: 'capitalize',
                    name: 'Capitalize'
                  },
                  {
                    id: 'lowercase',
                    name: 'Lowercase'
                  },
                  {
                    id: 'uppercase',
                    name: 'Uppercase'
                  },
                  ...defaultSelectOptions
                ],
                defaults: 'unset'
              },
              'text-shadow',
              {
                name: 'Text indent',
                property: 'text-indent',
                type: 'integer',
                units: ['px', '%', 'em', 'rem', 'vh', 'vw', ''],
                unit: ''
              },
              {
                type: 'select',
                label: 'Text orientation',
                name: 'Text orientation',
                property: 'text-orientation',
                placeholder: 'Enter text orientation',
                options: [
                  {
                    id: 'upright',
                    name: 'Upright'
                  },
                  {
                    id: 'sideways',
                    name: 'Sideways'
                  },
                  {
                    id: 'sideways-right',
                    name: 'Sideways right'
                  },
                  ...defaultSelectOptions
                ],
                defaults: 'unset'
              },
              {
                type: 'select',
                label: 'Text rendering',
                name: 'Text rendering',
                property: 'text-rendering',
                placeholder: 'Enter text rendering',
                options: [
                  {
                    id: 'optimizeSpeed',
                    name: 'Optimize speed'
                  },
                  {
                    id: 'optimizeLegibility',
                    name: 'Optimize legibility'
                  },
                  {
                    id: 'geometricPrecision',
                    name: 'Geometric precision'
                  },
                  {
                    id: 'auto',
                    name: 'Auto'
                  },
                  {
                    id: 'revert-layer',
                    name: 'Revert layer'
                  },
                  ...defaultSelectOptions
                ],
                defaults: 'unset'
              },
              {
                type: 'select',
                label: 'Text direction',
                name: 'Text direction',
                property: 'direction',
                placeholder: 'Enter text direction',
                options: [
                  {
                    id: 'ltr',
                    name: 'Left to right'
                  },
                  {
                    id: 'rtl',
                    name: 'Right to left'
                  },
                  ...defaultSelectOptions
                ],
                defaults: 'unset'
              }
            ]
          },
          {
            name: 'Decorations',
            open: false,
            properties: [
              'border-radius',
              'border-color',
              'border-style',
              'border-width',
              'box-shadow',
              'background-image',
              'background-color',
              'background-repeat',
              'background-size',
              'background-position',
              'background-attachment',
              'background-clip',
              'background-origin',
              'clip-path',
              'outline',
              'filter',
              'perspective',
              'backface-visibility',
              {
                type: 'select',
                name: 'Object Fit',
                property: 'object-fit',
                placeholder: 'Enter object fit',
                defaults: 'unset',
                options: [
                  {
                    id: 'cover',
                    name: 'Cover'
                  },
                  {
                    id: 'contain',
                    name: 'Contain'
                  },
                  {
                    id: 'fill',
                    name: 'Fill'
                  },
                  {
                    id: 'scale-down',
                    name: 'Scale down'
                  },
                  ...defaultSelectOptions
                ]
              },
              {
                type: 'select',
                name: 'Object Position',
                property: 'object-position',
                placeholder: 'Enter object position',
                defaults: 'unset',
                options: [
                  {
                    id: 'top',
                    name: 'Top'
                  },
                  {
                    id: 'bottom',
                    name: 'Bottom'
                  },
                  {
                    id: 'center',
                    name: 'Center'
                  },
                  {
                    id: 'left',
                    name: 'Left'
                  },
                  {
                    id: 'right',
                    name: 'Right'
                  },
                  ...defaultSelectOptions
                ]
              }
            ]
          },
          {
            name: 'Extra',
            open: false,
            buildProps: [
              'transition',
              'perspective',
              'will-change',
              'transform',
              'transform-origin',
              'transform-style'
            ],
            properties: [
              {
                extend: 'filter'
              },
              {
                extend: 'filter',
                property: 'backdrop-filter'
              },
              {
                extend: 'background-color',
                property: 'fill',
                label: 'SVG Fill',
                default: 'none'
              },
              {
                extend: 'background-color',
                property: 'stroke',
                label: 'SVG Stroke',
                default: 'none'
              }
            ]
          },
          {
            name: 'Display',
            open: false,
            properties: [
              {
                name: 'Display Type',
                property: 'display',
                type: 'select',
                list: [
                  {
                    value: 'block',
                    name: 'Block'
                  },
                  {
                    value: 'inline-block',
                    name: 'Inline block'
                  },
                  {
                    value: 'flex',
                    name: 'Flex'
                  },
                  {
                    value: 'inline-flex',
                    name: 'Inline flex'
                  },
                  {
                    value: 'grid',
                    name: 'Grid'
                  },
                  {
                    value: 'inline-grid',
                    name: 'Inline grid'
                  },
                  {
                    value: 'contents',
                    name: 'Contents'
                  },
                  {
                    value: 'inline',
                    name: 'Inline'
                  },
                  {
                    value: 'table',
                    name: 'Table'
                  },
                  {
                    value: 'inline-table',
                    name: 'Inline table'
                  },
                  {
                    value: 'table-row',
                    name: 'Table row'
                  },
                  {
                    value: 'table-row-group',
                    name: 'Table row group'
                  },
                  {
                    value: 'table-column',
                    name: 'Table column'
                  },
                  {
                    value: 'table-column-group',
                    name: 'Table column group'
                  },
                  {
                    value: 'table-caption',
                    name: 'Table caption'
                  },
                  {
                    value: 'table-cell',
                    name: 'Table cell'
                  },
                  {
                    value: 'table-header-group',
                    name: 'Table header group'
                  },
                  {
                    value: 'table-footer-group',
                    name: 'Table footer group'
                  },
                  {
                    value: '-webkit-box',
                    name: 'Webkit box'
                  },
                  {
                    value: '-webkit-inline-box',
                    name: 'Webkit inline box'
                  },
                  {
                    value: 'none',
                    name: 'None'
                  },
                  {
                    value: 'unset',
                    name: 'Unset'
                  }
                ],
                defaults: 'block'
              },
              {
                name: 'Visibility',
                property: 'visibility',
                type: 'select',
                list: [
                  {
                    value: 'visible',
                    name: 'Visible'
                  },
                  {
                    value: 'hidden',
                    name: 'Hidden'
                  },
                  {
                    value: 'collapse',
                    name: 'Collapse'
                  },
                  {
                    value: 'revert',
                    name: 'Revert'
                  },
                  {
                    value: 'unset',
                    name: 'Unset'
                  }
                ],
                defaults: 'visible'
              },
              {
                name: 'Flex direction',
                property: 'flex-direction',
                type: 'radio',
                list: [
                  {
                    value: 'row',
                    name: 'Row',
                    className: 'icons-flex icon-dir-row',
                    title: 'Row'
                  },
                  {
                    value: 'row-reverse',
                    name: 'Row reverse',
                    className: 'icons-flex icon-dir-row-rev',
                    title: 'Row reverse'
                  },
                  {
                    value: 'column',
                    name: 'Column',
                    title: 'Column',
                    className: 'icons-flex icon-dir-col'
                  },
                  {
                    value: 'column-reverse',
                    name: 'Column reverse',
                    title: 'Column reverse',
                    className: 'icons-flex icon-dir-col-rev'
                  }
                ],
                defaults: 'row'
              },
              {
                type: 'select',
                label: 'Flex wrap',
                name: 'Flex wrap',
                placeholder: 'Enter flex wrap',
                options: [
                  {
                    id: 'wrap',
                    name: 'Wrap'
                  },
                  {
                    id: 'nowrap',
                    name: 'No wrap'
                  },
                  {
                    id: 'wrap-reverse',
                    name: 'Wrap reverse'
                  },
                  ...defaultSelectOptions
                ],
                defaults: 'unset'
              },
              {
                name: 'Flex order',
                property: 'order',
                type: 'integer',
                defaults: 0,
                min: 0
              },
              {
                name: 'Flex row gap',
                property: 'row-gap',
                type: 'integer',
                units: ['px', '%', 'em', 'rem', 'vh', 'vw'],
                unit: 'px',
                defaults: 0
              },
              {
                name: 'Flex column gap',
                property: 'column-gap',
                type: 'integer',
                units: ['px', '%', 'em', 'rem', 'vh', 'vw'],
                unit: 'px',
                defaults: 0
              },
              {
                name: 'Justify content',
                property: 'justify-content',
                type: 'radio',
                list: [
                  {
                    value: 'flex-start',
                    className: 'icons-flex icon-just-start',
                    title: 'Start'
                  },
                  {
                    value: 'flex-end',
                    title: 'End',
                    className: 'icons-flex icon-just-end'
                  },
                  {
                    value: 'space-between',
                    title: 'Space between',
                    className: 'icons-flex icon-just-sp-bet'
                  },
                  {
                    value: 'space-around',
                    title: 'Space around',
                    className: 'icons-flex icon-just-sp-ar'
                  },
                  {
                    value: 'stretch',
                    title: 'Stretch',
                    className: 'icons-flex icon-al-str'
                  },
                  {
                    value: 'center',
                    title: 'Center',
                    className: 'icons-flex icon-just-sp-cent'
                  }
                ],
                defaults: 'flex-start'
              },
              {
                name: 'Align items',
                property: 'align-items',
                type: 'radio',
                list: [
                  {
                    value: 'flex-start',
                    className: 'icons-flex icon-just-start',
                    title: 'Start'
                  },
                  {
                    value: 'flex-end',
                    title: 'End',
                    className: 'icons-flex icon-just-end'
                  },
                  {
                    value: 'space-between',
                    title: 'Space between',
                    className: 'icons-flex icon-just-sp-bet'
                  },
                  {
                    value: 'space-around',
                    title: 'Space around',
                    className: 'icons-flex icon-just-sp-ar'
                  },
                  {
                    value: 'stretch',
                    title: 'Stretch',
                    className: 'icons-flex icon-al-str'
                  },
                  {
                    value: 'center',
                    title: 'Center',
                    className: 'icons-flex icon-just-sp-cent'
                  }
                ],
                defaults: 'flex-start'
              },
              {
                name: 'Flex grow / shrink / basis',
                property: 'flex',
                type: 'composite',
                properties: [
                  {
                    name: 'Grow',
                    property: 'flex-grow',
                    type: 'integer',
                    units: ['px', '%', 'em', 'rem', 'vh', 'vw'],
                    defaults: 0,
                    min: 0
                  },
                  {
                    name: 'Shrink',
                    property: 'flex-shrink',
                    type: 'integer',
                    units: ['px', '%', 'em', 'rem', 'vh', 'vw'],
                    defaults: 0,
                    min: 0
                  },
                  {
                    name: 'Basis',
                    property: 'flex-basis',
                    type: 'text',
                    defaults: 'auto',
                    full: true
                  }
                ]
              },
              {
                name: 'Align self',
                property: 'align-self',
                type: 'radio',
                defaults: 'flex-start',
                list: [
                  {
                    value: 'flex-start',
                    className: 'icons-flex icon-just-start',
                    title: 'Start'
                  },
                  {
                    value: 'flex-end',
                    title: 'End',
                    className: 'icons-flex icon-just-end'
                  },
                  {
                    value: 'space-between',
                    title: 'Space between',
                    className: 'icons-flex icon-just-sp-bet'
                  },
                  {
                    value: 'space-around',
                    title: 'Space around',
                    className: 'icons-flex icon-just-sp-ar'
                  },
                  {
                    value: 'stretch',
                    title: 'Stretch',
                    className: 'icons-flex icon-al-str'
                  },
                  {
                    value: 'center',
                    title: 'Center',
                    className: 'icons-flex icon-just-sp-cent'
                  }
                ]
              },
              {
                name: 'Align content',
                property: 'align-content',
                type: 'radio',
                defaults: 'flex-start',
                list: [
                  {
                    value: 'flex-start',
                    className: 'icons-flex icon-just-start',
                    title: 'Start'
                  },
                  {
                    value: 'flex-end',
                    title: 'End',
                    className: 'icons-flex icon-just-end'
                  },
                  {
                    value: 'space-between',
                    title: 'Space between',
                    className: 'icons-flex icon-just-sp-bet'
                  },
                  {
                    value: 'space-around',
                    title: 'Space around',
                    className: 'icons-flex icon-just-sp-ar'
                  },
                  {
                    value: 'stretch',
                    title: 'Stretch',
                    className: 'icons-flex icon-al-str'
                  },
                  {
                    value: 'center',
                    title: 'Center',
                    className: 'icons-flex icon-just-sp-cent'
                  }
                ]
              },
              {
                name: 'Justify self',
                property: 'justify-self',
                type: 'radio',
                defaults: 'flex-start',
                list: [
                  {
                    value: 'flex-start',
                    className: 'icons-flex icon-just-start',
                    title: 'Start'
                  },
                  {
                    value: 'flex-end',
                    title: 'End',
                    className: 'icons-flex icon-just-end'
                  },
                  {
                    value: 'space-between',
                    title: 'Space between',
                    className: 'icons-flex icon-just-sp-bet'
                  },
                  {
                    value: 'space-around',
                    title: 'Space around',
                    className: 'icons-flex icon-just-sp-ar'
                  },
                  {
                    value: 'stretch',
                    title: 'Stretch',
                    className: 'icons-flex icon-al-str'
                  },
                  {
                    value: 'center',
                    title: 'Center',
                    className: 'icons-flex icon-just-sp-cent'
                  }
                ]
              },
              {
                name: 'Justify items',
                property: 'justify-items',
                type: 'radio',
                defaults: 'flex-start',
                list: [
                  {
                    value: 'flex-start',
                    className: 'icons-flex icon-just-start',
                    title: 'Start'
                  },
                  {
                    value: 'flex-end',
                    title: 'End',
                    className: 'icons-flex icon-just-end'
                  },
                  {
                    value: 'space-between',
                    title: 'Space between',
                    className: 'icons-flex icon-just-sp-bet'
                  },
                  {
                    value: 'space-around',
                    title: 'Space around',
                    className: 'icons-flex icon-just-sp-ar'
                  },
                  {
                    value: 'stretch',
                    title: 'Stretch',
                    className: 'icons-flex icon-al-str'
                  },
                  {
                    value: 'center',
                    title: 'Center',
                    className: 'icons-flex icon-just-sp-cent'
                  }
                ]
              },
              {
                type: 'select',
                label: 'Webkit box orient',
                name: 'Webkit box orient',
                property: '-webkit-box-orient',
                placeholder: 'Enter webkit box orient',
                options: [
                  {
                    id: 'horizontal',
                    name: 'Horizontal'
                  },
                  {
                    id: 'vertical',
                    name: 'Vertical'
                  },
                  ...defaultSelectOptions
                ],
                defaults: 'unset'
              },
              {
                name: 'Webkit line clamp',
                property: '-webkit-line-clamp',
                type: 'integer',
                min: 0
              },
              'grid-auto-rows',
              'grid-auto-columns',
              'grid-auto-flow',
              'grid-area',
              'grid-template',
              'grid-template-rows',
              'grid-template-columns',
              'grid-template-areas',
              'grid-row-start',
              'grid-row-end',
              {
                name: 'Grid Row Gap',
                property: 'grid-row-gap',
                type: 'integer',
                units: ['px', '%', 'em', 'rem', 'vh', 'vw']
              },
              'grid-column-start',
              'grid-column-end',
              {
                name: 'Grid Column Gap',
                property: 'grid-column-gap',
                type: 'integer',
                units: ['px', '%', 'em', 'rem', 'vh', 'vw']
              }
            ]
          }
        ]
      },
      blockManager: {
        blocks: [
          {
            id: 'grid-items',
            type: 'grid-items',
            label: t('grid_items'),
            activate: true,
            media: `<img src="${GridIcon}" height="52" width="52" style="height: 52px; width: 52px; object-fit: contain;" />`,
            content: '<table data-gjs-type="table" class="grid-item-row gjs-selected"><tbody data-gjs-type="tbody"><tr data-gjs-type="row"><td data-gjs-type="cell" class="grid-item-cell2-l"><table data-gjs-type="table" class="grid-item-card"><tbody data-gjs-type="tbody"><tr data-gjs-type="row"><td data-gjs-type="cell" class="grid-item-card-cell"><img data-gjs-type="image" src="https://via.placeholder.com/250x150/78c5d6/fff/" alt="Image" class="grid-item-image"><table data-gjs-type="table" class="grid-item-card-body"><tbody data-gjs-type="tbody"><tr data-gjs-type="row"><td data-gjs-type="cell" class="grid-item-card-content"><h1 data-gjs-type="text" class="card-title">Title here</h1><p data-gjs-type="text" class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p></td></tr></tbody></table></td></tr></tbody></table></td><td data-gjs-type="cell" class="grid-item-cell2-r"><table data-gjs-type="table" class="grid-item-card"><tbody data-gjs-type="tbody"><tr data-gjs-type="row"><td data-gjs-type="cell" class="grid-item-card-cell"><img data-gjs-type="image" src="https://via.placeholder.com/250x150/78c5d6/fff/" alt="Image" class="grid-item-image"><table data-gjs-type="table" class="grid-item-card-body"><tbody data-gjs-type="tbody"><tr data-gjs-type="row"><td data-gjs-type="cell" class="grid-item-card-content"><h1 data-gjs-type="text" class="card-title">Title here</h1><p data-gjs-type="text" class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p></td></tr></tbody></table></td></tr></tbody></table></td></tr></tbody></table>',
            category: 'Basic'
          },
          {
            id: 'list-items',
            type: 'list-items',
            label: t('list_items'),
            activate: true,
            media: `<img src="${ListIcon}" height="52" width="52" style="height: 52px; width: 52px; object-fit: contain;" />`,
            content: '<table data-gjs-type="table" class="list-item gjs-selected"><tbody data-gjs-type="tbody"><tr data-gjs-type="row"><td data-gjs-type="cell" class="list-item-cell"><table data-gjs-type="table" class="list-item-content"><tbody data-gjs-type="tbody"><tr data-gjs-type="row" class="list-item-row"><td data-gjs-type="cell" class="list-cell-left"><img data-gjs-type="image" src="https://via.placeholder.com/150/78c5d6/fff" alt="Image" class="list-item-image"></td><td data-gjs-type="cell" class="list-cell-right"><h1 data-gjs-type="text" class="card-title">Title here</h1><p data-gjs-type="text" class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p></td></tr></tbody></table></td></tr></tbody></table><table data-gjs-type="table" class="list-item gjs-selected"><tbody data-gjs-type="tbody"><tr data-gjs-type="row"><td data-gjs-type="cell" class="list-item-cell"><table data-gjs-type="table" class="list-item-content"><tbody data-gjs-type="tbody"><tr data-gjs-type="row" class="list-item-row"><td data-gjs-type="cell" class="list-cell-left"><img data-gjs-type="image" src="https://via.placeholder.com/150/78c5d6/fff" alt="Image" class="list-item-image"></td><td data-gjs-type="cell" class="list-cell-right"><h1 data-gjs-type="text" class="card-title">Title here</h1><p data-gjs-type="text" class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p></td></tr></tbody></table></td></tr></tbody></table>',
            category: 'Basic'
          }
        ]
      },
      wrapper: {
        removable: false,
        badgable: false,
        selectable: false,
        copyable: false,
        draggable: false
      },
      parser: {
        optionsHtml: {
          allowScripts: true,
          allowUnsafeAttr: true
        }
      },
      plugins: GRAPES_JS_PLUGINS
    };
  }
};
