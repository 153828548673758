import { FEATURES_PLAN } from 'qs-api/FeaturePlan/ApiCacheConnector';
import {
  attachFeaturePlanDataListener,
  removeFeaturePlanDataListener,
  getFeaturePlanFromCache,
  getFeaturePlanDataFromRemote
} from 'qs-data-manager/FeaturePlan/FeaturePlan';

export const getCacheKeyForFeaturePlan = () => FEATURES_PLAN.cacheKey;

export const fetchFeaturePlanHandler = ({ listener }) => {
  attachFeaturePlanDataListener({ listener });

  //Cached data exists, don't make a new API call
  if (getFeaturePlanFromCache()) {
    return;
  }
  getFeaturePlanDataFromRemote();
};

export const handleFeaturePlanCleanup = ({ listener }) => {
  removeFeaturePlanDataListener({ listener });
};

export const getFeaturePlanData = () => getFeaturePlanFromCache();
