import React, { useState, useEffect } from 'react';
import toastr from 'toastr';
import { ReactComponent as CloseIcon } from 'qs-assets/Media/delete.svg';
import { ReactComponent as EditIcon } from 'qs-assets/Media/pencil.svg';
import { ReactComponent as SaveIcon } from 'qs-assets/Media/checkmark.svg';
import Checkbox from 'qs-components/Common/CheckBox';
import { toggleGlobalLoader } from 'qs-helpers';
import LabelTitle from '../LabelTitle';
import Mixpanel from 'qs-data-manager/Mixpanel';
import { editCatalogueLabelNameToRemote } from 'qs-data-manager/Catalogues/CatalogueLabels';
import { selectedLabels, selectedLabelsToEdit } from 'qs-data-manager/Selected';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

const Label = ({ data }) => {
  const [editing, setEditing] = useState(false);
  const [label, setLabel] = useState(data.name);
  const [isLabelSelected, setIsLabelSelected] = useState(selectedLabels.isSelected(data.id));
  const [isLabelChecked, setIsLabelChecked] = useState(false);
  const [editingLabelsEnabled, setEditingLabelsEnabled] = useState(false);
  const { t } = getI18N();

  useEffect(() => {
    const onLabelCheck = () => {
      setIsLabelChecked(selectedLabelsToEdit.isSelected(data.id));
    };
    const onLabelSelect = () => {
      setIsLabelSelected(selectedLabels.isSelected(data.id));
    };
    const onEditingLabelsToggle = () => {
      setEditingLabelsEnabled(selectedLabelsToEdit.isActive());
    };

    selectedLabels.addListener(onLabelSelect);
    selectedLabelsToEdit.addActiveListener(onEditingLabelsToggle);
    selectedLabelsToEdit.addListener(onLabelCheck);
    return () => {
      selectedLabels.removeListener(onLabelSelect);
      selectedLabelsToEdit.removeActiveListener(onEditingLabelsToggle);
      selectedLabelsToEdit.removeListener(onLabelCheck);
    };
  }, [data.id]);

  const closeEditingLabel = () => {
    setLabel(data.name);
    setEditing(false);
  };

  const editLabelTitle = async event => {
    event.preventDefault();
    if (!label.trim() || label.trim() === data.name) {
      closeEditingLabel();
      return;
    }
    const key = `editCatalogueLabelName${Date.now()}`;
    toggleGlobalLoader(key, true);
    try {
      await editCatalogueLabelNameToRemote({
        labelId: data.id,
        labelName: label
      });
      Mixpanel.sendEvent({
        eventName: 'catalogue_label_edit',
        props: {
          new_label_name: label,
          label_id: data.id
        }
      });
    } catch (updateError) {
      toastr.error(t('failed_to_update_name_of_catalogue_label'));
      closeEditingLabel();
    } finally {
      setEditing(false);
      toggleGlobalLoader(key, false);
    }
  };

  if (editingLabelsEnabled) {
    return (
      <div className={'catalogueLabelInList labelContent'}>
        <Checkbox
          checked={isLabelChecked}
          onCheckChanged={() => selectedLabelsToEdit.toggle(data.id)}
          styleProps={{ margin: '0 14px 0 0' }}
        />
        {editing ? (
          <>
            <input
              className={'catalogueLabelInput'}
              value={label}
              placeholder={t('enter_label_name')}
              onChange={event => setLabel(event.target.value)}
            />
            <div className={'labelEditOptions'}>
              <SaveIcon className={'catalogueLabelAction'} onClick={editLabelTitle} />
              <CloseIcon className={'catalogueLabelAction'} onClick={closeEditingLabel} />
            </div>
          </>
        ) : (
          <>
            <div
              className={'ellipsis catalogueLabelName'}
              onClick={() => selectedLabelsToEdit.toggle(data.id)}
            >
              {data.name}
            </div>
            <EditIcon className={'catalogueLabelAction'} onClick={() => setEditing(true)} />
          </>
        )}
      </div>
    );
  }

  return (
    <LabelTitle
      title={data.name}
      onClick={() => selectedLabels.toggle(data.id)}
      isActive={isLabelSelected}
    />
  );
};

export default Label;
