import React, { useEffect, useState, useCallback, useMemo, useReducer } from 'react';
import { productListMetaInit, productListMetaReducer, RESET_PRODUCT_LIST_META } from './reducer';
import ProductHeader from './ProductHeader';
import ProductsList from './ProductsList';
import eventbus from 'eventing-bus';
import { ACTIVE_CATALOGUE_META } from 'qs-data-manager/Catalogues';
import NoCatalogueSelected from './NoCatalogueSelected';
import CatalogueLib from 'qs-data-manager/Catalogues';
import { useDropzone } from 'react-dropzone';
import {
  clearProductPositionMapForCatalogue,
  handleProcessedRawFiles,
  setProductPositionMap
} from 'qs-data-manager/Products';
import ConfirmImageUploadModal from './ConfirmImageUploadModal';
import {
  attachProductChangesListener,
  removeProductChangeListener
} from 'qs-services/Firebase/Products';
import Mixpanel from 'qs-data-manager/Mixpanel';
import ImageUploadProgressBar from '../../Common/ImageUploadProgressBar';
import { UPLOAD_PRODUCT_HEADER } from 'qs-helpers/ProcessUploadedImage';
import { getRawFilesFromEvent } from 'qs-helpers/FileUploads/ProcessFilesystemEntries';
import ImageUploadFormat from './ConfirmImageUploadModal/ImageUploadFormat';
import FilterResult from './FilterResult';
import CatalogueProductsError from './ProductsList/CatalogueProductsError';
import CatalogueTags from './ProductsList/CatalogueTags';
import { ActiveCatalogueProductListMeta } from './context';
import {
  attachProductListListener,
  removeProductListListener,
  getProductListFromCache
} from 'qs-data-manager/Products/CatalogueProductsList';
import CacheListenerCallback from 'qs-helpers/CacheListenerCallback';
import { attachFirebaseListeners, removeFirebaseListeners } from 'qs-helpers';
import './styles.scss';

export default ({ changeFlexWidth, headerClass, toggleProductLibrary }) => {
  const [productListMeta, setProductListMeta] = useReducer(
    productListMetaReducer,
    undefined,
    productListMetaInit
  );

  //TODO eventually move it to context of parent catalogue. Same for activeProductId
  const [activeCatalogueId, setActiveCatalogueId] = useState(null);
  const [showHeader, setShowHeader] = useState(false);

  const productListMetaContext = useMemo(
    () => ({
      productListMeta,
      setProductListMeta
    }),
    [productListMeta]
  );

  const isProductListEmpty = useCallback(productList => {
    if (!Array.isArray(productList) || productList.length === 0) {
      return true;
    }
    return false;
  }, []);

  const onDrop = useCallback(acceptedFiles => {
    handleProcessedRawFiles(acceptedFiles);
  }, []);

  const { getRootProps, isDragActive } = useDropzone({
    getFilesFromEvent: event => getRawFilesFromEvent(event),
    onDrop
  });

  useEffect(() => {
    const removeListener = eventbus.on(ACTIVE_CATALOGUE_META.eventbusKey, catalogueId => {
      setActiveCatalogueId(catalogueId);
      setProductListMeta({ type: RESET_PRODUCT_LIST_META });

      Mixpanel.sendEvent({
        eventName: 'START - CatalogueDetailActivity',
        props: {
          catalogueId
        }
      });
    });

    return () => removeListener();
  }, []);

  useEffect(() => {
    //Fetch the product list for this ctalogue to determine whether to show the header or not
    const cachedProductsList = getProductListFromCache({ catalogueId: activeCatalogueId });
    setShowHeader(!isProductListEmpty(cachedProductsList));
    setProductPositionMap({ productList: cachedProductsList, catalogueId: activeCatalogueId });
    const listener = (error, payload) => {
      const { data } = CacheListenerCallback(error, payload);
      if (!data) {
        return;
      }

      setShowHeader(!isProductListEmpty(data.productsList));
      setProductPositionMap({ productList: data.productsList, catalogueId: activeCatalogueId });
    };
    attachProductListListener({ listener, catalogueId: activeCatalogueId });
    return () => {
      removeProductListListener({ listener, catalogueId: activeCatalogueId });
      clearProductPositionMapForCatalogue({ catalogueId: activeCatalogueId });
    };
  }, [activeCatalogueId, isProductListEmpty]);

  useEffect(() => {
    if (activeCatalogueId) {
      CatalogueLib.getCatalogueLinkFromRemote({ catalogueId: activeCatalogueId });
      attachFirebaseListeners(() =>
        attachProductChangesListener({ catalogueId: activeCatalogueId })
      );
    }

    return activeCatalogueId
      ? () =>
          removeFirebaseListeners(() =>
            removeProductChangeListener({ catalogueId: activeCatalogueId })
          )
      : () => {};
  }, [activeCatalogueId, isProductListEmpty]);

  if (!activeCatalogueId) {
    return <NoCatalogueSelected />;
  }

  return (
    <div
      {...getRootProps()}
      id={'ProductListScreen'}
      className={changeFlexWidth ? 'reduceFlexWidth' : ''}
    >
      <ActiveCatalogueProductListMeta.Provider value={productListMetaContext}>
        {showHeader && (
          <ProductHeader activeCatalogueId={activeCatalogueId} headerClass={headerClass} />
        )}
        <ImageUploadProgressBar
          uploadHeader={UPLOAD_PRODUCT_HEADER}
          mappingId={activeCatalogueId}
          containerClassName="imageUploadHeader"
        />
        <CatalogueProductsError catalogueId={activeCatalogueId} />
        <FilterResult catalogueId={activeCatalogueId} />
        <CatalogueTags activeCatalogueId={activeCatalogueId} />
        <ProductsList
          activeCatalogueId={activeCatalogueId}
          toggleProductLibrary={toggleProductLibrary}
        />
        {isDragActive && (
          <div className="DropZone">
            <span>DROP PRODUCTS HERE</span>
          </div>
        )}
        {/* Handles all other image upload cases*/}
        <ConfirmImageUploadModal />
        {/* Handles the case when a folder is uploaded with only files*/}
        <ImageUploadFormat />
      </ActiveCatalogueProductListMeta.Provider>
    </div>
  );
};
