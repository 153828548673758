import React from 'react';
import './styles.scss';

// Consider this string in javascript ->
// "Please specify a *parent product SKU* field, that will help us identify which sizes and/or colors belong to a single product"
// Could you write a code to split this string and extract the text wrapped between *

// "Please specify a *parent product SKU* field, that will help us identify which sizes and/or colors belong to a single product"

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './MuiStyles';
import { getI18N } from 'qs-services/i18N';

export default ({ dismissModal, showDialogue }) => {
  const muiStyles = useStyles();
  const { t } = getI18N();

  const getFormattedText = () => {
    try {
      const headerText = t(
        'please_specify_a_parent_product_sku_field_that_will_help_us_identify_sizes_and_colors'
      );
      const highlightRegex = /\*([^*]+)\*/g;
      let match;
      let matches = [];
      while ((match = highlightRegex.exec())) {
        matches.push(match[1]);
      }
      return headerText.replace(highlightRegex, '<span id="excel-dialogue-parent-sku">$1</span>');
    } catch (error) {
      return t(
        'please_specify_a_parent_product_sku_field_that_will_help_us_identify_sizes_and_colors'
      );
    }
  };

  return (
    <Dialog
      onClose={(_, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          dismissModal();
        }
      }}
      aria-labelledby="simple-dialog-title"
      open={showDialogue}
      classes={{ paper: muiStyles.dialoguePaper }}
    >
      <DialogTitle classes={{ root: muiStyles.titleRoot }}>
        {t('select_parent_product_sku')}
      </DialogTitle>
      <DialogContent>
        <Typography gutterBottom dangerouslySetInnerHTML={{ __html: getFormattedText() }} />
        <Typography classes={{ root: muiStyles.exampleRoot }} gutterBottom>
          {t('example_will_help_us_club_the_different_sizes')}
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button classes={{ label: muiStyles.buttonLabel }} onClick={dismissModal} color="white">
          {t('ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
