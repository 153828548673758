import {
  getProductIsSlabFromCache,
  getProductPriceFromCache,
  getProductSlabPricesFromCache
} from 'qs-data-manager/ProductDetails';
// import { getDiscountPercentage, isProductPriceValid } from 'qs-helpers/Products/ProductPricing';

export const ADD_NEW_SLAB = 'ADD_NEW_SLAB';
export const REMOVE_A_SLAB = 'REMOVE_A_SLAB';
export const FETCH_SLAB_PRICES = 'FETCH_SLAB_PRICES';
export const FETCH_SLAB_PRICES_SUCCESS = 'FETCH_SLAB_PRICES_SUCCESS';
export const FETCH_SLAB_PRICES_FAILURE = 'FETCH_SLAB_PRICES_FAILURE';
export const UPDATE_SLAB_PRICES = 'UPDATE_SLAB_PRICES';
export const UPDATE_SLAB_PRICES_SUCCESS = 'UPDATE_SLAB_PRICES_SUCCESS';
export const UPDATE_SLAB_PRICES_FAILURE = 'UPDATE_SLAB_PRICES_FAILURE';
export const UPDATE_SLAB_PRICE = 'UPDATE_SLAB_PRICE';
export const UPDATE_SLAB_MAX_QUANTITY = 'UPDATE_SLAB_MAX_QUANTITY';
export const RESET_CHANGES = 'RESET_CHANGES';
export const DELETE_SLABS_PRICES = 'DELETE_SLABS_PRICES';
export const DELETE_SLABS_PRICES_SUCCESS = 'DELETE_SLABS_PRICES_SUCCESS';
export const DELETE_SLABS_PRICES_FAILURE = 'DELETE_SLABS_PRICES_FAILURE';
export const UPDATE_DEFAULT_PRICE = 'UPDATE_DEFAULT_PRICE';

export const SLAB_FIELD = {
  PRICE: 'price',
  MAX_QUANTITY: 'maxQuantity'
};

const defaultSlabRange = 99;

const defaultSlab = price => [
  {
    minQuantity: 1,
    maxQuantity: -1,
    price
  }
];

const addNewSlab = (slabs, defaultPrice) => {
  const lastSlab = slabs[slabs.length - 1];
  let { maxQuantity = 0 } = lastSlab;

  if (maxQuantity === -1) {
    // no limit
    // const secondLastSlab = slabs[slabs.length - 2] || {};

    // let range = (secondLastSlab.maxQuantity || 0) - (secondLastSlab.minQuantity || 0) + 1;
    // if (range < 1) {
    //   range = defaultSlabRange;
    // }

    slabs[slabs.length - 1] = {
      ...slabs[slabs.length - 1],
      maxQuantity: slabs[slabs.length - 1].minQuantity + defaultSlabRange
    };
  }

  const newSlab = {
    minQuantity: slabs[slabs.length - 1].maxQuantity + 1,
    maxQuantity: -1,
    price: !slabs.length ? defaultPrice : slabs[slabs.length - 1].price
  };

  return [...slabs, newSlab];
};

const updateMaxRangeOfSlab = ({ slabs, slabIndex, newMaxQuantity }) => {
  const slab = slabs[slabIndex];

  slabs[slabIndex] = {
    ...slabs[slabIndex],
    maxQuantity: newMaxQuantity
  };

  if (slab.minQuantity > newMaxQuantity) {
    // user still typing
    return slabs;
  }

  for (let i = slabIndex + 1; i < slabs.length; i++) {
    const prevMaxQuantity = slabs[i - 1].maxQuantity;

    if (slabs[i].minQuantity <= prevMaxQuantity) {
      slabs[i] = {
        ...slabs[i],
        minQuantity: prevMaxQuantity + 1
      };
    } else if (slabs[i].minQuantity > prevMaxQuantity + 1) {
      slabs[i] = {
        ...slabs[i],
        minQuantity: prevMaxQuantity + 1
      };
    }

    if (slabs[i].maxQuantity <= slabs[i].minQuantity) {
      slabs[i] = {
        ...slabs[i],
        maxQuantity: slabs[i].minQuantity + defaultSlabRange
      };
    }

    if (slabs[i].maxQuantity < slabs[i].minQuantity) {
      break;
    }
  }

  // last one
  slabs[slabs.length - 1] = {
    ...slabs[slabs.length - 1],
    maxQuantity: -1
  };

  return slabs;
};

const deleteASlab = ({ slabs, slabIndex }) => {
  const { minQuantity, maxQuantity } = slabs[slabIndex];

  // slab is last
  if (maxQuantity === -1) {
    slabs[slabIndex - 1] = {
      ...slabs[slabIndex - 1],
      maxQuantity: -1
    };
  } else {
    slabs[slabIndex + 1] = {
      ...slabs[slabIndex + 1],
      minQuantity
    };
  }

  slabs.splice(slabIndex, 1);

  return slabs;
};

// const updateSlabsOnDefaultPriceChange = ({ slabs, newDefaultPrice, oldDefaultPrice }) => {
//   if (!isProductPriceValid(newDefaultPrice)) {
//     return slabs;
//   }

//   let newPriceNum = newDefaultPrice;

//   if (Number.isNaN(newPriceNum)) {
//     newPriceNum = Number(newDefaultPrice);
//   }

//   return slabs.map(({ minQuantity, maxQuantity, price }) => {
//     const discount = getDiscountPercentage(oldDefaultPrice - price, oldDefaultPrice);
//     const newSlabPriceAfterDiscount = (discount * newDefaultPrice) / 100;
//     return { minQuantity, maxQuantity, price: newSlabPriceAfterDiscount };
//   });
// };

export const getSlabPricesFromCacheForProductOrVariant = ({ productId, parentProductId }) => {
  let slabPrices = getProductSlabPricesFromCache({ productId });

  if (parentProductId && slabPrices === null) {
    slabPrices = getProductSlabPricesFromCache({ productId: parentProductId });
  }

  return slabPrices;
};

export const getIsSlabPricesFromCacheForProductOrVariant = ({ productId, parentProductId }) => {
  let isSlab = getProductIsSlabFromCache({ productId });

  if (parentProductId && isSlab === null) {
    isSlab = getProductIsSlabFromCache({ productId: parentProductId });
  }

  return isSlab;
};

export const slabReducerInit = ({ productId, parentProductId, price }) => {
  const slabPrices = getSlabPricesFromCacheForProductOrVariant({
    productId,
    parentProductId
  });
  const isSlab = getIsSlabPricesFromCacheForProductOrVariant({ productId, parentProductId });
  return {
    slabs: slabPrices || [],
    isSlab: isSlab,
    defaultPrice: price || getProductPriceFromCache({ productId }),
    parentProductId
  };
};

export const slabPricesReducer = (state, { type, payload }) => {
  switch (type) {
    case FETCH_SLAB_PRICES: {
      return {
        ...state,
        loading: true,
        error: false,
        slabs: []
      };
    }
    case FETCH_SLAB_PRICES_SUCCESS: {
      let { slabPrices, isSlab } = payload;

      const { parentProductId } = state;
      if (parentProductId && !slabPrices) {
        isSlab = getProductIsSlabFromCache({ productId: parentProductId });
        slabPrices = getProductSlabPricesFromCache({ productId: parentProductId });
      }

      if (!slabPrices) {
        isSlab = false;
        slabPrices = defaultSlab(state.defaultPrice);
      }
      return {
        ...state,
        loading: false,
        error: false,
        slabs: slabPrices,
        isSlab
      };
    }
    case FETCH_SLAB_PRICES_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        slabs: []
      };
    }
    case UPDATE_SLAB_PRICES: {
      return {
        ...state,
        updating: true,
        updateError: false
      };
    }
    case UPDATE_DEFAULT_PRICE: {
      const { defaultPrice } = payload;
      return {
        ...state,
        // slabs: updateSlabsOnDefaultPriceChange({
        //   slabs: state.slabs,
        //   newDefaultPrice: defaultPrice,
        //   oldDefaultPrice: state.defaultPrice
        // }),
        defaultPrice: defaultPrice
      };
    }
    case UPDATE_SLAB_PRICES_SUCCESS: {
      return {
        ...state,
        isSlab: true,
        slabs: payload.slabPrices,
        updating: false,
        updateError: false
      };
    }
    case UPDATE_SLAB_PRICES_FAILURE: {
      const { invalidSlabs, error } = payload;
      return {
        ...state,
        updating: false,
        updateError: { invalidSlabs, error }
      };
    }
    case ADD_NEW_SLAB: {
      return {
        ...state,
        slabs: addNewSlab(state.slabs || [], state.defaultPrice || '')
      };
    }
    case REMOVE_A_SLAB: {
      const { slabIndex = -1 } = payload;
      const deepCopiedSlabs = [...state.slabs];
      const slab = deepCopiedSlabs[slabIndex];

      if (!slab) {
        return state;
      }

      const { invalidSlabs: { invalidSlabIndex } = {} } = state.updateError || {};
      return {
        ...state,
        updateError: slabIndex === invalidSlabIndex ? false : state.updateError,
        slabs: deleteASlab({ slabs: deepCopiedSlabs, slabIndex })
      };
    }
    case UPDATE_SLAB_PRICE: {
      const { slabIndex = -1, updatedPrice } = payload;
      const deepCopiedSlabs = [...state.slabs];
      const slab = deepCopiedSlabs[slabIndex];

      if (!slab) {
        return state;
      }

      const { invalidSlabs: { invalidSlabIndex, invalidSlabField } = {} } = state.updateError || {};

      deepCopiedSlabs[slabIndex] = {
        ...slab,
        price: updatedPrice
      };

      return {
        ...state,
        updateError:
          invalidSlabIndex === slabIndex && invalidSlabField === SLAB_FIELD.PRICE
            ? false
            : state.updateError,
        slabs: deepCopiedSlabs
      };
    }
    case UPDATE_SLAB_MAX_QUANTITY: {
      const { slabIndex = -1, newMaxQuantity } = payload;
      const deepCopiedSlabs = [...state.slabs];
      const slab = deepCopiedSlabs[slabIndex];

      if (!slab) {
        return state;
      }

      const { invalidSlabs: { invalidSlabIndex, invalidSlabField } = {} } = state.updateError || {};

      return {
        ...state,
        updateError:
          invalidSlabIndex === slabIndex && invalidSlabField === SLAB_FIELD.MAX_QUANTITY
            ? false
            : state.updateError,
        slabs: updateMaxRangeOfSlab({
          slabs: deepCopiedSlabs,
          slabIndex,
          newMaxQuantity
        })
      };
    }

    case RESET_CHANGES: {
      let { slabs, isSlab } = payload;

      if (!slabs) {
        isSlab = false;
        slabs = defaultSlab(state.defaultPrice);
      }

      return {
        ...state,
        loading: false,
        error: false,
        updating: false,
        updateError: false,
        slabs,
        isSlab
      };
    }

    case DELETE_SLABS_PRICES: {
      return {
        ...state,
        deleting: true
      };
    }

    case DELETE_SLABS_PRICES_SUCCESS: {
      return {
        ...state,
        deleting: false,
        slabs: defaultSlab(state.defaultPrice),
        isSlab: null
      };
    }

    case DELETE_SLABS_PRICES_FAILURE: {
      return {
        ...state,
        deleting: false
      };
    }

    default:
      return state;
  }
};
