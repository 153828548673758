import React, { useEffect, useState } from 'react';
import eventbus from 'eventing-bus';
import { UPLOAD_IMAGE_FORMAT, openImageUploadModal } from 'qs-data-manager/Products';
import { unpackSingleFolderFiles } from 'qs-helpers/FileUploads/ProcessUploadedFiles';
import { RawFileData } from 'qs-helpers/FileUploads/RawFileData';
import { getActiveCatalogueId } from 'qs-data-manager/Catalogues';
import { DialogDarkTheme } from '../../../../Common/DarkThemeDialog';
import { DialogTitle, DialogContent, Button, makeStyles } from '@material-ui/core';
import { computeDataUrlFromFile } from 'qs-helpers';
import ErrorPopup from '../../../../Common/ErrorPopup';
import PrepareImageUpload from '../PrepareImageUpload';
import { ReactComponent as Arrow } from 'qs-assets/Media/icon_right_arrow.svg';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

const useButtonStyles = makeStyles({
  root: {
    backgroundColor: '#168c5b',
    alignSelf: 'center'
  }
});

export default () => {
  const [modalData, setModalData] = useState({});
  const [uploadErrorMessage, setUploadErrorMessage] = useState();
  const [firstFourImages, setFirstFourImages] = useState(['', '', '', '']);
  const [createMultiple, setCreateMultiple] = useState(true);
  const [showImageUploader, setShowImageUploader] = useState(false);
  const muiButtonStyles = useButtonStyles();

  const { t } = getI18N();

  useEffect(() => {
    const computeImage = async file => {
      try {
        return await computeDataUrlFromFile(file);
      } catch (error) {
        //No-op, added to prevent uncaught errors
      }
    };

    const computeFirstFourImages = async rawFolderData => {
      const imageComputePromises = [];
      for (let index = 0; index < Math.min(rawFolderData.files.length, 4); index++) {
        const file = rawFolderData.files[index];
        imageComputePromises.push(computeImage(file));
      }

      const computedImages = await Promise.all(imageComputePromises);
      setFirstFourImages(firstFourImages.map((_, index) => computedImages[index]));
    };

    const removeEventbus = eventbus.on(UPLOAD_IMAGE_FORMAT.eventbusKey, data => {
      const { rawFolderData } = data;
      if (!(rawFolderData instanceof RawFileData)) {
        setModalData({
          showFormatModal: false,
          rawFolderData: undefined
        });
        return;
      }

      computeFirstFourImages(rawFolderData);
      setModalData({
        showFormatModal: true,
        rawFolderData
      });
    });

    return removeEventbus;
  }, [setModalData, setFirstFourImages, firstFourImages]);

  const closeModal = () => {
    setModalData({});
    setShowImageUploader(false);
    setCreateMultiple(true);
    setFirstFourImages(['', '', '', '']);
    setUploadErrorMessage();
  };

  const proceedToNextStep = async rawFileDataForUpdate => {
    openImageUploadModal({ files: rawFileDataForUpdate, catalogueId: getActiveCatalogueId() });
    closeModal();
  };

  const handleSingleProductCreation = event => {
    event.preventDefault();
    proceedToNextStep([modalData.rawFolderData]);
  };

  const handleMultipleProductCreation = async event => {
    event.preventDefault();
    const unpackedFiles = unpackSingleFolderFiles(modalData.rawFolderData);
    proceedToNextStep(unpackedFiles);
  };

  const getImageContent = (pictureUrl, contentClass, showPlaceholder) => {
    if (showPlaceholder || typeof pictureUrl !== 'string') {
      return <div className={`${contentClass} placeholderImage`} />;
    }

    if (pictureUrl === '') {
      return <div className={`${contentClass} animateImageLoading`} />;
    }

    return (
      <div
        className={`${contentClass} backgroundImage`}
        style={{ backgroundImage: `url(${pictureUrl})` }}
      />
    );
  };

  const getSingleProductUploadFormat = () => {
    const remainingPictures = [];
    for (let index = 1; index < firstFourImages.length; index++) {
      const additionalPictureUrl = firstFourImages[index];
      remainingPictures.push(
        <div className="additionalPictureContainer" key={index}>
          {getImageContent(additionalPictureUrl, 'additionalPicture', createMultiple)}
        </div>
      );
    }

    const defaultProductImage = firstFourImages[0];
    return (
      <>
        {getImageContent(defaultProductImage, 'defaultImage', createMultiple)}
        <div className="additionalProductPictures">{remainingPictures}</div>
      </>
    );
  };

  const getImageRowContent = startIndex => {
    const rowContent = [];
    for (
      let index = startIndex;
      index < Math.min(firstFourImages.length, 2 + startIndex);
      index++
    ) {
      const imageDataUrl = firstFourImages[index];
      rowContent.push(getImageContent(imageDataUrl, 'productImageContainer', !createMultiple));
    }

    return <div className="unpackUploadProductsImages">{rowContent}</div>;
  };

  const getMultipleProductContent = () => {
    return (
      <>
        {getImageRowContent(0)}
        {getImageRowContent(2)}
      </>
    );
  };

  const { showFormatModal, rawFolderData } = modalData;

  if (!showFormatModal) {
    return null;
  }

  if (uploadErrorMessage) {
    return (
      <ErrorPopup
        title={t('upload_images_error')}
        onClose={closeModal}
        mailProps={{
          subject: t('image_upload_from_single_folder_failure'),
          body: uploadErrorMessage
        }}
      >
        {t('something_went_wrong_while_uploading_the_images')}
      </ErrorPopup>
    );
  }

  if (showImageUploader) {
    return <PrepareImageUpload onClose={closeModal} />;
  }

  return (
    <DialogDarkTheme open={true} maxWidth={'xs'} onClose={closeModal}>
      <DialogTitle className="imageUploadFormatTitle">{t('select_upload_mode')}</DialogTitle>
      <DialogContent className="imageUploadFormatContentContainer">
        <div className="imageUploadFormatContainer">
          <div
            className={`unpackUploadProductsContainer ${createMultiple ? 'selected' : ''}`}
            onClick={() => setCreateMultiple(true)}
          >
            <div className="imageTitle">
              {t('count_new_products', { count: rawFolderData.filesLength })}
            </div>
            {getMultipleProductContent()}
          </div>
          <div className="sectionSeperator" />
          <div
            className={`singleUploadProductContainer ${createMultiple ? '' : 'selected'}`}
            onClick={() => setCreateMultiple(false)}
          >
            <div className="imageTitle">
              {t('count_product_with_count_images', {
                productCount: 1,
                imageComputePromises: rawFolderData.filesLength
              })}
            </div>
            {getSingleProductUploadFormat()}
          </div>
        </div>
        <Button
          variant="contained"
          onClick={createMultiple ? handleMultipleProductCreation : handleSingleProductCreation}
          color="primary"
          classes={{ root: muiButtonStyles.root }}
          endIcon={<Arrow className={'arrow'} />}
        >
          {createMultiple
            ? t('create_count_new_products', { count: rawFolderData.filesLength })
            : t('create_count_product_with_count_images', {
                productCount: 1,
                imageCount: rawFolderData.filesLength
              })}
        </Button>
      </DialogContent>
    </DialogDarkTheme>
  );
};
