import React, { useState, useEffect } from 'react';
import eventbus from 'eventing-bus';

import './styles.css';

const LOADER_QUEUE = {};
export const GLOBAL_LOADER_KEY = 'GLOBAL_LOADER_KEY';

export default () => {
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    eventbus.on(GLOBAL_LOADER_KEY, (uniqueKey, value) => {
      if (!uniqueKey || typeof value !== 'boolean') {
        return;
      }

      if (value) {
        LOADER_QUEUE[uniqueKey] = true;
        if (!loader) {
          setLoader(true);
        }
      } else {
        delete LOADER_QUEUE[uniqueKey];
        if (!Object.keys(LOADER_QUEUE).length) {
          setLoader(false);
        }
      }
    });
  }, [loader]);

  if (!loader) {
    return null;
  }

  return (
    <div className="progress">
      <div className="indeterminate" />
    </div>
  );
};
