import { AVAILABLE_DOMAIN_DATA } from 'qs-api/Domain/ApiCacheConnector';
import CacheRequest from '../CacheRequest';

export const getDomainDataFromCache = () =>
  CacheRequest.getCacheForKey(AVAILABLE_DOMAIN_DATA.cacheKey);

export const setDomainDataInCache = domainData =>
  CacheRequest.setCacheForKey(AVAILABLE_DOMAIN_DATA.cacheKey, { data: domainData });

export const attachDomainDataListner = ({ listener }) =>
  CacheRequest.attachListener(AVAILABLE_DOMAIN_DATA.cacheKey, listener);

export const removeDomainDataListener = ({ listener }) =>
  CacheRequest.removeListener(AVAILABLE_DOMAIN_DATA.cacheKey, listener);

export const getDomainDataFromRemote = async () => {
  const key = `${AVAILABLE_DOMAIN_DATA.cacheKey}`;
  const apiName = AVAILABLE_DOMAIN_DATA.apiFunction;
  CacheRequest.makeRequest(key, apiName, {
    params: [],
    options: {
      shouldNotStoreInNative: true
    }
  });
};
