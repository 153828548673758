import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import toastr from 'toastr';

import './AddTagInput.scss';
import { ReactComponent as TagIcon } from 'qs-assets/Media/tag-icon.svg';
import { ReactComponent as InfoIcon } from 'qs-assets/Media/info-filled.svg';
// import { ReactComponent as BulbIcon } from 'qs-assets/Media/light-bulb.svg';
import { ReactComponent as TimesIcon } from 'qs-assets/Media/close.svg';

import Tags from 'qs-data-manager/Tags';
import { getI18N } from 'qs-services/i18N';

class AddTagInput extends PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = {
      tagValue: '',
      tipVisible: false
    };
  }

  onTagValueChange = e => {
    this.setState({ tagValue: e.target.value });
  };

  onInfoPress = () => {
    this.setState({ tipVisible: !this.state.tipVisible });
  };

  onInfoClose = () => {
    this.setState({ tipVisible: false });
  };

  showNoTagError = () => {
    const { t } = getI18N();

    toastr.error(t('type_a_sub_category_name'), null, {
      positionClass: 'toast-bottom-right',
      iconClass: 'tags-toast-error-icon'
    });
  };

  onSubmit = () => {
    const { tagValue } = this.state;
    const { onSubmit } = this.props;

    if (!tagValue) {
      this.showNoTagError();
      return;
    }

    if (Tags.isTagValid(tagValue)) {
      this.setState({ tagValue: '' });
    }

    onSubmit(tagValue);
  };

  onKeyDown = e => {
    if (e.keyCode === 13) {
      this.onSubmit();
    }
  };

  render() {
    const { tagValue, tipVisible } = this.state;
    const { t } = getI18N();

    return (
      <div className="addTagInput">
        <div className="infoButton" onClick={this.onInfoPress}>
          <InfoIcon fill="#7CD3A3" className="infoButtonIcon" />
        </div>

        <div className="inputContainer">
          <input
            autoFocus
            className="input"
            placeholder={t('type_a_sub_category_name')}
            maxLength={30}
            value={tagValue}
            onChange={this.onTagValueChange}
            onKeyDown={this.onKeyDown}
          />
        </div>

        <div className="submitButton" onClick={this.onSubmit}>
          <div className="submitButtonWrapper">
            <TagIcon fill="#fff" className="submitButtonTagIcon" />
            <div className="submitButtonPlusIcon">
              <span className="submitButtonPlusIconText">+</span>
            </div>
          </div>
        </div>

        {tipVisible && (
          <div className="tipContainer">
            <div className="tipHeader">
              {/*<BulbIcon fill="#62B788" className="tipHeaderIcon" />*/}

              <div className="tipHeaderText">{t('tip')}</div>

              <div className="tipCloseButton" onClick={this.onInfoClose}>
                <TimesIcon fill="#D6D6D6" className="tipCloseIcon" />
              </div>
            </div>

            <div className="tipContent">
              <span className="tipContentText">
                {t('try_to_keep_sub_category_names_as_short_as_possible')}
              </span>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default AddTagInput;
