import { updateRemoteWithDeviceId } from 'qs-helpers';
import { v4 as uuidv4 } from 'uuid';

let cachedUniqueDeviceId = null;

export const getUniqueDeviceId = () => {
  return cachedUniqueDeviceId;
};

export const getCachedUniqueDeviceId = () => {
  if (cachedUniqueDeviceId !== null) {
    return cachedUniqueDeviceId;
  }

  return localStorage.getItem('UNIQUE_DEVICE_ID');
};

export const saveIdToNativeStorage = uniqueDeviceId => {
  if (uniqueDeviceId && uniqueDeviceId !== 'null') {
    localStorage.setItem('UNIQUE_DEVICE_ID', uniqueDeviceId);
  }
};

export const generateUniqueDeviceIdIfNotExists = ({ companyId }) => {
  let uniqueId = getCachedUniqueDeviceId();
  //Add an explicit null check because the string form of null may have been saved
  if (uniqueId && uniqueId !== 'null') {
    cachedUniqueDeviceId = uniqueId;
    saveIdToNativeStorage(cachedUniqueDeviceId);
    return cachedUniqueDeviceId;
  }

  uniqueId = uuidv4();
  saveIdToNativeStorage(uniqueId);
  updateRemoteWithDeviceId({
    companyId,
    uniqueId,
    osType: 'WEB',
    device: navigator.userAgent
  });
  cachedUniqueDeviceId = uniqueId;
};
