import { CATALOGUE_PUBLISH_TO_HOMEPAGE } from 'qs-api/Catalogues/ApiCacheConnector';
import { setHomepagePublish } from 'qs-api/Catalogues/api';
import { reportError } from 'qs-helpers/ErrorReporting';
import CacheRequest from '../CacheRequest';

const createCacheKeyForPublishToHomepage = catalogueId =>
  `${CATALOGUE_PUBLISH_TO_HOMEPAGE.cacheKey}${catalogueId}`;

export const setPublishToHomepageInCache = ({ catalogueIds, published }) => {
  for (let i = 0; i < catalogueIds.length; i++) {
    CacheRequest.setCacheForKey(createCacheKeyForPublishToHomepage(catalogueIds[i]), { published });
  }
};

export const getPublishToHomepageFromCache = catalogueId =>
  CacheRequest.getCacheForKey(createCacheKeyForPublishToHomepage(catalogueId));

export const attachPublishToHomepageListener = ({ listener, catalogueId }) => {
  CacheRequest.attachListener(createCacheKeyForPublishToHomepage(catalogueId), listener);
};

export const removePublishToHomepageListener = ({ catalogueId, listener }) => {
  CacheRequest.removeListener(createCacheKeyForPublishToHomepage(catalogueId), listener);
};

export const updatePublishToHomepage = async ({ catalogueIds, published }) => {
  if (catalogueIds.length === 0) {
    return;
  }
  try {
    await setHomepagePublish({
      catalogueIds,
      publish: published
    });
    setPublishToHomepageInCache({ catalogueIds, published });
  } catch (updateError) {
    reportError(updateError);
    throw updateError;
  }
};
