import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { Button, TextField } from '@material-ui/core';
import Modal from 'react-responsive-modal';
import { ReactComponent as DeleteIcon } from 'qs-assets/Media/trash.svg';
import { getActiveProductId } from 'qs-data-manager/Products';
import {
  deleteSlabPricesForProduct,
  getSlabPricesForProduct,
  setSlabPricesForProduct
} from 'qs-data-manager/ProductDetails';
import PriceInput from './PriceInput';
import toastr from 'toastr';
import Loader from '../../../../../Common/Loader';
import {
  ADD_NEW_SLAB,
  DELETE_SLABS_PRICES,
  DELETE_SLABS_PRICES_FAILURE,
  DELETE_SLABS_PRICES_SUCCESS,
  FETCH_SLAB_PRICES,
  FETCH_SLAB_PRICES_FAILURE,
  FETCH_SLAB_PRICES_SUCCESS,
  getIsSlabPricesFromCacheForProductOrVariant,
  getSlabPricesFromCacheForProductOrVariant,
  REMOVE_A_SLAB,
  RESET_CHANGES,
  slabPricesReducer,
  slabReducerInit,
  SLAB_FIELD,
  UPDATE_DEFAULT_PRICE,
  UPDATE_SLAB_MAX_QUANTITY,
  UPDATE_SLAB_PRICE,
  UPDATE_SLAB_PRICES,
  UPDATE_SLAB_PRICES_FAILURE,
  UPDATE_SLAB_PRICES_SUCCESS
} from './slab.reducer';
import { getFormmateedPriceString } from 'qs-helpers/Orders/OrderPriceCalculator';
import { getDiscountPercentage } from 'qs-helpers/Products/ProductPricing';
import BulkDiscount from '../../../ActiveTabMeta/BasicInfo/ProductPriceDiscountAndSet/ProductDiscount/BulkEditDiscount/BulkEditDiscount';
import { roundNumberToGivenDecimals } from 'qs-helpers';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

const DISCOUNT_INIT_STATE = {
  open: false,
  discount: null,
  onSave: null
};

export default ({
  productId: activeProductId,
  showBulkDiscountButton,
  buttonClassName,
  price,
  disabled,
  isSet,
  setBulkDiscountState
}) => {
  if (!showBulkDiscountButton) {
    return null;
  }

  const { t } = getI18N();

  const activeId = getActiveProductId();
  const parentProductId = activeProductId === activeId ? undefined : activeId;
  const productId = activeProductId || parentProductId;
  const [showBulkDiscountModal, setShowBulkDiscountModal] = useState(false);
  const [showDiscardConfirmationBox, setShowDiscardConfirmationBox] = useState(false);
  const [showDiscountModalForSlab, setShowDiscountModalForSlab] = useState(DISCOUNT_INIT_STATE);

  const [slabPricesMetaData, dispatchSlabPricesMetaDataAction] = useReducer(
    slabPricesReducer,
    {
      productId,
      parentProductId,
      price
    },
    slabReducerInit
  );

  const {
    isSlab,
    slabs,
    loading,
    error,
    updating,
    updateError,
    deleting,
    defaultPrice
  } = slabPricesMetaData;

  const slabsRef = useRef(slabs);
  slabsRef.current = slabs;

  const productIdRef = useRef(productId);
  productIdRef.current = productId;

  const parentProductIdRef = useRef(parentProductId);
  parentProductIdRef.current = parentProductId;

  const priceRef = useRef(defaultPrice);
  priceRef.current = defaultPrice;

  const modalStyles = useRef({
    backgroundColor: 'white',
    borderRadius: 6,
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    width: '500px'
  });

  const openBulkDiscountModal = useCallback(() => {
    setShowBulkDiscountModal(true);
  }, []);

  const closeBulkDiscountModal = useCallback(({ forceClose } = {}) => {
    let changed = false;
    if (!forceClose) {
      const savedSlabs = getSlabPricesFromCacheForProductOrVariant({
        productId: productIdRef.current,
        parentProductId: parentProductIdRef.current
      });
      const updatedSlabs = slabsRef.current;

      const notADefaultSlab = slabsToCheck => {
        if (!Array.isArray(slabsToCheck) || slabsToCheck.length !== 1) {
          return true;
        }

        const { minQuantity, maxQuantity, price } = slabsToCheck[0];

        return !(minQuantity === 1 && maxQuantity === -1 && price === priceRef.current);
      };

      if (!savedSlabs && !updatedSlabs) {
        changed = false;
      } else if (!savedSlabs && updatedSlabs && notADefaultSlab(updatedSlabs)) {
        changed = true;
      } else if (Array.isArray(savedSlabs) && Array.isArray(updatedSlabs)) {
        if (savedSlabs.length !== updatedSlabs.length) {
          changed = true;
        } else {
          // deep compare
          changed = updatedSlabs.some(({ price, minQuantity, maxQuantity }, index) => {
            const {
              price: prevPrice,
              minQuantity: prevMinQuantity,
              maxQuantity: prevMaxQuantity
            } = savedSlabs[index];

            return (
              Number(price) !== prevPrice ||
              minQuantity !== prevMinQuantity ||
              maxQuantity !== prevMaxQuantity
            );
          });
        }
      }
    }

    if (changed) {
      setShowDiscardConfirmationBox(true);
      return;
    }
    setShowBulkDiscountModal(false);
  }, []);

  const closeShowDiscardConfirmationBox = () => {
    setShowDiscardConfirmationBox(false);
  };

  const confirmDiscardChanges = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();
      dispatchSlabPricesMetaDataAction({
        type: RESET_CHANGES,
        payload: {
          slabs: getSlabPricesFromCacheForProductOrVariant({ productId, parentProductId }),
          isSlab: getIsSlabPricesFromCacheForProductOrVariant({ productId, parentProductId })
        }
      });
      setShowDiscardConfirmationBox(false);
      setShowBulkDiscountModal(false);
    },
    [productId, parentProductId]
  );

  const fetchAndUpdateSlabs = useCallback(() => {
    const fetchData = async () => {
      try {
        dispatchSlabPricesMetaDataAction({ type: FETCH_SLAB_PRICES });
        let { isSlab, slabPrices } = await getSlabPricesForProduct({ productId });
        dispatchSlabPricesMetaDataAction({
          type: FETCH_SLAB_PRICES_SUCCESS,
          payload: { isSlab, slabPrices }
        });
      } catch (error) {
        dispatchSlabPricesMetaDataAction({ type: FETCH_SLAB_PRICES_FAILURE, payload: { error } });
      }
    };
    fetchData();
  }, [productId]);

  const updateSlabPrices = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();
      const updateSlabs = async () => {
        try {
          const slabPrices = slabs.map(slab => ({
            ...slab,
            price: slab.price === '' ? null : Number(slab.price)
          }));

          const isPriceInValid = priceValue => !priceValue && priceValue !== 0;

          const invalidSlabIndex = slabPrices.findIndex(
            ({ price, minQuantity, maxQuantity }, index) =>
              isPriceInValid(price) ||
              price > priceRef.current ||
              !maxQuantity ||
              (minQuantity > maxQuantity && maxQuantity !== -1) ||
              (index < slabs.length - 1 && maxQuantity < 0)
          );
          if (invalidSlabIndex !== -1) {
            const { minQuantity, maxQuantity, price } = slabs[invalidSlabIndex];
            let invalidSlabField = SLAB_FIELD.PRICE;

            let errorMessage = '';
            if (price === '' || price === null) {
              errorMessage = t('price_cannot_be_empty');
            } else if (Number(price) > priceRef.current) {
              errorMessage = t('price_can_not_be_more_than_actual_price');
            }

            if (
              !maxQuantity ||
              (minQuantity > maxQuantity && maxQuantity !== -1) ||
              (invalidSlabIndex < slabs.length - 1 && maxQuantity < 0)
            ) {
              invalidSlabField = SLAB_FIELD.MAX_QUANTITY;
              errorMessage =
                invalidSlabIndex < slabs.length - 2 && maxQuantity < 0
                  ? t('max_quantity_cannot_be_negative')
                  : !maxQuantity
                  ? maxQuantity === 0
                    ? t('max_quantity_cannot_be_zero')
                    : t('max_quantity_cannot_be_empty')
                  : t('max_quanity_should_be_greater_than_or_equal_min_quantity');
            }

            if (errorMessage) {
              toastr.error(errorMessage);
            }

            dispatchSlabPricesMetaDataAction({
              type: UPDATE_SLAB_PRICES_FAILURE,
              payload: { invalidSlabs: { invalidSlabIndex, invalidSlabField } }
            });
            return;
          }
          dispatchSlabPricesMetaDataAction({ type: UPDATE_SLAB_PRICES });
          await setSlabPricesForProduct({
            productId,
            slabPrices
          });
          dispatchSlabPricesMetaDataAction({
            type: UPDATE_SLAB_PRICES_SUCCESS,
            payload: { slabPrices }
          });
          closeBulkDiscountModal({ forceClose: true });
        } catch (error) {
          dispatchSlabPricesMetaDataAction({
            type: UPDATE_SLAB_PRICES_FAILURE,
            payload: { error }
          });
        }
      };
      updateSlabs();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [closeBulkDiscountModal, productId, slabs]
  );

  const deleteSlabPrices = useCallback(() => {
    const deletePrices = async () => {
      try {
        dispatchSlabPricesMetaDataAction({ type: DELETE_SLABS_PRICES });
        await deleteSlabPricesForProduct({ productId });
        dispatchSlabPricesMetaDataAction({ type: DELETE_SLABS_PRICES_SUCCESS });
      } catch (error) {
        toastr.error(error ? error.message : t('could_not_delete_quantity_based_discount'));
        dispatchSlabPricesMetaDataAction({ type: DELETE_SLABS_PRICES_FAILURE });
      }
    };
    deletePrices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  const addNewSlabPrice = () => {
    dispatchSlabPricesMetaDataAction({ type: ADD_NEW_SLAB });
  };

  const deleteSlab = slabIndex => () =>
    dispatchSlabPricesMetaDataAction({
      type: REMOVE_A_SLAB,
      payload: { slabIndex }
    });

  const updateMaxRangeOfSlab = slabIndex => changeEvent => {
    const newValueStr = changeEvent.target.value;
    dispatchSlabPricesMetaDataAction({
      type: UPDATE_SLAB_MAX_QUANTITY,
      payload: { slabIndex, newMaxQuantity: newValueStr ? Number(newValueStr) : '' }
    });
  };

  const updateSlabPrice = slabIndex => updatedPrice => {
    // saving string values for to avoid unnecessory processing
    dispatchSlabPricesMetaDataAction({
      type: UPDATE_SLAB_PRICE,
      payload: { slabIndex, updatedPrice }
    });
  };

  const showModalFromSingleDiscount = ({ index: slabIndex, discountInPercent }) => () => {
    setShowDiscountModalForSlab({
      open: true,
      discount: discountInPercent,
      onSave: newDiscount => {
        const newPrice = roundNumberToGivenDecimals(((100 - newDiscount) * defaultPrice) / 100, 2);
        updateSlabPrice(slabIndex)(newPrice);
        closeModalFromSingleDiscount();
      }
    });
  };

  const closeModalFromSingleDiscount = () => {
    setShowDiscountModalForSlab(DISCOUNT_INIT_STATE);
  };

  const isErroredField = (index, fieldKey) => {
    const { invalidSlabs } = updateError || {};

    if (!invalidSlabs) {
      return false;
    }

    const { invalidSlabIndex, invalidSlabField } = invalidSlabs || {};

    if (invalidSlabIndex === index) {
      return fieldKey === invalidSlabField;
    }

    return false;
  };

  useEffect(() => {
    fetchAndUpdateSlabs();
  }, [fetchAndUpdateSlabs]);

  useEffect(() => {
    if (loading || updating || deleting) {
      return;
    }

    setBulkDiscountState({ isSlabBulkDiscountEnabled: isSlab });
  }, [isSlab, setBulkDiscountState, loading, updating, deleting]);

  useEffect(() => {
    if (!updateError) {
      return;
    }
    const { error, invalidSlabs } = updateError;

    if (error) {
      toastr.error(error.message || t('something_went_wrong_while_updating_the_slab_prices'));
      return;
    }

    if (invalidSlabs) {
      const firstInvalidField = document.querySelector('.input-field.errored');
      if (!firstInvalidField) {
        return;
      }

      if (typeof firstInvalidField.focus === 'function') {
        firstInvalidField.focus();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateError]);

  useEffect(() => {
    dispatchSlabPricesMetaDataAction({
      type: UPDATE_DEFAULT_PRICE,
      payload: { defaultPrice: price }
    });
  }, [price]);

  const onScrollSlabs = () => {
    const slabsList = document.querySelector('.bulk-discount-content');
    if (!slabsList) {
      return;
    }
    const { scrollTop } = slabsList;

    const slabsListHeading = document.querySelector('.bulk-discount-content .heading.slab-row');
    if (!slabsListHeading) {
      return;
    }

    if (scrollTop > 0) {
      slabsListHeading.style.boxShadow = '0px 1px 13px -2px #cfcfcf';

      return;
    }

    slabsListHeading.style.boxShadow = 'none';
  };

  const renderSlabPricesContent = () => {
    if (loading) {
      return (
        <div className="slab-row loading">
          <Loader size="small" />
        </div>
      );
    }

    if (error) {
      return (
        <div className="slab-row error">
          {t('could_not_fetch_slab_prices')}
          <button className="try-again-button" onClick={fetchAndUpdateSlabs}>
            {t('retry')}
          </button>
        </div>
      );
    }

    if (!slabs) {
      return null;
    }

    return slabs.map((slab, index) => {
      const { minQuantity, maxQuantity, price } = slab;
      const isErroredMaxQuantity = isErroredField(index, SLAB_FIELD.MAX_QUANTITY);
      const isErroredPrice = isErroredField(index, SLAB_FIELD.PRICE);
      const discountInPercent = price === '' ? null : getDiscountPercentage(price, defaultPrice);
      return (
        <div className="slab-row" key={index}>
          <div className="range-min">{minQuantity}</div>
          <div className="range-saparator">to</div>
          <div className="range-max">
            {maxQuantity !== -1 || index < slabs.length - 1 ? (
              <TextField
                className={`range-max-input`}
                inputProps={{
                  className: `input-field ${isErroredMaxQuantity ? 'errored' : ''}`,
                  min: 0
                }}
                color={isErroredMaxQuantity ? 'secondary' : 'primary'}
                type="number"
                variant="outlined"
                value={maxQuantity}
                onChange={updateMaxRangeOfSlab(index)}
              />
            ) : (
              t('no_limit')
            )}
          </div>
          <div className="discounted-price">
            <div className="price-details">
              <PriceInput
                inputProps={{
                  className: `input-field  ${isErroredPrice ? 'errored' : ''}`
                }}
                color={isErroredPrice ? 'secondary' : 'primary'}
                margin={price}
                onChange={updateSlabPrice(index)}
              />
              {!!discountInPercent && (
                <div
                  onClick={showModalFromSingleDiscount({ index, discountInPercent })}
                  className={`discountInPercent${discountInPercent < 0 ? ' errored' : ''}`}
                >
                  {discountInPercent}% {t('discount')}
                </div>
              )}
            </div>
            {slabs.length > 1 && <DeleteIcon className="delete-icon" onClick={deleteSlab(index)} />}
          </div>
        </div>
      );
    });
  };

  const renderBulkPriceModal = () => (
    <Modal
      open={showBulkDiscountModal}
      showCloseIcon={false}
      onClose={closeBulkDiscountModal}
      styles={{ modal: modalStyles.current }}
    >
      <form onSubmit={updateSlabPrices}>
        <div className="bulk-discount-modal">
          <div className="bulk-discount-title">
            <div className="title">{t('quantity_based_discount')}</div>
            <Button
              className="add-price-slab-button"
              variant="outlined"
              color="primary"
              onClick={addNewSlabPrice}
            >
              <span className="add">+</span>
              <span>{t('add_price_slab')}</span>
            </Button>
          </div>
          <div className="bulk-discount-content" onScroll={onScrollSlabs}>
            <div className="heading slab-row">
              <div className="range-min"></div>
              <div className="range-saparator"></div>
              <div className="range-max">{t('max_qty')}</div>
              <div className="discounted-price">{t('discounted_price')}</div>
            </div>
            {renderSlabPricesContent()}
          </div>
          <div className="bulk-discount-footer">
            <div className="left-footer-content">
              <Button className="close-btn" variant="text" onClick={closeBulkDiscountModal}>
                {t('close')}
              </Button>
            </div>
            <div className="right-footer-content">
              {updating && <Loader size="extraSmall" />}
              <Button
                className="set-pricing"
                variant="text"
                color="primary"
                type="submit"
                disabled={updating}
                onClick={updateSlabPrices}
              >
                {t('set_pricing')}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );

  const renderDiscardConfirmationBox = () => (
    <Modal
      open={showDiscardConfirmationBox}
      showCloseIcon={false}
      onClose={closeShowDiscardConfirmationBox}
      styles={{ modal: { ...modalStyles.current, width: '370px' } }}
    >
      <div className="bulk-discount-modal confirmation-modal">
        <div className="bulk-discount-title">{t('unsaved_changes')}</div>
        <div className="bulk-discount-content">
          <p>{t('you_have_unsaved_changes_do_you_want_to_discard')}</p>
        </div>
        <div className="bulk-discount-footer">
          <div className="left-footer-content" />
          <div className="left-footer-content">
            <Button variant="text" onClick={closeShowDiscardConfirmationBox}>
              {t('cancel')}
            </Button>
            <Button variant="text" color="primary" onClick={confirmDiscardChanges}>
              {t('discard')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );

  const renderDiscountWisePriceModal = () => {
    const { open, discount, onSave } = showDiscountModalForSlab;

    if (!open) {
      return null;
    }

    return (
      <BulkDiscount
        visible={open}
        initialPercentage={discount}
        closeModal={closeModalFromSingleDiscount}
        onSetPercentClick={onSave}
      />
    );
  };

  const renderBulkPricing = () => {
    const savedIsSlab = getIsSlabPricesFromCacheForProductOrVariant({ productId, parentProductId });
    const savedSlabs = getSlabPricesFromCacheForProductOrVariant({ productId, parentProductId });
    if (savedIsSlab && Array.isArray(savedSlabs) && price) {
      return (
        <div className="formFieldContainer slab-prices-details-container">
          <div className="rowContainer slab-prices-details">
            <div className="rowHeading">{t('bulk_pricing')}</div>
            {slabs.map(({ price, minQuantity, maxQuantity }, index) => (
              <div className="slab-detail" key={index}>
                <span className="slab-range">{`${minQuantity} ${maxQuantity === -1 ? 'or' : '-'} ${
                  maxQuantity === -1 ? 'more' : maxQuantity
                },`}</span>
                <span>{`${getFormmateedPriceString({ price })} per ${isSet ? 'set' : 'pc'}`}</span>
              </div>
            ))}
            <div className="delete-bulk-slab-prices-option">
              <button
                className="delete-bulk-slab-prices-btn"
                onClick={deleteSlabPrices}
                disabled={deleting}
              >
                {t('remove_bulk_pricing')}
              </button>
              {deleting && <Loader size="extraSmall" />}
            </div>
          </div>
          <div className="right-section">
            <button
              className="change-bulk-discount-btn changeButton"
              onClick={openBulkDiscountModal}
            >
              {t('change')}
            </button>
          </div>
        </div>
      );
    }

    return (
      <button
        className={`bulkDiscountButton ${buttonClassName}${disabled ? ' disabled' : ''}`}
        disabled={disabled}
        onClick={openBulkDiscountModal}
      >
        {t('quantity_based_discount')}
      </button>
    );
  };

  if (loading) {
    return <Loader size="small" />;
  }

  return (
    <>
      {renderBulkPricing()}
      {renderBulkPriceModal()}
      {renderDiscardConfirmationBox()}
      {renderDiscountWisePriceModal()}
    </>
  );
};
