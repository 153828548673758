import { openWhatsApp } from 'qs-helpers';

export const handleCallClick = visitorPhone => {
  const callAnchor = document.createElement('a');
  callAnchor.href = `tel:${visitorPhone}`;
  callAnchor.click();
};

export const handleChatClick = visitorPhone => {
  const text = 'Hello';
  let contact = visitorPhone;
  if (visitorPhone.charAt(0) === '+') {
    contact = contact.slice(1);
  }
  openWhatsApp({ text, contact });
};
