export const BOTTOM_SHEET_TABS = {
  BASIC_INFO: 'BASIC_INFO',
  VARIANTS: 'VARIANTS',
  INVENTORY: 'INVENTORY',
  PRIVATE_NOTES: 'PRIVATE_NOTES',
  CUSTOM: 'CUSTOM'
};

export const SAVE_BUTTON_META = {
  PRIVATE_NOTES: {
    id: 'PRIVATE_NOTES'
  },
  PRODUCT_SKU: {
    id: 'PRODUCT_SKU'
  },
  PRODUCT_TITLE: {
    id: 'PRODUCT_TITLE'
  },
  PRODUCT_PRICE: {
    id: 'PRODUCT_PRICE'
  },
  PRODUCT_DISCOUNT: {
    id: 'PRODUCT_DISCOUNT'
  },
  PRODUCT_DESCRIPTION: {
    id: 'PRODUCT_DESCRIPTION'
  },
  PRODUCT_CUSTOM_FIELD: {
    id: 'PRODUCT_CUSTOM_FIELD'
  },
  PRODUCT_WEIGHT_FIELD: {
    id: 'PRODUCT_WEIGHT_FIELD'
  },
  PRODUCT_SET_FIELD: {
    id: 'PRODUCT_SET_FIELD'
  },
  PRODUCT_SET_TYPE: {
    id: 'PRODUCT_SET_TYPE'
  },
  PRODUCT_SET_NAME_FIELD: {
    id: 'PRODUCT_SET_NAME_FIELD'
  },
  PRODUCT_SET_QTY_FIELD: {
    id: 'PRODUCT_SET_QTY_FIELD'
  },
  PRODUCT_MOQ_FIELD: {
    id: 'PRODUCT_MOQ_FIELD'
  },
  PRODUCT_TYPE_DETAILS: {
    id: 'PRODUCT_TYPE_DETAILS'
  },
  PRODUCT_ENTITY_TYPE: {
    id: 'PRODUCT_ENTITY_TYPE'
  },
  PRODUCT_ENTITY_LABEL: {
    id: 'PRODUCT_ENTITY_LABEL'
  },
  PRODUCT_ENTITY_QUALITY: {
    id: 'PRODUCT_ENTITY_QUALITY'
  },
  PRODUCT_GROSS_WEIGHT: {
    id: 'PRODUCT_GROSS_WEIGHT'
  },
  PRODUCT_NET_WEIGHT: {
    id: 'PRODUCT_NET_WEIGHT'
  },
  PRODUCT_MAKING_CHARGE_TYPE: {
    id: 'PRODUCT_MAKING_CHARGE_TYPE'
  },
  PRODUCT_MAKING_CHARGE: {
    id: 'PRODUCT_MAKING_CHARGE'
  },
  PRODUCT_OTHER_CHARGES: {
    id: 'PRODUCT_OTHER_CHARGES'
  },
  PRODUCT_OTHER_CHARGE_TYPE: {
    id: 'PRODUCT_OTHER_CHARGE_TYPE'
  },
  PRODUCT_OTHER_CHARGE_AMOUNT: {
    id: 'PRODUCT_OTHER_CHARGE_AMOUNT'
  },
  PRODUCT_WASTAGE_CHARGE: {
    id: 'PRODUCT_WASTAGE_CHARGE'
  },
  PRODUCT_WASTAGE_CHARGE_TYPE: {
    id: 'PRODUCT_WASTAGE_CHARGE_TYPE'
  },
  eventbusKey: 'SAVE_BUTTON_EVENTBUS_KEY',
  eventType: {
    AUTO_SAVE: {
      id: 'AUTO_SAVE'
    },
    REGISTER_VALIDATOR: {
      id: 'REGISTER_VALIDATOR'
    },
    UNREGISTER_VALIDATOR: {
      id: 'UNREGISTER_VALIDATOR'
    },
    PRODUCT_META: {
      id: 'PRODUCT_META'
    }
  }
};

export const PRICE_FILTER_KEYS_CONFIG = {
  EQUALS: {
    id: 'EQUALS',
    keyMap: { key: 'eq', analyticsKey: 'price_filter_applied' }
  },
  RANGE: {
    id: 'RANGE',
    keyMap: {
      gtKey: 'gt',
      ltKey: 'lt',
      analyticsGtKey: 'filter_max_price',
      analyticsLtKey: 'filter_min_price'
    }
  },
  LESS_THAN: {
    id: 'LESS_THAN',
    keyMap: { key: 'lt', analyticsKey: 'filter_min_price' }
  },
  MORE_THAN: {
    id: 'MORE_THAN',
    keyMap: { key: 'gt', analyticsKey: 'filter_max_price' }
  }
};

export const PRICE_FILTER_OPTIONS = [
  { id: 'EQUALS', title: 'equals', key: PRICE_FILTER_KEYS_CONFIG.EQUALS.keyMap.key },
  { id: 'RANGE', title: 'range', key: PRICE_FILTER_KEYS_CONFIG.RANGE.keyMap.gtKey },
  { id: 'LESS_THAN', title: 'less_than', key: PRICE_FILTER_KEYS_CONFIG.LESS_THAN.keyMap.key },
  { id: 'MORE_THAN', title: 'more_than', key: PRICE_FILTER_KEYS_CONFIG.MORE_THAN.keyMap.key }
];

export const IN_STOCK_TAG = 'In stock';

export const OUT_OF_STOCK_TAG = 'Out of stock';

export const NUMERIC_FIELD_PRICE_FILTER_OPTIONS = {
  EQUALS: {
    title: 'is',
    displayValue: 'is',
    placeholder: {
      eq: 'enter_rank'
    }
  },
  RANGE: {
    title: 'is_between',
    displayValue: 'is_between',
    placeholder: {
      gt: 'enter_min_rank',
      lt: 'enter_max_rank'
    }
  },
  LESS_THAN: {
    title: 'is less than',
    displayValue: 'is_less_than',
    placeholder: {
      lt: 'enter_max_rank'
    }
  },
  MORE_THAN: {
    title: 'is greater than',
    displayValue: 'is_greater_than',
    placeholder: {
      gt: 'enter_min_rank'
    }
  }
};

export const CUSTOM_NUMERIC_FIELD_FILTER_OPTIONS = [
  {
    value: 'EQUALS',
    displayValue: NUMERIC_FIELD_PRICE_FILTER_OPTIONS.EQUALS.displayValue
  },
  {
    value: 'LESS_THAN',
    displayValue: NUMERIC_FIELD_PRICE_FILTER_OPTIONS.LESS_THAN.displayValue
  },
  {
    value: 'MORE_THAN',
    displayValue: NUMERIC_FIELD_PRICE_FILTER_OPTIONS.MORE_THAN.displayValue
  },
  {
    value: 'RANGE',
    displayValue: NUMERIC_FIELD_PRICE_FILTER_OPTIONS.RANGE.displayValue
  }
];

export const SEARCH_ACTIVE = 'SEARCH_ACTIVE';
export const EDIT_ACTIVE = 'EDIT_ACTIVE';
export const REORDER_ACTIVE = 'REORDER_ACTIVE';
export const REORDER_DEACTIVE = 'REORDER_DEACTIVE';
export const HEADLINE_COMPONENT_HEIGHT = 50;

export const ACTION_BUTTONS = [
  {
    key: 'try_and_buy',
    value: 'try_and_buy'
  },
  {
    key: 'send_inquiry',
    value: 'send_inquiry'
  },
  {
    key: 'buy_now',
    value: 'buy_now'
  },
  {
    key: 'add_to_list',
    value: 'add_to_list'
  },
  {
    key: 'add_to_cart',
    value: 'add_to_cart'
  },
  {
    key: 'request_quote',
    value: 'request_quote'
  }
];

export const TYPE_OF_LAYOUTS = [
  {
    title: 'grid_view',
    info: 'for_large_no_of_products',
    field: 'grid'
  },
  {
    title: 'list_view',
    info: 'recommended_for_fmcg_grocery_food_delivery_and_similar',
    field: 'list-view'
  },
  {
    title: 'magazine_view',
    info: 'for_smaller_no_of_products',
    field: 'list'
  }
];

export const SORT_PRODUCTS_OPTIONS = [
  {
    title: 'name',
    subTitle: 'a_to_z',
    sortType: 'ALPHABETICAL',
    sortOrder: 'ASC'
  },
  {
    title: 'name',
    subTitle: 'z_to_a',
    sortType: 'ALPHABETICAL',
    sortOrder: 'DESC'
  },
  {
    title: 'price',
    subTitle: 'low_to_high',
    sortType: 'PRICE',
    sortOrder: 'ASC'
  },
  {
    title: 'price',
    subTitle: 'high_to_low',
    sortType: 'PRICE',
    sortOrder: 'DESC'
  },
  {
    title: 'random',
    subTitle: 'shuffle_products',
    sortType: 'RANDOM'
  },
  {
    title: 'date_uploaded',
    subTitle: 'newest_on_top',
    sortType: 'DATE_UPLOADED',
    sortOrder: 'ASC'
  },
  {
    title: 'date_uploaded',
    subTitle: 'oldest_on_top',
    sortType: 'DATE_UPLOADED',
    sortOrder: 'DESC'
  }
];

export const COMPANY_UNIT_TYPES = {
  PIECE: 'piece',
  SET: 'set'
};

export const COMPANY_UNIT_VALUES = {
  PER_SET: 'PER_SET',
  AS_SET: 'AS_SET',
  AS_PIECE: 'AS_PIECE',
  PER_PIECE: 'PER_PIECE'
};
