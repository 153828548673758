import { getCompanyCurrencySymbol, getCompanyCurrencySymbolOptions } from 'qs-data-manager/Company';
import { roundNumberToGivenDecimals } from '..';
import { AMOUNT_FLAT } from './constants';
import {
  getFlatValueIfEstimateIsClose,
  getShippingCostFromInquiryData,
  isCouponFlatAmount
} from './ResponseProcessor';

export const getFormmateedPriceString = ({ price }) => {
  const currencySymbol = getCompanyCurrencySymbol();
  const { symbolOnRight } = getCompanyCurrencySymbolOptions() || {};

  const formattedPrice = price;

  if (symbolOnRight) {
    return `${formattedPrice} ${currencySymbol}`;
  }

  return `${currencySymbol} ${formattedPrice}`;
};

export const getPriceAfterDiscount = ({ price, discountedPrice, coupon }) => {
  const { discountPercent = 0, applyOnDiscountedPrice } = coupon || {};

  const originalPrice = price || null;
  const priceNum = Number(price);

  const originalDiscountedPrice = discountedPrice || null;
  const discountedPriceNum = Number(discountedPrice);

  /*
    If the applyOnDiscountedPrice is a boolean and if it is true then if the
    discountedPrice exists then the discount will be applied on the discountedPrice
    However, if applyOnDiscountedPrice is not a boolean but the discountedPrice
    exists then the final price will be the discounted price.
  */
  if (originalDiscountedPrice !== null) {
    if (applyOnDiscountedPrice === true) {
      //The final discount will be calculated based on the discountedPrice.
      //If it is invalid or less than zero then no need to process it further
      if (discountedPriceNum >= 0) {
        return roundNumberToGivenDecimals(discountedPriceNum * (discountPercent / 100), 2);
      }
    }
    return -1;
  }

  /*
    Discounted price does not exist or the coupon discount cannot be applied
    to the discountedPrice then apply the discount on the original price
  */
  if (originalPrice !== null && priceNum >= 0) {
    return roundNumberToGivenDecimals(priceNum * (discountPercent / 100), 2);
  }

  return -1;
};

export const getDiscountOnOrderEntry = ({ discountedPrice, sellingPrice }) => {
  if (!(typeof sellingPrice === 'number' && typeof discountedPrice === 'number')) {
    return 0;
  }

  const discountOnEntry = sellingPrice - discountedPrice;
  if (discountOnEntry >= 0) {
    return discountOnEntry;
  }

  return 0;
};

export const getDiscountedOrSellingPrice = ({ discountedPrice, sellingPrice }) => {
  if (typeof discountedPrice === 'number') {
    return discountedPrice;
  }

  if (typeof sellingPrice === 'number') {
    return sellingPrice;
  }

  return null;
};

export const getOrderTotalBreakdown = inquiryData => {
  const { inquiries: orderEntries, coupon, orderDiscount, orderDiscountType } = inquiryData;
  if (!Array.isArray(orderEntries)) {
    return {
      baseTotal: 0,
      couponDiscountTotal: 0,
      customDiscountTotal: 0,
      tax: 0
    };
  }

  const orderTotalBreakdown = orderEntries.reduce(
    (
      totalBreakdown,
      {
        couponDiscountPrice,
        customDiscountPrice,
        sellingPrice,
        taxAmount,
        isDeletedByVisitor,
        itemCount
      }
    ) => {
      if (isDeletedByVisitor) {
        return totalBreakdown;
      }

      if (typeof sellingPrice === 'number') {
        totalBreakdown.baseTotal += sellingPrice * itemCount;
      }

      totalBreakdown.couponDiscountTotal +=
        getDiscountOnOrderEntry({ discountedPrice: couponDiscountPrice, sellingPrice }) * itemCount;

      totalBreakdown.customDiscountTotal +=
        getDiscountOnOrderEntry({
          discountedPrice: customDiscountPrice,
          sellingPrice: getDiscountedOrSellingPrice({
            discountedPrice: couponDiscountPrice,
            sellingPrice
          })
        }) * itemCount;

      totalBreakdown.tax += typeof taxAmount === 'number' ? taxAmount * itemCount : 0;

      return totalBreakdown;
    },
    {
      baseTotal: 0,
      customDiscountTotal: 0,
      couponDiscountTotal: 0,
      tax: 0
    }
  );

  orderTotalBreakdown.baseTotal = roundNumberToGivenDecimals(orderTotalBreakdown.baseTotal);
  orderTotalBreakdown.couponDiscountTotal = roundNumberToGivenDecimals(
    orderTotalBreakdown.couponDiscountTotal
  );

  if (isCouponFlatAmount(coupon)) {
    orderTotalBreakdown.couponDiscountTotal = getFlatValueIfEstimateIsClose({
      flatValue: coupon.discountAmount,
      estimatedValue: orderTotalBreakdown.couponDiscountTotal
    });
  }

  orderTotalBreakdown.customDiscountTotal = roundNumberToGivenDecimals(
    orderTotalBreakdown.customDiscountTotal
  );

  if (orderDiscountType === AMOUNT_FLAT && typeof orderDiscount === 'number' && orderDiscount > 0) {
    orderTotalBreakdown.customDiscountTotal = getFlatValueIfEstimateIsClose({
      flatValue: orderDiscount,
      estimatedValue: orderTotalBreakdown.customDiscountTotal
    });
  }

  orderTotalBreakdown.tax = roundNumberToGivenDecimals(orderTotalBreakdown.tax);

  return orderTotalBreakdown;
};

export const getCouponDiscountAmount = ({ amount, coupon }) => {
  if (typeof amount !== 'number' || !(coupon && coupon.discountPercent)) {
    return 0;
  }

  const { discountPercent } = coupon;
  return roundNumberToGivenDecimals((amount * discountPercent) / 100);
};

export const getMinimumPriceForOrder = inquiryData => {
  const { inquiries } = inquiryData;
  const shippingCost = getShippingCostFromInquiryData(inquiryData);
  let totalOrderPrice = inquiries.reduce((accumalatedVal, currVal) => {
    const { itemCount, isDeletedByVisitor, finalPrice } = currVal;

    //Inquiry is deleted by visitor simply ignore this inquiry's price
    if (isDeletedByVisitor) {
      return accumalatedVal;
    }

    accumalatedVal += (finalPrice || 0) * itemCount;
    return accumalatedVal;
  }, 0);

  if (typeof shippingCost === 'number') {
    totalOrderPrice += shippingCost;
  }

  return roundNumberToGivenDecimals(totalOrderPrice, 2);
};
