import React from 'react';
import SelectInput from 'qs-components/Common/SelectInput';
import { VARIANT_ADD_TABS } from 'qs-helpers/Variants/constants';
import { UPDATE_SELECTED_VARIANT } from '../../../reducer';
import {
  getPlaceholderInputData,
  renderValueForSelect,
  keepArrayNoSelectionValueSameForSelect
} from 'qs-helpers/SelectInputHelpers';
import './styles.scss';
import OutOfStockLabel from '../../../../../Common/OutOfStockLabel';
import { getI18N } from 'qs-services/i18N';

export default ({ variants, variantSelection, setProductListMeta }) => {
  const { t } = getI18N();
  const noSelectionValue = [];

  // Ensure that the empty value is the same as the empty value passed to the selection input
  // this will ensure that the no selection state is maintained
  const colorValue = keepArrayNoSelectionValueSameForSelect(
    variantSelection[VARIANT_ADD_TABS.COLORS],
    noSelectionValue
  );
  const sizeValue = keepArrayNoSelectionValueSameForSelect(
    variantSelection[VARIANT_ADD_TABS.SIZES],
    noSelectionValue
  );

  const customValue = optionType => {
    return keepArrayNoSelectionValueSameForSelect(variantSelection[optionType], noSelectionValue);
  };

  const onCustomChange = (event, customVariant) => {
    setProductListMeta({
      type: UPDATE_SELECTED_VARIANT,
      variantType: customVariant,
      variantValues: event.target.value
    });
  };

  const onSizeChange = event => {
    setProductListMeta({
      type: UPDATE_SELECTED_VARIANT,
      variantType: VARIANT_ADD_TABS.SIZES,
      variantValues: event.target.value
    });
  };

  const onColorChange = event => {
    setProductListMeta({
      type: UPDATE_SELECTED_VARIANT,
      variantType: VARIANT_ADD_TABS.COLORS,
      variantValues: event.target.value
    });
  };

  if (Array.isArray(variants) && variants.length === 0) {
    return null;
  }

  const firstLetterUpperCase = string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const renderVariantDisplayValue = (rgbValue, optionValue, outOfStock) => {
    let optionElement = <span className="ellipsis">{optionValue}</span>;
    let displayValue = optionElement;
    if (Array.isArray(rgbValue)) {
      displayValue = (
        <div className="valueIconContainer">
          <div
            style={{ backgroundColor: `rgb(${rgbValue[0]}, ${rgbValue[1]}, ${rgbValue[2]})` }}
            className="variantColor"
          />
          {optionElement}
        </div>
      );
    }

    return (
      <div className="variantProductFilterContainer variantOptionValueContainer">
        {displayValue}
        {outOfStock && <OutOfStockLabel outOfStockClass={'outOfStock'} />}
      </div>
    );
  };

  return (
    <>
      <div className="variantProductFilterContainer titleContainer">{t('filter_by_variant')}</div>
      <div className="variantProductFilterContainer variantOptionsContainer">
        {variants.map(({ variantType, variantValues }, index) => {
          let valueForSelect = customValue(variantType),
            onChangeHandler = e => onCustomChange(e, variantType),
            optionLabel = firstLetterUpperCase(variantType.toLowerCase());
          if (variantType === VARIANT_ADD_TABS.COLORS) {
            valueForSelect = colorValue;
            onChangeHandler = onColorChange;
            optionLabel = t('colors');
          }
          if (variantType === VARIANT_ADD_TABS.SIZES) {
            valueForSelect = sizeValue;
            onChangeHandler = onSizeChange;
            optionLabel = t('sizes');
          }

          return (
            <div className="variantRowContainer" key={index}>
              <div className="optionLabel">{optionLabel}</div>
              <SelectInput
                containerClass="selectInput"
                displayEmpty
                noSelectionValue={noSelectionValue}
                onChange={onChangeHandler}
                multiple={true}
                value={valueForSelect}
                renderValue={selected =>
                  renderValueForSelect(selected, {
                    seperator: ', ',
                    placeholder: t('select_value')
                  })
                }
                options={variantValues.reduce(
                  (cumulativeArray, { optionValue, rgbValue, outOfStock }) => {
                    cumulativeArray.push({
                      value: optionValue,
                      displayValue: renderVariantDisplayValue(rgbValue, optionValue, outOfStock)
                    });
                    return cumulativeArray;
                  },
                  [getPlaceholderInputData({ displayValue: t('select_value') })]
                )}
                materialStyles={{
                  backgroundColor: 'transparent',
                  border: '1px solid #59c18f',
                  unselectedBorder: '1px solid #59c18f',
                  unselectTextColor: '#000',
                  selectTextColor: '#000',
                  formSelectionBorder: '1px solid #59c18f',
                  unselectedHoverFill: '#4da47a',
                  selectedHoverFill: '#4da47a',
                  selectedHoverColor: '#fff',
                  unselectedHoverColor: '#fff',
                  selectedItemColor: '#fff',
                  unselectedDropDownIconHoverColor: '#fff',
                  selectedDropDownIconHoverColor: '#fff'
                }}
                menuStyles={{
                  backgroundColor: '#fff',
                  color: '#000'
                }}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};
