import { nativeDB } from '../../DexieInit';
import * as Sentry from '@sentry/browser';

const deleteProductsFromNative = async (
  { productIds, catalogueId, changeCatalogueRow = true },
  changes
) => {
  if (!changes || !Array.isArray(changes.newProductList)) {
    return;
  }

  const promises = [];

  if (changeCatalogueRow) {
    promises.push(nativeDB.catalogueRowMeta.put(changes.newCatalogueRow, catalogueId));
  }

  try {
    await Promise.all([
      nativeDB.productRowMeta.bulkDelete(productIds),
      nativeDB.productListMeta.put(changes.newProductList, catalogueId),
      ...promises
    ]);
  } catch (e) {
    Sentry.captureException(e);
  }
};

const updateExistingProductsInNative = async (products = []) => {
  try {
    await nativeDB.productRowMeta.bulkPut(products);
  } catch (error) {
    Sentry.captureException(error);
  }
};

const addProductsInNative = async ({ products, productsList, catalogueId, catalogueMeta }) => {
  try {
    await Promise.all([
      nativeDB.productListMeta.put(productsList, catalogueId),
      nativeDB.productRowMeta.bulkPut(products),
      nativeDB.catalogueRowMeta.put(catalogueMeta, catalogueId)
    ]);
  } catch (error) {
    Sentry.captureException(error);
  }
};

const changeProductsListInNative = async ({ productsList, catalogueId }) => {
  try {
    await nativeDB.productListMeta.put(productsList, catalogueId);
  } catch (error) {
    // Handle error
  }
};

const clearProductRowMetaFromNative = async () => {
  try {
    await nativeDB.productRowMeta.clear();
  } catch (error) {
    // Handle error
  }
};

const clearProductListFromNative = async () => {
  try {
    await nativeDB.productListMeta.clear();
  } catch (error) {
    Sentry.captureException(error);
  }
};

const clearProductDataFromNative = async () => {
  await Promise.all([clearProductRowMetaFromNative(), clearProductListFromNative()]);
};

export {
  deleteProductsFromNative,
  updateExistingProductsInNative,
  addProductsInNative,
  changeProductsListInNative,
  clearProductDataFromNative
};
