import React from 'react';
import NumberInput from '../../../../../../Common/NumberInput';
import SelectInput from '../../../../../../Common/SelectInput';
import { GROSS_WEIGHT, LABELS, NET_WEIGHT } from '../ProductTypeDetails';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

export default ({
  wastageOn,
  wastageCharge,
  onWastageChargeSelectChange,
  onWastageChargeInputChange
}) => {
  const { t } = getI18N();
  return (
    <div className="wastageCharges">
      <div className="titleAndInput">
        <div className="title">{t('wastage')}</div>
        <div className="inputContainer wastageChargesInputContainer">
          <NumberInput
            onChange={onWastageChargeInputChange}
            value={wastageCharge}
            inputClassName="input setInput"
            placeholder={t('type_wastage')}
            digitsAfterDecimal={3}
          />
          <span className={'percentageSymbol'}>%</span>
        </div>
      </div>
      <div className="setDropDownWrapper">
        <SelectInput
          value={wastageOn || NET_WEIGHT.id}
          options={[
            {
              value: NET_WEIGHT.id,
              displayValue: t(LABELS[NET_WEIGHT.id])
            },
            {
              value: GROSS_WEIGHT.id,
              displayValue: t(LABELS[GROSS_WEIGHT.id])
            }
          ]}
          onChange={onWastageChargeSelectChange}
          materialStyles={{
            formSelectionBorder: '1px solid #ffffff',
            selectTextColor: '#ffffff',
            selectedItemBackgroundColor: '#ffffff',
            selectedItemColor: '#232b36'
          }}
        />
      </div>
    </div>
  );
};
