import React from 'react';
import { ReactComponent as MenuBuilderIcon } from 'qs-assets/Media/menu-builder.svg';
import './styles.scss';

export default ({ width, height, fill, classes = '' }) => {
  return (
    <MenuBuilderIcon
      style={{ width, height, fill }}
      className={`settingsIconComponent ${classes}`}
    />
  );
};
