import AppConfig from 'qs-config/AppConfig';
import request from '../../Request';

export const getCompanyTaxes = () => request.authGet(`${AppConfig.qsApiEndPoint}/v1/tax/all`);

export const createNewCompanyTax = ({ taxType, taxPercentage } = {}) =>
  request.authPost(`${AppConfig.qsApiEndPoint}/v1/tax`, {
    taxType,
    taxPercentage
  });

export const setDefaultTaxCompany = taxId =>
  request.authPost(`${AppConfig.qsApiEndPoint}/v1/tax/set-default`, taxId);

export const removeDefaultTaxCompany = taxId =>
  request.authPost(`${AppConfig.qsApiEndPoint}/v1/tax/remove-default`, taxId);

export const deleteCompanyTax = taxId =>
  request.authDelete(`${AppConfig.qsApiEndPoint}/v1/tax`, taxId);
