import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

import './ProductTagsModal.scss';
import { ReactComponent as TagIcon } from 'qs-assets/Media/tag-icon.svg';
import { ReactComponent as TimesIcon } from 'qs-assets/Media/close.svg';
import ProductTagRow from './ProductTagRow/ProductTagRow';
import AddTagInput from './AddTagInput/AddTagInput';

import Tags from 'qs-data-manager/Tags';
import { getActiveCatalogueId } from 'qs-data-manager/Catalogues';
import { getI18N } from 'qs-services/i18N';

const SortableItem = SortableElement(ProductTagRow);

class SelectedTagsList extends Component {
  static propTypes = {
    items: PropTypes.array,
    isBulkEdit: PropTypes.bool,
    addTagActive: PropTypes.bool,
    renderUnselectedTags: PropTypes.func,
    onDeleteSelectedTag: PropTypes.func,
    onAddTagPress: PropTypes.func
  };

  renderItem = (item, index) => {
    const {
      isBulkEdit,
      addTagActive,
      renderUnselectedTags,
      onAddTagPress,
      onDeleteSelectedTag
    } = this.props;
    const { t } = getI18N();

    if (item === 'UNSELECTED_TAGS') {
      return <div key="UNSELECTED_TAGS">{isBulkEdit ? null : renderUnselectedTags()}</div>;
    }

    if (item === 'ADD_TAG') {
      return (
        <div key="ADD_TAG">
          {addTagActive ? null : (
            <div className="selectedAddTagButton" onClick={onAddTagPress}>
              <div className="selectedAddTagButtonText">{t('add_a_new_sub_category')}</div>
            </div>
          )}
        </div>
      );
    }

    return (
      <SortableItem
        key={item}
        tag={item}
        disabled={isBulkEdit}
        isBulkEdit={isBulkEdit}
        onDeletePress={() => onDeleteSelectedTag(item)}
        index={index - 1}
      />
    );
  };

  render() {
    const { items } = this.props;

    return <div className="selectedTagsList">{items.map(this.renderItem)}</div>;
  }
}

const SortableList = SortableContainer(SelectedTagsList);

class ProductTagsModal extends Component {
  static propTypes = {
    isBulkEdit: PropTypes.bool,
    catalogueId: PropTypes.string,
    productId: PropTypes.string,
    productIds: PropTypes.array,
    selectedTags: PropTypes.array,
    unselectedTags: PropTypes.array,
    onClose: PropTypes.func
  };

  static defaultProps = {
    selectedTags: [],
    unselectedTags: []
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedTags: props.selectedTags,
      unselectedTags: props.unselectedTags,
      addTagActive: false
    };
  }

  HEADER_HEIGHT = 51;
  MODAL_MARGIN = 20;
  ADD_TAG_INPUT = 63;

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { productId } = this.props;
    const { selectedTags } = this.state;

    const newSelectedTags = arrayMove(selectedTags, oldIndex, newIndex);

    this.setState({ selectedTags: newSelectedTags });

    Tags.sortProductTags({ productId, tagsIds: newSelectedTags });
  };

  // TODO: Check if this is working right with onUnselectedTagPress in parent component
  onUnselectedTagPress = (tag, callback = () => {}) => {
    const { productId, productIds, isBulkEdit } = this.props;
    const { selectedTags, unselectedTags } = this.state;

    const pos = unselectedTags.indexOf(tag);

    if (pos === -1) {
      return;
    }

    this.setState(
      {
        selectedTags: [...selectedTags, tag],
        unselectedTags: [...unselectedTags.slice(0, pos), ...unselectedTags.slice(pos + 1)]
      },
      callback
    );

    const catalogueId = getActiveCatalogueId();
    if (isBulkEdit) {
      Tags.addProductsTag({ catalogueId, productIds, tag });
    } else {
      Tags.addProductTag({ catalogueId, productId, tagId: tag, position: selectedTags.length });
    }
  };

  onDeleteSelectedTag = tag => {
    const {
      catalogueId,
      productId,
      productIds,
      isBulkEdit,
      unselectedTags,
      updateTagsFromModal
    } = this.props;
    const { selectedTags } = this.state;

    if (isBulkEdit) {
      const newTags = selectedTags.filter(t => t !== tag);
      this.setState({
        selectedTags: newTags
      });
      updateTagsFromModal({ newTags, tag });
      const catalogueId = getActiveCatalogueId();

      Tags.deleteProductsTag({
        catalogueId,
        productIds,
        tag
      });
    } else {
      let selectedTags = [];
      let newUnselectedTags = [];
      ({ selectedTags, newUnselectedTags } = Tags.deleteProductTag({
        catalogueId,
        productId,
        tag,
        unselectedTags
      }));

      this.setState({
        selectedTags,
        unselectedTags: newUnselectedTags
      });
    }

    Tags.deleteProductTagInRemote({
      catalogueId,
      productIds: isBulkEdit ? productIds : [productId],
      tagId: tag
    });
  };

  onAddTagPress = () => {
    this.setState({ addTagActive: true });
  };

  showDuplicateError = () => {
    const { t } = getI18N();

    toastr.error(t('duplicate_entry_not_allowed'), null, {
      positionClass: 'toast-bottom-right',
      iconClass: 'tags-toast-error-icon'
    });
  };

  showSpeciaCharacterError = () => {
    const { t } = getI18N();

    toastr.error(t('special_characters_not_allowed'), null, {
      positionClass: 'toast-bottom-right',
      iconClass: 'tags-toast-error-icon'
    });
  };

  scrollToEnd = () => {
    if (this.dummyScrollEnd && this.dummyScrollEnd.scrollIntoView) {
      this.dummyScrollEnd.scrollIntoView({ behavior: 'smooth' });
    } else if (this.contentScroll) {
      this.contentScroll.scrollTop = this.contentScroll.scrollHeight;
    }
  };

  onTagSubmit = tag => {
    const { catalogueId, productId, productIds, isBulkEdit, addNewTagFromModal } = this.props;
    const { selectedTags, unselectedTags } = this.state;

    if (!Tags.isTagValid(tag)) {
      this.showSpeciaCharacterError();
      return;
    }

    const selectedPos = selectedTags.indexOf(tag);

    if (selectedPos !== -1) {
      this.showDuplicateError();
      return;
    }

    if (unselectedTags.indexOf(tag) !== -1) {
      this.onUnselectedTagPress(tag, () =>
        setTimeout(() => selectedTags.length && this.scrollToEnd())
      );
    } else {
      this.setState({ selectedTags: [...selectedTags, tag] }, () => {
        setTimeout(() => selectedTags.length && this.scrollToEnd());
      });
      addNewTagFromModal(tag);

      if (isBulkEdit) {
        Tags.addProductsTag({ catalogueId, productIds, tag });
      } else {
        const catalogueId = getActiveCatalogueId();
        Tags.addProductTag({ catalogueId, productId, tagId: tag, position: selectedTags.length });
      }
    }
  };

  renderUnselectedTags = () => {
    const { unselectedTags } = this.state;
    const { t } = getI18N();

    if (!unselectedTags.length) {
      return null;
    }

    return (
      <div className="contentHeader">
        <div className="contentHeaderTitle">{t('sub_categories_from_other_products')}</div>
        <div className="contentHeaderSubtitle">{t('tap_to_add_current_product')}</div>

        <div className="unselectedTagsContainer">
          {unselectedTags.map((tag, key) => (
            <div key={key} className="unselectedTag" onClick={() => this.onUnselectedTagPress(tag)}>
              <span className="unselectedTagText">{tag}</span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  render() {
    const { selectedTags, addTagActive } = this.state;
    const { onClose, isBulkEdit } = this.props;
    const { t } = getI18N();

    return (
      <div className="productTagsModal">
        <div className="header">
          <div className="headerLeft">
            <TagIcon fill="#7CD3A3" className="headerIcon" />
            <span className="headerText">{t('product_sub_categories')}</span>
          </div>

          <div className="headerRight">
            <div className="closeButton" onClick={onClose}>
              <TimesIcon fill="#999" className="closeButtonIcon" />
            </div>
          </div>
        </div>

        <div className="content">
          <div
            ref={element => (this.contentScroll = element)}
            className="contentScroll"
            style={{
              maxHeight:
                window.innerHeight -
                this.HEADER_HEIGHT -
                2 * this.MODAL_MARGIN -
                (addTagActive ? this.ADD_TAG_INPUT : 0)
            }}
          >
            <div className="selectedContent">
              {selectedTags.length ? (
                <div className="selectedTagsContainer">
                  <SortableList
                    useDragHandle
                    helperClass="activeProductTagSortRow"
                    items={['UNSELECTED_TAGS', ...selectedTags, 'ADD_TAG']}
                    isBulkEdit={isBulkEdit}
                    addTagActive={addTagActive}
                    renderUnselectedTags={this.renderUnselectedTags}
                    onSortEnd={this.onSortEnd}
                    onDeleteSelectedTag={this.onDeleteSelectedTag}
                    onAddTagPress={this.onAddTagPress}
                    lockAxis={'y'}
                  />
                </div>
              ) : (
                <div>
                  {isBulkEdit ? <div /> : this.renderUnselectedTags()}

                  <div className="noSelectedTagsContainer">
                    <TagIcon fill="#D0DED3" className="noSelectedTagsIcon" />
                    <div className="noSelectedTagsText">
                      {t(
                        'organise_your_products_using_sub_categories_and_allow_customers_to_find_what_they_are_looking_for_more_quickly'
                      )}
                    </div>

                    {!addTagActive && (
                      <div className="noSelectedAddTagButton" onClick={this.onAddTagPress}>
                        <span className="noSelectedAddTagButtonText">
                          {t('add_a_new_sub_category')}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>

            <div ref={element => (this.dummyScrollEnd = element)} />
          </div>
        </div>

        {addTagActive && <AddTagInput onSubmit={this.onTagSubmit} />}
      </div>
    );
  }
}

export default ProductTagsModal;
