import React, { useState } from 'react';
import OrderDetailsScreen from '../OrderDetailsScreen';
import OrdersList from './OrdersList';
import './styles.scss';

export default () => {
  const [noOrdersAvailable, setNoOrdersAvailable] = useState(false);

  return (
    <div className="orderPageContainer">
      <OrdersList onNoOrders={setNoOrdersAvailable} />
      {!noOrdersAvailable && <OrderDetailsScreen />}
    </div>
  );
};
