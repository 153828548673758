import React from 'react';
import { DialogContent, DialogActions, withStyles } from '@material-ui/core';
import {
  DialogDarkTheme,
  PrimaryButton,
  SecondaryButton,
  DarkThemeTitle
} from '../DarkThemeDialog';
import './styles.scss';

const SpacedDialogActions = withStyles({
  spacing: {
    justifyContent: 'space-between',
    padding: '8px 8px 8px 20px'
  }
})(DialogActions);

export default ({
  title,
  showContactSupport = true,
  onClose,
  mailProps: { subject, body } = {},
  children,
  primaryButtonLabel,
  primaryButtonClick
}) => {
  const onContactClick = event => {
    const mailToQuery = [];
    if (subject) {
      mailToQuery.push(`subject=${encodeURIComponent(subject)}`);
    }
    if (body) {
      mailToQuery.push(`body=${encodeURIComponent(`Encountered error: ${body}`)}`);
    }

    let mailToQueryString = '';
    if (mailToQuery.length > 0) {
      mailToQueryString += `?${mailToQuery.join('&')}`;
    }
    window.location.href = `mailto:support@quicksell.co${mailToQueryString}`;
    event.preventDefault();
    onClose();
  };

  const getDialogActions = () => {
    if (showContactSupport) {
      return (
        <SpacedDialogActions>
          <SecondaryButton onClick={() => onClose()}>
            <span className="cancelButtonText">Cancel</span>
          </SecondaryButton>
          {showContactSupport && (
            <PrimaryButton
              color={'primary'}
              onClick={primaryButtonClick ? primaryButtonClick : onContactClick}
            >
              {primaryButtonLabel ? primaryButtonLabel : 'CONTACT SUPPORT'}
            </PrimaryButton>
          )}
        </SpacedDialogActions>
      );
    }

    return (
      <DialogActions>
        <PrimaryButton color={'primary'} onClick={() => onClose()}>
          <span>Cancel</span>
        </PrimaryButton>
      </DialogActions>
    );
  };

  return (
    <DialogDarkTheme open={true} maxWidth={'xs'}>
      <DarkThemeTitle id="operationError">{title}</DarkThemeTitle>
      <DialogContent>
        <div className="errorPopup contentContainer">{children}</div>
      </DialogContent>
      {getDialogActions()}
    </DialogDarkTheme>
  );
};
