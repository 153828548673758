import React from 'react';
import SettingsRow from '../SettingsRow/SettingsRow';
import ClickHandlerWithLoaderContainer from '../../../Common/ClickHandlerWithLoader/ClickHandlerWithLoaderContainer';
import './LinkSettingsRow.scss';
import Mixpanel from 'qs-data-manager/Mixpanel';
import { SETTINGS_COMPONENT_ID_MAP } from 'qs-helpers/CompanySettings/constants';
import { getI18N } from 'qs-services/i18N';

export default ({
  rowData: { linkUrl, imageSrc, title, description, componentId, componentEvent },
  classForContainer
}) => {
  const { t } = getI18N();
  let descriptionComponent;
  switch (componentId) {
    case SETTINGS_COMPONENT_ID_MAP.QUICKSELL_PARTNER:
      descriptionComponent = (
        <span className="settings-partner">
          {t('sell_quicksell_in_your_local_region', { percent: '15' })}
        </span>
      );
      break;
    default:
      descriptionComponent = <span>{description}</span>;
      break;
  }

  const handleLinkClick = function(currentClickedLink) {
    switch (currentClickedLink) {
      case SETTINGS_COMPONENT_ID_MAP.NEW_UPDATES:
      case SETTINGS_COMPONENT_ID_MAP.HELP_TRANSLATE:
        Mixpanel.sendEvent({
          eventName: componentEvent.eventName,
          props: componentEvent.props
        });
        break;
      default:
        break;
    }
  };

  return (
    <ClickHandlerWithLoaderContainer
      linkProps={{ linkUrl }}
      clickProps={{
        clickHandler: handleLinkClick,
        clickParams: componentId
      }}
      type="link"
      classForContainer={classForContainer}
    >
      <SettingsRow imageSrc={imageSrc} title={title}>
        {descriptionComponent}
      </SettingsRow>
    </ClickHandlerWithLoaderContainer>
  );
};
