import {
  updateProductViaSKU,
  getSkuList,
  getUpdateProductViaSKUStatus,
  getStoneDetailsbySku
} from 'qs-api/GlobalInventory/api';
import { reportError } from 'qs-helpers/ErrorReporting';

export const updateProductViaSku = async ({ updates, requestType }) => {
  try {
    return await updateProductViaSKU(updates, requestType);
  } catch (err) {
    reportError(err);
    throw err;
  }
};
export const getUpdateProductViaSkuStatus = async ({ requestId }) => {
  try {
    return await getUpdateProductViaSKUStatus(requestId);
  } catch (err) {
    reportError(err);
    throw err;
  }
};
export const getAllSku = async () => {
  try {
    return await getSkuList();
  } catch (err) {
    reportError(err);
    throw err;
  }
};

export const downloadStoneDetailsBySku = async () => {
  try {
    return await getStoneDetailsbySku();
  } catch (err) {
    reportError(err);
    throw err;
  }
};
