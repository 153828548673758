import React from 'react';
import ThemeApp from '../Theme';
import './styles.scss';

import Navigation from './Navigation';
import 'qs-data-manager/Dexie/ApiAndNativeConnector/index';

export default () => {
  return (
    <ThemeApp>
      <div id={'AppMainContainer'}>
        <Navigation />
      </div>
    </ThemeApp>
  );
};
