import React, { useEffect, useRef, useState } from 'react';
import { Button, InputAdornment, makeStyles, TextField, withStyles } from '@material-ui/core';
import { AMOUNT_PERCENT, AMOUNT_FLAT } from 'qs-helpers/Orders/constants';
import { getCompanyCurrencySymbol } from 'qs-data-manager/Company';
import DialogBox from '../../../../../Common/DialogBox';
import { ReactComponent as EditIcon } from 'qs-assets/Media/pencil.svg';
import { ReactComponent as TrashIcon } from 'qs-assets/Media/trash.svg';
import './styles.scss';
import { t } from 'i18next';

const useStyles = makeStyles({
  textFieldFlatRoot: {
    marginTop: 5,
    width: '100%'
  },
  textFieldPercentRoot: {
    marginTop: 5,
    width: 150
  },
  textInput: {
    color: '#ffffff'
  },
  textInputError: {
    color: '#DB5354'
  },
  textInputUnderline: {
    '&:before': {
      borderColor: '#ffffff'
    },
    '&:after': {
      borderColor: '#ffffff'
    },
    '&:hover:before': {
      borderColor: '#ffffff !important'
    },
    '&$textInputFocused': {
      '&:not($textInputError):after': {
        borderColor: '#4ed29b'
      }
    }
  },
  textInputFocused: {},
  editDialogContainer: {
    width: 300
  },
  editDialogContentContainer: {
    paddingTop: 0
  },
  editDialogTitleContainer: {
    paddingBottom: 10
  },
  modifyDialogContainerClass: {
    width: '100%'
  },
  modifyDialogContentContainer: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0
  },
  dialogTitleClass: {
    paddingLeft: 15,
    paddingRight: 15,
    paddingBottom: 0
  },
  modifyButtonRoot: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: 15,
    borderBottom: '1px solid #1C262B',
    borderRadius: 0,
    '&:last-child': {
      borderBottom: 'none'
    }
  },
  modifyButtonRootRipple: {
    color: '#fff'
  },
  modifyButtonStartIcon: {
    marginRight: 15,
    marginLeft: 0
  },
  modifyButtonLabelEdit: {
    color: '#fff'
  },
  modifyButtonLabelTrash: {
    color: '#FCA4A4'
  }
});

const DIALOG_MODES = {
  EDIT: 'EDIT',
  REMOVE: 'REMOVE',
  MODIFY: 'MODIFY'
};

export default ({ onCancel, onUpdate, orderDiscount, orderDiscountType }) => {
  const componentStyles = useStyles();

  const stringifiedDiscount = (orderDiscount || '').toString();
  const [selectedType, setSelectedType] = useState(orderDiscountType || AMOUNT_PERCENT);
  const [orderDiscountFlatVal, setOrderDiscountFlatVal] = useState(
    selectedType === AMOUNT_FLAT ? stringifiedDiscount : ''
  );
  const [orderDiscountPercentVal, setOrderDiscountPercentVal] = useState(
    selectedType === AMOUNT_PERCENT ? stringifiedDiscount : ''
  );
  const [inputError, setInputError] = useState();
  const [showDialogMode, setShowDialogMode] = useState(
    typeof orderDiscount === 'number' && orderDiscount > 0 ? DIALOG_MODES.MODIFY : DIALOG_MODES.EDIT
  );
  const errorClearTimer = useRef();

  useEffect(() => () => clearTimeout(errorClearTimer.current), []);

  const handleModeChange = selectedMode => {
    setSelectedType(selectedMode);
    setInputError();
    clearTimeout(errorClearTimer.current);
  };

  const onInputChange = event => {
    const { value } = event.target;
    clearTimeout(errorClearTimer.current);
    setInputError();
    if (selectedType === AMOUNT_FLAT) {
      setOrderDiscountFlatVal(value);
      return;
    }

    const valueNum = Number(value);
    // Restrict percent value to 100
    if (!Number.isNaN(valueNum) && valueNum > 100) {
      setInputError(t('discount_percent_should_not_be_more_than', { percent: '100' }));

      // since the error won't be removed until the input changes, auto remove it after 3 seconds
      errorClearTimer.current = setTimeout(() => {
        setInputError();
      }, 3000);
      return;
    }
    setOrderDiscountPercentVal(value);
  };

  const isDiscountAmountValid = valToTest => {
    if (typeof valToTest !== 'string') {
      return { valid: false, reason: '' };
    }

    // Unsetting the discount is allowed
    if (valToTest.trim() === '') {
      return { valid: true };
    }

    const valueNum = Number(valToTest);
    if (Number.isNaN(valueNum)) {
      return { valid: false, reason: t('enter_a_valid_number') };
    }

    if (valueNum < 0) {
      return { valid: false, reason: t('discount_value_cannot_be_negative') };
    }

    if (selectedType === AMOUNT_PERCENT) {
      // discount percent cannot be more than 100
      if (valueNum > 100) {
        return {
          valid: false,
          reason: t('discount_percent_should_not_be_more_than', { percent: '100' })
        };
      }
    }

    return { valid: true };
  };

  const onProceed = () => {
    let valToTest = orderDiscountFlatVal;
    if (selectedType === AMOUNT_PERCENT) {
      valToTest = orderDiscountPercentVal;
    }
    const { valid, reason } = isDiscountAmountValid(valToTest);
    if (!valid) {
      setInputError(reason);
      return;
    }

    onUpdate({ amount: Number(valToTest), type: selectedType });
  };

  const onRemove = () => {
    onUpdate({ amount: null, type: selectedType });
  };

  const renderPriceInput = () => (
    <TextField
      key="fixed"
      classes={{ root: componentStyles.textFieldFlatRoot }}
      error={inputError !== undefined}
      onChange={onInputChange}
      value={orderDiscountFlatVal}
      placeholder={t('enter_discount_amount')}
      helperText={inputError || undefined}
      InputProps={{
        autoFocus: true,
        startAdornment: (
          <InputAdornment
            position="start"
            classes={{
              root:
                inputError === undefined
                  ? componentStyles.textInput
                  : componentStyles.textInputError
            }}
            disableTypography
          >
            {getCompanyCurrencySymbol()}
          </InputAdornment>
        ),
        classes: {
          error: componentStyles.textInputError,
          root: componentStyles.textInput,
          underline: componentStyles.textInputUnderline,
          focused: componentStyles.textInputFocused
        },
        error: inputError !== undefined
      }}
    />
  );

  const renderPercentageInput = () => (
    <TextField
      key="percentage"
      classes={{ root: componentStyles.textFieldPercentRoot }}
      error={inputError !== undefined}
      onChange={onInputChange}
      value={orderDiscountPercentVal}
      placeholder={t('enter_percentage')}
      helperText={inputError || undefined}
      InputProps={{
        autoFocus: true,
        endAdornment: (
          <InputAdornment
            position="start"
            classes={{
              root:
                inputError === undefined
                  ? componentStyles.textInput
                  : componentStyles.textInputError
            }}
            disableTypography
          >
            %
          </InputAdornment>
        ),
        classes: {
          error: componentStyles.textInputError,
          root: componentStyles.textInput,
          underline: componentStyles.textInputUnderline,
          focused: componentStyles.textInputFocused
        },
        error: inputError !== undefined
      }}
    />
  );

  const renderInputAsPerSelection = () => {
    if (selectedType === AMOUNT_PERCENT) {
      return (
        <>
          <span className="discountViewTitleText">{t('discount_percentage')}</span>
          {renderPercentageInput()}
        </>
      );
    }

    return (
      <>
        <span className="discountViewTitleText">{t('discount_amount')}</span>
        {renderPriceInput()}
      </>
    );
  };

  const renderDiscountTypeButtons = () => {
    const buttons = [
      {
        label: t('percentage'),
        textIcon: '%',
        type: AMOUNT_PERCENT
      },
      {
        label: t('fixed'),
        textIcon: '#',
        type: AMOUNT_FLAT
      }
    ];

    return (
      <div className="discountTypeButtonContainer">
        {buttons.map(({ label, type, textIcon }) => {
          const StyledButton = withStyles({
            root: {
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: type === selectedType ? '#4DA47A' : '#4D515D',
              paddingLeft: 15,
              paddingRight: 15,
              flexGrow: 0,
              flexShrink: 0,
              flexBasis: 'auto',
              '&:hover': {
                backgroundColor: type === selectedType ? '#4DA47A' : '#4D515D'
              },
              '&:first-child': {
                borderRadius: '5px 0px 0px 5px'
              },
              '&:last-child': {
                borderRadius: '0px 5px 5px 0px'
              }
            }
          })(Button);

          return (
            <StyledButton key={type} onClick={() => handleModeChange(type)}>
              <span className="discountButtonIconText">{textIcon}</span>
              <span className="discountButtonText">{label}</span>
            </StyledButton>
          );
        })}
      </div>
    );
  };

  const renderEditDialog = () => (
    <DialogBox
      open={true}
      onClose={onCancel}
      dialogAsForm={{
        onSubmit: onProceed
      }}
      title={t('edit_discount')}
      titleRootClass={componentStyles.editDialogTitleContainer}
      maxWidth="xs"
      dialogContentContainerClassName="editOrderDiscountDialog"
      dialogContainerClass={componentStyles.editDialogContainer}
      dialogContentContainerClass={componentStyles.editDialogContentContainer}
      actionsConfig={[
        {
          clickHandler: onCancel,
          content: t('cancel'),
          role: 'SECONDARY'
        },
        {
          clickHandler: onProceed,
          content: t('set_discount'),
          role: 'PRIMARY'
        }
      ]}
    >
      <>
        {renderDiscountTypeButtons()}
        {renderInputAsPerSelection()}
      </>
    </DialogBox>
  );

  const renderRemoveDialog = () => (
    <DialogBox
      open={true}
      onClose={onCancel}
      title={t('remove_discount')}
      maxWidth="xs"
      actionsConfig={[
        {
          clickHandler: onCancel,
          content: t('cancel'),
          role: 'SECONDARY'
        },
        {
          clickHandler: onRemove,
          content: t('yes_remove'),
          role: 'DANGER'
        }
      ]}
    >
      <span>{t('are_you_sure_you_want_to_remove_discount_on_this_order')}?</span>
    </DialogBox>
  );

  const renderModifyDialog = () => {
    const buttonsData = [
      {
        type: DIALOG_MODES.EDIT,
        iconComponent: <EditIcon fill="#fff" className="modifyButtonIcon" />,
        label: t('edit_discount'),
        labelStyles: componentStyles.modifyButtonLabelEdit
      },
      {
        type: DIALOG_MODES.REMOVE,
        iconComponent: <TrashIcon fill="#FCA4A4" className="modifyButtonIcon" />,
        label: t('remove_discount'),
        labelStyles: componentStyles.modifyButtonLabelTrash
      }
    ];

    return (
      <DialogBox
        open={true}
        onClose={onCancel}
        title={t('modify_order_discount')}
        titleRootClass={componentStyles.dialogTitleClass}
        maxWidth="xs"
        dialogContainerClass={componentStyles.modifyDialogContainerClass}
        dialogContentContainerClassName="editOrderDiscountDialog"
        dialogContentContainerClass={componentStyles.modifyDialogContentContainer}
      >
        {buttonsData.map(({ iconComponent, label, labelStyles, type }) => {
          return (
            <Button
              key={label}
              classes={{
                root: componentStyles.modifyButtonRoot,
                label: labelStyles,
                startIcon: componentStyles.modifyButtonStartIcon
              }}
              TouchRippleProps={{ classes: { root: componentStyles.modifyButtonRootRipple } }}
              startIcon={iconComponent}
              onClick={() => setShowDialogMode(type)}
            >
              {label}
            </Button>
          );
        })}
      </DialogBox>
    );
  };

  if (showDialogMode === DIALOG_MODES.EDIT) {
    return renderEditDialog();
  }

  if (showDialogMode === DIALOG_MODES.REMOVE) {
    return renderRemoveDialog();
  }

  if (showDialogMode === DIALOG_MODES.MODIFY) {
    return renderModifyDialog();
  }
};
