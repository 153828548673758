import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import OrdersRow from './OrdersRow';
import { ORDERS_LIST } from 'qs-helpers/Orders/constants';
import InfiniteLoader from 'react-window-infinite-loader';
import { isPaginatedCallInProg } from 'qs-helpers/Orders/ResponseProcessor';
import { getPaginatedCompanyOrders } from 'qs-data-manager/Orders/OrdersOperations';
import useMakeQuery from 'qs-hooks/useMakeQuery';
import {
  fetchCompanyOrdersHandler,
  handleCompanyOrdersCleanup,
  getCachedCompanyOrdersList
} from 'qs-helpers/Orders/DataQueryHelper';
import { ActiveOrderMeta } from '../../context';
import { UPDATE_ORDER_META } from '../../reducer';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

export default ({ onNoOrders }) => {
  const {
    activeOrderMeta: { currentSortKey: sortKey, currentConfirmFilter: confirmed, searchText },
    setActiveOrderMeta
  } = useContext(ActiveOrderMeta);
  const previousValues = useRef({
    loading: false
  });
  const { t } = getI18N();

  const [{ loading, error, data: ordersData }] = useMakeQuery({
    changeDependancy: [confirmed, sortKey, searchText],
    cachedDataHandler: getCachedCompanyOrdersList,
    actionHandler: fetchCompanyOrdersHandler,
    cleanupHandler: handleCompanyOrdersCleanup
  });

  useEffect(() => {
    if (!ordersData) {
      return;
    }
    if (ordersData.orders.length === 0) {
      onNoOrders(true);
      return;
    }
    onNoOrders(false);
  }, [onNoOrders, ordersData]);

  useEffect(() => {
    if (!ordersData || loading) {
      previousValues.current.loading = loading;
      return;
    }

    /*
      prev value was loading, currently it is not loading and the list exists
      select the first order and set it to view
    */
    if (!previousValues.current.loading) {
      return;
    }

    const { id: orderId = null } = ordersData.orders[0] || {};
    setActiveOrderMeta({ type: UPDATE_ORDER_META, orderId });
    previousValues.current.loading = loading;
  }, [ordersData, loading, setActiveOrderMeta]);

  const { orders: ordersList = [], totalOrders } = ordersData || {};

  const createShimmerComponent = useCallback((styles, key) => {
    return (
      <div className="shimmerContainer" style={styles} key={key}>
        <div className="dataPlaceholder animate"></div>
        <div className="dataPlaceholder animate"></div>
        <div className="dataPlaceholder animate"></div>
      </div>
    );
  }, []);

  const itemKey = useCallback((index, data) => {
    const inquiryData = data[index];
    if (inquiryData === undefined) {
      return `shimmer-${index}`;
    }
    return `${data[index].id}${index}`;
  }, []);

  const renderRow = useCallback(
    ({ data, index, style }) => {
      const inquiryData = data[index];
      if (inquiryData === undefined) {
        return createShimmerComponent(style, index);
      }
      return <OrdersRow orderData={data[index]} style={style} />;
    },
    [createShimmerComponent]
  );

  const allOrdersLoaded = () => totalOrders === ordersList.length;

  const getItemCount = () => {
    let itemCount = ordersList.length;
    if (!allOrdersLoaded()) {
      //For displaying loader at the bottom
      itemCount += 1;
    }
    return itemCount;
  };

  const isItemLoaded = index => {
    if (allOrdersLoaded() || index < ordersList.length) {
      return true;
    }

    return false;
  };

  const loadMoreItems = () => {
    if (isPaginatedCallInProg({ sortKey, confirmed }) || allOrdersLoaded()) {
      return;
    }

    getPaginatedCompanyOrders({ confirmed, sortKey, searchText });
  };

  const getLoadShimmers = () => {
    const loadingShimmers = [];
    for (let index = 0; index < 5; index++) {
      loadingShimmers.push(createShimmerComponent({ height: ORDERS_LIST.rowHeight }, index));
    }
    return loadingShimmers;
  };

  if (error) {
    return (
      <div className="ordersListContainer noOrdersContainer errorContainer">
        <div>{t('something_went_wrong_while_fetching_your_orders')}</div>
      </div>
    );
  }

  if (!ordersData || loading) {
    return <div className="ordersListContainer shimmerLoaderContainer">{getLoadShimmers()}</div>;
  }

  if (ordersList.length === 0) {
    return <div className="ordersListContainer noOrdersContainer">{t('no_orders')}</div>;
  }

  const listItemCount = getItemCount();

  return (
    <div className="ordersListContainer">
      <AutoSizer>
        {({ height, width }) => (
          <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={listItemCount}
            loadMoreItems={loadMoreItems}
          >
            {({ onItemsRendered, ref }) => (
              <List
                ref={ref}
                onItemsRendered={onItemsRendered}
                height={height}
                itemData={ordersList}
                itemCount={listItemCount}
                itemKey={itemKey}
                itemSize={ORDERS_LIST.rowHeight}
                width={width}
                overscanCount={ORDERS_LIST.overscanCount}
              >
                {renderRow}
              </List>
            )}
          </InfiniteLoader>
        )}
      </AutoSizer>
    </div>
  );
};
