import { LOCAL_STORAGE_KEYS } from 'qs-services/Rehydration';
import { getAllCatalogueCount, getAllProductCount } from 'qs-data-manager/Company';
import eventbus from 'eventing-bus';
import { registerCleanupHandler } from 'qs-helpers/ClearSavedData';

let CURRENT_PLAN = null;
let FEATURE_LIMITS = {};

export const FEATURE_LIMITING_MODAL_META = {
  EVENTBUS_KEY: 'FEATURE_LIMITING_MODAL_META'
};

export const PLAN_TYPES = {
  LITE: 'LITE',
  FREE: 'FREE',
  UNLIMITED: 'UNLIMITED'
};

export const FEATURE_LIST = {
  CATALOGUES: {
    id: 'CATALOGUES',
    description: 'to_create_more_catalogues_contact_us_or_delete_existing_catalogues',
    iconStyles: { width: 34, height: 42 },
    title: 'catalogues_limit_reached'
  },
  PRODUCTS: {
    id: 'PRODUCTS',
    description: 'to_create_more_products_contact_us_or_delete_existing_products',
    buttons: [{ title: 'delete_products', gradientColors: ['#FF7373', '#CE3737'] }],
    title: 'products_limit_reached'
  },
  SET_WISE_ORDERING: {
    id: 'SET_WISE_ORDERING',
    description: 'to_enable_upgrade_to_the_platinum_plan',
    title: 'only_available_in_platinum_plan'
  },
  CHECKOUT_FORM_SETTINGS: {
    id: 'CUSTOM_CHECKOUT_FIELDS',
    description: 'to_enable_upgrade_to_premium_or_above_plans',
    title: 'only_available_in_premium_or_above_plans'
  },
  WEBHOOKS: {
    id: 'WEBHOOKS',
    description: 'to_enable_upgrade_to_premium_or_above_plans',
    title: 'only_available_in_premium_or_above_plans'
  }
};

export const rehydrateCurrentPlan = currentPlan => {
  CURRENT_PLAN = currentPlan;
};

export const rehydrateFeatureLimit = featureLimits => {
  convertFeatureLimitMeta(featureLimits);
};

export const saveCurrentPlan = currentPlan => {
  CURRENT_PLAN = currentPlan;
  const data = JSON.stringify(currentPlan);
  localStorage.setItem(LOCAL_STORAGE_KEYS.CURRENT_PLAN, data);
};

const convertFeatureLimitMeta = featureLimits => {
  featureLimits.plans.forEach(plan => {
    const features = {};
    plan.features.forEach(row => {
      features[row.feature] = row;
    });

    FEATURE_LIMITS[plan.planType] = {
      planMeta: plan,
      featureIdLimitMap: features
    };
  });
};

export const saveFeatureLimits = featureLimits => {
  const data = JSON.stringify(featureLimits);
  localStorage.setItem(LOCAL_STORAGE_KEYS.FEATURE_LIMIT, data);
  convertFeatureLimitMeta(featureLimits);
};

export const canUseFeature = (id, { extraProductCount } = {}, suppressModal = false) => {
  const isUnlimited = CURRENT_PLAN.planType === PLAN_TYPES.UNLIMITED;
  if (isUnlimited) {
    return true;
  }
  if (
    !FEATURE_LIMITS ||
    !CURRENT_PLAN.planType ||
    !FEATURE_LIMITS[CURRENT_PLAN.planType] ||
    !FEATURE_LIMITS[CURRENT_PLAN.planType].featureIdLimitMap ||
    !FEATURE_LIMITS[CURRENT_PLAN.planType].featureIdLimitMap[id]
  ) {
    return false;
  }
  const { limit, count, allowed } = FEATURE_LIMITS[CURRENT_PLAN.planType].featureIdLimitMap[
    id
  ].options;
  if (typeof allowed === 'boolean') {
    return allowed;
  }
  if (!limit) {
    return true;
  }
  const canUse = usageStatistics(id, count, extraProductCount);
  if (canUse) {
    return true;
  }
  if (!suppressModal) {
    toggleFeatureLimitModal(true, id, count);
  }
  return false;
};

const usageStatistics = (id, count, extraProductCount) => {
  switch (id) {
    case FEATURE_LIST.CATALOGUES.id:
      return canCreateCatalogue(count);
    case FEATURE_LIST.PRODUCTS.id:
      return canCreateProducts(count, extraProductCount);
    default:
      break;
  }
};

const canCreateCatalogue = count => {
  const userCatalogues = getAllCatalogueCount();
  if (typeof userCatalogues !== 'number') {
    return false;
  }
  const limit = userCatalogues + 1;
  return limit <= count;
};

const canCreateProducts = (count, extraProductCount) => {
  const userProducts = getAllProductCount();
  if (typeof userProducts !== 'number') {
    return false;
  }
  const limit = userProducts + (extraProductCount || 0);
  return limit <= count;
};

const toggleFeatureLimitModal = (visibility, id, count) => {
  eventbus.publish(FEATURE_LIMITING_MODAL_META.EVENTBUS_KEY, {
    featureId: id,
    visibility,
    count
  });
};

const cleanupAllPlanData = () => {
  CURRENT_PLAN = null;
  FEATURE_LIMITS = {};
};

registerCleanupHandler(cleanupAllPlanData);
