import { nativeDB } from 'qs-services/DexieInit';
import * as Sentry from '@sentry/browser';

// helper functions for storing data in native storage
const saveCatalogueListMeta = async (apiResp = {}) => {
  try {
    const { catalogueIds = [] } = apiResp;
    await nativeDB.cataloguesListMeta.clear();
    await nativeDB.cataloguesListMeta.bulkPut(catalogueIds);
  } catch (error) {
    // Handle error
  }
};

const saveCatalogueRowMeta = async (apiResp = {}) => {
  // return id in object so that you dont have to put external keys into native storage
  const { catalogueMeta = {} } = apiResp;
  const catalogueIds = Object.keys(catalogueMeta);
  if (catalogueIds.length) {
    const keys = [];
    const data = catalogueIds.map(id => {
      keys.push(id);
      return catalogueMeta[id];
    });

    try {
      await nativeDB.catalogueRowMeta.bulkPut(data, keys);
    } catch (error) {
      Sentry.captureException(error);
    }
  }
};

const saveCatalogueTags = async (apiResp = {}, options = {}) => {
  try {
    const { catalogueId } = options;
    const { tags } = apiResp;
    if (!catalogueId || !tags) {
      return;
    }

    await nativeDB.catalogueTags.put(tags, catalogueId);
  } catch (error) {
    // Handle error
  }
};

export { saveCatalogueListMeta, saveCatalogueRowMeta, saveCatalogueTags };
