import { getLastFetchedVariantTimeStamp } from 'qs-helpers/Variants/ResponseProcessor';
import CacheRequest from 'qs-data-manager/CacheRequest';
import { PRODUCT_VARIANT_IDS, PRODUCT_VARIANT_META } from 'qs-api/Variants/ApiCacheConnector';
import { debouncer, DEBOUNCER_TYPE } from 'qs-helpers';
import { deleteVariantData, setDefaultVariant, getVariantsForProducts } from 'qs-api/Variants/api';
import { reportError } from 'qs-helpers/ErrorReporting';
import { registerCleanupHandler } from 'qs-helpers/ClearSavedData';
import { deleteProductOptions } from './ProductOptions';

const VARIANT_META_DEBOUNCER = {
  key: 'VARIANT_META_DEBOUNCER__KEY',
  timeInMs: 200,
  type: DEBOUNCER_TYPE.ADD
};

let VARIANT_META_REQUEST_SEND = {};

export const attachProductVariantListener = ({ listener, productId }) => {
  const key = `${PRODUCT_VARIANT_IDS.cacheKey}${productId}`;
  CacheRequest.attachListener(key, listener);
};

export const removeProductVariantListener = ({ listener, productId }) => {
  const key = `${PRODUCT_VARIANT_IDS.cacheKey}${productId}`;
  CacheRequest.removeListener(key, listener);
};

export const getVariantsDataForProduct = async ({ productId }) => {
  const lastFetchedData = getLastFetchedVariantTimeStamp(productId);
  const variantKey = `${PRODUCT_VARIANT_IDS.cacheKey}${productId}`;
  try {
    CacheRequest.checkAndNotifyListeners(variantKey);
    const variantsData = await getVariantsForProducts(productId, lastFetchedData);
    const oldVariantsData = getVariantsDataForProductFromCache(productId);

    //If the cached data does not exist or if the new response has the variant list
    // then overwrite the existing variant list in cache
    if (!oldVariantsData || Array.isArray(variantsData.variantProductIds)) {
      setVariantsDataForProductInCache({ productId, newVariantProductData: variantsData });
    } else {
      //Send back the old data because some listeners may expect dat after refresh to complete
      // their post processing
      CacheRequest.notifyListeners(variantKey, null, {
        status: CacheRequest.OPERATION_STATUS.SUCCESS,
        data: oldVariantsData
      });
    }

    const { changes } = variantsData;
    if (Array.isArray(changes)) {
      changes.forEach(variantMeta => {
        setVariantsMetaDataInCache({ variantId: variantMeta.id, metaData: variantMeta });
      });
    }
  } catch (fetchVariantsError) {
    //Notify all listeners of an error
    CacheRequest.setCacheForKey(variantKey, undefined, {
      error: fetchVariantsError
    });
  }
};

export const getVariantsDataForProductFromCache = productId => {
  const key = `${PRODUCT_VARIANT_IDS.cacheKey}${productId}`;
  const variantData = CacheRequest.getCacheForKey(key) || null;
  return variantData;
};

export const setVariantsDataForProductInCache = ({ productId, newVariantProductData }) => {
  const key = `${PRODUCT_VARIANT_IDS.cacheKey}${productId}`;
  CacheRequest.setCacheForKey(key, newVariantProductData);
};

export const attachProductVariantMetaListener = ({ listener, variantId }) => {
  const key = `${PRODUCT_VARIANT_META.cacheKey}${variantId}`;
  CacheRequest.attachListener(key, listener);
};

export const removeProductVariantMetaListener = ({ listener, variantId }) => {
  const key = `${PRODUCT_VARIANT_META.cacheKey}${variantId}`;
  CacheRequest.removeListener(key, listener);
};

const variantMetaBatchCallback = (response, error, extraData) => {
  const sharedCacheKey = PRODUCT_VARIANT_META.cacheKey;
  if (error) {
    const failedVariantIds = Object.keys(extraData.variantIdsRequestMap || {});
    failedVariantIds.forEach(failedVariantId => {
      const key = `${sharedCacheKey}${failedVariantId}`;
      CacheRequest.setCacheForKey(key, null, { error });
    });
    return;
  }

  if (response && response.variants && Array.isArray(response.variants)) {
    response.variants.forEach(variantsMetaData => {
      const { id } = variantsMetaData;
      const cache = variantsMetaData;
      const key = `${sharedCacheKey}${id}`;
      VARIANT_META_REQUEST_SEND[id] = true;
      CacheRequest.setCacheForKey(key, cache);
    });
  }
};

const variantMetaDebounceCallback = (multipleBatchedVariantData = []) => {
  const sharedCacheKey = PRODUCT_VARIANT_META.cacheKey;
  const variantIdsRequestMap = {};

  const eligibleVariantIds = multipleBatchedVariantData.reduce((acummalatorArray, variantIds) => {
    variantIds.forEach(variantId => {
      if (!variantId) {
        return;
      }

      if (!VARIANT_META_REQUEST_SEND[variantId]) {
        variantIdsRequestMap[variantId] = true;
        acummalatorArray.push(variantId);
      }
    });

    return acummalatorArray;
  }, []);

  if (!eligibleVariantIds.length) {
    return;
  }

  const oneTimeUniqueKey = `PRODUCT_VARIANT_META_LISTENER_${new Date().getTime()}`;
  const apiCall = PRODUCT_VARIANT_META.apiFunction;

  CacheRequest.makeRequest(oneTimeUniqueKey, apiCall, {
    params: [eligibleVariantIds],
    options: {
      isBatched: true,
      sharedCacheKey: sharedCacheKey,
      shouldNotStoreInNative: true,
      batchCallback: variantMetaBatchCallback,
      extraData: {
        variantIdsRequestMap
      }
    }
  });
};

export const getVariantsMetaData = ({ variantIds } = {}) => {
  debouncer(
    { data: variantIds, key: VARIANT_META_DEBOUNCER.key },
    { time: VARIANT_META_DEBOUNCER.timeInMs, type: VARIANT_META_DEBOUNCER.type },
    variantMetaDebounceCallback
  );
};

export const getVariantsMetaDataFromCache = variantId => {
  const key = `${PRODUCT_VARIANT_META.cacheKey}${variantId}`;
  const meta = CacheRequest.getCacheForKey(key) || {};
  return meta;
};

export const setVariantsMetaDataInCache = ({ variantId, metaData }) => {
  const key = `${PRODUCT_VARIANT_META.cacheKey}${variantId}`;
  CacheRequest.setCacheForKey(key, metaData);
};

const deleteVariantsFromCache = (variantIds, productId) => {
  const variantIdsCacheKey = PRODUCT_VARIANT_IDS.cacheKey;
  const variantIdSet = new Set(variantIds);

  const oldVariantsListData = CacheRequest.getCacheForKey(`${variantIdsCacheKey}${productId}`);
  const { variantProductIds = [] } = oldVariantsListData;
  let { defaultVariantId } = oldVariantsListData;
  const newVariantList = [],
    variantMetaCacheKeys = [];

  let defaultVariantDeleted = false,
    nextVariantIndex = -1;
  variantProductIds.forEach((variantArray, index) => {
    const variantId = variantArray[0];
    //Current variant is not being deleted
    if (!variantIdSet.has(variantId)) {
      newVariantList.push(variantArray);
      return;
    }

    if (nextVariantIndex === index || nextVariantIndex === -1) {
      nextVariantIndex = index + 1;
    }

    if (variantId === defaultVariantId) {
      defaultVariantDeleted = true;
    }

    variantMetaCacheKeys.push(`${PRODUCT_VARIANT_META.cacheKey}${variantId}`);
  });

  let nextVariantId;
  defaultVariantId = null;

  // All variants deleted, no need to process anything, set the cache values,
  // nullify the defaultVariant and return an undefined id to reset the view
  if (newVariantList.length !== 0) {
    //Last eligible variant was deleted, pick the last one in the new list
    if (nextVariantIndex >= variantProductIds.length) {
      nextVariantId = newVariantList[newVariantList.length - 1][0];
    } else {
      //Pick the next variant id from the original list because the index
      // is calculated from the old list
      nextVariantId = variantProductIds[nextVariantIndex][0];
    }

    ({ defaultVariantId } = oldVariantsListData);
    // Default variant was deleted, set the default now to the first item
    // in the new list
    if (defaultVariantDeleted) {
      defaultVariantId = newVariantList[0][0];
    }
  }

  CacheRequest.setCacheForKey(`${variantIdsCacheKey}${productId}`, {
    ...oldVariantsListData,
    defaultVariantId,
    variantProductIds: newVariantList
  });
  CacheRequest.deleteCacheForKeys(variantMetaCacheKeys);

  return nextVariantId;
};

export const deleteVariants = async (variantIds = [], productId) => {
  const allDeleteApis = variantIds.map(async variantId => {
    try {
      return await deleteVariantData(variantId);
    } catch (deleteVariantsError) {
      reportError(deleteVariantsError);
      throw deleteVariantsError;
    }
  });
  const deletedOptionIds = await Promise.all(allDeleteApis);

  const nextVariantId = deleteVariantsFromCache(variantIds, productId);
  deleteProductOptions({ productId, deletedOptionIds });
  return nextVariantId;
};

export const changeDefaultVariant = async ({ parentProductId, variantId }) => {
  try {
    await setDefaultVariant(parentProductId, variantId);
  } catch (error) {
    reportError(error);
    throw error;
  }

  const cachedVariantData = getVariantsDataForProductFromCache(parentProductId);
  const newVariantProductData = {
    ...cachedVariantData,
    defaultVariantId: variantId
  };
  setVariantsDataForProductInCache({ productId: parentProductId, newVariantProductData });
};

const cleanupVariantMeta = () => {
  VARIANT_META_REQUEST_SEND = {};
};

registerCleanupHandler(cleanupVariantMeta);
