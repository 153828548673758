import React, { useState, useEffect, useCallback } from 'react';
import { Input } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const NewInput = withStyles({
  root: {
    color: 'white',
    marginBottom: '1.5rem',
    fontSize: 18,
    fontWeight: 600
  },
  underline: {
    '&:before': {
      borderBottom: '1px solid #565D70 !important'
    }
  }
})(Input);

export default function TextInput(props) {
  const [value, setValue] = useState(props.value || '');
  const { onChange, pattern = '', ...remaining } = props;

  useEffect(() => {
    onChange(value);
  }, [value, onChange]);

  const handleChange = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();
      const {
        target: { value }
      } = e;
      if (new RegExp(pattern).test(value)) {
        setValue(value);
      }
    },
    [pattern]
  );

  return <NewInput onChange={handleChange} {...remaining} value={value} />;
}
