import React from 'react';

export default ({
  onChange,
  inputClassName,
  negative = false,
  integer = false,
  inputRef = null,
  digitsAfterDecimal = 0,
  ...inputProps
}) => {
  const onInputChange = event => {
    event.preventDefault();
    const val = event.target.value.trim();
    if (val === '') {
      if (typeof onChange === 'function') {
        onChange(val);
      }
      return;
    }

    let numVal = Number(val);
    if (Number.isNaN(numVal)) {
      return;
    }

    if (!negative && numVal < 0) {
      return;
    }

    const onChangeListener = num => {
      if (typeof onChange === 'function') {
        onChange(num.toString());
      }
    };

    if (integer) {
      if (!Number.isInteger(numVal)) {
        return;
      }
      onChangeListener(numVal);
      return;
    } else {
      const decimalVal = val.split('.')[1];

      //Check if there's any value after the decimal and add it to the number
      // if required
      if (typeof decimalVal === 'string') {
        const numString = numVal.toString();

        /*
          The number constructor gracefully converts a string with a single '.'
          to a number by removing the string. Hence, the split can be done
          without worrying about the validity of the number
        */
        const numDecimal = numString.split('.')[1];
        //The number value does not have a decimal part, construct the decimal
        // string.
        if (typeof numDecimal !== 'string') {
          numVal = numString + `.${decimalVal}`;
        }
      }
    }

    if (digitsAfterDecimal) {
      const values = val.split('.');
      if (values[1]?.length > digitsAfterDecimal) {
        numVal = `${values[0]}.${values[1].substring(0, digitsAfterDecimal)}`;
        onChangeListener(numVal);
        return;
      }
    }

    if (numVal.toString() !== val) {
      numVal = val;
    }

    onChangeListener(numVal);
  };

  return (
    <input
      {...inputProps}
      ref={inputRef}
      type="tel"
      onChange={onInputChange}
      className={`${inputClassName || ''}`}
    />
  );
};
