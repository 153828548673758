import { setOrderMetaInCache } from 'qs-data-manager/Orders/OrdersOperations';
import { getPriceFormattedComponent } from '..';
import { registerCleanupHandler } from '../ClearSavedData';
import {
  ACCEPTED_ORDER_META,
  ACCEPTED_ORDERS,
  COMPLETED_ORDER_META,
  COMPLETED_ORDERS,
  CONFIRMED_ORDER_META,
  CONFIRMED_ORDERS,
  ORDER_STATUS,
  PAYMENT_MODES,
  REJECTED_ORDER_META,
  REJECTED_ORDERS,
  UNCONFIRMED_ORDER_META,
  UNCONFIRMED_ORDERS
} from './constants';

const ORDERS_LAST_FETCHED = {
  ts: {},
  key: {},
  inProgress: {},
  requestNum: {}
};

export const createKeyForOrderOperation = ({ confirmed, sortKey }) => `${sortKey}${confirmed}`;

export const setLastFetchedOrderTimeStamp = ({ confirmed, sortKey, date }) => {
  if (!date) {
    return;
  }
  ORDERS_LAST_FETCHED.ts[createKeyForOrderOperation({ confirmed, sortKey })] = date;
};

export const getLastFetchedOrderTimeStamp = ({ confirmed, sortKey }) =>
  ORDERS_LAST_FETCHED.ts[createKeyForOrderOperation({ confirmed, sortKey })];

export const setLastFetchedOrderKey = ({ confirmed, sortKey, key }) => {
  if (!key) {
    return;
  }
  ORDERS_LAST_FETCHED.key[createKeyForOrderOperation({ confirmed, sortKey })] = key;
};

export const getLastFetchedOrderKey = ({ confirmed, sortKey }) =>
  ORDERS_LAST_FETCHED.key[createKeyForOrderOperation({ confirmed, sortKey })];

export const clearCompanyOrdersLastFetchedResponse = () => {
  ORDERS_LAST_FETCHED.ts = undefined;
  ORDERS_LAST_FETCHED.key = undefined;
  ORDERS_LAST_FETCHED.inProgress = false;
};

export const setPaginatedCallInProg = ({ confirmed, sortKey, inProgress }) => {
  if (typeof inProgress !== 'boolean') {
    return;
  }
  ORDERS_LAST_FETCHED.inProgress[createKeyForOrderOperation({ confirmed, sortKey })] = inProgress;
};

export const isPaginatedCallInProg = ({ confirmed, sortKey }) =>
  ORDERS_LAST_FETCHED.inProgress[createKeyForOrderOperation({ confirmed, sortKey })];

export const getCurrentRequestNumber = ({ confirmed, sortKey }) =>
  ORDERS_LAST_FETCHED.requestNum[createKeyForOrderOperation({ confirmed, sortKey })];

export const incrementRequestNumber = ({ confirmed, sortKey }) => {
  const currentRequestNum = getCurrentRequestNumber({ confirmed, sortKey });
  ORDERS_LAST_FETCHED.requestNum[createKeyForOrderOperation({ confirmed, sortKey })] =
    typeof currentRequestNum === 'number' ? currentRequestNum + 1 : 0;
};

export const normalizePaginatedOrdersList = ordersList => {
  if (!Array.isArray(ordersList)) {
    return;
  }

  return ordersList.map(orderData => {
    setOrderMetaInCache({ orderId: orderData.id, updates: orderData });
    return { id: orderData.id };
  });
};

export const getPaymentModeTextFromEnum = (paymentMode, additionalPaymentMode) => {
  switch (paymentMode) {
    case PAYMENT_MODES.CASH_ON_DELIVERY:
      return 'Cash on delivery';
    case PAYMENT_MODES.RAZORPAY:
      return 'Razorpay';
    case PAYMENT_MODES.PAYTM:
      return 'Paytm';
    case PAYMENT_MODES.PAYPAL:
      return 'Paypal';
    case PAYMENT_MODES.PARTIAL_COD:
      if (!additionalPaymentMode) {
        return '';
      }
      return `${getPaymentModeTextFromEnum(additionalPaymentMode)} + Partial COD`;
    default:
      return paymentMode;
  }
};

/**
 * This method will return the setQuantity for the product that can be used as
 * a multiplier for all calculations that rely on the inquiry itemCount. If the
 * product is not a set then 1 will be returned so that non-set products remain
 * unchanged
 * @param {object} param0 a valid object
 *   product is the product whose set multiplier must be determined
 * @returns setQuantity of the product or 1 if not a set. If product is not
 * specified then 0 is returned
 */
export const getSetMultiplierFromProduct = ({ product }) => {
  if (!product) {
    return 0;
  }

  if (!isInquirySet({ product })) {
    return 1;
  }

  const { optionMeta } = product;
  //Inquiry is a product, return the set value
  if (!Array.isArray(optionMeta)) {
    const { setQuantity } = product;
    return setQuantity || 1;
  }

  return optionMeta.reduce((finalQuantity, { setQuantity, isSet }) => {
    let finalSetQuantity = 1;
    if (isSet) {
      finalSetQuantity = setQuantity || 1;
    }

    finalQuantity *= finalSetQuantity;
    return finalQuantity;
  }, 1);
};

export const isInquirySet = ({ product }) => {
  if (!product) {
    return false;
  }

  const { optionMeta } = product;
  //Inquiry is a product, return the set value
  if (!Array.isArray(optionMeta)) {
    return product.isSet || false;
  }

  for (const option of optionMeta) {
    if (option.isSet) {
      return true;
    }
  }
  return false;
};

export const getOrderDisplayStatus = ({ isFinalized, orderStatus } = {}) => {
  if (!isFinalized) {
    return ORDER_STATUS.UNCONFIRMED;
  }

  if (typeof orderStatus === 'string') {
    return orderStatus;
  }

  return ORDER_STATUS.CONFIRMED;
};

export const getOrderMetaAsPerOrderStatus = (orderStatus, isFinalized) => {
  if (!isFinalized) {
    return UNCONFIRMED_ORDER_META;
  }

  switch (orderStatus) {
    case ORDER_STATUS.ACCEPTED:
      return ACCEPTED_ORDER_META;
    case ORDER_STATUS.REJECTED:
      return REJECTED_ORDER_META;
    case ORDER_STATUS.COMPLETED:
      return COMPLETED_ORDER_META;
    default:
      return CONFIRMED_ORDER_META;
  }
};

export const getOrderStatusFromStatusFilter = orderStatusFilter => {
  switch (orderStatusFilter) {
    case CONFIRMED_ORDERS:
      return ORDER_STATUS.CONFIRMED;
    case UNCONFIRMED_ORDERS:
      return ORDER_STATUS.UNCONFIRMED;
    case ACCEPTED_ORDERS:
      return ORDER_STATUS.ACCEPTED;
    case REJECTED_ORDERS:
      return ORDER_STATUS.REJECTED;
    case COMPLETED_ORDERS:
      return ORDER_STATUS.COMPLETED;
    default:
      return ORDER_STATUS.ALL;
  }
};

export const getStockCountFromNewItemCount = ({ stockCount, itemCount, updatedCount }) =>
  stockCount + (itemCount - Number(updatedCount));

export const getShippingCostFromInquiryData = inquiryData => {
  const { shippingCost, shippingCostSeller } = inquiryData || {};
  if (typeof shippingCostSeller === 'number') {
    return shippingCostSeller;
  }

  return shippingCost;
};

export const canConfirmUnconfirmedOrder = inquiries => {
  if (!Array.isArray(inquiries)) {
    return false;
  }

  let orderCanBeConfirmed = false;
  for (const inquiry of inquiries) {
    //At least one inquiry is not deleted, allow order to be confirmed
    if (!inquiry.isDeletedByVisitor) {
      orderCanBeConfirmed = true;
      break;
    }
  }

  return orderCanBeConfirmed;
};

export const getFlatValueIfEstimateIsClose = ({ flatValue, estimatedValue }) => {
  if (Math.abs(flatValue - estimatedValue) <= 0.05) {
    return flatValue;
  }

  return estimatedValue;
};

export const isCouponFlatAmount = coupon => coupon && coupon.discountAmount;

export const canApplyCouponOnDiscountedPrice = coupon => !coupon || coupon.applyOnDiscountedPrice;

const doesCouponExist = coupon => coupon && Object.keys(coupon).length >= 0;

export const getCouponDiscountValueToShow = coupon => {
  if (!doesCouponExist(coupon)) {
    return null;
  }

  const { discountPercent, discountAmount } = coupon;
  let discountValueToShow = null;
  if (typeof discountPercent === 'number') {
    discountValueToShow = `(${discountPercent}%)`;
  }
  if (typeof discountAmount === 'number') {
    discountValueToShow = `(${getPriceFormattedComponent(discountAmount)})`;
  }

  return discountValueToShow;
};

export const getCouponCode = coupon => {
  if (!doesCouponExist(coupon)) {
    return '';
  }

  return coupon.code || '';
};

export const clearSavedQueryVars = () => {
  ORDERS_LAST_FETCHED.ts = {};
  ORDERS_LAST_FETCHED.key = {};
  ORDERS_LAST_FETCHED.inProgress = {};
};

const cleanupSavedQueryVars = () => {
  ORDERS_LAST_FETCHED.ts = {};
  ORDERS_LAST_FETCHED.key = {};
  ORDERS_LAST_FETCHED.inProgress = {};
  ORDERS_LAST_FETCHED.requestNum = {};
};

registerCleanupHandler(cleanupSavedQueryVars);
