import React from 'react';

export const CurrentDefaultImageContext = React.createContext({
  currentImageMeta: null,
  setNewImageData: () => {}
});

export const ActiveVariantId = React.createContext({
  activeVariantId: null,
  setActiveVariantId: () => {}
});
