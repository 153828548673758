import React, { useState, useEffect } from 'react';
import { Modal } from '@material-ui/core';
import ProductLibraryView from '../../Catalogue/ProductLibraryUploader/ProductLibraryView';
import { POST_MESSAGE_SLUGS, windowPostMessageProxy } from 'qs-helpers/WindowPostMessageProxy';

export const ProductLibraryForCustomPagesIframe = () => {
  const [showProductLibrary, toggleShowProductLibrary] = useState(false);
  const [postMessageMetadata, setPostMessageMetadata] = useState(null);
  const [defaultSelectedProducts, setDefaultSelectedProducts] = useState([]);

  useEffect(() => {
    if (!showProductLibrary) {
      sendIframePostMessage({
        slug: postMessageMetadata
          ? POST_MESSAGE_SLUGS.ProductLinkLibrary
          : POST_MESSAGE_SLUGS.ProductLibrary,
        value: POST_MESSAGE_SLUGS.Hide
      });
    }
  }, [postMessageMetadata, showProductLibrary]);

  windowPostMessageProxy.addHandler({
    test: message => {
      return (
        [POST_MESSAGE_SLUGS.ProductLibrary, POST_MESSAGE_SLUGS.ProductLinkLibrary].indexOf(
          message?.slug
        ) > -1
      );
    },
    handle: message => {
      if (message?.slug === POST_MESSAGE_SLUGS.ProductLinkLibrary && message?.metaData) {
        setPostMessageMetadata(message?.metaData);
      } else {
        setPostMessageMetadata(null);
      }
      if (message?.metaData) {
        setDefaultSelectedProducts(message?.metaData?.productIds || []);
      } else {
        setDefaultSelectedProducts([]);
      }
      setShowProductLibrary(message?.value === POST_MESSAGE_SLUGS.Show);
      return;
    }
  });

  const setShowProductLibrary = showProductLibrary => {
    toggleShowProductLibrary(showProductLibrary);
  };

  const getSelectedProducts = (selectedProducts, currentCatalogue) => {
    sendIframePostMessage({
      slug: postMessageMetadata
        ? POST_MESSAGE_SLUGS.ProductLinkSelected
        : POST_MESSAGE_SLUGS.ProductSelected,
      value: selectedProducts,
      metaData: postMessageMetadata
        ? { ...postMessageMetadata, catalogueId: currentCatalogue }
        : null
    });
  };

  const sendIframePostMessage = postMessageBody => {
    const iframe =
      document.querySelector('iframe#custom_pages') ||
      document.querySelector('iframe#other_pages') ||
      document.querySelector('iframe#catalogue_banners');
    if (iframe && iframe.contentWindow && iframe.contentWindow.postMessage) {
      windowPostMessageProxy.postMessage(iframe.contentWindow, postMessageBody);
    }
  };

  return (
    <Modal open={showProductLibrary}>
      <div style={{ height: '100%', width: '100%' }}>
        <ProductLibraryView
          onCancel={() => setShowProductLibrary(false)}
          productLibraryUsedWithinIframe={true}
          disableMultiple={postMessageMetadata}
          defaultSelectedProducts={defaultSelectedProducts}
          getSelectedProducts={getSelectedProducts}
        />
      </div>
    </Modal>
  );
};

export default ProductLibraryForCustomPagesIframe;
