export const ORDERS_LIST = {
  rowHeight: 90,
  overscanCount: 5
};

export const ORDER_PRODUCT_LIST = {
  rowHeight: 80,
  headerHeight: 50,
  overscanCount: 5
};

export const ORDER_TAX_LIST = {
  rowHeight: 60,
  overscanCount: 5
};

export const ALL_ORDERS = 'ALL_ORDERS';

export const CONFIRMED_ORDERS = 'CONFIRMED_ORDERS';

export const UNCONFIRMED_ORDERS = 'UNCONFIRMED_ORDERS';

export const COMPLETED_ORDERS = 'COMPLETED_ORDERS';

export const ACCEPTED_ORDERS = 'ACCEPTED_ORDERS';

export const REJECTED_ORDERS = 'REJECTED_ORDERS';

export const DATE_CREATED_SORT_KEY = 'dateCreated';

export const DATE_UPDATED_SORT_KEY = 'dateUpdated';

export const EDIT_NOT_ALLOWED = {
  UNCONFIRMED_ORDER_REASONS: {
    priceReason: 'price_cannot_be_changed_for_an_unconfirmed_order',
    quantityReason: 'quantity_cannot_be_edited_for_an_unconfirmed_order',
    taxReason: 'tax_cannot_be_added_for_an_unconfirmed_order',
    discountPriceReason: 'discounted_price_cannot_be_changed_for_an_unconfirmed_order',
    orderDiscountReason: 'discount_cannot_be_edited_for_an_unconfirmed_order',
    shippingReason: 'shipping_cannot_be_edited_for_an_unconfirmed_order'
  },
  SHIPPING_PROVIDERS: {
    SHIPROCKET: {
      priceReason: 'price_cannot_be_changed_for_an_order_that_is_placed_with_shiprocket',
      quantityReason: 'quantity_cannot_be_edited_for_an_order_that_is_placed_with_shiprocket',
      taxReason: 'tax_cannot_be_added_for_an_order_that_is_placed_with_shiprocket',
      discountPriceReason:
        'discounted_price_cannot_be_changed_for_an_order_that_is_placed_with_shiprocket',
      orderDiscountReason: 'discount_cannot_be_edited_for_an_order_that_is_placed_with_shiprocket',
      shippingReason: 'shipping_cannot_be_edited_for_an_order_that_is_placed_with_shiprockett'
    }
  }
};

/*
  This denotes all the possible filter/sort combination. Each list will be
  cached against this key combinations. Hence the required list-key can be
  obtained using this array
*/
export const ALL_FILTER_SORT_KEYS = [
  { confirmed: ALL_ORDERS, sortKey: DATE_UPDATED_SORT_KEY },
  { confirmed: CONFIRMED_ORDERS, sortKey: DATE_UPDATED_SORT_KEY },
  { confirmed: UNCONFIRMED_ORDERS, sortKey: DATE_UPDATED_SORT_KEY },
  { confirmed: ACCEPTED_ORDERS, sortKey: DATE_UPDATED_SORT_KEY },
  { confirmed: REJECTED_ORDERS, sortKey: DATE_UPDATED_SORT_KEY },
  { confirmed: COMPLETED_ORDERS, sortKey: DATE_UPDATED_SORT_KEY }
];

export const ITEM_DELETE_HEADER_VIEW = 'ITEM_DELETE';

export const INVENTORY_HEADER_VIEW = 'INVENTORY';

export const PRICE_INPUT = 'PRICE_INPUT';

export const DISCOUNT_INPUT = 'DISCOUNT_INPUT';

export const ORDER_STATUS = {
  ALL: 'ALL',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
  COMPLETED: 'COMPLETED',
  CONFIRMED: 'CONFIRMED',
  UNCONFIRMED: 'UNCONFIRMED'
};

export const CALCULATED_SHIPPING = 'CALCULATED_SHIPPING';
export const CUSTOM_SHIPPING = 'CUSTOM_SHIPPING';
export const NO_SHIPPING = 'NO_SHIPPING';

export const ALL_ORDER_META = {
  orderStatusCacheKey: ALL_ORDERS
};

export const CONFIRMED_ORDER_META = {
  orderStatusCacheKey: CONFIRMED_ORDERS
};

export const UNCONFIRMED_ORDER_META = {
  orderStatusCacheKey: UNCONFIRMED_ORDERS
};

export const ACCEPTED_ORDER_META = {
  orderStatusCacheKey: ACCEPTED_ORDERS
};

export const REJECTED_ORDER_META = {
  orderStatusCacheKey: REJECTED_ORDERS
};

export const COMPLETED_ORDER_META = {
  orderStatusCacheKey: COMPLETED_ORDERS
};

export const AMOUNT_FLAT = 'FLAT';
export const AMOUNT_PERCENT = 'PERCENT';

export const SHIPPING_PROVIDERS = {
  SHIPROCKET: {
    label: 'Shiprocket',
    displaySection: true
  }
};

export const PAYMENT_MODES = {
  CASH_ON_DELIVERY: 'CASH_ON_DELIVERY',
  PAYTM: 'PAYTM',
  PAYPAL: 'PAYPAL',
  RAZORPAY: 'RAZORPAY',
  PARTIAL_COD: 'PARTIAL_COD'
};

export const EDIT_ORDER_CUSTOM_ERROR_MESSAGES = {
  PRODUCT_OOS_ORDER_NOT_ALLOWED: {
    message: 'the_quantity_you_have_chosen_will_make_the_product_out_of_stock',
    shouldShowSupportButton: false
  },
  QUANTITY_NOT_LESS_THAN_MOQ: {
    message: 'the_quantity_you_have_chosen_does_not_satisfy_the_minimum_order_quantity',
    shouldShowSupportButton: false
  }
};
