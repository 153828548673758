import React, { useCallback, useEffect, useRef, useState } from 'react';
import usePreviousHook from '../../CustomHooks/usePreviousHook';
import { attachHLSMediaToVideo, VIDEO_ERROR_CODES } from './Video';
import { hslVideoUrl } from 'qs-services/VideoUpload/UploadVideos/Api';
import './styles.scss';

export default function VideoPlayer({
  onVideoMount,
  className,
  videoId,
  videoUrl,
  seekTo,
  withAudio,
  controls
}) {
  const videoRef = useRef();
  const videoProcessing = useRef({});
  const [videoError, setVideoError] = useState(false);

  const [previousVideoError] = usePreviousHook({ values: [videoError] });

  const loadVideo = useCallback(() => {
    attachHLSMediaToVideo({
      videoRef: videoRef.current,
      videoSrcs: videoUrl || hslVideoUrl(videoId)
    })
      .then(({ onDone } = {}) => {
        videoProcessing.current.onDone = onDone;
      })
      .catch(({ code } = {}) => {
        if (
          code === VIDEO_ERROR_CODES.HLS_MODULE_LOAD ||
          code === VIDEO_ERROR_CODES.HLS_VIDEO_LOAD
        ) {
          setVideoError({ retry: true, message: 'An error occured during playback' });
          return;
        }

        if (code === VIDEO_ERROR_CODES.HLS_UNSUPPORTED) {
          setVideoError({
            message: 'Video playback is not supported on your device'
          });
          return;
        }

        if (code === VIDEO_ERROR_CODES.INTERNAL_CODE_ERROR) {
          setVideoError({
            message: 'An internal error occured during playback'
          });
          return;
        }

        setVideoError({
          message: 'Something went wrong while processing this video'
        });
      });
  }, [videoUrl, videoId]);

  useEffect(() => {
    loadVideo();
  }, [loadVideo]);

  useEffect(() => {
    if (videoRef.current && typeof seekTo === 'number') {
      videoRef.current.currentTime = seekTo;
    }
  }, [seekTo]);

  useEffect(() => {
    // On click of retry the error will be cleared. Hence check with the previous data
    if (previousVideoError && previousVideoError.retry && !videoError) {
      loadVideo();
    }
  }, [loadVideo, videoError, previousVideoError]);

  const clearVideoResources = useCallback(() => {
    if (typeof videoProcessing.current.onDone === 'function') {
      videoProcessing.current.onDone();
    }
  }, []);

  useEffect(
    () => () => {
      clearVideoResources();
    },
    [clearVideoResources]
  );

  const handleVideoRef = useCallback(
    ref => {
      videoRef.current = ref;
      onVideoMount(ref);
    },
    [onVideoMount]
  );

  const onRetryVideo = () => {
    setVideoError();
    clearVideoResources();
  };

  if (videoError) {
    const { message, retry } = videoError;
    return (
      <div className={`video-error-container`}>
        <span>{message}</span>
        {retry && (
          <button className="retry-button" onClick={onRetryVideo}>
            Retry
          </button>
        )}
      </div>
    );
  }

  return (
    <video controls={controls} muted={!withAudio} ref={handleVideoRef} className={className} />
  );
}
