import React from 'react';

function OnePerPageIcon() {
  return (
    <svg width="61" height="46" viewBox="0 0 61 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="46" width="46" height="61" rx="2" transform="rotate(-90 0 46)" fill="#9DA4B1" />
    </svg>
  );
}

export default OnePerPageIcon;
