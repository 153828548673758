import React from 'react';
// import { withStyles, Input } from '@material-ui/core';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

// const SearchInput = withStyles({
//   root: {
//     backgroundColor: '#323743',
//     color: '#ffffff',
//     marginRight: 20,
//     padding: 5,
//     borderRadius: 4,
//     boxShadow: '0px 2px 13px -6px rgba(0,0,0,0.5)'
//   },
//   input: {
//     paddingLeft: 5
//   }
// })(Input);

export default ({ productCount = 0 }) => {
  // const [searchVal, setSearchVal] = useState('');
  const { t } = getI18N();

  // const handleSearchChange = event => {
  //   setSearchVal(event.target.value);
  // };

  return (
    <div className="productLibraryHeaderContainer">
      <div className="searchCountContainer">
        {/*TODO will be added in the next release */}
        {/*<div>
          <SearchInput
            placeholder="Search"
            name="search-products"
            onChange={handleSearchChange}
            value={searchVal}
            disableUnderline
            startAdornment={
              <InputAdornment>
                <SearchIcon className="searchIcon" />
              </InputAdornment>
            }
          />
          </div>*/}
        <div>
          {productCount} {t('products')}
        </div>
      </div>
    </div>
  );
};
