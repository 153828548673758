import React, { Fragment } from 'react';
import './styles.scss';
import { EXCEL_UPLOAD_META } from 'qs-data-manager/Catalogues';
import ImagePreview from './ImagePreview';
import RowPreview from './RowPreview';
import { getI18N } from 'qs-services/i18N';

export default ({ previewMeta, excelComponentMap }) => {
  const { t } = getI18N();

  if (!previewMeta || !Object.keys(previewMeta).length) {
    return null;
  }

  const renderPreviewRow = rowId => {
    if (!(rowId in previewMeta)) {
      const { ignoreMissingPreview = false } = excelComponentMap[rowId] || {};
      if (ignoreMissingPreview) {
        return (
          <RowPreview
            key={rowId}
            rowId={rowId}
            previewMeta={{}}
            excelComponentMap={excelComponentMap}
          />
        );
      }

      return null;
    }

    return (
      <Fragment key={rowId}>
        {rowId === EXCEL_UPLOAD_META.IMAGE.id && previewMeta && previewMeta[rowId] ? (
          <ImagePreview key={rowId} url={previewMeta[rowId][0]} />
        ) : null}

        <RowPreview rowId={rowId} previewMeta={previewMeta} excelComponentMap={excelComponentMap} />
      </Fragment>
    );
  };

  return (
    <div id={'excel-product-preview'}>
      <div className={'header'}>{t('preview')}</div>
      <div className={'product-preview'}>
        <div className={'product-details'}>
          {EXCEL_UPLOAD_META.RENDER_ORDER.reduce((cumulativeArray, rowId) => {
            const { subExcelComponents } = excelComponentMap[rowId] || {};
            let subSectionRows = [];
            if (Array.isArray(subExcelComponents)) {
              subSectionRows = subExcelComponents.reduce((filteredArray, subComponentId) => {
                const subSectionRow = renderPreviewRow(subComponentId);
                if (subSectionRow !== null) {
                  filteredArray.push(subSectionRow);
                }
                return filteredArray;
              }, []);
            }

            //Sub sections exist and none of them have been mapped, don't show the entire section
            if (Array.isArray(subExcelComponents) && subSectionRows.length === 0) {
              return cumulativeArray;
            }

            cumulativeArray.push(renderPreviewRow(rowId));
            cumulativeArray.push(...subSectionRows);
            return cumulativeArray;
          }, [])}
        </div>
      </div>
    </div>
  );
};
