import { B2BErrorReasonMap, B2B_PDF_CANT_GENERATE_REASONS } from './constants';

export const getB2BErrorFromReason = cantGenerateB2BInvoiceReason => {
  // TODO later will change to the dynamically set value
  let reason = '';
  switch (cantGenerateB2BInvoiceReason) {
    case B2B_PDF_CANT_GENERATE_REASONS.ORDER_MISSING_EXPAND_VARIANT_B2B_INVOICE:
      reason =
        B2BErrorReasonMap[B2B_PDF_CANT_GENERATE_REASONS.ORDER_MISSING_EXPAND_VARIANT_B2B_INVOICE];
      break;
    case B2B_PDF_CANT_GENERATE_REASONS.ORDER_WRONG_VARIANT_COMBINATIONS_B2B_INVOICE:
      reason =
        B2BErrorReasonMap[
          B2B_PDF_CANT_GENERATE_REASONS.ORDER_WRONG_VARIANT_COMBINATIONS_B2B_INVOICE
        ];
      break;
    case B2B_PDF_CANT_GENERATE_REASONS.ORDER_TOO_MANY_EXPAND_VARIANT_COLUMNS_B2B_INVOICE:
      reason =
        B2BErrorReasonMap[
          B2B_PDF_CANT_GENERATE_REASONS.ORDER_TOO_MANY_EXPAND_VARIANT_COLUMNS_B2B_INVOICE
        ];
      break;

    // Mapping not handled
    default:
      reason = 'something_went_wrong_please_try_again';
      break;
  }

  return reason;
};
