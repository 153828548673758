import React, { useCallback, useMemo, useState } from 'react';
import { VariableSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import useDynamicSizedList from 'qs-hooks/dynamicSizedList';
import { DialogDarkTheme } from '../../../../../Common/DarkThemeDialog';
import { Button, DialogContent, makeStyles, Modal } from '@material-ui/core';
import {
  getCachedCompanyTaxes,
  fetchCompanyTaxesHandler,
  handleCompanyTaxesCleanup
} from 'qs-helpers/Tax/DataQueryHelper';
import { ORDER_TAX_LIST } from 'qs-helpers/Orders/constants';
import useMakeQuery from 'qs-hooks/useMakeQuery';
import Loader from 'qs-components/Common/Loader';
import ErrorIcon from 'qs-components/Common/ErrorIcon';
import useUpdateListOnItemUpdate from 'qs-hooks/useUpdateListOnItemUpdate';
import { stringifyTax } from 'qs-helpers';
import TaxSettings from 'qs-components/AccountSettings/CompanySettings/TaxSettings';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

const useStyles = makeStyles({
  dialogContainer: {
    width: '100%',
    height: '100%'
  },
  dialogContentContainer: {
    paddingLeft: 0,
    paddingRight: 0
  },
  manageTax: {
    color: '#82B4FB',
    fontSize: 14,
    fontWeight: 400
  },
  taxButton: {
    flex: '0 0 auto',
    color: '#ffffff'
  },
  setTaxButton: {
    backgroundColor: '#49a579',
    '&:hover': {
      backgroundColor: '#31d184'
    }
  },
  removeTaxButton: {
    backgroundColor: '#d25b5b',
    '&:hover': {
      backgroundColor: '#e34545'
    }
  }
});

export default ({ onUpdate, onCancel, taxes }) => {
  const classes = useStyles();
  const [{ loading, error, data: companyTaxes }] = useMakeQuery({
    changeDependancy: [],
    actionHandler: fetchCompanyTaxesHandler,
    cleanupHandler: handleCompanyTaxesCleanup,
    cachedDataHandler: getCachedCompanyTaxes,
    globalLoader: false
  });
  const [inquiryTaxListRef, itemHeightMap, reRenderListOnItemUpdate] = useDynamicSizedList();
  const [showTaxesView, setShowTaxesView] = useState(false);
  const { t } = getI18N();

  const toggleShowTaxes = useCallback(() => {
    setShowTaxesView(prevVal => !prevVal);
  }, []);

  const appliedTaxIds = useMemo(() => {
    if (!Array.isArray(taxes)) {
      return {};
    }

    return taxes.reduce((cummulativeMap, { taxId }) => {
      cummulativeMap[taxId] = true;
      return cummulativeMap;
    }, []);
  }, [taxes]);

  const itemKey = useCallback((index, data) => data[index].taxId, []);

  const onSetTax = useCallback(
    taxData => {
      onUpdate(taxData);
    },
    [onUpdate]
  );

  const onRemoveTax = useCallback(() => {
    onUpdate();
  }, [onUpdate]);

  const renderRow = useCallback(
    ({ data, index, style }) => {
      const taxRowData = data[index] || {};
      const [, setOrderRowRef] = useUpdateListOnItemUpdate({
        reRenderListOnItemUpdate,
        defaultRowHeight: ORDER_TAX_LIST.rowHeight,
        index,
        uniqueId: taxRowData.taxId
      });

      const currentTaxApplied = appliedTaxIds[taxRowData.taxId];
      let buttonText = t('set_tax');
      let buttonStyle = classes.setTaxButton;
      let buttonClickHandler = onSetTax;
      if (currentTaxApplied) {
        buttonText = t('remove_tax');
        buttonClickHandler = onRemoveTax;
        buttonStyle = classes.removeTaxButton;
      }

      return (
        <div style={style} className={`taxRowHover ${currentTaxApplied ? 'selected' : ''}`}>
          <div ref={setOrderRowRef} className="taxRow">
            <div className="taxStringified">{stringifyTax([taxRowData])}</div>
            <Button
              variant="contained"
              onClick={() => buttonClickHandler(taxRowData)}
              classes={{
                root: classes.taxButton,
                contained: buttonStyle
              }}
            >
              {buttonText}
            </Button>
          </div>
        </div>
      );
    },
    [
      reRenderListOnItemUpdate,
      appliedTaxIds,
      t,
      classes.setTaxButton,
      classes.taxButton,
      classes.removeTaxButton,
      onSetTax,
      onRemoveTax
    ]
  );

  const getItemHeight = useCallback(
    index => {
      const taxData = companyTaxes.taxes[index];
      if (!taxData) {
        return ORDER_TAX_LIST.rowHeight;
      }
      const taxRowHeight = itemHeightMap.current.get(taxData.taxId);
      if (taxRowHeight !== undefined) {
        return taxRowHeight;
      }

      return ORDER_TAX_LIST.rowHeight;
    },
    [companyTaxes, itemHeightMap]
  );

  const renderTaxListComponent = () => {
    if (error) {
      return (
        <div className="placeholderDataContainer">
          <ErrorIcon />
        </div>
      );
    }

    if (loading) {
      return (
        <div className="placeholderDataContainer">
          <Loader size="large" />
        </div>
      );
    }

    const { taxes } = companyTaxes || {};
    if (!Array.isArray(taxes) || taxes.length === 0) {
      return <div className="placeholderDataContainer">{t('no_taxes_available')}</div>;
    }

    return (
      <AutoSizer>
        {({ height, width }) => (
          <List
            ref={inquiryTaxListRef}
            height={height}
            width={width}
            itemData={taxes}
            itemCount={taxes.length}
            itemKey={itemKey}
            itemSize={getItemHeight}
            overscanCount={ORDER_TAX_LIST.overscanCount}
          >
            {renderRow}
          </List>
        )}
      </AutoSizer>
    );
  };

  const handleEmptySpaceClick = event => {
    if (event.target.id === 'order-edit-manage-taxes') {
      toggleShowTaxes();
    }
  };

  const renderTaxesView = () => {
    if (!showTaxesView) {
      return null;
    }

    return (
      <Modal open={true} onClose={toggleShowTaxes}>
        <div
          className="orderEditManageTaxesModalContainer"
          id="order-edit-manage-taxes"
          onClick={handleEmptySpaceClick}
        >
          <div className="taxesModalViewContainer">
            <div className="taxViewContainer">
              <TaxSettings />
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <>
      <DialogDarkTheme
        open={true}
        onClose={onCancel}
        maxWidth="xs"
        PaperProps={{
          classes: {
            root: classes.dialogContainer
          }
        }}
      >
        <DialogContent
          className="orderTaxListEditDialogContainer"
          classes={{ root: classes.dialogContentContainer }}
        >
          <div className="titleContainer">
            <div className="title">{t('taxes')}</div>
            <Button onClick={toggleShowTaxes} classes={{ root: classes.manageTax }}>
              {t('manage_taxes')}
            </Button>
          </div>
          <div className="listContainer">{renderTaxListComponent()}</div>
        </DialogContent>
      </DialogDarkTheme>
      {renderTaxesView()}
    </>
  );
};
