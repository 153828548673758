import Api from 'qs-services/Api';
import { reportError } from 'qs-helpers/ErrorReporting';
import { connector } from 'qs-data-manager/ApiAndCacheConnector';
import CacheRequest from 'qs-data-manager/CacheRequest';
import {
  updateProductsListInNative,
  updateProductMetaInNative,
  updateProductListInCache
} from '../Helper/CacheNativeHelper';
import { deleteDefaultImageBasicInfo } from '../Helper/ProductBasicInfoHelper';
import { updateCatalogueInNative } from 'qs-data-manager/Dexie/CatalogueDexieHelpers';
import { getActiveProductId, resetActiveProductId } from 'qs-data-manager/Products';

const updateCatalogueErrorCount = catalogueId => {
  const catalogueRowCacheKey = `${connector.CATALOGUE_ROW_META.cacheKey}${catalogueId}`;
  const oldCatalogueRowCache = CacheRequest.getCacheForKey(catalogueRowCacheKey);

  // Delete all will either delete all erorored or will change the errored
  // ones into a non-errored state. Hence the count will be 0. In case
  // if in the same catalogue, at about the same time another product was errored, then
  // the real time update will take care of it. Hence reduce the count to 0 safely
  const newCatalogueRowCache = { ...oldCatalogueRowCache, erroredProductCount: 0 };
  CacheRequest.setCacheForKey(catalogueRowCacheKey, newCatalogueRowCache);
  return newCatalogueRowCache;
};

const deleteProductsDataFromCache = deletedProductIds => {
  let activeProductDeleted = false;
  const activeProductId = getActiveProductId();
  const cacheKeysToDelete = deletedProductIds.map(productId => {
    if (productId === activeProductId) {
      activeProductDeleted = true;
    }
    return `${connector.PRODUCT_ROW_META.cacheKey}${productId}`;
  });
  CacheRequest.deleteCacheForKeys(cacheKeysToDelete);

  //If the errored product is being viewed and the product is deleted,
  // then reset the active Id so that the view is also reset
  if (activeProductDeleted) {
    resetActiveProductId();
  }
};

const writeProductsDataToCache = (response, error) => {
  const sharedCacheKey = connector.PRODUCT_ROW_META.cacheKey;
  if (error) {
    return;
  }

  if (!!response && !!response.productMeta && typeof response.productMeta === 'object') {
    const updateProductData = Object.keys(response.productMeta).reduce((productMetaArray, id) => {
      const cache = response.productMeta[id];
      productMetaArray.push(cache);
      const key = `${sharedCacheKey}${id}`;
      CacheRequest.setCacheForKey(key, cache);
      return productMetaArray;
    }, []);

    updateProductMetaInNative(updateProductData);
  }
};

/**
 * Update only those products whose meta has already been fetched
 * @param {Array} updatedProductIds the products that have been updated in remote
 */
const updateProductsDataInCache = updatedProductIds => {
  let activeProductData = null;

  /*
    Send a batched update call for only those products for whom a meta call has
    already been made. The rest of the products will load when they come in view
  */
  const productIdsForUpdate = updatedProductIds.reduce((filteredProductsIds, productId) => {
    const productCacheKey = `${connector.PRODUCT_ROW_META.cacheKey}${productId}`;
    const productDataFromCache = CacheRequest.getCacheForKey(productCacheKey);

    //Current product data does not exist in cache, proceed to the next product
    if (!productDataFromCache) {
      return filteredProductsIds;
    }

    if (productId === getActiveProductId()) {
      activeProductData = {
        productId,
        pictureId: productDataFromCache.pictureId
      };
    }

    filteredProductsIds.push(productId);

    // Default image of these products has updated. Remove prepared and error info
    // So that a loader starts to appear on the screens. The correct data will be updated
    // once the meta call succeeds
    const newCacheValue = {
      ...productDataFromCache,
      isPrepared: false,
      defaultImageErrored: false
    };
    CacheRequest.setCacheForKey(productCacheKey, newCacheValue);
    return filteredProductsIds;
  }, []);

  return { productIdsForUpdate, activeProductData };
};

const updateProductsViewData = ({ updatedProductIds }) => {
  //No products updated, nothing to process
  if (updatedProductIds.length === 0) {
    return;
  }

  const { productIdsForUpdate, activeProductData } = updateProductsDataInCache(updatedProductIds);
  if (activeProductData) {
    deleteDefaultImageBasicInfo(activeProductData);
  }

  const sharedCacheKey = connector.PRODUCT_ROW_META.cacheKey;
  const oneTimeUniqueKey = `PRODUCT_META_UPDATER_${new Date().getTime()}`;
  const apiCall = connector.PRODUCT_ROW_META.apiFunction;

  CacheRequest.makeRequest(oneTimeUniqueKey, apiCall, {
    params: [productIdsForUpdate],
    options: {
      isBatched: true,
      sharedCacheKey: sharedCacheKey,
      batchCallback: writeProductsDataToCache,
      nativeStorageKey: connector.PRODUCT_ROW_META.nativeStorageKey
    }
  });
};

const updateDataInCacheAndNative = async (
  catalogueId,
  { deletedProductIds, updatedProductIds, productList }
) => {
  const newCatalogueRow = updateCatalogueErrorCount(catalogueId);
  updateProductListInCache({ catalogueId, productList });
  deleteProductsDataFromCache(deletedProductIds);
  updateProductsViewData({ updatedProductIds });

  await Promise.all([
    updateCatalogueInNative(catalogueId, newCatalogueRow),
    updateProductsListInNative({ catalogueId, productList })
  ]);
};

export const deleteErroredProductsCatalogue = async ({ catalogueId }) => {
  try {
    const deleteResult = await Api.deleteErroredCatalogueProducts(catalogueId);
    updateDataInCacheAndNative(catalogueId, deleteResult);
    return deleteResult;
  } catch (deleteError) {
    reportError(deleteError);
    throw deleteError;
  }
};
