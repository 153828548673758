import React, { Component } from 'react';
import './styles.css';

class Loader extends Component {
  render() {
    let width;
    const { height, style } = this.props;
    if (this.props.size === 'extraSmall') {
      width = 23;
    } else if (this.props.size === 'small') {
      width = 30;
    } else if (this.props.size === 'large') {
      width = 60;
    } else if (typeof this.props.width === 'number') {
      width = this.props.width;
    }

    return (
      <div className="loader" style={{ height, width, ...style }}>
        <svg className="circular" viewBox="25 25 50 50">
          <circle
            className="path"
            cx="50"
            cy="50"
            r="20"
            fill="none"
            strokeWidth="4"
            strokeMiterlimit="10"
          />
        </svg>
      </div>
    );
  }
}

export default Loader;
