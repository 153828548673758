import {
  attachProductListListener,
  removeProductListListener,
  getProductList,
  getCatalogueProductSearchParamsFromCache,
  attachCatalogueProductSearchParamsListener,
  getCatalogueProductSearchParams,
  removeCatalogueProductSearchParamsListener,
  deleteFilteredCacheData,
  getProductListDataFromCache
} from 'qs-data-manager/Products/CatalogueProductsList';
import {
  getProductWeightFromCache,
  attachProductWeightListener,
  getProductWeight,
  removeProductWeightListener
} from 'qs-data-manager/Products/Weights';
import { productListFiltersExist } from './ProductsListFilterHelper';

export const getCachedProductWeight = ({ changeDependancy: [productId] }) =>
  getProductWeightFromCache(productId);

export const fetchProductWeightHandler = ({ listener, changeDependancy: [productId] }) => {
  if (!productId) {
    return;
  }
  attachProductWeightListener({ listener, productId });
  getProductWeight(productId);
};

export const handleProductWeightCleanup = ({ listener, changeDependancy: [productId] }) => {
  removeProductWeightListener({ listener, productId });
};

export const getCachedCatalogueProductsList = ({
  changeDependancy: [
    catalogueId,
    selectedTags,
    searchTerm,
    priceFilters,
    variantSelection,
    customFieldsSelection
  ]
}) => {
  if (
    productListFiltersExist({
      selectedTags,
      searchTerm,
      priceFilters,
      variantSelection,
      customFieldsSelection
    })
  ) {
    return undefined;
  }

  return getProductListDataFromCache({ catalogueId });
};

export const fetchCatalogueProductsHandler = ({
  listener,
  changeDependancy: [
    catalogueId,
    selectedTags,
    searchTerm,
    priceFilters,
    variantSelection,
    customFieldsSelection
  ]
}) => {
  if (!catalogueId) {
    return;
  }

  const filters = productListFiltersExist({
    selectedTags,
    searchTerm,
    priceFilters,
    variantSelection,
    customFieldsSelection
  });

  if (!filters) {
    deleteFilteredCacheData({ catalogueId });
  }

  attachProductListListener({
    listener,
    catalogueId,
    filters
  });
  getProductList({
    catalogueId,
    selectedTags,
    searchTerm,
    priceFilters,
    variantSelection,
    customFieldsSelection,
    filters
  });
};

export const handleCatalogueProductsCleanup = ({
  listener,
  changeDependancy: [
    catalogueId,
    selectedTags,
    searchTerm,
    priceFilters,
    variantSelection,
    customFieldsSelection
  ]
}) => {
  removeProductListListener({
    listener,
    catalogueId,
    filters: productListFiltersExist({
      selectedTags,
      searchTerm,
      priceFilters,
      variantSelection,
      customFieldsSelection
    })
  });
};

export const getCachedCatalogueProductSearchParams = ({ changeDependancy: [catalogueId] }) =>
  getCatalogueProductSearchParamsFromCache(catalogueId);

export const fetchCatalogueProductSearchParamsHandler = ({
  listener,
  changeDependancy: [catalogueId]
}) => {
  if (!catalogueId) {
    return;
  }

  attachCatalogueProductSearchParamsListener({ listener, catalogueId });
  getCatalogueProductSearchParams({ catalogueId });
};

export const handleCatalogueProductSearchParamsCleanup = ({
  listener,
  changeDependancy: [catalogueId]
}) => {
  removeCatalogueProductSearchParamsListener({ listener, catalogueId });
};
