import React from 'react';
import NumberInput from '../../../../../../Common/NumberInput';
import { ReactComponent as CloseIcon } from 'qs-assets/Media/close.svg';
import './styles.scss';
import { getI18N } from 'qs-services/i18N';

export default ({
  rowTitle,
  otherChargesList = [],
  onOtherChargesChange,
  onChargeTypeTitleChange,
  onChargeTypeAmountChange,
  currencySymbol,
  onDeleteOtherCharge
}) => {
  const { t } = getI18N();
  const onAddChargeClick = () => {
    onOtherChargesChange();
  };
  return (
    <div className="otherChargesContainer">
      <div className={'title'}>{t(rowTitle)}</div>
      {otherChargesList.map(({ type, amount = '' }, index) => {
        amount = amount.toString();
        return (
          <div className="inputFieldContainer" key={index}>
            <div className="chargeType">
              <div id={'TitleContainer'}>
                <div className={'rowContainer'}>
                  <div className={'inputContainer'}>
                    <input
                      type="text"
                      value={type}
                      onChange={e => {
                        const { value } = e.target;
                        onChargeTypeTitleChange(value, index);
                      }}
                      className={`input`}
                      placeholder={t('type_charge_type')}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="inputContainer chargeAmount">
              <span className={'currencySymbol'}>{currencySymbol}</span>
              <NumberInput
                onChange={value => {
                  onChargeTypeAmountChange(value, index);
                }}
                value={amount}
                digitsAfterDecimal={3}
                inputClassName="input setInput"
                placeholder={t('type_charge_amount')}
              />
              <div
                onClick={() => onDeleteOtherCharge(index)}
                className={'deleteOtherChargesContainer'}
              >
                <CloseIcon className={'deleteOtherChargesIcon'} />
              </div>
            </div>
          </div>
        );
      })}

      <div className="addCharge" onClick={onAddChargeClick}>
        + {t('add_charge')}
      </div>
    </div>
  );
};
