import React, { useState, useEffect } from 'react';
import eventbus from 'eventing-bus';
import { SAVE_BUTTON_META } from 'qs-helpers/Products/constants';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

export default ({ setName }) => {
  const existingSetName = setName || '';
  const [nameForSet, setNameForSet] = useState(existingSetName);
  const { t } = getI18N();

  useEffect(() => {
    //On unmount clear the saved data to ensure that it is not sent in the request
    return () => {
      eventbus.publish(SAVE_BUTTON_META.eventbusKey, {
        id: SAVE_BUTTON_META.PRODUCT_SET_NAME_FIELD.id,
        hasChanged: false,
        eventType: SAVE_BUTTON_META.eventType.PRODUCT_META.id
      });
    };
  }, []);

  useEffect(() => {
    setNameForSet(existingSetName);
  }, [existingSetName]);

  const onNameChange = event => {
    const { value } = event.target;
    setNameForSet(value);
    eventbus.publish(SAVE_BUTTON_META.eventbusKey, {
      id: SAVE_BUTTON_META.PRODUCT_SET_NAME_FIELD.id,
      hasChanged: value !== existingSetName,
      data: value,
      eventType: SAVE_BUTTON_META.eventType.PRODUCT_META.id
    });
  };

  return (
    <div className={'rowContainer productSetNameContainer'}>
      <div className={'rowHeading'}>{t('set_name')}</div>
      <div className={'inputContainer'}>
        <input
          onChange={onNameChange}
          value={nameForSet}
          className="input setInput"
          placeholder={t('type_set_name')}
        />
      </div>
    </div>
  );
};
