import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import './ForwardToShowroom.css';

import arrowRight from 'qs-assets/Media/ic_arrow_right.svg';
import ConfirmForwardToShowroom from './ConfirmForwardToShowroom/ConfirmForwardToShowroom';
import request from 'qs-services/Request';
import Firebase from 'qs-config/FirebaseConfig';
import Loader from 'qs-components/Common/Loader';
import eventbus from 'eventing-bus';
import { GRT_PM_META } from 'qs-data-manager';
import AppConfig from 'qs-config/AppConfig';
import { supportPhoneNumber } from 'qs-helpers';
import { getI18N } from 'qs-services/i18N';

const fetchShowrooms = token => {
  return request.get(`${AppConfig.qsApiEndPoint}/v1/grt/showroom/list`, {
    Authorization: token
  });
};

class ForwardToShowroom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      selectedShowroomMap: {},
      showConfirmationModal: false,
      id: true,
      isDataLoaded: false,
      showRoomDetail: {}
    };
    this.allShowroomList = [];
  }

  componentDidMount() {
    eventbus.on(GRT_PM_META.EVENT_BUS_KEY, ({ showModal, catalogueLink, catalogueName }) => {
      const updates = {
        visible: showModal
      };

      if (showModal) {
        updates.showRoomDetail = {
          catalogueLink,
          catalogueName
        };
      }

      this.setState(updates);

      if (showModal) {
        Firebase.auth()
          .currentUser.getIdToken()
          .then(token => {
            if (token) {
              fetchShowrooms(token)
                .then(data => {
                  this.allShowroomList = data;
                  this.setState({
                    isDataLoaded: true,
                    selectedShowroomMap: {}
                  });
                })
                .catch(() => {
                  // Handle error
                });
            } else {
              // Token is not available
            }
          })
          .catch(() => {
            // Handle error
          });
      }
    });
  }

  componentWillUnmount() {
    this.setState({ visible: false });
  }

  handleSendNow = () => {
    this.setState({
      showConfirmationModal: true
    });
  };

  renderSelectedButton = () => {
    const { t } = getI18N();
    return (
      <div className="selectedEmailIds" onClick={() => this.handleSendNow()}>
        <div className="selectedCount">
          {Object.keys(this.state.selectedShowroomMap).length + ' selected'}
        </div>
        <div className="emptySpace" />
        <div className="forward">
          <div>
            {t('send_now')} <img src={arrowRight} alt="" />
          </div>
        </div>
      </div>
    );
  };

  handleSelectAll = isChecked => {
    const checked = isChecked;
    const tempData = {};
    this.allShowroomList.forEach((row, index) => {
      if (checked) {
        tempData[row.showroomId] = row.showroomEmailId;
      }
      this.allShowroomList[index].checked = checked;
    });
    if (checked) {
      this.setState({
        selectedShowroomMap: tempData
      });
    } else {
      this.setState({
        selectedShowroomMap: tempData
      });
    }
  };

  handleClick = (index, showroomId, showroomEmailId, isChecked) => {
    const checked = isChecked;
    const tempData = { ...this.state.selectedShowroomMap };
    this.allShowroomList[Number(index)].checked = checked;
    if (checked) {
      tempData[String(showroomId)] = showroomEmailId;
      this.setState({
        selectedShowroomMap: tempData
      });
    } else {
      delete tempData[String(showroomId)];
      this.setState({
        selectedShowroomMap: tempData
      });
    }
  };

  closeModal = () => {
    this.setState({
      visible: false,
      showConfirmationModal: false,
      selectedShowroomMap: {},
      id: true,
      isDataLoaded: false,
      showRoomDetail: {}
    });
  };

  closeConfirmForwardToShowroom = () => {
    this.setState({
      showConfirmationModal: false
    });
  };

  deselectAll = () => {
    this.allShowroomList.forEach((row, index) => {
      this.allShowroomList[index].checked = false;
    });
    this.setState({
      selectedShowroomMap: {}
    });
  };

  renderSelectAll = () => {
    const checked =
      Object.keys(this.state.selectedShowroomMap).length !== this.allShowroomList.length;
    const isEmailIdsSelected = Object.keys(this.state.selectedShowroomMap).length > 0;
    const { t } = getI18N();

    return (
      <div className="selectAllShowrooms">
        <input
          type="checkbox"
          className="selectAllCheckbox"
          checked={!checked}
          onChange={event => this.handleSelectAll(event.target.checked)}
        />
        <div className="selectAll" onClick={() => this.handleSelectAll(checked)}>
          {t('select_all')}
        </div>
        <div className="emptySpace" />
        <div className="clearSelection" onClick={this.deselectAll}>
          {isEmailIdsSelected ? t('clear_selection') : ''}
          <div className="line" style={{ borderBottom: '1px solid blue' }} />
        </div>
      </div>
    );
  };

  renderEmailListLoading = () => {
    return (
      <div className="loadingEmailList">
        <Loader />
      </div>
    );
  };

  renderEmailList = () => {
    return this.allShowroomList.map((row, index) => (
      <div
        key={index}
        className="rowContainer"
        onClick={() => this.handleClick(index, row.showroomId, row.showroomEmailId, !row.checked)}
      >
        <div className="rows">
          <div className="grtCheckbox">
            <input
              type="checkbox"
              checked={(row && row.checked) || false}
              onChange={event => this.handleClick(index, row.showroomId, event.target.checked)}
            />
          </div>
          <div className="showroomMeta">
            <div className="showroomName">{row.showroomName}</div>
            <div className="showroomEmailId">{row.showroomEmailId}</div>
            <div className="line" style={{ borderBottom: '1px solid #DBDBDB' }} />
          </div>
        </div>
      </div>
    ));
  };

  render() {
    const {
      showConfirmationModal,
      isDataLoaded,
      selectedShowroomMap,
      visible,
      showRoomDetail
    } = this.state;
    const { catalogueLink, catalogueName } = showRoomDetail;

    return (
      <Modal
        open={visible}
        center
        styles={{
          modal: {
            backgroundColor: 'white',
            borderRadius: 4,
            maxHeight: '90vh',
            height: '100%'
          }
        }}
        onClose={this.closeModal}
      >
        <div className="grtContainer">
          <div className="grtHeaderContainer">
            <div className="grtShowroomTitle">GRT Showrooms</div>
          </div>
          {isDataLoaded ? this.renderSelectAll() : ''}
          <div className="grtShowroomEmailList">
            {isDataLoaded ? this.renderEmailList() : this.renderEmailListLoading()}
          </div>
          {Object.keys(this.state.selectedShowroomMap).length > 0
            ? this.renderSelectedButton()
            : ''}
        </div>
        <ConfirmForwardToShowroom
          visible={showConfirmationModal}
          closeForwardModal={this.closeModal}
          closeModal={this.closeConfirmForwardToShowroom}
          catalogueName={catalogueName}
          catalogueLink={catalogueLink}
          selectedShowroomMap={selectedShowroomMap}
          supportPhoneNumber={`+${supportPhoneNumber()}`}
        />
      </Modal>
    );
  }
}

export default ForwardToShowroom;
// NOTE: Entire GRT PM has been taken from prev version of prod
