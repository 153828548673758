import React from 'react';

import { ReactComponent as ChangeActionIcon } from 'qs-assets/Media/change_action.svg';
import { ACTION_BUTTONS } from 'qs-helpers/Products/constants';
import { DialogLightTheme } from 'qs-components/Common/LightThemeDialog';
import './styles.scss';
import Loader from 'qs-components/Common/Loader';
import CatalogueLib from 'qs-data-manager/Catalogues';
import { toggleGlobalLoader } from 'qs-helpers/index';
import { getI18N } from 'qs-services/i18N';
import toastr from 'toastr';

const ActionButtonDialog = ({
  open,
  loading,
  errorFetchingData,
  selectedActionButton,
  catalogueId,
  onClose
}) => {
  const { t } = getI18N();
  const updateActionButton = async actionButtonValue => {
    const loaderKey = Date.now();
    toggleGlobalLoader(loaderKey, true);
    try {
      const changes = {};
      changes['action-button-id'] = actionButtonValue;
      await CatalogueLib.changeCatalogueSettings({ catalogueId, changes });
      toastr.success(t('action_button_updated_successfully'));
    } catch (error) {
      toastr.error(t('something_went_wrong_while_changing_action_button'));
    } finally {
      toggleGlobalLoader(loaderKey, false);
      onClose();
    }
  };

  const renderActionButtons = () => {
    if (loading) {
      return <Loader size={'small'} />;
    }

    if (errorFetchingData) {
      return t('something_went_wrong_please_try_again');
    }

    return ACTION_BUTTONS.map(actionButton => {
      return (
        <div
          key={actionButton.key}
          onClick={() => updateActionButton(actionButton.key)}
          className={`actionButton ${
            selectedActionButton === actionButton.key ? 'selectedButton' : ''
          }`}
        >
          {t(actionButton.value)}
        </div>
      );
    });
  };

  return (
    <DialogLightTheme
      open={open}
      onClose={onClose}
      title={''}
      maxWidth={'xs'}
      onClick={event => {
        event.stopPropagation();
        event.preventDefault();
      }}
    >
      <div className={'actionButtonDialogContainer'}>
        <div className={'titleContainer'}>
          <ChangeActionIcon className={'changeActionIcon'} />
          {t('change_action_button')}
        </div>
        <div className={'actionButtonInfo'}>
          {t(
            'choose_the_primary_action_that_you_would_like_your_visitors_to_perform_on_your_catalogue'
          )}
        </div>
        <div className={'actionButtons'}>{renderActionButtons()}</div>
      </div>
    </DialogLightTheme>
  );
};

export default ActionButtonDialog;
