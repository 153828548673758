import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { sortableHandle } from 'react-sortable-hoc';

import './styles.scss';
import { ReactComponent as EyeIcon } from 'qs-assets/Media/eye.svg';
import { ReactComponent as EyeOffIcon } from 'qs-assets/Media/eye-off.svg';
import { ReactComponent as ReorderIcon } from 'qs-assets/Media/drag-reorder.svg';

const DragHandle = sortableHandle(() => (
  <div className="reorderIconContainer">
    <ReorderIcon fill="#c3dac8" className="reorderIcon" />
  </div>
));

class CatalogueTagRow extends PureComponent {
  static propTypes = {
    tag: PropTypes.string,
    isVisible: PropTypes.bool,
    onVisiblePress: PropTypes.func
  };

  static defaultProps = {
    tag: '',
    isVisible: true,
    onVisiblePress: () => {}
  };

  render() {
    const { key, tag, isVisible, onVisiblePress } = this.props;

    if (tag === 'Out of stock' || tag === 'In stock') {
      return <div></div>;
    }

    return (
      <div key={key} className="catalogueTagRow">
        <button className="visibleButton" onClick={onVisiblePress}>
          {isVisible ? (
            <EyeIcon fill="#000" className="visibleButtonIcon" />
          ) : (
            <EyeOffIcon fill="#000" className="visibleButtonIcon" />
          )}
        </button>

        <div className="tagContainer">
          <div className="tag">
            <span className={`tagText ${!isVisible && 'tagStrikethrough'}`}>{tag}</span>
          </div>
        </div>

        <DragHandle />
      </div>
    );
  }
}

export default CatalogueTagRow;
