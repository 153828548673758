import worker from 'workerize-loader!../slave.worker.js'; // eslint-disable-line import/no-webpack-loader-syntax
import { attachConnectivityChangeListener } from 'qs-services/Firebase/Connectivity';
import webWorkerConfig from 'qs-web-worker/config';
import {
  imageUploadComplete,
  compressImageOnMainThread,
  loadImageAndReturnExifOrientation,
  checkBrowserExifOrientation
} from 'qs-image-upload/WorkerProcessor/ProcessWorkerResponse';
import { onVideoUploadDone } from '../../Helpers/ProcessVideo/ProcessUploadedVideo';
import { getVideoUploadProgress } from 'qs-helpers/ProcessVideo/VideosProcessingHelper';

const instance = worker();

//Listen to changes in connectivity and notify the worker
attachConnectivityChangeListener(offlineStatus => {
  sendMessageToWorker({
    type: webWorkerConfig.INTERNET_CONNECTIVITY_MESSAGE.type,
    data: {
      offlineStatus
    }
  });
});

export const sendMessageToWorker = payloadToSend => {
  instance.receiveMessageFromMaster(payloadToSend);
};

instance.addEventListener(
  'message',
  async ({ data: { type, payload, metaData = {} } = {} } = {}) => {
    switch (type) {
      case webWorkerConfig.IMAGE_UPLOAD_DONE_REPLY.type:
        imageUploadComplete(payload);
        break;
      case webWorkerConfig.VIDEO_UPLOAD_DONE_REPLY.type:
        onVideoUploadDone(payload);
        break;
      case webWorkerConfig.VIDEO_UPLOAD_PROGRESS.type:
        getVideoUploadProgress(payload);
        break;
      case webWorkerConfig.COMPRESS_IMAGE_MAIN.type: {
        const payloadToSend = await compressImageOnMainThread(payload, metaData);
        sendMessageToWorker(payloadToSend);
        break;
      }
      case webWorkerConfig.LOAD_IMAGE_DATAURI.type: {
        const payloadToSend = await loadImageAndReturnExifOrientation(payload);
        sendMessageToWorker(payloadToSend);
        break;
      }
      case webWorkerConfig.EXIF_ORIENTATION_MAIN.type: {
        const payloadToSend = await checkBrowserExifOrientation();
        sendMessageToWorker(payloadToSend);
        break;
      }
      default:
        break;
    }
  }
);
