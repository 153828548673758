import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { SAVE_BUTTON_META } from 'qs-helpers/Products/constants';
import './styles.scss';
import MetalAndKarat from './MetalAndKarat';
import GrossAndNetWeight from './GrossAndNetWeight';
import MakingCharges from './MakingCharges';
import WastageCharges from './WastageCharges';
import OtherCharges from './OtherCharges';
import ProductPriceAndCalculation from './ProductPriceAndCalculation';
import Modal from 'react-responsive-modal';
import eventbus from 'eventing-bus';
import MetalRate from './MetalRate';
import { roundNumberToGivenDecimals } from 'qs-helpers';
import {
  anyJewelleryRelatedFieldsIsSet,
  createANewOtherChargesList,
  showStoneValueDetailsName,
  getAverageSize,
  getFinalStonePrice,
  getFormattedStoneData,
  getSelectedMetalKaratPrice as getSelectedMetalKaratPriceCommon,
  getStoneCost,
  LABELS,
  JEWELLERY,
  METAL_TYPES,
  METAL_UNITS,
  NET_WEIGHT,
  PERCENT,
  PERGRAM,
  SELECT_VALUE,
  DIAMOND,
  STONE,
  STONE_KEYS,
  validateInputValues,
  validateJewelleryRelatedFieldsInBasicInfo
} from './ProductTypeDetails';
import StoneCharges from './StoneCharges';
import { Button } from '@material-ui/core';
import {
  deleteProductStoneDetails,
  setProductStoneDetails,
  updateProductStoneDetails
} from 'qs-data-manager/ProductDetails';
import isDeepEqual from 'fast-deep-equal/es6';
import Loader from 'qs-components/Common/Loader';
import toastr from 'toastr';
import Diamond from './Diamond';
import Stone from './Stones';
import { ReactComponent as CloseIcon } from 'qs-assets/Media/close.svg';
import {
  getProductTypeDetailsForProductOrVariantFromCache,
  getProductTypeRateChargesForProductOrVariantFromCache,
  saveVariantChanges,
  updateProductOrVariantStoneDetailsInCache
} from 'qs-data-manager/Variants/VariantsDetails';
import { JewelleryRateCharge } from './JewelleryRateCharge';
import { VariantDataForUpdate } from '../../../ProductVariantView/context';
import {
  UPDATE_JEWELLERY_RATES,
  UPDATE_ORIGINAL_TO_CURRENT
} from '../../../ProductVariantView/reducer';
import { getI18N } from 'qs-services/i18N';

const modalStyles = {
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'column',
  height: '450px',
  width: '350px',
  borderRadius: '8px',
  padding: 0
};

const stonesModalStyles = {
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'column',
  width: '450px',
  borderRadius: '8px',
  padding: '15px 25px'
};

const initialStoneDetailsState = {
  [STONE_KEYS.TYPE]: '',
  [STONE_KEYS.POSITION]: '',
  [STONE_KEYS.NAME]: '',
  [STONE_KEYS.SHAPE]: '',
  [STONE_KEYS.WEIGHT]: '',
  [STONE_KEYS.COLOR]: '',
  [STONE_KEYS.RATE]: '',
  [STONE_KEYS.PIECES]: '',
  [STONE_KEYS.COST]: '',
  [STONE_KEYS.CLARITY]: '',
  [STONE_KEYS.AVERAGE_SIZE]: '',
  [STONE_KEYS.DISCOUNT_TYPE]: PERCENT.id,
  [STONE_KEYS.DISCOUNT_AMOUNT]: ''
};

// Temp solution
const formatNumber = number => {
  return number;
};

const getCachedJewelleryInfo = ({ activeProductId, parentProductId, isBulkEditing }) => {
  const productTypeDetails = getProductTypeDetailsForProductOrVariantFromCache({
    productId: activeProductId,
    parentProductId,
    isBulkEditing
  });

  const productTypeRateCharges =
    getProductTypeRateChargesForProductOrVariantFromCache({
      productId: activeProductId,
      parentProductId,
      isBulkEditing
    }) || [];

  return {
    productTypeDetails,
    productTypeRateCharges
  };
};

export default ({
  activeProductId,
  parentProductId, // optional
  // activeProductIds,
  isBulkEditing,
  productMetaFromRow,
  goldRateAndProductType
}) => {
  const [
    { productTypeRateCharges: productStoneValues = [], productTypeDetails = {} },
    setJewelleryInfo
  ] = useState(
    getCachedJewelleryInfo({
      activeProductId,
      parentProductId,
      isBulkEditing
    })
  );
  const { t } = getI18N();
  const { variantData, setVariantData } = useContext(VariantDataForUpdate);
  const isVariant = !!parentProductId;

  const priceCalculationModalStyles = useRef(modalStyles);
  const stonesModalStyling = useRef(stonesModalStyles);
  const productStoneRef = useRef(productStoneValues);

  const {
    currencySymbol,
    price: existingPrice,
    productTypeRateChargesFromProduct,
    productTypeDetailsFromProduct
  } = productMetaFromRow;

  const {
    entityQuality: originalEntityQuality,
    grossWeight: originalGrossWeight,
    netWeight: originalNetWeight,
    makingChargeType: originalMakingChargeType,
    makingCharge: originalMakingCharge,
    entityType: originalEntityType,
    wastagePercent: originalWastagePercent,
    wastageOn: originalWastageOn
  } = productTypeDetails || {};

  let { otherCharges: originalOtherChargesList } = productTypeDetails || {};

  const { productType = '', entityRates: entityRatesArray = [] } = goldRateAndProductType;

  const [selectedEntityType, setSelectedEntityType] = useState(
    originalEntityType || METAL_TYPES.GOLD
  );

  const isAllProductTypeDetailsFieldsEmpty = Object.keys(productTypeDetails || {}).every(key => {
    if (key !== 'otherCharges' && !productTypeDetails[key]) {
      return true;
    } else if (key === 'otherCharges' && (productTypeDetails.otherCharges || []).length === 0) {
      return true;
    }
    return false;
  });

  const entityRates =
    entityRatesArray.find(
      ({ entityLabel, entityType }) => entityLabel === 'METAL' && entityType === selectedEntityType
    ) || {};

  const originalOtherChargesListLength = (originalOtherChargesList || []).length;

  const [productStone, setProductStone] = useState(
    (productStoneValues || []).length > 0 ? productStoneValues : []
  );

  const [necessaryJewelleryFieldValuesExist, setNecessaryJewelleryFieldValuesExist] = useState(
    false
  );

  const [metalKaratQuality, setMetalKaratQuality] = useState(
    productTypeDetails.entityQuality
      ? formatNumber(productTypeDetails.entityQuality)
      : SELECT_VALUE.id
  );

  const [otherChargesList, setOtherChargesList] = useState(
    createANewOtherChargesList(productTypeDetails.otherCharges || [])
  );

  const [makingCharges, setMakingCharges] = useState({
    makingChargeType: productTypeDetails.makingChargeType || PERCENT.id,
    makingCharge: productTypeDetails.makingCharge ? productTypeDetails.makingCharge.toString() : ''
  });

  const [wastageCharges, setWastageCharges] = useState({
    wastageOn: productTypeDetails.wastageOn || NET_WEIGHT.id,
    wastagePercent: productTypeDetails.wastagePercent
      ? productTypeDetails.wastagePercent.toString()
      : ''
  });

  const [grossWeight, setGrossWeight] = useState(
    productTypeDetails.grossWeight ? productTypeDetails.grossWeight.toString() : ''
  );

  const [netWeight, setNetWeight] = useState(
    productTypeDetails.netWeight ? productTypeDetails.netWeight.toString() : ''
  );

  const [showModal, setShowModal] = useState(false);
  const [jewelleryFieldValue, setJewelleryFieldValue] = useState(false);
  const [showStonesModal, setShowStonesModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});
  const [loading, setLoading] = useState(false);
  const [showConfirmationBox, setShowConfirmationBox] = useState(false);
  const [addStoneDetails, setAddStoneDetails] = useState(initialStoneDetailsState);

  if (!isDeepEqual(productStone, productStoneRef)) {
    productStoneRef.current = productStoneValues;
  }

  useEffect(() => {
    setProductStone(productStoneValues);
  }, [productStoneValues]);

  const getSelectedMetalKaratPrice = useCallback(() => {
    return getSelectedMetalKaratPriceCommon({
      productId: activeProductId,
      selectedEntityType,
      metalKaratQuality
    });
  }, [activeProductId, selectedEntityType, metalKaratQuality]);

  const checkIfNecessaryJewelleryFieldsAreSet = useCallback(() => {
    if (
      (grossWeight && netWeight && metalKaratQuality !== SELECT_VALUE.id) ||
      productStone.length
    ) {
      setNecessaryJewelleryFieldValuesExist(true);
      return;
    }
    setNecessaryJewelleryFieldValuesExist(false);
  }, [
    grossWeight,
    netWeight,
    metalKaratQuality,
    productStone.length,
    setNecessaryJewelleryFieldValuesExist
  ]);

  const checkOtherChargesValueHasChanged = useCallback(() => {
    if ((otherChargesList || []).length !== originalOtherChargesListLength) {
      return true;
    }
    return (otherChargesList || []).some((otherCharges, index) => {
      return (
        otherCharges.amount !== originalOtherChargesList[index].amount ||
        otherCharges.type !== originalOtherChargesList[index].type
      );
    });
  }, [otherChargesList, originalOtherChargesListLength, originalOtherChargesList]);

  const checkIfAnyJewelleryRelatedFieldsAreSet = useCallback(() => {
    return anyJewelleryRelatedFieldsIsSet({
      metalKaratQuality,
      grossWeight,
      netWeight,
      makingCharges: makingCharges.makingCharge,
      wastageCharges: wastageCharges.wastagePercent,
      otherChargesList
    });
  }, [metalKaratQuality, grossWeight, netWeight, makingCharges, wastageCharges, otherChargesList]);

  useEffect(() => {
    checkIfNecessaryJewelleryFieldsAreSet();
  }, [grossWeight, netWeight, metalKaratQuality, checkIfNecessaryJewelleryFieldsAreSet]);

  useEffect(() => {
    setSelectedEntityType(originalEntityType || METAL_TYPES.GOLD);
    setOtherChargesList(createANewOtherChargesList(originalOtherChargesList || []));
    setMakingCharges({
      makingChargeType: originalMakingChargeType || PERCENT.id,
      makingCharge: originalMakingCharge ? originalMakingCharge.toString() : ''
    });
    setWastageCharges({
      wastageOn: originalWastageOn || NET_WEIGHT.id,
      wastagePercent: originalWastagePercent ? originalWastagePercent.toString() : ''
    });
    setGrossWeight(originalGrossWeight ? originalGrossWeight.toString() : '');
    setNetWeight(originalNetWeight ? originalNetWeight.toString() : '');
    setMetalKaratQuality(originalEntityQuality ? Number(originalEntityQuality) : SELECT_VALUE.id);
    setJewelleryFieldValue(false);
  }, [
    originalEntityType,
    originalEntityQuality,
    originalGrossWeight,
    originalMakingCharge,
    originalMakingChargeType,
    originalWastagePercent,
    originalWastageOn,
    originalNetWeight,
    originalOtherChargesList
  ]);

  useEffect(() => {
    setJewelleryInfo(
      getCachedJewelleryInfo({
        activeProductId,
        parentProductId,
        isBulkEditing
      })
    );
  }, [setJewelleryInfo, activeProductId, parentProductId, isBulkEditing]);

  //for showing alert
  useEffect(() => {
    if (!(checkIfAnyJewelleryRelatedFieldsAreSet() || makingCharges.makingChargeType)) {
      eventbus.publish(SAVE_BUTTON_META.eventbusKey, {
        id: 'jewellery-related-fields-validator',
        eventType: SAVE_BUTTON_META.eventType.UNREGISTER_VALIDATOR.id
      });
      return;
    }

    const updatedEntityType = selectedEntityType || null;
    const updatedGrossWeight = grossWeight ? Number(grossWeight) : null;
    const updatedNetWeight = netWeight ? Number(netWeight) : null;
    const updatedMakingCharge = makingCharges.makingCharge
      ? Number(makingCharges.makingCharge)
      : null;
    const updatedMakingChargeType = updatedMakingCharge ? makingCharges.makingChargeType : '';
    const updatedWastagePercent = wastageCharges.wastagePercent
      ? Number(wastageCharges.wastagePercent)
      : null;
    const updatedWastageOn = updatedWastagePercent ? wastageCharges.wastageOn : '';

    const updatedOtherChargesList = [...otherChargesList].map(otherCharges => {
      const updatedOtherCharge = { ...otherCharges };
      updatedOtherCharge.amount = updatedOtherCharge.amount
        ? Number(updatedOtherCharge.amount)
        : null;
      return updatedOtherCharge;
    });

    const updatedMetalKaratQuality =
      metalKaratQuality !== SELECT_VALUE.id ? Number(metalKaratQuality) : null;

    const selectedKaratPrice = getSelectedMetalKaratPrice();

    const validator = validateJewelleryRelatedFieldsInBasicInfo({
      updatedGrossWeight,
      updatedNetWeight,
      updatedMakingCharge,
      updatedWastagePercent,
      updatedOtherChargesList,
      updatedMetalKaratQuality,
      updatedEntityType,
      getTheSelectedKaratPrice: selectedKaratPrice,
      checkIfAnyJewelleryRelatedFieldsAreSet
    });

    if (isVariant) {
      const { errorMessage, valid } = validator;
      if (!valid && errorMessage) {
        alert(t(errorMessage));
        return;
      }
      setVariantData({
        type: UPDATE_JEWELLERY_RATES,
        productTypeDetails: {
          grossWeight: updatedGrossWeight,
          entityLabel: productTypeDetails.entityLabel,
          entityQuality: updatedMetalKaratQuality,
          entityType: updatedEntityType,
          makingCharge: updatedMakingCharge,
          makingChargeType: updatedMakingChargeType,
          wastagePercent: updatedWastagePercent,
          wastageOn: updatedWastageOn,
          netWeight: updatedNetWeight,
          otherCharges: updatedOtherChargesList
        },
        productTypeRateCharges: productStoneValues
      });
      return;
    }

    eventbus.publish(SAVE_BUTTON_META.eventbusKey, {
      id: 'jewellery-related-fields-validator',
      validator,
      eventType: SAVE_BUTTON_META.eventType.REGISTER_VALIDATOR.id
    });

    return () =>
      eventbus.publish(SAVE_BUTTON_META.eventbusKey, {
        id: 'jewellery-related-fields-validator',
        eventType: SAVE_BUTTON_META.eventType.UNREGISTER_VALIDATOR.id
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isVariant,
    setVariantData,
    grossWeight,
    netWeight,
    makingCharges,
    wastageCharges,
    otherChargesList,
    jewelleryFieldValue,
    metalKaratQuality,
    getSelectedMetalKaratPrice,
    productTypeDetails,
    productStoneValues,
    checkIfAnyJewelleryRelatedFieldsAreSet,
    selectedEntityType
  ]);

  const onClose = () => {
    setShowModal(false);
  };
  const onCloseStonesModal = () => {
    setShowStonesModal('');
    setAddStoneDetails(initialStoneDetailsState);
    setErrorMessage({});
  };

  const onSelectChangeMetalType = event => {
    const { value } = event.target;

    setSelectedEntityType(value);
    eventbus.publish(SAVE_BUTTON_META.eventbusKey, {
      id: SAVE_BUTTON_META.PRODUCT_ENTITY_TYPE.id,
      hasChanged: value !== selectedEntityType,
      data: value || null,
      eventType: SAVE_BUTTON_META.eventType.PRODUCT_META.id
    });

    if (selectedEntityType !== value) {
      onSelectChangeMetalKaratQuality({ target: { value: SELECT_VALUE.id } });
    }
  };

  const onSelectChangeMetalKaratQuality = event => {
    const { value } = event.target;
    if (value === SELECT_VALUE.id) {
      setMetalKaratQuality(value);
      eventbus.publish(SAVE_BUTTON_META.eventbusKey, {
        id: SAVE_BUTTON_META.PRODUCT_ENTITY_QUALITY.id,
        hasChanged: !isAllProductTypeDetailsFieldsEmpty,
        data: null,
        eventType: SAVE_BUTTON_META.eventType.PRODUCT_META.id
      });
      return;
    }
    setMetalKaratQuality(formatNumber(value));
    eventbus.publish(SAVE_BUTTON_META.eventbusKey, {
      id: SAVE_BUTTON_META.PRODUCT_ENTITY_QUALITY.id,
      hasChanged: formatNumber(value) !== metalKaratQuality,
      data: value ? formatNumber(value) : null,
      eventType: SAVE_BUTTON_META.eventType.PRODUCT_META.id
    });
  };

  const onGrossWeightChange = value => {
    setGrossWeight(value);
    eventbus.publish(SAVE_BUTTON_META.eventbusKey, {
      id: SAVE_BUTTON_META.PRODUCT_GROSS_WEIGHT.id,
      hasChanged: value !== grossWeight,
      data: value !== '' ? formatNumber(value) : null,
      eventType: SAVE_BUTTON_META.eventType.PRODUCT_META.id
    });
  };

  const onNetWeightChange = value => {
    setNetWeight(value);
    eventbus.publish(SAVE_BUTTON_META.eventbusKey, {
      id: SAVE_BUTTON_META.PRODUCT_NET_WEIGHT.id,
      hasChanged: value !== netWeight,
      data: value !== '' ? formatNumber(value) : null,
      eventType: SAVE_BUTTON_META.eventType.PRODUCT_META.id
    });
  };

  const checkIfRatesAreNotSet = () => {
    if (metalKaratQuality === SELECT_VALUE.id) {
      return false;
    }
    const { rates = [] } = entityRates || {};
    let selectedKaratMetalRateDoesNotExists = false;
    for (let singleRate of rates) {
      if (
        formatNumber(singleRate.quality) === formatNumber(metalKaratQuality) &&
        (singleRate.rate === null || typeof singleRate.rate !== 'number')
      ) {
        selectedKaratMetalRateDoesNotExists = true;
      }
    }
    return selectedKaratMetalRateDoesNotExists;
  };

  const onMakingChargeSelectChange = event => {
    const { value } = event.target;
    if (value !== productTypeDetails.makingChargeType) {
      setMakingCharges(prevState => ({
        ...prevState,
        makingChargeType: value,
        makingCharge: ''
      }));
    } else {
      setMakingCharges(prevState => ({
        ...prevState,
        makingChargeType: value,
        makingCharge: originalMakingCharge
      }));
    }
    if (!originalMakingChargeType && value === PERCENT.id) {
      eventbus.publish(SAVE_BUTTON_META.eventbusKey, {
        id: SAVE_BUTTON_META.PRODUCT_MAKING_CHARGE_TYPE.id,
        hasChanged: false,
        data: null,
        eventType: SAVE_BUTTON_META.eventType.PRODUCT_META.id
      });
      eventbus.publish(SAVE_BUTTON_META.eventbusKey, {
        id: SAVE_BUTTON_META.PRODUCT_MAKING_CHARGE.id,
        hasChanged: false,
        data: null,
        eventType: SAVE_BUTTON_META.eventType.PRODUCT_META.id
      });
      return;
    }
    eventbus.publish(SAVE_BUTTON_META.eventbusKey, {
      id: SAVE_BUTTON_META.PRODUCT_MAKING_CHARGE_TYPE.id,
      hasChanged: value !== makingCharges.makingChargeType,
      data: { makingCharge: formatNumber(makingCharges.makingCharge), makingChargeType: value },
      eventType: SAVE_BUTTON_META.eventType.PRODUCT_META.id
    });
  };
  const onMakingChargeInputChange = value => {
    setMakingCharges(prevState => ({
      ...prevState,
      makingCharge: value
    }));
    eventbus.publish(SAVE_BUTTON_META.eventbusKey, {
      id: SAVE_BUTTON_META.PRODUCT_MAKING_CHARGE.id,
      hasChanged: value !== makingCharges.makingCharge,
      data: {
        makingCharge: value !== '' ? formatNumber(value) : '',
        makingChargeType: makingCharges.makingChargeType
      },
      eventType: SAVE_BUTTON_META.eventType.PRODUCT_META.id
    });
  };

  const onWastageChargeSelectChange = event => {
    const { value: newWastageOn } = event.target;

    setWastageCharges(prevState => ({
      ...prevState,
      wastageOn: newWastageOn
    }));

    if (!originalWastageOn && newWastageOn === NET_WEIGHT.id) {
      eventbus.publish(SAVE_BUTTON_META.eventbusKey, {
        id: SAVE_BUTTON_META.PRODUCT_WASTAGE_CHARGE_TYPE.id,
        hasChanged: false,
        data: null,
        eventType: SAVE_BUTTON_META.eventType.PRODUCT_META.id
      });
      return;
    }

    eventbus.publish(SAVE_BUTTON_META.eventbusKey, {
      id: SAVE_BUTTON_META.PRODUCT_WASTAGE_CHARGE_TYPE.id,
      hasChanged: newWastageOn !== wastageCharges.wastageOn,
      data: {
        wastageOn: newWastageOn
      },
      eventType: SAVE_BUTTON_META.eventType.PRODUCT_META.id
    });
  };

  const onWastageChargeInputChange = value => {
    setWastageCharges(prevState => ({
      ...prevState,
      wastagePercent: value
    }));
    eventbus.publish(SAVE_BUTTON_META.eventbusKey, {
      id: SAVE_BUTTON_META.PRODUCT_WASTAGE_CHARGE.id,
      hasChanged: value !== wastageCharges.wastagePercent,
      data: {
        wastagePercent: value !== '' ? formatNumber(value) : ''
      },
      eventType: SAVE_BUTTON_META.eventType.PRODUCT_META.id
    });
  };

  const onOtherChargesChange = () => {
    const copiedList = [...otherChargesList];
    copiedList.push({ type: '', amount: '' });
    eventbus.publish(SAVE_BUTTON_META.eventbusKey, {
      id: SAVE_BUTTON_META.PRODUCT_OTHER_CHARGES.id,
      hasChanged: copiedList.length !== originalOtherChargesListLength,
      data: copiedList,
      eventType: SAVE_BUTTON_META.eventType.PRODUCT_META.id
    });
    setOtherChargesList(copiedList);
  };

  const onDeleteOtherCharge = index => {
    const copiedList = [...otherChargesList];
    copiedList.splice(index, 1);
    eventbus.publish(SAVE_BUTTON_META.eventbusKey, {
      id: SAVE_BUTTON_META.PRODUCT_OTHER_CHARGES.id,
      hasChanged: copiedList.length !== originalOtherChargesListLength,
      data: copiedList,
      eventType: SAVE_BUTTON_META.eventType.PRODUCT_META.id
    });
    setOtherChargesList(copiedList);
  };

  const onChargeTypeTitleChange = (value, index) => {
    const copiedList = [...otherChargesList];
    let hasChanged = false;
    const updatedOtherChargesList = copiedList.map((otherCharges, idx) => {
      if (index === idx) {
        hasChanged =
          copiedList.length !== originalOtherChargesListLength || value !== otherCharges.type;
        otherCharges.type = value;
      }
      return otherCharges;
    });
    eventbus.publish(SAVE_BUTTON_META.eventbusKey, {
      id: SAVE_BUTTON_META.PRODUCT_OTHER_CHARGES.id,
      hasChanged: hasChanged,
      data: updatedOtherChargesList,
      eventType: SAVE_BUTTON_META.eventType.PRODUCT_META.id
    });
    setOtherChargesList(updatedOtherChargesList);
  };

  const onChargeTypeAmountChange = (value, index) => {
    const copiedList = [...otherChargesList];
    let hasChanged = false;
    const updatedOtherChargesList = copiedList.map((otherCharges, idx) => {
      if (index === idx) {
        hasChanged =
          copiedList.length !== originalOtherChargesListLength ||
          formatNumber(value) !== formatNumber(otherCharges.amount);
        otherCharges.amount = value;
      }
      return otherCharges;
    });
    eventbus.publish(SAVE_BUTTON_META.eventbusKey, {
      id: SAVE_BUTTON_META.PRODUCT_OTHER_CHARGES.id,
      hasChanged: hasChanged,
      data: updatedOtherChargesList,
      eventType: SAVE_BUTTON_META.eventType.PRODUCT_META.id
    });
    setOtherChargesList(updatedOtherChargesList);
  };

  const getTotalAmountFromOtherChargesList = useCallback(() => {
    if (Array.isArray(otherChargesList) && otherChargesList.length > 0) {
      const totalAmount = (otherChargesList || []).reduce((acc, otherCharges) => {
        if (otherCharges.type.trim().length) {
          acc += otherCharges.amount ? Number(otherCharges.amount) : 0;
        }
        return acc;
      }, 0);
      return totalAmount;
    }
    return 0;
  }, [otherChargesList]);

  const calculateMakingCharges = useCallback(() => {
    if (makingCharges.makingChargeType === PERCENT.id && makingCharges.makingCharge) {
      return roundNumberToGivenDecimals(
        (Number(makingCharges.makingCharge) / 100) *
          Number(netWeight) *
          getSelectedMetalKaratPrice()
      );
    } else if (makingCharges.makingChargeType === PERGRAM.id && makingCharges.makingCharge) {
      return roundNumberToGivenDecimals(Number(netWeight) * Number(makingCharges.makingCharge));
    }
    return 0;
  }, [
    getSelectedMetalKaratPrice,
    makingCharges.makingCharge,
    makingCharges.makingChargeType,
    netWeight
  ]);

  const calculateWastageCharges = useCallback(() => {
    if (wastageCharges.wastagePercent) {
      const isWastageOnNetWeight = (wastageCharges.wastageOn || NET_WEIGHT.id) === NET_WEIGHT.id;
      return roundNumberToGivenDecimals(
        (Number(wastageCharges.wastagePercent) / 100) *
          Number(isWastageOnNetWeight ? netWeight : grossWeight) *
          getSelectedMetalKaratPrice()
      );
    }
    return 0;
  }, [
    wastageCharges.wastagePercent,
    wastageCharges.wastageOn,
    netWeight,
    grossWeight,
    getSelectedMetalKaratPrice
  ]);

  const getAllStoneCosts = useCallback(() => {
    if (!productStone.length) {
      return 0;
    }
    const cost = productStone.reduce((acc, { details = {} }) => {
      acc += details.cost;
      return acc;
    }, 0);
    if (cost) {
      return cost;
    }
    return 0;
  }, [productStone]);

  const getFinalPrice = useCallback(() => {
    if (
      (productStone || []).length ||
      (grossWeight &&
        netWeight &&
        metalKaratQuality !== SELECT_VALUE.id &&
        getSelectedMetalKaratPrice())
    ) {
      const price =
        calculateMakingCharges() +
        calculateWastageCharges() +
        getSelectedMetalKaratPrice() * Number(netWeight) +
        getTotalAmountFromOtherChargesList() +
        getAllStoneCosts();
      return roundNumberToGivenDecimals(price, 2) || 0;
    }
    return 0;
  }, [
    productStone,
    grossWeight,
    netWeight,
    metalKaratQuality,
    getSelectedMetalKaratPrice,
    calculateMakingCharges,
    calculateWastageCharges,
    getTotalAmountFromOtherChargesList,
    getAllStoneCosts
  ]);

  useEffect(() => {
    const productPrice = getFinalPrice();

    if (typeof productPrice === 'number' && productPrice) {
      eventbus.publish(SAVE_BUTTON_META.eventbusKey, {
        id: SAVE_BUTTON_META.PRODUCT_PRICE.id,
        hasChanged:
          roundNumberToGivenDecimals(productPrice, 2) !==
          roundNumberToGivenDecimals(existingPrice, 2),
        data: productPrice,
        eventType: SAVE_BUTTON_META.eventType.PRODUCT_META.id
      });
    }

    return () => {
      eventbus.publish(SAVE_BUTTON_META.eventbusKey, {
        id: SAVE_BUTTON_META.PRODUCT_PRICE.id,
        hasChanged: false,
        data: null,
        eventType: SAVE_BUTTON_META.eventType.PRODUCT_META.id
      });
    };
  }, [
    grossWeight,
    netWeight,
    metalKaratQuality,
    makingCharges.makingCharge,
    checkOtherChargesValueHasChanged,
    activeProductId,
    getFinalPrice,
    existingPrice
  ]);

  const goldCost = () => {
    return roundNumberToGivenDecimals(getSelectedMetalKaratPrice() * Number(netWeight));
  };

  if (productType !== JEWELLERY.id || isBulkEditing) {
    return null;
  }

  const renderCost = ({ title }) => {
    if (!title) {
      return null;
    }
    return (
      <div className="avgSizeContainer">
        <div className="title">
          {title}
          <span className="asterisk"> *</span>
        </div>
        <div className="avgSize">
          {currencySymbol} {getStoneCost(addStoneDetails)}
        </div>
      </div>
    );
  };

  const showAverageSize = ({ title }) => {
    if (!title) {
      return null;
    }

    const averageSize = getAverageSize(addStoneDetails);

    return (
      <div className="avgSizeContainer">
        <div className="title">
          {title}
          <span className="asterisk"> *</span>
        </div>
        <div className="avgSize">{averageSize ? `${averageSize} ct` : '-'}</div>
      </div>
    );
  };

  const showFinalStonePrice = () => {
    if (!addStoneDetails.discountAmount) {
      return;
    }
    return (
      <div className="avgSizeContainer">
        <div className="title">{'Final Price'}</div>
        <div className="avgSize" style={{ paddingTop: 15, paddingBottom: 0 }}>
          {currencySymbol} {getFinalStonePrice(addStoneDetails)}
        </div>
      </div>
    );
  };

  const showMandatoryText = () => {
    return (
      <div className="mandatoryTextContainer">
        <div className="mandatoryText">* {t('mandatory_fields')}</div>
        <div className="autoCalculate">* {t('auto_calculate')}</div>
      </div>
    );
  };

  const showStoneOptions = name => {
    if (name === DIAMOND.id) {
      return (
        <Diamond
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          addStoneDetails={addStoneDetails}
          setAddStoneDetails={setAddStoneDetails}
          showAverageSize={showAverageSize}
          renderCost={renderCost}
          showFinalStonePrice={showFinalStonePrice}
          showMandatoryText={showMandatoryText}
          entityRatesArray={entityRatesArray}
          currencySymbol={currencySymbol}
        />
      );
    }
    if (name === STONE.id) {
      return (
        <Stone
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          addStoneDetails={addStoneDetails}
          setAddStoneDetails={setAddStoneDetails}
          renderCost={renderCost}
          showFinalStonePrice={showFinalStonePrice}
          showMandatoryText={showMandatoryText}
          currencySymbol={currencySymbol}
        />
      );
    }
    return null;
  };

  const validateForm = name => {
    const { errorFlag, errorMessage } = validateInputValues({ t, name, addStoneDetails });
    if (Object.keys(errorMessage).length > 0) {
      setErrorMessage(errorMessage);
    }
    return errorFlag;
  };

  const saveVariantJewelleryPrices = async (variantId, forceUpdate) => {
    if (
      isVariant &&
      (productTypeDetailsFromProduct || productTypeRateChargesFromProduct || forceUpdate)
    ) {
      await saveVariantChanges([
        {
          data: {
            ...variantData,
            productTypeDetails: variantData.productTypeDetails
          },
          forceUpdate,
          variantId: variantId
        }
      ]);
    }
  };

  const saveProductDetailsToVariant = async ({
    variantId,
    formData,
    productStonesData,
    deleting
  }) => {
    let idx;
    let addingNewStone = deleting ? false : !formData.id;

    const updatedVariantStonesData = productStonesData.map((stoneData, index) => {
      if (stoneData.id === formData.id) {
        idx = index;
        addingNewStone = false;
        formData.position = stoneData.position || index;
        delete formData.id;
        // saving it separately to get final product price from the api on last save
        return formData;
      }
      return getFormattedStoneData({
        activeProductId,
        addStoneDetails: {
          ...stoneData.details,
          id: undefined,
          position: stoneData.position || index,
          type: stoneData.type
        }
      });
    });

    if (addingNewStone) {
      updatedVariantStonesData.push(formData);
    }

    await saveVariantJewelleryPrices(variantId, true);
    const stoneValues = await Promise.all(
      updatedVariantStonesData.map(stoneData =>
        setProductStoneDetails({ productId: variantId, formData: stoneData })
      )
    );

    if (typeof idx !== 'number') {
      idx = stoneValues.length - 1;
    }

    setVariantData({
      type: UPDATE_JEWELLERY_RATES,
      productTypeDetails,
      productTypeRateCharges: stoneValues
    });

    return { data: stoneValues[idx], updatedProductStone: stoneValues };
  };

  const onSubmit = async name => {
    let errorFlag = validateForm(name);
    if (errorFlag) {
      return;
    }
    const formData = getFormattedStoneData({
      activeProductId,
      addStoneDetails,
      productStoneLength: productStone.length
    });
    setLoading(true);

    const done = type => {
      toastr.success(
        type === STONE.id
          ? t(`${STONE.displayValue.toLowerCase()}_has_been_successfully_added`)
          : t(`${DIAMOND.displayValue.toLowerCase()}_has_been_successfully_added`)
      );
      onCloseStonesModal();
    };

    try {
      let data;

      if (productTypeRateChargesFromProduct) {
        const { updatedProductStone, data } = await saveProductDetailsToVariant({
          formData,
          variantId: activeProductId,
          productStonesData: productStone
        });
        delete formData.id;
        const { productPrice, type = STONE.id } = data || {};
        setProductStone(updatedProductStone);
        updateProductOrVariantStoneDetailsInCache({
          productId: activeProductId,
          parentProductId,
          productTypeRateCharges: updatedProductStone,
          price: productPrice
        });
        done(type);
        return;
      }

      if (!productTypeRateChargesFromProduct) {
        data = await (formData.id
          ? updateProductStoneDetails(formData)
          : setProductStoneDetails({ formData, productId: activeProductId }));
      }

      const { productPrice, type = STONE.displayValue } = data || {};
      delete data.productPrice;

      if (formData.id) {
        const updatedProductStone = productStone.map(stoneDetails => {
          const { id } = stoneDetails;
          if (id === data.id) {
            stoneDetails = data;
          }
          return stoneDetails;
        });
        setProductStone(updatedProductStone);
        updateProductOrVariantStoneDetailsInCache({
          productId: activeProductId,
          parentProductId,
          productTypeRateCharges: updatedProductStone,
          price: productPrice
        });
        done(type);
        return;
      }

      const updatedProductStone = productStone;
      updatedProductStone.push(data);
      setProductStone(updatedProductStone);
      updateProductOrVariantStoneDetailsInCache({
        productId: activeProductId,
        parentProductId,
        productTypeRateCharges: updatedProductStone,
        price: productPrice
      });
      done(type);
    } catch (error) {
      const { message } = error;
      toastr.error(message);
      if (message) {
        const fields = message
          .replaceAll('"', '')
          .split(': ')[1]
          ?.split(', ');
        if (fields?.length) {
          setErrorMessage(
            fields.reduce((prev, field) => {
              if (
                !!field &&
                [t('name'), t('rate'), t('shape'), t('weight'), t('discount'), t('pieces')]
                  .map(fieldName => fieldName.toLowerCase())
                  .includes(field.toLowerCase())
              ) {
                prev[field] = t('field_is_required', { field });
              }
              return prev;
            }, {})
          );
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const deleteStoneDetails = async ({ productId, id }) => {
    setLoading(true);
    try {
      const deletedProductStone = productStone.find(stoneDetails => stoneDetails.id === id);
      let updatedProductStone = productStone.filter(stoneDetails => stoneDetails.id !== id);

      if (!deletedProductStone) {
        return;
      }

      let productPrice;
      if (productTypeRateChargesFromProduct) {
        const data = await saveProductDetailsToVariant({
          formData: {},
          deleting: true,
          variantId: activeProductId,
          productStonesData: updatedProductStone
        });
        updatedProductStone = data.updatedProductStone;
        productPrice = data.data.productPrice;
        delete data.data;
      }
      if (!productTypeRateChargesFromProduct) {
        const data = await deleteProductStoneDetails({ productId, id });
        productPrice = (data || {}).productPrice;
        delete data.productPrice;
      }
      updateProductOrVariantStoneDetailsInCache({
        productId: activeProductId,
        parentProductId,
        productTypeRateCharges: updatedProductStone,
        price: productPrice
      });
      setProductStone(updatedProductStone);
      if (isVariant) {
        setVariantData({ type: UPDATE_ORIGINAL_TO_CURRENT });
      }
      setJewelleryInfo(prev => ({
        ...prev,
        productTypeRateCharges: updatedProductStone
      }));
      toastr.success(
        deletedProductStone.type === STONE.id
          ? t(`${STONE.displayValue.toLowerCase()}_has_been_successfully_deleted`)
          : t(`${DIAMOND.displayValue.toLowerCase()}_has_been_successfully_deleted`)
      );
    } catch (error) {
      toastr.error(error.message);
    } finally {
      setLoading(false);
      closeConfirmationDialogBox();
    }
  };

  const closeConfirmationDialogBox = () => {
    setShowConfirmationBox(false);
    setAddStoneDetails(initialStoneDetailsState);
  };

  const renderConfirmationBox = type => {
    return (
      <Modal
        open={showConfirmationBox}
        center={true}
        onClose={closeConfirmationDialogBox}
        showCloseIcon={false}
        styles={{ modal: stonesModalStyling.current }}
      >
        <div className="stonesModalTitle">
          {type.toLowerCase() === STONE.id ? t('delete_stone') : t('delete_diamond')}
        </div>
        <div className="subHeading">
          {type.toLowerCase() === STONE.id
            ? t('are_you_sure_you_want_to_delete_this_stone')
            : t('are_you_sure_you_want_to_delete_this_diamond')}
          <span className="diamondName">
            {' '}
            {showStoneValueDetailsName(addStoneDetails.id, productStone)} ?
          </span>
        </div>
        <div className="buttonContainer">
          <Button className="cancel" onClick={closeConfirmationDialogBox} style={{ fontSize: 14 }}>
            {t('cancel')}
          </Button>
          <Button
            type="submit"
            className="save"
            style={{ fontSize: 14 }}
            onClick={() => {
              deleteStoneDetails({ id: addStoneDetails.id, productId: activeProductId });
            }}
          >
            {loading ? <Loader size="small" /> : <span>{t('delete')}</span>}
          </Button>
        </div>
      </Modal>
    );
  };

  const renderStonesModal = name => {
    return (
      <Modal
        open={!!showStonesModal}
        center={true}
        onClose={onCloseStonesModal}
        showCloseIcon={false}
        styles={{ modal: stonesModalStyling.current }}
      >
        <div className="stonesModalTitle">
          {name.toLowerCase() === STONE.id.toLowerCase() ? t('add_stone') : t('add_diamond')}
        </div>
        <div className="stoneOptions">{showStoneOptions(name)}</div>
        <div className="buttonContainer">
          <Button className="cancel" onClick={onCloseStonesModal}>
            {t('cancel')}
          </Button>
          <Button
            type="submit"
            color="primary"
            className="save"
            onClick={() => {
              onSubmit(name);
            }}
          >
            {loading ? (
              <Loader size="small" />
            ) : (
              <span>{name === DIAMOND.id ? t('add_diamond') : t('add_stone')}</span>
            )}
          </Button>
        </div>
      </Modal>
    );
  };

  const renderModal = () => {
    const stoneTypeDiamond = (productStone || []).filter(stone => stone.type === DIAMOND.id);
    const otherStones = (productStone || []).filter(stone => stone.type === STONE.id);
    return (
      <Modal
        open={showModal}
        center={true}
        onClose={onClose}
        showCloseIcon={false}
        styles={{ modal: priceCalculationModalStyles.current }}
      >
        <div className="modal">
          <div className="header">
            <div className="title">{t('price_calculation')}</div>
            <div
              className="closeIconContainer"
              onClick={() => {
                onClose();
              }}
            >
              <CloseIcon />
            </div>
          </div>

          <div className="chargesBreakDownContainer">
            {metalKaratQuality && metalKaratQuality !== SELECT_VALUE.id && (
              <div className="charges">
                <div className="chargesTitle">
                  {`${metalKaratQuality}${METAL_UNITS[entityRates.entityQualityUnit] ||
                    ''} Metal Charges`}
                  <div className="subTitle">{`(${currencySymbol} ${getSelectedMetalKaratPrice()}/gm x ${netWeight}gm)`}</div>
                </div>
                <div className="cost">{`${currencySymbol} ${goldCost()}`}</div>
              </div>
            )}

            {makingCharges.makingCharge && (
              <div className="charges">
                <div className="chargesTitle">
                  <span>{t('making_charges')}</span>
                  <div className="subTitle">
                    {makingCharges.makingChargeType === PERCENT.id &&
                      t('percent_of_metal_charges', { percent: makingCharges.makingCharge })}
                    {makingCharges.makingChargeType === PERGRAM.id &&
                      `(${currencySymbol} ${makingCharges.makingCharge} ${t('per_gram')})`}
                  </div>
                </div>
                <div className="cost">{`${currencySymbol} ${calculateMakingCharges()}`}</div>
              </div>
            )}

            {wastageCharges.wastagePercent && (
              <div className="charges">
                <div className="chargesTitle">
                  <span>{t('wastage')}</span>
                  {!!wastageCharges.wastagePercent && (
                    <div className="subTitle">
                      {t('percent_of_wastage_on_wastage_type', {
                        wastagePercent: wastageCharges.wastagePercent,
                        wastageType: t(LABELS[wastageCharges.wastageOn || NET_WEIGHT.id])
                      })}
                    </div>
                  )}
                </div>
                <div className="cost">{`${currencySymbol} ${calculateWastageCharges()}`}</div>
              </div>
            )}

            {stoneTypeDiamond && stoneTypeDiamond.length > 0 && (
              <div className="diamondStoneDetails">
                <div className="chargesTitle">{'Stone - Diamond'}</div>
                {stoneTypeDiamond.map(stoneDetails => {
                  const { id, details } = stoneDetails;
                  return (
                    <div className="subTitleAndCost" key={id}>
                      <div className="subTitle">{showStoneValueDetailsName(id, productStone)}</div>
                      <div className="cost">{`${currencySymbol} ${details.cost}`}</div>
                    </div>
                  );
                })}
              </div>
            )}
            {otherStones &&
              otherStones.length > 0 &&
              otherStones.map(stoneDetails => {
                const { id, details } = stoneDetails;
                return (
                  <div className="diamondStoneDetails" key={id}>
                    <div className="chargesTitle">
                      <span>{`${t('stone')} - ${details.name}`}</span>
                    </div>
                    <div className="subTitleAndCost">
                      <div className="subTitle" style={{ textTransform: 'capitalize' }}>
                        {showStoneValueDetailsName(id, productStone)}
                      </div>
                      <div className="cost">{`${currencySymbol} ${details.cost}`}</div>
                    </div>
                  </div>
                );
              })}
            {(otherChargesList || []).map(({ type, amount }, key) => {
              if (typeof type === 'string' && type.trim().length) {
                return (
                  <div key={key} className="charges">
                    <div className="chargesTitle">{type}</div>
                    <div className="cost">{`${currencySymbol} ${amount ? amount : 0}`}</div>
                  </div>
                );
              }
              return null;
            })}
          </div>
          <div className="totalPriceContainer">
            <div className="totalPriceTitle">{t('total_price')}</div>
            <div className="totalPrice">{`${currencySymbol} ${getFinalPrice()}`}</div>
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <>
      <div className="jewelleryBasicInfo">
        <JewelleryRateCharge
          initialDisabled={productTypeDetailsFromProduct && isVariant}
          showSaveButton={metalKaratQuality !== originalEntityQuality}
          showChangeButton={!!originalEntityQuality && productTypeDetailsFromProduct && isVariant}
        >
          <MetalAndKarat
            placeholder={t('type_design_number_or_product_title')}
            metalLabel={productTypeDetails.entityLabel}
            metalType={selectedEntityType}
            entityQualityUnit={entityRates.entityQualityUnit}
            metalKaratQuality={metalKaratQuality}
            onSelectChangeMetalType={onSelectChangeMetalType}
            onSelectChangeMetalKaratQuality={onSelectChangeMetalKaratQuality}
            entityRates={entityRates}
            checkIfRatesAreNotSet={checkIfRatesAreNotSet}
          />
        </JewelleryRateCharge>
        <MetalRate
          metalKaratQuality={metalKaratQuality}
          currencySymbol={currencySymbol}
          selectedMetalType={selectedEntityType}
          getSelectedMetalKaratPrice={getSelectedMetalKaratPrice}
          checkIfRatesAreNotSet={checkIfRatesAreNotSet}
        />
        <GrossAndNetWeight
          originalGrossWeight={originalGrossWeight}
          grossWeight={grossWeight}
          onGrossWeightChange={onGrossWeightChange}
          isGrossWidgetChanged={(originalGrossWeight || '').toString() !== grossWeight}
          originalNetWeight={originalNetWeight}
          netWeight={netWeight}
          onNetWeightChange={onNetWeightChange}
          initialDisabled={productTypeDetailsFromProduct && isVariant}
          isNetWeightChanged={(originalNetWeight || '').toString() !== netWeight}
        />
        <JewelleryRateCharge
          initialDisabled={!!originalMakingCharge && productTypeDetailsFromProduct && isVariant}
          showSaveButton={
            makingCharges.makingChargeType !== originalMakingChargeType ||
            (originalMakingCharge || '').toString() !== makingCharges.makingCharge
          }
          showChangeButton={productTypeDetailsFromProduct && isVariant}
        >
          <MakingCharges
            makingChargeType={makingCharges.makingChargeType}
            makingCharge={makingCharges.makingCharge}
            currencySymbol={currencySymbol}
            onMakingChargeSelectChange={onMakingChargeSelectChange}
            onMakingChargeInputChange={onMakingChargeInputChange}
          />
        </JewelleryRateCharge>
        <JewelleryRateCharge
          initialDisabled={!!originalWastagePercent && productTypeDetailsFromProduct && isVariant}
          showSaveButton={
            wastageCharges.wastageOn !== originalWastageOn ||
            (originalWastagePercent || '').toString() !== wastageCharges.wastagePercent
          }
          showChangeButton={productTypeDetailsFromProduct && isVariant}
        >
          <WastageCharges
            wastageOn={wastageCharges.wastageOn}
            wastageCharge={wastageCharges.wastagePercent}
            onWastageChargeSelectChange={onWastageChargeSelectChange}
            onWastageChargeInputChange={onWastageChargeInputChange}
            currencySymbol={currencySymbol}
          />
        </JewelleryRateCharge>
        <StoneCharges
          setShowStonesModal={setShowStonesModal}
          productStone={productStone}
          setProductStone={setProductStone}
          setAddStoneDetails={setAddStoneDetails}
          setShowConfirmationBox={setShowConfirmationBox}
          currencySymbol={currencySymbol}
        />
        <OtherCharges
          currencySymbol={currencySymbol}
          rowTitle={t('other_charges')}
          otherChargesList={otherChargesList}
          setOtherChargesList={setOtherChargesList}
          onOtherChargesChange={onOtherChargesChange}
          onChargeTypeTitleChange={onChargeTypeTitleChange}
          onChargeTypeAmountChange={onChargeTypeAmountChange}
          onDeleteOtherCharge={onDeleteOtherCharge}
        />
        <ProductPriceAndCalculation
          setShowModal={setShowModal}
          getFinalPrice={getFinalPrice}
          currencySymbol={currencySymbol}
          entityRates={entityRates}
          checkIfRatesAreNotSet={checkIfRatesAreNotSet}
          existingPrice={existingPrice}
          necessaryJewelleryFieldValuesExist={necessaryJewelleryFieldValuesExist}
          getSelectedMetalKaratPrice={getSelectedMetalKaratPrice}
          metalKaratQuality={metalKaratQuality}
          netWeight={netWeight}
          grossWeight={grossWeight}
          checkIfAnyJewelleryRelatedFieldsAreSet={checkIfAnyJewelleryRelatedFieldsAreSet}
          isAllProductTypeDetailsFieldsEmpty={isAllProductTypeDetailsFieldsEmpty}
          productStone={productStone}
        />
        {isVariant && (
          <p style={{ fontSize: '12px' }}>
            ({t('includes_the_price_of_stones_and_diamonds_from_product_if_any')})
          </p>
        )}
        {showModal && renderModal()}
        {showStonesModal && renderStonesModal(showStonesModal)}
        {showConfirmationBox && renderConfirmationBox(addStoneDetails.type)}
      </div>
    </>
  );
};
