import React, { useState, useEffect, useCallback, Fragment } from 'react';
import ProductRowShimmer from './ProductRowShimmer';
import {
  attachProductMetaListener,
  removeProductMetaListener,
  getProductMetaFromCache,
  OPERATION_STATUS,
  deleteProducts,
  setActiveProductId,
  isProductSelected,
  ACTIVE_PRODUCT_ID_META,
  getProductMeta,
  PRODUCT_ROW_TYPES,
  getActiveProductId,
  resetActiveProductId
} from 'qs-data-manager/Products';
import { selectedProducts } from 'qs-data-manager/Selected';
import CheckBox from 'qs-components/Common/CheckBox';
import { getActiveCatalogueId } from 'qs-data-manager/Catalogues';
import eventbus from 'eventing-bus';
import SmallImageLoader from 'qs-components/Common/SmallImageLoader';
import OutOfStockLabel from 'qs-components/Common/OutOfStockLabel';
import {
  getCurrencySymbol,
  constructPluralTextFromSize,
  roundNumberToGivenDecimals
} from 'qs-helpers';
import { getCompanyCurrencyCode } from 'qs-data-manager/Company';
import { ReactComponent as DeleteIcon } from 'qs-assets/Media/trash.svg';
import ProductRowUploader from './ProductRowUploader';
import { VARIANT_ADD_TABS } from 'qs-helpers/Variants/constants';
import { isAnyColorNull } from 'qs-helpers/Variants/ResponseProcessor';
import useUpdateListOnItemUpdate from 'qs-hooks/useUpdateListOnItemUpdate';
import { isProductPriceValid } from 'qs-helpers/Products/ProductPricing';
import { EDIT_ACTIVE, SEARCH_ACTIVE } from 'qs-helpers/Products/constants';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

const NO_CATALOGUE_TITLE_PLACEHOLDER = 'no_product_title';
const NO_CATALOGUE_DESCRIPTION_PLACEHOLDER = 'no_product_description';

export default ({ productId, productIndex, reRenderListOnItemUpdate, getDragIcon } = {}) => {
  const [editMode, setEditMode] = useState(() =>
    selectedProducts.isActive([EDIT_ACTIVE, SEARCH_ACTIVE])
  );
  const [checkboxValue, setCheckboxValue] = useState(() => {
    if (editMode) {
      return selectedProducts.isSelected(productId);
    }
  });

  const { t } = getI18N();

  const [productMetaState, setProductMetaState] = useState({
    loading: false,
    refreshing: false,
    success: false,
    error: null,
    productMeta: getProductMetaFromCache(productId)
  });
  const [isSelected, setIsSelected] = useState(() => isProductSelected(productId));

  // Attach the hook that will update the list whenever the DOM height changes
  const [, setProductRowRef] = useUpdateListOnItemUpdate({
    reRenderListOnItemUpdate,
    defaultRowHeight: PRODUCT_ROW_TYPES.PRODUCT_ROW.estimatedHeight,
    index: productIndex,
    uniqueId: productId
  });

  useEffect(() => {
    const removeListener = eventbus.on(ACTIVE_PRODUCT_ID_META.eventbusKey, productIds => {
      const id = productIds[0];
      setIsSelected(id === productId);
    });

    return () => removeListener();
  }, [productId]);

  useEffect(() => {
    const isSelected = isProductSelected(productId);
    setIsSelected(isSelected);
  }, [productId, productIndex]);

  const productMetaListener = useCallback((err, { status, data }) => {
    const updates = {};
    if (err) {
      updates.loading = false;
      updates.refreshing = false;
      updates.success = false;
      updates.error = err;
    } else {
      switch (status) {
        case OPERATION_STATUS.LOADING: {
          updates.loading = true;
          updates.refreshing = false;
          updates.success = false;
          updates.error = null;
          break;
        }
        case OPERATION_STATUS.REFRESHING: {
          updates.loading = false;
          updates.refreshing = true;
          updates.success = false;
          updates.error = null;
          updates.productMeta = data || {};
          break;
        }
        case OPERATION_STATUS.SUCCESS: {
          updates.loading = false;
          updates.refreshing = false;
          updates.success = true;
          updates.error = null;
          updates.productMeta = data || {};
          break;
        }
        case OPERATION_STATUS.UPDATE: {
          updates.loading = false;
          updates.refreshing = false;
          updates.success = false;
          updates.error = null;
          updates.productMeta = data || {};
          break;
        }
        default:
          break;
      }

      if (updates.productMeta) {
        const currencyCode = getCompanyCurrencyCode();
        updates.productMeta.currencySymbol = getCurrencySymbol({ currencyCode });
      }

      setProductMetaState(updates);
    }
  }, []);

  useEffect(() => {
    const onSelectedValueChange = (all = {}) => {
      const selectionState = !!all[productId];
      setCheckboxValue(selectionState);
    };

    const onSelectionStateChange = isActive => {
      setEditMode(isActive);
      if (isActive) {
        selectedProducts.removeListener(onSelectedValueChange);
        selectedProducts.addListener(onSelectedValueChange);
        return;
      }
    };

    attachProductMetaListener({ listener: productMetaListener, productId });
    getProductMeta({ productIds: [productId] });

    const productMeta = getProductMetaFromCache(productId);

    setProductMetaState(prevState => ({
      ...prevState,
      productMeta
    }));

    selectedProducts.addActiveListener([EDIT_ACTIVE, SEARCH_ACTIVE], onSelectionStateChange);

    return () => {
      removeProductMetaListener({ listener: productMetaListener, productId });
      selectedProducts.removeActiveListener([EDIT_ACTIVE, SEARCH_ACTIVE], onSelectionStateChange);
      selectedProducts.removeListener(onSelectedValueChange);
    };
  }, [productId, productMetaListener]);

  const toggleCheckboxValue = event => {
    event.stopPropagation();
    event.preventDefault();
    const currentCheckboxValue = !checkboxValue;

    if (currentCheckboxValue) {
      selectedProducts.add(productId);
    } else {
      selectedProducts.remove(productId);
    }
  };

  const deleteProduct = useCallback(
    e => {
      e.stopPropagation();

      const removeProduct = window.confirm(
        t('are_you_sure_you_want_to_delete_this_product_from_catalogue')
      );

      if (removeProduct) {
        const catalogueId = getActiveCatalogueId();
        selectedProducts.remove(productId);
        deleteProducts([productId], catalogueId, {
          showLoader: true,
          makeRemoteChanges: true
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [productId]
  );

  const onProductRowClick = event => {
    let editViewMode = false;
    // Since the selection activation is specific to components, ensure that editmode is on before proceeding to toggle checkboxes
    if (editMode) {
      editViewMode = selectedProducts.isAnySelected();
    }

    if (editViewMode) {
      toggleCheckboxValue(event);
      return;
    }

    event.stopPropagation();
    event.preventDefault();

    if (productId === getActiveProductId()) {
      resetActiveProductId();
      return;
    }
    setActiveProductId(productId);
    setIsSelected(true);
  };

  const getRightContainer = () => {
    if (productMetaState.productMeta.defaultImageErrored) {
      return <ProductRowUploader productId={productMetaState.productMeta.productId} />;
    }

    const { discount, isSlab, price } = productMetaState.productMeta;
    const discountValid = !isSlab && isProductPriceValid(discount);
    const priceValid = isProductPriceValid(price);

    return (
      <Fragment>
        <div
          className={`priceAndDiscountContainer ${editMode ? 'editModeWidth' : 'nonEditModeWidth'}`}
        >
          <div className={`price ${discountValid ? 'discountAvailable' : ''}`}>
            {priceValid
              ? `${productMetaState.productMeta.currencySymbol} ${roundNumberToGivenDecimals(
                  price,
                  2
                )}`
              : '-'}
          </div>
          {discountValid && (
            <div className={'discount'}>
              {productMetaState.productMeta.currencySymbol}{' '}
              {roundNumberToGivenDecimals(discount, 2)}
            </div>
          )}
        </div>

        {!!editMode ? (
          <div onClick={deleteProduct} className={'deleteContainer'}>
            <DeleteIcon className={'deleteIcon'} />
          </div>
        ) : null}
      </Fragment>
    );
  };

  const createVariantColorsComponent = variantColorsData => {
    if (!Array.isArray(variantColorsData)) {
      return null;
    }

    const nullColorExists = isAnyColorNull(variantColorsData);
    if (nullColorExists) {
      return constructPluralTextFromSize({
        arrayToCheck: variantColorsData,
        text: (variantColorsData.length > 1 ? t('colors') : t('color')).toLowerCase()
      });
    }

    return (
      <div className="variantsColorsContainer">
        {variantColorsData.reduce((rgbColorData, { rgbValue }, index) => {
          if (!Array.isArray(rgbValue)) {
            return rgbColorData;
          }

          const [red, green, blue] = rgbValue;
          rgbColorData.push(
            <div
              key={index}
              className="variantColor"
              style={{ backgroundColor: `rgb(${red}, ${green}, ${blue})` }}
            />
          );
          return rgbColorData;
        }, [])}
      </div>
    );
  };

  const renderVariantsMetaData = variantOptionsMeta => {
    if (!variantOptionsMeta) {
      return null;
    }

    const colorsComponent = createVariantColorsComponent(
      variantOptionsMeta[VARIANT_ADD_TABS.COLORS]
    );
    let sizesText = '';
    const sizesCount = variantOptionsMeta[VARIANT_ADD_TABS.SIZES];

    if (Array.isArray(sizesCount)) {
      sizesText = constructPluralTextFromSize({
        arrayToCheck: variantOptionsMeta[VARIANT_ADD_TABS.SIZES],
        text: (sizesCount.length > 1 ? t('sizes') : t('size')).toLowerCase()
      });
    }

    let customVariantsCount = 0;
    Object.entries(variantOptionsMeta).map(variant => {
      if (
        variant[0] &&
        variant[1] &&
        variant[1].length &&
        variant[0] !== VARIANT_ADD_TABS.COLORS &&
        variant[0] !== VARIANT_ADD_TABS.SIZES
      ) {
        customVariantsCount += variant[1].length;
      }
      return customVariantsCount;
    });

    let sizesAndVariantsComponent = null;
    if (sizesText !== '' || customVariantsCount) {
      sizesAndVariantsComponent = (
        <div className="variantsMetaText">
          {sizesText !== ''
            ? customVariantsCount > 1
              ? t('custom_variant_and_more_variants', {
                  customVariantName: sizesText,
                  customVariantsCount
                })
              : customVariantsCount === 0
              ? sizesText
              : t('custom_variant_and_more_variant', {
                  customVariantName: sizesText,
                  customVariantsCount
                })
            : customVariantsCount
            ? t('count_of_variants', { variantsCount: customVariantsCount })
            : ''}
        </div>
      );
    }

    // Null rgb exists, only a single div must be rendered
    if (typeof colorsComponent === 'string') {
      let variantMetaText = `${colorsComponent}`;
      if (sizesText !== '') {
        variantMetaText += `, ${sizesText}`;
      }
      return (
        <div className="variantsMetaText">
          {customVariantsCount > 1
            ? t('custom_variant_and_more_variants', {
                customVariantName: variantMetaText,
                customVariantsCount
              })
            : customVariantsCount === 0
            ? variantMetaText
            : t('custom_variant_and_more_variant', {
                customVariantName: variantMetaText,
                customVariantsCount
              })}
        </div>
      );
    }

    return (
      <Fragment>
        {colorsComponent}
        {sizesAndVariantsComponent}
      </Fragment>
    );
  };

  if (!productMetaState.productMeta || !productMetaState.productMeta.productId) {
    return <ProductRowShimmer />;
  }

  return (
    <div
      onClick={onProductRowClick}
      className={`ProductRow noselect ${isSelected || checkboxValue ? 'productRowSelected' : ''}`}
      ref={setProductRowRef}
    >
      <div className={'leftContainer'}>
        {editMode && (
          <CheckBox
            checked={checkboxValue}
            onCheckChanged={toggleCheckboxValue}
            styleProps={{ margin: '0 14px 0 0' }}
          />
        )}

        <div className="productImageContainer">
          <SmallImageLoader
            key={productMetaState.productMeta.pictureId}
            pictureId={productMetaState.productMeta.pictureId}
            url={productMetaState.productMeta.pictureUrl}
            isPrepared={productMetaState.productMeta.isPrepared}
            error={productMetaState.productMeta.defaultImageErrored}
            pictureCount={productMetaState.productMeta.pictureCount}
            videoCount={productMetaState.productMeta.videoCount}
          />
        </div>

        <div className={'productDataContainer'}>
          {productMetaState.productMeta.defaultImageErrored && (
            <div className="productError ellipsis">
              {t('picture_upload_error_please_try_again')}
            </div>
          )}
          <div className={'title ellipsis'}>
            {!!productMetaState.productMeta.name
              ? productMetaState.productMeta.name
              : t(NO_CATALOGUE_TITLE_PLACEHOLDER)}
          </div>
          <div className={'description ellipsis'}>
            {!!productMetaState.productMeta.description
              ? productMetaState.productMeta.description
              : t(NO_CATALOGUE_DESCRIPTION_PLACEHOLDER)}
          </div>
          {productMetaState.productMeta.stock === 0 && (
            <OutOfStockLabel outOfStockClass={'outOfStock'} />
          )}
          {renderVariantsMetaData(productMetaState.productMeta.optionsMeta)}
        </div>
      </div>
      <div className={'rightContainer'}>
        {getRightContainer()}
        {typeof getDragIcon === 'function' && getDragIcon(productId)}
      </div>
    </div>
  );
};
