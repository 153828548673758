import AppConfig from 'qs-config/AppConfig';
import request from '../../Request';

export const COMPANY_DETAIL_KEYS = {
  FACEBOOK: 'FACEBOOK',
  INSTAGRAM: 'INSTAGRAM',
  YOUTUBE: 'YOUTUBE',
  TWITTER: 'TWITTER'
};

export const saveProfileLogo = data => {
  return request.postRequestFileUpload(`${AppConfig.qsApiEndPoint}/v1/company/logo`, data);
};

export const getFacebook = () => {
  return request.authGet(
    `${AppConfig.qsApiEndPoint}/v1/company/company-details/${COMPANY_DETAIL_KEYS.FACEBOOK}`
  );
};
export const setFacebook = value => {
  return request.authPost(
    `${AppConfig.qsApiEndPoint}/v1/company/company-details/${COMPANY_DETAIL_KEYS.FACEBOOK}`,
    { value }
  );
};

export const getInstagram = () => {
  return request.authGet(
    `${AppConfig.qsApiEndPoint}/v1/company/company-details/${COMPANY_DETAIL_KEYS.INSTAGRAM}`
  );
};
export const setInstagram = value => {
  return request.authPost(
    `${AppConfig.qsApiEndPoint}/v1/company/company-details/${COMPANY_DETAIL_KEYS.INSTAGRAM}`,
    { value }
  );
};

export const getYoutube = () => {
  return request.authGet(
    `${AppConfig.qsApiEndPoint}/v1/company/company-details/${COMPANY_DETAIL_KEYS.YOUTUBE}`
  );
};
export const setYoutube = value => {
  return request.authPost(
    `${AppConfig.qsApiEndPoint}/v1/company/company-details/${COMPANY_DETAIL_KEYS.YOUTUBE}`,
    { value }
  );
};

export const getTwitter = () => {
  return request.authGet(
    `${AppConfig.qsApiEndPoint}/v1/company/company-details/${COMPANY_DETAIL_KEYS.TWITTER}`
  );
};
export const setTwitter = value => {
  return request.authPost(
    `${AppConfig.qsApiEndPoint}/v1/company/company-details/${COMPANY_DETAIL_KEYS.TWITTER}`,
    { value }
  );
};
