import CouponIcon from 'qs-assets/Media/ic_coupon_white.png';
import { markOrderInquiriesAsRead } from 'qs-data-manager/Orders/OrdersProductsOperations';
import { selectedOrders } from 'qs-data-manager/Selected';
import { ORDER_PRODUCT_LIST } from 'qs-helpers/Orders/constants';
import { getOrderTotalBreakdown } from 'qs-helpers/Orders/OrderPriceCalculator';
import { getCouponCode, getCouponDiscountValueToShow } from 'qs-helpers/Orders/ResponseProcessor';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { ActiveOrderMeta } from '../../context';
import OrderKeyValue from '../OrderKeyValue';
import OrderDiscount from './OrderDiscount';
import OrderMetaData from './OrderMetaData';
import OrderProductsRow from './OrderProductsRow';
import { handleGalleryData } from '../../../../Services/Helpers/Orders/AlterData';
import ShipppingCost from './ShipppingCost';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

export default ({ inquiryData, onListMount }) => {
  const { coupon, orderEditable, shippingConfig } = inquiryData;
  const {
    activeOrderMeta: { selectedOrderId, currentConfirmFilter }
  } = useContext(ActiveOrderMeta);
  const [viewContainerRef, setViewContainerRef] = useState(null);
  const previousOrderEntries = useRef();
  const previousOrderId = useRef();
  const { t } = getI18N();

  const modifiedOrderEntries = useMemo(() => {
    const nonDeletedEntries = [];
    const deletedEntries = [];
    for (const inquiryEntry of inquiryData.inquiries) {
      const { isDeletedByVisitor } = inquiryEntry;
      if (isDeletedByVisitor) {
        deletedEntries.push(inquiryEntry);
        continue;
      }
      nonDeletedEntries.push(inquiryEntry);
    }

    nonDeletedEntries.push(...deletedEntries);
    return nonDeletedEntries;
  }, [inquiryData.inquiries]);

  const viewContainerMount = useCallback(
    ref => {
      if (ref) {
        setViewContainerRef(ref);
      }
    },
    [setViewContainerRef]
  );

  useEffect(() => {
    // Both the dependancies are new, mark the inquiries for the current order as read
    if (previousOrderId.current === selectedOrderId) {
      previousOrderEntries.current = modifiedOrderEntries;
      return;
    }

    // Order id has changed but the inquiry data has not yet updated, don't process anything
    if (previousOrderEntries.current === modifiedOrderEntries) {
      return;
    }

    // Both order id and inquiries are different, mark as read and update the previous values to prevent firing this REST call repeatedly
    markOrderInquiriesAsRead({
      orderId: selectedOrderId,
      inquiries: modifiedOrderEntries,
      confirmed: currentConfirmFilter
    });
    previousOrderId.current = selectedOrderId;
    previousOrderEntries.current = modifiedOrderEntries;
  }, [selectedOrderId, modifiedOrderEntries, currentConfirmFilter]);

  useEffect(() => {
    if (!viewContainerRef) {
      return;
    }

    const resizeObserver = new ResizeObserver(entries => {
      if (!(Array.isArray(entries) && entries.length)) {
        return;
      }
      const firstEntry = entries[0];

      let rowWidth;
      // Content box property exists, pick that otherwise fallback to the target property
      if (Array.isArray(firstEntry.contentBoxSize) && firstEntry.contentBoxSize.length) {
        rowWidth = Math.floor(firstEntry.contentBoxSize[0].inlineSize);
      } else if (firstEntry.contentRect) {
        rowWidth = Math.floor(firstEntry.contentRect.width);
      } else if (firstEntry.target) {
        // Target exists use it, if not then fallback to fetching the offsetHeight directly
        rowWidth = firstEntry.target.clientWidth;
      } else if (viewContainerRef) {
        rowWidth = viewContainerRef.clientWidth;
      }

      onListMount(rowWidth);
    });

    resizeObserver.observe(viewContainerRef);
    return () => resizeObserver.unobserve(viewContainerRef);
  }, [viewContainerRef, onListMount]);

  const galleryData = useMemo(() => {
    return handleGalleryData(modifiedOrderEntries);
  }, [modifiedOrderEntries]);

  const renderRow = useCallback(
    (index, data) => (
      <OrderProductsRow
        orderProductsPictureData={galleryData}
        productData={data}
        coupon={coupon}
        index={index}
        orderEditable={orderEditable}
        shippingConfig={shippingConfig}
      />
    ),
    [coupon, orderEditable, shippingConfig, galleryData]
  );

  const handleCheckboxChange = useCallback(
    selected => {
      modifiedOrderEntries.forEach(({ inquiryId }) => {
        if (selected) {
          selectedOrders.add(inquiryId);
          return;
        }

        selectedOrders.remove(inquiryId);
      });
    },
    [modifiedOrderEntries]
  );

  const {
    additionalCheckoutFields,
    businessName,
    paymentMode,
    paymentCaptured,
    remainingOrderAmount,
    orderDiscount,
    orderDiscountType,
    taxNumber,
    additionalPaymentMode,
    pendingOrderAmount
  } = inquiryData;

  const baseOrderData = getOrderTotalBreakdown({
    orderDiscount,
    orderDiscountType,
    inquiries: modifiedOrderEntries,
    coupon
  });

  let couponLabel = t('coupon_applied');
  const couponDiscountValueToShow = getCouponDiscountValueToShow(coupon);
  if (typeof couponDiscountValueToShow === 'string') {
    couponLabel += ` ${couponDiscountValueToShow}`;
  }

  return (
    <div className="orderProductsViewContainer" ref={viewContainerMount}>
      <OrderMetaData
        additionalCheckoutFields={additionalCheckoutFields}
        businessName={businessName}
        taxNumber={taxNumber}
        paymentMode={paymentMode}
        paymentCaptured={paymentCaptured}
        amountCaptured={remainingOrderAmount}
        shippingConfig={shippingConfig}
        additionalPaymentMode={additionalPaymentMode}
        pendingOrderAmount={pendingOrderAmount}
      />
      <div className="orderListHeaderContainer">
        <OrderProductsRow
          headerRow
          style={{ height: ORDER_PRODUCT_LIST.headerHeight }}
          extraClass="orderListHeader"
          headerCheckboxChange={handleCheckboxChange}
          ordersProductCount={modifiedOrderEntries.length}
        />
      </div>
      <div className="orderProductsListContainer">
        <div>
          <Virtuoso
            customScrollParent={viewContainerRef}
            data={modifiedOrderEntries}
            totalCount={modifiedOrderEntries.length}
            itemContent={renderRow}
            overscan={600}
          />
        </div>
      </div>
      <div className="oriderPriceBreakupContainer">
        {baseOrderData.baseTotal > 0 && (
          <>
            <OrderKeyValue rows={[{ label: t('subtotal'), value: baseOrderData.baseTotal }]} />
            {baseOrderData.couponDiscountTotal > 0 && (
              <OrderKeyValue
                rows={[
                  { label: couponLabel, value: baseOrderData.couponDiscountTotal, negative: true },
                  {
                    label: getCouponCode(coupon),
                    icon: ({ iconClass }) => <img src={CouponIcon} alt="" className={iconClass} />
                  }
                ]}
              />
            )}
            <OrderDiscount
              discountValue={baseOrderData.customDiscountTotal}
              orderDiscount={orderDiscount}
              orderDiscountType={orderDiscountType}
              orderEditable={orderEditable}
              shippingConfig={shippingConfig}
            />
          </>
        )}
        {baseOrderData.tax > 0 && (
          <OrderKeyValue rows={[{ label: t('tax'), value: baseOrderData.tax }]} />
        )}
        <ShipppingCost inquiryData={inquiryData} />
      </div>
    </div>
  );
};
