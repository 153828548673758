import React from 'react';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

export default () => {
  const { t } = getI18N();

  return (
    <div id={'EmptyCatalogueList'}>
      {t('no_catalogues_available_to_display_create_a_new_catalogue')}
    </div>
  );
};
