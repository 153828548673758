import React, { memo, useLayoutEffect, useRef } from 'react';
import { getCompanyCurrencySymbol } from 'qs-data-manager/Company';
import { ReactComponent as RightArrow } from 'qs-assets/Media/long_arrow_right.svg';
import SmallImageLoader from 'qs-components/Common/SmallImageLoader';
import useVariantMetaHook from '../../../Hooks/variantMetaHook';
import ErrorIcon from '../../../../../Common/ErrorIcon';
import VariantShimmer from './VariantShimmer';
import './styles.scss';
import { getPictureIdFromImageUrl } from 'qs-helpers';

export default memo(({ variantId, onMetaUpdate, indexInList }) => {
  const variantMetaData = useVariantMetaHook(variantId);

  const summaryViewRef = useRef(null);

  const { loading, metaData, error } = variantMetaData;

  useLayoutEffect(() => {
    //No point updating the parent until either the label or the meta is available
    if (Object.keys(metaData || {}).length === 0) {
      return;
    }

    if (typeof onMetaUpdate === 'function' && summaryViewRef.current) {
      onMetaUpdate(indexInList, variantId, summaryViewRef.current.offsetHeight);
    }
  }, [metaData, indexInList, onMetaUpdate, variantId]);

  if (error) {
    return (
      <div className="variantDetailsContainer variantErrorContainer">
        <ErrorIcon />
        <div>Failed to load data for this variant</div>
      </div>
    );
  }

  if (loading) {
    return <VariantShimmer />;
  }

  const { color, label, name, price, pictureUrl } = metaData;

  return (
    <div className="variantDetailsContainer" ref={summaryViewRef}>
      <div className="variantItem">
        <div className="variantLabelContainer">
          {Array.isArray(color) && (
            <div
              className="variantColor"
              style={{ background: `rgb(${color[0]},${color[1]},${color[2]})` }}
            />
          )}
          <div className="variantlabel">{label}</div>
        </div>
        {typeof name === 'string' && name !== '' && <div className="variantName">{name}</div>}
        {typeof price === 'number' && (
          <div className="variantPrice">
            {getCompanyCurrencySymbol()} {price}
          </div>
        )}
      </div>
      <div className="rightContainer">
        {pictureUrl && (
          <div className="variantImageContainer">
            <SmallImageLoader
              url={pictureUrl}
              pictureId={getPictureIdFromImageUrl({ url: pictureUrl })}
              imageContainerClassName={'variantImage'}
              loaderSize="extraSmall"
            />
          </div>
        )}
        <div className="detailsButtonContainer">
          <RightArrow className="detailsButton" />
        </div>
      </div>
    </div>
  );
});
