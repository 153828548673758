import React from 'react';
import { getCompanyCurrencySymbol } from 'qs-data-manager/Company';
import { PRICE_FILTER_KEYS_CONFIG } from 'qs-helpers/Products/constants';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

export default ({
  priceFilterObj,
  selectedOption,
  onChange,
  inputConfig = {},
  defaultInputVal = '',
  currencySymbolRequired = false,
  rangeInputClassName = '',
  priceInputClassName = ''
}) => {
  const { t } = getI18N();

  const getPlaceholderAsPerOption = (key, defaultVal) => {
    const inputData = inputConfig[selectedOption];
    if (!inputData) {
      return defaultVal;
    }

    const { placeholder: { [key]: placeholderForKey } = {} } = inputData;
    return typeof placeholderForKey === 'string' ? t(placeholderForKey) : defaultVal;
  };

  const companyCurrency = getCompanyCurrencySymbol();

  if (selectedOption === PRICE_FILTER_KEYS_CONFIG.RANGE.id) {
    const { gtKey, ltKey } = PRICE_FILTER_KEYS_CONFIG.RANGE.keyMap;
    return (
      <div className={`productPriceFilterInputContainer rangeWrapper ${rangeInputClassName}`}>
        <div className="rangeContainer">
          {currencySymbolRequired && (
            <div className="currencyContainer">
              <div className="WebRupee">{companyCurrency}</div>
            </div>
          )}
          <input
            autoFocus
            className="rangeInput"
            placeholder={getPlaceholderAsPerOption(gtKey, t('enter_min_price'))}
            value={(priceFilterObj.RANGE || {})[gtKey] || defaultInputVal}
            onChange={onChange(gtKey)}
          />
        </div>

        <span className="rangeTo">{t('to')}</span>

        <div className="rangeContainer">
          {currencySymbolRequired && (
            <div className="currencyContainer">
              <div className="WebRupee">{companyCurrency}</div>
            </div>
          )}
          <input
            className="rangeInput"
            placeholder={getPlaceholderAsPerOption(ltKey, t('enter_max_price'))}
            value={(priceFilterObj.RANGE || {})[ltKey] || ''}
            onChange={onChange(ltKey)}
          />
        </div>
      </div>
    );
  }

  const selectedOptionKey = PRICE_FILTER_KEYS_CONFIG[selectedOption].keyMap.key;
  return (
    <div className={`productPriceFilterInputContainer priceContainer ${priceInputClassName}`}>
      {currencySymbolRequired && (
        <div className="currencyContainer">
          <div className="WebRupee">{companyCurrency}</div>
        </div>
      )}
      <input
        autoFocus
        className="inputPrice"
        value={(priceFilterObj[selectedOption] || {})[selectedOptionKey] || defaultInputVal}
        onChange={onChange(selectedOptionKey)}
        placeholder={getPlaceholderAsPerOption(selectedOptionKey, t('enter_price'))}
      />
    </div>
  );
};
