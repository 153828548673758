import React, { useCallback } from 'react';
import { Button, withStyles } from '@material-ui/core';
import {
  fetchCataloguesProductLibHandler,
  getCachedCataloguesProductLib,
  handleCataloguesProductLibCleanup
} from 'qs-helpers/Catalogues/DataQueryHelper';
import useMakeQuery from 'qs-hooks/useMakeQuery';
import Loader from '../../../../../Common/Loader';
import AutoSizer from 'react-virtualized-auto-sizer';
import InfiniteLoader from 'react-window-infinite-loader';
import { FixedSizeList as List } from 'react-window';
import {
  CATALOGUE_COPY_DIALOG_WIDTH,
  CATALOGUE_PRODUCT_LIB_FIXED_LIST
} from 'qs-helpers/Catalogues/constants';
import { searchCataloguesFromLibrary } from 'qs-data-manager/Catalogues/CatalogueProductLibrary';
import {
  hasCatalogueProductLibPaginationCompleted,
  isCatalogueProductLibPaginatedCallInProg
} from 'qs-helpers/Catalogues/ResponseProcessor';
import CatalogueOverlappingImages from '../../../../../Common/CatalogueOverlappingImages';
import { getPictureIdFromImageUrl } from 'qs-helpers';
import { DialogDarkTheme } from '../../../../../Common/DarkThemeDialog';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

const CopyButton = withStyles({
  root: {
    flex: '0 0 auto',
    backgroundColor: '#168255',
    '&:hover': {
      backgroundColor: '#1eac71'
    },
    color: '#fff'
  }
})(Button);

const CopyToCatalogueListDialog = withStyles({
  paper: {
    width: '100%',
    height: '100%',
    maxWidth: CATALOGUE_COPY_DIALOG_WIDTH
  }
})(DialogDarkTheme);

export default ({ onClose, onDuplicate, activeCatalogueId }) => {
  const forceLoad = true;
  const [{ loading, data: cataloguesData, error }] = useMakeQuery({
    cachedDataHandler: getCachedCataloguesProductLib,
    actionHandler: fetchCataloguesProductLibHandler,
    cleanupHandler: handleCataloguesProductLibCleanup,
    changeDependancy: [forceLoad, activeCatalogueId],
    globalLoader: false
  });

  const { t } = getI18N();

  const onCatalogueClick = useCallback(
    (catalogueId, catalogueTitle) => {
      onDuplicate(catalogueId, catalogueTitle);
    },
    [onDuplicate]
  );

  const getItemCount = () => {
    if (!cataloguesData || !Array.isArray(cataloguesData.catalogues)) {
      return 0;
    }
    let itemCount = cataloguesData.catalogues.length;
    if (!hasCatalogueProductLibPaginationCompleted()) {
      //For displaying loader at the bottom
      itemCount += 1;
    }
    return itemCount;
  };

  const loadMoreItems = useCallback(() => {
    if (isCatalogueProductLibPaginatedCallInProg() || hasCatalogueProductLibPaginationCompleted()) {
      return Promise.resolve();
    }

    return searchCataloguesFromLibrary({ forceLoad, activeCatalogueId });
  }, [forceLoad, activeCatalogueId]);

  const isItemLoaded = index => {
    if (hasCatalogueProductLibPaginationCompleted() || index < cataloguesData.catalogues.length) {
      return true;
    }
    return false;
  };

  const itemKey = useCallback((index, data) => {
    const catalogue = data[index];
    if (catalogue === undefined) {
      return `shimmer-${index}`;
    }
    return catalogue.id;
  }, []);

  const renderCatalogueRow = useCallback(
    ({ data, index, style }) => {
      const catalogueData = data[index];
      if (!catalogueData) {
        return <div>{t('loading')}</div>;
      }
      const { id, title, productsCount, productImages } = catalogueData;
      return (
        <div style={style} className="productListCopyCatalogueList catalogueRowContainer">
          <CatalogueOverlappingImages
            pictures={productImages.map(url => ({
              pictureId: getPictureIdFromImageUrl({ url }),
              url,
              prepared: true
            }))}
            overlapContainerClass="copyCatalogueImageContainer"
          />
          <div className="catalogueMetaContainer">
            <div className="catalogueTitle">{title}</div>
            <div className="catalogueProductCount ellipsis">{`${
              productsCount === 1
                ? t('selected_product', { selectedProductsCount: productsCount })
                : t('selected_products', { selectedProductsCount: productsCount })
            }`}</div>
          </div>
          <CopyButton onClick={() => onCatalogueClick(id, title)}>{t('copy_to')}</CopyButton>
        </div>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onCatalogueClick]
  );

  const renderCatalogueList = () => {
    if (error) {
      return (
        <div className="productListCopyCatalogueList placeholderContentContainer">
          <p className="errorText">{t('something_went_wrong')}</p>
        </div>
      );
    }

    if (loading) {
      return (
        <div className="productListCopyCatalogueList placeholderContentContainer">
          <Loader size="large" />
          <p>{t('loading')}</p>
        </div>
      );
    }

    if (
      !cataloguesData ||
      !Array.isArray(cataloguesData.catalogues) ||
      cataloguesData.catalogues.length === 0
    ) {
      return (
        <div className="productListCopyCatalogueList placeholderContentContainer">
          <div>{t('no_catalogues_to_display')}</div>
        </div>
      );
    }

    const listItemCount = getItemCount();
    return (
      <AutoSizer>
        {({ height, width }) => (
          <InfiniteLoader
            itemCount={listItemCount}
            isItemLoaded={isItemLoaded}
            loadMoreItems={loadMoreItems}
            threshold={CATALOGUE_PRODUCT_LIB_FIXED_LIST.nextPageThresholdRows}
          >
            {({ onItemsRendered, ref }) => (
              <List
                ref={ref}
                itemCount={listItemCount}
                onItemsRendered={onItemsRendered}
                height={height}
                width={width}
                itemData={cataloguesData.catalogues}
                itemKey={itemKey}
                itemSize={CATALOGUE_PRODUCT_LIB_FIXED_LIST.height}
                overscanCount={CATALOGUE_PRODUCT_LIB_FIXED_LIST.overscanCount}
              >
                {renderCatalogueRow}
              </List>
            )}
          </InfiniteLoader>
        )}
      </AutoSizer>
    );
  };

  return (
    <CopyToCatalogueListDialog open onClose={onClose}>
      {renderCatalogueList()}
    </CopyToCatalogueListDialog>
  );
};
