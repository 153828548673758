import React, { useState, useEffect, useCallback } from 'react';
import { ReactComponent as EditIcon } from 'qs-assets/Media/pencil.svg';
import {
  DialogDarkTheme,
  DarkThemeTitle,
  PrimaryButton,
  SecondaryButton
} from 'qs-components/Common/DarkThemeDialog';
import { DialogActions, DialogContent, TextField } from '@material-ui/core';
import Loader from 'qs-components/Common/Loader';
import { updateExistingOptionSetQuantity } from 'qs-data-manager/Variants/ProductOptions';
import toastr from 'toastr';
import { useInputStyles } from 'qs-hooks/useInputStyles';
import { ensureInputIsNumber } from 'qs-helpers';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

export default ({ setQuantity, optionType, activeProductId, optionId }) => {
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [quantityValue, setQuantityValue] = useState(setQuantity || '');
  const [quantityError, setQuantityError] = useState();
  const inputStyles = useInputStyles();
  const { t } = getI18N();

  const onEditClose = useCallback(event => {
    if (event) {
      event.preventDefault();
    }
    setShowEditPopup(false);
  }, []);

  useEffect(() => {
    setQuantityValue(setQuantity);
    setQuantityError();
  }, [setQuantity]);

  const handleSetQuantity = async event => {
    event.preventDefault();
    if (quantityValue === '' || Number.isNaN(Number(quantityValue))) {
      setQuantityError(t('please_enter_a_non_zero_number'));
      return;
    }
    onEditClose();
    setShowLoader(true);
    const optionData = {
      id: optionId,
      isSet: true,
      setQuantity: Number(quantityValue),
      optionType,
      parentProductId: activeProductId
    };

    try {
      await updateExistingOptionSetQuantity(optionData);
    } catch (updateError) {
      toastr.error(t('something_went_wrong_while_updating_the_quantity_of_the_set'));
    } finally {
      setShowLoader(false);
    }
  };

  const handleQuantityChange = event => {
    const value = ensureInputIsNumber({ event, integer: true });
    if (value === null) {
      return;
    }

    setQuantityValue(value);
    setQuantityError();
  };

  let onEditQuantityClick = null,
    containerClass = 'disabled';
  if (!showLoader) {
    onEditQuantityClick = () => setShowEditPopup(true);
    containerClass = 'clickable';
  }

  return (
    <>
      <div
        className={`optionSetQuantityDisplay ${containerClass}`}
        onClick={showEditPopup ? null : onEditQuantityClick}
      >
        <div className="setQuantityContainer">
          <span>{t('set_of_set_quantity', { setQuantity })}</span>
          {showLoader && (
            <div className="overlayLoader">
              <Loader size="extraSmall" />
            </div>
          )}
        </div>
        <EditIcon className={'editQuantity'} />
        {showEditPopup && (
          <DialogDarkTheme
            maxWidth="xs"
            open={showEditPopup}
            PaperProps={{
              component: 'form',
              onSubmit: handleSetQuantity,
              noValidate: true
            }}
          >
            <DarkThemeTitle>Edit set quantity</DarkThemeTitle>
            <DialogContent>
              <TextField
                InputProps={{
                  placeholder: t('enter_set_quantity'),
                  autoFocus: true,
                  classes: {
                    root: inputStyles.inputRoot,
                    underline: inputStyles.inputUnderline,
                    input: inputStyles.input
                  }
                }}
                FormHelperTextProps={{
                  classes: { root: inputStyles.inputError }
                }}
                error={typeof quantityError !== 'undefined'}
                helperText={quantityError}
                value={quantityValue}
                onChange={handleQuantityChange}
              />
            </DialogContent>
            <DialogActions>
              <SecondaryButton onClick={onEditClose}>
                <span>{t('cancel')}</span>
              </SecondaryButton>
              <PrimaryButton color={'primary'} onClick={handleSetQuantity} type="submit">
                <span>{t('update')}</span>
              </PrimaryButton>
            </DialogActions>
          </DialogDarkTheme>
        )}
      </div>
    </>
  );
};
