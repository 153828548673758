import React, { Fragment, useEffect } from 'react';
import SelectInput from 'qs-components/Common/SelectInput';
import CheckBox from '../../../../../Common/CheckBox';
import {
  UPDATE_CUSTOM_FIELDS_NUMERIC_SELECTION,
  UPDATE_SELECTED_CUSTOM_FIELDS
} from '../../../reducer';
import {
  BOOLEAN_FIELD,
  NUMBER_FIELD,
  STRING_FIELD
} from 'qs-helpers/CustomProductFields/constants';
import {
  getPlaceholderInputData,
  renderValueForSelect,
  keepArrayNoSelectionValueSameForSelect
} from 'qs-helpers/SelectInputHelpers';
import { isValidNumber } from 'qs-helpers';
import {
  CUSTOM_NUMERIC_FIELD_FILTER_OPTIONS,
  NUMERIC_FIELD_PRICE_FILTER_OPTIONS,
  PRICE_FILTER_KEYS_CONFIG
} from 'qs-helpers/Products/constants';
import { Button } from '@material-ui/core';
import FilterInput from '../FilterInput';
import { isRangeInputDataValid } from 'qs-helpers/Products/ProductsListFilterHelper';
import './styles.scss';
import { getI18N } from 'qs-services/i18N';

export default ({
  customFields,
  customFieldsSelection,
  customFieldsNumericOption,
  setProductListMeta,
  registerFilterCallback,
  unRegisterFilterCallback
}) => {
  const noSelectionValue = [];
  const { t } = getI18N();

  useEffect(() => {
    const applyNumericFieldFilter = ({
      currentCustomFieldsSelection: customFieldsSelection,
      customFieldsNumericOption: numericCustomFields
    }) => {
      //Check if any input is invalid
      return !Object.keys(customFieldsSelection).some(fieldId => {
        const { numericValue } = customFieldsSelection[fieldId];
        const selectedOptionId = numericCustomFields[fieldId];
        if (!numericValue || !selectedOptionId) {
          return false;
        }

        return !isRangeInputDataValid(selectedOptionId, numericValue, {
          [PRICE_FILTER_KEYS_CONFIG.RANGE.keyMap.gtKey]: { message: 'Min value is missing' },
          [PRICE_FILTER_KEYS_CONFIG.RANGE.keyMap.ltKey]: { message: 'Max value is missing' },
          [PRICE_FILTER_KEYS_CONFIG.RANGE.id]: { message: 'Max value must be more than min value' }
        });
      });
    };

    registerFilterCallback(applyNumericFieldFilter);

    return () => unRegisterFilterCallback(applyNumericFieldFilter);
  }, [registerFilterCallback, unRegisterFilterCallback]);

  const onStringFieldChange = fieldId => event => {
    setProductListMeta({
      type: UPDATE_SELECTED_CUSTOM_FIELDS,
      fieldId,
      fieldType: STRING_FIELD,
      value: event.target.value
    });
  };

  const onBoolFieldChange = fieldId => event => {
    setProductListMeta({
      type: UPDATE_SELECTED_CUSTOM_FIELDS,
      fieldId,
      fieldType: BOOLEAN_FIELD,
      value: event.target.checked
    });
  };

  const onNumberOptionFieldChange = fieldId => event => {
    setProductListMeta({
      type: UPDATE_CUSTOM_FIELDS_NUMERIC_SELECTION,
      fieldId,
      optionId: event.target.value
    });
  };

  const resetNumberOption = fieldId => event => {
    event.preventDefault();
    setProductListMeta({
      type: UPDATE_CUSTOM_FIELDS_NUMERIC_SELECTION,
      fieldId
    });
  };

  const onNumberFieldChange = fieldId => key => event => {
    const { value } = event.target;
    if (value !== '' && !isValidNumber(value)) {
      window.alert(t('please_enter_only_numbers'));
      return;
    }

    setProductListMeta({
      type: UPDATE_SELECTED_CUSTOM_FIELDS,
      fieldId,
      fieldType: NUMBER_FIELD,
      value,
      key
    });
  };

  const renderBoolFieldRow = ({ fieldId, fieldName }) => {
    const { booleanValue = false } = customFieldsSelection[fieldId] || {};
    return (
      <Fragment>
        <div className="optionLabel">{fieldName}</div>
        <CheckBox
          checked={booleanValue}
          onCheckChanged={onBoolFieldChange(fieldId)}
          styleProps={{
            uncheckedBgColor: 'transparent',
            border: '1px solid #59c18f',
            uncheckedHoverFill: '#4da47a'
          }}
        />
      </Fragment>
    );
  };

  const renderStringFieldRow = ({ stringPotentialValues, fieldId, fieldName }) => {
    const selectedValues = keepArrayNoSelectionValueSameForSelect(
      (customFieldsSelection[fieldId] || {}).stringValues,
      noSelectionValue
    );

    return (
      <Fragment>
        <div className="optionLabel">{fieldName}</div>
        <SelectInput
          containerClass="selectInput"
          displayEmpty
          noSelectionValue={noSelectionValue}
          onChange={onStringFieldChange(fieldId)}
          multiple={true}
          value={selectedValues}
          renderValue={selected =>
            renderValueForSelect(selected, { seperator: ', ', placeholder: t('select_value') })
          }
          options={stringPotentialValues.reduce(
            (cumulativeArray, value) => {
              cumulativeArray.push({
                value,
                displayValue: value
              });
              return cumulativeArray;
            },
            [getPlaceholderInputData({ displayValue: t('select_value') })]
          )}
          materialStyles={{
            backgroundColor: 'transparent',
            border: '1px solid #59c18f',
            unselectedBorder: '1px solid #59c18f',
            unselectTextColor: '#000',
            selectTextColor: '#000',
            formSelectionBorder: '1px solid #59c18f',
            unselectedHoverFill: '#4da47a',
            selectedHoverFill: '#4da47a',
            selectedItemColor: '#fff',
            selectedHoverColor: '#fff',
            unselectedHoverColor: '#fff',
            unselectedDropDownIconHoverColor: '#fff',
            selectedDropDownIconHoverColor: '#fff'
          }}
          menuStyles={{
            backgroundColor: '#fff',
            color: '#000'
          }}
        />
      </Fragment>
    );
  };

  const renderNumberFields = (fieldId, selectedOption) => {
    const { numericValue } = customFieldsSelection[fieldId] || {};
    return (
      <FilterInput
        priceFilterObj={numericValue || {}}
        selectedOption={selectedOption}
        inputConfig={NUMERIC_FIELD_PRICE_FILTER_OPTIONS}
        onChange={onNumberFieldChange(fieldId)}
        priceInputClassName="priceInputContainer"
        rangeInputClassName="priceInputContainer"
      />
    );
  };

  const renderAdditionalNumericField = ({ fieldId, fieldType }) => {
    const selectedOption = customFieldsNumericOption[fieldId];
    if (fieldType !== NUMBER_FIELD || !selectedOption) {
      return null;
    }

    return (
      <div className="customFieldRowContainer numericFieldContainer">
        {renderNumberFields(fieldId, selectedOption)}
        <Button color="primary" onClick={resetNumberOption(fieldId)}>
          {t('reset')}
        </Button>
      </div>
    );
  };

  const renderNumberFieldRow = ({ fieldId, fieldName }) => {
    const selectedValue = customFieldsNumericOption[fieldId];
    if (selectedValue) {
      return (
        <div className="optionLabel">
          {`${fieldName} ${t(NUMERIC_FIELD_PRICE_FILTER_OPTIONS[selectedValue].displayValue)}`}
        </div>
      );
    }

    return (
      <Fragment>
        <div className="optionLabel">{fieldName}</div>
        <SelectInput
          containerClass="selectInput"
          displayEmpty
          noSelectionValue={''}
          onChange={onNumberOptionFieldChange(fieldId)}
          value={selectedValue || ''}
          renderValue={selected =>
            renderValueForSelect(selected, { seperator: ', ', placeholder: t('select_value') })
          }
          options={[
            getPlaceholderInputData({ displayValue: t('select_value') }),
            ...CUSTOM_NUMERIC_FIELD_FILTER_OPTIONS.map(option => ({
              ...option,
              displayValue: t(option.displayValue)
            }))
          ]}
          materialStyles={{
            backgroundColor: 'transparent',
            border: '1px solid #59c18f',
            unselectedBorder: '1px solid #59c18f',
            unselectTextColor: '#000',
            selectTextColor: '#000',
            formSelectionBorder: '1px solid #59c18f',
            unselectedHoverFill: '#4da47a',
            selectedHoverFill: '#4da47a',
            selectedItemColor: '#fff',
            selectedHoverColor: '#fff',
            unselectedHoverColor: '#fff',
            unselectedDropDownIconHoverColor: '#fff',
            selectedDropDownIconHoverColor: '#fff'
          }}
          menuStyles={{
            backgroundColor: '#fff',
            color: '#000'
          }}
        />
      </Fragment>
    );
  };

  const renderCustomFieldsRowOnType = customFieldData => {
    const { fieldType } = customFieldData;
    switch (fieldType) {
      case NUMBER_FIELD:
        return renderNumberFieldRow(customFieldData);
      case BOOLEAN_FIELD:
        return renderBoolFieldRow(customFieldData);
      default:
        return renderStringFieldRow(customFieldData);
    }
  };

  if (Array.isArray(customFields) && customFields.length === 0) {
    return null;
  }

  return (
    <>
      <div className="productFieldFilterContainer titleContainer">{t('filter_by_field')}</div>
      <div className="productFieldFilterContainer customFieldsContainer">
        {customFields.map((customFieldData, index) => {
          return (
            <Fragment key={index}>
              <div className="customFieldRowContainer">
                {renderCustomFieldsRowOnType(customFieldData)}
              </div>
              {renderAdditionalNumericField(customFieldData)}
            </Fragment>
          );
        })}
      </div>
    </>
  );
};
