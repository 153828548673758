import React from 'react';
import './styles.scss';
import NumberInput from '../../../../../../Common/NumberInput';
import { JewelleryRateCharge } from '../JewelleryRateCharge';
import { getI18N } from 'qs-services/i18N';

export default ({
  originalGrossWeight,
  grossWeight,
  netWeight,
  onNetWeightChange,
  onGrossWeightChange,
  originalNetWeight,
  initialDisabled,
  isGrossWidgetChanged,
  isNetWeightChanged
}) => {
  const { t } = getI18N();
  return (
    <div className="goldWeightContainer">
      <JewelleryRateCharge
        initialDisabled={initialDisabled}
        showSaveButton={isGrossWidgetChanged}
        showChangeButton={!!originalGrossWeight && initialDisabled}
      >
        <div className="grossWeight">
          <div className="title">{t('gross_weight')}</div>
          <div className="inputContainer weightInputAndCurrency ">
            <NumberInput
              onChange={onGrossWeightChange}
              value={grossWeight}
              inputClassName="input setInput"
              digitsAfterDecimal={3}
              placeholder={t('type_gross_weight')}
            />
            <span className="symbol">gm</span>
          </div>
        </div>
      </JewelleryRateCharge>
      <JewelleryRateCharge
        initialDisabled={initialDisabled}
        showSaveButton={isNetWeightChanged}
        showChangeButton={originalNetWeight && initialDisabled}
      >
        <div className="netWeight">
          <div className="title">{t('net_weight')}</div>
          <div className="inputContainer weightInputAndCurrency">
            <NumberInput
              onChange={onNetWeightChange}
              value={netWeight}
              digitsAfterDecimal={3}
              inputClassName="input setInput"
              placeholder={t('type_net_weight')}
            />
            <span className="symbol">gm</span>
          </div>
        </div>
      </JewelleryRateCharge>
    </div>
  );
};
