let cleanupHandlers = [];

export const registerCleanupHandler = handler => {
  cleanupHandlers.push(handler);
};

export const unRegisterCleanupHandler = handler => {
  cleanupHandlers = cleanupHandlers.filter(existingHandler => existingHandler !== handler);
};

export const deleteSavedData = () => {
  cleanupHandlers.forEach(handler => {
    if (typeof handler === 'function') {
      handler();
      return;
    }
  });
};
