import {
  attachOrdersProductsListener,
  getOrdersProducts,
  removeOrdersProductsListener,
  getOrdersProductsFromCache,
  changeOrderProductStockCount,
  attachOrdersShippingListener,
  removeOrdersShippingListener,
  getOrdersShippingFromCache,
  getOrdersShippingInfo
} from 'qs-data-manager/Orders/OrdersProductsOperations';
import {
  getPaginatedCompanyOrders,
  attachCompanyOrdersListener,
  removeCompanyOrdersListener,
  getCompanyOrdersFromCache,
  attachCompanyOrderPdfSettingListener,
  getCompanyOrderPDFSettings,
  removeCompanyOrderPdfSettingListener,
  clearCachedQueryData,
  attachOrderMetaListener,
  removeOrderMetaListener,
  getOrderMetaFromCache,
  attachInventoryUpdateInfoListener,
  getInventoryUpdateInfo,
  removeInventoryUpdateInfoListener,
  attachCompanyUnreadInquiryListener,
  getCompanyUnreadInquiriesForStatus,
  removeCompanyUnreadInquiryListener
} from 'qs-data-manager/Orders/OrdersOperations';
import Mixpanel from 'qs-data-manager/Mixpanel';
import {
  getCompanyOrderPdfSettingsFromCache,
  getCompanyUnreadInquiryFromCache,
  getInventoryUpdateInfoFromCache
} from './CacheHelpers';

export const fetchOrderProductsHandler = ({ listener, changeDependancy: [orderId] }) => {
  if (!orderId) {
    return;
  }
  attachOrdersProductsListener({ listener, orderId });
  getOrdersProducts({ orderId });
};

export const handleOrderChange = ({ listener, changeDependancy: [orderId] }) => {
  if (!orderId) {
    return;
  }
  removeOrdersProductsListener({ listener, orderId });
};

export const getCachedOrderProductsData = ({ changeDependancy: [orderId] }) =>
  getOrdersProductsFromCache({ orderId });

export const handleOrderMetaFetch = ({ listener, changeDependancy: [orderId] }) => {
  if (!orderId) {
    return;
  }
  attachOrderMetaListener({ listener, orderId });
};

export const handleOrderMetaCleanup = ({ listener, changeDependancy: [orderId] }) => {
  if (!orderId) {
    return;
  }
  removeOrderMetaListener({ listener, orderId });
};

export const getCachedOrderMetaData = ({ changeDependancy: [orderId] }) =>
  getOrderMetaFromCache({ orderId });

export const getCachedCompanyOrdersList = ({ changeDependancy: [confirmed, sortKey] }) =>
  getCompanyOrdersFromCache({ sortKey, confirmed });

export const fetchCompanyOrdersHandler = ({
  listener,
  changeDependancy: [confirmed, sortKey, searchText]
}) => {
  clearCachedQueryData();
  attachCompanyOrdersListener({ listener, confirmed, sortKey });
  getPaginatedCompanyOrders({ sortKey, confirmed, searchText });
};

export const handleCompanyOrdersCleanup = ({
  listener,
  changeDependancy: [confirmed, sortKey]
}) => {
  removeCompanyOrdersListener({ listener, confirmed, sortKey });
};

export const handleStockCountChange = async ({ orderId, inquiryId, productId, stockCount }) => {
  Mixpanel.sendEvent({
    eventName: 'orders_set_product_stock',
    props: {
      orderId,
      inquiryId,
      productId,
      stockCount,
      SCREEN_NAME: 'INQURIES_SCREEN'
    }
  });
  await changeOrderProductStockCount({ orderId, productId, stockCount, inquiryId });
};

export const fetchOrderShippingHandler = ({ listener, changeDependancy: [orderId] }) => {
  if (!orderId) {
    return;
  }
  attachOrdersShippingListener({ listener, orderId });
  getOrdersShippingInfo({ orderId });
};

export const handleOrderShippingChange = ({ listener, changeDependancy: [orderId] }) => {
  if (!orderId) {
    return;
  }
  removeOrdersShippingListener({ listener, orderId });
};

export const getCachedOrderShippingData = ({ changeDependancy: [orderId] }) =>
  getOrdersShippingFromCache({ orderId });

export const handleFetchOrderPdfSettings = ({ listener }) => {
  attachCompanyOrderPdfSettingListener({ listener });
  if (getCompanyOrderPdfSettingsFromCache()) {
    return;
  }

  getCompanyOrderPDFSettings();
};

export const handleOrderPdfSettingsCleanup = ({ listener }) => {
  removeCompanyOrderPdfSettingListener({ listener });
};

export const getCachedOrderPdfSettingsData = () => getCompanyOrderPdfSettingsFromCache();

export const handleFetchOrderInventoryUpdate = ({ listener, changeDependancy: [orderId] }) => {
  attachInventoryUpdateInfoListener({ listener, orderId });
  getInventoryUpdateInfo({ orderId });
};

export const handleOrderInventoryUpdateCleanup = ({ listener }) => {
  removeInventoryUpdateInfoListener({ listener });
};

export const getCachedOrderInventoryUpdateData = ({ changeDependancy: [orderId] }) =>
  getInventoryUpdateInfoFromCache({ orderId });

export const handleFetchCompanyUnreadInquiries = ({
  listener,
  changeDependancy: [orderStatusFilter]
}) => {
  attachCompanyUnreadInquiryListener({ listener, orderStatusFilter });
  getCompanyUnreadInquiriesForStatus({ orderStatusFilter });
};

export const handleCompanyUnreadInquiriesCleanup = ({
  listener,
  changeDependancy: [orderStatusFilter]
}) => {
  removeCompanyUnreadInquiryListener({ listener, orderStatusFilter });
};

export const getCachedCompanyUnreadInquiries = ({ changeDependancy: [orderStatusFilter] }) =>
  getCompanyUnreadInquiryFromCache({ orderStatusFilter });
