import React, { useEffect, useState, memo } from 'react';
import ProductTitle from './ProductTitle';
import ProductDescription from './ProductDescription';
import ProductTags from './ProductTags';
import ProductPictures from './ProductPictures';
import ProductSku from './ProductSku';
import {
  getBasicInfo,
  computeBasicInfoFromProductRow,
  removeBasicInfoListener,
  attachBasicInfoListener,
  getBasicInfoFromRemote,
  getProductSetInfoFromCache
} from 'qs-data-manager/ProductDetails';
import { attachProductMetaListener, removeProductMetaListener } from 'qs-data-manager/Products';
import { videoThumbnailUrl } from '../../../../../Services/VideoUpload/UploadVideos/Api';
import CacheCallback from 'qs-helpers/CacheListenerCallback';
import { SAVE_BUTTON_META } from 'qs-helpers/Products/constants';
import './styles.scss';
import ProductLink from './ProductLink';
import { ProductPrices } from './ProductPrices';
import ProductPriceDiscountAndSet from './ProductPriceDiscountAndSet';
import { getI18N } from 'qs-services/i18N';

export default memo(
  ({ activeProductIds = [], isBulkEditing = '', activeProductId = '', scrollToTop } = {}) => {
    const [productDetails, setProductDetails] = useState({
      err: null,
      loading: false,
      refreshing: false,
      basicInfo: {
        tags: [],
        pictures: null,
        videos: null,
        ...getProductSetInfoFromCache({ productId: activeProductId })
      },
      productMetaFromRow: getBasicInfo({ activeProductId, isBulkEditing }) // for Title, price, discount, description,
    });

    const { t } = getI18N();

    // Effect to compute tags and pictures from remote
    useEffect(() => {
      const listener = (error, payload) => {
        const { err, loading, refreshing, data } = CacheCallback(error, payload);
        const updates = {};

        if (err) {
          updates.err = err;
          updates.loading = loading;
          return;
        }

        if (loading) {
          updates.loading = loading;
          updates.refreshing = refreshing;
          updates.err = err;
          setProductDetails(prevState => {
            return {
              ...prevState,
              ...updates
            };
          });
          return;
        }

        const { tags = {}, pictures, videos, isSet, setName, setQuantity, setType } = data || {};
        const pictureUrls = Object.values(pictures || {}).map(picture => ({
          id: picture.id,
          url: picture.url,
          prepared: picture.prepared,
          error: picture.error,
          position: picture.position || undefined
        }));

        const videoThumbnail = Object.values(videos || {}).map(
          video =>
            !!video.id && {
              id: video.id,
              url: videoThumbnailUrl(video.id),
              videoUrl: video.url,
              prepared: video.prepared,
              processed: video.processed,
              error: video.error
            }
        );

        let sortedTags = Object.values(tags)
          .sort((t1, t2) => {
            if (t1.v === t2.v) {
              return t1.p - t2.p;
            }

            return t2.v ? 1 : -1;
          })
          .map(tag => tag.title);

        setProductDetails(prevState => ({
          ...prevState,
          loading: false,
          basicInfo: {
            ...prevState.basicInfo,
            tags: sortedTags,
            pictures: [...pictureUrls, ...videoThumbnail],
            isSet,
            setName,
            setQuantity,
            setType
          }
        }));
      };

      if (!isBulkEditing) {
        attachBasicInfoListener({ listener, productId: activeProductId });
        getBasicInfoFromRemote({ productId: activeProductId });
      }

      return isBulkEditing
        ? () => {}
        : () => {
            removeBasicInfoListener({ listener, productId: activeProductId });
          };
    }, [activeProductId, isBulkEditing]);

    // Effect to compute name, price, discount, description data from product row
    useEffect(() => {
      const listener = (error, payload) => {
        const { err, data } = CacheCallback(error, payload);
        if (err) {
          return;
        }

        if (data) {
          const updates = computeBasicInfoFromProductRow(data);
          setProductDetails(prevState => ({
            ...prevState,
            productMetaFromRow: {
              ...prevState.productMetaFromRow,
              ...updates
            }
          }));
        }
      };

      if (!isBulkEditing) {
        attachProductMetaListener({ listener, productId: activeProductId });
      }
      const updates = getBasicInfo({ activeProductId, isBulkEditing });
      setProductDetails(prevState => ({
        ...prevState,
        productMetaFromRow: {
          ...prevState.productMetaFromRow,
          ...updates
        }
      }));

      return isBulkEditing
        ? () => {}
        : () => removeProductMetaListener({ listener, productId: activeProductId });
    }, [activeProductId, isBulkEditing]);

    const areProductPicturesReady = () => {
      return Array.isArray(basicInfo.pictures);
    };

    const { basicInfo, productMetaFromRow } = productDetails;

    const priceAndDiscountRenderer = () => {
      return (
        <ProductPriceDiscountAndSet
          currencySymbol={productMetaFromRow.currencySymbol}
          price={productMetaFromRow.price}
          discount={productMetaFromRow.discount}
          isSet={basicInfo.isSet}
          setName={basicInfo.setName}
          setQuantity={basicInfo.setQuantity}
          setType={basicInfo.setType}
          activeProductId={activeProductId}
          activeProductIds={activeProductIds}
          isBulkEditing={isBulkEditing}
          loading={productDetails.loading}
        />
      );
    };

    const renderProductPriceAndDiscount = () => {
      return (
        <ProductPrices
          productId={activeProductId}
          activeProductIds={activeProductIds}
          isBulkEditing={isBulkEditing}
          productDetails={productDetails}
          priceAndDiscountRenderer={priceAndDiscountRenderer}
        />
      );
    };

    const renderBasicInfo = () => {
      return (
        <>
          <ProductTitle
            value={productMetaFromRow.title}
            activeProductId={activeProductId}
            isBulkEditing={isBulkEditing}
            activeProductIds={activeProductIds}
            fieldId={SAVE_BUTTON_META.PRODUCT_TITLE.id}
            rowTitle={t('title')}
            placeholder={t('type_design_number_or_product_title')}
          />
          <ProductSku
            value={productMetaFromRow.sku}
            activeProductId={activeProductId}
            isBulkEditing={isBulkEditing}
            fieldId={SAVE_BUTTON_META.PRODUCT_SKU.id}
            rowTitle={'SKU'}
            placeholder={t('type_sku')}
          />
          {renderProductPriceAndDiscount()}
          <ProductDescription
            description={productMetaFromRow.description}
            activeProductId={activeProductId}
            activeProductIds={activeProductIds}
            isBulkEditing={isBulkEditing}
          />
          <ProductTags
            loading={productDetails.loading}
            tags={basicInfo.tags}
            activeProductId={activeProductId}
            activeProductIds={activeProductIds}
            isBulkEditing={isBulkEditing}
          />
        </>
      );
    };

    return (
      <>
        {areProductPicturesReady() ? (
          isBulkEditing ? null : (
            <ProductPictures
              scrollToTop={scrollToTop}
              loading={productDetails.loading}
              pictures={basicInfo.pictures}
              activeProductId={activeProductId}
            />
          )
        ) : null}
        {isBulkEditing ? null : <ProductLink activeProductId={activeProductId} />}
        <div id={'BasicInfo'}>{renderBasicInfo()}</div>
      </>
    );
  }
);
