import React, { useState, Fragment, useCallback } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  withStyles
} from '@material-ui/core';
import {
  CATALOGUE_COPY_DIALOG_WIDTH,
  CATALOGUE_COPY_OPTIONS,
  CATALOGUE_COPY_OPTIONS_MAP
} from 'qs-helpers/Catalogues/constants';
import { ReactComponent as Arrow } from 'qs-assets/Media/icon_long_arrow_right.svg';
import CatalogueListForCopy from '../CatalogueListForCopy';
import Loader from '../../../../../Common/Loader';
import { selectedProducts } from 'qs-data-manager/Selected';
import ErrorPopup from '../../../../../Common/ErrorPopup';
import { duplicateProductsToCatalogue } from 'qs-data-manager/Catalogues/DuplicateProducts';
import { PrimaryButton } from '../../../../../Common/DarkThemeDialog';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

const CatalogueCopyDialog = withStyles({
  paper: {
    width: '100%',
    maxWidth: CATALOGUE_COPY_DIALOG_WIDTH
  }
})(Dialog);

const CatalogueCopyTitle = withStyles({
  root: {
    paddingBottom: 0
  }
})(DialogTitle);

export default ({ onClose, onComplete, catalogueTitle, activeCatalogueId }) => {
  const [showCatalogueList, setShowCatalogueList] = useState(false);
  const [showDuplicatingLoader, setShowDuplicatingLoader] = useState(false);
  const [duplicatingError, setDuplicatingError] = useState();
  const [confirmationData, setShowConfirmation] = useState();

  const { t } = getI18N();

  const onCloseCopyList = useCallback(() => {
    setShowCatalogueList(false);
  }, []);

  const onCloseConfirmation = useCallback(() => {
    setShowConfirmation();
  }, []);

  const duplicateSelectedProductsToCatalogue = useCallback(
    async catalogueId => {
      onCloseCopyList();
      onCloseConfirmation();
      setShowDuplicatingLoader(true);
      try {
        await duplicateProductsToCatalogue({
          productIds: Array.from(selectedProducts.getAllPreservedSelections()),
          catalogueId,
          catalogueTitle
        });
        onComplete();
      } catch (duplicateError) {
        setDuplicatingError({
          subject: `Failed to duplicate products to ${catalogueId ? catalogueId : 'new catalogue'}`,
          body: duplicateError
        });
      } finally {
        setShowDuplicatingLoader(false);
      }
    },
    [onCloseCopyList, onCloseConfirmation, setShowDuplicatingLoader, onComplete, catalogueTitle]
  );

  const handleOptionClick = (event, id) => {
    event.preventDefault();
    if (id === CATALOGUE_COPY_OPTIONS_MAP.EXISTING_CATALOGUE.id) {
      setShowCatalogueList(true);
      return;
    }

    setShowConfirmation({});
  };

  const onCopyToCatalogue = useCallback((catalogueId, catalogueTitle) => {
    setShowConfirmation({ savedCatalogueId: catalogueId, savedCatalogueTitle: catalogueTitle });
  }, []);

  const onConfirm = () => {
    if (!confirmationData) {
      setDuplicatingError({
        subject: `Failed to duplicate products`,
        body: new Error('Internal error')
      });
      return;
    }

    const { savedCatalogueId } = confirmationData;
    duplicateSelectedProductsToCatalogue(savedCatalogueId);
  };

  const renderCopyOptionDialogButtons = ({ id, buttonText }) => (
    <button
      key={id || buttonText}
      className="copyProductsCatalogueOptions actionButton"
      onClick={event => handleOptionClick(event, id)}
    >
      <div className="buttonText">{t(buttonText)}</div>
      <Arrow className={'rightArrow'} />
    </button>
  );

  const getConfirmationDialogContentAndTitle = () => {
    const selectedProductsCount = selectedProducts.getTotalPreservedSelectionCount();
    const selectedProductsText = `${
      selectedProductsCount === 1
        ? t('selected_product', { selectedProductsCount })
        : t('selected_products', { selectedProductsCount })
    }`;
    const { savedCatalogueId, savedCatalogueTitle } = confirmationData;
    if (savedCatalogueId) {
      return (
        <>
          <DialogTitle>{t('copy_selected_text', { selectedProductsText })}</DialogTitle>
          <DialogContent>
            {t('are_you_sure_you_want_to_copy_selected_product_to_catalogue', {
              product: selectedProductsText,
              catalogue: savedCatalogueTitle
            })}
          </DialogContent>
        </>
      );
    }

    return (
      <>
        <DialogTitle>{t('create_new_catalogue')}</DialogTitle>
        <DialogContent>
          {t('do_you_want_to_create_a_new_catalogue_with_selected_product', {
            product: selectedProductsText
          })}
        </DialogContent>
      </>
    );
  };

  return (
    <Fragment>
      <CatalogueCopyDialog onClose={onClose} open>
        <CatalogueCopyTitle>{t('export_products')}</CatalogueCopyTitle>
        {CATALOGUE_COPY_OPTIONS.map(optionsData => renderCopyOptionDialogButtons(optionsData))}
      </CatalogueCopyDialog>
      {showCatalogueList && (
        <CatalogueListForCopy
          activeCatalogueId={activeCatalogueId}
          onClose={onCloseCopyList}
          onDuplicate={onCopyToCatalogue}
        />
      )}
      {confirmationData && (
        <Dialog open={true}>
          {getConfirmationDialogContentAndTitle()}
          <DialogActions>
            <Button onClick={onCloseConfirmation}>{t('cancel')}</Button>
            <PrimaryButton color={'primary'} onClick={onConfirm}>
              {t('yes')}
            </PrimaryButton>
          </DialogActions>
        </Dialog>
      )}
      {showDuplicatingLoader && (
        <Dialog open={true}>
          <div className="copyProductsCatalogueOptions duplicatingLoader">
            <Loader size="large" />
            <p className="duplicatingLoaderText">{t('duplicating_products')}</p>
          </div>
        </Dialog>
      )}
      {duplicatingError && (
        <ErrorPopup
          onClose={onClose}
          mailProps={duplicatingError}
          title={t('duplicate_selected_products')}
        >
          <span>{t('something_went_wrong_while_duplicating_products')}</span>
        </ErrorPopup>
      )}
    </Fragment>
  );
};
