import ProductListTileIcon from 'qs-assets/Media/product_list_tile_icon.svg';
import { GRAPES_JS_PLUGINS } from '../Plugins';
import { GRAPES_JS_DEFAULT_CONFIG } from '../Plugins/config';
import { capitalizeFirstLetter } from 'qs-helpers';
import { getI18N } from 'qs-services/i18N';
import {
  ACCENT_COLOR,
  WHITE_COLOR,
  BLACK_COLOR,
  TEXT_COLOR,
  BORDER_COLOR,
  PRODUCT_LIST_TILE_ELEMENT_TYPES,
  PRODUCT_LIST_TILE_VARIABLES_MAP,
  PRODUCT_LIST_TILE_CLASSNAMES_MAP,
  PRODUCT_LIST_TILE_CLASSNAMES_PREFIX
} from '../Constants';

export const PRODUCT_LIST_TILE_ELEMENT_TYPE_TRAIT_ID = `data-${PRODUCT_LIST_TILE_CLASSNAMES_PREFIX}-element-type`;

export const PRODUCT_LIST_TILE_ELEMENT_TYPE_TRAIT = {
  get CONFIG() {
    const { t } = getI18N();
    return {
      type: 'select',
      label: t('product_list_tile_element_type'),
      name: PRODUCT_LIST_TILE_ELEMENT_TYPE_TRAIT_ID,
      placeholder: t('enter_product_list_tile_element_type'),
      default: 'none',
      options: [{
          id: 'none',
          name: t('none')
        },
        ...PRODUCT_LIST_TILE_ELEMENT_TYPES.CONFIG
      ]
    };
  }
};

export const PRODUCT_LIST_TILE_CUSTOM_COMPONENT = {
  get HTML() {
    const { t } = getI18N();
    return `
      <div
        class="${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileContainer} gjs-row"
        data-${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileId}="${PRODUCT_LIST_TILE_VARIABLES_MAP.productId}"
        data-quicksell-component-custom-name="${t('product_list_tile')}"
        data-quicksell-component-type="${PRODUCT_LIST_TILE_CLASSNAMES_PREFIX}"
        data-quicksell-component-editable="false"
        data-quicksell-component-removable="false"
        data-quicksell-component-copyable="false"
        data-quicksell-component-draggable="false"
      >
        <div
          class="${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileImageContainer} gjs-cell"
          data-${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileImageContainer}="${PRODUCT_LIST_TILE_VARIABLES_MAP.productId}"
          data-quicksell-component-type="default"
          data-quicksell-component-editable="false"
          data-quicksell-component-removable="false"
          data-quicksell-component-copyable="false"
          data-quicksell-component-draggable="false"
        >
          <div
            class="${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileDiscountBadge}"
            data-${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileDiscountBadge}="${PRODUCT_LIST_TILE_VARIABLES_MAP.productId}"
            data-${PRODUCT_LIST_TILE_CLASSNAMES_PREFIX}-element-type="${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileDiscountBadge}"
            data-quicksell-component-type="text"
            data-quicksell-component-editable="false"
            data-quicksell-component-removable="false"
            data-quicksell-component-copyable="false"
            data-quicksell-component-draggable="false"
          >
            ${PRODUCT_LIST_TILE_VARIABLES_MAP.discountPercent}
          </div>
          <img
            class="${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileImage}"
            data-${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileImage}="${PRODUCT_LIST_TILE_VARIABLES_MAP.productId}"
            data-${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileImageId}="${PRODUCT_LIST_TILE_VARIABLES_MAP.pictureId}"
            data-${PRODUCT_LIST_TILE_CLASSNAMES_PREFIX}-element-type="${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileImage}"
            src="${PRODUCT_LIST_TILE_VARIABLES_MAP.pictureUrl}"
            alt="${PRODUCT_LIST_TILE_VARIABLES_MAP.pictureAlt}"
            decoding="sync"
            loading="lazy"
            height="${PRODUCT_LIST_TILE_VARIABLES_MAP.pictureHeight}"
            data-${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileImageHeight}="${PRODUCT_LIST_TILE_VARIABLES_MAP.pictureHeight}"
            width="${PRODUCT_LIST_TILE_VARIABLES_MAP.pictureWidth}"
            data-${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileImageWidth}="${PRODUCT_LIST_TILE_VARIABLES_MAP.pictureWidth}"
            data-quicksell-component-type="image"
            data-quicksell-component-editable="false"
            data-quicksell-component-removable="false"
            data-quicksell-component-copyable="false"
            data-quicksell-component-draggable="false"
          />
        </div>
        <div
          class="${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileDetailsContainer} gjs-cell"
          data-${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileDetailsContainer}="${PRODUCT_LIST_TILE_VARIABLES_MAP.productId}"
          data-quicksell-component-type="default"
          data-quicksell-component-editable="false"
          data-quicksell-component-removable="false"
          data-quicksell-component-copyable="false"
          data-quicksell-component-draggable="false"
        >
          <div
            class="${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileName}"
            data-${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileName}="${PRODUCT_LIST_TILE_VARIABLES_MAP.productId}"
            data-${PRODUCT_LIST_TILE_CLASSNAMES_PREFIX}-element-type="${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileName}"
            data-quicksell-component-type="text"
            data-quicksell-component-editable="false"
            data-quicksell-component-removable="false"
            data-quicksell-component-copyable="false"
            data-quicksell-component-draggable="false"
          >
            ${PRODUCT_LIST_TILE_VARIABLES_MAP.productName}
          </div>
          <div
            class="${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileOutOfStockBadge}"
            data-${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileOutOfStockBadge}="${PRODUCT_LIST_TILE_VARIABLES_MAP.productId}"
            data-${PRODUCT_LIST_TILE_CLASSNAMES_PREFIX}-element-type="${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileOutOfStockBadge}"
            data-quicksell-component-type="text"
            data-quicksell-component-editable="false"
            data-quicksell-component-removable="false"
            data-quicksell-component-copyable="false"
            data-quicksell-component-draggable="false"
          >
            ${PRODUCT_LIST_TILE_VARIABLES_MAP.outOfStockText}
          </div>
          <p
            class="${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileDescription}"
            data-${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileDescription}="${PRODUCT_LIST_TILE_VARIABLES_MAP.productId}"
            data-${PRODUCT_LIST_TILE_CLASSNAMES_PREFIX}-element-type="${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileDescription}"
            data-quicksell-component-type="text"
            data-quicksell-component-editable="false"
            data-quicksell-component-removable="false"
            data-quicksell-component-copyable="false"
            data-quicksell-component-draggable="false"
          >${PRODUCT_LIST_TILE_VARIABLES_MAP.productDescription}</p>
          <div
            class="${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileVariantDetailsContainer}"
            data-${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileVariantDetailsContainer}="${PRODUCT_LIST_TILE_VARIABLES_MAP.productId}"
            data-quicksell-component-type="default"
            data-quicksell-component-editable="false"
            data-quicksell-component-removable="false"
            data-quicksell-component-copyable="false"
            data-quicksell-component-draggable="false"
          >
            <div
              class="${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileVariantColors}"
              data-${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileVariantColors}="${PRODUCT_LIST_TILE_VARIABLES_MAP.productId}"
              data-quicksell-component-type="default"
              data-quicksell-component-editable="false"
              data-quicksell-component-removable="false"
              data-quicksell-component-copyable="false"
              data-quicksell-component-draggable="false"
            >
              <div
                class="${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileVariantColor}"
                data-${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileVariantColor}="${PRODUCT_LIST_TILE_VARIABLES_MAP.productId}"
                data-quicksell-component-type="default"
                data-quicksell-component-editable="false"
                data-quicksell-component-removable="false"
                data-quicksell-component-copyable="false"
                data-quicksell-component-draggable="false"
              >
                &nbsp;
              </div>
              <div
                class="${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileVariantColor}"
                data-${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileVariantColor}="${PRODUCT_LIST_TILE_VARIABLES_MAP.productId}"
                data-quicksell-component-type="default"
                data-quicksell-component-editable="false"
                data-quicksell-component-removable="false"
                data-quicksell-component-copyable="false"
                data-quicksell-component-draggable="false"
              >
                &nbsp;
              </div>
              <div
                class="${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileVariantColor}"
                data-${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileVariantColor}="${PRODUCT_LIST_TILE_VARIABLES_MAP.productId}"
                data-quicksell-component-type="default"
                data-quicksell-component-editable="false"
                data-quicksell-component-removable="false"
                data-quicksell-component-copyable="false"
                data-quicksell-component-draggable="false"
              >
                &nbsp;
              </div>
            </div>
            <div
              class="${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileVariantColorCount}"
              data-${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileVariantColorCount}="${PRODUCT_LIST_TILE_VARIABLES_MAP.productId}"
              data-${PRODUCT_LIST_TILE_CLASSNAMES_PREFIX}-element-type="${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileVariantColorCount}"
              data-quicksell-component-type="text"
              data-quicksell-component-editable="false"
              data-quicksell-component-removable="false"
              data-quicksell-component-copyable="false"
              data-quicksell-component-draggable="false"
            >
              ${PRODUCT_LIST_TILE_VARIABLES_MAP.variantColorCount}
            </div>
          </div>
          <div
            class="${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTilePriceAddToCartContainer}"
            data-${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTilePriceAddToCartContainer}="${PRODUCT_LIST_TILE_VARIABLES_MAP.productId}"
            data-quicksell-component-type="default"
            data-quicksell-component-editable="false"
            data-quicksell-component-removable="false"
            data-quicksell-component-copyable="false"
            data-quicksell-component-draggable="false"
          >
            <div
              class="${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTilePriceDiscountContainer}"
              data-${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTilePriceDiscountContainer}="${PRODUCT_LIST_TILE_VARIABLES_MAP.productId}"
              data-quicksell-component-type="default"
              data-quicksell-component-editable="false"
              data-quicksell-component-removable="false"
              data-quicksell-component-copyable="false"
              data-quicksell-component-draggable="false"
            >
              <div
                class="${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTilePriceContainer}"
                data-${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTilePriceContainer}="${PRODUCT_LIST_TILE_VARIABLES_MAP.productId}"
                data-quicksell-component-type="default"
                data-quicksell-component-editable="false"
                data-quicksell-component-removable="false"
                data-quicksell-component-copyable="false"
                data-quicksell-component-draggable="false"
              >
                <div
                  class="${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTilePrice}"
                  data-${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTilePrice}="${PRODUCT_LIST_TILE_VARIABLES_MAP.productId}"
                  data-${PRODUCT_LIST_TILE_CLASSNAMES_PREFIX}-element-type="${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTilePrice}"
                  data-quicksell-component-type="text"
                  data-quicksell-component-editable="false"
                  data-quicksell-component-removable="false"
                  data-quicksell-component-copyable="false"
                  data-quicksell-component-draggable="false"
                >
                  ${PRODUCT_LIST_TILE_VARIABLES_MAP.productPrice}
                </div>
                <div
                  class="${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTilePriceDiscount}"
                  data-${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTilePriceDiscount}="${PRODUCT_LIST_TILE_VARIABLES_MAP.productId}"
                  data-${PRODUCT_LIST_TILE_CLASSNAMES_PREFIX}-element-type="${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTilePriceDiscount}"
                  data-quicksell-component-type="text"
                  data-quicksell-component-editable="false"
                  data-quicksell-component-removable="false"
                  data-quicksell-component-copyable="false"
                  data-quicksell-component-draggable="false"
                >
                  ${PRODUCT_LIST_TILE_VARIABLES_MAP.productDiscountedPrice}
                </div>
              </div>
              <div
                class="${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTilePriceTax}"
                data-${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTilePriceTax}="${PRODUCT_LIST_TILE_VARIABLES_MAP.productId}"
                data-${PRODUCT_LIST_TILE_CLASSNAMES_PREFIX}-element-type="${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTilePriceTax}"
                data-quicksell-component-type="text"
                data-quicksell-component-editable="false"
                data-quicksell-component-removable="false"
                data-quicksell-component-copyable="false"
                data-quicksell-component-draggable="false"
              >
                ${PRODUCT_LIST_TILE_VARIABLES_MAP.productTaxPrice}
              </div>
            </div>
            <div
              class="${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileAddToCartContainer} gjs-cell"
              data-${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileAddToCartContainer}="${PRODUCT_LIST_TILE_VARIABLES_MAP.productId}"
              data-quicksell-component-type="default"
              data-quicksell-component-editable="false"
              data-quicksell-component-removable="false"
              data-quicksell-component-copyable="false"
              data-quicksell-component-draggable="false"
            >
              <div
                class="${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileAddToCartLoader}"
                data-${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileAddToCartLoader}="${PRODUCT_LIST_TILE_VARIABLES_MAP.productId}"
                data-quicksell-component-type="text"
                data-quicksell-component-editable="false"
                data-quicksell-component-removable="false"
                data-quicksell-component-copyable="false"
                data-quicksell-component-draggable="false"
                data-quicksell-component-layerable="false"
              >
                <svg
                  class="${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileAddToCartLoaderSvg}"
                  data-${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileAddToCartLoaderSvg}="${PRODUCT_LIST_TILE_VARIABLES_MAP.productId}"
                  viewBox="0 0 38 38"
                  height="24"
                  width="24"
                  stroke="${WHITE_COLOR}"
                  data-quicksell-component-custom-name="${capitalizeFirstLetter(t('svg'))}"
                  data-quicksell-component-type="svg"
                  data-quicksell-component-editable="false"
                  data-quicksell-component-removable="false"
                  data-quicksell-component-copyable="false"
                  data-quicksell-component-draggable="false"
                  data-quicksell-component-layerable="false"
                >
                  <g data-quicksell-component-type="svg-in" fill="none" fill-rule="evenodd">
                    <g data-quicksell-component-type="svg-in" transform="translate(1 1)" stroke-width="2">
                      <circle data-quicksell-component-type="svg-in" stroke-opacity=".5" cx="18" cy="18" r="18"/>
                      <path data-quicksell-component-type="svg-in" d="M36 18c0-9.94-8.06-18-18-18">
                      </path>
                    </g>
                  </g>
                </svg>
              </div>
              <div
                class="${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileAddToCart}"
                data-${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileAddToCart}="${PRODUCT_LIST_TILE_VARIABLES_MAP.productId}"
                data-${PRODUCT_LIST_TILE_CLASSNAMES_PREFIX}-element-type="${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileAddToCart}"
                data-quicksell-component-type="text"
                data-quicksell-component-editable="false"
                data-quicksell-component-removable="false"
                data-quicksell-component-copyable="false"
                data-quicksell-component-draggable="false"
              >
                ${PRODUCT_LIST_TILE_VARIABLES_MAP.addToCartButtonText}
              </div>
              <div
                class="${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileInquiryContainer} gjs-cell"
                data-${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileInquiryContainer}="${PRODUCT_LIST_TILE_VARIABLES_MAP.productId}"
                data-quicksell-component-type="default"
                data-quicksell-component-editable="false"
                data-quicksell-component-removable="false"
                data-quicksell-component-copyable="false"
                data-quicksell-component-draggable="false"
                data-quicksell-component-selectable="false"
                data-quicksell-component-layerable="false"
              >
                <div
                  class="${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileInquiryMinus}"
                  data-${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileInquiryMinus}="${PRODUCT_LIST_TILE_VARIABLES_MAP.productId}"
                  data-quicksell-component-type="default"
                  data-quicksell-component-editable="false"
                  data-quicksell-component-removable="false"
                  data-quicksell-component-copyable="false"
                  data-quicksell-component-draggable="false"
                  data-quicksell-component-selectable="false"
                  data-quicksell-component-layerable="false"
                >
                  <div
                    class="${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileInquiryMinusText}"
                    data-${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileInquiryMinusText}="${PRODUCT_LIST_TILE_VARIABLES_MAP.productId}"
                    data-quicksell-component-type="text"
                    data-quicksell-component-editable="false"
                    data-quicksell-component-removable="false"
                    data-quicksell-component-copyable="false"
                    data-quicksell-component-draggable="false"
                    data-quicksell-component-selectable="false"
                    data-quicksell-component-layerable="false"
                  >
                    -
                  </div>
                </div>
                <div
                  class="${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileInquiryCount}"
                  data-${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileInquiryCount}="${PRODUCT_LIST_TILE_VARIABLES_MAP.productId}"
                  data-quicksell-component-type="default"
                  data-quicksell-component-editable="false"
                  data-quicksell-component-removable="false"
                  data-quicksell-component-copyable="false"
                  data-quicksell-component-draggable="false"
                  data-quicksell-component-selectable="false"
                  data-quicksell-component-layerable="false"
                >
                  <div
                    class="${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileInquiryCountText}"
                    data-${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileInquiryCountText}="${PRODUCT_LIST_TILE_VARIABLES_MAP.productId}"
                    data-quicksell-component-type="text"
                    data-quicksell-component-editable="false"
                    data-quicksell-component-removable="false"
                    data-quicksell-component-copyable="false"
                    data-quicksell-component-draggable="false"
                    data-quicksell-component-selectable="false"
                    data-quicksell-component-layerable="false"
                  >
                    1
                  </div>
                </div>
                <div
                  class="${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileInquiryPlus}"
                  data-${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileInquiryPlus}="${PRODUCT_LIST_TILE_VARIABLES_MAP.productId}"
                  data-quicksell-component-type="default"
                  data-quicksell-component-editable="false"
                  data-quicksell-component-removable="false"
                  data-quicksell-component-copyable="false"
                  data-quicksell-component-draggable="false"
                  data-quicksell-component-selectable="false"
                  data-quicksell-component-layerable="false"
                >
                  <div
                    class="${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileInquiryPlusText}"
                    data-${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileInquiryPlusText}="${PRODUCT_LIST_TILE_VARIABLES_MAP.productId}"
                    data-quicksell-component-type="text"
                    data-quicksell-component-editable="false"
                    data-quicksell-component-removable="false"
                    data-quicksell-component-copyable="false"
                    data-quicksell-component-draggable="false"
                    data-quicksell-component-selectable="false"
                    data-quicksell-component-layerable="false"
                  >
                    +
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    `;
  },
  get STYLE() {
    return `
      html,
      body {
        height: 100%;
        min-height: 100%;
        max-height: 100%;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        padding: 0px 0px 0px 0px;
        margin: 0px 0px 0px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }

      .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileContainer} {
        height: 100%;
        min-height: 0px;
        max-height: 100%;
        width: 100%;
        min-width: 0px;
        max-width: 100%;
        margin: 0px 0px 0px 0px;
        padding: 12px 12px 12px 12px;
        background-repeat: repeat;
        background-position: left top;
        background-attachment: scroll;
        background-size: auto;
        background-color: ${WHITE_COLOR};
        border-width: 1px 1px 1px 1px;
        border-style: solid;
        border-color: ${BORDER_COLOR};
        border-radius: 5px 5px 5px 5px;
        box-shadow: 0px 0px 10px 1px rgba(26, 32, 39, 0.1);
        display: flex;
        justify-content: stretch;
        align-items: stretch;
        flex-wrap: nowrap;
        flex-direction: row;
        overflow-x: hidden;
        overflow-y: hidden;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        text-decoration: none;
      }

      .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileContainer}:hover .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileImageContainer} .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileImage} {
        transform: scale(1.05);
      }

      .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileImageContainer} {
        height: 100%;
        min-height: 100%;
        max-height: 100%;
        width: 96px;
        min-width: 96px;
        max-width: 96px;
        padding: 0px 0px 0px 0px;
        margin: 0px 0px 0px 0px;
        top: 0px;
        right: 0px;
        left: 0px;
        bottom: 0px;
        border-radius: 0px 0px 0px 0px;
        border-width: 0px 0px 0px 0px;
        border-style: solid;
        border-color: ${BORDER_COLOR};
        color: ${TEXT_COLOR};
        cursor: pointer;
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: auto;
        align-self: center;
        position: relative;
        transition: all 0.2s ease-in-out;
      }

      .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileDiscountBadge} {
        height: auto;
        min-height: auto;
        max-height: auto;
        width: auto;
        min-width: auto;
        max-width: 96px;
        background-repeat: repeat;
        background-position: left top;
        background-attachment: scroll;
        background-size: auto;
        background-color: ${ACCENT_COLOR};
        color: ${WHITE_COLOR};
        border-radius: 5px 5px 5px 5px;
        font-size: 12px;
        font-weight: 400;
        padding: 3px 8px 3px 8px;
        position: absolute;
        bottom: -5px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 3;
        overflow-x: hidden;
        overflow-y: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-decoration: none;
      }

      .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileDiscountBadge}:empty {
        display: none;
      }

      .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileImage} {
        height: 100%;
        min-height: 100%;
        max-height: 144px;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        color: ${TEXT_COLOR};
        object-fit: cover;
        border-radius: 5px 5px 5px 5px;
        display: inline-block;
        cursor: pointer;
        overflow-x: hidden;
        overflow-y: hidden;
        vertical-align: middle;
        transition: all 0.2s ease-in-out;
        text-decoration: none;
      }

      .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileDetailsContainer} {
        height: auto;
        min-height: auto;
        max-height: 100%;
        width: calc(100% - 96px);
        min-width: calc(100% - 96px);
        max-width: calc(100% - 96px);
        padding: 0px 0px 0px 12px;
        margin: 0px 0px 0px 0px;
        border-radius: 0px 0px 0px 0px;
        border-width: 0px 0px 0px 0px;
        border-style: solid;
        border-color: ${BORDER_COLOR};
        color: ${TEXT_COLOR};
        font-weight: 400;
        font-size: 13px;
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: auto;
        cursor: pointer;
        background-repeat: repeat;
        background-position: left top;
        background-attachment: scroll;
        background-size: auto;
        background-color: ${WHITE_COLOR};
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        align-items: flex-start;
        transition: all 0.2s ease-in-out;
        text-decoration: none;
      }

      .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileDetailsContainer}:empty  {
        display: none;
      }

      .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileName} {
        height: auto;
        min-height: auto;
        max-height: auto;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        margin: 0px 0px 0px 0px;
        color: ${TEXT_COLOR};
        font-size: 16px;
        font-weight: 500;
        text-align: left;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        display: -webkit-box;
        line-height: 1.2;
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: auto;
        overflow-x: hidden;
        overflow-y: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        text-decoration: none;
        word-wrap: break-word;
        word-break: break-word;
      }

      .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileName}:empty {
        display: none;
      }

      .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileOutOfStockBadge} {
        height: auto;
        min-height: auto;
        max-height: auto;
        width: auto;
        min-width: auto;
        max-width: 100%;
        background-repeat: repeat;
        background-position: left top;
        background-attachment: scroll;
        background-size: auto;
        background-color: rgba(255, 0, 0, 0.8);
        color: ${WHITE_COLOR};
        border-radius: 5px 5px 5px 5px;
        font-size: 12px;
        font-weight: 400;
        margin: 5px 0px 0px 0px;
        padding: 2px 4px 2px 4px;
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: auto;
        overflow-x: hidden;
        overflow-y: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-decoration: none;
      }

      .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileOutOfStockBadge}:empty {
        display: none;
      }

      .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileDescription} {
        height: auto;
        min-height: auto;
        max-height: auto;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        margin: 7.5px 0px 0px 0px;
        color: ${TEXT_COLOR};
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        display: -webkit-box;
        line-height: 1.2;
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: auto;
        overflow-x: hidden;
        overflow-y: hidden;
        text-overflow: ellipsis;
        white-space: pre-wrap;
        text-decoration: none;
        word-wrap: break-word;
        word-break: break-word;
      }

      .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileDescription}:empty {
        display: none;
      }

      .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileVariantDetailsContainer} {
        height: auto;
        min-height: auto;
        max-height: auto;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        margin: 5px 0px 0px 0px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: auto;
        overflow-x: hidden;
        overflow-y: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-decoration: none;
      }

      .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileVariantDetailsContainer}:empty  {
        display: none;
      }

      .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileVariantColors} {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 0px 6px 0px 0px;
        text-decoration: none;
      }

      .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileVariantColors}:empty  {
        display: none;
      }

      .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileVariantColor} {
        height: 12px;
        min-height: 12px;
        max-height: 12px;
        width: 12px;
        min-width: 12px;
        max-width: 12px;
        background-repeat: repeat;
        background-position: left top;
        background-attachment: scroll;
        background-size: auto;
        background-color: ${WHITE_COLOR};
        border-width: 1px 1px 1px 1px;
        border-style: solid;
        border-color: ${WHITE_COLOR};
        border-radius: 50%;
        box-shadow: 0px 0px 0px 1px rgba(26, 32, 39, 0.1);
        text-decoration: none;
      }

      .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileVariantColor}:nth-child(1) {
        margin-left: 0px;
        background-repeat: repeat;
        background-position: left top;
        background-attachment: scroll;
        background-size: auto;
        background-color: #f74246;
      }

      .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileVariantColor}:nth-child(2) {
        margin-left: -6px;
        background-repeat: repeat;
        background-position: left top;
        background-attachment: scroll;
        background-size: auto;
        background-color: #f88c49;
      }

      .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileVariantColor}:nth-child(3) {
        margin-left: -6px;
        background-repeat: repeat;
        background-position: left top;
        background-attachment: scroll;
        background-size: auto;
        background-color: #f9d34f;
      }

      .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileVariantColorCount} {
        color: ${TEXT_COLOR};
        font-size: 14px;
        font-weight: 600;
        text-align: left;
        overflow-x: hidden;
        overflow-y: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-decoration: none;
      }

      .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileVariantColorCount}:empty {
        display: none;
      }

      .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTilePriceAddToCartContainer} {
        height: auto;
        min-height: auto;
        max-height: auto;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        flex-wrap: nowrap;
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: auto;
      }

      .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTilePriceAddToCartContainer}:empty {
        display: none;
      }

      .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTilePriceDiscountContainer} {
        height: auto;
        min-height: auto;
        max-height: auto;
        width: calc(100% - 96px);
        min-width: calc(100% - 96px);
        max-width: calc(100% - 96px);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: nowrap;
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: auto;
        margin: 0px 0px 0px 0px;
        padding: 0px 12px 0px 0px;
      }

      .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTilePriceDiscountContainer}:empty {
        display: none;
      }

      .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTilePriceContainer} {
        height: auto;
        min-height: auto;
        max-height: auto;
        width: auto;
        min-width: auto;
        max-width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTilePriceContainer}:empty {
        display: none;
      }

      .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTilePrice} {
        height: auto;
        min-height: auto;
        max-height: auto;
        width: auto;
        min-width: auto;
        max-width: 100%;
        margin: 5px 0px 0px 0px;
        padding: 0px 0px 0px 0px;
        color: ${TEXT_COLOR};
        font-size: 16px;
        font-weight: 600;
        text-align: left;
        word-wrap: break-word;
        word-break: break-word;
      }

      .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTilePrice}:empty {
        display: none;
      }

      .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTilePriceDiscount} {
        height: auto;
        min-height: auto;
        max-height: auto;
        width: auto;
        min-width: auto;
        max-width: 100%;
        color: ${TEXT_COLOR};
        font-size: 14px;
        font-weight: 300;
        text-align: left;
        margin: 5px 0px 0px 5px;
        padding: 0px 0px 0px 0px;
        text-decoration: line-through;
        word-wrap: break-word;
        word-break: break-word;
      }

      .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTilePriceDiscount}:empty {
        display: none;
      }

      .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTilePriceTax} {
        height: auto;
        min-height: auto;
        max-height: auto;
        width: auto;
        min-width: auto;
        max-width: 100%;
        margin: 5px 0px 0px 0px;
        padding: 0px 0px 0px 0px;
        color: ${TEXT_COLOR};
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        display: -webkit-box;
        line-height: 1.2;
        overflow-x: hidden;
        overflow-y: hidden;
        text-decoration: none;
        word-wrap: break-word;
        word-break: break-word;
      }

      .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTilePriceTax}:empty {
        display: none;
      }
      
      .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileAddToCartContainer} {
        height: 30px;
        min-height: 30px;
        max-height: 30px;
        width: auto;
        min-width: 96px;
        max-width: 96px;
        padding: 0px 0px 0px 0px;
        margin: 7.5px 0px 0px 0px;
        border-radius: 5px 5px 5px 5px;
        border-width: 0px 0px 0px 0px;
        border-style: solid;
        border-color: ${BORDER_COLOR};
        outline-width: 0px;
        outline-style: solid;
        outline-color: ${BORDER_COLOR};
        box-shadow: 0px 0px 0px 0px ${BLACK_COLOR};
        background-repeat: repeat;
        background-position: left top;
        background-attachment: scroll;
        background-size: auto;
        background-color: ${ACCENT_COLOR};
        display: flex;
        justify-content: center;
        align-items: center;
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: auto;
        text-align: center;
        text-transform: uppercase;
        font-weight: 400;
        font-size: 14px;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
      }

      .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileAddToCartLoader} {
        height: 30px;
        min-height: auto;
        max-height: 100%;
        width: 30px;
        min-width: auto;
        max-width: 100%;
        aspect-ratio: 1 / 1;
        display: none;
        align-items: center;
        justify-content: center;
      }

      .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileAddToCartLoaderSvg} {
        height: 100%;
        min-height: 100%;
        max-height: 100%;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        padding: 0px 6px 0px 6px;
        margin: 0px 0px 0px 0px;
        animation: rotating 1s linear infinite;
      }

      .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileAddToCart} {
        height: 100%;
        min-height: 100%;
        max-height: 100%;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        padding: 0px 6px 0px 6px;
        margin: 0px 0px 0px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${WHITE_COLOR};
        text-align: center;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 14px;
        overflow-x: hidden;
        overflow-y: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-decoration: none;
      }

      .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileAddToCart}:empty {
        display: none;
      }

      .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileInquiryContainer} {
        height: 100%;
        min-height: 100%;
        max-height: 100%;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        padding: 0px 0px 0px 0px;
        margin: 0px 0px 0px 0px;
        border-radius: 5px 5px 5px 5px;
        border-width: 1px 1px 1px 1px;
        border-style: solid;
        border-color: ${BORDER_COLOR};
        display: none;
        justify-content: center;
        align-items: center;
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: auto;
        text-align: center;
        text-transform: uppercase;
        font-weight: 400;
        font-size: 14px;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
      }

      .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileInquiryMinus} {
        height: 100%;
        min-height: 100%;
        max-height: 100%;
        width: calc(100% / 3);
        min-width: calc(100% / 3);
        max-width: calc(100% / 3);
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-radius: 4px 0px 0px 4px;
        flex: 3 1 auto;
        overflow-x: hidden;
        overflow-y: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        background-repeat: repeat;
        background-position: left top;
        background-attachment: scroll;
        background-size: auto;
        background-color: rgb(234, 239, 247);
        border-right-width: 1px;
        border-right-style: solid;
        border-right-color: ${BORDER_COLOR};
        transition: all 0.2s ease-in-out;
      }

      .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileInquiryMinusText} {
        height: 100%;
        min-height: 100%;
        max-height: 100%;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        color: ${BLACK_COLOR};
        text-align: center;
        text-transform: uppercase;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        text-decoration: none;
      }

      .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileInquiryCount} {
        height: 100%;
        min-height: 100%;
        max-height: 100%;
        width: calc(100% / 3);
        min-width: calc(100% / 3);
        max-width: calc(100% / 3);
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 3 1 auto;
        overflow-x: hidden;
        overflow-y: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        background-repeat: repeat;
        background-position: left top;
        background-attachment: scroll;
        background-size: auto;
        background-color: ${WHITE_COLOR};
        transition: all 0.2s ease-in-out;
      }

      .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileInquiryCountText} {
        height: 100%;
        min-height: 100%;
        max-height: 100%;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        color: ${BLACK_COLOR};
        text-align: center;
        text-transform: uppercase;
        font-weight: 400;
        font-size: 14px;
        line-height: 30px;
        text-decoration: none;
      }

      .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileInquiryPlus} {
        height: 100%;
        min-height: 100%;
        max-height: 100%;
        width: calc(100% / 3);
        min-width: calc(100% / 3);
        max-width: calc(100% / 3);
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex: 3 1 auto;
        border-radius: 0px 4px 4px 0px;
        overflow-x: hidden;
        overflow-y: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        background-repeat: repeat;
        background-position: left top;
        background-attachment: scroll;
        background-size: auto;
        background-color: ${ACCENT_COLOR};
        border-left-width: 1px;
        border-left-style: solid;
        border-left-color: ${BORDER_COLOR};
        transition: all 0.2s ease-in-out;
      }

      .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileInquiryPlusText} {
        height: 100%;
        min-height: 100%;
        max-height: 100%;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        color: ${WHITE_COLOR};
        text-align: center;
        text-transform: uppercase;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        text-decoration: none;
      }

      @keyframes rotating {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
    `;
  }
};

export const PRODUCT_LIST_TILE_GRAPES_JS_CONFIG = {
  get CONFIG() {
    return {
      ...GRAPES_JS_DEFAULT_CONFIG.COMMON,
      container: '.custom-html-builder-editor',
      plugins: [...GRAPES_JS_PLUGINS, ...(GRAPES_JS_DEFAULT_CONFIG.COMMON.plugins || [])]
    };
  }
};

export const getProductListTypeThemeStyles = (theme) => {
  const defaultTheme = (!!theme && Object.keys(theme).length > 0) ? theme : {};
  const defaultTextColor = defaultTheme.pageTextColor || defaultTheme.textColor || TEXT_COLOR;
  const tileTextColor = (defaultTheme.homeGrid || {}).tileTextColor || TEXT_COLOR;
  const tileBackgroundColor = (defaultTheme.homeGrid || {}).tileBackgroundColor || WHITE_COLOR;
  const defaultBorderColor = (defaultTheme.homepage || {}).productTileBorderColorCode || BORDER_COLOR;
  const discountBarBackgroundColor = defaultTheme.discountBarBackgroundColor || WHITE_COLOR;
  const tileDiscountTextColor = (defaultTheme.homeGrid || {}).tileDiscountTextColor || ACCENT_COLOR;
  const addToCartButtonBackgroundColor = (defaultTheme.addToCartButton || {}).backgroundColor || ACCENT_COLOR;
  const addToCartButtonTextColor = (defaultTheme.addToCartButton || {}).textColor || WHITE_COLOR;
  return `
    .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileContainer} {
      color: ${defaultTextColor};
      border-color: ${defaultBorderColor};
      background-color: ${tileBackgroundColor};
    }
    .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileInquiryContainer} {
      border-color: ${defaultBorderColor};
    }
    .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileInquiryMinus} {
      border-right-color: ${defaultBorderColor};
    }
    .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileInquiryPlus} {
      border-left-color: ${defaultBorderColor};
    }
    .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileImageContainer} {
      color: ${defaultTextColor};
      background-color: ${tileBackgroundColor};
    }
    .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileImage} {
      color: ${defaultTextColor};
    }
    .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileDiscountBadge} {
      background-color: ${discountBarBackgroundColor};
      color: ${tileDiscountTextColor};
    }
    .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileDiscountBadge} {
      color: ${addToCartButtonTextColor};
    }
    .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileDetailsContainer} {
      color: ${tileTextColor};
      background-color: ${tileBackgroundColor};
    }
    .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileName} {
      color: ${tileTextColor};
    }
    .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileDescription} {
      color: ${tileTextColor};
    }
    .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileVariantColorCount} {
      color: ${tileTextColor};
    }
    .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTilePrice} {
      color: ${tileTextColor};
    }
    .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTilePriceDiscount} {
      color: ${tileTextColor};
    }
    .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTilePriceTax} {
      color: ${tileTextColor};
    }
    .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileAddToCartLoaderSvg} {
      stroke: ${addToCartButtonTextColor};
    }
    .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileAddToCartContainer} {
      background-color: ${addToCartButtonBackgroundColor};
    }
    .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileAddToCart} {
      color: ${addToCartButtonTextColor};
    }
    .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileInquiryMinusText} {
      color: ${BLACK_COLOR};
    }
    .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileInquiryCountText} {
      color: ${BLACK_COLOR};
    }
    .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileInquiryPlus} {
      background-color: ${addToCartButtonBackgroundColor};
    }
    .${PRODUCT_LIST_TILE_CLASSNAMES_MAP.productListTileInquiryPlusText} {
      color: ${addToCartButtonTextColor};
    }
  `;
};

export const getProductListTileBlockConfig = (productListTileBlockContent) => {
  const { t } = getI18N();
  return {
    id: PRODUCT_LIST_TILE_CLASSNAMES_PREFIX,
    type: PRODUCT_LIST_TILE_CLASSNAMES_PREFIX,
    label: t('product_list_tile'),
    activate: true,
    media: `<img src="${ProductListTileIcon}" height="52" width="52" style="height: 52px; width: 52px; object-fit: cover;" />`,
    category: t('quicksell'),
    copyable: productListTileBlockContent.copyable,
    'custom-name': productListTileBlockContent['custom-name'],
    draggable: productListTileBlockContent.draggable,
    editable: productListTileBlockContent.editable,
    removable: productListTileBlockContent.removable,
    tagName: productListTileBlockContent.tagName,
    attributes: productListTileBlockContent.attributes,
    content: {
      classes: productListTileBlockContent.classes,
      components: productListTileBlockContent.components
    }
  };
};

export const getProductListTileBlockComponent = (productListTileBlockContent) => ({
  model: {
    defaults: {
      copyable: productListTileBlockContent.copyable,
      'custom-name': productListTileBlockContent['custom-name'],
      draggable: productListTileBlockContent.draggable,
      editable: productListTileBlockContent.editable,
      removable: productListTileBlockContent.removable,
      tagName: productListTileBlockContent.tagName,
      attributes: productListTileBlockContent.attributes
    }
  }
});
