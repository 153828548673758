import { toggleGlobalLoader } from '.';

export const setGlobalLoader = ({ prevLoader, currLoader }) => {
  //Previous value was not global loader, show the loader
  if (prevLoader !== currLoader) {
    const uniqueLoaderKey = `refreshingData${Date.now()}`;
    toggleGlobalLoader(uniqueLoaderKey, true);
    return uniqueLoaderKey;
  }
};

export const unsetGlobalLoader = ({ refreshing, uniqueLoaderKey }) => {
  if (refreshing && uniqueLoaderKey) {
    toggleGlobalLoader(uniqueLoaderKey, false);
  }
};
