import React from 'react';
import ClickHandlerWithLoaderContainer from '../../../../Common/ClickHandlerWithLoader/ClickHandlerWithLoaderContainer';
import SettingsRow from '../../../Common/SettingsRow/SettingsRow';
import CustomFrontPageIcon from '../../../../Common/CustomFrontPageIcon';
import { getI18N } from 'qs-services/i18N';

export default ({ onSelected, settingComponentId, classForContainer }) => {
  const { t } = getI18N();

  return (
    <ClickHandlerWithLoaderContainer
      clickProps={{
        clickHandler: onSelected,
        clickParams: settingComponentId
      }}
      type="button"
      classForContainer={classForContainer}
    >
      <SettingsRow Icon={CustomFrontPageIcon} title={t('custom_front_page')}>
        <span>{t('design_your_front_page')}</span>
      </SettingsRow>
    </ClickHandlerWithLoaderContainer>
  );
};
