import React, { useCallback } from 'react';
import { COLUMN_DEFS, defaultColumnProps } from '../CellConfigurations';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { PRODUCT_LIBRARY_GRID } from 'qs-helpers/ProductLibrary/constants';
import { getI18N } from 'qs-services/i18N';

export default ({
  productsData,
  handleRowSelection,
  handleRowChange,
  getRowNodeId,
  containerClass,
  disableMultiple = false
}) => {
  COLUMN_DEFS[0].headerCheckboxSelection = disableMultiple ? false : true;
  COLUMN_DEFS[0].checkboxSelection = true;

  const { t } = getI18N();

  const rowDataChangeEvent = useCallback(
    event => {
      const { api } = event || {};
      handleRowChange(api);
    },
    [handleRowChange]
  );

  return (
    <div style={{ height: '100%', width: '100%' }} className={containerClass}>
      <AgGridReact
        modules={[ClientSideRowModelModule]}
        columnDefs={COLUMN_DEFS.map(column => ({ ...column, headerName: t(column.headerName) }))}
        defaultColDef={defaultColumnProps}
        rowHeight={PRODUCT_LIBRARY_GRID.rowHeight}
        headerHeight={PRODUCT_LIBRARY_GRID.headerHeight}
        rowBuffer={PRODUCT_LIBRARY_GRID.rowsBufferedForDisplay}
        rowData={productsData.products}
        rowMultiSelectWithClick={true}
        rowSelection={disableMultiple ? 'single' : 'multiple'}
        onRowSelected={handleRowSelection}
        getRowNodeId={getRowNodeId}
        onRowDataChanged={rowDataChangeEvent}
      />
    </div>
  );
};
