import React, { useState, useCallback, useEffect } from 'react';
import CatalogueLib, { ACTIVE_CATALOGUE_META_TITLE } from 'qs-services/DataManager/Catalogues';
import { isEnterKey } from 'qs-services/Helpers/index';
import { ReactComponent as EditIcon } from 'qs-assets/Media/pencil.svg';
import { ReactComponent as CloseIcon } from 'qs-assets/Media/close.svg';
import eventingBus from 'eventing-bus';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

export default ({ title: catalogueTitle, catalogueEditMode, id }) => {
  const [editMode, setEditMode] = useState(false);
  const [title, setTitle] = useState(catalogueTitle || '');
  const [editModeTitle, setEditModeTitle] = useState(catalogueTitle);
  const [saveButtonVisibility, setSaveButtonVisibility] = useState(false);

  const { t } = getI18N();

  useEffect(() => {
    const removeEventbus = eventingBus.on(ACTIVE_CATALOGUE_META_TITLE.eventbusKey(id), title => {
      if (!title) {
        setEditMode(false);
        setTitle(catalogueTitle || '');
        return;
      }
      setEditMode(true);
      setTitle(title);
    });

    return () => removeEventbus();
  }, [id, catalogueTitle]);

  useEffect(() => {
    setTitle(catalogueTitle || '');
  }, [catalogueTitle]);

  if (catalogueEditMode && editMode) {
    setEditMode(false);
  }

  const closeEditMode = useCallback(
    e => {
      e.stopPropagation();
      setEditMode(false);
      setSaveButtonVisibility(false);
      setEditModeTitle(title);
    },
    [title]
  );

  const openEditMode = useCallback(
    e => {
      e.stopPropagation();
      setEditMode(true);
      setEditModeTitle(title);
    },
    [title]
  );

  const saveTitleChange = useCallback(
    e => {
      if (e) {
        e.stopPropagation();
      }
      let titleToSave = editModeTitle.trim();
      if (titleToSave === '') {
        return;
      }
      setEditMode(false);
      setSaveButtonVisibility(false);
      setTitle(titleToSave);
      CatalogueLib.changeCatalogueTitle({
        title: titleToSave,
        id
      });
    },
    [editModeTitle, id]
  );

  const changeEditModeTitle = e => {
    e.stopPropagation();
    const value = e.target.value;
    let visibility = true;
    if (value === catalogueTitle || value.trim() === '') {
      visibility = false;
    }

    setEditModeTitle(value);
    setSaveButtonVisibility(visibility);
  };

  const onKeyPress = useCallback(
    e => {
      e.stopPropagation();
      const isEnter = isEnterKey(e);
      if (isEnter) {
        saveTitleChange();
      }
    },
    [saveTitleChange]
  );

  if (editMode) {
    return (
      <div className="commonTitleContainer catalogueEditTitle">
        <input
          autoFocus
          value={editModeTitle}
          onChange={changeEditModeTitle}
          placeholder={t('enter_catalogue_title')}
          onKeyPress={onKeyPress}
        />
        <div className="closeIconContainer">
          <CloseIcon className="closeIcon" onClick={closeEditMode} />
        </div>
        {saveButtonVisibility && !!editModeTitle.length && (
          <div className="saveButton" onClick={saveTitleChange}>
            {t('save')}
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="commonTitleContainer titleContainer">
      <div className="title catalogueTitle">{title}</div>
      {!catalogueEditMode && <EditIcon onClick={openEditMode} className="titleEditIcon" />}
    </div>
  );
};
