const LAZY_COMPONENTS = {
  VIDEO_HLS: 'VIDEO_HLS'
};

export const VIDEO_ERROR_CODES = {
  INTERNAL_CODE_ERROR: 1000,
  HLS_UNSUPPORTED: 1001,
  HLS_MODULE_LOAD: 1002,
  HLS_VIDEO_LOAD: 1003,
  HLS_UNHANDLED: 1004,
  HLS_INTERNAL_VIDEO_ERROR: 1005
};

let lazyRouteComponents = {};

const setLazyComponent = (key, component) => {
  lazyRouteComponents[key] = component;
};

const getLazyComponent = key => lazyRouteComponents[key];

const isVideoPlaybackPossible = ({ videoRef, videoSrcs }) => {
  return Array.isArray(videoSrcs) && videoSrcs.length >= 0 && videoRef;
};

const isAutoPlayBlocked = error => {
  const { name, code } = error;

  // Indicates that auto play is not supported, gracefully return
  if (name === 'NotSupportedError' || code === 9) {
    return true;
  }
  return false;
};

export const handleLoadNativeBrowserVideo = ({ videoRef, videoSrcs }) =>
  new Promise((resolve, reject) => {
    if (!isVideoPlaybackPossible({ videoRef, videoSrcs })) {
      reject({
        code: VIDEO_ERROR_CODES.INTERNAL_CODE_ERROR
      });
      return;
    }

    const onComplete = () => {
      resolve();
      clearHandlers();
    };

    const handleError = ({ code, browserError }) => {
      reject({ code });
      clearHandlers();

      if (browserError) {
        // logException(new Error('Failed to load video using native HLS'), {
        //   error: browserError,
        //   srcs: videoSrcs
        // });
      }
    };

    let videoIndex = 0;
    const loadVideoSource = () => {
      const currentVideoSrc = videoSrcs[videoIndex];
      if (!currentVideoSrc) {
        return;
      }

      videoIndex += 1;
      videoRef.src = currentVideoSrc;
    };

    const processDataLoaded = () => {
      Promise.resolve(videoRef.play()).catch(playError => {
        if (isAutoPlayBlocked(playError)) {
          onComplete();
          return;
        }

        handleError({
          code: VIDEO_ERROR_CODES.HLS_INTERNAL_VIDEO_ERROR,
          browserError: playError
        });
      });
    };

    const errorHandler = () => {
      if (videoIndex < videoSrcs.length) {
        loadVideoSource();
        return;
      }

      let errorCode = VIDEO_ERROR_CODES.HLS_INTERNAL_VIDEO_ERROR;
      let browserError = videoRef.error;
      if (videoRef.error.code === 1 || videoRef.error.code === 2) {
        errorCode = VIDEO_ERROR_CODES.HLS_VIDEO_LOAD;
        browserError = null;
      }

      handleError({ code: errorCode, browserError });
    };

    const onPlay = () => {
      onComplete();
    };

    const clearHandlers = () => {
      // Push removal to the end of the queue
      setTimeout(() => {
        videoRef.removeEventListener('play', onPlay);
        videoRef.removeEventListener('error', errorHandler);
        videoRef.removeEventListener('canplay', processDataLoaded);
      }, 0);
    };

    videoRef.addEventListener('error', errorHandler);
    videoRef.addEventListener('canplay', processDataLoaded);
    videoRef.addEventListener('play', onPlay);
    loadVideoSource();
  });

export const browserSupportsHLS = videoRef => {
  if (!videoRef) {
    return false;
  }
  return videoRef.canPlayType('application/vnd.apple.mpegurl');
};

export const handleLoadHlsLibVideo = ({ numRetries, videoSrcs, videoRef }) =>
  new Promise((resolve, reject) => {
    const Hls = getLazyComponent(LAZY_COMPONENTS.VIDEO_HLS);
    if (!Hls || !isVideoPlaybackPossible({ videoRef, videoSrcs })) {
      reject({
        code: VIDEO_ERROR_CODES.INTERNAL_CODE_ERROR
      });
      return;
    }

    // Below variables will be used for retry of media error if it needs to be handled
    /* let mediaRetries = numRetries;
    let quickMediaFailure = false;
    let mediaErrorTimer = null; */
    let videoIndex = 0;

    const hls = new Hls({
      manifestLoadingMaxRetry: numRetries,
      manifestLoadingRetryDelay: 5000
    });

    const loadVideoSrc = () => {
      const currentVideoSrc = videoSrcs[videoIndex];
      if (!currentVideoSrc) {
        return;
      }

      videoIndex += 1;
      hls.loadSource(currentVideoSrc);
    };

    const cleanupHlsResources = () => {
      hls.destroy();
    };

    const retriesExhaustedForSrc = ({ code, libError }) => {
      if (videoIndex < videoSrcs.length) {
        /* mediaRetries = numRetries;
        quickMediaFailure = false;
        clearTimeout(mediaErrorTimer);
        mediaErrorTimer = null; */

        loadVideoSrc();
        return;
      }

      videoRef.removeEventListener('play', playHandler);
      cleanupHlsResources();
      reject({ code });
      if (libError) {
        // logException(new Error('Failed to load video using HLS lib'), {
        //   code: libError,
        //   srcs: videoSrcs
        // });
      }
    };

    const processHlsErrorEvent = (_, data) => {
      if (!data.fatal) {
        return;
      }
      switch (data.type) {
        case Hls.ErrorTypes.NETWORK_ERROR:
          retriesExhaustedForSrc({ code: VIDEO_ERROR_CODES.HLS_VIDEO_LOAD });
          break;
        // Leaving below as commented so that if media error needs to be handled then
        // the below code can come handy
        /* case Hls.ErrorTypes.MEDIA_ERROR:
          if (mediaRetries === 0) {
            retriesExhaustedForSrc({ code: VIDEO_ERROR_CODES.HLS_INTERNAL_VIDEO_ERROR });
            return;
          }

          if (quickMediaFailure) {
            hls.swapAudioCodec();
          }

          hls.recoverMediaError();
          quickMediaFailure = true;
          clearTimeout(mediaErrorTimer);
          mediaErrorTimer = setTimeout(() => {
            quickMediaFailure = false;
          }, 200);
          mediaRetries--;
          break; */
        default:
          retriesExhaustedForSrc({ code: VIDEO_ERROR_CODES.HLS_UNHANDLED, libError: data });
          break;
      }
    };

    const processMediaAttached = () => {
      loadVideoSrc();
    };

    const processManifestParsed = () => {
      Promise.resolve(videoRef.play()).catch(playError => {
        if (isAutoPlayBlocked(playError)) {
          videoRef.removeEventListener('play', playHandler);
          resolve({
            onDone: cleanupHlsResources
          });
          return;
        }

        retriesExhaustedForSrc({
          code: VIDEO_ERROR_CODES.HLS_INTERNAL_VIDEO_ERROR,
          libError: playError
        });
      });
    };

    const playHandler = () => {
      // For safety unsubscribe from these events as they are no longer needed
      // once video starts playing
      hls.off(Hls.Events.ERROR, processHlsErrorEvent);
      hls.off(Hls.Events.MEDIA_ATTACHED, processMediaAttached);
      hls.off(Hls.Events.MANIFEST_PARSED, processManifestParsed);

      resolve({
        onDone: cleanupHlsResources
      });

      // Push removal to the end of the queue. Once listener to resolve
      setTimeout(() => {
        videoRef.removeEventListener('play', playHandler);
      }, 1);
    };

    videoRef.addEventListener('play', playHandler);
    hls.on(Hls.Events.ERROR, processHlsErrorEvent);
    hls.on(Hls.Events.MEDIA_ATTACHED, processMediaAttached);
    hls.on(Hls.Events.MANIFEST_PARSED, processManifestParsed);
    hls.attachMedia(videoRef);
  });

const attachToVideo = ({ videoRef, videoSrcs, numRetries = 1 }) =>
  new Promise((resolve, reject) => {
    const Hls = getLazyComponent(LAZY_COMPONENTS.VIDEO_HLS);
    if (!Hls) {
      reject({
        code: VIDEO_ERROR_CODES.INTERNAL_CODE_ERROR
      });
      return;
    }

    if (!Hls.isSupported()) {
      if (browserSupportsHLS(videoRef)) {
        handleLoadNativeBrowserVideo({ videoRef, videoSrcs })
          .then(resolve)
          .catch(reject);
        return;
      }

      reject({
        code: VIDEO_ERROR_CODES.HLS_UNSUPPORTED
      });
      return;
    }

    handleLoadHlsLibVideo({ numRetries, videoSrcs, videoRef })
      .then(resolve)
      .catch(reject);
  });

export const attachHLSMediaToVideo = async ({ videoRef, videoSrcs }) => {
  if (!isVideoPlaybackPossible({ videoRef, videoSrcs })) {
    return Promise.reject({
      code: VIDEO_ERROR_CODES.INTERNAL_CODE_ERROR
    });
  }

  let Hls = getLazyComponent(LAZY_COMPONENTS.VIDEO_HLS);
  if (!Hls) {
    return import(/* webpackChunkName: "hlsvideo" */ /* webpackPrefetch: true */ 'hls.js')
      .then(({ default: Hls }) => {
        setLazyComponent(LAZY_COMPONENTS.VIDEO_HLS, Hls);
      })
      .catch(() =>
        Promise.reject({
          code: VIDEO_ERROR_CODES.HLS_MODULE_LOAD
        })
      )
      .then(() => attachToVideo({ videoRef, videoSrcs }));
  }

  return attachToVideo({ videoRef, videoSrcs });
};
