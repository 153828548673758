import React, { useState, useRef, useEffect, Fragment } from 'react';
import eventbus from 'eventing-bus';
import SettingsDataRow from '../../Common/SettingsDataRow/SettingsDataRow';
import './LanguageSettings.scss';
import Modal from 'react-responsive-modal';
import CacheListenerCallback from 'qs-helpers/CacheListenerCallback';
import {
  setCatalogueLanguage,
  setAppLanguage,
  attachCompanySettingsListener,
  removeCompanySettingsListener,
  getCompanySettingsFromCache,
  COMPANY_LOCAL_STORAGE_KEYS
} from 'qs-data-manager/Company';
import Loader from '../../../Common/Loader';
import {
  getConfigFromCache,
  attachConfigsListener,
  removeConfigsListener
} from 'qs-data-manager/Config';
import toastr from 'toastr';
import ClickHandlerWithLoaderContainer from '../../../Common/ClickHandlerWithLoader/ClickHandlerWithLoaderContainer';
import Mixpanel from 'qs-data-manager/Mixpanel';
import {
  extractCompanyLanguage,
  extractAppLanguage
} from 'qs-helpers/CompanySettings/DataExtracters';
import {
  CATALOGUE_LANGUAGUES,
  APP_LANGUAGUES,
  APP_LANGUAGE_CHANGED_EB_KEY
} from 'qs-helpers/CompanySettings/constants';
import { getI18N } from 'qs-services/i18N';

export default () => {
  const currentlySetCatalogueLanguage = extractCompanyLanguage(getCompanySettingsFromCache());
  const currentlySetAppLanguage = extractAppLanguage();
  const [catalogueDisplayLanguage, setCatalogueDisplayLanguage] = useState(
    currentlySetCatalogueLanguage
  );
  const [appDisplayLanguage, setAppDisplayLanguage] = useState(currentlySetAppLanguage);
  const [currentCatalogueLanguage, setCurrentCatalogueLanguage] = useState(
    currentlySetCatalogueLanguage
  );
  const [currentAppLanguage, setCurrentAppLanguage] = useState(currentlySetAppLanguage);
  const [helpTranslateLink, setHelpTranslateLink] = useState(getConfigFromCache() || {});
  const [showCatalogueLanguageModal, toggleCatalogueLanguageModal] = useState(false);
  const [showAppLanguageModal, toggleAppLanguageModal] = useState(false);
  const [showCatalogueLanguageLoader, toggleShowCatalogueLanguageLoader] = useState(false);
  const [showAppLanguageLoader, toggleShowAppLanguageLoader] = useState(false);

  const { t } = getI18N();

  const modalStyles = useRef({
    backgroundColor: 'white',
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    width: '400px'
  });

  useEffect(() => {
    const catalogueLanguageListener = function(error, payload) {
      const { err, loading, refreshing, data } = CacheListenerCallback(error, payload);

      if (loading || refreshing) {
        return;
      }

      if (err) {
        toastr.error(t('something_went_wrong_while_fetching_the_user_current_set_language'));
        return;
      }

      const { settings } = data || {};
      const catalogueLanguageData = extractCompanyLanguage(settings);
      setCurrentCatalogueLanguage(catalogueLanguageData);
      setCatalogueDisplayLanguage(catalogueLanguageData);
    };

    attachCompanySettingsListener(catalogueLanguageListener);
    return () => removeCompanySettingsListener(catalogueLanguageListener);
  });

  useEffect(() => {
    const configListener = function(error, payload) {
      const { err, loading, refreshing, data } = CacheListenerCallback(error, payload);

      if (loading || refreshing || err) {
        return;
      }

      setHelpTranslateLink(data);
    };

    attachConfigsListener(configListener);
    return () => removeConfigsListener(configListener);
  }, []);

  const handleCatalogueLanguageClick = function(event) {
    event.preventDefault();
    event.stopPropagation();
    toggleCatalogueLanguageModal(!showCatalogueLanguageModal);
  };

  const handleAppLanguageClick = function(event) {
    event.preventDefault();
    event.stopPropagation();
    toggleAppLanguageModal(!showAppLanguageModal);
  };

  const closeCatalogueLanguageModal = function() {
    toggleCatalogueLanguageModal(false);
    setCurrentCatalogueLanguage(catalogueDisplayLanguage);
  };

  const closeAppLanguageModal = function() {
    toggleAppLanguageModal(false);
    setCurrentAppLanguage(appDisplayLanguage);
  };

  const handleCatalogueLanguageCancel = function(event) {
    event.preventDefault();
    closeCatalogueLanguageModal();
  };

  const handleAppLanguageCancel = function(event) {
    event.preventDefault();
    closeAppLanguageModal();
  };

  const submitCatalogueLanguage = async function(event) {
    event.preventDefault();
    closeCatalogueLanguageModal();
    let catalogueLanguageToSend;
    for (const langProps of CATALOGUE_LANGUAGUES) {
      if (langProps[1] === currentCatalogueLanguage) {
        catalogueLanguageToSend = langProps[0];
        break;
      }
    }
    toggleShowCatalogueLanguageLoader(true);
    try {
      await setCatalogueLanguage(catalogueLanguageToSend);
    } catch (error) {
      toastr.error(t('something_went_wrong_while_setting_the_language'));
    }
    toggleShowCatalogueLanguageLoader(false);
  };

  const submitAppLanguage = async function(event) {
    event.preventDefault();
    closeAppLanguageModal();
    let appLanguageToSend;
    for (const langProps of APP_LANGUAGUES) {
      if (langProps[1] === currentAppLanguage) {
        appLanguageToSend = langProps[0];
        break;
      }
    }
    toggleShowAppLanguageLoader(true);
    try {
      await setAppLanguage(appLanguageToSend);
      localStorage.setItem(COMPANY_LOCAL_STORAGE_KEYS.APP_LANGUAGE, appLanguageToSend);
      eventbus.publish(APP_LANGUAGE_CHANGED_EB_KEY, appLanguageToSend);
      const appLanguageData = extractAppLanguage();
      setCurrentAppLanguage(appLanguageData);
      setAppDisplayLanguage(appLanguageData);
      window.location.reload();
    } catch (error) {
      toastr.error(t('something_went_wrong_while_setting_the_language'));
    }
    toggleShowAppLanguageLoader(false);
  };

  const handleCatalogueLanguageChange = function(event) {
    event.preventDefault();
    setCurrentCatalogueLanguage(CATALOGUE_LANGUAGUES.get(event.currentTarget.dataset.lang));
  };

  const handleAppLanguageChange = function(event) {
    event.preventDefault();
    setCurrentAppLanguage(APP_LANGUAGUES.get(event.currentTarget.dataset.lang));
  };

  const handleHelpTranslateClick = function() {
    Mixpanel.sendEvent({
      eventName: 'help_us_translate_clicked',
      props: {
        from: 'Language Settings'
      }
    });
  };

  const renderCatalogueLanguages = function() {
    const catalogueLanguageComponents = [];
    CATALOGUE_LANGUAGUES.forEach((value, key) => {
      const currentLanguageSelected = value === currentCatalogueLanguage;
      let selectedValClass, selectedComponent;
      if (currentLanguageSelected) {
        selectedValClass = 'language-text--selected';
        selectedComponent = <span className="selected-text">{t('selected')}</span>;
      }
      catalogueLanguageComponents.push(
        <button
          key={key}
          className="language-text"
          data-lang={key}
          onClick={handleCatalogueLanguageChange}
        >
          <span className={selectedValClass}>{value}</span>
          {selectedComponent}
        </button>
      );
    });
    return catalogueLanguageComponents;
  };

  const renderAppLanguages = function() {
    const appLanguageComponents = [];
    APP_LANGUAGUES.forEach((value, key) => {
      const currentLanguageSelected = value === currentAppLanguage;
      let selectedValClass, selectedComponent;
      if (currentLanguageSelected) {
        selectedValClass = 'language-text--selected';
        selectedComponent = <span className="selected-text">{t('selected')}</span>;
      }
      appLanguageComponents.push(
        <button
          key={key}
          className="language-text"
          data-lang={key}
          onClick={handleAppLanguageChange}
        >
          <span className={selectedValClass}>{value}</span>
          {selectedComponent}
        </button>
      );
    });
    return appLanguageComponents;
  };

  const renderCatalogueLanguageSelectionModal = () => (
    <Modal
      open={true}
      center={true}
      onClose={closeCatalogueLanguageModal}
      showCloseIcon={false}
      styles={{ modal: modalStyles.current }}
    >
      <h3 className="language-section-modal language-modal-title">
        {t('select_catalogue_language')}
      </h3>
      <div className="language-section-modal language-container">{renderCatalogueLanguages()}</div>
      <div className="language-section-modal button-container">
        <button type="button" className="button-plain" onClick={handleCatalogueLanguageCancel}>
          {t('cancel')}
        </button>
        <button
          type="submit"
          className="button-plain button-submit"
          onClick={submitCatalogueLanguage}
        >
          {t('set_language')}
        </button>
      </div>
    </Modal>
  );

  const renderAppLanguageSelectionModal = () => (
    <Modal
      open={true}
      center={true}
      onClose={closeAppLanguageModal}
      showCloseIcon={false}
      styles={{ modal: modalStyles.current }}
    >
      <h3 className="language-section-modal language-modal-title">{t('select_app_language')}</h3>
      <div className="language-section-modal language-container">{renderAppLanguages()}</div>
      <div className="language-section-modal button-container">
        <button type="button" className="button-plain" onClick={handleAppLanguageCancel}>
          {t('cancel')}
        </button>
        <button type="submit" className="button-plain button-submit" onClick={submitAppLanguage}>
          {t('set_language')}
        </button>
      </div>
    </Modal>
  );

  let languageComponent;
  if (catalogueDisplayLanguage) {
    languageComponent = (
      <Fragment>
        <SettingsDataRow
          icon={
            <button onClick={handleCatalogueLanguageClick} className="text-template-share">
              {catalogueDisplayLanguage}
            </button>
          }
          loader={showCatalogueLanguageLoader}
          title={t('catalogue_language')}
          onSectionClick={handleCatalogueLanguageClick}
        >
          <span>{t('select_the_default_language_for_your_catalogue')}</span>
        </SettingsDataRow>
        <SettingsDataRow
          icon={
            <button onClick={handleAppLanguageClick} className="text-template-share">
              {appDisplayLanguage}
            </button>
          }
          loader={showAppLanguageLoader}
          title={t('app_language')}
          onSectionClick={handleAppLanguageClick}
        >
          <span>{t('select_the_default_language_for_your_app')}</span>
        </SettingsDataRow>
        <ClickHandlerWithLoaderContainer
          linkProps={{ linkUrl: helpTranslateLink.translationInviteLink }}
          clickProps={{
            clickHandler: handleHelpTranslateClick
          }}
          type="link"
          classForContainer={'language-section-link'}
        >
          <SettingsDataRow title={t('help_us_translate')}>
            <span>{t('help_us_translate_quicksell_app')}</span>
          </SettingsDataRow>
        </ClickHandlerWithLoaderContainer>
        {showCatalogueLanguageModal && renderCatalogueLanguageSelectionModal()}
        {showAppLanguageModal && renderAppLanguageSelectionModal()}
      </Fragment>
    );
  } else {
    languageComponent = (
      <div className="language-section-loader">
        <Loader size="large" />
      </div>
    );
  }

  return <div className="language-section">{languageComponent}</div>;
};
