import React, { useContext, useEffect, useCallback } from 'react';
import { ReactComponent as BackIcon } from 'qs-assets/Media/arrow-left.svg';
import { ActiveOrderView } from '../../context';
import { UPDATE_MANAGE_INVENTORY } from '../../reducer';
import { ActiveOrderMeta } from '../../../context';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

export default ({ onBack }) => {
  const { t } = getI18N();
  const { setActiveOrderViewData } = useContext(ActiveOrderView);
  const {
    activeOrderMeta: { selectedOrderId }
  } = useContext(ActiveOrderMeta);

  const handleBackClick = useCallback(() => {
    setActiveOrderViewData({ type: UPDATE_MANAGE_INVENTORY, manageInventory: false });
    onBack();
  }, [onBack, setActiveOrderViewData]);

  useEffect(() => {
    //selected orderId has changed, exit this view
    return () => handleBackClick();
  }, [handleBackClick, selectedOrderId]);

  return (
    <div className="stockManagmentHeaderView">
      <div className="backContainer" onClick={handleBackClick}>
        <BackIcon className="orderHeaderButtonIcon" />
        <span className="backText">{t('back')}</span>
      </div>
    </div>
  );
};
