import { connector } from '../ApiAndCacheConnector';
import CacheRequest from '../CacheRequest';
import { setProductMetaInCache } from '../Products';

export const updateCacheForProductSet = ({ setData, productId }) => {
  if (!productId || !setData || Object.keys(setData).length === 0) {
    return;
  }

  let key = `${connector.BASIC_INFO.cacheKey}${productId}`;
  let cache = CacheRequest.getCacheForKey(key);
  if (cache) {
    CacheRequest.setCacheForKey(key, {
      ...cache,
      ...setData
    });
  }

  key = `${connector.INVENTORY.cacheKey}${productId}`;
  cache = CacheRequest.getCacheForKey(key);
  if (cache) {
    const newCacheData = { ...cache };
    newCacheData.setDetails = {
      ...cache.setDetails,
      ...setData
    };
    CacheRequest.setCacheForKey(key, newCacheData);
  }

  setProductMetaInCache({ productId, updates: setData });
};
