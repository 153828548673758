import {
  attachCompanyTaxListener,
  getCompanyTaxes,
  removeCompanyTaxListener
} from 'qs-data-manager/Tax/TaxOperations';
import { getCompanyTaxesFromCache } from './CacheHelpers';

export const getCachedCompanyTaxes = () => getCompanyTaxesFromCache();

export const fetchCompanyTaxesHandler = ({ listener }) => {
  attachCompanyTaxListener({ listener });
  getCompanyTaxes();
};

export const handleCompanyTaxesCleanup = ({ listener }) => {
  removeCompanyTaxListener({ listener });
};
