import { Dialog, DialogTitle, Button, withStyles } from '@material-ui/core';

export const DialogDarkTheme = withStyles({
  paper: {
    backgroundColor: '#191d25',
    color: '#ffffff'
  },
  root: {}
})(Dialog);

export const DarkThemeTitle = withStyles({
  root: {
    fontSize: 26,
    fontWeight: 700
  }
})(DialogTitle);

export const ButtonDanger = withStyles({
  root: {
    fontSize: 14,
    color: '#f04b4b'
  }
})(Button);

export const PrimaryButton = withStyles({
  root: {
    fontSize: 14,
    color: '#4ed29b',
    '&$disabled': {
      color: '#4ed29b',
      opacity: 0.5
    }
  },
  disabled: {}
})(Button);

export const SecondaryButton = withStyles({
  root: {
    fontSize: 14,
    color: '#ffffff'
  }
})(Button);
