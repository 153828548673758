import React, { useEffect, useCallback, memo, useContext, useState } from 'react';
import {
  attachProductVariantListener,
  removeProductVariantListener,
  getVariantsDataForProduct,
  getVariantsDataForProductFromCache
} from 'qs-data-manager/Variants/FetchVariants';
import CacheListenerCallback from 'qs-helpers/CacheListenerCallback';
import VariantSummaryView from './VariantSummaryView';
import WindowScroller from 'react-virtualized/dist/commonjs/WindowScroller';
import { VariableSizeList as List } from 'react-window';
import { ActiveVariantId } from '../../context';
import { VARIANT_ROW } from 'qs-helpers/Variants/constants';
import useRefreshHandlerHook from 'qs-hooks/refreshHandlerHook';
import DefaultVariant from './DefaultVariant';
import useDynamicSizedList from 'qs-hooks/dynamicSizedList';
import VariantShimmer from './VariantSummaryView/VariantShimmer';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

export default memo(({ activeProductId, containerRef, contentContainerRef, reloadVariants }) => {
  const { setActiveVariantId } = useContext(ActiveVariantId);
  const cachedVariantList = getVariantsDataForProductFromCache(activeProductId);
  const [productVariantStatus, updateProductVariantStatus] = useRefreshHandlerHook(
    cachedVariantList
  );
  const [variantsListRef, itemHeightMap, reRenderListOnItemUpdate] = useDynamicSizedList();
  const [variantList, setVariantList] = useState(cachedVariantList);
  const { t } = getI18N();

  useEffect(() => {
    const cachedListForProduct = getVariantsDataForProductFromCache(activeProductId);
    setVariantList(cachedListForProduct);
    updateProductVariantStatus({
      data: cachedListForProduct,
      localRefresh: true
    });

    const productVariantListener = (error, payload) => {
      const dataFromListener = CacheListenerCallback(error, payload);
      updateProductVariantStatus(dataFromListener);
      const { err, loading, refreshing, data } = dataFromListener;
      if (err || loading || refreshing || !data) {
        return;
      }

      setVariantList(data);
    };

    attachProductVariantListener({ productId: activeProductId, listener: productVariantListener });
    getVariantsDataForProduct({ productId: activeProductId });
    return () => {
      removeProductVariantListener({
        productId: activeProductId,
        listener: productVariantListener
      });
    };
  }, [activeProductId, updateProductVariantStatus, reloadVariants]);

  const handleDetailsClick = useCallback(
    (event, variantId) => {
      event.preventDefault();
      setActiveVariantId(variantId);
    },
    [setActiveVariantId]
  );

  const renderRow = useCallback(
    ({ data, index, style }) => {
      const variantId = data[index][0];

      return (
        <div style={style} className="variantRowHoverContainer">
          <div
            className="variantRowContainer"
            onClick={event => handleDetailsClick(event, variantId)}
          >
            <VariantSummaryView
              variantId={variantId}
              onMetaUpdate={reRenderListOnItemUpdate}
              indexInList={index}
            />
          </div>
        </div>
      );
    },
    [handleDetailsClick, reRenderListOnItemUpdate]
  );

  const handleScroll = ({ scrollTop }) => {
    if (variantsListRef.current) {
      variantsListRef.current.scrollTo(scrollTop);
    }
  };

  const itemKey = useCallback((index, data) => data[index][0], []);

  const getItemHeight = useCallback(
    index => {
      const variantArray = variantList.variantProductIds[index];
      if (!Array.isArray(variantArray)) {
        return VARIANT_ROW.estimatedHeight;
      }

      const variantId = variantArray[0];
      const variantHeight = itemHeightMap.current.get(variantId);
      if (variantHeight !== undefined) {
        return variantHeight + 20;
      }

      return VARIANT_ROW.estimatedHeight;
    },
    [variantList, itemHeightMap]
  );

  /*
   * Pass the container that is being scrolled.
   * Take the height of the parent container to render the first few items.
   * The rest will be rendered as and when the scroll occurs
   */
  const renderVariantsList = () => {
    return (
      <WindowScroller scrollElement={contentContainerRef} onScroll={handleScroll}>
        {() => (
          <List
            ref={variantsListRef}
            height={containerRef.clientHeight}
            itemData={variantList.variantProductIds}
            itemCount={variantList.variantProductIds.length}
            itemKey={itemKey}
            itemSize={getItemHeight}
            width={'100%'}
            overscanCount={VARIANT_ROW.overscanCount}
            className="variantWindowedList"
            estimatedItemSize={VARIANT_ROW.estimatedHeight}
          >
            {renderRow}
          </List>
        )}
      </WindowScroller>
    );
  };

  const renderFiveShimmers = () => {
    const fiveShimmers = [];
    for (let index = 0; index < 5; index++) {
      fiveShimmers.push(<VariantShimmer className={'variantsLoaderShimmer'} key={index} />);
    }
    return fiveShimmers;
  };

  const { loading, error } = productVariantStatus;

  if (error) {
    return (
      <div className={'variantsNoContentContainer'}>
        <span className="errorMsg">
          Something went wrong while fetching the variants for this product
        </span>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="productVariantsContainer">
        <DefaultVariant
          defaultVariantId={null}
          onVariantIdChange={setActiveVariantId}
          titleClass="title"
        />
        <div className="allVariantsContainer">
          <div className="title listTitle">{t('all_variants')}</div>
          <div className="variantsLoaderShimmerContainer">{renderFiveShimmers()}</div>
        </div>
      </div>
    );
  }

  return (
    <div className="variantsSection">
      {variantList && variantList.variantProductIds && variantList.variantProductIds.length ? (
        <div className="productVariantsContainer">
          <DefaultVariant
            defaultVariantId={variantList.defaultVariantId}
            onVariantIdChange={setActiveVariantId}
            titleClass="title"
          />
          <div className="allVariantsContainer">
            <div className="title listTitle">
              {t('all_variants_count', { count: variantList.variantProductIds.length })}
            </div>
            {renderVariantsList()}
          </div>
        </div>
      ) : (
        <div className="variantsNoContentContainer">
          <span style={{ color: '#737f91' }}>{t('no_variants_added_for_this_product')}</span>
        </div>
      )}
    </div>
  );
});
