import {
  attachShippingWeightUnitsListener,
  removeShippingWeightUnitsListener,
  getShippingWeightUnits
} from 'qs-data-manager/Shipping/WeightUnits';

export const fetchShippingWeightUnitsHandler = ({ listener }) => {
  attachShippingWeightUnitsListener({ listener });
  getShippingWeightUnits();
};

export const handleShippingWeightUnitsCleanup = ({ listener }) => {
  removeShippingWeightUnitsListener({ listener });
};
