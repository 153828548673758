import React, { useCallback, useEffect, useState, useRef } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList as List } from 'react-window';
import ProductOptionsRow from '../ProductOptionsRow';
import {
  attachProductOptionsListener,
  removeProductOptionsListener,
  getCurrentProductOptionsListFromCache,
  reorderOptionsProduct
} from 'qs-data-manager/Variants/ProductOptions';
import WindowScroller from 'react-virtualized/dist/commonjs/WindowScroller';
import { PRODUCT_OPTIONS, VARIANT_ADD_TABS } from 'qs-helpers/Variants/constants';
import CacheListenerCallback from 'qs-helpers/CacheListenerCallback';
import useDynamicSizedList from 'qs-hooks/dynamicSizedList';
import './styles.scss';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { getI18N } from 'qs-services/i18N';
import toastr from 'toastr';

const SortableItem = sortableElement(
  ({
    style,
    key,
    reRenderListOnItemUpdate,
    optionsData,
    productIndex,
    activeOptionId,
    activeProductId,
    currentTab
  }) => {
    return (
      <div key={key} style={style} className="sortableElementProductOptionsListContainer">
        <ProductOptionsRow
          optionsData={optionsData}
          index={productIndex}
          activeOptionId={activeOptionId}
          activeProductId={activeProductId}
          currentTab={currentTab}
          onOptionRender={reRenderListOnItemUpdate}
          reRenderListOnItemUpdate={reRenderListOnItemUpdate}
        />
      </div>
    );
  }
);

const VirtualList = ({
  activeProductId,
  innerListMounted,
  currentTab,
  optionsList,
  optionAdded,
  optionAdditionProcessed,
  contentContainerRef
}) => {
  const [variantOptionsListRef, itemHeightMap, reRenderListOnItemUpdate] = useDynamicSizedList();
  const scrollToTop = useCallback(() => {
    if (!contentContainerRef) {
      return;
    }
    contentContainerRef.scrollTo(0, 'start');
  }, [contentContainerRef]);

  useEffect(() => {
    scrollToTop();
  }, [activeProductId, scrollToTop]);

  useEffect(() => {
    if (!variantOptionsListRef.current || !optionAdded) {
      return;
    }

    variantOptionsListRef.current.scrollToItem(optionsList.length - 1);
    optionAdditionProcessed();
  }, [optionsList.length, optionAdded, optionAdditionProcessed, variantOptionsListRef]);

  const innerRefElement = useCallback(
    innerListRef => {
      innerListMounted(innerListRef);
    },
    [innerListMounted]
  );

  const renderRow = useCallback(
    ({ index, style, data }) => {
      return (
        <SortableItem
          key={data[index].id}
          index={index}
          optionsData={data[index]}
          activeOptionId={data[index].id}
          productIndex={index}
          currentTab={currentTab}
          reRenderListOnItemUpdate={reRenderListOnItemUpdate}
          activeProductId={activeProductId}
          style={style}
        />
      );
    },
    [currentTab, reRenderListOnItemUpdate, activeProductId]
  );

  const getItemHeight = useCallback(
    index => {
      const colorOption = optionsList[index] || {};
      if (!colorOption) {
        return PRODUCT_OPTIONS.estimatedHeight;
      }
      const optionHeight = itemHeightMap.current.get(colorOption.id);
      if (optionHeight !== undefined) {
        return optionHeight;
      }

      return PRODUCT_OPTIONS.estimatedHeight;
    },
    [itemHeightMap, optionsList]
  );
  const handleScroll = ({ scrollTop }) => {
    if (variantOptionsListRef.current) {
      variantOptionsListRef.current.scrollTo(scrollTop);
    }
  };

  const itemKey = (index, data) => {
    return data[index].id;
  };

  return (
    <WindowScroller scrollElement={contentContainerRef} onScroll={handleScroll}>
      {() => (
        <AutoSizer disableHeight={true}>
          {({ width }) => (
            <List
              key={activeProductId}
              ref={variantOptionsListRef}
              height={contentContainerRef.clientHeight}
              innerRef={innerRefElement}
              itemData={optionsList}
              itemCount={optionsList.length}
              itemKey={itemKey}
              itemSize={getItemHeight}
              width={width}
              overscanCount={PRODUCT_OPTIONS.overscanCount}
              estimatedItemSize={PRODUCT_OPTIONS.estimatedHeight}
              className="window-products-list"
            >
              {renderRow}
            </List>
          )}
        </AutoSizer>
      )}
    </WindowScroller>
  );
};

const SortableVirtualList = sortableContainer(VirtualList);
export default ({
  activeProductId,
  currentTab,
  optionAdded,
  optionAdditionProcessed,
  changeReorderStatus
}) => {
  const [optionsList, setOptionsList] = useState(
    getCurrentProductOptionsListFromCache({
      productId: activeProductId,
      currentTab: currentTab.type
    })
  );
  const { t } = getI18N();

  const [, setContentContainerRefMounted] = useState(null);
  const contentContainerRef = useRef(null);
  const contentContainerRefMounted = useCallback(ref => {
    if (ref) {
      contentContainerRef.current = ref;
      setContentContainerRefMounted(ref);
      return;
    }
    contentContainerRef.current = null;
  }, []);

  useEffect(() => {
    const listener = (error, payload) => {
      const { err, loading, refreshing, data } = CacheListenerCallback(error, payload);
      if (err || loading || refreshing || !data) {
        return;
      }

      setOptionsList(
        getCurrentProductOptionsListFromCache({
          productId: activeProductId,
          currentTab: currentTab.type
        })
      );
    };

    attachProductOptionsListener({ listener, productId: activeProductId });
    return () => removeProductOptionsListener({ listener, productId: activeProductId });
  }, [activeProductId, currentTab.type]);

  const savedCatalogueId = useRef();
  savedCatalogueId.current = activeProductId;
  const innerListRef = useRef(null);

  const innerListMounted = useCallback(ref => {
    innerListRef.current = ref;
  }, []);
  const clearOverlays = () => {
    document.querySelector('.styles_overlay__CLSq-').click();
  };

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) {
      return;
    }

    const newList = arrayMove(optionsList, oldIndex, newIndex).map((row, index) => ({
      ...row,
      position: index
    }));

    setOptionsList(newList);
    const optionClicked = newList[newIndex];
    const optionId = optionClicked.id;

    const reorderOptions = await reorderOptionsProduct({
      optionsList: newList,
      productId: activeProductId,
      currentTab: currentTab.type,
      optionId,
      newIndex
    });
    clearOverlays();
    if (reorderOptions) {
      toastr.success(t('reorder_successful'));
      changeReorderStatus();
    } else {
      toastr.error(t('something_went_wrong_while_reordering_the_products_options'));
    }
  };

  if (optionsList.length === 0) {
    let emptyListMessage = t('no_options_available');
    if (currentTab.type === VARIANT_ADD_TABS.SIZES) {
      emptyListMessage = t('no_sizes_added');
    }
    if (currentTab.type === VARIANT_ADD_TABS.COLORS) {
      emptyListMessage = t('no_colors_added');
    }
    return (
      <div className="productOptionsList emptyProductList">
        <span>{emptyListMessage}</span>
      </div>
    );
  }
  const renderList = () => {
    if (!contentContainerRef.current) {
      return null;
    }
    return (
      <SortableVirtualList
        items={optionsList}
        distance={15}
        optionAdded={optionAdded}
        optionAdditionProcessed={optionAdditionProcessed}
        optionsList={optionsList}
        innerListMounted={innerListMounted}
        contentContainerRef={contentContainerRef.current}
        onSortEnd={onSortEnd}
        pressThreshold={200}
        lockAxis={'y'}
        currentTab={currentTab}
        activeProductId={activeProductId}
      />
    );
  };
  return (
    <div className="productOptionsList" ref={contentContainerRefMounted}>
      {renderList()}
    </div>
  );
};
