import { searchProductLibCatalogueMeta, getCatalogueHeadline } from './api';

export const CATALOGUE_PRODUCT_LIB_META = {
  cacheKey: 'CATALOGUE_PRODUCT_LIB_META',
  apiFunction: searchProductLibCatalogueMeta
};

export const CATALOGUE_HEADLINE = {
  cacheKey: 'CATALOGUE_HEADLINE',
  apiFunction: getCatalogueHeadline
};

export const CATALOGUE_PUBLISH_TO_HOMEPAGE = {
  cacheKey: 'CATALOGUE_PUBLISH_TO_HOMEPAGE'
};
