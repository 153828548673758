import React from 'react';
import { ReactComponent as MetalRatePageIcon } from 'qs-assets/Media/ic_metal_rate_icon.svg';
import './styles.scss';

export default ({ width, height, fill, classes = '' }) => {
  return (
    <MetalRatePageIcon
      style={{ width, height, fill }}
      className={`settingsIconComponent settingsMetalRateIcon ${classes}`}
    />
  );
};
