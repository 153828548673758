import React, { useEffect, useState, useContext } from 'react';
import { ReactComponent as BackIcon } from 'qs-assets/Media/arrow-left.svg';
import { ReactComponent as DeleteIcon } from 'qs-assets/Media/trash.svg';
import { selectedOrders } from 'qs-data-manager/Selected';
import {
  DialogDarkTheme,
  ButtonDanger,
  SecondaryButton
} from 'qs-components/Common/DarkThemeDialog';
import { DialogActions, DialogTitle, Button } from '@material-ui/core';
import { deleteProductsFromOrder } from 'qs-data-manager/Orders/OrdersProductsOperations';
import { ActiveOrderMeta } from '../../../context';
import { toggleGlobalLoader, getErrorMessage } from 'qs-helpers';
import { UPDATE_ORDER_META } from '../../../reducer';
import ErrorPopup from 'qs-components/Common/ErrorPopup';
import InventoryUpdateConfirm from '../../OrderProductsView/InventoryUpdateConfirm';
import toastr from 'toastr';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

export default ({ onBack }) => {
  const {
    activeOrderMeta: { selectedOrderId, currentSortKey, currentConfirmFilter },
    setActiveOrderMeta
  } = useContext(ActiveOrderMeta);
  const [selectedItemCount, setSelectedItemCount] = useState(0);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [errorData, setErrorData] = useState();
  const [updateInventory, setUpdateInventory] = useState(false);
  const { t } = getI18N();

  useEffect(() => {
    // Listen for selections and set the count accordingly
    const listener = () => {
      setSelectedItemCount(selectedOrders.getCount());
    };
    selectedOrders.addListener(listener);
    return () => selectedOrders.removeListener(listener);
  }, []);

  const toggleDeleteConfirmation = () => {
    setShowDeleteConfirmation(prevVal => !prevVal);
  };

  const getDeletedItemsHeader = () => {
    return t(selectedItemCount === 1 ? 'delete_count_item' : 'delete_count_items', {
      count: selectedItemCount
    });
  };

  const getInventoryRevertMessage = () => {
    if (selectedItemCount === 1) {
      return t('add_back_item_to_inventory');
    }
    return t('add_back_items_to_inventory');
  };

  const handleDeleteItemsClick = async () => {
    toggleDeleteConfirmation();
    const loaderKey = `deleteSelectedInquiries${Date.now()}`;
    toggleGlobalLoader(loaderKey, true);
    try {
      const { orderDeleted, nextEligibleOrderId, inventoryUpdated } = await deleteProductsFromOrder(
        {
          orderId: selectedOrderId,
          inquiryIdsMap: selectedOrders.getAll(),
          sortKey: currentSortKey,
          confirmed: currentConfirmFilter,
          updateInventory
        }
      );
      if (orderDeleted) {
        setActiveOrderMeta({ type: UPDATE_ORDER_META, orderId: nextEligibleOrderId });
      }

      if (inventoryUpdated) {
        toastr.success(t('inventory_updated'));
      }

      onBack();
    } catch (deleteItemsError) {
      setErrorData({
        title: getDeletedItemsHeader(),
        subject: t('failed_to_delete_items_for_selected_order', { selectedOrderId }),
        body: getErrorMessage(deleteItemsError),
        message: t('something_went_wrong_while_deleting_the_selected_items')
      });
    } finally {
      toggleGlobalLoader(loaderKey, false);
    }
  };

  return (
    <>
      <div className="deleteOrderItemsHeaderView">
        <div className="backContainer" onClick={onBack}>
          <BackIcon className="orderHeaderButtonIcon" />
          <span className="backText">{t('back')}</span>
        </div>
        {selectedItemCount >= 1 && (
          <Button
            startIcon={<DeleteIcon className="orderHeaderButtonIcon" />}
            color="primary"
            variant="contained"
            onClick={toggleDeleteConfirmation}
          >
            {getDeletedItemsHeader()}
          </Button>
        )}
      </div>
      {showDeleteConfirmation && (
        <DialogDarkTheme open={true}>
          <DialogTitle>{getDeletedItemsHeader()}</DialogTitle>
          <InventoryUpdateConfirm
            updateInventory={updateInventory}
            setUpdateInventory={setUpdateInventory}
            label={t('are_you_sure_you_want_to_delete_multiple_items_from_this_order')}
            inventoryLabel={getInventoryRevertMessage()}
          />
          <DialogActions>
            <SecondaryButton onClick={toggleDeleteConfirmation}>
              <span className="cancelButtonText">{t('cancel')}</span>
            </SecondaryButton>
            <ButtonDanger color={'primary'} onClick={handleDeleteItemsClick}>
              <span>{t('delete')}</span>
            </ButtonDanger>
          </DialogActions>
        </DialogDarkTheme>
      )}
      {errorData && (
        <ErrorPopup onClose={() => setErrorData()} mailProps={errorData} title={errorData.title}>
          {errorData.message}
        </ErrorPopup>
      )}
    </>
  );
};
