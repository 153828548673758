import React, { useState, useEffect } from 'react';
import ProductPrice from './ProductPrice';
import ProductDiscount from './ProductDiscount';
import ProductSetDropdown from './ProductSetDropdown';
import ProductSetPriceField from './ProductSetPriceField';
import { canUseFeature, FEATURE_LIST } from 'qs-data-manager/FeatureUsage';
import {
  getPriceAsStringFromSetQty,
  getPriceForSet,
  getPricePerPieceAsString,
  getProductPriceDetails
} from 'qs-helpers/Products/ProductSetOrdering';
import { roundNumberToGivenDecimals } from 'qs-helpers';
import eventingBus from 'eventing-bus';
import { SAVE_BUTTON_META } from 'qs-helpers/Products/constants';
import { getPercentChangedDiscount, isProductPriceValid } from 'qs-helpers/Products/ProductPricing';
import ProductSetQuantity from './ProductSetQuantity';
import ProductSetName from './ProductSetName';
import './styles.scss';
import { getI18N } from 'qs-services/i18N';

export default ({
  price,
  discount,
  isSet,
  setName,
  setQuantity,
  setType,
  activeProductIds,
  isBulkEditing,
  activeProductId,
  currencySymbol,
  loading
}) => {
  const {
    price: updatedPrice,
    discount: updatedDiscount,
    setPrice,
    setQty
  } = getProductPriceDetails({
    price,
    discount,
    isSet,
    setQuantity,
    isBulkEditing
  });

  const { t } = getI18N();

  const [modifiedPrice, setModifiedPrice] = useState(updatedPrice);
  const [originalPrice, setOriginalPrice] = useState(updatedPrice);
  const [modifiedDiscount, setModifiedDiscount] = useState(updatedDiscount);
  const [originalDiscount, setOriginalDiscount] = useState(updatedDiscount);
  const [modifiedSetPrice, setModifiedSetPrice] = useState(setPrice);
  const [updateToSet, setUpdateToSet] = useState(isSet);
  const [updateSetType, setUpdateSetType] = useState(setType);
  const [modifiedSetQuantity, setModifiedSetQuantity] = useState(setQty);
  const [originalSetQuantity, setOriginalSetQuantity] = useState(setQty);

  // Effect to reset data when productId changes or any of the product parameters changes
  useEffect(() => {
    const {
      price: updatedPrice,
      discount: updatedDiscount,
      setPrice,
      setQty
    } = getProductPriceDetails({
      price: price ? Number(price) : '',
      discount: discount ? Number(discount) : '',
      isSet,
      setQuantity: setQuantity ? Number(setQuantity) : '',
      isBulkEditing
    });

    setModifiedPrice(updatedPrice);
    setOriginalPrice(updatedPrice);

    setModifiedDiscount(updatedDiscount);
    setOriginalDiscount(updatedDiscount);

    setModifiedSetPrice(setPrice);
    setUpdateToSet(isSet);
    setUpdateSetType(setType);

    setModifiedSetQuantity(setQty);
    setOriginalSetQuantity(setQty);
  }, [price, discount, isSet, setQuantity, setType, isBulkEditing, activeProductId]);

  const getNumSetQty = () => Number(modifiedSetQuantity || 1);

  const getProductPrice = () => {
    if (isProductPriceValid(modifiedDiscount)) {
      return modifiedDiscount;
    }
    return modifiedPrice;
  };

  const isDiscountMoreThanPrice = (discount, price) => {
    if (Number(discount) > Number(price)) {
      alert(t('discount_cannot_be_greater_than_price'));
      return true;
    }
    return false;
  };

  const saveProductFieldsIfNeeded = ({ updatedSetQty = modifiedSetQuantity }) => {
    const numUpdatedSetQty = Number(updatedSetQty || 1);
    const numSetQty = Number(originalSetQuantity || 1);

    if (isProductPriceValid(modifiedDiscount)) {
      const originalDiscountSet = getPriceForSet(modifiedDiscount, updateToSet, numSetQty);
      const modifiedDiscountSet = getPriceForSet(modifiedDiscount, updateToSet, numUpdatedSetQty);
      let hasChanged = true;

      // If the previous set discounted price is the same as the current quantity and the discount values are the same then remove discount from changed value
      if (originalDiscountSet === modifiedDiscountSet && modifiedDiscount === originalDiscount) {
        hasChanged = false;
      }

      eventingBus.publish(SAVE_BUTTON_META.eventbusKey, {
        id: SAVE_BUTTON_META.PRODUCT_DISCOUNT.id,
        hasChanged,
        data: modifiedDiscount !== '' ? modifiedDiscountSet : null,
        eventType: SAVE_BUTTON_META.eventType.PRODUCT_META.id
      });
    }

    const originalPriceSet = getPriceForSet(modifiedPrice, updateToSet, numSetQty);
    const modifiedPriceSet = getPriceForSet(modifiedPrice, updateToSet, numUpdatedSetQty);
    let hasChanged = true;

    if (originalPriceSet === modifiedPriceSet && modifiedPrice === originalPrice) {
      hasChanged = false;
    }
    eventingBus.publish(SAVE_BUTTON_META.eventbusKey, {
      id: SAVE_BUTTON_META.PRODUCT_PRICE.id,
      hasChanged,
      data: modifiedPriceSet !== '' ? modifiedPriceSet : null,
      eventType: SAVE_BUTTON_META.eventType.PRODUCT_META.id
    });
  };

  const onPriceChange = (newPrice, updateSetPrice = true) => {
    const numSetQty = getNumSetQty();
    const hasChanged = originalPrice !== newPrice;
    let totalPrice = null;
    if (newPrice !== '') {
      totalPrice = getPriceForSet(newPrice, updateToSet, numSetQty);
    }

    eventingBus.publish(SAVE_BUTTON_META.eventbusKey, {
      id: SAVE_BUTTON_META.PRODUCT_PRICE.id,
      hasChanged,
      data: totalPrice,
      eventType: SAVE_BUTTON_META.eventType.PRODUCT_META.id
    });

    // Get the discount data before the price is changed in state
    let discountForPrice = getPercentChangedDiscount(modifiedDiscount, newPrice, modifiedPrice);
    setModifiedPrice(newPrice);

    // Product price has been cleared, remove the discount too
    if (!isProductPriceValid(newPrice)) {
      setModifiedSetPrice('');
      setModifiedDiscount('');
      return;
    }

    if (isProductPriceValid(modifiedDiscount)) {
      if (typeof discountForPrice === 'number') {
        discountForPrice = discountForPrice.toString();
      } else {
        discountForPrice = '';
      }

      onDiscountChange(discountForPrice, newPrice);
      return;
    }

    // Set price need not be updated, don't proceed further
    if (!updateSetPrice) {
      return;
    }

    setModifiedSetPrice(roundNumberToGivenDecimals(Number(newPrice) * numSetQty, 3).toString());
  };

  const onDiscountChange = (newDiscount, newPrice = modifiedPrice, updateSetPrice = true) => {
    if (isDiscountMoreThanPrice(newDiscount, newPrice)) {
      return false;
    }

    setModifiedDiscount(newDiscount);
    const numSetQty = getNumSetQty();
    const hasChanged = newDiscount !== originalDiscount;
    let totalDiscount = null;
    if (newDiscount !== '') {
      totalDiscount = getPriceForSet(newDiscount, updateToSet, numSetQty);
    }

    eventingBus.publish(SAVE_BUTTON_META.eventbusKey, {
      id: SAVE_BUTTON_META.PRODUCT_DISCOUNT.id,
      hasChanged,
      data: totalDiscount,
      eventType: SAVE_BUTTON_META.eventType.PRODUCT_META.id
    });

    if (!updateSetPrice) {
      return true;
    }

    if (!isProductPriceValid(newPrice)) {
      setModifiedSetPrice('');
      return true;
    }

    if (isProductPriceValid(newDiscount)) {
      setModifiedSetPrice(
        roundNumberToGivenDecimals(Number(newDiscount) * numSetQty, 3).toString()
      );
      return true;
    }

    setModifiedSetPrice(roundNumberToGivenDecimals(Number(newPrice) * numSetQty, 3).toString());
    return true;
  };

  const onSetOptionChange = modifiedIsSet => {
    if (modifiedIsSet === updateToSet) {
      return;
    }

    setUpdateToSet(modifiedIsSet);
    if (modifiedIsSet) {
      setOriginalPrice(priceVal => getPricePerPieceAsString(priceVal, modifiedSetQuantity));
      setOriginalDiscount(priceVal => getPricePerPieceAsString(priceVal, modifiedSetQuantity));

      setModifiedPrice(priceVal => getPricePerPieceAsString(priceVal, modifiedSetQuantity));
      setModifiedDiscount(priceVal => getPricePerPieceAsString(priceVal, modifiedSetQuantity));

      setModifiedSetPrice(getPricePerPieceAsString(getProductPrice()));
      return;
    }

    // Re-calculate price and discounted price
    setOriginalPrice(priceVal => getPriceAsStringFromSetQty(priceVal, modifiedSetQuantity));
    setOriginalDiscount(priceVal => getPriceAsStringFromSetQty(priceVal, modifiedSetQuantity));

    setModifiedPrice(priceVal => getPriceAsStringFromSetQty(priceVal, modifiedSetQuantity));
    setModifiedDiscount(priceVal => getPriceAsStringFromSetQty(priceVal, modifiedSetQuantity));
  };

  const onSetTypeChange = modifiedSetType => {
    setUpdateSetType(modifiedSetType);
  };

  const onSetQuantityChange = quantity => {
    setModifiedSetQuantity(quantity);
    const updatedSetPrice = roundNumberToGivenDecimals(
      Number(getProductPrice()) * Number(quantity)
    ).toString();
    setModifiedSetPrice(updatedSetPrice);
    saveProductFieldsIfNeeded({ updatedSetQty: quantity });
  };

  const onSetPriceChange = updatedSetPrice => {
    if (updatedSetPrice === '') {
      onPriceChange('');
      return;
    }

    const numSetQty = getNumSetQty();

    // Update the discount or price field
    if (modifiedDiscount) {
      const updatedDiscountedPrice = getPricePerPieceAsString(updatedSetPrice, numSetQty);
      const discountUpdated = onDiscountChange(updatedDiscountedPrice, undefined, false);
      if (discountUpdated) {
        setModifiedSetPrice(updatedSetPrice);
      }
      return;
    }

    const updatedPrice = getPricePerPieceAsString(updatedSetPrice, numSetQty);
    onPriceChange(updatedPrice, false);
    setModifiedSetPrice(updatedSetPrice);
  };

  const modifiedPriceForDiscount = modifiedPrice !== '' ? Number(modifiedPrice) : '';

  // For bulk editing or for those users that can't view the feature, show product and discount next to each other
  if (isBulkEditing || !canUseFeature(FEATURE_LIST.SET_WISE_ORDERING.id)) {
    return (
      <div
        id={'ProductPriceAndDiscount'}
        className={isBulkEditing ? 'bulkPriceAndDiscountContainer' : ''}
      >
        <ProductPrice
          price={modifiedPrice}
          originalPrice={originalPrice}
          informDiscountOfPriceChange={onPriceChange}
          activeProductId={activeProductId}
          activeProductIds={activeProductIds}
          isBulkEditing={isBulkEditing}
          currencySymbol={currencySymbol}
        />
        <ProductDiscount
          discount={modifiedDiscount}
          originalDiscount={originalDiscount}
          originalPrice={originalPrice}
          price={modifiedPriceForDiscount}
          isSetSelected={updateToSet}
          currencySymbol={currencySymbol}
          handleDiscountChange={onDiscountChange}
          activeProductId={activeProductId}
          activeProductIds={activeProductIds}
          isBulkEditing={isBulkEditing}
          bulkContainerClass="productBulkDiscountContainer"
          discountPercentAbsolute={true}
        />
      </div>
    );
  }

  if (!updateToSet) {
    return (
      <>
        <div id={'ProductPriceAndDiscount'}>
          <ProductPrice
            price={modifiedPrice}
            originalPrice={originalPrice}
            informDiscountOfPriceChange={onPriceChange}
            activeProductId={activeProductId}
            activeProductIds={activeProductIds}
            isBulkEditing={isBulkEditing}
            currencySymbol={currencySymbol}
            setQuantity={modifiedSetQuantity}
          />
          <ProductSetDropdown
            isSet={updateToSet}
            setIsSet={onSetOptionChange}
            originalSetValue={isSet}
            setType={updateSetType}
            setSetType={onSetTypeChange}
            originalSetType={setType}
            loading={loading}
          />
        </div>
        <ProductDiscount
          discount={modifiedDiscount}
          originalDiscount={originalDiscount}
          originalPrice={originalPrice}
          isSetSelected={updateToSet}
          price={modifiedPriceForDiscount}
          currencySymbol={currencySymbol}
          handleDiscountChange={onDiscountChange}
          activeProductId={activeProductId}
          singleContainerClass="productDiscountContainerWithSet"
          singleButtonContainerClass="productDiscountButtonContainer"
        />
      </>
    );
  }

  return (
    <>
      <ProductSetDropdown
        isSet={updateToSet}
        setIsSet={onSetOptionChange}
        originalSetValue={isSet}
        setType={updateSetType}
        setSetType={onSetTypeChange}
        originalSetType={setType}
        loading={loading}
        containerClass="productPriceSetDisabledContainer"
      />
      <div className="productPriceSetContainer">
        <ProductPrice
          price={modifiedPrice}
          originalPrice={originalPrice}
          informDiscountOfPriceChange={onPriceChange}
          activeProductId={activeProductId}
          activeProductIds={activeProductIds}
          isBulkEditing={isBulkEditing}
          currencySymbol={currencySymbol}
          setQuantity={modifiedSetQuantity}
        />
        {!loading && (
          <ProductSetQuantity
            setName={setName}
            setQuantity={modifiedSetQuantity}
            existingSetQuantity={originalSetQuantity}
            onSetQuantityChange={onSetQuantityChange}
          />
        )}
      </div>
      <ProductDiscount
        discount={modifiedDiscount}
        originalDiscount={originalDiscount}
        originalPrice={originalPrice}
        price={modifiedPriceForDiscount}
        currencySymbol={currencySymbol}
        isSetSelected={updateToSet}
        handleDiscountChange={onDiscountChange}
        activeProductId={activeProductId}
        singleContainerClass="productDiscountContainerWithSet"
        singleButtonContainerClass="productDiscountButtonContainer"
      />
      {!loading && (
        <ProductSetPriceField
          currencySymbol={currencySymbol}
          setPrice={modifiedSetPrice}
          onChangeSetPrice={onSetPriceChange}
          discount={modifiedDiscount}
        />
      )}
      {!loading && <ProductSetName setName={setName} />}
    </>
  );
};
