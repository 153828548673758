export const getE16Number = ({ callingCode, nationalNumberRaw }) => {
  const nationalNumber = nationalNumberRaw
    .trim()
    .split(' ')
    .join('')
    .split('-')
    .join('');

  return callingCode + nationalNumber;
};
