import React from 'react';
import { ReactComponent as CustomProductMagazineTileIcon } from 'qs-assets/Media/ic_product_magazine_tile_icon.svg';
import './styles.scss';

export default ({ width, height, fill, classes = '' }) => {
  return (
    <CustomProductMagazineTileIcon
      style={{ width, height, fill }}
      className={`settingsIconComponent ${classes}`}
    />
  );
};
