import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Modal, withStyles, Backdrop } from '@material-ui/core';
import Loader from '../Loader';
import ErrorIcon from '../ErrorIcon';
import { convertImageUrlSize } from 'qs-helpers';
import usePictureRetryHook from 'qs-hooks/usePictureRetryHook';
import { IMAGE_LOADER, IMAGE_ERROR, IMAGE_HIDE_LOADER } from 'qs-helpers/PicturesProcessingHelper';
import { ReactComponent as LeftArrow } from 'qs-assets/Media/gallery-icon-left.svg';
import { ReactComponent as RightArrow } from 'qs-assets/Media/gallery-icon-right.svg';
import './styles.scss';

const ImageBackDrop = withStyles({
  root: {
    backgroundColor: 'rgba(0,0,0,0.85)'
  }
})(Backdrop);

export default ({ picturesData, onClose, children, startIndex = 0 }) => {
  const [currentIndex, setCurrentIndex] = useState(startIndex);

  // Ensure that if picturesData changes but startIndex does not change then this code does not break
  const pictureData = picturesData[currentIndex] || {};
  const imageUrl = useMemo(() => convertImageUrlSize({ size: 'FULL', url: pictureData.url }), [
    pictureData.url
  ]);

  const [
    picturesLoadState,
    handleImageLoad,
    handleImageError,
    updateTimeoutRefForPictures
  ] = usePictureRetryHook();

  useEffect(() => {
    updateTimeoutRefForPictures([pictureData]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pictureData.id, updateTimeoutRefForPictures]);

  useEffect(() => {
    setCurrentIndex(startIndex);
  }, [startIndex]);

  const contentBackdropClick = event => {
    event.preventDefault();
    if (
      event.target.id === 'content-backdrop' ||
      event.target.id === 'image-navigation-container' ||
      event.target.id === 'image-container'
    ) {
      onClose();
    }
  };

  const canHidePrevious = () => currentIndex === 0;

  const canHideNext = () => currentIndex + 1 === picturesData.length;

  const onPreviousClick = () => {
    if (canHidePrevious()) {
      return;
    }
    setCurrentIndex(currentIndex - 1);
  };

  const onNextClick = () => {
    if (canHideNext()) {
      return;
    }
    setCurrentIndex(currentIndex + 1);
  };

  const getPictureData = () => {
    const { state = IMAGE_LOADER, retryCount = 0 } = picturesLoadState[pictureData.id] || {};

    if (pictureData.error || state === IMAGE_ERROR) {
      return <ErrorIcon width={80} height={80} />;
    }

    if (pictureData.prepared) {
      return (
        <Fragment>
          {state === IMAGE_LOADER && (
            <div className="imageLoader">
              <Loader size={'large'} />
            </div>
          )}
          <img
            key={`${pictureData.id}-${retryCount}`}
            onError={handleImageError(pictureData.id, IMAGE_ERROR)}
            onLoad={handleImageLoad(pictureData.id, IMAGE_HIDE_LOADER)}
            src={imageUrl}
            className={'imageFull'}
            alt={''}
          />
        </Fragment>
      );
    }
    return <Loader size={'large'} />;
  };

  const getImageGalleryView = () => {
    if (pictureData) {
      let leftArrowClass = '';
      let rightArrowClass = '';
      if (canHidePrevious()) {
        leftArrowClass = 'hidden';
      }
      if (canHideNext()) {
        rightArrowClass = 'hidden';
      }

      //Both should be hidden, don't display the arrow at all
      if (leftArrowClass && rightArrowClass) {
        rightArrowClass = 'hideDisplay';
        leftArrowClass = 'hideDisplay';
      }

      return (
        <div id="content-backdrop" className="singleImageContent" onClick={contentBackdropClick}>
          {children}
          <div className="productsImageNavigation" id="image-navigation-container">
            <LeftArrow onClick={onPreviousClick} className={`arrow ${leftArrowClass}`} />
            <div className="imageParentContainer" id="image-container">
              {getPictureData()}
            </div>
            <RightArrow onClick={onNextClick} className={`arrow ${rightArrowClass}`} />
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <Modal
      open={true}
      onClose={onClose}
      BackdropComponent={ImageBackDrop}
      className="imageFullViewContainer"
    >
      {getImageGalleryView()}
    </Modal>
  );
};
