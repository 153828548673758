import { EXCEL_UPLOAD_META } from 'qs-data-manager/Catalogues';
import React from 'react';
import ImagePreview from '../ImagePreview';
import './styles.scss';
import { getI18N } from 'qs-services/i18N';

const renderAdditionalPictures = ({ rowId, previewMeta, excelComponentMap }) => {
  const previewTitle = excelComponentMap[rowId].previewTitle;
  const { t } = getI18N();

  return (
    <>
      {previewMeta[rowId].length > 1 ? (
        <div className={'title'}>
          {typeof previewTitle === 'string' ? t(previewTitle) : previewTitle}
        </div>
      ) : null}
      <div className={'additional-pictures'}>
        {previewMeta[rowId].map((url, index) =>
          index === 0 ? null : <ImagePreview key={index} url={url} />
        )}
      </div>
    </>
  );
};

export default ({ rowId, previewMeta, excelComponentMap }) => {
  const rowData = excelComponentMap[rowId] || {};
  const { t } = getI18N();

  const getPreviewTitle = () => {
    let { previewTitle, parentId } = rowData;
    if (typeof previewTitle === 'string') {
      return previewTitle;
    }

    if (!parentId) {
      return '';
    }

    ({ previewTitle } = excelComponentMap[parentId] || {});
    return previewTitle || '';
  };

  const getPreviewValue = () => {
    const { getApiFormattedValue, getPreviewFormattedValue } = rowData;
    if (typeof getPreviewFormattedValue === 'function') {
      return getPreviewFormattedValue(previewMeta[rowId]);
    }

    if (typeof getApiFormattedValue !== 'function') {
      return;
    }
    return getApiFormattedValue(previewMeta[rowId]);
  };

  const { disabled } = rowData;
  const previewTitle = getPreviewTitle();
  const value = getPreviewValue();
  let disabledClass = '';
  if (disabled) {
    disabledClass = 'disabled';
  }

  return (
    <div id={`product-row-${rowId}`} className={`excel-preview-row`}>
      {rowId === EXCEL_UPLOAD_META.IMAGE.id ? (
        renderAdditionalPictures({ rowId, previewMeta, excelComponentMap })
      ) : (
        <>
          <div className={`title ${disabledClass}`}>{previewTitle}</div>
          <div
            className={`value ${disabledClass} ${
              typeof value === 'undefined' || value === 'UNDEFINED' ? 'italics' : ''
            }`}
          >
            {typeof value === 'undefined' || value === 'UNDEFINED' ? t('empty') : value}
          </div>
        </>
      )}
    </div>
  );
};
