import { PRODUCT_VARIANT_OPTIONS, GLOBAL_OPTIONS } from 'qs-api/Variants/ApiCacheConnector';
import CacheRequest from 'qs-data-manager/CacheRequest';
import {
  deleteOptionsData,
  createProductOption,
  updateProductOption,
  updateProductOptionQuantity,
  reorderOptions
} from 'qs-api/Variants/api';
import { reportError } from 'qs-helpers/ErrorReporting';
import { getVariantsDataForProduct } from './FetchVariants';
import { VARIANT_ADD_TABS } from 'qs-helpers/Variants/constants';
import { setProductMetaInCache, getProductMetaFromCache } from '../Products';
import {
  convertOptionsDataToProductOptionsFormat,
  getOptionsDataToSet
} from 'qs-helpers/Variants/ResponseProcessor';
import CacheListenerCallback from 'qs-helpers/CacheListenerCallback';
export const attachProductOptionsListener = ({ listener, productId }) => {
  const key = `${PRODUCT_VARIANT_OPTIONS.cacheKey}${productId}`;
  CacheRequest.attachListener(key, listener);
};

export const removeProductOptionsListener = ({ listener, productId }) => {
  const key = `${PRODUCT_VARIANT_OPTIONS.cacheKey}${productId}`;
  CacheRequest.removeListener(key, listener);
};

const updateProductOptionsMeta = productId => {
  const listener = (error, payload) => {
    const { err, loading, refreshing, data } = CacheListenerCallback(error, payload);
    if (err || loading || refreshing || !data) {
      return;
    }

    // Data received, remove the listener and proceed with the execution
    removeProductOptionsListener({ productId, listener });
    const { options } = data;
    if (!Array.isArray(options)) {
      return;
    }
    const optionsMeta = {};
    options.map((option, index) => {
      const { data: optionData, type: optionType } = option[index];
      if (optionData.length) {
        optionsMeta[optionType] = convertOptionsDataToProductOptionsFormat(optionData);
      }
      return optionsMeta;
    });

    // One of the data exists, replace the one held by the product cache
    if (Object.keys(optionsMeta).length) {
      setProductMetaInCache({
        productId,
        updates: {
          optionsMeta
        }
      });
      return;
    }

    // No options found in the fresh call, remove the optionsMeta from the product data
    setProductMetaInCache({
      productId,
      updates: {
        optionsMeta: undefined
      }
    });
  };
  attachProductOptionsListener({ productId, listener });
};

export const getProductOptionsData = ({ productId }) => {
  const key = `${PRODUCT_VARIANT_OPTIONS.cacheKey}${productId}`;
  const apiCall = PRODUCT_VARIANT_OPTIONS.apiFunction;

  // Attaches the listener to update the product data when the options are loaded
  updateProductOptionsMeta(productId);
  CacheRequest.makeRequest(key, apiCall, {
    params: [productId],
    options: {
      shouldNotStoreInNative: true
    }
  });
};

export const getProductOptionsFromCache = productId => {
  const key = `${PRODUCT_VARIANT_OPTIONS.cacheKey}${productId}`;
  const productOptions = CacheRequest.getCacheForKey(key) || null;
  return productOptions;
};

export const setProductOptionsInCache = ({ productId, updates }) => {
  const key = `${PRODUCT_VARIANT_OPTIONS.cacheKey}${productId}`;
  const productOptions = CacheRequest.getCacheForKey(key) || {};
  CacheRequest.setCacheForKey(key, {
    ...productOptions,
    ...updates
  });
};

export const getCurrentProductOptionsListFromCache = ({ productId, currentTab }) => {
  const key = `${PRODUCT_VARIANT_OPTIONS.cacheKey}${productId}`;
  const productOptions = CacheRequest.getCacheForKey(key);
  if (!productOptions) {
    return [];
  }
  const currentTabOption = productOptions.options.find(currValue => currValue.type === currentTab);
  if (!currentTabOption) {
    return [];
  }
  return currentTabOption.data || [];
};

const updateOptionsDataForParentProduct = ({
  productId,
  currentTab,
  modifiedOptionsData,
  productOptions
}) => {
  const { optionsMeta } = getProductMetaFromCache(productId) || {};
  const { options } = productOptions;
  const existingOptions = optionsMeta || {};
  const convertedOptionFormat = convertOptionsDataToProductOptionsFormat(modifiedOptionsData);
  const formatedUpdateOptionData = getOptionsDataToSet(convertedOptionFormat);
  const previousOptions = {};

  options.map(option => {
    if (optionsMeta && optionsMeta.hasOwnProperty(option.type)) {
      previousOptions[option.type] = existingOptions[option.type];
    }
    return previousOptions;
  });

  const updatedData = {
    ...previousOptions,
    [currentTab]: formatedUpdateOptionData
  };

  setProductMetaInCache({
    productId,
    updates: {
      optionsMeta: updatedData
    }
  });
};
export const reorderOptionsProduct = async ({
  optionId,
  newIndex,
  productId,
  optionsList,
  currentTab
}) => {
  // Save changes to cache
  reorderOptionsInCache({ productId, optionsList, currentTab });

  try {
    await reorderOptions({ optionId, newIndex });
  } catch (reOrderOptionError) {
    reportError(reOrderOptionError);
    throw reOrderOptionError;
  }
  return true;
};

const reorderOptionsInCache = ({ productId, optionsList, currentTab }) => {
  setModifiedOptionsDataInCache({
    productId,
    modifiedOptionsData: optionsList,
    currentTab
  });
};
const setModifiedOptionsDataInCache = ({ productId, currentTab, modifiedOptionsData }) => {
  const key = `${PRODUCT_VARIANT_OPTIONS.cacheKey}${productId}`;
  const productOptions = { ...CacheRequest.getCacheForKey(key) };
  const optionIndex = productOptions.options.findIndex(currValue => currValue.type === currentTab);
  const currentTabOption = productOptions.options[optionIndex];
  if (!currentTabOption || !currentTabOption.data) {
    return;
  }

  productOptions.options[optionIndex].data = modifiedOptionsData;
  CacheRequest.setCacheForKey(key, productOptions);

  // Update the existing meta options of the current product
  updateOptionsDataForParentProduct({ productId, currentTab, modifiedOptionsData, productOptions });
};

export const deleteProductOption = async ({ productId, optionId, currentTab }) => {
  try {
    await deleteOptionsData(productId, optionId);
  } catch (deleteOptionError) {
    reportError(deleteOptionError);
    throw deleteOptionError;
  }

  // Refresh the product list
  getVariantsDataForProduct({ productId });

  const currentTabOptionData = getCurrentProductOptionsListFromCache({ productId, currentTab });
  const modifiedOptionsData = currentTabOptionData.reduce((newOptions, optionData) => {
    if (optionData.id !== optionId) {
      newOptions.push(optionData);
    }
    return newOptions;
  }, []);

  setModifiedOptionsDataInCache({ productId, currentTab, modifiedOptionsData });
};

export const createNewOption = async optionData => {
  let createdOption = null;
  const {
    optionValue,
    rgbValue,
    optionType,
    parentProductId,
    isSet,
    setQuantity,
    optionTypeId
  } = optionData;
  try {
    const optionsForRemote = {
      optionValue,
      parentProductId,
      optionType,
      rgbValue,
      isSet,
      setQuantity,
      optionTypeId
    };
    const createdOptions = await createProductOption([optionsForRemote]);
    createdOption = createdOptions.data[0];
  } catch (error) {
    reportError(error);
    throw error;
  }

  // Refresh the product list
  getVariantsDataForProduct({ productId: parentProductId });

  const currentTabOptionData = getCurrentProductOptionsListFromCache({
    productId: parentProductId,
    currentTab: optionType
  });

  const newOptionData = {
    id: createdOption.id,
    label: optionValue
  };

  if (optionType === VARIANT_ADD_TABS.COLORS) {
    newOptionData.rgb = rgbValue;
  }

  if (isSet) {
    newOptionData.isSet = isSet;
    newOptionData.setQuantity = setQuantity;
  }

  const modifiedOptionsData = [...currentTabOptionData];
  modifiedOptionsData.push(newOptionData);

  setModifiedOptionsDataInCache({
    productId: parentProductId,
    currentTab: optionType,
    modifiedOptionsData
  });
};

// The cache and remote options are spit because the view must be updated in a controlled manner
export const updateExistingOptionInCache = ({
  id,
  label,
  rgb,
  isSet,
  setQuantity,
  optionType,
  parentProductId
}) => {
  //Refresh the product variant list
  getVariantsDataForProduct({ productId: parentProductId });

  const currentTabOptionData = getCurrentProductOptionsListFromCache({
    productId: parentProductId,
    currentTab: optionType
  });

  const modifiedOptionsData = currentTabOptionData.map(optionDatum => {
    if (id === optionDatum.id) {
      const newLabel = label || optionDatum.label;
      const newRgb = rgb || optionDatum.rgb;
      const newSetVal = isSet || optionDatum.isSet;
      const newSetQuantity = setQuantity || optionDatum.setQuantity;
      return {
        ...optionDatum,
        label: newLabel,
        rgb: newRgb,
        isSet: newSetVal,
        setQuantity: newSetQuantity
      };
    }

    return optionDatum;
  });

  setModifiedOptionsDataInCache({
    productId: parentProductId,
    currentTab: optionType,
    modifiedOptionsData
  });
};

export const updateExistingOptionInRemote = async optionData => {
  try {
    await updateProductOption(optionData);
  } catch (error) {
    reportError(error);
    throw error;
  }
};

export const updateExistingOptionSetQuantity = async optionData => {
  try {
    await updateProductOptionQuantity(optionData);
  } catch (error) {
    reportError(error);
    throw error;
  }

  updateExistingOptionInCache(optionData);
};

export const attachGlobalOptionsListener = ({ listener, optionType }) => {
  const key = `${GLOBAL_OPTIONS.cacheKey}${optionType}`;
  CacheRequest.attachListener(key, listener);
};

export const removeGlobalOptionsListener = ({ listener, optionType }) => {
  const key = `${GLOBAL_OPTIONS.cacheKey}${optionType}`;
  CacheRequest.removeListener(key, listener);
};

export const getGlobalOptionsData = optionType => {
  const key = `${GLOBAL_OPTIONS.cacheKey}${optionType}`;
  const apiCall = GLOBAL_OPTIONS.apiFunction;
  CacheRequest.makeRequest(key, apiCall, {
    params: [optionType],
    options: {
      shouldNotStoreInNative: true
    }
  });
};

export const getGlobalOptionsFromCache = optionType => {
  const key = `${GLOBAL_OPTIONS.cacheKey}${optionType}`;
  const globalOptionColors = CacheRequest.getCacheForKey(key) || {};
  return globalOptionColors.data;
};

const isDeletedOptionsSetEmpty = deletedOptionIdsSet => {
  if (!(deletedOptionIdsSet instanceof Set)) {
    return true;
  }

  if (!deletedOptionIdsSet.size) {
    return true;
  }

  return false;
};

const deleteOptionFromProductOptions = ({ productId, deletedOptionIdsSet }) => {
  const productOptions = getProductOptionsFromCache(productId);
  if (!productOptions || isDeletedOptionsSetEmpty(deletedOptionIdsSet)) {
    return;
  }

  const modifiedOptions = productOptions.options.map(optionMeta => {
    const { data } = optionMeta;
    return {
      ...optionMeta,
      data: data.filter(({ id }) => !deletedOptionIdsSet.has(id))
    };
  });
  setProductOptionsInCache({
    productId,
    updates: {
      options: modifiedOptions
    }
  });
};

const deleteOptionFromProductMeta = ({ productId, deletedOptionIdsSet }) => {
  const productMeta = getProductMetaFromCache(productId);
  if (!(productMeta && productMeta.optionsMeta) || isDeletedOptionsSetEmpty(deletedOptionIdsSet)) {
    return;
  }

  const { optionsMeta } = { ...productMeta };
  for (const optionType in optionsMeta) {
    if (!optionsMeta.hasOwnProperty(optionType)) {
      continue;
    }

    const optionData = optionsMeta[optionType];
    if (!Array.isArray(optionData)) {
      continue;
    }

    // Remove all those options that are present in the set
    const filteredOptions = optionData.filter(({ optionId }) => !deletedOptionIdsSet.has(optionId));
    // Ensure an empty array is not set as the data
    optionsMeta[optionType] = getOptionsDataToSet(filteredOptions);
  }

  setProductMetaInCache({
    productId,
    updates: {
      optionsMeta
    }
  });
};

export const deleteProductOptions = ({ productId, deletedOptionIds }) => {
  if (!Array.isArray(deletedOptionIds)) {
    return;
  }

  const deletedOptionIdsSet = deletedOptionIds.reduce((optionsIdSet, optionData) => {
    if (!(optionData && Array.isArray(optionData.deletedOptionIds))) {
      return optionsIdSet;
    }

    optionData.deletedOptionIds.forEach(optionId => {
      optionsIdSet.add(optionId);
    });
    return optionsIdSet;
  }, new Set());
  deleteOptionFromProductMeta({ productId, deletedOptionIdsSet });
  deleteOptionFromProductOptions({ productId, deletedOptionIdsSet });
};
