import React, { Component } from 'react';
import Route from 'route-parser';
import PropTypes from 'prop-types';
import Loader from 'qs-components/Common/Loader';

import './styles.scss';
import { onLogin, AUTH_PROVIDERS, IS_LOGGING_IN, onLogout } from 'qs-data-manager/Authentication';
import { ROUTE_MAP } from 'qs-helpers/CompanySettings/constants';
import eventbus from 'eventing-bus';
import WithNavRef from 'qs-helpers/WithNavRef';
import Mixpanel from 'qs-data-manager/Mixpanel';
import UnauthorizedLogin from './UnauthorizedLogin';
import LoginErrorDialogue from './LoginErrorDialogue';
import ErrorPopup from '../../Common/ErrorPopup';
import SetupCompany from './SetupCompany';
import LoginBanner from './LoginBanner';
import LoginContent from './LoginContent';
import { openPopup } from 'qs-utils';

const INITIAL_STATE = {
  showLoader: {
    isLoggingIn: false
  },
  error: {
    message: '',
    didError: false,
    title: '',
    description: ''
  },
  notAllowedToUse: false,
  userEmail: '',
  isNewUser: false,
  currentPlan: undefined,
  webStorageUnsupported: false,
  setupIncomplete: false,
  user: {},
  company: {}
};

const whatsappCodeError = 'whatsappCodeError';

class Login extends Component {
  static propTypes = {
    showRedirectionLoader: PropTypes.bool,
    loggingIn: PropTypes.bool,
    isNewUser: PropTypes.bool,
    isPlatinumUser: PropTypes.bool,
    userEmail: PropTypes.string,
    onGoogleLoginFail: PropTypes.func,
    setPlatinumUser: PropTypes.func,
    planType: PropTypes.string,
    setPlanType: PropTypes.func
  };

  state = INITIAL_STATE;

  componentDidMount() {
    const {
      location: { pathname }
    } = this.props;

    eventbus.on(IS_LOGGING_IN.eventbusKey, authState => {
      if (typeof authState.webStorageUnsupported === 'boolean') {
        this.setState({ webStorageUnsupported: authState.webStorageUnsupported });
      }

      if (typeof authState.setupIncomplete === 'boolean') {
        this.setState({
          setupIncomplete: authState.setupIncomplete,
          company: authState.company,
          user: authState.user
        });
      }

      if (typeof authState.isLoggingIn !== 'undefined') {
        let loader = { ...this.state.showLoader };
        loader.isLoggingIn = authState.isLoggingIn;

        this.setState({ showLoader: loader });
      }

      if (typeof authState.didError !== 'undefined') {
        if (authState.message === whatsappCodeError) {
          this.setState({
            error: {
              message: authState.message,
              didError: authState.didError,
              title: 'Code Expired',
              description: 'The whatsapp code is either expired or incorrect. Please try again!'
            }
          });
        } else {
          this.setState({
            error: {
              message: authState.message,
              didError: authState.didError,
              title: 'Login Failed',
              description:
                'Something went wrong. Please try again later or contact customer support'
            }
          });
        }
      }

      if (typeof authState.isNewUser !== 'undefined') {
        this.setState({
          isNewUser: authState.isNewUser
        });
      }

      if (typeof authState.notAllowedToUse !== 'undefined') {
        this.setState({
          notAllowedToUse: authState.notAllowedToUse,
          userEmail: authState.userEmail || '',
          currentPlan: authState.currentPlan
        });
      }
    });

    const loginCode = new Route(`/${ROUTE_MAP.LOGIN}/:id`).match(pathname).id;
    if (loginCode) {
      onLogin(AUTH_PROVIDERS.WHATSAPP, loginCode);
    }
  }

  handleLogin = provider => {
    Mixpanel.sendEvent({
      eventName: 'sign_in_button_clicked',
      props: {
        provider
      }
    });
    onLogin(provider);
  };

  handleLogout = () => {
    onLogout();
    this.setState(INITIAL_STATE);
  };

  renderLoader = () => {
    const { showLoader } = this.state;
    const shouldShow = Object.values(showLoader || {}).filter(value => !!value).length;
    if (!shouldShow) {
      return null;
    }

    return (
      <div className="loginCardLoading">
        <div className="loginLoaderContainer">
          <Loader size="large" />
          <div className="singingInText">Signing in…</div>
        </div>
      </div>
    );
  };

  isWhatsappCodeError = errorMessage => errorMessage === whatsappCodeError;

  closeErrorModal = () => {
    if (this.isWhatsappCodeError(this.state.error.message)) {
      window.location.replace(`/${ROUTE_MAP.LOGIN}`);
    }
    this.setState({
      error: {
        message: '',
        didError: false,
        title: '',
        description: ''
      }
    });
  };

  redirectToPayment = () => {
    openPopup('https://quicksell.co/pricing?ref=quicksell.co');
  };

  onErrorPopupClose = () => {
    this.setState({ webStorageUnsupported: false, setupIncomplete: false });
  };

  redirectToCookies = () => {
    openPopup(
      'https://learn.quicksell.co/en/articles/4240542-i-m-unable-to-sign-in-on-quicksell-web-web-quicksell-co'
    );
  };

  renderLoginOrSetupCompanyContent = () => {
    const { isNewUser, setupIncomplete, user, company } = this.state;
    if (setupIncomplete) {
      return <SetupCompany user={user} company={company} onSetupLogoutClick={this.handleLogout} />;
    }

    return (
      <>
        {this.renderLoader()}
        <LoginContent handleLogin={this.handleLogin} isNewUser={isNewUser} />
      </>
    );
  };

  render() {
    const { notAllowedToUse, error, webStorageUnsupported } = this.state;

    return (
      <WithNavRef navigationRef={this.props}>
        <div className="login">
          <UnauthorizedLogin
            handleLogout={this.handleLogout}
            notAllowedToUse={notAllowedToUse}
            redirectToPayment={this.redirectToPayment}
          />

          {!!webStorageUnsupported && (
            <ErrorPopup
              title={'3rd party cookies blocked'}
              showContactSupport={true}
              onClose={this.onErrorPopupClose}
              primaryButtonLabel={'VIEW DOCUMENTATION'}
              primaryButtonClick={this.redirectToCookies}
            >
              Please enable 3rd party cookies to sign into QuickSell.
              <div className={'cookie-redirection'} onClick={this.redirectToCookies}>
                Click here to see how to enable 3rd party cookies
              </div>
            </ErrorPopup>
          )}
          <LoginBanner />
          <div className="loginCard">{this.renderLoginOrSetupCompanyContent()}</div>
          <LoginErrorDialogue
            didError={error.didError}
            error={error}
            showSupportButton={!this.isWhatsappCodeError(error.message)}
            closeErrorModal={this.closeErrorModal}
          />
        </div>
      </WithNavRef>
    );
  }
}

export default Login;
