import ProductGridTileIcon from 'qs-assets/Media/product_grid_tile_icon.svg';
import { GRAPES_JS_PLUGINS } from '../Plugins';
import { GRAPES_JS_DEFAULT_CONFIG } from '../Plugins/config';
import { capitalizeFirstLetter } from 'qs-helpers';
import { getI18N } from 'qs-services/i18N';
import {
  ACCENT_COLOR,
  WHITE_COLOR,
  BLACK_COLOR,
  TEXT_COLOR,
  BORDER_COLOR,
  PRODUCT_GRID_TILE_ELEMENT_TYPES,
  PRODUCT_GRID_TILE_VARIABLES_MAP,
  PRODUCT_GRID_TILE_CLASSNAMES_MAP,
  PRODUCT_GRID_TILE_CLASSNAMES_PREFIX
} from '../Constants';

export const PRODUCT_GRID_TILE_ELEMENT_TYPE_TRAIT_ID = `data-${PRODUCT_GRID_TILE_CLASSNAMES_PREFIX}-element-type`;

export const PRODUCT_GRID_TILE_ELEMENT_TYPE_TRAIT = {
  get CONFIG() {
    const { t } = getI18N();
    return {
      type: 'select',
      label: t('product_grid_tile_element_type'),
      name: PRODUCT_GRID_TILE_ELEMENT_TYPE_TRAIT_ID,
      placeholder: t('enter_product_grid_tile_element_type'),
      default: 'none',
      options: [{
          id: 'none',
          name: t('none')
        },
        ...PRODUCT_GRID_TILE_ELEMENT_TYPES.CONFIG
      ]
    };
  }
};

export const PRODUCT_GRID_TILE_CUSTOM_COMPONENT = {
  get HTML() {
    const { t } = getI18N();
    return `
      <div
        class="${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileContainer} gjs-row"
        data-${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileId}="${PRODUCT_GRID_TILE_VARIABLES_MAP.productId}"
        data-quicksell-component-custom-name="${t('product_grid_tile')}"
        data-quicksell-component-type="${PRODUCT_GRID_TILE_CLASSNAMES_PREFIX}"
        data-quicksell-component-editable="false"
        data-quicksell-component-removable="false"
        data-quicksell-component-copyable="false"
        data-quicksell-component-draggable="false"
      >
        <div
          class="${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileImageContainer} gjs-cell"
          data-${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileImageContainer}="${PRODUCT_GRID_TILE_VARIABLES_MAP.productId}"
          data-quicksell-component-type="default"
          data-quicksell-component-editable="false"
          data-quicksell-component-removable="false"
          data-quicksell-component-copyable="false"
          data-quicksell-component-draggable="false"
        >
          <div
            class="${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileDiscountBadge}"
            data-${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileDiscountBadge}="${PRODUCT_GRID_TILE_VARIABLES_MAP.productId}"
            data-${PRODUCT_GRID_TILE_CLASSNAMES_PREFIX}-element-type="${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileDiscountBadge}"
            data-quicksell-component-type="text"
            data-quicksell-component-editable="false"
            data-quicksell-component-removable="false"
            data-quicksell-component-copyable="false"
            data-quicksell-component-draggable="false"
          >
            ${PRODUCT_GRID_TILE_VARIABLES_MAP.discountPercent}
          </div>
          <div
            class="${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileOutOfStockBadge}"
            data-${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileOutOfStockBadge}="${PRODUCT_GRID_TILE_VARIABLES_MAP.productId}"
            data-${PRODUCT_GRID_TILE_CLASSNAMES_PREFIX}-element-type="${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileOutOfStockBadge}"
            data-quicksell-component-type="text"
            data-quicksell-component-editable="false"
            data-quicksell-component-removable="false"
            data-quicksell-component-copyable="false"
            data-quicksell-component-draggable="false"
          >
            ${PRODUCT_GRID_TILE_VARIABLES_MAP.outOfStockText}
          </div>
          <svg
            class="${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileVideoBadge}"
            data-${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileVideoBadge}="${PRODUCT_GRID_TILE_VARIABLES_MAP.productId}"
            data-${PRODUCT_GRID_TILE_CLASSNAMES_PREFIX}-element-type="${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileVideoBadge}"
            viewBox="0 0 24 24"
            height="24px"
            width="24px"
            fill="${ACCENT_COLOR}"
            data-quicksell-component-custom-name="${capitalizeFirstLetter(t('svg'))}"
            data-quicksell-component-type="svg"
            data-quicksell-component-editable="false"
            data-quicksell-component-removable="false"
            data-quicksell-component-copyable="false"
            data-quicksell-component-draggable="false"
          >
            <path
              d="M20, 3H4C2.897, 3, 2, 3.897, 2, 5v14c0, 1.103, 0.897, 2, 2, 2h16c1.103, 0, 2-0.897, 2-2V5C22, 3.897, 21.103, 3, 20, 3z M9, 16V8l7, 4L9, 16z"
              data-quicksell-component-type="svg-in"
              data-quicksell-component-editable="false"
              data-quicksell-component-removable="false"
              data-quicksell-component-copyable="false"
              data-quicksell-component-draggable="false"
            >
            </path>
          </svg>
          <div
            class="${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTilePhotoCountBadge}"
            data-${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTilePhotoCountBadge}="${PRODUCT_GRID_TILE_VARIABLES_MAP.productId}"
            data-${PRODUCT_GRID_TILE_CLASSNAMES_PREFIX}-element-type="${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTilePhotoCountBadge}"
            data-quicksell-component-type="text"
            data-quicksell-component-editable="false"
            data-quicksell-component-removable="false"
            data-quicksell-component-copyable="false"
            data-quicksell-component-draggable="false"
          >
            ${PRODUCT_GRID_TILE_VARIABLES_MAP.pictureCount}
          </div>
          <img
            class="${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileImage}"
            data-${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileImage}="${PRODUCT_GRID_TILE_VARIABLES_MAP.productId}"
            data-${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileImageId}="${PRODUCT_GRID_TILE_VARIABLES_MAP.pictureId}"
            data-${PRODUCT_GRID_TILE_CLASSNAMES_PREFIX}-element-type="${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileImage}"
            src="${PRODUCT_GRID_TILE_VARIABLES_MAP.pictureUrl}"
            alt="${PRODUCT_GRID_TILE_VARIABLES_MAP.pictureAlt}"
            decoding="sync"
            loading="lazy"
            height="${PRODUCT_GRID_TILE_VARIABLES_MAP.pictureHeight}"
            data-${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileImageHeight}="${PRODUCT_GRID_TILE_VARIABLES_MAP.pictureHeight}"
            width="${PRODUCT_GRID_TILE_VARIABLES_MAP.pictureWidth}"
            data-${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileImageWidth}="${PRODUCT_GRID_TILE_VARIABLES_MAP.pictureWidth}"
            data-quicksell-component-type="image"
            data-quicksell-component-editable="false"
            data-quicksell-component-removable="false"
            data-quicksell-component-copyable="false"
            data-quicksell-component-draggable="false"
          />
        </div>
        <div
          class="${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileDetailsContainer} gjs-cell"
          data-${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileDetailsContainer}="${PRODUCT_GRID_TILE_VARIABLES_MAP.productId}"
          data-quicksell-component-type="default"
          data-quicksell-component-editable="false"
          data-quicksell-component-removable="false"
          data-quicksell-component-copyable="false"
          data-quicksell-component-draggable="false"
        >
          <div
            class="${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileVariantDetailsContainer}"
            data-${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileVariantDetailsContainer}="${PRODUCT_GRID_TILE_VARIABLES_MAP.productId}"
            data-quicksell-component-type="default"
            data-quicksell-component-editable="false"
            data-quicksell-component-removable="false"
            data-quicksell-component-copyable="false"
            data-quicksell-component-draggable="false"
          >
            <div
              class="${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileVariantColors}"
              data-${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileVariantColors}="${PRODUCT_GRID_TILE_VARIABLES_MAP.productId}"
              data-quicksell-component-type="default"
              data-quicksell-component-editable="false"
              data-quicksell-component-removable="false"
              data-quicksell-component-copyable="false"
              data-quicksell-component-draggable="false"
            >
              <div
                class="${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileVariantColor}"
                data-${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileVariantColor}="${PRODUCT_GRID_TILE_VARIABLES_MAP.productId}"
                data-quicksell-component-type="default"
                data-quicksell-component-editable="false"
                data-quicksell-component-removable="false"
                data-quicksell-component-copyable="false"
                data-quicksell-component-draggable="false"
              >
                &nbsp;
              </div>
              <div
                class="${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileVariantColor}"
                data-${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileVariantColor}="${PRODUCT_GRID_TILE_VARIABLES_MAP.productId}"
                data-quicksell-component-type="default"
                data-quicksell-component-editable="false"
                data-quicksell-component-removable="false"
                data-quicksell-component-copyable="false"
                data-quicksell-component-draggable="false"
              >
                &nbsp;
              </div>
              <div
                class="${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileVariantColor}"
                data-${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileVariantColor}="${PRODUCT_GRID_TILE_VARIABLES_MAP.productId}"
                data-quicksell-component-type="default"
                data-quicksell-component-editable="false"
                data-quicksell-component-removable="false"
                data-quicksell-component-copyable="false"
                data-quicksell-component-draggable="false"
              >
                &nbsp;
              </div>
            </div>
            <div
              class="${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileVariantColorCount}"
              data-${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileVariantColorCount}="${PRODUCT_GRID_TILE_VARIABLES_MAP.productId}"
              data-${PRODUCT_GRID_TILE_CLASSNAMES_PREFIX}-element-type="${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileVariantColorCount}"
              data-quicksell-component-type="text"
              data-quicksell-component-editable="false"
              data-quicksell-component-removable="false"
              data-quicksell-component-copyable="false"
              data-quicksell-component-draggable="false"
            >
              ${PRODUCT_GRID_TILE_VARIABLES_MAP.variantColorCount}
            </div>
          </div>
          <div
            class="${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileName}"
            data-${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileName}="${PRODUCT_GRID_TILE_VARIABLES_MAP.productId}"
            data-${PRODUCT_GRID_TILE_CLASSNAMES_PREFIX}-element-type="${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileName}"
            data-quicksell-component-type="text"
            data-quicksell-component-editable="false"
            data-quicksell-component-removable="false"
            data-quicksell-component-copyable="false"
            data-quicksell-component-draggable="false"
          >
            ${PRODUCT_GRID_TILE_VARIABLES_MAP.productName}
          </div>
          <div
            class="${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTilePriceContainer}"
            data-${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTilePriceContainer}="${PRODUCT_GRID_TILE_VARIABLES_MAP.productId}"
            data-quicksell-component-type="default"
            data-quicksell-component-editable="false"
            data-quicksell-component-removable="false"
            data-quicksell-component-copyable="false"
            data-quicksell-component-draggable="false"
          >
            <div
              class="${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTilePrice}"
              data-${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTilePrice}="${PRODUCT_GRID_TILE_VARIABLES_MAP.productId}"
              data-${PRODUCT_GRID_TILE_CLASSNAMES_PREFIX}-element-type="${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTilePrice}"
              data-quicksell-component-type="text"
              data-quicksell-component-editable="false"
              data-quicksell-component-removable="false"
              data-quicksell-component-copyable="false"
              data-quicksell-component-draggable="false"
            >
              ${PRODUCT_GRID_TILE_VARIABLES_MAP.productPrice}
            </div>
            <div
              class="${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTilePriceDiscount}"
              data-${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTilePriceDiscount}="${PRODUCT_GRID_TILE_VARIABLES_MAP.productId}"
              data-${PRODUCT_GRID_TILE_CLASSNAMES_PREFIX}-element-type="${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTilePriceDiscount}"
              data-quicksell-component-type="text"
              data-quicksell-component-editable="false"
              data-quicksell-component-removable="false"
              data-quicksell-component-copyable="false"
              data-quicksell-component-draggable="false"
            >
              ${PRODUCT_GRID_TILE_VARIABLES_MAP.productDiscountedPrice}
            </div>
          </div>
          <div
            class="${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTilePriceTax}"
            data-${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTilePriceTax}="${PRODUCT_GRID_TILE_VARIABLES_MAP.productId}"
            data-${PRODUCT_GRID_TILE_CLASSNAMES_PREFIX}-element-type="${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTilePriceTax}"
            data-quicksell-component-type="text"
            data-quicksell-component-editable="false"
            data-quicksell-component-removable="false"
            data-quicksell-component-copyable="false"
            data-quicksell-component-draggable="false"
          >
            ${PRODUCT_GRID_TILE_VARIABLES_MAP.productTaxPrice}
          </div>
        </div>
        <div
          class="${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileAddToCartContainer} gjs-cell"
          data-${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileAddToCartContainer}="${PRODUCT_GRID_TILE_VARIABLES_MAP.productId}"
          data-quicksell-component-type="default"
          data-quicksell-component-editable="false"
          data-quicksell-component-removable="false"
          data-quicksell-component-copyable="false"
          data-quicksell-component-draggable="false"
        >
          <div
            class="${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileAddToCartLoader}"
            data-${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileAddToCartLoader}="${PRODUCT_GRID_TILE_VARIABLES_MAP.productId}"
            data-quicksell-component-type="text"
            data-quicksell-component-editable="false"
            data-quicksell-component-removable="false"
            data-quicksell-component-copyable="false"
            data-quicksell-component-draggable="false"
            data-quicksell-component-layerable="false"
          >
            <svg
              class="${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileAddToCartLoaderSvg}"
              data-${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileAddToCartLoaderSvg}="${PRODUCT_GRID_TILE_VARIABLES_MAP.productId}"
              viewBox="0 0 38 38"
              height="24"
              width="24"
              stroke="${WHITE_COLOR}"
              data-quicksell-component-custom-name="${capitalizeFirstLetter(t('svg'))}"
              data-quicksell-component-type="svg"
              data-quicksell-component-editable="false"
              data-quicksell-component-removable="false"
              data-quicksell-component-copyable="false"
              data-quicksell-component-draggable="false"
              data-quicksell-component-layerable="false"
            >
              <g data-quicksell-component-type="svg-in" fill="none" fill-rule="evenodd">
                <g data-quicksell-component-type="svg-in" transform="translate(1 1)" stroke-width="2">
                  <circle data-quicksell-component-type="svg-in" stroke-opacity=".5" cx="18" cy="18" r="18"/>
                  <path data-quicksell-component-type="svg-in" d="M36 18c0-9.94-8.06-18-18-18">
                  </path>
                </g>
              </g>
            </svg>
          </div>
          <div
            class="${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileAddToCart}"
            data-${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileAddToCart}="${PRODUCT_GRID_TILE_VARIABLES_MAP.productId}"
            data-${PRODUCT_GRID_TILE_CLASSNAMES_PREFIX}-element-type="${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileAddToCart}"
            data-quicksell-component-type="text"
            data-quicksell-component-editable="false"
            data-quicksell-component-removable="false"
            data-quicksell-component-copyable="false"
            data-quicksell-component-draggable="false"
          >
            ${PRODUCT_GRID_TILE_VARIABLES_MAP.addToCartButtonText}
          </div>
          <div
            class="${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileInquiryContainer} gjs-cell"
            data-${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileInquiryContainer}="${PRODUCT_GRID_TILE_VARIABLES_MAP.productId}"
            data-quicksell-component-type="default"
            data-quicksell-component-editable="false"
            data-quicksell-component-removable="false"
            data-quicksell-component-copyable="false"
            data-quicksell-component-draggable="false"
            data-quicksell-component-selectable="false"
            data-quicksell-component-layerable="false"
          >
            <div
              class="${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileInquiryMinus}"
              data-${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileInquiryMinus}="${PRODUCT_GRID_TILE_VARIABLES_MAP.productId}"
              data-quicksell-component-type="default"
              data-quicksell-component-editable="false"
              data-quicksell-component-removable="false"
              data-quicksell-component-copyable="false"
              data-quicksell-component-draggable="false"
              data-quicksell-component-selectable="false"
              data-quicksell-component-layerable="false"
            >
              <div
                class="${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileInquiryMinusText}"
                data-${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileInquiryMinusText}="${PRODUCT_GRID_TILE_VARIABLES_MAP.productId}"
                data-quicksell-component-type="text"
                data-quicksell-component-editable="false"
                data-quicksell-component-removable="false"
                data-quicksell-component-copyable="false"
                data-quicksell-component-draggable="false"
                data-quicksell-component-selectable="false"
                data-quicksell-component-layerable="false"
              >
                -
              </div>
            </div>
            <div
              class="${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileInquiryCount}"
              data-${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileInquiryCount}="${PRODUCT_GRID_TILE_VARIABLES_MAP.productId}"
              data-quicksell-component-type="default"
              data-quicksell-component-editable="false"
              data-quicksell-component-removable="false"
              data-quicksell-component-copyable="false"
              data-quicksell-component-draggable="false"
              data-quicksell-component-selectable="false"
              data-quicksell-component-layerable="false"
            >
              <div
                class="${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileInquiryCountText}"
                data-${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileInquiryCountText}="${PRODUCT_GRID_TILE_VARIABLES_MAP.productId}"
                data-quicksell-component-type="text"
                data-quicksell-component-editable="false"
                data-quicksell-component-removable="false"
                data-quicksell-component-copyable="false"
                data-quicksell-component-draggable="false"
                data-quicksell-component-selectable="false"
                data-quicksell-component-layerable="false"
              >
                1
              </div>
            </div>
            <div
              class="${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileInquiryPlus}"
              data-${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileInquiryPlus}="${PRODUCT_GRID_TILE_VARIABLES_MAP.productId}"
              data-quicksell-component-type="default"
              data-quicksell-component-editable="false"
              data-quicksell-component-removable="false"
              data-quicksell-component-copyable="false"
              data-quicksell-component-draggable="false"
              data-quicksell-component-selectable="false"
              data-quicksell-component-layerable="false"
            >
              <div
                class="${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileInquiryPlusText}"
                data-${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileInquiryPlusText}="${PRODUCT_GRID_TILE_VARIABLES_MAP.productId}"
                data-quicksell-component-type="text"
                data-quicksell-component-editable="false"
                data-quicksell-component-removable="false"
                data-quicksell-component-copyable="false"
                data-quicksell-component-draggable="false"
                data-quicksell-component-selectable="false"
                data-quicksell-component-layerable="false"
              >
                +
              </div>
            </div>
          </div>
        </div>
      </div>
    `;
  },
  get STYLE() {
    return `
      html,
      body {
        height: 100%;
        min-height: 100%;
        max-height: 100%;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        padding: 0px 0px 0px 0px;
        margin: 0px 0px 0px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }

      .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileContainer} {
        height: 100%;
        min-height: 0px;
        max-height: 100%;
        width: 100%;
        min-width: 0px;
        max-width: 100%;
        margin: 0px 0px 0px 0px;
        padding: 0px 0px 0px 0px;
        background-repeat: repeat;
        background-position: left top;
        background-attachment: scroll;
        background-size: auto;
        background-color: ${WHITE_COLOR};
        border-width: 1px 1px 1px 1px;
        border-style: solid;
        border-color: ${BORDER_COLOR};
        border-radius: 5px 5px 5px 5px;
        box-shadow: 0px 0px 10px 1px rgba(26, 32, 39, 0.1);
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        flex-wrap: nowrap;
        flex-direction: column;
        overflow-x: hidden;
        overflow-y: hidden;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        text-decoration: none;
      }

      .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileContainer}:hover .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileImageContainer} .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileImage} {
        transform: scale(1.05);
      }

      .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileImageContainer} {
        height: auto;
        min-height: 75px;
        max-height: 100%;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        padding: 0px 0px 0px 0px;
        margin: 0px 0px 0px 0px;
        border-radius: 4px 4px 0px 0px;
        top: 0px;
        right: 0px;
        left: 0px;
        bottom: 0px;
        border-width: 0px 0px 0px 0px;
        border-style: solid;
        border-color: ${BORDER_COLOR};
        color: ${TEXT_COLOR};
        cursor: pointer;
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: auto;
        position: relative;
        overflow-x: hidden;
        overflow-y: hidden;
        transition: all 0.2s ease-in-out;
      }

      .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileDiscountBadge} {
        height: auto;
        min-height: auto;
        max-height: auto;
        width: auto;
        min-width: auto;
        max-width: 100px;
        background-repeat: repeat;
        background-position: left top;
        background-attachment: scroll;
        background-size: auto;
        background-color: ${ACCENT_COLOR};
        color: ${WHITE_COLOR};
        border-radius: 0px 5px 5px 0px;
        font-size: 13px;
        font-weight: 400;
        padding: 3px 8px 3px 8px;
        position: absolute;
        top: 10px;
        left: 0px;
        z-index: 3;
        overflow-x: hidden;
        overflow-y: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-decoration: none;
      }

      .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileDiscountBadge}:empty {
        display: none;
      }

      .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileOutOfStockBadge} {
        height: auto;
        min-height: auto;
        max-height: auto;
        width: auto;
        min-width: auto;
        max-width: 100px;
        background-repeat: repeat;
        background-position: left top;
        background-attachment: scroll;
        background-size: auto;
        background-color: rgba(255, 0, 0, 0.8);
        color: ${WHITE_COLOR};
        border-radius: 5px 5px 5px 5px;
        font-size: 13px;
        font-weight: 400;
        padding: 3px 8px 3px 8px;
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 3;
        overflow-x: hidden;
        overflow-y: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-decoration: none;
      }

      .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileOutOfStockBadge}:empty {
        display: none;
      }

      .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileVideoBadge} {
        height: 24px;
        min-height: 24px;
        max-height: 24px;
        width: 24px;
        min-width: 24px;
        max-width: 24px;
        position: absolute;
        bottom: 10px;
        left: 10px;
        object-fit: contain;
        color: ${TEXT_COLOR};
        z-index: 3;
        text-decoration: none;
      }

      .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTilePhotoCountBadge} {
        height: 22px;
        min-height: 22px;
        max-height: 22px;
        width: auto;
        min-width: auto;
        max-width: 100px;
        border-radius: 10px 10px 10px 10px;
        flex: 0 0 auto;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        padding: 4px 10px 4px 10px;
        background-repeat: repeat;
        background-position: left top;
        background-attachment: scroll;
        background-size: auto;
        background-color: ${WHITE_COLOR};
        color: ${BLACK_COLOR};
        box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px 1px;
        position: absolute;
        bottom: 14px;
        right: 14px;
        z-index: 3;
        overflow-x: hidden;
        overflow-y: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-decoration: none;
      }

      .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTilePhotoCountBadge}:empty {
        display: none;
      }

      .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileImage} {
        height: 100%;
        min-height: 100%;
        max-height: 400px;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        color: ${TEXT_COLOR};
        object-fit: contain;
        border-radius: 4px 4px 0px 0px;
        display: inline-block;
        cursor: pointer;
        overflow-x: hidden;
        overflow-y: hidden;
        vertical-align: middle;
        transition: all 0.2s ease-in-out;
        text-decoration: none;
      }

      .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileDetailsContainer} {
        height: auto;
        min-height: auto;
        max-height: 100%;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        padding: 6px 10px 6px 10px;
        margin: 0px 0px 0px 0px;
        border-radius: 0px 0px 0px 0px;
        border-width: 0px 0px 0px 0px;
        border-style: solid;
        border-color: ${BORDER_COLOR};
        color: ${TEXT_COLOR};
        font-weight: 400;
        font-size: 14px;
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: auto;
        cursor: pointer;
        background-repeat: repeat;
        background-position: left top;
        background-attachment: scroll;
        background-size: auto;
        background-color: ${WHITE_COLOR};
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        transition: all 0.2s ease-in-out;
        text-decoration: none;
      }

      .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileDetailsContainer}:empty  {
        display: none;
      }

      .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileVariantDetailsContainer} {
        margin: 2.5px 0px 0px 0px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: auto;
        min-width: auto;
        max-width: 100%;
        overflow-x: hidden;
        overflow-y: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-decoration: none;
      }

      .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileVariantDetailsContainer}:empty  {
        display: none;
      }

      .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileVariantColors} {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 0px 10px 0px 0px;
        text-decoration: none;
      }

      .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileVariantColors}:empty  {
        display: none;
      }

      .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileVariantColor} {
        height: 16px;
        min-height: 16px;
        max-height: 16px;
        width: 16px;
        min-width: 16px;
        max-width: 16px;
        background-repeat: repeat;
        background-position: left top;
        background-attachment: scroll;
        background-size: auto;
        background-color: ${WHITE_COLOR};
        border-width: 1px 1px 1px 1px;
        border-style: solid;
        border-color: ${WHITE_COLOR};
        border-radius: 50%;
        box-shadow: 0px 0px 0px 1px rgba(26, 32, 39, 0.1);
        text-decoration: none;
      }

      .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileVariantColor}:nth-child(1) {
        margin-left: 0px;
        background-repeat: repeat;
        background-position: left top;
        background-attachment: scroll;
        background-size: auto;
        background-color: #f74246;
      }

      .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileVariantColor}:nth-child(2) {
        margin-left: -8px;
        background-repeat: repeat;
        background-position: left top;
        background-attachment: scroll;
        background-size: auto;
        background-color: #f88c49;
      }

      .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileVariantColor}:nth-child(3) {
        margin-left: -8px;
        background-repeat: repeat;
        background-position: left top;
        background-attachment: scroll;
        background-size: auto;
        background-color: #f9d34f;
      }

      .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileVariantColorCount} {
        color: ${TEXT_COLOR};
        font-size: 14px;
        font-weight: 600;
        text-align: left;
        overflow-x: hidden;
        overflow-y: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-decoration: none;
      }

      .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileVariantColorCount}:empty {
        display: none;
      }

      .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileName} {
        height: auto;
        min-height: auto;
        max-height: auto;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        margin: 2.5px 0px 0px 0px;
        padding: 0px 0px 0px 0px;
        color: ${TEXT_COLOR};
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        line-height: 1.2;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        display: -webkit-box;
        overflow-x: hidden;
        overflow-y: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        text-decoration: none;
        word-wrap: break-word;
        word-break: break-word;
      }

      .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileName}:empty {
        display: none;
      }

      .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTilePriceContainer} {
        height: auto;
        min-height: auto;
        max-height: auto;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        overflow-x: hidden;
        overflow-y: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-decoration: none;
        word-wrap: break-word;
        word-break: break-word;
      }

      .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTilePriceContainer}:empty {
        display: none;
      }

      .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTilePrice} {
        margin: 2.5px 0px 0px 0px;
        color: ${TEXT_COLOR};
        font-size: 18px;
        font-weight: 600;
        text-align: left;
        height: auto;
        min-height: auto;
        max-height: auto;
        width: auto;
        min-width: auto;
        max-width: 100%;
        overflow-x: hidden;
        overflow-y: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-decoration: none;
      }

      .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTilePrice}:empty {
        display: none;
      }

      .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTilePriceDiscount} {
        height: auto;
        min-height: auto;
        max-height: auto;
        width: auto;
        min-width: auto;
        max-width: 100%;
        margin: 2.5px 0px 0px 5px;
        padding: 0px 0px 0px 0px;
        color: ${TEXT_COLOR};
        font-size: 14px;
        font-weight: 300;
        text-align: left;
        overflow-x: hidden;
        overflow-y: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-decoration: line-through;
      }

      .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTilePriceDiscount}:empty {
        display: none;
      }

      .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTilePriceTax} {
        height: auto;
        min-height: auto;
        max-height: auto;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        margin: 2.5px 0px 0px 0px;
        padding: 0px 0px 0px 0px;
        color: ${TEXT_COLOR};
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        overflow-x: hidden;
        overflow-y: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-decoration: none;
        word-wrap: break-word;
        word-break: break-word;
      }

      .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTilePriceTax}:empty {
        display: none;
      }
      
      .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileAddToCartContainer} {
        height: 38px;
        min-height: 38px;
        max-height: 38px;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        padding: 0px 0px 0px 0px;
        margin: 0px 0px 0px 0px;
        border-radius: 0px 0px 4px 4px;
        border-width: 0px 0px 0px 0px;
        border-style: solid;
        border-color: ${BORDER_COLOR};
        outline-width: 0px;
        outline-style: solid;
        outline-color: ${BORDER_COLOR};
        box-shadow: 0px 0px 0px 0px ${BLACK_COLOR};
        background-repeat: repeat;
        background-position: left top;
        background-attachment: scroll;
        background-size: auto;
        background-color: ${ACCENT_COLOR};
        display: flex;
        justify-content: center;
        align-items: center;
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: auto;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
      }

      .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileAddToCartLoader} {
        height: 38px;
        min-height: 38px;
        max-height: 38px;
        width: 38px;
        min-width: 38px;
        max-width: 38px;
        display: none;
        align-items: center;
        justify-content: center;
      }

      .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileAddToCartLoaderSvg} {
        height: 100%;
        min-height: 100%;
        max-height: 100%;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        padding: 0px 10px 0px 10px;
        margin: 0px 0px 0px 0px;
        animation: rotating 1s linear infinite;
      }

      .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileAddToCart} {
        height: 100%;
        min-height: 100%;
        max-height: 100%;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        padding: 0px 10px 0px 10px;
        margin: 0px 0px 0px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${WHITE_COLOR};
        text-align: center;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 16px;
        overflow-x: hidden;
        overflow-y: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-decoration: none;
      }

      .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileAddToCart}:empty {
        display: none;
      }

      .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileInquiryContainer} {
        height: 38px;
        min-height: 38px;
        max-height: 38px;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        padding: 0px 0px 0px 0px;
        margin: 0px 0px 0px 0px;
        border-radius: 0px 0px 4px 4px;
        border-width: 1px 0px 0px 0px;
        border-style: solid;
        border-color: ${BORDER_COLOR};
        display: none;
        justify-content: center;
        align-items: center;
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: auto;
        transition: all 0.2s ease-in-out;
        text-align: center;
        text-transform: uppercase;
        font-weight: 400;
        font-size: 14px;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
      }

      .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileInquiryMinus} {
        height: 100%;
        min-height: 100%;
        max-height: 100%;
        width: auto;
        min-width: auto;
        max-width: 100%;
        border-radius: 0px 0px 0px 4px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex: 3 1 auto;
        overflow-x: hidden;
        overflow-y: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        background-repeat: repeat;
        background-position: left top;
        background-attachment: scroll;
        background-size: auto;
        background-color: rgb(234, 239, 247);
        border-right-width: 1px;
        border-right-style: solid;
        border-right-color: ${BORDER_COLOR};
        transition: all 0.2s ease-in-out;
      }

      .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileInquiryMinusText} {
        height: 100%;
        min-height: 100%;
        max-height: 100%;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        color: ${BLACK_COLOR};
        text-align: center;
        text-transform: uppercase;
        font-weight: 400;
        font-size: 24px;
        line-height: 34px;
        text-decoration: none;
      }

      .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileInquiryCount} {
        height: 100%;
        min-height: 100%;
        max-height: 100%;
        width: auto;
        min-width: auto;
        max-width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 3 1 auto;
        overflow-x: hidden;
        overflow-y: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        background-repeat: repeat;
        background-position: left top;
        background-attachment: scroll;
        background-size: auto;
        background-color: ${WHITE_COLOR};
        transition: all 0.2s ease-in-out;
      }

      .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileInquiryCountText} {
        height: 100%;
        min-height: 100%;
        max-height: 100%;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        color: ${BLACK_COLOR};
        text-align: center;
        text-transform: uppercase;
        font-weight: 400;
        font-size: 16px;
        line-height: 38px;
        text-decoration: none;
      }

      .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileInquiryPlus} {
        height: 100%;
        min-height: 100%;
        max-height: 100%;
        width: auto;
        min-width: auto;
        max-width: 100%;
        border-radius: 0px 0px 4px 0px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex: 3 1 auto;
        overflow-x: hidden;
        overflow-y: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        background-repeat: repeat;
        background-position: left top;
        background-attachment: scroll;
        background-size: auto;
        background-color: ${ACCENT_COLOR};
        border-left-width: 1px;
        border-left-style: solid;
        border-left-color: ${BORDER_COLOR};
        transition: all 0.2s ease-in-out;
      }

      .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileInquiryPlusText} {
        height: 100%;
        min-height: 100%;
        max-height: 100%;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        color: ${WHITE_COLOR};
        text-align: center;
        text-transform: uppercase;
        font-weight: 400;
        font-size: 24px;
        line-height: 34px;
        text-decoration: none;
      }

      @keyframes rotating {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
    `;
  }
};

export const PRODUCT_GRID_TILE_GRAPES_JS_CONFIG = {
  get CONFIG() {
    return {
      ...GRAPES_JS_DEFAULT_CONFIG.COMMON,
      container: '.custom-html-builder-editor',
      plugins: [...GRAPES_JS_PLUGINS, ...(GRAPES_JS_DEFAULT_CONFIG.COMMON.plugins || [])]
    };
  }
};

export const getProductGridTypeThemeStyles = (theme) => {
  const defaultTheme = (!!theme && Object.keys(theme).length > 0) ? theme : {};
  const defaultTextColor = defaultTheme.pageTextColor || defaultTheme.textColor || TEXT_COLOR;
  const tileTextColor = (defaultTheme.homeGrid || {}).tileTextColor || TEXT_COLOR;
  const tileBackgroundColor = (defaultTheme.homeGrid || {}).tileBackgroundColor || WHITE_COLOR;
  const defaultBorderColor = (defaultTheme.homepage || {}).productTileBorderColorCode || BORDER_COLOR;
  const tilePictureCountTextColor = (defaultTheme.homeGrid || {}).tilePictureCountTextColor || ACCENT_COLOR;
  const tilePictureCountBackgroundColor = (defaultTheme.homeGrid || {}).tilePictureCountBackgroundColor || WHITE_COLOR;
  const discountBarBackgroundColor = defaultTheme.discountBarBackgroundColor || WHITE_COLOR;
  const tileDiscountTextColor = (defaultTheme.homeGrid || {}).tileDiscountTextColor || ACCENT_COLOR;
  const addToCartButtonBackgroundColor = (defaultTheme.addToCartButton || {}).backgroundColor || ACCENT_COLOR;
  const addToCartButtonTextColor = (defaultTheme.addToCartButton || {}).textColor || WHITE_COLOR;
  return `
    .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileContainer} {
      color: ${defaultTextColor};
      border-color: ${defaultBorderColor};
      background-color: ${tileBackgroundColor};
    }
    .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileInquiryContainer} {
      border-color: ${defaultBorderColor};
    }
    .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileInquiryMinus} {
      border-right-color: ${defaultBorderColor};
    }
    .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileInquiryPlus} {
      border-left-color: ${defaultBorderColor};
    }
    .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileImageContainer} {
      color: ${defaultTextColor};
      background-color: ${tileBackgroundColor};
    }
    .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileImage} {
      color: ${defaultTextColor};
    }
    .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTilePhotoCountBadge} {
      color: ${tilePictureCountTextColor};
      background-color: ${tilePictureCountBackgroundColor};
    }
    .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileDiscountBadge} {
      background-color: ${discountBarBackgroundColor};
      color: ${tileDiscountTextColor};
    }
    .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileDiscountBadge} {
      color: ${addToCartButtonTextColor};
    }
    .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileVideoBadge} {
      fill: ${addToCartButtonBackgroundColor};
    }
    .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileDetailsContainer} {
      color: ${tileTextColor};
      background-color: ${tileBackgroundColor};
    }
    .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileVariantColorCount} {
      color: ${tileTextColor};
    }
    .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileName} {
      color: ${tileTextColor};
    }
    .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTilePrice} {
      color: ${tileTextColor};
    }
    .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTilePriceDiscount} {
      color: ${tileTextColor};
    }
    .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTilePriceTax} {
      color: ${tileTextColor};
    }
    .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileAddToCartLoaderSvg} {
      stroke: ${addToCartButtonTextColor};
    }
    .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileAddToCartContainer} {
      background-color: ${addToCartButtonBackgroundColor};
    }
    .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileAddToCart} {
      color: ${addToCartButtonTextColor};
    }
    .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileInquiryMinusText} {
      color: ${BLACK_COLOR};
    }
    .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileInquiryCountText} {
      color: ${BLACK_COLOR};
    }
    .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileInquiryPlus} {
      background-color: ${addToCartButtonBackgroundColor};
    }
    .${PRODUCT_GRID_TILE_CLASSNAMES_MAP.productGridTileInquiryPlusText} {
      color: ${addToCartButtonTextColor};
    }
  `;
};

export const getProductGridTileBlockConfig = (productGridTileBlockContent) => {
  const { t } = getI18N();
  return {
    id: PRODUCT_GRID_TILE_CLASSNAMES_PREFIX,
    type: PRODUCT_GRID_TILE_CLASSNAMES_PREFIX,
    label: t('product_grid_tile'),
    activate: true,
    media: `<img src="${ProductGridTileIcon}" height="52" width="52" style="height: 52px; width: 52px; object-fit: cover;" />`,
    category: t('quicksell'),
    copyable: productGridTileBlockContent.copyable,
    'custom-name': productGridTileBlockContent['custom-name'],
    draggable: productGridTileBlockContent.draggable,
    editable: productGridTileBlockContent.editable,
    removable: productGridTileBlockContent.removable,
    tagName: productGridTileBlockContent.tagName,
    attributes: productGridTileBlockContent.attributes,
    content: {
      classes: productGridTileBlockContent.classes,
      components: productGridTileBlockContent.components
    }
  };
};

export const getProductGridTileBlockComponent = (productGridTileBlockContent) => ({
  model: {
    defaults: {
      copyable: productGridTileBlockContent.copyable,
      'custom-name': productGridTileBlockContent['custom-name'],
      draggable: productGridTileBlockContent.draggable,
      editable: productGridTileBlockContent.editable,
      removable: productGridTileBlockContent.removable,
      tagName: productGridTileBlockContent.tagName,
      attributes: productGridTileBlockContent.attributes
    }
  }
});
