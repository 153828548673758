import React, { useState, useRef } from 'react';
import Modal from 'react-responsive-modal';
import { getI18N } from 'qs-services/i18N';
import './index.scss';
import toastr from 'toastr';

export default ({
  onClose,
  defaultValue,
  title,
  onSubmit,
  saveButtonText,
  placeholder,
  validationPattern,
  errorMsg
}) => {
  const modalStyles = useRef({
    backgroundColor: '#242C36',
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    width: '400px',
    padding: '0px'
  });

  const { t } = getI18N();

  const [value, setValue] = useState(defaultValue);
  const handleInputChange = function(event) {
    setValue(event.target.value);
  };

  const handleSave = function(event) {
    event.preventDefault();
    if (!value.trim()) {
      return;
    }

    if (validationPattern && !validationPattern.test(value) && errorMsg) {
      toastr.error(errorMsg);
      return;
    }

    onSubmit(value);
    onClose();
  };

  return (
    <Modal
      open={true}
      onClose={onClose}
      center={true}
      showCloseIcon={false}
      styles={{ modal: modalStyles.current }}
    >
      <h3 className="editValue title">{title}</h3>
      <form onSubmit={handleSave}>
        <div
          className="editValue inputContainer"
          style={{
            borderBottom: `1px solid ${
              validationPattern && !validationPattern.test(value) ? '#c72121' : '#ffffff'
            }`
          }}
        >
          <input
            className="inputBox"
            type="text"
            value={value}
            onChange={handleInputChange}
            autoFocus={true}
            placeholder={placeholder}
          />
        </div>
        <div className="editValue buttonContainer">
          <button type="reset" className="button buttonCancel" onClick={() => onClose()}>
            {t('cancel')}
          </button>
          <button type="submit" className="button buttonPlain">
            {saveButtonText}
          </button>
        </div>
      </form>
    </Modal>
  );
};
