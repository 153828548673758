import React from 'react';
import ClickHandlerWithLoaderContainer from '../../../../Common/ClickHandlerWithLoader/ClickHandlerWithLoaderContainer';
import SettingsRow from '../../../Common/SettingsRow/SettingsRow';
import CustomProductMagazineTileIcon from '../../../../Common/CustomProductMagazineTileIcon';
import { getI18N } from 'qs-services/i18N';

export default ({ onSelected, settingComponentId, classForContainer }) => {
  const { t } = getI18N();

  return (
    <ClickHandlerWithLoaderContainer
      clickProps={{
        clickHandler: onSelected,
        clickParams: settingComponentId
      }}
      type="button"
      classForContainer={classForContainer}
    >
      <SettingsRow Icon={CustomProductMagazineTileIcon} title={t('product_magazine_tile')}>
        <span>{t('create_custom_product_magazine_tile_for_your_website')}</span>
      </SettingsRow>
    </ClickHandlerWithLoaderContainer>
  );
};
