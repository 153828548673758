import React, { useState, Fragment } from 'react';
import { ReactComponent as EditIcon } from 'qs-assets/Media/pencil.svg';
import { ReactComponent as CloseIcon } from 'qs-assets/Media/close.svg';
import { ReactComponent as CheckIcon } from 'qs-assets/Media/checkmark.svg';
import toastr from 'toastr';
import {
  updateExistingOptionInRemote,
  updateExistingOptionInCache
} from 'qs-data-manager/Variants/ProductOptions';
import Loader from '../../../../../../Common/Loader';
import ColorPicker from '../ColorPicker';
import DeleteOption from './DeleteOption';
import { VARIANT_ADD_TABS, PRODUCT_OPTIONS } from 'qs-helpers/Variants/constants';
import SetQuantity from './SetQuantity';
import useUpdateListOnItemUpdate from 'qs-hooks/useUpdateListOnItemUpdate';
import { canUseFeature, FEATURE_LIST } from 'qs-data-manager/FeatureUsage';
import { ReactComponent as ReorderIcon } from 'qs-assets/Media/drag-reorder.svg';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

export default ({
  optionsData,
  styleForContainer,
  onOptionRender,
  index,
  currentTab,
  activeProductId
}) => {
  const setFeatureAvailable = canUseFeature(FEATURE_LIST.SET_WISE_ORDERING.id);
  const { id, label, rgb, isSet, setQuantity } = optionsData;
  const [editMode, toggleEditMode] = useState(false);
  const [labelText, setLabelText] = useState('');
  const [showLoaderForSave, toggleShowLoaderForSave] = useState(false);
  const [showLoaderForColor, toggleShowLoaderForColor] = useState(false);
  const [showColorPicker, toggleColorPicker] = useState(false);
  const { t } = getI18N();

  const [, setOptionsRowRef] = useUpdateListOnItemUpdate({
    reRenderListOnItemUpdate: onOptionRender,
    defaultRowHeight: PRODUCT_OPTIONS.estimatedHeight,
    index,
    uniqueId: id
  });

  const handleOptionClick = event => {
    event.preventDefault();
    toggleEditMode(true);
    setLabelText(label || '');
  };

  const closeEditMode = event => {
    event.preventDefault();

    toggleEditMode(false);
    setLabelText('');
  };

  const isOptionValid = () => {
    if (labelText.trim() === '') {
      window.alert(t('please_enter_the_name_of_the_option'));
      return false;
    }

    return true;
  };

  const getBasicOptionData = () => ({
    id,
    optionType: currentTab.type,
    parentProductId: activeProductId,
    isSet,
    setQuantity
  });

  const onUpdateOption = event => {
    event.preventDefault();

    if (!isOptionValid()) {
      return;
    }

    const optionData = {
      label: labelText,
      ...getBasicOptionData()
    };
    toggleShowLoaderForSave(true);

    /*
      Update the remote first, then the local state and then the cache;
      this was essential to ensure synchronous updates to the view to resize
      the list dynamically. With the switch to using resize observer, this
      ordering is no longer essential
    */
    updateExistingOptionInRemote(optionData)
      .then(() => {
        toggleEditMode(false);
        setLabelText('');
        toggleShowLoaderForSave(false);
        updateExistingOptionInCache(optionData);
      })
      .catch(err => {
        toastr.error(
          err ? err.message : t('something_went_wrong_while_updating_the_label_of_the_variant')
        );
        toggleShowLoaderForSave(false);
      });
  };

  const handleColorPickerClick = event => {
    event.preventDefault();
    toggleColorPicker(true);
  };

  const handleColorPickerClose = () => {
    toggleColorPicker(false);
  };

  const handleColorPickerSelection = rgbFromPicker => {
    toggleColorPicker(false);
    if (!rgbFromPicker) {
      return;
    }

    const optionData = {
      rgb: rgbFromPicker,
      ...getBasicOptionData()
    };
    toggleShowLoaderForColor(true);
    updateExistingOptionInRemote(optionData)
      .then(() => {
        toggleShowLoaderForColor(false);
        updateExistingOptionInCache(optionData);
      })
      .catch(() => {
        toastr.error(t('something_went_wrong_while_updating_the_color_of_the_variant'));
        toggleShowLoaderForColor(false);
      });
  };

  const handleLabelChange = event => {
    event.preventDefault();
    setLabelText(event.target.value);
  };

  const getTitleComponent = () => {
    if (editMode) {
      let saveIcon;
      if (showLoaderForSave) {
        saveIcon = <Loader />;
      } else {
        saveIcon = (
          <button type="submit" className="buttonIcon" onClick={onUpdateOption}>
            <CheckIcon className={'saveOptionIcon'} />
          </button>
        );
      }

      return (
        <form className="editOptionForm">
          <div className={`productOptionsName editOption`}>
            <input
              autoFocus={true}
              className="optionTextInput"
              type="text"
              value={labelText}
              onChange={handleLabelChange}
              disabled={showLoaderForSave}
            />
            {saveIcon}
            <button onClick={closeEditMode} type="button" className="buttonIcon">
              <CloseIcon className={'cancelEditIcon'} />
            </button>
          </div>
        </form>
      );
    }

    return (
      <div className={`productOptionsName`} onClick={handleOptionClick}>
        <div>{label}</div>
        <EditIcon className={'optionEditIcon'} />
      </div>
    );
  };

  const getRgbComponent = () => {
    if (currentTab.type !== VARIANT_ADD_TABS.COLORS) {
      return null;
    }

    if (showLoaderForColor) {
      return (
        <div className="productOptionsColorContainer">
          <Loader />
        </div>
      );
    }

    if (!rgb) {
      //Currently set can only be applied when color is not there. Prevent adding color
      let clickHandler = null,
        containerClass = '';
      if (!isSet) {
        clickHandler = handleColorPickerClick;
        containerClass = 'setColor';
      }

      return (
        <div
          className={`productOptionsColorContainer colorWheelIcon ${containerClass}`}
          onClick={clickHandler}
          style={{ backgroundImage: `url(${currentTab.url})` }}
        ></div>
      );
    }

    return (
      <div className={`productOptionsColorContainer editable`} onClick={handleColorPickerClick}>
        <div
          style={{
            backgroundColor: `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`
          }}
        />
      </div>
    );
  };

  return (
    <Fragment>
      <div style={styleForContainer}>
        <div className="productOptionsRow" ref={setOptionsRowRef}>
          <div className="productOptionsDetails">
            {getRgbComponent()}
            {getTitleComponent()}
            <DeleteOption productId={activeProductId} optionId={id} currentTab={currentTab.type} />
            <div className="productOptionsMove">
              <ReorderIcon fill="#fff" className="reorderIcon" />
            </div>
          </div>
          {setFeatureAvailable && isSet && (
            <SetQuantity
              setQuantity={setQuantity}
              optionType={currentTab.type}
              activeProductId={activeProductId}
              optionId={id}
            />
          )}
        </div>
      </div>
      {showColorPicker && (
        <ColorPicker
          rgb={rgb}
          onClose={handleColorPickerClose}
          onSelectColor={handleColorPickerSelection}
        />
      )}
    </Fragment>
  );
};
