import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, Button, DialogContent, DialogActions } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { getI18N } from 'qs-services/i18N';

const useStyles = makeStyles({
  dlgTitle: {
    fontSize: 22
  }
});

const NewDialogTitle = withStyles({
  root: {
    color: 'white',
    fontSize: 26,
    fontWeight: 700
  }
})(DialogTitle);

const CommonButton = withStyles({
  root: {
    fontSize: 14,
    fontWeight: 700,
    color: '#9D9D9D'
  }
})(Button);

export default function DialogBox(props) {
  const {
    show = false,
    title = 'Title',
    onClose,
    onSuccess,
    primaryBtnText,
    secondaryBtnText,
    content,
    dark = false,
    danger = false,
    width,
    height,
    children,
    type
  } = props;
  const [open, setOpen] = useState(show);

  const { t } = getI18N();

  const classes = useStyles();
  let paperStyle = {
    width,
    height
  };
  paperStyle = dark
    ? {
        backgroundColor: '#1F2229',
        ...paperStyle
      }
    : paperStyle;

  useEffect(() => {
    setOpen(show);
  }, [show]);

  const onCancelClick = () => {
    onClose && onClose();
  };

  const onSucessClick = () => {
    onSuccess && onSuccess();
  };

  const DiagTitle = dark ? NewDialogTitle : DialogTitle;

  let btnColor = '';

  if (dark) {
    btnColor = danger ? '#E76F6F' : '#92CFB3';
  } else {
    btnColor = danger ? '#ed5151' : '#4DA47A';
  }

  const MainButton = withStyles({
    root: {
      fontSize: 14,
      fontWeight: 700,
      color: btnColor
    }
  })(Button);

  return (
    <Dialog
      open={open}
      PaperProps={{
        style: paperStyle
      }}
      onClose={onCancelClick}
      aria-labelledby="form-dialog-title"
    >
      {type !== 'simple' && (
        <DiagTitle disableTypography={true} id="form-dialog-title">
          <div className={classes.dlgTitle}>{title}</div>
        </DiagTitle>
      )}
      <DialogContent>{content || children}</DialogContent>
      {type !== 'simple' && (
        <DialogActions>
          <CommonButton onClick={onCancelClick} color="primary">
            {`${secondaryBtnText || t('cancel')}`}
          </CommonButton>
          <MainButton onClick={onSucessClick} color="primary">
            {`${primaryBtnText || t('ok')}`}
          </MainButton>
        </DialogActions>
      )}
    </Dialog>
  );
}
