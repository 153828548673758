import React from 'react';
import ClickHandlerWithLoaderContainer from '../../../../Common/ClickHandlerWithLoader/ClickHandlerWithLoaderContainer';
import CustomWebhooksIcon from '../../../../Common/CustomWebhooksIcon';
import SettingsRow from '../../../Common/SettingsRow/SettingsRow';
import { getI18N } from 'qs-services/i18N';

export default ({ onSelected, settingComponentId, classForContainer }) => {
  const { t } = getI18N();
  return (
    <ClickHandlerWithLoaderContainer
      clickProps={{
        clickHandler: onSelected,
        clickParams: settingComponentId
      }}
      type="button"
      classForContainer={classForContainer}
    >
      <SettingsRow Icon={CustomWebhooksIcon} title={t('webhooks')}>
        <span>{t('manage_your_webhooks_settings')}</span>
      </SettingsRow>
    </ClickHandlerWithLoaderContainer>
  );
};
