import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  dialoguePaper: {
    backgroundColor: '#151A21',
    color: 'white'
  },
  exampleRoot: {
    color: '#C1CBD9 !important',
    marginTop: '20px'
  },
  titleRoot: {
    fontSize: '20px'
  },
  buttonLabel: {
    color: 'white !important'
  }
}));

export { useStyles };
