import { makeStyles } from '@material-ui/core/styles';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 6 + ITEM_PADDING_TOP,
      width: 220,
      backgroundColor: 'rgb(28, 33, 43)',
      color: 'white'
    }
  }
};

const useStyles = makeStyles(() => ({
  select: {
    color: 'rgb(186, 203, 222)'
  },
  icon: {
    color: 'rgb(174, 184, 195) !important'
  },
  underline: {
    borderBottom: 'none !important',
    '&:after': {
      borderBottom: 'none !important'
    },
    '&:before': {
      borderBottom: 'none !important'
    }
  },
  root: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.4) !important'
    }
  },
  listItemRoot: {
    '&$listItemDisabled': {
      opacity: 'unset'
    }
  },
  listItemDisabled: {}
}));

const useSelectedStyles = makeStyles(() => ({
  select: {
    color: 'rgb(255, 255, 255)'
  },
  icon: {
    color: 'rgb(95, 209, 162) !important'
  },
  selected: {
    backgroundColor: 'rgb(30, 172, 113) !important',
    '&:hover': {
      backgroundColor: 'rgb(30, 172, 113) !important'
    }
  }
}));

const hasSelectedColumns = ({ labelValue }) =>
  Array.isArray(labelValue) ? labelValue.length : labelValue;

const getLabelStyles = ({ classes, labelValue, selectedClasses }) => {
  const hasSelected = hasSelectedColumns({ labelValue });

  return {
    select: hasSelected ? selectedClasses.select : classes.select,
    icon: hasSelected ? selectedClasses.icon : classes.icon,
    underline: classes.underline,
    selected: selectedClasses.selected,
    root: classes.root,
    listItemRoot: classes.listItemRoot,
    listItemDisabled: classes.listItemDisabled
  };
};

export { MenuProps, useStyles, getLabelStyles, hasSelectedColumns, useSelectedStyles };
