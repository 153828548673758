import CacheRequest from '../CacheRequest';
import { PRODUCT_WEIGHT } from 'qs-api/Products/ApiCacheConnector';

export const attachProductWeightListener = ({ listener, productId }) => {
  CacheRequest.attachListener(`${PRODUCT_WEIGHT.cacheKey}${productId}`, listener);
};

export const removeProductWeightListener = ({ listener, productId }) => {
  CacheRequest.removeListener(`${PRODUCT_WEIGHT.cacheKey}${productId}`, listener);
};

export const getProductWeight = productId => {
  const key = `${PRODUCT_WEIGHT.cacheKey}${productId}`;
  const apiName = PRODUCT_WEIGHT.apiFunction;
  CacheRequest.makeRequest(key, apiName, {
    params: [productId],
    options: {
      shouldNotStoreInNative: true
    }
  });
};

export const getProductWeightFromCache = productId =>
  CacheRequest.getCacheForKey(`${PRODUCT_WEIGHT.cacheKey}${productId}`) || undefined;

export const setProductWeightInCache = ({ productId, updates }) => {
  const existingShippingUnits = getProductWeightFromCache() || {};
  CacheRequest.setCacheForKey(`${PRODUCT_WEIGHT.cacheKey}${productId}`, {
    ...existingShippingUnits,
    ...updates
  });
};

export const updateProductWeightData = async ({ productId, weight }) => {
  setProductWeightInCache({
    productId,
    updates: {
      weight
    }
  });
};
