import React, { useState } from 'react';
import { ReactComponent as ErrorIcon } from 'qs-assets/Media/image-error.svg';
import './styles.scss';

export default ({ url = '' }) => {
  const [error, setError] = useState(false);

  const onError = () => {
    setError(true);
  };

  const onLoad = () => {
    setError(false);
  };

  return (
    <div id={'excel-image-preview'}>
      <img onError={onError} onLoad={onLoad} src={`${url}`} className={'no-width'} alt={''} />
      {!error && <div style={{ backgroundImage: `url(${url})` }} className={'picture'} />}
      {!!error && <ErrorIcon className={'errorIcon'} />}
    </div>
  );
};
