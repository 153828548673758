import React, { useCallback, useRef } from 'react';
import Popover, { ArrowContainer } from 'react-tiny-popover';
import toastr from 'toastr';
import { isNil } from 'lodash';
import {
  updateProductViaSku,
  getAllSku,
  getUpdateProductViaSkuStatus,
  downloadStoneDetailsBySku
} from 'qs-data-manager/GlobalInventory/SkuForGlobalInventory';
import { ReactComponent as DownloadIcon } from 'qs-assets/Media/downloadTemplate.svg';
import { ReactComponent as MsExcel } from 'qs-assets/Media/msExcel.svg';
import useOnClickOutside from 'qs-hooks/useOnClickOutside';
import { parseFileToCsv } from 'qs-helpers/CSVUploader';
import Mixpanel from 'qs-data-manager/Mixpanel';
import { downloadFileFromLink, isFileCSV, isValidNumber } from 'qs-services/Helpers';
import { toggleGlobalLoader } from 'qs-helpers';
import './styles.scss';
import { delay } from 'qs-services/Helpers';
import { matchText } from 'qs-helpers/TextMatcher';
import { getCompanyLevelGoldRateAndProductTypeFromCache } from 'qs-data-manager/Company';
import {
  JEWELLERY,
  STONE_EXCEL_COLUMN_NAMES
} from '../../../Catalogue/ProductDetailsScreen/ActiveTabMeta/BasicInfo/JewelleryProductPrices/ProductTypeDetails';
import DiamondIcon from '../../../ReactComponentOfSVGIcons/DiamondIcon';
import { getI18N } from 'qs-services/i18N';

export default ({ hidePopOver }) => {
  const { t } = getI18N();
  const invalidFileError = t('invalid_file_format_upload_excel_file');
  const excelError = t('excel_does_not_contain_sku_or_Inventory_count_rows');
  const invalidStoneRowsError = t(
    'invalid_stones_data_weight_and_total_cost_of_stones_are_mandatory'
  );
  const templateDownloadError = t('something_went_wrong_while_downloading_the_template');
  const uploadInventoryError = t('something_went_wrong_while_uploading_the_inventory');
  const uploadInventorySuccess = t('successfully_updated_products_by_skus');
  const maxTimeDurationInSeconds = 8;

  const productType = getCompanyLevelGoldRateAndProductTypeFromCache();

  const downloadTemplate = useCallback(async () => {
    Mixpanel.sendEvent({ eventName: 'download_global_inventory_clicked' });
    const key = `downloadGlobalInventoryTemplate${Date.now()}`;
    try {
      toggleGlobalLoader(key, true);
      const response = await getAllSku();
      toggleGlobalLoader(key, false);
      downloadFileFromLink(response.excelLink);
      hidePopOver();
    } catch (error) {
      toastr.error(templateDownloadError);
      toggleGlobalLoader(key, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hidePopOver]);

  const downloadStoneDetailsData = useCallback(async () => {
    const key = `downloadStoneDetailsData${Date.now()}`;
    try {
      toggleGlobalLoader(key, true);
      await downloadStoneDetailsBySku();
      toggleGlobalLoader(key, false);
      hidePopOver();
      toastr.success(t('you_will_get_the_stone_details_by_sku_data_on_your_registered_email'));
    } catch (error) {
      toastr.error(templateDownloadError);
      toggleGlobalLoader(key, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hidePopOver]);

  const getNextTimerDuration = timeInSeconds => {
    if (timeInSeconds === 0) return 1;
    if (timeInSeconds === maxTimeDurationInSeconds) return timeInSeconds;
    return timeInSeconds * 2;
  };

  const getSkuUpdatingStatus = useCallback(async ({ requestId, timeInSeconds = 0 }) => {
    const response = await getUpdateProductViaSkuStatus({ requestId });
    if (response.status === 'IN_PROGRESS') {
      await delay(timeInSeconds);
      const nextTimerDurationInSeconds = getNextTimerDuration(timeInSeconds);
      return getSkuUpdatingStatus({ requestId, timeInSeconds: nextTimerDurationInSeconds });
    }
    return downloadFileFromLink(response.excelLink);
  }, []);

  const uploadInventory = useCallback(
    async (updates, requestType = '') => {
      const key = `uploadGlobalInventory${Date.now()}`;
      try {
        toggleGlobalLoader(key, true);
        const response = await updateProductViaSku({
          updates,
          requestType
        });
        await getSkuUpdatingStatus(response);
        toggleGlobalLoader(key, false);
        toastr.success(uploadInventorySuccess);
        Mixpanel.sendEvent({ eventName: 'uploaded_global_inventory_by_sku' });
        hidePopOver();
      } catch (error) {
        toastr.error(uploadInventoryError);
        toggleGlobalLoader(key, false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hidePopOver, getSkuUpdatingStatus]
  );

  const excelRef = useRef(null);
  const globalInventoryDialogRef = useOnClickOutside(hidePopOver);

  const getTrimmedValue = rowValue => {
    if (typeof rowValue === 'string' || typeof rowValue === 'number') {
      return rowValue.toString().trim();
    }
    return null;
  };

  const formatStoneData = useCallback(
    inputData => {
      const uploadData = [];
      if (inputData.length === 0 || inputData.length === 1) {
        toastr.error(excelError);
        return;
      }
      const row = inputData[0];
      const [
        skuIndex,
        stoneTypeIndex,
        stoneShapeIndex,
        stonePiecesIndex,
        diamondColorIndex,
        diamondClarityIndex,
        totalStoneWeightIndex,
        totalStoneCostIndex,
        discountPercentIndex,
        discountAmountIndex,
        finalAmountIndex
      ] = [
        STONE_EXCEL_COLUMN_NAMES.SKU,
        STONE_EXCEL_COLUMN_NAMES.STONE_TYPE,
        STONE_EXCEL_COLUMN_NAMES.STONE_SHAPE,
        STONE_EXCEL_COLUMN_NAMES.STONE_PCS,
        STONE_EXCEL_COLUMN_NAMES.DIA_COLOR,
        STONE_EXCEL_COLUMN_NAMES.DIA_CLARITY,
        STONE_EXCEL_COLUMN_NAMES.TOTAL_STONE_WEIGHT,
        STONE_EXCEL_COLUMN_NAMES.TOTAL_STN_COST,
        STONE_EXCEL_COLUMN_NAMES.DISCOUNT_PERCENT,
        STONE_EXCEL_COLUMN_NAMES.DISCOUNT_AMOUNT,
        STONE_EXCEL_COLUMN_NAMES.FINAL_COST
      ].map(matchValue =>
        row.findIndex(rowItem =>
          matchText({ text: rowItem, matchValue }, { caseInsensitive: true })
        )
      );
      const invalidRows = [];

      for (let m = 1; m < inputData.length; m++) {
        const row = inputData[m];
        const totalStoneWeight = getTrimmedValue(row[totalStoneWeightIndex]);
        const totalStoneCost = getTrimmedValue(row[totalStoneCostIndex]);
        if (!totalStoneCost || !totalStoneWeight) {
          invalidRows.push({
            rowId: m,
            invalidFields: [
              ...(!totalStoneCost ? ['stone cost'] : []),
              ...(!totalStoneWeight ? ['stone weight'] : [])
            ]
          });
          break;
        }
        uploadData.push({
          sku: row[skuIndex],
          productUpdate: [
            {
              stoneType: getTrimmedValue(row[stoneTypeIndex]),
              stoneShape: getTrimmedValue(row[stoneShapeIndex]),
              stonePieces: getTrimmedValue(row[stonePiecesIndex]),
              diamondColor: getTrimmedValue(row[diamondColorIndex]),
              diamondClarity: getTrimmedValue(row[diamondClarityIndex]),
              totalStoneWeight,
              totalStoneCost,
              discountPercent: getTrimmedValue(row[discountPercentIndex]),
              discountAmount: getTrimmedValue(row[discountAmountIndex]),
              finalAmount: getTrimmedValue(row[finalAmountIndex])
            }
          ]
        });
      }

      if (uploadData.length === 0) {
        toastr.error(excelError);
        return;
      }

      if (invalidRows.length) {
        toastr.error(invalidStoneRowsError);
        return;
      }
      uploadInventory(uploadData, 'DIAMONDS');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [uploadInventory]
  );

  const formatData = useCallback(
    inputData => {
      const uploadData = [];
      if (inputData.length === 0 || inputData.length === 1) {
        toastr.error(excelError);
        return;
      }
      const row = inputData[0];
      const [skuIndex, inventoryIndex, priceIndex, discountedPriceIndex] = [
        'SKU',
        'Inventory',
        'Price',
        'Discounted Price'
      ].map(matchValue =>
        row.findIndex(rowItem =>
          matchText({ text: rowItem, matchValue }, { caseInsensitive: true })
        )
      );

      /** ignore 1st row --> header **/
      for (let m = 1; m < inputData.length; m++) {
        const row = inputData[m];
        if (row[skuIndex]) {
          uploadData.push({
            sku: row[skuIndex],
            productUpdate: {
              ...(isValidNumber(row[inventoryIndex]) && {
                stockCount: parseInt(row[inventoryIndex])
              }),
              ...(isValidNumber(row[priceIndex]) && { price: parseFloat(row[priceIndex]) }),
              ...(isValidNumber(row[discountedPriceIndex]) && {
                discountedPrice: parseFloat(row[discountedPriceIndex])
              })
            }
          });
        }
      }
      if (uploadData.length === 0) {
        toastr.error(excelError);
        return;
      }
      uploadInventory(uploadData);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [uploadInventory]
  );

  const handleUpload = useCallback(
    async (e, isStoneDetailsUpload) => {
      e.stopPropagation();
      e.preventDefault();

      const file = e.target.files[0];
      if (isFileCSV(file)) {
        const result = await parseFileToCsv(file);
        isStoneDetailsUpload ? formatStoneData(result) : formatData(result);
      } else {
        toastr.error(invalidFileError);
      }
      if (!isNil(excelRef) && excelRef.current.value) {
        excelRef.current.value = null;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formatData, formatStoneData]
  );

  const renderGlobalSkuUpload = useCallback(
    ({ title, downloadText, buttonText, isStoneDetailsUpload = false, onDownloadButtonClick }) => {
      return (
        <>
          <p className={`title ${isStoneDetailsUpload ? 'customStyles' : ''}`}>{title}</p>
          <button
            className="globalInventoryButton downloadTemplateButton"
            onClick={onDownloadButtonClick}
          >
            <DownloadIcon className="iconStyles" style={{ fill: 'white' }} />
            <span className="text">{downloadText}</span>
          </button>
          <label className="globalInventoryButton uploadInventoryButton">
            <input
              className="fileInput uploadInventoryInput"
              ref={excelRef}
              type="file"
              name="excelFile"
              accept=".xls,.xlsx"
              onChange={e => handleUpload(e, isStoneDetailsUpload)}
            />
            {isStoneDetailsUpload ? (
              <DiamondIcon className="iconStyles" width={18} height={18} />
            ) : (
              <MsExcel className="iconStyles" style={{ fill: 'white' }} />
            )}
            <span className="text">{buttonText}</span>
          </label>
        </>
      );
    },
    [handleUpload]
  );

  const content = useCallback(
    ({ position, targetRect, popoverRect }) => {
      return (
        <>
          <ArrowContainer
            position={position}
            targetRect={targetRect}
            popoverRect={popoverRect}
            arrowColor="#1C242E"
            arrowSize={10}
          >
            <div className={'globalInventoryContainer'} ref={globalInventoryDialogRef}>
              {renderGlobalSkuUpload({
                title: t('sync_global_product_data'),
                downloadText: t('download_template'),
                buttonText: t('update_products_by_sku'),
                onDownloadButtonClick: downloadTemplate
              })}
              {productType === JEWELLERY.id &&
                renderGlobalSkuUpload({
                  title: t('upload_stone_details'),
                  downloadText: t('download_data'),
                  buttonText: t('upload_stone_details'),
                  onDownloadButtonClick: downloadStoneDetailsData,
                  isStoneDetailsUpload: true
                })}
            </div>
          </ArrowContainer>
        </>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      downloadStoneDetailsData,
      downloadTemplate,
      globalInventoryDialogRef,
      productType,
      renderGlobalSkuUpload
    ]
  );

  return (
    <Popover
      ref={globalInventoryDialogRef}
      isOpen={true}
      position={['right']}
      padding={10}
      containerStyle={{ zIndex: 11 }}
      content={content}
    >
      {<div></div>}
    </Popover>
  );
};
