import { registerCleanupHandler } from 'qs-helpers/ClearSavedData';

class Selected {
  constructor() {
    this.active = false;
    this.selected = {};
    this.listeners = [];
    this.activeListeners = [];
    this.allItems = [];
    this.preservedSelections = new Set();
  }

  // is selection state active
  isActive() {
    return this.active;
  }

  isSelected(key) {
    return !!this.selected[key];
  }

  isAnySelected() {
    return !!Object.keys(this.selected).length;
  }

  isAnyPreservedSelected() {
    return this.getTotalPreservedSelectionCount() !== 0;
  }

  getCount() {
    return Object.keys(this.selected).length;
  }

  getTotalPreservedSelectionCount() {
    return this.preservedSelections.size;
  }

  // get all selected key
  getAll() {
    return this.selected;
  }

  getAllSelectedItems() {
    return Object.keys(this.selected);
  }

  getAllPreservedSelections() {
    return this.preservedSelections;
  }

  // checks if all items of that list are selected
  areAllSelected() {
    return this.getCount() === this.allItems.length && this.allItems.length !== 0;
  }

  // takes passed array and considers it as the list on which actions will be performed
  setAllItems(items) {
    this.allItems = items;
  }

  getAllItems() {
    return this.allItems;
  }

  addItems(items) {
    this.allItems = this.allItems.concat(items);
  }

  removeItems(items) {
    const toRemove = new Set(items);
    this.allItems = this.allItems.filter(item => !toRemove.has(item));
  }

  getAllItemsCount() {
    return this.allItems.length;
  }

  // activates the selection state and notifies everybody that is subscribed
  activate() {
    if (this.active) {
      return;
    }
    this.active = true;
    this.notifyActive();
  }

  // deactivates the selection state and notifies everybody that is subscribed and removes all selected items
  deactivate() {
    if (!this.active) {
      return;
    }

    this.active = false;
    this.notifyActive();

    this.removeAll();
  }

  //activates or deactivates the selection without removing all selected items
  toggleActive() {
    this.active = !this.active;
    this.notifyActive();
  }

  setActive(value) {
    if (this.active === value) {
      return;
    }
    this.active = value;
    this.notifyActive();
  }

  // deactivates the selection state and removes all items from the selected list
  reset() {
    this.deactivate();
    this.setAllItems([]);
  }

  // notifies all subscribed listeners of the selected list
  notify({ refreshing = false } = {}) {
    this.listeners.forEach(listener => {
      try {
        listener(this.getAll(), { refreshing });
      } catch (error) {
        // Handle error
      }
    });
  }

  notifyActive() {
    this.activeListeners.forEach(listener => {
      try {
        listener(this.isActive());
      } catch (error) {
        // Handle error
      }
    });
  }

  toggle(key) {
    if (this.selected[key]) {
      this.remove(key);
    } else {
      this.add(key);
    }
  }

  set(selected, { refreshing } = {}) {
    this.selected = selected;
    this.notify({ refreshing });
  }

  setAll() {
    const selected = {};
    this.allItems.forEach(item => {
      selected[item] = true;
      this.preservedSelections.add(item);
    });

    this.set(selected);
  }

  add(key) {
    this.selected[key] = true;
    this.preservedSelections.add(key);
    this.notify();
  }

  remove(key) {
    delete this.selected[key];
    this.preservedSelections.delete(key);
    this.notify();
  }

  removeSelected() {
    Object.keys(this.selected).forEach(key => {
      this.preservedSelections.delete(key);
    });
    this.selected = {};
    this.notify();
  }

  removeKeys(keys) {
    keys.forEach(key => {
      delete this.selected[key];
      this.preservedSelections.delete(key);
    });
    this.notify();
  }

  // removes all items from the selection state
  removeAll() {
    this.selected = {};
    this.preservedSelections.clear();
    this.notify();
  }

  refreshItemsAndSelections(items) {
    this.setAllItems(items);
    const updatedSelection = {};
    this.allItems.forEach(item => {
      //Preserve existing selections
      if (this.selected[item] || this.preservedSelections.has(item)) {
        updatedSelection[item] = true;
      }
    });
    this.set(updatedSelection, { refreshing: true });
  }

  addListener(listener) {
    this.listeners.push(listener);
    listener(this.getAll());
  }

  removeListener(listener) {
    const pos = this.listeners.indexOf(listener);

    if (pos !== -1) {
      this.listeners.splice(pos, 1);
    }
  }

  addActiveListener(listener) {
    this.activeListeners.push(listener);
    listener(this.isActive());
  }

  removeActiveListener(listener) {
    const pos = this.activeListeners.indexOf(listener);

    if (pos !== -1) {
      this.activeListeners.splice(pos, 1);
    }
  }
}

class SelectedCatalogues extends Selected {}
export let selectedCatalogue = new SelectedCatalogues();

class SelectedProducts extends Selected {
  constructor() {
    super();
    //Component specific subscribers
    this.activeComponent = null;
    this.activeComponentListeners = {};
  }

  isActive(components) {
    if (!Array.isArray(components)) {
      components = [components];
    }
    return components.find(component => this.activeComponent === component);
  }

  notifyActive(component) {
    const activeListeners = this.activeComponentListeners[component];
    if (!Array.isArray(activeListeners)) {
      return;
    }
    activeListeners.forEach(listener => {
      listener(this.isActive(component));
    });
  }

  addActiveListener(components, listener) {
    if (!Array.isArray(components)) {
      components = [components];
    }

    components.forEach(component => {
      let activeListeners = this.activeComponentListeners[component];
      if (!Array.isArray(activeListeners)) {
        activeListeners = [];
      }
      activeListeners.push(listener);
      this.activeComponentListeners[component] = activeListeners;
    });

    listener(this.isActive(components));
  }

  removeActiveListener(components, listener) {
    if (!Array.isArray(components)) {
      components = [components];
    }

    components.forEach(component => {
      const activeListeners = this.activeComponentListeners[component];
      if (!Array.isArray(activeListeners)) {
        return;
      }

      const pos = activeListeners.indexOf(listener);
      if (pos === -1) {
        return;
      }
      activeListeners.splice(pos, 1);
    });
  }

  // activates the selection state and notifies everybody that is subscribed
  activate(component) {
    if (this.activeComponent === component) {
      return;
    }
    this.activeComponent = component;
    this.notifyActive(component);
  }

  deactivate(component) {
    if (this.activeComponent !== component && !this.activeComponent) {
      return;
    }

    const componentToDeactivate = component || this.activeComponent;
    this.activeComponent = null;
    this.notifyActive(componentToDeactivate);
    this.removeAll();
  }
}

export let selectedProducts = new SelectedProducts();

export let selectedOrders = new Selected();

export let selectedLabels = new Selected();

export let selectedLabelsToEdit = new Selected();

// Put this in services/helpers
const resetSelectedData = () => {
  selectedCatalogue = new SelectedCatalogues();
  selectedProducts = new SelectedProducts();
  selectedOrders = new Selected();
  selectedLabels = new Selected();
  selectedLabelsToEdit = new Selected();
};

registerCleanupHandler(resetSelectedData);
