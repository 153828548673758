import React, { useState, useCallback, useEffect } from 'react';
import { selectedProducts } from 'qs-data-manager/Selected';
import { deleteProducts } from 'qs-data-manager/Products';
import Catalogues, { getActiveCatalogueId, setActiveCatalogueId } from 'qs-data-manager/Catalogues';
import Mixpanel from 'qs-data-manager/Mixpanel';
import HeaderWithBackAndSelect from 'qs-components/Common/HeaderWithBackAndSelect';
import { ReactComponent as CreateCatalogueIcon } from 'qs-assets/Media/create_catalogue.svg';
import CopySelectedProducts from '../SearchProductHeader/CopySelectedProducts';
import { EDIT_ACTIVE } from 'qs-helpers/Products/constants';
import { Tooltip, withStyles } from '@material-ui/core';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

const IconToolTip = withStyles({
  tooltip: {
    backgroundColor: '#37A06D',
    fontSize: 12
  }
})(Tooltip);

export default ({ goBack, headerClass, activeCatalogueId } = {}) => {
  const [deleteIconVisibility, setDeleteIconVisibility] = useState(false);
  const [showCatalogueCopyOptions, setShowCatalogueCopyOptions] = useState(false);
  const [checkboxValue, setCheckboxValue] = useState(false);
  const [totalItemsCount, setTotalItemsCount] = useState(0);

  const { t } = getI18N();

  useEffect(() => {
    let previousAnySelected;
    const onSelectedValueChange = (_, { refreshing } = {}) => {
      const isAnySelected = selectedProducts.isAnySelected();
      setDeleteIconVisibility(isAnySelected);
      setCheckboxValue(selectedProducts.areAllSelected());
      setTotalItemsCount(selectedProducts.getAllItemsCount());

      //Data was refreshed because of some filtering action, no need to handle the case of none selected
      if (refreshing) {
        previousAnySelected = undefined;
        return;
      }

      if (
        typeof previousAnySelected === 'boolean' &&
        previousAnySelected !== isAnySelected &&
        !isAnySelected
      ) {
        goBack();
        return;
      }
      previousAnySelected = isAnySelected;
    };

    const activeListener = isActive => {
      if (!isActive) {
        goBack();
      }
    };

    selectedProducts.activate(EDIT_ACTIVE);
    selectedProducts.addListener(onSelectedValueChange);
    selectedProducts.addActiveListener(EDIT_ACTIVE, activeListener);

    Mixpanel.sendEvent({ eventName: 'edit_catalogue_tags_button_clicked' });

    return () => {
      selectedProducts.removeListener(onSelectedValueChange);
      selectedProducts.removeActiveListener(EDIT_ACTIVE, activeListener);
      selectedProducts.deactivate(EDIT_ACTIVE);
    };
  }, [goBack]);

  const deleteSelectedProducts = useCallback(
    e => {
      e.stopPropagation();
      const selectedProductsCount = selectedProducts.getTotalPreservedSelectionCount();
      const shouldRemove = window.confirm(
        selectedProductsCount > 1
          ? t('are_you_sure_you_want_to_delete_selected_products_from_catalogue', {
              selectedProductsCount
            })
          : t('are_you_sure_you_want_to_delete_selected_product_from_catalogue', {
              selectedProductsCount
            })
      );
      if (shouldRemove) {
        const productIds = Array.from(selectedProducts.getAllPreservedSelections());
        deleteProducts(productIds);
        const catalogueId = getActiveCatalogueId();
        setActiveCatalogueId(catalogueId);
        goBack();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [goBack]
  );

  const onCopyClick = () => {
    setShowCatalogueCopyOptions(true);
  };

  const onCancelCopyClick = useCallback(() => {
    setShowCatalogueCopyOptions(false);
  }, []);

  const onCompleteCopy = useCallback(() => {
    onCancelCopyClick();
    goBack();
  }, [onCancelCopyClick, goBack]);

  const checkboxValueChange = event => {
    event.stopPropagation();
    const { checked } = event.target;
    if (checked) {
      selectedProducts.setAll();
      return;
    }

    selectedProducts.deactivate();
  };

  const renderAdditionalIcons = () => (
    <IconToolTip title={t('copy_products')}>
      <CreateCatalogueIcon
        aria-label="Copy products"
        onClick={onCopyClick}
        className={'editProductHeaderStyling copyIcon'}
      />
    </IconToolTip>
  );
  const catalogueTitle = Catalogues.getCatalogueMetaFromCache(activeCatalogueId).title || '';

  return (
    <>
      <HeaderWithBackAndSelect
        headerClass={headerClass}
        goBack={goBack}
        deleteIconVisibility={deleteIconVisibility}
        onDeleteClick={deleteSelectedProducts}
        checkboxValue={checkboxValue}
        onCheckBoxToggle={checkboxValueChange}
        disableCheckbox={totalItemsCount === 0}
        deleteIconTitle={t('delete_products')}
        backButtonClass="editProductHeaderStyling editProductHeaderBackButton"
        selectButtonClass="editProductHeaderStyling editProductHeaderSelectCheckbox"
        checkboxLabel={`${checkboxValue ? t('deselect_all') : t('select_all')} ${totalItemsCount}`}
        renderAdditionalIcons={renderAdditionalIcons}
      />
      {showCatalogueCopyOptions && (
        <CopySelectedProducts
          onClose={onCancelCopyClick}
          onComplete={onCompleteCopy}
          catalogueTitle={catalogueTitle}
          activeCatalogueId={activeCatalogueId}
        />
      )}
    </>
  );
};
