import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { getI18N } from 'qs-services/i18N';
import DomainDialog from '../DomainDialog';
import { ReactComponent as RightArrow } from 'qs-assets/Media/icon_long_arrow_right.svg';
import './style.scss';

export default function SetUp({ type, update }) {
  const [dialogDetail, setDialogDetail] = useState(false);
  const [domainType, setDomainType] = useState();

  const setup = {};
  const { t } = getI18N();

  const updateDomain = () => {
    update();
  };

  const handleClose = () => {
    setDialogDetail(false);
  };

  const dialog = () => {
    setDomainType(type);
    setDialogDetail(true);
  };

  if (type === 'catalog') {
    setup.title = `catalog.to ${t('subdomain')}`;
    setup.content = t('get_your_branded_catalog.to_subdomain');

    setup.example = ['abc.catalog.to', 'xyz.catalog.to'];
  }

  if (type === 'custom') {
    setup.title = t('custom_website_domain');
    setup.content = t('connect_your_domain_or_sub_domain');
    setup.example = ['www.abc.com', 'www.xyz.com', 'www.example.com'];
  }

  return (
    <div className="setupContainer">
      <div className="setupTitle">{setup.title}</div>
      <div className="setupContent">
        {setup.content} <br />
        <br /> {t('example')} -
      </div>

      <div className="setupExample">
        {setup.example.map((example, key) => {
          return <div key={key}>{example}</div>;
        })}
      </div>
      <div className="setupButton">
        <Button style={{ color: type === 'custom' ? '#a9aaea' : '#f7e88f' }} onClick={dialog}>
          <div style={{ display: 'flex' }}>
            <span>{t('set_up_now')}</span>
            <RightArrow fill="white" style={{ width: '25px', marginLeft: '10px' }} />
          </div>
        </Button>
      </div>
      {dialogDetail && (
        <DomainDialog
          domainType={domainType}
          dialogClose={handleClose}
          updateDomain={() => {
            updateDomain();
          }}
        />
      )}
    </div>
  );
}
