import React, { useEffect, useState, useContext, useCallback, Fragment } from 'react';
import {
  DialogDarkTheme,
  PrimaryButton,
  SecondaryButton
} from '../../../../Common/DarkThemeDialog';
import Loader from '../../../../Common/Loader';
import SmallImageLoader from 'qs-components/Common/SmallImageLoader';
import ImageGalleryView from '../../../../Common/ImageGalleryView';
import { getPictureIdFromImageUrl, stringifyTax, getPriceFormattedComponent } from 'qs-helpers';
import { selectedOrders } from 'qs-data-manager/Selected';
import { saveMerchantInquiryText } from 'qs-api/Orders/api';
import { makeStyles, Checkbox, DialogActions, DialogContent } from '@material-ui/core';
import { getDiscountOnOrderEntry } from 'qs-helpers/Orders/OrderPriceCalculator';
import CouponIcon from 'qs-assets/Media/ic_coupon_white.png';
import Popover, { ArrowContainer } from 'react-tiny-popover';
import EditableField from '../../EditableField';
import { ActiveOrderView } from '../../context';
import StockManagement from './StockManagement';
import { DISCOUNT_INPUT, PRICE_INPUT } from 'qs-helpers/Orders/constants';
import { editNotAllowedReason } from 'qs-helpers/Orders/ShippingProviderHelper';
import {
  canApplyCouponOnDiscountedPrice,
  getCouponCode,
  getCouponDiscountValueToShow,
  getSetMultiplierFromProduct,
  isInquirySet
} from 'qs-helpers/Orders/ResponseProcessor';
import SetPrice from './SetPrice';
import {
  changeInquiryQuantity,
  changeOrderProductTax,
  getOrdersProductsFromCache,
  setOrdersProductsInCache,
  updateOrderProductsPrice
} from 'qs-data-manager/Orders/OrdersProductsOperations';
import InquiryQuantityEdit from './InquiryQuantityEdit';
import { ActiveOrderMeta } from '../../../context';
import useMakeQuery from 'qs-hooks/useMakeQuery';
import {
  getCachedOrderMetaData,
  handleOrderMetaCleanup,
  handleOrderMetaFetch
} from 'qs-helpers/Orders/DataQueryHelper';
import EditTax from './EditTax';
import toastr from 'toastr';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

const useStyles = makeStyles({
  root: {
    color: '#494f5d'
  }
});

export default ({
  productData,
  coupon,
  extraClass = '',
  headerRow = false,
  headerCheckboxChange,
  ordersProductCount,
  index,
  orderProductsPictureData,
  orderEditable,
  shippingConfig
}) => {
  const {
    isDeletedByVisitor,
    inquiryId,
    inquiryText: customerInquiryText,
    merchantNotes: merchantInquiryText,
    originalPrice,
    product = {},
    discountedPrice,
    couponDiscountPrice,
    customDiscountPrice,
    finalPrice = 0,
    taxAmount,
    itemCount = 1,
    read,
    canUpdateStock,
    stockCount
  } = productData || {};
  const { taxes, name, pictureUrl, optionMeta, discounted_price, sku } = product;
  const pictureId = getPictureIdFromImageUrl({ url: pictureUrl });
  const setMultiplier = getSetMultiplierFromProduct({ product });
  const isSet = isInquirySet({ product });
  const customDiscountPriceExists = (customDiscountPrice || 0) > 0;
  const productDiscountedPriceExists =
    (discounted_price || 0) > 0 && canApplyCouponOnDiscountedPrice(coupon);
  const couponDiscountPriceExists = (couponDiscountPrice || 0) > 0;
  const { discountPriceReason, priceReason, quantityReason, taxReason } = editNotAllowedReason(
    shippingConfig
  );
  const { t } = getI18N();

  const {
    activeOrderView: { manageInventory }
  } = useContext(ActiveOrderView);

  const {
    activeOrderMeta: { selectedOrderId }
  } = useContext(ActiveOrderMeta);

  const [{ data }] = useMakeQuery({
    changeDependancy: [selectedOrderId],
    cachedDataHandler: getCachedOrderMetaData,
    actionHandler: handleOrderMetaFetch,
    cleanupHandler: handleOrderMetaCleanup
  });

  const { isFinalized } = data || {};

  const [selectionMode, setSelectionMode] = useState(() => selectedOrders.isActive());
  const [showDiscountPopup, setShowDiscountPopup] = useState(false);
  const [showInquiryTextModal, setShowInquiryTextModal] = useState(false);
  const [inquiryTextModalContent, setInquiryTextModalContent] = useState(
    typeof merchantInquiryText === 'string' && merchantInquiryText.length > 0
      ? merchantInquiryText
      : ''
  );
  const [inquiryTextLoading, setInquiryTextLoading] = useState(false);
  const [checkBoxValue, setCheckboxValue] = useState(() => selectedOrders.isSelected(inquiryId));
  const checkboxClasses = useStyles();
  const [showExpandedImage, setShowExpandedImage] = useState(false);

  const handleSaveMerchantInquiryNote = async () => {
    try {
      setInquiryTextLoading(true);
      await saveMerchantInquiryText({
        inquiryId,
        notes:
          !!inquiryTextModalContent && typeof inquiryTextModalContent === 'string'
            ? inquiryTextModalContent
            : ''
      });
      setShowInquiryTextModal(false);
      const inquiryData = getOrdersProductsFromCache({ orderId: selectedOrderId });
      const currentInquiryIndex = (inquiryData.inquiries || []).findIndex(
        inquiry => inquiry.inquiryId === inquiryId
      );
      if (currentInquiryIndex > -1) {
        const currentInquiry = { ...inquiryData.inquiries[currentInquiryIndex] };
        const updatedInquiries = [...inquiryData.inquiries];
        updatedInquiries.splice(currentInquiryIndex, 1, {
          ...currentInquiry,
          merchantNotes: inquiryTextModalContent
        });
        setOrdersProductsInCache({
          orderId: selectedOrderId,
          updates: {
            inquiries: updatedInquiries
          }
        });
      }
      toastr.success(t('order_notes_updated'));
    } catch (error) {
      toastr.error(error.message || t('error_saving_order_note'));
    } finally {
      setInquiryTextLoading(false);
    }
  };

  useEffect(() => {
    const onSelectionStateChange = isActive => {
      setSelectionMode(isActive);
    };

    const onSelectedValueChange = (all = {}) => {
      if (headerRow) {
        if (selectedOrders.getCount() === ordersProductCount) {
          setCheckboxValue(true);
          return;
        }
        setCheckboxValue(false);
        return;
      }
      setCheckboxValue(all[inquiryId] ? true : false);
    };

    selectedOrders.addActiveListener(onSelectionStateChange);
    selectedOrders.addListener(onSelectedValueChange);

    return () => {
      selectedOrders.removeActiveListener(onSelectionStateChange);
      selectedOrders.removeListener(onSelectedValueChange);
    };
  }, [inquiryId, headerRow, ordersProductCount]);

  const isDiscountHoverable = () => {
    if (customDiscountPriceExists) {
      return productDiscountedPriceExists || couponDiscountPriceExists;
    }

    // product has discounted_price, show the breakup only if the coupon can actually be applied
    // on the discounted_price, otherwise the final value is just the coupon value
    if (productDiscountedPriceExists) {
      return coupon && coupon.applyOnDiscountedPrice;
    }

    // only coupon price exists, that is the final price
    return false;
  };

  const isDiscountEditable = () => {
    if (isDeletedByVisitor || (coupon && coupon.applyOnDiscountedPrice === false)) {
      return false;
    }

    return true;
  };

  const canEditInquiryField = useCallback(() => {
    return orderEditable;
  }, [orderEditable]);

  const canEditInquiryQuantity = useCallback(() => {
    if (isFinalized) {
      return orderEditable;
    }
    return true;
  }, [orderEditable, isFinalized]);

  const updatePriceData = useCallback(
    (updatedPrice, updatedDiscountedPrice) =>
      updateOrderProductsPrice({
        orderId: selectedOrderId,
        inquiryId,
        price: updatedPrice,
        discountedPrice: updatedDiscountedPrice
      }),
    [inquiryId, selectedOrderId]
  );

  const onUpdateTax = useCallback(
    tax => changeOrderProductTax({ orderId: selectedOrderId, inquiryId, taxes: [tax] }),
    [selectedOrderId, inquiryId]
  );

  const onEditInquiryQuantity = useCallback(
    async updatedItemCount => {
      const { inventoryUpdated } = await changeInquiryQuantity({
        inquiryId,
        orderId: selectedOrderId,
        itemCount: updatedItemCount,
        isFinalized
      });

      if (inventoryUpdated) {
        toastr.success(t('inventory_updated'));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inquiryId, selectedOrderId, isFinalized]
  );

  const handleSelectionOrderRowClick = () => {
    const checked = !checkBoxValue;
    if (headerRow) {
      headerCheckboxChange(checked);
      setCheckboxValue(checked);
      return;
    }

    if (checked) {
      selectedOrders.add(inquiryId);
      return;
    }
    selectedOrders.remove(inquiryId);
  };

  const getProductImageComponent = () => {
    if (typeof pictureUrl !== 'string' || headerRow) {
      return null;
    }
    return (
      <>
        <SmallImageLoader
          url={pictureUrl}
          pictureId={pictureId}
          onClick={() => setShowExpandedImage(true)}
        />
        {showExpandedImage && (
          <ImageGalleryView
            onClose={() => {
              setShowExpandedImage(false);
            }}
            picturesData={orderProductsPictureData}
            startIndex={index}
          />
        )}
      </>
    );
  };

  const getVariantOptionData = optionExtraData => {
    const { rgbValue } = optionExtraData || {};
    if (!Array.isArray(rgbValue)) {
      return null;
    }

    const [r, g, b] = rgbValue;
    return (
      <div className="variantColorBlock" style={{ backgroundColor: `rgb(${r}, ${g}, ${b})` }} />
    );
  };

  const getVariantComponent = () => {
    if ((optionMeta || []).length === 0) {
      return null;
    }

    return (
      <div className="variantDataContainer">
        {optionMeta.map(optionData => (
          <div key={optionData.optionValue} className="variantOptionContainer">
            {getVariantOptionData(optionData.extraData)}
            <span className="ellipsis">{optionData.optionValue}</span>
          </div>
        ))}
      </div>
    );
  };

  const getTitleComponent = () => {
    if (headerRow) {
      return t('title');
    }

    return (
      <>
        {isDeletedByVisitor && (
          <span className="deletedByVisitorText ellipsis">{t('deleted_by_customer')}</span>
        )}
        <div className="titleContainer">
          {!read && <div className="unreadInquiry ellipsis">{t('new')}</div>}
          <div className="ellipsis">{name}</div>
        </div>
        {getVariantComponent()}
        {typeof customerInquiryText === 'string' && customerInquiryText.length > 0 && (
          <span className="customerInquiryTextContainer">
            {t('customer_message')}: {customerInquiryText}
          </span>
        )}
      </>
    );
  };

  const getSkuComponent = () => {
    if (headerRow) {
      return 'SKU';
    }

    return sku;
  };

  const getMerchantInquiryTextComponent = () => {
    if (headerRow) {
      return t('note');
    }

    const hasMerchantInquiryText =
      typeof merchantInquiryText === 'string' && merchantInquiryText.length > 0;
    const isInquiryTextDisabled =
      !!inquiryTextModalContent && typeof inquiryTextModalContent === 'string'
        ? inquiryTextModalContent.length === 0
        : true;

    if (isDeletedByVisitor && !merchantInquiryText) {
      return null;
    }

    const inquiryTextModalComponent = (
      <DialogDarkTheme
        open={showInquiryTextModal}
        PaperProps={{
          className: 'merchantInquiryTextContentContainer',
          noValidate: true
        }}
        onClose={() => setShowInquiryTextModal(false)}
      >
        <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ fontWeight: '700' }}>{t('add_note')}</div>
          <textarea
            className="editMerchantInquiryNoteTextArea"
            onChange={e => setInquiryTextModalContent(e.target.value)}
            value={
              typeof inquiryTextModalContent === 'string' && inquiryTextModalContent.length > 0
                ? inquiryTextModalContent
                : ''
            }
            disabled={isDeletedByVisitor || inquiryTextLoading}
            placeholder={t('add_note')}
            autoFocus={!isDeletedByVisitor}
            autoCapitalize="sentences"
            autoComplete="off"
            autoCorrect="off"
          />
        </DialogContent>
        <DialogActions>
          <SecondaryButton onClick={() => setShowInquiryTextModal(false)}>
            <span>{t('cancel')}</span>
          </SecondaryButton>
          {!isDeletedByVisitor && (
            <PrimaryButton
              color={'primary'}
              onClick={handleSaveMerchantInquiryNote}
              type="submit"
              disabled={inquiryTextLoading || isInquiryTextDisabled}
            >
              {inquiryTextLoading ? <Loader size="extraSmall" /> : <span>{t('save_note')}</span>}
            </PrimaryButton>
          )}
        </DialogActions>
      </DialogDarkTheme>
    );

    return (
      <Fragment>
        <div
          className="merchantInquiryNoteTrigger"
          onClick={() => {
            setInquiryTextModalContent(
              typeof merchantInquiryText === 'string' && merchantInquiryText.length > 0
                ? merchantInquiryText
                : ''
            );
            setShowInquiryTextModal(true);
          }}
        >
          {hasMerchantInquiryText ? t('view_note') : t('add_note')}
        </div>
        {inquiryTextModalComponent}
      </Fragment>
    );
  };

  const getQuantityComponent = () => {
    if (headerRow) {
      return t('qty');
    }

    let quantityValue = itemCount;
    if (isSet) {
      quantityValue = `${itemCount} x ${setMultiplier}`;
    }

    if (isDeletedByVisitor) {
      return quantityValue;
    }

    return (
      <EditableField
        canEditField={canEditInquiryQuantity}
        updaterFunction={onEditInquiryQuantity}
        errorInfo={{
          mail: {
            title: t('edit_product_quantity'),
            subject: t('failed_to_modify_quantity_for_inquiry_order', {
              inquiryId,
              selectedOrderId
            }),
            message: t('something_went_wrong_while_modifying_this_product_quantity')
          },
          fieldEditError: {
            title: t('edit_product_quantity'),
            content: quantityReason
          }
        }}
        renderEditableField={({ onCancel, onUpdate }) => (
          <InquiryQuantityEdit
            inquiryQuantity={itemCount}
            inquiryId={inquiryId}
            stockCount={stockCount}
            canUpdateStock={canUpdateStock}
            onUpdate={onUpdate}
            onCancel={onCancel}
            setMultiplier={setMultiplier}
            isSet={isSet}
            isFinalized={isFinalized}
          />
        )}
      >
        {quantityValue}
      </EditableField>
    );
  };

  const getTaxComponent = () => {
    if (headerRow) {
      return t('tax');
    }

    let editableTaxComponent;
    let editComponentClass;
    let taxDisplayComponent = null;
    const taxForDisplay = stringifyTax(taxes);

    if (taxForDisplay === '') {
      editableTaxComponent = t('add_tax');
      editComponentClass = 'orderSingleViewAddMissingData';
    } else {
      editableTaxComponent = getPriceFormattedComponent(taxAmount / setMultiplier);
      taxDisplayComponent = <div className="taxString ellipsis">{taxForDisplay}</div>;
    }

    if (isDeletedByVisitor) {
      return (
        <>
          {taxForDisplay ? editableTaxComponent : null}
          {taxDisplayComponent}
        </>
      );
    }

    return (
      <>
        <div className="editableFieldContainerForColumnFlex">
          <EditableField
            classes={editComponentClass}
            canEditField={canEditInquiryField}
            updaterFunction={onUpdateTax}
            errorInfo={{
              mail: {
                title: t('edit_inquiry_tax'),
                subject: t('failed_to_modify_tax_for_inquiry_order', {
                  inquiryId,
                  selectedOrderId
                }),
                message: t('something_went_wrong_while_modifying_this_inquiry_tax')
              },
              fieldEditError: {
                title: t('edit_inquiry_tax'),
                content: taxReason
              }
            }}
            renderEditableField={({ onCancel, onUpdate }) => (
              <EditTax onCancel={onCancel} onUpdate={onUpdate} taxes={taxes} />
            )}
          >
            {editableTaxComponent}
          </EditableField>
        </div>
        {taxDisplayComponent}
      </>
    );
  };

  const getPriceComponent = () => {
    if (headerRow) {
      return t('price');
    }

    const pricePerPiece =
      typeof originalPrice === 'number' ? originalPrice / setMultiplier : originalPrice;
    const finalPrice = getPriceFormattedComponent(pricePerPiece);
    if (isDeletedByVisitor) {
      return <span className="ellipsis">{finalPrice}</span>;
    }

    let priceComponent;
    let editableComponentClass;
    if (!finalPrice) {
      editableComponentClass = 'orderSingleViewAddMissingData';
      priceComponent = 'ADD PRICE';
    } else {
      priceComponent = finalPrice;
    }

    return (
      <EditableField
        classes={editableComponentClass}
        canEditField={canEditInquiryField}
        updaterFunction={updatePriceData}
        errorInfo={{
          mail: {
            title: t('edit_price'),
            subject: t('failed_to_modify_edit_price_for_order', { selectedOrderId }),
            message: t('something_went_wrong_while_editing_this_order_price')
          },
          fieldEditError: {
            title: t('edit_price'),
            content: priceReason
          }
        }}
        renderEditableField={({ onCancel, onUpdate }) => (
          <SetPrice
            onComplete={onUpdate}
            onCancel={onCancel}
            price={originalPrice}
            isSet={isSet}
            setMultiplier={setMultiplier}
            discountedPrice={discounted_price}
            coupon={coupon}
            selectedField={PRICE_INPUT}
            disableDiscountField={!isDiscountEditable()}
          />
        )}
      >
        {priceComponent}
      </EditableField>
    );
  };

  const getDiscountBreakupComponent = ({ position, targetRect, popoverRect }) => {
    const breakupData = [
      {
        showSection: productDiscountedPriceExists,
        label: t('discount_on_product'),
        currentDiscountedPrice: discounted_price,
        key: 'PRODUCT_DISCOUNT'
      },
      {
        showSection: couponDiscountPriceExists,
        label: t('discount_from_coupon'),
        currentDiscountedPrice: couponDiscountPrice,
        key: 'COUPON_DISCOUNT'
      },
      {
        showSection: customDiscountPriceExists,
        label: t('order_discount'),
        currentDiscountedPrice: customDiscountPrice,
        key: 'ORDER_DISCOUNT'
      },
      {
        showSection: true,
        label: t('total_discount'),
        againstPrice: originalPrice,
        currentDiscountedPrice: discountedPrice,
        key: 'TOTAL_DISCOUNT'
      }
    ];

    return (
      <ArrowContainer
        position={position}
        targetRect={targetRect}
        popoverRect={popoverRect}
        arrowColor={'#10151b'}
        arrowSize={6}
      >
        <div className="orderProductRowDiscountBreakup">
          {
            breakupData.reduce(
              (
                cumulativeObj,
                { showSection, label, currentDiscountedPrice, againstPrice, key }
              ) => {
                if (showSection > 0) {
                  let { prevPrice } = cumulativeObj;
                  if (typeof againstPrice === 'number') {
                    prevPrice = againstPrice;
                  }

                  cumulativeObj.cumulativeArray.push(
                    <div className="discountBreakupRow" key={key}>
                      <span className="discountLabel">{label}</span>
                      <span>
                        {getPriceFormattedComponent(
                          getDiscountOnOrderEntry({
                            discountedPrice: currentDiscountedPrice,
                            sellingPrice: prevPrice
                          }) / setMultiplier
                        )}
                      </span>
                    </div>
                  );
                  cumulativeObj.prevPrice = currentDiscountedPrice;
                }
                return cumulativeObj;
              },
              { cumulativeArray: [], prevPrice: originalPrice }
            ).cumulativeArray
          }
        </div>
      </ArrowContainer>
    );
  };

  const getCouponComponent = () => {
    const discountValueToShow = getCouponDiscountValueToShow(coupon);
    if (!discountValueToShow) {
      return null;
    }

    const code = getCouponCode(coupon);

    return (
      <div className="couponLabelContainer">
        <img src={CouponIcon} alt="" className="couponIcon" />
        <span className="couponLabel ellipsis">
          {code} {discountValueToShow}
        </span>
      </div>
    );
  };

  const getDiscountPriceComponent = editable => {
    const discountPriceData = getPriceFormattedComponent(discountedPrice / setMultiplier);

    if (!editable) {
      return <div className="ellipsis">{discountPriceData}</div>;
    }

    return (
      <div className="editableFieldContainerForColumnFlex">
        <EditableField
          canEditField={canEditInquiryField}
          updaterFunction={updatePriceData}
          errorInfo={{
            mail: {
              title: t('edit_discounted_price'),
              subject: t('failed_to_edit_discounted_price_for_order', { selectedOrderId }),
              message: t('something_went_wrong_while_editing_this_order_discounted_price')
            },
            fieldEditError: {
              title: t('edit_discounted_price'),
              content: discountPriceReason
            }
          }}
          renderEditableField={({ onCancel, onUpdate }) => (
            <SetPrice
              onComplete={onUpdate}
              onCancel={onCancel}
              price={originalPrice}
              isSet={isSet}
              setMultiplier={setMultiplier}
              discountedPrice={discounted_price}
              coupon={coupon}
              selectedField={DISCOUNT_INPUT}
            />
          )}
        >
          {discountPriceData}
        </EditableField>
      </div>
    );
  };

  const getDiscountComponent = () => {
    if (headerRow) {
      return (
        <div className="discountContainer">
          <span className="ellipsis">{t('discounted_price')}</span>
        </div>
      );
    }

    if (typeof discountedPrice !== 'number') {
      // Return an empty container to ensure that the layout is maintained
      return <div className="discountContainer" />;
    }

    let onShow = null;
    let onHide = null;

    if (isDiscountHoverable()) {
      onShow = () => setShowDiscountPopup(true);
      onHide = () => setShowDiscountPopup(false);
    }

    const discountEditable = isDiscountEditable();
    let discountContainerClass = 'discountContainer';
    if (discountEditable) {
      discountContainerClass += ' discountEditable';
    }

    return (
      <Popover
        isOpen={showDiscountPopup}
        position={['bottom']}
        containerStyle={{ zIndex: 2 }}
        content={getDiscountBreakupComponent}
      >
        <div
          className={discountContainerClass}
          onMouseEnter={onShow}
          onMouseLeave={onHide}
          onClick={onHide}
        >
          {getDiscountPriceComponent(discountEditable)}
          {getCouponComponent()}
        </div>
      </Popover>
    );
  };

  const getTotalComponent = () => {
    if (headerRow) {
      return t('total');
    }

    if (isDeletedByVisitor) {
      return getPriceFormattedComponent(0);
    }

    const total = finalPrice * itemCount;
    return getPriceFormattedComponent(total);
  };

  let orderRowClass = 'orderProductRowContainer';
  if (isDeletedByVisitor) {
    orderRowClass += ' deletedByVisitor';
  }
  if (headerRow) {
    orderRowClass += ' headerRowContainer';
  }

  let orderRowContainerClass = `orderProductRowHoverContainer ${extraClass}`,
    orderRowClick = null,
    checkBoxChange = null;
  if (selectionMode) {
    if (!headerRow) {
      orderRowContainerClass += ` selectionMode`;
      orderRowClick = handleSelectionOrderRowClick;
    } else {
      checkBoxChange = handleSelectionOrderRowClick;
    }
  }
  // Select the row only if it is not the header row
  if (checkBoxValue && !headerRow) {
    orderRowContainerClass += ` selectedRow`;
  }

  return (
    <>
      <div className={orderRowContainerClass} onClick={orderRowClick}>
        <div className={orderRowClass}>
          {selectionMode && (
            <div className="checkboxContainer">
              <Checkbox
                classes={{ root: checkboxClasses.root }}
                className="checkbox"
                checked={checkBoxValue}
                onChange={checkBoxChange}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </div>
          )}
          <div className="productImageContainer">{getProductImageComponent()}</div>
          <div className="productSummaryContainer">{getTitleComponent()}</div>
          <div className="skuContainer ellipsis">{getSkuComponent()}</div>
          <div className="merchantInquiryTextContainer">{getMerchantInquiryTextComponent()}</div>
          <div className="priceNumberContainer">{getPriceComponent()}</div>
          <div className="quantityContainer">{getQuantityComponent()}</div>
          <div className="taxContainer">{getTaxComponent()}</div>
          {getDiscountComponent()}
          <div className="totalContainer">
            <span className="ellipsis">{getTotalComponent()}</span>
          </div>
          {manageInventory && <StockManagement headerRow={headerRow} inquiryData={productData} />}
        </div>
      </div>
    </>
  );
};
