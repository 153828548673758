import React, { useEffect, useContext, Fragment, useState, useCallback } from 'react';
import eventbus from 'eventing-bus';
import arrayMove from 'array-move';
import toastr from 'toastr';

import { IMAGE_LOADER, IMAGE_ERROR, IMAGE_HIDE_LOADER } from 'qs-helpers/PicturesProcessingHelper';
import { getBasicInfoFromCache, setPictureAsDefault } from 'qs-data-manager/ProductDetails';
import { convertImageUrlSize, toggleGlobalLoader } from 'qs-helpers';
import { CurrentDefaultImageContext } from '../../../context';
import usePictureRetryHook from 'qs-hooks/usePictureRetryHook';
import usePreviousHook from 'qs-hooks/usePreviousHook';
import { UPDATE_IMAGE_DATA } from '../../../reducer';
import { sortPictures } from 'qs-api/Products/api';
import {
  DELETED_PICTURE_ID,
  getPictures,
  UPDATE_PICTURES_POSITIONS,
  TOGGLE_REARRANGING_PICTURES
} from 'qs-data-manager/Products';

import Loader from 'qs-components/Common/Loader';
import ImageUpload from '../../../Common/ImageUpload';
import { ReactComponent as ErrorIcon } from 'qs-assets/Media/image-error.svg';
import './styles.scss';

import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { getI18N } from 'qs-services/i18N';

const SortableItem = SortableElement(({ value, getImageUiBasedOnState }) =>
  getImageUiBasedOnState(value)
);

const SortableList = SortableContainer(
  ({ items, getImageUiBasedOnState, disableRearrangingForPicture }) => (
    <div className="sortableListContainer">
      {items.map((value, index) => {
        return (
          <SortableItem
            key={`item-${value.id}`}
            index={index}
            value={value}
            disabled={value.hasOwnProperty('videoUrl') || disableRearrangingForPicture(value)}
            collection={value.hasOwnProperty('videoUrl') ? 1 : 0}
            getImageUiBasedOnState={getImageUiBasedOnState}
          />
        );
      })}
    </div>
  )
);

export default ({ loading, pictures, scrollToTop, activeProductId }) => {
  const [
    picturesLoadState,
    handleImageLoad,
    handleImageError,
    updateTimeoutRefForPictures
  ] = usePictureRetryHook();
  const imageDataContext = useContext(CurrentDefaultImageContext);
  const [positionedPictures, setPositionedPictures] = useState(getPictures({ pictures }));
  const [prevPictures] = usePreviousHook({ values: [pictures] });
  const [rearrengingPictures, setRearrengingPictures] = useState(false);
  const { t } = getI18N();

  useEffect(() => {
    updateTimeoutRefForPictures(pictures);
  }, [pictures, updateTimeoutRefForPictures]);

  useEffect(() => {
    const removeToggleRearrengingPicturesListener = eventbus.on(
      TOGGLE_REARRANGING_PICTURES.eventbusKey,
      value => {
        setRearrengingPictures(value);
      }
    );
    return () => removeToggleRearrengingPicturesListener();
  }, []);

  useEffect(() => {
    eventbus.publish('REARRANGING_PICTURES_IN_PROGRESS', rearrengingPictures);
  }, [rearrengingPictures]);

  useEffect(() => {
    const removeDefaultPictureIdListener = eventbus.on(
      UPDATE_PICTURES_POSITIONS.eventbusKey,
      data => {
        const { pictureIds, defaultPictureId } = data;

        if (!Array.isArray(pictureIds) || pictureIds.length === 0) {
          return;
        }

        const orderedPictures = positionedPictures.map(picture => {
          if (picture.position) {
            picture.position = pictureIds.indexOf(picture.id) + 1;
          }
          return picture;
        });
        setPositionedPictures(getPictures({ pictures: orderedPictures, defaultPictureId }));
      }
    );
    return () => removeDefaultPictureIdListener();
  }, [positionedPictures]);

  useEffect(() => {
    const removeDeletedPictureIdListener = eventbus.on(
      DELETED_PICTURE_ID.eventbusKey,
      (id, extraData) => {
        const showingPictureOfIndex = positionedPictures.findIndex(
          ({ id: prevPictureId }) => prevPictureId === id
        );
        if (showingPictureOfIndex !== 0) {
          extraData.callback(positionedPictures[showingPictureOfIndex - 1] || {});
        }
      }
    );
    return () => removeDeletedPictureIdListener();
  }, [positionedPictures]);

  useEffect(() => {
    if (pictures !== prevPictures) {
      setPositionedPictures(getPictures({ pictures }));
    }
  }, [pictures, prevPictures]);

  const imageValidator = useCallback(async url => {
    const img = new Image();
    img.src = url;
    try {
      new Promise((resolve, reject) => {
        img.onload = () => resolve();
        img.onerror = () => reject();
      });
      return true;
    } catch (error) {
      return false;
    }
  }, []);

  const loadThisImage = useCallback(
    pictureState => {
      //Picture to load is same as the current picture, ignore it
      if (pictureState.id === imageDataContext.currentImageMeta.currentImageData.id) {
        return;
      }

      scrollToTop();
      imageDataContext.setNewImageData({ type: UPDATE_IMAGE_DATA, newImageData: pictureState });
    },
    [imageDataContext, scrollToTop]
  );

  const disableRearrangeOfPictures = useCallback(() => {
    if (
      rearrengingPictures ||
      !Array.isArray(positionedPictures) ||
      positionedPictures.length === 1 ||
      (positionedPictures.length === 2 && positionedPictures[0].hasOwnProperty('videoUrl'))
    ) {
      return true;
    }
    return false;
  }, [positionedPictures, rearrengingPictures]);

  const checkIfSecondPictureStateIsLoading = (oldIndex, newIndex) => {
    const positionOfSecondImage = positionedPictures[0].hasOwnProperty('videoUrl') ? 2 : 1;
    if (newIndex !== positionOfSecondImage - 1 && oldIndex !== positionOfSecondImage - 1) {
      return false;
    }
    return (
      !positionedPictures[positionOfSecondImage].id ||
      !picturesLoadState[positionedPictures[positionOfSecondImage].id] ||
      !picturesLoadState[positionedPictures[positionOfSecondImage].id].state ||
      picturesLoadState[positionedPictures[positionOfSecondImage].id].state === 'LOADER'
    );
  };

  const onSortEnd = useCallback(
    async ({ oldIndex, newIndex }) => {
      loadThisImage(positionedPictures[oldIndex]);
      if (oldIndex === newIndex || checkIfSecondPictureStateIsLoading(oldIndex, newIndex)) {
        return;
      }
      setRearrengingPictures(true);
      const sortedPictures = arrayMove(positionedPictures, oldIndex, newIndex);
      const pictureIds = [];
      sortedPictures.forEach(picture => {
        if (picture.hasOwnProperty('videoUrl')) {
          return;
        }
        pictureIds.push(picture.id);
      });
      const updatedPictures = getBasicInfoFromCache({ productId: activeProductId }).pictures;
      pictureIds.forEach((pictureId, index) => {
        if (!updatedPictures[pictureId]) {
          updatedPictures[pictureId] = {};
        }
        updatedPictures[pictureId].position = index + 1;
      });
      const key = `reorderCatalogueLabels${Date.now()}`;
      setPositionedPictures(sortedPictures);
      toggleGlobalLoader(key, true);

      try {
        await sortPictures({
          productId: activeProductId,
          pictureIds
        });
        let indexOfDefaultPicture = 0;
        if (sortedPictures[0].hasOwnProperty('videoUrl')) {
          indexOfDefaultPicture = 1;
        }
        if (oldIndex === indexOfDefaultPicture || newIndex === indexOfDefaultPicture) {
          setPictureAsDefault(
            sortedPictures[indexOfDefaultPicture],
            sortedPictures[indexOfDefaultPicture].url
          );
        }
      } catch (error) {
        setPositionedPictures(positionedPictures);
        toastr.error(t('failed_to_update_position'));
      } finally {
        setRearrengingPictures(false);
        toggleGlobalLoader(key, false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [positionedPictures, activeProductId, loadThisImage, picturesLoadState]
  );

  const disableRearrangingForPicture = useCallback(
    pictureState => {
      if (!picturesLoadState || !picturesLoadState[pictureState.id]) {
        return true;
      }
      const { state } = picturesLoadState[pictureState.id];
      return state === 'LOADER';
    },
    [picturesLoadState]
  );

  const getImageUiBasedOnState = pictureState => {
    const selectedPictureClass =
      imageDataContext.currentImageMeta.currentImageData.id === pictureState.id
        ? 'selectedPicture'
        : '';
    const cursorClass =
      pictureState.hasOwnProperty('videoUrl') ||
      positionedPictures.length === 1 ||
      (positionedPictures[0].hasOwnProperty('videoUrl') && positionedPictures.length === 2)
        ? 'cursorClass'
        : '';

    const { state = IMAGE_LOADER, retryCount = 0 } = picturesLoadState[pictureState.id] || {};

    if (state === IMAGE_ERROR) {
      return (
        <div
          key={pictureState.id}
          className={`activeMetaTabPictureContainer activeMetaTabPictureError ${cursorClass} ${selectedPictureClass}`}
          onClick={() => loadThisImage(pictureState)}
        >
          <ErrorIcon className={'errorIcon'} />
        </div>
      );
    }

    const url = convertImageUrlSize({ size: '100', url: pictureState.url });
    let isImageUrlValid;
    (async () => {
      const isValid = await imageValidator(pictureState.url);
      isImageUrlValid = isValid;
    })();

    return (
      <Fragment key={pictureState.id}>
        <div
          className={`activeMetaTabPictureContainer ${cursorClass}`}
          onClick={() => loadThisImage(pictureState)}
        >
          <Fragment>
            {state === 'LOADER' && (
              <div className={`pictureLoader ${selectedPictureClass}`}>
                <Loader size={'small'} />
              </div>
            )}
            <Fragment>
              <div
                key={`${pictureState.id}-background-${retryCount}`}
                className={`picture picturesAspectRatio ${selectedPictureClass}`}
                style={
                  (picturesLoadState[pictureState.id] || {}).state === IMAGE_HIDE_LOADER
                    ? { backgroundImage: `url('${isImageUrlValid ? url : pictureState.url}')` }
                    : {}
                }
              />
              {pictureState.videoUrl && <span className="playIcon" />}
              <img
                key={`${pictureState.id}-${retryCount}`}
                onError={handleImageError(pictureState.id, IMAGE_ERROR)}
                onLoad={handleImageLoad(pictureState.id, IMAGE_HIDE_LOADER)}
                src={isImageUrlValid ? url : pictureState.url}
                className={'previewImage'}
                alt={''}
              />
            </Fragment>
          </Fragment>
        </div>
        {pictureState.hasOwnProperty('videoUrl') && <div className="picturesDivider" />}
      </Fragment>
    );
  };

  const renderProductPicturesList = () => {
    if (loading) {
      return (
        <div className={'picturesLoadingContainer'}>
          <Loader size={'small'} />
        </div>
      );
    }
    if (Array.isArray(positionedPictures) && positionedPictures.length !== 0) {
      return (
        <div className={'picturesContainer'}>
          <SortableList
            axis={'x'}
            lockAxis={'x'}
            items={positionedPictures}
            distance={1}
            onSortEnd={onSortEnd}
            scrollToTop={scrollToTop}
            getImageUiBasedOnState={getImageUiBasedOnState}
            disableRearrangingForPicture={disableRearrangingForPicture}
            shouldCancelStart={disableRearrangeOfPictures}
          />
        </div>
      );
    }
    return null;
  };

  return (
    <div id={'ProductPictures'}>
      <div className={'rowContainer'}>
        <div className={'picturesWrapper'}>
          {renderProductPicturesList()}
          <ImageUpload classes={'margin-left'} />
        </div>
      </div>
    </div>
  );
};
