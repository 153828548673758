import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import { ReactComponent as MenuIcon } from 'qs-assets/Media/menu-three-dots_icon.svg';
import { selectedOrders } from 'qs-data-manager/Selected';
import { ActionButtonIcon } from './Common/ActionButtons';
import DeleteOrderItems from './DeleteOrderItems';
import OrderActionsMenu from './OrderActionsMenu';
import { makeStyles } from '@material-ui/core';
import ContactBuyer from './ContactBuyer';
import SendOrder from './SendOrder';
import StockManagementView from './StockManagementView';
import {
  attachOrderMetaListener,
  getOrderMetaFromCache,
  removeOrderMetaListener
} from 'qs-data-manager/Orders/OrdersOperations';
import { ActiveOrderView } from '../context';
import { UPDATE_HEADER_VIEW } from '../reducer';
import {
  ITEM_DELETE_HEADER_VIEW,
  INVENTORY_HEADER_VIEW,
  ORDER_STATUS
} from 'qs-helpers/Orders/constants';
import { getOrderDisplayStatus } from 'qs-helpers/Orders/ResponseProcessor';
import './styles.scss';
import CacheListenerCallback from 'qs-helpers/CacheListenerCallback';
import ViewExternalOrder from './ViewExternalOrder';
import PlaceExternalOrder from './PlaceExternalOrder';
import { getI18N } from 'qs-services/i18N';

const useComponentStyles = makeStyles({
  buttonRoot: {
    marginLeft: '10px',
    marginBottom: '6px'
  },
  actionButtonRoot: {
    marginLeft: '10px',
    marginBottom: '6px'
  }
});

export default ({
  displayOrderId,
  paymentCaptured,
  paymentFailed,
  paymentPending,
  marginScrollOffset,
  selectedOrderId,
  shippingConfig,
  b2bInvoiceConfig
}) => {
  const [showActionsMenu, setShowActionsMenu] = useState(false);
  const [orderMeta, setOrderMeta] = useState();
  const deleteButtonRef = useRef();
  const componentStyles = useComponentStyles();

  const { t } = getI18N();

  const {
    activeOrderView: { headerView },
    setActiveOrderViewData
  } = useContext(ActiveOrderView);

  useEffect(() => {
    setOrderMeta(getOrderMetaFromCache({ orderId: selectedOrderId }) || {});
    const listener = (_, payload) => {
      const { data } = CacheListenerCallback(undefined, payload);
      if (!data) {
        return;
      }
      setOrderMeta(data);
    };

    attachOrderMetaListener({ listener, orderId: selectedOrderId });
    return () => removeOrderMetaListener({ listener, orderId: selectedOrderId });
  }, [selectedOrderId]);

  const resetState = useCallback(() => {
    setActiveOrderViewData({ type: UPDATE_HEADER_VIEW });
    setShowActionsMenu(false);
  }, [setActiveOrderViewData, setShowActionsMenu]);

  // New order selected, reset everything
  useEffect(() => {
    return () => {
      resetState();
      selectedOrders.deactivate();
    };
  }, [selectedOrderId, resetState]);

  const toggleActionsMenu = () => {
    setShowActionsMenu(prevVal => !prevVal);
  };

  const handleItemsHeaderBack = () => {
    resetState();
    selectedOrders.deactivate();
  };

  const getComponentForPaymentStatus = () => {
    if (paymentCaptured) {
      return <div className="paymentCaptured orderDataTagLabel">PAYMENT CAPTURED</div>;
    }
    if (paymentFailed) {
      return <div className="paymentFailed orderDataTagLabel">PAYMENT FAILED</div>;
    }
    if (paymentPending) {
      return <div className="paymentPending orderDataTagLabel">PAYMENT PENDING</div>;
    }
    return null;
  };

  const getOrderStatusStyles = ({ isFinalized, orderStatus }) => {
    if (!isFinalized) {
      return 'unconfirmedOrder';
    }
    if (orderStatus === ORDER_STATUS.REJECTED) {
      return 'rejectedOrder';
    }
    return 'confirmedOrder';
  };

  if (headerView === ITEM_DELETE_HEADER_VIEW) {
    return <DeleteOrderItems onBack={handleItemsHeaderBack} />;
  }

  if (headerView === INVENTORY_HEADER_VIEW) {
    return <StockManagementView onBack={resetState} />;
  }

  const { isFinalized, orderStatus } = orderMeta || {};

  return (
    <div className="orderHeaderContainer" style={{ marginRight: marginScrollOffset }}>
      <div className="actionHeaderContainer">
        <div className="leftTextContainer">
          <span className="displayOrderId">
            {t('order')} #{displayOrderId}
          </span>
          <div className="orderTagsContainer">
            <div
              className={`orderDataTagLabel ${getOrderStatusStyles({ isFinalized, orderStatus })}`}
            >
              {t(getOrderDisplayStatus({ isFinalized, orderStatus }).toLowerCase())}
            </div>
            {getComponentForPaymentStatus()}
          </div>
        </div>
        <div className="rightActionContainer">
          <ViewExternalOrder buttonClasses={componentStyles} shippingConfig={shippingConfig} />
          <PlaceExternalOrder
            buttonClasses={componentStyles}
            shippingConfig={shippingConfig}
            orderId={selectedOrderId}
          />
          <ContactBuyer buttonClasses={componentStyles} />
          <SendOrder buttonClasses={componentStyles} b2bInvoiceConfig={b2bInvoiceConfig} />
          <ActionButtonIcon
            startIcon={<MenuIcon className="orderHeaderButtonIcon" />}
            classes={{ root: componentStyles.actionButtonRoot }}
            color={'primary'}
            variant="contained"
            onClick={toggleActionsMenu}
            ref={deleteButtonRef}
          />
          {showActionsMenu && (
            <OrderActionsMenu
              deleteButtonRef={deleteButtonRef.current}
              onClose={toggleActionsMenu}
              onDeleteItemsClick={() =>
                setActiveOrderViewData({
                  type: UPDATE_HEADER_VIEW,
                  headerView: ITEM_DELETE_HEADER_VIEW
                })
              }
              onInventoryClick={() =>
                setActiveOrderViewData({
                  type: UPDATE_HEADER_VIEW,
                  headerView: INVENTORY_HEADER_VIEW
                })
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};
