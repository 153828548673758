import CacheRequest from './CacheRequest';

const attachCompanyDetailListener = (key, listener) => {
  CacheRequest.attachListener(key, listener);
};

const removeCompanyDetailListener = (key, listener) => {
  CacheRequest.removeListener(key, listener);
};

const getCompanyDetail = (key, apiName) => {
  return CacheRequest.makeRequest(key, apiName, {
    params: [],
    options: {
      shouldNotStoreInNative: true
    }
  });
};

const getCompanyDetailFromCache = key => {
  return CacheRequest.getCacheForKey(key);
};

const setCompanyDetailFromCache = (key, value) => {
  return CacheRequest.setCacheForKey(key, value);
};

export {
  attachCompanyDetailListener,
  removeCompanyDetailListener,
  getCompanyDetail,
  getCompanyDetailFromCache,
  setCompanyDetailFromCache
};
