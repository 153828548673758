import React, { useEffect } from 'react';

import CatalogueListHeader from './CatalogueListHeader';
import CatalogueList from './CatalogueList';
import MultiCatalogueShareFooter from './MultiCatalogueShareFooter';

import './styles.scss';
import Mixpanel from 'qs-data-manager/Mixpanel';

export default ({ changeFlexWidth, headerClass, showCatalogueList }) => {
  useEffect(() => {
    Mixpanel.sendEvent({ eventName: 'START - MainCataloguesActivity' });
  }, []);

  let flexWidthClass = '';
  if (changeFlexWidth) {
    flexWidthClass = 'reduceFlexWidth';
  }

  if (!showCatalogueList) {
    return null;
  }

  return (
    <>
      <div id={'CatalogueListScreen'} className={flexWidthClass}>
        <CatalogueListHeader headerClass={headerClass} />
        <CatalogueList />
        <MultiCatalogueShareFooter />
      </div>
    </>
  );
};
