import Api from '../../Api';
import CacheRequest from '../CacheRequest';

const CATALOGUE_PRODUCTS_SHOWCASE_LINK_DATA = 'CATALOGUE_PRODUCTS_SHOWCASE_LINK_DATA';

const getShowcaseLinkCacheKeyForCatalogueId = catalogueId =>
  CATALOGUE_PRODUCTS_SHOWCASE_LINK_DATA + catalogueId;

export const setShowcaseLinkCacheForCatalogueId = catalogueId => {
  const key = getShowcaseLinkCacheKeyForCatalogueId(catalogueId);
  CacheRequest.setCacheForKey(key, {});
};

export const setShowcaseLinkInCacheForProductId = ({ catalogueId, productId, link }) => {
  const key = getShowcaseLinkCacheKeyForCatalogueId(catalogueId);
  const cachedShowcaseLinkData = CacheRequest.getCacheForKey(key);
  cachedShowcaseLinkData[productId] = link;
  CacheRequest.setCacheForKey(key, cachedShowcaseLinkData);
};

export const clearCachedShowcaseLinkDataForCatalogueId = catalogueId => {
  const key = getShowcaseLinkCacheKeyForCatalogueId(catalogueId);
  CacheRequest.deleteCacheForKeys([key]);
};

export const getShowcaseLinkForProductId = ({ catalogueId, productId }) => {
  const key = getShowcaseLinkCacheKeyForCatalogueId(catalogueId);
  const cachedShowcaseLinkData = CacheRequest.getCacheForKey(key);

  if (cachedShowcaseLinkData && cachedShowcaseLinkData[productId]) {
    return new Promise(resolve =>
      resolve({ link: cachedShowcaseLinkData[productId], cachedLink: true })
    );
  }
  return Api.createShowCaseLink({
    catalogueIds: [catalogueId],
    productId
  });
};
