import React, { useState, useEffect, Fragment, useCallback, useMemo } from 'react';
import { Typography, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Loader from 'qs-components/Common/Loader';
import toastr from 'toastr';
import { ReactComponent as TaxSvg } from 'qs-assets/Media/tax.svg';

import TextInput from './TextInput';
import DialogBox from './DialogBox';
import TaxesList from './TaxesList';
import useStyles from './styles.js';
import {
  createNewTax,
  deleteTax,
  removeTaxDefault,
  setTaxDefault
} from 'qs-data-manager/Tax/TaxOperations';
import useMakeQuery from 'qs-hooks/useMakeQuery';
import {
  fetchCompanyTaxesHandler,
  getCachedCompanyTaxes,
  handleCompanyTaxesCleanup
} from 'qs-helpers/Tax/DataQueryHelper';
import { getI18N } from 'qs-services/i18N';

const style = {
  color: 'white',
  fontSize: 18,
  fontWeight: 600,
  marginBottom: '0.5rem'
};

export default function TaxSettings(props) {
  const classes = useStyles();
  const [show, setShow] = useState(false);
  const [dialogData, setDialogData] = useState({});
  const [fetchingData, setFetchingdata] = useState(false);
  let taxType = '';
  let taxPercent = 0;
  let percentageError = false;
  let typeError = false;
  const { t } = getI18N();

  const [{ loading, error, data: companyTaxes }] = useMakeQuery({
    changeDependancy: [],
    actionHandler: fetchCompanyTaxesHandler,
    cleanupHandler: handleCompanyTaxesCleanup,
    cachedDataHandler: getCachedCompanyTaxes,
    globalLoader: false
  });

  const taxes = useMemo(() => {
    if (!companyTaxes || !Array.isArray(companyTaxes.taxes)) {
      return [];
    }
    return companyTaxes.taxes;
  }, [companyTaxes]);

  const defaultTax = useMemo(() => {
    return taxes.find(({ isDefault }) => isDefault);
  }, [taxes]);

  useEffect(() => {
    if (!error) {
      return;
    }
    toastr.error(t('something_went_wrong_please_try_again'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const onTaxTypeChange = value => {
    taxType = value;
  };

  const onTaxPercentChange = value => {
    taxPercent = value;
  };

  const toggleDialog = useCallback(() => {
    setShow(prevState => !prevState);
  }, []);

  const onDeleteTax = useCallback(taxId => {
    const callApi = async () => {
      try {
        setFetchingdata(true);
        await deleteTax({ taxId });
      } catch (err) {
        toastr.error(t('something_went_wrong_please_try_again'));
      } finally {
        setFetchingdata(false);
      }
    };
    callApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSetDefaultTax = useCallback(taxId => {
    const callApi = async () => {
      try {
        setFetchingdata(true);
        await setTaxDefault({ taxId });
      } catch (err) {
        toastr.error(t('something_went_wrong_please_try_again'));
      } finally {
        setFetchingdata(false);
      }
    };
    callApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onRemoveTax = useCallback(() => {
    const callApi = async () => {
      try {
        toggleDialog();
        setFetchingdata(true);
        const { taxId } = defaultTax;
        await removeTaxDefault({ taxId });
      } catch (err) {
        toastr.error(t('something_went_wrong_please_try_again'));
      } finally {
        setFetchingdata(false);
      }
    };
    callApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleDialog, defaultTax]);

  const onAddNewTax = async () => {
    percentageError = taxPercent.length === 0;
    typeError = taxType.length === 0;
    if (!percentageError && !typeError) {
      try {
        toggleDialog();
        setFetchingdata(true);
        await createNewTax({ taxType, taxPercentage: Number(taxPercent) });
      } catch (err) {
        toastr.error(t('something_went_wrong_please_try_again'));
      } finally {
        setFetchingdata(false);
      }
    } else {
      onAddTax();
    }
  };

  const onRemoveDefaultTax = () => {
    const DangerLabel = withStyles({
      root: { ...style, color: '#DA5252', fontSize: 18 }
    })(Typography);
    const NormalLabel = withStyles({
      root: { ...style, fontSize: 18 }
    })(Typography);
    const content = (
      <Fragment>
        <NormalLabel>{t('are_you_sure_you_want_to_remove_default_tax')}?</NormalLabel>
        <DangerLabel>
          {t('you_will_have_to_add_tax_to_newly_created_products_manually')}
        </DangerLabel>
      </Fragment>
    );
    const data = {
      dark: true,
      primaryBtnText: t('remove_default_tax'),
      danger: true,
      title: t('remove_default_tax'),
      content,
      width: 482,
      height: 220,
      onClose: toggleDialog,
      onSuccess: onRemoveTax
    };
    setDialogData(data);
    setShow(true);
  };

  const onAddTax = () => {
    const CommonTypography = withStyles({
      root: { ...style, fontSize: 16 }
    })(Typography);
    const PercentText = withStyles({
      root: {
        ...style,
        color: '#565D70',
        display: 'flex',
        alignItems: 'center',
        height: 35
      }
    })(Typography);
    const content = (
      <Fragment>
        <CommonTypography>{t('tax_type')}</CommonTypography>
        <TextInput
          placeholder={t('gst_sales_etc')}
          name="taxType"
          fullWidth
          autoFocus
          onChange={onTaxTypeChange}
          className={classes.taxType}
          error={typeError}
          // pattern="^[a-zA-Z , -]{0,}$"
        />
        <CommonTypography>{t('tax_percent')} (%)</CommonTypography>
        <div className={classes.taxPercentDiv}>
          <TextInput
            placeholder="5, 10, 15.."
            name="taxPercent"
            onChange={onTaxPercentChange}
            className={classes.taxPercentage}
            pattern="^\d{0,2}(?:\.\d{0,2})?$"
            error={percentageError}
          />
          <PercentText>%</PercentText>
        </div>
      </Fragment>
    );
    const data = {
      dark: true,
      primaryBtnText: t('set_tax'),
      danger: false,
      title: t('add_tax'),
      content,
      width: 250,
      height: 307,
      onClose: toggleDialog,
      onSuccess: onAddNewTax
    };
    setDialogData(data);
    setShow(true);
  };

  if (loading || fetchingData) {
    return (
      <div className={classes.loaderContainer}>
        <Loader size="large" />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.titleContainer}>
        <Typography className={classes.title} color="primary" gutterBottom>
          {t('tax_settings')}
        </Typography>
        {taxes.length > 0 && (
          <Button
            variant="contained"
            onClick={onAddTax}
            className={classes.newTaxBtn}
            color="primary"
          >
            {t('new_tax')}
          </Button>
        )}
      </div>
      {taxes.length > 0 ? (
        <div className={classes.contentContainer}>
          {defaultTax && (
            <div className={classes.description}>
              <Typography className={classes.defaultTax}>{t('default_tax')}</Typography>
              <Typography className={classes.info}>
                {t('this_tax_amount_will_be_applied_by_default')}
              </Typography>
              <div className={classes.removeTaxContainer}>
                <Typography className={classes.taxText}>
                  {`${defaultTax.taxPercentage}% ${defaultTax.taxType}`}
                </Typography>
                <Button onClick={onRemoveDefaultTax} className={classes.removeDefault}>
                  {t('remove_default_tax')}
                </Button>
              </div>
            </div>
          )}
          <TaxesList setDefault={onSetDefaultTax} onDelete={onDeleteTax} taxes={taxes} />
        </div>
      ) : (
        <div className={classes.noTaxContainer}>
          <div className={classes.noTaxIcon}>
            <TaxSvg />
            <Typography className={classes.info} gutterBottom>
              {t('no_tax_profiles_added')}
            </Typography>
          </div>
          <Button
            variant="contained"
            onClick={onAddTax}
            className={classes.addTaxButton}
            color="primary"
          >
            {t('add_tax')}
          </Button>
        </div>
      )}
      <DialogBox show={show} {...dialogData} />
    </div>
  );
}
