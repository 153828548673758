import React from 'react';
import { ReactComponent as CustomFrontPageIcon } from 'qs-assets/Media/ic_custom_front_page.svg';
import './styles.scss';

export default ({ width, height, fill, classes = '' }) => {
  return (
    <CustomFrontPageIcon
      style={{ width, height, fill }}
      className={`settingsIconComponent ${classes}`}
    />
  );
};
