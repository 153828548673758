import { Dialog, DialogTitle, Button, withStyles } from '@material-ui/core';

export const DialogLightTheme = withStyles({
  paper: {
    backgroundColor: '#ffffff',
    color: '#000000'
  },
  root: {}
})(Dialog);

export const LightThemeTitle = withStyles({
  root: {
    fontSize: 26,
    fontWeight: 700
  }
})(DialogTitle);

export const ButtonDanger = withStyles({
  root: {
    fontSize: 14,
    color: '#f04b4b'
  }
})(Button);

export const PrimaryButton = withStyles({
  root: {
    fontSize: 14,
    color: '#00a16b',
    '&$disabled': {
      color: '#00a16b',
      opacity: 0.5
    }
  },
  disabled: {}
})(Button);

export const SecondaryButton = withStyles({
  root: {
    fontSize: 14,
    color: '#BDBDBD'
  }
})(Button);
