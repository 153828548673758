import React, { useState, useContext, useCallback } from 'react';
import { DialogActions, DialogTitle } from '@material-ui/core';
import { selectedOrders } from 'qs-data-manager/Selected';
import {
  DialogDarkTheme,
  ButtonDanger,
  SecondaryButton
} from 'qs-components/Common/DarkThemeDialog';
import { deleteOrderFromList } from 'qs-data-manager/Orders/OrdersOperations';
import { ActiveOrderMeta, OrderNoteContext } from '../../../context';
import { toggleGlobalLoader, getErrorMessage } from 'qs-helpers';
import { UPDATE_ORDER_META } from '../../../reducer';
import { useDropDownStyles } from '../Common/ActionButtons';
import { ActiveOrderView } from '../../context';
import { UPDATE_MANAGE_INVENTORY } from '../../reducer';
import ErrorPopup from 'qs-components/Common/ErrorPopup';
import { UNCONFIRMED_ORDERS } from 'qs-helpers/Orders/constants';
import toastr from 'toastr';
import MenuDropDown from 'qs-components/Common/MenuDropDown';
import InventoryUpdateConfirm from '../../OrderProductsView/InventoryUpdateConfirm';
import { getI18N } from 'qs-services/i18N';

export default ({ deleteButtonRef, onClose, onDeleteItemsClick, onInventoryClick }) => {
  const {
    activeOrderMeta: { selectedOrderId, currentSortKey, currentConfirmFilter },
    setActiveOrderMeta
  } = useContext(ActiveOrderMeta);
  const { setOpen } = useContext(OrderNoteContext);

  const { setActiveOrderViewData } = useContext(ActiveOrderView);
  const [errorData, setErrorData] = useState();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [updateInventory, setUpdateInventory] = useState(false);
  const componentStyles = useDropDownStyles();
  const { t } = getI18N();

  const toggleDeleteConfirmation = () => {
    setShowDeleteConfirmation(prevVal => !prevVal);

    // Delete confirmation is open, on cancel close this entire view
    if (showDeleteConfirmation) {
      onClose();
    }
  };

  const handleDeleteConfirmation = async () => {
    onClose();
    const loaderKey = `deleteOrder${Date.now()}`;
    toggleGlobalLoader(loaderKey, true);
    try {
      const { nextEligibleOrderId, inventoryUpdated } = await deleteOrderFromList({
        orderId: selectedOrderId,
        sortKey: currentSortKey,
        confirmed: currentConfirmFilter,
        updateInventory
      });

      if (inventoryUpdated) {
        toastr.success(t('inventory_updated'));
      }

      setActiveOrderMeta({ type: UPDATE_ORDER_META, orderId: nextEligibleOrderId });
    } catch (deleteError) {
      setErrorData({
        title: t('delete_order'),
        subject: t('failed_to_delete_order_id', { selectedOrderId }),
        body: getErrorMessage(deleteError),
        message: t('something_went_wrong_while_deleting_the_order')
      });
    } finally {
      toggleGlobalLoader(loaderKey, false);
    }
  };

  const handleDeleteItemsClick = () => {
    selectedOrders.activate();
    onDeleteItemsClick();
  };

  const handleManageInventoryClick = () => {
    setActiveOrderViewData({ type: UPDATE_MANAGE_INVENTORY, manageInventory: true });
    onInventoryClick();
  };

  const handleAddOrderNoteClick = useCallback(() => {
    setOpen(true);
    onClose();
  }, [onClose, setOpen]);

  const menuItemClasses = { root: componentStyles.menuItemRoot };
  const menuItemsConfig = [
    {
      classes: menuItemClasses,
      clickHandler: toggleDeleteConfirmation,
      label: t('delete_order')
    },
    {
      classes: menuItemClasses,
      clickHandler: handleDeleteItemsClick,
      label: t('delete_items')
    },

    {
      classes: menuItemClasses,
      clickHandler: handleAddOrderNoteClick,
      label: t('add_order_note')
    }
  ];

  if (currentConfirmFilter !== UNCONFIRMED_ORDERS) {
    menuItemsConfig.push({
      classes: menuItemClasses,
      clickHandler: handleManageInventoryClick,
      label: t('manage_inventory')
    });
  }

  return (
    <>
      {!showDeleteConfirmation && (
        <MenuDropDown
          buttonRef={deleteButtonRef}
          onClose={onClose}
          paperClasses={{ root: componentStyles.paperRoot }}
          menuItemsConfig={menuItemsConfig}
        />
      )}
      {showDeleteConfirmation && (
        <DialogDarkTheme open={true}>
          <DialogTitle>{t('delete_order')}</DialogTitle>
          <InventoryUpdateConfirm
            updateInventory={updateInventory}
            setUpdateInventory={setUpdateInventory}
            inventoryLabel={t('add_back_items_in_this_order')}
            label={t('are_you_sure_you_want_to_delete_this_order')}
          />
          <DialogActions>
            <SecondaryButton onClick={toggleDeleteConfirmation}>
              <span className="cancelButtonText">{t('cancel')}</span>
            </SecondaryButton>
            <ButtonDanger color={'primary'} onClick={handleDeleteConfirmation}>
              <span>{t('delete')}</span>
            </ButtonDanger>
          </DialogActions>
        </DialogDarkTheme>
      )}
      {errorData && (
        <ErrorPopup onClose={() => setErrorData()} mailProps={errorData} title={errorData.title}>
          {errorData.message}
        </ErrorPopup>
      )}
    </>
  );
};
