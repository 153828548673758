import React from 'react';
import { ReactComponent as MainLoginIllustration } from 'qs-assets/Media/mainLoginIllustration.svg';
import { ReactComponent as StartOnlineIcon } from 'qs-assets/Media/startOnline.svg';
import { ReactComponent as BulkUploadIcon } from 'qs-assets/Media/bulkUpload.svg';
import { ReactComponent as CreateCatalogueIcon } from 'qs-assets/Media/createCatalogue.svg';
import { ReactComponent as GrowBusinessIcon } from 'qs-assets/Media/growBusiness.svg';
import './styles.scss';

export default () => {
  return (
    <div className={'loginBannerContainer'}>
      <div className={'banner'}>
        <MainLoginIllustration className={'loginIllustration'} />
      </div>
      <div className={'loginPageInfo'}>
        <div className={'productInfo'}>
          <BulkUploadIcon />
          <p className="title">Bulk upload via Excel</p>
        </div>
        <div className={'productInfo'}>
          <CreateCatalogueIcon />
          <p className="title">Create your catalogue</p>
        </div>
        <div className={'productInfo'}>
          <StartOnlineIcon />
          <p className="title">Start your online business</p>
        </div>
        <div className={'productInfo'}>
          <GrowBusinessIcon />
          <p className="title">Grow your business</p>
        </div>
      </div>
    </div>
  );
};
