import React, { useState } from 'react';
import CatalogueLoader from './CatalogLoader';
import LinkSettings from './LinkSettings';
import SettingsOptions from './SettingsOptions';
import SettingsData from './helper';
import './styles.css';
import CataloguePrivacySettings from './CataloguePrivacySettings';

export default function MainSettings(props) {
  const {
    createLink,
    state,
    onSettingChanged,
    renderResetModal,
    resetToCompanyDefault,
    toggleCustomComponent,
    catalogueId,
    closeCataloguePrivacyDialog
  } = props;
  const [showCataloguePrivacySettings, setShowCataloguePrivacySettings] = useState(false);
  const { showResetModal, catalogueLink, generatingLink, fetchingLink } = state;
  const toggleCataloguePrivacySettings = () => {
    setShowCataloguePrivacySettings(!showCataloguePrivacySettings);
  };
  return (
    <div
      className="visibilityStyle"
      style={
        showCataloguePrivacySettings
          ? { minHeight: 'none', maxHeight: 'none', height: 'calc(100vh - 160px)' }
          : {}
      }
    >
      {!showCataloguePrivacySettings ? (
        <>
          <CatalogueLoader fetchingLink={fetchingLink} />
          <LinkSettings
            generatingLink={generatingLink}
            catalogueLink={catalogueLink}
            createLink={createLink}
          />
          <SettingsOptions
            Settings={SettingsData}
            state={state}
            onSettingChanged={onSettingChanged}
            showResetModal={showResetModal}
            renderResetModal={renderResetModal}
            resetToCompanyDefault={resetToCompanyDefault}
            toggleCustomComponent={toggleCustomComponent}
            toggleCataloguePrivacySettings={toggleCataloguePrivacySettings}
          />
        </>
      ) : (
        <CataloguePrivacySettings
          toggleCataloguePrivacySettings={toggleCataloguePrivacySettings}
          catalogueId={catalogueId}
          closeCataloguePrivacyDialog={closeCataloguePrivacyDialog}
        />
      )}
    </div>
  );
}
