import React, { useState, useEffect } from 'react';
import toastr from 'toastr';
import CreateNewLabelDialog from 'qs-components/Common/CreateNewLabelDialog';
import DeleteLabelDialog from './CatalogueLabel/DeleteLabelDialog';
import { selectedLabels, selectedLabelsToEdit } from 'qs-data-manager/Selected';
import useMakeQuery from 'qs-hooks/useMakeQuery';
import CatalogueLabel from './CatalogueLabel';
import CatalogueLabelHeader from './CatalogueLabelHeader';
import Loader from '../../Common/Loader';
import {
  getCachedCatalogueLabels,
  fetchCatalogueLabelsHandler,
  handleCatalogueLabelsCleanup
} from 'qs-helpers/Catalogues/DataQueryHelper';
import { getCataloguesAttachedToLabel } from 'qs-data-manager/Catalogues/CatalogueLabels';
import Mixpanel from 'qs-data-manager/Mixpanel';
import { toggleGlobalLoader } from 'qs-helpers';
import ErrorIcon from '../../Common/ErrorIcon';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

export default ({ headerClass, toggleShowCatalogueList }) => {
  const [openCreateNewLabelDialog, setOpenCreateNewLabelDialog] = useState(false);
  const [showCatalogueLabelScreen, setShowCatalogueLabelScreen] = useState(true);
  const [openDeleteLabelDialog, setOpenDeleteLabelDialog] = useState(false);
  const [noLabelSelected, setNoLabelSelected] = useState(true);
  const [editingLabel, setEditingLabel] = useState(false);

  const { t } = getI18N();

  const [{ loading, error, data }] = useMakeQuery({
    changeDependancy: [],
    cachedDataHandler: getCachedCatalogueLabels,
    actionHandler: fetchCatalogueLabelsHandler,
    cleanupHandler: handleCatalogueLabelsCleanup,
    globalLoader: false
  });

  useEffect(() => {
    selectedLabelsToEdit.removeAll();
    toggleShowCatalogueList(!editingLabel);
  }, [editingLabel, toggleShowCatalogueList]);

  useEffect(() => {
    if (data && data.labels.length !== 0 && selectedLabelsToEdit.getAllItemsCount() === 0) {
      const allLabelIds = data.labels.map(label => label.id);
      selectedLabelsToEdit.setAllItems(allLabelIds);
    }
  }, [data]);

  useEffect(() => {
    const onLabelSelection = () => {
      getCataLogueData();
      setNoLabelSelected(!selectedLabels.isAnySelected());
    };
    const showLabelsToggle = () => {
      setShowCatalogueLabelScreen(selectedLabels.isActive());
    };
    const onLabelsEditToggle = () => {
      setEditingLabel(selectedLabelsToEdit.isActive());
    };

    selectedLabels.addListener(onLabelSelection);
    selectedLabels.addActiveListener(showLabelsToggle);
    selectedLabelsToEdit.addActiveListener(onLabelsEditToggle);
    return () => {
      selectedLabels.removeListener(onLabelSelection);
      selectedLabels.removeActiveListener(showLabelsToggle);
      selectedLabelsToEdit.removeActiveListener(onLabelsEditToggle);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCataLogueData = async () => {
    const key = `getCatalogueDataForLabels${Date.now()}`;
    toggleGlobalLoader(key, true);
    try {
      const selectedLabelIds = selectedLabels.getAllSelectedItems();
      const labels = (getCachedCatalogueLabels() || {}).labels || [];
      await getCataloguesAttachedToLabel(selectedLabelIds);
      if (selectedLabelIds.length > 0) {
        Mixpanel.sendEvent({
          eventName: 'catalogue_label_filter',
          props: {
            label_name_list: selectedLabelIds
              .map(labelId => {
                const label = labels.find(label => label.id === labelId);
                return label ? label.name : undefined;
              })
              .filter(Boolean)
          }
        });
      }
    } catch (updateError) {
      toastr.error(t('failed_to_update_catalogue_data'));
    } finally {
      toggleGlobalLoader(key, false);
    }
  };

  if (!showCatalogueLabelScreen) {
    return null;
  }

  if (error || loading) {
    return (
      <div className={'catalogueLabelScreenContainer'}>
        <div className={'loadingOrErrorCatalogueLabels'}>
          {error && (
            <div>
              <ErrorIcon />
              <div>{t('something_went_wrong_while_fetching_catalogue_labels')}</div>
            </div>
          )}
          {loading && <Loader size={'small'} />}
        </div>
      </div>
    );
  }

  return (
    <div className={`catalogueLabelScreenContainer ${editingLabel && 'addWidthToLabelScreen'}`}>
      <CatalogueLabelHeader
        data={data}
        noLabelSelected={noLabelSelected}
        editingLabel={editingLabel}
        headerClass={headerClass}
        setOpenCreateNewLabelDialog={setOpenCreateNewLabelDialog}
        setOpenDeleteLabelDialog={setOpenDeleteLabelDialog}
      />
      <CatalogueLabel data={data} />
      <CreateNewLabelDialog open={openCreateNewLabelDialog} setOpen={setOpenCreateNewLabelDialog} />
      <DeleteLabelDialog
        labels={data && Array.isArray(data.labels) && data.labels.length !== 0 ? data.labels : []}
        openDeleteLabelDialog={openDeleteLabelDialog}
        setOpenDeleteLabelDialog={setOpenDeleteLabelDialog}
      />
    </div>
  );
};
