import { onImageUploadDone } from 'qs-helpers/ProcessUploadedImage';
import webWorkerConfig from 'qs-web-worker/config';
import { loadImageAndCheckOrientation, AUTO_ORIENTATION } from '../Helpers/ImageAutoOrientation.js';

export const imageUploadComplete = imageData => {
  onImageUploadDone(imageData);
};

let imageCompressorModule;

const getImageCompressor = async () => {
  if (imageCompressorModule) {
    return imageCompressorModule;
  }

  /*
    Dynamically import the main compressor to ensure that the bundle size for
    those browsers that support compression on worker are not impacted.
  */
  imageCompressorModule = await import('../ImageCompressors/MainThreadCompressor.js');
  return imageCompressorModule;
};

export const compressImageOnMainThread = async (imagesData, metaData) => {
  try {
    const { default: imageCompressor } = await getImageCompressor();
    const compressedImageData = await imageCompressor(imagesData, metaData.options);
    return {
      type: webWorkerConfig.COMPRESS_IMAGE_MAIN_COMPLETE.type,
      data: {
        payload: compressedImageData,
        metaData
      }
    };
  } catch (importError) {
    const compressFailedImages = imagesData.map(imageData => ({
      ...imageData,
      isPrepared: false,
      error: 'MODULE_LOAD_FAILED'
    }));

    return {
      type: webWorkerConfig.COMPRESS_IMAGE_MAIN_COMPLETE.type,
      data: {
        payload: { compressedImages: [], compressFailedImages },
        metaData
      }
    };
  }
};

/**
 * This method will be called to load an image. This will be executed only in the
 * main thread because the wroker thread, at the time of implementation, does not
 * have access to the image constructor. The load image helper will determine the
 * correct value and respond. For any other status apart from auto-orientation,
 * simply return false to indicaate falling back to the image's exif value
 * @param {string} imageDataUri the data uri for the image that must be loaded
 */
export const loadImageAndReturnExifOrientation = async imageDataUri => {
  const { status } = await loadImageAndCheckOrientation(imageDataUri);
  if (status === AUTO_ORIENTATION) {
    return {
      type: webWorkerConfig.BROWSER_EXIF_ORIENTATION_LOAD_IMAGE.type,
      data: true
    };
  }

  return {
    type: webWorkerConfig.BROWSER_EXIF_ORIENTATION_LOAD_IMAGE.type,
    data: false
  };
};

/**
 * This method will be used to determine whether the browser performs auto
 * exif orientations. This will be called when the browser does not have
 * offscreen canvas support. Hence the worker will send a message to the main
 * thread to determine the browser's auto-orientation
 */
export const checkBrowserExifOrientation = async () => {
  const { isAutoOrientationInBrowser } = await getImageCompressor();
  const { status } = await isAutoOrientationInBrowser();
  if (status === AUTO_ORIENTATION) {
    return {
      type: webWorkerConfig.BROWSER_EXIF_ORIENTATION.type,
      data: true
    };
  }

  return {
    type: webWorkerConfig.BROWSER_EXIF_ORIENTATION.type,
    data: false
  };
};
