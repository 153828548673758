import React, { useCallback, useEffect, useState } from 'react';
import toastr from 'toastr';
import { ReactComponent as EditIcon } from 'qs-assets/Media/pencil.svg';
import SettingsDataRow from '../../../Common/SettingsDataRow/SettingsDataRow';
import { getAllCompanyVariants } from 'qs-data-manager/Variants/AllVariants';
import B2BBulkOrderDialog from 'qs-components/Common/B2BBulkOrderDialog';
import { getI18N } from 'qs-services/i18N';

export default ({ selectedBulkOrderingViewSetting }) => {
  const [showB2BBulkOrderLoader, setShowB2BBulkOrderLoader] = useState(false);
  const [allVariants, setAllVariants] = useState([]);
  const [showB2BBulkOrderModal, setShowB2BBulkOrderModal] = useState(false);

  const { t } = getI18N();

  const fetchAllCompanyVariants = useCallback(async () => {
    try {
      setShowB2BBulkOrderLoader(true);
      const optionTypes = await getAllCompanyVariants();
      setAllVariants(optionTypes);
      setShowB2BBulkOrderLoader(false);
    } catch (error) {
      setShowB2BBulkOrderLoader(false);
      toastr.error(t('something_went_wrong_while_fetching_the_b2b_bulk_order_setting'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchAllCompanyVariants();
  }, [fetchAllCompanyVariants]);

  const onEditClick = () => {
    setShowB2BBulkOrderModal(true);
  };

  const onCloseEdit = () => {
    setShowB2BBulkOrderModal(false);
  };

  const title = t('b2b_bulk_ordering');

  return (
    <>
      <SettingsDataRow
        icon={<EditIcon />}
        loader={showB2BBulkOrderLoader}
        title={title}
        onSectionClick={onEditClick}
      >
        <span>{t('allow_your_b2b_customers_to_select')}</span>
      </SettingsDataRow>
      <B2BBulkOrderDialog
        show={showB2BBulkOrderModal}
        title={title}
        allVariants={allVariants}
        selectedBulkOrderingViewSetting={selectedBulkOrderingViewSetting}
        onClose={onCloseEdit}
      />
    </>
  );
};
