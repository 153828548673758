import React, { useContext } from 'react';
import CheckBox from 'qs-components/Common/CheckBox';
import { ORDER_STATUS } from 'qs-helpers/Orders/constants';
import { ActiveOrderMeta } from '../../../context';
import { getOrderMetaFromCache } from 'qs-data-manager/Orders/OrdersOperations';
import { DialogContent, withStyles } from '@material-ui/core';

const FlexDialogContent = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch'
  }
})(DialogContent);

export default ({ updateInventory, setUpdateInventory, inventoryLabel, label }) => {
  const {
    activeOrderMeta: { selectedOrderId }
  } = useContext(ActiveOrderMeta);

  const handleCheckboxChange = () => {
    setUpdateInventory(prevVal => !prevVal);
  };

  const renderCheckbox = () => {
    const { orderStatus, isFinalized } = getOrderMetaFromCache({ orderId: selectedOrderId }) || {};
    if (ORDER_STATUS.REJECTED === orderStatus || !isFinalized) {
      return null;
    }

    return (
      <CheckBox
        checked={updateInventory}
        onCheckChanged={handleCheckboxChange}
        label={inventoryLabel}
        styleProps={{
          margin: '0px 10px 0px 0px',
          controlMarginTop: 10
        }}
      />
    );
  };

  return (
    <FlexDialogContent>
      <span>{label}</span>
      {renderCheckbox()}
    </FlexDialogContent>
  );
};
