import React from 'react';
import ClickHandlerWithLoaderContainer from '../../../../Common/ClickHandlerWithLoader/ClickHandlerWithLoaderContainer';
import SettingsRow from '../../../Common/SettingsRow/SettingsRow';
import CustomCatalogueGridTileIcon from '../../../../Common/CustomCatalogueGridTileIcon';
import { getI18N } from 'qs-services/i18N';

export default ({ onSelected, settingComponentId, classForContainer }) => {
  const { t } = getI18N();

  return (
    <ClickHandlerWithLoaderContainer
      clickProps={{
        clickHandler: onSelected,
        clickParams: settingComponentId
      }}
      type="button"
      classForContainer={classForContainer}
    >
      <SettingsRow Icon={CustomCatalogueGridTileIcon} title={t('catalogue_grid_tile')}>
        <span>{t('create_custom_catalogue_grid_tile_for_your_website')}</span>
      </SettingsRow>
    </ClickHandlerWithLoaderContainer>
  );
};
