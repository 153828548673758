import React from 'react';
import { capitalize } from '@material-ui/core';
import SelectInput from '../../../../../../Common/SelectInput';
import { METAL_TYPES, METAL_UNITS, SELECT_VALUE } from '../ProductTypeDetails';
import './styles.scss';
import { getI18N } from 'qs-services/i18N';

const materialThemeStyles = {
  formSelectionBorder: '1px solid #ffffff',
  selectTextColor: '#ffffff',
  selectedItemBackgroundColor: '#ffffff',
  selectedItemColor: '#232b36'
};

export default ({
  onSelectChangeMetalType,
  onSelectChangeMetalKaratQuality,
  metalKaratQuality,
  entityRates = {}
}) => {
  const { t } = getI18N();
  const { entityLabel = '', entityType = '', rates = [], entityQualityUnit } = entityRates;

  const metalTypeOptions = [
    { displayValue: t(METAL_TYPES.GOLD.toLowerCase()), value: METAL_TYPES.GOLD },
    { displayValue: t(METAL_TYPES.SILVER.toLowerCase()), value: METAL_TYPES.SILVER }
  ];

  const metalQualityOptions = [
    {
      value: SELECT_VALUE.id,
      displayValue: t(SELECT_VALUE.displayValue)
    }
  ];

  const copiedRates = [...rates] || [];
  copiedRates.forEach(singleRate => {
    const { quality, label } = singleRate;
    const updatedRate = {
      value: quality,
      displayValue: `${singleRate.quality}${label || METAL_UNITS[entityQualityUnit] || ''}`
    };
    metalQualityOptions.push(updatedRate);
  });

  return (
    <div className="metalAndKaratContainer">
      <div className="metalContainer">
        <div className="title">
          {capitalize(entityLabel ? t(entityLabel.toLowerCase()) : t('metal'))}
        </div>
        <div className="setDropDownWrapper">
          <SelectInput
            value={entityType}
            onChange={onSelectChangeMetalType}
            options={metalTypeOptions}
            materialStyles={materialThemeStyles}
          />
        </div>
      </div>
      <div className="karat">
        <div className="title">
          {capitalize(entityQualityUnit ? t(entityQualityUnit.toLowerCase()) : t('purity'))}
        </div>
        <div className="setDropDownWrapper">
          <SelectInput
            value={metalKaratQuality}
            onChange={onSelectChangeMetalKaratQuality}
            options={metalQualityOptions}
            materialStyles={materialThemeStyles}
          />
        </div>
      </div>
    </div>
  );
};
