export const ACCESS_LEVEL_META = {
  DEFAULT: {
    displayName: 'public',
    description: 'anyone_with_a_link_to_view_your_catalogue'
  },
  SEMI_PRIVATE: {
    displayName: 'semi_private',
    description: 'block_certain_people_or_groups_of_people_from_viewing'
  },
  PRIVATE: {
    displayName: 'private',
    description: 'allow_only_certain_people_or_group_of_people_to_view_your_catalogue'
  }
};

export const ENTITY_TYPE = {
  CATALOGUE: 'CATALOGUE',
  COMPANY: 'COMPANY'
};

export const MANAGE_PRIVACY = {
  SEMI_PRIVATE: {
    title: 'blocked_contacts',
    description: 'contacts_are_blocked',
    noContactDescription: 'no_contacts_are_blocked',
    color: '#DD5454',
    noContactColor: '#2826E7'
  },
  PRIVATE: {
    title: 'allowed_contacts',
    description: 'contacts_are_allowed',
    noContactDescription: 'no_one_is_allowed_to_access_the_catalogue',
    color: '#2826E7',
    noContactColor: '#DD5454'
  }
};
export const ACCESS_TYPE = {
  SEMI_PRIVATE: 'BLOCKED',
  PRIVATE: 'ALLOWED'
};

export const getCataloguePrivacySettingsCacheKey = catalogueId =>
  `${ENTITY_TYPE.CATALOGUE}_${catalogueId}`;
