import React, { useCallback, useState, useEffect } from 'react';

import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import { VariableSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import arrayMove from 'array-move';
import Label from './Label/index';
import toastr from 'toastr';
import { reorderCatalougeLabel } from 'qs-data-manager/Catalogues/CatalogueLabels';
import {
  CATALOGUE_LABEL_ROW_HEIGHT,
  CATALOGUE_LABEL_OVERSACN_COUNT
} from 'qs-helpers/CatalogueLabels/constants';
import { toggleGlobalLoader } from 'qs-helpers';
import Mixpanel from 'qs-data-manager/Mixpanel';
import { selectedLabelsToEdit } from 'qs-data-manager/Selected';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

const SortableItem = sortableElement(({ rowData, key, style }) => (
  <div key={key} style={style}>
    <Label key={key} data={rowData} />
  </div>
));

const VirtualList = ({ labelsData, height, width, editingLabel }) => {
  const renderRow = useCallback(
    ({ data, index, style }) => {
      return (
        <SortableItem
          key={data[index].id}
          index={index}
          productIndex={index}
          style={style}
          rowData={data[index]}
          editingLabel={editingLabel}
        />
      );
    },
    [editingLabel]
  );

  const getItemHeight = useCallback(() => {
    return CATALOGUE_LABEL_ROW_HEIGHT;
  }, []);

  const itemKey = (index, data) => data[index].id;

  return (
    <List
      height={height}
      width={width}
      itemData={labelsData.labels}
      itemCount={labelsData.labels.length}
      itemSize={getItemHeight}
      itemKey={itemKey}
      overscanCount={CATALOGUE_LABEL_OVERSACN_COUNT}
    >
      {renderRow}
    </List>
  );
};

const SortableVirtualList = sortableContainer(VirtualList);

export default ({ data }) => {
  const { t } = getI18N();
  const [editingLabel, setEditingLabel] = useState(false);

  useEffect(() => {
    const toggleEditingLabel = () => {
      setEditingLabel(selectedLabelsToEdit.isActive());
    };
    selectedLabelsToEdit.addActiveListener(toggleEditingLabel);
    return () => {
      selectedLabelsToEdit.removeActiveListener(toggleEditingLabel);
    };
  }, []);

  const onSortEnd = useCallback(
    async ({ oldIndex, newIndex }) => {
      if (oldIndex === newIndex) {
        return;
      }
      const newList = arrayMove(data.labels, oldIndex, newIndex).map(row => {
        return row;
      });
      const key = `reorderCatalogueLabels${Date.now()}`;
      toggleGlobalLoader(key, true);
      try {
        await reorderCatalougeLabel({ newList });
        Mixpanel.sendEvent({
          eventName: 'catalogue_label_reorder',
          props: {
            label_name: newList[newIndex].name,
            from_position: oldIndex,
            to_position: newIndex
          }
        });
      } catch (updateError) {
        toastr.error(t('failed_to_reorder_labels'));
      } finally {
        toggleGlobalLoader(key, false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  return (
    <div className={'labelScrollContainer'}>
      <AutoSizer>
        {({ width, height }) => (
          <SortableVirtualList
            items={data.labels || []}
            onSortEnd={onSortEnd}
            width={width}
            height={height}
            pressDelay={150}
            lockAxis={'y'}
            labelsData={data}
            editingLabel={editingLabel}
            shouldCancelStart={() => !editingLabel}
          />
        )}
      </AutoSizer>
    </div>
  );
};
