import React from 'react';
import {
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  ListItemIcon
} from '@material-ui/core';

export default ({ buttonRef, paperClasses, onClose, menuItemsConfig }) => {
  return (
    <Popper
      open={true}
      anchorEl={buttonRef}
      role={undefined}
      transition
      disablePortal
      placement={'bottom-end'}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <Paper classes={paperClasses} elevation={8}>
            <ClickAwayListener onClickAway={onClose}>
              <MenuList disablePadding autoFocusItem={true} id="menu-list-grow">
                {menuItemsConfig.map(
                  ({ classes, clickHandler, iconClass, icon, label, disabled } = {}) => (
                    <MenuItem
                      key={label}
                      classes={classes}
                      onClick={clickHandler}
                      disabled={disabled}
                    >
                      {icon && <ListItemIcon classes={iconClass}>{icon}</ListItemIcon>}
                      <span>{label}</span>
                    </MenuItem>
                  )
                )}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};
