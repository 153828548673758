import React, { useEffect, useState } from 'react';
import { getCatalogueCDNAssetPath } from '../../../../Services/Helpers';
import {
  getCountryFromCountryCode,
  getCountries
} from '../../../../Services/Helpers/CallingCodeMapper';
import { currencies, getSortedCurrencies } from '../../../../Services/Helpers/CurrencyMapper';
import CommonPicker from '../../../Common/CommonPicker';
import { Checkbox } from '@material-ui/core';
import './styles.scss';
import { saveDataAndRedirectToCatalogue } from '../../../../Services/DataManager/Authentication';
import Api from '../../../../Services/Api';
import Loader from '../../../Common/Loader';
import { ReactComponent as DropdownArrow } from 'qs-assets/Media/down_arrow_icon.svg';
import { getE16Number } from 'qs-helpers/Login';
import Mixpanel from 'qs-data-manager/Mixpanel';

export default ({ user, company, onSetupLogoutClick }) => {
  const [brandName, setBrandName] = useState('');
  const [showCurrencies, setShowCurrencies] = useState(false);
  const [showCoutryCallingCodes, setShowCoutryCallingCodes] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState(currencies.INR);
  const [selectedCountry, setSelectedCountry] = useState(getCountryFromCountryCode('IN'));
  const [nationalNumber, setNationalNumber] = useState('');
  const [subscribeToWhatsApp, setSubscribeToWhatsApp] = useState(true);
  const [invalidBrandName, setInvalidBrandName] = useState(false);
  const [invalidNumber, setInvalidNumber] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (company && company.name) {
      setBrandName(company.name);
    }

    (async () => {
      Api.getCountryCode().then(countryCodeData => {
        const { countryCode = 'IN', currencyCode = 'INR' } = countryCodeData;
        const countryFromCountryCode = getCountryFromCountryCode(countryCode);
        setSelectedCurrency(currencies[currencyCode] ? currencies[currencyCode] : currencies.INR);
        setSelectedCountry(
          countryFromCountryCode ? countryFromCountryCode : getCountryFromCountryCode('IN')
        );
      });
    })();
  }, [company]);

  const createAccount = async event => {
    event.preventDefault();
    if (loading) {
      return;
    }
    Mixpanel.sendEvent({ eventName: 'save_company_details' });
    const nationalNumberRaw = nationalNumber;
    if (!nationalNumberRaw.trim() || !brandName.trim()) {
      setInvalidNumber(!nationalNumberRaw.trim());
      setInvalidBrandName(!brandName.trim());
      return;
    }

    setLoading(true);

    const callingCode = selectedCountry.symbol.split(' ').join('');
    const phone = getE16Number({ callingCode, nationalNumberRaw });
    try {
      await Api.saveCompanyDetails({
        companyName: brandName,
        currencyCode: selectedCurrency.currencyCode,
        phone,
        subscribeToWhatsApp,
        referralCode: ''
      });
      const result = await Api.getPlanFromRemote();
      const { plans = [], eligibleForTrial = false } = result;
      const products = (plans.length !== 0 && plans[0].products && plans[0].products) || [];
      const planId = products.length !== 0 && products[0].id;
      if (planId && eligibleForTrial) {
        await Api.startFreeTrial({
          planId
        });
      }
      saveDataAndRedirectToCatalogue({ user, company });
    } catch (error) {
      if (error.reason === 'INVALID_PHONE') {
        setInvalidNumber(true);
      }
    } finally {
      setLoading(false);
    }
  };

  const onBrandNameChange = event => {
    event.preventDefault();
    setBrandName(event.target.value);
    setInvalidBrandName(false);
  };

  const onNumberChange = event => {
    event.preventDefault();
    setNationalNumber(event.target.value);
    setInvalidNumber(false);
  };

  const toggleSubscribeToWhatsApp = event => {
    event.preventDefault();
    setSubscribeToWhatsApp(!subscribeToWhatsApp);
  };

  return (
    <div className="setupCompanyContainer">
      <h2>Create a new account</h2>
      <h3>Email</h3>
      <div className="userDetails">
        <p className="userEmail">{(user && user.email) || ''}</p>
        <p className="logout" onClick={() => onSetupLogoutClick()}>
          Logout
        </p>
      </div>
      <div className="inputContainer">
        <p className="inputLabel">Brand name</p>
        <div className={`setupInput ${invalidBrandName && 'invalid'}`}>
          <input
            autoFocus
            className="input"
            type="text"
            value={brandName}
            onChange={onBrandNameChange}
            placeholder="Enter your brand name"
          />
        </div>
      </div>
      <div className="inputContainer">
        <p className="inputLabel">Currency</p>
        <div className="setupInput" onClick={() => setShowCurrencies(true)}>
          <input
            className="input"
            value={
              selectedCurrency
                ? selectedCurrency.label +
                  '  ' +
                  selectedCurrency.symbol +
                  '  ' +
                  selectedCurrency.currencyCode
                : ''
            }
            type="text"
            readOnly
          />
          <DropdownArrow className="dropdownArrowInput" />
        </div>
      </div>
      <div className="inputContainer">
        <p className="inputLabel">WhatsApp number</p>
        <div className={`setupInput ${invalidNumber && 'invalid'}`}>
          <div className="countryCode" onClick={() => setShowCoutryCallingCodes(true)}>
            <div>
              <img
                className="countryFlag"
                alt=""
                src={getCatalogueCDNAssetPath(
                  `assets/flags/${selectedCountry.countryCode &&
                    selectedCountry.countryCode.toLowerCase()}.png`
                )}
              />
              <span>{selectedCountry ? selectedCountry.symbol : ''} </span>
              <DropdownArrow className="dropdownArrow" />
            </div>
          </div>
          <input
            className="input"
            type="text"
            value={nationalNumber}
            onChange={onNumberChange}
            placeholder="Enter number"
          />
        </div>
      </div>
      <div className="checkboxInput" onClick={toggleSubscribeToWhatsApp}>
        <Checkbox
          checked={subscribeToWhatsApp}
          onChange={() => {}}
          className={'subscribeToWhatsApp'}
        />
        <div className="subscribeToWhatsAppText">
          Subscribe to receive our WhatsApp notifications
        </div>
      </div>
      {loading ? (
        <div className="setupLoader">
          <Loader size="small" />
        </div>
      ) : (
        <button onClick={createAccount} className="createAccount">
          <span>CREATE ACCOUNT</span>
        </button>
      )}
      <CommonPicker
        pickerList={getSortedCurrencies()}
        selectedPickerValue={selectedCurrency}
        onSelect={data => setSelectedCurrency(data)}
        type={'CURRENCY'}
        placeHolderText={'SEARCH COUNTRY'}
        isVisible={showCurrencies}
        onClose={() => setShowCurrencies(false)}
      />
      <CommonPicker
        pickerList={getCountries()}
        selectedPickerValue={selectedCountry}
        showIcon={true}
        type={'CALLING_CODE'}
        onSelect={data => setSelectedCountry(data)}
        placeHolderText={'SEARCH COUNTRY'}
        isVisible={showCoutryCallingCodes}
        onClose={() => setShowCoutryCallingCodes(false)}
      />
    </div>
  );
};
