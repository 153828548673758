import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-responsive-modal';
import CatalogueLib, { getActiveCatalogueId } from 'qs-data-manager/Catalogues';
import CacheCallback from 'qs-helpers/CacheListenerCallback';
import { getElementParents, toggleGlobalLoader } from 'qs-helpers/index';
import {
  attachCompanySettingsListener,
  removeCompanySettingsListener
} from 'qs-data-manager/Company';
import Api from 'qs-services/Api';
import Settings from './Settings';
import { MIN_ORDER_QTY } from './helper';
import toastr from 'toastr';
import './styles.css';
import { getI18N } from 'qs-services/i18N';

class CatalogueSettings extends Component {
  static propTypes = {
    closeCatalogueSettings: PropTypes.func
  };

  static defaultProps = {
    closeCatalogueSettings: () => {}
  };

  constructor() {
    super();
    this.catalogueId = getActiveCatalogueId() || null;
    const link = CatalogueLib.getCatalogueLinkFromCache(this.catalogueId);
    let catalogueLink = '';
    const fetchingLink = typeof link !== 'string';
    if (typeof link !== 'string') {
      link.then(resp => {
        const { link } = resp;
        this.setState({
          catalogueLink: link,
          fetchingLink: false
        });
      });
    } else {
      catalogueLink = link;
    }
    const { updates, settingsCache } = this.getUpdates();
    this.state = {
      fetchingLink,
      catalogueLink,
      generatingLink: false,
      showResetModal: false,
      showCustomOptionModal: false,
      modalToggledFrom: '',
      ...updates,
      companySettings: settingsCache.companySettings
    };
  }

  componentDidMount() {
    const { catalogueLink, generatingLink, companySettings } = this.state;
    CatalogueLib.attachCatalogueSettingsListener({
      catalogueId: this.catalogueId,
      listener: this.catalogueSettingsListener
    });
    attachCompanySettingsListener(this.companySettingsListener);
    document.querySelector('body').addEventListener('click', this.handleBodyClick);

    if (catalogueLink && !generatingLink) {
      CatalogueLib.getCatalogueSettings({ catalogueId: this.catalogueId });
    }

    if (catalogueLink && !generatingLink && !Object.keys(companySettings || {}).length) {
      CatalogueLib.getCompanySettings().then(resp => {
        this.setState({
          companySettings: resp.settings ? resp.settings : {}
        });
      });
    }
  }

  componentWillUnmount() {
    CatalogueLib.removeCatalogueSettingsListener({
      catalogueId: this.catalogueId,
      listener: this.catalogueSettingsListener
    });
    removeCompanySettingsListener(this.companySettingsListener);
    document.querySelector('body').removeEventListener('click', this.handleBodyClick);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { catalogueLink, generatingLink, visitorsEnabled, companySettings } = this.state;

    if (catalogueLink && !generatingLink && typeof visitorsEnabled !== 'boolean') {
      CatalogueLib.getCatalogueSettings({ catalogueId: this.catalogueId });
    }

    if (catalogueLink && !generatingLink && !Object.keys(companySettings || {}).length) {
      CatalogueLib.getCompanySettings().then(resp => {
        this.setState({
          companySettings: resp.settings ? resp.settings : {}
        });
      });
    }
  }

  getCatalogueSettings = data => {
    if (!data || !data.experiments) {
      return {};
    }
    return { ...data.experiments };
  };

  catalogueSettingsListener = (error, payload) => {
    const { err, loading, data } = CacheCallback(error, payload);
    if (err) {
      return;
    }

    if (loading) {
      return;
    }
    const { updates } = this.getUpdates();

    this.setState(prevState => {
      return {
        ...prevState,
        ...updates,
        ...data.experiments
      };
    });
  };

  companySettingsListener = (error, payload) => {
    const { err, loading, data } = CacheCallback(error, payload);
    if (err) {
      return;
    }

    if (loading) {
      return;
    }
    const { updates } = this.getUpdates();
    this.setState(prevState => {
      return {
        ...prevState,
        ...updates,
        ...data.settings
      };
    });
  };

  getUpdates = () => {
    const settingsCache = CatalogueLib.getCatalogueSettingsFromCache(this.catalogueId);
    const updates = this.getCatalogueSettings(settingsCache);
    if (
      updates.stockManagedFrom === 'company' &&
      Object.keys(settingsCache.companySettings || {}).length > 0
    ) {
      updates.stockVisibility = settingsCache.companySettings.showOutOfStockProduct || false;
    }

    if (
      updates.orderOnOOS === 'company' &&
      Object.keys(settingsCache.companySettings || {}).length > 0
    ) {
      updates.orderOnOutOfStock = settingsCache.companySettings.allowOrdersOnOutOfStock || false;
    }
    return { updates, settingsCache };
  };

  onSettingChanged = async (status, field) => {
    const loaderKey = Date.now();
    this.setState({ [field]: status });
    let shouldMakeReq = true;
    toggleGlobalLoader(loaderKey, true);
    const changes = {};

    switch (field) {
      case 'stockVisibility': {
        shouldMakeReq = false;
        await CatalogueLib.changeOutOfStockVisibility({
          catalogueId: this.catalogueId,
          outOfStockVisibility: status
        });
        break;
      }
      case 'orderOnOutOfStock': {
        shouldMakeReq = false;
        await CatalogueLib.changeOrderOnOutOfStock({
          catalogueId: this.catalogueId,
          allowOrderOnOutOfStock: status
        });
        break;
      }
      case 'firstProductAsCover':
        shouldMakeReq = false;
        await CatalogueLib.changeCatalogueProductCover({
          catalogueId: this.catalogueId,
          enable: status
        });
        break;
      case MIN_ORDER_QTY:
        shouldMakeReq = false;
        await CatalogueLib.changeMinimumOrderQuantity({
          catalogueId: this.catalogueId,
          minOrderQuantity: status ? Number(status) : null
        });
        break;
      // case 'pdfDownload':
      // case 'productDownload':
      // case 'shippingAddress':
      // case 'orderConfirmationMessage':
      default:
        changes[field] = status;
        break;
    }
    if (shouldMakeReq) {
      await CatalogueLib.changeCatalogueSettings({ catalogueId: this.catalogueId, changes });
    }
    toggleGlobalLoader(loaderKey, false);
  };

  handleBodyClick = event => {
    const { showResetModal, showCustomOptionModal } = this.state;
    if (showResetModal || showCustomOptionModal) {
      return;
    }
    event.preventDefault();

    const parents = getElementParents(event.target);
    const parentsClass = parents.map(parent => parent.className);

    if (
      !parentsClass.includes('CatalogueSettingsContainer') &&
      !parentsClass.includes('iconContainer settingsIconContainer') &&
      !parentsClass.includes('productListHeader')
    ) {
      this.props.closeCatalogueSettings(false);
    }
  };

  toggleResetToCompanyDefault = ({ toggleValue, id, resetMessage }) => {
    this.setState({ showResetModal: toggleValue, modalToggledFrom: id, resetMessage });
  };

  toggleCustomComponent = () => {
    this.setState({ showCustomOptionModal: !this.state.showCustomOptionModal });
  };

  resetToCompanySettings = async () => {
    const { modalToggledFrom } = this.state;
    const loaderKey = Date.now();
    toggleGlobalLoader(loaderKey, true);
    try {
      this.toggleResetToCompanyDefault({ toggleValue: false, modalToggledFrom: '' });
      if (modalToggledFrom === 'stockVisibility') {
        await CatalogueLib.changeOutOfStockVisibility({
          catalogueId: this.catalogueId,
          outOfStockVisibility: null
        });

        const { settings } = await Api.getCompanySettings();
        const value =
          settings && typeof settings.showOutOfStockProduct === 'boolean'
            ? settings.showOutOfStockProduct
            : false;
        this.setState({
          stockVisibility: value,
          stockManagedFrom: 'company'
        });
      } else if (modalToggledFrom === 'orderOnOutOfStock') {
        await CatalogueLib.changeOrderOnOutOfStock({
          catalogueId: this.catalogueId,
          allowOrderOnOutOfStock: null
        });

        const { settings } = await Api.getCompanySettings();

        const value =
          settings && typeof settings.allowOrdersOnOutOfStock === 'boolean'
            ? settings.allowOrdersOnOutOfStock
            : false;

        this.setState({
          ...this.state,
          orderOnOutOfStock: value,
          stockManagedFrom: 'company'
        });
      } else if (modalToggledFrom === MIN_ORDER_QTY) {
        //Don't send min order quantity, that will reset the value
        await CatalogueLib.changeMinimumOrderQuantity({
          catalogueId: this.catalogueId
        });
      } else {
        const changes = {};
        changes[modalToggledFrom] = null;
        this.toggleResetToCompanyDefault({ toggleValue: false, modalToggledFrom: '' });
        await CatalogueLib.changeCatalogueSettings({ catalogueId: this.catalogueId, changes });
        CatalogueLib.getCatalogueSettings({ catalogueId: this.catalogueId });
      }
    } catch (resetError) {
      const { t } = getI18N();
      toastr.error(t('something_went_wrong_please_try_again'));
    } finally {
      toggleGlobalLoader(loaderKey, false);
    }
  };

  createLink = async () => {
    this.setState({
      generatingLink: true
    });

    const resp = await CatalogueLib.createCataloguesLink([this.catalogueId]);
    const { link } = resp;
    if (link) {
      CatalogueLib.getCatalogueSettings({ catalogueId: this.catalogueId });

      CatalogueLib.getCompanySettings().then(resp => {
        this.setState({
          companySettings: resp.settings ? resp.settings : {}
        });
      });

      this.setState({
        generatingLink: false,
        catalogueLink: link
      });
    } else {
      this.setState({
        generatingLink: false
      });
    }
  };

  renderResetModal = () => (
    <Modal
      open={this.state.showResetModal}
      onClose={() => {
        this.toggleResetToCompanyDefault(false);
      }}
      center
      styles={{ modal: { backgroundColor: 'white', borderRadius: 10, maxWidth: 300 } }}
      showCloseIcon={false}
    >
      <div className="resetInventoryModalContainer">
        <div className="header">Reset to default</div>
        <div className="title">
          {this.state.resetMessage
            ? this.state.resetMessage
            : 'This setting will be automatically turned on or off based on your company setting'}
        </div>
        <div className="buttonCotnainer">
          <div onClick={() => this.toggleResetToCompanyDefault(false)} className="cancelButton">
            Cancel
          </div>
          <div onClick={this.resetToCompanySettings} className="acceptButton">
            Reset
          </div>
        </div>
      </div>
    </Modal>
  );

  render() {
    return (
      <div className="CatalogueSettingsContainer">
        <Settings
          createLink={this.createLink}
          state={this.state}
          onSettingChanged={this.onSettingChanged}
          renderResetModal={this.renderResetModal}
          resetToCompanyDefault={this.toggleResetToCompanyDefault}
          toggleCustomComponent={this.toggleCustomComponent}
          catalogueId={this.catalogueId}
          closeCataloguePrivacyDialog={this.props.closeCataloguePrivacyDialog}
        />
      </div>
    );
  }
}

export default CatalogueSettings;
