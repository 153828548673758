import React from 'react';
import DialogBox from 'qs-components/Common/DialogBox';

import { toggleGlobalLoader } from 'qs-helpers';
import toastr from 'toastr';
import { selectedLabelsToEdit } from 'qs-data-manager/Selected';
import { deleteCatalogueLabelFromRemote } from 'qs-data-manager/Catalogues/CatalogueLabels';
import Mixpanel from 'qs-data-manager/Mixpanel';
import { getI18N } from 'qs-services/i18N';

const DeleteLabelDialog = ({ labels = [], openDeleteLabelDialog, setOpenDeleteLabelDialog }) => {
  const { t } = getI18N();

  const deleteCatalogueLabel = async event => {
    event.preventDefault();
    const key = `deleteCatalogueLabel${Date.now()}`;
    toggleGlobalLoader(key, true);
    try {
      const selectedLabels = selectedLabelsToEdit.getAll();
      await deleteCatalogueLabelFromRemote(Object.keys(selectedLabels));
      toastr.success(t('catalogue_label_removed_successfully'));
      Object.keys(selectedLabels).forEach(labelId => {
        const label = labels.find(label => label.id === labelId);
        if (label) {
          Mixpanel.sendEvent({
            eventName: 'catalogue_label_delete',
            props: {
              label_name: label.name,
              label_id: label.id
            }
          });
        }
      });
    } catch (updateError) {
      toastr.error(t('failed_to_delete_catalogue_label'));
    } finally {
      setOpenDeleteLabelDialog(false);
      toggleGlobalLoader(key, false);
    }
  };

  return (
    <DialogBox
      open={openDeleteLabelDialog}
      onClose={() => setOpenDeleteLabelDialog(false)}
      title={t('delete_label')}
      actionsConfig={[
        {
          content: t('cancel'),
          clickHandler: () => setOpenDeleteLabelDialog(false),
          role: 'SECONDARY'
        },
        {
          content: t('confirm'),
          clickHandler: deleteCatalogueLabel,
          role: 'PRIMARY'
        }
      ]}
      maxWidth="sm"
      id="label-delete-confirm-dialog-box"
    >
      <p>{t('are_you_sure_you_want_to_delete_this_label')}</p>
      <p>{t('catalogues_will_not_be_deleted_only_label_will_be_deleted')}</p>
    </DialogBox>
  );
};

export default DeleteLabelDialog;
