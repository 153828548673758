import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  formControl: {
    width: 200,
    borderRadius: '5px',
    padding: '4px 12px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  unselectedFormControl: {
    border: '2px solid rgb(129, 146, 167)',
    backgroundColor: 'rgb(24, 31, 39)',
    '&:hover': {
      backgroundColor: '#121821'
    }
  },
  selectedFormControl: {
    border: '2px solid rgb(30, 172, 113)',
    backgroundColor: 'rgba(22, 130, 85, 0.38)'
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

const getFormControlStyles = ({ labelValue, classes }) => {
  let decoratingClass = null;

  if (Array.isArray(labelValue)) {
    decoratingClass = labelValue.length
      ? classes.selectedFormControl
      : classes.unselectedFormControl;
  } else {
    decoratingClass = !!labelValue ? classes.selectedFormControl : classes.unselectedFormControl;
  }

  return decoratingClass ? clsx(classes.formControl, decoratingClass) : clsx(classes.formControl);
};

export { useStyles, getFormControlStyles };
