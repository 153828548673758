import { EXCEL_UPLOAD_META } from 'qs-data-manager/Catalogues';
import { getProductMetaFromCSV } from 'qs-data-manager/Products';
import XLSX from 'xlsx';
import { convertStringToTitleCase, getValidNumber } from '..';
import Api from '../../Api';
import { NUMBER_FIELD } from '../CustomProductFields/constants';
import { reportError } from '../ErrorReporting';
import { PROPERTY_NAME_MAP } from './constants';

let singleComponentsMapped = {};

export const onSelectFromDropDown = (id, columnName) => {
  singleComponentsMapped[id] = columnName;
};

export const shouldShowInDropDown = (id, columnName) =>
  typeof singleComponentsMapped[id] === 'undefined'
    ? true
    : columnName === singleComponentsMapped[id];

export const unSelectFromDropDown = id => {
  delete singleComponentsMapped[id];
};

export const resetSelectedDropDown = () => {
  singleComponentsMapped = {};
};

export const parseFileToCsv = file => {
  return new Promise((res, rej) => {
    const fileReader = new FileReader();
    fileReader.addEventListener('load', e => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: 'binary' });

      const sheetName = workbook.SheetNames[0];
      let result =
        XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
          header: 1,
          raw: false,
          blankrows: false
        }) || [];
      let i;
      for (i = 0; i < result.length; i += 1) {
        if (!result[i] || !result[i].length) {
          break;
        }

        result[i] = result[i].map(row => {
          if (typeof row === 'string') {
            // TODO: need to test this thoroughly, ideally passing raw as false to sheet_to_json should have worked
            // But for some reason some currency columns are getting formatted with $ sign
            if (row.startsWith('$')) {
              row = row.slice(1);
            }
          }
          return row;
        });
      }

      result = result.slice(0, i);
      res(result);
    });

    fileReader.addEventListener('error', event => {
      rej(event);
    });

    fileReader.readAsBinaryString(file);
  });
};

const removeEmptyColumns = ({ csvData }) => {
  let columnsWithData = {};

  for (let i = 1; i < csvData.length; i += 1) {
    let countOfColumnsWithData = 0;
    const row = csvData[i];
    for (let j = 0; j < row.length; j += 1) {
      const cell = row[j];

      if (typeof cell !== 'undefined') {
        countOfColumnsWithData += 1;
        columnsWithData[j] = true;
      }
    }

    //Columns with data equals all the columns in the csv, no need to process further since all columns are valid
    if (countOfColumnsWithData === csvData[0].length) {
      break;
    }
  }

  const emptyColumns = {};
  csvData[0].forEach((_, index) => {
    if (!columnsWithData[index]) {
      emptyColumns[index] = true;
    }
  });

  const csvVoidOfEmptyColumns = [];
  for (let k = 0; k < csvData.length; k += 1) {
    const row = csvData[k];
    const newRow = [];

    for (let l = 0; l < row.length; l += 1) {
      if (typeof emptyColumns[l] === 'undefined') {
        newRow.push(row[l]);
      }
    }

    csvVoidOfEmptyColumns[k] = newRow;
  }

  return csvVoidOfEmptyColumns;
};

export const verifyExcel = ({ csvData }) => {
  return new Promise((resolve, reject) => {
    const csvVoidOfEmptyColumns = removeEmptyColumns({ csvData });

    const sanitizedCsvData = csvVoidOfEmptyColumns.reduce((newCsvData, csvRow) => {
      if (!Array.isArray(csvRow) || csvRow.length === 0) {
        return newCsvData;
      }

      let emptyColumns = csvRow.length;
      csvRow.forEach(csvColumnData => {
        if (typeof csvColumnData === 'undefined') {
          emptyColumns--;
        }
      });

      if (emptyColumns !== 0) {
        newCsvData.push(csvRow);
      }

      return newCsvData;
    }, []);

    if (sanitizedCsvData.length === 0) {
      reject('This excel does not contain any data');
      return;
    }

    if (sanitizedCsvData.length === 1) {
      reject(
        'This excel only contains the headings. Please add rows for the headings you wish to map'
      );
      return;
    }

    resolve(sanitizedCsvData);
  });
};

export const excelIdentifierMapper = async ({ mappingKey, mapValues, catalogueId }) => {
  try {
    return await Api.excelUploadChanges({
      catalogueId,
      keyForMapping: mappingKey,
      valuesForMapping: mapValues
    });
  } catch (excelChangesError) {
    reportError(excelChangesError);
    throw excelChangesError;
  }
};

export const getPreviewFromExistingMapping = ({
  existingColumnMap,
  columnNames,
  row,
  excelComponentMap
}) => {
  const preview = {};

  const reverseMapping = getMappingOnIds({ columnNameDetailsMap: existingColumnMap });

  for (const key in reverseMapping) {
    if (reverseMapping.hasOwnProperty(key)) {
      preview[key] = getProductMetaFromCSV({
        headers: columnNames,
        componentCSVMapping: reverseMapping,
        row,
        fieldId: key,
        excelComponentMap
      });
    }
  }

  return preview;
};

export const getMappingOnIds = ({ columnNameDetailsMap }) => {
  const mapping = {};

  Object.keys(columnNameDetailsMap).forEach(columnName => {
    const values = columnNameDetailsMap[columnName];
    Object.keys(values).forEach(id => {
      if (!mapping[id]) {
        mapping[id] = [];
      }

      mapping[id].push(columnName);
    });
  });

  return mapping;
};

export const getMappingOnExcelColumns = ({ data = {} }) => {
  const mapping = {};

  Object.keys(data).forEach(key => {
    const excelColumns = data[key];

    excelColumns.forEach((column, index) => {
      if (!mapping[column]) {
        mapping[column] = {};
      }

      mapping[column][key] = index;
    });
  });

  return mapping;
};

const addToMapping = (mapping, { columnName, componentId, parentId }) => {
  if (!columnName) {
    return;
  }

  if (!mapping[columnName]) {
    mapping[columnName] = {};
  }

  mapping[columnName][componentId] = { parentId };
};

const getCurrentExcelComponentData = ({ componentId, excelComponentMap }) => {
  let {
    title,
    mapOnPattern,
    acceptsMultipleColumns,
    excludeCurrent = false,
    parentId,
    subExcelComponents
  } = excelComponentMap[componentId] || {};

  //Inherit properties from parentId
  if (parentId && excelComponentMap[parentId]) {
    title = title || excelComponentMap[parentId].title;
    mapOnPattern = mapOnPattern || excelComponentMap[parentId].mapOnPattern;
    acceptsMultipleColumns =
      acceptsMultipleColumns || excelComponentMap[parentId].acceptsMultipleColumns;
  }

  return {
    title,
    mapOnPattern,
    acceptsMultipleColumns,
    excludeCurrent,
    parentId,
    subExcelComponents
  };
};

const mapExcelComponentToExcelColumn = ({
  excelComponents,
  excelComponentMap,
  excelColumn,
  componentIdColumnNameMap
}) => {
  excelComponents.forEach(componentId => {
    const {
      title,
      mapOnPattern,
      acceptsMultipleColumns,
      excludeCurrent,
      parentId,
      subExcelComponents
    } = getCurrentExcelComponentData({ componentId, excelComponentMap });

    if (excludeCurrent) {
      if (!subExcelComponents) {
        return;
      }
      //recursive call to handle mapping for sub sections
      mapExcelComponentToExcelColumn({
        excelComponents: subExcelComponents,
        excelComponentMap,
        excelColumn,
        componentIdColumnNameMap
      });
      return;
    }

    let patternMatchRegex = mapOnPattern;
    if (typeof mapOnPattern === 'function') {
      patternMatchRegex = mapOnPattern(title);
    }
    if (!(patternMatchRegex instanceof RegExp)) {
      return;
    }
    const patternMatched = patternMatchRegex.test(excelColumn);
    if (patternMatched) {
      const matchesTitle = excelColumn.toLowerCase().trim() === title.toLowerCase();

      if (!componentIdColumnNameMap[componentId]) {
        componentIdColumnNameMap[componentId] = [];
      }

      componentIdColumnNameMap[componentId].push({
        columnName: excelColumn,
        matchesTitle,
        parentId
      });

      if (!acceptsMultipleColumns) {
        onSelectFromDropDown(componentId, excelColumn);
      }
    }

    if (!subExcelComponents) {
      return;
    }
    //recursive call to handle mapping for sub sections
    mapExcelComponentToExcelColumn({
      excelComponents: subExcelComponents,
      excelComponentMap,
      excelColumn,
      componentIdColumnNameMap
    });
  });
  return componentIdColumnNameMap;
};

const automaticallyMapComponentIds = ({ columnNames }) => {
  let componentIdColumnNameMap = {};

  columnNames.forEach(columnName => {
    columnName = `${columnName}`;
    componentIdColumnNameMap = mapExcelComponentToExcelColumn({
      excelComponents: EXCEL_UPLOAD_META.RENDER_ORDER,
      excelColumn: columnName,
      excelComponentMap: EXCEL_UPLOAD_META,
      componentIdColumnNameMap
    });
  });

  return componentIdColumnNameMap;
};

const discardExtraMatches = ({ mappedColumns, excelMapping }) => {
  const mappedColumnNames = {};

  Object.keys(mappedColumns).forEach(componentId => {
    const { parentId } = excelMapping[componentId] || {};
    let { ifMultipleTitlesMatch } = excelMapping[componentId] || {};

    //Inherit from parent if possible
    if (parentId && !ifMultipleTitlesMatch) {
      ({ ifMultipleTitlesMatch } = excelMapping[parentId] || {});
    }

    if (!mappedColumns[componentId].length) {
      return;
    }

    if (mappedColumns[componentId].length === 1) {
      const { columnName, parentId } = mappedColumns[componentId][0];

      addToMapping(mappedColumnNames, { columnName, componentId, parentId });
      return;
    }

    if (ifMultipleTitlesMatch === 'MATCH_NONE') {
      return;
    }

    if (ifMultipleTitlesMatch === 'MATCH_ALL') {
      mappedColumns[componentId].forEach(({ columnName, parentId }) => {
        addToMapping(mappedColumnNames, { columnName, componentId, parentId });
      });
      return;
    }

    if (ifMultipleTitlesMatch === 'MATCH_TITLE') {
      const titleColumn = mappedColumns[componentId].filter(({ isTitle }) => isTitle);
      const { columnName, parentId } = titleColumn[0]
        ? titleColumn[0]
        : mappedColumns[componentId][0];

      addToMapping(mappedColumnNames, { columnName, componentId, parentId });
    }
  });

  return mappedColumnNames;
};

export const automaticallyMapColumns = ({ columnNames }) => {
  let mappedColumnNames = {};

  if (!columnNames || !Array.isArray(columnNames)) {
    return mappedColumnNames;
  }

  const componentIdColumnNameMap = automaticallyMapComponentIds({ columnNames });

  mappedColumnNames = discardExtraMatches({
    mappedColumns: componentIdColumnNameMap,
    excelMapping: EXCEL_UPLOAD_META
  });

  return mappedColumnNames;
};

//TODO create existing values map from existing values
// and then peroform operations on it to enable mapping static
// and dynamic columns on to the same excle column
export const lazyAutoMapColumns = ({ columnNames, excelComponents, excelComponentMap }) => {
  if (!columnNames || !Array.isArray(columnNames)) {
    return {};
  }

  let componentIdColumnNameMap = {};

  columnNames.forEach(columnName => {
    columnName = `${columnName}`;
    componentIdColumnNameMap = mapExcelComponentToExcelColumn({
      excelComponents: excelComponents,
      excelColumn: columnName,
      excelComponentMap,
      componentIdColumnNameMap
    });
  });

  const mappedColumnNames = discardExtraMatches({
    mappedColumns: componentIdColumnNameMap,
    excelMapping: excelComponentMap
  });

  return mappedColumnNames;
};

export const getDefaultMappedResult = rowLength => {
  return { changes: { newProducts: rowLength, updatedProducts: 0 } };
};

export const getDropDownValuesFromSelection = ({ columnNameDetailsMap, excelMappingFieldIds }) => {
  if (!Array.isArray(excelMappingFieldIds)) {
    return [];
  }

  return excelMappingFieldIds.reduce((optionValues, { id, title } = {}) => {
    if (columnNameDetailsMap[id]) {
      optionValues.push({
        value: id,
        displayValue: title
      });
    }
    return optionValues;
  }, []);
};

export const getFirstMappedField = ({ columnNameDetailsMap, excelMappingFieldIds }) => {
  if (!Array.isArray(excelMappingFieldIds)) {
    return '';
  }

  for (const fieldObj of excelMappingFieldIds) {
    const { id } = fieldObj;
    if (columnNameDetailsMap[id]) {
      return id;
    }
  }

  return '';
};

export const getBestSelectorAndUpsertCount = ({ catalogueId, excelRows }) =>
  Api.getBestSelectorAndUpsertCount({ catalogueId, excelRows });

export const checkForSameSkus = ({ componentCSVMapping, csvData, excelComponentMap }) => {
  const columnNames = csvData[0];

  const skus = {};
  let rowsHaveSameSkus = false;

  for (let i = 1; i < csvData.length; i += 1) {
    const row = csvData[i];

    const meta = getProductMetaFromCSV({
      headers: columnNames,
      componentCSVMapping,
      row,
      fieldId: EXCEL_UPLOAD_META.SKU.id,
      excelComponentMap
    });

    const sku = EXCEL_UPLOAD_META.SKU.getApiFormattedValue(meta);

    if (sku && skus[sku]) {
      rowsHaveSameSkus = true;
      break;
    }

    if (sku) {
      skus[sku] = true;
    }
  }

  return rowsHaveSameSkus;
};

export const formatNumberForExcelUploadApi = data => {
  if (!Array.isArray(data) || !data.length) {
    return undefined;
  }

  const validNumber = getValidNumber(data[0]);

  if (validNumber === null) {
    return undefined;
  }

  return validNumber;
};

const OBJECT_KEY_CACHE_KEY_MAPPING = {
  [PROPERTY_NAME_MAP.FIELDS]: 'CUSTOM_FIELDS',
  [PROPERTY_NAME_MAP.VARIANTS]: 'CUSTOM_VARIANTS'
};

const getCustomPropertyFields = ({ propertyFieldName, error, loading, refreshing, data }) => ({
  ...EXCEL_UPLOAD_META[OBJECT_KEY_CACHE_KEY_MAPPING[propertyFieldName]],
  subExcelComponents: (data[propertyFieldName] || []).map(({ id }) => `${propertyFieldName}_${id}`),
  loading,
  refreshing,
  error
});

const getApiFormattedValueFunction = ({ key, fieldType, id }) => {
  if (key === PROPERTY_NAME_MAP.FIELDS) {
    return input => {
      if (!Array.isArray(input) || !input.length) {
        return;
      }
      return {
        id,
        fieldType,
        value: input[0]
      };
    };
  }

  if (key === PROPERTY_NAME_MAP.VARIANTS) {
    return input => {
      if (!Array.isArray(input) || !input.length) {
        return;
      }
      return input[0];
    };
  }

  return null;
};

const getPreviewFormattedValueFunction = ({ key, fieldType }) => {
  if (key === PROPERTY_NAME_MAP.FIELDS) {
    return input => {
      if (fieldType === NUMBER_FIELD) {
        return formatNumberForExcelUploadApi(input);
      }
      if (!Array.isArray(input) || input.length === 0) {
        return;
      }
      return input[0];
    };
  }

  if (key === PROPERTY_NAME_MAP.VARIANTS) {
    return input => {
      if (!Array.isArray(input) || !input.length) {
        return;
      }
      return input[0];
    };
  }

  return null;
};

const getParentId = ({ key, id }) => {
  if (key === PROPERTY_NAME_MAP.FIELDS) {
    return EXCEL_UPLOAD_META.CUSTOM_FIELDS.id;
  }

  if (key === PROPERTY_NAME_MAP.VARIANTS) {
    return EXCEL_UPLOAD_META.CUSTOM_VARIANTS.id;
  }

  return null;
};

const getExcelMappingEntry = ({ key, customPropertyData }) => {
  const { fieldName, fieldType, id } = customPropertyData;
  const isCustomVariant = key === PROPERTY_NAME_MAP.VARIANTS;
  const titleCasedFieldName = isCustomVariant ? convertStringToTitleCase(fieldName) : fieldName;
  return {
    title: titleCasedFieldName,
    previewTitle: titleCasedFieldName,
    getApiFormattedValue: getApiFormattedValueFunction({ key, fieldType, id }),
    getPreviewFormattedValue: getPreviewFormattedValueFunction({ key, fieldType }),
    parentId: getParentId({ key, id }),
    ...(fieldType && { fieldType }),
    ...(isCustomVariant && {
      apiKey: fieldName,
      mapOnPattern: new RegExp(`^${fieldName}$`, 'i')
    })
  };
};

const getInitialExcelMappingData = ({ key, data, loading, refreshing, error }) => ({
  [EXCEL_UPLOAD_META[OBJECT_KEY_CACHE_KEY_MAPPING[key]].id]: getCustomPropertyFields({
    propertyFieldName: key,
    error,
    loading,
    refreshing,
    data
  })
});

export const getCustomProductPropertiesIdMap = ({ data, loading, refreshing, error }) => {
  if (loading || refreshing || error || !data) {
    return {};
  }

  return Object.entries(data).reduce(
    (finalMap, [key, value]) => ({
      ...finalMap,
      ...value.reduce((cumulativeMap, customPropertyData) => {
        cumulativeMap[`${key}_${customPropertyData.id}`] = getExcelMappingEntry({
          key,
          customPropertyData
        });
        return cumulativeMap;
      }, getInitialExcelMappingData({ key, data, loading, refreshing, error }))
    }),
    {}
  );
};
