import { makeStyles } from '@material-ui/core';

export const useInputStyles = makeStyles({
  inputRoot: {
    color: '#ffffff !important'
  },
  input: {
    '&::placeholder': {
      color: '#c1cbe6'
    }
  },
  inputError: {
    fontSize: '14px'
  },
  inputUnderline: {
    '&:before': {
      borderColor: '#878c96'
    },
    '&:after': {
      borderColor: '#ffffff'
    },
    '&:hover:before': {
      borderColor: '#878c96 !important'
    }
  }
});
