import { getVariantsMetaDataFromCache } from 'qs-data-manager/Variants/FetchVariants';
import { MOQ_PRODUCT_ENTITY } from 'qs-helpers/Moq/constants';
import {
  fetchMoqForEntityHandler,
  getCachedMoqData,
  handleMoqForEntityCleanup
} from 'qs-helpers/Moq/DataQueryHelper';
import { processMoqForVariant } from 'qs-helpers/Variants/ResponseProcessor';
import useMakeQuery from 'qs-hooks/useMakeQuery';
import React, { useContext, useEffect } from 'react';
import MoqField from '../../../Common/MoqField';
import { ActiveVariantId } from '../../../context';
import { VariantDataForUpdate } from '../../context';
import { UPDATE_FROM_PRODUCT_MOQ, UPDATE_MOQ, RESET_MOQ_DATA } from '../../reducer';

export default () => {
  const { activeVariantId } = useContext(ActiveVariantId);

  const {
    variantData: { moq, showMoqChange },
    setVariantData
  } = useContext(VariantDataForUpdate);

  const [{ loading, refreshing, error, data: variantMoq }] = useMakeQuery({
    changeDependancy: [activeVariantId, MOQ_PRODUCT_ENTITY, true],
    actionHandler: fetchMoqForEntityHandler,
    cleanupHandler: handleMoqForEntityCleanup,
    cachedDataHandler: getCachedMoqData,
    onceListener: true
  });

  useEffect(() => {
    // Wait until all of the data fetching is done
    if (loading || refreshing) {
      return;
    }

    setVariantData({
      type: RESET_MOQ_DATA,
      moqData: processMoqForVariant({ variantMoq })
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, refreshing, variantMoq]);

  const onMoqChange = value => {
    setVariantData({ type: UPDATE_MOQ, moq: value });
  };

  const onMoqChangeClick = () => {
    setVariantData({ type: UPDATE_FROM_PRODUCT_MOQ, showMoqChange: false });
  };

  let isSet = false;
  const variantMeta = getVariantsMetaDataFromCache(activeVariantId);
  if (variantMeta) {
    ({ isSet } = variantMeta);
  }

  return (
    <MoqField
      moq={moq}
      onChange={onMoqChange}
      changeButton={showMoqChange}
      onChangeClick={onMoqChangeClick}
      error={error}
      loading={loading}
      disabled={refreshing}
      isSet={isSet}
    />
  );
};
