import React, { useCallback, useMemo } from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import { InfiniteRowModelModule } from '@ag-grid-community/infinite-row-model';
import { COLUMN_DEFS, defaultColumnProps } from '../CellConfigurations';
import {
  getProductLibraryProductsDataBasedOnFilter,
  getCompanyProductsData
  //searchProductsFromLibrary
} from 'qs-data-manager/ProductLibrary/ProductsForLibrary';
import { PRODUCT_LIBRARY_GRID } from 'qs-helpers/ProductLibrary/constants';
import { getI18N } from 'qs-services/i18N';
//import { getProductsSearchDataFromCache } from 'qs-helpers/ProductLibrary/CacheHelpers';

export default ({
  handleRowSelection,
  handleRowChange,
  getRowNodeId,
  containerClass,
  catalogueFilter = '',
  currentCatalogueId,
  disableMultiple = false
}) => {
  const { t } = getI18N();

  const infiniteScrollDataSource = useMemo(() => {
    //Save these variables so that the closure can access them
    const batchMap = new Map();
    let newBatchStartIndex = 0,
      lastfilteredProductIndex = 0;

    return {
      /**
       * This method will process the batch requests for the grid.
       * This works on two assumptions
       * 1. The batch size of the grid remains the same throught. This ensures
       * that the saved batch can be picked and the list can be filtered again
       * from that position
       * 2. Products data remains the same. This means that from an index where
       * the products list was processed, no products are added or removed from
       * the list, hence the saved batch can be filtered once again and the
       * operation remains idempotent
       */
      getRows: async params => {
        let { startRow, endRow, successCallback, failCallback } = params || {};
        const rowBatch = endRow - startRow;
        const filteredProducts = [];

        /**
         * All products have been loaded, set the filtered product count as
         * the last row index
         * @param {number} totalProductsCount
         */
        const getLastRowIndex = totalProductsCount => {
          if (newBatchStartIndex !== totalProductsCount) {
            return -1;
          }
          return lastfilteredProductIndex;
        };

        /**
         * Save last visited index and lastFilteredIndex data only if update is needed
         * This will be used for processing the next new batch and setting the
         * last row index for the grid pagination
         * @param {boolean} updateRequired
         * @param {number} lastIndex
         */
        const persistFilterDataIfRequired = (updateRequired, lastIndex) => {
          if (updateRequired) {
            newBatchStartIndex = lastIndex + 1;
            lastfilteredProductIndex += filteredProducts.length - 1;
          }
        };

        /**
         * This method will filter the provided product list until either the
         * list is exchausted or the batch size is fulfilled. It will return the
         * last index that was checked so that further iteration if needed can be
         * started from this index
         * @param {Array} productList
         * @param {number} filterStartIndex
         * @returns {number} lastValidIndex: The index till which the iteration was done
         */
        const filterProducts = (productList, filterStartIndex) => {
          let lastValidIndex = productList.length - 1;
          for (let index = filterStartIndex; index < productList.length; index++) {
            const { catalogueId } = productList[index];
            if (catalogueId === currentCatalogueId) {
              continue;
            }

            filteredProducts.push(productList[index]);
            if (filteredProducts.length === rowBatch) {
              lastValidIndex = index;
              break;
            }
          }

          return lastValidIndex;
        };

        /**
         * Returns true if the batch size is satisified or if all of the products have
         * been retrieved. If complete, it will call the grid success callback
         * and complete the pagination handling for the grid as well as save the data
         * required for the processing of the next batch
         * @param {object} param0
         * @returns {bool} true if pagination has been completed, false otherwise
         */
        const checkIfDataIsComplete = ({
          productList,
          newBatch,
          lastValidIndex,
          totalProducts
        }) => {
          if (filteredProducts.length === rowBatch || productList.length === totalProducts) {
            persistFilterDataIfRequired(newBatch, lastValidIndex);
            successCallback(filteredProducts, getLastRowIndex(totalProducts));
            return true;
          }
          return false;
        };

        let { products, totalProducts } = getProductLibraryProductsDataBasedOnFilter(
          catalogueFilter
        );
        /* let { products, productsTotal } = getProductsSearchDataFromCache({
          catalogueId: catalogueFilter
        }); */

        let startIndex = newBatchStartIndex,
          newBatch = false;
        const savedBatchStart = batchMap.get(startRow);
        if (typeof savedBatchStart === 'number') {
          startIndex = savedBatchStart;
        } else {
          //Set the startIndex as the start of this batch for later processing
          batchMap.set(startRow, startIndex);
          newBatch = true;
        }

        let lastValidIndex = filterProducts(products, startIndex);
        let dataComplete = checkIfDataIsComplete({
          productList: products,
          //totalProducts: productsTotal,
          totalProducts,
          newBatch,
          lastValidIndex
        });
        if (dataComplete) {
          return;
        }

        //Continue fetching next batch until pagination is completed for the
        //current batch. TODO fix API for all case
        while (true) {
          try {
            await getCompanyProductsData({ returnError: true });
            //await searchProductsFromLibrary({ catalogueId: catalogueFilter, forceLoad: true });
            ({ products, totalProducts } = getProductLibraryProductsDataBasedOnFilter(
              catalogueFilter
            ));
            /* ({ products, productsTotal } = getProductsSearchDataFromCache({
              catalogueId: catalogueFilter
            })); */
          } catch (error) {
            failCallback();
            return;
          }

          lastValidIndex = filterProducts(products, lastValidIndex + 1);
          dataComplete = checkIfDataIsComplete({
            productList: products,
            totalProducts,
            //totalProducts: productsTotal,
            newBatch,
            lastValidIndex
          });

          //Pagination completed exit the loop
          if (dataComplete) {
            return;
          }
        }
      }
    };
  }, [currentCatalogueId, catalogueFilter]);

  /**
   * Rows in the grid have changed, check if any of these rows were previously selected.
   * This event is fired even before the grid is ready
   */
  const rowDataChangeEvent = useCallback(
    event => {
      const { api } = event || {};
      handleRowChange(api);
    },
    [handleRowChange]
  );

  // TODO add all selections
  COLUMN_DEFS[0].headerCheckboxSelection = false;
  COLUMN_DEFS[0].checkboxSelection = true;

  return (
    <div style={{ height: '100%', width: '100%' }} className={containerClass}>
      <AgGridReact
        modules={[InfiniteRowModelModule]}
        columnDefs={COLUMN_DEFS.map(column => ({ ...column, headerName: t(column.headerName) }))}
        defaultColDef={defaultColumnProps}
        rowHeight={PRODUCT_LIBRARY_GRID.rowHeight}
        headerHeight={PRODUCT_LIBRARY_GRID.headerHeight}
        rowMultiSelectWithClick={true}
        rowSelection={disableMultiple ? 'single' : 'multiple'}
        onRowSelected={handleRowSelection}
        getRowNodeId={getRowNodeId}
        rowModelType="infinite"
        datasource={infiniteScrollDataSource}
        cacheBlockSize={PRODUCT_LIBRARY_GRID.pageSize}
        cacheOverflowSize={PRODUCT_LIBRARY_GRID.unloadedRowCount}
        maxBlocksInCache={PRODUCT_LIBRARY_GRID.maxCachedBlocks}
        onPaginationChanged={rowDataChangeEvent}
        rowBuffer={PRODUCT_LIBRARY_GRID.rowsBufferedForDisplay}
      />
    </div>
  );
};
