import React, { useCallback, useState } from 'react';
import Loader from 'qs-components/Common/Loader';
import ErrorPopup from 'qs-components/Common/ErrorPopup';
import {
  DialogDarkTheme,
  DarkThemeTitle,
  PrimaryButton
} from 'qs-components/Common/DarkThemeDialog';
import { DialogActions, DialogContent } from '@material-ui/core';
import { getErrorMessage } from 'qs-helpers';
import { EDIT_ORDER_CUSTOM_ERROR_MESSAGES } from 'qs-helpers/Orders/constants';
import { ReactComponent as EditIcon } from 'qs-assets/Media/pencil_filled.svg';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

export default ({
  children,
  classes = '',
  canEditField,
  updaterFunction,
  errorInfo = {},
  renderEditableField
}) => {
  const [showEditableField, setShowEditableField] = useState(false);
  const [showEditError, setShowEditError] = useState(false);
  const [overlayLoader, setOverlayLoader] = useState(false);
  const [errorData, setErrorData] = useState();

  const { t } = getI18N();

  const updateData = useCallback(
    async (...apiParams) => {
      if (typeof updaterFunction !== 'function') {
        return;
      }

      setOverlayLoader(true);
      setShowEditableField(false);
      try {
        await updaterFunction(...apiParams);
      } catch (error) {
        const updatedErroData = {
          ...(errorInfo.mail || {}),
          body: getErrorMessage(error),
          showContactSupport: true
        };
        if (error && error.reason && EDIT_ORDER_CUSTOM_ERROR_MESSAGES[error.reason]) {
          updatedErroData.message = t(EDIT_ORDER_CUSTOM_ERROR_MESSAGES[error.reason].message);
          updatedErroData.showContactSupport =
            EDIT_ORDER_CUSTOM_ERROR_MESSAGES[error.reason].shouldShowSupportButton;
        }
        setErrorData(updatedErroData);
      } finally {
        setOverlayLoader(false);
      }
    },
    [updaterFunction, errorInfo.mail, t]
  );

  const handleEditableFieldClick = () => {
    if (typeof canEditField !== 'function') {
      setShowEditError(true);
      return;
    }

    if (canEditField()) {
      setShowEditableField(true);
      return;
    }

    setShowEditError(true);
  };

  let editableClass = `orderProductEditableField editableField ${classes}`,
    clickHandler = null;
  if (!overlayLoader) {
    editableClass += ` hoverEnabled`;
    clickHandler = handleEditableFieldClick;
  } else {
    editableClass += ` showLoader`;
  }

  const { fieldEditError = {} } = errorInfo;

  return (
    <>
      <div className={editableClass} onClick={clickHandler}>
        <div className="childWithIcon">
          <span className="ellipsis">{children}</span>
          <EditIcon className="editIcon" />
        </div>
        {overlayLoader && (
          <div className="loaderContainer">
            <Loader size="extraSmall" />
          </div>
        )}
      </div>
      {showEditableField &&
        renderEditableField({ onCancel: () => setShowEditableField(false), onUpdate: updateData })}
      {errorData && (
        <ErrorPopup
          onClose={() => setErrorData()}
          mailProps={errorData}
          title={t(errorData.title)}
          showContactSupport={errorData.showContactSupport}
        >
          {t(errorData.message)}
        </ErrorPopup>
      )}
      {showEditError && (
        <DialogDarkTheme open={true} maxWidth={'xs'}>
          <DarkThemeTitle>{t(fieldEditError.title)}</DarkThemeTitle>
          <DialogContent>{t(fieldEditError.content)}</DialogContent>
          <DialogActions>
            <PrimaryButton color={'primary'} onClick={() => setShowEditError(false)}>
              <span>{t('ok')}</span>
            </PrimaryButton>
          </DialogActions>
        </DialogDarkTheme>
      )}
    </>
  );
};
