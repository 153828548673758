import AppConfig from 'qs-config/AppConfig';

export const IMAGE_LOADER = 'LOADER';

export const IMAGE_ERROR = 'LOAD_ERROR';

export const IMAGE_HIDE_LOADER = 'HIDE_LOADER';

export const hasImageChanged = (changedImage, currentImage) => {
  //TODO remove the boolean check once error handling for the
  // third pane is also complete
  if (
    changedImage.id !== currentImage.id ||
    changedImage.prepared !== currentImage.prepared ||
    (typeof changedImage.error === 'boolean' &&
      typeof currentImage.error === 'boolean' &&
      changedImage.error !== currentImage.error)
  ) {
    return true;
  }
  return false;
};

export const replaceCfWithS3 = imageUrl => {
  if (!imageUrl) {
    return imageUrl;
  }

  return imageUrl.replace(AppConfig.imageUrlSource.CDN, AppConfig.imageUrlSource.S3);
};

export const modifyPicturesUrlBackToS3 = pictures => {
  if (!Array.isArray(pictures)) {
    return pictures;
  }

  return pictures.map(pictureData => ({
    ...pictureData,
    url: replaceCfWithS3(pictureData.url)
  }));
};
