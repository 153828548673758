import React, { useRef, useState } from 'react';
import Modal from 'react-responsive-modal';

export default ({ onClose, defaultCompany, phone }) => {
  const modalStyles = useRef({
    backgroundColor: 'white',
    borderRadius: 10,
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    width: '400px'
  });

  const [companyDetails, setCompanyDetails] = useState({ ...defaultCompany, phone });

  const handleCompanyInfoChange = function(event) {
    const inputId = event.target.id;
    const value = event.target.value;
    const newCompanyDetails = { ...companyDetails };
    const newErrorObj = {};
    switch (inputId) {
      case 'companyName':
        newCompanyDetails.addressLineOne = value;
        delete newErrorObj.addressOne;
        break;
      case 'companyCurrency':
        newCompanyDetails.currencyCode = value;
        break;
      case 'phone':
        newCompanyDetails.phone = value;
        delete newErrorObj.zip;
        break;
      case 'city':
        newCompanyDetails.city = value;
        delete newErrorObj.city;
        break;
      default:
        break;
    }

    setCompanyDetails(newCompanyDetails);
  };

  return (
    <Modal
      open={true}
      center={true}
      onClose={onClose}
      showCloseIcon={false}
      styles={{ modal: modalStyles.current }}
    >
      <h3>Setup your company</h3>
      <div>
        <section>
          <h2>Brand name</h2>
          <p>Your brand name will be visible to customer on your catalogues</p>
          <input
            type="text"
            id="companyName"
            value={companyDetails.name}
            onChange={handleCompanyInfoChange}
          />
        </section>
        <section>
          <h2>Currency</h2>
          <p>Please select the default currency of your products</p>
          <input type="text" id="companyCurrency" value={companyDetails.currencyCode} />
        </section>
        <section>
          <h2>WhatsApp number</h2>
          <p>
            Your active business or whatsapp phone number is required so that customers can contact
            you
          </p>
          <input type="tel" id="phone" value={companyDetails.phone} />
        </section>
      </div>
    </Modal>
  );
};
