import React, { useContext, useEffect, useState } from 'react';
import useMakeQuery from 'qs-hooks/useMakeQuery';
import {
  attachProductListListener,
  deleteFilteredCacheData,
  removeProductListListener
} from 'qs-data-manager/Products/CatalogueProductsList';
import { ActiveCatalogueProductListMeta } from '../context';
import {
  PRODUCT_LIST_CLEAR_FILTERS,
  UNSET_PRODUCT_LIST_APPLY_FILTER,
  UNSET_PRODUCT_LIST_META_RESET
} from '../reducer';
import './styles.scss';
import { getI18N } from 'qs-services/i18N';

export default ({ catalogueId }) => {
  const {
    productListMeta: {
      currentPriceFilters,
      applyFilters,
      currentVariantSelection,
      currentCustomFieldsSelection,
      customFieldsNumericOption,
      reset
    },
    setProductListMeta
  } = useContext(ActiveCatalogueProductListMeta);

  const [showClearFilter, setShowClearFilter] = useState(false);

  const { t } = getI18N();

  const [{ data }] = useMakeQuery({
    cachedDataHandler: () => {},
    actionHandler: ({ listener, changeDependancy: [activeCatalogueId] }) => {
      attachProductListListener({ listener, catalogueId: activeCatalogueId, filters: true });
    },
    cleanupHandler: ({ listener, changeDependancy: [activeCatalogueId] }) => {
      removeProductListListener({ listener, catalogueId: activeCatalogueId, filters: true });
      deleteFilteredCacheData({ catalogueId: activeCatalogueId });
    },
    changeDependancy: [catalogueId],
    sendEmptyData: true
  });

  useEffect(() => {
    if (!reset) {
      return;
    }
    setShowClearFilter(false);
    setProductListMeta({ type: UNSET_PRODUCT_LIST_META_RESET });
  }, [reset, setProductListMeta]);

  useEffect(() => {
    if (!applyFilters) {
      return;
    }

    setShowClearFilter(
      Object.keys(currentPriceFilters).length > 0 ||
        Object.keys(currentVariantSelection).length > 0 ||
        (Object.keys(currentCustomFieldsSelection).length > 0 &&
          Object.keys(customFieldsNumericOption).length > 0)
    );
    setProductListMeta({ type: UNSET_PRODUCT_LIST_APPLY_FILTER });
  }, [
    currentPriceFilters,
    applyFilters,
    currentVariantSelection,
    currentCustomFieldsSelection,
    customFieldsNumericOption,
    setProductListMeta
  ]);

  const onClearFilters = () => {
    setProductListMeta({ type: PRODUCT_LIST_CLEAR_FILTERS });
  };

  if (!data || !Array.isArray(data.productsList)) {
    return null;
  }

  const totalProducts = data.productsList.length;

  return (
    <div id={'catalogueSearchResult'}>
      <div className={'result'}>
        {totalProducts === 1
          ? t('search_products_found', { totalProducts })
          : t('search_product_found', { totalProducts })}
      </div>
      {showClearFilter && (
        <div onClick={onClearFilters} className={'clearFilterContainer'}>
          {t('clear_filter')}
        </div>
      )}
    </div>
  );
};
