import ProductMagazineTileIcon from 'qs-assets/Media/product_magazine_tile_icon.svg';
import { GRAPES_JS_PLUGINS } from '../Plugins';
import { GRAPES_JS_DEFAULT_CONFIG } from '../Plugins/config';
import { capitalizeFirstLetter } from 'qs-helpers';
import { getI18N } from 'qs-services/i18N';
import {
  ACCENT_COLOR,
  WHITE_COLOR,
  BLACK_COLOR,
  TEXT_COLOR,
  BORDER_COLOR,
  PRODUCT_MAGAZINE_TILE_ELEMENT_TYPES,
  PRODUCT_MAGAZINE_TILE_VARIABLES_MAP,
  PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP,
  PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX
} from '../Constants';

export const PRODUCT_MAGAZINE_TILE_ELEMENT_TYPE_TRAIT_ID = `data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX}-element-type`;

export const PRODUCT_MAGAZINE_TILE_ELEMENT_TYPE_TRAIT = {
  get CONFIG() {
    const { t } = getI18N();
    return {
      type: 'select',
      label: t('product_magazine_tile_element_type'),
      name: PRODUCT_MAGAZINE_TILE_ELEMENT_TYPE_TRAIT_ID,
      placeholder: t('enter_product_magazine_tile_element_type'),
      default: 'none',
      options: [{
          id: 'none',
          name: t('none')
        },
        ...PRODUCT_MAGAZINE_TILE_ELEMENT_TYPES.CONFIG
      ]
    };
  }
};

export const PRODUCT_MAGAZINE_TILE_CUSTOM_COMPONENT = {
  get HTML() {
    const { t } = getI18N();
    return `
      <div
        class="${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileContainer} gjs-row"
        data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileId}="${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productId}"
        data-quicksell-component-custom-name="${t('product_magazine_tile')}"
        data-quicksell-component-type="${PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX}"
        data-quicksell-component-editable="false"
        data-quicksell-component-removable="false"
        data-quicksell-component-copyable="false"
        data-quicksell-component-draggable="false"
      >
        <div
          class="${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileImageContainer} gjs-cell"
          data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileImageContainer}="${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productId}"
          data-quicksell-component-type="default"
          data-quicksell-component-editable="false"
          data-quicksell-component-removable="false"
          data-quicksell-component-copyable="false"
          data-quicksell-component-draggable="false"
        >
          <div
            class="${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileOutOfStockBadge}"
            data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileOutOfStockBadge}="${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productId}"
            data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX}-element-type="${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileOutOfStockBadge}"
            data-quicksell-component-type="text"
            data-quicksell-component-editable="false"
            data-quicksell-component-removable="false"
            data-quicksell-component-copyable="false"
            data-quicksell-component-draggable="false"
          >
            ${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.outOfStockText}
          </div>
          <img
            class="${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileImage}"
            data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileImage}="${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productId}"
            data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileImageId}="${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.pictureId}"
            data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX}-element-type="${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileImage}"
            src="${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.pictureUrl}"
            alt="${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.pictureAlt}"
            decoding="sync"
            loading="lazy"
            height="${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.pictureHeight}"
            data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileImageHeight}="${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.pictureHeight}"
            width="${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.pictureWidth}"
            data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileImageWidth}="${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.pictureWidth}"
            data-quicksell-component-type="image"
            data-quicksell-component-editable="false"
            data-quicksell-component-removable="false"
            data-quicksell-component-copyable="false"
            data-quicksell-component-draggable="false"
          />
          <img
            class="${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileImagePrevious}"
            data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileImagePrevious}="${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productId}"
            data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX}-element-type="${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileImagePrevious}"
            src="https://catalogue-cdn.quicksell.co/s/assets/left-angle.png"
            alt="${t('product_magazine_tile_image_previous')}"
            decoding="sync"
            loading="lazy"
            height="61"
            width="40"
            data-quicksell-component-type="image"
            data-quicksell-component-editable="false"
            data-quicksell-component-removable="false"
            data-quicksell-component-copyable="false"
            data-quicksell-component-draggable="false"
          />
          <img
            class="${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileImageNext}"
            data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileImageNext}="${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productId}"
            data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX}-element-type="${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileImageNext}"
            src="https://catalogue-cdn.quicksell.co/s/assets/right-angle.png"
            alt="${t('product_magazine_tile_image_next')}"
            decoding="sync"
            loading="lazy"
            height="61"
            width="40"
            data-quicksell-component-type="image"
            data-quicksell-component-editable="false"
            data-quicksell-component-removable="false"
            data-quicksell-component-copyable="false"
            data-quicksell-component-draggable="false"
          />
        </div>
        <div
          class="${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileDetailsContainer} gjs-cell"
          data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileDetailsContainer}="${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productId}"
          data-quicksell-component-type="default"
          data-quicksell-component-editable="false"
          data-quicksell-component-removable="false"
          data-quicksell-component-copyable="false"
          data-quicksell-component-draggable="false"
        >
          <div
            class="${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileThumbnailsContainer}"
            data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileThumbnailsContainer}="${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productId}"
            data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX}-element-type="${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileThumbnailsContainer}"
            data-quicksell-component-type="text"
            data-quicksell-component-editable="false"
            data-quicksell-component-removable="false"
            data-quicksell-component-copyable="false"
            data-quicksell-component-draggable="false"
          >
            ${[1, 2, 3, 4, 5].map((key) => {
              return `
                <div
                  class="${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileThumbnailContainer}"
                  data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileThumbnailContainer}="${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productId}"
                  data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX}-element-type="${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileThumbnailContainer}"
                  data-quicksell-component-type="text"
                  data-quicksell-component-editable="false"
                  data-quicksell-component-removable="false"
                  data-quicksell-component-copyable="false"
                  data-quicksell-component-draggable="false"
                >
                  <img
                    class="${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileThumbnail}${key === 1 ? ` ${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileThumbnailSelected}` : ''}"
                    data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileThumbnail}="${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productId}"
                    data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileThumbnailId}="${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.pictureId}"
                    data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX}-element-type="${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileThumbnail}"
                    src="${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.pictureUrl}"
                    alt="${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.pictureAlt}"
                    decoding="sync"
                    loading="lazy"
                    height="${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.pictureHeight}"
                    data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileImageHeight}="${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.pictureHeight}"
                    width="${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.pictureWidth}"
                    data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileImageWidth}="${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.pictureWidth}"
                    data-quicksell-component-type="image"
                    data-quicksell-component-editable="false"
                    data-quicksell-component-removable="false"
                    data-quicksell-component-copyable="false"
                    data-quicksell-component-draggable="false"
                  />
                </div>
              `;
            }).join('')}
          </div>
          <div
            class="${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileName}"
            data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileName}="${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productId}"
            data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX}-element-type="${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileName}"
            data-quicksell-component-type="text"
            data-quicksell-component-editable="false"
            data-quicksell-component-removable="false"
            data-quicksell-component-copyable="false"
            data-quicksell-component-draggable="false"
          >
            ${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productName}
          </div>
          <div
            class="${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileSku}"
            data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileSku}="${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productId}"
            data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX}-element-type="${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileSku}"
            data-quicksell-component-type="text"
            data-quicksell-component-editable="false"
            data-quicksell-component-removable="false"
            data-quicksell-component-copyable="false"
            data-quicksell-component-draggable="false"
          >
            ${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productSku}
          </div>
          <div
            class="${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileDiscountBadge}"
            data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileDiscountBadge}="${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productId}"
            data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX}-element-type="${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileDiscountBadge}"
            data-quicksell-component-type="text"
            data-quicksell-component-editable="false"
            data-quicksell-component-removable="false"
            data-quicksell-component-copyable="false"
            data-quicksell-component-draggable="false"
          >
            ${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.discountPercent}
          </div>
          <div
            class="${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTilePriceContainer}"
            data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTilePriceContainer}="${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productId}"
            data-quicksell-component-type="default"
            data-quicksell-component-editable="false"
            data-quicksell-component-removable="false"
            data-quicksell-component-copyable="false"
            data-quicksell-component-draggable="false"
          >
            <div
              class="${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTilePrice}"
              data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTilePrice}="${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productId}"
              data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX}-element-type="${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTilePrice}"
              data-quicksell-component-type="text"
              data-quicksell-component-editable="false"
              data-quicksell-component-removable="false"
              data-quicksell-component-copyable="false"
              data-quicksell-component-draggable="false"
            >
              ${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productPrice}
            </div>
            <div
              class="${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTilePriceDiscount}"
              data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTilePriceDiscount}="${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productId}"
              data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX}-element-type="${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTilePriceDiscount}"
              data-quicksell-component-type="text"
              data-quicksell-component-editable="false"
              data-quicksell-component-removable="false"
              data-quicksell-component-copyable="false"
              data-quicksell-component-draggable="false"
            >
              ${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productDiscountedPrice}
            </div>
          </div>
          <div
            class="${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTilePriceTax}"
            data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTilePriceTax}="${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productId}"
            data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX}-element-type="${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTilePriceTax}"
            data-quicksell-component-type="text"
            data-quicksell-component-editable="false"
            data-quicksell-component-removable="false"
            data-quicksell-component-copyable="false"
            data-quicksell-component-draggable="false"
          >
            ${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productTaxPrice}
          </div>
          <div
            class="${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTilePriceMinimumOrderQuantity}"
            data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTilePriceMinimumOrderQuantity}="${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productId}"
            data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX}-element-type="${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTilePriceMinimumOrderQuantity}"
            data-quicksell-component-type="text"
            data-quicksell-component-editable="false"
            data-quicksell-component-removable="false"
            data-quicksell-component-copyable="false"
            data-quicksell-component-draggable="false"
          >
            ${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productMinimumOrderQuantity}
          </div>
          <div
            class="${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileVariantDetailsContainer}"
            data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileVariantDetailsContainer}="${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productId}"
            data-quicksell-component-type="default"
            data-quicksell-component-editable="false"
            data-quicksell-component-removable="false"
            data-quicksell-component-copyable="false"
            data-quicksell-component-draggable="false"
          >
            <div
              class="${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileVariantColors}"
              data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileVariantColors}="${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productId}"
              data-quicksell-component-type="default"
              data-quicksell-component-editable="false"
              data-quicksell-component-removable="false"
              data-quicksell-component-copyable="false"
              data-quicksell-component-draggable="false"
            >
              <div
                class="${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileVariantColor}"
                data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileVariantColor}="${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productId}"
                data-quicksell-component-type="default"
                data-quicksell-component-editable="false"
                data-quicksell-component-removable="false"
                data-quicksell-component-copyable="false"
                data-quicksell-component-draggable="false"
              >
                &nbsp;
              </div>
              <div
                class="${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileVariantColor}"
                data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileVariantColor}="${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productId}"
                data-quicksell-component-type="default"
                data-quicksell-component-editable="false"
                data-quicksell-component-removable="false"
                data-quicksell-component-copyable="false"
                data-quicksell-component-draggable="false"
              >
                &nbsp;
              </div>
              <div
                class="${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileVariantColor}"
                data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileVariantColor}="${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productId}"
                data-quicksell-component-type="default"
                data-quicksell-component-editable="false"
                data-quicksell-component-removable="false"
                data-quicksell-component-copyable="false"
                data-quicksell-component-draggable="false"
              >
                &nbsp;
              </div>
            </div>
            <div
              class="${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileVariantColorCount}"
              data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileVariantColorCount}="${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productId}"
              data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX}-element-type="${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileVariantColorCount}"
              data-quicksell-component-type="text"
              data-quicksell-component-editable="false"
              data-quicksell-component-removable="false"
              data-quicksell-component-copyable="false"
              data-quicksell-component-draggable="false"
            >
              ${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.variantColorCount}
            </div>
          </div>
          <p
            class="${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileDescription}"
            data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileDescription}="${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productId}"
            data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX}-element-type="${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileDescription}"
            data-quicksell-component-type="text"
            data-quicksell-component-editable="false"
            data-quicksell-component-removable="false"
            data-quicksell-component-copyable="false"
            data-quicksell-component-draggable="false"
          >${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productDescription}</p>
        </div>
        <div
          class="${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileAddToCartContainer} gjs-cell"
          data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileAddToCartContainer}="${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productId}"
          data-quicksell-component-type="default"
          data-quicksell-component-editable="false"
          data-quicksell-component-removable="false"
          data-quicksell-component-copyable="false"
          data-quicksell-component-draggable="false"
        >
          <div
            class="${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileAddToCartLoader}"
            data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileAddToCartLoader}="${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productId}"
            data-quicksell-component-type="text"
            data-quicksell-component-editable="false"
            data-quicksell-component-removable="false"
            data-quicksell-component-copyable="false"
            data-quicksell-component-draggable="false"
            data-quicksell-component-layerable="false"
          >
            <svg
              class="${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileAddToCartLoaderSvg}"
              data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileAddToCartLoaderSvg}="${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productId}"
              viewBox="0 0 38 38"
              height="24"
              width="24"
              stroke="${WHITE_COLOR}"
              data-quicksell-component-custom-name="${capitalizeFirstLetter(t('svg'))}"
              data-quicksell-component-type="svg"
              data-quicksell-component-editable="false"
              data-quicksell-component-removable="false"
              data-quicksell-component-copyable="false"
              data-quicksell-component-draggable="false"
              data-quicksell-component-layerable="false"
            >
              <g data-quicksell-component-type="svg-in" fill="none" fill-rule="evenodd">
                <g data-quicksell-component-type="svg-in" transform="translate(1 1)" stroke-width="2">
                  <circle data-quicksell-component-type="svg-in" stroke-opacity=".5" cx="18" cy="18" r="18"/>
                  <path data-quicksell-component-type="svg-in" d="M36 18c0-9.94-8.06-18-18-18">
                  </path>
                </g>
              </g>
            </svg>
          </div>
          <div
            class="${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileAddToCart}"
            data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileAddToCart}="${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productId}"
            data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX}-element-type="${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileAddToCart}"
            data-quicksell-component-type="text"
            data-quicksell-component-editable="false"
            data-quicksell-component-removable="false"
            data-quicksell-component-copyable="false"
            data-quicksell-component-draggable="false"
          >
            ${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.addToCartButtonText}
          </div>
          <div
            class="${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileInquiryContainer} gjs-cell"
            data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileInquiryContainer}="${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productId}"
            data-quicksell-component-type="default"
            data-quicksell-component-editable="false"
            data-quicksell-component-removable="false"
            data-quicksell-component-copyable="false"
            data-quicksell-component-draggable="false"
            data-quicksell-component-selectable="false"
            data-quicksell-component-layerable="false"
          >
            <div
              class="${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileInquiryMinus}"
              data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileInquiryMinus}="${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productId}"
              data-quicksell-component-type="default"
              data-quicksell-component-editable="false"
              data-quicksell-component-removable="false"
              data-quicksell-component-copyable="false"
              data-quicksell-component-draggable="false"
              data-quicksell-component-selectable="false"
              data-quicksell-component-layerable="false"
            >
              <div
                class="${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileInquiryMinusText}"
                data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileInquiryMinusText}="${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productId}"
                data-quicksell-component-type="text"
                data-quicksell-component-editable="false"
                data-quicksell-component-removable="false"
                data-quicksell-component-copyable="false"
                data-quicksell-component-draggable="false"
                data-quicksell-component-selectable="false"
                data-quicksell-component-layerable="false"
              >
                -
              </div>
            </div>
            <div
              class="${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileInquiryCount}"
              data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileInquiryCount}="${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productId}"
              data-quicksell-component-type="default"
              data-quicksell-component-editable="false"
              data-quicksell-component-removable="false"
              data-quicksell-component-copyable="false"
              data-quicksell-component-draggable="false"
              data-quicksell-component-selectable="false"
              data-quicksell-component-layerable="false"
            >
              <div
                class="${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileInquiryCountText}"
                data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileInquiryCountText}="${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productId}"
                data-quicksell-component-type="text"
                data-quicksell-component-editable="false"
                data-quicksell-component-removable="false"
                data-quicksell-component-copyable="false"
                data-quicksell-component-draggable="false"
                data-quicksell-component-selectable="false"
                data-quicksell-component-layerable="false"
              >
                1
              </div>
            </div>
            <div
              class="${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileInquiryPlus}"
              data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileInquiryPlus}="${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productId}"
              data-quicksell-component-type="default"
              data-quicksell-component-editable="false"
              data-quicksell-component-removable="false"
              data-quicksell-component-copyable="false"
              data-quicksell-component-draggable="false"
              data-quicksell-component-selectable="false"
              data-quicksell-component-layerable="false"
            >
              <div
                class="${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileInquiryPlusText}"
                data-${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileInquiryPlusText}="${PRODUCT_MAGAZINE_TILE_VARIABLES_MAP.productId}"
                data-quicksell-component-type="text"
                data-quicksell-component-editable="false"
                data-quicksell-component-removable="false"
                data-quicksell-component-copyable="false"
                data-quicksell-component-draggable="false"
                data-quicksell-component-selectable="false"
                data-quicksell-component-layerable="false"
              >
                +
              </div>
            </div>
          </div>
        </div>
      </div>
    `;
  },
  get STYLE() {
    return `
      html,
      body {
        height: 100%;
        min-height: 100%;
        max-height: 100%;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        padding: 0px 0px 0px 0px;
        margin: 0px 0px 0px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }

      .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileContainer} {
        height: 100%;
        min-height: 0px;
        max-height: 100%;
        width: 100%;
        min-width: 0px;
        max-width: 100%;
        margin: 0px 0px 0px 0px;
        padding: 0px 0px 0px 0px;
        background-repeat: repeat;
        background-position: left top;
        background-attachment: scroll;
        background-size: auto;
        background-color: ${WHITE_COLOR};
        border-width: 1px 1px 1px 1px;
        border-style: solid;
        border-color: ${BORDER_COLOR};
        border-radius: 5px 5px 5px 5px;
        box-shadow: 0px 0px 10px 1px rgba(26, 32, 39, 0.1);
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        flex-wrap: nowrap;
        flex-direction: column;
        overflow-x: hidden;
        overflow-y: hidden;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        text-decoration: none;
      }

      .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileContainer}:hover .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileImageContainer} .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileImage} {
        transform: scale(1.05);
      }

      .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileImageContainer} {
        height: auto;
        min-height: 75px;
        max-height: 100%;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        padding: 0px 0px 0px 0px;
        margin: 0px 0px 0px 0px;
        border-radius: 4px 4px 0px 0px;
        top: 0px;
        right: 0px;
        left: 0px;
        bottom: 0px;
        border-width: 0px 0px 0px 0px;
        border-style: solid;
        border-color: ${BORDER_COLOR};
        color: ${TEXT_COLOR};
        cursor: pointer;
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: auto;
        position: relative;
        overflow-x: hidden;
        overflow-y: hidden;
        transition: all 0.2s ease-in-out;
      }

      .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileOutOfStockBadge} {
        height: auto;
        min-height: auto;
        max-height: auto;
        width: auto;
        min-width: auto;
        max-width: 100px;
        background-repeat: repeat;
        background-position: left top;
        background-attachment: scroll;
        background-size: auto;
        background-color: rgba(255, 0, 0, 0.8);
        color: ${WHITE_COLOR};
        border-radius: 5px 5px 5px 5px;
        font-size: 14px;
        font-weight: 400;
        padding: 3px 8px 3px 8px;
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 3;
        overflow-x: hidden;
        overflow-y: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-decoration: none;
      }

      .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileOutOfStockBadge}:empty {
        display: none;
      }

      .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileImage} {
        height: 100%;
        min-height: 100%;
        max-height: 400px;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        color: ${TEXT_COLOR};
        object-fit: contain;
        border-radius: 4px 4px 0px 0px;
        display: inline-block;
        cursor: pointer;
        overflow-x: hidden;
        overflow-y: hidden;
        vertical-align: middle;
        transition: all 0.2s ease-in-out;
        text-decoration: none;
      }

      .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileImagePrevious} {
        height: 100%;
        min-height: 100%;
        max-height: 100%;
        width: 36px;
        min-width: 36px;
        max-width: 36px;
        color: ${TEXT_COLOR};
        object-fit: contain;
        margin: 0px 0px 0px 0px;
        padding: 0px 9px 0px 9px;
        border-radius: 0px 0px 0px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: absolute;
        top: 0px;
        left: 0px;
        bottom: 0px;
        z-index: 3;
        overflow-x: hidden;
        overflow-y: hidden;
        vertical-align: middle;
        transition: all 0.2s ease-in-out;
        text-decoration: none;
      }

      .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileImageNext} {
        height: 100%;
        min-height: 100%;
        max-height: 100%;
        width: 36px;
        min-width: 36px;
        max-width: 36px;
        color: ${TEXT_COLOR};
        object-fit: contain;
        margin: 0px 0px 0px 0px;
        padding: 0px 9px 0px 9px;
        border-radius: 0px 0px 0px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        z-index: 3;
        overflow-x: hidden;
        overflow-y: hidden;
        vertical-align: middle;
        transition: all 0.2s ease-in-out;
        text-decoration: none;
      }

      .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileDetailsContainer} {
        height: auto;
        min-height: auto;
        max-height: 100%;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        padding: 10px 15px 10px 15px;
        margin: 0px 0px 0px 0px;
        border-radius: 0px 0px 0px 0px;
        border-width: 0px 0px 0px 0px;
        border-style: solid;
        border-color: ${BORDER_COLOR};
        color: ${TEXT_COLOR};
        font-weight: 400;
        font-size: 14px;
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: auto;
        cursor: pointer;
        background-repeat: repeat;
        background-position: left top;
        background-attachment: scroll;
        background-size: auto;
        background-color: ${WHITE_COLOR};
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        transition: all 0.2s ease-in-out;
        text-decoration: none;
      }

      .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileDetailsContainer}:empty  {
        display: none;
      }

      .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileThumbnailsContainer} {
        height: 75px;
        min-height: 75px;
        max-height: 75px;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        padding: 0px 0px 0px 0px;
        margin: 0px 0px 0px 0px;
        border-radius: 0px 0px 0px 0px;
        border-width: 0px 0px 0px 0px;
        border-style: solid;
        border-color: ${BORDER_COLOR};
        color: ${TEXT_COLOR};
        font-weight: 400;
        font-size: 14px;
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: auto;
        cursor: pointer;
        background-repeat: repeat;
        background-position: left top;
        background-attachment: scroll;
        background-size: auto;
        background-color: ${WHITE_COLOR};
        overflow-x: auto;
        overflow-y: hidden;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: nowrap;
        row-gap: 12px;
        column-gap: 12px;
        transition: all 0.2s ease-in-out;
        text-decoration: none;
      }

      .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileThumbnailsContainer}:empty {
        display: none;
      }

      .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileThumbnailsContainer}::-webkit-scrollbar {
        width: 0px;
        height: 0px;
      }

      .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileThumbnailContainer} {
        height: 60px;
        min-height: 60px;
        max-height: 60px;
        width: 60px;
        min-width: 60px;
        max-width: 60px;
        color: ${TEXT_COLOR};
        border-radius: 50% 50% 50% 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        overflow-x: hidden;
        overflow-y: hidden;
      }

      .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileThumbnailContainer}:empty {
        display: none;
      }

      .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileThumbnail} {
        height: 100%;
        min-height: 100%;
        max-height: 100%;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        color: ${TEXT_COLOR};
        object-fit: cover;
        border-radius: 50% 50% 50% 50%;
        display: inline-block;
        cursor: pointer;
        overflow-x: hidden;
        overflow-y: hidden;
        vertical-align: middle;
        transition: all 0.2s ease-in-out;
        text-decoration: none;
      }

      .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileThumbnail}.${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileThumbnailSelected} {
        height: calc(100% - 16px);
        min-height: calc(100% - 16px);
        max-height: calc(100% - 16px);
        width: calc(100% - 16px);
        min-width: calc(100% - 16px);
        max-width: calc(100% - 16px);
        margin-left: 8px;
        margin-right: 8px;
        box-shadow: rgb(255, 255, 255) 0px 0px 0px 4px, ${ACCENT_COLOR} 0px 0px 0px 8px;
      }

      .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileName} {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        margin: 0px 0px 0px 0px;
        color: ${TEXT_COLOR};
        font-size: 20px;
        font-weight: 600;
        text-align: left;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        display: -webkit-box;
        line-height: 1.2;
        overflow-x: hidden;
        overflow-y: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        text-decoration: none;
        word-wrap: break-word;
        word-break: break-word;
      }

      .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileName}:empty {
        display: none;
      }

      .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileSku} {
        height: auto;
        min-height: auto;
        max-height: auto;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        margin: 2.5px 0px 0px 0px;
        padding: 0px 0px 0px 0px;
        color: grey;
        font-size: 16px;
        font-weight: 400;
        text-align: left;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        display: -webkit-box;
        line-height: 1.2;
        overflow-x: hidden;
        overflow-y: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        text-decoration: none;
        word-wrap: break-word;
        word-break: break-word;
      }

      .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileSku}:empty {
        display: none;
      }

      .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileDiscountBadge} {
        height: auto;
        min-height: auto;
        max-height: auto;
        width: auto;
        min-width: auto;
        max-width: 100%;
        margin: 7.5px 0px 5px 0px;
        padding: 0px 0px 0px 0px;
        background-repeat: repeat;
        background-position: left top;
        background-attachment: scroll;
        background-size: auto;
        background-color: ${ACCENT_COLOR};
        color: ${WHITE_COLOR};
        border-radius: 5px 5px 5px 5px;
        font-size: 14px;
        font-weight: 400;
        padding: 3px 8px 3px 8px;
        overflow-x: hidden;
        overflow-y: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-decoration: none;
      }

      .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileDiscountBadge}:empty {
        display: none;
      }

      .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTilePriceContainer} {
        height: auto;
        min-height: auto;
        max-height: auto;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        overflow-x: hidden;
        overflow-y: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-decoration: none;
        word-wrap: break-word;
        word-break: break-word;
      }

      .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTilePriceContainer}:empty {
        display: none;
      }

      .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTilePrice} {
        height: auto;
        min-height: auto;
        max-height: auto;
        width: auto;
        min-width: auto;
        max-width: 100%;
        margin: 2.5px 0px 0px 0px;
        padding: 0px 0px 0px 0px;
        color: ${TEXT_COLOR};
        font-size: 20px;
        font-weight: 600;
        text-align: left;
        overflow-x: hidden;
        overflow-y: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-decoration: none;
      }

      .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTilePrice}:empty {
        display: none;
      }

      .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTilePriceDiscount} {
        height: auto;
        min-height: auto;
        max-height: auto;
        width: auto;
        min-width: auto;
        max-width: 100%;
        color: ${TEXT_COLOR};
        font-size: 18px;
        font-weight: 300;
        text-align: left;
        margin: 2.5px 0px 0px 5px;
        padding: 0px 0px 0px 0px;
        overflow-x: hidden;
        overflow-y: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-decoration: line-through;
      }

      .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTilePriceDiscount}:empty {
        display: none;
      }

      .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTilePriceTax} {
        height: auto;
        min-height: auto;
        max-height: auto;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        margin: 2.5px 0px 0px 0px;
        padding: 0px 0px 0px 0px;
        color: ${TEXT_COLOR};
        font-size: 16px;
        font-weight: 400;
        text-align: left;
        overflow-x: hidden;
        overflow-y: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-decoration: none;
        word-wrap: break-word;
        word-break: break-word;
      }

      .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTilePriceTax}:empty {
        display: none;
      }

      .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTilePriceMinimumOrderQuantity} {
        height: auto;
        min-height: auto;
        max-height: auto;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        margin: 2.5px 0px 0px 0px;
        padding: 0px 0px 0px 0px;
        color: ${TEXT_COLOR};
        font-size: 16px;
        font-weight: 600;
        text-align: left;
        overflow-x: hidden;
        overflow-y: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-decoration: none;
        word-wrap: break-word;
        word-break: break-word;
      }

      .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTilePriceMinimumOrderQuantity}:empty {
        display: none;
      }

      .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileVariantDetailsContainer} {
        height: auto;
        min-height: auto;
        max-height: auto;
        width: auto;
        min-width: auto;
        max-width: 100%;
        margin: 5px 0px 0px 0px;
        padding: 0px 0px 0px 0px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        overflow-x: hidden;
        overflow-y: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-decoration: none;
      }

      .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileVariantDetailsContainer}:empty  {
        display: none;
      }

      .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileVariantColors} {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 0px 10px 0px 0px;
        text-decoration: none;
      }

      .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileVariantColors}:empty  {
        display: none;
      }

      .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileVariantColor} {
        height: 16px;
        min-height: 16px;
        max-height: 16px;
        width: 16px;
        min-width: 16px;
        max-width: 16px;
        background-repeat: repeat;
        background-position: left top;
        background-attachment: scroll;
        background-size: auto;
        background-color: ${WHITE_COLOR};
        border-width: 1px 1px 1px 1px;
        border-style: solid;
        border-color: ${WHITE_COLOR};
        border-radius: 50%;
        box-shadow: 0px 0px 0px 1px rgba(26, 32, 39, 0.1);
        text-decoration: none;
      }

      .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileVariantColor}:nth-child(1) {
        margin-left: 0px;
        background-repeat: repeat;
        background-position: left top;
        background-attachment: scroll;
        background-size: auto;
        background-color: #f74246;
      }

      .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileVariantColor}:nth-child(2) {
        margin-left: -8px;
        background-repeat: repeat;
        background-position: left top;
        background-attachment: scroll;
        background-size: auto;
        background-color: #f88c49;
      }

      .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileVariantColor}:nth-child(3) {
        margin-left: -8px;
        background-repeat: repeat;
        background-position: left top;
        background-attachment: scroll;
        background-size: auto;
        background-color: #f9d34f;
      }

      .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileVariantColorCount} {
        color: ${TEXT_COLOR};
        font-size: 16px;
        font-weight: 400;
        text-align: left;
        overflow-x: hidden;
        overflow-y: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-decoration: none;
      }

      .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileVariantColorCount}:empty {
        display: none;
      }

      .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileDescription} {
        height: auto;
        min-height: auto;
        max-height: auto;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        margin: 2.5px 0px 0px 0px;
        color: ${TEXT_COLOR};
        font-size: 16px;
        font-weight: 400;
        text-align: left;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        display: -webkit-box;
        line-height: 1.2;
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: auto;
        overflow-x: hidden;
        overflow-y: hidden;
        text-overflow: ellipsis;
        white-space: pre-wrap;
        text-decoration: none;
        word-wrap: break-word;
        word-break: break-word;
      }

      .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileDescription}:empty {
        display: none;
      }
      
      .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileAddToCartContainer} {
        height: 38px;
        min-height: 38px;
        max-height: 38px;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        padding: 0px 0px 0px 0px;
        margin: 0px 0px 0px 0px;
        border-radius: 0px 0px 4px 4px;
        border-width: 0px 0px 0px 0px;
        border-style: solid;
        border-color: ${BORDER_COLOR};
        outline-width: 0px;
        outline-style: solid;
        outline-color: ${BORDER_COLOR};
        box-shadow: 0px 0px 0px 0px ${BLACK_COLOR};
        background-repeat: repeat;
        background-position: left top;
        background-attachment: scroll;
        background-size: auto;
        background-color: ${ACCENT_COLOR};
        display: flex;
        justify-content: center;
        align-items: center;
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: auto;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
      }

      .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileAddToCartLoader} {
        height: 38px;
        min-height: auto;
        max-height: 100%;
        width: 38px;
        min-width: auto;
        max-width: 100%;
        aspect-ratio: 1 / 1;
        display: none;
        align-items: center;
        justify-content: center;
      }

      .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileAddToCartLoaderSvg} {
        height: 100%;
        min-height: 100%;
        max-height: 100%;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        padding: 0px 10px 0px 10px;
        margin: 0px 0px 0px 0px;
        animation: rotating 1s linear infinite;
      }

      .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileAddToCart} {
        height: 100%;
        min-height: 100%;
        max-height: 100%;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        padding: 0px 10px 0px 10px;
        margin: 0px 0px 0px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${WHITE_COLOR};
        text-align: center;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 16px;
        overflow-x: hidden;
        overflow-y: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-decoration: none;
      }

      .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileAddToCart}:empty {
        display: none;
      }

      .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileInquiryContainer} {
        height: 100%;
        min-height: 100%;
        max-height: 100%;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        padding: 0px 0px 0px 0px;
        margin: 0px 0px 0px 0px;
        border-radius: 0px 0px 4px 4px;
        border-width: 1px 0px 0px 0px;
        border-style: solid;
        border-color: ${BORDER_COLOR};
        display: none;
        justify-content: center;
        align-items: center;
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: auto;
        transition: all 0.2s ease-in-out;
        text-align: center;
        text-transform: uppercase;
        font-weight: 400;
        font-size: 16px;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
      }

      .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileInquiryMinus} {
        height: 100%;
        min-height: 100%;
        max-height: 100%;
        width: auto;
        min-width: auto;
        max-width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-radius: 0px 0px 0px 4px;
        flex: 3 1 auto;
        overflow-x: hidden;
        overflow-y: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        background-repeat: repeat;
        background-position: left top;
        background-attachment: scroll;
        background-size: auto;
        background-color: rgb(234, 239, 247);
        border-right-width: 1px;
        border-right-style: solid;
        border-right-color: ${BORDER_COLOR};
        transition: all 0.2s ease-in-out;
      }

      .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileInquiryMinusText} {
        height: 100%;
        min-height: 100%;
        max-height: 100%;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        color: ${BLACK_COLOR};
        text-align: center;
        text-transform: uppercase;
        font-weight: 400;
        font-size: 24px;
        line-height: 34px;
        text-decoration: none;
      }

      .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileInquiryCount} {
        height: 100%;
        min-height: 100%;
        max-height: 100%;
        width: auto;
        min-width: auto;
        max-width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 3 1 auto;
        overflow-x: hidden;
        overflow-y: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        background-repeat: repeat;
        background-position: left top;
        background-attachment: scroll;
        background-size: auto;
        background-color: ${WHITE_COLOR};
        transition: all 0.2s ease-in-out;
      }

      .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileInquiryCountText} {
        height: 100%;
        min-height: 100%;
        max-height: 100%;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        color: ${BLACK_COLOR};
        text-align: center;
        text-transform: uppercase;
        font-weight: 400;
        font-size: 16px;
        line-height: 38px;
        text-decoration: none;
      }

      .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileInquiryPlus} {
        height: 100%;
        min-height: 100%;
        max-height: 100%;
        width: auto;
        min-width: auto;
        max-width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex: 3 1 auto;
        border-radius: 0px 0px 4px 0px;
        overflow-x: hidden;
        overflow-y: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        background-repeat: repeat;
        background-position: left top;
        background-attachment: scroll;
        background-size: auto;
        background-color: ${ACCENT_COLOR};
        border-left-width: 1px;
        border-left-style: solid;
        border-left-color: ${BORDER_COLOR};
        transition: all 0.2s ease-in-out;
      }

      .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileInquiryPlusText} {
        height: 100%;
        min-height: 100%;
        max-height: 100%;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        color: ${WHITE_COLOR};
        text-align: center;
        text-transform: uppercase;
        font-weight: 400;
        font-size: 24px;
        line-height: 34px;
        text-decoration: none;
      }

      @keyframes rotating {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
    `;
  }
};

export const PRODUCT_MAGAZINE_TILE_GRAPES_JS_CONFIG = {
  get CONFIG() {
    return {
      ...GRAPES_JS_DEFAULT_CONFIG.COMMON,
      container: '.custom-html-builder-editor',
      plugins: [...GRAPES_JS_PLUGINS, ...(GRAPES_JS_DEFAULT_CONFIG.COMMON.plugins || [])]
    };
  }
};

export const getProductMagazineTypeThemeStyles = (theme) => {
  const defaultTheme = (!!theme && Object.keys(theme).length > 0) ? theme : {};
  const defaultTextColor = defaultTheme.pageTextColor || defaultTheme.textColor || TEXT_COLOR;
  const priceTextColor = defaultTheme.priceTextColor || ACCENT_COLOR;
  const tileTextColor = (defaultTheme.homeGrid || {}).tileTextColor || TEXT_COLOR;
  const tileBackgroundColor = (defaultTheme.homeGrid || {}).tileBackgroundColor || WHITE_COLOR;
  const defaultBorderColor = (defaultTheme.homepage || {}).productTileBorderColorCode || BORDER_COLOR;
  const discountBarBackgroundColor = defaultTheme.discountBarBackgroundColor || WHITE_COLOR;
  const tileDiscountTextColor = (defaultTheme.homeGrid || {}).tileDiscountTextColor || ACCENT_COLOR;
  const addToCartButtonBackgroundColor = (defaultTheme.addToCartButton || {}).backgroundColor || ACCENT_COLOR;
  const addToCartButtonTextColor = (defaultTheme.addToCartButton || {}).textColor || WHITE_COLOR;
  return `
    .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileContainer} {
      color: ${defaultTextColor};
      border-color: ${defaultBorderColor};
      background-color: ${tileBackgroundColor};
    }
    .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileInquiryContainer} {
      border-color: ${defaultBorderColor};
    }
    .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileInquiryMinus} {
      border-right-color: ${defaultBorderColor};
    }
    .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileInquiryPlus} {
      border-left-color: ${defaultBorderColor};
    }
    .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileImageContainer} {
      color: ${defaultTextColor};
      background-color: ${tileBackgroundColor};
    }
    .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileImage} {
      color: ${defaultTextColor};
      background-color: ${tileBackgroundColor};
    }
    .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileDiscountBadge} {
      background-color: ${discountBarBackgroundColor};
      color: ${tileDiscountTextColor};
    }
    .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileDetailsContainer} {
      color: ${tileTextColor};
      background-color: ${tileBackgroundColor};
    }
    .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileThumbnailsContainer} {
      color: ${tileTextColor};
      background-color: ${tileBackgroundColor};
    }
    .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileThumbnailContainer} {
      color: ${defaultTextColor};
      background-color: ${tileBackgroundColor};
    }
    .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileThumbnail}.${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileThumbnailSelected} {
      box-shadow: rgb(255, 255, 255) 0px 0px 0px 4px, ${addToCartButtonBackgroundColor} 0px 0px 0px 8px;
    }
    .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileThumbnail} {
      color: ${tileTextColor};
      background-color: ${tileBackgroundColor};
    }
    .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileName} {
      color: ${tileTextColor};
    }
    .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileDescription} {
      color: ${tileTextColor};
    }
    .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTilePrice} {
      color: ${priceTextColor};
    }
    .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTilePriceDiscount} {
      color: ${priceTextColor};
    }
    .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTilePriceTax} {
      color: ${priceTextColor};
    }
    .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTilePriceMinimumOrderQuantity} {
      color: ${priceTextColor};
    }
    .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileVariantColorCount} {
      color: ${tileTextColor};
    }
    .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileAddToCartLoaderSvg} {
      stroke: ${addToCartButtonTextColor};
    }
    .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileAddToCartContainer} {
      background-color: ${addToCartButtonBackgroundColor};
    }
    .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileAddToCart} {
      color: ${addToCartButtonTextColor};
    }
    .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileInquiryMinusText} {
      color: ${BLACK_COLOR};
    }
    .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileInquiryCountText} {
      color: ${BLACK_COLOR};
    }
    .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileInquiryPlus} {
      background-color: ${addToCartButtonBackgroundColor};
    }
    .${PRODUCT_MAGAZINE_TILE_CLASSNAMES_MAP.productMagazineTileInquiryPlusText} {
      color: ${addToCartButtonTextColor};
    }
  `;
};

export const getProductMagazineTileBlockConfig = (productMagazineTileBlockContent) => {
  const { t } = getI18N();
  return {
    id: PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX,
    type: PRODUCT_MAGAZINE_TILE_CLASSNAMES_PREFIX,
    label: t('product_magazine_tile'),
    activate: true,
    media: `<img src="${ProductMagazineTileIcon}" height="52" width="52" style="height: 52px; width: 52px; object-fit: cover;" />`,
    category: t('quicksell'),
    copyable: productMagazineTileBlockContent.copyable,
    'custom-name': productMagazineTileBlockContent['custom-name'],
    draggable: productMagazineTileBlockContent.draggable,
    editable: productMagazineTileBlockContent.editable,
    removable: productMagazineTileBlockContent.removable,
    tagName: productMagazineTileBlockContent.tagName,
    attributes: productMagazineTileBlockContent.attributes,
    content: {
      classes: productMagazineTileBlockContent.classes,
      components: productMagazineTileBlockContent.components
    }
  };
};

export const getProductMagazineTileBlockComponent = (productMagazineTileBlockContent) => ({
  model: {
    defaults: {
      copyable: productMagazineTileBlockContent.copyable,
      'custom-name': productMagazineTileBlockContent['custom-name'],
      draggable: productMagazineTileBlockContent.draggable,
      editable: productMagazineTileBlockContent.editable,
      removable: productMagazineTileBlockContent.removable,
      tagName: productMagazineTileBlockContent.tagName,
      attributes: productMagazineTileBlockContent.attributes
    }
  }
});
