import React, { useCallback, useState, useEffect } from 'react';

import './styles.scss';
import eventbus from 'eventing-bus';
import { bulkSaveDescription } from 'qs-data-manager/ProductDetails';
import { SAVE_BUTTON_META } from 'qs-helpers/Products/constants';
import { getI18N } from 'qs-services/i18N';

export default ({ description, activeProductIds, activeProductId, isBulkEditing }) => {
  const [descriptionState, setDescriptionSate] = useState({
    modifiedDescription: description || '',
    showSaveButton: false
  });

  const { t } = getI18N();

  useEffect(() => {
    setDescriptionSate({
      modifiedDescription: description || '',
      showSaveButton: false
    });
  }, [description, activeProductId]);

  useEffect(() => {
    if (isBulkEditing) {
      setDescriptionSate(prevState => ({
        ...prevState,
        modifiedDescription: '',
        showSaveButton: false
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [(activeProductIds || []).length, isBulkEditing]);

  const changeDescription = useCallback(
    e => {
      e.stopPropagation();
      const value = e.target.value;
      const hasChanged = (description || '') !== value;

      const showSaveButton = isBulkEditing && hasChanged;

      setDescriptionSate(prevState => ({
        ...prevState,
        modifiedDescription: value,
        showSaveButton
      }));

      if (!isBulkEditing) {
        eventbus.publish(SAVE_BUTTON_META.eventbusKey, {
          id: SAVE_BUTTON_META.PRODUCT_DESCRIPTION.id,
          hasChanged,
          data: value,
          eventType: SAVE_BUTTON_META.eventType.PRODUCT_META.id
        });
      }
    },
    [description, isBulkEditing]
  );

  const onSaveClick = useCallback(() => {
    bulkSaveDescription({
      productIds: activeProductIds,
      description: descriptionState.modifiedDescription
    }).then(() => {
      setDescriptionSate(prevState => ({
        ...prevState,
        showSaveButton: false
      }));
    });
  }, [activeProductIds, descriptionState.modifiedDescription]);

  return (
    <div id={'ProductDescription'}>
      <div className={'rowContainer'}>
        <div className={'rowHeading'}>{t('description')}</div>
        <div className={'textAreaContainer'}>
          <textarea
            rows="5"
            cols="20"
            type="text"
            onChange={changeDescription}
            value={descriptionState.modifiedDescription}
            className={`description ${descriptionState.showSaveButton ? 'restrictWidth' : ''}`}
            placeholder={t('type_supplementary_information_such_as_size_color_weight')}
          />
          {!!descriptionState.showSaveButton ? (
            <div className={'saveButton'} onClick={onSaveClick}>
              {t('save')}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
