import Listener from 'qs-helpers/Listener';
import { generalDb } from 'qs-config/FirebaseConfig';

const firebaseListener = new Listener(generalDb);
let LISTENERS_TO_NOTIFY = [];
let appOffline = false;
const END_POINT = {
  CONNECTED_INFO: () => `.info/connected`
};

export const isAppOffline = () => appOffline;

export const attachConnectivityListener = () => {
  const key = END_POINT.CONNECTED_INFO();
  firebaseListener.add({ key, type: 'value' }, snapshot => {
    const value = snapshot.val();
    appOffline = !value;

    LISTENERS_TO_NOTIFY.forEach(listener => {
      listener(appOffline);
    });
  });
};

export const removeConnectivityListener = () => {
  const key = END_POINT.CONNECTED_INFO();
  firebaseListener.remove({ key, type: 'value' });
};

export const attachConnectivityChangeListener = listener => {
  LISTENERS_TO_NOTIFY.push(listener);
};

export const removeConnectivityChangeListener = listener => {
  LISTENERS_TO_NOTIFY = LISTENERS_TO_NOTIFY.filter(savedListener => !(savedListener === listener));
};
