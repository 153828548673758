export const TOGGLE_SHOW_IMAGES_IN_PDF = 'TOGGLE_SHOW_IMAGES_IN_PDF';
export const TOGGLE_REMOVE_CUSTOMER_DETAILS_FROM_PDF = 'TOGGLE_REMOVE_CUSTOMER_DETAILS_FROM_PDF';
export const TOGGLE_SHOW_SKU_BAR_CODE = 'TOGGLE_SHOW_SKU_BAR_CODE';

export const pdfOrderSettingsInit = pdfSettings => {
  const { showImagesInPdf, removeCustomerDetailsFromPdf, showSkuBarcode } = pdfSettings || {};
  return {
    showImagesInPdf: typeof showImagesInPdf === 'boolean' ? showImagesInPdf : null,
    removeCustomerDetailsFromPdf:
      typeof removeCustomerDetailsFromPdf === 'boolean' ? removeCustomerDetailsFromPdf : null,
    showSkuBarcode: showSkuBarcode
  };
};

export const pdfOrderSettingsReducer = (state, action) => {
  switch (action.type) {
    case TOGGLE_SHOW_IMAGES_IN_PDF:
      return {
        ...state,
        showImagesInPdf: !state.showImagesInPdf
      };
    case TOGGLE_REMOVE_CUSTOMER_DETAILS_FROM_PDF:
      return {
        ...state,
        removeCustomerDetailsFromPdf: !state.removeCustomerDetailsFromPdf
      };
    case TOGGLE_SHOW_SKU_BAR_CODE:
      return {
        ...state,
        showSkuBarcode: !state.showSkuBarcode
      };

    default:
      return state;
  }
};
