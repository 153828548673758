import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    height: 'calc(100% - 2 * 20px)',
    width: 'calc(100% - 2 * 20px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    rowGap: '20px',
    padding: '20px'
  },
  titleContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    columnGap: '20px',
    flex: '0 1 auto'
  },
  title: {
    fontWeight: 700,
    color: '#FFFFFF',
    fontSize: 24,
    margin: 0,
    flex: '0 1 auto'
  },
  contentContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    rowGap: '20px',
    flex: '1 0 auto'
  },
  description: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    rowGap: '10px'
  },
  defaultTax: {
    fontWeight: 600,
    color: '#56D397',
    fontSize: 16,
    margin: 0
  },
  info: {
    fontWeight: 600,
    color: '#777E8F',
    fontSize: 14,
    margin: 0
  },
  removeTaxContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    columnGap: '20px',
    width: '100%'
  },
  taxText: {
    fontWeight: 600,
    color: '#FFFFFF',
    fontSize: 18,
    flex: '0 1 auto'
  },
  removeDefault: {
    fontWeight: 600,
    color: '#F77171',
    fontSize: 14,
    textDecoration: 'underline',
    textTransform: 'none',
    flex: '1 0 auto',
    width: 'auto',
    maxWidth: 'max-content'
  },
  noTaxContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    rowGap: '20px',
    flex: '1 0 auto'
  },
  noTaxIcon: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  addTaxButton: {
    fontSize: 19,
    fontWeight: 500,
    width: 156,
    height: 42
  },
  listText: {
    fontSize: 18,
    fontWeight: 500,
    color: '#FFFFFF',
    flex: '0 1 auto'
  },
  defaultButton: {
    fontSize: 14,
    fontWeight: 600
  },
  deleteButton: {
    padding: '6px',
    borderRadius: '50%'
  },
  listActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    columnGap: '10px',
    flex: '1 0 auto'
  },
  newTaxBtn: {
    fontSize: 16,
    fontWeight: 500,
    textTransform: 'none',
    width: 'auto',
    maxWidth: 'max-content',
    flex: '1 0 auto'
  },
  list: {
    width: '100%',
    padding: '0px'
  },
  listHeading: {
    padding: '0px',
    width: '100%'
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    columnGap: '20px',
    borderBottom: '1px solid #3D4352',
    padding: '15px 0px',
    width: '100%'
  },
  taxType: {
    marginBottom: '1.5rem'
  },
  taxPercentDiv: {
    display: 'flex'
  },
  taxPercentage: {
    width: 82
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%'
  }
});

export default useStyles;
