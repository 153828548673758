import eventbus from 'eventing-bus';
import { CSV_UPLOADER_EB_KEY, getActiveCatalogueId } from 'qs-data-manager/Catalogues';
import { getErrorMessage } from 'qs-helpers';
import {
  automaticallyMapColumns,
  checkForSameSkus,
  getMappingOnExcelColumns,
  getMappingOnIds,
  parseFileToCsv,
  resetSelectedDropDown,
  verifyExcel
} from 'qs-helpers/CSVUploader';
import React, { useEffect, useState } from 'react';
import { deleteCustomProductPropertiesFromCache } from 'qs-data-manager/CSVUploader/Operations';
import ExcelColumnMapper from './ExcelColumnMapper';
import ExcelMappingResult from './ExcelMappingResult';
import './styles.scss';
import VerifyingExcel from './VerifyingExcel';

const COMPONENT_NAMES = {
  EXCEL_VERIFY: 'EXCEL_VERIFY',
  COLUMN_MAPPER: 'COLUMN_MAPPER',
  RESULT_MAPPER: 'RESULT_MAPPER'
};

export default () => {
  const [csvRowCol, setCsvRowCol] = useState({
    csvData: [],
    columnNames: []
  });
  const [activeComponent, setActiveComponent] = useState('');
  const [catalogueId, setCatalogueId] = useState('');
  const [activeComponentData, setActiveComponentData] = useState({});

  const resetComponentState = () => {
    setActiveComponent('');
    setActiveComponentData({});
    setCatalogueId('');
    setCsvRowCol({ csvData: [], columnNames: [] });
    resetSelectedDropDown();
    deleteCustomProductPropertiesFromCache();
  };

  const onResultMapperBack = ({ columnNameDetailsMap }) => {
    const reverseMapping = getMappingOnExcelColumns({ data: columnNameDetailsMap });
    setActiveComponent(COMPONENT_NAMES.COLUMN_MAPPER);
    setActiveComponentData({
      columnNameDetailsMap: reverseMapping
    });
  };

  const onNext = ({ columnNameDetailsMap, excelComponentMap }) => {
    const reverseMapping = getMappingOnIds({ columnNameDetailsMap });
    const rowsHaveSameSkus = checkForSameSkus({
      componentCSVMapping: reverseMapping,
      csvData: csvRowCol.csvData,
      excelComponentMap
    });

    if (rowsHaveSameSkus) {
      setActiveComponent(COMPONENT_NAMES.EXCEL_VERIFY);
      setActiveComponentData({
        state: 'VERIFYING',
        error:
          'The Excel file you uploaded has multiple rows with the same SKU. Kindly ensure that each row in your Excel file has unique SKUs only.',
        actualError: 'multiple rows have same sku'
      });
      return;
    }

    setActiveComponent(COMPONENT_NAMES.RESULT_MAPPER);
    setActiveComponentData({
      columnNameDetailsMap: reverseMapping,
      excelComponentMap
    });
  };

  useEffect(() => {
    const removeListener = eventbus.on(CSV_UPLOADER_EB_KEY, async (uploaderState, file) => {
      if (uploaderState !== true || !file) {
        return;
      }

      setCatalogueId(getActiveCatalogueId());
      setActiveComponent(COMPONENT_NAMES.EXCEL_VERIFY);
      setActiveComponentData({
        state: 'PARSING'
      });

      let csvData;
      try {
        csvData = await parseFileToCsv(file);
      } catch (parseCsvError) {
        setActiveComponentData({
          state: 'PARSING',
          error: 'The uploaded excel can not be read',
          actualError: getErrorMessage(parseCsvError)
        });
        return;
      }

      //No CSV data found, fail the operation
      if (!Array.isArray(csvData) || csvData.length === 0) {
        setActiveComponentData({
          state: 'VERIFYING',
          error: 'This Excel does not contain any data',
          actualError: 'csvData has no length'
        });
        return;
      }

      setCsvRowCol({ csvData: csvData, columnNames: csvData[0] });
      setActiveComponentData({
        state: 'VERIFYING'
      });

      let sanitizedCsvData = csvData;

      try {
        sanitizedCsvData = await verifyExcel({ csvData });
        setCsvRowCol({ csvData: sanitizedCsvData, columnNames: sanitizedCsvData[0] });
      } catch (verifyExcelError) {
        setActiveComponentData({
          state: 'VERIFYING',
          error: verifyExcelError,
          actualError: getErrorMessage(verifyExcelError)
        });
        return;
      }

      resetSelectedDropDown();
      const columnNameDetailsMap = automaticallyMapColumns({ columnNames: sanitizedCsvData[0] });

      setActiveComponentData({
        columnNameDetailsMap
      });
      setActiveComponent(COMPONENT_NAMES.COLUMN_MAPPER);
    });

    return () => removeListener();
  }, []);

  if (activeComponent === COMPONENT_NAMES.EXCEL_VERIFY) {
    const { error, actualError, state } = activeComponentData;
    return (
      <VerifyingExcel
        verifyErrorMsg={error}
        actualError={actualError}
        status={state}
        csvRowLength={csvRowCol.csvData.length}
        csvColumnLength={csvRowCol.columnNames.length}
        onCancel={resetComponentState}
      />
    );
  }

  if (activeComponent === COMPONENT_NAMES.COLUMN_MAPPER) {
    const { columnNameDetailsMap } = activeComponentData;
    return (
      <ExcelColumnMapper
        columnNames={csvRowCol.columnNames}
        csvData={csvRowCol.csvData}
        onCancel={resetComponentState}
        onNext={onNext}
        existingColumnMap={columnNameDetailsMap}
      />
    );
  }

  if (activeComponent === COMPONENT_NAMES.RESULT_MAPPER) {
    const { columnNameDetailsMap, excelComponentMap } = activeComponentData;
    return (
      <ExcelMappingResult
        csvData={csvRowCol.csvData}
        headers={csvRowCol.columnNames}
        catalogueId={catalogueId}
        columnNameDetailsMap={columnNameDetailsMap}
        excelComponentMap={excelComponentMap}
        onCanceled={resetComponentState}
        onBack={onResultMapperBack}
      />
    );
  }

  return null;
};
