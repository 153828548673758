import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { DialogLightTheme } from 'qs-components/Common/LightThemeDialog';
import SettingsIframeComponent from 'qs-components/AccountSettings/CompanySettings/SettingsIframeComponent';
import { POST_MESSAGE_SLUGS, windowPostMessageProxy } from 'qs-helpers/WindowPostMessageProxy';
import {
  SETTINGS_COMPONENT_ID_MAP,
  SETTINGS_COMPONENT_ROUTE_MAP
} from 'qs-helpers/CompanySettings/constants';
import './index.scss';
import { getI18N } from 'qs-services/i18N';

const AddCatalogueBannerDialog = ({ open, catalogueId, onClose }) => {
  const history = useHistory();

  const onCloseHandler = useCallback(() => {
    onClose();
    if (window.location.hash.includes(catalogueId)) {
      history.goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catalogueId, onClose]);

  const hashChangeListener = useCallback(() => {
    if (!window.location.hash.includes(catalogueId)) {
      onCloseHandler();
    }
  }, [onCloseHandler, catalogueId]);

  useEffect(() => {
    const handler = {
      test: message => {
        return message?.slug === POST_MESSAGE_SLUGS.AddCatalogueBannerDialog;
      },
      handle: message => {
        if (message?.value === POST_MESSAGE_SLUGS.Hide) {
          onCloseHandler();
          return;
        }
        return;
      }
    };
    windowPostMessageProxy.addHandler(handler);
    return () => windowPostMessageProxy.removeHandler(handler);
  }, [onCloseHandler]);

  useEffect(() => {
    if (open) {
      if (!window.location.hash.includes(catalogueId)) {
        window.location.hash = '#/' + catalogueId;
      }
      window.addEventListener('hashchange', hashChangeListener);
      return () => {
        window.removeEventListener('hashchange', hashChangeListener);
      };
    }
  }, [open, catalogueId, hashChangeListener]);

  const { t } = getI18N();

  return (
    <DialogLightTheme
      open={open}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          onCloseHandler();
        }
      }}
      title={''}
      className="add-catalogue-banner-dialog"
      onClick={event => {
        event.stopPropagation();
        event.preventDefault();
      }}
    >
      <div className="add-catalogue-banner-dialog-container">
        <SettingsIframeComponent
          id={
            SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CATALOGUE_BANNER_SETTINGS].TITLE
          }
          title={t(
            SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CATALOGUE_BANNER_SETTINGS].TITLE
          )}
          route={
            SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CATALOGUE_BANNER_SETTINGS]
              .IFRAME_ROUTE
          }
          removeBeginningHash={
            SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CATALOGUE_BANNER_SETTINGS]
              .REMOVE_HASH
          }
          queryParams={[
            {
              key: 'catalogueId',
              value: catalogueId
            }
          ]}
        />
      </div>
    </DialogLightTheme>
  );
};

export default AddCatalogueBannerDialog;
