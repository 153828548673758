import React from 'react';
import ClickHandlerWithLoaderContainer from '../../../../Common/ClickHandlerWithLoader/ClickHandlerWithLoaderContainer';
import JewelleryPriceDisplayIcon from '../../../../Common/JewelleryPriceDisplayIcon';
import SettingsRow from '../../../Common/SettingsRow/SettingsRow';
import { getI18N } from 'qs-services/i18N';

export default ({ onSelected, settingComponentId, classForContainer }) => {
  const { t } = getI18N();
  return (
    <ClickHandlerWithLoaderContainer
      clickProps={{
        clickHandler: onSelected,
        clickParams: settingComponentId
      }}
      type="button"
      classForContainer={classForContainer}
    >
      <SettingsRow Icon={JewelleryPriceDisplayIcon} title={t('jewellery_price_display')}>
        <span>{t('manage_jewellery_price_display_settings')}</span>
      </SettingsRow>
    </ClickHandlerWithLoaderContainer>
  );
};
