import React from 'react';
import ClickHandlerWithLoaderContainer from '../../../../Common/ClickHandlerWithLoader/ClickHandlerWithLoaderContainer';
import SettingsRow from '../../../Common/SettingsRow/SettingsRow';
import { getI18N } from 'qs-services/i18N';

export default ({ onSelected, settingComponentId, classForContainer }) => {
  const { t } = getI18N();

  return (
    <ClickHandlerWithLoaderContainer
      clickProps={{
        clickHandler: onSelected,
        clickParams: settingComponentId
      }}
      type="button"
      classForContainer={classForContainer}
    >
      <SettingsRow
        imageSrc={'https://web.dev.quicksell.co/static/media/google_analytics_logo.svg'}
        title={t('google_analytics')}
        invert={true}
      >
        <span>{t('connect_google_analytics_to_track_people')}</span>
      </SettingsRow>
    </ClickHandlerWithLoaderContainer>
  );
};
