import { createContext } from 'react';

export const VariantDataForUpdate = createContext({
  variantData: {},
  fieldsData: {},
  setVariantData: () => {}
});

//TODO will be used when the image is shown for a variant
export const VariantCurrentImageMeta = createContext({
  variantImageMeta: {},
  setVariantImageMeta: () => {}
});
