import Listener from 'qs-helpers/Listener';
import { setCompanyMetaInCache } from 'qs-data-manager/Company';
import { setGrtPmInCache } from 'qs-data-manager';
import { registerCleanupHandler } from 'qs-helpers/ClearSavedData';

const listener = new Listener();
let LISTENERS_ATTACHED = {};
const END_POINT = {
  COMPANY_META: ({ companyId }) => `companies/${companyId}`,
  GRT_PM: ({ companyId }) => `grt-purchase-managers-companyIds/${companyId}`
};

const attachCompanyMetaListener = ({ companyId }) => {
  if (!companyId) {
    return;
  }

  attachCompanyListener({ companyId });
  attachGrtPmListener({ companyId });
};

const attachCompanyListener = ({ companyId }) => {
  // Company meta listener
  const key = END_POINT.COMPANY_META({ companyId });

  if (LISTENERS_ATTACHED[key]) {
    return;
  }

  LISTENERS_ATTACHED[key] = true;
  listener.add({ key, type: 'value' }, snapshot => {
    const value = snapshot.val();
    setCompanyMetaInCache({ data: value });
  });
};

const attachGrtPmListener = ({ companyId }) => {
  // Grt PM listener
  const key = END_POINT.GRT_PM({ companyId });

  if (LISTENERS_ATTACHED[key]) {
    return;
  }

  LISTENERS_ATTACHED[key] = true;
  listener.add({ key, type: 'value' }, snapshot => {
    const value = snapshot.val();
    setGrtPmInCache(value);
  });
};

const removeCompanyMetaListener = ({ companyId }) => {
  if (!companyId) {
    return;
  }

  const companyMetaKey = END_POINT.COMPANY_META({ companyId });
  const grtPmKey = END_POINT.GRT_PM({ companyId });

  listener.remove({ key: companyMetaKey, type: 'value' });
  listener.remove({ key: grtPmKey, type: 'value' });
};

const clearAttachedListener = () => {
  LISTENERS_ATTACHED = {};
  listener.removeAll();
};

registerCleanupHandler(clearAttachedListener);

export { attachCompanyMetaListener, removeCompanyMetaListener };
