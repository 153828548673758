import React, { useState, useCallback, useEffect } from 'react';
import eventbus from 'eventing-bus';
import { bulkSaveTitle } from 'qs-data-manager/ProductDetails';
import { SAVE_BUTTON_META } from 'qs-helpers/Products/constants';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';
import '../styles.scss';

export default ({
  value,
  isBulkEditing,
  activeProductIds,
  activeProductId,
  fieldId,
  rowTitle,
  placeholder
}) => {
  const [titleState, setTitleState] = useState({
    modifiedTitle: value || '',
    showSaveButton: false
  });

  const { t } = getI18N();

  useEffect(() => {
    setTitleState({
      modifiedTitle: value || '',
      showSaveButton: false
    });
  }, [value, isBulkEditing, activeProductId]);

  useEffect(
    () => {
      if (isBulkEditing) {
        setTitleState(prevState => ({
          ...prevState,
          modifiedTitle: '',
          showSaveButton: false
        }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [(activeProductIds || []).length, isBulkEditing]
  );

  const onTitleChanged = useCallback(
    e => {
      e.stopPropagation();
      const modifiedTitle = e.target.value;
      const hasChanged = (value || '') !== modifiedTitle;

      const showSaveButton = isBulkEditing && hasChanged;

      setTitleState(prevState => ({
        ...prevState,
        modifiedTitle,
        showSaveButton
      }));

      if (!isBulkEditing) {
        eventbus.publish(SAVE_BUTTON_META.eventbusKey, {
          id: fieldId,
          hasChanged,
          data: modifiedTitle,
          eventType: SAVE_BUTTON_META.eventType.PRODUCT_META.id
        });
      }
    },
    [value, isBulkEditing, fieldId]
  );

  const onSaveClick = useCallback(() => {
    bulkSaveTitle({ productIds: activeProductIds, title: titleState.modifiedTitle }).then(() => {
      setTitleState(prevState => ({
        ...prevState,
        showSaveButton: false
      }));
    });
  }, [activeProductIds, titleState.modifiedTitle]);

  return (
    <div id={'TitleContainer'}>
      <div className={'rowContainer'}>
        <div className={'rowHeading'}>{rowTitle}</div>
        <div className={'inputContainer'}>
          <input
            type="text"
            value={titleState.modifiedTitle}
            onChange={onTitleChanged}
            className={`input ${titleState.showSaveButton ? 'restrictWidth' : ''}`}
            placeholder={placeholder}
          />
          {!!titleState.showSaveButton ? (
            <div className={'saveButton'} onClick={onSaveClick}>
              {t('save')}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
