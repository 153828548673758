import React, { useState, useRef } from 'react';
import Modal from 'react-responsive-modal';
import { getI18N } from 'qs-services/i18N';
import './EditSocialLinks.scss';

export default ({
  onClose,
  onSubmit,
  type = 'text',
  defaultValue = '',
  title = 'Social link',
  placeholder = 'Enter social link',
  urlPrefix = 'https://'
}) => {
  const modalStyles = useRef({
    backgroundColor: '#242C36',
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    width: '400px',
    padding: '0px'
  });

  const [value, setValue] = useState(defaultValue.replace(urlPrefix, ''));
  const { t } = getI18N();
  const handleInput = function(event) {
    const input = event.target.value;
    event.target.value = urlPrefix + input.substr(urlPrefix.length);
    setValue(event.target.value || '');
  };

  const handleSave = function(event) {
    event.preventDefault();
    onSubmit(value.trim() === urlPrefix ? '' : value);
    onClose();
  };

  const updateInitialValue = function(target) {
    if (!target || target.value.includes(urlPrefix)) {
      return;
    }
    target.value = urlPrefix + value;
  };

  return (
    <Modal
      open={true}
      center={true}
      onClose={onClose}
      showCloseIcon={false}
      styles={{ modal: modalStyles.current }}
    >
      <h3 className="edit-social-link title">{title}</h3>
      <form onSubmit={handleSave}>
        <div className="edit-social-link input-container">
          <input
            className="input-box"
            type={type}
            placeholder={placeholder}
            onChange={handleInput}
            autoFocus={true}
            ref={updateInitialValue}
          />
        </div>
        <div className="edit-social-link button-container">
          <button type="reset" className="button button-reset" onClick={onClose}>
            {t('cancel')}
          </button>
          <button type="submit" className="button button-plain">
            {t('save')}
          </button>
        </div>
      </form>
    </Modal>
  );
};
