import React from 'react';
import './index.scss';

export default ({ notAllowedToUse, redirectToPayment, handleLogout }) => {
  if (!notAllowedToUse) {
    return null;
  }

  return (
    <div id="NonPlatinumUserDailog">
      <div className="content">
        <p className="title">
          QuickSell Desktop is not supported on your current plan. Please{' '}
          <span className={'pricingRedirectionForLite'} onClick={redirectToPayment}>
            upgrade your plan
          </span>{' '}
          to use QuickSell Desktop
        </p>
        <div className="buttonContainer">
          <a
            className={'contactSupportLiteDialogue'}
            href="mailto:support@quicksell.co?subject=I'm interested in using QuickSell Web"
          >
            Contact support
          </a>
          <button className={'contactSupportLiteDialogue'} onClick={handleLogout}>
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};
