import React, { useEffect, useState, useContext, useRef } from 'react';
import { DialogDarkTheme } from '../../../../../../Common/DarkThemeDialog';
import { ReactComponent as Arrow } from 'qs-assets/Media/icon_long_arrow_right.svg';
import { processCommonMetaCount } from 'qs-helpers/Variants/ResponseProcessor';
import Loader from '../../../../../../Common/Loader';
import ErrorPopup from '../../../../../../Common/ErrorPopup';
import { VARIANT_ADD_TABS } from 'qs-helpers/Variants/constants';
import { ActiveVariantId } from '../../../../context';
import { getVariantsMetaDataFromCache } from 'qs-data-manager/Variants/FetchVariants';
import { computeDataUrlFromFile } from 'qs-helpers';
import ErrorIcon from '../../../../../../Common/ErrorIcon';
import { withStyles } from '@material-ui/core';
import './styles.scss';
import { getI18N } from 'qs-services/i18N';

const ImagePopupDialog = withStyles({
  paper: {
    minWidth: 350,
    maxWidth: 500
  },
  root: {
    backgroundColor: 'rgba(0,0,0,0.8)'
  }
})(DialogDarkTheme);

export default ({ uploadedImages, onUpload, onClose }) => {
  const [relatedVariants, setRelatedVariants] = useState();
  const [metaCountState, setMetaCountState] = useState('LOADER');
  const { activeVariantId } = useContext(ActiveVariantId);
  const [pictureLoadData, setPictureLoadData] = useState({});
  const pictureProcessed = useRef({});

  const { t } = getI18N();

  useEffect(() => {
    // Create a self-executing function because use effect's funtion can not be async
    (async function() {
      try {
        const processedVariants = await processCommonMetaCount(activeVariantId);
        if (processedVariants.length > 0) {
          setRelatedVariants(processedVariants);
          setMetaCountState('SUCCESS');
          return;
        }

        onUpload();
      } catch (metaCountError) {
        setMetaCountState('ERROR');
      }
    })();
  }, [onUpload, setRelatedVariants, setMetaCountState, activeVariantId]);

  useEffect(() => {
    uploadedImages.forEach(async uploadedImageFile => {
      // src already calculated for this image
      if (typeof pictureProcessed.current[uploadedImageFile.name] !== 'undefined') {
        return;
      }

      pictureProcessed.current[uploadedImageFile.name] = true;
      try {
        const dataUrl = await computeDataUrlFromFile(uploadedImageFile);
        setPictureLoadData(prevState => ({
          ...prevState,
          [uploadedImageFile.name]: {
            src: dataUrl
          }
        }));
      } catch (computeImageError) {
        setPictureLoadData(prevState => ({
          ...prevState,
          [uploadedImageFile.name]: {
            loadStatus: 'ERROR'
          }
        }));
      }
    });
  }, [uploadedImages, setPictureLoadData]);

  if (metaCountState === 'ERROR') {
    return (
      <ErrorPopup
        title={'Retrieve related variants data'}
        showContactSupport={false}
        onClose={onClose}
      >
        {t(
          'something_went_wrong_while_fetching_those_variants_that_are_related_to_the_current_one'
        )}
      </ErrorPopup>
    );
  }

  if (metaCountState === 'LOADER') {
    return (
      <DialogDarkTheme open={true}>
        <div className="addImageVariant initialLoaderContainer">
          <Loader size={'large'} />
        </div>
      </DialogDarkTheme>
    );
  }

  const handleOptionClick = (event, id) => {
    event.preventDefault();
    onUpload(id);
  };

  const getButtonComponent = (buttonText, id) => {
    return (
      <div
        key={id || buttonText}
        className="actionButton"
        onClick={event => handleOptionClick(event, id)}
      >
        <button className="buttonPlain">{buttonText}</button>
        <Arrow className={'rightArrow'} />
      </div>
    );
  };

  const getButtonTextForCurrentVariant = () => {
    const buttonText = t('add_to_variant_type_only', {
      variantType: getVariantsMetaDataFromCache(activeVariantId).label
    });
    return getButtonComponent(buttonText);
  };

  const handleImageLoad = (pictureName, status) => {
    setPictureLoadData(prevState => {
      const prevPictureData = prevState[pictureName];
      return {
        ...prevState,
        [pictureName]: {
          ...prevPictureData,
          loadStatus: status
        }
      };
    });
  };

  const renderImages = () => {
    const imagesDom = [];
    for (const pictureName in pictureLoadData) {
      if (pictureLoadData.hasOwnProperty(pictureName)) {
        const { src, loadStatus = 'LOADER' } = pictureLoadData[pictureName];
        if (loadStatus === 'ERROR') {
          imagesDom.push(
            <div className="imageContainer imagePlaceholder" key={pictureName}>
              <ErrorIcon />
            </div>
          );
          continue;
        }

        if (!src) {
          imagesDom.push(
            <div className="imageContainer imagePlaceholder" key={pictureName}>
              <Loader size="small" />
            </div>
          );
          continue;
        }

        imagesDom.push(
          <div key={pictureName} className="imageContainer">
            {loadStatus === 'LOADER' && (
              <div className="placeholderLoader">
                <Loader size="small" />
              </div>
            )}
            <div className={`pictureContainer`} style={{ backgroundImage: `url(${src})` }} />
            <img
              className="loadStatusPicture"
              src={src}
              onLoad={() => handleImageLoad(pictureName, 'HIDE_LOADER')}
              onError={() => handleImageLoad(pictureName, 'ERROR')}
              alt="variant thumbnail"
            />
          </div>
        );
      }
    }
    return imagesDom;
  };

  return (
    <ImagePopupDialog open={true} onClose={() => onClose()}>
      <div className="addImageVariant imageViewerContainer">
        <div className="addImageViewer">{renderImages()}</div>
      </div>
      <div className="addImageVariant actionContainer">
        {getButtonTextForCurrentVariant()}
        {relatedVariants.map(relatedVariantData => {
          const { id, totalVariants, variantType, variantValue } = relatedVariantData;
          if (!variantType) {
            return null;
          }
          let variantTypeString = variantType.toLowerCase();
          if (variantType === VARIANT_ADD_TABS.SIZES) {
            variantTypeString = 'size';
          }
          if (variantType === VARIANT_ADD_TABS.COLORS) {
            variantTypeString = 'color';
          }

          return getButtonComponent(
            t('add_to_all_variant_type_count', {
              variantValue,
              variantType: variantTypeString,
              variantCount: totalVariants
            }),
            id
          );
        })}
      </div>
    </ImagePopupDialog>
  );
};
