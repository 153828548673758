import { useEffect, useRef } from 'react';

const defaultValues = [];
export default ({ values = defaultValues }) => {
  const previousValues = useRef([]);
  useEffect(() => {
    previousValues.current = values;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, values);
  return previousValues.current;
};
