import React from 'react';
import ProductTitle from '../ProductTitle';

export default ({ value, isBulkEditing, activeProductId, fieldId, rowTitle, placeholder }) => {
  if (isBulkEditing) {
    return null;
  }

  return (
    <ProductTitle
      value={value}
      isBulkEditing={false}
      activeProductId={activeProductId}
      fieldId={fieldId}
      rowTitle={rowTitle}
      placeholder={placeholder}
    />
  );
};
