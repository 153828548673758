import { useReducer, useCallback, useEffect } from 'react';
import { unsetGlobalLoader, setGlobalLoader } from 'qs-helpers/DataRefresh';

const SET_ERROR = 'SET_ERROR';
const SET_LOAD_STATE = 'SET_LOAD_STATE';
const SET_REFRESH_STATE = 'SET_REFRESH_STATE';
const RESET_LOAD_ERROR = 'RESET_LOAD_ERROR';
const RESET_OPERATION_STATUS = 'RESET_OPERATION_STATUS';

const operationStatusInit = data => ({
  error: undefined,
  loading: Object.keys(data || {}).length > 0 ? undefined : true,
  refreshing: undefined,
  uniqueOperationKey: undefined
});

const operationStatusReducer = (state, action) => {
  const { globalLoader } = action;
  switch (action.type) {
    case SET_ERROR:
      unsetGlobalLoader({
        refreshing: state.refreshing,
        uniqueLoaderKey: state.uniqueOperationKey
      });

      //Show the error only if the state was not refreshing
      let errorToSet = action.err;
      if (state.refreshing) {
        errorToSet = null;
      }

      return {
        ...state,
        error: errorToSet,
        loading: undefined,
        refreshing: undefined
      };
    case SET_LOAD_STATE:
      unsetGlobalLoader({
        refreshing: state.refreshing,
        uniqueLoaderKey: state.uniqueOperationKey
      });
      return {
        ...state,
        loading: true,
        error: undefined,
        refreshing: undefined
      };
    case SET_REFRESH_STATE:
      let uniqueOperationKey;
      if (globalLoader) {
        uniqueOperationKey = setGlobalLoader({ prevLoader: state.refreshing, currLoader: true });
      }

      if (!uniqueOperationKey) {
        uniqueOperationKey = state.uniqueOperationKey;
      }

      return {
        ...state,
        refreshing: true,
        loading: undefined,
        error: undefined,
        uniqueOperationKey
      };
    case RESET_LOAD_ERROR:
      unsetGlobalLoader({
        refreshing: state.refreshing,
        uniqueLoaderKey: state.uniqueOperationKey
      });
      return {
        ...state,
        loading: undefined,
        refreshing: undefined,
        error: undefined
      };
    case RESET_OPERATION_STATUS:
      unsetGlobalLoader({
        refreshing: state.refreshing,
        uniqueLoaderKey: state.uniqueOperationKey
      });
      return operationStatusInit(action.data);
    default:
      return state;
  }
};

export default (initialState, globalLoader = true) => {
  const [operationStatus, setOperationStatus] = useReducer(
    operationStatusReducer,
    initialState,
    operationStatusInit
  );

  const handleOperationUpdates = useCallback(({ err, loading, refreshing, data, localRefresh }) => {
    // The component wants to refresh the data, hence check the data and set the
    // loaders accordingly
    if (localRefresh) {
      setOperationStatus({ type: RESET_OPERATION_STATUS, data, globalLoader });
      return;
    }

    if (err) {
      setOperationStatus({ type: SET_ERROR, err, globalLoader });
      return;
    }

    if (loading) {
      setOperationStatus({
        type: SET_LOAD_STATE,
        globalLoader
      });
      return;
    }

    if (refreshing) {
      setOperationStatus({
        type: SET_REFRESH_STATE,
        globalLoader
      });
      return;
    }

    if (!data) {
      // No error loading or refreshing, don't update the data but reset everything else
      setOperationStatus({ type: RESET_LOAD_ERROR, globalLoader });
      return;
    }

    setOperationStatus({ type: RESET_OPERATION_STATUS, data, globalLoader });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => setOperationStatus({ type: RESET_LOAD_ERROR, globalLoader });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [operationStatus, handleOperationUpdates];
};
