import React, { useEffect } from 'react';
import eventBus from 'eventing-bus';
import HeaderWithBackAndSelect from 'qs-components/Common/HeaderWithBackAndSelect';
import { REORDER_ACTIVE, REORDER_DEACTIVE } from 'qs-helpers/Products/constants';

export default ({ goBack, headerClass } = {}) => {
  useEffect(() => {
    eventBus.publish(REORDER_ACTIVE);
    return () => eventBus.publish(REORDER_DEACTIVE);
  }, []);

  return (
    <HeaderWithBackAndSelect
      headerClass={headerClass}
      showCheckbox={false}
      goBack={goBack}
      backButtonClass="editProductHeaderStyling editProductHeaderBackButton"
    />
  );
};
