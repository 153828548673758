import React, { useCallback } from 'react';
import { Modal } from '@material-ui/core';
import ProductLibraryView from './ProductLibraryView';
import './styles.scss';

export default ({ toggleProductLibrary }) => {
  const onModalClose = useCallback(() => {
    toggleProductLibrary(false);
  }, [toggleProductLibrary]);

  return (
    <Modal open={true} onClose={onModalClose}>
      <div style={{ height: '100%', width: '100%' }}>
        <ProductLibraryView onCancel={onModalClose} />
      </div>
    </Modal>
  );
};
