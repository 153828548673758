import { nativeDB } from './DexieInit';
import CacheRequest from './DataManager/CacheRequest';
import { connector } from './DataManager/ApiAndCacheConnector';
import { rehydrateCurrentPlan, rehydrateFeatureLimit } from 'qs-data-manager/FeatureUsage';
import { CATALOGUES_LAST_FETCH_TS, rehydrateCatalogueLastFetch } from 'qs-helpers';
import {
  COMPANY_LOCAL_STORAGE_KEYS,
  rehydrateCompanyMeta,
  rehydrateUserMeta
} from 'qs-data-manager/Company';
import * as Sentry from '@sentry/browser';

// needs to be moved out from here
export const LOCAL_STORAGE_KEYS = {
  CURRENT_PLAN: 'CURRENT_PLAN',
  FEATURE_LIMIT: 'FEATURE_LIMIT'
};

const populateCatalogueListMeta = async (collectionName, apiCacheKey) => {
  try {
    const catalogueListMeta = [];
    await nativeDB[collectionName].each(meta => {
      catalogueListMeta.push(meta);
    });
    const sortedCatalogueListMeta = catalogueListMeta.sort(
      (cat1, cat2) => cat2.sortTimestamp - cat1.sortTimestamp
    );
    const cache = {
      catalogueIds: sortedCatalogueListMeta
    };
    CacheRequest.setCacheForKey(apiCacheKey, cache);
  } catch (error) {
    // Handle error
  }
};

const populateCatalogueRowMeta = async (collectionName, sharedApiCacheKey) => {
  try {
    await nativeDB[collectionName].each((value, cursor) => {
      const key = `${sharedApiCacheKey}${cursor.primaryKey}`;
      CacheRequest.setCacheForKey(key, value);
    });
  } catch (error) {
    Sentry.captureException(error);
  }
};

const populateProductListMeta = async (collectionName, sharedApiCacheKey) => {
  try {
    await nativeDB[collectionName].each((value, cursor) => {
      const key = `${sharedApiCacheKey}${cursor.primaryKey}`;
      const data = { productsList: value };
      CacheRequest.setCacheForKey(key, data);
    });
  } catch (error) {
    Sentry.captureException(error);
  }
};

const populateProductRowMeta = async (collectionName, sharedApiCacheKey) => {
  try {
    await nativeDB[collectionName].each((value, cursor) => {
      const key = `${sharedApiCacheKey}${cursor.primaryKey}`;
      CacheRequest.setCacheForKey(key, value);
    });
  } catch (error) {
    Sentry.captureException(error);
  }
};

const populateCatalogueTags = async (collectionName, sharedApiCacheKey) => {
  try {
    await nativeDB[collectionName].each((value, cursor) => {
      const key = `${sharedApiCacheKey}${cursor.primaryKey}`;
      CacheRequest.setCacheForKey(key, { tags: value });
    });
  } catch (error) {
    Sentry.captureException(error);
  }
};

// Rehydration config

const rehydrationConfig = {
  cataloguesListMeta: {
    rehydrationFunction: populateCatalogueListMeta,
    apiCacheKey: connector.CATALOGUE_LIST_META.cacheKey
  },
  catalogueRowMeta: {
    rehydrationFunction: populateCatalogueRowMeta,
    apiCacheKey: connector.CATALOGUE_ROW_META.cacheKey
  },
  productListMeta: {
    rehydrationFunction: populateProductListMeta,
    apiCacheKey: connector.PRODUCT_LIST_META.cacheKey
  },
  productRowMeta: {
    rehydrationFunction: populateProductRowMeta,
    apiCacheKey: connector.PRODUCT_ROW_META.cacheKey
  },
  catalogueTags: {
    rehydrationFunction: populateCatalogueTags,
    apiCacheKey: connector.CATALOGUE_TAGS.cacheKey
  }
};

export const rehydrationService = () => {
  try {
    rehydrateFromLocalStorage();

    Object.keys(rehydrationConfig).forEach(collectionName => {
      const rehydrationService = rehydrationConfig[collectionName].rehydrationFunction;
      const apiCacheKey = rehydrationConfig[collectionName].apiCacheKey;
      rehydrationService(collectionName, apiCacheKey);
    });
  } catch (error) {
    Sentry.captureException(error);
  }
};

const rehydrateFromLocalStorage = () => {
  let currentPlan = localStorage.getItem(LOCAL_STORAGE_KEYS.CURRENT_PLAN);
  currentPlan = JSON.parse(currentPlan);
  if (currentPlan) {
    rehydrateCurrentPlan(currentPlan);
  }

  let featureLimit = localStorage.getItem(LOCAL_STORAGE_KEYS.FEATURE_LIMIT);
  featureLimit = JSON.parse(featureLimit);

  if (featureLimit) {
    rehydrateFeatureLimit(featureLimit);
  }

  let catalogueListLastFetchDate = localStorage.getItem(CATALOGUES_LAST_FETCH_TS.localstorageKey);
  if (catalogueListLastFetchDate) {
    rehydrateCatalogueLastFetch(catalogueListLastFetchDate);
  }

  const companyMeta = localStorage.getItem(COMPANY_LOCAL_STORAGE_KEYS.COMPANY_META);
  if (companyMeta) {
    rehydrateCompanyMeta(companyMeta);
  }

  const userMeta = localStorage.getItem(COMPANY_LOCAL_STORAGE_KEYS.USER_META);
  if (userMeta) {
    rehydrateUserMeta(userMeta);
  }
};
