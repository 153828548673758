import { useEffect } from 'react';
import { setNavigationRef } from 'qs-data-manager';

export default ({ navigationRef, children }) => {
  useEffect(() => {
    setNavigationRef(navigationRef);
  }, [navigationRef]);

  return children;
};
