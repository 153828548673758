import {
  attachDomainDataListner,
  getDomainDataFromCache,
  removeDomainDataListener
} from 'qs-data-manager/Domain/DomainCheck';

export const getCachedDomainData = () => getDomainDataFromCache();

export const setupDomainDataHandler = ({ listener }) => attachDomainDataListner({ listener });

export const handleCatalogueHeadlineCleanup = ({ listener }) =>
  removeDomainDataListener({ listener });
