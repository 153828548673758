import { useEffect, useState } from 'react';

export default ({ reRenderListOnItemUpdate, defaultRowHeight, index, uniqueId }) => {
  const [rowToObserveRef, setRowRef] = useState(null);

  /*
    This effect will run whenever the container mounts, the unique id or index changes.
    This will ensure that whenver anything related to the row changes, then
    the resize observer will be attached and the list height can be resized whenever
    the height changes
  */
  useEffect(() => {
    if (!rowToObserveRef || !uniqueId || typeof reRenderListOnItemUpdate !== 'function') {
      return;
    }

    const resizeObserver = new ResizeObserver(entries => {
      if (!(Array.isArray(entries) && entries.length)) {
        return;
      }
      const firstEntry = entries[0];

      //Default row height fallback if none of the conditions match
      let rowHeight = defaultRowHeight;
      //Border box property exists, pick that otherwise fallback to the target property
      if (Array.isArray(firstEntry.borderBoxSize) && firstEntry.borderBoxSize.length) {
        rowHeight = Math.floor(firstEntry.borderBoxSize[0].blockSize);
      } else if (firstEntry.target) {
        //Target exists use it, if not then fallback to fetching the offsetHeight directly
        rowHeight = firstEntry.target.offsetHeight;
      } else if (rowToObserveRef) {
        rowHeight = rowToObserveRef.offsetHeight;
      }

      reRenderListOnItemUpdate(index, uniqueId, rowHeight);
    });

    resizeObserver.observe(rowToObserveRef);
    return () => resizeObserver.unobserve(rowToObserveRef);
  }, [reRenderListOnItemUpdate, index, uniqueId, rowToObserveRef, defaultRowHeight]);

  return [rowToObserveRef, setRowRef];
};
