import React, { useState, useEffect } from 'react';
import useMakeQuery from 'qs-hooks/useMakeQuery';
import WeightInput from '../../../Common/WeightInput';
import {
  getCachedProductWeight,
  fetchProductWeightHandler,
  handleProductWeightCleanup
} from 'qs-helpers/Products/DataQueryHelper';
import Loader from '../../../../../Common/Loader';
import eventbus from 'eventing-bus';
import { SAVE_BUTTON_META } from 'qs-helpers/Products/constants';
import { getProductMetaFromCache } from 'qs-data-manager/Products';

export default ({ activeProductId }) => {
  const [weight, setWeight] = useState('');

  const [{ loading, refreshing, error, data }] = useMakeQuery({
    changeDependancy: [activeProductId],
    actionHandler: fetchProductWeightHandler,
    cleanupHandler: handleProductWeightCleanup,
    cachedDataHandler: getCachedProductWeight
  });

  useEffect(() => {
    if (!data) {
      return;
    }

    setWeight(data.weight || '');
  }, [data]);

  const onWeightChange = enteredWeight => {
    const initialWeight = (data || {}).weight || '';

    setWeight(enteredWeight);
    eventbus.publish(SAVE_BUTTON_META.eventbusKey, {
      id: SAVE_BUTTON_META.PRODUCT_WEIGHT_FIELD.id,
      hasChanged: initialWeight !== enteredWeight,
      data: enteredWeight,
      eventType: SAVE_BUTTON_META.eventType.PRODUCT_META.id
    });
  };

  if (!activeProductId || error) {
    return null;
  }

  if (loading) {
    return <Loader size="small" />;
  }

  let isSet = false;
  const productMeta = getProductMetaFromCache(activeProductId);
  if (productMeta) {
    ({ isSet } = productMeta);
  }

  return (
    <WeightInput
      onWeightChange={onWeightChange}
      weight={weight}
      showWeightChange={false}
      disabled={refreshing}
      isSet={isSet}
    />
  );
};
