import { useReducer, useEffect } from 'react';
import {
  getVariantsMetaDataFromCache,
  attachProductVariantMetaListener,
  removeProductVariantMetaListener,
  getVariantsMetaData
} from 'qs-data-manager/Variants/FetchVariants';
import CacheListenerCallback from 'qs-helpers/CacheListenerCallback';

const SET_META_LOADING = 'SET_META_LOADING';
const SET_META_ERROR = 'SET_META_ERROR';
const SET_VARIANT_META_DATA = 'SET_VARIANT_META_DATA';
const RESET_VARIANT_META_DATA = 'RESET_VARIANT_META_DATA';

const variantsMetaInit = metaData => ({
  error: null,
  loading: Object.keys(metaData || {}).length === 0,
  refreshing: false,
  metaData
});

const variantsMetaReducer = (state, action) => {
  switch (action.type) {
    case SET_META_LOADING:
      return {
        ...state,
        loading: action.loading,
        refreshing: action.refreshing,
        error: !!action.err
      };
    case SET_META_ERROR:
      return {
        ...state,
        error: !!action.err,
        loading: action.loading
      };
    case SET_VARIANT_META_DATA:
      return {
        ...state,
        refreshing: action.refreshing,
        metaData: action.data,
        error: !!action.err,
        loading: false
      };
    case RESET_VARIANT_META_DATA:
      return variantsMetaInit(action.metaData);
    default:
      return state;
  }
};

export default variantId => {
  const [variantMetaData, setVariantMetaData] = useReducer(
    variantsMetaReducer,
    getVariantsMetaDataFromCache(variantId),
    variantsMetaInit
  );

  useEffect(() => {
    // Get the data of the variants of the active product
    setVariantMetaData({
      type: RESET_VARIANT_META_DATA,
      metaData: getVariantsMetaDataFromCache(variantId)
    });

    const variantMetaListener = (error, payload) => {
      const { err, loading, refreshing, data } = CacheListenerCallback(error, payload);

      if (err) {
        setVariantMetaData({ type: SET_META_ERROR, loading, err });
        return;
      }

      if (loading || refreshing) {
        setVariantMetaData({ type: SET_META_LOADING, loading, refreshing, err });
        return;
      }

      if (!data) {
        return;
      }

      setVariantMetaData({ type: SET_VARIANT_META_DATA, data, refreshing, err });
    };

    attachProductVariantMetaListener({
      listener: variantMetaListener,
      variantId
    });
    getVariantsMetaData({ variantIds: [variantId] });

    return () =>
      removeProductVariantMetaListener({
        variantId,
        listener: variantMetaListener
      });
  }, [variantId]);

  return variantMetaData;
};
