import React, { useState, Fragment, useRef } from 'react';
import { onLogout } from 'qs-data-manager/Authentication';
import { isImageUploadInProgress } from 'qs-helpers/ProcessUploadedImage';
import {
  failPendingImagesForThisDevice,
  logoutUserFromAllDevices
} from 'qs-helpers/ProcessUploadedImage';
import Modal from 'react-responsive-modal';
import { getI18N } from 'qs-services/i18N';
import Loader from '../../../../Common/Loader';
import './styles.scss';

export default () => {
  const [showLoader, toggleShowLoader] = useState(false);
  const modalStyles = useRef({
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '400px',
    padding: '10px'
  });
  const { t } = getI18N();

  const handleConfirmation = async () => {
    if (!isImageUploadInProgress()) {
      onLogout();
      return;
    }

    const logout = window.confirm(
      t('images_that_are_uploading_will_fail_are_you_sure_you_want_to_logout')
    );

    if (!logout) {
      return;
    }

    toggleShowLoader(true);
    await failPendingImagesForThisDevice();
    toggleShowLoader(false);
    onLogout();
  };

  const handleLogoutFromAllDevicesConfirmation = async () => {
    if (!isImageUploadInProgress()) {
      toggleShowLoader(true);
      await logoutUserFromAllDevices();
      toggleShowLoader(false);
      onLogout();
      return;
    }

    const logout = window.confirm(
      t('images_that_are_uploading_will_fail_are_you_sure_you_want_to_logout')
    );

    if (!logout) {
      return;
    }

    toggleShowLoader(true);
    await failPendingImagesForThisDevice();
    await logoutUserFromAllDevices();
    toggleShowLoader(false);
    onLogout();
  };

  return (
    <Fragment>
      <div className="logout-button-container">
        <button className="logout-button" onClick={handleConfirmation}>
          {t('logout')}
        </button>
        <button className="logout-button" onClick={handleLogoutFromAllDevicesConfirmation}>
          {t('logout_from_all_devices')}
        </button>
      </div>
      {showLoader && (
        <Modal
          open={true}
          center={true}
          onClose={null}
          closeOnOverlayClick={false}
          closeOnEsc={false}
          showCloseIcon={false}
          styles={{ modal: modalStyles.current }}
        >
          <Loader size="large" />
          <span>{t('logging_you_out')}</span>
        </Modal>
      )}
    </Fragment>
  );
};
