import React, { useCallback, useEffect, useReducer, useMemo, useRef } from 'react';
import BulkDiscount from '../../ActiveTabMeta/BasicInfo/ProductPriceDiscountAndSet/ProductDiscount/BulkEditDiscount/BulkEditDiscount';
import { ReactComponent as CloseIcon } from 'qs-assets/Media/close.svg';
import InfoFormField from '../InfoFormField';
import NumberInput from '../../../../Common/NumberInput';
import { getDiscountPercentage } from 'qs-helpers/Products/ProductPricing';
import BulkSlabDiscount from './BulkSlabDiscount';
import './styles.scss';
import { getProductIsSlabFromCache } from 'qs-data-manager/ProductDetails';
import { getActiveProductId } from 'qs-data-manager/Products';
import { getI18N } from 'qs-services/i18N';

const RESET_DISCOUNT_VALUES = 'RESET_DISCOUNT_VALUES';
const HIDE_DISCOUNT_BUTTON = 'HIDE_DISCOUNT_BUTTON';
const TOGGLE_DISCOUNT_BUTTON = 'TOGGLE_DISCOUNT_BUTTON';
const TOGGLE_APPLY_BUTTON = 'TOGGLE_APPLY_BUTTON';
const SHOW_MODAL = 'SHOW_MODAL';
const HIDE_MODAL = 'HIDE_MODAL';
const SET_SLAB_BULK_DISCOUNT = 'SET_SLAB_BULK_DISCOUNT';

const canApplyDiscount = price => {
  const priceVal = Number(price);

  return !(Number.isNaN(priceVal) || priceVal <= 0);
};

const showDiscountButton = discount => {
  //discount does not exist, show the button
  return discount === '' || discount === null || Number.isNaN(Number(discount));
};

const discountInit = ({ originalDiscount, originalPrice, discount, productId }) => {
  let shouldShowDiscountButton = showDiscountButton(originalDiscount);
  if (shouldShowDiscountButton) {
    shouldShowDiscountButton = showDiscountButton(discount);
  }
  return {
    applyDiscount: canApplyDiscount(originalPrice),
    shouldShowDiscountButton,
    modalVisible: false,
    isSlabBulkDiscountEnabled: getProductIsSlabFromCache({ productId })
  };
};

const discountReducer = (state, action) => {
  switch (action.type) {
    case HIDE_DISCOUNT_BUTTON:
      return {
        ...state,
        shouldShowDiscountButton: false
      };
    case SHOW_MODAL:
      return {
        ...state,
        modalVisible: true
      };
    case HIDE_MODAL:
      return {
        ...state,
        modalVisible: false
      };
    case RESET_DISCOUNT_VALUES:
      return {
        ...state,
        shouldShowDiscountButton: true
      };
    case TOGGLE_DISCOUNT_BUTTON: {
      let shouldShowDiscountButton = showDiscountButton(action.originalDiscount);
      if (shouldShowDiscountButton) {
        shouldShowDiscountButton = showDiscountButton(action.discount);
      }

      let applyDiscount = canApplyDiscount(action.originalPrice);
      // If discount cannot be applied then show the discount button
      if (!applyDiscount) {
        shouldShowDiscountButton = true;
      }
      return {
        ...state,
        shouldShowDiscountButton,
        applyDiscount
      };
    }
    case TOGGLE_APPLY_BUTTON: {
      let shouldShowDiscountButton = state.shouldShowDiscountButton;
      let applyDiscount = canApplyDiscount(action.price);
      // If discount cannot be applied then show the discount button
      if (!applyDiscount) {
        shouldShowDiscountButton = true;
      }
      return {
        ...state,
        shouldShowDiscountButton,
        applyDiscount
      };
    }
    case SET_SLAB_BULK_DISCOUNT: {
      const { isSlabBulkDiscountEnabled, price, discount } = action;
      let shouldShowDiscountButton = showDiscountButton(discount);
      const applyDiscount = canApplyDiscount(price);

      if (!applyDiscount) {
        shouldShowDiscountButton = true;
      }

      if (isSlabBulkDiscountEnabled) {
        shouldShowDiscountButton = false;
      }

      return {
        ...state,
        shouldShowDiscountButton: shouldShowDiscountButton,
        applyDiscount,
        isSlabBulkDiscountEnabled: isSlabBulkDiscountEnabled
      };
    }
    default:
      return state;
  }
};

export default ({
  discount,
  originalDiscount,
  price,
  productId,
  originalPrice,
  currencySymbol,
  isSetSelected,
  handleDiscountChange,
  onDiscountRemoval,
  inputLabel,
  disabled,
  placeholder,
  containerClass = '',
  buttonContainerClass = '',
  discountPercentAbsolute = false
} = {}) => {
  const [discountState, setDiscountState] = useReducer(
    discountReducer,
    { originalDiscount, originalPrice, discount, productId: getActiveProductId() },
    discountInit
  );
  const { t } = getI18N();

  //Save the discount as a ref so that whenever the below effect runs,
  //it has the discount
  const savedDiscount = useRef();
  savedDiscount.current = discount;

  useEffect(() => {
    setDiscountState({
      type: TOGGLE_DISCOUNT_BUTTON,
      originalPrice,
      originalDiscount,
      discount: savedDiscount.current
    });
  }, [originalPrice, originalDiscount]);

  useEffect(() => {
    setDiscountState({ type: TOGGLE_APPLY_BUTTON, price });
  }, [price]);

  const discountInPercentage = useMemo(() => getDiscountPercentage(discount, price), [
    discount,
    price
  ]);

  const offerDiscount = event => {
    event.stopPropagation();
    setDiscountState({ type: HIDE_DISCOUNT_BUTTON });
  };

  const onDiscountChange = stringifiedDiscount => {
    const priceVal = Number(price);
    if (Number.isNaN(priceVal)) {
      return;
    }

    if (Number(stringifiedDiscount) > priceVal) {
      alert(t('discount_cannot_be_greater_than_price'));
      return;
    }

    handleDiscountChange(stringifiedDiscount);
  };

  const showModalFromSingleDiscount = () => {
    setDiscountState({ type: SHOW_MODAL });
  };

  const onDeleteDiscount = () => {
    const shouldRemove = window.confirm(t('are_you_sure_you_want_to_remove_this_discount'));
    if (!shouldRemove) {
      return;
    }

    setDiscountState({ type: RESET_DISCOUNT_VALUES });
    onDiscountRemoval();
  };

  const closeSingeDiscountModal = () => {
    setDiscountState({ type: HIDE_MODAL });
  };

  const setBulkDiscountState = useCallback(
    ({ isSlabBulkDiscountEnabled } = {}) => {
      setDiscountState({
        type: SET_SLAB_BULK_DISCOUNT,
        isSlabBulkDiscountEnabled,
        price,
        discount: savedDiscount.current
      });
    },
    [price]
  );

  const setPercentageSingleDiscount = useCallback(
    rawDiscount => {
      if (rawDiscount === 0) {
        setDiscountState({ type: HIDE_MODAL });
        return;
      }

      const discount = Math.round(rawDiscount * 100) / 100;
      if (discount === discountInPercentage) {
        setDiscountState({ type: HIDE_MODAL });
        return;
      }

      let discountGiven = (discount / 100) * price;
      let newDiscount = price - discountGiven;
      newDiscount = newDiscount > -1 ? newDiscount : 0;
      newDiscount = Math.round(newDiscount * 100) / 100;
      handleDiscountChange(newDiscount.toString());
      setDiscountState({ type: HIDE_MODAL });
    },
    [discountInPercentage, handleDiscountChange, price]
  );

  const renderBulkSlabDiscount = () => {
    return (
      <BulkSlabDiscount
        showBulkDiscountButton={!isSetSelected}
        productId={productId}
        price={price}
        buttonClassName={`discountButton bulkDiscountButton ${
          discountState.applyDiscount ? '' : 'disableDiscountButton'
        } `}
        setBulkDiscountState={setBulkDiscountState}
        disabled={!discountState.applyDiscount}
      />
    );
  };

  return (
    <div id={'ProductDiscount'} className={containerClass}>
      {!discountState.isSlabBulkDiscountEnabled && !discountState.shouldShowDiscountButton && (
        <div className="discountInputContainer">
          <InfoFormField fieldType={'INPUT'} label={inputLabel} fieldLabel={inputLabel}>
            <div className="currencyFieldContainer">
              <span className="currency">{currencySymbol}</span>
              <div className="discountFieldContainer">
                <NumberInput
                  autoFocus={false}
                  value={discount}
                  onChange={onDiscountChange}
                  disabled={disabled}
                  digitsAfterDecimal={3}
                  placeholder={placeholder}
                />
                {discount && (
                  <div onClick={onDeleteDiscount} className={'deleteDiscountContainer'}>
                    <CloseIcon className={'deleteDiscountIcon'} />
                  </div>
                )}
              </div>
            </div>
            {discount && (
              <div
                onClick={showModalFromSingleDiscount}
                className={`discountInPercent ${discountPercentAbsolute ? 'absolutePosition' : ''}`}
              >
                {discountInPercentage}%
              </div>
            )}
          </InfoFormField>
          <BulkDiscount
            visible={discountState.modalVisible}
            initialPercentage={discountInPercentage ? discountInPercentage.toString() : ''}
            closeModal={closeSingeDiscountModal}
            onSetPercentClick={setPercentageSingleDiscount}
          />
        </div>
      )}
      <div className={`discountButtonContainer ${buttonContainerClass}`}>
        {!discountState.isSlabBulkDiscountEnabled && discountState.shouldShowDiscountButton && (
          <button
            onClick={offerDiscount}
            disabled={!discountState.applyDiscount}
            className={`discountButton ${
              discountState.applyDiscount ? '' : 'disableDiscountButton'
            }`}
          >
            {t('offer_discount')}
          </button>
        )}
        {renderBulkSlabDiscount()}
      </div>
    </div>
  );
};
