import { useCallback, useState, useRef, useEffect } from 'react';
import { IMAGE_LOADER } from 'qs-helpers/PicturesProcessingHelper';

export default () => {
  const picturesTimer = useRef({});
  const [picturesLoadState, setPicturesLoadState] = useState({});

  const updateTimeoutRefForPictures = useCallback(newPictures => {
    //Create a set of picture ids of the picture
    const updatedPictures = newPictures.reduce((includedPictures, pictureState) => {
      if (pictureState) {
        includedPictures.add(pictureState.id || pictureState.pictureId);
      }
      return includedPictures;
    }, new Set());

    for (const pictureId in picturesTimer.current) {
      if (!picturesTimer.current.hasOwnProperty(pictureId) || updatedPictures.has(pictureId)) {
        continue;
      }

      //Clear any existing retry timeouts, because the existing picture is no longer present
      //in the new map
      clearTimeout(picturesTimer.current[pictureId]);
      delete picturesTimer.current[pictureId];
    }

    // Remove the pictures that no longer exist to prevent holding them in memory
    setPicturesLoadState(prevState => {
      const stateToModify = { ...prevState };
      for (const pictureId in stateToModify) {
        if (!stateToModify.hasOwnProperty(pictureId) || updatedPictures.has(pictureId)) {
          continue;
        }
        delete stateToModify[pictureId];
      }
      return stateToModify;
    });
  }, []);

  //Clear out any pending time-outs on component unmount
  useEffect(() => {
    return () => updateTimeoutRefForPictures([]);
  }, [updateTimeoutRefForPictures]);

  const handleImageLoad = (pictureId, state) => () => {
    setPicturesLoadState(prevState => {
      const { retryCount = 0 } = prevState[pictureId] || {};
      const currPicState = { state, retryCount };
      return {
        ...prevState,
        [pictureId]: currPicState
      };
    });
  };

  const handleImageError = (pictureId, state) => () => {
    const { retryCount = 0, state: loaderState = IMAGE_LOADER } =
      picturesLoadState[pictureId] || {};
    if (retryCount >= 3) {
      //Set error state
      handleImageLoad(pictureId, state)();
      return;
    }

    //Increment the retry count and retry loading the image
    clearTimeout(picturesTimer.current[pictureId]);
    picturesTimer.current[pictureId] = setTimeout(() => {
      setPicturesLoadState(prevState => {
        const currPicState = { state: loaderState, retryCount: retryCount + 1 };
        return {
          ...prevState,
          [pictureId]: currPicState
        };
      });
    }, 5000);
  };

  return [picturesLoadState, handleImageLoad, handleImageError, updateTimeoutRefForPictures];
};
