import React from 'react';
import { Typography, Button, Box, List, ListItem, IconButton } from '@material-ui/core';
import { ReactComponent as BinSvg } from 'qs-assets/Media/bin.svg';
import useStyles from './styles.js';
import { getI18N } from 'qs-services/i18N';

export default function TaxesList(props) {
  const { taxes = [], setDefault, onDelete } = props;
  const classes = useStyles();
  const { t } = getI18N();

  return (
    <List className={classes.list}>
      <ListItem classes={{ root: classes.listHeading }}>
        <Typography className={classes.defaultTax}>{t('tax_profiles')}</Typography>
      </ListItem>
      {taxes.map(({ taxPercentage: tax, taxType: type, isDefault, taxId }) => (
        <ListItem key={taxId} classes={{ root: classes.listItem }}>
          <Typography className={classes.listText}>{`${tax}% ${type}`}</Typography>
          <Box className={classes.listActions}>
            <Button
              className={classes.defaultButton}
              variant={`${isDefault ? 'contained' : 'outlined'}`}
              color="primary"
              onClick={() => setDefault(taxId)}
            >
              {`${isDefault ? t('default') : t('set_as_default')}`}
            </Button>
            <IconButton className={classes.deleteButton}>
              <BinSvg width="16" height="16" onClick={() => onDelete(taxId)} fill="#9CA0AC" />
            </IconButton>
          </Box>
        </ListItem>
      ))}
    </List>
  );
}
