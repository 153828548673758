export const getTotalOperationRunningCount = ({ data = {}, operationKeys = [] }) => {
  let totalOperationCount = 0;
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      if (operationKeys.indexOf(key) !== -1) {
        totalOperationCount += 1;
      }
    }
  }

  return totalOperationCount;
};
