import CacheRequest from 'qs-data-manager/CacheRequest';
import { CUSTOM_PRODUCT_PROPERTIES } from '../../Apis/CustomProductProperties/ApiCacheConnector';

export const getCacheKeyForCustomProductProperties = () => CUSTOM_PRODUCT_PROPERTIES.cacheKey;

const cacheKey = getCacheKeyForCustomProductProperties();

export const getCustomProductProperties = () => CacheRequest.getCacheForKey(cacheKey);

export const setCustomProductProperties = updates => {
  if (!updates || !Object.keys(updates).length) {
    return CacheRequest.deleteCacheForKeys([cacheKey]);
  }
  CacheRequest.setCacheForKey(cacheKey, updates);
};
