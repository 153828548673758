import React from 'react';
import Loader from '../../../Common/Loader';
import './SettingsDataRow.scss';
import { ButtonBase } from '@material-ui/core';

export default ({ icon, loader, title, onSectionClick, children }) => {
  const getIconOrLoader = () => {
    if (loader) {
      return (
        <div className="icon-container">
          <Loader size="extraSmall" style={{ minHeight: 35, minWidth: 35, margin: 0 }} />
        </div>
      );
    }

    if (!icon) {
      return null;
    }

    return <div className="icon-container">{icon}</div>;
  };

  return (
    <ButtonBase
      className={`settings-data-container${onSectionClick ? ' settings-data--click' : ''}${
        loader ? ' settings-data--disable' : ''
      }`}
      onClick={onSectionClick}
      component="section"
    >
      <div className="settings-data-row-container">
        <div className="settings-data-text-container">
          {title && <h4 className="settings-data-text--title">{title}</h4>}
          {children && <div className="settings-data-text--description">{children}</div>}
        </div>
        {getIconOrLoader()}
      </div>
    </ButtonBase>
  );
};
