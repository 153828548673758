import React, { useEffect } from 'react';
import NumberInput from '../../../../../../Common/NumberInput';
import eventbus from 'eventing-bus';
import { validateSetFields } from 'qs-helpers/Products/ProductSetOrdering';
import { SAVE_BUTTON_META } from 'qs-helpers/Products/constants';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

export default ({ setName, setQuantity, existingSetQuantity, onSetQuantityChange }) => {
  const existingSetName = setName || '';
  const { t } = getI18N();

  useEffect(() => {
    eventbus.publish(SAVE_BUTTON_META.eventbusKey, {
      id: 'set-fields-validator',
      validator: validateSetFields({ t, existingSetName, existingSetQuantity }),
      eventType: SAVE_BUTTON_META.eventType.REGISTER_VALIDATOR.id
    });

    return () =>
      eventbus.publish(SAVE_BUTTON_META.eventbusKey, {
        id: 'set-fields-validator',
        eventType: SAVE_BUTTON_META.eventType.UNREGISTER_VALIDATOR.id
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingSetName, existingSetQuantity]);

  useEffect(() => {
    // On unmount clear the saved data to ensure that it is not sent in the request
    return () => {
      eventbus.publish(SAVE_BUTTON_META.eventbusKey, {
        id: SAVE_BUTTON_META.PRODUCT_SET_QTY_FIELD.id,
        hasChanged: false,
        eventType: SAVE_BUTTON_META.eventType.PRODUCT_META.id
      });
    };
  }, []);

  const onQuantityChange = quantity => {
    onSetQuantityChange(quantity);
    eventbus.publish(SAVE_BUTTON_META.eventbusKey, {
      id: SAVE_BUTTON_META.PRODUCT_SET_QTY_FIELD.id,
      hasChanged: quantity !== existingSetQuantity,
      data: Number(quantity),
      eventType: SAVE_BUTTON_META.eventType.PRODUCT_META.id
    });
  };

  return (
    <div className={'rowContainer productSetQuantityContainer'}>
      <div className={'rowHeading'}>{t('set_quantity')}</div>
      <div className={'inputContainer'}>
        <NumberInput
          integer
          onChange={onQuantityChange}
          value={setQuantity || ''}
          inputClassName="input setInput"
          placeholder={t('type_set_quantity')}
        />
      </div>
    </div>
  );
};
