import AppConfig from 'qs-config/AppConfig';
import request from '../../Request';

export const getCompanyCustomComponent = ({ type }) => {
  return request.authGet(`${AppConfig.qsApiEndPoint}/v1/company/custom-component?type=${type}`);
};

export const createCompanyCustomComponent = customComponent => {
  return request.authPost(
    `${AppConfig.qsApiEndPoint}/v1/company/custom-component`,
    customComponent
  );
};

export const updateCompanyCustomComponent = customComponent => {
  return request.authPut(`${AppConfig.qsApiEndPoint}/v1/company/custom-component`, customComponent);
};

export const deleteCompanyCustomComponent = ({ id }) => {
  return request.authDelete(`${AppConfig.qsApiEndPoint}/v1/company/custom-component`, { id });
};

export const deleteCompanyCustomComponentType = ({ type }) => {
  return request.authDelete(`${AppConfig.qsApiEndPoint}/v1/company/custom-component/type`, {
    type
  });
};
