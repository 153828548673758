import AppConfig from 'qs-config/AppConfig';
import request from '../../Request';

export const getCatalogueLabels = async () =>
  request.authGet(`${AppConfig.qsApiEndPoint}/v1/catalogue-label`);

export const createNewCatalogueLabel = data =>
  request.authPost(`${AppConfig.qsApiEndPoint}/v1/catalogue-label`, data);

export const deleteCatalogueLabel = data =>
  request.authDelete(`${AppConfig.qsApiEndPoint}/v1/catalogue-label`, data);

export const editCatalogueLabelName = data =>
  request.authPut(`${AppConfig.qsApiEndPoint}/v1/catalogue-label`, data);

export const reorderCatalogueLabels = data =>
  request.authPost(`${AppConfig.qsApiEndPoint}/v1/catalogue-label/reorder`, data);

export const getAttachedCataloguesToLabel = data =>
  request.authPost(`${AppConfig.qsApiEndPoint}/v1/catalogue/filter`, data);

export const attachCataloguesToLabels = data =>
  request.authPost(`${AppConfig.qsApiEndPoint}/v1/catalogue-label/attach`, data);

export const detachCataloguesToLabels = data =>
  request.authPost(`${AppConfig.qsApiEndPoint}/v1/catalogue-label/detach`, data);
