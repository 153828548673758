import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import './ConfirmForwardToShowroom.css';
import Loader from 'qs-components/Common/Loader';
import Mixpanel from 'qs-data-manager/Mixpanel';
import { getI18N } from 'qs-services/i18N';

const forwardCatalogueToShowroom = body => {
  let message = `Dear GRT Showroom,

Please click on the below link and select products to place an order - 
${body.catalogueLink}

Sent via QuickSell App
On behalf of GRT Purchase manager`;
  message = encodeURIComponent(message);
  let link = `mailto:${body.showroomEmailId[0]}?subject=New catalogue - ${
    body.catalogueName
  }&bcc=${body.showroomEmailId.join(',') + '\n'}&body=${message}`;

  const a = document.createElement('a');
  a.href = link;
  a.target = '_blank';
  a.click();
};

class ConfirmForwardToShowroom extends Component {
  static propTypes = {};

  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      closeModal: false,
      visible: props.visible,
      isResponseSuccessful: false,
      responseReceived: false,
      responseMessage: ''
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      visible: nextProps.visible
    });
  }

  handleSendNow = () => {
    const { catalogueLink, catalogueName, selectedShowroomMap } = this.props;
    const selectedShowroomCount = Object.keys(selectedShowroomMap).length;
    this.setState({ showLoader: true });

    const body = {
      catalogueLink,
      catalogueName,
      showroomEmailId: Object.values(selectedShowroomMap)
    };
    forwardCatalogueToShowroom(body);
    this.props.closeForwardModal();
    Mixpanel.sendEvent({
      eventName: 'grt_send_now_clicked',
      props: {
        catalogueLink,
        selectedEmailIdsCount: selectedShowroomCount
      }
    });
  };

  handleCancel = () => {
    this.props.closeModal();
  };

  renderConfirmState = () => {
    const { t } = getI18N();
    const showroomCount = Object.keys(this.props.selectedShowroomMap).length;
    return (
      <div>
        <div className="sendToGrtShowroomTitle">{t('send_to_grt_showrooms')}</div>
        <div className="message">
          <div>
            {t(
              showroomCount === 1
                ? 'are_you_sure_you_want_to_send_catalogue_to_grt_showroom'
                : 'are_you_sure_you_want_to_send_catalogue_to_grt_showrooms',
              { catalogueName: this.props.catalogueName }
            )}
          </div>
        </div>
        <div className="confirmationButtonContainer">
          {!this.state.showLoader && (
            <div className="cancelButton" onClick={this.handleCancel}>
              {t('cancel')}
            </div>
          )}
          <div style={{ flex: 1 }} />
          {this.state.showLoader ? (
            <Loader size="small" />
          ) : (
            <div className="sendNowButton" onClick={this.handleSendNow}>
              {t('send_now')}
            </div>
          )}
        </div>
      </div>
    );
  };

  renderResponseState = () => {
    const { isResponseSuccessful, responseMessage } = this.state;
    const { closeForwardModal, supportPhoneNumber } = this.props;
    const { t } = getI18N();
    return (
      <div>
        <div className={isResponseSuccessful ? 'responseSuccess' : 'responseFailed'}>
          {isResponseSuccessful ? 'Broadcast successful' : ' Broadcast failed'}
        </div>
        <div className="message">
          {isResponseSuccessful
            ? responseMessage
            : t('we_encountered_an_error_please_contact_support_number', { supportPhoneNumber })}
        </div>
        <div className="responseButtonContainer">
          <div className="okButton" onClick={closeForwardModal}>
            {t('ok')}
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { visible, responseReceived } = this.state;

    return (
      <Modal
        open={visible}
        center
        styles={{
          modal: {
            backgroundColor: 'white',
            borderRadius: 4,
            padding: '0'
          }
        }}
        showCloseIcon={false}
      >
        <div className="container">
          {!responseReceived ? this.renderConfirmState() : this.renderResponseState()}
        </div>
      </Modal>
    );
  }
}

export default ConfirmForwardToShowroom;
