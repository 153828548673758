import { SHIPPING_WEIGHT_UNITS } from 'qs-api/Shipping/ApiCacheConnector';
import CacheRequest from '../CacheRequest';

export const attachShippingWeightUnitsListener = ({ listener }) => {
  CacheRequest.attachListener(`${SHIPPING_WEIGHT_UNITS.cacheKey}`, listener);
};

export const removeShippingWeightUnitsListener = ({ listener }) => {
  CacheRequest.removeListener(`${SHIPPING_WEIGHT_UNITS.cacheKey}`, listener);
};

export const getShippingWeightUnits = () => {
  const key = `${SHIPPING_WEIGHT_UNITS.cacheKey}`;
  const apiName = SHIPPING_WEIGHT_UNITS.apiFunction;
  CacheRequest.makeRequest(key, apiName, {
    params: [],
    options: {
      shouldNotStoreInNative: true
    }
  });
};

export const getShippingWeightUnitsFromCache = () =>
  CacheRequest.getCacheForKey(`${SHIPPING_WEIGHT_UNITS.cacheKey}`) || undefined;
