import React, { useRef, useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import eventbus from 'eventing-bus';
import {
  SAVE_BUTTON_META,
  COMPANY_UNIT_TYPES,
  COMPANY_UNIT_VALUES
} from 'qs-helpers/Products/constants';
import Loader from '../../../../../../Common/Loader';
import SelectInput from '../../../../../../Common/SelectInput';
import CacheListenerCallback from 'qs-helpers/CacheListenerCallback';
import {
  getCompanyUnitDetailsFromCache,
  getCompanyUnitDetailsFromRemote,
  attachCompanyUnitDetailsListner,
  removeCompanyUnitDetailsListener
} from 'qs-data-manager/Company';
import './styles.scss';
import { getI18N } from 'qs-services/i18N';

export default ({
  isSet,
  setIsSet,
  originalSetValue,
  setType,
  setSetType,
  originalSetType,
  loading,
  containerClass = ''
}) => {
  const [showSetInfo, setShowInfoPopup] = useState();
  const [companyUnitDetails, setCompanyUnitDetails] = useState(getCompanyUnitDetailsFromCache());
  const viewContainerRef = useRef();

  const { t } = getI18N();

  const customUnitDetails = (companyUnitDetails || {}).unitDetails || [];
  const customUnitOptions = customUnitDetails
    .filter(
      ({ qtyType }) => qtyType !== COMPANY_UNIT_TYPES.PIECE && qtyType !== COMPANY_UNIT_TYPES.SET
    )
    .map(({ title, setType, qtyType }) => ({
      value: setType,
      displayValue: title || t('price_per_unit', { unit: qtyType })
    }));

  useEffect(() => {
    if (!companyUnitDetails || !companyUnitDetails.unitDetails) {
      getCompanyUnitDetailsFromRemote();
    }
    attachCompanyUnitDetailsListner({ listener: companyUnitDetailsListener });
    return () => {
      removeCompanyUnitDetailsListener({ listener: companyUnitDetailsListener });
    };
  }, [companyUnitDetails]);

  const companyUnitDetailsListener = (error, payload) => {
    const { data } = CacheListenerCallback(error, payload);
    if (!data || !data.unitDetails) {
      return;
    }
    setCompanyUnitDetails(data);
  };

  const onSelectChange = event => {
    const { value } = event.target;
    const updatedIsSet = !!value && value !== COMPANY_UNIT_VALUES.AS_PIECE ? true : false;
    setIsSet(updatedIsSet);
    const updatedSetType =
      !!value && value !== COMPANY_UNIT_VALUES.AS_PIECE && value !== COMPANY_UNIT_VALUES.AS_SET
        ? value
        : null;
    setSetType(updatedSetType);
    eventbus.publish(SAVE_BUTTON_META.eventbusKey, {
      id: SAVE_BUTTON_META.PRODUCT_SET_FIELD.id,
      hasChanged: updatedIsSet !== originalSetValue,
      data: updatedIsSet,
      eventType: SAVE_BUTTON_META.eventType.PRODUCT_META.id
    });
    eventbus.publish(SAVE_BUTTON_META.eventbusKey, {
      id: SAVE_BUTTON_META.PRODUCT_SET_TYPE.id,
      hasChanged: updatedSetType !== originalSetType,
      data: updatedSetType,
      eventType: SAVE_BUTTON_META.eventType.PRODUCT_META.id
    });
  };

  const onWhatsThisClick = event => {
    event.preventDefault();
    setShowInfoPopup(true);
  };

  const getSetDropDown = () => {
    if (loading) {
      return (
        <div className="productDetailsDropDownLoader">
          <Loader />
        </div>
      );
    }

    const selectValue =
      !!setType && !!customUnitOptions && customUnitOptions.length > 0
        ? setType
        : isSet
        ? COMPANY_UNIT_VALUES.AS_SET
        : COMPANY_UNIT_VALUES.AS_PIECE;

    return (
      <SelectInput
        value={selectValue}
        onChange={onSelectChange}
        containerClass="productSetDropDownContainer"
        materialStyles={{
          formSelectionBorder: '1px solid #ffffff',
          selectTextColor: '#ffffff',
          selectedItemBackgroundColor: '#ffffff',
          selectedItemColor: '#232b36'
        }}
        options={[
          {
            value: COMPANY_UNIT_VALUES.AS_PIECE,
            displayValue: t('price_per_piece')
          },
          {
            value: COMPANY_UNIT_VALUES.AS_SET,
            displayValue: t('price_per_set')
          },
          ...customUnitOptions
        ]}
      />
    );
  };

  const renderPortalForSetMessage = () => {
    if (!showSetInfo) {
      return null;
    }

    const bodyElement = document.getElementsByTagName('body')[0];
    if (!bodyElement || !viewContainerRef.current) {
      return;
    }

    const { left, bottom, top } = viewContainerRef.current.getBoundingClientRect();
    const absoluteStyle = {
      left
    };
    if (bottom / bodyElement.clientHeight > 0.75) {
      absoluteStyle.bottom = bodyElement.clientHeight - top;
    } else {
      absoluteStyle.top = bottom;
    }

    const customUnitDetail =
      customUnitDetails.find(customUnitDetail => {
        if (customUnitDetail.setType === setType) {
          return true;
        }
        return false;
      }) || {};

    return createPortal(
      <div onClick={() => setShowInfoPopup(false)} className="productSetUsageFixedOverlay">
        <div className="setInfoOverlayContainer" style={absoluteStyle}>
          {t(
            !!customUnitDetail.qtyType
              ? 'when_you_choose_the_price_per_unit_option_you_customers_have_to_purchase_your_product_in_multiples'
              : 'when_you_choose_the_price_per_set_option_you_customers_have_to_purchase_your_product_in_multiples',
            { unit: customUnitDetail.qtyType }
          )}
        </div>
      </div>,
      bodyElement
    );
  };

  return (
    <div ref={viewContainerRef} className={`productSetViewContainer ${containerClass}`}>
      <div className="setDropDownWrapper">{getSetDropDown()}</div>
      {isSet && (
        <div className="showSetInfoButtonContainer">
          <button className="showSetInfoButton" onClick={onWhatsThisClick}>
            {t('whats_this')}
          </button>
        </div>
      )}
      {renderPortalForSetMessage()}
    </div>
  );
};
