import { ALL_COMPANY_TAXES } from 'qs-api/Tax/ApiCacheConnector';
import CacheRequest from 'qs-data-manager/CacheRequest';

export const createCompanyTaxCacheKey = () => ALL_COMPANY_TAXES.cacheKey;

export const getCompanyTaxesFromCache = () =>
  //Explicitly return undefined, so that default variable assignment works
  CacheRequest.getCacheForKey(createCompanyTaxCacheKey()) || undefined;

export const setCompanyTaxesInCache = updatedTaxList => {
  if (!Array.isArray(updatedTaxList)) {
    return;
  }

  CacheRequest.setCacheForKey(createCompanyTaxCacheKey(), {
    taxes: updatedTaxList
  });
};
