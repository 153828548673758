import React, { useState, useEffect } from 'react';

import './ProductDemoRow.scss';
import SettingsRow from '../../../Common/SettingsRow/SettingsRow';
import {
  getCompanyProductDemoFromCache,
  attachCompanyProductDemoListener,
  getCompanyProductDemo,
  removeCompanyProductDemoListener
} from 'qs-data-manager/Company';
import CacheListenerCallback from 'qs-helpers/CacheListenerCallback';
import toastr from 'toastr';
import ClickHandlerWithLoaderContainer from '../../../../Common/ClickHandlerWithLoader/ClickHandlerWithLoaderContainer';
import { getI18N } from 'qs-services/i18N';

export default ({ classForContainer, onSelected, settingComponentId }) => {
  const [showProductDemo, toggleProductDemo] = useState(() => {
    const productDemo = getCompanyProductDemoFromCache();
    if (productDemo) {
      return productDemo.status;
    }
    return false;
  });

  // Attach product demo listener to determine whether to show the product demo link or not
  useEffect(() => {
    const productDemoListener = function(error, payload) {
      const { err, loading, refreshing, data } = CacheListenerCallback(error, payload);

      if (loading || refreshing) {
        return;
      }

      if (err) {
        toastr.error(t('something_went_wrong_while_fetching_the_info_for_the_demo_option'));
        return;
      }

      if (data && typeof data.status === 'boolean') {
        toggleProductDemo(data.status);
      }
    };

    attachCompanyProductDemoListener(productDemoListener);
    getCompanyProductDemo();

    return () => removeCompanyProductDemoListener(productDemoListener);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!showProductDemo) {
    return null;
  }

  const { t } = getI18N();

  return (
    <ClickHandlerWithLoaderContainer
      clickProps={{
        clickHandler: onSelected,
        clickParams: settingComponentId
      }}
      type="button"
      classForContainer={classForContainer}
    >
      <SettingsRow
        imageSrc={'https://web.quicksell.co/static/media/ic_speak_to_expert.png'}
        title={t('product_demo')}
      >
        <div className={'product-demo-container'}>
          <div className={'product-demo-text'}>{t('get_a_complete_walkthrough')}</div>
          <div className={'view-live-demo'}>{t('view_live_demo')}</div>
        </div>
      </SettingsRow>
    </ClickHandlerWithLoaderContainer>
  );
};
