import React from 'react';
import eventbus from 'eventing-bus';
import toastr from 'toastr';

import { TOGGLE_REARRANGING_PICTURES } from 'qs-data-manager/Products';
import { setPictureAsDefault } from 'qs-data-manager/ProductDetails';
import { toggleGlobalLoader } from 'qs-helpers';
import { getI18N } from 'qs-services/i18N';

import './styles.scss';

export default ({ currentImageData, pictureUrl }) => {
  const { t } = getI18N();

  const setAsDefault = async () => {
    const loaderKey = `setPictureAsDefault${Date.now()}`;
    toggleGlobalLoader(loaderKey, true);
    eventbus.publish(TOGGLE_REARRANGING_PICTURES.eventbusKey, true);
    const shouldUpdateProduct = true;

    try {
      await setPictureAsDefault(currentImageData, pictureUrl, shouldUpdateProduct);
    } catch (setDefaultError) {
      toastr.error(t('could_not_set_image_as_default'));
    } finally {
      toggleGlobalLoader(loaderKey, false);
      eventbus.publish(TOGGLE_REARRANGING_PICTURES.eventbusKey, false);
    }
  };

  return (
    <div className={'productDetails setAsDefaultContainer'}>
      <div className="setAsDefaultButton" onClick={setAsDefault}>
        {t('set_as_main_image')}
      </div>
    </div>
  );
};
