import { duplicateSelectedProducts } from 'qs-api/Catalogues/api';
import { db } from 'qs-config/FirebaseConfig';
import { reportError } from 'qs-helpers/ErrorReporting';
import { connector } from '../ApiAndCacheConnector';
import CacheRequest from '../CacheRequest';
import { setActiveCatalogueId } from '../Catalogues';
import { createCatalogueInNative } from '../Dexie/CatalogueDexieHelpers';

const createCatalogueDataInCache = ({ productIds, catalogueId, title }) => {
  const catalogueListCacheKey = connector.CATALOGUE_LIST_META.cacheKey;
  const { catalogueIds = [] } = CacheRequest.getCacheForKey(catalogueListCacheKey) || {};
  const updatedCatalogueIds = [{ catalogueId, sortTimestamp: new Date().getTime() }].concat(
    catalogueIds
  );

  const catalogueMeta = {
    title,
    picturesMeta: [],
    labelIds: [],
    productCount: productIds.length
  };

  CacheRequest.setCacheForKey(
    `${connector.CATALOGUE_ROW_META.cacheKey}${catalogueId}`,
    catalogueMeta
  );
  CacheRequest.setCacheForKey(catalogueListCacheKey, { catalogueIds: updatedCatalogueIds });
  setActiveCatalogueId(catalogueId);

  //create catalogue data in native storage in background
  createCatalogueInNative(catalogueMeta, catalogueId).catch(ex => ex);
};

export const duplicateProductsToCatalogue = async ({ productIds, catalogueId, catalogueTitle }) => {
  if (!Array.isArray(productIds) || productIds.length === 0) {
    return;
  }

  let newCatalogueId = catalogueId,
    shouldCreate = false;
  if (!newCatalogueId) {
    newCatalogueId = db.ref('catalogues').push().key;
    shouldCreate = true;
  }

  let title;
  if (shouldCreate && typeof catalogueTitle === 'string') {
    title = `Copy of ${catalogueTitle}`.trim();
  }

  try {
    await duplicateSelectedProducts({
      productIds,
      catalogueId: newCatalogueId,
      shouldCreate,
      title
    });
    if (shouldCreate) {
      createCatalogueDataInCache({ productIds, catalogueId: newCatalogueId, title });
      return;
    }
    setActiveCatalogueId(newCatalogueId);
  } catch (duplicateError) {
    reportError(duplicateError);
    throw duplicateError;
  }
};
