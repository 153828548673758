import request from '../../Request';
import AppConfig from 'qs-config/AppConfig';

export const getMoqForEntityApi = (entityId, entityType) =>
  request.authGet(
    `${AppConfig.qsApiEndPoint}/v1/experiment/min-order-quantity?entityId=${encodeURIComponent(
      entityId
    )}&entityType=${encodeURIComponent(entityType)}`
  );

export const getComputetdMoqForEntityApi = (entityId, entityType) =>
  request.authGet(
    `${
      AppConfig.qsApiEndPoint
    }/v1/experiment/min-order-quantity/computed?entityId=${encodeURIComponent(
      entityId
    )}&entityType=${encodeURIComponent(entityType)}`
  );

export const setMoqForEntityApi = (entityId, entityType, minOrderQuantity) =>
  request.authPost(`${AppConfig.qsApiEndPoint}/v1/experiment/min-order-quantity`, {
    entityId,
    entityType,
    minOrderQuantity
  });
