import { CATALOGUE_LABELS } from 'qs-api/CatalogueLabels/ApiCacheConnector';
import {
  createNewCatalogueLabel,
  deleteCatalogueLabel,
  editCatalogueLabelName,
  reorderCatalogueLabels,
  getAttachedCataloguesToLabel,
  attachCataloguesToLabels,
  detachCataloguesToLabels
} from 'qs-api/CatalogueLabels/api';
import CacheRequest from '../CacheRequest';
import CatalogueLib from 'qs-data-manager/Catalogues';
import { reportError } from 'qs-helpers/ErrorReporting';
import api from 'qs-services/Api';
import { selectedLabels, selectedCatalogue, selectedLabelsToEdit } from '../Selected';
import {
  getCurrentLabelsDataRequestNumber,
  incrementLabelsDataRequestNumber
} from 'qs-helpers/CatalogueLabels/ResponseProcessor';

const createCacheKeyForLabels = () => CATALOGUE_LABELS.cacheKey;

export const getCatalogueLabelsFromCache = () =>
  CacheRequest.getCacheForKey(createCacheKeyForLabels()) || undefined;

export const attachCatalogueLabelsListener = ({ listener }) => {
  CacheRequest.attachListener(createCacheKeyForLabels(), listener);
};

export const getCatalogueLabelsData = () => {
  const key = createCacheKeyForLabels();
  const apiName = CATALOGUE_LABELS.apiFunction;
  CacheRequest.makeRequest(key, apiName, {
    options: {
      shouldNotStoreInNative: true
    }
  });
};

export const removeCatalogueLabelsListener = ({ listener }) => {
  CacheRequest.removeListener(createCacheKeyForLabels(), listener);
};

const setCatalogueLabelsInCache = data => {
  CacheRequest.setCacheForKey(createCacheKeyForLabels(), { labels: data });
};

const addNewCatalogueLabelInCache = result => {
  const oldData = getCatalogueLabelsFromCache();
  const oldLabels = Array.isArray(oldData.labels) ? oldData.labels : [];
  const labels = [...oldLabels, result.label];
  selectedLabelsToEdit.addItems([result.label.id]);
  setCatalogueLabelsInCache(labels);
};

const removeDeletedCatalogueLabelFromCache = ids => {
  const oldData = getCatalogueLabelsFromCache();
  const labelsToRemove = new Set(ids);
  const newData = oldData.labels.filter(element => !labelsToRemove.has(element.id));
  setCatalogueLabelsInCache(newData);
};

const updateEditedCatalogueLabelNameInCache = ({ labelId, labelName }) => {
  const oldData = getCatalogueLabelsFromCache();
  const newData = oldData.labels.map(element => {
    if (element.id === labelId) {
      element.name = labelName;
    }
    return element;
  });
  setCatalogueLabelsInCache(newData);
};

export const addNewCatalogueLabelToRemote = async ({ labelName }) => {
  try {
    const result = await createNewCatalogueLabel({ labelName });
    addNewCatalogueLabelInCache(result);
  } catch (error) {
    reportError(error);
    throw error;
  }
};

export const deleteCatalogueLabelFromRemote = async ids => {
  try {
    await deleteCatalogueLabel({
      labelIds: ids
    });
    removeDeletedCatalogueLabelFromCache(ids);
    selectedLabelsToEdit.removeAll(); //removing all because, ids array will have all selected labels to edit.
    selectedLabelsToEdit.removeItems(ids);
    selectedLabels.removeItems(ids);
    selectedLabels.removeKeys(ids);
    if (selectedLabelsToEdit.getAllItemsCount() === 0) {
      selectedLabelsToEdit.deactivate();
    }
  } catch (error) {
    reportError(error);
    throw error;
  }
};

export const editCatalogueLabelNameToRemote = async ({ labelId, labelName }) => {
  try {
    await editCatalogueLabelName({ labelId, labelName });
    updateEditedCatalogueLabelNameInCache({ labelId, labelName });
  } catch (error) {
    reportError(error);
    throw error;
  }
};

export const reorderCatalougeLabel = async ({ newList }) => {
  try {
    await reorderCatalogueLabels({ labelIds: newList.map(labelElement => labelElement.id) });
    setCatalogueLabelsInCache(newList);
  } catch (error) {
    reportError(error);
    throw error;
  }
};

export const getCataloguesAttachedToLabel = async labelIds => {
  let result = [];
  incrementLabelsDataRequestNumber();
  const currentReqeustNumber = getCurrentLabelsDataRequestNumber();
  try {
    if (labelIds.length === 0) {
      const data = await api.getCatalogueIds();
      result = data.catalogueIds;
    } else {
      const apiData = {
        filter: {
          labelIds
        }
      };
      const data = await getAttachedCataloguesToLabel(apiData);
      result = data.filteredCatalogues;
    }
    if (currentReqeustNumber !== getCurrentLabelsDataRequestNumber()) {
      return;
    }
    CatalogueLib.updateCatalogueIdsInCacheForLabels(result);
    selectedCatalogue.removeAll();
  } catch (error) {
    CatalogueLib.updateCatalogueIdsInCacheForLabels([]);
    selectedCatalogue.removeAll();
    reportError(error);
    throw error;
  }
};

export const attachAndDetachLabels = async ({ attachLabels, detachLabels, catalogueIds }) => {
  try {
    const waitForPromises = [];
    if (detachLabels.length !== 0) {
      waitForPromises.push(detachCataloguesToLabels({ catalogueIds, labelIds: detachLabels }));
    }
    if (attachLabels.length !== 0) {
      waitForPromises.push(attachCataloguesToLabels({ catalogueIds, labelIds: attachLabels }));
    }
    await Promise.all(waitForPromises);
    CatalogueLib.updateLabelsInCatalogeMetaCache({ attachLabels, detachLabels, catalogueIds });
    await getCataloguesAttachedToLabel(selectedLabels.getAllSelectedItems());
  } catch (error) {
    reportError(error);
    throw error;
  }
};
