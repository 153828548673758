import React, { useState, useEffect, useCallback } from 'react';
import Popover from 'react-tiny-popover';
import { ReactComponent as AddOrRemoveIcon } from 'qs-assets/Media/edit_filled.svg';
import { ReactComponent as ChangeThemeIcon } from 'qs-assets/Media/change_theme.svg';
import { ReactComponent as ChangeActionIcon } from 'qs-assets/Media/change_action.svg';
import { ReactComponent as ChangeLayoutIcon } from 'qs-assets/Media/change_layout.svg';
import { ReactComponent as SortProductsIcon } from 'qs-assets/Media/sort_products.svg';
import ReorderBarIcon from 'qs-components/Common/ReorderBarIcon';
import useMakeQuery from 'qs-hooks/useMakeQuery';
import {
  fetchCatalogueSettingsHandler,
  handleCatalogueSettingsCleanup,
  getCachedCatalogueSettings
} from 'qs-helpers/Catalogues/DataQueryHelper';
import LayoutDialog from './LayoutDialog';
import ActionButtonDialog from './ActionButtonDialog';
import SortProductsDialog from './SortProductsDialog';
import ConfirmSortDialog from './ConfirmSortDialog';
import { navigateToPath } from 'qs-data-manager';
import {
  ROUTE_MAP,
  SETTINGS_COMPONENT_ID_MAP,
  SETTINGS_COMPONENT_ROUTE_MAP
} from 'qs-helpers/CompanySettings/constants';
import { EDIT_PRODUCTS_HEADER, REORDER_PRODUCTS_HEADER } from '../../constants';
import CatalogueBannerIcon from 'qs-components/Common/CatalogueBannerIcon';
import AddCatalogueBannerDialog from './AddCatalogueBannerDialog';
import { getElementParents } from 'qs-helpers';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

export default ({ children, showPopover, catalogueId, closePopover, onClickHeaderOption }) => {
  const [showSortProductsDialog, setShowSortProductsDialog] = useState(false);
  const [showChangeLayoutDialog, setShowChangeLayoutDialog] = useState(false);
  const [showAddCatalogueBannerDialog, setShowAddCatalogueBannerDialog] = useState(false);
  const [showChangeActionButtonDialog, setShowChangeActionButtonDialog] = useState(false);
  const [typeOfSort, setTypeOfSort] = useState({});

  const { t } = getI18N();

  const [{ loading, error, data }] = useMakeQuery({
    changeDependancy: [catalogueId],
    actionHandler: fetchCatalogueSettingsHandler,
    cleanupHandler: handleCatalogueSettingsCleanup,
    cachedDataHandler: getCachedCatalogueSettings
  });

  useEffect(() => {
    const handleBodyClick = event => {
      const parents = getElementParents(event.target);
      const parentsClass = parents.map(parent => parent.className);

      if (
        !parentsClass.includes('react-tiny-popover-container') &&
        !parentsClass.includes('iconContainer editDialogContainer')
      ) {
        closePopover();
      }
    };
    if (showPopover) {
      document.querySelector('body').addEventListener('click', handleBodyClick);
    }

    return () => document.querySelector('body').removeEventListener('click', handleBodyClick);
  }, [showPopover, closePopover]);

  const toggleShowSortDialog = event => {
    event.stopPropagation();
    event.preventDefault();
    setShowSortProductsDialog(prevState => !prevState);
  };

  const onAddOrRemoveClick = event => {
    event.stopPropagation();
    event.preventDefault();
    setShowSortProductsDialog(false);
    onClickHeaderOption(EDIT_PRODUCTS_HEADER);
  };

  const onClickReorderProducts = event => {
    event.stopPropagation();
    event.preventDefault();
    setShowSortProductsDialog(false);
    onClickHeaderOption(REORDER_PRODUCTS_HEADER);
  };

  const onClickAddCatalogueBanner = useCallback(
    event => {
      event.stopPropagation();
      event.preventDefault();
      closePopover();
      setShowAddCatalogueBannerDialog(true);
    },
    [closePopover]
  );

  const closeAddCatalogueBannerDialog = useCallback(() => {
    setShowAddCatalogueBannerDialog(false);
  }, []);

  const navigateToThemesManager = event => {
    event.stopPropagation();
    event.preventDefault();
    navigateToPath(
      `/${ROUTE_MAP.ACCOUNT_SETTINGS}/${
        SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CUSTOM_THEMES_SETTINGS].ROUTE
      }`
    );
  };

  const toggleChangeLayoutDialog = event => {
    event.stopPropagation();
    event.preventDefault();
    setShowChangeLayoutDialog(prevState => !prevState);
  };

  const toggleChangeActionButtonDialog = event => {
    event.stopPropagation();
    event.preventDefault();
    setShowChangeActionButtonDialog(prevState => !prevState);
  };

  const content = (
    <div className={'editDialogueContainer'}>
      <div className={'editDialogueOption'} onClick={onAddOrRemoveClick}>
        <AddOrRemoveIcon className={'editDialogueIcon'} />
        <div className={'title'}>{t('add_remove')}</div>
      </div>
      <div className={'editDialogueOption'} onClick={onClickReorderProducts}>
        <ReorderBarIcon className={'editDialogueIcon'} />
        <div className={'title'}>{t('reorder')}</div>
      </div>
      <div className={'editDialogueOption'} onClick={onClickAddCatalogueBanner}>
        <CatalogueBannerIcon className={'editDialogueIcon'} />
        <div className={'title'}>{t('add_banner')}</div>
      </div>
      <div className={'editDialogueOption'} onClick={navigateToThemesManager}>
        <ChangeThemeIcon className={'editDialogueIcon'} />
        <div className={'title'}>{t('change_theme')}</div>
      </div>
      <div className={'editDialogueOption'} onClick={toggleChangeActionButtonDialog}>
        <ChangeActionIcon className={'editDialogueIcon'} />
        <div className={'title'}>{t('change_action_button')}</div>
      </div>
      <div className={'editDialogueOption'} onClick={toggleChangeLayoutDialog}>
        <ChangeLayoutIcon className={'editDialogueIcon'} />
        <div className={'title'}>{t('change_layout')}</div>
      </div>
      <div className={'editDialogueOption'} onClick={toggleShowSortDialog}>
        <SortProductsIcon className={'editDialogueIcon'} />
        <div className={'title withoutBorder'}>{t('sort_products')}</div>
      </div>
    </div>
  );

  return (
    <>
      <Popover
        isOpen={showPopover}
        position={['bottom']}
        padding={20}
        containerStyle={{ maxWidth: 200 }}
        content={content}
        onClickOutside={closePopover}
      >
        {children}
      </Popover>
      <ActionButtonDialog
        open={showChangeActionButtonDialog}
        errorFetchingData={error}
        loading={loading}
        selectedActionButton={
          (data && data.experiments && data.experiments['action-button-id']) || ''
        }
        catalogueId={catalogueId}
        onClose={() => {
          setShowChangeActionButtonDialog(false);
          closePopover();
        }}
      />
      <AddCatalogueBannerDialog
        open={showAddCatalogueBannerDialog}
        catalogueId={catalogueId}
        onClose={closeAddCatalogueBannerDialog}
      />
      <LayoutDialog
        open={showChangeLayoutDialog}
        loading={loading}
        errorFetchingData={error}
        selectedLayout={(data && data.experiments && data.experiments.homeLayout) || ''}
        catalogueId={catalogueId}
        onClose={() => {
          setShowChangeLayoutDialog(false);
          closePopover();
        }}
      />
      <SortProductsDialog
        open={showSortProductsDialog}
        setTypeOfSort={setTypeOfSort}
        onClose={toggleShowSortDialog}
      />
      <ConfirmSortDialog
        open={Object.keys(typeOfSort).length > 0}
        onClose={() => {
          setTypeOfSort({});
          setShowSortProductsDialog(false);
        }}
        catalogueId={catalogueId}
        typeOfSort={typeOfSort}
        setShowSortProducts={setShowSortProductsDialog}
      />
    </>
  );
};
