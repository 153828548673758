import { CUSTOM_PRODUCT_PROPERTIES } from '../../Apis/CustomProductProperties/ApiCacheConnector';
import { getCacheKeyForCustomProductProperties } from '../../Helpers/CSVUploader/CacheHelpers';
import CacheRequest from '../CacheRequest';

const cacheKey = getCacheKeyForCustomProductProperties();

export const attachCustomPropertiesListener = ({ listener }) => {
  CacheRequest.attachListener(cacheKey, listener);
};

export const removeCustomPropertiesListener = ({ listener }) => {
  CacheRequest.removeListener(cacheKey, listener);
};

export const getCustomProductProperties = () =>
  CacheRequest.makeRequest(cacheKey, CUSTOM_PRODUCT_PROPERTIES.apiFunction, {
    params: [],
    options: {
      shouldNotStoreInNative: true
    }
  });

export const getCustomProductPropertiesFromCache = () => CacheRequest.getCacheForKey(cacheKey);

export const setCustomProductPropertiesInCache = ({ updates }) => {
  const existingCustomFields = getCustomProductPropertiesFromCache();
  CacheRequest.setCacheForKey(cacheKey, {
    fields: [...(existingCustomFields.fields || []), ...(updates.fields || [])],
    variants: [...(existingCustomFields.variants || []), ...(updates.variants || [])]
  });
};

export const deleteCustomProductPropertiesFromCache = () =>
  CacheRequest.deleteCacheForKeys([cacheKey]);
