import React, { useEffect, useState } from 'react';
import { changeLanguage } from 'i18next';
import Catalogue from 'qs-components/Catalogue';
import Orders from 'qs-components/Orders';
import AccountSettings from 'qs-components/AccountSettings';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import UserInfoBar from 'qs-components/Common/UserInfoBar';
import SideNavbar from 'qs-components/Common/SideNavbar';
import LoginScreen from 'qs-components/Authentication/LoginScreen';
import Loader from 'qs-components/Common/Loader';
import firebase from 'qs-config/FirebaseConfig';
import {
  onLogout,
  getIsLoggingIn,
  checkAndHandleUserAccessToWeb,
  IS_LOGGING_IN
} from 'qs-data-manager/Authentication';
import AppOnReadyHandler from 'qs-helpers/AppOnReadyHandler';
import eventbus from 'eventing-bus';
import LimboState from 'qs-components/Common/LimboState';
import { formWindowEndpointForCatalogue, localstorageExists } from 'qs-helpers';
import { navigateToPath, sideBarStateOnMount, SIDE_BARS_META, showSideBars } from 'qs-data-manager';
import FeatureLimitModal from 'qs-components/Common/FeatureLimitModal';
import ResellerModal from 'qs-components/Common/ResellerModal/ResellModal';
import ForwardToShowroom from 'qs-components/Common/ForwardToShowroom/ForwardToShowroom';
import RenderForMobile from 'qs-components/Common/RenderForMobile/RenderForMobile';
import ProductLibraryForCustomPagesIframe from 'qs-components/Common/ProductLibraryForCustomPagesIframe';
import HtmlSectionForCustomPagesIframe from 'qs-components/Common/HtmlSectionForCustomPagesIframe';
import { checkIfMobile } from 'qs-helpers';
import {
  setCompanyMetaInCache,
  getCompanyIdFromCache,
  setCompanyLevelGoldRateAndProductTypeInCache
} from 'qs-data-manager/Company';
import { setUserMetaInCache } from 'qs-data-manager/User';
import Api from 'qs-services/Api';
import { saveCurrentPlan, saveFeatureLimits } from 'qs-data-manager/FeatureUsage';
import { generateUniqueDeviceIdIfNotExists } from 'qs-helpers/DeviceIdGenerator';
import { APP_LANGUAGE_CHANGED_EB_KEY, ROUTE_MAP } from 'qs-helpers/CompanySettings/constants';

import './styles.scss';

export default () => {
  const [isReady, setIsReady] = useState(false); // Ensures firebase auth is ready
  const [loginState, setLoginState] = useState({
    isLoggedIn: false,
    loggingIn: false,
    isSetupIncomplete: false
  });
  const [showBars, setShowBars] = useState(() => {
    return sideBarStateOnMount();
  });
  const [isMobile] = useState(() => checkIfMobile());

  useEffect(() => {
    const key = SIDE_BARS_META.EVENT_BUS;
    const removeListener = eventbus.on(key, value => {
      setShowBars(value);
    });

    return () => {
      removeListener();
    };
  }, []);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      const pathHasLogin = window.location.href.includes(`/${ROUTE_MAP.LOGIN}`);
      const pathHasNothing = window.location.pathname === '/';

      const isLoggingIn = getIsLoggingIn();
      let isLoggedIn = !!user;

      const setReadyState = () => {
        setTimeout(() => {
          setIsReady(true);
          setLoginState({
            isLoggedIn: isLoggedIn,
            loggingIn: isLoggingIn,
            isSetupIncomplete: false
          });
        }, 100);
      };

      if (user && !isLoggingIn) {
        const navigateToCatalogue = () => {
          try {
            navigateToPath(`/${ROUTE_MAP.CATALOGUES}`);
          } catch (error) {
            window.location.href = formWindowEndpointForCatalogue();
          }

          setReadyState();
          showSideBars();
        };
        const storageExists = localstorageExists();
        const remoteDataPromises = [];
        if (!storageExists) {
          remoteDataPromises.push(Api.getCompanyAndUserObject());
        }
        remoteDataPromises.push(
          Api.getCurrentPlan(),
          Api.getPaymentPlan(),
          Api.getGoldRatesAndProductType()
        );
        Promise.all(remoteDataPromises)
          .then(remoteDataList => {
            let index = 0;
            const { productType } = remoteDataList[2];
            setCompanyLevelGoldRateAndProductTypeInCache({ data: productType });
            // Company meta call was made, ensure that data is processed
            if (!storageExists) {
              const { company, user } = remoteDataList[index];
              setCompanyMetaInCache({ data: company });
              setUserMetaInCache({ data: user });

              if (company && user && (!company.name || !user.phone)) {
                setIsReady(true);
                setLoginState({
                  isLoggedIn: isLoggedIn,
                  loggingIn: isLoggingIn,
                  isSetupIncomplete: true
                });
                if (!pathHasLogin) {
                  navigateToPath(`/${ROUTE_MAP.LOGIN}`);
                }
                return eventbus.publish(IS_LOGGING_IN.eventbusKey, {
                  setupIncomplete: true,
                  isLoggingIn: true,
                  company,
                  user
                });
              }
              index += 1;
            }
            const currentPlan = remoteDataList[index];
            const allowed = checkAndHandleUserAccessToWeb(user, currentPlan);
            if (!allowed) {
              return;
            }

            saveCurrentPlan(currentPlan);
            index += 1;
            saveFeatureLimits(remoteDataList[index]);

            //Complete pending checks
            if (pathHasLogin || pathHasNothing) {
              navigateToCatalogue();
            } else {
              setReadyState();
            }
            generateUniqueDeviceIdIfNotExists({ companyId: getCompanyIdFromCache() });
          })
          .catch(err => {
            onLogout();
            setReadyState();
          });
      } else if (!user) {
        if (!pathHasLogin || pathHasNothing) {
          onLogout();
        }

        setTimeout(() => {
          setIsReady(true);
          setLoginState({
            isLoggedIn: isLoggedIn,
            loggingIn: isLoggingIn,
            isSetupIncomplete: false
          });
        }, 100);
      }
    });
  }, []);

  useEffect(() => {
    const removeEventbus = eventbus.on(APP_LANGUAGE_CHANGED_EB_KEY, languageCode => {
      changeLanguage(languageCode);
    });
    return () => removeEventbus();
  }, []);

  if (isMobile) {
    return <RenderForMobile />;
  }

  return (
    <div id={'Navigation'} className="appWrapper">
      {showBars && isReady && <UserInfoBar />}
      <div className="screenWrapper" id="screenWrapper">
        {isReady ? (
          <AppOnReadyHandler loginState={loginState}>
            <Router>
              {showBars && <SideNavbar isReady={isReady} />}
              <Switch>
                <Route exact path="/" component={LimboState} />
                <Route path={`/${ROUTE_MAP.CATALOGUES}`} component={Catalogue} />
                <Route path={`/${ROUTE_MAP.INQUIRIES}`} component={Orders} />
                <Route path={`/${ROUTE_MAP.ACCOUNT_SETTINGS}`} component={AccountSettings} />
                <Route
                  exact
                  path={`/${ROUTE_MAP.ACCOUNT_SETTINGS}/:id`}
                  component={AccountSettings}
                />
                <Route path={`/${ROUTE_MAP.LOGIN}`} component={LoginScreen} />
                <Route exact path={`/${ROUTE_MAP.LOGIN}/:id`} component={LoginScreen} />
                <Redirect from="*" to={`/${ROUTE_MAP.CATALOGUES}`} />
              </Switch>
              <HtmlSectionForCustomPagesIframe />
            </Router>
            <FeatureLimitModal />
            <ResellerModal />
            <ForwardToShowroom />
            <ProductLibraryForCustomPagesIframe />
          </AppOnReadyHandler>
        ) : (
          <div className={'authNotReady'}>
            <Loader size={'large'} />
          </div>
        )}
      </div>
    </div>
  );
};
