import React, { useState, useEffect, useCallback } from 'react';
import './style.scss';
import DomainDialog from '../DomainDialog';
import { getCatalogCount, deleteDomainData } from 'qs-api/Domain/api';
import { ReactComponent as ConnectedIcon } from 'qs-assets/Media/checkmark_filled.svg';
import { ReactComponent as DeletedIcon } from 'qs-assets/Media/icon_delete_link.svg';
import { DialogActions, DialogContent, DialogTitle, Button } from '@material-ui/core';
import {
  PrimaryButton,
  DialogDarkTheme,
  SecondaryButton
} from '../../../../Common/DarkThemeDialog';
import toastr from 'toastr';
import { getI18N } from 'qs-services/i18N';

export default function AddDomain({ type, domain, status, update }) {
  const [dialogDetail, setDialogDetail] = useState(false);
  const [domainType, setDomainType] = useState();
  const [catalogueCount, setCatalogueCount] = useState(0);
  const [domainStatus, setDomainStatus] = useState(status);
  const [showDisconnectConfirm, setShowDisconnectConfirm] = useState(false);

  const { t } = getI18N();

  const getCount = useCallback(() => {
    if (type !== 'catalog') {
      return;
    }
    getCatalogCount()
      .then(data => setCatalogueCount(data.catalogueCount))
      .catch(e => e);
  }, [type]);

  useEffect(() => {
    getCount();
  }, [getCount]);

  const disconnectDomain = () => {
    deleteDomainData({ type: type, domainName: domain })
      .then(res => {
        setDomainStatus(res.operation);
        toggleDeleteConfirm();
        toastr.success(t('domain_is_disconnected_successfully', { domain }));
        updateDomain();
      })
      .catch(() => {
        toggleDeleteConfirm();
        toastr.error(t('an_error_occured_while_disconnecting_domain'));
      });
  };

  const updateDomain = () => {
    if (typeof update === 'function') {
      update();
    }
  };

  const handleClose = () => {
    setDialogDetail(false);
  };

  const dialog = type => {
    setDomainType(type);
    setDialogDetail(true);
  };

  const toggleDeleteConfirm = useCallback(() => {
    setShowDisconnectConfirm(showConfirm => !showConfirm);
  }, []);

  return (
    <div className="domain-container">
      <div className="domain-info">
        <div className="info">{domain}</div>
        {domainStatus === 'connected' && (
          <div className="statusInfo">
            <div className="info">
              <ConnectedIcon fill="#4DA47A" style={{ height: '20px', marginRight: '10px' }} />
              <div> {t('connected')}</div>
            </div>
            {type === 'catalog' && (
              <div className="info">
                {t('showing_catalogues_to_public', { count: catalogueCount })}
              </div>
            )}
            <div
              className="info"
              style={{
                color: '#CE7171',
                cursor: 'pointer',
                textDecorationStyle: 'solid',
                textDecorationLine: 'underline',
                textUnderlineOffset: '4px'
              }}
              onClick={() => setShowDisconnectConfirm(true)}
            >
              {t('disconnect_domain')}
            </div>
          </div>
        )}
        {domainStatus === 'connecting' && type === 'custom' && (
          <div className="statusInfo">
            <div className="info" style={{ color: '#E7C76C' }}>
              {t('in_progress')}
            </div>
            <div className="info" style={{ color: '#6F7B91' }}>
              {t('once_you_have_updated_your_dns_settings_come_back_and_check_again')}.
            </div>
          </div>
        )}
        {domainStatus === 'disconnected' && (
          <div className="statusInfo">
            <div className="info" style={{ color: '#CE7171' }}>
              <DeletedIcon style={{ height: '20px', marginRight: '10px' }} />
              {t('not_connected')}
            </div>
          </div>
        )}
      </div>
      <div style={{ marginTop: '10px' }}>
        <Button
          className="change-button"
          style={{ backgroundColor: '#1D875D' }}
          onClick={() => dialog(type)}
        >
          <span>{t('change')}</span>
        </Button>
      </div>
      {dialogDetail && (
        <DomainDialog
          domainType={domainType}
          domainName={domain}
          dialogClose={handleClose}
          updateDomain={updateDomain}
        />
      )}
      {showDisconnectConfirm && (
        <DialogDarkTheme open={true} onClose={toggleDeleteConfirm}>
          <DialogTitle>{t('delete_domain')}</DialogTitle>
          <DialogContent>
            {t('are_you_sure_you_want_to_disconnect')} {domain} {t('domain')}?
          </DialogContent>
          <DialogActions>
            <SecondaryButton onClick={toggleDeleteConfirm}>
              <span>{t('cancel')}</span>
            </SecondaryButton>
            <PrimaryButton color={'primary'} onClick={disconnectDomain}>
              <span>{t('yes')}</span>
            </PrimaryButton>
          </DialogActions>
        </DialogDarkTheme>
      )}
    </div>
  );
}
