import React from 'react';
import './styles.scss';
import Loader from '../Loader';
import WithNavRef from 'qs-helpers/WithNavRef';

export default props => {
  return (
    <WithNavRef navigationRef={props}>
      <div id={'LimboState'}>
        <Loader size={'large'} />
      </div>
    </WithNavRef>
  );
};
