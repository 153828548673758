import AppConfig from 'qs-config/AppConfig';
import { IN_STOCK_TAG, OUT_OF_STOCK_TAG } from 'qs-helpers/Products/constants';
import request from '../../Request';

export const getProductWeight = productId =>
  request
    .authGet(`${AppConfig.qsApiEndPoint}/v1/product/weight?productId=${productId}`)
    .then(response => (response || {}).productDetails);

export const getProductList = catalogueId =>
  request.authGet(
    `${AppConfig.qsApiEndPoint}/v1/catalogue/product-list?catalogueId=${catalogueId}`
  );

export const getProductListSearchParams = catalogueId =>
  request.authPost(`${AppConfig.qsApiEndPoint}/v2/search/product/parameters/app`, { catalogueId });

export const addNewVideo = async ({ videos, productId }) => {
  try {
    const data = await request.authPost(`${AppConfig.qsApiEndPoint}/v1/product/videos`, {
      productId,
      videos
    });
    const {
      uploadCredentials: { AccessKeyId, Expiration, SecretAccessKey, SessionToken },
      videos: videosData
    } = data;

    const videoData = {
      AccessKeyId,
      Expiration,
      SecretAccessKey,
      SessionToken,
      videoId: videosData[0].videoId,
      videosData: videosData[0],
      videoDuration: videos[0].duration,
      productId: productId
    };
    return videoData;
  } catch (error) {
    throw error;
  }
};

export const deleteProductVideo = async videoIds => {
  try {
    await request.authDelete(`${AppConfig.qsApiEndPoint}/v1/product/videos`, {
      videoIds: [videoIds]
    });
  } catch (error) {
    // Handle error
  }
};

export const checkVideoUploadStatus = async videoIds => {
  try {
    const videoStatus = await request.authPost(
      `${AppConfig.qsApiEndPoint}/v1/product/videos-status`,
      {
        videoIds: [videoIds]
      }
    );

    return videoStatus;
  } catch (error) {
    // Handle error
  }
};

export const getProductVideoUploadCredentials = videoIds => {
  return request.authPost(`${AppConfig.qsApiEndPoint}/v1/product/video-upload-credentials`, {
    videoIds
  });
};

export const getFilteredProductList = async (
  catalogueId,
  searchTerm,
  priceFilters,
  selectedTags,
  variantSelection,
  customFieldsSelection
) => {
  const filter = {};
  let returnAggregatedTags = false;
  if (selectedTags instanceof Set && selectedTags.size > 0) {
    const tags = [];
    for (const tagId of selectedTags) {
      if (tagId === IN_STOCK_TAG) {
        filter.inStock = true;
        continue;
      }

      if (tagId === OUT_OF_STOCK_TAG) {
        filter.outOfStock = true;
        continue;
      }

      tags.push(tagId);
    }

    if (tags.length > 0) {
      filter.tags = tags;
    }
  }

  if (priceFilters) {
    filter.price = priceFilters;
    returnAggregatedTags = true;
  }

  if (variantSelection) {
    filter.variants = Object.keys(variantSelection).map(variantType => ({
      type: variantType,
      values: variantSelection[variantType]
    }));
    returnAggregatedTags = true;
  }

  if (Array.isArray(customFieldsSelection) && customFieldsSelection.length > 0) {
    filter.customFields = customFieldsSelection;
    returnAggregatedTags = true;
  }

  let search;
  if (typeof searchTerm === 'string') {
    search = searchTerm;
    returnAggregatedTags = true;
  }

  const filterProducts = await request.authPost(
    `${AppConfig.qsApiEndPoint}/v1/search/product/app`,
    {
      catalogueId,
      filter,
      search,
      returnAggregatedTags
    }
  );

  //Match catalogueProducts response structure
  const productsList = filterProducts.productIds.map(productId => ({ productId }));
  delete filterProducts.productIds;
  return {
    ...filterProducts,
    productsList
  };
};

export const sortProductsForCatalogue = data =>
  request.authPost(`${AppConfig.qsApiEndPoint}/v1/product/sort`, data);

export const sortPictures = data =>
  request.authPost(`${AppConfig.qsApiEndPoint}/v1/product/rearrange-pictures`, data);
