import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

import AppConfig from 'qs-config/AppConfig';

let config, generalDatabaseURL;
if (AppConfig.env === 'development') {
  config = {
    apiKey: 'AIzaSyABzfqrEHkJkaSTooHh2EJ7BSmdp1VCxvM',
    authDomain: 'project-7804444097795271748.firebaseapp.com',
    databaseURL: 'https://project-7804444097795271748.firebaseio.com',
    projectId: 'project-7804444097795271748',
    storageBucket: 'project-7804444097795271748.appspot.com',
    messagingSenderId: '1031733541585'
  };
  generalDatabaseURL = 'https://qs-general-db-stage.firebaseio.com';
} else {
  config = {
    apiKey: 'AIzaSyD1U5aoVoFVxs2GhJ7Q0mpHwL25fJwks2U',
    authDomain: 'sx-app-a04c1.firebaseapp.com',
    databaseURL: 'https://sx-app-a04c1.firebaseio.com',
    projectId: 'sx-app-a04c1',
    storageBucket: 'sx-app-a04c1.appspot.com',
    messagingSenderId: '223646330354'
  };
  generalDatabaseURL = 'https://qs-general-db.firebaseio.com';
}

const app = firebase.initializeApp(config);

export const db = app.database();
export const generalDb = app.database(generalDatabaseURL);

export default firebase;
