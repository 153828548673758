import React from 'react';
import SelectInput from '../../../../../../../Common/SelectInput';
import { SELECT_VALUE } from '../../ProductTypeDetails';
import './styles.scss';
import { getI18N } from 'qs-services/i18N';

const materialThemeStyles = {
  formSelectionBorder: '2px solid #eaeaea',
  selectTextColor: '#00000',
  selectedItemBackgroundColor: '#ffffff',
  selectedItemColor: '#00000'
};

const StonesDropDown = ({
  title,
  options = [],
  value,
  setAddStoneDetails,
  keyName,
  mandatory,
  autoCalculate = false,
  errorMessage,
  setErrorMessage
}) => {
  const { t } = getI18N();
  const updatedOption = [
    {
      value: SELECT_VALUE.id.toLowerCase(),
      displayValue: t(SELECT_VALUE.displayValue)
    }
  ];
  options.forEach(singleOption => {
    updatedOption.push(singleOption);
  });
  if (keyName === 'discountType') {
    updatedOption.shift();
  }
  const onDropDownChange = e => {
    const { value, name } = e.target;
    setAddStoneDetails(prevState => {
      return {
        ...prevState,
        [name]: value === SELECT_VALUE.id.toLowerCase() ? '' : value.toUpperCase()
      };
    });
    if (errorMessage[name]) {
      setErrorMessage(prevState => {
        return {
          ...prevState,
          [name]: ''
        };
      });
    }
  };

  return (
    <div className="stonesDropDown" style={{ flex: '1 1 calc(50% - 40px)' }}>
      {title && (
        <div className="title">
          {title}
          {autoCalculate || mandatory ? (
            <span
              style={{
                ...(mandatory ? { color: '#FF4572' } : {}),
                ...(autoCalculate ? { color: '#0820FA' } : {})
              }}
            >
              {' '}
              *
            </span>
          ) : null}
        </div>
      )}

      <div className="setDropDownWrapper" style={!title ? { paddingTop: 25 } : {}}>
        <SelectInput
          containerClass={'productSetDropDownContainer'}
          value={value || 'select_value'}
          onChange={e => onDropDownChange(e)}
          options={updatedOption}
          materialStyles={materialThemeStyles}
          menuStyles={{
            backgroundColor: '#fff',
            color: '#000',
            textTransform: 'capitalize'
          }}
          name={keyName}
        />
      </div>
      {errorMessage && Object.keys(errorMessage).length > 0 && errorMessage[keyName] && (
        <span className="error">
          {typeof errorMessage[keyName] === 'string'
            ? t(errorMessage[keyName])
            : errorMessage[keyName]}
        </span>
      )}
    </div>
  );
};

export default StonesDropDown;
