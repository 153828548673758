import {
  attachCataloguesProductLibSearchListener,
  clearCatalogueSearchDataFromCache,
  getCataloguesProductLibListFromCache,
  removeCataloguesProductLibSearchListener,
  searchCataloguesFromLibrary
} from 'qs-data-manager/Catalogues/CatalogueProductLibrary';
import {
  attachCatalogueHeadlineListener,
  getCatalogueHeadlineData,
  getCatalogueHeadlineFromCache,
  removeCatalogueHeadlineListener
} from 'qs-data-manager/Catalogues/Headline';
import {
  getPublishToHomepageFromCache,
  attachPublishToHomepageListener,
  removePublishToHomepageListener
} from 'qs-data-manager/Catalogues/PublishToHomepage';
import {
  getCatalogueLabelsFromCache,
  attachCatalogueLabelsListener,
  getCatalogueLabelsData,
  removeCatalogueLabelsListener
} from 'qs-data-manager/Catalogues/CatalogueLabels';
import CatalogueLib from 'qs-data-manager/Catalogues';

export const getCachedCataloguesProductLib = () => getCataloguesProductLibListFromCache();

export const fetchCataloguesProductLibHandler = ({
  listener,
  changeDependancy: [forceLoad, activeCatalogueId, searchTerm]
}) => {
  attachCataloguesProductLibSearchListener({ listener });
  searchCataloguesFromLibrary({ searchTerm, forceLoad, activeCatalogueId });
};

export const handleCataloguesProductLibCleanup = ({ listener }) => {
  removeCataloguesProductLibSearchListener({ listener });
  clearCatalogueSearchDataFromCache();
};

export const getCachedCatalogueHeadline = ({ changeDependancy: [catalogueId] }) =>
  getCatalogueHeadlineFromCache({ catalogueId });

export const fetchCatalogueHeadlineHandler = ({ listener, changeDependancy: [catalogueId] }) => {
  attachCatalogueHeadlineListener({ listener, catalogueId });
  getCatalogueHeadlineData({ catalogueId });
};

export const handleCatalogueHeadlineCleanup = ({ listener, changeDependancy: [catalogueId] }) => {
  removeCatalogueHeadlineListener({ catalogueId, listener });
};

export const getCachedPublishToHomepage = ({ changeDependancy: [catalogueId] }) =>
  getPublishToHomepageFromCache(catalogueId);

export const setupPublishToHomepageHandler = ({ listener, changeDependancy: [catalogueId] }) => {
  attachPublishToHomepageListener({ listener, catalogueId });
};

export const handlePublishToHomepageCleanup = ({ listener, changeDependancy: [catalogueId] }) => {
  removePublishToHomepageListener({ listener, catalogueId });
};

export const getCachedCatalogueLabels = () => getCatalogueLabelsFromCache();

export const fetchCatalogueLabelsHandler = ({ listener }) => {
  attachCatalogueLabelsListener({ listener });
  getCatalogueLabelsData();
};

export const attachListenerToCatalogueLabel = ({ listener }) =>
  attachCatalogueLabelsListener({ listener });

export const handleCatalogueLabelsCleanup = ({ listener }) => {
  removeCatalogueLabelsListener({ listener });
};

export const getCachedCatalogueSettings = ({ changeDependancy: [catalogueId] }) =>
  CatalogueLib.getCatalogueSettingsFromCacheForDropDown({ catalogueId });

export const fetchCatalogueSettingsHandler = ({ listener, changeDependancy: [catalogueId] }) => {
  CatalogueLib.attachCatalogueSettingsListener({ catalogueId, listener });
  CatalogueLib.getCatalogueSettings({ catalogueId });
};

export const handleCatalogueSettingsCleanup = ({ listener, changeDependancy: [catalogueId] }) => {
  CatalogueLib.removeCatalogueSettingsListener({ listener, catalogueId });
};
