import React, { useEffect, useCallback, useState } from 'react';
import CatalogueRow from './CatalogueRow';
import { selectedCatalogue, selectedLabels } from 'qs-data-manager/Selected';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import CatalogueLib, { clearActiveCatalogueId } from 'qs-data-manager/Catalogues';
import Loader from 'qs-components/Common/Loader';
import EmptyCatalogueList from './EmptyCatalogueList';
import NoCatalogueFound from './NoCatalogueFound';
import {
  CREATING_NEW_CATALOGUE,
  CATALOGUE_SEARCH,
  CATALOGUE_SEARCH_STATE,
  CATALOGUE_SEARCH_TERM,
  CATALOGUE_ROW_TYPES
} from 'qs-services/DataManager/Catalogues';
import eventbus from 'eventing-bus';
import CatalogueSearchResult from './CatalogueSearchResult';
import { modifyCatalogueSearchResult } from 'qs-helpers';
import './styles.scss';

let SEARCH_RESP_MAINTAINER = null;
let LIST_REF = null;

export default () => {
  const [listState, setListState] = useState(() => {
    const catalogueMeta = CatalogueLib.getCatalogueIdsFromCache();
    const loading = !catalogueMeta;
    const { catalogueIds } = catalogueMeta || {};

    return {
      loading,
      refreshing: false,
      error: false,
      catalogueIds,
      searchResult: ''
    };
  });
  const [searchMode, setSearchMode] = useState(false);
  const [newCatalogueMode, setNewCatalogueMode] = useState({
    isCreatingNewCatalogue: false,
    catalogueHeaderCallback: null
  });
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    CatalogueLib.attachCatalogueIdsListener(catalogueIdsListener);
    if (!selectedLabels.isAnySelected()) {
      CatalogueLib.getAllCatalogueIds();
    }
    return () => CatalogueLib.removeCatalogueIdsListener(catalogueIdsListener);
  }, []);

  const catalogueIdsListener = (err, { status, data } = {}) => {
    const updates = {};
    if (err) {
      updates.loading = false;
      updates.error = err;
    } else {
      switch (status) {
        case CatalogueLib.OPERATION_STATUS.LOADING: {
          updates.loading = true;
          updates.refreshing = false;
          updates.error = null;
          break;
        }
        case CatalogueLib.OPERATION_STATUS.REFRESHING: {
          updates.loading = false;
          updates.refreshing = true;
          updates.error = null;
          updates.catalogueIds = data.catalogueIds || [];
          break;
        }
        case CatalogueLib.OPERATION_STATUS.SUCCESS: {
          updates.loading = false;
          updates.refreshing = false;
          updates.error = null;
          updates.catalogueIds = data.catalogueIds || [];
          break;
        }
        case CatalogueLib.OPERATION_STATUS.UPDATE: {
          updates.loading = false;
          updates.refreshing = false;
          updates.error = null;
          updates.catalogueIds = data.catalogueIds || [];
          break;
        }
        default:
      }

      if (updates.catalogueIds) {
        updates.catalogueIds = CatalogueLib.computeUniqueSortedIds({
          catalogueIdsMeta: updates.catalogueIds
        });

        const ids = updates.catalogueIds.map(({ catalogueId }) => catalogueId);

        selectedCatalogue.setAllItems(ids);
      }
      setListState(prevState => ({ ...prevState, ...updates }));
    }
  };

  useEffect(() => {
    const showAllCatalogues = () => {
      const { catalogueIds } = CatalogueLib.getCatalogueIdsFromCache();
      setShowLoader(false);
      setListState(prevState => ({
        ...prevState,
        catalogueIds,
        searchResult: ''
      }));
    };
    const removeEventbus = eventbus.on(CATALOGUE_SEARCH, async ({ type, value }) => {
      if (type === CATALOGUE_SEARCH_STATE) {
        setSearchMode(value);
        if (!value) {
          showAllCatalogues();
        }
      } else if (type === CATALOGUE_SEARCH_TERM) {
        if (!value.length) {
          showAllCatalogues();
          return;
        } else if (value.length < 2) {
          return;
        }

        setShowLoader(true);
        SEARCH_RESP_MAINTAINER = Date.now();
        const lastResp = SEARCH_RESP_MAINTAINER;
        const { data } = await CatalogueLib.searchCatalogueIds(value);
        const shouldApplyResp = (() => SEARCH_RESP_MAINTAINER === lastResp)();

        if (!shouldApplyResp) {
          return;
        }

        SEARCH_RESP_MAINTAINER = null;
        setShowLoader(false);
        const { catalogues } = data;
        const catalogueIdMap = {};
        const allCatalogues = CatalogueLib.getCatalogueIdsFromCache() || {};
        (allCatalogues.catalogueIds || []).forEach(row => {
          catalogueIdMap[row.catalogueId] = row;
        });
        const searchedCatalogueIds = (catalogues || [])
          .map(catalogue => catalogueIdMap[catalogue.catalogue_id])
          .filter(({ catalogueId } = {}) => !!catalogueId);

        const result = modifyCatalogueSearchResult({
          searchedIdsLength: searchedCatalogueIds.length
        });

        setListState(prevState => {
          return {
            ...prevState,
            catalogueIds: searchedCatalogueIds,
            searchResult: result
          };
        });
      }
    });
    return () => removeEventbus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [(listState.catalogueIds || []).length, searchMode]);

  // Effect for creating new catalogue
  useEffect(() => {
    const removeEventbus = eventbus.on(
      CREATING_NEW_CATALOGUE.NEW_CATALOGUE_EB_KEY,
      (messageType, extraData) => {
        if (messageType === CREATING_NEW_CATALOGUE.CREATING_CATALOGUE) {
          setListState(prevState => ({
            ...prevState,
            catalogueIds: [
              { type: CATALOGUE_ROW_TYPES.SKELETON_CATALOGUE.type },
              ...prevState.catalogueIds
            ]
          }));
          setNewCatalogueMode({
            isCreatingNewCatalogue: true,
            catalogueHeaderCallback: extraData.callback
          });
          if (LIST_REF) {
            LIST_REF.scrollToItem(0);
          }
        } else if (messageType === CREATING_NEW_CATALOGUE.DELETE_CATALOGUE) {
          setListState(prevState => {
            return {
              ...prevState,
              catalogueIds: prevState.catalogueIds.filter(({ catalogueId } = {}) => !!catalogueId)
            };
          });
          setNewCatalogueMode({
            isCreatingNewCatalogue: false,
            catalogueHeaderCallback: null
          });
        } else if (messageType === CREATING_NEW_CATALOGUE.SAVE_CATALOGUE) {
          newCatalogueMode.catalogueHeaderCallback();
        }
      }
    );

    return () => removeEventbus();
  }, [newCatalogueMode]);

  useEffect(() => {
    return () => clearActiveCatalogueId();
  }, []);

  const renderRow = useCallback(({ data, index, style }) => {
    const row = data[index];
    return (
      <div style={style}>
        <CatalogueRow
          id={row.catalogueId}
          isSkeleton={!!row.type && row.type === CATALOGUE_ROW_TYPES.SKELETON_CATALOGUE.type}
        />
      </div>
    );
  }, []);

  const itemKey = useCallback((index, data) => `${data[index].catalogueId}${index}`, []);

  if (listState.loading || showLoader) {
    return (
      <div id={'catalogueListLoaderContainer'}>
        <Loader size={'large'} color={'white'} />
      </div>
    );
  }

  if (searchMode && listState && listState.catalogueIds && !listState.catalogueIds.length) {
    return <NoCatalogueFound />;
  }

  if (listState && listState.catalogueIds && !listState.catalogueIds.length) {
    return <EmptyCatalogueList />;
  }

  return (
    <div id={'mainCatalogueScreen'}>
      <CatalogueSearchResult result={listState.searchResult} />
      <AutoSizer>
        {({ height, width }) => (
          <List
            ref={ref => (LIST_REF = ref)}
            height={listState.searchResult ? height - 50 : height}
            itemData={listState.catalogueIds}
            itemCount={listState.catalogueIds.length}
            itemKey={itemKey}
            itemSize={CATALOGUE_ROW_TYPES.CATALOGUE_META.height}
            width={width}
            overscanCount={CATALOGUE_ROW_TYPES.CATALOGUE_META.overscanCount}
          >
            {renderRow}
          </List>
        )}
      </AutoSizer>
    </div>
  );
};
