import React, { useEffect, useState } from 'react';
import {
  getVariantsDataForProductFromCache,
  attachProductVariantListener,
  getVariantsDataForProduct,
  removeProductVariantListener
} from 'qs-data-manager/Variants/FetchVariants';
import Inventory from './Inventory';
import CacheListenerCallback from 'qs-helpers/CacheListenerCallback';
import useRefreshHandlerHook from 'qs-hooks/refreshHandlerHook';
import VariantInventory from './VariantInventory';
import Loader from '../../../../Common/Loader';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

export default ({ showProductImage, isBulkEditing, activeProductId, activeProductIds }) => {
  let cachedVariantList;
  if (!isBulkEditing) {
    cachedVariantList = getVariantsDataForProductFromCache(activeProductId);
  }

  const [productVariantStatus, updateProductVariantStatus] = useRefreshHandlerHook(
    cachedVariantList
  );
  const [variantList, setVariantList] = useState(cachedVariantList);

  const showProductInventory = variantArray => {
    if (variantArray.length !== 0) {
      return false;
    }

    return true;
  };

  const { t } = getI18N();

  useEffect(() => {
    if (isBulkEditing) {
      // Set to empty array so that the inventory view is shown
      setVariantList([]);
      return;
    }

    // Get the data of the variants of the active product
    const cachedListForProduct = getVariantsDataForProductFromCache(activeProductId);
    setVariantList(cachedListForProduct);
    updateProductVariantStatus({
      data: cachedListForProduct,
      localRefresh: true
    });

    const productVariantListener = (error, payload) => {
      // Callback when cache is manipulated
      const dataFromListener = CacheListenerCallback(error, payload);
      updateProductVariantStatus(dataFromListener);
      const { err, loading, refreshing, data } = dataFromListener;
      if (err || loading || refreshing || !data) {
        return;
      }

      setVariantList(data);

      // Decide whether to show the image or not
      if (showProductInventory(data.variantProductIds)) {
        showProductImage(true);
        return;
      }

      showProductImage(false);
    };

    attachProductVariantListener({ productId: activeProductId, listener: productVariantListener });
    getVariantsDataForProduct({ productId: activeProductId });
    return () => {
      removeProductVariantListener({
        productId: activeProductId,
        listener: productVariantListener
      });
    };
  }, [activeProductId, isBulkEditing, showProductImage, updateProductVariantStatus]);

  // In bulk edit mode directly return the inventory mode, do not process anything else
  if (isBulkEditing) {
    return (
      <div className="productInventoryContainer">
        <Inventory
          isBulkEditing={isBulkEditing}
          activeProductId={activeProductId}
          activeProductIds={activeProductIds}
        />
      </div>
    );
  }

  const { error, loading } = productVariantStatus;

  if (error) {
    return (
      <div className={'variantInventoryNoContentContainer'}>
        <span className="errorMsg">
          {t('something_went_wrong_while_fetching_variants_for_this_product')}
        </span>
      </div>
    );
  }

  const { variantProductIds } = variantList || {};

  if (loading || !Array.isArray(variantProductIds)) {
    return (
      <div className={'variantInventoryNoContentContainer'}>
        <Loader size={'large'} />
      </div>
    );
  }

  if (showProductInventory(variantProductIds)) {
    return (
      <div className="productInventoryContainer">
        <Inventory isBulkEditing={false} activeProductId={activeProductId} />
      </div>
    );
  }

  return <VariantInventory variantList={variantProductIds} activeProductId={activeProductId} />;
};
