import React from 'react';
import './styles.scss';

const LabelTitle = ({ title, isActive, onClick }) => (
  <div
    className={`catalogueLabelElement ${isActive && 'selectedCatalogueLabel'}`}
    onClick={onClick}
  >
    <div className={'ellipsis labelTitle'}>{title}</div>
    <div className={`rightDiv ${isActive && 'selectedRightDiv'}`}></div>
  </div>
);

export default LabelTitle;
