import { CATALOGUE_TAGS_LIST } from 'qs-api/CatalogueTags/ApiCacheConnector';
import { IN_STOCK_TAG, OUT_OF_STOCK_TAG } from 'qs-helpers/Products/constants';
import CacheRequest from '../CacheRequest';

const createCacheKeyForTags = ({ catalogueId, productFilter }) =>
  `${CATALOGUE_TAGS_LIST}${catalogueId}${productFilter ? '_PRODUCT-FILTER' : ''}`;

export const attachCatalogueTagsListener = ({ listener, catalogueId, productFilter }) => {
  CacheRequest.attachListener(createCacheKeyForTags({ catalogueId, productFilter }), listener);
};

export const removeCatalogueTagsListener = ({ listener, catalogueId, productFilter }) => {
  CacheRequest.removeListener(createCacheKeyForTags({ catalogueId, productFilter }), listener);
};

export const deleteProductsFilteredCatalogueTags = catalogueId =>
  CacheRequest.deleteCacheForKeys([createCacheKeyForTags({ catalogueId, productFilter: true })]);

export const setCatalogueFilterTagsInCache = ({
  catalogueId,
  updates: { tags, inStockCount, outOfStockCount }
}) => {
  if (!tags) {
    deleteProductsFilteredCatalogueTags(catalogueId);
    return;
  }

  if (typeof inStockCount === 'number' && inStockCount > 0) {
    tags.push({ tag: IN_STOCK_TAG, productCount: inStockCount });
  }
  if (typeof outOfStockCount === 'number' && outOfStockCount > 0) {
    tags.push({ tag: OUT_OF_STOCK_TAG, productCount: outOfStockCount });
  }

  CacheRequest.setCacheForKey(createCacheKeyForTags({ catalogueId, productFilter: true }), {
    tags,
    productFiltered: true
  });
};
