import React, { memo, useCallback, useContext } from 'react';
import { ActiveVariantId } from '../../../context';
import Loader from '../../../../../Common/Loader';
import useVariantMetaHook from '../../../Hooks/variantMetaHook';
import ErrorIcon from '../../../../../Common/ErrorIcon';
import './styles.scss';

const VariantLabelView = memo(({ variantId, variantIdChange, onRender, index }) => {
  const variantMetaData = useVariantMetaHook(variantId);
  const { loading, error, metaData: { label } = {} } = variantMetaData;

  /*
    Intentionally skip adding dependancies to the hook so that the function remains
    refrentially stable and hence the ref callback is not executed more than once
    throughout the components lifetime. This list view can not be modified as long
    as it is in view, hence this can be executed safely
  */
  const labelRendered = useCallback(ref => {
    if (ref) {
      onRender(index, variantId, ref.offsetWidth);
    } else {
      onRender(index);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error) {
    return (
      <div className="horizontalListView">
        <ErrorIcon />
      </div>
    );
  }

  if (loading) {
    return (
      <div className="horizontalListView">
        <Loader size="small" />
      </div>
    );
  }

  return (
    <div className="horizontalListView activeView" onClick={() => variantIdChange(variantId)}>
      <span className="labelView" ref={labelRendered}>
        {label}
      </span>
    </div>
  );
});

export default ({ data, style, index, reRenderListOnItemUpdate }) => {
  const currentVariantId = data[index][0];
  const { activeVariantId, setActiveVariantId } = useContext(ActiveVariantId);
  let activeVariantClass = '';
  if (activeVariantId === currentVariantId) {
    activeVariantClass = 'activeVariant';
  }

  return (
    <div style={style} className={`variantColContainer ${activeVariantClass}`}>
      <VariantLabelView
        variantId={currentVariantId}
        variantIdChange={setActiveVariantId}
        onRender={reRenderListOnItemUpdate}
        index={index}
      />
    </div>
  );
};
