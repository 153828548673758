import { MINIMUM_ORDER_QUANTITY } from 'qs-api/Moq/ApiCacheConnector';
import Mixpanel from 'qs-data-manager/Mixpanel';
import {
  attachMoqListener,
  getMoqForEntity,
  getMoqForEntityFromCache,
  removeMoqListener
} from 'qs-data-manager/Moq/MoqOperations';

export const getCacheKeyForMoq = ({ entityId, entityType }) =>
  `${MINIMUM_ORDER_QUANTITY.cacheKey}${entityId}${entityType}`;

export const fetchMoqForEntityHandler = ({
  listener,
  changeDependancy: [entityId, entityType, computed]
}) => {
  if (!(entityId && entityType)) {
    return;
  }

  attachMoqListener({ listener, entityId, entityType });
  getMoqForEntity({ entityId, entityType, computed });
};

export const handleMoqForEntityCleanup = ({
  listener,
  changeDependancy: [entityId, entityType]
}) => {
  if (!(entityId && entityType)) {
    return;
  }
  removeMoqListener({ listener, entityId, entityType });
};

export const getCachedMoqData = ({ changeDependancy: [entityId, entityType] }) =>
  getMoqForEntityFromCache({ entityId, entityType });

export const sendMoqAnalyticsEvent = ({ entityType }) => {
  Mixpanel.sendEvent({
    eventName: 'moq_saved',
    props: {
      type: entityType
    }
  });
};
