import React, { useCallback, useEffect, useState } from 'react';
import ProductTagsSelector from './ProductTagsSelector/ProductTagsSelector';
import Loader from 'qs-components/Common/Loader';
import CatalogueLib, { getActiveCatalogueId } from 'qs-data-manager/Catalogues';
import CacheCallback from 'qs-helpers/CacheListenerCallback';
import Tags from 'qs-data-manager/Tags';
import { getI18N } from 'qs-services/i18N';

import './styles.scss';

export default ({ loading, tags, activeProductId, activeProductIds = [], isBulkEditing }) => {
  const catalogueId = getActiveCatalogueId();
  const [unselectedTags, setUnselectedTags] = useState(
    isBulkEditing ? [] : CatalogueLib.getAllUnselectedTags(tags)
  );
  const [bulkCommonTags, setBulkCommonTags] = useState([]);
  const [fetchingBulkTags, setFetchingBulkTags] = useState(false);

  const { t } = getI18N();

  useEffect(() => {
    if (activeProductIds && activeProductIds.length) {
      setFetchingBulkTags(true);

      Tags.fetchCommonBulkTags({ productIds: activeProductIds }).then(selectedTags => {
        const catalogueId = getActiveCatalogueId();
        const unselected = Tags.computeUnSelectedFromSelected(catalogueId, selectedTags);
        setBulkCommonTags(selectedTags);
        setUnselectedTags(unselected);
        setFetchingBulkTags(false);
      });
    }
  }, [activeProductIds, tags]);

  useEffect(() => {
    const updateState = () => {
      const unselectedTags = CatalogueLib.getAllUnselectedTags(tags);
      setUnselectedTags(unselectedTags);
    };

    const listener = (error, payload) => {
      const { refreshing } = CacheCallback(error, payload);

      if (refreshing) {
        updateState();
      }
    };

    if (!isBulkEditing) {
      const catalogueId = getActiveCatalogueId();

      CatalogueLib.attachCatalogueTagsListener(listener, catalogueId);
      updateState();
    }

    return isBulkEditing
      ? () => {}
      : () => CatalogueLib.removeCatalogueTagsListener(listener, catalogueId);
  }, [catalogueId, isBulkEditing, tags]);

  const refreshTags = () => {};

  const onUnselectedTagPress = useCallback(
    tag => {
      const pos = unselectedTags.indexOf(tag);

      if (pos === -1) {
        return;
      }

      setUnselectedTags([...unselectedTags.slice(0, pos), ...unselectedTags.slice(pos + 1)]);
      if (isBulkEditing) {
        setBulkCommonTags(prevState => {
          return [...prevState, tag];
        });
      }

      const productIds = isBulkEditing ? [...activeProductIds] : [activeProductId];
      const catalogueId = getActiveCatalogueId();

      Tags.addProductsTag({
        catalogueId: catalogueId,
        productIds: productIds,
        tag
      });
    },
    [unselectedTags, activeProductIds, activeProductId, isBulkEditing]
  );

  const addNewTagFromModal = useCallback(tag => {
    setBulkCommonTags(prevState => {
      return [...prevState, tag];
    });
  }, []);

  const updateTagsFromModal = useCallback(({ newTags, tag }) => {
    setBulkCommonTags(newTags);
    setUnselectedTags(prevState => [...prevState, tag]);
  }, []);

  return (
    <div id="ProductTags">
      <div className={'rowContainer'}>
        <div className={'rowHeading'}>{t('sub_categories')}s</div>
      </div>

      {loading || fetchingBulkTags ? (
        <div className={'TagsLoader'}>
          <Loader size={'small'} />
        </div>
      ) : (
        <ProductTagsSelector
          productId={isBulkEditing ? null : activeProductId}
          productIds={isBulkEditing ? activeProductIds : null}
          isBulkEdit={isBulkEditing}
          catalogueId={catalogueId}
          selectedTags={isBulkEditing ? bulkCommonTags : tags}
          unselectedTags={unselectedTags}
          refreshTags={refreshTags}
          onUnselectedTagPress={onUnselectedTagPress}
          addNewTagFromModal={addNewTagFromModal}
          updateTagsFromModal={updateTagsFromModal}
        />
      )}
    </div>
  );
};
