import { setMoqForEntityApi } from 'qs-api/Moq/api';
import { MINIMUM_ORDER_QUANTITY } from 'qs-api/Moq/ApiCacheConnector';
import { reportError } from 'qs-helpers/ErrorReporting';
import { getCacheKeyForMoq, sendMoqAnalyticsEvent } from 'qs-helpers/Moq/DataQueryHelper';
import CacheRequest from '../CacheRequest';

export const attachMoqListener = ({ listener, entityId, entityType }) => {
  CacheRequest.attachListener(getCacheKeyForMoq({ entityId, entityType }), listener);
};

export const removeMoqListener = ({ listener, entityId, entityType }) => {
  CacheRequest.removeListener(getCacheKeyForMoq({ entityId, entityType }), listener);
};

export const getMoqForEntity = ({ entityId, entityType, computed = false }) => {
  const key = getCacheKeyForMoq({ entityId, entityType });
  let apiName = MINIMUM_ORDER_QUANTITY.apiFunction;
  if (computed) {
    apiName = MINIMUM_ORDER_QUANTITY.computedApiFunction;
  }
  CacheRequest.makeRequest(key, apiName, {
    params: [entityId, entityType],
    options: {
      shouldNotStoreInNative: true
    }
  });
};

export const getMoqForEntityFromCache = ({ entityId, entityType }) =>
  CacheRequest.getCacheForKey(getCacheKeyForMoq({ entityId, entityType })) || undefined;

export const setMoqForEntityInCache = ({ entityId, entityType, updates }) => {
  const existingMoq = getMoqForEntityFromCache({ entityId, entityType }) || {};
  CacheRequest.setCacheForKey(getCacheKeyForMoq({ entityId, entityType }), {
    ...existingMoq,
    ...updates
  });
};

export const updateMoqForEntity = async ({ entityId, entityType, moqChange }) => {
  if (!Object.keys(moqChange).length || !entityId || !entityType) {
    return;
  }

  sendMoqAnalyticsEvent({ entityType });
  try {
    await setMoqForEntityApi(entityId, entityType, moqChange.minOrderQuantity);
  } catch (updateMoqError) {
    reportError(updateMoqError);
    throw updateMoqError;
  }

  setMoqForEntityInCache({ entityId, entityType, updates: moqChange });
};
