import React, { useState, useCallback, useEffect } from 'react';
import ProductHeaderOptions from './ProductHeaderOptions';
import EditProductsHeader from './EditProductsHeader';
import SearchProductHeader from './SearchProductHeader';
import './styles.scss';
import {
  PRODUCT_HEADER,
  EDIT_PRODUCTS_HEADER,
  SEARCH_PRODUCTS_HEADER,
  REORDER_PRODUCTS_HEADER,
  HEADERS
} from './constants';
import ReorderProductsHeader from './ReorderProductsHeader';

export default ({ activeCatalogueId, headerClass } = {}) => {
  const [productHeader, setProductHeader] = useState(PRODUCT_HEADER);

  useEffect(() => {
    setProductHeader(PRODUCT_HEADER);
  }, [activeCatalogueId]);

  const onClickHeaderOption = useCallback(headerType => {
    if (!headerType || !HEADERS[headerType]) {
      return;
    }
    setProductHeader(headerType);
  }, []);

  const onSearchIconClick = useCallback(() => {
    setProductHeader(SEARCH_PRODUCTS_HEADER);
  }, []);

  const setHeaderAsProductHeader = useCallback(() => {
    setProductHeader(PRODUCT_HEADER);
  }, []);

  if (productHeader === PRODUCT_HEADER) {
    return (
      <ProductHeaderOptions
        headerClass={headerClass}
        onClickHeaderOption={onClickHeaderOption}
        onSearchIconClick={onSearchIconClick}
        activeCatalogueId={activeCatalogueId}
      />
    );
  }

  if (productHeader === EDIT_PRODUCTS_HEADER) {
    return (
      <EditProductsHeader
        goBack={setHeaderAsProductHeader}
        headerClass={headerClass}
        activeCatalogueId={activeCatalogueId}
      />
    );
  }

  if (productHeader === REORDER_PRODUCTS_HEADER) {
    return <ReorderProductsHeader goBack={setHeaderAsProductHeader} headerClass={headerClass} />;
  }

  if (productHeader === SEARCH_PRODUCTS_HEADER) {
    return (
      <SearchProductHeader
        goBack={setHeaderAsProductHeader}
        headerClass={headerClass}
        activeCatalogueId={activeCatalogueId}
      />
    );
  }

  return null;
};
