import CatalogueGridTileIcon from 'qs-assets/Media/catalogue_grid_tile_icon.svg';
import { GRAPES_JS_PLUGINS } from '../Plugins';
import { GRAPES_JS_DEFAULT_CONFIG } from '../Plugins/config';
import { capitalizeFirstLetter } from 'qs-helpers';
import { getI18N } from 'qs-services/i18N';
import {
  BLACK_COLOR,
  WHITE_COLOR,
  TEXT_COLOR,
  BORDER_COLOR,
  ACCENT_COLOR,
  CATALOGUE_GRID_TILE_ELEMENT_TYPES,
  CATALOGUE_GRID_TILE_VARIABLES_MAP,
  CATALOGUE_GRID_TILE_CLASSNAMES_MAP,
  CATALOGUE_GRID_TILE_CLASSNAMES_PREFIX
} from '../Constants';

export const CATALOGUE_GRID_TILE_ELEMENT_TYPE_TRAIT_ID = `data-${CATALOGUE_GRID_TILE_CLASSNAMES_PREFIX}-element-type`;

export const CATALOGUE_GRID_TILE_ELEMENT_TYPE_TRAIT = {
  get CONFIG() {
    const { t } = getI18N();
    return {
      type: 'select',
      label: t('catalogue_grid_tile_element_type'),
      name: CATALOGUE_GRID_TILE_ELEMENT_TYPE_TRAIT_ID,
      placeholder: t('enter_catalogue_grid_tile_element_type'),
      default: 'none',
      options: [{
          id: 'none',
          name: t('none')
        },
        ...CATALOGUE_GRID_TILE_ELEMENT_TYPES.CONFIG
      ]
    };
  }
};

export const CATALOGUE_GRID_TILE_CUSTOM_COMPONENT = {
  get HTML() {
    const { t } = getI18N();
    return `
      <div
        class="${CATALOGUE_GRID_TILE_CLASSNAMES_MAP.catalogueGridTileContainer} gjs-row"
        data-${CATALOGUE_GRID_TILE_CLASSNAMES_MAP.catalogueGridTileId}="${CATALOGUE_GRID_TILE_VARIABLES_MAP.catalogueId}"
        data-quicksell-component-custom-name="${t('catalogue_grid_tile')}"
        data-quicksell-component-type="${CATALOGUE_GRID_TILE_CLASSNAMES_PREFIX}"
        data-quicksell-component-editable="false"
        data-quicksell-component-removable="false"
        data-quicksell-component-copyable="false"
        data-quicksell-component-draggable="false"
      >
        <div
          class="${CATALOGUE_GRID_TILE_CLASSNAMES_MAP.catalogueGridTileImageContainer} gjs-cell"
          data-${CATALOGUE_GRID_TILE_CLASSNAMES_MAP.catalogueGridTileImageContainer}="${CATALOGUE_GRID_TILE_VARIABLES_MAP.catalogueId}"
          data-quicksell-component-type="default"
          data-quicksell-component-editable="false"
          data-quicksell-component-removable="false"
          data-quicksell-component-copyable="false"
          data-quicksell-component-draggable="false"
        >
          <svg
            class="${CATALOGUE_GRID_TILE_CLASSNAMES_MAP.catalogueGridTileLockBadge}"
            data-${CATALOGUE_GRID_TILE_CLASSNAMES_MAP.catalogueGridTileLockBadge}="${CATALOGUE_GRID_TILE_VARIABLES_MAP.catalogueId}"
            viewBox="0 0 24 24"
            fill="${BLACK_COLOR}"
            data-quicksell-component-custom-name="${capitalizeFirstLetter(t('svg'))}"
            data-quicksell-component-type="svg"
            data-quicksell-component-editable="false"
            data-quicksell-component-removable="false"
            data-quicksell-component-copyable="false"
            data-quicksell-component-draggable="false"
            data-quicksell-component-layerable="false"
          >
            <path
              d="M 12 1 C 8.6761905 1 6 3.6761905 6 7 L 6 8 C 4.9 8 4 8.9 4 10 L 4 20 C 4 21.1 4.9 22 6 22 L 18 22 C 19.1 22 20 21.1 20 20 L 20 10 C 20 8.9 19.1 8 18 8 L 18 7 C 18 3.6761905 15.32381 1 12 1 z M 12 3 C 14.27619 3 16 4.7238095 16 7 L 16 8 L 8 8 L 8 7 C 8 4.7238095 9.7238095 3 12 3 z M 12 13 C 13.1 13 14 13.9 14 15 C 14 16.1 13.1 17 12 17 C 10.9 17 10 16.1 10 15 C 10 13.9 10.9 13 12 13 z"
              fill="${BLACK_COLOR}"
              data-quicksell-component-type="svg-in"
              data-quicksell-component-editable="false"
              data-quicksell-component-removable="false"
              data-quicksell-component-copyable="false"
              data-quicksell-component-draggable="false"
              data-quicksell-component-layerable="false"
            >
            </path>
          </svg>
          <img
            class="${CATALOGUE_GRID_TILE_CLASSNAMES_MAP.catalogueGridTileImage}"
            data-${CATALOGUE_GRID_TILE_CLASSNAMES_MAP.catalogueGridTileImage}="${CATALOGUE_GRID_TILE_VARIABLES_MAP.catalogueId}"
            data-${CATALOGUE_GRID_TILE_CLASSNAMES_MAP.catalogueGridTileImageId}="${CATALOGUE_GRID_TILE_VARIABLES_MAP.pictureId}"
            data-${CATALOGUE_GRID_TILE_CLASSNAMES_PREFIX}-element-type="${CATALOGUE_GRID_TILE_CLASSNAMES_MAP.catalogueGridTileImage}"
            src="${CATALOGUE_GRID_TILE_VARIABLES_MAP.pictureUrl}"
            alt="${CATALOGUE_GRID_TILE_VARIABLES_MAP.pictureAlt}"
            decoding="sync"
            loading="lazy"
            height="${CATALOGUE_GRID_TILE_VARIABLES_MAP.pictureHeight}"
            data-${CATALOGUE_GRID_TILE_CLASSNAMES_MAP.catalogueGridTileImageHeight}="${CATALOGUE_GRID_TILE_VARIABLES_MAP.pictureHeight}"
            width="${CATALOGUE_GRID_TILE_VARIABLES_MAP.pictureWidth}"
            data-${CATALOGUE_GRID_TILE_CLASSNAMES_MAP.catalogueGridTileImageWidth}="${CATALOGUE_GRID_TILE_VARIABLES_MAP.pictureWidth}"
            data-quicksell-component-type="image"
            data-quicksell-component-editable="false"
            data-quicksell-component-removable="false"
            data-quicksell-component-copyable="false"
            data-quicksell-component-draggable="false"
          />
        </div>
        <div
          class="${CATALOGUE_GRID_TILE_CLASSNAMES_MAP.catalogueGridTileLoader}"
          data-${CATALOGUE_GRID_TILE_CLASSNAMES_MAP.catalogueGridTileLoader}="${CATALOGUE_GRID_TILE_VARIABLES_MAP.catalogueId}"
          data-quicksell-component-type="text"
          data-quicksell-component-editable="false"
          data-quicksell-component-removable="false"
          data-quicksell-component-copyable="false"
          data-quicksell-component-draggable="false"
          data-quicksell-component-layerable="false"
        >
          <svg
            class="${CATALOGUE_GRID_TILE_CLASSNAMES_MAP.catalogueGridTileLoaderSvg}"
            data-${CATALOGUE_GRID_TILE_CLASSNAMES_MAP.catalogueGridTileLoaderSvg}="${CATALOGUE_GRID_TILE_VARIABLES_MAP.catalogueId}"
            viewBox="0 0 38 38"
            height="24"
            width="24"
            stroke="${ACCENT_COLOR}"
            data-quicksell-component-custom-name="${capitalizeFirstLetter(t('svg'))}"
            data-quicksell-component-type="svg"
            data-quicksell-component-editable="false"
            data-quicksell-component-removable="false"
            data-quicksell-component-copyable="false"
            data-quicksell-component-draggable="false"
            data-quicksell-component-layerable="false"
          >
            <g data-quicksell-component-type="svg-in" fill="none" fill-rule="evenodd">
              <g data-quicksell-component-type="svg-in" transform="translate(1 1)" stroke-width="2">
                <circle data-quicksell-component-type="svg-in" stroke-opacity=".5" cx="18" cy="18" r="18"/>
                <path data-quicksell-component-type="svg-in" d="M36 18c0-9.94-8.06-18-18-18">
                </path>
              </g>
            </g>
          </svg>
        </div>
        <div
          class="${CATALOGUE_GRID_TILE_CLASSNAMES_MAP.catalogueGridTileDetailsContainer} gjs-cell"
          data-${CATALOGUE_GRID_TILE_CLASSNAMES_MAP.catalogueGridTileDetailsContainer}="${CATALOGUE_GRID_TILE_VARIABLES_MAP.catalogueId}"
          data-quicksell-component-type="default"
          data-quicksell-component-editable="false"
          data-quicksell-component-removable="false"
          data-quicksell-component-copyable="false"
          data-quicksell-component-draggable="false"
        >
          <div
            class="${CATALOGUE_GRID_TILE_CLASSNAMES_MAP.catalogueGridTileTitle}"
            data-${CATALOGUE_GRID_TILE_CLASSNAMES_MAP.catalogueGridTileTitle}="${CATALOGUE_GRID_TILE_VARIABLES_MAP.catalogueId}"
            data-${CATALOGUE_GRID_TILE_CLASSNAMES_PREFIX}-element-type="${CATALOGUE_GRID_TILE_CLASSNAMES_MAP.catalogueGridTileTitle}"
            data-quicksell-component-type="text"
            data-quicksell-component-editable="false"
            data-quicksell-component-removable="false"
            data-quicksell-component-copyable="false"
            data-quicksell-component-draggable="false"
          >
            ${CATALOGUE_GRID_TILE_VARIABLES_MAP.catalogueTitle}
          </div>
          <div
            class="${CATALOGUE_GRID_TILE_CLASSNAMES_MAP.catalogueGridTileProductCount}"
            data-${CATALOGUE_GRID_TILE_CLASSNAMES_MAP.catalogueGridTileProductCount}="${CATALOGUE_GRID_TILE_VARIABLES_MAP.catalogueId}"
            data-${CATALOGUE_GRID_TILE_CLASSNAMES_PREFIX}-element-type="${CATALOGUE_GRID_TILE_CLASSNAMES_MAP.catalogueGridTileProductCount}"
            data-quicksell-component-type="text"
            data-quicksell-component-editable="false"
            data-quicksell-component-removable="false"
            data-quicksell-component-copyable="false"
            data-quicksell-component-draggable="false"
          >
            ${CATALOGUE_GRID_TILE_VARIABLES_MAP.productCount}
          </div>
        </div>
      </div>
    `;
  },
  get STYLE() {
    return `
      html,
      body {
        height: 100%;
        min-height: 100%;
        max-height: 100%;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        padding: 0px 0px 0px 0px;
        margin: 0px 0px 0px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }

      .${CATALOGUE_GRID_TILE_CLASSNAMES_MAP.catalogueGridTileContainer} {
        height: 100%;
        min-height: 0px;
        max-height: 100%;
        width: 100%;
        min-width: 0px;
        max-width: 100%;
        margin: 0px 0px 0px 0px;
        padding: 0px 0px 0px 0px;
        background-repeat: repeat;
        background-position: left top;
        background-attachment: scroll;
        background-size: auto;
        background-color: ${WHITE_COLOR};
        border-width: 1px 1px 1px 1px;
        border-style: solid;
        border-color: ${BORDER_COLOR};
        border-radius: 10px 10px 10px 10px;
        box-shadow: 0px 0px 5px rgba(125, 125, 125, 0.2);
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        flex-wrap: nowrap;
        flex-direction: column;
        overflow-x: hidden;
        overflow-y: hidden;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        text-decoration: none;
      }

      .${CATALOGUE_GRID_TILE_CLASSNAMES_MAP.catalogueGridTileContainer}:hover .${CATALOGUE_GRID_TILE_CLASSNAMES_MAP.catalogueGridTileImageContainer} .${CATALOGUE_GRID_TILE_CLASSNAMES_MAP.catalogueGridTileImage} {
        transform: scale(1.05);
      }

      .${CATALOGUE_GRID_TILE_CLASSNAMES_MAP.catalogueGridTileImageContainer} {
        height: auto;
        min-height: 75px;
        max-height: 100%;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        padding: 0px 0px 0px 0px;
        margin: 0px 0px 0px 0px;
        border-radius: 9px 9px 0px 0px;
        top: 0px;
        right: 0px;
        left: 0px;
        bottom: 0px;
        border-width: 0px 0px 0px 0px;
        border-style: solid;
        border-color: ${BORDER_COLOR};
        color: ${TEXT_COLOR};
        cursor: pointer;
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: auto;
        position: relative;
        overflow-x: hidden;
        overflow-y: hidden;
        transition: all 0.2s ease-in-out;
        text-decoration: none;
      }

      .${CATALOGUE_GRID_TILE_CLASSNAMES_MAP.catalogueGridTileLockBadge} {
        height: 50px;
        min-height: 50px;
        max-height: 50px;
        width: 50px;
        min-width: 50px;
        max-width: 50px;
        padding: 13px 13px 13px 13px;
        margin: 0px 0px 0px 0px;
        border-radius: 50% 50% 50% 50%;
        background-color: ${WHITE_COLOR};
        color: ${BLACK_COLOR};
        object-fit: contain;
        display: none;
        cursor: pointer;
        overflow-x: hidden;
        overflow-y: hidden;
        position: absolute;
        top: 50%;
        left: 50%;
        bottom: 0px;
        right: 0px;
        box-shadow: 5px 5px 10px rgba(25, 25, 25, 0.6);
        transform: translate(-50%, -50%);
        transition: all 0.2s ease-in-out;
        z-index: 3;
        text-decoration: none;
      }

      .${CATALOGUE_GRID_TILE_CLASSNAMES_MAP.catalogueGridTileImage} {
        height: 100%;
        min-height: 100%;
        max-height: 300px;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        color: ${TEXT_COLOR};
        object-fit: cover;
        border-radius: 9px 9px 0px 0px;
        display: inline-block;
        cursor: pointer;
        overflow-x: hidden;
        overflow-y: hidden;
        vertical-align: middle;
        transition: all 0.2s ease-in-out;
        text-decoration: none;
      }

      .${CATALOGUE_GRID_TILE_CLASSNAMES_MAP.catalogueGridTileDetailsContainer} {
        height: auto;
        min-height: auto;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        margin: 0px 0px 0px 0px;
        padding: 8px 10px 8px 10px;
        border-radius: 0px 0px 9px 9px;
        border-width: 0px 0px 0px 0px;
        border-style: solid;
        border-color: ${BORDER_COLOR};
        color: ${TEXT_COLOR};
        font-weight: 400;
        font-size: 14px;
        text-align: center;
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: auto;
        cursor: pointer;
        background-repeat: repeat;
        background-position: left top;
        background-attachment: scroll;
        background-size: auto;
        background-color: ${WHITE_COLOR};
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        text-decoration: none;
        transition: all 0.2s ease-in-out;
      }

      .${CATALOGUE_GRID_TILE_CLASSNAMES_MAP.catalogueGridTileDetailsContainer}:empty  {
        display: none;
      }

      .${CATALOGUE_GRID_TILE_CLASSNAMES_MAP.catalogueGridTileLoader} {
        height: 52px;
        min-height: 52px;
        max-height: 52px;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        padding: 12px 10px 12px 10px;
        margin: 0px 0px 0px 0px;
        display: none;
        align-items: center;
        justify-content: center;
      }

      .${CATALOGUE_GRID_TILE_CLASSNAMES_MAP.catalogueGridTileLoaderSvg} {
        height: 100%;
        min-height: 100%;
        max-height: 100%;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        padding: 0px 10px 0px 10px;
        margin: 0px 0px 0px 0px;
        animation: rotating 1s linear infinite;
      }

      .${CATALOGUE_GRID_TILE_CLASSNAMES_MAP.catalogueGridTileTitle} {
        width: 100%;
        color: ${TEXT_COLOR};
        font-size: 14px;
        font-weight: 700;
        text-align: center;
        margin: 0px 0px 0px 0px;
        padding: 0px 0px 0px 0px;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        display: -webkit-box;
        line-height: 1.2;
        overflow-x: hidden;
        overflow-y: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        text-decoration: none;
        word-wrap: break-word;
        word-break: break-word;
      }

      .${CATALOGUE_GRID_TILE_CLASSNAMES_MAP.catalogueGridTileTitle}:empty {
        display: none;
      }

      .${CATALOGUE_GRID_TILE_CLASSNAMES_MAP.catalogueGridTileProductCount} {
        width: 100%;
        color: ${TEXT_COLOR};
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        line-height: 20px;
        overflow-x: hidden;
        overflow-y: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin: 2.5px 0px 0px 0px;
        text-decoration: none;
        word-wrap: break-word;
        word-break: break-word;
      }

      .${CATALOGUE_GRID_TILE_CLASSNAMES_MAP.catalogueGridTileProductCount}:empty {
        display: none;
      }

      @keyframes rotating {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
    `;
  }
};

export const CATALOGUE_GRID_TILE_GRAPES_JS_CONFIG = {
  get CONFIG() {
    return {
      ...GRAPES_JS_DEFAULT_CONFIG.COMMON,
      container: '.custom-html-builder-editor',
      plugins: [...GRAPES_JS_PLUGINS, ...(GRAPES_JS_DEFAULT_CONFIG.COMMON.plugins || [])]
    };
  }
};

export const getCatalogueGridTypeThemeStyles = (theme) => {
  const defaultTheme = (!!theme && Object.keys(theme).length > 0) ? theme : {};
  const catalogueBoxShadow = (defaultTheme.homepage || {}).catalogueBoxShadow || 'none';
  const catalogueItemBackground = (defaultTheme.homepage || {}).catalogueItemBackground || WHITE_COLOR;
  const catalogueItemCountColor = (defaultTheme.homepage || {}).catalogueItemCountColor || TEXT_COLOR;
  const catalogueTitleColor = (defaultTheme.homepage || {}).catalogueTitleColor || TEXT_COLOR;
  const catalogueTileBorderColorCode = (defaultTheme.homepage || {}).catalogueTileBorderColorCode || BORDER_COLOR;
  const addToCartButtonBackgroundColor = (defaultTheme.addToCartButton || {}).backgroundColor || ACCENT_COLOR;
  return `
    .${CATALOGUE_GRID_TILE_CLASSNAMES_MAP.catalogueGridTileContainer} {
      color: ${catalogueTitleColor};
      border-color: ${catalogueTileBorderColorCode};
      background-color: ${catalogueItemBackground};
      box-shadow: ${catalogueBoxShadow};
    }
    .${CATALOGUE_GRID_TILE_CLASSNAMES_MAP.catalogueGridTileImageContainer} {
      color: ${catalogueTitleColor};
      background-color: ${catalogueItemBackground};
    }
    .${CATALOGUE_GRID_TILE_CLASSNAMES_MAP.catalogueGridTileImage} {
      color: ${catalogueTitleColor};
    }
    .${CATALOGUE_GRID_TILE_CLASSNAMES_MAP.catalogueGridTileDetailsContainer} {
      color: ${catalogueTitleColor};
      background-color: ${catalogueItemBackground};
    }
    .${CATALOGUE_GRID_TILE_CLASSNAMES_MAP.catalogueGridTileLoaderSvg} {
      stroke: ${addToCartButtonBackgroundColor};
    }
    .${CATALOGUE_GRID_TILE_CLASSNAMES_MAP.catalogueGridTileTitle} {
      color: ${catalogueTitleColor};
    }
    .${CATALOGUE_GRID_TILE_CLASSNAMES_MAP.catalogueGridTileProductCount} {
      color: ${catalogueItemCountColor};
    }
  `;
};

export const getCatalogueGridTileBlockConfig = (catalogueGridTileBlockContent) => {
  const { t } = getI18N();
  return {
    id: CATALOGUE_GRID_TILE_CLASSNAMES_PREFIX,
    type: CATALOGUE_GRID_TILE_CLASSNAMES_PREFIX,
    label: t('catalogue_grid_tile'),
    activate: true,
    media: `<img src="${CatalogueGridTileIcon}" height="52" width="52" style="height: 52px; width: 52px; object-fit: cover;" />`,
    category: t('quicksell'),
    copyable: catalogueGridTileBlockContent.copyable,
    'custom-name': catalogueGridTileBlockContent['custom-name'],
    draggable: catalogueGridTileBlockContent.draggable,
    editable: catalogueGridTileBlockContent.editable,
    removable: catalogueGridTileBlockContent.removable,
    tagName: catalogueGridTileBlockContent.tagName,
    attributes: catalogueGridTileBlockContent.attributes,
    content: {
      classes: catalogueGridTileBlockContent.classes,
      components: catalogueGridTileBlockContent.components
    }
  };
};

export const getCatalogueGridTileBlockComponent = (catalogueGridTileBlockContent) => ({
  model: {
    defaults: {
      copyable: catalogueGridTileBlockContent.copyable,
      'custom-name': catalogueGridTileBlockContent['custom-name'],
      draggable: catalogueGridTileBlockContent.draggable,
      editable: catalogueGridTileBlockContent.editable,
      removable: catalogueGridTileBlockContent.removable,
      tagName: catalogueGridTileBlockContent.tagName,
      attributes: catalogueGridTileBlockContent.attributes
    }
  }
});