import { isFileCSV } from 'qs-helpers';
import { RawFileData, DIRECTORY_TYPE, FILE_TYPE } from './RawFileData';
import {
  getImageTypeFromExtension,
  getVideoTypeFromExtension
} from 'qs-image-upload/Helpers/ImageAutoOrientation';

export const createNameForProduct = imageName => {
  const lastIndex = imageName.lastIndexOf('.');
  return imageName.substring(0, lastIndex);
};

export const isFileImage = file => {
  if (!file instanceof File) {
    return false;
  }

  if (file.type !== '') {
    if (/^image/.test(file.type)) {
      return true;
    }

    return false;
  }

  const imageType = getImageTypeFromExtension(file.name);
  if (typeof imageType === 'string') {
    return true;
  }

  return false;
};

export const isFileVideo = file => {
  if (!file instanceof File) {
    return false;
  }

  if (file.type !== '') {
    if (/^video/.test(file.type)) {
      return true;
    }

    return false;
  }

  const videoType = getVideoTypeFromExtension(file.name);
  if (typeof videoType === 'string') {
    return true;
  }

  return false;
};

const validateVideo = file => {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video');
    video.preload = 'metadata';

    video.onloadedmetadata = () => {
      window.URL.revokeObjectURL(video.src);
      const videoDuration = Number(parseFloat(video.duration).toFixed(2));
      const videoFileSize = Math.round(file.size / 1048576);

      if (videoDuration > 60) {
        reject('Video too long. Videos must be 60 seconds or less in length');
      } else if (videoFileSize > 200) {
        reject('Video size should be less than 200mb');
      } else {
        resolve(videoDuration);
      }
    };

    video.src = URL.createObjectURL(file);
  });
};

export const isFileFormatValid = file => {
  if (!file instanceof File) {
    return false;
  }

  if (isFileCSV(file)) {
    return true;
  }

  if (isFileImage(file)) {
    return true;
  }

  return false;
};

/**
 * @param {FileList} files
 */
export const processFileList = files => {
  if (!(files instanceof FileList)) {
    return [];
  }
  const allFiles = [];
  for (let index = 0; index < files.length; index++) {
    const file = files.item(index);
    if (!isFileFormatValid(file)) {
      continue;
    }
    const fileData = new RawFileData({ fileData: file });
    allFiles.push(fileData);
  }
  return allFiles;
};

/**
 * @param {RawFileData} rawFileData
 */
const getFileNameFromRawFileData = rawFileData => {
  if (rawFileData.type === DIRECTORY_TYPE) {
    return rawFileData.extraData.name;
  }

  if (rawFileData.type === FILE_TYPE) {
    return createNameForProduct(rawFileData.files[0].name);
  }
};

/**
 * @param {Array<RawFileData>} acceptedFiles
 */
export const getFirstFileMeta = acceptedFiles => {
  let firstFile = acceptedFiles[0],
    displayName = '';
  if (!(firstFile instanceof RawFileData)) {
    return {};
  }

  displayName = getFileNameFromRawFileData(firstFile);
  firstFile = firstFile.files[0];
  if (firstFile instanceof RawFileData) {
    displayName = getFileNameFromRawFileData(firstFile);
    firstFile = firstFile.files[0];
  }

  return { file: firstFile, displayName };
};

/**
 * @param {RawFileData} rawFileData
 */
export const unpackSingleFolderFiles = rawFileData => {
  const acceptedFiles = [];
  for (const fileData of rawFileData.files) {
    if (fileData instanceof RawFileData) {
      acceptedFiles.push(fileData);
      continue;
    }

    if (fileData instanceof File) {
      acceptedFiles.push(new RawFileData({ fileData }));
    }
  }
  return acceptedFiles;
};

/**
 * @param {RawFileData} rawFileData
 */
export const doesFolderContainOnlyFiles = rawFileData => {
  for (const fileData of rawFileData.files) {
    if (fileData instanceof RawFileData && fileData.type === DIRECTORY_TYPE) {
      return false;
    }
  }

  return true;
};

/**
 * @param {Array<RawFileData>} acceptedFiles
 */
export const isSingleFolderUploaded = acceptedFiles => {
  if (acceptedFiles.length !== 1) {
    return false;
  }

  const rawFileData = acceptedFiles[0];
  if (!(rawFileData instanceof RawFileData)) {
    return false;
  }

  return rawFileData.type === DIRECTORY_TYPE;
};

/**
 * @param {Array<RawFileData>} acceptedFiles
 */
export const getTotalLengthOfImages = acceptedFiles => {
  if (!Array.isArray(acceptedFiles)) {
    return 0;
  }

  let totalImages = 0;
  for (let index = 0; index < acceptedFiles.length; index++) {
    const rawFileData = acceptedFiles[index];
    totalImages += rawFileData.length;
  }
  return totalImages;
};

/**
 * @param {Array<RawFileData>} acceptedFiles
 */
export const removeCSVFromFinalList = acceptedFiles => {
  if (!Array.isArray(acceptedFiles)) {
    return acceptedFiles;
  }

  return acceptedFiles.filter(rawFileData => {
    const filteredRawFiles = rawFileData.files.filter(file => {
      if (isFileCSV(file)) {
        return false;
      }
      return true;
    });
    if (filteredRawFiles.length === 0) {
      return false;
    }

    rawFileData.files = filteredRawFiles;
    return true;
  });
};

/**
 * @param {FileList} fileList
 */
export const createDirectoryStructureFromRelativePath = fileList => {
  let folderName = '';
  const acceptedFiles = [];
  for (let index = 0; index < fileList.length; index++) {
    const fileEntry = fileList.item(index);
    const path = fileEntry.webkitRelativePath || fileEntry.relativePath || '';
    const pathParts = path.split('/');

    /*
      The relative path starts from the source directory, hence the path of a
      valid file will be folder/file. Hence if splitting does not yield an
      array of 2 elements, then either the patah is not a folder or the path
      is a nested folder which is not currently accepted
    */
    if (pathParts.length !== 2) {
      continue;
    }

    folderName = pathParts[0];
    acceptedFiles.push(fileEntry);
  }

  const rawFileData = new RawFileData({
    fileData: acceptedFiles,
    type: DIRECTORY_TYPE,
    extraData: {
      name: folderName
    }
  });

  if (!rawFileData.files) {
    return [];
  }

  return [rawFileData];
};

/**
 * @param {FileList} fileList
 */
export const filterImagesFromFiles = fileList => {
  const images = [];
  for (let index = 0; index < fileList.length; index++) {
    const file = fileList.item(index);
    if (isFileImage(file)) {
      images.push(file);
    }
  }

  return images;
};

/**
 * @param {FileList} fileList
 */
export const filterImagesAndVideoFromFiles = async fileList => {
  const mediaFiles = [];
  let videoCount = 0;
  for (let index = 0; index < fileList.length; index++) {
    const file = fileList.item(index);
    if (isFileImage(file)) {
      mediaFiles.push(file);
    } else if (isFileVideo(file)) {
      try {
        videoCount++;
        const duration = await validateVideo(file);
        if (typeof duration === 'number' && videoCount <= 1) {
          file.duration = duration;
          mediaFiles.push(file);
        } else {
          return { mediaFiles, error: 'cannot_upload_more_than_1_video_per_product' };
        }
      } catch (error) {
        return { mediaFiles, error };
      }
    }
  }

  return { mediaFiles };
};
