import React, { useReducer, useMemo, useContext } from 'react';
import Header from './Header';
import { VariantDataForUpdate } from './context';
import { variantDataReducer, variantInit } from './reducer';
import VariantBasicInfo from './VariantBasicInfo';
import VariantsQuickView from './VariantsQuickView';
import SetDefaultVariant from './SetDefaultVariant';
import ImageUploadProgressBar from '../../../Common/ImageUploadProgressBar';
import { UPLOAD_VARIANT_HEADER } from 'qs-helpers/ProcessUploadedImage';
import { ActiveVariantId } from '../context';
import './styles.scss';

export default ({ activeProductId }) => {
  const [variantData, setVariantData] = useReducer(variantDataReducer, undefined, variantInit);
  const { activeVariantId } = useContext(ActiveVariantId);

  const dataForContext = useMemo(
    () => ({
      variantData,
      setVariantData
    }),
    [variantData]
  );

  return (
    <div className="variantViewContainer">
      <VariantDataForUpdate.Provider value={dataForContext}>
        <Header activeProductId={activeProductId} />
        <VariantsQuickView activeProductId={activeProductId} />
        <ImageUploadProgressBar
          uploadHeader={UPLOAD_VARIANT_HEADER}
          mappingId={activeVariantId}
          containerClassName="imageUploadHeader"
        />
        <div className="variantDataContainer">
          <VariantBasicInfo activeProductId={activeProductId} />
          <SetDefaultVariant activeProductId={activeProductId} />
        </div>
      </VariantDataForUpdate.Provider>
    </div>
  );
};
