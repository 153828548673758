import { DialogActions, DialogContent } from '@material-ui/core';
import { saveOrderNotes } from 'qs-api/Orders/api';
import React, { useCallback, useEffect, useState } from 'react';
import {
  DialogDarkTheme,
  PrimaryButton,
  SecondaryButton
} from '../../../../../Common/DarkThemeDialog';
import Loader from '../../../../../Common/Loader';
import toastr from 'toastr';
import './styles.scss';
import { getI18N } from 'qs-services/i18N';

const EditOrderNoteDialog = ({
  open,
  setOpen,
  orderNoteText,
  addToInvoice,
  orderId,
  setOrderNoteText
}) => {
  const [orderNote, setOrderNote] = useState(orderNoteText || '');
  const [loading, setLoading] = useState(false);
  const isDisabled = !!orderNote && typeof orderNote === 'string' ? orderNote.length === 0 : true;
  const { t } = getI18N();

  const handleSaveOrderNote = useCallback(async () => {
    try {
      setLoading(true);
      await saveOrderNotes({
        orderId,
        notes: !!orderNote && typeof orderNote === 'string' ? orderNote : '',
        addToInvoice: addToInvoice
      });
      setOrderNoteText(orderNote);
      setOpen(false);
      toastr.success(t('order_notes_updated'));
    } catch (error) {
      toastr.error(error.message || t('error_saving_order_note'));
    } finally {
      setLoading(false);
    }
  }, [addToInvoice, orderId, orderNote, setOpen, setOrderNoteText, t]);

  useEffect(() => {
    setOrderNote(orderNoteText || '');
  }, [orderNoteText]);

  return (
    <DialogDarkTheme
      open={open}
      PaperProps={{
        className: 'orderNoteContentContainer',
        noValidate: true
      }}
      onClose={() => setOpen(false)}
    >
      <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
        <div
          style={{
            fontWeight: '700'
          }}
        >
          {t('order_note')}
        </div>
        <textarea
          className="editOrderNoteTextArea"
          onChange={e => setOrderNote(e.target.value)}
          value={orderNote}
          disabled={loading}
          placeholder={t('order_notes_placeholder')}
          autoFocus={true}
          autoCapitalize="sentences"
          autoComplete="off"
          autoCorrect="off"
        />
      </DialogContent>
      <DialogActions>
        <SecondaryButton onClick={() => setOpen(false)}>
          <span>{t('cancel')}</span>
        </SecondaryButton>
        <PrimaryButton
          color={'primary'}
          onClick={handleSaveOrderNote}
          type="submit"
          disabled={loading || isDisabled}
        >
          {loading ? <Loader size="extraSmall" /> : <span>{t('save_note')}</span>}
        </PrimaryButton>
      </DialogActions>
    </DialogDarkTheme>
  );
};

export default EditOrderNoteDialog;
