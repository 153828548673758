import Dexie from 'dexie';

export const NATIVE_STORAGE_EVENT_BUS_KEY = 'NATIVE_STORAGE_EVENT_BUS_KEY';
export const nativeDB = new Dexie('qsDesktop');

const nativeDbIndexingConfig = {
  cataloguesListMeta: 'catalogueId',
  catalogueRowMeta: '',
  productListMeta: '',
  productRowMeta: 'productId',
  catalogueTags: ''
};

nativeDB.version(1).stores(nativeDbIndexingConfig);

const nativeDbV2Config = {
  pictureMeta: 'pictureId, productId, catalogueId'
};

nativeDB.version(2).stores(nativeDbV2Config);

const nativeDbV3Config = {
  pictureMeta: null
};

nativeDB.version(3).stores(nativeDbV3Config);
