import { EDIT_NOT_ALLOWED } from 'qs-helpers/Orders/constants';

export const editNotAllowedReason = shippingConfig => {
  const { UNCONFIRMED_ORDER_REASONS, SHIPPING_PROVIDERS } = EDIT_NOT_ALLOWED;
  const { key } = shippingConfig || {};
  const errorReasons = SHIPPING_PROVIDERS[key];
  if (errorReasons) {
    return errorReasons;
  }
  return UNCONFIRMED_ORDER_REASONS;
};
