import React, { useState, useCallback, useContext } from 'react';
import Popover, { ArrowContainer } from 'react-tiny-popover';
import CatalogueTagsPopover from '../CatalogueTagsPopover';
import { ReactComponent as CircledDownIcon } from 'qs-assets/Media/circled_down.svg';
import { ReactComponent as CircledDownFilledIcon } from 'qs-assets/Media/circled_down_filled.svg';
import { ActiveCatalogueProductListMeta } from '../../../context';
import './styles.scss';
import { getI18N } from 'qs-services/i18N';

export default ({ sortedTags, onToggleTag }) => {
  const { t } = getI18N();

  const {
    productListMeta: { currentSelectedTags }
  } = useContext(ActiveCatalogueProductListMeta);

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const onClosePopover = useCallback(() => {
    setIsPopoverOpen(false);
  }, []);

  const onOpenPopover = useCallback(() => {
    setIsPopoverOpen(prevState => !prevState);
  }, []);

  return (
    <div className="catalogueTagsMenu">
      <Popover
        isOpen={isPopoverOpen}
        position={['bottom', 'right', 'left', 'top']}
        onClickOutside={onClosePopover}
        containerStyle={{ zIndex: 11, padding: 20, overflow: 'visible' }}
        content={({ position, targetRect, popoverRect }) => (
          <ArrowContainer
            position={position}
            targetRect={targetRect}
            popoverRect={popoverRect}
            arrowColor="#394757"
            arrowSize={10}
          >
            <CatalogueTagsPopover
              sortedTags={sortedTags}
              onClosePopover={onClosePopover}
              onToggleTag={onToggleTag}
              currentSelectedTags={currentSelectedTags}
            />
          </ArrowContainer>
        )}
      >
        <div className="tagsMenuButtonContainer">
          <button className="tagsMenuButton" onClick={onOpenPopover}>
            <div className="tagsCountContainer">
              <div className="tagsCount">{sortedTags.length}</div>
              <div className="tagsCountLabel">{t('sub_categories')}</div>
            </div>

            <CircledDownIcon
              fill="#000"
              className={`tagsMenuButtonIcon tagsMenuButtonIconNormal ${isPopoverOpen &&
                'tagsMenuButtonIconHidden'}`}
            />
            <CircledDownFilledIcon
              fill="#000"
              className={`tagsMenuButtonIcon tagsMenuButtonIconFilled ${isPopoverOpen &&
                'tagsMenuButtonIconVisible'}`}
            />
          </button>
        </div>
      </Popover>
    </div>
  );
};
