import React, { useRef, useState } from 'react';
import { getStockCountFromNewItemCount } from 'qs-helpers/Orders/ResponseProcessor';
import {
  DarkThemeTitle,
  DialogDarkTheme,
  PrimaryButton,
  SecondaryButton
} from '../../../../../Common/DarkThemeDialog';
import { DialogActions, DialogContent } from '@material-ui/core';
import ModifyCounter from '../../../../../Common/ModifyCounter';
import { RESET_COUNTER, UPDATE_COUNTER } from 'qs-helpers/constants';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

export default ({
  inquiryId,
  inquiryQuantity,
  stockCount,
  canUpdateStock,
  isSet,
  setMultiplier,
  onUpdate,
  onCancel,
  isFinalized
}) => {
  const [quantity, setQuantity] = useState(inquiryQuantity.toString());
  const [showOutOfStockConfirmation, setShowOutOfStockConfirmation] = useState(false);
  const stockDecrementAccepted = useRef(false);
  const savedPromiseResolver = useRef(null);
  const { t } = getI18N();

  //On receiving positive confirmation, immediately schedule the updates
  const onDecrementStockBeyondZero = () => {
    setShowOutOfStockConfirmation(false);
    stockDecrementAccepted.current = true;
    savedPromiseResolver.current({ operationKey: UPDATE_COUNTER, schedule: false });
    savedPromiseResolver.current = null;
  };

  const onBeyondZeroStockCancel = () => {
    setShowOutOfStockConfirmation(false);
    savedPromiseResolver.current({ operationKey: RESET_COUNTER });
    savedPromiseResolver.current = null;
  };

  const shouldDecrementStockBeyondZero = (_, quantityNum) =>
    new Promise(resolve => {
      // Either stock of this inquiry can't be updated or confirmation has
      // already been provided or updated stock count is more than zero,
      // no need for confirmation in any of these cases
      if (
        !canUpdateStock ||
        stockDecrementAccepted.current ||
        stockCount - Number(quantityNum) >= 0
      ) {
        resolve({ operationKey: UPDATE_COUNTER, schedule: true });
        return;
      }

      savedPromiseResolver.current = resolve;
      setShowOutOfStockConfirmation(true);
    });

  const getTotalQuantity = () => {
    if (!isSet || setMultiplier <= 1) {
      return quantity;
    }

    return quantity * setMultiplier;
  };

  const renderQuantityWithSet = () => {
    if (!isSet || setMultiplier <= 1) {
      return null;
    }

    return (
      <div className="additionalInquiryData">
        {t('total_quantity_count_and_set_quantity', { quantity: getTotalQuantity() })}
      </div>
    );
  };

  const renderRemainingStock = () => {
    if (!canUpdateStock || typeof stockCount !== 'number') {
      return null;
    }

    if (isFinalized && stockCount > 0) {
      stockCount = getStockCountFromNewItemCount({
        stockCount,
        itemCount: inquiryQuantity,
        updatedCount: quantity
      });
    }

    return (
      <div className="additionalInquiryData">
        {t('remaining_stock_and_set_quantity', {
          quantity: Math.max(stockCount, 0) * setMultiplier
        })}
      </div>
    );
  };

  const renderConfirmation = () => {
    if (!showOutOfStockConfirmation) {
      return null;
    }

    return (
      <DialogDarkTheme open={true}>
        <DialogContent>
          {t('this_product_may_go_out_of_stock_if_this_order_is_confirmed_by_you_or_your_customer')}
        </DialogContent>
        <DialogActions>
          <SecondaryButton onClick={onBeyondZeroStockCancel}>{t('no')}</SecondaryButton>
          <PrimaryButton color="primary" onClick={onDecrementStockBeyondZero}>
            {t('yes')}
          </PrimaryButton>
        </DialogActions>
      </DialogDarkTheme>
    );
  };

  const updateQuantityIfValidOnClose = () => {
    const quantityNum = Number(quantity);
    //Invalid number or current value same as the original value, no change required
    if (quantity === '' || Number.isNaN(quantityNum) || quantityNum === inquiryQuantity) {
      onCancel();
      return;
    }
    onUpdate(quantityNum);
  };

  return (
    <>
      <DialogDarkTheme open={true} onClose={updateQuantityIfValidOnClose}>
        <DarkThemeTitle>{t('edit_product_quantity')}</DarkThemeTitle>
        <DialogContent className="inquiryQuantityEditDialogContainer">
          <ModifyCounter
            key={inquiryId}
            quantity={quantity}
            setQuantity={setQuantity}
            originalCounter={inquiryQuantity}
            isUpdatePossible={shouldDecrementStockBeyondZero}
            scale={35}
          />
          {renderQuantityWithSet()}
          {renderRemainingStock()}
        </DialogContent>
      </DialogDarkTheme>
      {renderConfirmation()}
    </>
  );
};
