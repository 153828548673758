import { canUseFeature, FEATURE_LIST } from 'qs-data-manager/FeatureUsage';
import { roundNumberToGivenDecimals } from '..';
import { SAVE_BUTTON_META } from './constants';

// This method is registered only when isSet is shown, hence validate the set fields directly.
export const validateSetFields = ({ t, existingSetName, existingSetQuantity }) => savedData => {
  const nameField = savedData[SAVE_BUTTON_META.PRODUCT_SET_NAME_FIELD.id];
  const qtyField = savedData[SAVE_BUTTON_META.PRODUCT_SET_QTY_FIELD.id];

  if ((typeof nameField !== 'string' && !existingSetName) || nameField === '') {
    return {
      valid: false,
      errorMessage: t('set_name_is_required_when_creating_a_product_as_a_set')
    };
  }

  if (typeof qtyField !== 'number') {
    if (!existingSetQuantity) {
      return { valid: false, errorMessage: t('please_enter_the_number_of_items_in_a_single_set') };
    }
  } else {
    if (qtyField <= 0) {
      return { valid: false, errorMessage: t('please_enter_non_zero_quantity') };
    }
  }

  return { valid: true };
};

export const getSetPrice = ({ price, discount, isSet }) => {
  if (!isSet) {
    return;
  }

  if (discount === '' || discount === null || discount === undefined) {
    return price;
  }
  return discount;
};

export const getProductPriceDetails = ({ price, discount, isSet, setQuantity, isBulkEditing }) => {
  const stringifiedSetQty = setQuantity ? Number(setQuantity).toString() : null;
  if (isBulkEditing) {
    return {
      price: '',
      discount: '',
      setPrice: '',
      setQty: stringifiedSetQty
    };
  }

  if (!isSet || !canUseFeature(FEATURE_LIST.SET_WISE_ORDERING.id)) {
    return {
      price: getPricePerPieceAsString(price),
      discount: getPricePerPieceAsString(discount),
      setPrice: '',
      setQty: stringifiedSetQty
    };
  }

  let setPrice = getSetPrice({ price, discount, isSet });
  if (setPrice === null || setPrice === undefined) {
    setPrice = '';
  }
  if (setPrice !== '') {
    setPrice = roundNumberToGivenDecimals(Number(setPrice), 2).toString();
  }

  return {
    price: getPricePerPieceAsString(price, stringifiedSetQty),
    discount: getPricePerPieceAsString(discount, stringifiedSetQty),
    setPrice,
    setQty: stringifiedSetQty
  };
};

export const getPricePerPieceAsString = (priceVal, setQty) => {
  if (priceVal === '' || priceVal === null || priceVal === undefined) {
    return '';
  }

  // Ignore the passed value. If set is not enabled then setQty will not take effect
  if (!canUseFeature(FEATURE_LIST.SET_WISE_ORDERING.id)) {
    setQty = 1;
  }

  return roundNumberToGivenDecimals(Number(priceVal) / Number(setQty || 1), 2).toString();
};

export const getPriceAsStringFromSetQty = (priceVal, setQty = 1) => {
  if (priceVal === '' || priceVal === null || priceVal === undefined) {
    return '';
  }

  // Ignore the passed value. If set is not enabled then setQty will not take effect
  if (!canUseFeature(FEATURE_LIST.SET_WISE_ORDERING.id)) {
    setQty = 1;
  }

  return roundNumberToGivenDecimals(Number(priceVal) * Number(setQty)).toString();
};

export const getPriceForSet = (price, isSet, setQty = 1) => {
  // Ignore the passed value. If set is not enabled then setQty will not take effect
  if (!canUseFeature(FEATURE_LIST.SET_WISE_ORDERING.id) || !isSet) {
    setQty = 1;
  }

  return roundNumberToGivenDecimals(Number(price) * Number(setQty));
};
