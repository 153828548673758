import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

class CustomList extends Component {
  static propTypes = {
    horizontal: PropTypes.bool,
    data: PropTypes.array.isRequired,
    renderItem: PropTypes.func.isRequired,
    keyExtractor: PropTypes.func,
    contentContainerClassName: PropTypes.string,
    contentContainerStyle: PropTypes.object,
    ItemSeparatorComponent: PropTypes.func,
    ListHeaderComponent: PropTypes.func,
    ListFooterComponent: PropTypes.func
  };

  static defaultProps = {
    horizontal: true,
    keyExtractor: item => item.key,
    contentContainerClassName: '',
    contentContainerStyle: {},
    ItemSeparatorComponent: () => null,
    ListHeaderComponent: () => null,
    ListFooterComponent: () => null
  };

  render() {
    const {
      horizontal,
      data,
      renderItem,
      keyExtractor,
      contentContainerClassName,
      contentContainerStyle,
      ItemSeparatorComponent,
      ListHeaderComponent,
      ListFooterComponent
    } = this.props;

    return (
      <div className={`customList ${contentContainerClassName}`} style={contentContainerStyle}>
        {data.map((item, index) => (
          <div
            className={`customListItem ${
              horizontal ? 'customListItemHorizontal' : 'customListItemVertical'
            }`}
            key={keyExtractor(item, index)}
          >
            {index === 0 && ListHeaderComponent()}

            {renderItem({ item, index })}

            {data[index + 1] ? ItemSeparatorComponent() : ListFooterComponent()}
          </div>
        ))}
      </div>
    );
  }
}

export default CustomList;
