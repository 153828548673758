import React from 'react';
import './styles.scss';

export default ({ tag, onToggleTag, isSelected }) => {
  return (
    <div
      className={`popoverTag ${isSelected && 'popoverSelectedTag'}`}
      onClick={() => onToggleTag(tag.title)}
    >
      <span className={`popoverTagText ${!tag.v && 'inactivePopoverTagText'}`}>{tag.title}</span>
    </div>
  );
};
