import React, { useState, useRef } from 'react';
import Modal from 'react-responsive-modal';
import { getI18N } from 'qs-services/i18N';
import { isInputValid } from 'qs-helpers';

import './EditCompanyAddress.scss';

const AddressFormField = ({ error, label, inputId, value = '', ...restInputProps }) => {
  return (
    <div className={`editAddress inputContainer ${error ? 'error' : ''}`}>
      <label htmlFor={inputId} className="companyAddressLabel">
        {label}
      </label>
      <input
        className={`inputBox ${error ? 'inputBoxError' : ''}`}
        value={value}
        id={inputId}
        {...restInputProps}
      />
      {error && typeof error === 'string' && <span className="addressErrorMessage">{error}</span>}
    </div>
  );
};

export default ({ onSubmit, onClose, defaultAddress = {}, saveButtonText }) => {
  const modalStyles = useRef({
    backgroundColor: '#242C36',
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    width: '400px',
    padding: '0px'
  });

  const { t } = getI18N();

  const [addressObj, setAddressObj] = useState(defaultAddress);
  const [addressError, setAddressError] = useState({});
  const handleAddressChange = function(event) {
    const inputId = event.target.id;
    const value = event.target.value;
    const newAddressObj = { ...addressObj };
    const newErrorObj = { ...addressError };
    switch (inputId) {
      case 'addressOne':
        newAddressObj.addressLineOne = value;
        delete newErrorObj.addressOne;
        break;
      case 'addressTwo':
        newAddressObj.addressLineTwo = value;
        break;
      case 'zip':
        newAddressObj.pincode = value;
        delete newErrorObj.zip;
        break;
      case 'city':
        newAddressObj.city = value;
        delete newErrorObj.city;
        break;
      case 'state':
        newAddressObj.state = value;
        delete newErrorObj.state;
        break;
      case 'country':
        newAddressObj.country = value;
        delete newErrorObj.country;
        break;
      default:
        break;
    }

    setAddressObj(newAddressObj);
    setAddressError(newErrorObj);
  };

  const validateAddress = function() {
    const newAddressError = {};
    if (!addressObj.addressLineOne) {
      newAddressError.addressOne = true;
    }
    if (!addressObj.pincode) {
      newAddressError.zip = true;
    } else if (!isInputValid(addressObj.pincode, '^[a-zA-Z0-9_.-]*$')) {
      newAddressError.zip = 'Pincode/zip must contain only alphabets or numbers';
    }
    if (!addressObj.city) {
      newAddressError.city = true;
    }
    if (!addressObj.state) {
      newAddressError.state = true;
    }
    if (!addressObj.country) {
      newAddressError.country = true;
    }

    return newAddressError;
  };

  const handleAddressSave = function(event) {
    if (event) {
      event.preventDefault();
    }
    const updateError = validateAddress();
    if (Object.keys(updateError).length > 0) {
      setAddressError(updateError);
      return;
    }
    onSubmit(addressObj);
    onClose();
  };

  return (
    <Modal
      open={true}
      center={true}
      onClose={onClose}
      showCloseIcon={false}
      styles={{ modal: modalStyles.current }}
    >
      <h3 className="editAddress title">{t('address')}</h3>
      <form onSubmit={handleAddressSave}>
        <AddressFormField
          error={addressError.addressOne}
          label={`${t('address_line_1')}*`}
          inputId={'addressOne'}
          onChange={handleAddressChange}
          type="text"
          value={addressObj.addressLineOne}
        />
        <AddressFormField
          label={`${t('address_line_2')}*`}
          inputId={'addressTwo'}
          onChange={handleAddressChange}
          type="text"
          value={addressObj.addressLineTwo}
        />
        <AddressFormField
          error={addressError.zip}
          label={`${t('pincode')}/${t('zipcode')}*`}
          inputId={'zip'}
          onChange={handleAddressChange}
          type="text"
          value={addressObj.pincode}
        />
        <AddressFormField
          error={addressError.city}
          label={`${t('city')}*`}
          inputId={'city'}
          onChange={handleAddressChange}
          type="text"
          value={addressObj.city}
        />
        <AddressFormField
          error={addressError.state}
          label={t('state')}
          inputId={'state'}
          onChange={handleAddressChange}
          type="text"
          value={addressObj.state}
        />
        <AddressFormField
          error={addressError.country}
          label={`${t('country')}*`}
          inputId={'country'}
          onChange={handleAddressChange}
          type="text"
          value={addressObj.country}
        />
        <div className="editAddress buttonContainer">
          <button type="reset" className="button buttonCancel" onClick={onClose}>
            {t('cancel')}
          </button>
          <button type="submit" className="button buttonPlain">
            {saveButtonText}
          </button>
        </div>
      </form>
    </Modal>
  );
};
