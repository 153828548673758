/* import {
  attachProductSearchListener,
  removeProductSearchListener
} from 'qs-data-manager/ProductLibrary/ProductsForLibrary';
import CacheListenerCallback from 'qs-helpers/CacheListenerCallback';
import { getProductsSearchDataFromCache } from 'qs-helpers/ProductLibrary/CacheHelpers'; */
import React, { useCallback, /* useEffect, */ useState } from 'react';
import HeaderView from './HeaderView';
import ProductLibraryGrid from './ProductLibraryGrid';
import './styles.scss';

export default ({
  filters: { catalogueFilter = '' } = {},
  onSelectionChange,
  currentCatalogueId,
  disableMultiple = false,
  defaultSelectedProducts = []
}) => {
  const [totalProductCount, setTotalProductCount] = useState(defaultSelectedProducts.length);

  const onProductsLoaded = useCallback(productsData => {
    const { productsTotal } = productsData || {};
    setTotalProductCount(productsTotal || 0);
  }, []);

  /*   useEffect(() => {
    const onProductsLoaded = productsData => {
      const { productsTotal } = productsData || {};
      setTotalProductCount(productsTotal || 0);
    };

    //Fetch the product list for this ctalogue to determine whether to show the header or not
    const cachedSearchData = getProductsSearchDataFromCache({ catalogueId: catalogueFilter });
    onProductsLoaded(cachedSearchData);
    const listener = (error, payload) => {
      const { data } = CacheListenerCallback(error, payload);
      if (!data) {
        return;
      }

      onProductsLoaded(data);
    };
    attachProductSearchListener({ listener, catalogueId: catalogueFilter });
    return () => {
      removeProductSearchListener({ listener, catalogueId: catalogueFilter });
    };
  }, [catalogueFilter]); */

  return (
    <div className="productLibraryContainer">
      <HeaderView productCount={totalProductCount} />
      <ProductLibraryGrid
        onProductsLoaded={onProductsLoaded}
        productSelection={onSelectionChange}
        catalogueFilter={catalogueFilter}
        currentCatalogueId={currentCatalogueId}
        disableMultiple={disableMultiple}
        defaultSelectedProducts={defaultSelectedProducts}
      />
    </div>
  );
};
