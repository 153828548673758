export const paginatedSearchProductLibrary = async ({
  searchTerm,
  forceLoad = false,
  notifyListeners,
  notifyError,
  processSearchTermChange,
  processPageIncrement,
  apiFunction,
  getApiParams,
  onMergePaginatedData,
  searchDataConst
}) => {
  const { currentSearchTerm } = searchDataConst;
  //Search term has changed, remove saved data
  if (currentSearchTerm !== searchTerm) {
    processSearchTermChange({ searchTerm });
  }

  //Nothing to show if no search term available
  if (!forceLoad && !searchTerm) {
    return;
  }

  searchDataConst.requestNumber += 1;
  const currentRequestNumber = searchDataConst.requestNumber;
  searchDataConst.paginationInProg = true;
  processPageIncrement();

  notifyListeners();

  //Make paginated API call
  try {
    const searchData = await apiFunction(getApiParams());

    //Skip this response if another request has been sent
    if (searchDataConst.requestNumber !== currentRequestNumber) {
      return;
    }

    // Important mark pagination completed before notifying listeners so that if a call is made again it is not blocked
    searchDataConst.paginationInProg = false;
    onMergePaginatedData({ apiSearchData: searchData });
  } catch (error) {
    searchDataConst.paginationInProg = false;
    notifyError({ error });
  }
};
