import { withStyles, Button, makeStyles } from '@material-ui/core';

const buttonRoot = {
  flexShrink: 0,
  '&:hover': {
    backgroundColor: 'rgb(65, 75, 88)'
  }
};

const buttonContainedPrimary = {
  backgroundColor: '#2D3540',
  color: '#ffffff'
};

export const ActionButton = withStyles({
  root: buttonRoot,
  containedPrimary: buttonContainedPrimary
})(Button);

export const ActionButtonIcon = withStyles({
  root: {
    ...buttonRoot,
    minWidth: 'unset'
  },
  startIcon: {
    margin: '0px'
  },
  containedPrimary: buttonContainedPrimary
})(Button);

//Common dropdown styles
export const useDropDownStyles = makeStyles({
  paperRoot: {
    marginTop: '10px',
    backgroundColor: '#2D3540',
    color: '#ffffff',
    overflow: 'hidden'
  },
  menuItemRoot: {
    '&:hover': {
      backgroundColor: '#222831'
    },
    paddingTop: '10px',
    paddingBottom: '10px',
    borderBottom: '1px solid #272c31',
    '&:last-child': {
      borderBottom: 'none'
    }
  },
  listIconRoot: {
    minWidth: 'unset !important',
    marginRight: '15px'
  }
});
