import React, { useContext, useCallback, useRef, Fragment } from 'react';
import {
  fetchCatalogueProductSearchParamsHandler,
  getCachedCatalogueProductSearchParams,
  handleCatalogueProductSearchParamsCleanup
} from 'qs-helpers/Products/DataQueryHelper';
import useMakeQuery from 'qs-hooks/useMakeQuery';
import { ActiveCatalogueProductListMeta } from '../../../context';
import { SET_PRODUCT_LIST_APPLY_FILTER } from '../../../reducer';
import FilterPriceProducts from '../FilterPriceProducts';
import Loader from '../../../../../Common/Loader';
import ErrorIcon from '../../../../../Common/ErrorIcon';
import FilterOnVariants from '../FilterOnVariants';
import FilterOnCustomFields from '../FilterOnCustomFields';
import { Button, Dialog, DialogActions, DialogContent, makeStyles } from '@material-ui/core';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

const useApplyButtonStyles = makeStyles({
  text: {
    fontSize: 14,
    letterSpacing: 1,
    color: '#4da47a'
  }
});

const useDialogStyles = makeStyles({
  paper: {
    maxWidth: 420,
    width: '100%'
  }
});

export default ({ showPopover, onClosePopover, catalogueId }) => {
  const { productListMeta, setProductListMeta } = useContext(ActiveCatalogueProductListMeta);
  const applyButtonStyles = useApplyButtonStyles();
  const dialogStyles = useDialogStyles();

  const [{ loading, data, error }] = useMakeQuery({
    cachedDataHandler: getCachedCatalogueProductSearchParams,
    actionHandler: fetchCatalogueProductSearchParamsHandler,
    cleanupHandler: handleCatalogueProductSearchParamsCleanup,
    changeDependancy: [catalogueId],
    globalLoader: false
  });

  const { t } = getI18N();

  const {
    currentPriceFilters,
    lastSeenPriceValue,
    currentPriceFilterSelectedOption,
    currentVariantSelection,
    currentCustomFieldsSelection,
    customFieldsNumericOption
  } = productListMeta;

  const registeredFilters = useRef([]);

  const registerFilterCallback = useCallback(callback => {
    registeredFilters.current.push(callback);
  }, []);

  const unRegisterFilterCallback = useCallback(callback => {
    registeredFilters.current = registeredFilters.current.filter(
      registeredCallback => callback !== registeredCallback
    );
  }, []);

  const applyFilters = () => {
    //Check if one of the registered apply filters returns false. If true then do not allow apply
    const atLeastOneHasError = registeredFilters.current.some(
      filterCallback => !filterCallback(productListMeta)
    );

    if (atLeastOneHasError) {
      return;
    }

    setProductListMeta({
      type: SET_PRODUCT_LIST_APPLY_FILTER
    });
    onClosePopover();
  };

  const renderRemainingFilters = () => {
    if (error) {
      return (
        <div className="productFilterModalContainer placeholderContainer filterErrorContainer">
          <ErrorIcon classes="errorIcon" />
          <p className="errorText">
            {t('something_went_wrong_while_fetching_available_filter_values')}
          </p>
        </div>
      );
    }

    if (loading) {
      return (
        <div className="productFilterModalContainer placeholderContainer">
          <Loader size="large" />
        </div>
      );
    }

    return (
      <Fragment>
        <FilterOnVariants
          variants={data.variants}
          variantSelection={currentVariantSelection}
          setProductListMeta={setProductListMeta}
        />
        <FilterOnCustomFields
          customFields={data.customFields}
          customFieldsSelection={currentCustomFieldsSelection}
          customFieldsNumericOption={customFieldsNumericOption}
          setProductListMeta={setProductListMeta}
          registerFilterCallback={registerFilterCallback}
          unRegisterFilterCallback={unRegisterFilterCallback}
        />
      </Fragment>
    );
  };

  return (
    <Dialog
      open={showPopover}
      onClose={onClosePopover}
      maxWidth={'sm'}
      PaperProps={{
        className: `${dialogStyles.paper}`
      }}
    >
      <DialogContent>
        <FilterPriceProducts
          setProductListMeta={setProductListMeta}
          currentPriceFilters={currentPriceFilters}
          lastSeenPriceValue={lastSeenPriceValue}
          selectedOption={currentPriceFilterSelectedOption}
          registerFilterCallback={registerFilterCallback}
          unRegisterFilterCallback={unRegisterFilterCallback}
        />
        {renderRemainingFilters()}
      </DialogContent>
      <DialogActions>
        <Button onClick={applyFilters} className={`${applyButtonStyles.text}`}>
          {t('apply_filter')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
