import React from 'react';
import './styles.scss';

export default ({ className = '' }) => {
  return (
    <div className={`${className} VariantsShimmerContainer`}>
      <div className="variantItem">
        <div className="variantLabelContainer">
          <div className="variantColor shimmer animate" />
          <div className="variantlabel shimmer animate"></div>
        </div>
      </div>
    </div>
  );
};
