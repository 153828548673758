import { Dialog, DialogTitle, Button, withStyles } from '@material-ui/core';

export const DialogGradientTheme = withStyles({
  paper: {
    background: 'linear-gradient(to bottom, #456178, #0C161F)',
    color: '#ffffff'
  },
  root: {}
})(Dialog);

export const GradientThemeTitle = withStyles({
  root: {
    fontSize: 26,
    fontWeight: 700
  }
})(DialogTitle);

export const ButtonDanger = withStyles({
  root: {
    fontSize: 14,
    color: '#f04b4b'
  }
})(Button);

export const PrimaryButton = withStyles({
  root: {
    fontSize: 16,
    background: '#5775FE',
    marginBottom: '15px',
    padding: '10px 15px',
    textTransform: 'unset',
    display: 'flex',
    flex: '1 1 auto',
    color: '#fff',
    '&:hover': {
      background: '#4466FF'
    }
  },
  disabled: {}
})(Button);

export const SecondaryButton = withStyles({
  root: {
    fontSize: 14,
    color: '#ffffff'
  }
})(Button);

export const ContactSupportButton = withStyles({
  root: {
    fontSize: 16,
    background: '#0B131E',
    marginBottom: '15px',
    padding: '10px 15px',
    textTransform: 'unset',
    display: 'flex',
    flex: '1 1 auto',
    color: '#fff',
    '&$hover': {
      color: '#0B141A'
    },
    '&$disabled': {
      color: '#4ed29b',
      opacity: 0.5
    }
  },
  disabled: {}
})(Button);
