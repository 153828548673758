import React from 'react';
import Logo from 'qs-assets/Media/logo.png';
import GoogleLogo from 'qs-assets/Media/google.png';
import WhatsAppLogo from 'qs-assets/Media/whatsapp.png';
import AppleLogo from 'qs-assets/Media/apple_logo_white.svg';
import { AUTH_PROVIDERS } from 'qs-data-manager/Authentication';
import './styles.scss';

export default ({ isNewUser, handleLogin }) => {
  return (
    <div className="loginContentContainer">
      <div className="loginContentHeader">
        {isNewUser && (
          <div className="">
            <span style={{ color: 'red' }}>Please signup through QuickSell app.</span>
            <a className="" href="https://quicksell.co/" target="_blank" rel="noopener noreferrer">
              QuickSell
            </a>
          </div>
        )}
        <h2>GET STARTED</h2>
      </div>
      <div className="loginContentContainer loginContentBody">
        <img src={Logo} className="logo" alt="" />
        <h1>QuickSell</h1>
      </div>
      <div className="loginContentContainer loginCardFooter">
        <button onClick={() => handleLogin(AUTH_PROVIDERS.GOOGLE)} className="btn googleBtn">
          <div className="logoContainer googleLogoContainer">
            <img src={GoogleLogo} alt="" />
          </div>
          <span className="text">Sign in with Google</span>
        </button>
        <button onClick={() => handleLogin(AUTH_PROVIDERS.WHATSAPP)} className="btn whatsappBtn">
          <div className="logoContainer">
            <img className="image" src={WhatsAppLogo} alt="" />
          </div>
          <span className="text whatsappText">Sign in with WhatsApp</span>
        </button>
        <button onClick={() => handleLogin(AUTH_PROVIDERS.APPLE)} className="btn appleBtn">
          <div className="logoContainer">
            <img className="image" src={AppleLogo} alt="" />
          </div>
          <span className="text appleText">Sign In with Apple</span>
        </button>
      </div>
    </div>
  );
};
