export const displayCompanyAddress = addressObj => {
  if (!addressObj || Object.keys(addressObj).length === 0) {
    return '';
  }

  return (
    addressObj.addressLineOne +
    (addressObj.addressLineTwo ? ', ' + addressObj.addressLineTwo : '') +
    '\n' +
    addressObj.city +
    ' - ' +
    addressObj.pincode +
    '\n' +
    addressObj.state +
    ', ' +
    addressObj.country
  );
};
