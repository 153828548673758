import React, { useState, useEffect } from 'react';
import './styles.scss';
import CatalogueLabelScreen from './CatalogueLabelScreen';
import CatalogueListScreen from './CatalogueListScreen';
import ProductsListScreen from './ProductsListScreen';
import ProductDetailsScreen from './ProductDetailsScreen';
import CSVUploader from './CSVUploader';
import WithNavRef from 'qs-helpers/WithNavRef';
import ProductLibraryUploader from './ProductLibraryUploader';
import { selectedLabels } from 'qs-data-manager/Selected';

export default props => {
  const [changeFlexWidth, toggleChangeFlexWidth] = useState(false);
  const [showProductLibrary, toggleShowProductLibrary] = useState(false);
  const [showCatalogueList, toggleShowCatalogueList] = useState(true);

  useEffect(() => {
    selectedLabels.activate();
    //show or hide label screen section is dependent on above activate.
  }, []);

  return (
    <WithNavRef navigationRef={props}>
      <div id={'mainAppContainer'}>
        <CatalogueLabelScreen
          headerClass={'headerStyles'}
          toggleShowCatalogueList={toggleShowCatalogueList}
        />
        <CatalogueListScreen
          showCatalogueList={showCatalogueList}
          changeFlexWidth={changeFlexWidth}
          headerClass={'headerStyles'}
        />
        <ProductsListScreen
          changeFlexWidth={changeFlexWidth}
          headerClass={'headerStyles'}
          toggleProductLibrary={toggleShowProductLibrary}
        />
        <ProductDetailsScreen onRender={toggleChangeFlexWidth} headerClass={'headerStyles'} />
        <CSVUploader />
        {showProductLibrary && (
          <ProductLibraryUploader toggleProductLibrary={toggleShowProductLibrary} />
        )}
      </div>
    </WithNavRef>
  );
};
