import React, { useEffect, useState, useCallback, useMemo, useReducer, useRef } from 'react';
import eventbus from 'eventing-bus';
import { ACTIVE_PRODUCT_ID_META } from 'qs-data-manager/Products';
import { selectedProducts, selectedLabels } from 'qs-data-manager/Selected';
import { resetActiveProductId } from 'qs-data-manager/Products';
import Header from './Header';
import { ACTIVE_CATALOGUE_META } from 'qs-data-manager/Catalogues';
import { getImagesStatus } from 'qs-data-manager/ProductDetails';
import ActiveTabMeta from './ActiveTabMeta';
import { CurrentDefaultImageContext, ActiveVariantId } from './context';
import ProductSingleImage from './ProductSingleImage';
import ProductBulkImage from './ProductBulkImage';
import {
  imageMetaReducer,
  imageMetaInitFunction,
  SET_CURRENT_AS_DEFAULT_IMAGE,
  activeProductsReducer,
  activeProductsInitFunction,
  UPDATE_PRODUCT_ID,
  NEW_CATALOGUE_OPENED,
  BULK_PRODUCTS_UPDATED
} from './reducer';
import ProductVariantView from './ProductVariantView';
import AddProductOptions from './ActiveTabMeta/Variants/AddProductOptions';
import { BOTTOM_SHEET_TABS } from 'qs-helpers/Products/constants';
import './styles.scss';

export default ({ onRender, headerClass }) => {
  const [activeProductIdMeta, setActiveProductIdMeta] = useReducer(
    activeProductsReducer,
    null,
    activeProductsInitFunction
  );

  const [showProductImage, toggleShowProductImage] = useState(true);

  const [currentImageMeta, setCurrentImageMeta] = useReducer(
    imageMetaReducer,
    null,
    imageMetaInitFunction
  );

  const [activeVariantId, setActiveVariantId] = useState();
  //State to trigger a re-render when the ref is available so that the
  // children can do their processing
  const [, setContainerRefMounted] = useState(null);
  const containerRef = useRef(null);
  //Simply used to re-render the component so that the children can make
  //use of the ref
  const [, setContentContainerRefMounted] = useState(null);
  const contentContainerRef = useRef(null);
  const bulkEditRef = useRef(null);
  const [activeTab, setActiveTab] = useState(BOTTOM_SHEET_TABS.BASIC_INFO);
  const [reloadVariants, setReloadVariants] = useState(false);

  /*
    Memoize the value that is sent to context so that on any subsequent
    re-render the context value is not updated unless the image meta has changed.
    Hence the child components are not unnecessarily re-rendered
  */
  const contextData = useMemo(
    () => ({
      currentImageMeta,
      setNewImageData: setCurrentImageMeta
    }),
    [currentImageMeta, setCurrentImageMeta]
  );

  const activeIdsData = useMemo(
    () => ({
      activeVariantId,
      setActiveVariantId
    }),
    [activeVariantId, setActiveVariantId]
  );

  const onChangeTab = useCallback((activeTab, showImageForTab) => {
    setActiveTab(activeTab);
    toggleShowProductImage(showImageForTab);
  }, []);

  useEffect(() => {
    const removeListener = eventbus.on(ACTIVE_PRODUCT_ID_META.eventbusKey, ({ productId } = {}) => {
      if (activeProductIdMeta.activeProductId === productId) {
        return;
      }
      //Reset the variant id so that the variant view is no longer shown
      setActiveVariantId();
      setActiveProductIdMeta({ type: UPDATE_PRODUCT_ID, productId });

      //Product id does not exist no need to process product specific data
      if (!productId) {
        return;
      }

      const newImageData = getImagesStatus({ activeProductIds: [productId] })[0];
      setCurrentImageMeta({ type: SET_CURRENT_AS_DEFAULT_IMAGE, newImageData });
      if (
        activeTab === BOTTOM_SHEET_TABS.INVENTORY ||
        activeTab === BOTTOM_SHEET_TABS.VARIANTS ||
        activeTab === BOTTOM_SHEET_TABS.CUSTOM
      ) {
        //If the current tab is inventory or variants then hide the image.
        // If the image can be shown then it will be updated by the respective components
        toggleShowProductImage(false);
      }
    });

    return removeListener;
  }, [activeTab, activeProductIdMeta.activeProductId]);

  useEffect(() => {
    const removeListener = eventbus.on(ACTIVE_CATALOGUE_META.eventbusKey, () => {
      setActiveProductIdMeta({ type: NEW_CATALOGUE_OPENED });
      setActiveVariantId();
      setActiveTab(BOTTOM_SHEET_TABS.BASIC_INFO);
      toggleShowProductImage(true);
    });

    return removeListener;
  }, []);

  useEffect(() => {
    const onSelectionStateChange = () => {
      setActiveProductIdMeta({
        type: BULK_PRODUCTS_UPDATED,
        all: selectedProducts.getAllPreservedSelections()
      });
      const isBulkEdit = selectedProducts.isAnyPreservedSelected();
      if (bulkEditRef.current === isBulkEdit) {
        return;
      }
      bulkEditRef.current = isBulkEdit;
      if (isBulkEdit) {
        resetActiveProductId();
        setActiveVariantId();
        //Variants tab must not be shown for bulk edits
        if (activeTab === BOTTOM_SHEET_TABS.VARIANTS || activeTab === BOTTOM_SHEET_TABS.CUSTOM) {
          setActiveTab(BOTTOM_SHEET_TABS.BASIC_INFO);
        }
        //For bulk edit the image must not be hidden, toggle the state back
        toggleShowProductImage(true);
      }
    };
    selectedProducts.addListener(onSelectionStateChange);
    return () => {
      selectedProducts.removeListener(onSelectionStateChange);
    };
  }, [activeTab]);

  const scrollContentContainerToTop = useCallback(() => {
    if (contentContainerRef.current) {
      contentContainerRef.current.scrollTop = 0;
    }
  }, []);

  useEffect(() => {
    scrollContentContainerToTop();
  }, [scrollContentContainerToTop, activeProductIdMeta.activeProductId]);

  // Ensure that this method is not created everytime
  // If the ref callback becomes an inline function, then the callback
  // will be hit everytime this component is re-rendered
  const containerMounted = useCallback(
    ref => {
      if (ref) {
        onRender(true);
        selectedLabels.setActive(false);
        containerRef.current = ref;
        setContainerRefMounted(ref);

        return;
      }
      //View is unmounting, nullify the ref so that on the next mount
      //stale ref is not passed to the child components
      onRender(false);
      containerRef.current = null;
    },
    [onRender]
  );

  // Ensure that this method is not created everytime
  // If the ref callback becomes an inline function, then the callback
  // will be hit everytime this component is re-rendered
  const contentContainerMounted = useCallback(ref => {
    if (ref) {
      contentContainerRef.current = ref;
      setContentContainerRefMounted(ref);
      return;
    }
    //View is unmounting, nullify the ref so that on the next mount
    //stale ref is not passed to the child components
    contentContainerRef.current = null;
  }, []);

  const getProductImageComponent = () => {
    if (activeProductIdMeta.isBulkEditing) {
      return <ProductBulkImage activeProductIds={activeProductIdMeta.activeProductIds} />;
    }

    if (!showProductImage) {
      return null;
    }

    return (
      <ProductSingleImage
        containerRef={containerRef.current}
        activeProductId={activeProductIdMeta.activeProductId}
      />
    );
  };
  const reloadVariant = useCallback(() => {
    setReloadVariants(prevVal => !prevVal);
  }, []);
  const getDataForCurrentTab = () => {
    if (activeProductIdMeta.isBulkEditing) {
      return null;
    }

    if (activeTab === BOTTOM_SHEET_TABS.VARIANTS) {
      return (
        <AddProductOptions
          activeProductId={activeProductIdMeta.activeProductId}
          reloadVariants={reloadVariant}
        />
      );
    }

    return null;
  };

  return activeProductIdMeta.activeProductId || activeProductIdMeta.activeProductIds ? (
    <div id={'ProductDetailsScreen'} ref={containerMounted}>
      <ActiveVariantId.Provider value={activeIdsData}>
        <CurrentDefaultImageContext.Provider value={contextData}>
          <Header
            onChangeTab={onChangeTab}
            activeTab={activeTab}
            isBulkEditing={activeProductIdMeta.isBulkEditing}
            activeProductId={activeProductIdMeta.activeProductId}
            headerClass={headerClass}
          />
          <div className="productDetailsContentContainer" ref={contentContainerMounted}>
            {getProductImageComponent()}
            <div className="productDataContainer">
              <ActiveTabMeta
                showProductImage={toggleShowProductImage}
                activeTab={activeTab}
                contentContainerRef={contentContainerRef.current}
                containerRef={containerRef.current}
                activeProductIds={activeProductIdMeta.activeProductIds}
                isBulkEditing={activeProductIdMeta.isBulkEditing}
                activeProductId={activeProductIdMeta.activeProductId}
                scrollToTop={scrollContentContainerToTop}
                reloadVariants={reloadVariants}
              />
            </div>
            {getDataForCurrentTab()}
          </div>
        </CurrentDefaultImageContext.Provider>
        {activeVariantId && (
          <ProductVariantView activeProductId={activeProductIdMeta.activeProductId} />
        )}
      </ActiveVariantId.Provider>
    </div>
  ) : null;
};
