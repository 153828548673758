import React, { useEffect, useState, Fragment } from 'react';
import {
  COMPANY_NAME_VERIFY_REGEX,
  ROUTE_MAP,
  SETTINGS_COMPONENT_ID_MAP,
  SETTINGS_COMPONENT_ROUTE_MAP
} from 'qs-helpers/CompanySettings/constants';
import { useHistory } from 'react-router-dom';
import toastr from 'toastr';
import {
  getCompanyMetaFromCache,
  attachCompanyMetaListener,
  removeCompanyMetaListener,
  getCompanyIdFromCache,
  setCompanyMetaInCache,
  updateCompanyAddress
} from 'qs-data-manager/Company';
import {
  attachDomainDataListner,
  removeDomainDataListener
} from 'qs-data-manager/Domain/DomainCheck';
import { getConnectedDomainData } from 'qs-helpers/Domain/ResponseProcessor';
import { ReactComponent as EditIcon } from 'qs-assets/Media/pencil.svg';
import { ReactComponent as RightArrow } from 'qs-assets/Media/icon_long_arrow_right.svg';
import { ReactComponent as ErrorIcon } from 'qs-assets/Media/image-error.svg';
import UploadIcon from 'qs-assets/Media/upload.png';
import EditSocialLinks from './EditCompanyDetails/EditSocialLinks';
import {
  COMPANY_DETAIL_KEYS,
  saveProfileLogo,
  getFacebook,
  setFacebook,
  getInstagram,
  setInstagram,
  getYoutube,
  setYoutube,
  getTwitter,
  setTwitter
} from '../../../../Services/Apis/CompanyDetaills/api';
import {
  getCompanyDetailFromCache,
  setCompanyDetailFromCache,
  attachCompanyDetailListener,
  getCompanyDetail,
  removeCompanyDetailListener
} from 'qs-data-manager/CompanyDetail';
import {
  getUserMetaFromCache,
  attachUserMetaListener,
  removeUserMetaListener,
  setUserMetaInCache
} from 'qs-data-manager/User';
import { currencies, getSortedCurrencies } from 'qs-helpers/CurrencyMapper';
import CacheListenerCallback from 'qs-helpers/CacheListenerCallback';
import { displayCompanyAddress } from 'qs-helpers/CompanySettings';
import Api from 'qs-services/Api';

import SettingsDataRow from '../../Common/SettingsDataRow/SettingsDataRow';
import EditCompanyAddress from './EditCompanyAddress/EditCompanyAddress';
import EditCompanyDetails from './EditCompanyDetails/EditCompanyDetails';
import CommonPicker from '../../../Common/CommonPicker';
import Loader from '../../../Common/Loader';
import EditDialog from './EditDialog';
import './CompanyProfile.scss';
import { getI18N } from 'qs-services/i18N';

export default () => {
  const history = useHistory();
  const [companyProfile, setCompanyProfile] = useState(getCompanyMetaFromCache());
  const [connectedDomain, setConnectedDomain] = useState('');
  const [userProfile, setUserProfile] = useState(getUserMetaFromCache());
  const [showEditName, toggleEditName] = useState(false);
  const [showEditCompanyName, toggleEditCompanyName] = useState(false);
  const [showEditCurrency, toggleEditCurrency] = useState(false);
  const [showAddressEdit, toggleAddressEdit] = useState(false);
  const [showCompanyEdit, toggleCompanyEdit] = useState(false);

  const [loadingFacebookPage, setLoadingFacebookPage] = useState(false);
  const [loadingInstagramPage, setLoadingInstagramPage] = useState(false);
  const [loadingYoutubePage, setLoadingYoutubeChannel] = useState(false);
  const [loadingTwitterPage, setLoadingTwitterPage] = useState(false);
  const [loadingName, setLoadingName] = useState(false);
  const [loadingCompanyName, setLoadingCompanyName] = useState(false);
  const [loadingCurrency, setLoadingCurrency] = useState(false);
  const [loadingAddress, setLoadingAddress] = useState(false);

  const [showFacebookEdit, toggleFacebookEdit] = useState(false);
  const [showInstagramEdit, toggleInstagramEdit] = useState(false);
  const [showYoutubeEdit, toggleYoutubeEdit] = useState(false);
  const [showTwitterEdit, toggleTwitterEdit] = useState(false);

  const [loadingLogo, setLoadingLogo] = useState(false);
  const [errorLoading, setErrorLoading] = useState(false);

  const { t } = getI18N();

  const [facebookPage, setFacebookPage] = useState(() => {
    const companyDetail = getCompanyDetailFromCache(COMPANY_DETAIL_KEYS.FACEBOOK);
    if (companyDetail) {
      return companyDetail.facebook || '';
    }
    return '';
  });

  const [instagramPage, setInstagramPage] = useState(() => {
    const companyDetail = getCompanyDetailFromCache(COMPANY_DETAIL_KEYS.INSTAGRAM);
    if (companyDetail) {
      return companyDetail.instagram || '';
    }
    return '';
  });

  const [youtubeChannel, setYoutubeChannel] = useState(() => {
    const companyDetail = getCompanyDetailFromCache(COMPANY_DETAIL_KEYS.YOUTUBE);
    if (companyDetail) {
      return companyDetail.youtube || '';
    }
    return '';
  });

  const [twitterPage, setTwitterPage] = useState(() => {
    const companyDetail = getCompanyDetailFromCache(COMPANY_DETAIL_KEYS.TWITTER);
    if (companyDetail) {
      return companyDetail.twitter || '';
    }
    return '';
  });

  useEffect(() => {
    const companyDetailListener = function(error, payload) {
      const { err, loading, refreshing, data } = CacheListenerCallback(error, payload);

      if (loading || refreshing) {
        return;
      }

      if (err) {
        toastr.error(t('something_went_wrong_while_fetching_the_info_for_the_demo_option'));
        return;
      }

      if (data) {
        if (data.facebook) {
          setFacebookPage(data.facebook);
        } else if (data.instagram) {
          setInstagramPage(data.instagram);
        } else if (data.youtube) {
          setYoutubeChannel(data.youtube);
        } else if (data.twitter) {
          setTwitterPage(data.twitter);
        }
      }
    };

    if (!facebookPage) {
      getCompanyDetail(COMPANY_DETAIL_KEYS.FACEBOOK, getFacebook);
    }
    attachCompanyDetailListener(COMPANY_DETAIL_KEYS.FACEBOOK, companyDetailListener);

    if (!instagramPage) {
      getCompanyDetail(COMPANY_DETAIL_KEYS.INSTAGRAM, getInstagram);
    }
    attachCompanyDetailListener(COMPANY_DETAIL_KEYS.INSTAGRAM, companyDetailListener);

    if (!youtubeChannel) {
      getCompanyDetail(COMPANY_DETAIL_KEYS.YOUTUBE, getYoutube);
    }
    attachCompanyDetailListener(COMPANY_DETAIL_KEYS.YOUTUBE, companyDetailListener);

    if (!twitterPage) {
      getCompanyDetail(COMPANY_DETAIL_KEYS.TWITTER, getTwitter);
    }
    attachCompanyDetailListener(COMPANY_DETAIL_KEYS.TWITTER, companyDetailListener);

    return () => {
      removeCompanyDetailListener(COMPANY_DETAIL_KEYS.FACEBOOK, companyDetailListener);
      removeCompanyDetailListener(COMPANY_DETAIL_KEYS.INSTAGRAM, companyDetailListener);
      removeCompanyDetailListener(COMPANY_DETAIL_KEYS.YOUTUBE, companyDetailListener);
      removeCompanyDetailListener(COMPANY_DETAIL_KEYS.TWITTER, companyDetailListener);
    };
  }, [facebookPage, instagramPage, t, twitterPage, youtubeChannel]);

  useEffect(() => {
    const companyListener = function(error, payload) {
      const { err, loading, refreshing, data } = CacheListenerCallback(error, payload);
      if (loading || refreshing) {
        return;
      }

      if (err) {
        toastr.error(t('something_went_wrong_while_fetching_the_updated_company_values'));
        return;
      }
      setCompanyProfile(data);
      setLoadingLogo(false);
    };

    const domainDataListener = function(error, payload) {
      const { data } = CacheListenerCallback(error, payload);
      if (!data) {
        return;
      }

      const connectedDomains = getConnectedDomainData(data);
      setConnectedDomain(connectedDomains.custom || connectedDomains.catalog || '');
    };

    attachCompanyMetaListener(companyListener);
    attachDomainDataListner({ listener: domainDataListener });
    return () => {
      removeCompanyMetaListener(companyListener);
      removeDomainDataListener({ listener: domainDataListener });
    };
  }, [t]);

  const saveFacebookPage = async value => {
    setLoadingFacebookPage(true);
    try {
      await setFacebook(value);
      setFacebookPage(value);
      setCompanyDetailFromCache(COMPANY_DETAIL_KEYS.FACEBOOK, { facebook: value });
      toastr.success(t('facebook_page_saved_successfully'));
    } catch (error) {
      toastr.error(t('something_went_wrong_while_saving_facebook_page'));
    } finally {
      setLoadingFacebookPage(false);
    }
  };

  const saveInstagramPage = async value => {
    setLoadingInstagramPage(true);
    try {
      await setInstagram(value);
      setInstagramPage(value);
      setCompanyDetailFromCache(COMPANY_DETAIL_KEYS.INSTAGRAM, { instagram: value });
      toastr.success(t('instagram_page_saved_successfully'));
    } catch (error) {
      toastr.error(t('something_went_wrong_while_saving_instagram_page'));
    } finally {
      setLoadingInstagramPage(false);
    }
  };

  const saveYoutubeChannel = async value => {
    setLoadingYoutubeChannel(true);
    try {
      await setYoutube(value);
      setYoutubeChannel(value);
      setCompanyDetailFromCache(COMPANY_DETAIL_KEYS.YOUTUBE, { youtube: value });
      toastr.success(t('youtube_channel_saved_successfully'));
    } catch (error) {
      toastr.error(t('something_went_wrong_while_saving_youtube_channel'));
    } finally {
      setLoadingYoutubeChannel(false);
    }
  };

  const saveTwitterPage = async value => {
    setLoadingTwitterPage(true);
    try {
      await setTwitter(value);
      setTwitterPage(value);
      setCompanyDetailFromCache(COMPANY_DETAIL_KEYS.TWITTER, { twitter: value });
      toastr.success(t('twitter_page_saved_successfully'));
    } catch (error) {
      toastr.error(t('something_went_wrong_while_saving_twitter_page'));
    } finally {
      setLoadingTwitterPage(false);
    }
  };

  const saveAddress = async value => {
    setLoadingAddress(true);
    try {
      await updateCompanyAddress(value);
      setCompanyMetaInCache({
        data: {
          ...companyProfile,
          address: value
        }
      });
      toastr.success(t('address_saved_successfully'));
    } catch (error) {
      toastr.error(t('something_went_wrong_while_saving_address'));
    } finally {
      setLoadingAddress(false);
    }
  };

  const getEditIcon = () => {
    return (
      <div className="company-details-edit-icon-container">
        <EditIcon className="company-details-edit-icon" />
      </div>
    );
  };

  const getRightArrow = () => {
    return (
      <div className="company-details-edit-icon-container">
        <RightArrow className="company-details-edit-icon" />
      </div>
    );
  };

  useEffect(() => {
    const userListener = function(error, payload) {
      const { err, loading, refreshing, data } = CacheListenerCallback(error, payload);

      if (loading || refreshing) {
        return;
      }

      if (err) {
        toastr.error(t('something_went_wrong_while_fetching_the_updated_user_values'));
        return;
      }
      setUserProfile(data);
    };

    attachUserMetaListener(userListener);
    return () => removeUserMetaListener(userListener);
  }, [t]);

  const uploadLogo = photo => {
    if (!photo) {
      return;
    }

    const formData = new FormData();
    formData.append('logo', photo);
    setLoadingLogo(true);
    saveProfileLogo(formData)
      .then(() => {
        toastr.success(t('logo_updated_successfully'));
      })
      .catch(() => {
        setLoadingLogo(false);
        setErrorLoading(true);
        toastr.error(t('something_went_wrong_while_updating_logo'));
      });
  };

  const getCompanyLogo = function() {
    if (!companyProfile) {
      return;
    }
    const { logoExists, logoVersion } = companyProfile;
    if (!logoExists) {
      return (
        <div className="placeholder-logo">
          {loadingLogo ? (
            <Loader size="small" />
          ) : (
            <>
              <label htmlFor="file-input" className="image-label">
                <img src={UploadIcon} alt="Your company profile" />
                <span className="logo-text">{t('logo')}</span>
              </label>
              <input
                type="file"
                accept="image/*"
                id="file-input"
                onChange={event => {
                  uploadLogo(event.target.files[0]);
                }}
              />
            </>
          )}
        </div>
      );
    }

    const companyId = getCompanyIdFromCache();
    return (
      <div className="logo-container">
        {loadingLogo ? (
          <Loader size="large" />
        ) : (
          <>
            {errorLoading ? (
              <ErrorIcon className="errorIcon" />
            ) : (
              <img
                src={`https://s3.ap-south-1.amazonaws.com/quicksell-logos/${companyId}.jpg?v=${logoVersion}`}
                alt="Your company profile"
              />
            )}
            <label htmlFor="file-input">
              <EditIcon className="company-details-edit-profile-icon" />
            </label>
            <input
              id="file-input"
              type="file"
              accept="image/*"
              onChange={event => {
                uploadLogo(event.target.files[0]);
              }}
            />
          </>
        )}
      </div>
    );
  };

  const toggleEditNameView = function() {
    toggleEditName(!showEditName);
  };

  const toggleEditCompanyNameView = function() {
    toggleEditCompanyName(!showEditCompanyName);
  };

  const toggleEditCurrencyView = function() {
    toggleEditCurrency(!showEditCurrency);
  };

  const toggleAddressView = function() {
    toggleAddressEdit(!showAddressEdit);
  };

  const toggleCompanyEditView = function() {
    toggleCompanyEdit(!showCompanyEdit);
  };

  const toggleFacebookViewEdit = function() {
    toggleFacebookEdit(!showFacebookEdit);
  };

  const toggleInstagramViewEdit = function() {
    toggleInstagramEdit(!showInstagramEdit);
  };

  const toggleYoutubeViewEdit = function() {
    toggleYoutubeEdit(!showYoutubeEdit);
  };

  const toggleTwitterViewEdit = function() {
    toggleTwitterEdit(!showTwitterEdit);
  };

  const saveName = async value => {
    setLoadingName(true);
    try {
      await Api.updateUserMetaData({ name: value }, userProfile.id);
      setUserMetaInCache({
        data: {
          ...userProfile,
          name: value
        }
      });
      toastr.success(t('name_updated_successfully'));
    } catch (error) {
      toastr.error(t('something_went_wrong_while_updating_name'));
    } finally {
      setLoadingName(false);
    }
  };

  const saveCompanyName = async value => {
    setLoadingCompanyName(true);
    try {
      await Api.saveCompanyDetails({
        companyName: value,
        currencyCode: companyProfile.currencyCode,
        phone: userProfile.phone
      });
      setCompanyMetaInCache({
        data: {
          ...companyProfile,
          name: value
        }
      });
      toastr.success(t('company_name_updated_successfully'));
    } catch (error) {
      toastr.error(t('something_went_wrong_while_updating_company_name'));
    } finally {
      setLoadingCompanyName(false);
    }
  };

  const updateCompanyCurrency = async value => {
    toggleEditCurrencyView();
    setLoadingCurrency(true);
    try {
      await Api.saveCompanyDetails({
        companyName: companyProfile.name,
        currencyCode: value.currencyCode,
        phone: userProfile.phone
      });
      setCompanyMetaInCache({
        data: {
          ...companyProfile,
          currencyCode: value.currencyCode
        }
      });
      toastr.success(t('currency_updated_successfully'));
    } catch (error) {
      toastr.error(t('something_went_wrong_while_updating_currency'));
    } finally {
      setLoadingCurrency(false);
    }
  };

  const closeCurrencyPicker = event => {
    if (event) {
      event.preventDefault();
    }
    if (!showEditCurrency) {
      return;
    }
    toggleEditCurrencyView();
  };

  let companyDetailsComponent;
  if (companyProfile && userProfile) {
    companyDetailsComponent = (
      <Fragment>
        <div className="company-image-container">{getCompanyLogo(companyProfile)}</div>
        <SettingsDataRow
          title={t('name')}
          icon={getEditIcon()}
          onSectionClick={toggleEditName}
          loader={loadingName}
        >
          <span>{userProfile.name}</span>
        </SettingsDataRow>
        <SettingsDataRow
          title={t('company_name')}
          icon={getEditIcon()}
          onSectionClick={toggleEditCompanyNameView}
          loader={loadingCompanyName}
        >
          <span>{companyProfile.name}</span>
        </SettingsDataRow>
        <SettingsDataRow
          title={t('currency')}
          icon={getEditIcon()}
          onSectionClick={toggleEditCurrencyView}
          loader={loadingCurrency}
        >
          <span>{companyProfile.currencyCode}</span>
        </SettingsDataRow>
        <SettingsDataRow
          title={t('domain')}
          icon={getRightArrow()}
          onSectionClick={() => {
            history.push(
              `/${ROUTE_MAP.ACCOUNT_SETTINGS}/${
                SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.DOMAIN_SETTINGS].ROUTE
              }`
            );
          }}
        >
          <span>
            {companyProfile.domain ||
              companyProfile.companyDomain ||
              connectedDomain ||
              t('no_domain_set')}
          </span>
        </SettingsDataRow>
        <SettingsDataRow title={t('email')}>
          <span>{userProfile.accountEmail ? userProfile.accountEmail : userProfile.email}</span>
        </SettingsDataRow>
        <SettingsDataRow icon={null} title={t('phone')}>
          <span>{userProfile.phone}</span>
        </SettingsDataRow>
        <SettingsDataRow
          loader={loadingAddress}
          title={t('address')}
          icon={getEditIcon()}
          onSectionClick={toggleAddressView}
        >
          <pre className="address-container">{displayCompanyAddress(companyProfile.address)}</pre>
        </SettingsDataRow>
        <SettingsDataRow
          title={t('facebook_page')}
          onSectionClick={toggleFacebookViewEdit}
          loader={loadingFacebookPage}
          icon={getEditIcon()}
        >
          <span>{facebookPage || ''}</span>
        </SettingsDataRow>
        <SettingsDataRow
          title={t('instagram')}
          onSectionClick={toggleInstagramViewEdit}
          loader={loadingInstagramPage}
          icon={getEditIcon()}
        >
          <span>{instagramPage || ''}</span>
        </SettingsDataRow>
        <SettingsDataRow
          title={t('youtube_channel')}
          onSectionClick={toggleYoutubeViewEdit}
          loader={loadingYoutubePage}
          icon={getEditIcon()}
        >
          <span>{youtubeChannel || ''}</span>
        </SettingsDataRow>
        <SettingsDataRow
          title={t('twitter')}
          onSectionClick={toggleTwitterViewEdit}
          loader={loadingTwitterPage}
          icon={getEditIcon()}
        >
          <span>{twitterPage || ''}</span>
        </SettingsDataRow>
        {showEditName && (
          <EditDialog
            onClose={toggleEditNameView}
            defaultValue={userProfile.name}
            title={t('name')}
            onSubmit={saveName}
            saveButtonText={t('save_name')}
            placeholder={t('type_name')}
          />
        )}
        {showEditCompanyName && (
          <EditDialog
            onClose={toggleEditCompanyNameView}
            defaultValue={companyProfile.name}
            title={t('company_name')}
            onSubmit={saveCompanyName}
            saveButtonText={t('set_company_name')}
            placeholder={t('type_company_name')}
            validationPattern={COMPANY_NAME_VERIFY_REGEX}
            errorMsg={t('company_name_should_contain_atleast_one_alphabet')}
          />
        )}
        {showAddressEdit && (
          <EditCompanyAddress
            onClose={toggleAddressView}
            defaultAddress={companyProfile.address}
            saveButtonText={t('save')}
            onSubmit={saveAddress}
          />
        )}
        {showCompanyEdit && (
          <EditCompanyDetails
            onClose={toggleCompanyEditView}
            defaultCompany={companyProfile}
            phone={userProfile.phone}
          />
        )}
        {showFacebookEdit && (
          <EditSocialLinks
            onClose={toggleFacebookViewEdit}
            onSubmit={saveFacebookPage}
            defaultValue={facebookPage}
            title={t('facebook_page')}
            urlPrefix="https://www.facebook.com/"
            placeholder={t('enter_facebook_page')}
            type="text"
          />
        )}
        {showInstagramEdit && (
          <EditSocialLinks
            onClose={toggleInstagramViewEdit}
            onSubmit={saveInstagramPage}
            defaultValue={instagramPage}
            title="Instagram"
            urlPrefix="https://www.instagram.com/"
            placeholder={t('enter_instagram_page')}
            type="text"
          />
        )}
        {showYoutubeEdit && (
          <EditSocialLinks
            onClose={toggleYoutubeViewEdit}
            onSubmit={saveYoutubeChannel}
            defaultValue={youtubeChannel}
            title={t('youtube_channel')}
            urlPrefix="https://www.youtube.com/"
            placeholder={t('enter_youtube_channel_link')}
            type="text"
          />
        )}
        {showTwitterEdit && (
          <EditSocialLinks
            onClose={toggleTwitterViewEdit}
            onSubmit={saveTwitterPage}
            defaultValue={twitterPage}
            title={t('twitter')}
            urlPrefix="https://www.twitter.com/"
            placeholder={t('enter_twitter_page')}
            type="text"
          />
        )}
        <CommonPicker
          pickerList={getSortedCurrencies()}
          selectedPickerValue={currencies[companyProfile.currencyCode]}
          onSelect={value => updateCompanyCurrency(value)}
          type={'CURRENCY'}
          placeHolderText={t('search_country')}
          isVisible={showEditCurrency}
          onClose={closeCurrencyPicker}
        />
      </Fragment>
    );
  } else {
    companyDetailsComponent = <Loader size="large" />;
  }

  return <div className="company-profile-container">{companyDetailsComponent}</div>;
};
