import { RESET_COUNTER, UPDATE_COUNTER } from 'qs-helpers/constants';
import React, { useCallback, useEffect, useRef } from 'react';
import './styles.scss';

export default ({
  quantity,
  setQuantity,
  originalCounter,
  onUpdate,
  isUpdatePossible,
  scale = 30
}) => {
  const editDebounceTimer = useRef(null);
  const pendingQuantityUpdate = useRef(null);

  const clearScheduledQuantityUpdates = useCallback(() => {
    clearTimeout(editDebounceTimer.current);
    editDebounceTimer.current = null;
    pendingQuantityUpdate.current = null;
  }, []);

  const resetQuantityToPropValue = useCallback(() => {
    setQuantity(originalCounter.toString());
  }, [originalCounter, setQuantity]);

  useEffect(
    () => () => {
      clearScheduledQuantityUpdates();
    },
    [clearScheduledQuantityUpdates]
  );

  const updateCounter = quantityNum => {
    if (typeof onUpdate === 'function') {
      updateCounter(quantityNum);
    }
  };

  const scheduleEditQuantity = () => {
    clearTimeout(editDebounceTimer.current);
    editDebounceTimer.current = setTimeout(() => {
      editDebounceTimer.current = null;
      // Pick the pening value which is the latest value
      updateCounter(pendingQuantityUpdate.current);
    }, 200);
  };

  const onChangeQuantity = (quantityNum, schedule = true) => {
    pendingQuantityUpdate.current = quantityNum;
    setQuantity(quantityNum.toString());
    if (schedule) {
      scheduleEditQuantity();
      return;
    }

    updateCounter(quantityNum);
  };

  const clearSavedDataForInvalidInput = valueToSet => {
    //Set the value so that the user can see what was typed
    setQuantity(valueToSet.toString());
    //Clear any pending updates
    clearScheduledQuantityUpdates();
  };

  const processUpdateIfRequired = async (operation, quantityNum) => {
    // Clear any pending state update, before checking whether update can be processed
    clearScheduledQuantityUpdates();

    const { operationKey, schedule, updatedQuantityNum } = await isUpdatePossible(
      operation,
      quantityNum
    );

    if (operationKey === UPDATE_COUNTER) {
      onChangeQuantity(
        typeof updatedQuantityNum === 'number' ? updatedQuantityNum : quantityNum,
        schedule
      );
      return;
    }

    if (operationKey === RESET_COUNTER) {
      resetQuantityToPropValue();
    }
  };

  const changeQuantityIfValid = (operation, value) => {
    switch (operation) {
      case 'INC': {
        let quantityNum = Number(quantity);
        //Invalid value, reset everything and set quantity as initial value
        if (quantity === '' || Number.isNaN(quantityNum)) {
          clearScheduledQuantityUpdates();
          resetQuantityToPropValue();
          break;
        }

        quantityNum++;
        processUpdateIfRequired(operation, quantityNum);
        break;
      }
      case 'DEC': {
        let quantityNum = Number(quantity);
        if (quantity === '' || Number.isNaN(quantityNum)) {
          clearScheduledQuantityUpdates();
          resetQuantityToPropValue();
          break;
        }
        quantityNum--;
        if (quantityNum <= 0) {
          onChangeQuantity(1);
          return;
        }
        processUpdateIfRequired(operation, quantityNum);
        break;
      }
      case 'SET': {
        let quantityNum = Number(value);
        if (value === '' || quantityNum <= 0 || Number.isNaN(quantityNum)) {
          clearSavedDataForInvalidInput(value);
          break;
        }

        processUpdateIfRequired(operation, quantityNum);
        break;
      }
      case 'RESET_INVALID': {
        let quantityNum = Number(quantity);
        if (value === '' || quantityNum <= 0 || Number.isNaN(quantityNum)) {
          resetQuantityToPropValue();
        }
        break;
      }
      default:
        break;
    }
  };

  const decrementQuantity = () => {
    changeQuantityIfValid('DEC');
  };

  const incrementQuantity = async () => {
    changeQuantityIfValid('INC');
  };

  const onQuantityChange = async event => {
    changeQuantityIfValid('SET', event.target.value);
  };

  const onQuantityBlur = () => {
    changeQuantityIfValid('RESET_INVALID');
  };

  return (
    <div className="counterModificationInputContainer">
      <div
        onClick={decrementQuantity}
        className="counterMinusContainer"
        style={{ fontSize: scale }}
      >
        -
      </div>
      <div className="counterInputContainer" style={{ fontSize: scale }}>
        <input
          type="tel"
          value={quantity}
          className="counterInput"
          onChange={onQuantityChange}
          onBlur={onQuantityBlur}
        />
      </div>
      <div onClick={incrementQuantity} className="counterPlusContainer" style={{ fontSize: scale }}>
        <span style={{ fontSize: Math.floor(scale / 1.2) }}>+</span>
      </div>
    </div>
  );
};
