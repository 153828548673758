import React, { useEffect, useState } from 'react';
import LabelTitle from '../CatalogueLabel/LabelTitle';
import HeaderWithBackAndSelect from 'qs-components/Common/HeaderWithBackAndSelect';
import { ReactComponent as NewCatalogueLabel } from 'qs-assets/Media/plus.svg';
import { ReactComponent as EditIcon } from 'qs-assets/Media/pencil.svg';
import { Tooltip, withStyles } from '@material-ui/core';
import './styles.scss';
import { selectedLabels, selectedLabelsToEdit } from 'qs-data-manager/Selected';
import { getI18N } from 'qs-services/i18N';

const IconToolTip = withStyles({
  tooltip: {
    backgroundColor: '#37A06D',
    fontSize: 12
  }
})(Tooltip);

export default ({
  data,
  editingLabel,
  headerClass,
  setOpenCreateNewLabelDialog,
  setOpenDeleteLabelDialog,
  noLabelSelected
}) => {
  const [deleteAllLabels, setDeleteAllLabels] = useState(false);
  const [showDeleteIcon, setShowDeleteIcon] = useState(false);

  const { t } = getI18N();

  useEffect(() => {
    const onLabelsCheck = () => {
      setDeleteAllLabels(selectedLabelsToEdit.areAllSelected());
      setShowDeleteIcon(selectedLabelsToEdit.isAnySelected());
    };
    selectedLabelsToEdit.addListener(onLabelsCheck);
    return () => {
      selectedLabelsToEdit.removeListener(onLabelsCheck);
    };
  }, []);

  const toggleDeleteLabelCheckbox = () => {
    if (selectedLabelsToEdit.areAllSelected()) {
      selectedLabelsToEdit.removeAll();
      return;
    }
    selectedLabelsToEdit.setAll();
  };

  const toggleSelectedLabelsToEdit = event => {
    event.preventDefault();
    selectedLabelsToEdit.toggleActive();
  };

  const addNewCatalogueLabelIcon = () => (
    <IconToolTip title={t('add_new_label')}>
      <NewCatalogueLabel
        aria-label={t('add_label')}
        className={'icon createNewIcon copyIcon'}
        onClick={() => setOpenCreateNewLabelDialog(true)}
      />
    </IconToolTip>
  );

  if (editingLabel) {
    return (
      <HeaderWithBackAndSelect
        headerClass={headerClass}
        goBack={toggleSelectedLabelsToEdit}
        deleteIconVisibility={showDeleteIcon}
        deleteIconTitle={t('delete_labels')}
        onDeleteClick={() => setOpenDeleteLabelDialog(true)}
        checkboxValue={deleteAllLabels}
        onCheckBoxToggle={() => toggleDeleteLabelCheckbox()}
        rightSideAdditionalIcons={addNewCatalogueLabelIcon}
      />
    );
  }

  return (
    <>
      <div className={'catalogueLabelHeaderContainer'}>
        {t('labels')}
        <div className={'options'}>
          {Array.isArray(data.labels) && data.labels.length !== 0 && (
            <EditIcon onClick={toggleSelectedLabelsToEdit} className={'icon editIcon'} />
          )}
          {addNewCatalogueLabelIcon()}
        </div>
      </div>
      <LabelTitle
        title={t('all_catalogues')}
        isActive={noLabelSelected}
        onClick={() => selectedLabels.isAnySelected() && selectedLabels.removeAll()}
      />
    </>
  );
};
