import React from 'react';
import './styles.scss';
import { getI18N } from 'qs-services/i18N';

export default ({
  label,
  fieldType,
  fieldLabel,
  containerClass = '',
  fieldContainerClass = '',
  fieldHeaderClass = '',
  changeButton,
  onChangeClick,
  children
}) => {
  let classForField = fieldContainerClass;
  switch (fieldType) {
    case 'INPUT':
      classForField += ' fieldInputContainer';
      break;
    case 'TEXTAREA':
      classForField += ' fieldTextAreaContainer';
      break;
    default:
      break;
  }

  const { t } = getI18N();

  const changeClicked = event => {
    event.preventDefault();
    onChangeClick(fieldLabel);
  };

  return (
    <div className={`formFieldContainer ${containerClass}`}>
      <div className={`fieldHeading ${fieldHeaderClass}`}>{label}</div>
      <div className={classForField}>{children}</div>
      {changeButton && (
        <div className="changeButton" onClick={changeClicked}>
          {t('change')}
        </div>
      )}
    </div>
  );
};
