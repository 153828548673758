import { Button } from '@material-ui/core';
import React, { useCallback, useContext, useEffect } from 'react';
import { ReactComponent as DeleteIcon } from 'qs-assets/Media/trash.svg';
import { ReactComponent as EditIcon } from 'qs-assets/Media/pencil.svg';
import { getOrderNotes, saveOrderNotes } from 'qs-api/Orders/api';
import CheckBox from '../../../../Common/CheckBox';
import EditOrderNoteDialog from './OrderNoteDialog';
import { useState } from 'react';
import toastr from 'toastr';
import Loader from '../../../../Common/Loader';
import DeleteOrderNoteDialog from './DeleteNoteDialog';
import './styles.scss';
import { OrderNoteContext } from '../../../context';
import { getI18N } from 'qs-services/i18N';

const OrderNoteComponent = ({ orderId }) => {
  const [orderNoteText, setOrderNoteText] = useState('');
  const { open, setOpen } = useContext(OrderNoteContext);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addToInvoice, setAddToInvoice] = useState(false);
  const hasOrderNote = typeof orderNoteText === 'string' && orderNoteText.length > 0;
  const { t } = getI18N();
  const fetchOrderNote = useCallback(async () => {
    try {
      setLoading(true);
      const orderNoteData = await getOrderNotes({ orderId });
      if (Object.keys(orderNoteData).length > 0) {
        setOrderNoteText(orderNoteData.notes);
        setAddToInvoice(orderNoteData.addToInvoice);
      }
    } catch (error) {
      toastr.error(error.message || t('error_fetching_order_note'));
    } finally {
      setLoading(false);
    }
  }, [orderId, t]);

  const onDeleteNoteSuccess = useCallback(() => {
    setOrderNoteText(t('order_notes_placeholder'));
    setAddToInvoice(false);
    setOpenDeleteModal(false);
  }, [t]);

  const toggleCheckbox = useCallback(async () => {
    const newValue = !addToInvoice;
    setAddToInvoice(newValue);
    try {
      await saveOrderNotes({ orderId, notes: orderNoteText, addToInvoice: newValue });
      toastr.success(t('order_notes_updated'));
    } catch (error) {
      toastr.error(error.message || t('error_saving_order_note'));
    } finally {
      setLoading(false);
    }
  }, [addToInvoice, orderId, orderNoteText, t]);

  useEffect(() => {
    fetchOrderNote();
  }, [fetchOrderNote]);

  if (loading) {
    return (
      <div className="loadingOrderNote">
        <Loader size="small" />
      </div>
    );
  }

  return (
    <>
      <DeleteOrderNoteDialog
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        orderId={orderId}
        onDeleteNoteSuccess={onDeleteNoteSuccess}
      />
      <EditOrderNoteDialog
        open={open}
        setOpen={setOpen}
        orderId={orderId}
        addToInvoice={addToInvoice}
        orderNoteText={orderNoteText}
        setOrderNoteText={setOrderNoteText}
      />
      {hasOrderNote && (
        <div className="sectionContainer orderNotesSection">
          <div className="titleContainer">
            <span className={`title`}>{t('order_note')}</span>
          </div>
          <div className="orderNoteText" onClick={() => setOpen(true)}>
            {orderNoteText.trim()}
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <CheckBox
              checked={addToInvoice}
              disabled={loading}
              onCheckChanged={toggleCheckbox}
              className={'orderNote'}
              label={t('add_note_to_invoice')}
              styleProps={{ padding: 9, fontSize: 15 }}
            />
          </div>

          <div className="orderNoteButtons">
            <Button variant="text" className="actionButton" onClick={() => setOpen(true)}>
              <EditIcon className={'icon'} />
            </Button>

            <Button
              variant="text"
              className="actionButton"
              onClick={() => setOpenDeleteModal(true)}
            >
              <DeleteIcon className={'icon'} />
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default OrderNoteComponent;
