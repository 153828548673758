import {
  ALL_CATALOGUE_META /* ,
  CATALOGUE_SEARCH_DATA */
} from 'qs-api/ProductLibrary/ApiCacheAndConnector';
/* import {
  getCacheKeyForCatalogueSearch,
  getCataloguesDataFromCache,
  mergeCatalogueSearchDataWithCache,
  notifyCatalogueErrorListener,
  notifyCatalogueSearchListeners
} from 'qs-helpers/ProductLibrary/CacheHelpers';
import { PRODUCT_LIB_CATALOGUE_SEARCH_DATA } from 'qs-helpers/ProductLibrary/constants';
import { paginatedSearchProductLibrary } from 'qs-helpers/ProductLibrary/PaginatedRequest';
import { processCataloguePageIncrement } from 'qs-helpers/ProductLibrary/ResponseProcessor'; */
import CacheRequest from '../CacheRequest';

export const attachCatalogueFilterListener = ({ listener }) => {
  const key = ALL_CATALOGUE_META.cacheKey;
  CacheRequest.attachListener(key, listener);
};

export const removeCatalogueFilterListener = ({ listener }) => {
  const key = ALL_CATALOGUE_META.cacheKey;
  CacheRequest.removeListener(key, listener);
};

export const getCatalogueFilterData = () => {
  const key = ALL_CATALOGUE_META.cacheKey;
  const apiCall = ALL_CATALOGUE_META.apiFunction;
  CacheRequest.makeRequest(key, apiCall, {
    params: [],
    options: {
      shouldNotStoreInNative: true
    }
  });
};

export const getCatalogueFilterDataFromCache = () => {
  const key = ALL_CATALOGUE_META.cacheKey;
  const catalogueFilter = CacheRequest.getCacheForKey(key);
  if (!catalogueFilter || !Array.isArray(catalogueFilter.catalogues)) {
    return [];
  }

  return catalogueFilter.catalogues;
};

export const getCachedCatalogueTitleForFilter = catalogueIdToFind => {
  const catalogues = getCatalogueFilterDataFromCache();

  const matchedCatalogueData = catalogues.find(
    ({ catalogueId }) => catalogueId === catalogueIdToFind
  );
  if (!matchedCatalogueData) {
    return '';
  }

  return matchedCatalogueData.title;
};

/* export const getCataloguesListFromCache = () => {
  const { catalogues } = getCataloguesDataFromCache() || {};
  if (!catalogues || !Array.isArray(catalogues)) {
    return [];
  }

  return catalogues;
};

export const getCachedCatalogueTitleForFilter = catalogueIdToFind => {
  const catalogues = getCataloguesListFromCache();

  const matchedCatalogueData = catalogues.find(
    ({ catalogueId }) => catalogueId === catalogueIdToFind
  );
  if (!matchedCatalogueData) {
    return '';
  }

  return matchedCatalogueData.title;
};

export const attachCatalogueSearchListener = listener => {
  CacheRequest.attachListener(getCacheKeyForCatalogueSearch(), listener);
};

export const removeCatalogueSearchListener = listener => {
  CacheRequest.removeListener(getCacheKeyForCatalogueSearch(), listener);
};

const clearCatalogueSearchDataFromCache = ({ searchTerm } = {}) => {
  PRODUCT_LIB_CATALOGUE_SEARCH_DATA.currentSearchTerm = searchTerm;
  PRODUCT_LIB_CATALOGUE_SEARCH_DATA.currentPage = 0;
  PRODUCT_LIB_CATALOGUE_SEARCH_DATA.paginationCompleted = false;
  CacheRequest.deleteCacheForKeys([getCacheKeyForCatalogueSearch()]);
};

export const searchCataloguesFromLibrary = ({ searchTerm, forceLoad, excludeCatalogueId }) =>
  paginatedSearchProductLibrary({
    searchTerm,
    forceLoad,
    notifyListeners: notifyCatalogueSearchListeners,
    apiFunction: CATALOGUE_SEARCH_DATA.apiFunction,
    getApiParams: () => ({
      searchTerm,
      page: PRODUCT_LIB_CATALOGUE_SEARCH_DATA.currentPage,
      excludeCatalogueId
    }),
    notifyError: notifyCatalogueErrorListener,
    processSearchTermChange: clearCatalogueSearchDataFromCache,
    processPageIncrement: processCataloguePageIncrement,
    searchDataConst: PRODUCT_LIB_CATALOGUE_SEARCH_DATA,
    onMergePaginatedData: mergeCatalogueSearchDataWithCache
  }); */

/* const canRequestNextSearchCataloguePage = () =>
  !PRODUCT_LIB_SEARCH_DATA.paginationInProg &&
  !PRODUCT_LIB_CATALOGUE_SEARCH_DATA.paginationInProg &&
  !PRODUCT_LIB_CATALOGUE_SEARCH_DATA.paginationCompleted; */
