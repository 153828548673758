import React, { useCallback, useState, useEffect } from 'react';
import { previewCatalogue } from 'qs-services/DataManager/Products';
import ShareDialoguePopover from './ShareDialoguePopover';
import EditDialoguePopover from './EditDialoguePopover';
import CatalogueSettings from './CatalogueSettings';
import { getActiveCatalogueId } from 'qs-data-manager/Catalogues';
import { ReactComponent as EditIcon } from 'qs-assets/Media/pencil.svg';
import { ReactComponent as SearchIcon } from 'qs-assets/Media/magnifyingGlass.svg';
import { ReactComponent as ShareIcon } from 'qs-assets/Media/share.svg';
import { ReactComponent as PreviewIcon } from 'qs-assets/Media/play.svg';
import { ReactComponent as SettingsIcon } from 'qs-assets/Media/userDetailsGearFilled.svg';
import './styles.scss';
import Mixpanel from 'qs-data-manager/Mixpanel';
import CataloguePrivacySettingsDialog from './CatalogueSettings/CataloguePrivacySettings/CataloguePrivacySettingsDialog';
import { getI18N } from 'qs-services/i18N';

export default ({ onClickHeaderOption, onSearchIconClick, activeCatalogueId, headerClass }) => {
  const [sharePopover, setSharePopover] = useState(false);
  const [settingsModal, setSettingsModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [showCataloguePrivacyDialog, setShowCataloguePrivacyDialog] = useState(false);
  const { t } = getI18N();

  const onPreviewClick = useCallback(() => {
    const catalogueId = getActiveCatalogueId();
    previewCatalogue({ catalogueId });
    Mixpanel.sendEvent({
      eventName: 'catalogue_previewed',
      props: {
        catalogueId
      }
    });
  }, []);

  useEffect(() => {
    setEditModal(false);
  }, [activeCatalogueId, settingsModal]);

  const onShareClick = useCallback(() => {
    setSharePopover(prevState => !prevState);
  }, []);

  const onSettingsIconClick = useCallback(e => {
    e.stopPropagation();
    setSettingsModal(prevState => !prevState);
  }, []);

  const closeCatalogueSettings = useCallback(() => {
    setSettingsModal(false);
  }, []);

  const closeSharePopover = useCallback(() => {
    setSharePopover(false);
  }, []);

  const onHeaderEditIconClick = event => {
    event.stopPropagation();
    event.preventDefault();
    setEditModal(prevState => !prevState);
  };

  const closeEditModal = event => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    setEditModal(false);
  };

  const closeCataloguePrivacyDialog = () => {
    closeCatalogueSettings();
    setShowCataloguePrivacyDialog(prevState => !prevState);
  };

  return (
    <div id={'ProductHeaderWrapper'} className={`productListHeader ${headerClass}`}>
      <div id={'ProductHeaderContainer'} className={'container'}>
        <div onClick={onHeaderEditIconClick} className={'iconContainer editDialogContainer'}>
          <EditDialoguePopover
            showPopover={editModal}
            catalogueId={activeCatalogueId}
            closePopover={closeEditModal}
            onClickHeaderOption={onClickHeaderOption}
          >
            <div className={'productHeaderIconContainer'}>
              <EditIcon className={'productHeaderIcon editIcon'} />
              <div className={'label'}>{t('edit')}</div>
            </div>
          </EditDialoguePopover>
        </div>
        <div onClick={onSearchIconClick} className={'iconContainer'}>
          <SearchIcon className={'productHeaderIcon'} />
          <div className={'label'}>{t('search')}</div>
        </div>
        <div onClick={onShareClick} className={'iconContainer shareDialogueContainer'}>
          <ShareDialoguePopover
            showPopover={sharePopover}
            catalogueId={activeCatalogueId}
            closePopover={closeSharePopover}
          >
            <div className={'productHeaderIconContainer'}>
              <ShareIcon className={'productHeaderIcon'} />
              <div className={'label'}>{t('share')}</div>
            </div>
          </ShareDialoguePopover>
        </div>
        <div onClick={onPreviewClick} className={'iconContainer'}>
          <PreviewIcon className={'productHeaderIcon'} />
          <div className={'label'}>{t('preview')}</div>
        </div>
        <div onClick={onSettingsIconClick} className={'iconContainer settingsIconContainer'}>
          <SettingsIcon className={'productHeaderIcon settingsIcon'} />
          <div className={'label'}>{t('settings')}</div>
        </div>
        {settingsModal ? (
          <CatalogueSettings
            closeCatalogueSettings={closeCatalogueSettings}
            closeCataloguePrivacyDialog={closeCataloguePrivacyDialog}
          />
        ) : null}
        {showCataloguePrivacyDialog && (
          <CataloguePrivacySettingsDialog
            closeCatalogueSettings={closeCatalogueSettings}
            closeCataloguePrivacyDialog={closeCataloguePrivacyDialog}
            catalogueId={activeCatalogueId}
            showCataloguePrivacyDialog={showCataloguePrivacyDialog}
          />
        )}
      </div>
    </div>
  );
};
