import Listener from 'qs-helpers/Listener';
import CatalogueLib from 'qs-data-manager/Catalogues';
import { registerCleanupHandler } from 'qs-helpers/ClearSavedData';
import { generalDb } from 'qs-config/FirebaseConfig';

const listener = new Listener(generalDb);
let LISTENERS_ATTACHED = {};
const END_POINT = {
  CATALOGUE_CHANGE_TIMESTAMP: ({ companyId }) =>
    `screen-update-timestamps/${companyId}/catalogues-screen`
};

const attachCatalogueChangesListener = ({ companyId }) => {
  if (!companyId) {
    return;
  }

  const key = END_POINT.CATALOGUE_CHANGE_TIMESTAMP({ companyId });

  if (LISTENERS_ATTACHED[key]) {
    return;
  }

  LISTENERS_ATTACHED[key] = true;
  listener.add(
    { key, type: 'value' },
    snapshot => {
      const value = snapshot.val();
      CatalogueLib.handleCatalogueChangeListener({ timestamp: value });
    },
    () => {}
  );
};

const removeCatalogueChangesListener = ({ companyId }) => {
  if (!companyId) {
    return;
  }

  const key = END_POINT.CATALOGUE_CHANGE_TIMESTAMP({ companyId });
  LISTENERS_ATTACHED[key] = false;
  listener.remove({ key, type: 'value' });
};

const clearAttachedListener = () => {
  LISTENERS_ATTACHED = {};
  listener.removeAll();
};

registerCleanupHandler(clearAttachedListener);

export { attachCatalogueChangesListener, removeCatalogueChangesListener };
