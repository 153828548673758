import { connector } from './ApiAndCacheConnector';
import CacheRequest from './CacheRequest';

const attachConfigsListener = listener => {
  const key = connector.CONFIG_DATA.cacheKey;
  CacheRequest.attachListener(key, listener);
};

const removeConfigsListener = listener => {
  const key = connector.CONFIG_DATA.cacheKey;
  CacheRequest.removeListener(key, listener);
};

const setConfigInCache = data => {
  const key = connector.CONFIG_DATA.cacheKey;
  CacheRequest.setCacheForKey(key, data);
};

const getConfigFromCache = () => {
  const key = connector.CONFIG_DATA.cacheKey;
  return CacheRequest.getCacheForKey(key);
};

//Company request product demo
const attachConfigDemoLinkListener = listener => {
  const key = connector.CONFIG_PRODUCT_DEMO.cacheKey;
  CacheRequest.attachListener(key, listener);
};

const removeConfigDemoLinkListener = listener => {
  const key = connector.CONFIG_PRODUCT_DEMO.cacheKey;
  CacheRequest.removeListener(key, listener);
};

const getConfigDemoLink = () => {
  const key = connector.CONFIG_PRODUCT_DEMO.cacheKey;
  const apiName = connector.CONFIG_PRODUCT_DEMO.apiFunction;
  return CacheRequest.makeRequest(key, apiName, {
    params: [],
    options: {
      shouldNotStoreInNative: true
    }
  });
};

const getConfigDemoLinkFromCache = () => {
  const key = connector.CONFIG_PRODUCT_DEMO.cacheKey;
  return CacheRequest.getCacheForKey(key);
};

export {
  attachConfigsListener,
  removeConfigsListener,
  setConfigInCache,
  getConfigFromCache,
  attachConfigDemoLinkListener,
  removeConfigDemoLinkListener,
  getConfigDemoLink,
  getConfigDemoLinkFromCache
};
