import ImageLoader from './CellRenderers/ImageLoader';
import {
  formatProductTitle,
  formatTaxes,
  formatCreatedDate,
  priceRenderer
} from 'qs-helpers/ProductLibrary/CellHelpers';

/*
  Important: The padding must be accounted for in the width calculation because
  the width of each column is used to caclculate the total width. The box-sizing
  model is border-box, hence to porivde the desired width the padding must be
  added. If the box-sizing model is changed the row is less than the desired width
  because the grid calculate the total width from the provided widths only
*/

//Horizontal grid padding is the same as the ProductLibraryGrid component's scss
const horizontalGridPadding = 15 * 2;

//Defined in the ProductLibraryGrid component's scss
const checkboxColumnClass = 'checkBoxColumn';

// Total padding defined in the ProductLibraryGrid component's scss class
// denoted by checkboxColumnClass
const checkboxColumnHorizontalPadding = 36;

export const COLUMN_DEFS = [
  // Checbox selection is enabled and disabled by the respective grid components
  {
    headerName: 'image',
    field: 'image',
    cellRenderer: ImageLoader,
    width: 92 + checkboxColumnHorizontalPadding,
    pinned: 'left',
    cellClass: checkboxColumnClass,
    headerClass: checkboxColumnClass
  },
  {
    headerName: 'SKU',
    field: 'sku',
    width: 80 + horizontalGridPadding
  },
  {
    headerName: 'title',
    field: 'name',
    valueFormatter: formatProductTitle,
    minWidth: 350 + horizontalGridPadding,
    flex: 2
  },
  {
    headerName: 'price',
    field: 'price',
    valueFormatter: priceRenderer,
    width: 100 + horizontalGridPadding
  },
  {
    headerName: 'discounted_price',
    field: 'discountedPrice',
    valueFormatter: priceRenderer,
    width: 150 + horizontalGridPadding
  },
  {
    headerName: 'tax',
    field: 'taxes',
    valueFormatter: formatTaxes,
    minWidth: 150 + horizontalGridPadding,
    flex: 1
  },
  {
    headerName: 'date_created',
    field: 'dateCreated',
    valueFormatter: formatCreatedDate,
    width: 200 + horizontalGridPadding
  }
];

export const defaultColumnProps = { suppressMovable: true };
