import React, { useState, useEffect, useCallback } from 'react';
import { selectedCatalogue } from 'qs-data-manager/Selected';
import CatalogueLib from 'qs-services/DataManager/Catalogues';
import Mixpanel from 'qs-data-manager/Mixpanel';
import HeaderWithBackAndSelect from 'qs-components/Common/HeaderWithBackAndSelect';
import { ReactComponent as LabelIcon } from 'qs-assets/Media/tag-icon.svg';
import { Tooltip, withStyles } from '@material-ui/core';
import SelectLabelsDialog from './SelectLabelsDialog';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

const IconToolTip = withStyles({
  tooltip: {
    backgroundColor: '#37A06D',
    fontSize: 12
  }
})(Tooltip);

export default ({ onBackClick, headerClass }) => {
  const [checkbox, setCheckbox] = useState(false);
  const [deleteIconVisibility, setDeleteIconVisibility] = useState(false);
  const [openLabelDialogue, setOpenLabelDialogue] = useState(false);

  const { t } = getI18N();

  const deactivateSelectionState = useCallback(() => {
    selectedCatalogue.deactivate();
  }, []);

  const onSelectedStateChange = useCallback(() => {
    const areAllSelected = selectedCatalogue.areAllSelected();
    const isAnySelected = selectedCatalogue.isAnySelected();
    setCheckbox(areAllSelected);
    setDeleteIconVisibility(isAnySelected);
  }, []);

  useEffect(() => {
    selectedCatalogue.addListener(onSelectedStateChange);
    return () => {
      selectedCatalogue.removeListener(onSelectedStateChange);
    };
  }, [onSelectedStateChange]);

  useEffect(() => {
    return () => deactivateSelectionState();
  }, [deactivateSelectionState]);

  useEffect(() => {
    if (openLabelDialogue) {
      Mixpanel.sendEvent({ eventName: 'menu_add_or_remove_catalogue_label' });
    }
  }, [openLabelDialogue]);

  const onCheckBoxToggle = () => {
    const newValue = !checkbox;
    setCheckbox(newValue);

    if (newValue !== deleteIconVisibility) {
      setDeleteIconVisibility(newValue);
    }

    if (newValue) {
      selectedCatalogue.setAll();
    } else {
      selectedCatalogue.removeAll();
    }
  };

  const goBack = () => {
    deactivateSelectionState();
    onBackClick();
  };

  const onDeleteClick = () => {
    const shouldRemove = window.confirm(
      t('are_you_sure_you_want_to_delete_the_selected_catalogues')
    );

    if (shouldRemove) {
      const ids = Object.keys(selectedCatalogue.getAll());
      CatalogueLib.deleteCatalogues(ids);
      goBack();
    }
  };

  const renderAdditionalIcons = () => (
    <IconToolTip title={t('add_labels')}>
      <LabelIcon
        aria-label={'Add Labels'}
        onClick={() => setOpenLabelDialogue(true)}
        className={'copyIcon'}
      />
    </IconToolTip>
  );

  return (
    <>
      <HeaderWithBackAndSelect
        headerClass={headerClass}
        goBack={goBack}
        deleteIconVisibility={deleteIconVisibility}
        deleteIconTitle={t('delete_catalogues')}
        onDeleteClick={onDeleteClick}
        checkboxValue={checkbox}
        onCheckBoxToggle={onCheckBoxToggle}
        renderAdditionalIcons={renderAdditionalIcons}
      />
      <SelectLabelsDialog
        show={openLabelDialogue}
        onClose={setOpenLabelDialogue}
        title={t('select_catalogue_labels')}
      />
    </>
  );
};
